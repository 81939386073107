import Head from 'next/head';
import React from 'react';

import { TITLE } from '@core/strings';

type PageTitleProps = {
  title: string;
};

const PageTitle = ({ title }: PageTitleProps) => (
  <Head>
    <title>{`${title} - ${TITLE}`}</title>
  </Head>
);

export default PageTitle;
