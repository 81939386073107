import type { Tool } from '@app/StudioPage/components/LFComposerToolbar/types';
import type {
  DataSource,
  InverseLabelMap,
  LabelMap,
  LFsInfo,
  SplitType,
  TaskConfig,
} from '@core/types';
import type { User } from '@global/state/reducers/users/types';
import asNumber from '@utils/asNumber';
import pipe from '@utils/pipe';

import type { TaskSettings, TaskState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<TaskState> = ({ task }: RootState) => task;

export const selectClassLabels: Selector<(string | number)[]> = pipe(
  selectAll,
  ({ class_labels }: TaskState) => class_labels,
);
export const selectDatasetName: Selector<string> = pipe(
  selectAll,
  ({ dataset_name }: TaskState) => dataset_name,
);
export const selectDatasetUid: Selector<number> = pipe(
  selectAll,
  ({ dataset_uid }: TaskState) => dataset_uid,
);
export const selectIsSettingsLoading: Selector<boolean> = pipe(
  selectAll,
  ({ settings_loading }: TaskState) => settings_loading,
);
export const selectLfsInfo: Selector<LFsInfo> = pipe(
  selectAll,
  ({ lfsInfo }: TaskState) => lfsInfo,
);
export const selectName: Selector<string> = pipe(
  selectAll,
  ({ name }: TaskState) => name,
);
export const selectNodeId: Selector<number> = pipe(
  selectAll,
  ({ name }: TaskState) => asNumber(name),
);
export const selectSettings: Selector<TaskSettings> = pipe(
  selectAll,
  ({ settings }: TaskState) => settings,
);
export const selectSplit: Selector<SplitType> = pipe(
  selectAll,
  ({ split }: TaskState) => split,
);
export const selectSplits: Selector<SplitType[]> = pipe(
  selectAll,
  ({ splits }: TaskState) => splits,
);
export const selectIsDataInitialized: Selector<boolean> = pipe(
  selectAll,
  ({ dataInitialized }: TaskState) => dataInitialized,
);
export const selectTaskConfig: Selector<TaskConfig> = pipe(
  selectAll,
  ({ task_config }: TaskState) => task_config,
);
export const selectTool: Selector<Tool> = pipe(
  selectAll,
  ({ tool }: TaskState) => tool,
);
export const selectUsers: Selector<User[]> = pipe(
  selectAll,
  ({ users }: TaskState) => users,
);

export const selectApplicationUid: Selector<number | undefined> = pipe(
  selectAll,
  ({ application_uid }: TaskState) => application_uid,
);
export const selectDataSources: Selector<DataSource[] | undefined> = pipe(
  selectAll,
  ({ dataSources }: TaskState) => dataSources,
);
export const selectMode: Selector<string | undefined> = pipe(
  selectAll,
  ({ mode }: TaskState) => mode,
);
export const selectNodeCls = pipe(
  selectAll,
  ({ node_cls }: TaskState) => node_cls,
);
// export const selectNodeUid: Selector<number | undefined> = pipe(
//   selectAll,
//   ({ node_uid }: TaskState) => node_uid,
// );
export const selectUnknownLabelStr: Selector<string | undefined> = pipe(
  selectAll,
  ({ unknown_label_str }: TaskState) => unknown_label_str,
);

export const selectInverseLabelMap: Selector<InverseLabelMap> = pipe(
  selectTaskConfig,
  ({ inverse_label_map }: TaskConfig) => inverse_label_map,
);
export const selectLabelMap: Selector<LabelMap> = pipe(
  selectTaskConfig,
  ({ label_map }: TaskConfig) => label_map,
);

export default null;
