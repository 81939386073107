import type { RefObject } from 'react';

import type { ModelInfo } from '@api/tdm';
import type * as ViewTypes from '@coral/components/ModularDataView/types/viewTypes';

export type LabelMap = {
  [label: string]: number;
};

export type GetOnSelectPopupFnType = (
  containerRef: RefObject<HTMLDivElement>,
) => React.ReactNode;

export type ColumnControlProps = {
  selectedColumns: string[];
  columns: string[];
  onSelectedColumnsChange: (newSelectedOptions: string[]) => void;
};

export type ModularMultiHighlightControlsProps = {
  viewMode: ViewTypes.ViewType;
  labels: string[];
  labelColors?: string[];
  hasTrainingSets: boolean;
  hasModels: boolean;
  highlightViewConfig: HighlightViewConfigType;
  showIncorrectHighlights?: boolean;
  toggleShowIncorrectHighlights?: VoidFunction;
};

export type SpanTextsModel = number | SpanTextsModelOptions;

export enum SpanTextsModelOptions {
  GT = 'gt',
  CURR_LF = 'curr_lf',
}

export type ColorSpanDropdownProps = {
  model: SpanTextsModel;
  setModel: (model: SpanTextsModel) => void;
  models: ModelInfo[];
};

export type SpanDrawerControlProps = {
  spanFields: string[];
  selectedSpanFields: string[];
  updateSelectedSpanFields: (fields: string[]) => void;
  setSelectedSpan: (selected: string[]) => void;
};

export type LabelCounter = { [label: number]: number };

export type GTConflictMap = {
  [spanText: string]: LabelCounter;
};

export type UpdateSelectedVariantFnType = (
  selected: string | null,
) => Promise<void>;
type ToggleVariantFnType = () => Promise<void>;

export type HighlightViewConfigType = {
  selectedGTHighlights: string;
  selectedLFVotes: string;
  selectedModelPredictions?: number;
  selectedTrainingSetVotes?: number;
  showGTHighlights: boolean;
  showLFVotes: boolean;
  showModelPredictions: boolean;
  showTrainingSetVotes: boolean;
  showMultiHighlightLegend: boolean;
  showOtherUsersGTHighlights: boolean;
  updateSelectedGTHighlights: UpdateSelectedVariantFnType;
  updateSelectedLFVotes: UpdateSelectedVariantFnType;
  toggleShowGTHighlights: ToggleVariantFnType;
  toggleShowLFVotes: ToggleVariantFnType;
  toggleShowModelPredictions: ToggleVariantFnType;
  toggleShowTrainingSetVotes: ToggleVariantFnType;
  toggleShowMultiHighlightLegend: ToggleVariantFnType;
};

export enum Direction {
  LTR = 'ltr',
  RTL = 'rtl',
}

export type CurrentLfVotes = { [uid: number]: number };

export type Span = {
  char_start: number;
  char_end: number;
  rich_doc_span_start_char_offset?: number;
  rich_doc_span_end_char_offset?: number;
  rich_doc_span_end_word_id?: string;
  span_text?: string;
} & {
  [field: string]: any;
};

export type ContextBody = {
  context_uid: number;
  text?: string;
  criterion?: string;
  ground_truth?: LabelMap | string[] | null;
  __DATAPOINT_UID: string;
} & {
  [field: string]: any;
};

export type EntityMap = {
  span_entity: string;
  __DATAPOINT_UID: string;
};

export type Spans = Span[];

export type Context = {
  context_uid: number;
  context: ContextBody;
  spans: Spans;
  entity?: EntityMap[];
};

export type TableDatumValueType = string | number | boolean | null | object;
export type TableDatum = Record<string, TableDatumValueType>;
export type TableData = TableDatum[];
