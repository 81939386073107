import { useState } from 'react';

import Icon, { Icons } from '@coral/components/Icon';
import SelectMenuButton from '@coral/components/SelectMenuButton';
import type { NodeDetails } from '@core/types';
import type { AppDetailsState } from '@global/state/reducers/appDetails/types';

import NavigationButton from '../common/NavigationButton';

const NODE_SELECTOR_PLACEHOLDER = 'Select model node';

interface NodeSelectorProps {
  application: AppDetailsState;
  className?: string;
  value?: number;
  onNodeChange: (nodeId: number) => void;
}

const getNodeString = (nodeId: number | string) => `Model node #${nodeId}`;

const NodeSelector = ({
  application,
  className,
  value,
  onNodeChange,
}: NodeSelectorProps) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const { dag: nodes = {} } = application || {};

  // obj to map node display string to node ID, so we don't have to parse it all the time
  const nodeLabelMap = Object.entries(nodes as Record<string, NodeDetails>)
    .filter(([_nodeId, node]) => node.expected_op_type === 'Model')
    .reduce(
      (acc, [nodeId]) => {
        const nodeString = getNodeString(nodeId);
        acc[nodeString] = Number(nodeId);

        return acc;
      },
      {} as Record<string, number>,
    );

  const listOfNodeLabels = Object.keys(nodeLabelMap);

  const handleNodeChange = (nextNode: string) => {
    if (nextNode === NODE_SELECTOR_PLACEHOLDER) {
      return;
    }

    const nextNodeId = nodeLabelMap[nextNode];
    onNodeChange(nextNodeId);
  };

  const handleOpenChange = (isOpen: boolean) => setIsSelectOpen(isOpen);

  const selectedNodeId = value
    ? getNodeString(value)
    : NODE_SELECTOR_PLACEHOLDER;

  if (listOfNodeLabels.length < 2) {
    return null;
  }

  return (
    <div className={className}>
      <SelectMenuButton
        value={selectedNodeId}
        data={listOfNodeLabels}
        variant="large"
        onOpenChange={handleOpenChange}
        onValueChange={handleNodeChange}
        button={
          <NavigationButton
            dataCy="node-selector-navigation-button"
            className={`mb-1 rounded-full border border-gray-200 focus:outline-none ${isSelectOpen ? 'bg-gray-100' : ''}`}
          >
            <span className="flex-1 truncate whitespace-pre">
              {selectedNodeId}
            </span>

            <Icon
              name={Icons.CARET__DOWN}
              className={
                isSelectOpen ? '-rotate-180 transition-all' : 'transition-all'
              }
            />
          </NavigationButton>
        }
      />
    </div>
  );
};

export default NodeSelector;
