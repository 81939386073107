/* tslint:disable */
/* eslint-disable */
/**
 * Studio
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from './base';

/**
 *
 * @export
 * @interface ActorModel
 */
export interface ActorModel {
  /**
   *
   * @type {string}
   * @memberof ActorModel
   */
  actor_id: string;
  /**
   *
   * @type {string}
   * @memberof ActorModel
   */
  namespace: string;
  /**
   *
   * @type {ActorResources}
   * @memberof ActorModel
   */
  normalized_resources?: ActorResources;
  /**
   *
   * @type {object}
   * @memberof ActorModel
   */
  ray_details?: object;
}
/**
 * A (normalized) view of the resources being taken up by a running actor. This is calculated by looking at the ActorMeta.required_resources field returned from the Ray state API and parsing out the placement-group-specific and Ray-specific markup on the resource names into something clean and sensible.  How many CPUs and how much memory is this actor holding?  [  {   \"state\": \"ALIVE\",   \"actor_id\": \"03470a5bbaf2defdd7e9f49001000000\",   # This actor has a placement group   \"required_resources\": {    \"memory_group_969629c36d4718b06c2da587b9e601000000\": 500000000.0,    \"CPU_group_969629c36d4718b06c2da587b9e601000000\": 1.0   },   # ...   \"class_name\": \"TaskServerActor\",   \"ray_namespace\": \"STUDIO_RAY_NAMESPACE\"  },  {   \"state\": \"PENDING_CREATION\",   \"actor_id\": \"1b021b22315e765e4954477a05000000\",   # This actor has no placement group   \"required_resources\": {    \"CPU\": 1.0,    \"memory\": 104857600.0   },   # ..   \"class_name\": \"UserNodeActor\",   \"ray_namespace\": \"STUDIO_RAY_NAMESPACE\"  } ]
 * @export
 * @interface ActorResources
 */
export interface ActorResources {
  /**
   *
   * @type {number}
   * @memberof ActorResources
   */
  memory_gb?: number;
  /**
   *
   * @type {number}
   * @memberof ActorResources
   */
  num_cpus?: number;
  /**
   *
   * @type {string}
   * @memberof ActorResources
   */
  placement_group_id?: string;
}
/**
 *
 * @export
 * @interface AdvancedStateRequest
 */
export interface AdvancedStateRequest {
  /**
   *
   * @type {string}
   * @memberof AdvancedStateRequest
   */
  next_state?: string;
}
/**
 *
 * @export
 * @interface ApplyLfRequestBody
 */
export interface ApplyLfRequestBody {
  /**
   *
   * @type {number}
   * @memberof ApplyLfRequestBody
   */
  apply_timeout_secs?: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplyLfRequestBody
   */
  fault_tolerant?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplyLfRequestBody
   */
  filter_active?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplyLfRequestBody
   */
  run_async?: boolean;
}
/**
 *
 * @export
 * @interface AsyncJobResponse
 */
export interface AsyncJobResponse {
  /**
   *
   * @type {string}
   * @memberof AsyncJobResponse
   */
  job_uid: string;
}
/**
 *
 * @export
 * @interface AutoLFRequestBody
 */
export interface AutoLFRequestBody {
  /**
   *
   * @type {string}
   * @memberof AutoLFRequestBody
   */
  field: string;
  /**
   *
   * @type {any}
   * @memberof AutoLFRequestBody
   */
  label?: any;
}
/**
 * Available embeddings for a dataset.
 * @export
 * @interface AvailableEmbeddings
 */
export interface AvailableEmbeddings {
  /**
   *
   * @type {Array<EmbeddingNodeInfo>}
   * @memberof AvailableEmbeddings
   */
  embedding_infos: Array<EmbeddingNodeInfo>;
}
/**
 *
 * @export
 * @interface BatchApplyLFRequestBody
 */
export interface BatchApplyLFRequestBody {
  /**
   *
   * @type {number}
   * @memberof BatchApplyLFRequestBody
   */
  apply_timeout_secs?: number;
  /**
   *
   * @type {boolean}
   * @memberof BatchApplyLFRequestBody
   */
  fault_tolerant?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BatchApplyLFRequestBody
   */
  filter_active?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof BatchApplyLFRequestBody
   */
  lf_uids?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof BatchApplyLFRequestBody
   */
  run_async?: boolean;
}
/**
 *
 * @export
 * @interface BulkDeleteLFs
 */
export interface BulkDeleteLFs {
  /**
   *
   * @type {Array<number>}
   * @memberof BulkDeleteLFs
   */
  lf_uids?: Array<number>;
}
/**
 *
 * @export
 * @interface CPUProfilingParams
 */
export interface CPUProfilingParams {
  /**
   *
   * @type {string}
   * @memberof CPUProfilingParams
   */
  service: string;
}
/**
 *
 * @export
 * @interface CPUProfilingStatusResponse
 */
export interface CPUProfilingStatusResponse {
  /**
   *
   * @type {string}
   * @memberof CPUProfilingStatusResponse
   */
  service: string;
  /**
   *
   * @type {string}
   * @memberof CPUProfilingStatusResponse
   */
  status: string;
}
/**
 *
 * @export
 * @interface Cluster
 */
export interface Cluster {
  /**
   *
   * @type {ClusterData}
   * @memberof Cluster
   */
  cluster_data: ClusterData;
  /**
   *
   * @type {ClusterMetadata}
   * @memberof Cluster
   */
  cluster_metadata: ClusterMetadata;
  /**
   *
   * @type {ClusterMetadataStatic}
   * @memberof Cluster
   */
  cluster_metadata_static: ClusterMetadataStatic;
  /**
   *
   * @type {ClusterMetadataVariable}
   * @memberof Cluster
   */
  cluster_metadata_variable: ClusterMetadataVariable;
  /**
   *
   * @type {string}
   * @memberof Cluster
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof Cluster
   */
  node_uid: number;
  /**
   *
   * @type {string}
   * @memberof Cluster
   */
  x_uid_hash: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Cluster
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface ClusterData
 */
export interface ClusterData {
  /**
   *
   * @type {ClusterType}
   * @memberof ClusterData
   */
  cluster_type?: ClusterType;
}

/**
 * Stats about a cluster of documents
 * @export
 * @interface ClusterMetadata
 */
export interface ClusterMetadata {
  /**
   *
   * @type {boolean}
   * @memberof ClusterMetadata
   */
  finished?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClusterMetadata
   */
  job_id?: string;
}
/**
 * Static stats about a cluster of documents
 * @export
 * @interface ClusterMetadataStatic
 */
export interface ClusterMetadataStatic {
  /**
   *
   * @type {Array<any>}
   * @memberof ClusterMetadataStatic
   */
  n_grams_per_class?: Array<any>;
}
/**
 * Changing stats about a cluster of documents
 * @export
 * @interface ClusterMetadataVariable
 */
export interface ClusterMetadataVariable {
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ClusterMetadataVariable
   */
  class_distribution?: { [key: string]: number };
  /**
   *
   * @type {any}
   * @memberof ClusterMetadataVariable
   */
  lf_label?: any;
  /**
   *
   * @type {any}
   * @memberof ClusterMetadataVariable
   */
  lf_metrics?: any;
  /**
   *
   * @type {LFState}
   * @memberof ClusterMetadataVariable
   */
  lf_state?: LFState;
  /**
   *
   * @type {number}
   * @memberof ClusterMetadataVariable
   */
  lf_uid?: number;
}

/**
 * A point in the 2D space of the embedding
 * @export
 * @interface ClusterPoint
 */
export interface ClusterPoint {
  /**
   *
   * @type {any}
   * @memberof ClusterPoint
   */
  label?: any;
  /**
   *
   * @type {number}
   * @memberof ClusterPoint
   */
  x: number;
  /**
   *
   * @type {string}
   * @memberof ClusterPoint
   */
  x_uid: string;
  /**
   *
   * @type {number}
   * @memberof ClusterPoint
   */
  y: number;
}
/**
 *
 * @export
 * @interface ClusterResponse
 */
export interface ClusterResponse {
  /**
   *
   * @type {string}
   * @memberof ClusterResponse
   */
  cluster_group_x_uid_hash?: string;
  /**
   *
   * @type {Array<Cluster>}
   * @memberof ClusterResponse
   */
  clusters: Array<Cluster>;
  /**
   *
   * @type {string}
   * @memberof ClusterResponse
   */
  error_message?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClusterResponse
   */
  is_processing_embeddings?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClusterResponse
   */
  job_id?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ClusterStrategy = {
  Autocluster: 'autocluster',
  Hierarchical: 'hierarchical',
} as const;

export type ClusterStrategy =
  (typeof ClusterStrategy)[keyof typeof ClusterStrategy];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ClusterType = {
  Autocluster: 'autocluster',
  Explore: 'explore',
  EmbeddingLf: 'embedding_lf',
} as const;

export type ClusterType = (typeof ClusterType)[keyof typeof ClusterType];

/**
 *
 * @export
 * @interface ComputeSearchMetricsRequest
 */
export interface ComputeSearchMetricsRequest {
  /**
   *
   * @type {boolean}
   * @memberof ComputeSearchMetricsRequest
   */
  compute_feedback?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ComputeSearchMetricsRequest
   */
  compute_full_metrics?: boolean;
  /**
   *
   * @type {number}
   * @memberof ComputeSearchMetricsRequest
   */
  editing_lf_uid?: number;
  /**
   *
   * @type {string}
   * @memberof ComputeSearchMetricsRequest
   */
  filter_config?: string;
  /**
   *
   * @type {Graph}
   * @memberof ComputeSearchMetricsRequest
   */
  graph?: Graph;
  /**
   *
   * @type {any}
   * @memberof ComputeSearchMetricsRequest
   */
  label?: any;
  /**
   *
   * @type {Array<object>}
   * @memberof ComputeSearchMetricsRequest
   */
  template_configs?: Array<object>;
}
/**
 *
 * @export
 * @interface ComputeSearchMetricsResponse
 */
export interface ComputeSearchMetricsResponse {
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ComputeSearchMetricsResponse
   */
  class_distribution: { [key: string]: number };
  /**
   *
   * @type {FeedbackMetrics}
   * @memberof ComputeSearchMetricsResponse
   */
  feedback_metrics?: FeedbackMetrics;
  /**
   *
   * @type {Array<FeedbackResponse>}
   * @memberof ComputeSearchMetricsResponse
   */
  feedback_responses?: Array<FeedbackResponse>;
  /**
   *
   * @type {number}
   * @memberof ComputeSearchMetricsResponse
   */
  highest_precision_class?: number;
  /**
   *
   * @type {object}
   * @memberof ComputeSearchMetricsResponse
   */
  metrics: object;
  /**
   *
   * @type {number}
   * @memberof ComputeSearchMetricsResponse
   */
  num_matches: number;
}
/**
 *
 * @export
 * @interface ComputeSimilarityHistogramRequest
 */
export interface ComputeSimilarityHistogramRequest {
  /**
   *
   * @type {string}
   * @memberof ComputeSimilarityHistogramRequest
   */
  filter_config?: string;
  /**
   *
   * @type {Graph}
   * @memberof ComputeSimilarityHistogramRequest
   */
  graph?: Graph;
  /**
   *
   * @type {Array<object>}
   * @memberof ComputeSimilarityHistogramRequest
   */
  template_configs: Array<object>;
}
/**
 *
 * @export
 * @interface ComputeSimilarityHistogramResponse
 */
export interface ComputeSimilarityHistogramResponse {
  /**
   *
   * @type {Array<HistogramBin>}
   * @memberof ComputeSimilarityHistogramResponse
   */
  histogram_bins: Array<HistogramBin>;
}
/**
 *
 * @export
 * @interface ContextOutputData
 */
export interface ContextOutputData {
  /**
   *
   * @type {Array<string>}
   * @memberof ContextOutputData
   */
  context_fields: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ContextOutputData
   */
  count: number;
  /**
   *
   * @type {Array<FormattedContext>}
   * @memberof ContextOutputData
   */
  data: Array<FormattedContext>;
  /**
   *
   * @type {Array<string>}
   * @memberof ContextOutputData
   */
  entity_fields: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ContextOutputData
   */
  offset: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ContextOutputData
   */
  span_fields: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ContextOutputData
   */
  span_gt_field: string;
  /**
   *
   * @type {string}
   * @memberof ContextOutputData
   */
  span_uid_field: string;
  /**
   *
   * @type {number}
   * @memberof ContextOutputData
   */
  total_count: number;
}
/**
 *
 * @export
 * @interface CreateClusterLFRequest
 */
export interface CreateClusterLFRequest {
  /**
   *
   * @type {any}
   * @memberof CreateClusterLFRequest
   */
  label?: any;
}
/**
 *
 * @export
 * @interface CreatePreviewRequest
 */
export interface CreatePreviewRequest {
  /**
   *
   * @type {number}
   * @memberof CreatePreviewRequest
   */
  sample_size?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CreatePreviewRequest
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface CreatePreviewResponse
 */
export interface CreatePreviewResponse {
  /**
   *
   * @type {number}
   * @memberof CreatePreviewResponse
   */
  datasource_uid: number;
}
/**
 *
 * @export
 * @interface DatasetResponse
 */
export interface DatasetResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof DatasetResponse
   */
  compatible_model_training_fields: Array<string>;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof DatasetResponse
   */
  compressed_data: { [key: string]: any };
  /**
   *
   * @type {string}
   * @memberof DatasetResponse
   */
  context_uid_field?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetResponse
   */
  context_x_uid_field?: string;
  /**
   *
   * @type {number}
   * @memberof DatasetResponse
   */
  count: number;
  /**
   *
   * @type {Array<object>}
   * @memberof DatasetResponse
   */
  data: Array<object>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof DatasetResponse
   */
  field_docstrings: { [key: string]: string };
  /**
   *
   * @type {Array<string>}
   * @memberof DatasetResponse
   */
  field_types: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DatasetResponse
   */
  fields: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DatasetResponse
   */
  gt_field: string;
  /**
   *
   * @type {number}
   * @memberof DatasetResponse
   */
  offset: number;
  /**
   *
   * @type {string}
   * @memberof DatasetResponse
   */
  rich_doc_field: string;
  /**
   *
   * @type {string}
   * @memberof DatasetResponse
   */
  suggest_field?: string;
  /**
   *
   * @type {number}
   * @memberof DatasetResponse
   */
  total_count: number;
  /**
   *
   * @type {Array<string>}
   * @memberof DatasetResponse
   */
  total_index: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DatasetResponse
   */
  uid_field: string;
}
/**
 *
 * @export
 * @interface DatasetUidsResponse
 */
export interface DatasetUidsResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof DatasetUidsResponse
   */
  uids: Array<string>;
}
/**
 * Information about an embedding node.  Attributes ---------- node_uid: int     The node_uid of the node that this embedding node is associated with.     When the embeddings originate from the new \"services.embeddings\" API,     this will be the same as the embeddings_uid. input_field: str     The name of the field that this embedding node is associated with. output_field: str     The name of the field that this embedding node will output. candidate_field: Optional[str]     The name of the field that this embedding node will use to find candidates. embeddings_uid: Optional[int]     The embeddings_uid of the embeddings from the \"services.embeddings\" API.
 * @export
 * @interface EmbeddingNodeInfo
 */
export interface EmbeddingNodeInfo {
  /**
   *
   * @type {string}
   * @memberof EmbeddingNodeInfo
   */
  candidate_field?: string;
  /**
   *
   * @type {number}
   * @memberof EmbeddingNodeInfo
   */
  embeddings_uid?: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingNodeInfo
   */
  input_field: string;
  /**
   *
   * @type {number}
   * @memberof EmbeddingNodeInfo
   */
  node_uid: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingNodeInfo
   */
  output_field: string;
}
/**
 *
 * @export
 * @interface EmbeddingSimilarityRequestBody
 */
export interface EmbeddingSimilarityRequestBody {
  /**
   *
   * @type {string}
   * @memberof EmbeddingSimilarityRequestBody
   */
  input: string;
}
/**
 *
 * @export
 * @interface EmbeddingSimilarityResponse
 */
export interface EmbeddingSimilarityResponse {
  /**
   *
   * @type {Array<number>}
   * @memberof EmbeddingSimilarityResponse
   */
  bin_edges: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof EmbeddingSimilarityResponse
   */
  histogram: Array<number>;
  /**
   *
   * @type {number}
   * @memberof EmbeddingSimilarityResponse
   */
  max_val: number;
  /**
   *
   * @type {number}
   * @memberof EmbeddingSimilarityResponse
   */
  min_val: number;
  /**
   *
   * @type {Array<SimilarityPoint>}
   * @memberof EmbeddingSimilarityResponse
   */
  sorted_points: Array<SimilarityPoint>;
}
/**
 *
 * @export
 * @interface ExportLFRequestBody
 */
export interface ExportLFRequestBody {
  /**
   *
   * @type {number}
   * @memberof ExportLFRequestBody
   */
  append_package_uid?: number;
  /**
   *
   * @type {boolean}
   * @memberof ExportLFRequestBody
   */
  label?: boolean;
  /**
   *
   * @type {string}
   * @memberof ExportLFRequestBody
   */
  name?: string;
}
/**
 *
 * @export
 * @interface FeedbackMetrics
 */
export interface FeedbackMetrics {
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof FeedbackMetrics
   */
  conflict_per_lf: { [key: string]: number };
  /**
   *
   * @type {number}
   * @memberof FeedbackMetrics
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof FeedbackMetrics
   */
  coverage: number;
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof FeedbackMetrics
   */
  duplicate_coverage: { [key: string]: boolean };
  /**
   *
   * @type {number}
   * @memberof FeedbackMetrics
   */
  est_precision: number;
  /**
   *
   * @type {number}
   * @memberof FeedbackMetrics
   */
  incremental_coverage: number;
  /**
   *
   * @type {number}
   * @memberof FeedbackMetrics
   */
  num_gt_for_lf_prec: number;
  /**
   *
   * @type {number}
   * @memberof FeedbackMetrics
   */
  precision: number;
  /**
   *
   * @type {number}
   * @memberof FeedbackMetrics
   */
  recall: number;
}
/**
 *
 * @export
 * @interface FeedbackResponse
 */
export interface FeedbackResponse {
  /**
   *
   * @type {number}
   * @memberof FeedbackResponse
   */
  score: number;
  /**
   *
   * @type {string}
   * @memberof FeedbackResponse
   */
  text: string;
}
/**
 *
 * @export
 * @interface FetchFilterInfoModel
 */
export interface FetchFilterInfoModel {
  /**
   *
   * @type {Array<object>}
   * @memberof FetchFilterInfoModel
   */
  info: Array<object>;
}
/**
 *
 * @export
 * @interface FetchLFVotedInfoParams
 */
export interface FetchLFVotedInfoParams {
  /**
   *
   * @type {Array<Array<Array<number>>>}
   * @memberof FetchLFVotedInfoParams
   */
  spans?: Array<Array<Array<number>>>;
  /**
   *
   * @type {string}
   * @memberof FetchLFVotedInfoParams
   */
  split: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FetchLFVotedInfoParams
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface FetchLFVotedInfoResponse
 */
export interface FetchLFVotedInfoResponse {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof FetchLFVotedInfoResponse
   */
  result: { [key: string]: any };
}
/**
 *
 * @export
 * @interface FilterToBatchModel
 */
export interface FilterToBatchModel {
  /**
   *
   * @type {Array<object>}
   * @memberof FilterToBatchModel
   */
  created_batches: Array<object>;
}
/**
 *
 * @export
 * @interface FilterToBatchPayload
 */
export interface FilterToBatchPayload {
  /**
   *
   * @type {string}
   * @memberof FilterToBatchPayload
   */
  custom_batch_name?: string;
  /**
   *
   * @type {string}
   * @memberof FilterToBatchPayload
   */
  filter_config?: string;
  /**
   *
   * @type {number}
   * @memberof FilterToBatchPayload
   */
  overlap?: number;
  /**
   *
   * @type {boolean}
   * @memberof FilterToBatchPayload
   */
  parallelize_batches?: boolean;
  /**
   *
   * @type {number}
   * @memberof FilterToBatchPayload
   */
  random_seed?: number;
  /**
   *
   * @type {number}
   * @memberof FilterToBatchPayload
   */
  sub_sample?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof FilterToBatchPayload
   */
  user_uids?: Array<number>;
}
/**
 *
 * @export
 * @interface FilterToTagsModel
 */
export interface FilterToTagsModel {
  /**
   *
   * @type {Array<any>}
   * @memberof FilterToTagsModel
   */
  filtered_tags: Array<any>;
}
/**
 *
 * @export
 * @interface FilterToTagsPayload
 */
export interface FilterToTagsPayload {
  /**
   *
   * @type {string}
   * @memberof FilterToTagsPayload
   */
  filter_config?: string;
  /**
   *
   * @type {boolean}
   * @memberof FilterToTagsPayload
   */
  gt_only?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FilterToTagsPayload
   */
  is_context_tag_type?: boolean;
  /**
   *
   * @type {number}
   * @memberof FilterToTagsPayload
   */
  random_seed?: number;
  /**
   *
   * @type {string}
   * @memberof FilterToTagsPayload
   */
  tag_description?: string;
  /**
   *
   * @type {string}
   * @memberof FilterToTagsPayload
   */
  tag_name?: string;
  /**
   *
   * @type {number}
   * @memberof FilterToTagsPayload
   */
  tag_type_uid?: number;
}
/**
 *
 * @export
 * @interface FlagLFsResponse
 */
export interface FlagLFsResponse {
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof FlagLFsResponse
   */
  lfs: { [key: string]: number };
}
/**
 *
 * @export
 * @interface FormattedContext
 */
export interface FormattedContext {
  /**
   *
   * @type {object}
   * @memberof FormattedContext
   */
  context: object;
  /**
   *
   * @type {Array<object>}
   * @memberof FormattedContext
   */
  entity?: Array<object>;
  /**
   *
   * @type {Array<object>}
   * @memberof FormattedContext
   */
  spans: Array<object>;
}
/**
 *
 * @export
 * @interface GetNgramDataResponse
 */
export interface GetNgramDataResponse {
  /**
   *
   * @type {number}
   * @memberof GetNgramDataResponse
   */
  count: number;
  /**
   *
   * @type {Array<NgramData>}
   * @memberof GetNgramDataResponse
   */
  data: Array<NgramData>;
  /**
   *
   * @type {number}
   * @memberof GetNgramDataResponse
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof GetNgramDataResponse
   */
  total_count: number;
}
/**
 *
 * @export
 * @interface GetPreviewRequest
 */
export interface GetPreviewRequest {
  /**
   *
   * @type {boolean}
   * @memberof GetPreviewRequest
   */
  group_by_context_uid?: boolean;
  /**
   *
   * @type {number}
   * @memberof GetPreviewRequest
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof GetPreviewRequest
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof GetPreviewRequest
   */
  target_op_id?: number;
}
/**
 *
 * @export
 * @interface GetPreviewResponse
 */
export interface GetPreviewResponse {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof GetPreviewResponse
   */
  compressed_data: { [key: string]: any };
  /**
   *
   * @type {number}
   * @memberof GetPreviewResponse
   */
  count: number;
  /**
   *
   * @type {Array<object>}
   * @memberof GetPreviewResponse
   */
  data: Array<object>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof GetPreviewResponse
   */
  field_docstrings: { [key: string]: string };
  /**
   *
   * @type {Array<string>}
   * @memberof GetPreviewResponse
   */
  field_types: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof GetPreviewResponse
   */
  fields_value: Array<string>;
  /**
   *
   * @type {Array<PreviewMessage>}
   * @memberof GetPreviewResponse
   */
  preview_messages?: Array<PreviewMessage>;
  /**
   *
   * @type {number}
   * @memberof GetPreviewResponse
   */
  total_count: number;
}
/**
 *
 * @export
 * @interface Graph
 */
export interface Graph {}
/**
 *
 * @export
 * @interface Graph1
 */
export interface Graph1 {}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface HierarchicalClusterDistResponse
 */
export interface HierarchicalClusterDistResponse {
  /**
   *
   * @type {Array<HierarchicalDistInfo>}
   * @memberof HierarchicalClusterDistResponse
   */
  hierarchical_dist: Array<HierarchicalDistInfo>;
}
/**
 *
 * @export
 * @interface HierarchicalDistInfo
 */
export interface HierarchicalDistInfo {
  /**
   *
   * @type {number}
   * @memberof HierarchicalDistInfo
   */
  avg_cluster_size: number;
  /**
   *
   * @type {number}
   * @memberof HierarchicalDistInfo
   */
  num_clusters: number;
  /**
   *
   * @type {number}
   * @memberof HierarchicalDistInfo
   */
  value: number;
}
/**
 *
 * @export
 * @interface HighlightMetadataParams
 */
export interface HighlightMetadataParams {
  /**
   *
   * @type {boolean}
   * @memberof HighlightMetadataParams
   */
  fetch_span_text?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HighlightMetadataParams
   */
  filter_unlabeled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HighlightMetadataParams
   */
  is_context?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof HighlightMetadataParams
   */
  lf_uids?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof HighlightMetadataParams
   */
  model_uids?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof HighlightMetadataParams
   */
  return_confidences?: boolean;
  /**
   *
   * @type {string}
   * @memberof HighlightMetadataParams
   */
  split?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof HighlightMetadataParams
   */
  ts_uids?: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof HighlightMetadataParams
   */
  x_uids: Array<string>;
}
/**
 * For each of these dictionaries, keys are xuids and values are nested dictionaries with keys of the model_uid (or training_set_uid etc) and values with intervals and labels for those intervals. For examples of the types seen in these nested dictionaries (the Any part), look at the _transform methods at the bottom of this file
 * @export
 * @interface HighlightMetadataResponse
 */
export interface HighlightMetadataResponse {
  /**
   *
   * @type {object}
   * @memberof HighlightMetadataResponse
   */
  gt_labels?: object;
  /**
   *
   * @type {object}
   * @memberof HighlightMetadataResponse
   */
  lf_labels?: object;
  /**
   *
   * @type {object}
   * @memberof HighlightMetadataResponse
   */
  model_predictions?: object;
  /**
   *
   * @type {object}
   * @memberof HighlightMetadataResponse
   */
  training_set_labels?: object;
}
/**
 *
 * @export
 * @interface HighlightRequestBody
 */
export interface HighlightRequestBody {
  /**
   *
   * @type {Graph}
   * @memberof HighlightRequestBody
   */
  graph?: Graph;
  /**
   *
   * @type {Array<object>}
   * @memberof HighlightRequestBody
   */
  template_configs: Array<object>;
}
/**
 *
 * @export
 * @interface HighlightsRequestBody
 */
export interface HighlightsRequestBody {
  /**
   *
   * @type {Array<number>}
   * @memberof HighlightsRequestBody
   */
  lf_uids: Array<number>;
}
/**
 *
 * @export
 * @interface HistogramBin
 */
export interface HistogramBin {
  /**
   *
   * @type {number}
   * @memberof HistogramBin
   */
  bin_left_edge: number;
  /**
   *
   * @type {number}
   * @memberof HistogramBin
   */
  count: number;
}
/**
 *
 * @export
 * @interface JobGroup
 */
export interface JobGroup {
  /**
   *
   * @type {Array<RQJobId>}
   * @memberof JobGroup
   */
  jobs: Array<RQJobId>;
  /**
   *
   * @type {RQMetaJobId}
   * @memberof JobGroup
   */
  meta_job_id?: RQMetaJobId;
}
/**
 * Class used to track asynchronous engine jobs. Use data_models.engine.start_tdm_engine_job to get a RQJobId, and JobInfo.from_rq to get the full job info. The expectation is that once start_tdm_engine_job() is fired off, the FastAPI route returns the job ID that the client uses to poll until completion.
 * @export
 * @interface JobInfo
 */
export interface JobInfo {
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  application_name?: string;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  application_uid?: number;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  dataset_uid?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof JobInfo
   */
  dependency_job_ids?: Array<string>;
  /**
   *
   * @type {object}
   * @memberof JobInfo
   */
  detail?: object;
  /**
   * If null, job has not finished.
   * @type {string}
   * @memberof JobInfo
   */
  end_time: string;
  /**
   * We don\'t expect enqueued time to be null, but if it is, job has not been fully queued up yet
   * @type {string}
   * @memberof JobInfo
   */
  enqueued_time?: string;
  /**
   * If this field is null, job has not been picked up by a worker yet
   * @type {string}
   * @memberof JobInfo
   */
  execution_start_time: string;
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  function_name?: string;
  /**
   *
   * @type {JobGroup}
   * @memberof JobInfo
   */
  job_group?: JobGroup;
  /**
   *
   * @type {JobType}
   * @memberof JobInfo
   */
  job_type: JobType;
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  node_uid?: number;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  percent?: number;
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  pod_name?: string;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  process_id?: number;
  /**
   *
   * @type {JobState}
   * @memberof JobInfo
   */
  state: JobState;
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  sub_message?: string;
  /**
   *
   * @type {{ [key: string]: Timing; }}
   * @memberof JobInfo
   */
  timing?: { [key: string]: Timing };
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  uid: string;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  user_uid?: number;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  workspace_uid?: number;
}

/**
 *
 * @export
 * @interface JobListResponse
 */
export interface JobListResponse {
  /**
   *
   * @type {Array<JobInfo>}
   * @memberof JobListResponse
   */
  jobs: Array<JobInfo>;
  /**
   * For pagination purposes. To go forwards one page, set start_time to this value and direction to \'older\'
   * @type {number}
   * @memberof JobListResponse
   */
  next_start_time: number;
  /**
   * For pagination purposes. To go backwards one page, set start_time to this value and direction to \'newer\'
   * @type {number}
   * @memberof JobListResponse
   */
  previous_start_time: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const JobState = {
  Running: 'running',
  Completed: 'completed',
  Failed: 'failed',
  Cancelled: 'cancelled',
} as const;

export type JobState = (typeof JobState)[keyof typeof JobState];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const JobType = {
  AnalyzeDatasource: 'analyze-datasource',
  ApplyPopulator: 'apply-populator',
  CreatePopulator: 'create-populator',
  GarbageCollectPopulators: 'garbage-collect-populators',
  CreateTask: 'create-task',
  ComputeCluster: 'compute-cluster',
  ComputeScores: 'compute-scores',
  ComputeOptimalThreshold: 'compute-optimal-threshold',
  AlignGroundTruth: 'align-ground-truth',
  AddGroundTruth: 'add-ground-truth',
  AddFlatGroundTruth: 'add-flat-ground-truth',
  PatchTask: 'patch-task',
  IngestData: 'ingest-data',
  DeleteDatasource: 'delete-datasource',
  IngestAndSwapDatasource: 'ingest-and-swap-datasource',
  ResplitData: 'resplit-data',
  PrepAndIngestData: 'prep-and-ingest-data',
  PreviewData: 'preview-data',
  SetTaskData: 'set-task-data',
  CreateApplicationDeployment: 'create-application-deployment',
  IngestReferenceDataset: 'ingest-reference-dataset',
  IdentityJob: 'identity-job',
  PredictionApiAsyncInference: 'prediction-api-async-inference',
  SetActivePlaceholderData: 'set-active-placeholder-data',
  SetActiveNodeData: 'set-active-node-data',
  CreateTrainSampleDatasource: 'create-train-sample-datasource',
  GetNodePreview: 'get-node-preview',
  LabelDataset: 'label-dataset',
  FitOperator: 'fit-operator',
  FitModelBasedFeaturizer: 'fit-model-based-featurizer',
  TrainModel: 'train-model',
  RegisterPredictModel: 'register-predict-model',
  PackageLabelTrain: 'package-label-train',
  RunPromptInference: 'run-prompt-inference',
  RunAllPromptInference: 'run-all-prompt-inference',
  PromptFm: 'prompt-fm',
  ContinueLongRunningLf: 'continue-long-running-lf',
  BuildReport: 'build-report',
  TakeBackup: 'take-backup',
  RestoreBackup: 'restore-backup',
  ListBackups: 'list-backups',
  IngestGroundTruth: 'ingest-ground-truth',
  LoadGroundTruthVersion: 'load-ground-truth-version',
  TransferAssets: 'transfer-assets',
  TransferGroundTruth: 'transfer-ground-truth',
  TransferLfs: 'transfer-lfs',
  DownloadRemoteObject: 'download-remote-object',
  GetSpanLevelGroundTruthConflicts: 'get-span-level-ground-truth-conflicts',
  DeleteApplication: 'delete-application',
  ExecuteGraphOnDataApplication: 'execute-graph-on-data-application',
  RefreshApplication: 'refresh-application',
  LoadDataset: 'load-dataset',
  RemoveDataset: 'remove-dataset',
  ProfileDataset: 'profile-dataset',
  PeekDatasourceColumns: 'peek-datasource-columns',
  FetchDatasetColumnTypes: 'fetch-dataset-column-types',
  FetchDataframeColumnTypes: 'fetch-dataframe-column-types',
  PeekDatasourceColumnMap: 'peek-datasource-column-map',
  CreateOpVersionAddOpPredictions: 'create-op-version-add-op-predictions',
  CreateSimpleLfSuggestionsJob: 'create-simple-lf-suggestions-job',
  PopulateLfSuggestions: 'populate-lf-suggestions',
  GarbageCollectDatasetApplicationNodes:
    'garbage-collect-dataset-application-nodes',
  RegisterIngestModel: 'register-ingest-model',
  ExportMlflowDeploymentExternalRegistry:
    'export-mlflow-deployment-external-registry',
  GarbageCollectDatasetApplication: 'garbage-collect-dataset-application',
  StoreFeatures: 'store-features',
  GetLfSuggestionsForStudioV2: 'get-lf-suggestions-for-studio-v2',
  ApplyLfsWrapper: 'apply-lfs-wrapper',
  CreatePredictionApiService: 'create-prediction-api-service',
  DeletePredictionApiService: 'delete-prediction-api-service',
  InteractiveSharedLfApply: 'interactive-shared-lf-apply',
  InteractiveDedicatedLfApply: 'interactive-dedicated-lf-apply',
  NoOp: 'no-op',
  MergeAssets: 'merge-assets',
  ExecuteBatch: 'execute-batch',
  LoadUserNodeActor: 'load-user-node-actor',
  DestroyUserNodeActor: 'destroy-user-node-actor',
  ComputeEmbeddings: 'compute-embeddings',
  UpdateStudioDfEmbeddings: 'update-studio-df-embeddings',
  ClearStudioDfCache: 'clear-studio-df-cache',
  DeleteNode: 'delete-node',
  ComputeMetrics: 'compute-metrics',
  ComputeDocMetrics: 'compute-doc-metrics',
  FetchDatasetColumns: 'fetch-dataset-columns',
  FetchDataframeColumns: 'fetch-dataframe-columns',
  RefreshActiveDatasources: 'refresh-active-datasources',
  ReadCandidates: 'read-candidates',
  CreateDeploymentFromWorkflow: 'create-deployment-from-workflow',
  ExecuteWorkflowFile: 'execute-workflow-file',
  ExecuteWorkflowDf: 'execute-workflow-df',
  ExecuteWorkflowConfig: 'execute-workflow-config',
  PostprocessModel: 'postprocess-model',
  GetUniqueValuesInColumn: 'get-unique-values-in-column',
  RemoveStudioNodeData: 'remove-studio-node-data',
  CleanDiskLruCache: 'clean-disk-lru-cache',
  SaveLfVotesToDb: 'save-lf-votes-to-db',
  AddModelPredToDb: 'add-model-pred-to-db',
  AddTsToDb: 'add-ts-to-db',
  CopyLabelSchema: 'copy-label-schema',
  UploadStaticAssets: 'upload-static-assets',
  FinetuneModel: 'finetune-model',
  PrepAndIngestFineTuningData: 'prep-and-ingest-fine-tuning-data',
  FinetuneModelInference: 'finetune-model-inference',
  EvaluationComputeMetrics: 'evaluation-compute-metrics',
  BackfillPredictions: 'backfill-predictions',
  SliceApply: 'slice-apply',
  PredictModel: 'predict-model',
} as const;

export type JobType = (typeof JobType)[keyof typeof JobType];

/**
 *
 * @export
 * @interface LFHighlight
 */
export interface LFHighlight {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof LFHighlight
   */
  aggregate_spans: { [key: string]: any };
  /**
   *
   * @type {string}
   * @memberof LFHighlight
   */
  lf_name: string;
  /**
   *
   * @type {number}
   * @memberof LFHighlight
   */
  lf_uid: number;
  /**
   *
   * @type {boolean}
   * @memberof LFHighlight
   */
  supports_lf_highlights: boolean;
  /**
   *
   * @type {Array<{ [key: string]: any; }>}
   * @memberof LFHighlight
   */
  template_spans: Array<{ [key: string]: any }>;
}
/**
 *
 * @export
 * @interface LFJobResults
 */
export interface LFJobResults {
  /**
   *
   * @type {string}
   * @memberof LFJobResults
   */
  job_id: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LFState = {
  Active: 'active',
  Edited: 'edited',
  Archived: 'archived',
  Suggested: 'suggested',
  Rejected: 'rejected',
  InProgress: 'in_progress',
} as const;

export type LFState = (typeof LFState)[keyof typeof LFState];

/**
 *
 * @export
 * @interface LFStateRequest
 */
export interface LFStateRequest {
  /**
   *
   * @type {object}
   * @memberof LFStateRequest
   */
  config: object;
  /**
   *
   * @type {string}
   * @memberof LFStateRequest
   */
  current_state: string;
  /**
   *
   * @type {string}
   * @memberof LFStateRequest
   */
  next_state?: string;
}
/**
 *
 * @export
 * @interface LFStateResponse
 */
export interface LFStateResponse {
  /**
   *
   * @type {object}
   * @memberof LFStateResponse
   */
  config: object;
  /**
   *
   * @type {Array<object>}
   * @memberof LFStateResponse
   */
  display: Array<object>;
  /**
   *
   * @type {string}
   * @memberof LFStateResponse
   */
  state: string;
}
/**
 *
 * @export
 * @interface LFSummary
 */
export interface LFSummary {
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof LFSummary
   */
  class_balance?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof LFSummary
   */
  class_coverage?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof LFSummary
   */
  class_coverage_counts?: { [key: string]: any };
  /**
   *
   * @type {Array<any>}
   * @memberof LFSummary
   */
  conflict_matrix?: Array<any>;
  /**
   *
   * @type {number}
   * @memberof LFSummary
   */
  estimated_num_spans_per_doc?: number;
  /**
   *
   * @type {boolean}
   * @memberof LFSummary
   */
  is_class_imbalanced?: boolean;
  /**
   *
   * @type {number}
   * @memberof LFSummary
   */
  label_density?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof LFSummary
   */
  label_distribution?: { [key: string]: number };
  /**
   *
   * @type {Array<any>}
   * @memberof LFSummary
   */
  overlap_matrix?: Array<any>;
  /**
   *
   * @type {boolean}
   * @memberof LFSummary
   */
  skipped_compute?: boolean;
  /**
   *
   * @type {number}
   * @memberof LFSummary
   */
  total_conflict?: number;
  /**
   *
   * @type {number}
   * @memberof LFSummary
   */
  total_coverage?: number;
  /**
   *
   * @type {number}
   * @memberof LFSummary
   */
  total_lfs?: number;
  /**
   *
   * @type {number}
   * @memberof LFSummary
   */
  total_points?: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LLMType = {
  TaskHead: 'task_head',
  Setfit: 'setfit',
  Sdnet: 'sdnet',
  Entailment: 'entailment',
  MaskedLmZeroShot: 'masked_lm_zero_shot',
  MaskedLmFewShot: 'masked_lm_few_shot',
  Qa: 'qa',
  Text2text: 'text2text',
  Text2textMultiLabel: 'text2text_multi_label',
  DocVqa: 'doc_vqa',
  Text2textQa: 'text2text_qa',
  RagPromptEnrichmentDocClsPredictor: 'rag_prompt_enrichment_doc_cls_predictor',
  RagPromptEnrichmentSeqTagPredictor: 'rag_prompt_enrichment_seq_tag_predictor',
  PdfText2textExtractor: 'pdf_text2text_extractor',
  WordText2textQa: 'word_text2text_qa',
} as const;

export type LLMType = (typeof LLMType)[keyof typeof LLMType];

/**
 *
 * @export
 * @interface Label
 */
export interface Label {
  /**
   *
   * @type {string}
   * @memberof Label
   */
  label_str: string;
  /**
   *
   * @type {string}
   * @memberof Label
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface LabelsAutoFillRequestBody
 */
export interface LabelsAutoFillRequestBody {
  /**
   *
   * @type {string}
   * @memberof LabelsAutoFillRequestBody
   */
  autofill_strategy: string;
  /**
   *
   * @type {string}
   * @memberof LabelsAutoFillRequestBody
   */
  normalizer_class?: string;
  /**
   *
   * @type {boolean}
   * @memberof LabelsAutoFillRequestBody
   */
  override_span_ground_truths?: boolean;
  /**
   *
   * @type {UserDefinedOperatorConfig}
   * @memberof LabelsAutoFillRequestBody
   */
  user_defined_normalizer_config?: UserDefinedOperatorConfig;
}
/**
 *
 * @export
 * @interface LabelsRequestBody
 */
export interface LabelsRequestBody {
  /**
   *
   * @type {Array<Label>}
   * @memberof LabelsRequestBody
   */
  labels: Array<Label>;
}
/**
 *
 * @export
 * @interface LoadDatasetParams
 */
export interface LoadDatasetParams {
  /**
   *
   * @type {number}
   * @memberof LoadDatasetParams
   */
  batch_uid?: number;
}
/**
 *
 * @export
 * @interface LocalCacheDeleteParams
 */
export interface LocalCacheDeleteParams {
  /**
   *
   * @type {number}
   * @memberof LocalCacheDeleteParams
   */
  max_access_age_seconds: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const MODELTYPES = {
  ZslTextMatch: 'zsl_text_match',
  ZslEntailment: 'zsl_entailment',
  ZslPrompt: 'zsl_prompt',
  ZslPromptRemote: 'zsl_prompt_remote',
  ZslPromptMultiLabel: 'zsl_prompt_multi_label',
  ZslPromptMultiLabelRemote: 'zsl_prompt_multi_label_remote',
  MaskedLm: 'masked_lm',
  LogisticRegression: 'logistic_regression',
  OneClassSvm: 'one_class_svm',
  TfidfLogreg: 'tfidf_logreg',
  Setfit: 'setfit',
  SetfitMultilabel: 'setfit_multilabel',
  Sdnet: 'sdnet',
  SequenceEmbedding: 'sequence_embedding',
} as const;

export type MODELTYPES = (typeof MODELTYPES)[keyof typeof MODELTYPES];

/**
 *
 * @export
 * @interface MarginExamples
 */
export interface MarginExamples {
  /**
   *
   * @type {string}
   * @memberof MarginExamples
   */
  key: string;
  /**
   *
   * @type {number}
   * @memberof MarginExamples
   */
  margin_distance: number;
}
/**
 *
 * @export
 * @interface MarginFilterRequest
 */
export interface MarginFilterRequest {
  /**
   *
   * @type {boolean}
   * @memberof MarginFilterRequest
   */
  apply_postprocessors?: boolean;
  /**
   *
   * @type {number}
   * @memberof MarginFilterRequest
   */
  label_1: number;
  /**
   *
   * @type {number}
   * @memberof MarginFilterRequest
   */
  label_2: number;
}
/**
 *
 * @export
 * @interface MarginFilterResponse
 */
export interface MarginFilterResponse {
  /**
   *
   * @type {Array<MarginExamples>}
   * @memberof MarginFilterResponse
   */
  examples: Array<MarginExamples>;
}
/**
 *
 * @export
 * @interface MarginMatrixResponse
 */
export interface MarginMatrixResponse {
  /**
   *
   * @type {{ [key: string]: { [key: string]: any; }; }}
   * @memberof MarginMatrixResponse
   */
  matrix: { [key: string]: { [key: string]: any } };
}
/**
 *
 * @export
 * @interface MemoryProfile
 */
export interface MemoryProfile {
  /**
   *
   * @type {string}
   * @memberof MemoryProfile
   */
  profile: string;
  /**
   *
   * @type {string}
   * @memberof MemoryProfile
   */
  timestamp: string;
}
/**
 *
 * @export
 * @interface MemoryProfilingParams
 */
export interface MemoryProfilingParams {
  /**
   *
   * @type {PersistenceMode}
   * @memberof MemoryProfilingParams
   */
  persistence_mode?: PersistenceMode;
  /**
   *
   * @type {string}
   * @memberof MemoryProfilingParams
   */
  service: string;
}

/**
 *
 * @export
 * @interface MemoryProfilingStatusResponse
 */
export interface MemoryProfilingStatusResponse {
  /**
   *
   * @type {string}
   * @memberof MemoryProfilingStatusResponse
   */
  service: string;
  /**
   *
   * @type {string}
   * @memberof MemoryProfilingStatusResponse
   */
  status: string;
}
/**
 *
 * @export
 * @interface MemoryProfilingTraceResponse
 */
export interface MemoryProfilingTraceResponse {
  /**
   *
   * @type {Array<MemoryProfile>}
   * @memberof MemoryProfilingTraceResponse
   */
  profiles: Array<MemoryProfile>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const MessageLevel = {
  Info: 'info',
  Warning: 'warning',
  Error: 'error',
} as const;

export type MessageLevel = (typeof MessageLevel)[keyof typeof MessageLevel];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const MessageName = {
  NoData: 'no_data',
} as const;

export type MessageName = (typeof MessageName)[keyof typeof MessageName];

/**
 *
 * @export
 * @interface MetricsBucket
 */
export interface MetricsBucket {
  /**
   *
   * @type {number}
   * @memberof MetricsBucket
   */
  bin_size: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof MetricsBucket
   */
  class_votes?: { [key: string]: number };
  /**
   *
   * @type {number}
   * @memberof MetricsBucket
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof MetricsBucket
   */
  coverage?: number;
  /**
   *
   * @type {number}
   * @memberof MetricsBucket
   */
  emp_support: number;
  /**
   *
   * @type {number}
   * @memberof MetricsBucket
   */
  est_precision?: number;
  /**
   *
   * @type {number}
   * @memberof MetricsBucket
   */
  est_recall?: number;
  /**
   *
   * @type {object}
   * @memberof MetricsBucket
   */
  per_class_metrics?: object;
  /**
   *
   * @type {number}
   * @memberof MetricsBucket
   */
  precision?: number;
  /**
   *
   * @type {number}
   * @memberof MetricsBucket
   */
  recall?: number;
  /**
   *
   * @type {number}
   * @memberof MetricsBucket
   */
  score?: number;
  /**
   *
   * @type {number}
   * @memberof MetricsBucket
   */
  threshold: number;
}
/**
 *
 * @export
 * @interface NextLFStatesRequest
 */
export interface NextLFStatesRequest {
  /**
   *
   * @type {boolean}
   * @memberof NextLFStatesRequest
   */
  all_states?: boolean;
  /**
   *
   * @type {object}
   * @memberof NextLFStatesRequest
   */
  config?: object;
  /**
   *
   * @type {string}
   * @memberof NextLFStatesRequest
   */
  current_state?: string;
}
/**
 *
 * @export
 * @interface NextLFStatesResponse
 */
export interface NextLFStatesResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof NextLFStatesResponse
   */
  next_states: Array<string>;
}
/**
 *
 * @export
 * @interface NgramData
 */
export interface NgramData {
  /**
   *
   * @type {Array<number>}
   * @memberof NgramData
   */
  counts: Array<number>;
  /**
   *
   * @type {string}
   * @memberof NgramData
   */
  field: string;
  /**
   *
   * @type {string}
   * @memberof NgramData
   */
  label: string;
  /**
   *
   * @type {Array<string>}
   * @memberof NgramData
   */
  ngrams: Array<string>;
}
/**
 *
 * @export
 * @interface OnboardingModelCard
 */
export interface OnboardingModelCard {
  /**
   *
   * @type {object}
   * @memberof OnboardingModelCard
   */
  config?: object;
  /**
   *
   * @type {object}
   * @memberof OnboardingModelCard
   */
  model_init_args?: object;
  /**
   *
   * @type {string}
   * @memberof OnboardingModelCard
   */
  model_name: string;
  /**
   *
   * @type {MODELTYPES}
   * @memberof OnboardingModelCard
   */
  model_type: MODELTYPES;
  /**
   *
   * @type {string}
   * @memberof OnboardingModelCard
   */
  suggest_model?: string;
  /**
   *
   * @type {boolean}
   * @memberof OnboardingModelCard
   */
  support_local?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OnboardingModelCard
   */
  support_remote?: boolean;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const PersistenceMode = {
  Disk: 'disk',
  Inmemory: 'inmemory',
} as const;

export type PersistenceMode =
  (typeof PersistenceMode)[keyof typeof PersistenceMode];

/**
 *
 * @export
 * @interface PopulateLFSuggestionsRequest
 */
export interface PopulateLFSuggestionsRequest {
  /**
   *
   * @type {boolean}
   * @memberof PopulateLFSuggestionsRequest
   */
  activate_lfs?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PopulateLFSuggestionsRequest
   */
  columns?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PopulateLFSuggestionsRequest
   */
  filter_strategy?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PopulateLFSuggestionsRequest
   */
  inference_splits?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PopulateLFSuggestionsRequest
   */
  keyword_precision_threshold?: number;
  /**
   *
   * @type {number}
   * @memberof PopulateLFSuggestionsRequest
   */
  max_num_lfs?: number;
  /**
   *
   * @type {number}
   * @memberof PopulateLFSuggestionsRequest
   */
  min_confidence_threshold?: number;
  /**
   *
   * @type {number}
   * @memberof PopulateLFSuggestionsRequest
   */
  min_coverage_threshold?: number;
  /**
   *
   * @type {number}
   * @memberof PopulateLFSuggestionsRequest
   */
  min_lf_precision_threshold?: number;
  /**
   *
   * @type {object}
   * @memberof PopulateLFSuggestionsRequest
   */
  model_init_args?: object;
  /**
   *
   * @type {string}
   * @memberof PopulateLFSuggestionsRequest
   */
  model_name?: string;
  /**
   *
   * @type {string}
   * @memberof PopulateLFSuggestionsRequest
   */
  model_type?: string;
  /**
   *
   * @type {number}
   * @memberof PopulateLFSuggestionsRequest
   */
  n_grams?: number;
  /**
   *
   * @type {string}
   * @memberof PopulateLFSuggestionsRequest
   */
  origin?: string;
  /**
   *
   * @type {string}
   * @memberof PopulateLFSuggestionsRequest
   */
  split?: string;
  /**
   *
   * @type {string}
   * @memberof PopulateLFSuggestionsRequest
   */
  suggest_model?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof PopulateLFSuggestionsRequest
   */
  tag_uids?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof PopulateLFSuggestionsRequest
   */
  use_multipolar_template?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PopulateLFSuggestionsRequest
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface PopulateLFSuggestionsResults
 */
export interface PopulateLFSuggestionsResults {
  /**
   *
   * @type {object}
   * @memberof PopulateLFSuggestionsResults
   */
  results: object;
}
/**
 *
 * @export
 * @interface PostAutotunersRequestBody
 */
export interface PostAutotunersRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostAutotunersRequestBody
   */
  autotuner_type: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PostAutotunersRequestBody
   */
  field_names: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PostAutotunersRequestBody
   */
  min_precision: number;
  /**
   *
   * @type {boolean}
   * @memberof PostAutotunersRequestBody
   */
  optimize_uncovered?: boolean;
  /**
   *
   * @type {string}
   * @memberof PostAutotunersRequestBody
   */
  target_label: string;
}
/**
 *
 * @export
 * @interface PostGeneratorsRequestBody
 */
export interface PostGeneratorsRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostGeneratorsRequestBody
   */
  comments?: string;
  /**
   *
   * @type {boolean}
   * @memberof PostGeneratorsRequestBody
   */
  dry_run?: boolean;
  /**
   *
   * @type {object}
   * @memberof PostGeneratorsRequestBody
   */
  generator_template: object;
  /**
   *
   * @type {any}
   * @memberof PostGeneratorsRequestBody
   */
  label?: any;
  /**
   *
   * @type {string}
   * @memberof PostGeneratorsRequestBody
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof PostGeneratorsRequestBody
   */
  run_async?: boolean;
}
/**
 *
 * @export
 * @interface PostLFRequestBody
 */
export interface PostLFRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostLFRequestBody
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof PostLFRequestBody
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof PostLFRequestBody
   */
  generate_name?: boolean;
  /**
   *
   * @type {Graph1}
   * @memberof PostLFRequestBody
   */
  graph?: Graph1;
  /**
   *
   * @type {boolean}
   * @memberof PostLFRequestBody
   */
  is_trusted?: boolean;
  /**
   *
   * @type {any}
   * @memberof PostLFRequestBody
   */
  label?: any;
  /**
   *
   * @type {string}
   * @memberof PostLFRequestBody
   */
  lf_code?: string;
  /**
   *
   * @type {object}
   * @memberof PostLFRequestBody
   */
  multipolar_template?: object;
  /**
   *
   * @type {string}
   * @memberof PostLFRequestBody
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PostLFRequestBody
   */
  parent_lf_uid?: number;
  /**
   *
   * @type {LFState}
   * @memberof PostLFRequestBody
   */
  state?: LFState;
  /**
   *
   * @type {SuggestionMetadata}
   * @memberof PostLFRequestBody
   */
  suggestion_metadata?: SuggestionMetadata;
  /**
   *
   * @type {Array<object>}
   * @memberof PostLFRequestBody
   */
  templates?: Array<object>;
}

/**
 *
 * @export
 * @interface PreviewMessage
 */
export interface PreviewMessage {
  /**
   *
   * @type {MessageLevel}
   * @memberof PreviewMessage
   */
  level: MessageLevel;
  /**
   *
   * @type {string}
   * @memberof PreviewMessage
   */
  message: string;
  /**
   *
   * @type {MessageName}
   * @memberof PreviewMessage
   */
  name: MessageName;
}

/**
 *
 * @export
 * @interface PromptDataOutputResponse
 */
export interface PromptDataOutputResponse {
  /**
   *
   * @type {Array<PromptOutputData>}
   * @memberof PromptDataOutputResponse
   */
  data: Array<PromptOutputData>;
}
/**
 * Params used by get_predictor to retrieve a BaseLLMPredictor  Paramaters: ----------- model_type: str     The type of FM setup to use, e.g. setfit, sdnet, text2text, etc. model_name: str     The unique name of the FM to use, e.g. bigscience/T0pp prompt_text: str     The unformatted prompt to the FM primary_text_field: str     The name of the field to format the prompt with labels_to_targets: Dict[str, str]     A mapping of labels to targets, e.g. {\"SPAM\": \"0\", \"HAM\": \"1\"} output_code: str     Code used to map the FM output to a label, e.g. \"def map_output(output): return \'SPAM\' if \"win\" in output else \'HAM\'\" max_input_tokens: int     After this many tokens, the input will be truncated. use_rag: bool     Whether to use RAG for document chunking. Only supported for text2textqa for now. fuzzy_string_match_threshold: int     The threshold for fuzzy string matching between 0(no match) and 100(exact match). Only supported for pdf_text2text_extractor for now.
 * @export
 * @interface PromptInputModel
 */
export interface PromptInputModel {
  /**
   *
   * @type {number}
   * @memberof PromptInputModel
   */
  fuzzy_string_match_threshold?: number;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof PromptInputModel
   */
  labels_to_targets?: { [key: string]: string };
  /**
   *
   * @type {object}
   * @memberof PromptInputModel
   */
  model_hyperparameters?: object;
  /**
   *
   * @type {string}
   * @memberof PromptInputModel
   */
  model_name: string;
  /**
   *
   * @type {LLMType}
   * @memberof PromptInputModel
   */
  model_type: LLMType;
  /**
   *
   * @type {string}
   * @memberof PromptInputModel
   */
  output_code?: string;
  /**
   *
   * @type {string}
   * @memberof PromptInputModel
   */
  primary_text_field?: string;
  /**
   *
   * @type {string}
   * @memberof PromptInputModel
   */
  prompt_text: string;
  /**
   *
   * @type {boolean}
   * @memberof PromptInputModel
   */
  use_rag?: boolean;
}

/**
 *
 * @export
 * @interface PromptMetricsResponse
 */
export interface PromptMetricsResponse {
  /**
   *
   * @type {Array<MetricsBucket>}
   * @memberof PromptMetricsResponse
   */
  thresholded_metrics?: Array<MetricsBucket>;
}
/**
 *
 * @export
 * @interface PromptOutputData
 */
export interface PromptOutputData {
  /**
   *
   * @type {number}
   * @memberof PromptOutputData
   */
  confidence: number;
  /**
   *
   * @type {string}
   * @memberof PromptOutputData
   */
  context_uid: string;
  /**
   *
   * @type {any}
   * @memberof PromptOutputData
   */
  ground_truth?: any;
  /**
   *
   * @type {any}
   * @memberof PromptOutputData
   */
  label?: any;
  /**
   *
   * @type {string}
   * @memberof PromptOutputData
   */
  output: string;
  /**
   *
   * @type {object}
   * @memberof PromptOutputData
   */
  snippet_data?: object;
  /**
   *
   * @type {string}
   * @memberof PromptOutputData
   */
  snippet_type: string;
  /**
   *
   * @type {string}
   * @memberof PromptOutputData
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof PromptOutputData
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface PromptResultsInputRequest
 */
export interface PromptResultsInputRequest {
  /**
   *
   * @type {boolean}
   * @memberof PromptResultsInputRequest
   */
  compress_label_if_multilabel?: boolean;
  /**
   *
   * @type {string}
   * @memberof PromptResultsInputRequest
   */
  filter_config?: string;
  /**
   *
   * @type {PromptInputModel}
   * @memberof PromptResultsInputRequest
   */
  prompt_input: PromptInputModel;
  /**
   *
   * @type {number}
   * @memberof PromptResultsInputRequest
   */
  threshold?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof PromptResultsInputRequest
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface RQJobId
 */
export interface RQJobId {
  /**
   *
   * @type {string}
   * @memberof RQJobId
   */
  uid: string;
}
/**
 *
 * @export
 * @interface RQMetaJobId
 */
export interface RQMetaJobId {
  /**
   *
   * @type {string}
   * @memberof RQMetaJobId
   */
  uid: string;
}
/**
 *
 * @export
 * @interface ResampleRequestBody
 */
export interface ResampleRequestBody {
  /**
   *
   * @type {Array<number>}
   * @memberof ResampleRequestBody
   */
  context_uids?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof ResampleRequestBody
   */
  max_labeled?: number;
  /**
   *
   * @type {number}
   * @memberof ResampleRequestBody
   */
  max_total?: number;
  /**
   *
   * @type {number}
   * @memberof ResampleRequestBody
   */
  min_per_class?: number;
  /**
   *
   * @type {boolean}
   * @memberof ResampleRequestBody
   */
  resample_size_check?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ResampleRequestBody
   */
  sample_by_docs?: boolean;
  /**
   *
   * @type {number}
   * @memberof ResampleRequestBody
   */
  seed?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ResampleRequestBody
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface ResponseApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost
 */
export interface ResponseApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost {}
/**
 *
 * @export
 * @interface Results
 */
export interface Results {}
/**
 *
 * @export
 * @interface RunPromptInferenceRequest
 */
export interface RunPromptInferenceRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof RunPromptInferenceRequest
   */
  exclude_x_uids?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof RunPromptInferenceRequest
   */
  num_examples?: number;
  /**
   *
   * @type {PromptInputModel}
   * @memberof RunPromptInferenceRequest
   */
  prompt_input: PromptInputModel;
}
/**
 *
 * @export
 * @interface RunPromptOutputResponse
 */
export interface RunPromptOutputResponse {
  /**
   *
   * @type {string}
   * @memberof RunPromptOutputResponse
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface SearchRequestBody
 */
export interface SearchRequestBody {
  /**
   *
   * @type {boolean}
   * @memberof SearchRequestBody
   */
  ascending?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchRequestBody
   */
  fetch_span_text?: boolean;
  /**
   *
   * @type {string}
   * @memberof SearchRequestBody
   */
  filter_config?: string;
  /**
   *
   * @type {Graph}
   * @memberof SearchRequestBody
   */
  graph?: Graph;
  /**
   *
   * @type {boolean}
   * @memberof SearchRequestBody
   */
  is_default_search?: boolean;
  /**
   *
   * @type {any}
   * @memberof SearchRequestBody
   */
  label?: any;
  /**
   *
   * @type {number}
   * @memberof SearchRequestBody
   */
  lf_uid?: number;
  /**
   *
   * @type {number}
   * @memberof SearchRequestBody
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof SearchRequestBody
   */
  model_uid?: number;
  /**
   *
   * @type {string}
   * @memberof SearchRequestBody
   */
  sort?: string;
  /**
   *
   * @type {string}
   * @memberof SearchRequestBody
   */
  suggest_field?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof SearchRequestBody
   */
  template_configs: Array<object>;
  /**
   *
   * @type {number}
   * @memberof SearchRequestBody
   */
  training_set_uid?: number;
}
/**
 *
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
  /**
   *
   * @type {SnippetType}
   * @memberof SearchResponse
   */
  snippet_type: SnippetType;
  /**
   *
   * @type {object}
   * @memberof SearchResponse
   */
  snippets: object;
  /**
   *
   * @type {number}
   * @memberof SearchResponse
   */
  total_matches?: number;
}

/**
 *
 * @export
 * @interface SimilarityPoint
 */
export interface SimilarityPoint {
  /**
   *
   * @type {number}
   * @memberof SimilarityPoint
   */
  distance: number;
  /**
   *
   * @type {string}
   * @memberof SimilarityPoint
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface SingleClusterResponse
 */
export interface SingleClusterResponse {
  /**
   *
   * @type {Cluster}
   * @memberof SingleClusterResponse
   */
  cluster: Cluster;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SnippetType = {
  Snippet: 'snippet',
  ContextualSnippet: 'contextual_snippet',
  GalleryTile: 'gallery_tile',
} as const;

export type SnippetType = (typeof SnippetType)[keyof typeof SnippetType];

/**
 *
 * @export
 * @interface StartTraceMalloc
 */
export interface StartTraceMalloc {
  /**
   *
   * @type {number}
   * @memberof StartTraceMalloc
   */
  nframe?: number;
}
/**
 *
 * @export
 * @interface StudioApplyJobResponse
 */
export interface StudioApplyJobResponse {
  /**
   *
   * @type {boolean}
   * @memberof StudioApplyJobResponse
   */
  complete: boolean;
  /**
   *
   * @type {object}
   * @memberof StudioApplyJobResponse
   */
  detail: object;
  /**
   *
   * @type {boolean}
   * @memberof StudioApplyJobResponse
   */
  failed: boolean;
  /**
   *
   * @type {string}
   * @memberof StudioApplyJobResponse
   */
  message: string;
  /**
   *
   * @type {number}
   * @memberof StudioApplyJobResponse
   */
  percent: number;
}
/**
 *
 * @export
 * @interface StudioBackendResourcesActorsStudioRayJobsDataResponse
 */
export interface StudioBackendResourcesActorsStudioRayJobsDataResponse {
  /**
   *
   * @type {object}
   * @memberof StudioBackendResourcesActorsStudioRayJobsDataResponse
   */
  data?: object;
}
/**
 *
 * @export
 * @interface StudioBackendResourcesActorsStudioRayWorkerMemoryResponse
 */
export interface StudioBackendResourcesActorsStudioRayWorkerMemoryResponse {
  /**
   *
   * @type {number}
   * @memberof StudioBackendResourcesActorsStudioRayWorkerMemoryResponse
   */
  worker_average_rss_mb: number;
}
/**
 *
 * @export
 * @interface StudioBackendResourcesApplyStudioRayJobsDataResponse
 */
export interface StudioBackendResourcesApplyStudioRayJobsDataResponse {
  /**
   *
   * @type {object}
   * @memberof StudioBackendResourcesApplyStudioRayJobsDataResponse
   */
  data?: object;
}
/**
 *
 * @export
 * @interface StudioBackendResourcesApplyStudioRayWorkerMemoryResponse
 */
export interface StudioBackendResourcesApplyStudioRayWorkerMemoryResponse {
  /**
   *
   * @type {number}
   * @memberof StudioBackendResourcesApplyStudioRayWorkerMemoryResponse
   */
  worker_average_rss_mb: number;
}
/**
 *
 * @export
 * @interface SuggestedLF
 */
export interface SuggestedLF {
  /**
   *
   * @type {object}
   * @memberof SuggestedLF
   */
  config: object;
  /**
   *
   * @type {number}
   * @memberof SuggestedLF
   */
  lf_uid: number;
  /**
   *
   * @type {number}
   * @memberof SuggestedLF
   */
  node_uid: number;
  /**
   *
   * @type {LFState}
   * @memberof SuggestedLF
   */
  state: LFState;
  /**
   *
   * @type {object}
   * @memberof SuggestedLF
   */
  suggestion_metadata: object;
}

/**
 *
 * @export
 * @interface SuggestionMetadata
 */
export interface SuggestionMetadata {
  /**
   *
   * @type {string}
   * @memberof SuggestionMetadata
   */
  algorithm?: string;
  /**
   *
   * @type {string}
   * @memberof SuggestionMetadata
   */
  job_id?: string;
  /**
   *
   * @type {string}
   * @memberof SuggestionMetadata
   */
  origin?: string;
  /**
   *
   * @type {number}
   * @memberof SuggestionMetadata
   */
  version?: number;
}
/**
 *
 * @export
 * @interface SupportedOnboardingModels
 */
export interface SupportedOnboardingModels {
  /**
   *
   * @type {Array<OnboardingModelCard>}
   * @memberof SupportedOnboardingModels
   */
  local_base_fms?: Array<OnboardingModelCard>;
  /**
   *
   * @type {Array<OnboardingModelCard>}
   * @memberof SupportedOnboardingModels
   */
  local_smart_start_models?: Array<OnboardingModelCard>;
  /**
   *
   * @type {Array<OnboardingModelCard>}
   * @memberof SupportedOnboardingModels
   */
  remote_base_fms?: Array<OnboardingModelCard>;
  /**
   *
   * @type {Array<OnboardingModelCard>}
   * @memberof SupportedOnboardingModels
   */
  remote_smart_start_models?: Array<OnboardingModelCard>;
}
/**
 *
 * @export
 * @interface TempClusterRequestBody
 */
export interface TempClusterRequestBody {
  /**
   *
   * @type {boolean}
   * @memberof TempClusterRequestBody
   */
  compute_metrics?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof TempClusterRequestBody
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface TempClusterResponse
 */
export interface TempClusterResponse {
  /**
   *
   * @type {Cluster}
   * @memberof TempClusterResponse
   */
  cluster?: Cluster;
  /**
   *
   * @type {boolean}
   * @memberof TempClusterResponse
   */
  is_processing_embeddings?: boolean;
}
/**
 *
 * @export
 * @interface TextToCodeRequest
 */
export interface TextToCodeRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof TextToCodeRequest
   */
  code_responses: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof TextToCodeRequest
   */
  prompts: Array<string>;
}
/**
 *
 * @export
 * @interface TextToCodeResponse
 */
export interface TextToCodeResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof TextToCodeResponse
   */
  code_responses: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof TextToCodeResponse
   */
  prompts: Array<string>;
  /**
   *
   * @type {string}
   * @memberof TextToCodeResponse
   */
  serialized_lf: string;
}
/**
 *
 * @export
 * @interface Timing
 */
export interface Timing {
  /**
   *
   * @type {number}
   * @memberof Timing
   */
  max_rss_mb?: number;
  /**
   *
   * @type {{ [key: string]: Timing; }}
   * @memberof Timing
   */
  sub_timings?: { [key: string]: Timing };
  /**
   *
   * @type {number}
   * @memberof Timing
   */
  total_ms?: number;
}
/**
 *
 * @export
 * @interface UpdateSuggestionStatePayload
 */
export interface UpdateSuggestionStatePayload {
  /**
   *
   * @type {string}
   * @memberof UpdateSuggestionStatePayload
   */
  name?: string;
  /**
   *
   * @type {LFState}
   * @memberof UpdateSuggestionStatePayload
   */
  state?: LFState;
}

/**
 *
 * @export
 * @interface UpdateSuggestionStateResponse
 */
export interface UpdateSuggestionStateResponse {
  /**
   *
   * @type {SuggestedLF}
   * @memberof UpdateSuggestionStateResponse
   */
  suggested_lf: SuggestedLF;
}
/**
 *
 * @export
 * @interface UserDefinedOperatorConfig
 */
export interface UserDefinedOperatorConfig {
  /**
   *
   * @type {object}
   * @memberof UserDefinedOperatorConfig
   */
  op_config: object;
  /**
   *
   * @type {string}
   * @memberof UserDefinedOperatorConfig
   */
  op_type: string;
}
/**
 *
 * @export
 * @interface UserNodeActorModel
 */
export interface UserNodeActorModel {
  /**
   *
   * @type {string}
   * @memberof UserNodeActorModel
   */
  actor_id: string;
  /**
   *
   * @type {string}
   * @memberof UserNodeActorModel
   */
  namespace: string;
  /**
   *
   * @type {number}
   * @memberof UserNodeActorModel
   */
  node_uid: number;
  /**
   *
   * @type {ActorResources}
   * @memberof UserNodeActorModel
   */
  normalized_resources?: ActorResources;
  /**
   *
   * @type {object}
   * @memberof UserNodeActorModel
   */
  ray_details?: object;
  /**
   *
   * @type {number}
   * @memberof UserNodeActorModel
   */
  user_uid: number;
}
/**
 *
 * @export
 * @interface UserNodeActorResponse
 */
export interface UserNodeActorResponse {
  /**
   *
   * @type {UserNodeActorModel}
   * @memberof UserNodeActorResponse
   */
  actor?: UserNodeActorModel;
  /**
   *
   * @type {string}
   * @memberof UserNodeActorResponse
   */
  error?: string;
  /**
   *
   * @type {number}
   * @memberof UserNodeActorResponse
   */
  ttl?: number;
}
/**
 *
 * @export
 * @interface UserNodeActorsResponse
 */
export interface UserNodeActorsResponse {
  /**
   *
   * @type {Array<UserNodeActorModel>}
   * @memberof UserNodeActorsResponse
   */
  actors: Array<UserNodeActorModel>;
  /**
   *
   * @type {string}
   * @memberof UserNodeActorsResponse
   */
  error?: string;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<ValidationErrorLocInner>}
   * @memberof ValidationError
   */
  loc: Array<ValidationErrorLocInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}
/**
 *
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {}
/**
 *
 * @export
 * @interface WarmStartConfig
 */
export interface WarmStartConfig {
  /**
   *
   * @type {string}
   * @memberof WarmStartConfig
   */
  method: string;
  /**
   *
   * @type {string}
   * @memberof WarmStartConfig
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof WarmStartConfig
   */
  provider: string;
}
/**
 *
 * @export
 * @interface WarmStartConfigCost
 */
export interface WarmStartConfigCost {
  /**
   *
   * @type {number}
   * @memberof WarmStartConfigCost
   */
  additional_cost: number;
  /**
   *
   * @type {string}
   * @memberof WarmStartConfigCost
   */
  method: string;
  /**
   *
   * @type {string}
   * @memberof WarmStartConfigCost
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof WarmStartConfigCost
   */
  provider: string;
}
/**
 *
 * @export
 * @interface WarmStartCostsRequest
 */
export interface WarmStartCostsRequest {
  /**
   *
   * @type {Array<WarmStartConfig>}
   * @memberof WarmStartCostsRequest
   */
  configs: Array<WarmStartConfig>;
  /**
   *
   * @type {Array<string>}
   * @memberof WarmStartCostsRequest
   */
  input_columns?: Array<string>;
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof WarmStartCostsRequest
   */
  openai_costs?: { [key: string]: { [key: string]: number } };
  /**
   *
   * @type {Array<string>}
   * @memberof WarmStartCostsRequest
   */
  splits?: Array<string>;
}
/**
 *
 * @export
 * @interface WarmStartCostsResults
 */
export interface WarmStartCostsResults {
  /**
   *
   * @type {string}
   * @memberof WarmStartCostsResults
   */
  error?: string;
  /**
   *
   * @type {Array<WarmStartConfigCost>}
   * @memberof WarmStartCostsResults
   */
  results?: Array<WarmStartConfigCost>;
}

/**
 * ActorsApi - axios parameter creator
 * @export
 */
export const ActorsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Usernodeactor
     * @param {number} userUid
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete:
      async (
        userUid: number,
        nodeUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'userUid' is not null or undefined
        assertParamExists(
          'deleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete',
          'userUid',
          userUid,
        );
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'deleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete',
          'nodeUid',
          nodeUid,
        );
        const localVarPath =
          `/ray-control-panel/actors/user-node-actors/{user_uid}/{node_uid}`
            .replace(`{${'user_uid'}}`, encodeURIComponent(String(userUid)))
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'DELETE',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Fetch Task Server Actor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchTaskServerActorRayControlPanelActorsTaskServerActorGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/ray-control-panel/actors/task-server-actor`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Usernodeactor
     * @param {number} userUid
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet:
      async (
        userUid: number,
        nodeUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'userUid' is not null or undefined
        assertParamExists(
          'getUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet',
          'userUid',
          userUid,
        );
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet',
          'nodeUid',
          nodeUid,
        );
        const localVarPath =
          `/ray-control-panel/actors/user-node-actors/{user_uid}/{node_uid}`
            .replace(`{${'user_uid'}}`, encodeURIComponent(String(userUid)))
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary List Usernodeactors
     * @param {boolean} [filterAlive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listUsernodeactorsRayControlPanelActorsUserNodeActorsGet: async (
      filterAlive?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/ray-control-panel/actors/user-node-actors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filterAlive !== undefined) {
        localVarQueryParameter['filter_alive'] = filterAlive;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Kick off a job to attempt to create a new usernodeactor, if possible given the available resources. If a job is already running to attempt to create an actor for this user/node combo, return that running job ID
     * @summary Spawn Usernodeactor
     * @param {number} userUid
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost:
      async (
        userUid: number,
        nodeUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'userUid' is not null or undefined
        assertParamExists(
          'spawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost',
          'userUid',
          userUid,
        );
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'spawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost',
          'nodeUid',
          nodeUid,
        );
        const localVarPath =
          `/ray-control-panel/actors/user-node-actors/{user_uid}/{node_uid}`
            .replace(`{${'user_uid'}}`, encodeURIComponent(String(userUid)))
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * ActorsApi - functional programming interface
 * @export
 */
export const ActorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ActorsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Usernodeactor
     * @param {number} userUid
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete(
      userUid: number,
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete(
          userUid,
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ActorsApi.deleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Task Server Actor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchTaskServerActorRayControlPanelActorsTaskServerActorGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActorModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchTaskServerActorRayControlPanelActorsTaskServerActorGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ActorsApi.fetchTaskServerActorRayControlPanelActorsTaskServerActorGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Usernodeactor
     * @param {number} userUid
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet(
      userUid: number,
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserNodeActorResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet(
          userUid,
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ActorsApi.getUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Usernodeactors
     * @param {boolean} [filterAlive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listUsernodeactorsRayControlPanelActorsUserNodeActorsGet(
      filterAlive?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserNodeActorsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listUsernodeactorsRayControlPanelActorsUserNodeActorsGet(
          filterAlive,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ActorsApi.listUsernodeactorsRayControlPanelActorsUserNodeActorsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Kick off a job to attempt to create a new usernodeactor, if possible given the available resources. If a job is already running to attempt to create an actor for this user/node combo, return that running job ID
     * @summary Spawn Usernodeactor
     * @param {number} userUid
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost(
      userUid: number,
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.spawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost(
          userUid,
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ActorsApi.spawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ActorsApi - factory interface
 * @export
 */
export const ActorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ActorsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Usernodeactor
     * @param {ActorsApiDeleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete(
      requestParameters: ActorsApiDeleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .deleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete(
          requestParameters.userUid,
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Task Server Actor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchTaskServerActorRayControlPanelActorsTaskServerActorGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ActorModel> {
      return localVarFp
        .fetchTaskServerActorRayControlPanelActorsTaskServerActorGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Usernodeactor
     * @param {ActorsApiGetUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet(
      requestParameters: ActorsApiGetUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserNodeActorResponse> {
      return localVarFp
        .getUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet(
          requestParameters.userUid,
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Usernodeactors
     * @param {ActorsApiListUsernodeactorsRayControlPanelActorsUserNodeActorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listUsernodeactorsRayControlPanelActorsUserNodeActorsGet(
      requestParameters: ActorsApiListUsernodeactorsRayControlPanelActorsUserNodeActorsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserNodeActorsResponse> {
      return localVarFp
        .listUsernodeactorsRayControlPanelActorsUserNodeActorsGet(
          requestParameters.filterAlive,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Kick off a job to attempt to create a new usernodeactor, if possible given the available resources. If a job is already running to attempt to create an actor for this user/node combo, return that running job ID
     * @summary Spawn Usernodeactor
     * @param {ActorsApiSpawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost(
      requestParameters: ActorsApiSpawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .spawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost(
          requestParameters.userUid,
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete operation in ActorsApi.
 * @export
 * @interface ActorsApiDeleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDeleteRequest
 */
export interface ActorsApiDeleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof ActorsApiDeleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete
   */
  readonly userUid: number;

  /**
   *
   * @type {number}
   * @memberof ActorsApiDeleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for getUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet operation in ActorsApi.
 * @export
 * @interface ActorsApiGetUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGetRequest
 */
export interface ActorsApiGetUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof ActorsApiGetUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet
   */
  readonly userUid: number;

  /**
   *
   * @type {number}
   * @memberof ActorsApiGetUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for listUsernodeactorsRayControlPanelActorsUserNodeActorsGet operation in ActorsApi.
 * @export
 * @interface ActorsApiListUsernodeactorsRayControlPanelActorsUserNodeActorsGetRequest
 */
export interface ActorsApiListUsernodeactorsRayControlPanelActorsUserNodeActorsGetRequest {
  /**
   *
   * @type {boolean}
   * @memberof ActorsApiListUsernodeactorsRayControlPanelActorsUserNodeActorsGet
   */
  readonly filterAlive?: boolean;
}

/**
 * Request parameters for spawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost operation in ActorsApi.
 * @export
 * @interface ActorsApiSpawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPostRequest
 */
export interface ActorsApiSpawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPostRequest {
  /**
   *
   * @type {number}
   * @memberof ActorsApiSpawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost
   */
  readonly userUid: number;

  /**
   *
   * @type {number}
   * @memberof ActorsApiSpawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost
   */
  readonly nodeUid: number;
}

/**
 * ActorsApi - object-oriented interface
 * @export
 * @class ActorsApi
 * @extends {BaseAPI}
 */
export class ActorsApi extends BaseAPI {
  /**
   *
   * @summary Delete Usernodeactor
   * @param {ActorsApiDeleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActorsApi
   */
  public deleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete(
    requestParameters: ActorsApiDeleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ActorsApiFp(this.configuration)
      .deleteUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidDelete(
        requestParameters.userUid,
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Task Server Actor
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActorsApi
   */
  public fetchTaskServerActorRayControlPanelActorsTaskServerActorGet(
    options?: RawAxiosRequestConfig,
  ) {
    return ActorsApiFp(this.configuration)
      .fetchTaskServerActorRayControlPanelActorsTaskServerActorGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Usernodeactor
   * @param {ActorsApiGetUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActorsApi
   */
  public getUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet(
    requestParameters: ActorsApiGetUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ActorsApiFp(this.configuration)
      .getUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidGet(
        requestParameters.userUid,
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Usernodeactors
   * @param {ActorsApiListUsernodeactorsRayControlPanelActorsUserNodeActorsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActorsApi
   */
  public listUsernodeactorsRayControlPanelActorsUserNodeActorsGet(
    requestParameters: ActorsApiListUsernodeactorsRayControlPanelActorsUserNodeActorsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return ActorsApiFp(this.configuration)
      .listUsernodeactorsRayControlPanelActorsUserNodeActorsGet(
        requestParameters.filterAlive,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Kick off a job to attempt to create a new usernodeactor, if possible given the available resources. If a job is already running to attempt to create an actor for this user/node combo, return that running job ID
   * @summary Spawn Usernodeactor
   * @param {ActorsApiSpawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActorsApi
   */
  public spawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost(
    requestParameters: ActorsApiSpawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ActorsApiFp(this.configuration)
      .spawnUsernodeactorRayControlPanelActorsUserNodeActorsUserUidNodeUidPost(
        requestParameters.userUid,
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ApplyApi - axios parameter creator
 * @export
 */
export const ApplyApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Apply an LF and return statistics.
     * @summary Apply Lf Route
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} lfUid
     * @param {ApplyLfRequestBody} [applyLfRequestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost: async (
      nodeUid: number,
      split: string,
      lfUid: number,
      applyLfRequestBody?: ApplyLfRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost',
        'split',
        split,
      );
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists(
        'applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost',
        'lfUid',
        lfUid,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/lfs/{lf_uid}/apply`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applyLfRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Apply an LF and return statistics.
     * @summary Batch Apply Lfs Route
     * @param {number} nodeUid
     * @param {string} split
     * @param {BatchApplyLFRequestBody} [batchApplyLFRequestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost: async (
      nodeUid: number,
      split: string,
      batchApplyLFRequestBody?: BatchApplyLFRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'batchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'batchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost',
        'split',
        split,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/apply`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        batchApplyLFRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cancel Studio Job
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelStudioJobStudioJobsJobIdCancelPost: async (
      jobId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists(
        'cancelStudioJobStudioJobsJobIdCancelPost',
        'jobId',
        jobId,
      );
      const localVarPath = `/studio-jobs/{job_id}/cancel`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This function is used by Studio 2.0 on every search query to fetch the precision/recall of the query (if a label is selected), as well as the label distribution of the results. We are coupling these functions for efficiency purposes.  We don\'t persist the results of the \'lf vote\' here (how this diverges from our other apply functions), as these are intermediate search queries that don\'t need the overhead of being written to the db.
     * @summary Compute Search Metrics
     * @param {number} nodeUid
     * @param {string} split
     * @param {ComputeSearchMetricsRequest} computeSearchMetricsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost: async (
      nodeUid: number,
      split: string,
      computeSearchMetricsRequest: ComputeSearchMetricsRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost',
        'split',
        split,
      );
      // verify required parameter 'computeSearchMetricsRequest' is not null or undefined
      assertParamExists(
        'computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost',
        'computeSearchMetricsRequest',
        computeSearchMetricsRequest,
      );
      const localVarPath =
        `/nodes/{node_uid}/splits/{split}/compute-search-metrics`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        computeSearchMetricsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Compute Similarity Histogram
     * @param {number} nodeUid
     * @param {string} split
     * @param {ComputeSimilarityHistogramRequest} computeSimilarityHistogramRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost:
      async (
        nodeUid: number,
        split: string,
        computeSimilarityHistogramRequest: ComputeSimilarityHistogramRequest,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost',
          'split',
          split,
        );
        // verify required parameter 'computeSimilarityHistogramRequest' is not null or undefined
        assertParamExists(
          'computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost',
          'computeSimilarityHistogramRequest',
          computeSimilarityHistogramRequest,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/compute-similarity-histogram`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          computeSimilarityHistogramRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Delete Task Server Actor
     * @param {boolean} restart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTaskServerActorRayControlPanelActorsTaskServerActorDelete: async (
      restart: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'restart' is not null or undefined
      assertParamExists(
        'deleteTaskServerActorRayControlPanelActorsTaskServerActorDelete',
        'restart',
        restart,
      );
      const localVarPath = `/ray-control-panel/actors/task-server-actor`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (restart !== undefined) {
        localVarQueryParameter['restart'] = restart;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Studio Apply Job Status
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet:
      async (
        nodeUid: number,
        split: string,
        jobId: string,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet',
          'split',
          split,
        );
        // verify required parameter 'jobId' is not null or undefined
        assertParamExists(
          'getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet',
          'jobId',
          jobId,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/studio-apply-jobs/{job_id}`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)))
            .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Studio Ray Jobs Data
     * @param {boolean} [shouldShowTaskData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudioRayJobsDataRayControlPanelJobsGet: async (
      shouldShowTaskData?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/ray-control-panel/jobs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (shouldShowTaskData !== undefined) {
        localVarQueryParameter['should_show_task_data'] = shouldShowTaskData;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Restart Task Server Actor Workers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restartTaskServerActorWorkersRayControlPanelActorsTaskServerActorRestartWorkersPatch:
      async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/ray-control-panel/actors/task-server-actor/restart-workers`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Spin Up Task Server Actor Workers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spinUpTaskServerActorWorkersRayControlPanelActorsTaskServerActorSpinUpWorkersPatch:
      async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/ray-control-panel/actors/task-server-actor/spin-up-workers`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * ApplyApi - functional programming interface
 * @export
 */
export const ApplyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ApplyApiAxiosParamCreator(configuration);
  return {
    /**
     * Apply an LF and return statistics.
     * @summary Apply Lf Route
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} lfUid
     * @param {ApplyLfRequestBody} [applyLfRequestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost(
      nodeUid: number,
      split: string,
      lfUid: number,
      applyLfRequestBody?: ApplyLfRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost(
          nodeUid,
          split,
          lfUid,
          applyLfRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplyApi.applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Apply an LF and return statistics.
     * @summary Batch Apply Lfs Route
     * @param {number} nodeUid
     * @param {string} split
     * @param {BatchApplyLFRequestBody} [batchApplyLFRequestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost(
      nodeUid: number,
      split: string,
      batchApplyLFRequestBody?: BatchApplyLFRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.batchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost(
          nodeUid,
          split,
          batchApplyLFRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplyApi.batchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Cancel Studio Job
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelStudioJobStudioJobsJobIdCancelPost(
      jobId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelStudioJobStudioJobsJobIdCancelPost(
          jobId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplyApi.cancelStudioJobStudioJobsJobIdCancelPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * This function is used by Studio 2.0 on every search query to fetch the precision/recall of the query (if a label is selected), as well as the label distribution of the results. We are coupling these functions for efficiency purposes.  We don\'t persist the results of the \'lf vote\' here (how this diverges from our other apply functions), as these are intermediate search queries that don\'t need the overhead of being written to the db.
     * @summary Compute Search Metrics
     * @param {number} nodeUid
     * @param {string} split
     * @param {ComputeSearchMetricsRequest} computeSearchMetricsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost(
      nodeUid: number,
      split: string,
      computeSearchMetricsRequest: ComputeSearchMetricsRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ComputeSearchMetricsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost(
          nodeUid,
          split,
          computeSearchMetricsRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplyApi.computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Compute Similarity Histogram
     * @param {number} nodeUid
     * @param {string} split
     * @param {ComputeSimilarityHistogramRequest} computeSimilarityHistogramRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost(
      nodeUid: number,
      split: string,
      computeSimilarityHistogramRequest: ComputeSimilarityHistogramRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ComputeSimilarityHistogramResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost(
          nodeUid,
          split,
          computeSimilarityHistogramRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplyApi.computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Task Server Actor
     * @param {boolean} restart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTaskServerActorRayControlPanelActorsTaskServerActorDelete(
      restart: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteTaskServerActorRayControlPanelActorsTaskServerActorDelete(
          restart,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplyApi.deleteTaskServerActorRayControlPanelActorsTaskServerActorDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Studio Apply Job Status
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet(
      nodeUid: number,
      split: string,
      jobId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StudioApplyJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet(
          nodeUid,
          split,
          jobId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplyApi.getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Studio Ray Jobs Data
     * @param {boolean} [shouldShowTaskData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStudioRayJobsDataRayControlPanelJobsGet(
      shouldShowTaskData?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StudioBackendResourcesApplyStudioRayJobsDataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStudioRayJobsDataRayControlPanelJobsGet(
          shouldShowTaskData,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplyApi.getStudioRayJobsDataRayControlPanelJobsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Restart Task Server Actor Workers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restartTaskServerActorWorkersRayControlPanelActorsTaskServerActorRestartWorkersPatch(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StudioBackendResourcesApplyStudioRayWorkerMemoryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.restartTaskServerActorWorkersRayControlPanelActorsTaskServerActorRestartWorkersPatch(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplyApi.restartTaskServerActorWorkersRayControlPanelActorsTaskServerActorRestartWorkersPatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Spin Up Task Server Actor Workers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spinUpTaskServerActorWorkersRayControlPanelActorsTaskServerActorSpinUpWorkersPatch(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StudioBackendResourcesApplyStudioRayWorkerMemoryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.spinUpTaskServerActorWorkersRayControlPanelActorsTaskServerActorSpinUpWorkersPatch(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplyApi.spinUpTaskServerActorWorkersRayControlPanelActorsTaskServerActorSpinUpWorkersPatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ApplyApi - factory interface
 * @export
 */
export const ApplyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ApplyApiFp(configuration);
  return {
    /**
     * Apply an LF and return statistics.
     * @summary Apply Lf Route
     * @param {ApplyApiApplyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost(
      requestParameters: ApplyApiApplyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.lfUid,
          requestParameters.applyLfRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Apply an LF and return statistics.
     * @summary Batch Apply Lfs Route
     * @param {ApplyApiBatchApplyLfsRouteNodesNodeUidSplitsSplitApplyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost(
      requestParameters: ApplyApiBatchApplyLfsRouteNodesNodeUidSplitsSplitApplyPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .batchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.batchApplyLFRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Cancel Studio Job
     * @param {ApplyApiCancelStudioJobStudioJobsJobIdCancelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelStudioJobStudioJobsJobIdCancelPost(
      requestParameters: ApplyApiCancelStudioJobStudioJobsJobIdCancelPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .cancelStudioJobStudioJobsJobIdCancelPost(
          requestParameters.jobId,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * This function is used by Studio 2.0 on every search query to fetch the precision/recall of the query (if a label is selected), as well as the label distribution of the results. We are coupling these functions for efficiency purposes.  We don\'t persist the results of the \'lf vote\' here (how this diverges from our other apply functions), as these are intermediate search queries that don\'t need the overhead of being written to the db.
     * @summary Compute Search Metrics
     * @param {ApplyApiComputeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost(
      requestParameters: ApplyApiComputeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ComputeSearchMetricsResponse> {
      return localVarFp
        .computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.computeSearchMetricsRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Compute Similarity Histogram
     * @param {ApplyApiComputeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost(
      requestParameters: ApplyApiComputeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ComputeSimilarityHistogramResponse> {
      return localVarFp
        .computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.computeSimilarityHistogramRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Task Server Actor
     * @param {ApplyApiDeleteTaskServerActorRayControlPanelActorsTaskServerActorDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTaskServerActorRayControlPanelActorsTaskServerActorDelete(
      requestParameters: ApplyApiDeleteTaskServerActorRayControlPanelActorsTaskServerActorDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteTaskServerActorRayControlPanelActorsTaskServerActorDelete(
          requestParameters.restart,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Studio Apply Job Status
     * @param {ApplyApiGetStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet(
      requestParameters: ApplyApiGetStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<StudioApplyJobResponse> {
      return localVarFp
        .getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.jobId,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Studio Ray Jobs Data
     * @param {ApplyApiGetStudioRayJobsDataRayControlPanelJobsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudioRayJobsDataRayControlPanelJobsGet(
      requestParameters: ApplyApiGetStudioRayJobsDataRayControlPanelJobsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<StudioBackendResourcesApplyStudioRayJobsDataResponse> {
      return localVarFp
        .getStudioRayJobsDataRayControlPanelJobsGet(
          requestParameters.shouldShowTaskData,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Restart Task Server Actor Workers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restartTaskServerActorWorkersRayControlPanelActorsTaskServerActorRestartWorkersPatch(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<StudioBackendResourcesApplyStudioRayWorkerMemoryResponse> {
      return localVarFp
        .restartTaskServerActorWorkersRayControlPanelActorsTaskServerActorRestartWorkersPatch(
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Spin Up Task Server Actor Workers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spinUpTaskServerActorWorkersRayControlPanelActorsTaskServerActorSpinUpWorkersPatch(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<StudioBackendResourcesApplyStudioRayWorkerMemoryResponse> {
      return localVarFp
        .spinUpTaskServerActorWorkersRayControlPanelActorsTaskServerActorSpinUpWorkersPatch(
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost operation in ApplyApi.
 * @export
 * @interface ApplyApiApplyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPostRequest
 */
export interface ApplyApiApplyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplyApiApplyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ApplyApiApplyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof ApplyApiApplyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost
   */
  readonly lfUid: number;

  /**
   *
   * @type {ApplyLfRequestBody}
   * @memberof ApplyApiApplyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost
   */
  readonly applyLfRequestBody?: ApplyLfRequestBody;
}

/**
 * Request parameters for batchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost operation in ApplyApi.
 * @export
 * @interface ApplyApiBatchApplyLfsRouteNodesNodeUidSplitsSplitApplyPostRequest
 */
export interface ApplyApiBatchApplyLfsRouteNodesNodeUidSplitsSplitApplyPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplyApiBatchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ApplyApiBatchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost
   */
  readonly split: string;

  /**
   *
   * @type {BatchApplyLFRequestBody}
   * @memberof ApplyApiBatchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost
   */
  readonly batchApplyLFRequestBody?: BatchApplyLFRequestBody;
}

/**
 * Request parameters for cancelStudioJobStudioJobsJobIdCancelPost operation in ApplyApi.
 * @export
 * @interface ApplyApiCancelStudioJobStudioJobsJobIdCancelPostRequest
 */
export interface ApplyApiCancelStudioJobStudioJobsJobIdCancelPostRequest {
  /**
   *
   * @type {string}
   * @memberof ApplyApiCancelStudioJobStudioJobsJobIdCancelPost
   */
  readonly jobId: string;
}

/**
 * Request parameters for computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost operation in ApplyApi.
 * @export
 * @interface ApplyApiComputeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPostRequest
 */
export interface ApplyApiComputeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplyApiComputeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ApplyApiComputeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost
   */
  readonly split: string;

  /**
   *
   * @type {ComputeSearchMetricsRequest}
   * @memberof ApplyApiComputeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost
   */
  readonly computeSearchMetricsRequest: ComputeSearchMetricsRequest;
}

/**
 * Request parameters for computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost operation in ApplyApi.
 * @export
 * @interface ApplyApiComputeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPostRequest
 */
export interface ApplyApiComputeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplyApiComputeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ApplyApiComputeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost
   */
  readonly split: string;

  /**
   *
   * @type {ComputeSimilarityHistogramRequest}
   * @memberof ApplyApiComputeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost
   */
  readonly computeSimilarityHistogramRequest: ComputeSimilarityHistogramRequest;
}

/**
 * Request parameters for deleteTaskServerActorRayControlPanelActorsTaskServerActorDelete operation in ApplyApi.
 * @export
 * @interface ApplyApiDeleteTaskServerActorRayControlPanelActorsTaskServerActorDeleteRequest
 */
export interface ApplyApiDeleteTaskServerActorRayControlPanelActorsTaskServerActorDeleteRequest {
  /**
   *
   * @type {boolean}
   * @memberof ApplyApiDeleteTaskServerActorRayControlPanelActorsTaskServerActorDelete
   */
  readonly restart: boolean;
}

/**
 * Request parameters for getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet operation in ApplyApi.
 * @export
 * @interface ApplyApiGetStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGetRequest
 */
export interface ApplyApiGetStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApplyApiGetStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ApplyApiGetStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof ApplyApiGetStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet
   */
  readonly jobId: string;
}

/**
 * Request parameters for getStudioRayJobsDataRayControlPanelJobsGet operation in ApplyApi.
 * @export
 * @interface ApplyApiGetStudioRayJobsDataRayControlPanelJobsGetRequest
 */
export interface ApplyApiGetStudioRayJobsDataRayControlPanelJobsGetRequest {
  /**
   *
   * @type {boolean}
   * @memberof ApplyApiGetStudioRayJobsDataRayControlPanelJobsGet
   */
  readonly shouldShowTaskData?: boolean;
}

/**
 * ApplyApi - object-oriented interface
 * @export
 * @class ApplyApi
 * @extends {BaseAPI}
 */
export class ApplyApi extends BaseAPI {
  /**
   * Apply an LF and return statistics.
   * @summary Apply Lf Route
   * @param {ApplyApiApplyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplyApi
   */
  public applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost(
    requestParameters: ApplyApiApplyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplyApiFp(this.configuration)
      .applyLfRouteNodesNodeUidSplitsSplitLfsLfUidApplyPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.lfUid,
        requestParameters.applyLfRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Apply an LF and return statistics.
   * @summary Batch Apply Lfs Route
   * @param {ApplyApiBatchApplyLfsRouteNodesNodeUidSplitsSplitApplyPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplyApi
   */
  public batchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost(
    requestParameters: ApplyApiBatchApplyLfsRouteNodesNodeUidSplitsSplitApplyPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplyApiFp(this.configuration)
      .batchApplyLfsRouteNodesNodeUidSplitsSplitApplyPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.batchApplyLFRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cancel Studio Job
   * @param {ApplyApiCancelStudioJobStudioJobsJobIdCancelPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplyApi
   */
  public cancelStudioJobStudioJobsJobIdCancelPost(
    requestParameters: ApplyApiCancelStudioJobStudioJobsJobIdCancelPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplyApiFp(this.configuration)
      .cancelStudioJobStudioJobsJobIdCancelPost(
        requestParameters.jobId,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * This function is used by Studio 2.0 on every search query to fetch the precision/recall of the query (if a label is selected), as well as the label distribution of the results. We are coupling these functions for efficiency purposes.  We don\'t persist the results of the \'lf vote\' here (how this diverges from our other apply functions), as these are intermediate search queries that don\'t need the overhead of being written to the db.
   * @summary Compute Search Metrics
   * @param {ApplyApiComputeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplyApi
   */
  public computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost(
    requestParameters: ApplyApiComputeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplyApiFp(this.configuration)
      .computeSearchMetricsNodesNodeUidSplitsSplitComputeSearchMetricsPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.computeSearchMetricsRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Compute Similarity Histogram
   * @param {ApplyApiComputeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplyApi
   */
  public computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost(
    requestParameters: ApplyApiComputeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplyApiFp(this.configuration)
      .computeSimilarityHistogramNodesNodeUidSplitsSplitComputeSimilarityHistogramPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.computeSimilarityHistogramRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Task Server Actor
   * @param {ApplyApiDeleteTaskServerActorRayControlPanelActorsTaskServerActorDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplyApi
   */
  public deleteTaskServerActorRayControlPanelActorsTaskServerActorDelete(
    requestParameters: ApplyApiDeleteTaskServerActorRayControlPanelActorsTaskServerActorDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplyApiFp(this.configuration)
      .deleteTaskServerActorRayControlPanelActorsTaskServerActorDelete(
        requestParameters.restart,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Studio Apply Job Status
   * @param {ApplyApiGetStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplyApi
   */
  public getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet(
    requestParameters: ApplyApiGetStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplyApiFp(this.configuration)
      .getStudioApplyJobStatusNodesNodeUidSplitsSplitStudioApplyJobsJobIdGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.jobId,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Studio Ray Jobs Data
   * @param {ApplyApiGetStudioRayJobsDataRayControlPanelJobsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplyApi
   */
  public getStudioRayJobsDataRayControlPanelJobsGet(
    requestParameters: ApplyApiGetStudioRayJobsDataRayControlPanelJobsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return ApplyApiFp(this.configuration)
      .getStudioRayJobsDataRayControlPanelJobsGet(
        requestParameters.shouldShowTaskData,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Restart Task Server Actor Workers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplyApi
   */
  public restartTaskServerActorWorkersRayControlPanelActorsTaskServerActorRestartWorkersPatch(
    options?: RawAxiosRequestConfig,
  ) {
    return ApplyApiFp(this.configuration)
      .restartTaskServerActorWorkersRayControlPanelActorsTaskServerActorRestartWorkersPatch(
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Spin Up Task Server Actor Workers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplyApi
   */
  public spinUpTaskServerActorWorkersRayControlPanelActorsTaskServerActorSpinUpWorkersPatch(
    options?: RawAxiosRequestConfig,
  ) {
    return ApplyApiFp(this.configuration)
      .spinUpTaskServerActorWorkersRayControlPanelActorsTaskServerActorSpinUpWorkersPatch(
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ClustersApi - axios parameter creator
 * @export
 */
export const ClustersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Cluster Lf
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {string} xUidHash
     * @param {CreateClusterLFRequest} createClusterLFRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost:
      async (
        nodeUid: number,
        split: string,
        embeddingNodeUid: number,
        xUidHash: string,
        createClusterLFRequest: CreateClusterLFRequest,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost',
          'split',
          split,
        );
        // verify required parameter 'embeddingNodeUid' is not null or undefined
        assertParamExists(
          'createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost',
          'embeddingNodeUid',
          embeddingNodeUid,
        );
        // verify required parameter 'xUidHash' is not null or undefined
        assertParamExists(
          'createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost',
          'xUidHash',
          xUidHash,
        );
        // verify required parameter 'createClusterLFRequest' is not null or undefined
        assertParamExists(
          'createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost',
          'createClusterLFRequest',
          createClusterLFRequest,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/embedding-node/{embedding_node_uid}/clusters/{x_uid_hash}/cluster-lf`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)))
            .replace(
              `{${'embedding_node_uid'}}`,
              encodeURIComponent(String(embeddingNodeUid)),
            )
            .replace(`{${'x_uid_hash'}}`, encodeURIComponent(String(xUidHash)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          createClusterLFRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Dissociates the LF with the cluster, if there is one. Note that we don\'t actually delete the LF, since there is a small probability that a user has earlier accepted / saved the LF in a package version, so deleting could be problematic
     * @summary Dissociate Cluster Lf
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {string} xUidHash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete:
      async (
        nodeUid: number,
        split: string,
        embeddingNodeUid: number,
        xUidHash: string,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete',
          'split',
          split,
        );
        // verify required parameter 'embeddingNodeUid' is not null or undefined
        assertParamExists(
          'dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete',
          'embeddingNodeUid',
          embeddingNodeUid,
        );
        // verify required parameter 'xUidHash' is not null or undefined
        assertParamExists(
          'dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete',
          'xUidHash',
          xUidHash,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/embedding-node/{embedding_node_uid}/clusters/{x_uid_hash}/cluster-lf`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)))
            .replace(
              `{${'embedding_node_uid'}}`,
              encodeURIComponent(String(embeddingNodeUid)),
            )
            .replace(`{${'x_uid_hash'}}`, encodeURIComponent(String(xUidHash)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'DELETE',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Embedding Similarity
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {EmbeddingSimilarityRequestBody} embeddingSimilarityRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost:
      async (
        nodeUid: number,
        split: string,
        embeddingNodeUid: number,
        embeddingSimilarityRequestBody: EmbeddingSimilarityRequestBody,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost',
          'split',
          split,
        );
        // verify required parameter 'embeddingNodeUid' is not null or undefined
        assertParamExists(
          'embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost',
          'embeddingNodeUid',
          embeddingNodeUid,
        );
        // verify required parameter 'embeddingSimilarityRequestBody' is not null or undefined
        assertParamExists(
          'embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost',
          'embeddingSimilarityRequestBody',
          embeddingSimilarityRequestBody,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/embedding-node/{embedding_node_uid}/embedding-similarity`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)))
            .replace(
              `{${'embedding_node_uid'}}`,
              encodeURIComponent(String(embeddingNodeUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          embeddingSimilarityRequestBody,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Available Embeddings
     * @param {number} nodeUid
     * @param {string} split
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet: async (
      nodeUid: number,
      split: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet',
        'split',
        split,
      );
      const localVarPath =
        `/nodes/{node_uid}/splits/{split}/available-embeddings`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Cluster Points
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet:
      async (
        nodeUid: number,
        split: string,
        embeddingNodeUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet',
          'split',
          split,
        );
        // verify required parameter 'embeddingNodeUid' is not null or undefined
        assertParamExists(
          'getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet',
          'embeddingNodeUid',
          embeddingNodeUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/embedding-node/{embedding_node_uid}/cluster-points`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)))
            .replace(
              `{${'embedding_node_uid'}}`,
              encodeURIComponent(String(embeddingNodeUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Clusters
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {ClusterStrategy} [clusterStrategy]
     * @param {number} [clusterStrength]
     * @param {string} [filterConfig]
     * @param {string} [lfConfig]
     * @param {number} [topK]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet:
      async (
        nodeUid: number,
        split: string,
        embeddingNodeUid: number,
        clusterStrategy?: ClusterStrategy,
        clusterStrength?: number,
        filterConfig?: string,
        lfConfig?: string,
        topK?: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet',
          'split',
          split,
        );
        // verify required parameter 'embeddingNodeUid' is not null or undefined
        assertParamExists(
          'getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet',
          'embeddingNodeUid',
          embeddingNodeUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/embedding-node/{embedding_node_uid}/clusters`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)))
            .replace(
              `{${'embedding_node_uid'}}`,
              encodeURIComponent(String(embeddingNodeUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (clusterStrategy !== undefined) {
          localVarQueryParameter['cluster_strategy'] = clusterStrategy;
        }

        if (clusterStrength !== undefined) {
          localVarQueryParameter['cluster_strength'] = clusterStrength;
        }

        if (filterConfig !== undefined) {
          localVarQueryParameter['filter_config'] = filterConfig;
        }

        if (lfConfig !== undefined) {
          localVarQueryParameter['lf_config'] = lfConfig;
        }

        if (topK !== undefined) {
          localVarQueryParameter['top_k'] = topK;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Hierarchical Cluster Dist
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet:
      async (
        nodeUid: number,
        split: string,
        embeddingNodeUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet',
          'split',
          split,
        );
        // verify required parameter 'embeddingNodeUid' is not null or undefined
        assertParamExists(
          'hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet',
          'embeddingNodeUid',
          embeddingNodeUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/embedding-node/{embedding_node_uid}/hierarchical-cluster-dist`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)))
            .replace(
              `{${'embedding_node_uid'}}`,
              encodeURIComponent(String(embeddingNodeUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Temp Clusters
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {TempClusterRequestBody} tempClusterRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost:
      async (
        nodeUid: number,
        split: string,
        embeddingNodeUid: number,
        tempClusterRequestBody: TempClusterRequestBody,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost',
          'split',
          split,
        );
        // verify required parameter 'embeddingNodeUid' is not null or undefined
        assertParamExists(
          'tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost',
          'embeddingNodeUid',
          embeddingNodeUid,
        );
        // verify required parameter 'tempClusterRequestBody' is not null or undefined
        assertParamExists(
          'tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost',
          'tempClusterRequestBody',
          tempClusterRequestBody,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/embedding-node/{embedding_node_uid}/temp-clusters`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)))
            .replace(
              `{${'embedding_node_uid'}}`,
              encodeURIComponent(String(embeddingNodeUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          tempClusterRequestBody,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * ClustersApi - functional programming interface
 * @export
 */
export const ClustersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClustersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Cluster Lf
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {string} xUidHash
     * @param {CreateClusterLFRequest} createClusterLFRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost(
      nodeUid: number,
      split: string,
      embeddingNodeUid: number,
      xUidHash: string,
      createClusterLFRequest: CreateClusterLFRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SingleClusterResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost(
          nodeUid,
          split,
          embeddingNodeUid,
          xUidHash,
          createClusterLFRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ClustersApi.createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Dissociates the LF with the cluster, if there is one. Note that we don\'t actually delete the LF, since there is a small probability that a user has earlier accepted / saved the LF in a package version, so deleting could be problematic
     * @summary Dissociate Cluster Lf
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {string} xUidHash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete(
      nodeUid: number,
      split: string,
      embeddingNodeUid: number,
      xUidHash: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SingleClusterResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete(
          nodeUid,
          split,
          embeddingNodeUid,
          xUidHash,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ClustersApi.dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Embedding Similarity
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {EmbeddingSimilarityRequestBody} embeddingSimilarityRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost(
      nodeUid: number,
      split: string,
      embeddingNodeUid: number,
      embeddingSimilarityRequestBody: EmbeddingSimilarityRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EmbeddingSimilarityResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost(
          nodeUid,
          split,
          embeddingNodeUid,
          embeddingSimilarityRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ClustersApi.embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Available Embeddings
     * @param {number} nodeUid
     * @param {string} split
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet(
      nodeUid: number,
      split: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AvailableEmbeddings>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet(
          nodeUid,
          split,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ClustersApi.getAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Cluster Points
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet(
      nodeUid: number,
      split: string,
      embeddingNodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ClusterPoint>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet(
          nodeUid,
          split,
          embeddingNodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ClustersApi.getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Clusters
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {ClusterStrategy} [clusterStrategy]
     * @param {number} [clusterStrength]
     * @param {string} [filterConfig]
     * @param {string} [lfConfig]
     * @param {number} [topK]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet(
      nodeUid: number,
      split: string,
      embeddingNodeUid: number,
      clusterStrategy?: ClusterStrategy,
      clusterStrength?: number,
      filterConfig?: string,
      lfConfig?: string,
      topK?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ClusterResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet(
          nodeUid,
          split,
          embeddingNodeUid,
          clusterStrategy,
          clusterStrength,
          filterConfig,
          lfConfig,
          topK,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ClustersApi.getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Hierarchical Cluster Dist
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet(
      nodeUid: number,
      split: string,
      embeddingNodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HierarchicalClusterDistResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet(
          nodeUid,
          split,
          embeddingNodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ClustersApi.hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Temp Clusters
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} embeddingNodeUid
     * @param {TempClusterRequestBody} tempClusterRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost(
      nodeUid: number,
      split: string,
      embeddingNodeUid: number,
      tempClusterRequestBody: TempClusterRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TempClusterResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost(
          nodeUid,
          split,
          embeddingNodeUid,
          tempClusterRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ClustersApi.tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ClustersApi - factory interface
 * @export
 */
export const ClustersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ClustersApiFp(configuration);
  return {
    /**
     *
     * @summary Create Cluster Lf
     * @param {ClustersApiCreateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost(
      requestParameters: ClustersApiCreateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SingleClusterResponse> {
      return localVarFp
        .createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.embeddingNodeUid,
          requestParameters.xUidHash,
          requestParameters.createClusterLFRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Dissociates the LF with the cluster, if there is one. Note that we don\'t actually delete the LF, since there is a small probability that a user has earlier accepted / saved the LF in a package version, so deleting could be problematic
     * @summary Dissociate Cluster Lf
     * @param {ClustersApiDissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete(
      requestParameters: ClustersApiDissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SingleClusterResponse> {
      return localVarFp
        .dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.embeddingNodeUid,
          requestParameters.xUidHash,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Embedding Similarity
     * @param {ClustersApiEmbeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost(
      requestParameters: ClustersApiEmbeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<EmbeddingSimilarityResponse> {
      return localVarFp
        .embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.embeddingNodeUid,
          requestParameters.embeddingSimilarityRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Available Embeddings
     * @param {ClustersApiGetAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet(
      requestParameters: ClustersApiGetAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AvailableEmbeddings> {
      return localVarFp
        .getAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet(
          requestParameters.nodeUid,
          requestParameters.split,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Cluster Points
     * @param {ClustersApiGetClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet(
      requestParameters: ClustersApiGetClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ClusterPoint>> {
      return localVarFp
        .getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.embeddingNodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Clusters
     * @param {ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet(
      requestParameters: ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ClusterResponse> {
      return localVarFp
        .getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.embeddingNodeUid,
          requestParameters.clusterStrategy,
          requestParameters.clusterStrength,
          requestParameters.filterConfig,
          requestParameters.lfConfig,
          requestParameters.topK,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Hierarchical Cluster Dist
     * @param {ClustersApiHierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet(
      requestParameters: ClustersApiHierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HierarchicalClusterDistResponse> {
      return localVarFp
        .hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.embeddingNodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Temp Clusters
     * @param {ClustersApiTempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost(
      requestParameters: ClustersApiTempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TempClusterResponse> {
      return localVarFp
        .tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.embeddingNodeUid,
          requestParameters.tempClusterRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost operation in ClustersApi.
 * @export
 * @interface ClustersApiCreateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPostRequest
 */
export interface ClustersApiCreateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPostRequest {
  /**
   *
   * @type {number}
   * @memberof ClustersApiCreateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ClustersApiCreateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof ClustersApiCreateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost
   */
  readonly embeddingNodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ClustersApiCreateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost
   */
  readonly xUidHash: string;

  /**
   *
   * @type {CreateClusterLFRequest}
   * @memberof ClustersApiCreateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost
   */
  readonly createClusterLFRequest: CreateClusterLFRequest;
}

/**
 * Request parameters for dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete operation in ClustersApi.
 * @export
 * @interface ClustersApiDissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDeleteRequest
 */
export interface ClustersApiDissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof ClustersApiDissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ClustersApiDissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof ClustersApiDissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete
   */
  readonly embeddingNodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ClustersApiDissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete
   */
  readonly xUidHash: string;
}

/**
 * Request parameters for embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost operation in ClustersApi.
 * @export
 * @interface ClustersApiEmbeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPostRequest
 */
export interface ClustersApiEmbeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPostRequest {
  /**
   *
   * @type {number}
   * @memberof ClustersApiEmbeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ClustersApiEmbeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof ClustersApiEmbeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost
   */
  readonly embeddingNodeUid: number;

  /**
   *
   * @type {EmbeddingSimilarityRequestBody}
   * @memberof ClustersApiEmbeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost
   */
  readonly embeddingSimilarityRequestBody: EmbeddingSimilarityRequestBody;
}

/**
 * Request parameters for getAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet operation in ClustersApi.
 * @export
 * @interface ClustersApiGetAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGetRequest
 */
export interface ClustersApiGetAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ClustersApiGetAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ClustersApiGetAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet
   */
  readonly split: string;
}

/**
 * Request parameters for getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet operation in ClustersApi.
 * @export
 * @interface ClustersApiGetClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGetRequest
 */
export interface ClustersApiGetClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ClustersApiGetClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ClustersApiGetClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof ClustersApiGetClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet
   */
  readonly embeddingNodeUid: number;
}

/**
 * Request parameters for getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet operation in ClustersApi.
 * @export
 * @interface ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGetRequest
 */
export interface ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGetRequest {
  /**
   *
   * @type {number}
   * @memberof ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet
   */
  readonly embeddingNodeUid: number;

  /**
   *
   * @type {ClusterStrategy}
   * @memberof ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet
   */
  readonly clusterStrategy?: ClusterStrategy;

  /**
   *
   * @type {number}
   * @memberof ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet
   */
  readonly clusterStrength?: number;

  /**
   *
   * @type {string}
   * @memberof ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet
   */
  readonly filterConfig?: string;

  /**
   *
   * @type {string}
   * @memberof ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet
   */
  readonly lfConfig?: string;

  /**
   *
   * @type {number}
   * @memberof ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet
   */
  readonly topK?: number;
}

/**
 * Request parameters for hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet operation in ClustersApi.
 * @export
 * @interface ClustersApiHierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGetRequest
 */
export interface ClustersApiHierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGetRequest {
  /**
   *
   * @type {number}
   * @memberof ClustersApiHierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ClustersApiHierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof ClustersApiHierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet
   */
  readonly embeddingNodeUid: number;
}

/**
 * Request parameters for tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost operation in ClustersApi.
 * @export
 * @interface ClustersApiTempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPostRequest
 */
export interface ClustersApiTempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPostRequest {
  /**
   *
   * @type {number}
   * @memberof ClustersApiTempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ClustersApiTempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof ClustersApiTempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost
   */
  readonly embeddingNodeUid: number;

  /**
   *
   * @type {TempClusterRequestBody}
   * @memberof ClustersApiTempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost
   */
  readonly tempClusterRequestBody: TempClusterRequestBody;
}

/**
 * ClustersApi - object-oriented interface
 * @export
 * @class ClustersApi
 * @extends {BaseAPI}
 */
export class ClustersApi extends BaseAPI {
  /**
   *
   * @summary Create Cluster Lf
   * @param {ClustersApiCreateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClustersApi
   */
  public createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost(
    requestParameters: ClustersApiCreateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ClustersApiFp(this.configuration)
      .createClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.embeddingNodeUid,
        requestParameters.xUidHash,
        requestParameters.createClusterLFRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Dissociates the LF with the cluster, if there is one. Note that we don\'t actually delete the LF, since there is a small probability that a user has earlier accepted / saved the LF in a package version, so deleting could be problematic
   * @summary Dissociate Cluster Lf
   * @param {ClustersApiDissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClustersApi
   */
  public dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete(
    requestParameters: ClustersApiDissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ClustersApiFp(this.configuration)
      .dissociateClusterLfNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersXUidHashClusterLfDelete(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.embeddingNodeUid,
        requestParameters.xUidHash,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Embedding Similarity
   * @param {ClustersApiEmbeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClustersApi
   */
  public embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost(
    requestParameters: ClustersApiEmbeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ClustersApiFp(this.configuration)
      .embeddingSimilarityNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidEmbeddingSimilarityPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.embeddingNodeUid,
        requestParameters.embeddingSimilarityRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Available Embeddings
   * @param {ClustersApiGetAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClustersApi
   */
  public getAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet(
    requestParameters: ClustersApiGetAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ClustersApiFp(this.configuration)
      .getAvailableEmbeddingsNodesNodeUidSplitsSplitAvailableEmbeddingsGet(
        requestParameters.nodeUid,
        requestParameters.split,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Cluster Points
   * @param {ClustersApiGetClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClustersApi
   */
  public getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet(
    requestParameters: ClustersApiGetClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ClustersApiFp(this.configuration)
      .getClusterPointsNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClusterPointsGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.embeddingNodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Clusters
   * @param {ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClustersApi
   */
  public getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet(
    requestParameters: ClustersApiGetClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ClustersApiFp(this.configuration)
      .getClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidClustersGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.embeddingNodeUid,
        requestParameters.clusterStrategy,
        requestParameters.clusterStrength,
        requestParameters.filterConfig,
        requestParameters.lfConfig,
        requestParameters.topK,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Hierarchical Cluster Dist
   * @param {ClustersApiHierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClustersApi
   */
  public hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet(
    requestParameters: ClustersApiHierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ClustersApiFp(this.configuration)
      .hierarchicalClusterDistNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidHierarchicalClusterDistGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.embeddingNodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Temp Clusters
   * @param {ClustersApiTempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClustersApi
   */
  public tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost(
    requestParameters: ClustersApiTempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ClustersApiFp(this.configuration)
      .tempClustersNodesNodeUidSplitsSplitEmbeddingNodeEmbeddingNodeUidTempClustersPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.embeddingNodeUid,
        requestParameters.tempClusterRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CodeLfApi - axios parameter creator
 * @export
 */
export const CodeLfApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Text To Code
     * @param {number} nodeUid
     * @param {string} split
     * @param {TextToCodeRequest} textToCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    textToCodeNodesNodeUidSplitsSplitTextToCodePost: async (
      nodeUid: number,
      split: string,
      textToCodeRequest: TextToCodeRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'textToCodeNodesNodeUidSplitsSplitTextToCodePost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'textToCodeNodesNodeUidSplitsSplitTextToCodePost',
        'split',
        split,
      );
      // verify required parameter 'textToCodeRequest' is not null or undefined
      assertParamExists(
        'textToCodeNodesNodeUidSplitsSplitTextToCodePost',
        'textToCodeRequest',
        textToCodeRequest,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/text-to-code`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        textToCodeRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CodeLfApi - functional programming interface
 * @export
 */
export const CodeLfApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CodeLfApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Text To Code
     * @param {number} nodeUid
     * @param {string} split
     * @param {TextToCodeRequest} textToCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async textToCodeNodesNodeUidSplitsSplitTextToCodePost(
      nodeUid: number,
      split: string,
      textToCodeRequest: TextToCodeRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TextToCodeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.textToCodeNodesNodeUidSplitsSplitTextToCodePost(
          nodeUid,
          split,
          textToCodeRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CodeLfApi.textToCodeNodesNodeUidSplitsSplitTextToCodePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CodeLfApi - factory interface
 * @export
 */
export const CodeLfApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CodeLfApiFp(configuration);
  return {
    /**
     *
     * @summary Text To Code
     * @param {CodeLfApiTextToCodeNodesNodeUidSplitsSplitTextToCodePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    textToCodeNodesNodeUidSplitsSplitTextToCodePost(
      requestParameters: CodeLfApiTextToCodeNodesNodeUidSplitsSplitTextToCodePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TextToCodeResponse> {
      return localVarFp
        .textToCodeNodesNodeUidSplitsSplitTextToCodePost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.textToCodeRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for textToCodeNodesNodeUidSplitsSplitTextToCodePost operation in CodeLfApi.
 * @export
 * @interface CodeLfApiTextToCodeNodesNodeUidSplitsSplitTextToCodePostRequest
 */
export interface CodeLfApiTextToCodeNodesNodeUidSplitsSplitTextToCodePostRequest {
  /**
   *
   * @type {number}
   * @memberof CodeLfApiTextToCodeNodesNodeUidSplitsSplitTextToCodePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof CodeLfApiTextToCodeNodesNodeUidSplitsSplitTextToCodePost
   */
  readonly split: string;

  /**
   *
   * @type {TextToCodeRequest}
   * @memberof CodeLfApiTextToCodeNodesNodeUidSplitsSplitTextToCodePost
   */
  readonly textToCodeRequest: TextToCodeRequest;
}

/**
 * CodeLfApi - object-oriented interface
 * @export
 * @class CodeLfApi
 * @extends {BaseAPI}
 */
export class CodeLfApi extends BaseAPI {
  /**
   *
   * @summary Text To Code
   * @param {CodeLfApiTextToCodeNodesNodeUidSplitsSplitTextToCodePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CodeLfApi
   */
  public textToCodeNodesNodeUidSplitsSplitTextToCodePost(
    requestParameters: CodeLfApiTextToCodeNodesNodeUidSplitsSplitTextToCodePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CodeLfApiFp(this.configuration)
      .textToCodeNodesNodeUidSplitsSplitTextToCodePost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.textToCodeRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ContextDatasetApi - axios parameter creator
 * @export
 */
export const ContextDatasetApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Context Dataset
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} [filterConfig]
     * @param {number} [modelUid]
     * @param {string} [sort]
     * @param {boolean} [ascending]
     * @param {number} [seed]
     * @param {number} [limit]
     * @param {boolean} [dynamicPaging]
     * @param {number} [dynamicPagingSizeKB]
     * @param {number} [offset]
     * @param {Array<string>} [includeTagTypeUid]
     * @param {Array<string>} [excludeTagTypeUid]
     * @param {string} [lfConfig]
     * @param {string} [findSpanUid]
     * @param {number} [batchUid]
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContextDatasetNodesNodeUidSplitsSplitContextsGet: async (
      nodeUid: number,
      split: string,
      filterConfig?: string,
      modelUid?: number,
      sort?: string,
      ascending?: boolean,
      seed?: number,
      limit?: number,
      dynamicPaging?: boolean,
      dynamicPagingSizeKB?: number,
      offset?: number,
      includeTagTypeUid?: Array<string>,
      excludeTagTypeUid?: Array<string>,
      lfConfig?: string,
      findSpanUid?: string,
      batchUid?: number,
      requestBody?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getContextDatasetNodesNodeUidSplitsSplitContextsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getContextDatasetNodesNodeUidSplitsSplitContextsGet',
        'split',
        split,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/contexts`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filterConfig !== undefined) {
        localVarQueryParameter['filter_config'] = filterConfig;
      }

      if (modelUid !== undefined) {
        localVarQueryParameter['model_uid'] = modelUid;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (ascending !== undefined) {
        localVarQueryParameter['ascending'] = ascending;
      }

      if (seed !== undefined) {
        localVarQueryParameter['seed'] = seed;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (dynamicPaging !== undefined) {
        localVarQueryParameter['dynamic_paging'] = dynamicPaging;
      }

      if (dynamicPagingSizeKB !== undefined) {
        localVarQueryParameter['dynamic_paging_size_KB'] = dynamicPagingSizeKB;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (includeTagTypeUid) {
        localVarQueryParameter['include_tag_type_uid'] = includeTagTypeUid;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (lfConfig !== undefined) {
        localVarQueryParameter['lf_config'] = lfConfig;
      }

      if (findSpanUid !== undefined) {
        localVarQueryParameter['find_span_uid'] = findSpanUid;
      }

      if (batchUid !== undefined) {
        localVarQueryParameter['batch_uid'] = batchUid;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContextDatasetApi - functional programming interface
 * @export
 */
export const ContextDatasetApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ContextDatasetApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Context Dataset
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} [filterConfig]
     * @param {number} [modelUid]
     * @param {string} [sort]
     * @param {boolean} [ascending]
     * @param {number} [seed]
     * @param {number} [limit]
     * @param {boolean} [dynamicPaging]
     * @param {number} [dynamicPagingSizeKB]
     * @param {number} [offset]
     * @param {Array<string>} [includeTagTypeUid]
     * @param {Array<string>} [excludeTagTypeUid]
     * @param {string} [lfConfig]
     * @param {string} [findSpanUid]
     * @param {number} [batchUid]
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContextDatasetNodesNodeUidSplitsSplitContextsGet(
      nodeUid: number,
      split: string,
      filterConfig?: string,
      modelUid?: number,
      sort?: string,
      ascending?: boolean,
      seed?: number,
      limit?: number,
      dynamicPaging?: boolean,
      dynamicPagingSizeKB?: number,
      offset?: number,
      includeTagTypeUid?: Array<string>,
      excludeTagTypeUid?: Array<string>,
      lfConfig?: string,
      findSpanUid?: string,
      batchUid?: number,
      requestBody?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ContextOutputData>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getContextDatasetNodesNodeUidSplitsSplitContextsGet(
          nodeUid,
          split,
          filterConfig,
          modelUid,
          sort,
          ascending,
          seed,
          limit,
          dynamicPaging,
          dynamicPagingSizeKB,
          offset,
          includeTagTypeUid,
          excludeTagTypeUid,
          lfConfig,
          findSpanUid,
          batchUid,
          requestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ContextDatasetApi.getContextDatasetNodesNodeUidSplitsSplitContextsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ContextDatasetApi - factory interface
 * @export
 */
export const ContextDatasetApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContextDatasetApiFp(configuration);
  return {
    /**
     *
     * @summary Get Context Dataset
     * @param {ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContextDatasetNodesNodeUidSplitsSplitContextsGet(
      requestParameters: ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContextOutputData> {
      return localVarFp
        .getContextDatasetNodesNodeUidSplitsSplitContextsGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.filterConfig,
          requestParameters.modelUid,
          requestParameters.sort,
          requestParameters.ascending,
          requestParameters.seed,
          requestParameters.limit,
          requestParameters.dynamicPaging,
          requestParameters.dynamicPagingSizeKB,
          requestParameters.offset,
          requestParameters.includeTagTypeUid,
          requestParameters.excludeTagTypeUid,
          requestParameters.lfConfig,
          requestParameters.findSpanUid,
          requestParameters.batchUid,
          requestParameters.requestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getContextDatasetNodesNodeUidSplitsSplitContextsGet operation in ContextDatasetApi.
 * @export
 * @interface ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGetRequest
 */
export interface ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly filterConfig?: string;

  /**
   *
   * @type {number}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly modelUid?: number;

  /**
   *
   * @type {string}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly sort?: string;

  /**
   *
   * @type {boolean}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly ascending?: boolean;

  /**
   *
   * @type {number}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly seed?: number;

  /**
   *
   * @type {number}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly limit?: number;

  /**
   *
   * @type {boolean}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly dynamicPaging?: boolean;

  /**
   *
   * @type {number}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly dynamicPagingSizeKB?: number;

  /**
   *
   * @type {number}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly offset?: number;

  /**
   *
   * @type {Array<string>}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly includeTagTypeUid?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly excludeTagTypeUid?: Array<string>;

  /**
   *
   * @type {string}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly lfConfig?: string;

  /**
   *
   * @type {string}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly findSpanUid?: string;

  /**
   *
   * @type {number}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly batchUid?: number;

  /**
   *
   * @type {Array<string>}
   * @memberof ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGet
   */
  readonly requestBody?: Array<string>;
}

/**
 * ContextDatasetApi - object-oriented interface
 * @export
 * @class ContextDatasetApi
 * @extends {BaseAPI}
 */
export class ContextDatasetApi extends BaseAPI {
  /**
   *
   * @summary Get Context Dataset
   * @param {ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContextDatasetApi
   */
  public getContextDatasetNodesNodeUidSplitsSplitContextsGet(
    requestParameters: ContextDatasetApiGetContextDatasetNodesNodeUidSplitsSplitContextsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ContextDatasetApiFp(this.configuration)
      .getContextDatasetNodesNodeUidSplitsSplitContextsGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.filterConfig,
        requestParameters.modelUid,
        requestParameters.sort,
        requestParameters.ascending,
        requestParameters.seed,
        requestParameters.limit,
        requestParameters.dynamicPaging,
        requestParameters.dynamicPagingSizeKB,
        requestParameters.offset,
        requestParameters.includeTagTypeUid,
        requestParameters.excludeTagTypeUid,
        requestParameters.lfConfig,
        requestParameters.findSpanUid,
        requestParameters.batchUid,
        requestParameters.requestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CpuProfilingApi - axios parameter creator
 * @export
 */
export const CpuProfilingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearProfilingCpuProfileClearPost: async (
      cPUProfilingParams: CPUProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cPUProfilingParams' is not null or undefined
      assertParamExists(
        'clearProfilingCpuProfileClearPost',
        'cPUProfilingParams',
        cPUProfilingParams,
      );
      const localVarPath = `/cpu-profile/clear`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cPUProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the Profile backtrace from the Redis
     * @summary Profile
     * @param {string} service
     * @param {boolean} [outputAsTree]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileCpuProfileGet: async (
      service: string,
      outputAsTree?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'service' is not null or undefined
      assertParamExists('profileCpuProfileGet', 'service', service);
      const localVarPath = `/cpu-profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (service !== undefined) {
        localVarQueryParameter['service'] = service;
      }

      if (outputAsTree !== undefined) {
        localVarQueryParameter['output_as_tree'] = outputAsTree;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startProfilingCpuProfileStartPost: async (
      cPUProfilingParams: CPUProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cPUProfilingParams' is not null or undefined
      assertParamExists(
        'startProfilingCpuProfileStartPost',
        'cPUProfilingParams',
        cPUProfilingParams,
      );
      const localVarPath = `/cpu-profile/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cPUProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopProfilingCpuProfileStopPost: async (
      cPUProfilingParams: CPUProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cPUProfilingParams' is not null or undefined
      assertParamExists(
        'stopProfilingCpuProfileStopPost',
        'cPUProfilingParams',
        cPUProfilingParams,
      );
      const localVarPath = `/cpu-profile/stop`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cPUProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CpuProfilingApi - functional programming interface
 * @export
 */
export const CpuProfilingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CpuProfilingApiAxiosParamCreator(configuration);
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearProfilingCpuProfileClearPost(
      cPUProfilingParams: CPUProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CPUProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.clearProfilingCpuProfileClearPost(
          cPUProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CpuProfilingApi.clearProfilingCpuProfileClearPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get the Profile backtrace from the Redis
     * @summary Profile
     * @param {string} service
     * @param {boolean} [outputAsTree]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async profileCpuProfileGet(
      service: string,
      outputAsTree?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.profileCpuProfileGet(
          service,
          outputAsTree,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['CpuProfilingApi.profileCpuProfileGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startProfilingCpuProfileStartPost(
      cPUProfilingParams: CPUProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CPUProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.startProfilingCpuProfileStartPost(
          cPUProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CpuProfilingApi.startProfilingCpuProfileStartPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopProfilingCpuProfileStopPost(
      cPUProfilingParams: CPUProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CPUProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stopProfilingCpuProfileStopPost(
          cPUProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['CpuProfilingApi.stopProfilingCpuProfileStopPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CpuProfilingApi - factory interface
 * @export
 */
export const CpuProfilingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CpuProfilingApiFp(configuration);
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {CpuProfilingApiClearProfilingCpuProfileClearPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearProfilingCpuProfileClearPost(
      requestParameters: CpuProfilingApiClearProfilingCpuProfileClearPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CPUProfilingStatusResponse> {
      return localVarFp
        .clearProfilingCpuProfileClearPost(
          requestParameters.cPUProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Get the Profile backtrace from the Redis
     * @summary Profile
     * @param {CpuProfilingApiProfileCpuProfileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileCpuProfileGet(
      requestParameters: CpuProfilingApiProfileCpuProfileGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<object> {
      return localVarFp
        .profileCpuProfileGet(
          requestParameters.service,
          requestParameters.outputAsTree,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {CpuProfilingApiStartProfilingCpuProfileStartPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startProfilingCpuProfileStartPost(
      requestParameters: CpuProfilingApiStartProfilingCpuProfileStartPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CPUProfilingStatusResponse> {
      return localVarFp
        .startProfilingCpuProfileStartPost(
          requestParameters.cPUProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {CpuProfilingApiStopProfilingCpuProfileStopPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopProfilingCpuProfileStopPost(
      requestParameters: CpuProfilingApiStopProfilingCpuProfileStopPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CPUProfilingStatusResponse> {
      return localVarFp
        .stopProfilingCpuProfileStopPost(
          requestParameters.cPUProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for clearProfilingCpuProfileClearPost operation in CpuProfilingApi.
 * @export
 * @interface CpuProfilingApiClearProfilingCpuProfileClearPostRequest
 */
export interface CpuProfilingApiClearProfilingCpuProfileClearPostRequest {
  /**
   *
   * @type {CPUProfilingParams}
   * @memberof CpuProfilingApiClearProfilingCpuProfileClearPost
   */
  readonly cPUProfilingParams: CPUProfilingParams;
}

/**
 * Request parameters for profileCpuProfileGet operation in CpuProfilingApi.
 * @export
 * @interface CpuProfilingApiProfileCpuProfileGetRequest
 */
export interface CpuProfilingApiProfileCpuProfileGetRequest {
  /**
   *
   * @type {string}
   * @memberof CpuProfilingApiProfileCpuProfileGet
   */
  readonly service: string;

  /**
   *
   * @type {boolean}
   * @memberof CpuProfilingApiProfileCpuProfileGet
   */
  readonly outputAsTree?: boolean;
}

/**
 * Request parameters for startProfilingCpuProfileStartPost operation in CpuProfilingApi.
 * @export
 * @interface CpuProfilingApiStartProfilingCpuProfileStartPostRequest
 */
export interface CpuProfilingApiStartProfilingCpuProfileStartPostRequest {
  /**
   *
   * @type {CPUProfilingParams}
   * @memberof CpuProfilingApiStartProfilingCpuProfileStartPost
   */
  readonly cPUProfilingParams: CPUProfilingParams;
}

/**
 * Request parameters for stopProfilingCpuProfileStopPost operation in CpuProfilingApi.
 * @export
 * @interface CpuProfilingApiStopProfilingCpuProfileStopPostRequest
 */
export interface CpuProfilingApiStopProfilingCpuProfileStopPostRequest {
  /**
   *
   * @type {CPUProfilingParams}
   * @memberof CpuProfilingApiStopProfilingCpuProfileStopPost
   */
  readonly cPUProfilingParams: CPUProfilingParams;
}

/**
 * CpuProfilingApi - object-oriented interface
 * @export
 * @class CpuProfilingApi
 * @extends {BaseAPI}
 */
export class CpuProfilingApi extends BaseAPI {
  /**
   * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
   * @summary Clear Profiling
   * @param {CpuProfilingApiClearProfilingCpuProfileClearPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CpuProfilingApi
   */
  public clearProfilingCpuProfileClearPost(
    requestParameters: CpuProfilingApiClearProfilingCpuProfileClearPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CpuProfilingApiFp(this.configuration)
      .clearProfilingCpuProfileClearPost(
        requestParameters.cPUProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get the Profile backtrace from the Redis
   * @summary Profile
   * @param {CpuProfilingApiProfileCpuProfileGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CpuProfilingApi
   */
  public profileCpuProfileGet(
    requestParameters: CpuProfilingApiProfileCpuProfileGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CpuProfilingApiFp(this.configuration)
      .profileCpuProfileGet(
        requestParameters.service,
        requestParameters.outputAsTree,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Start profiling
   * @summary Start Profiling
   * @param {CpuProfilingApiStartProfilingCpuProfileStartPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CpuProfilingApi
   */
  public startProfilingCpuProfileStartPost(
    requestParameters: CpuProfilingApiStartProfilingCpuProfileStartPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CpuProfilingApiFp(this.configuration)
      .startProfilingCpuProfileStartPost(
        requestParameters.cPUProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Stop profiling
   * @summary Stop Profiling
   * @param {CpuProfilingApiStopProfilingCpuProfileStopPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CpuProfilingApi
   */
  public stopProfilingCpuProfileStopPost(
    requestParameters: CpuProfilingApiStopProfilingCpuProfileStopPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CpuProfilingApiFp(this.configuration)
      .stopProfilingCpuProfileStopPost(
        requestParameters.cPUProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DatasetApi - axios parameter creator
 * @export
 */
export const DatasetApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Clear Ddf Cache
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearDdfCacheNodesNodeUidClearDdfCacheDelete: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'clearDdfCacheNodesNodeUidClearDdfCacheDelete',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/clear-ddf-cache`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete disk cache entries that haven\'t been accessed for `max_access_age_seconds` seconds.
     * @summary Delete Local Cache Entries
     * @param {LocalCacheDeleteParams} localCacheDeleteParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLocalCacheEntriesLocalCacheDelete: async (
      localCacheDeleteParams: LocalCacheDeleteParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'localCacheDeleteParams' is not null or undefined
      assertParamExists(
        'deleteLocalCacheEntriesLocalCacheDelete',
        'localCacheDeleteParams',
        localCacheDeleteParams,
      );
      const localVarPath = `/local-cache`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        localCacheDeleteParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Export the Studio dataset for all batches in CSV format. Aggregates the entire set of batches and exports a merged, de-duplicated dataset in CSV format. CSV results are streamed to the caller.
     * @summary Export Batches
     * @param {number} nodeUid
     * @param {string} [filterConfig]
     * @param {string} [sort]
     * @param {boolean} [ascending]
     * @param {number} [seed]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {Array<string>} [includeTagTypeUid] The list of tags that should be included
     * @param {Array<string>} [excludeTagTypeUid] The list of tags that should be excluded
     * @param {Array<string>} [includeSliceTypeUid] The list of slices that should be included
     * @param {Array<string>} [excludeSliceTypeUid] The list of slices that should be excluded
     * @param {Array<string>} [selectedColumns] List of columns that should be in the export. Default is all columns.
     * @param {Array<number>} [modelUid] The associated model
     * @param {string} [tagColumn] The name for the tag column
     * @param {string} [commentColumn] The name for the comment column
     * @param {string} [batchNameColumn] The name for the batch name column
     * @param {boolean} [includeAnnotations]
     * @param {number} [chunkSize]
     * @param {string} [listseparator]
     * @param {string} [sep]
     * @param {string} [quotechar]
     * @param {string} [escapechar]
     * @param {number} [maxCharsPerCell]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportBatchesNodesNodeUidExportBatchesGet: async (
      nodeUid: number,
      filterConfig?: string,
      sort?: string,
      ascending?: boolean,
      seed?: number,
      limit?: number,
      offset?: number,
      includeTagTypeUid?: Array<string>,
      excludeTagTypeUid?: Array<string>,
      includeSliceTypeUid?: Array<string>,
      excludeSliceTypeUid?: Array<string>,
      selectedColumns?: Array<string>,
      modelUid?: Array<number>,
      tagColumn?: string,
      commentColumn?: string,
      batchNameColumn?: string,
      includeAnnotations?: boolean,
      chunkSize?: number,
      listseparator?: string,
      sep?: string,
      quotechar?: string,
      escapechar?: string,
      maxCharsPerCell?: number,
      applyPostprocessors?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'exportBatchesNodesNodeUidExportBatchesGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/export-batches`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filterConfig !== undefined) {
        localVarQueryParameter['filter_config'] = filterConfig;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (ascending !== undefined) {
        localVarQueryParameter['ascending'] = ascending;
      }

      if (seed !== undefined) {
        localVarQueryParameter['seed'] = seed;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (includeTagTypeUid) {
        localVarQueryParameter['include_tag_type_uid'] = includeTagTypeUid;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (includeSliceTypeUid) {
        localVarQueryParameter['include_slice_type_uid'] = includeSliceTypeUid;
      }

      if (excludeSliceTypeUid) {
        localVarQueryParameter['exclude_slice_type_uid'] = excludeSliceTypeUid;
      }

      if (selectedColumns) {
        localVarQueryParameter['selected_columns'] = selectedColumns;
      }

      if (modelUid) {
        localVarQueryParameter['model_uid'] = modelUid;
      }

      if (tagColumn !== undefined) {
        localVarQueryParameter['tag_column'] = tagColumn;
      }

      if (commentColumn !== undefined) {
        localVarQueryParameter['comment_column'] = commentColumn;
      }

      if (batchNameColumn !== undefined) {
        localVarQueryParameter['batch_name_column'] = batchNameColumn;
      }

      if (includeAnnotations !== undefined) {
        localVarQueryParameter['include_annotations'] = includeAnnotations;
      }

      if (chunkSize !== undefined) {
        localVarQueryParameter['chunk_size'] = chunkSize;
      }

      if (listseparator !== undefined) {
        localVarQueryParameter['listseparator'] = listseparator;
      }

      if (sep !== undefined) {
        localVarQueryParameter['sep'] = sep;
      }

      if (quotechar !== undefined) {
        localVarQueryParameter['quotechar'] = quotechar;
      }

      if (escapechar !== undefined) {
        localVarQueryParameter['escapechar'] = escapechar;
      }

      if (maxCharsPerCell !== undefined) {
        localVarQueryParameter['max_chars_per_cell'] = maxCharsPerCell;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Export the current StudioDataset in CSV format Take the given filters and use them to determine a view of the selected Studio dataset. Export that dataset in CSV format, optionally including tags and comments. CSV results are streamed to the caller.
     * @summary Export Dataset
     * @param {number} nodeUid
     * @param {string} split The dataset that is queried
     * @param {string} [filterConfig]
     * @param {number} [batchUid]
     * @param {string} [sort]
     * @param {boolean} [ascending]
     * @param {number} [seed]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {Array<string>} [includeTagTypeUid] The list of tags that should be included
     * @param {Array<string>} [excludeTagTypeUid] The list of tags that should be excluded
     * @param {Array<string>} [includeSliceTypeUid] The list of slices that should be included
     * @param {Array<string>} [excludeSliceTypeUid] The list of slices that should be excluded
     * @param {Array<string>} [selectedColumns] List of columns that should be in the export. Default is all columns.
     * @param {Array<number>} [modelUid] The associated model
     * @param {string} [tagColumn] The name for the tag column
     * @param {string} [commentColumn] The name for the comment column
     * @param {string} [batchNameColumn] The name for the batch name column
     * @param {boolean} [includeAnnotations]
     * @param {number} [chunkSize]
     * @param {string} [listseparator]
     * @param {string} [sep]
     * @param {string} [quotechar]
     * @param {string} [escapechar]
     * @param {number} [maxCharsPerCell]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportDatasetNodesNodeUidSplitsSplitExportDatasetGet: async (
      nodeUid: number,
      split: string,
      filterConfig?: string,
      batchUid?: number,
      sort?: string,
      ascending?: boolean,
      seed?: number,
      limit?: number,
      offset?: number,
      includeTagTypeUid?: Array<string>,
      excludeTagTypeUid?: Array<string>,
      includeSliceTypeUid?: Array<string>,
      excludeSliceTypeUid?: Array<string>,
      selectedColumns?: Array<string>,
      modelUid?: Array<number>,
      tagColumn?: string,
      commentColumn?: string,
      batchNameColumn?: string,
      includeAnnotations?: boolean,
      chunkSize?: number,
      listseparator?: string,
      sep?: string,
      quotechar?: string,
      escapechar?: string,
      maxCharsPerCell?: number,
      applyPostprocessors?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'exportDatasetNodesNodeUidSplitsSplitExportDatasetGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'exportDatasetNodesNodeUidSplitsSplitExportDatasetGet',
        'split',
        split,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/export-dataset`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filterConfig !== undefined) {
        localVarQueryParameter['filter_config'] = filterConfig;
      }

      if (batchUid !== undefined) {
        localVarQueryParameter['batch_uid'] = batchUid;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (ascending !== undefined) {
        localVarQueryParameter['ascending'] = ascending;
      }

      if (seed !== undefined) {
        localVarQueryParameter['seed'] = seed;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (includeTagTypeUid) {
        localVarQueryParameter['include_tag_type_uid'] = includeTagTypeUid;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (includeSliceTypeUid) {
        localVarQueryParameter['include_slice_type_uid'] = includeSliceTypeUid;
      }

      if (excludeSliceTypeUid) {
        localVarQueryParameter['exclude_slice_type_uid'] = excludeSliceTypeUid;
      }

      if (selectedColumns) {
        localVarQueryParameter['selected_columns'] = selectedColumns;
      }

      if (modelUid) {
        localVarQueryParameter['model_uid'] = modelUid;
      }

      if (tagColumn !== undefined) {
        localVarQueryParameter['tag_column'] = tagColumn;
      }

      if (commentColumn !== undefined) {
        localVarQueryParameter['comment_column'] = commentColumn;
      }

      if (batchNameColumn !== undefined) {
        localVarQueryParameter['batch_name_column'] = batchNameColumn;
      }

      if (includeAnnotations !== undefined) {
        localVarQueryParameter['include_annotations'] = includeAnnotations;
      }

      if (chunkSize !== undefined) {
        localVarQueryParameter['chunk_size'] = chunkSize;
      }

      if (listseparator !== undefined) {
        localVarQueryParameter['listseparator'] = listseparator;
      }

      if (sep !== undefined) {
        localVarQueryParameter['sep'] = sep;
      }

      if (quotechar !== undefined) {
        localVarQueryParameter['quotechar'] = quotechar;
      }

      if (escapechar !== undefined) {
        localVarQueryParameter['escapechar'] = escapechar;
      }

      if (maxCharsPerCell !== undefined) {
        localVarQueryParameter['max_chars_per_cell'] = maxCharsPerCell;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Export the given training set in CSV format
     * @summary Export Training Set
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {Array<string>} splits
     * @param {boolean} [trainingSetLabels]
     * @param {boolean} [predictedProbs]
     * @param {boolean} [abstains]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet:
      async (
        nodeUid: number,
        trainingSet: number,
        splits: Array<string>,
        trainingSetLabels?: boolean,
        predictedProbs?: boolean,
        abstains?: boolean,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'trainingSet' is not null or undefined
        assertParamExists(
          'exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet',
          'trainingSet',
          trainingSet,
        );
        // verify required parameter 'splits' is not null or undefined
        assertParamExists(
          'exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet',
          'splits',
          splits,
        );
        const localVarPath =
          `/nodes/{node_uid}/training-sets/{training_set}/export-training-set`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(
              `{${'training_set'}}`,
              encodeURIComponent(String(trainingSet)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (splits) {
          localVarQueryParameter['splits'] = splits;
        }

        if (trainingSetLabels !== undefined) {
          localVarQueryParameter['training_set_labels'] = trainingSetLabels;
        }

        if (predictedProbs !== undefined) {
          localVarQueryParameter['predicted_probs'] = predictedProbs;
        }

        if (abstains !== undefined) {
          localVarQueryParameter['abstains'] = abstains;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Data
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} xUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataNodesNodeUidSplitsSplitDatasetXUidGet: async (
      nodeUid: number,
      split: string,
      xUid: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getDataNodesNodeUidSplitsSplitDatasetXUidGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getDataNodesNodeUidSplitsSplitDatasetXUidGet',
        'split',
        split,
      );
      // verify required parameter 'xUid' is not null or undefined
      assertParamExists(
        'getDataNodesNodeUidSplitsSplitDatasetXUidGet',
        'xUid',
        xUid,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/dataset/{x_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)))
        .replace(`{${'x_uid'}}`, encodeURIComponent(String(xUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Dataset Fields
     * @param {number} nodeUid
     * @param {string} split
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet: async (
      nodeUid: number,
      split: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet',
        'split',
        split,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/dataset/fields`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return dataset and associated metadata.
     * @summary Get Dataset
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} [filterConfig]
     * @param {number} [batchUid]
     * @param {string} [sort]
     * @param {Array<string>} [multiSort]
     * @param {boolean} [ascending]
     * @param {Array<boolean>} [multiSortAscending]
     * @param {boolean} [showFilteredFlag]
     * @param {number} [seed]
     * @param {boolean} [fetchSpanText]
     * @param {number} [limit]
     * @param {boolean} [dynamicPaging]
     * @param {number} [dynamicPagingSizeKB]
     * @param {number} [offset]
     * @param {Array<string>} [datasetColumns]
     * @param {Array<string>} [includeTagTypeUid]
     * @param {Array<string>} [excludeTagTypeUid]
     * @param {Array<string>} [includeSliceTypeUid]
     * @param {Array<string>} [excludeSliceTypeUid]
     * @param {Array<number>} [modelUid]
     * @param {Array<number>} [trainingSetUid]
     * @param {Array<number>} [lfUid]
     * @param {string} [findUid]
     * @param {string} [tagColumn]
     * @param {string} [commentColumn] The name of the comment column if passed
     * @param {string} [lfConfig]
     * @param {boolean} [includeAnnotations]
     * @param {boolean} [compress]
     * @param {boolean} [applyPostprocessors]
     * @param {boolean} [findWithinPage]
     * @param {boolean} [returnEmbeddings]
     * @param {boolean} [includeTotalIndex]
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetNodesNodeUidSplitsSplitDatasetGet: async (
      nodeUid: number,
      split: string,
      filterConfig?: string,
      batchUid?: number,
      sort?: string,
      multiSort?: Array<string>,
      ascending?: boolean,
      multiSortAscending?: Array<boolean>,
      showFilteredFlag?: boolean,
      seed?: number,
      fetchSpanText?: boolean,
      limit?: number,
      dynamicPaging?: boolean,
      dynamicPagingSizeKB?: number,
      offset?: number,
      datasetColumns?: Array<string>,
      includeTagTypeUid?: Array<string>,
      excludeTagTypeUid?: Array<string>,
      includeSliceTypeUid?: Array<string>,
      excludeSliceTypeUid?: Array<string>,
      modelUid?: Array<number>,
      trainingSetUid?: Array<number>,
      lfUid?: Array<number>,
      findUid?: string,
      tagColumn?: string,
      commentColumn?: string,
      lfConfig?: string,
      includeAnnotations?: boolean,
      compress?: boolean,
      applyPostprocessors?: boolean,
      findWithinPage?: boolean,
      returnEmbeddings?: boolean,
      includeTotalIndex?: boolean,
      requestBody?: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getDatasetNodesNodeUidSplitsSplitDatasetGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getDatasetNodesNodeUidSplitsSplitDatasetGet',
        'split',
        split,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/dataset`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filterConfig !== undefined) {
        localVarQueryParameter['filter_config'] = filterConfig;
      }

      if (batchUid !== undefined) {
        localVarQueryParameter['batch_uid'] = batchUid;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (multiSort) {
        localVarQueryParameter['multi_sort'] = multiSort;
      }

      if (ascending !== undefined) {
        localVarQueryParameter['ascending'] = ascending;
      }

      if (multiSortAscending) {
        localVarQueryParameter['multi_sort_ascending'] = multiSortAscending;
      }

      if (showFilteredFlag !== undefined) {
        localVarQueryParameter['show_filtered_flag'] = showFilteredFlag;
      }

      if (seed !== undefined) {
        localVarQueryParameter['seed'] = seed;
      }

      if (fetchSpanText !== undefined) {
        localVarQueryParameter['fetch_span_text'] = fetchSpanText;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (dynamicPaging !== undefined) {
        localVarQueryParameter['dynamic_paging'] = dynamicPaging;
      }

      if (dynamicPagingSizeKB !== undefined) {
        localVarQueryParameter['dynamic_paging_size_KB'] = dynamicPagingSizeKB;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (datasetColumns) {
        localVarQueryParameter['dataset_columns'] = datasetColumns;
      }

      if (includeTagTypeUid) {
        localVarQueryParameter['include_tag_type_uid'] = includeTagTypeUid;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (includeSliceTypeUid) {
        localVarQueryParameter['include_slice_type_uid'] = includeSliceTypeUid;
      }

      if (excludeSliceTypeUid) {
        localVarQueryParameter['exclude_slice_type_uid'] = excludeSliceTypeUid;
      }

      if (modelUid) {
        localVarQueryParameter['model_uid'] = modelUid;
      }

      if (trainingSetUid) {
        localVarQueryParameter['training_set_uid'] = trainingSetUid;
      }

      if (lfUid) {
        localVarQueryParameter['lf_uid'] = lfUid;
      }

      if (findUid !== undefined) {
        localVarQueryParameter['find_uid'] = findUid;
      }

      if (tagColumn !== undefined) {
        localVarQueryParameter['tag_column'] = tagColumn;
      }

      if (commentColumn !== undefined) {
        localVarQueryParameter['comment_column'] = commentColumn;
      }

      if (lfConfig !== undefined) {
        localVarQueryParameter['lf_config'] = lfConfig;
      }

      if (includeAnnotations !== undefined) {
        localVarQueryParameter['include_annotations'] = includeAnnotations;
      }

      if (compress !== undefined) {
        localVarQueryParameter['compress'] = compress;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      if (findWithinPage !== undefined) {
        localVarQueryParameter['find_within_page'] = findWithinPage;
      }

      if (returnEmbeddings !== undefined) {
        localVarQueryParameter['return_embeddings'] = returnEmbeddings;
      }

      if (includeTotalIndex !== undefined) {
        localVarQueryParameter['include_total_index'] = includeTotalIndex;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Dataset Uids
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} [filterConfig]
     * @param {number} [batchUid]
     * @param {string} [sort]
     * @param {boolean} [ascending]
     * @param {boolean} [showFilteredFlag]
     * @param {number} [seed]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {Array<number>} [modelUid]
     * @param {string} [findUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet: async (
      nodeUid: number,
      split: string,
      filterConfig?: string,
      batchUid?: number,
      sort?: string,
      ascending?: boolean,
      showFilteredFlag?: boolean,
      seed?: number,
      limit?: number,
      offset?: number,
      modelUid?: Array<number>,
      findUid?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet',
        'split',
        split,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/dataset/uids`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filterConfig !== undefined) {
        localVarQueryParameter['filter_config'] = filterConfig;
      }

      if (batchUid !== undefined) {
        localVarQueryParameter['batch_uid'] = batchUid;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (ascending !== undefined) {
        localVarQueryParameter['ascending'] = ascending;
      }

      if (showFilteredFlag !== undefined) {
        localVarQueryParameter['show_filtered_flag'] = showFilteredFlag;
      }

      if (seed !== undefined) {
        localVarQueryParameter['seed'] = seed;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (modelUid) {
        localVarQueryParameter['model_uid'] = modelUid;
      }

      if (findUid !== undefined) {
        localVarQueryParameter['find_uid'] = findUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return all non-abstain votes for the given split, lf_uid, and context_uid Note: One purpose for this method is to fetch all the LF votes for a given context (e.g., to display LF votes in doc mode). There is a limit on how long a query string can be, so we can\'t pass all desired x_uids in the query string to the TDM endpoint. Instead, we fetch all x_uids for the given split and filter post-hoc.
     * @summary Get Lf Labels
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} lfUid
     * @param {number} contextUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet: async (
      nodeUid: number,
      split: string,
      lfUid: number,
      contextUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet',
        'split',
        split,
      );
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists(
        'getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet',
        'lfUid',
        lfUid,
      );
      // verify required parameter 'contextUid' is not null or undefined
      assertParamExists(
        'getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet',
        'contextUid',
        contextUid,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/lf-labels`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lfUid !== undefined) {
        localVarQueryParameter['lf_uid'] = lfUid;
      }

      if (contextUid !== undefined) {
        localVarQueryParameter['context_uid'] = contextUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Ngram Data
     * @param {number} nodeUid
     * @param {string} split
     * @param {Array<string>} datasetColumns
     * @param {Array<string>} labels
     * @param {string} [stopWords]
     * @param {number} [ngramMin]
     * @param {number} [ngramMax]
     * @param {number} [minAppearance]
     * @param {number} [threshold]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNgramDataNodesNodeUidSplitsSplitNGramDataGet: async (
      nodeUid: number,
      split: string,
      datasetColumns: Array<string>,
      labels: Array<string>,
      stopWords?: string,
      ngramMin?: number,
      ngramMax?: number,
      minAppearance?: number,
      threshold?: number,
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getNgramDataNodesNodeUidSplitsSplitNGramDataGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getNgramDataNodesNodeUidSplitsSplitNGramDataGet',
        'split',
        split,
      );
      // verify required parameter 'datasetColumns' is not null or undefined
      assertParamExists(
        'getNgramDataNodesNodeUidSplitsSplitNGramDataGet',
        'datasetColumns',
        datasetColumns,
      );
      // verify required parameter 'labels' is not null or undefined
      assertParamExists(
        'getNgramDataNodesNodeUidSplitsSplitNGramDataGet',
        'labels',
        labels,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/n-gram-data`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasetColumns) {
        localVarQueryParameter['dataset_columns'] = datasetColumns;
      }

      if (labels) {
        localVarQueryParameter['labels'] = labels;
      }

      if (stopWords !== undefined) {
        localVarQueryParameter['stop_words'] = stopWords;
      }

      if (ngramMin !== undefined) {
        localVarQueryParameter['ngram_min'] = ngramMin;
      }

      if (ngramMax !== undefined) {
        localVarQueryParameter['ngram_max'] = ngramMax;
      }

      if (minAppearance !== undefined) {
        localVarQueryParameter['min_appearance'] = minAppearance;
      }

      if (threshold !== undefined) {
        localVarQueryParameter['threshold'] = threshold;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Studio Load Job Status
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet:
      async (
        nodeUid: number,
        split: string,
        jobId: string,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet',
          'split',
          split,
        );
        // verify required parameter 'jobId' is not null or undefined
        assertParamExists(
          'getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet',
          'jobId',
          jobId,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/studio-loading-jobs/{job_id}`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)))
            .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Load Dataset
     * @param {number} nodeUid
     * @param {string} split
     * @param {LoadDatasetParams} loadDatasetParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost: async (
      nodeUid: number,
      split: string,
      loadDatasetParams: LoadDatasetParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost',
        'split',
        split,
      );
      // verify required parameter 'loadDatasetParams' is not null or undefined
      assertParamExists(
        'loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost',
        'loadDatasetParams',
        loadDatasetParams,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/load-dataset`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loadDatasetParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resample Split
     * @param {number} nodeUid
     * @param {string} split
     * @param {ResampleRequestBody} resampleRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resampleSplitNodesNodeUidSplitsSplitResamplePost: async (
      nodeUid: number,
      split: string,
      resampleRequestBody: ResampleRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'resampleSplitNodesNodeUidSplitsSplitResamplePost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'resampleSplitNodesNodeUidSplitsSplitResamplePost',
        'split',
        split,
      );
      // verify required parameter 'resampleRequestBody' is not null or undefined
      assertParamExists(
        'resampleSplitNodesNodeUidSplitsSplitResamplePost',
        'resampleRequestBody',
        resampleRequestBody,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/resample`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resampleRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatasetApi - functional programming interface
 * @export
 */
export const DatasetApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DatasetApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Clear Ddf Cache
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearDdfCacheNodesNodeUidClearDdfCacheDelete(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.clearDdfCacheNodesNodeUidClearDdfCacheDelete(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.clearDdfCacheNodesNodeUidClearDdfCacheDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete disk cache entries that haven\'t been accessed for `max_access_age_seconds` seconds.
     * @summary Delete Local Cache Entries
     * @param {LocalCacheDeleteParams} localCacheDeleteParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteLocalCacheEntriesLocalCacheDelete(
      localCacheDeleteParams: LocalCacheDeleteParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteLocalCacheEntriesLocalCacheDelete(
          localCacheDeleteParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.deleteLocalCacheEntriesLocalCacheDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Export the Studio dataset for all batches in CSV format. Aggregates the entire set of batches and exports a merged, de-duplicated dataset in CSV format. CSV results are streamed to the caller.
     * @summary Export Batches
     * @param {number} nodeUid
     * @param {string} [filterConfig]
     * @param {string} [sort]
     * @param {boolean} [ascending]
     * @param {number} [seed]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {Array<string>} [includeTagTypeUid] The list of tags that should be included
     * @param {Array<string>} [excludeTagTypeUid] The list of tags that should be excluded
     * @param {Array<string>} [includeSliceTypeUid] The list of slices that should be included
     * @param {Array<string>} [excludeSliceTypeUid] The list of slices that should be excluded
     * @param {Array<string>} [selectedColumns] List of columns that should be in the export. Default is all columns.
     * @param {Array<number>} [modelUid] The associated model
     * @param {string} [tagColumn] The name for the tag column
     * @param {string} [commentColumn] The name for the comment column
     * @param {string} [batchNameColumn] The name for the batch name column
     * @param {boolean} [includeAnnotations]
     * @param {number} [chunkSize]
     * @param {string} [listseparator]
     * @param {string} [sep]
     * @param {string} [quotechar]
     * @param {string} [escapechar]
     * @param {number} [maxCharsPerCell]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportBatchesNodesNodeUidExportBatchesGet(
      nodeUid: number,
      filterConfig?: string,
      sort?: string,
      ascending?: boolean,
      seed?: number,
      limit?: number,
      offset?: number,
      includeTagTypeUid?: Array<string>,
      excludeTagTypeUid?: Array<string>,
      includeSliceTypeUid?: Array<string>,
      excludeSliceTypeUid?: Array<string>,
      selectedColumns?: Array<string>,
      modelUid?: Array<number>,
      tagColumn?: string,
      commentColumn?: string,
      batchNameColumn?: string,
      includeAnnotations?: boolean,
      chunkSize?: number,
      listseparator?: string,
      sep?: string,
      quotechar?: string,
      escapechar?: string,
      maxCharsPerCell?: number,
      applyPostprocessors?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportBatchesNodesNodeUidExportBatchesGet(
          nodeUid,
          filterConfig,
          sort,
          ascending,
          seed,
          limit,
          offset,
          includeTagTypeUid,
          excludeTagTypeUid,
          includeSliceTypeUid,
          excludeSliceTypeUid,
          selectedColumns,
          modelUid,
          tagColumn,
          commentColumn,
          batchNameColumn,
          includeAnnotations,
          chunkSize,
          listseparator,
          sep,
          quotechar,
          escapechar,
          maxCharsPerCell,
          applyPostprocessors,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.exportBatchesNodesNodeUidExportBatchesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Export the current StudioDataset in CSV format Take the given filters and use them to determine a view of the selected Studio dataset. Export that dataset in CSV format, optionally including tags and comments. CSV results are streamed to the caller.
     * @summary Export Dataset
     * @param {number} nodeUid
     * @param {string} split The dataset that is queried
     * @param {string} [filterConfig]
     * @param {number} [batchUid]
     * @param {string} [sort]
     * @param {boolean} [ascending]
     * @param {number} [seed]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {Array<string>} [includeTagTypeUid] The list of tags that should be included
     * @param {Array<string>} [excludeTagTypeUid] The list of tags that should be excluded
     * @param {Array<string>} [includeSliceTypeUid] The list of slices that should be included
     * @param {Array<string>} [excludeSliceTypeUid] The list of slices that should be excluded
     * @param {Array<string>} [selectedColumns] List of columns that should be in the export. Default is all columns.
     * @param {Array<number>} [modelUid] The associated model
     * @param {string} [tagColumn] The name for the tag column
     * @param {string} [commentColumn] The name for the comment column
     * @param {string} [batchNameColumn] The name for the batch name column
     * @param {boolean} [includeAnnotations]
     * @param {number} [chunkSize]
     * @param {string} [listseparator]
     * @param {string} [sep]
     * @param {string} [quotechar]
     * @param {string} [escapechar]
     * @param {number} [maxCharsPerCell]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportDatasetNodesNodeUidSplitsSplitExportDatasetGet(
      nodeUid: number,
      split: string,
      filterConfig?: string,
      batchUid?: number,
      sort?: string,
      ascending?: boolean,
      seed?: number,
      limit?: number,
      offset?: number,
      includeTagTypeUid?: Array<string>,
      excludeTagTypeUid?: Array<string>,
      includeSliceTypeUid?: Array<string>,
      excludeSliceTypeUid?: Array<string>,
      selectedColumns?: Array<string>,
      modelUid?: Array<number>,
      tagColumn?: string,
      commentColumn?: string,
      batchNameColumn?: string,
      includeAnnotations?: boolean,
      chunkSize?: number,
      listseparator?: string,
      sep?: string,
      quotechar?: string,
      escapechar?: string,
      maxCharsPerCell?: number,
      applyPostprocessors?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportDatasetNodesNodeUidSplitsSplitExportDatasetGet(
          nodeUid,
          split,
          filterConfig,
          batchUid,
          sort,
          ascending,
          seed,
          limit,
          offset,
          includeTagTypeUid,
          excludeTagTypeUid,
          includeSliceTypeUid,
          excludeSliceTypeUid,
          selectedColumns,
          modelUid,
          tagColumn,
          commentColumn,
          batchNameColumn,
          includeAnnotations,
          chunkSize,
          listseparator,
          sep,
          quotechar,
          escapechar,
          maxCharsPerCell,
          applyPostprocessors,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.exportDatasetNodesNodeUidSplitsSplitExportDatasetGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Export the given training set in CSV format
     * @summary Export Training Set
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {Array<string>} splits
     * @param {boolean} [trainingSetLabels]
     * @param {boolean} [predictedProbs]
     * @param {boolean} [abstains]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet(
      nodeUid: number,
      trainingSet: number,
      splits: Array<string>,
      trainingSetLabels?: boolean,
      predictedProbs?: boolean,
      abstains?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet(
          nodeUid,
          trainingSet,
          splits,
          trainingSetLabels,
          predictedProbs,
          abstains,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Data
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} xUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataNodesNodeUidSplitsSplitDatasetXUidGet(
      nodeUid: number,
      split: string,
      xUid: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDataNodesNodeUidSplitsSplitDatasetXUidGet(
          nodeUid,
          split,
          xUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.getDataNodesNodeUidSplitsSplitDatasetXUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Dataset Fields
     * @param {number} nodeUid
     * @param {string} split
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet(
      nodeUid: number,
      split: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: string }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet(
          nodeUid,
          split,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.getDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return dataset and associated metadata.
     * @summary Get Dataset
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} [filterConfig]
     * @param {number} [batchUid]
     * @param {string} [sort]
     * @param {Array<string>} [multiSort]
     * @param {boolean} [ascending]
     * @param {Array<boolean>} [multiSortAscending]
     * @param {boolean} [showFilteredFlag]
     * @param {number} [seed]
     * @param {boolean} [fetchSpanText]
     * @param {number} [limit]
     * @param {boolean} [dynamicPaging]
     * @param {number} [dynamicPagingSizeKB]
     * @param {number} [offset]
     * @param {Array<string>} [datasetColumns]
     * @param {Array<string>} [includeTagTypeUid]
     * @param {Array<string>} [excludeTagTypeUid]
     * @param {Array<string>} [includeSliceTypeUid]
     * @param {Array<string>} [excludeSliceTypeUid]
     * @param {Array<number>} [modelUid]
     * @param {Array<number>} [trainingSetUid]
     * @param {Array<number>} [lfUid]
     * @param {string} [findUid]
     * @param {string} [tagColumn]
     * @param {string} [commentColumn] The name of the comment column if passed
     * @param {string} [lfConfig]
     * @param {boolean} [includeAnnotations]
     * @param {boolean} [compress]
     * @param {boolean} [applyPostprocessors]
     * @param {boolean} [findWithinPage]
     * @param {boolean} [returnEmbeddings]
     * @param {boolean} [includeTotalIndex]
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatasetNodesNodeUidSplitsSplitDatasetGet(
      nodeUid: number,
      split: string,
      filterConfig?: string,
      batchUid?: number,
      sort?: string,
      multiSort?: Array<string>,
      ascending?: boolean,
      multiSortAscending?: Array<boolean>,
      showFilteredFlag?: boolean,
      seed?: number,
      fetchSpanText?: boolean,
      limit?: number,
      dynamicPaging?: boolean,
      dynamicPagingSizeKB?: number,
      offset?: number,
      datasetColumns?: Array<string>,
      includeTagTypeUid?: Array<string>,
      excludeTagTypeUid?: Array<string>,
      includeSliceTypeUid?: Array<string>,
      excludeSliceTypeUid?: Array<string>,
      modelUid?: Array<number>,
      trainingSetUid?: Array<number>,
      lfUid?: Array<number>,
      findUid?: string,
      tagColumn?: string,
      commentColumn?: string,
      lfConfig?: string,
      includeAnnotations?: boolean,
      compress?: boolean,
      applyPostprocessors?: boolean,
      findWithinPage?: boolean,
      returnEmbeddings?: boolean,
      includeTotalIndex?: boolean,
      requestBody?: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DatasetResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDatasetNodesNodeUidSplitsSplitDatasetGet(
          nodeUid,
          split,
          filterConfig,
          batchUid,
          sort,
          multiSort,
          ascending,
          multiSortAscending,
          showFilteredFlag,
          seed,
          fetchSpanText,
          limit,
          dynamicPaging,
          dynamicPagingSizeKB,
          offset,
          datasetColumns,
          includeTagTypeUid,
          excludeTagTypeUid,
          includeSliceTypeUid,
          excludeSliceTypeUid,
          modelUid,
          trainingSetUid,
          lfUid,
          findUid,
          tagColumn,
          commentColumn,
          lfConfig,
          includeAnnotations,
          compress,
          applyPostprocessors,
          findWithinPage,
          returnEmbeddings,
          includeTotalIndex,
          requestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.getDatasetNodesNodeUidSplitsSplitDatasetGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Dataset Uids
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} [filterConfig]
     * @param {number} [batchUid]
     * @param {string} [sort]
     * @param {boolean} [ascending]
     * @param {boolean} [showFilteredFlag]
     * @param {number} [seed]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {Array<number>} [modelUid]
     * @param {string} [findUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet(
      nodeUid: number,
      split: string,
      filterConfig?: string,
      batchUid?: number,
      sort?: string,
      ascending?: boolean,
      showFilteredFlag?: boolean,
      seed?: number,
      limit?: number,
      offset?: number,
      modelUid?: Array<number>,
      findUid?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DatasetUidsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet(
          nodeUid,
          split,
          filterConfig,
          batchUid,
          sort,
          ascending,
          showFilteredFlag,
          seed,
          limit,
          offset,
          modelUid,
          findUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.getDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return all non-abstain votes for the given split, lf_uid, and context_uid Note: One purpose for this method is to fetch all the LF votes for a given context (e.g., to display LF votes in doc mode). There is a limit on how long a query string can be, so we can\'t pass all desired x_uids in the query string to the TDM endpoint. Instead, we fetch all x_uids for the given split and filter post-hoc.
     * @summary Get Lf Labels
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} lfUid
     * @param {number} contextUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet(
      nodeUid: number,
      split: string,
      lfUid: number,
      contextUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet(
          nodeUid,
          split,
          lfUid,
          contextUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Ngram Data
     * @param {number} nodeUid
     * @param {string} split
     * @param {Array<string>} datasetColumns
     * @param {Array<string>} labels
     * @param {string} [stopWords]
     * @param {number} [ngramMin]
     * @param {number} [ngramMax]
     * @param {number} [minAppearance]
     * @param {number} [threshold]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNgramDataNodesNodeUidSplitsSplitNGramDataGet(
      nodeUid: number,
      split: string,
      datasetColumns: Array<string>,
      labels: Array<string>,
      stopWords?: string,
      ngramMin?: number,
      ngramMax?: number,
      minAppearance?: number,
      threshold?: number,
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetNgramDataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNgramDataNodesNodeUidSplitsSplitNGramDataGet(
          nodeUid,
          split,
          datasetColumns,
          labels,
          stopWords,
          ngramMin,
          ngramMax,
          minAppearance,
          threshold,
          limit,
          offset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.getNgramDataNodesNodeUidSplitsSplitNGramDataGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Studio Load Job Status
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet(
      nodeUid: number,
      split: string,
      jobId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet(
          nodeUid,
          split,
          jobId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Load Dataset
     * @param {number} nodeUid
     * @param {string} split
     * @param {LoadDatasetParams} loadDatasetParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost(
      nodeUid: number,
      split: string,
      loadDatasetParams: LoadDatasetParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost(
          nodeUid,
          split,
          loadDatasetParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Resample Split
     * @param {number} nodeUid
     * @param {string} split
     * @param {ResampleRequestBody} resampleRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resampleSplitNodesNodeUidSplitsSplitResamplePost(
      nodeUid: number,
      split: string,
      resampleRequestBody: ResampleRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resampleSplitNodesNodeUidSplitsSplitResamplePost(
          nodeUid,
          split,
          resampleRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetApi.resampleSplitNodesNodeUidSplitsSplitResamplePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DatasetApi - factory interface
 * @export
 */
export const DatasetApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DatasetApiFp(configuration);
  return {
    /**
     *
     * @summary Clear Ddf Cache
     * @param {DatasetApiClearDdfCacheNodesNodeUidClearDdfCacheDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearDdfCacheNodesNodeUidClearDdfCacheDelete(
      requestParameters: DatasetApiClearDdfCacheNodesNodeUidClearDdfCacheDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .clearDdfCacheNodesNodeUidClearDdfCacheDelete(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Delete disk cache entries that haven\'t been accessed for `max_access_age_seconds` seconds.
     * @summary Delete Local Cache Entries
     * @param {DatasetApiDeleteLocalCacheEntriesLocalCacheDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLocalCacheEntriesLocalCacheDelete(
      requestParameters: DatasetApiDeleteLocalCacheEntriesLocalCacheDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteLocalCacheEntriesLocalCacheDelete(
          requestParameters.localCacheDeleteParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Export the Studio dataset for all batches in CSV format. Aggregates the entire set of batches and exports a merged, de-duplicated dataset in CSV format. CSV results are streamed to the caller.
     * @summary Export Batches
     * @param {DatasetApiExportBatchesNodesNodeUidExportBatchesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportBatchesNodesNodeUidExportBatchesGet(
      requestParameters: DatasetApiExportBatchesNodesNodeUidExportBatchesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .exportBatchesNodesNodeUidExportBatchesGet(
          requestParameters.nodeUid,
          requestParameters.filterConfig,
          requestParameters.sort,
          requestParameters.ascending,
          requestParameters.seed,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.includeTagTypeUid,
          requestParameters.excludeTagTypeUid,
          requestParameters.includeSliceTypeUid,
          requestParameters.excludeSliceTypeUid,
          requestParameters.selectedColumns,
          requestParameters.modelUid,
          requestParameters.tagColumn,
          requestParameters.commentColumn,
          requestParameters.batchNameColumn,
          requestParameters.includeAnnotations,
          requestParameters.chunkSize,
          requestParameters.listseparator,
          requestParameters.sep,
          requestParameters.quotechar,
          requestParameters.escapechar,
          requestParameters.maxCharsPerCell,
          requestParameters.applyPostprocessors,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Export the current StudioDataset in CSV format Take the given filters and use them to determine a view of the selected Studio dataset. Export that dataset in CSV format, optionally including tags and comments. CSV results are streamed to the caller.
     * @summary Export Dataset
     * @param {DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportDatasetNodesNodeUidSplitsSplitExportDatasetGet(
      requestParameters: DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .exportDatasetNodesNodeUidSplitsSplitExportDatasetGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.filterConfig,
          requestParameters.batchUid,
          requestParameters.sort,
          requestParameters.ascending,
          requestParameters.seed,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.includeTagTypeUid,
          requestParameters.excludeTagTypeUid,
          requestParameters.includeSliceTypeUid,
          requestParameters.excludeSliceTypeUid,
          requestParameters.selectedColumns,
          requestParameters.modelUid,
          requestParameters.tagColumn,
          requestParameters.commentColumn,
          requestParameters.batchNameColumn,
          requestParameters.includeAnnotations,
          requestParameters.chunkSize,
          requestParameters.listseparator,
          requestParameters.sep,
          requestParameters.quotechar,
          requestParameters.escapechar,
          requestParameters.maxCharsPerCell,
          requestParameters.applyPostprocessors,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Export the given training set in CSV format
     * @summary Export Training Set
     * @param {DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet(
      requestParameters: DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet(
          requestParameters.nodeUid,
          requestParameters.trainingSet,
          requestParameters.splits,
          requestParameters.trainingSetLabels,
          requestParameters.predictedProbs,
          requestParameters.abstains,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Data
     * @param {DatasetApiGetDataNodesNodeUidSplitsSplitDatasetXUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataNodesNodeUidSplitsSplitDatasetXUidGet(
      requestParameters: DatasetApiGetDataNodesNodeUidSplitsSplitDatasetXUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getDataNodesNodeUidSplitsSplitDatasetXUidGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.xUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Dataset Fields
     * @param {DatasetApiGetDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet(
      requestParameters: DatasetApiGetDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: string }> {
      return localVarFp
        .getDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet(
          requestParameters.nodeUid,
          requestParameters.split,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Return dataset and associated metadata.
     * @summary Get Dataset
     * @param {DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetNodesNodeUidSplitsSplitDatasetGet(
      requestParameters: DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetResponse> {
      return localVarFp
        .getDatasetNodesNodeUidSplitsSplitDatasetGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.filterConfig,
          requestParameters.batchUid,
          requestParameters.sort,
          requestParameters.multiSort,
          requestParameters.ascending,
          requestParameters.multiSortAscending,
          requestParameters.showFilteredFlag,
          requestParameters.seed,
          requestParameters.fetchSpanText,
          requestParameters.limit,
          requestParameters.dynamicPaging,
          requestParameters.dynamicPagingSizeKB,
          requestParameters.offset,
          requestParameters.datasetColumns,
          requestParameters.includeTagTypeUid,
          requestParameters.excludeTagTypeUid,
          requestParameters.includeSliceTypeUid,
          requestParameters.excludeSliceTypeUid,
          requestParameters.modelUid,
          requestParameters.trainingSetUid,
          requestParameters.lfUid,
          requestParameters.findUid,
          requestParameters.tagColumn,
          requestParameters.commentColumn,
          requestParameters.lfConfig,
          requestParameters.includeAnnotations,
          requestParameters.compress,
          requestParameters.applyPostprocessors,
          requestParameters.findWithinPage,
          requestParameters.returnEmbeddings,
          requestParameters.includeTotalIndex,
          requestParameters.requestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Dataset Uids
     * @param {DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet(
      requestParameters: DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetUidsResponse> {
      return localVarFp
        .getDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.filterConfig,
          requestParameters.batchUid,
          requestParameters.sort,
          requestParameters.ascending,
          requestParameters.showFilteredFlag,
          requestParameters.seed,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.modelUid,
          requestParameters.findUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Return all non-abstain votes for the given split, lf_uid, and context_uid Note: One purpose for this method is to fetch all the LF votes for a given context (e.g., to display LF votes in doc mode). There is a limit on how long a query string can be, so we can\'t pass all desired x_uids in the query string to the TDM endpoint. Instead, we fetch all x_uids for the given split and filter post-hoc.
     * @summary Get Lf Labels
     * @param {DatasetApiGetLfLabelsNodesNodeUidSplitsSplitLfLabelsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet(
      requestParameters: DatasetApiGetLfLabelsNodesNodeUidSplitsSplitLfLabelsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.lfUid,
          requestParameters.contextUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Ngram Data
     * @param {DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNgramDataNodesNodeUidSplitsSplitNGramDataGet(
      requestParameters: DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetNgramDataResponse> {
      return localVarFp
        .getNgramDataNodesNodeUidSplitsSplitNGramDataGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.datasetColumns,
          requestParameters.labels,
          requestParameters.stopWords,
          requestParameters.ngramMin,
          requestParameters.ngramMax,
          requestParameters.minAppearance,
          requestParameters.threshold,
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Studio Load Job Status
     * @param {DatasetApiGetStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet(
      requestParameters: DatasetApiGetStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.jobId,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Load Dataset
     * @param {DatasetApiLoadDatasetNodesNodeUidSplitsSplitLoadDatasetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost(
      requestParameters: DatasetApiLoadDatasetNodesNodeUidSplitsSplitLoadDatasetPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.loadDatasetParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Resample Split
     * @param {DatasetApiResampleSplitNodesNodeUidSplitsSplitResamplePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resampleSplitNodesNodeUidSplitsSplitResamplePost(
      requestParameters: DatasetApiResampleSplitNodesNodeUidSplitsSplitResamplePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .resampleSplitNodesNodeUidSplitsSplitResamplePost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.resampleRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for clearDdfCacheNodesNodeUidClearDdfCacheDelete operation in DatasetApi.
 * @export
 * @interface DatasetApiClearDdfCacheNodesNodeUidClearDdfCacheDeleteRequest
 */
export interface DatasetApiClearDdfCacheNodesNodeUidClearDdfCacheDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiClearDdfCacheNodesNodeUidClearDdfCacheDelete
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for deleteLocalCacheEntriesLocalCacheDelete operation in DatasetApi.
 * @export
 * @interface DatasetApiDeleteLocalCacheEntriesLocalCacheDeleteRequest
 */
export interface DatasetApiDeleteLocalCacheEntriesLocalCacheDeleteRequest {
  /**
   *
   * @type {LocalCacheDeleteParams}
   * @memberof DatasetApiDeleteLocalCacheEntriesLocalCacheDelete
   */
  readonly localCacheDeleteParams: LocalCacheDeleteParams;
}

/**
 * Request parameters for exportBatchesNodesNodeUidExportBatchesGet operation in DatasetApi.
 * @export
 * @interface DatasetApiExportBatchesNodesNodeUidExportBatchesGetRequest
 */
export interface DatasetApiExportBatchesNodesNodeUidExportBatchesGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly filterConfig?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly sort?: string;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly ascending?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly seed?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly offset?: number;

  /**
   * The list of tags that should be included
   * @type {Array<string>}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly includeTagTypeUid?: Array<string>;

  /**
   * The list of tags that should be excluded
   * @type {Array<string>}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly excludeTagTypeUid?: Array<string>;

  /**
   * The list of slices that should be included
   * @type {Array<string>}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly includeSliceTypeUid?: Array<string>;

  /**
   * The list of slices that should be excluded
   * @type {Array<string>}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly excludeSliceTypeUid?: Array<string>;

  /**
   * List of columns that should be in the export. Default is all columns.
   * @type {Array<string>}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly selectedColumns?: Array<string>;

  /**
   * The associated model
   * @type {Array<number>}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly modelUid?: Array<number>;

  /**
   * The name for the tag column
   * @type {string}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly tagColumn?: string;

  /**
   * The name for the comment column
   * @type {string}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly commentColumn?: string;

  /**
   * The name for the batch name column
   * @type {string}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly batchNameColumn?: string;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly includeAnnotations?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly chunkSize?: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly listseparator?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly sep?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly quotechar?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly escapechar?: string;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly maxCharsPerCell?: number;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiExportBatchesNodesNodeUidExportBatchesGet
   */
  readonly applyPostprocessors?: boolean;
}

/**
 * Request parameters for exportDatasetNodesNodeUidSplitsSplitExportDatasetGet operation in DatasetApi.
 * @export
 * @interface DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGetRequest
 */
export interface DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly nodeUid: number;

  /**
   * The dataset that is queried
   * @type {string}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly filterConfig?: string;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly batchUid?: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly sort?: string;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly ascending?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly seed?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly offset?: number;

  /**
   * The list of tags that should be included
   * @type {Array<string>}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly includeTagTypeUid?: Array<string>;

  /**
   * The list of tags that should be excluded
   * @type {Array<string>}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly excludeTagTypeUid?: Array<string>;

  /**
   * The list of slices that should be included
   * @type {Array<string>}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly includeSliceTypeUid?: Array<string>;

  /**
   * The list of slices that should be excluded
   * @type {Array<string>}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly excludeSliceTypeUid?: Array<string>;

  /**
   * List of columns that should be in the export. Default is all columns.
   * @type {Array<string>}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly selectedColumns?: Array<string>;

  /**
   * The associated model
   * @type {Array<number>}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly modelUid?: Array<number>;

  /**
   * The name for the tag column
   * @type {string}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly tagColumn?: string;

  /**
   * The name for the comment column
   * @type {string}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly commentColumn?: string;

  /**
   * The name for the batch name column
   * @type {string}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly batchNameColumn?: string;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly includeAnnotations?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly chunkSize?: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly listseparator?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly sep?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly quotechar?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly escapechar?: string;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly maxCharsPerCell?: number;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGet
   */
  readonly applyPostprocessors?: boolean;
}

/**
 * Request parameters for exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet operation in DatasetApi.
 * @export
 * @interface DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGetRequest
 */
export interface DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet
   */
  readonly trainingSet: number;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet
   */
  readonly splits: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet
   */
  readonly trainingSetLabels?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet
   */
  readonly predictedProbs?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet
   */
  readonly abstains?: boolean;
}

/**
 * Request parameters for getDataNodesNodeUidSplitsSplitDatasetXUidGet operation in DatasetApi.
 * @export
 * @interface DatasetApiGetDataNodesNodeUidSplitsSplitDatasetXUidGetRequest
 */
export interface DatasetApiGetDataNodesNodeUidSplitsSplitDatasetXUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDataNodesNodeUidSplitsSplitDatasetXUidGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDataNodesNodeUidSplitsSplitDatasetXUidGet
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDataNodesNodeUidSplitsSplitDatasetXUidGet
   */
  readonly xUid: string;
}

/**
 * Request parameters for getDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet operation in DatasetApi.
 * @export
 * @interface DatasetApiGetDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGetRequest
 */
export interface DatasetApiGetDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet
   */
  readonly split: string;
}

/**
 * Request parameters for getDatasetNodesNodeUidSplitsSplitDatasetGet operation in DatasetApi.
 * @export
 * @interface DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGetRequest
 */
export interface DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly filterConfig?: string;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly batchUid?: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly sort?: string;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly multiSort?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly ascending?: boolean;

  /**
   *
   * @type {Array<boolean>}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly multiSortAscending?: Array<boolean>;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly showFilteredFlag?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly seed?: number;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly fetchSpanText?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly limit?: number;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly dynamicPaging?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly dynamicPagingSizeKB?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly offset?: number;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly datasetColumns?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly includeTagTypeUid?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly excludeTagTypeUid?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly includeSliceTypeUid?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly excludeSliceTypeUid?: Array<string>;

  /**
   *
   * @type {Array<number>}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly modelUid?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly trainingSetUid?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly lfUid?: Array<number>;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly findUid?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly tagColumn?: string;

  /**
   * The name of the comment column if passed
   * @type {string}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly commentColumn?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly lfConfig?: string;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly includeAnnotations?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly compress?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly applyPostprocessors?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly findWithinPage?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly returnEmbeddings?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly includeTotalIndex?: boolean;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGet
   */
  readonly requestBody?: Array<string>;
}

/**
 * Request parameters for getDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet operation in DatasetApi.
 * @export
 * @interface DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGetRequest
 */
export interface DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly filterConfig?: string;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly batchUid?: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly sort?: string;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly ascending?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly showFilteredFlag?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly seed?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly offset?: number;

  /**
   *
   * @type {Array<number>}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly modelUid?: Array<number>;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet
   */
  readonly findUid?: string;
}

/**
 * Request parameters for getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet operation in DatasetApi.
 * @export
 * @interface DatasetApiGetLfLabelsNodesNodeUidSplitsSplitLfLabelsGetRequest
 */
export interface DatasetApiGetLfLabelsNodesNodeUidSplitsSplitLfLabelsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiGetLfLabelsNodesNodeUidSplitsSplitLfLabelsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetLfLabelsNodesNodeUidSplitsSplitLfLabelsGet
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetLfLabelsNodesNodeUidSplitsSplitLfLabelsGet
   */
  readonly lfUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetLfLabelsNodesNodeUidSplitsSplitLfLabelsGet
   */
  readonly contextUid: number;
}

/**
 * Request parameters for getNgramDataNodesNodeUidSplitsSplitNGramDataGet operation in DatasetApi.
 * @export
 * @interface DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGetRequest
 */
export interface DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGet
   */
  readonly split: string;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGet
   */
  readonly datasetColumns: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGet
   */
  readonly labels: Array<string>;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGet
   */
  readonly stopWords?: string;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGet
   */
  readonly ngramMin?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGet
   */
  readonly ngramMax?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGet
   */
  readonly minAppearance?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGet
   */
  readonly threshold?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGet
   */
  readonly offset?: number;
}

/**
 * Request parameters for getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet operation in DatasetApi.
 * @export
 * @interface DatasetApiGetStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGetRequest
 */
export interface DatasetApiGetStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiGetStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof DatasetApiGetStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet
   */
  readonly jobId: string;
}

/**
 * Request parameters for loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost operation in DatasetApi.
 * @export
 * @interface DatasetApiLoadDatasetNodesNodeUidSplitsSplitLoadDatasetPostRequest
 */
export interface DatasetApiLoadDatasetNodesNodeUidSplitsSplitLoadDatasetPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiLoadDatasetNodesNodeUidSplitsSplitLoadDatasetPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiLoadDatasetNodesNodeUidSplitsSplitLoadDatasetPost
   */
  readonly split: string;

  /**
   *
   * @type {LoadDatasetParams}
   * @memberof DatasetApiLoadDatasetNodesNodeUidSplitsSplitLoadDatasetPost
   */
  readonly loadDatasetParams: LoadDatasetParams;
}

/**
 * Request parameters for resampleSplitNodesNodeUidSplitsSplitResamplePost operation in DatasetApi.
 * @export
 * @interface DatasetApiResampleSplitNodesNodeUidSplitsSplitResamplePostRequest
 */
export interface DatasetApiResampleSplitNodesNodeUidSplitsSplitResamplePostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetApiResampleSplitNodesNodeUidSplitsSplitResamplePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetApiResampleSplitNodesNodeUidSplitsSplitResamplePost
   */
  readonly split: string;

  /**
   *
   * @type {ResampleRequestBody}
   * @memberof DatasetApiResampleSplitNodesNodeUidSplitsSplitResamplePost
   */
  readonly resampleRequestBody: ResampleRequestBody;
}

/**
 * DatasetApi - object-oriented interface
 * @export
 * @class DatasetApi
 * @extends {BaseAPI}
 */
export class DatasetApi extends BaseAPI {
  /**
   *
   * @summary Clear Ddf Cache
   * @param {DatasetApiClearDdfCacheNodesNodeUidClearDdfCacheDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public clearDdfCacheNodesNodeUidClearDdfCacheDelete(
    requestParameters: DatasetApiClearDdfCacheNodesNodeUidClearDdfCacheDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .clearDdfCacheNodesNodeUidClearDdfCacheDelete(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete disk cache entries that haven\'t been accessed for `max_access_age_seconds` seconds.
   * @summary Delete Local Cache Entries
   * @param {DatasetApiDeleteLocalCacheEntriesLocalCacheDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public deleteLocalCacheEntriesLocalCacheDelete(
    requestParameters: DatasetApiDeleteLocalCacheEntriesLocalCacheDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .deleteLocalCacheEntriesLocalCacheDelete(
        requestParameters.localCacheDeleteParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Export the Studio dataset for all batches in CSV format. Aggregates the entire set of batches and exports a merged, de-duplicated dataset in CSV format. CSV results are streamed to the caller.
   * @summary Export Batches
   * @param {DatasetApiExportBatchesNodesNodeUidExportBatchesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public exportBatchesNodesNodeUidExportBatchesGet(
    requestParameters: DatasetApiExportBatchesNodesNodeUidExportBatchesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .exportBatchesNodesNodeUidExportBatchesGet(
        requestParameters.nodeUid,
        requestParameters.filterConfig,
        requestParameters.sort,
        requestParameters.ascending,
        requestParameters.seed,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.includeTagTypeUid,
        requestParameters.excludeTagTypeUid,
        requestParameters.includeSliceTypeUid,
        requestParameters.excludeSliceTypeUid,
        requestParameters.selectedColumns,
        requestParameters.modelUid,
        requestParameters.tagColumn,
        requestParameters.commentColumn,
        requestParameters.batchNameColumn,
        requestParameters.includeAnnotations,
        requestParameters.chunkSize,
        requestParameters.listseparator,
        requestParameters.sep,
        requestParameters.quotechar,
        requestParameters.escapechar,
        requestParameters.maxCharsPerCell,
        requestParameters.applyPostprocessors,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Export the current StudioDataset in CSV format Take the given filters and use them to determine a view of the selected Studio dataset. Export that dataset in CSV format, optionally including tags and comments. CSV results are streamed to the caller.
   * @summary Export Dataset
   * @param {DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public exportDatasetNodesNodeUidSplitsSplitExportDatasetGet(
    requestParameters: DatasetApiExportDatasetNodesNodeUidSplitsSplitExportDatasetGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .exportDatasetNodesNodeUidSplitsSplitExportDatasetGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.filterConfig,
        requestParameters.batchUid,
        requestParameters.sort,
        requestParameters.ascending,
        requestParameters.seed,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.includeTagTypeUid,
        requestParameters.excludeTagTypeUid,
        requestParameters.includeSliceTypeUid,
        requestParameters.excludeSliceTypeUid,
        requestParameters.selectedColumns,
        requestParameters.modelUid,
        requestParameters.tagColumn,
        requestParameters.commentColumn,
        requestParameters.batchNameColumn,
        requestParameters.includeAnnotations,
        requestParameters.chunkSize,
        requestParameters.listseparator,
        requestParameters.sep,
        requestParameters.quotechar,
        requestParameters.escapechar,
        requestParameters.maxCharsPerCell,
        requestParameters.applyPostprocessors,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Export the given training set in CSV format
   * @summary Export Training Set
   * @param {DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet(
    requestParameters: DatasetApiExportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .exportTrainingSetNodesNodeUidTrainingSetsTrainingSetExportTrainingSetGet(
        requestParameters.nodeUid,
        requestParameters.trainingSet,
        requestParameters.splits,
        requestParameters.trainingSetLabels,
        requestParameters.predictedProbs,
        requestParameters.abstains,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Data
   * @param {DatasetApiGetDataNodesNodeUidSplitsSplitDatasetXUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public getDataNodesNodeUidSplitsSplitDatasetXUidGet(
    requestParameters: DatasetApiGetDataNodesNodeUidSplitsSplitDatasetXUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .getDataNodesNodeUidSplitsSplitDatasetXUidGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.xUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Dataset Fields
   * @param {DatasetApiGetDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public getDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet(
    requestParameters: DatasetApiGetDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .getDatasetFieldsNodesNodeUidSplitsSplitDatasetFieldsGet(
        requestParameters.nodeUid,
        requestParameters.split,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Return dataset and associated metadata.
   * @summary Get Dataset
   * @param {DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public getDatasetNodesNodeUidSplitsSplitDatasetGet(
    requestParameters: DatasetApiGetDatasetNodesNodeUidSplitsSplitDatasetGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .getDatasetNodesNodeUidSplitsSplitDatasetGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.filterConfig,
        requestParameters.batchUid,
        requestParameters.sort,
        requestParameters.multiSort,
        requestParameters.ascending,
        requestParameters.multiSortAscending,
        requestParameters.showFilteredFlag,
        requestParameters.seed,
        requestParameters.fetchSpanText,
        requestParameters.limit,
        requestParameters.dynamicPaging,
        requestParameters.dynamicPagingSizeKB,
        requestParameters.offset,
        requestParameters.datasetColumns,
        requestParameters.includeTagTypeUid,
        requestParameters.excludeTagTypeUid,
        requestParameters.includeSliceTypeUid,
        requestParameters.excludeSliceTypeUid,
        requestParameters.modelUid,
        requestParameters.trainingSetUid,
        requestParameters.lfUid,
        requestParameters.findUid,
        requestParameters.tagColumn,
        requestParameters.commentColumn,
        requestParameters.lfConfig,
        requestParameters.includeAnnotations,
        requestParameters.compress,
        requestParameters.applyPostprocessors,
        requestParameters.findWithinPage,
        requestParameters.returnEmbeddings,
        requestParameters.includeTotalIndex,
        requestParameters.requestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Dataset Uids
   * @param {DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public getDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet(
    requestParameters: DatasetApiGetDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .getDatasetUidsNodesNodeUidSplitsSplitDatasetUidsGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.filterConfig,
        requestParameters.batchUid,
        requestParameters.sort,
        requestParameters.ascending,
        requestParameters.showFilteredFlag,
        requestParameters.seed,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.modelUid,
        requestParameters.findUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Return all non-abstain votes for the given split, lf_uid, and context_uid Note: One purpose for this method is to fetch all the LF votes for a given context (e.g., to display LF votes in doc mode). There is a limit on how long a query string can be, so we can\'t pass all desired x_uids in the query string to the TDM endpoint. Instead, we fetch all x_uids for the given split and filter post-hoc.
   * @summary Get Lf Labels
   * @param {DatasetApiGetLfLabelsNodesNodeUidSplitsSplitLfLabelsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet(
    requestParameters: DatasetApiGetLfLabelsNodesNodeUidSplitsSplitLfLabelsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .getLfLabelsNodesNodeUidSplitsSplitLfLabelsGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.lfUid,
        requestParameters.contextUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Ngram Data
   * @param {DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public getNgramDataNodesNodeUidSplitsSplitNGramDataGet(
    requestParameters: DatasetApiGetNgramDataNodesNodeUidSplitsSplitNGramDataGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .getNgramDataNodesNodeUidSplitsSplitNGramDataGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.datasetColumns,
        requestParameters.labels,
        requestParameters.stopWords,
        requestParameters.ngramMin,
        requestParameters.ngramMax,
        requestParameters.minAppearance,
        requestParameters.threshold,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Studio Load Job Status
   * @param {DatasetApiGetStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet(
    requestParameters: DatasetApiGetStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .getStudioLoadJobStatusNodesNodeUidSplitsSplitStudioLoadingJobsJobIdGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.jobId,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Load Dataset
   * @param {DatasetApiLoadDatasetNodesNodeUidSplitsSplitLoadDatasetPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost(
    requestParameters: DatasetApiLoadDatasetNodesNodeUidSplitsSplitLoadDatasetPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .loadDatasetNodesNodeUidSplitsSplitLoadDatasetPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.loadDatasetParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Resample Split
   * @param {DatasetApiResampleSplitNodesNodeUidSplitsSplitResamplePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetApi
   */
  public resampleSplitNodesNodeUidSplitsSplitResamplePost(
    requestParameters: DatasetApiResampleSplitNodesNodeUidSplitsSplitResamplePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetApiFp(this.configuration)
      .resampleSplitNodesNodeUidSplitsSplitResamplePost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.resampleRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DownloadApi - axios parameter creator
 * @export
 */
export const DownloadApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Downloads a file. Optionally, can return only certain pages (only for PDFs).
     * @summary Download File
     * @param {string} filepath
     * @param {Array<number>} [pdfPages]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFileDownloadGet: async (
      filepath: string,
      pdfPages?: Array<number>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'filepath' is not null or undefined
      assertParamExists('downloadFileDownloadGet', 'filepath', filepath);
      const localVarPath = `/download`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filepath !== undefined) {
        localVarQueryParameter['filepath'] = filepath;
      }

      if (pdfPages) {
        localVarQueryParameter['pdf_pages'] = pdfPages;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DownloadApi - functional programming interface
 * @export
 */
export const DownloadApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DownloadApiAxiosParamCreator(configuration);
  return {
    /**
     * Downloads a file. Optionally, can return only certain pages (only for PDFs).
     * @summary Download File
     * @param {string} filepath
     * @param {Array<number>} [pdfPages]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadFileDownloadGet(
      filepath: string,
      pdfPages?: Array<number>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.downloadFileDownloadGet(
          filepath,
          pdfPages,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DownloadApi.downloadFileDownloadGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DownloadApi - factory interface
 * @export
 */
export const DownloadApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DownloadApiFp(configuration);
  return {
    /**
     * Downloads a file. Optionally, can return only certain pages (only for PDFs).
     * @summary Download File
     * @param {DownloadApiDownloadFileDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFileDownloadGet(
      requestParameters: DownloadApiDownloadFileDownloadGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .downloadFileDownloadGet(
          requestParameters.filepath,
          requestParameters.pdfPages,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for downloadFileDownloadGet operation in DownloadApi.
 * @export
 * @interface DownloadApiDownloadFileDownloadGetRequest
 */
export interface DownloadApiDownloadFileDownloadGetRequest {
  /**
   *
   * @type {string}
   * @memberof DownloadApiDownloadFileDownloadGet
   */
  readonly filepath: string;

  /**
   *
   * @type {Array<number>}
   * @memberof DownloadApiDownloadFileDownloadGet
   */
  readonly pdfPages?: Array<number>;
}

/**
 * DownloadApi - object-oriented interface
 * @export
 * @class DownloadApi
 * @extends {BaseAPI}
 */
export class DownloadApi extends BaseAPI {
  /**
   * Downloads a file. Optionally, can return only certain pages (only for PDFs).
   * @summary Download File
   * @param {DownloadApiDownloadFileDownloadGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DownloadApi
   */
  public downloadFileDownloadGet(
    requestParameters: DownloadApiDownloadFileDownloadGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DownloadApiFp(this.configuration)
      .downloadFileDownloadGet(
        requestParameters.filepath,
        requestParameters.pdfPages,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FiltersApi - axios parameter creator
 * @export
 */
export const FiltersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Filter To Batch
     * @param {number} nodeUid
     * @param {string} split
     * @param {FilterToBatchPayload} filterToBatchPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost: async (
      nodeUid: number,
      split: string,
      filterToBatchPayload: FilterToBatchPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost',
        'split',
        split,
      );
      // verify required parameter 'filterToBatchPayload' is not null or undefined
      assertParamExists(
        'filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost',
        'filterToBatchPayload',
        filterToBatchPayload,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/filter-to-batch`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        filterToBatchPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filter To Tags
     * @param {number} nodeUid
     * @param {string} split
     * @param {FilterToTagsPayload} filterToTagsPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost: async (
      nodeUid: number,
      split: string,
      filterToTagsPayload: FilterToTagsPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost',
        'split',
        split,
      );
      // verify required parameter 'filterToTagsPayload' is not null or undefined
      assertParamExists(
        'filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost',
        'filterToTagsPayload',
        filterToTagsPayload,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/filter-to-tags`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        filterToTagsPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return all static information about Filter types and their properties.
     * @summary Get Populated Filter Info
     * @param {number} nodeUid
     * @param {string} split
     * @param {boolean} isAnnotationMode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet:
      async (
        nodeUid: number,
        split: string,
        isAnnotationMode: boolean,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet',
          'split',
          split,
        );
        // verify required parameter 'isAnnotationMode' is not null or undefined
        assertParamExists(
          'getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet',
          'isAnnotationMode',
          isAnnotationMode,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/populated-filters-info`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (isAnnotationMode !== undefined) {
          localVarQueryParameter['is_annotation_mode'] = isAnnotationMode;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * FiltersApi - functional programming interface
 * @export
 */
export const FiltersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FiltersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Filter To Batch
     * @param {number} nodeUid
     * @param {string} split
     * @param {FilterToBatchPayload} filterToBatchPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost(
      nodeUid: number,
      split: string,
      filterToBatchPayload: FilterToBatchPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FilterToBatchModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost(
          nodeUid,
          split,
          filterToBatchPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FiltersApi.filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Filter To Tags
     * @param {number} nodeUid
     * @param {string} split
     * @param {FilterToTagsPayload} filterToTagsPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost(
      nodeUid: number,
      split: string,
      filterToTagsPayload: FilterToTagsPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FilterToTagsModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost(
          nodeUid,
          split,
          filterToTagsPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FiltersApi.filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return all static information about Filter types and their properties.
     * @summary Get Populated Filter Info
     * @param {number} nodeUid
     * @param {string} split
     * @param {boolean} isAnnotationMode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet(
      nodeUid: number,
      split: string,
      isAnnotationMode: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FetchFilterInfoModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet(
          nodeUid,
          split,
          isAnnotationMode,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FiltersApi.getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FiltersApi - factory interface
 * @export
 */
export const FiltersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FiltersApiFp(configuration);
  return {
    /**
     *
     * @summary Filter To Batch
     * @param {FiltersApiFilterToBatchNodesNodeUidSplitsSplitFilterToBatchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost(
      requestParameters: FiltersApiFilterToBatchNodesNodeUidSplitsSplitFilterToBatchPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FilterToBatchModel> {
      return localVarFp
        .filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.filterToBatchPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Filter To Tags
     * @param {FiltersApiFilterToTagsNodesNodeUidSplitsSplitFilterToTagsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost(
      requestParameters: FiltersApiFilterToTagsNodesNodeUidSplitsSplitFilterToTagsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FilterToTagsModel> {
      return localVarFp
        .filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.filterToTagsPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Return all static information about Filter types and their properties.
     * @summary Get Populated Filter Info
     * @param {FiltersApiGetPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet(
      requestParameters: FiltersApiGetPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FetchFilterInfoModel> {
      return localVarFp
        .getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.isAnnotationMode,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost operation in FiltersApi.
 * @export
 * @interface FiltersApiFilterToBatchNodesNodeUidSplitsSplitFilterToBatchPostRequest
 */
export interface FiltersApiFilterToBatchNodesNodeUidSplitsSplitFilterToBatchPostRequest {
  /**
   *
   * @type {number}
   * @memberof FiltersApiFilterToBatchNodesNodeUidSplitsSplitFilterToBatchPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof FiltersApiFilterToBatchNodesNodeUidSplitsSplitFilterToBatchPost
   */
  readonly split: string;

  /**
   *
   * @type {FilterToBatchPayload}
   * @memberof FiltersApiFilterToBatchNodesNodeUidSplitsSplitFilterToBatchPost
   */
  readonly filterToBatchPayload: FilterToBatchPayload;
}

/**
 * Request parameters for filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost operation in FiltersApi.
 * @export
 * @interface FiltersApiFilterToTagsNodesNodeUidSplitsSplitFilterToTagsPostRequest
 */
export interface FiltersApiFilterToTagsNodesNodeUidSplitsSplitFilterToTagsPostRequest {
  /**
   *
   * @type {number}
   * @memberof FiltersApiFilterToTagsNodesNodeUidSplitsSplitFilterToTagsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof FiltersApiFilterToTagsNodesNodeUidSplitsSplitFilterToTagsPost
   */
  readonly split: string;

  /**
   *
   * @type {FilterToTagsPayload}
   * @memberof FiltersApiFilterToTagsNodesNodeUidSplitsSplitFilterToTagsPost
   */
  readonly filterToTagsPayload: FilterToTagsPayload;
}

/**
 * Request parameters for getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet operation in FiltersApi.
 * @export
 * @interface FiltersApiGetPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGetRequest
 */
export interface FiltersApiGetPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGetRequest {
  /**
   *
   * @type {number}
   * @memberof FiltersApiGetPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof FiltersApiGetPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet
   */
  readonly split: string;

  /**
   *
   * @type {boolean}
   * @memberof FiltersApiGetPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet
   */
  readonly isAnnotationMode: boolean;
}

/**
 * FiltersApi - object-oriented interface
 * @export
 * @class FiltersApi
 * @extends {BaseAPI}
 */
export class FiltersApi extends BaseAPI {
  /**
   *
   * @summary Filter To Batch
   * @param {FiltersApiFilterToBatchNodesNodeUidSplitsSplitFilterToBatchPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FiltersApi
   */
  public filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost(
    requestParameters: FiltersApiFilterToBatchNodesNodeUidSplitsSplitFilterToBatchPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FiltersApiFp(this.configuration)
      .filterToBatchNodesNodeUidSplitsSplitFilterToBatchPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.filterToBatchPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filter To Tags
   * @param {FiltersApiFilterToTagsNodesNodeUidSplitsSplitFilterToTagsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FiltersApi
   */
  public filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost(
    requestParameters: FiltersApiFilterToTagsNodesNodeUidSplitsSplitFilterToTagsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FiltersApiFp(this.configuration)
      .filterToTagsNodesNodeUidSplitsSplitFilterToTagsPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.filterToTagsPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Return all static information about Filter types and their properties.
   * @summary Get Populated Filter Info
   * @param {FiltersApiGetPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FiltersApi
   */
  public getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet(
    requestParameters: FiltersApiGetPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FiltersApiFp(this.configuration)
      .getPopulatedFilterInfoNodesNodeUidSplitsSplitPopulatedFiltersInfoGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.isAnnotationMode,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * HighlightApi - axios parameter creator
 * @export
 */
export const HighlightApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * See LFHighlighter for more details. This will return a dict containing, 2 keys, plus a 3rd optional key:     \"template_spans\": representing what each template passed in         template_configs would highlight. This comes as a List of         List[HighlightedSpans]. This should be the same length         as template_configs.     \"aggregate_spans\", representing what the LF would highlight. This         uses the passed graph to calculate some combination of         templates.
     * @summary Apply Highlighter
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} xUid
     * @param {HighlightRequestBody} highlightRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost: async (
      nodeUid: number,
      split: string,
      xUid: string,
      highlightRequestBody: HighlightRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost',
        'split',
        split,
      );
      // verify required parameter 'xUid' is not null or undefined
      assertParamExists(
        'applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost',
        'xUid',
        xUid,
      );
      // verify required parameter 'highlightRequestBody' is not null or undefined
      assertParamExists(
        'applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost',
        'highlightRequestBody',
        highlightRequestBody,
      );
      const localVarPath =
        `/nodes/{node_uid}/splits/{split}/dataset/{x_uid}/highlight`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(`{${'split'}}`, encodeURIComponent(String(split)))
          .replace(`{${'x_uid'}}`, encodeURIComponent(String(xUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        highlightRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Apply Highlighters Given Lfs
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} xUid
     * @param {HighlightsRequestBody} highlightsRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost:
      async (
        nodeUid: number,
        split: string,
        xUid: string,
        highlightsRequestBody: HighlightsRequestBody,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost',
          'split',
          split,
        );
        // verify required parameter 'xUid' is not null or undefined
        assertParamExists(
          'applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost',
          'xUid',
          xUid,
        );
        // verify required parameter 'highlightsRequestBody' is not null or undefined
        assertParamExists(
          'applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost',
          'highlightsRequestBody',
          highlightsRequestBody,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/dataset/{x_uid}/highlights`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)))
            .replace(`{${'x_uid'}}`, encodeURIComponent(String(xUid)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          highlightsRequestBody,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Fetch Highlight Metadata
     * @param {number} nodeUid
     * @param {HighlightMetadataParams} highlightMetadataParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchHighlightMetadataNodesNodeUidHighlightMetadataPost: async (
      nodeUid: number,
      highlightMetadataParams: HighlightMetadataParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchHighlightMetadataNodesNodeUidHighlightMetadataPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'highlightMetadataParams' is not null or undefined
      assertParamExists(
        'fetchHighlightMetadataNodesNodeUidHighlightMetadataPost',
        'highlightMetadataParams',
        highlightMetadataParams,
      );
      const localVarPath = `/nodes/{node_uid}/highlight-metadata`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        highlightMetadataParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HighlightApi - functional programming interface
 * @export
 */
export const HighlightApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    HighlightApiAxiosParamCreator(configuration);
  return {
    /**
     * See LFHighlighter for more details. This will return a dict containing, 2 keys, plus a 3rd optional key:     \"template_spans\": representing what each template passed in         template_configs would highlight. This comes as a List of         List[HighlightedSpans]. This should be the same length         as template_configs.     \"aggregate_spans\", representing what the LF would highlight. This         uses the passed graph to calculate some combination of         templates.
     * @summary Apply Highlighter
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} xUid
     * @param {HighlightRequestBody} highlightRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost(
      nodeUid: number,
      split: string,
      xUid: string,
      highlightRequestBody: HighlightRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost(
          nodeUid,
          split,
          xUid,
          highlightRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'HighlightApi.applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Apply Highlighters Given Lfs
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} xUid
     * @param {HighlightsRequestBody} highlightsRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost(
      nodeUid: number,
      split: string,
      xUid: string,
      highlightsRequestBody: HighlightsRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: LFHighlight }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost(
          nodeUid,
          split,
          xUid,
          highlightsRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'HighlightApi.applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Highlight Metadata
     * @param {number} nodeUid
     * @param {HighlightMetadataParams} highlightMetadataParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchHighlightMetadataNodesNodeUidHighlightMetadataPost(
      nodeUid: number,
      highlightMetadataParams: HighlightMetadataParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HighlightMetadataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchHighlightMetadataNodesNodeUidHighlightMetadataPost(
          nodeUid,
          highlightMetadataParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'HighlightApi.fetchHighlightMetadataNodesNodeUidHighlightMetadataPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * HighlightApi - factory interface
 * @export
 */
export const HighlightApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HighlightApiFp(configuration);
  return {
    /**
     * See LFHighlighter for more details. This will return a dict containing, 2 keys, plus a 3rd optional key:     \"template_spans\": representing what each template passed in         template_configs would highlight. This comes as a List of         List[HighlightedSpans]. This should be the same length         as template_configs.     \"aggregate_spans\", representing what the LF would highlight. This         uses the passed graph to calculate some combination of         templates.
     * @summary Apply Highlighter
     * @param {HighlightApiApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost(
      requestParameters: HighlightApiApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<object> {
      return localVarFp
        .applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.xUid,
          requestParameters.highlightRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Apply Highlighters Given Lfs
     * @param {HighlightApiApplyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost(
      requestParameters: HighlightApiApplyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: LFHighlight }> {
      return localVarFp
        .applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.xUid,
          requestParameters.highlightsRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Highlight Metadata
     * @param {HighlightApiFetchHighlightMetadataNodesNodeUidHighlightMetadataPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchHighlightMetadataNodesNodeUidHighlightMetadataPost(
      requestParameters: HighlightApiFetchHighlightMetadataNodesNodeUidHighlightMetadataPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HighlightMetadataResponse> {
      return localVarFp
        .fetchHighlightMetadataNodesNodeUidHighlightMetadataPost(
          requestParameters.nodeUid,
          requestParameters.highlightMetadataParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost operation in HighlightApi.
 * @export
 * @interface HighlightApiApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPostRequest
 */
export interface HighlightApiApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPostRequest {
  /**
   *
   * @type {number}
   * @memberof HighlightApiApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof HighlightApiApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof HighlightApiApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost
   */
  readonly xUid: string;

  /**
   *
   * @type {HighlightRequestBody}
   * @memberof HighlightApiApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost
   */
  readonly highlightRequestBody: HighlightRequestBody;
}

/**
 * Request parameters for applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost operation in HighlightApi.
 * @export
 * @interface HighlightApiApplyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPostRequest
 */
export interface HighlightApiApplyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPostRequest {
  /**
   *
   * @type {number}
   * @memberof HighlightApiApplyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof HighlightApiApplyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof HighlightApiApplyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost
   */
  readonly xUid: string;

  /**
   *
   * @type {HighlightsRequestBody}
   * @memberof HighlightApiApplyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost
   */
  readonly highlightsRequestBody: HighlightsRequestBody;
}

/**
 * Request parameters for fetchHighlightMetadataNodesNodeUidHighlightMetadataPost operation in HighlightApi.
 * @export
 * @interface HighlightApiFetchHighlightMetadataNodesNodeUidHighlightMetadataPostRequest
 */
export interface HighlightApiFetchHighlightMetadataNodesNodeUidHighlightMetadataPostRequest {
  /**
   *
   * @type {number}
   * @memberof HighlightApiFetchHighlightMetadataNodesNodeUidHighlightMetadataPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {HighlightMetadataParams}
   * @memberof HighlightApiFetchHighlightMetadataNodesNodeUidHighlightMetadataPost
   */
  readonly highlightMetadataParams: HighlightMetadataParams;
}

/**
 * HighlightApi - object-oriented interface
 * @export
 * @class HighlightApi
 * @extends {BaseAPI}
 */
export class HighlightApi extends BaseAPI {
  /**
   * See LFHighlighter for more details. This will return a dict containing, 2 keys, plus a 3rd optional key:     \"template_spans\": representing what each template passed in         template_configs would highlight. This comes as a List of         List[HighlightedSpans]. This should be the same length         as template_configs.     \"aggregate_spans\", representing what the LF would highlight. This         uses the passed graph to calculate some combination of         templates.
   * @summary Apply Highlighter
   * @param {HighlightApiApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HighlightApi
   */
  public applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost(
    requestParameters: HighlightApiApplyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return HighlightApiFp(this.configuration)
      .applyHighlighterNodesNodeUidSplitsSplitDatasetXUidHighlightPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.xUid,
        requestParameters.highlightRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Apply Highlighters Given Lfs
   * @param {HighlightApiApplyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HighlightApi
   */
  public applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost(
    requestParameters: HighlightApiApplyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return HighlightApiFp(this.configuration)
      .applyHighlightersGivenLfsNodesNodeUidSplitsSplitDatasetXUidHighlightsPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.xUid,
        requestParameters.highlightsRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Highlight Metadata
   * @param {HighlightApiFetchHighlightMetadataNodesNodeUidHighlightMetadataPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HighlightApi
   */
  public fetchHighlightMetadataNodesNodeUidHighlightMetadataPost(
    requestParameters: HighlightApiFetchHighlightMetadataNodesNodeUidHighlightMetadataPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return HighlightApiFp(this.configuration)
      .fetchHighlightMetadataNodesNodeUidHighlightMetadataPost(
        requestParameters.nodeUid,
        requestParameters.highlightMetadataParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * IndexApi - axios parameter creator
 * @export
 */
export const IndexApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Hello World
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHelloWorldGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPingPingGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/ping`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * IndexApi - functional programming interface
 * @export
 */
export const IndexApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = IndexApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Hello World
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHelloWorldGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getHelloWorldGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['IndexApi.getHelloWorldGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPingPingGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPingPingGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['IndexApi.getPingPingGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * IndexApi - factory interface
 * @export
 */
export const IndexApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = IndexApiFp(configuration);
  return {
    /**
     *
     * @summary Get Hello World
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHelloWorldGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
      return localVarFp
        .getHelloWorldGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPingPingGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
      return localVarFp
        .getPingPingGet(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * IndexApi - object-oriented interface
 * @export
 * @class IndexApi
 * @extends {BaseAPI}
 */
export class IndexApi extends BaseAPI {
  /**
   *
   * @summary Get Hello World
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public getHelloWorldGet(options?: RawAxiosRequestConfig) {
    return IndexApiFp(this.configuration)
      .getHelloWorldGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Ping
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexApi
   */
  public getPingPingGet(options?: RawAxiosRequestConfig) {
    return IndexApiFp(this.configuration)
      .getPingPingGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Cancel Job
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelJobJobsJobUidCancelPost: async (
      jobUid: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobUid' is not null or undefined
      assertParamExists('cancelJobJobsJobUidCancelPost', 'jobUid', jobUid);
      const localVarPath = `/jobs/{job_uid}/cancel`.replace(
        `{${'job_uid'}}`,
        encodeURIComponent(String(jobUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Job
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteJobJobsJobUidDeletePost: async (
      jobUid: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobUid' is not null or undefined
      assertParamExists('deleteJobJobsJobUidDeletePost', 'jobUid', jobUid);
      const localVarPath = `/jobs/{job_uid}/delete`.replace(
        `{${'job_uid'}}`,
        encodeURIComponent(String(jobUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Job For Uid
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobForUidJobsJobUidGet: async (
      jobUid: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobUid' is not null or undefined
      assertParamExists('getJobForUidJobsJobUidGet', 'jobUid', jobUid);
      const localVarPath = `/jobs/{job_uid}`.replace(
        `{${'job_uid'}}`,
        encodeURIComponent(String(jobUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Jobs
     * @param {JobType} [jobType]
     * @param {number} [datasetId]
     * @param {number} [taskId]
     * @param {number} [workspaceId]
     * @param {JobState} [state]
     * @param {number} [startTime]
     * @param {string} [direction]
     * @param {number} [limit]
     * @param {boolean} [details]
     * @param {number} [userUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listJobsJobsGet: async (
      jobType?: JobType,
      datasetId?: number,
      taskId?: number,
      workspaceId?: number,
      state?: JobState,
      startTime?: number,
      direction?: string,
      limit?: number,
      details?: boolean,
      userUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/jobs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (jobType !== undefined) {
        localVarQueryParameter['job_type'] = jobType;
      }

      if (datasetId !== undefined) {
        localVarQueryParameter['dataset_id'] = datasetId;
      }

      if (taskId !== undefined) {
        localVarQueryParameter['task_id'] = taskId;
      }

      if (workspaceId !== undefined) {
        localVarQueryParameter['workspace_id'] = workspaceId;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      if (startTime !== undefined) {
        localVarQueryParameter['start_time'] = startTime;
      }

      if (direction !== undefined) {
        localVarQueryParameter['direction'] = direction;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (details !== undefined) {
        localVarQueryParameter['details'] = details;
      }

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Cancel Job
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelJobJobsJobUidCancelPost(
      jobUid: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelJobJobsJobUidCancelPost(
          jobUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['JobsApi.cancelJobJobsJobUidCancelPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Job
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteJobJobsJobUidDeletePost(
      jobUid: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteJobJobsJobUidDeletePost(
          jobUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['JobsApi.deleteJobJobsJobUidDeletePost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Job For Uid
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJobForUidJobsJobUidGet(
      jobUid: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getJobForUidJobsJobUidGet(
          jobUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['JobsApi.getJobForUidJobsJobUidGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Jobs
     * @param {JobType} [jobType]
     * @param {number} [datasetId]
     * @param {number} [taskId]
     * @param {number} [workspaceId]
     * @param {JobState} [state]
     * @param {number} [startTime]
     * @param {string} [direction]
     * @param {number} [limit]
     * @param {boolean} [details]
     * @param {number} [userUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listJobsJobsGet(
      jobType?: JobType,
      datasetId?: number,
      taskId?: number,
      workspaceId?: number,
      state?: JobState,
      startTime?: number,
      direction?: string,
      limit?: number,
      details?: boolean,
      userUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listJobsJobsGet(
        jobType,
        datasetId,
        taskId,
        workspaceId,
        state,
        startTime,
        direction,
        limit,
        details,
        userUid,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['JobsApi.listJobsJobsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = JobsApiFp(configuration);
  return {
    /**
     *
     * @summary Cancel Job
     * @param {JobsApiCancelJobJobsJobUidCancelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelJobJobsJobUidCancelPost(
      requestParameters: JobsApiCancelJobJobsJobUidCancelPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .cancelJobJobsJobUidCancelPost(requestParameters.jobUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Job
     * @param {JobsApiDeleteJobJobsJobUidDeletePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteJobJobsJobUidDeletePost(
      requestParameters: JobsApiDeleteJobJobsJobUidDeletePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteJobJobsJobUidDeletePost(requestParameters.jobUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Job For Uid
     * @param {JobsApiGetJobForUidJobsJobUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobForUidJobsJobUidGet(
      requestParameters: JobsApiGetJobForUidJobsJobUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<JobInfo> {
      return localVarFp
        .getJobForUidJobsJobUidGet(requestParameters.jobUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Jobs
     * @param {JobsApiListJobsJobsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listJobsJobsGet(
      requestParameters: JobsApiListJobsJobsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<JobListResponse> {
      return localVarFp
        .listJobsJobsGet(
          requestParameters.jobType,
          requestParameters.datasetId,
          requestParameters.taskId,
          requestParameters.workspaceId,
          requestParameters.state,
          requestParameters.startTime,
          requestParameters.direction,
          requestParameters.limit,
          requestParameters.details,
          requestParameters.userUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelJobJobsJobUidCancelPost operation in JobsApi.
 * @export
 * @interface JobsApiCancelJobJobsJobUidCancelPostRequest
 */
export interface JobsApiCancelJobJobsJobUidCancelPostRequest {
  /**
   *
   * @type {string}
   * @memberof JobsApiCancelJobJobsJobUidCancelPost
   */
  readonly jobUid: string;
}

/**
 * Request parameters for deleteJobJobsJobUidDeletePost operation in JobsApi.
 * @export
 * @interface JobsApiDeleteJobJobsJobUidDeletePostRequest
 */
export interface JobsApiDeleteJobJobsJobUidDeletePostRequest {
  /**
   *
   * @type {string}
   * @memberof JobsApiDeleteJobJobsJobUidDeletePost
   */
  readonly jobUid: string;
}

/**
 * Request parameters for getJobForUidJobsJobUidGet operation in JobsApi.
 * @export
 * @interface JobsApiGetJobForUidJobsJobUidGetRequest
 */
export interface JobsApiGetJobForUidJobsJobUidGetRequest {
  /**
   *
   * @type {string}
   * @memberof JobsApiGetJobForUidJobsJobUidGet
   */
  readonly jobUid: string;
}

/**
 * Request parameters for listJobsJobsGet operation in JobsApi.
 * @export
 * @interface JobsApiListJobsJobsGetRequest
 */
export interface JobsApiListJobsJobsGetRequest {
  /**
   *
   * @type {JobType}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly jobType?: JobType;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly datasetId?: number;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly taskId?: number;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly workspaceId?: number;

  /**
   *
   * @type {JobState}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly state?: JobState;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly startTime?: number;

  /**
   *
   * @type {string}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly direction?: string;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly limit?: number;

  /**
   *
   * @type {boolean}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly details?: boolean;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly userUid?: number;
}

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
  /**
   *
   * @summary Cancel Job
   * @param {JobsApiCancelJobJobsJobUidCancelPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public cancelJobJobsJobUidCancelPost(
    requestParameters: JobsApiCancelJobJobsJobUidCancelPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .cancelJobJobsJobUidCancelPost(requestParameters.jobUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Job
   * @param {JobsApiDeleteJobJobsJobUidDeletePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public deleteJobJobsJobUidDeletePost(
    requestParameters: JobsApiDeleteJobJobsJobUidDeletePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .deleteJobJobsJobUidDeletePost(requestParameters.jobUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Job For Uid
   * @param {JobsApiGetJobForUidJobsJobUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getJobForUidJobsJobUidGet(
    requestParameters: JobsApiGetJobForUidJobsJobUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .getJobForUidJobsJobUidGet(requestParameters.jobUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Jobs
   * @param {JobsApiListJobsJobsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public listJobsJobsGet(
    requestParameters: JobsApiListJobsJobsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .listJobsJobsGet(
        requestParameters.jobType,
        requestParameters.datasetId,
        requestParameters.taskId,
        requestParameters.workspaceId,
        requestParameters.state,
        requestParameters.startTime,
        requestParameters.direction,
        requestParameters.limit,
        requestParameters.details,
        requestParameters.userUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LabelsApi - axios parameter creator
 * @export
 */
export const LabelsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Updates a \"ground_truth\" label for a particular individual datapoint.
     * @summary Update Labels
     * @param {number} nodeUid
     * @param {string} split
     * @param {LabelsRequestBody} labelsRequestBody
     * @param {string} [sort]
     * @param {number} [seed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabelsNodesNodeUidSplitsSplitLabelsPut: async (
      nodeUid: number,
      split: string,
      labelsRequestBody: LabelsRequestBody,
      sort?: string,
      seed?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'updateLabelsNodesNodeUidSplitsSplitLabelsPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'updateLabelsNodesNodeUidSplitsSplitLabelsPut',
        'split',
        split,
      );
      // verify required parameter 'labelsRequestBody' is not null or undefined
      assertParamExists(
        'updateLabelsNodesNodeUidSplitsSplitLabelsPut',
        'labelsRequestBody',
        labelsRequestBody,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/labels`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (seed !== undefined) {
        localVarQueryParameter['seed'] = seed;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        labelsRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LabelsApi - functional programming interface
 * @export
 */
export const LabelsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LabelsApiAxiosParamCreator(configuration);
  return {
    /**
     * Updates a \"ground_truth\" label for a particular individual datapoint.
     * @summary Update Labels
     * @param {number} nodeUid
     * @param {string} split
     * @param {LabelsRequestBody} labelsRequestBody
     * @param {string} [sort]
     * @param {number} [seed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLabelsNodesNodeUidSplitsSplitLabelsPut(
      nodeUid: number,
      split: string,
      labelsRequestBody: LabelsRequestBody,
      sort?: string,
      seed?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateLabelsNodesNodeUidSplitsSplitLabelsPut(
          nodeUid,
          split,
          labelsRequestBody,
          sort,
          seed,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LabelsApi.updateLabelsNodesNodeUidSplitsSplitLabelsPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LabelsApi - factory interface
 * @export
 */
export const LabelsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LabelsApiFp(configuration);
  return {
    /**
     * Updates a \"ground_truth\" label for a particular individual datapoint.
     * @summary Update Labels
     * @param {LabelsApiUpdateLabelsNodesNodeUidSplitsSplitLabelsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabelsNodesNodeUidSplitsSplitLabelsPut(
      requestParameters: LabelsApiUpdateLabelsNodesNodeUidSplitsSplitLabelsPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .updateLabelsNodesNodeUidSplitsSplitLabelsPut(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.labelsRequestBody,
          requestParameters.sort,
          requestParameters.seed,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for updateLabelsNodesNodeUidSplitsSplitLabelsPut operation in LabelsApi.
 * @export
 * @interface LabelsApiUpdateLabelsNodesNodeUidSplitsSplitLabelsPutRequest
 */
export interface LabelsApiUpdateLabelsNodesNodeUidSplitsSplitLabelsPutRequest {
  /**
   *
   * @type {number}
   * @memberof LabelsApiUpdateLabelsNodesNodeUidSplitsSplitLabelsPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof LabelsApiUpdateLabelsNodesNodeUidSplitsSplitLabelsPut
   */
  readonly split: string;

  /**
   *
   * @type {LabelsRequestBody}
   * @memberof LabelsApiUpdateLabelsNodesNodeUidSplitsSplitLabelsPut
   */
  readonly labelsRequestBody: LabelsRequestBody;

  /**
   *
   * @type {string}
   * @memberof LabelsApiUpdateLabelsNodesNodeUidSplitsSplitLabelsPut
   */
  readonly sort?: string;

  /**
   *
   * @type {number}
   * @memberof LabelsApiUpdateLabelsNodesNodeUidSplitsSplitLabelsPut
   */
  readonly seed?: number;
}

/**
 * LabelsApi - object-oriented interface
 * @export
 * @class LabelsApi
 * @extends {BaseAPI}
 */
export class LabelsApi extends BaseAPI {
  /**
   * Updates a \"ground_truth\" label for a particular individual datapoint.
   * @summary Update Labels
   * @param {LabelsApiUpdateLabelsNodesNodeUidSplitsSplitLabelsPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public updateLabelsNodesNodeUidSplitsSplitLabelsPut(
    requestParameters: LabelsApiUpdateLabelsNodesNodeUidSplitsSplitLabelsPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LabelsApiFp(this.configuration)
      .updateLabelsNodesNodeUidSplitsSplitLabelsPut(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.labelsRequestBody,
        requestParameters.sort,
        requestParameters.seed,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LabelsAutofillApi - axios parameter creator
 * @export
 */
export const LabelsAutofillApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Autofill GROUND TRUTH labels for a split based on an autofill strategy.
     * @summary Apply Labels Auto Fill
     * @param {number} nodeUid
     * @param {string} split
     * @param {LabelsAutoFillRequestBody} labelsAutoFillRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut: async (
      nodeUid: number,
      split: string,
      labelsAutoFillRequestBody: LabelsAutoFillRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut',
        'split',
        split,
      );
      // verify required parameter 'labelsAutoFillRequestBody' is not null or undefined
      assertParamExists(
        'applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut',
        'labelsAutoFillRequestBody',
        labelsAutoFillRequestBody,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/labels/autofill`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        labelsAutoFillRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LabelsAutofillApi - functional programming interface
 * @export
 */
export const LabelsAutofillApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LabelsAutofillApiAxiosParamCreator(configuration);
  return {
    /**
     * Autofill GROUND TRUTH labels for a split based on an autofill strategy.
     * @summary Apply Labels Auto Fill
     * @param {number} nodeUid
     * @param {string} split
     * @param {LabelsAutoFillRequestBody} labelsAutoFillRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut(
      nodeUid: number,
      split: string,
      labelsAutoFillRequestBody: LabelsAutoFillRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut(
          nodeUid,
          split,
          labelsAutoFillRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LabelsAutofillApi.applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LabelsAutofillApi - factory interface
 * @export
 */
export const LabelsAutofillApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LabelsAutofillApiFp(configuration);
  return {
    /**
     * Autofill GROUND TRUTH labels for a split based on an autofill strategy.
     * @summary Apply Labels Auto Fill
     * @param {LabelsAutofillApiApplyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut(
      requestParameters: LabelsAutofillApiApplyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.labelsAutoFillRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut operation in LabelsAutofillApi.
 * @export
 * @interface LabelsAutofillApiApplyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPutRequest
 */
export interface LabelsAutofillApiApplyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPutRequest {
  /**
   *
   * @type {number}
   * @memberof LabelsAutofillApiApplyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof LabelsAutofillApiApplyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut
   */
  readonly split: string;

  /**
   *
   * @type {LabelsAutoFillRequestBody}
   * @memberof LabelsAutofillApiApplyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut
   */
  readonly labelsAutoFillRequestBody: LabelsAutoFillRequestBody;
}

/**
 * LabelsAutofillApi - object-oriented interface
 * @export
 * @class LabelsAutofillApi
 * @extends {BaseAPI}
 */
export class LabelsAutofillApi extends BaseAPI {
  /**
   * Autofill GROUND TRUTH labels for a split based on an autofill strategy.
   * @summary Apply Labels Auto Fill
   * @param {LabelsAutofillApiApplyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsAutofillApi
   */
  public applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut(
    requestParameters: LabelsAutofillApiApplyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LabelsAutofillApiFp(this.configuration)
      .applyLabelsAutoFillNodesNodeUidSplitsSplitLabelsAutofillPut(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.labelsAutoFillRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LfSuggesterApi - axios parameter creator
 * @export
 */
export const LfSuggesterApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Return a json with multiple configs for RuleBuilder widget.
     * @summary Create Auto Lf
     * @param {number} nodeUid
     * @param {string} split
     * @param {AutoLFRequestBody} autoLFRequestBody
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {number} [minLfPrecision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAutoLfNodesNodeUidSplitsSplitAutoLfPost: async (
      nodeUid: number,
      split: string,
      autoLFRequestBody: AutoLFRequestBody,
      limit?: number,
      offset?: number,
      minLfPrecision?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'createAutoLfNodesNodeUidSplitsSplitAutoLfPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'createAutoLfNodesNodeUidSplitsSplitAutoLfPost',
        'split',
        split,
      );
      // verify required parameter 'autoLFRequestBody' is not null or undefined
      assertParamExists(
        'createAutoLfNodesNodeUidSplitsSplitAutoLfPost',
        'autoLFRequestBody',
        autoLFRequestBody,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/auto-lf`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (minLfPrecision !== undefined) {
        localVarQueryParameter['min_lf_precision'] = minLfPrecision;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        autoLFRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Populate Lfs
     * @param {number} nodeUid
     * @param {string} jobId
     * @param {string} [suggestModel]
     * @param {number} [timeout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete: async (
      nodeUid: number,
      jobId: string,
      suggestModel?: string,
      timeout?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'deletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists(
        'deletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete',
        'jobId',
        jobId,
      );
      const localVarPath = `/nodes/{node_uid}/populate_lf_suggestions`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (jobId !== undefined) {
        localVarQueryParameter['job_id'] = jobId;
      }

      if (suggestModel !== undefined) {
        localVarQueryParameter['suggest_model'] = suggestModel;
      }

      if (timeout !== undefined) {
        localVarQueryParameter['timeout'] = timeout;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Endpoint to get Auto-Suggested LF\'s (NOTE: it doesn\'t save LF\'s only creates them in \'suggested\' state)     NOTES:         - this only currently supports numeric and text columns,         - is only supported within Classification Application Types         - For numeric columns: at most (1) LF will be returned         - For text columns:             - Keyword(s)-based LF\'s are returned             - Each Keyword(s)-based LF will have at most (3) keywords associated with it, all OR-d together             - At most (2) LF\'s are returned, if n_grams is 2, else at most (1) LF will be returned  Parameters ---------- column_name     name of dataset column to analyze for creating auto-suggested LF\'s column_type     datatype of corresponding to dataset column column_name. Should be one of \"str\", \"int\", \"float\" backend     algorithm to use generated auto-suggested LF\'s. Should be one of \"Auto\" (for all datatypes) or \"TFIDF\" (for text only) n_grams     word window size, when for keyword-based LF\'s (e.g when n_grams == 2 look at neighboring pairs of words, when n_grams == 1, look at single words )     NOTE: 1) recommended to be <= 2, 2) only supported for backend == \"Auto\" keyword_precision_threshold     minimum keyword \'precision\' that keyword must have, in order be considered for Auto-Suggested LF     \'precision\' here means (# occurrences of word in samples of target class) / (# of occurrences across all samples)  Returns ------- Tuple[ List[ {LF_field_name}, {LF_field_value} ], Metrics ]
     * @summary Get Lf Suggestions
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} columnName
     * @param {string} columnType
     * @param {number} [nGrams]
     * @param {number} [keywordPrecisionThreshold]
     * @param {string} [suggestModel]
     * @param {string} [origin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet: async (
      nodeUid: number,
      split: string,
      columnName: string,
      columnType: string,
      nGrams?: number,
      keywordPrecisionThreshold?: number,
      suggestModel?: string,
      origin?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet',
        'split',
        split,
      );
      // verify required parameter 'columnName' is not null or undefined
      assertParamExists(
        'getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet',
        'columnName',
        columnName,
      );
      // verify required parameter 'columnType' is not null or undefined
      assertParamExists(
        'getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet',
        'columnType',
        columnType,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/get_lf_suggestions`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (columnName !== undefined) {
        localVarQueryParameter['column_name'] = columnName;
      }

      if (columnType !== undefined) {
        localVarQueryParameter['column_type'] = columnType;
      }

      if (nGrams !== undefined) {
        localVarQueryParameter['n_grams'] = nGrams;
      }

      if (keywordPrecisionThreshold !== undefined) {
        localVarQueryParameter['keyword_precision_threshold'] =
          keywordPrecisionThreshold;
      }

      if (suggestModel !== undefined) {
        localVarQueryParameter['suggest_model'] = suggestModel;
      }

      if (origin !== undefined) {
        localVarQueryParameter['origin'] = origin;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Endpoint to estimate the cost of warm start methods for a given node.  Arguments:     configs : List[Dict[str, str]]         Dict containing warm_start `method`, `provider` and `model` for         which to estimate costs for.     openai_costs: Optional[Dict[Dict[str, float]]]         Inference cost per 1k tokens for each model. Note this will override         the pre-defined values.     splits: Optional[List[str]]         Dataset splits to use for estimating costs. Defaults to [\"train\",         \"dev\", \"valid\", \"test\"].     input_columns: Optional[List[str]]         List of input columns to use for estimating costs. Defaults to all         text columns.  Return:     costs : List[WarmStartMethodAndModelCost]         Estimate cost in USD for each method model combination
     * @summary Get Warm Start Method Costs
     * @param {number} nodeUid
     * @param {WarmStartCostsRequest} warmStartCostsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost: async (
      nodeUid: number,
      warmStartCostsRequest: WarmStartCostsRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'warmStartCostsRequest' is not null or undefined
      assertParamExists(
        'getWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost',
        'warmStartCostsRequest',
        warmStartCostsRequest,
      );
      const localVarPath =
        `/nodes/{node_uid}/estimate-warm-start-method-costs`.replace(
          `{${'node_uid'}}`,
          encodeURIComponent(String(nodeUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        warmStartCostsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Endpoint to generate Auto-Suggested LF\'s     NOTES:         - this only currently supports numeric and text columns,         - is only supported within Classification Application Types         - For numeric columns: at most (1) LF will be returned         - For text columns:             - Keyword(s)-based LF\'s are returned             - Each Keyword(s)-based LF will have at most (3) keywords associated with it, all OR-d together             - At most (2) LF\'s are returned, if n_grams is 2, else at most (1) LF will be returned  Parameters ---------- columns     dataset columns to analyze for creating auto-suggested LF\'s min_coverage_threshold     minimum coverage percentage for all returned auto-suggested LF(s) min_lf_precision_threshold     minimum precision percentage that auto-suggested must have in order to be saved to node and returned min_confidence_threshold     minimum confidence the model must have in order to not abstain on a datapoint n_grams     for text columns, return ngram-based Keyword LF\'s (e.g with \"Interest Rates\" and \"Bond Issuance\" as Keywords when n_grams=2) use_multipolar_template     If True, returns a single multipolar LF template, otherwise return unipolar LF templates. Note this     only applied to model based LFs. inference_splits     Defaults to all active datasources and splits. If specified, run model inference on only the specified splits.  Returns ------- Dict [ \"results\", Dict[ {column_name}, Tuple[ List[ {LF_field_name}, {LF_field_value} ], Metrics ] ] ]
     * @summary Populate Lf Suggestions
     * @param {number} nodeUid
     * @param {PopulateLFSuggestionsRequest} populateLFSuggestionsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    populateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost: async (
      nodeUid: number,
      populateLFSuggestionsRequest: PopulateLFSuggestionsRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'populateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'populateLFSuggestionsRequest' is not null or undefined
      assertParamExists(
        'populateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost',
        'populateLFSuggestionsRequest',
        populateLFSuggestionsRequest,
      );
      const localVarPath = `/nodes/{node_uid}/populate_lf_suggestions`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        populateLFSuggestionsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LfSuggesterApi - functional programming interface
 * @export
 */
export const LfSuggesterApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LfSuggesterApiAxiosParamCreator(configuration);
  return {
    /**
     * Return a json with multiple configs for RuleBuilder widget.
     * @summary Create Auto Lf
     * @param {number} nodeUid
     * @param {string} split
     * @param {AutoLFRequestBody} autoLFRequestBody
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {number} [minLfPrecision]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAutoLfNodesNodeUidSplitsSplitAutoLfPost(
      nodeUid: number,
      split: string,
      autoLFRequestBody: AutoLFRequestBody,
      limit?: number,
      offset?: number,
      minLfPrecision?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createAutoLfNodesNodeUidSplitsSplitAutoLfPost(
          nodeUid,
          split,
          autoLFRequestBody,
          limit,
          offset,
          minLfPrecision,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfSuggesterApi.createAutoLfNodesNodeUidSplitsSplitAutoLfPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Populate Lfs
     * @param {number} nodeUid
     * @param {string} jobId
     * @param {string} [suggestModel]
     * @param {number} [timeout]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete(
      nodeUid: number,
      jobId: string,
      suggestModel?: string,
      timeout?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete(
          nodeUid,
          jobId,
          suggestModel,
          timeout,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfSuggesterApi.deletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Endpoint to get Auto-Suggested LF\'s (NOTE: it doesn\'t save LF\'s only creates them in \'suggested\' state)     NOTES:         - this only currently supports numeric and text columns,         - is only supported within Classification Application Types         - For numeric columns: at most (1) LF will be returned         - For text columns:             - Keyword(s)-based LF\'s are returned             - Each Keyword(s)-based LF will have at most (3) keywords associated with it, all OR-d together             - At most (2) LF\'s are returned, if n_grams is 2, else at most (1) LF will be returned  Parameters ---------- column_name     name of dataset column to analyze for creating auto-suggested LF\'s column_type     datatype of corresponding to dataset column column_name. Should be one of \"str\", \"int\", \"float\" backend     algorithm to use generated auto-suggested LF\'s. Should be one of \"Auto\" (for all datatypes) or \"TFIDF\" (for text only) n_grams     word window size, when for keyword-based LF\'s (e.g when n_grams == 2 look at neighboring pairs of words, when n_grams == 1, look at single words )     NOTE: 1) recommended to be <= 2, 2) only supported for backend == \"Auto\" keyword_precision_threshold     minimum keyword \'precision\' that keyword must have, in order be considered for Auto-Suggested LF     \'precision\' here means (# occurrences of word in samples of target class) / (# of occurrences across all samples)  Returns ------- Tuple[ List[ {LF_field_name}, {LF_field_value} ], Metrics ]
     * @summary Get Lf Suggestions
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} columnName
     * @param {string} columnType
     * @param {number} [nGrams]
     * @param {number} [keywordPrecisionThreshold]
     * @param {string} [suggestModel]
     * @param {string} [origin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet(
      nodeUid: number,
      split: string,
      columnName: string,
      columnType: string,
      nGrams?: number,
      keywordPrecisionThreshold?: number,
      suggestModel?: string,
      origin?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet(
          nodeUid,
          split,
          columnName,
          columnType,
          nGrams,
          keywordPrecisionThreshold,
          suggestModel,
          origin,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfSuggesterApi.getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Endpoint to estimate the cost of warm start methods for a given node.  Arguments:     configs : List[Dict[str, str]]         Dict containing warm_start `method`, `provider` and `model` for         which to estimate costs for.     openai_costs: Optional[Dict[Dict[str, float]]]         Inference cost per 1k tokens for each model. Note this will override         the pre-defined values.     splits: Optional[List[str]]         Dataset splits to use for estimating costs. Defaults to [\"train\",         \"dev\", \"valid\", \"test\"].     input_columns: Optional[List[str]]         List of input columns to use for estimating costs. Defaults to all         text columns.  Return:     costs : List[WarmStartMethodAndModelCost]         Estimate cost in USD for each method model combination
     * @summary Get Warm Start Method Costs
     * @param {number} nodeUid
     * @param {WarmStartCostsRequest} warmStartCostsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost(
      nodeUid: number,
      warmStartCostsRequest: WarmStartCostsRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WarmStartCostsResults>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost(
          nodeUid,
          warmStartCostsRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfSuggesterApi.getWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Endpoint to generate Auto-Suggested LF\'s     NOTES:         - this only currently supports numeric and text columns,         - is only supported within Classification Application Types         - For numeric columns: at most (1) LF will be returned         - For text columns:             - Keyword(s)-based LF\'s are returned             - Each Keyword(s)-based LF will have at most (3) keywords associated with it, all OR-d together             - At most (2) LF\'s are returned, if n_grams is 2, else at most (1) LF will be returned  Parameters ---------- columns     dataset columns to analyze for creating auto-suggested LF\'s min_coverage_threshold     minimum coverage percentage for all returned auto-suggested LF(s) min_lf_precision_threshold     minimum precision percentage that auto-suggested must have in order to be saved to node and returned min_confidence_threshold     minimum confidence the model must have in order to not abstain on a datapoint n_grams     for text columns, return ngram-based Keyword LF\'s (e.g with \"Interest Rates\" and \"Bond Issuance\" as Keywords when n_grams=2) use_multipolar_template     If True, returns a single multipolar LF template, otherwise return unipolar LF templates. Note this     only applied to model based LFs. inference_splits     Defaults to all active datasources and splits. If specified, run model inference on only the specified splits.  Returns ------- Dict [ \"results\", Dict[ {column_name}, Tuple[ List[ {LF_field_name}, {LF_field_value} ], Metrics ] ] ]
     * @summary Populate Lf Suggestions
     * @param {number} nodeUid
     * @param {PopulateLFSuggestionsRequest} populateLFSuggestionsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async populateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost(
      nodeUid: number,
      populateLFSuggestionsRequest: PopulateLFSuggestionsRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PopulateLFSuggestionsResults>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.populateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost(
          nodeUid,
          populateLFSuggestionsRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfSuggesterApi.populateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LfSuggesterApi - factory interface
 * @export
 */
export const LfSuggesterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LfSuggesterApiFp(configuration);
  return {
    /**
     * Return a json with multiple configs for RuleBuilder widget.
     * @summary Create Auto Lf
     * @param {LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAutoLfNodesNodeUidSplitsSplitAutoLfPost(
      requestParameters: LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .createAutoLfNodesNodeUidSplitsSplitAutoLfPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.autoLFRequestBody,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.minLfPrecision,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Populate Lfs
     * @param {LfSuggesterApiDeletePopulateLfsNodesNodeUidPopulateLfSuggestionsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete(
      requestParameters: LfSuggesterApiDeletePopulateLfsNodesNodeUidPopulateLfSuggestionsDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete(
          requestParameters.nodeUid,
          requestParameters.jobId,
          requestParameters.suggestModel,
          requestParameters.timeout,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Endpoint to get Auto-Suggested LF\'s (NOTE: it doesn\'t save LF\'s only creates them in \'suggested\' state)     NOTES:         - this only currently supports numeric and text columns,         - is only supported within Classification Application Types         - For numeric columns: at most (1) LF will be returned         - For text columns:             - Keyword(s)-based LF\'s are returned             - Each Keyword(s)-based LF will have at most (3) keywords associated with it, all OR-d together             - At most (2) LF\'s are returned, if n_grams is 2, else at most (1) LF will be returned  Parameters ---------- column_name     name of dataset column to analyze for creating auto-suggested LF\'s column_type     datatype of corresponding to dataset column column_name. Should be one of \"str\", \"int\", \"float\" backend     algorithm to use generated auto-suggested LF\'s. Should be one of \"Auto\" (for all datatypes) or \"TFIDF\" (for text only) n_grams     word window size, when for keyword-based LF\'s (e.g when n_grams == 2 look at neighboring pairs of words, when n_grams == 1, look at single words )     NOTE: 1) recommended to be <= 2, 2) only supported for backend == \"Auto\" keyword_precision_threshold     minimum keyword \'precision\' that keyword must have, in order be considered for Auto-Suggested LF     \'precision\' here means (# occurrences of word in samples of target class) / (# of occurrences across all samples)  Returns ------- Tuple[ List[ {LF_field_name}, {LF_field_value} ], Metrics ]
     * @summary Get Lf Suggestions
     * @param {LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet(
      requestParameters: LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.columnName,
          requestParameters.columnType,
          requestParameters.nGrams,
          requestParameters.keywordPrecisionThreshold,
          requestParameters.suggestModel,
          requestParameters.origin,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Endpoint to estimate the cost of warm start methods for a given node.  Arguments:     configs : List[Dict[str, str]]         Dict containing warm_start `method`, `provider` and `model` for         which to estimate costs for.     openai_costs: Optional[Dict[Dict[str, float]]]         Inference cost per 1k tokens for each model. Note this will override         the pre-defined values.     splits: Optional[List[str]]         Dataset splits to use for estimating costs. Defaults to [\"train\",         \"dev\", \"valid\", \"test\"].     input_columns: Optional[List[str]]         List of input columns to use for estimating costs. Defaults to all         text columns.  Return:     costs : List[WarmStartMethodAndModelCost]         Estimate cost in USD for each method model combination
     * @summary Get Warm Start Method Costs
     * @param {LfSuggesterApiGetWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost(
      requestParameters: LfSuggesterApiGetWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<WarmStartCostsResults> {
      return localVarFp
        .getWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost(
          requestParameters.nodeUid,
          requestParameters.warmStartCostsRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Endpoint to generate Auto-Suggested LF\'s     NOTES:         - this only currently supports numeric and text columns,         - is only supported within Classification Application Types         - For numeric columns: at most (1) LF will be returned         - For text columns:             - Keyword(s)-based LF\'s are returned             - Each Keyword(s)-based LF will have at most (3) keywords associated with it, all OR-d together             - At most (2) LF\'s are returned, if n_grams is 2, else at most (1) LF will be returned  Parameters ---------- columns     dataset columns to analyze for creating auto-suggested LF\'s min_coverage_threshold     minimum coverage percentage for all returned auto-suggested LF(s) min_lf_precision_threshold     minimum precision percentage that auto-suggested must have in order to be saved to node and returned min_confidence_threshold     minimum confidence the model must have in order to not abstain on a datapoint n_grams     for text columns, return ngram-based Keyword LF\'s (e.g with \"Interest Rates\" and \"Bond Issuance\" as Keywords when n_grams=2) use_multipolar_template     If True, returns a single multipolar LF template, otherwise return unipolar LF templates. Note this     only applied to model based LFs. inference_splits     Defaults to all active datasources and splits. If specified, run model inference on only the specified splits.  Returns ------- Dict [ \"results\", Dict[ {column_name}, Tuple[ List[ {LF_field_name}, {LF_field_value} ], Metrics ] ] ]
     * @summary Populate Lf Suggestions
     * @param {LfSuggesterApiPopulateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    populateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost(
      requestParameters: LfSuggesterApiPopulateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PopulateLFSuggestionsResults> {
      return localVarFp
        .populateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost(
          requestParameters.nodeUid,
          requestParameters.populateLFSuggestionsRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createAutoLfNodesNodeUidSplitsSplitAutoLfPost operation in LfSuggesterApi.
 * @export
 * @interface LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPostRequest
 */
export interface LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPost
   */
  readonly split: string;

  /**
   *
   * @type {AutoLFRequestBody}
   * @memberof LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPost
   */
  readonly autoLFRequestBody: AutoLFRequestBody;

  /**
   *
   * @type {number}
   * @memberof LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPost
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPost
   */
  readonly offset?: number;

  /**
   *
   * @type {number}
   * @memberof LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPost
   */
  readonly minLfPrecision?: number;
}

/**
 * Request parameters for deletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete operation in LfSuggesterApi.
 * @export
 * @interface LfSuggesterApiDeletePopulateLfsNodesNodeUidPopulateLfSuggestionsDeleteRequest
 */
export interface LfSuggesterApiDeletePopulateLfsNodesNodeUidPopulateLfSuggestionsDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof LfSuggesterApiDeletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof LfSuggesterApiDeletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete
   */
  readonly jobId: string;

  /**
   *
   * @type {string}
   * @memberof LfSuggesterApiDeletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete
   */
  readonly suggestModel?: string;

  /**
   *
   * @type {number}
   * @memberof LfSuggesterApiDeletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete
   */
  readonly timeout?: number;
}

/**
 * Request parameters for getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet operation in LfSuggesterApi.
 * @export
 * @interface LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGetRequest
 */
export interface LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet
   */
  readonly columnName: string;

  /**
   *
   * @type {string}
   * @memberof LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet
   */
  readonly columnType: string;

  /**
   *
   * @type {number}
   * @memberof LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet
   */
  readonly nGrams?: number;

  /**
   *
   * @type {number}
   * @memberof LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet
   */
  readonly keywordPrecisionThreshold?: number;

  /**
   *
   * @type {string}
   * @memberof LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet
   */
  readonly suggestModel?: string;

  /**
   *
   * @type {string}
   * @memberof LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet
   */
  readonly origin?: string;
}

/**
 * Request parameters for getWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost operation in LfSuggesterApi.
 * @export
 * @interface LfSuggesterApiGetWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPostRequest
 */
export interface LfSuggesterApiGetWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfSuggesterApiGetWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {WarmStartCostsRequest}
   * @memberof LfSuggesterApiGetWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost
   */
  readonly warmStartCostsRequest: WarmStartCostsRequest;
}

/**
 * Request parameters for populateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost operation in LfSuggesterApi.
 * @export
 * @interface LfSuggesterApiPopulateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPostRequest
 */
export interface LfSuggesterApiPopulateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfSuggesterApiPopulateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {PopulateLFSuggestionsRequest}
   * @memberof LfSuggesterApiPopulateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost
   */
  readonly populateLFSuggestionsRequest: PopulateLFSuggestionsRequest;
}

/**
 * LfSuggesterApi - object-oriented interface
 * @export
 * @class LfSuggesterApi
 * @extends {BaseAPI}
 */
export class LfSuggesterApi extends BaseAPI {
  /**
   * Return a json with multiple configs for RuleBuilder widget.
   * @summary Create Auto Lf
   * @param {LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfSuggesterApi
   */
  public createAutoLfNodesNodeUidSplitsSplitAutoLfPost(
    requestParameters: LfSuggesterApiCreateAutoLfNodesNodeUidSplitsSplitAutoLfPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfSuggesterApiFp(this.configuration)
      .createAutoLfNodesNodeUidSplitsSplitAutoLfPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.autoLFRequestBody,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.minLfPrecision,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Populate Lfs
   * @param {LfSuggesterApiDeletePopulateLfsNodesNodeUidPopulateLfSuggestionsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfSuggesterApi
   */
  public deletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete(
    requestParameters: LfSuggesterApiDeletePopulateLfsNodesNodeUidPopulateLfSuggestionsDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfSuggesterApiFp(this.configuration)
      .deletePopulateLfsNodesNodeUidPopulateLfSuggestionsDelete(
        requestParameters.nodeUid,
        requestParameters.jobId,
        requestParameters.suggestModel,
        requestParameters.timeout,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Endpoint to get Auto-Suggested LF\'s (NOTE: it doesn\'t save LF\'s only creates them in \'suggested\' state)     NOTES:         - this only currently supports numeric and text columns,         - is only supported within Classification Application Types         - For numeric columns: at most (1) LF will be returned         - For text columns:             - Keyword(s)-based LF\'s are returned             - Each Keyword(s)-based LF will have at most (3) keywords associated with it, all OR-d together             - At most (2) LF\'s are returned, if n_grams is 2, else at most (1) LF will be returned  Parameters ---------- column_name     name of dataset column to analyze for creating auto-suggested LF\'s column_type     datatype of corresponding to dataset column column_name. Should be one of \"str\", \"int\", \"float\" backend     algorithm to use generated auto-suggested LF\'s. Should be one of \"Auto\" (for all datatypes) or \"TFIDF\" (for text only) n_grams     word window size, when for keyword-based LF\'s (e.g when n_grams == 2 look at neighboring pairs of words, when n_grams == 1, look at single words )     NOTE: 1) recommended to be <= 2, 2) only supported for backend == \"Auto\" keyword_precision_threshold     minimum keyword \'precision\' that keyword must have, in order be considered for Auto-Suggested LF     \'precision\' here means (# occurrences of word in samples of target class) / (# of occurrences across all samples)  Returns ------- Tuple[ List[ {LF_field_name}, {LF_field_value} ], Metrics ]
   * @summary Get Lf Suggestions
   * @param {LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfSuggesterApi
   */
  public getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet(
    requestParameters: LfSuggesterApiGetLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfSuggesterApiFp(this.configuration)
      .getLfSuggestionsNodesNodeUidSplitsSplitGetLfSuggestionsGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.columnName,
        requestParameters.columnType,
        requestParameters.nGrams,
        requestParameters.keywordPrecisionThreshold,
        requestParameters.suggestModel,
        requestParameters.origin,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Endpoint to estimate the cost of warm start methods for a given node.  Arguments:     configs : List[Dict[str, str]]         Dict containing warm_start `method`, `provider` and `model` for         which to estimate costs for.     openai_costs: Optional[Dict[Dict[str, float]]]         Inference cost per 1k tokens for each model. Note this will override         the pre-defined values.     splits: Optional[List[str]]         Dataset splits to use for estimating costs. Defaults to [\"train\",         \"dev\", \"valid\", \"test\"].     input_columns: Optional[List[str]]         List of input columns to use for estimating costs. Defaults to all         text columns.  Return:     costs : List[WarmStartMethodAndModelCost]         Estimate cost in USD for each method model combination
   * @summary Get Warm Start Method Costs
   * @param {LfSuggesterApiGetWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfSuggesterApi
   */
  public getWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost(
    requestParameters: LfSuggesterApiGetWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfSuggesterApiFp(this.configuration)
      .getWarmStartMethodCostsNodesNodeUidEstimateWarmStartMethodCostsPost(
        requestParameters.nodeUid,
        requestParameters.warmStartCostsRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Endpoint to generate Auto-Suggested LF\'s     NOTES:         - this only currently supports numeric and text columns,         - is only supported within Classification Application Types         - For numeric columns: at most (1) LF will be returned         - For text columns:             - Keyword(s)-based LF\'s are returned             - Each Keyword(s)-based LF will have at most (3) keywords associated with it, all OR-d together             - At most (2) LF\'s are returned, if n_grams is 2, else at most (1) LF will be returned  Parameters ---------- columns     dataset columns to analyze for creating auto-suggested LF\'s min_coverage_threshold     minimum coverage percentage for all returned auto-suggested LF(s) min_lf_precision_threshold     minimum precision percentage that auto-suggested must have in order to be saved to node and returned min_confidence_threshold     minimum confidence the model must have in order to not abstain on a datapoint n_grams     for text columns, return ngram-based Keyword LF\'s (e.g with \"Interest Rates\" and \"Bond Issuance\" as Keywords when n_grams=2) use_multipolar_template     If True, returns a single multipolar LF template, otherwise return unipolar LF templates. Note this     only applied to model based LFs. inference_splits     Defaults to all active datasources and splits. If specified, run model inference on only the specified splits.  Returns ------- Dict [ \"results\", Dict[ {column_name}, Tuple[ List[ {LF_field_name}, {LF_field_value} ], Metrics ] ] ]
   * @summary Populate Lf Suggestions
   * @param {LfSuggesterApiPopulateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfSuggesterApi
   */
  public populateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost(
    requestParameters: LfSuggesterApiPopulateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfSuggesterApiFp(this.configuration)
      .populateLfSuggestionsNodesNodeUidPopulateLfSuggestionsPost(
        requestParameters.nodeUid,
        requestParameters.populateLFSuggestionsRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LfsApi - axios parameter creator
 * @export
 */
export const LfsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Returns the default configuration options for an LF state
     * @summary Advanced Lf State
     * @param {number} nodeUid
     * @param {string} split
     * @param {AdvancedStateRequest} advancedStateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost: async (
      nodeUid: number,
      split: string,
      advancedStateRequest: AdvancedStateRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost',
        'split',
        split,
      );
      // verify required parameter 'advancedStateRequest' is not null or undefined
      assertParamExists(
        'advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost',
        'advancedStateRequest',
        advancedStateRequest,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/advanced-lf-state`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        advancedStateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove an LF from the list of active LFs.  Note that this method does not remove the LF from TDM.
     * @summary Archive Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveLfNodesNodeUidLfsLfUidDelete: async (
      nodeUid: number,
      lfUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'archiveLfNodesNodeUidLfsLfUidDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists('archiveLfNodesNodeUidLfsLfUidDelete', 'lfUid', lfUid);
      const localVarPath = `/nodes/{node_uid}/lfs/{lf_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove all LFs for the given task from ActiveLFs
     * @summary Archive Lfs
     * @param {number} nodeUid
     * @param {BulkDeleteLFs} [bulkDeleteLFs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveLfsNodesNodeUidLfsDelete: async (
      nodeUid: number,
      bulkDeleteLFs?: BulkDeleteLFs,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('archiveLfsNodesNodeUidLfsDelete', 'nodeUid', nodeUid);
      const localVarPath = `/nodes/{node_uid}/lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkDeleteLFs,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return a list of active LFs that are below a score threshold .
     * @summary Compute Scores
     * @param {number} nodeUid
     * @param {boolean} [forActiveLfs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeScoresNodesNodeUidComputeScoresGet: async (
      nodeUid: number,
      forActiveLfs?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'computeScoresNodesNodeUidComputeScoresGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/compute-scores`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (forActiveLfs !== undefined) {
        localVarQueryParameter['for_active_lfs'] = forActiveLfs;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch LF voted info (correct, incorrect, unknown) for given x_uids for active LFs.
     * @summary Fetch Lf Voted Info
     * @param {number} nodeUid
     * @param {FetchLFVotedInfoParams} fetchLFVotedInfoParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfVotedInfoNodesNodeUidLfsVotedInfoPost: async (
      nodeUid: number,
      fetchLFVotedInfoParams: FetchLFVotedInfoParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchLfVotedInfoNodesNodeUidLfsVotedInfoPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'fetchLFVotedInfoParams' is not null or undefined
      assertParamExists(
        'fetchLfVotedInfoNodesNodeUidLfsVotedInfoPost',
        'fetchLFVotedInfoParams',
        fetchLFVotedInfoParams,
      );
      const localVarPath = `/nodes/{node_uid}/lfs-voted-info`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fetchLFVotedInfoParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch a single LF by uid.
     * @summary Get Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfNodesNodeUidLfsLfUidGet: async (
      nodeUid: number,
      lfUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('getLfNodesNodeUidLfsLfUidGet', 'nodeUid', nodeUid);
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists('getLfNodesNodeUidLfsLfUidGet', 'lfUid', lfUid);
      const localVarPath = `/nodes/{node_uid}/lfs/{lf_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return all active LF configs for the given task.
     * @summary Get Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfsNodesNodeUidLfsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('getLfsNodesNodeUidLfsGet', 'nodeUid', nodeUid);
      const localVarPath = `/nodes/{node_uid}/lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Lf States
     * @param {number} nodeUid
     * @param {string} split
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listLfStatesNodesNodeUidSplitsSplitLfStatesGet: async (
      nodeUid: number,
      split: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listLfStatesNodesNodeUidSplitsSplitLfStatesGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'listLfStatesNodesNodeUidSplitsSplitLfStatesGet',
        'split',
        split,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/lf-states`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the set of available LF states, given the current state + config. If all_states is set to True, then returns a list of all available LFs regardless current state.
     * @summary Next Lf States
     * @param {number} nodeUid
     * @param {string} split
     * @param {NextLFStatesRequest} nextLFStatesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost: async (
      nodeUid: number,
      split: string,
      nextLFStatesRequest: NextLFStatesRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost',
        'split',
        split,
      );
      // verify required parameter 'nextLFStatesRequest' is not null or undefined
      assertParamExists(
        'nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost',
        'nextLFStatesRequest',
        nextLFStatesRequest,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/next-lf-states`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        nextLFStatesRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Autotuners
     * @param {number} nodeUid
     * @param {PostAutotunersRequestBody} postAutotunersRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAutotunersNodesNodeUidAutotunersPost: async (
      nodeUid: number,
      postAutotunersRequestBody: PostAutotunersRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'postAutotunersNodesNodeUidAutotunersPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'postAutotunersRequestBody' is not null or undefined
      assertParamExists(
        'postAutotunersNodesNodeUidAutotunersPost',
        'postAutotunersRequestBody',
        postAutotunersRequestBody,
      );
      const localVarPath = `/nodes/{node_uid}/autotuners`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postAutotunersRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create multiple new LFs based on LF Generator.
     * @summary Post Generators
     * @param {number} nodeUid
     * @param {PostGeneratorsRequestBody} postGeneratorsRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGeneratorsNodesNodeUidGeneratorsPost: async (
      nodeUid: number,
      postGeneratorsRequestBody: PostGeneratorsRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'postGeneratorsNodesNodeUidGeneratorsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'postGeneratorsRequestBody' is not null or undefined
      assertParamExists(
        'postGeneratorsNodesNodeUidGeneratorsPost',
        'postGeneratorsRequestBody',
        postGeneratorsRequestBody,
      );
      const localVarPath = `/nodes/{node_uid}/generators`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postGeneratorsRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new LF.
     * @summary Post Lf
     * @param {number} nodeUid
     * @param {PostLFRequestBody} postLFRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLfNodesNodeUidLfsPost: async (
      nodeUid: number,
      postLFRequestBody: PostLFRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('postLfNodesNodeUidLfsPost', 'nodeUid', nodeUid);
      // verify required parameter 'postLFRequestBody' is not null or undefined
      assertParamExists(
        'postLfNodesNodeUidLfsPost',
        'postLFRequestBody',
        postLFRequestBody,
      );
      const localVarPath = `/nodes/{node_uid}/lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postLFRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Executes a state transition (which allows us to keep information between LFs)
     * @summary Transition Lf State
     * @param {number} nodeUid
     * @param {string} split
     * @param {LFStateRequest} lFStateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost: async (
      nodeUid: number,
      split: string,
      lFStateRequest: LFStateRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost',
        'split',
        split,
      );
      // verify required parameter 'lFStateRequest' is not null or undefined
      assertParamExists(
        'transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost',
        'lFStateRequest',
        lFStateRequest,
      );
      const localVarPath =
        `/nodes/{node_uid}/splits/{split}/transition-lf-state`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        lFStateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Suggestion State
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {UpdateSuggestionStatePayload} updateSuggestionStatePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut: async (
      nodeUid: number,
      lfUid: number,
      updateSuggestionStatePayload: UpdateSuggestionStatePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists(
        'updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut',
        'lfUid',
        lfUid,
      );
      // verify required parameter 'updateSuggestionStatePayload' is not null or undefined
      assertParamExists(
        'updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut',
        'updateSuggestionStatePayload',
        updateSuggestionStatePayload,
      );
      const localVarPath = `/nodes/{node_uid}/lfs/{lf_uid}/suggestion`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSuggestionStatePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LfsApi - functional programming interface
 * @export
 */
export const LfsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LfsApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns the default configuration options for an LF state
     * @summary Advanced Lf State
     * @param {number} nodeUid
     * @param {string} split
     * @param {AdvancedStateRequest} advancedStateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost(
      nodeUid: number,
      split: string,
      advancedStateRequest: AdvancedStateRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LFStateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost(
          nodeUid,
          split,
          advancedStateRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Remove an LF from the list of active LFs.  Note that this method does not remove the LF from TDM.
     * @summary Archive Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveLfNodesNodeUidLfsLfUidDelete(
      nodeUid: number,
      lfUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.archiveLfNodesNodeUidLfsLfUidDelete(
          nodeUid,
          lfUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.archiveLfNodesNodeUidLfsLfUidDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Remove all LFs for the given task from ActiveLFs
     * @summary Archive Lfs
     * @param {number} nodeUid
     * @param {BulkDeleteLFs} [bulkDeleteLFs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveLfsNodesNodeUidLfsDelete(
      nodeUid: number,
      bulkDeleteLFs?: BulkDeleteLFs,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.archiveLfsNodesNodeUidLfsDelete(
          nodeUid,
          bulkDeleteLFs,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.archiveLfsNodesNodeUidLfsDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return a list of active LFs that are below a score threshold .
     * @summary Compute Scores
     * @param {number} nodeUid
     * @param {boolean} [forActiveLfs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async computeScoresNodesNodeUidComputeScoresGet(
      nodeUid: number,
      forActiveLfs?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FlagLFsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.computeScoresNodesNodeUidComputeScoresGet(
          nodeUid,
          forActiveLfs,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.computeScoresNodesNodeUidComputeScoresGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch LF voted info (correct, incorrect, unknown) for given x_uids for active LFs.
     * @summary Fetch Lf Voted Info
     * @param {number} nodeUid
     * @param {FetchLFVotedInfoParams} fetchLFVotedInfoParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchLfVotedInfoNodesNodeUidLfsVotedInfoPost(
      nodeUid: number,
      fetchLFVotedInfoParams: FetchLFVotedInfoParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FetchLFVotedInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchLfVotedInfoNodesNodeUidLfsVotedInfoPost(
          nodeUid,
          fetchLFVotedInfoParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.fetchLfVotedInfoNodesNodeUidLfsVotedInfoPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch a single LF by uid.
     * @summary Get Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLfNodesNodeUidLfsLfUidGet(
      nodeUid: number,
      lfUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLfNodesNodeUidLfsLfUidGet(
          nodeUid,
          lfUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.getLfNodesNodeUidLfsLfUidGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return all active LF configs for the given task.
     * @summary Get Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLfsNodesNodeUidLfsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLfsNodesNodeUidLfsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.getLfsNodesNodeUidLfsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Lf States
     * @param {number} nodeUid
     * @param {string} split
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listLfStatesNodesNodeUidSplitsSplitLfStatesGet(
      nodeUid: number,
      split: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LFStateResponse>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listLfStatesNodesNodeUidSplitsSplitLfStatesGet(
          nodeUid,
          split,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.listLfStatesNodesNodeUidSplitsSplitLfStatesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Returns the set of available LF states, given the current state + config. If all_states is set to True, then returns a list of all available LFs regardless current state.
     * @summary Next Lf States
     * @param {number} nodeUid
     * @param {string} split
     * @param {NextLFStatesRequest} nextLFStatesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost(
      nodeUid: number,
      split: string,
      nextLFStatesRequest: NextLFStatesRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NextLFStatesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost(
          nodeUid,
          split,
          nextLFStatesRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Autotuners
     * @param {number} nodeUid
     * @param {PostAutotunersRequestBody} postAutotunersRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAutotunersNodesNodeUidAutotunersPost(
      nodeUid: number,
      postAutotunersRequestBody: PostAutotunersRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAutotunersNodesNodeUidAutotunersPost(
          nodeUid,
          postAutotunersRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.postAutotunersNodesNodeUidAutotunersPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Create multiple new LFs based on LF Generator.
     * @summary Post Generators
     * @param {number} nodeUid
     * @param {PostGeneratorsRequestBody} postGeneratorsRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postGeneratorsNodesNodeUidGeneratorsPost(
      nodeUid: number,
      postGeneratorsRequestBody: PostGeneratorsRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postGeneratorsNodesNodeUidGeneratorsPost(
          nodeUid,
          postGeneratorsRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.postGeneratorsNodesNodeUidGeneratorsPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Create a new LF.
     * @summary Post Lf
     * @param {number} nodeUid
     * @param {PostLFRequestBody} postLFRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postLfNodesNodeUidLfsPost(
      nodeUid: number,
      postLFRequestBody: PostLFRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postLfNodesNodeUidLfsPost(
          nodeUid,
          postLFRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.postLfNodesNodeUidLfsPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Executes a state transition (which allows us to keep information between LFs)
     * @summary Transition Lf State
     * @param {number} nodeUid
     * @param {string} split
     * @param {LFStateRequest} lFStateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost(
      nodeUid: number,
      split: string,
      lFStateRequest: LFStateRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LFStateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost(
          nodeUid,
          split,
          lFStateRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Suggestion State
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {UpdateSuggestionStatePayload} updateSuggestionStatePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut(
      nodeUid: number,
      lfUid: number,
      updateSuggestionStatePayload: UpdateSuggestionStatePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateSuggestionStateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut(
          nodeUid,
          lfUid,
          updateSuggestionStatePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LfsApi - factory interface
 * @export
 */
export const LfsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LfsApiFp(configuration);
  return {
    /**
     * Returns the default configuration options for an LF state
     * @summary Advanced Lf State
     * @param {LfsApiAdvancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost(
      requestParameters: LfsApiAdvancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LFStateResponse> {
      return localVarFp
        .advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.advancedStateRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Remove an LF from the list of active LFs.  Note that this method does not remove the LF from TDM.
     * @summary Archive Lf
     * @param {LfsApiArchiveLfNodesNodeUidLfsLfUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveLfNodesNodeUidLfsLfUidDelete(
      requestParameters: LfsApiArchiveLfNodesNodeUidLfsLfUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .archiveLfNodesNodeUidLfsLfUidDelete(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Remove all LFs for the given task from ActiveLFs
     * @summary Archive Lfs
     * @param {LfsApiArchiveLfsNodesNodeUidLfsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveLfsNodesNodeUidLfsDelete(
      requestParameters: LfsApiArchiveLfsNodesNodeUidLfsDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .archiveLfsNodesNodeUidLfsDelete(
          requestParameters.nodeUid,
          requestParameters.bulkDeleteLFs,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Return a list of active LFs that are below a score threshold .
     * @summary Compute Scores
     * @param {LfsApiComputeScoresNodesNodeUidComputeScoresGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeScoresNodesNodeUidComputeScoresGet(
      requestParameters: LfsApiComputeScoresNodesNodeUidComputeScoresGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FlagLFsResponse> {
      return localVarFp
        .computeScoresNodesNodeUidComputeScoresGet(
          requestParameters.nodeUid,
          requestParameters.forActiveLfs,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch LF voted info (correct, incorrect, unknown) for given x_uids for active LFs.
     * @summary Fetch Lf Voted Info
     * @param {LfsApiFetchLfVotedInfoNodesNodeUidLfsVotedInfoPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfVotedInfoNodesNodeUidLfsVotedInfoPost(
      requestParameters: LfsApiFetchLfVotedInfoNodesNodeUidLfsVotedInfoPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FetchLFVotedInfoResponse> {
      return localVarFp
        .fetchLfVotedInfoNodesNodeUidLfsVotedInfoPost(
          requestParameters.nodeUid,
          requestParameters.fetchLFVotedInfoParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch a single LF by uid.
     * @summary Get Lf
     * @param {LfsApiGetLfNodesNodeUidLfsLfUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfNodesNodeUidLfsLfUidGet(
      requestParameters: LfsApiGetLfNodesNodeUidLfsLfUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getLfNodesNodeUidLfsLfUidGet(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Return all active LF configs for the given task.
     * @summary Get Lfs
     * @param {LfsApiGetLfsNodesNodeUidLfsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfsNodesNodeUidLfsGet(
      requestParameters: LfsApiGetLfsNodesNodeUidLfsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getLfsNodesNodeUidLfsGet(requestParameters.nodeUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Lf States
     * @param {LfsApiListLfStatesNodesNodeUidSplitsSplitLfStatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listLfStatesNodesNodeUidSplitsSplitLfStatesGet(
      requestParameters: LfsApiListLfStatesNodesNodeUidSplitsSplitLfStatesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<LFStateResponse>> {
      return localVarFp
        .listLfStatesNodesNodeUidSplitsSplitLfStatesGet(
          requestParameters.nodeUid,
          requestParameters.split,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Returns the set of available LF states, given the current state + config. If all_states is set to True, then returns a list of all available LFs regardless current state.
     * @summary Next Lf States
     * @param {LfsApiNextLfStatesNodesNodeUidSplitsSplitNextLfStatesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost(
      requestParameters: LfsApiNextLfStatesNodesNodeUidSplitsSplitNextLfStatesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<NextLFStatesResponse> {
      return localVarFp
        .nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.nextLFStatesRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Post Autotuners
     * @param {LfsApiPostAutotunersNodesNodeUidAutotunersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAutotunersNodesNodeUidAutotunersPost(
      requestParameters: LfsApiPostAutotunersNodesNodeUidAutotunersPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .postAutotunersNodesNodeUidAutotunersPost(
          requestParameters.nodeUid,
          requestParameters.postAutotunersRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Create multiple new LFs based on LF Generator.
     * @summary Post Generators
     * @param {LfsApiPostGeneratorsNodesNodeUidGeneratorsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGeneratorsNodesNodeUidGeneratorsPost(
      requestParameters: LfsApiPostGeneratorsNodesNodeUidGeneratorsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .postGeneratorsNodesNodeUidGeneratorsPost(
          requestParameters.nodeUid,
          requestParameters.postGeneratorsRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Create a new LF.
     * @summary Post Lf
     * @param {LfsApiPostLfNodesNodeUidLfsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLfNodesNodeUidLfsPost(
      requestParameters: LfsApiPostLfNodesNodeUidLfsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .postLfNodesNodeUidLfsPost(
          requestParameters.nodeUid,
          requestParameters.postLFRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Executes a state transition (which allows us to keep information between LFs)
     * @summary Transition Lf State
     * @param {LfsApiTransitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost(
      requestParameters: LfsApiTransitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LFStateResponse> {
      return localVarFp
        .transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.lFStateRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Suggestion State
     * @param {LfsApiUpdateSuggestionStateNodesNodeUidLfsLfUidSuggestionPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut(
      requestParameters: LfsApiUpdateSuggestionStateNodesNodeUidLfsLfUidSuggestionPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UpdateSuggestionStateResponse> {
      return localVarFp
        .updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          requestParameters.updateSuggestionStatePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost operation in LfsApi.
 * @export
 * @interface LfsApiAdvancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePostRequest
 */
export interface LfsApiAdvancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiAdvancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof LfsApiAdvancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost
   */
  readonly split: string;

  /**
   *
   * @type {AdvancedStateRequest}
   * @memberof LfsApiAdvancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost
   */
  readonly advancedStateRequest: AdvancedStateRequest;
}

/**
 * Request parameters for archiveLfNodesNodeUidLfsLfUidDelete operation in LfsApi.
 * @export
 * @interface LfsApiArchiveLfNodesNodeUidLfsLfUidDeleteRequest
 */
export interface LfsApiArchiveLfNodesNodeUidLfsLfUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiArchiveLfNodesNodeUidLfsLfUidDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfsApiArchiveLfNodesNodeUidLfsLfUidDelete
   */
  readonly lfUid: number;
}

/**
 * Request parameters for archiveLfsNodesNodeUidLfsDelete operation in LfsApi.
 * @export
 * @interface LfsApiArchiveLfsNodesNodeUidLfsDeleteRequest
 */
export interface LfsApiArchiveLfsNodesNodeUidLfsDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiArchiveLfsNodesNodeUidLfsDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {BulkDeleteLFs}
   * @memberof LfsApiArchiveLfsNodesNodeUidLfsDelete
   */
  readonly bulkDeleteLFs?: BulkDeleteLFs;
}

/**
 * Request parameters for computeScoresNodesNodeUidComputeScoresGet operation in LfsApi.
 * @export
 * @interface LfsApiComputeScoresNodesNodeUidComputeScoresGetRequest
 */
export interface LfsApiComputeScoresNodesNodeUidComputeScoresGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiComputeScoresNodesNodeUidComputeScoresGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof LfsApiComputeScoresNodesNodeUidComputeScoresGet
   */
  readonly forActiveLfs?: boolean;
}

/**
 * Request parameters for fetchLfVotedInfoNodesNodeUidLfsVotedInfoPost operation in LfsApi.
 * @export
 * @interface LfsApiFetchLfVotedInfoNodesNodeUidLfsVotedInfoPostRequest
 */
export interface LfsApiFetchLfVotedInfoNodesNodeUidLfsVotedInfoPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiFetchLfVotedInfoNodesNodeUidLfsVotedInfoPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {FetchLFVotedInfoParams}
   * @memberof LfsApiFetchLfVotedInfoNodesNodeUidLfsVotedInfoPost
   */
  readonly fetchLFVotedInfoParams: FetchLFVotedInfoParams;
}

/**
 * Request parameters for getLfNodesNodeUidLfsLfUidGet operation in LfsApi.
 * @export
 * @interface LfsApiGetLfNodesNodeUidLfsLfUidGetRequest
 */
export interface LfsApiGetLfNodesNodeUidLfsLfUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiGetLfNodesNodeUidLfsLfUidGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfsApiGetLfNodesNodeUidLfsLfUidGet
   */
  readonly lfUid: number;
}

/**
 * Request parameters for getLfsNodesNodeUidLfsGet operation in LfsApi.
 * @export
 * @interface LfsApiGetLfsNodesNodeUidLfsGetRequest
 */
export interface LfsApiGetLfsNodesNodeUidLfsGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiGetLfsNodesNodeUidLfsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for listLfStatesNodesNodeUidSplitsSplitLfStatesGet operation in LfsApi.
 * @export
 * @interface LfsApiListLfStatesNodesNodeUidSplitsSplitLfStatesGetRequest
 */
export interface LfsApiListLfStatesNodesNodeUidSplitsSplitLfStatesGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiListLfStatesNodesNodeUidSplitsSplitLfStatesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof LfsApiListLfStatesNodesNodeUidSplitsSplitLfStatesGet
   */
  readonly split: string;
}

/**
 * Request parameters for nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost operation in LfsApi.
 * @export
 * @interface LfsApiNextLfStatesNodesNodeUidSplitsSplitNextLfStatesPostRequest
 */
export interface LfsApiNextLfStatesNodesNodeUidSplitsSplitNextLfStatesPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiNextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof LfsApiNextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost
   */
  readonly split: string;

  /**
   *
   * @type {NextLFStatesRequest}
   * @memberof LfsApiNextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost
   */
  readonly nextLFStatesRequest: NextLFStatesRequest;
}

/**
 * Request parameters for postAutotunersNodesNodeUidAutotunersPost operation in LfsApi.
 * @export
 * @interface LfsApiPostAutotunersNodesNodeUidAutotunersPostRequest
 */
export interface LfsApiPostAutotunersNodesNodeUidAutotunersPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiPostAutotunersNodesNodeUidAutotunersPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {PostAutotunersRequestBody}
   * @memberof LfsApiPostAutotunersNodesNodeUidAutotunersPost
   */
  readonly postAutotunersRequestBody: PostAutotunersRequestBody;
}

/**
 * Request parameters for postGeneratorsNodesNodeUidGeneratorsPost operation in LfsApi.
 * @export
 * @interface LfsApiPostGeneratorsNodesNodeUidGeneratorsPostRequest
 */
export interface LfsApiPostGeneratorsNodesNodeUidGeneratorsPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiPostGeneratorsNodesNodeUidGeneratorsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {PostGeneratorsRequestBody}
   * @memberof LfsApiPostGeneratorsNodesNodeUidGeneratorsPost
   */
  readonly postGeneratorsRequestBody: PostGeneratorsRequestBody;
}

/**
 * Request parameters for postLfNodesNodeUidLfsPost operation in LfsApi.
 * @export
 * @interface LfsApiPostLfNodesNodeUidLfsPostRequest
 */
export interface LfsApiPostLfNodesNodeUidLfsPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiPostLfNodesNodeUidLfsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {PostLFRequestBody}
   * @memberof LfsApiPostLfNodesNodeUidLfsPost
   */
  readonly postLFRequestBody: PostLFRequestBody;
}

/**
 * Request parameters for transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost operation in LfsApi.
 * @export
 * @interface LfsApiTransitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePostRequest
 */
export interface LfsApiTransitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiTransitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof LfsApiTransitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost
   */
  readonly split: string;

  /**
   *
   * @type {LFStateRequest}
   * @memberof LfsApiTransitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost
   */
  readonly lFStateRequest: LFStateRequest;
}

/**
 * Request parameters for updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut operation in LfsApi.
 * @export
 * @interface LfsApiUpdateSuggestionStateNodesNodeUidLfsLfUidSuggestionPutRequest
 */
export interface LfsApiUpdateSuggestionStateNodesNodeUidLfsLfUidSuggestionPutRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiUpdateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfsApiUpdateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut
   */
  readonly lfUid: number;

  /**
   *
   * @type {UpdateSuggestionStatePayload}
   * @memberof LfsApiUpdateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut
   */
  readonly updateSuggestionStatePayload: UpdateSuggestionStatePayload;
}

/**
 * LfsApi - object-oriented interface
 * @export
 * @class LfsApi
 * @extends {BaseAPI}
 */
export class LfsApi extends BaseAPI {
  /**
   * Returns the default configuration options for an LF state
   * @summary Advanced Lf State
   * @param {LfsApiAdvancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost(
    requestParameters: LfsApiAdvancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .advancedLfStateNodesNodeUidSplitsSplitAdvancedLfStatePost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.advancedStateRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Remove an LF from the list of active LFs.  Note that this method does not remove the LF from TDM.
   * @summary Archive Lf
   * @param {LfsApiArchiveLfNodesNodeUidLfsLfUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public archiveLfNodesNodeUidLfsLfUidDelete(
    requestParameters: LfsApiArchiveLfNodesNodeUidLfsLfUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .archiveLfNodesNodeUidLfsLfUidDelete(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Remove all LFs for the given task from ActiveLFs
   * @summary Archive Lfs
   * @param {LfsApiArchiveLfsNodesNodeUidLfsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public archiveLfsNodesNodeUidLfsDelete(
    requestParameters: LfsApiArchiveLfsNodesNodeUidLfsDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .archiveLfsNodesNodeUidLfsDelete(
        requestParameters.nodeUid,
        requestParameters.bulkDeleteLFs,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Return a list of active LFs that are below a score threshold .
   * @summary Compute Scores
   * @param {LfsApiComputeScoresNodesNodeUidComputeScoresGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public computeScoresNodesNodeUidComputeScoresGet(
    requestParameters: LfsApiComputeScoresNodesNodeUidComputeScoresGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .computeScoresNodesNodeUidComputeScoresGet(
        requestParameters.nodeUid,
        requestParameters.forActiveLfs,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch LF voted info (correct, incorrect, unknown) for given x_uids for active LFs.
   * @summary Fetch Lf Voted Info
   * @param {LfsApiFetchLfVotedInfoNodesNodeUidLfsVotedInfoPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public fetchLfVotedInfoNodesNodeUidLfsVotedInfoPost(
    requestParameters: LfsApiFetchLfVotedInfoNodesNodeUidLfsVotedInfoPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .fetchLfVotedInfoNodesNodeUidLfsVotedInfoPost(
        requestParameters.nodeUid,
        requestParameters.fetchLFVotedInfoParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch a single LF by uid.
   * @summary Get Lf
   * @param {LfsApiGetLfNodesNodeUidLfsLfUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public getLfNodesNodeUidLfsLfUidGet(
    requestParameters: LfsApiGetLfNodesNodeUidLfsLfUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .getLfNodesNodeUidLfsLfUidGet(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Return all active LF configs for the given task.
   * @summary Get Lfs
   * @param {LfsApiGetLfsNodesNodeUidLfsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public getLfsNodesNodeUidLfsGet(
    requestParameters: LfsApiGetLfsNodesNodeUidLfsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .getLfsNodesNodeUidLfsGet(requestParameters.nodeUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Lf States
   * @param {LfsApiListLfStatesNodesNodeUidSplitsSplitLfStatesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public listLfStatesNodesNodeUidSplitsSplitLfStatesGet(
    requestParameters: LfsApiListLfStatesNodesNodeUidSplitsSplitLfStatesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .listLfStatesNodesNodeUidSplitsSplitLfStatesGet(
        requestParameters.nodeUid,
        requestParameters.split,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Returns the set of available LF states, given the current state + config. If all_states is set to True, then returns a list of all available LFs regardless current state.
   * @summary Next Lf States
   * @param {LfsApiNextLfStatesNodesNodeUidSplitsSplitNextLfStatesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost(
    requestParameters: LfsApiNextLfStatesNodesNodeUidSplitsSplitNextLfStatesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .nextLfStatesNodesNodeUidSplitsSplitNextLfStatesPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.nextLFStatesRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Autotuners
   * @param {LfsApiPostAutotunersNodesNodeUidAutotunersPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public postAutotunersNodesNodeUidAutotunersPost(
    requestParameters: LfsApiPostAutotunersNodesNodeUidAutotunersPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .postAutotunersNodesNodeUidAutotunersPost(
        requestParameters.nodeUid,
        requestParameters.postAutotunersRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Create multiple new LFs based on LF Generator.
   * @summary Post Generators
   * @param {LfsApiPostGeneratorsNodesNodeUidGeneratorsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public postGeneratorsNodesNodeUidGeneratorsPost(
    requestParameters: LfsApiPostGeneratorsNodesNodeUidGeneratorsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .postGeneratorsNodesNodeUidGeneratorsPost(
        requestParameters.nodeUid,
        requestParameters.postGeneratorsRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Create a new LF.
   * @summary Post Lf
   * @param {LfsApiPostLfNodesNodeUidLfsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public postLfNodesNodeUidLfsPost(
    requestParameters: LfsApiPostLfNodesNodeUidLfsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .postLfNodesNodeUidLfsPost(
        requestParameters.nodeUid,
        requestParameters.postLFRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Executes a state transition (which allows us to keep information between LFs)
   * @summary Transition Lf State
   * @param {LfsApiTransitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost(
    requestParameters: LfsApiTransitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .transitionLfStateNodesNodeUidSplitsSplitTransitionLfStatePost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.lFStateRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Suggestion State
   * @param {LfsApiUpdateSuggestionStateNodesNodeUidLfsLfUidSuggestionPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut(
    requestParameters: LfsApiUpdateSuggestionStateNodesNodeUidLfsLfUidSuggestionPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .updateSuggestionStateNodesNodeUidLfsLfUidSuggestionPut(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        requestParameters.updateSuggestionStatePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * MarginSuggestionsApi - axios parameter creator
 * @export
 */
export const MarginSuggestionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Margin Filter
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} modelUid
     * @param {MarginFilterRequest} marginFilterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost:
      async (
        nodeUid: number,
        split: string,
        modelUid: number,
        marginFilterRequest: MarginFilterRequest,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost',
          'split',
          split,
        );
        // verify required parameter 'modelUid' is not null or undefined
        assertParamExists(
          'getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost',
          'modelUid',
          modelUid,
        );
        // verify required parameter 'marginFilterRequest' is not null or undefined
        assertParamExists(
          'getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost',
          'marginFilterRequest',
          marginFilterRequest,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/models/{model_uid}/margin-filtering`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)))
            .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          marginFilterRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Margin Matrix
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} modelUid
     * @param {string} [multiLabelClass]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet: async (
      nodeUid: number,
      split: string,
      modelUid: number,
      multiLabelClass?: string,
      applyPostprocessors?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet',
        'split',
        split,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet',
        'modelUid',
        modelUid,
      );
      const localVarPath =
        `/nodes/{node_uid}/splits/{split}/models/{model_uid}/margin-matrix`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(`{${'split'}}`, encodeURIComponent(String(split)))
          .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (multiLabelClass !== undefined) {
        localVarQueryParameter['multi_label_class'] = multiLabelClass;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MarginSuggestionsApi - functional programming interface
 * @export
 */
export const MarginSuggestionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MarginSuggestionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Margin Filter
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} modelUid
     * @param {MarginFilterRequest} marginFilterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost(
      nodeUid: number,
      split: string,
      modelUid: number,
      marginFilterRequest: MarginFilterRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MarginFilterResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost(
          nodeUid,
          split,
          modelUid,
          marginFilterRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MarginSuggestionsApi.getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Margin Matrix
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} modelUid
     * @param {string} [multiLabelClass]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet(
      nodeUid: number,
      split: string,
      modelUid: number,
      multiLabelClass?: string,
      applyPostprocessors?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MarginMatrixResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet(
          nodeUid,
          split,
          modelUid,
          multiLabelClass,
          applyPostprocessors,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MarginSuggestionsApi.getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * MarginSuggestionsApi - factory interface
 * @export
 */
export const MarginSuggestionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MarginSuggestionsApiFp(configuration);
  return {
    /**
     *
     * @summary Get Margin Filter
     * @param {MarginSuggestionsApiGetMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost(
      requestParameters: MarginSuggestionsApiGetMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MarginFilterResponse> {
      return localVarFp
        .getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.modelUid,
          requestParameters.marginFilterRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Margin Matrix
     * @param {MarginSuggestionsApiGetMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet(
      requestParameters: MarginSuggestionsApiGetMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MarginMatrixResponse> {
      return localVarFp
        .getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.modelUid,
          requestParameters.multiLabelClass,
          requestParameters.applyPostprocessors,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost operation in MarginSuggestionsApi.
 * @export
 * @interface MarginSuggestionsApiGetMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPostRequest
 */
export interface MarginSuggestionsApiGetMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPostRequest {
  /**
   *
   * @type {number}
   * @memberof MarginSuggestionsApiGetMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof MarginSuggestionsApiGetMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof MarginSuggestionsApiGetMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost
   */
  readonly modelUid: number;

  /**
   *
   * @type {MarginFilterRequest}
   * @memberof MarginSuggestionsApiGetMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost
   */
  readonly marginFilterRequest: MarginFilterRequest;
}

/**
 * Request parameters for getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet operation in MarginSuggestionsApi.
 * @export
 * @interface MarginSuggestionsApiGetMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGetRequest
 */
export interface MarginSuggestionsApiGetMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGetRequest {
  /**
   *
   * @type {number}
   * @memberof MarginSuggestionsApiGetMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof MarginSuggestionsApiGetMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof MarginSuggestionsApiGetMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {string}
   * @memberof MarginSuggestionsApiGetMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet
   */
  readonly multiLabelClass?: string;

  /**
   *
   * @type {boolean}
   * @memberof MarginSuggestionsApiGetMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet
   */
  readonly applyPostprocessors?: boolean;
}

/**
 * MarginSuggestionsApi - object-oriented interface
 * @export
 * @class MarginSuggestionsApi
 * @extends {BaseAPI}
 */
export class MarginSuggestionsApi extends BaseAPI {
  /**
   *
   * @summary Get Margin Filter
   * @param {MarginSuggestionsApiGetMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarginSuggestionsApi
   */
  public getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost(
    requestParameters: MarginSuggestionsApiGetMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MarginSuggestionsApiFp(this.configuration)
      .getMarginFilterNodesNodeUidSplitsSplitModelsModelUidMarginFilteringPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.modelUid,
        requestParameters.marginFilterRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Margin Matrix
   * @param {MarginSuggestionsApiGetMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarginSuggestionsApi
   */
  public getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet(
    requestParameters: MarginSuggestionsApiGetMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MarginSuggestionsApiFp(this.configuration)
      .getMarginMatrixNodesNodeUidSplitsSplitModelsModelUidMarginMatrixGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.modelUid,
        requestParameters.multiLabelClass,
        requestParameters.applyPostprocessors,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * MemoryProfilingApi - axios parameter creator
 * @export
 */
export const MemoryProfilingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearProfilingMemoryProfileClearPost: async (
      memoryProfilingParams: MemoryProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memoryProfilingParams' is not null or undefined
      assertParamExists(
        'clearProfilingMemoryProfileClearPost',
        'memoryProfilingParams',
        memoryProfilingParams,
      );
      const localVarPath = `/memory-profile/clear`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        memoryProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Tracemalloc Profile
     * @param {number} [topK]
     * @param {number} [depth]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTracemallocProfileTracemallocGet: async (
      topK?: number,
      depth?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tracemalloc`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (topK !== undefined) {
        localVarQueryParameter['top_k'] = topK;
      }

      if (depth !== undefined) {
        localVarQueryParameter['depth'] = depth;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the Profile backtrace from Redis/disk
     * @summary Memory Profile
     * @param {string} service
     * @param {string} [pid]
     * @param {PersistenceMode} [persistenceMode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    memoryProfileMemoryProfileGet: async (
      service: string,
      pid?: string,
      persistenceMode?: PersistenceMode,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'service' is not null or undefined
      assertParamExists('memoryProfileMemoryProfileGet', 'service', service);
      const localVarPath = `/memory-profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (service !== undefined) {
        localVarQueryParameter['service'] = service;
      }

      if (pid !== undefined) {
        localVarQueryParameter['pid'] = pid;
      }

      if (persistenceMode !== undefined) {
        localVarQueryParameter['persistence_mode'] = persistenceMode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startProfilingMemoryProfileStartPost: async (
      memoryProfilingParams: MemoryProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memoryProfilingParams' is not null or undefined
      assertParamExists(
        'startProfilingMemoryProfileStartPost',
        'memoryProfilingParams',
        memoryProfilingParams,
      );
      const localVarPath = `/memory-profile/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        memoryProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start Tracemalloc
     * @param {StartTraceMalloc} startTraceMalloc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startTracemallocTracemallocStartPost: async (
      startTraceMalloc: StartTraceMalloc,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'startTraceMalloc' is not null or undefined
      assertParamExists(
        'startTracemallocTracemallocStartPost',
        'startTraceMalloc',
        startTraceMalloc,
      );
      const localVarPath = `/tracemalloc/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        startTraceMalloc,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopProfilingMemoryProfileStopPost: async (
      memoryProfilingParams: MemoryProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memoryProfilingParams' is not null or undefined
      assertParamExists(
        'stopProfilingMemoryProfileStopPost',
        'memoryProfilingParams',
        memoryProfilingParams,
      );
      const localVarPath = `/memory-profile/stop`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        memoryProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stop Tracemalloc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopTracemallocTracemallocStopPost: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tracemalloc/stop`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MemoryProfilingApi - functional programming interface
 * @export
 */
export const MemoryProfilingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MemoryProfilingApiAxiosParamCreator(configuration);
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearProfilingMemoryProfileClearPost(
      memoryProfilingParams: MemoryProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MemoryProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.clearProfilingMemoryProfileClearPost(
          memoryProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.clearProfilingMemoryProfileClearPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Tracemalloc Profile
     * @param {number} [topK]
     * @param {number} [depth]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTracemallocProfileTracemallocGet(
      topK?: number,
      depth?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTracemallocProfileTracemallocGet(
          topK,
          depth,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.getTracemallocProfileTracemallocGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get the Profile backtrace from Redis/disk
     * @summary Memory Profile
     * @param {string} service
     * @param {string} [pid]
     * @param {PersistenceMode} [persistenceMode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async memoryProfileMemoryProfileGet(
      service: string,
      pid?: string,
      persistenceMode?: PersistenceMode,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MemoryProfilingTraceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.memoryProfileMemoryProfileGet(
          service,
          pid,
          persistenceMode,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.memoryProfileMemoryProfileGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startProfilingMemoryProfileStartPost(
      memoryProfilingParams: MemoryProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MemoryProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.startProfilingMemoryProfileStartPost(
          memoryProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.startProfilingMemoryProfileStartPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Start Tracemalloc
     * @param {StartTraceMalloc} startTraceMalloc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startTracemallocTracemallocStartPost(
      startTraceMalloc: StartTraceMalloc,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.startTracemallocTracemallocStartPost(
          startTraceMalloc,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.startTracemallocTracemallocStartPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopProfilingMemoryProfileStopPost(
      memoryProfilingParams: MemoryProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MemoryProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stopProfilingMemoryProfileStopPost(
          memoryProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.stopProfilingMemoryProfileStopPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Stop Tracemalloc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopTracemallocTracemallocStopPost(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stopTracemallocTracemallocStopPost(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.stopTracemallocTracemallocStopPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * MemoryProfilingApi - factory interface
 * @export
 */
export const MemoryProfilingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MemoryProfilingApiFp(configuration);
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearProfilingMemoryProfileClearPost(
      requestParameters: MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MemoryProfilingStatusResponse> {
      return localVarFp
        .clearProfilingMemoryProfileClearPost(
          requestParameters.memoryProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Tracemalloc Profile
     * @param {MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTracemallocProfileTracemallocGet(
      requestParameters: MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<string> {
      return localVarFp
        .getTracemallocProfileTracemallocGet(
          requestParameters.topK,
          requestParameters.depth,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Get the Profile backtrace from Redis/disk
     * @summary Memory Profile
     * @param {MemoryProfilingApiMemoryProfileMemoryProfileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    memoryProfileMemoryProfileGet(
      requestParameters: MemoryProfilingApiMemoryProfileMemoryProfileGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MemoryProfilingTraceResponse> {
      return localVarFp
        .memoryProfileMemoryProfileGet(
          requestParameters.service,
          requestParameters.pid,
          requestParameters.persistenceMode,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startProfilingMemoryProfileStartPost(
      requestParameters: MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MemoryProfilingStatusResponse> {
      return localVarFp
        .startProfilingMemoryProfileStartPost(
          requestParameters.memoryProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Start Tracemalloc
     * @param {MemoryProfilingApiStartTracemallocTracemallocStartPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startTracemallocTracemallocStartPost(
      requestParameters: MemoryProfilingApiStartTracemallocTracemallocStartPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .startTracemallocTracemallocStartPost(
          requestParameters.startTraceMalloc,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopProfilingMemoryProfileStopPost(
      requestParameters: MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MemoryProfilingStatusResponse> {
      return localVarFp
        .stopProfilingMemoryProfileStopPost(
          requestParameters.memoryProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Stop Tracemalloc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopTracemallocTracemallocStopPost(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .stopTracemallocTracemallocStopPost(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for clearProfilingMemoryProfileClearPost operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest
 */
export interface MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest {
  /**
   *
   * @type {MemoryProfilingParams}
   * @memberof MemoryProfilingApiClearProfilingMemoryProfileClearPost
   */
  readonly memoryProfilingParams: MemoryProfilingParams;
}

/**
 * Request parameters for getTracemallocProfileTracemallocGet operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest
 */
export interface MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest {
  /**
   *
   * @type {number}
   * @memberof MemoryProfilingApiGetTracemallocProfileTracemallocGet
   */
  readonly topK?: number;

  /**
   *
   * @type {number}
   * @memberof MemoryProfilingApiGetTracemallocProfileTracemallocGet
   */
  readonly depth?: number;
}

/**
 * Request parameters for memoryProfileMemoryProfileGet operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiMemoryProfileMemoryProfileGetRequest
 */
export interface MemoryProfilingApiMemoryProfileMemoryProfileGetRequest {
  /**
   *
   * @type {string}
   * @memberof MemoryProfilingApiMemoryProfileMemoryProfileGet
   */
  readonly service: string;

  /**
   *
   * @type {string}
   * @memberof MemoryProfilingApiMemoryProfileMemoryProfileGet
   */
  readonly pid?: string;

  /**
   *
   * @type {PersistenceMode}
   * @memberof MemoryProfilingApiMemoryProfileMemoryProfileGet
   */
  readonly persistenceMode?: PersistenceMode;
}

/**
 * Request parameters for startProfilingMemoryProfileStartPost operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest
 */
export interface MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest {
  /**
   *
   * @type {MemoryProfilingParams}
   * @memberof MemoryProfilingApiStartProfilingMemoryProfileStartPost
   */
  readonly memoryProfilingParams: MemoryProfilingParams;
}

/**
 * Request parameters for startTracemallocTracemallocStartPost operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiStartTracemallocTracemallocStartPostRequest
 */
export interface MemoryProfilingApiStartTracemallocTracemallocStartPostRequest {
  /**
   *
   * @type {StartTraceMalloc}
   * @memberof MemoryProfilingApiStartTracemallocTracemallocStartPost
   */
  readonly startTraceMalloc: StartTraceMalloc;
}

/**
 * Request parameters for stopProfilingMemoryProfileStopPost operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest
 */
export interface MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest {
  /**
   *
   * @type {MemoryProfilingParams}
   * @memberof MemoryProfilingApiStopProfilingMemoryProfileStopPost
   */
  readonly memoryProfilingParams: MemoryProfilingParams;
}

/**
 * MemoryProfilingApi - object-oriented interface
 * @export
 * @class MemoryProfilingApi
 * @extends {BaseAPI}
 */
export class MemoryProfilingApi extends BaseAPI {
  /**
   * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
   * @summary Clear Profiling
   * @param {MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public clearProfilingMemoryProfileClearPost(
    requestParameters: MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .clearProfilingMemoryProfileClearPost(
        requestParameters.memoryProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Tracemalloc Profile
   * @param {MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public getTracemallocProfileTracemallocGet(
    requestParameters: MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .getTracemallocProfileTracemallocGet(
        requestParameters.topK,
        requestParameters.depth,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get the Profile backtrace from Redis/disk
   * @summary Memory Profile
   * @param {MemoryProfilingApiMemoryProfileMemoryProfileGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public memoryProfileMemoryProfileGet(
    requestParameters: MemoryProfilingApiMemoryProfileMemoryProfileGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .memoryProfileMemoryProfileGet(
        requestParameters.service,
        requestParameters.pid,
        requestParameters.persistenceMode,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Start profiling
   * @summary Start Profiling
   * @param {MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public startProfilingMemoryProfileStartPost(
    requestParameters: MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .startProfilingMemoryProfileStartPost(
        requestParameters.memoryProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start Tracemalloc
   * @param {MemoryProfilingApiStartTracemallocTracemallocStartPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public startTracemallocTracemallocStartPost(
    requestParameters: MemoryProfilingApiStartTracemallocTracemallocStartPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .startTracemallocTracemallocStartPost(
        requestParameters.startTraceMalloc,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Stop profiling
   * @summary Stop Profiling
   * @param {MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public stopProfilingMemoryProfileStopPost(
    requestParameters: MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .stopProfilingMemoryProfileStopPost(
        requestParameters.memoryProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stop Tracemalloc
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public stopTracemallocTracemallocStopPost(options?: RawAxiosRequestConfig) {
    return MemoryProfilingApiFp(this.configuration)
      .stopTracemallocTracemallocStopPost(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * OnboardingApi - axios parameter creator
 * @export
 */
export const OnboardingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get supported onboarding models for a node  Returns:     SupportedOnboardingModels: supported onboarding models     List of available models for the node depending on the node type and the user\'s external credentials
     * @summary Get Supported Onboarding Models
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGet:
      async (
        nodeUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGet',
          'nodeUid',
          nodeUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/get-supported-onboarding-models`.replace(
            `{${'node_uid'}}`,
            encodeURIComponent(String(nodeUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * OnboardingApi - functional programming interface
 * @export
 */
export const OnboardingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OnboardingApiAxiosParamCreator(configuration);
  return {
    /**
     * Get supported onboarding models for a node  Returns:     SupportedOnboardingModels: supported onboarding models     List of available models for the node depending on the node type and the user\'s external credentials
     * @summary Get Supported Onboarding Models
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupportedOnboardingModels>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OnboardingApi.getSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OnboardingApi - factory interface
 * @export
 */
export const OnboardingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OnboardingApiFp(configuration);
  return {
    /**
     * Get supported onboarding models for a node  Returns:     SupportedOnboardingModels: supported onboarding models     List of available models for the node depending on the node type and the user\'s external credentials
     * @summary Get Supported Onboarding Models
     * @param {OnboardingApiGetSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGet(
      requestParameters: OnboardingApiGetSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SupportedOnboardingModels> {
      return localVarFp
        .getSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGet operation in OnboardingApi.
 * @export
 * @interface OnboardingApiGetSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGetRequest
 */
export interface OnboardingApiGetSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGetRequest {
  /**
   *
   * @type {number}
   * @memberof OnboardingApiGetSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGet
   */
  readonly nodeUid: number;
}

/**
 * OnboardingApi - object-oriented interface
 * @export
 * @class OnboardingApi
 * @extends {BaseAPI}
 */
export class OnboardingApi extends BaseAPI {
  /**
   * Get supported onboarding models for a node  Returns:     SupportedOnboardingModels: supported onboarding models     List of available models for the node depending on the node type and the user\'s external credentials
   * @summary Get Supported Onboarding Models
   * @param {OnboardingApiGetSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnboardingApi
   */
  public getSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGet(
    requestParameters: OnboardingApiGetSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OnboardingApiFp(this.configuration)
      .getSupportedOnboardingModelsNodesNodeUidGetSupportedOnboardingModelsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PackageApi - axios parameter creator
 * @export
 */
export const PackageApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Export Lf
     * @param {number} nodeUid
     * @param {ExportLFRequestBody} [exportLFRequestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportLfNodesNodeUidLfsExportPost: async (
      nodeUid: number,
      exportLFRequestBody?: ExportLFRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'exportLfNodesNodeUidLfsExportPost',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/lfs/export`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        exportLFRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import Lf
     * @param {number} nodeUid
     * @param {number} packageVersion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importLfNodesNodeUidLfPackagesPackageVersionImportPost: async (
      nodeUid: number,
      packageVersion: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'importLfNodesNodeUidLfPackagesPackageVersionImportPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'packageVersion' is not null or undefined
      assertParamExists(
        'importLfNodesNodeUidLfPackagesPackageVersionImportPost',
        'packageVersion',
        packageVersion,
      );
      const localVarPath =
        `/nodes/{node_uid}/lf-packages/{package_version}/import`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(
            `{${'package_version'}}`,
            encodeURIComponent(String(packageVersion)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PackageApi - functional programming interface
 * @export
 */
export const PackageApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PackageApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Export Lf
     * @param {number} nodeUid
     * @param {ExportLFRequestBody} [exportLFRequestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportLfNodesNodeUidLfsExportPost(
      nodeUid: number,
      exportLFRequestBody?: ExportLFRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportLfNodesNodeUidLfsExportPost(
          nodeUid,
          exportLFRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PackageApi.exportLfNodesNodeUidLfsExportPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Import Lf
     * @param {number} nodeUid
     * @param {number} packageVersion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importLfNodesNodeUidLfPackagesPackageVersionImportPost(
      nodeUid: number,
      packageVersion: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.importLfNodesNodeUidLfPackagesPackageVersionImportPost(
          nodeUid,
          packageVersion,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PackageApi.importLfNodesNodeUidLfPackagesPackageVersionImportPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PackageApi - factory interface
 * @export
 */
export const PackageApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PackageApiFp(configuration);
  return {
    /**
     *
     * @summary Export Lf
     * @param {PackageApiExportLfNodesNodeUidLfsExportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportLfNodesNodeUidLfsExportPost(
      requestParameters: PackageApiExportLfNodesNodeUidLfsExportPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .exportLfNodesNodeUidLfsExportPost(
          requestParameters.nodeUid,
          requestParameters.exportLFRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Import Lf
     * @param {PackageApiImportLfNodesNodeUidLfPackagesPackageVersionImportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importLfNodesNodeUidLfPackagesPackageVersionImportPost(
      requestParameters: PackageApiImportLfNodesNodeUidLfPackagesPackageVersionImportPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .importLfNodesNodeUidLfPackagesPackageVersionImportPost(
          requestParameters.nodeUid,
          requestParameters.packageVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for exportLfNodesNodeUidLfsExportPost operation in PackageApi.
 * @export
 * @interface PackageApiExportLfNodesNodeUidLfsExportPostRequest
 */
export interface PackageApiExportLfNodesNodeUidLfsExportPostRequest {
  /**
   *
   * @type {number}
   * @memberof PackageApiExportLfNodesNodeUidLfsExportPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ExportLFRequestBody}
   * @memberof PackageApiExportLfNodesNodeUidLfsExportPost
   */
  readonly exportLFRequestBody?: ExportLFRequestBody;
}

/**
 * Request parameters for importLfNodesNodeUidLfPackagesPackageVersionImportPost operation in PackageApi.
 * @export
 * @interface PackageApiImportLfNodesNodeUidLfPackagesPackageVersionImportPostRequest
 */
export interface PackageApiImportLfNodesNodeUidLfPackagesPackageVersionImportPostRequest {
  /**
   *
   * @type {number}
   * @memberof PackageApiImportLfNodesNodeUidLfPackagesPackageVersionImportPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof PackageApiImportLfNodesNodeUidLfPackagesPackageVersionImportPost
   */
  readonly packageVersion: number;
}

/**
 * PackageApi - object-oriented interface
 * @export
 * @class PackageApi
 * @extends {BaseAPI}
 */
export class PackageApi extends BaseAPI {
  /**
   *
   * @summary Export Lf
   * @param {PackageApiExportLfNodesNodeUidLfsExportPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PackageApi
   */
  public exportLfNodesNodeUidLfsExportPost(
    requestParameters: PackageApiExportLfNodesNodeUidLfsExportPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PackageApiFp(this.configuration)
      .exportLfNodesNodeUidLfsExportPost(
        requestParameters.nodeUid,
        requestParameters.exportLFRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import Lf
   * @param {PackageApiImportLfNodesNodeUidLfPackagesPackageVersionImportPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PackageApi
   */
  public importLfNodesNodeUidLfPackagesPackageVersionImportPost(
    requestParameters: PackageApiImportLfNodesNodeUidLfPackagesPackageVersionImportPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PackageApiFp(this.configuration)
      .importLfNodesNodeUidLfPackagesPackageVersionImportPost(
        requestParameters.nodeUid,
        requestParameters.packageVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PredictionsApi - axios parameter creator
 * @export
 */
export const PredictionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Export Data
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<string>} [prediction]
     * @param {string} [searchStr]
     * @param {string} [format]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportDataNodesNodeUidModelsModelUidExportGet: async (
      nodeUid: number,
      modelUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      prediction?: Array<string>,
      searchStr?: string,
      format?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'exportDataNodesNodeUidModelsModelUidExportGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'exportDataNodesNodeUidModelsModelUidExportGet',
        'modelUid',
        modelUid,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}/export`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (prediction) {
        localVarQueryParameter['prediction'] = prediction;
      }

      if (searchStr !== undefined) {
        localVarQueryParameter['search_str'] = searchStr;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Data
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<string>} [prediction]
     * @param {string} [searchStr]
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDataNodesNodeUidModelsModelUidDataGet: async (
      nodeUid: number,
      modelUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      prediction?: Array<string>,
      searchStr?: string,
      offset?: number,
      limit?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchDataNodesNodeUidModelsModelUidDataGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'fetchDataNodesNodeUidModelsModelUidDataGet',
        'modelUid',
        modelUid,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}/data`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (prediction) {
        localVarQueryParameter['prediction'] = prediction;
      }

      if (searchStr !== undefined) {
        localVarQueryParameter['search_str'] = searchStr;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PredictionsApi - functional programming interface
 * @export
 */
export const PredictionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PredictionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Export Data
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<string>} [prediction]
     * @param {string} [searchStr]
     * @param {string} [format]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportDataNodesNodeUidModelsModelUidExportGet(
      nodeUid: number,
      modelUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      prediction?: Array<string>,
      searchStr?: string,
      format?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportDataNodesNodeUidModelsModelUidExportGet(
          nodeUid,
          modelUid,
          split,
          startDate,
          endDate,
          prediction,
          searchStr,
          format,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PredictionsApi.exportDataNodesNodeUidModelsModelUidExportGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Data
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<string>} [prediction]
     * @param {string} [searchStr]
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDataNodesNodeUidModelsModelUidDataGet(
      nodeUid: number,
      modelUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      prediction?: Array<string>,
      searchStr?: string,
      offset?: number,
      limit?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchDataNodesNodeUidModelsModelUidDataGet(
          nodeUid,
          modelUid,
          split,
          startDate,
          endDate,
          prediction,
          searchStr,
          offset,
          limit,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PredictionsApi.fetchDataNodesNodeUidModelsModelUidDataGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PredictionsApi - factory interface
 * @export
 */
export const PredictionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PredictionsApiFp(configuration);
  return {
    /**
     *
     * @summary Export Data
     * @param {PredictionsApiExportDataNodesNodeUidModelsModelUidExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportDataNodesNodeUidModelsModelUidExportGet(
      requestParameters: PredictionsApiExportDataNodesNodeUidModelsModelUidExportGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .exportDataNodesNodeUidModelsModelUidExportGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          requestParameters.prediction,
          requestParameters.searchStr,
          requestParameters.format,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Data
     * @param {PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDataNodesNodeUidModelsModelUidDataGet(
      requestParameters: PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchDataNodesNodeUidModelsModelUidDataGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          requestParameters.prediction,
          requestParameters.searchStr,
          requestParameters.offset,
          requestParameters.limit,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for exportDataNodesNodeUidModelsModelUidExportGet operation in PredictionsApi.
 * @export
 * @interface PredictionsApiExportDataNodesNodeUidModelsModelUidExportGetRequest
 */
export interface PredictionsApiExportDataNodesNodeUidModelsModelUidExportGetRequest {
  /**
   *
   * @type {number}
   * @memberof PredictionsApiExportDataNodesNodeUidModelsModelUidExportGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof PredictionsApiExportDataNodesNodeUidModelsModelUidExportGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiExportDataNodesNodeUidModelsModelUidExportGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiExportDataNodesNodeUidModelsModelUidExportGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiExportDataNodesNodeUidModelsModelUidExportGet
   */
  readonly endDate?: string;

  /**
   *
   * @type {Array<string>}
   * @memberof PredictionsApiExportDataNodesNodeUidModelsModelUidExportGet
   */
  readonly prediction?: Array<string>;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiExportDataNodesNodeUidModelsModelUidExportGet
   */
  readonly searchStr?: string;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiExportDataNodesNodeUidModelsModelUidExportGet
   */
  readonly format?: string;
}

/**
 * Request parameters for fetchDataNodesNodeUidModelsModelUidDataGet operation in PredictionsApi.
 * @export
 * @interface PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGetRequest
 */
export interface PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGetRequest {
  /**
   *
   * @type {number}
   * @memberof PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGet
   */
  readonly endDate?: string;

  /**
   *
   * @type {Array<string>}
   * @memberof PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGet
   */
  readonly prediction?: Array<string>;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGet
   */
  readonly searchStr?: string;

  /**
   *
   * @type {number}
   * @memberof PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGet
   */
  readonly offset?: number;

  /**
   *
   * @type {number}
   * @memberof PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGet
   */
  readonly limit?: number;
}

/**
 * PredictionsApi - object-oriented interface
 * @export
 * @class PredictionsApi
 * @extends {BaseAPI}
 */
export class PredictionsApi extends BaseAPI {
  /**
   *
   * @summary Export Data
   * @param {PredictionsApiExportDataNodesNodeUidModelsModelUidExportGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictionsApi
   */
  public exportDataNodesNodeUidModelsModelUidExportGet(
    requestParameters: PredictionsApiExportDataNodesNodeUidModelsModelUidExportGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PredictionsApiFp(this.configuration)
      .exportDataNodesNodeUidModelsModelUidExportGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.prediction,
        requestParameters.searchStr,
        requestParameters.format,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Data
   * @param {PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictionsApi
   */
  public fetchDataNodesNodeUidModelsModelUidDataGet(
    requestParameters: PredictionsApiFetchDataNodesNodeUidModelsModelUidDataGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PredictionsApiFp(this.configuration)
      .fetchDataNodesNodeUidModelsModelUidDataGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.prediction,
        requestParameters.searchStr,
        requestParameters.offset,
        requestParameters.limit,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PreviewApi - axios parameter creator
 * @export
 */
export const PreviewApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Preview Sample
     * @param {number} nodeUid
     * @param {CreatePreviewRequest} createPreviewRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPreviewSampleNodesNodeUidPreviewCreatePost: async (
      nodeUid: number,
      createPreviewRequest: CreatePreviewRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'createPreviewSampleNodesNodeUidPreviewCreatePost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'createPreviewRequest' is not null or undefined
      assertParamExists(
        'createPreviewSampleNodesNodeUidPreviewCreatePost',
        'createPreviewRequest',
        createPreviewRequest,
      );
      const localVarPath = `/nodes/{node_uid}/preview/create`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPreviewRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Preview Sample
     * @param {number} nodeUid
     * @param {GetPreviewRequest} getPreviewRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPreviewSampleNodesNodeUidPreviewGetPost: async (
      nodeUid: number,
      getPreviewRequest: GetPreviewRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getPreviewSampleNodesNodeUidPreviewGetPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'getPreviewRequest' is not null or undefined
      assertParamExists(
        'getPreviewSampleNodesNodeUidPreviewGetPost',
        'getPreviewRequest',
        getPreviewRequest,
      );
      const localVarPath = `/nodes/{node_uid}/preview/get`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getPreviewRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PreviewApi - functional programming interface
 * @export
 */
export const PreviewApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PreviewApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Preview Sample
     * @param {number} nodeUid
     * @param {CreatePreviewRequest} createPreviewRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPreviewSampleNodesNodeUidPreviewCreatePost(
      nodeUid: number,
      createPreviewRequest: CreatePreviewRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreatePreviewResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createPreviewSampleNodesNodeUidPreviewCreatePost(
          nodeUid,
          createPreviewRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PreviewApi.createPreviewSampleNodesNodeUidPreviewCreatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Preview Sample
     * @param {number} nodeUid
     * @param {GetPreviewRequest} getPreviewRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPreviewSampleNodesNodeUidPreviewGetPost(
      nodeUid: number,
      getPreviewRequest: GetPreviewRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPreviewResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPreviewSampleNodesNodeUidPreviewGetPost(
          nodeUid,
          getPreviewRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PreviewApi.getPreviewSampleNodesNodeUidPreviewGetPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PreviewApi - factory interface
 * @export
 */
export const PreviewApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PreviewApiFp(configuration);
  return {
    /**
     *
     * @summary Create Preview Sample
     * @param {PreviewApiCreatePreviewSampleNodesNodeUidPreviewCreatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPreviewSampleNodesNodeUidPreviewCreatePost(
      requestParameters: PreviewApiCreatePreviewSampleNodesNodeUidPreviewCreatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreatePreviewResponse> {
      return localVarFp
        .createPreviewSampleNodesNodeUidPreviewCreatePost(
          requestParameters.nodeUid,
          requestParameters.createPreviewRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Preview Sample
     * @param {PreviewApiGetPreviewSampleNodesNodeUidPreviewGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPreviewSampleNodesNodeUidPreviewGetPost(
      requestParameters: PreviewApiGetPreviewSampleNodesNodeUidPreviewGetPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetPreviewResponse> {
      return localVarFp
        .getPreviewSampleNodesNodeUidPreviewGetPost(
          requestParameters.nodeUid,
          requestParameters.getPreviewRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPreviewSampleNodesNodeUidPreviewCreatePost operation in PreviewApi.
 * @export
 * @interface PreviewApiCreatePreviewSampleNodesNodeUidPreviewCreatePostRequest
 */
export interface PreviewApiCreatePreviewSampleNodesNodeUidPreviewCreatePostRequest {
  /**
   *
   * @type {number}
   * @memberof PreviewApiCreatePreviewSampleNodesNodeUidPreviewCreatePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {CreatePreviewRequest}
   * @memberof PreviewApiCreatePreviewSampleNodesNodeUidPreviewCreatePost
   */
  readonly createPreviewRequest: CreatePreviewRequest;
}

/**
 * Request parameters for getPreviewSampleNodesNodeUidPreviewGetPost operation in PreviewApi.
 * @export
 * @interface PreviewApiGetPreviewSampleNodesNodeUidPreviewGetPostRequest
 */
export interface PreviewApiGetPreviewSampleNodesNodeUidPreviewGetPostRequest {
  /**
   *
   * @type {number}
   * @memberof PreviewApiGetPreviewSampleNodesNodeUidPreviewGetPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {GetPreviewRequest}
   * @memberof PreviewApiGetPreviewSampleNodesNodeUidPreviewGetPost
   */
  readonly getPreviewRequest: GetPreviewRequest;
}

/**
 * PreviewApi - object-oriented interface
 * @export
 * @class PreviewApi
 * @extends {BaseAPI}
 */
export class PreviewApi extends BaseAPI {
  /**
   *
   * @summary Create Preview Sample
   * @param {PreviewApiCreatePreviewSampleNodesNodeUidPreviewCreatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PreviewApi
   */
  public createPreviewSampleNodesNodeUidPreviewCreatePost(
    requestParameters: PreviewApiCreatePreviewSampleNodesNodeUidPreviewCreatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PreviewApiFp(this.configuration)
      .createPreviewSampleNodesNodeUidPreviewCreatePost(
        requestParameters.nodeUid,
        requestParameters.createPreviewRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Preview Sample
   * @param {PreviewApiGetPreviewSampleNodesNodeUidPreviewGetPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PreviewApi
   */
  public getPreviewSampleNodesNodeUidPreviewGetPost(
    requestParameters: PreviewApiGetPreviewSampleNodesNodeUidPreviewGetPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PreviewApiFp(this.configuration)
      .getPreviewSampleNodesNodeUidPreviewGetPost(
        requestParameters.nodeUid,
        requestParameters.getPreviewRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PromptApi - axios parameter creator
 * @export
 */
export const PromptApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Prompt Context Outputs
     * @param {number} nodeUid
     * @param {string} split
     * @param {PromptResultsInputRequest} promptResultsInputRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost:
      async (
        nodeUid: number,
        split: string,
        promptResultsInputRequest: PromptResultsInputRequest,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost',
          'split',
          split,
        );
        // verify required parameter 'promptResultsInputRequest' is not null or undefined
        assertParamExists(
          'getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost',
          'promptResultsInputRequest',
          promptResultsInputRequest,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/get-prompt-context-outputs`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          promptResultsInputRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Prompt Dataset Outputs
     * @param {number} nodeUid
     * @param {string} split
     * @param {PromptResultsInputRequest} promptResultsInputRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost:
      async (
        nodeUid: number,
        split: string,
        promptResultsInputRequest: PromptResultsInputRequest,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost',
          'split',
          split,
        );
        // verify required parameter 'promptResultsInputRequest' is not null or undefined
        assertParamExists(
          'getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost',
          'promptResultsInputRequest',
          promptResultsInputRequest,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/get-prompt-dataset-outputs`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          promptResultsInputRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Prompt Metrics
     * @param {number} nodeUid
     * @param {string} split
     * @param {PromptResultsInputRequest} promptResultsInputRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost: async (
      nodeUid: number,
      split: string,
      promptResultsInputRequest: PromptResultsInputRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost',
        'split',
        split,
      );
      // verify required parameter 'promptResultsInputRequest' is not null or undefined
      assertParamExists(
        'getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost',
        'promptResultsInputRequest',
        promptResultsInputRequest,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/prompt-metrics`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        promptResultsInputRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Prompt Outputs
     * @param {number} nodeUid
     * @param {string} split
     * @param {PromptResultsInputRequest} promptResultsInputRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost: async (
      nodeUid: number,
      split: string,
      promptResultsInputRequest: PromptResultsInputRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost',
        'split',
        split,
      );
      // verify required parameter 'promptResultsInputRequest' is not null or undefined
      assertParamExists(
        'getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost',
        'promptResultsInputRequest',
        promptResultsInputRequest,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/get-prompt-outputs`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        promptResultsInputRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Run Prompt Inference Endpoint
     * @param {number} nodeUid
     * @param {string} split
     * @param {RunPromptInferenceRequest} runPromptInferenceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost:
      async (
        nodeUid: number,
        split: string,
        runPromptInferenceRequest: RunPromptInferenceRequest,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'split' is not null or undefined
        assertParamExists(
          'runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost',
          'split',
          split,
        );
        // verify required parameter 'runPromptInferenceRequest' is not null or undefined
        assertParamExists(
          'runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost',
          'runPromptInferenceRequest',
          runPromptInferenceRequest,
        );
        const localVarPath =
          `/nodes/{node_uid}/splits/{split}/run-prompt-inference`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'split'}}`, encodeURIComponent(String(split)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          runPromptInferenceRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * PromptApi - functional programming interface
 * @export
 */
export const PromptApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PromptApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Prompt Context Outputs
     * @param {number} nodeUid
     * @param {string} split
     * @param {PromptResultsInputRequest} promptResultsInputRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost(
      nodeUid: number,
      split: string,
      promptResultsInputRequest: PromptResultsInputRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ContextOutputData>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost(
          nodeUid,
          split,
          promptResultsInputRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PromptApi.getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Prompt Dataset Outputs
     * @param {number} nodeUid
     * @param {string} split
     * @param {PromptResultsInputRequest} promptResultsInputRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost(
      nodeUid: number,
      split: string,
      promptResultsInputRequest: PromptResultsInputRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DatasetResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost(
          nodeUid,
          split,
          promptResultsInputRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PromptApi.getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Prompt Metrics
     * @param {number} nodeUid
     * @param {string} split
     * @param {PromptResultsInputRequest} promptResultsInputRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost(
      nodeUid: number,
      split: string,
      promptResultsInputRequest: PromptResultsInputRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PromptMetricsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost(
          nodeUid,
          split,
          promptResultsInputRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PromptApi.getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Prompt Outputs
     * @param {number} nodeUid
     * @param {string} split
     * @param {PromptResultsInputRequest} promptResultsInputRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost(
      nodeUid: number,
      split: string,
      promptResultsInputRequest: PromptResultsInputRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PromptDataOutputResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost(
          nodeUid,
          split,
          promptResultsInputRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PromptApi.getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Run Prompt Inference Endpoint
     * @param {number} nodeUid
     * @param {string} split
     * @param {RunPromptInferenceRequest} runPromptInferenceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost(
      nodeUid: number,
      split: string,
      runPromptInferenceRequest: RunPromptInferenceRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RunPromptOutputResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost(
          nodeUid,
          split,
          runPromptInferenceRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PromptApi.runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PromptApi - factory interface
 * @export
 */
export const PromptApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PromptApiFp(configuration);
  return {
    /**
     *
     * @summary Get Prompt Context Outputs
     * @param {PromptApiGetPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost(
      requestParameters: PromptApiGetPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContextOutputData> {
      return localVarFp
        .getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.promptResultsInputRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Prompt Dataset Outputs
     * @param {PromptApiGetPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost(
      requestParameters: PromptApiGetPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetResponse> {
      return localVarFp
        .getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.promptResultsInputRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Prompt Metrics
     * @param {PromptApiGetPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost(
      requestParameters: PromptApiGetPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PromptMetricsResponse> {
      return localVarFp
        .getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.promptResultsInputRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Prompt Outputs
     * @param {PromptApiGetPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost(
      requestParameters: PromptApiGetPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PromptDataOutputResponse> {
      return localVarFp
        .getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.promptResultsInputRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Run Prompt Inference Endpoint
     * @param {PromptApiRunPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost(
      requestParameters: PromptApiRunPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RunPromptOutputResponse> {
      return localVarFp
        .runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.runPromptInferenceRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost operation in PromptApi.
 * @export
 * @interface PromptApiGetPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPostRequest
 */
export interface PromptApiGetPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPostRequest {
  /**
   *
   * @type {number}
   * @memberof PromptApiGetPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof PromptApiGetPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost
   */
  readonly split: string;

  /**
   *
   * @type {PromptResultsInputRequest}
   * @memberof PromptApiGetPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost
   */
  readonly promptResultsInputRequest: PromptResultsInputRequest;
}

/**
 * Request parameters for getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost operation in PromptApi.
 * @export
 * @interface PromptApiGetPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPostRequest
 */
export interface PromptApiGetPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPostRequest {
  /**
   *
   * @type {number}
   * @memberof PromptApiGetPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof PromptApiGetPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost
   */
  readonly split: string;

  /**
   *
   * @type {PromptResultsInputRequest}
   * @memberof PromptApiGetPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost
   */
  readonly promptResultsInputRequest: PromptResultsInputRequest;
}

/**
 * Request parameters for getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost operation in PromptApi.
 * @export
 * @interface PromptApiGetPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPostRequest
 */
export interface PromptApiGetPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPostRequest {
  /**
   *
   * @type {number}
   * @memberof PromptApiGetPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof PromptApiGetPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost
   */
  readonly split: string;

  /**
   *
   * @type {PromptResultsInputRequest}
   * @memberof PromptApiGetPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost
   */
  readonly promptResultsInputRequest: PromptResultsInputRequest;
}

/**
 * Request parameters for getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost operation in PromptApi.
 * @export
 * @interface PromptApiGetPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPostRequest
 */
export interface PromptApiGetPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPostRequest {
  /**
   *
   * @type {number}
   * @memberof PromptApiGetPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof PromptApiGetPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost
   */
  readonly split: string;

  /**
   *
   * @type {PromptResultsInputRequest}
   * @memberof PromptApiGetPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost
   */
  readonly promptResultsInputRequest: PromptResultsInputRequest;
}

/**
 * Request parameters for runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost operation in PromptApi.
 * @export
 * @interface PromptApiRunPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePostRequest
 */
export interface PromptApiRunPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePostRequest {
  /**
   *
   * @type {number}
   * @memberof PromptApiRunPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof PromptApiRunPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost
   */
  readonly split: string;

  /**
   *
   * @type {RunPromptInferenceRequest}
   * @memberof PromptApiRunPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost
   */
  readonly runPromptInferenceRequest: RunPromptInferenceRequest;
}

/**
 * PromptApi - object-oriented interface
 * @export
 * @class PromptApi
 * @extends {BaseAPI}
 */
export class PromptApi extends BaseAPI {
  /**
   *
   * @summary Get Prompt Context Outputs
   * @param {PromptApiGetPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PromptApi
   */
  public getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost(
    requestParameters: PromptApiGetPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PromptApiFp(this.configuration)
      .getPromptContextOutputsNodesNodeUidSplitsSplitGetPromptContextOutputsPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.promptResultsInputRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Prompt Dataset Outputs
   * @param {PromptApiGetPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PromptApi
   */
  public getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost(
    requestParameters: PromptApiGetPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PromptApiFp(this.configuration)
      .getPromptDatasetOutputsNodesNodeUidSplitsSplitGetPromptDatasetOutputsPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.promptResultsInputRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Prompt Metrics
   * @param {PromptApiGetPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PromptApi
   */
  public getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost(
    requestParameters: PromptApiGetPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PromptApiFp(this.configuration)
      .getPromptMetricsNodesNodeUidSplitsSplitPromptMetricsPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.promptResultsInputRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Prompt Outputs
   * @param {PromptApiGetPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PromptApi
   */
  public getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost(
    requestParameters: PromptApiGetPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PromptApiFp(this.configuration)
      .getPromptOutputsNodesNodeUidSplitsSplitGetPromptOutputsPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.promptResultsInputRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Run Prompt Inference Endpoint
   * @param {PromptApiRunPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PromptApi
   */
  public runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost(
    requestParameters: PromptApiRunPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PromptApiFp(this.configuration)
      .runPromptInferenceEndpointNodesNodeUidSplitsSplitRunPromptInferencePost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.runPromptInferenceRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get all snippets for the matches of the given LF, provided as a subclass of the HighlightRequestBody.
     * @summary Search
     * @param {number} nodeUid
     * @param {string} split
     * @param {SearchRequestBody} searchRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchNodesNodeUidSplitsSplitSearchPost: async (
      nodeUid: number,
      split: string,
      searchRequestBody: SearchRequestBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'searchNodesNodeUidSplitsSplitSearchPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'searchNodesNodeUidSplitsSplitSearchPost',
        'split',
        split,
      );
      // verify required parameter 'searchRequestBody' is not null or undefined
      assertParamExists(
        'searchNodesNodeUidSplitsSplitSearchPost',
        'searchRequestBody',
        searchRequestBody,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/search`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration);
  return {
    /**
     * Get all snippets for the matches of the given LF, provided as a subclass of the HighlightRequestBody.
     * @summary Search
     * @param {number} nodeUid
     * @param {string} split
     * @param {SearchRequestBody} searchRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchNodesNodeUidSplitsSplitSearchPost(
      nodeUid: number,
      split: string,
      searchRequestBody: SearchRequestBody,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.searchNodesNodeUidSplitsSplitSearchPost(
          nodeUid,
          split,
          searchRequestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SearchApi.searchNodesNodeUidSplitsSplitSearchPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SearchApiFp(configuration);
  return {
    /**
     * Get all snippets for the matches of the given LF, provided as a subclass of the HighlightRequestBody.
     * @summary Search
     * @param {SearchApiSearchNodesNodeUidSplitsSplitSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchNodesNodeUidSplitsSplitSearchPost(
      requestParameters: SearchApiSearchNodesNodeUidSplitsSplitSearchPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SearchResponse> {
      return localVarFp
        .searchNodesNodeUidSplitsSplitSearchPost(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.searchRequestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for searchNodesNodeUidSplitsSplitSearchPost operation in SearchApi.
 * @export
 * @interface SearchApiSearchNodesNodeUidSplitsSplitSearchPostRequest
 */
export interface SearchApiSearchNodesNodeUidSplitsSplitSearchPostRequest {
  /**
   *
   * @type {number}
   * @memberof SearchApiSearchNodesNodeUidSplitsSplitSearchPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof SearchApiSearchNodesNodeUidSplitsSplitSearchPost
   */
  readonly split: string;

  /**
   *
   * @type {SearchRequestBody}
   * @memberof SearchApiSearchNodesNodeUidSplitsSplitSearchPost
   */
  readonly searchRequestBody: SearchRequestBody;
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
  /**
   * Get all snippets for the matches of the given LF, provided as a subclass of the HighlightRequestBody.
   * @summary Search
   * @param {SearchApiSearchNodesNodeUidSplitsSplitSearchPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public searchNodesNodeUidSplitsSplitSearchPost(
    requestParameters: SearchApiSearchNodesNodeUidSplitsSplitSearchPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SearchApiFp(this.configuration)
      .searchNodesNodeUidSplitsSplitSearchPost(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.searchRequestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SuggestionsApi - axios parameter creator
 * @export
 */
export const SuggestionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get LF and dataset statistics and return list of suggestions
     * @summary Get Suggestions
     * @param {number} nodeUid
     * @param {string} split
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuggestionsNodesNodeUidSplitsSplitSuggestionsGet: async (
      nodeUid: number,
      split: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getSuggestionsNodesNodeUidSplitsSplitSuggestionsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getSuggestionsNodesNodeUidSplitsSplitSuggestionsGet',
        'split',
        split,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/suggestions`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SuggestionsApi - functional programming interface
 * @export
 */
export const SuggestionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SuggestionsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get LF and dataset statistics and return list of suggestions
     * @summary Get Suggestions
     * @param {number} nodeUid
     * @param {string} split
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSuggestionsNodesNodeUidSplitsSplitSuggestionsGet(
      nodeUid: number,
      split: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSuggestionsNodesNodeUidSplitsSplitSuggestionsGet(
          nodeUid,
          split,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SuggestionsApi.getSuggestionsNodesNodeUidSplitsSplitSuggestionsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SuggestionsApi - factory interface
 * @export
 */
export const SuggestionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SuggestionsApiFp(configuration);
  return {
    /**
     * Get LF and dataset statistics and return list of suggestions
     * @summary Get Suggestions
     * @param {SuggestionsApiGetSuggestionsNodesNodeUidSplitsSplitSuggestionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuggestionsNodesNodeUidSplitsSplitSuggestionsGet(
      requestParameters: SuggestionsApiGetSuggestionsNodesNodeUidSplitsSplitSuggestionsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getSuggestionsNodesNodeUidSplitsSplitSuggestionsGet(
          requestParameters.nodeUid,
          requestParameters.split,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getSuggestionsNodesNodeUidSplitsSplitSuggestionsGet operation in SuggestionsApi.
 * @export
 * @interface SuggestionsApiGetSuggestionsNodesNodeUidSplitsSplitSuggestionsGetRequest
 */
export interface SuggestionsApiGetSuggestionsNodesNodeUidSplitsSplitSuggestionsGetRequest {
  /**
   *
   * @type {number}
   * @memberof SuggestionsApiGetSuggestionsNodesNodeUidSplitsSplitSuggestionsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof SuggestionsApiGetSuggestionsNodesNodeUidSplitsSplitSuggestionsGet
   */
  readonly split: string;
}

/**
 * SuggestionsApi - object-oriented interface
 * @export
 * @class SuggestionsApi
 * @extends {BaseAPI}
 */
export class SuggestionsApi extends BaseAPI {
  /**
   * Get LF and dataset statistics and return list of suggestions
   * @summary Get Suggestions
   * @param {SuggestionsApiGetSuggestionsNodesNodeUidSplitsSplitSuggestionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuggestionsApi
   */
  public getSuggestionsNodesNodeUidSplitsSplitSuggestionsGet(
    requestParameters: SuggestionsApiGetSuggestionsNodesNodeUidSplitsSplitSuggestionsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SuggestionsApiFp(this.configuration)
      .getSuggestionsNodesNodeUidSplitsSplitSuggestionsGet(
        requestParameters.nodeUid,
        requestParameters.split,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SummaryApi - axios parameter creator
 * @export
 */
export const SummaryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Return summary statistics for the current task.  IMPORTANT NOTE: Note in a DB TX due to a deadlock state with /lf-states
     * @summary Get Summary
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} [seed]
     * @param {boolean} [computeMatrices]
     * @param {Array<number>} [lfUidsFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSummaryNodesNodeUidSplitsSplitSummaryGet: async (
      nodeUid: number,
      split: string,
      seed?: number,
      computeMatrices?: boolean,
      lfUidsFilter?: Array<number>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getSummaryNodesNodeUidSplitsSplitSummaryGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getSummaryNodesNodeUidSplitsSplitSummaryGet',
        'split',
        split,
      );
      const localVarPath = `/nodes/{node_uid}/splits/{split}/summary`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'split'}}`, encodeURIComponent(String(split)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (seed !== undefined) {
        localVarQueryParameter['seed'] = seed;
      }

      if (computeMatrices !== undefined) {
        localVarQueryParameter['compute_matrices'] = computeMatrices;
      }

      if (lfUidsFilter) {
        localVarQueryParameter['lf_uids_filter'] = lfUidsFilter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SummaryApi - functional programming interface
 * @export
 */
export const SummaryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SummaryApiAxiosParamCreator(configuration);
  return {
    /**
     * Return summary statistics for the current task.  IMPORTANT NOTE: Note in a DB TX due to a deadlock state with /lf-states
     * @summary Get Summary
     * @param {number} nodeUid
     * @param {string} split
     * @param {number} [seed]
     * @param {boolean} [computeMatrices]
     * @param {Array<number>} [lfUidsFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSummaryNodesNodeUidSplitsSplitSummaryGet(
      nodeUid: number,
      split: string,
      seed?: number,
      computeMatrices?: boolean,
      lfUidsFilter?: Array<number>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LFSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSummaryNodesNodeUidSplitsSplitSummaryGet(
          nodeUid,
          split,
          seed,
          computeMatrices,
          lfUidsFilter,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SummaryApi.getSummaryNodesNodeUidSplitsSplitSummaryGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SummaryApi - factory interface
 * @export
 */
export const SummaryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SummaryApiFp(configuration);
  return {
    /**
     * Return summary statistics for the current task.  IMPORTANT NOTE: Note in a DB TX due to a deadlock state with /lf-states
     * @summary Get Summary
     * @param {SummaryApiGetSummaryNodesNodeUidSplitsSplitSummaryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSummaryNodesNodeUidSplitsSplitSummaryGet(
      requestParameters: SummaryApiGetSummaryNodesNodeUidSplitsSplitSummaryGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LFSummary> {
      return localVarFp
        .getSummaryNodesNodeUidSplitsSplitSummaryGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.seed,
          requestParameters.computeMatrices,
          requestParameters.lfUidsFilter,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getSummaryNodesNodeUidSplitsSplitSummaryGet operation in SummaryApi.
 * @export
 * @interface SummaryApiGetSummaryNodesNodeUidSplitsSplitSummaryGetRequest
 */
export interface SummaryApiGetSummaryNodesNodeUidSplitsSplitSummaryGetRequest {
  /**
   *
   * @type {number}
   * @memberof SummaryApiGetSummaryNodesNodeUidSplitsSplitSummaryGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof SummaryApiGetSummaryNodesNodeUidSplitsSplitSummaryGet
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof SummaryApiGetSummaryNodesNodeUidSplitsSplitSummaryGet
   */
  readonly seed?: number;

  /**
   *
   * @type {boolean}
   * @memberof SummaryApiGetSummaryNodesNodeUidSplitsSplitSummaryGet
   */
  readonly computeMatrices?: boolean;

  /**
   *
   * @type {Array<number>}
   * @memberof SummaryApiGetSummaryNodesNodeUidSplitsSplitSummaryGet
   */
  readonly lfUidsFilter?: Array<number>;
}

/**
 * SummaryApi - object-oriented interface
 * @export
 * @class SummaryApi
 * @extends {BaseAPI}
 */
export class SummaryApi extends BaseAPI {
  /**
   * Return summary statistics for the current task.  IMPORTANT NOTE: Note in a DB TX due to a deadlock state with /lf-states
   * @summary Get Summary
   * @param {SummaryApiGetSummaryNodesNodeUidSplitsSplitSummaryGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SummaryApi
   */
  public getSummaryNodesNodeUidSplitsSplitSummaryGet(
    requestParameters: SummaryApiGetSummaryNodesNodeUidSplitsSplitSummaryGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SummaryApiFp(this.configuration)
      .getSummaryNodesNodeUidSplitsSplitSummaryGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.seed,
        requestParameters.computeMatrices,
        requestParameters.lfUidsFilter,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}
