import { Tool } from '@app/StudioPage/components/LFComposerToolbar/types';
import type { DataSource, LFInfo, SplitType, TaskConfig } from '@core/types';
import type { User } from '@global/state/reducers/users/types';
import { createReducerSlice } from '@utils/redux/reducer';

import type { TaskSettings, TaskState } from './types';

const initialSettings: TaskSettings = {
  default_metrics: [],
  metrics: [],
  model_generated_bodies: [],
  models: [],
  primary_metric: '',
  suggest_field: '',
  supported_metrics: [],
  templates: [],
  viewer_highlight_configs: [],
  viewer_initially_deselected_fields: [],
  viewer_initially_selected_fields: [],
};

export const initialState: TaskState = {
  activeTaskDataSources: [],
  class_labels: [],
  dataInitialized: false,
  dataset_name: '',
  dataset_uid: undefined!,
  lfsInfo: [],
  loadingTaskDataSources: false,
  name: '0',
  processingTaskDataSources: false,
  settings: initialSettings,
  settings_loading: false,
  split: null as any,
  splits: [],
  task_config: { label_map: {}, inverse_label_map: {}, settings: {} },
  taskDataSources: [],
  tool: Tool.SEARCH,
  users: [],
};

const reducerSlice = createReducerSlice('task', initialState, {
  reset(_state: TaskState): TaskState {
    return initialState;
  },

  update(state: TaskState, delta: Partial<TaskState>): TaskState {
    return { ...state, ...delta };
  },

  setSplit(state: TaskState, split: SplitType): TaskState {
    return split === state.split ? state : { ...state, split };
  },

  setSplits(state: TaskState, splits: SplitType[]): TaskState {
    return splits === state.splits ? state : { ...state, splits };
  },

  setTool(state: TaskState, tool: Tool): TaskState {
    return tool === state.tool ? state : { ...state, tool };
  },

  setIsProcessingDataSources(
    state: TaskState,
    processingTaskDataSources: boolean,
  ): TaskState {
    return processingTaskDataSources === state.processingTaskDataSources
      ? state
      : { ...state, processingTaskDataSources };
  },

  setSettingsLoading(state: TaskState, settings_loading: boolean): TaskState {
    return settings_loading === state.settings_loading
      ? state
      : { ...state, settings_loading };
  },

  updateSettings(state: TaskState, settings: Partial<TaskSettings>): TaskState {
    return settings === state.settings
      ? state
      : { ...state, settings: { ...state.settings, ...settings } };
  },

  setDataInitialized(state: TaskState, dataInitialized: boolean): TaskState {
    return dataInitialized === state.dataInitialized
      ? state
      : { ...state, dataInitialized };
  },

  setLFsInfo(state: TaskState, lfsInfo: LFInfo[]): TaskState {
    return lfsInfo === state.lfsInfo ||
      (lfsInfo.length === 0 && state.lfsInfo.length === 0)
      ? state
      : { ...state, lfsInfo };
  },

  startLoadingTaskDatasources(state: TaskState): TaskState {
    return state.loadingTaskDataSources
      ? state
      : { ...state, loadingTaskDataSources: true };
  },

  setActiveTaskDatasources(
    state: TaskState,
    activeTaskDataSources: DataSource[],
  ): TaskState {
    return activeTaskDataSources === state.activeTaskDataSources ||
      (activeTaskDataSources?.length === 0 &&
        state.activeTaskDataSources.length === 0)
      ? state
      : { ...state, activeTaskDataSources };
  },

  setTaskDatasources(
    state: TaskState,
    {
      activeTaskDataSources,
      taskDataSources,
    }: { activeTaskDataSources: DataSource[]; taskDataSources: DataSource[] },
  ): TaskState {
    return {
      ...state,
      loadingTaskDataSources: false,
      activeTaskDataSources,
      taskDataSources,
    };
  },

  setUsers(state: TaskState, users: User[]): TaskState {
    return users === state.users ||
      (users.length === 0 && state.users.length === 0)
      ? state
      : { ...state, users };
  },

  setTaskConfig(state: TaskState, task_config: TaskConfig): TaskState {
    return task_config === state.task_config
      ? state
      : { ...state, task_config: { ...state.task_config, ...task_config } };
  },

  setName(state: TaskState, name: string): TaskState {
    return name === state.name ? state : { ...state, name };
  },

  setDatasetUid(state: TaskState, dataset_uid: number): TaskState {
    return dataset_uid === state.dataset_uid
      ? state
      : { ...state, dataset_uid };
  },

  setDatasetName(state: TaskState, dataset_name: string): TaskState {
    return dataset_name === state.dataset_name
      ? state
      : { ...state, dataset_name };
  },

  setDataSources(state: TaskState, dataSources: DataSource[]): TaskState {
    return dataSources === state.dataSources
      ? state
      : { ...state, dataSources };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;
