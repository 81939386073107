const getIsFlagFromQueryStringTrue = (
  flag: string,
  query: Record<string, any>,
) => {
  const key = `FLAG_${flag}`;

  return query[key] ? query[key] === 'true' : null;
};

export default getIsFlagFromQueryStringTrue;
