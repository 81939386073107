import { isEmpty } from 'lodash';

import type { DatasetResponse } from '@api/tdm';
import { Icons } from '@coral/components/Icon';
import type {
  NavigationId,
  NavigationItem,
} from '@global/NavigationContainer/types';
import type { AppDetailsState } from '@global/state/reducers/appDetails/types';

import {
  ANNOTATE,
  BATCHES,
  DEVELOP,
  EVALUATE,
  JOBS,
  OVERVIEW,
} from '../../constants';

export const isNavItemVisible = ({
  itemId,
  isSetupComplete,
  isMultiSchemaAnnotationEnabled = false,
  isLLMFineTuningNode = false,
}: {
  itemId: NavigationId;
  isSetupComplete: boolean;
  isMultiSchemaAnnotationEnabled?: boolean | undefined;
  isLLMFineTuningNode?: boolean;
}) => {
  switch (itemId) {
    case 'application-setup':
      return false;
    case 'overview':
    case 'develop':
    case 'jobs':
      return isSetupComplete;
    case 'evaluate':
      return isSetupComplete && isLLMFineTuningNode;
    case 'annotate':
    case 'batch':
      return isSetupComplete && !isMultiSchemaAnnotationEnabled;
    case 'pipeline':
      return false;
    default:
      return false;
  }
};

const calcAppNodeNavItems = ({
  application,
  nodeId,
  path,
  dataset,
  isLLMFineTuningNode = false,
}: {
  application: AppDetailsState;
  nodeId?: number | null;
  path: string;
  dataset?: DatasetResponse | undefined;
  isLLMFineTuningNode?: boolean;
}): NavigationItem[] => {
  const { isSetupComplete = false } = application || {};
  const { metadata } = dataset ?? {};
  const { enable_mta: isMultiSchemaAnnotationEnabled = false } = metadata ?? {};

  if (!application || isEmpty(application) || !nodeId || !isSetupComplete) {
    return [];
  }

  const navItems: NavigationItem[] = [
    {
      id: 'overview',
      href: `/applications/${application.id}/nodes/${nodeId}/overview`,
      icon: Icons.MODEL__2,
      title: OVERVIEW,
      isVisible: isNavItemVisible({ itemId: 'overview', isSetupComplete }),
      isDisabled: !application,
      isSelected:
        path.includes('applications') &&
        path.includes('nodes') &&
        path.includes('overview'),
    },
    {
      id: 'develop',
      href: `/applications/${application.id}/nodes/${nodeId}/develop`,
      icon: Icons.DEVELOPMENT,
      title: DEVELOP,
      isVisible: isNavItemVisible({ itemId: 'develop', isSetupComplete }),
      isDisabled: !application,
      isSelected: path.includes('develop'),
    },
    {
      id: 'evaluate',
      href: `/applications/${application.id}/nodes/${nodeId}/evaluate`,
      icon: Icons.ANALYSIS,
      title: EVALUATE,
      isVisible: isNavItemVisible({
        itemId: 'evaluate',
        isSetupComplete,
        isLLMFineTuningNode,
      }),
      isDisabled: !application,
      isSelected: path.includes('evaluate'),
    },
    {
      id: 'annotate',
      href: `/applications/${application.id}/nodes/${nodeId}/annotate`,
      icon: Icons.DOCUMENTATION,
      title: ANNOTATE,
      isVisible: isNavItemVisible({
        itemId: 'annotate',
        isSetupComplete,
        isMultiSchemaAnnotationEnabled,
      }),
      isDisabled: !application,
      isSelected:
        path.includes('annotate') && !path.includes('annotate/batches'),
      isLoading: false,
      subItems: [
        {
          id: 'batch',
          href: `/applications/${application.id}/nodes/${nodeId}/annotate/batches`,
          icon: Icons.BATCH,
          title: BATCHES,
          isVisible:
            path.includes('/annotate') &&
            isNavItemVisible({
              itemId: 'batch',
              isSetupComplete,
              isMultiSchemaAnnotationEnabled,
            }),
          isDisabled: false,
          isSelected: path.includes('annotate/batches'),
        },
      ],
    },
    {
      id: 'jobs',
      href: `/applications/${application.id}/nodes/${nodeId}/jobs`,
      icon: Icons.LIST_CHECK,
      title: JOBS,
      isVisible: isNavItemVisible({
        itemId: 'jobs',
        isSetupComplete,
      }),
      isDisabled: !application,
      isSelected: path.includes('jobs'),
    },
  ];

  return navItems;
};

export default calcAppNodeNavItems;
