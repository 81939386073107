import { PromptDataState } from '@app/StudioPage/components/PromptZone/types';
import { createReducerSlice } from '@utils/redux/reducer';

import type { AtomsState } from './types';

export const initialState: AtomsState = {
  promptModelName: null,
  promptState: PromptDataState.NOT_STARTED,
  promptTemplateDropdownLabel: null,
};

const reducerSlice = createReducerSlice('studioPage/atoms', initialState, {
  setPromptModelName(
    state: AtomsState,
    promptModelName: AtomsState['promptModelName'],
  ): AtomsState {
    return promptModelName === state.promptModelName
      ? state
      : { ...state, promptModelName };
  },

  setPromptState(
    state: AtomsState,
    promptState: AtomsState['promptState'],
  ): AtomsState {
    return promptState === state.promptState
      ? state
      : { ...state, promptState };
  },

  setPromptTemplateDropdownLabel(
    state: AtomsState,
    promptTemplateDropdownLabel: AtomsState['promptTemplateDropdownLabel'],
  ): AtomsState {
    return promptTemplateDropdownLabel === state.promptTemplateDropdownLabel
      ? state
      : { ...state, promptTemplateDropdownLabel };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;
