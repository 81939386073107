import type { Reducer, ReducersMapObject, UnknownAction } from 'redux';
import { combineReducers } from 'redux';

import { reducer as atomsReducer } from './atoms/slice';
import { reducer as dataFiltersReducer } from './dataFilters/slice';
import { reducer as docFiltersReducer } from './docFilters/slice';
import { reducer as plotReducer } from './plot/slice';
import { reducer as searchInputReducer } from './searchInput/slice';
import { reducer as similarityLfBuilderReducer } from './similarityLfBuilder/slice';
import { reducer as studioReducer } from './studio/slice';
import { reducer as suggestedSearchesReducer } from './suggestedSearches/slice';
import type { StudioPageState } from './types';

const studioPageReducersMap: ReducersMapObject<StudioPageState, UnknownAction> =
  {
    atoms: atomsReducer,
    dataFilters: dataFiltersReducer,
    docFilters: docFiltersReducer,
    plot: plotReducer,
    searchInput: searchInputReducer,
    studio: studioReducer,
    similarityLfBuilder: similarityLfBuilderReducer,
    suggestedSearches: suggestedSearchesReducer,
  } as ReducersMapObject<StudioPageState, UnknownAction>;

export const studioPageReducer: Reducer<StudioPageState> = combineReducers(
  studioPageReducersMap,
);

export default null;
