import { flow } from 'lodash';
import { twMerge } from 'tailwind-merge';

const combineClasses = (
  ...classes: Array<string | undefined | Record<string, any>>
) =>
  classes
    .reduce((className, currentValue) => {
      if (!currentValue) return className;

      if (typeof currentValue === 'object') {
        return Object.entries(currentValue).reduce((acc, [key, value]) => {
          if (value) return `${acc} ${key}`;

          return acc;
        }, className);
      }

      return `${className} ${currentValue}`;
    }, '')
    ?.trimStart();

export const combineTwClasses = flow(combineClasses, twMerge);

export default combineClasses;
