import * as Sentry from '@sentry/nextjs';

import { SENTRY_DSN, SENTRY_ENVIRONMENT } from '@core/constants';

const initSentry = () => {
  if (!SENTRY_DSN) {
    return;
  }

  Sentry.init({
    autoSessionTracking: false,
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllInputs: true,
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 1.0,
  });
};

export default initSentry;
