import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '@global/state/actions';
import type { User } from '@global/state/reducers/users/types';
import * as selectors from '@global/state/selectors';
import useFetchUsers from '@hooks/useFetchUsers';
import useRequest from '@hooks/useRequestWithLogging';

const UsersInitializer = () => {
  const dispatch = useDispatch();
  const selectedWorkspace = useSelector(
    selectors.workspaces.selectSelectedWorkspace,
  );
  const users = useSelector(selectors.users.selectUsers);
  const request = useRequest();
  const { fetchUsers } = useFetchUsers(request);

  const pullUsers = async (currentUsers: User[], shouldIgnore = false) => {
    dispatch(actions.users.setIsUsersDataLoading(true));

    if (!isNil(selectedWorkspace) && !isNil(selectedWorkspace.workspace_uid)) {
      const nextWorkspaceUsers = await fetchUsers(
        currentUsers,
        selectedWorkspace.workspace_uid!,
      );

      if (!shouldIgnore) {
        dispatch(actions.users.setWorkspaceUsers(nextWorkspaceUsers));
      }
    }

    const nextUsers = await fetchUsers(currentUsers);

    if (!shouldIgnore) {
      dispatch(actions.users.setUsers(nextUsers));
    }

    dispatch(actions.users.setIsUsersDataLoading(false));
  };

  useEffect(() => {
    let shouldIgnore = false;

    pullUsers(users, shouldIgnore);

    return () => {
      shouldIgnore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedWorkspace)]);

  return null;
};

export default UsersInitializer;
