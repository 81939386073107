import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from '@global/state/actions';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import { Pages } from '@utils/goToPage';

const useUserLastNodePageVisited = () => {
  const dispatch = useDispatch();
  const { asPath, query: { appId, nodeId } = {} } = useSnorkelRouter();

  const setUserLastNodePageVisited = useCallback(
    (path: string) => {
      if (!(typeof nodeId === 'string' && typeof appId === 'string')) {
        return;
      }

      const page = (path.split(/nodes\/[0-9]+\//)?.[1] || '').toUpperCase();

      dispatch(
        actions.navigation.setMetaLastNodePage({
          appId: parseInt(appId as string, 10),
          nodeId: parseInt(nodeId as string, 10),
          // @ts-ignore
          page: Pages[page] || Pages.OVERVIEW,
        }),
      );
    },
    [appId, dispatch, nodeId],
  );

  useEffect(() => {
    setUserLastNodePageVisited(asPath);
  }, [asPath, setUserLastNodePageVisited]);
};

export default useUserLastNodePageVisited;
