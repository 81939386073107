globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"1784387f303977777f72af8336ea7c6a0bd50c20"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production',
});
