import React from 'react';

import type { TextProps } from './types';
import { TextSize, Variant } from './types';
import getElement from './utils/getElement';
import getTextSize from './utils/getTextSize';
import getTextSpacing from './utils/getTextSpacing';
import getTextWeight from './utils/getTextWeight';

const combineClasses = (...classes: Array<string | undefined>) =>
  classes.filter((className: string | undefined) => className).join(' ');

/** @deprecated */
const DeprecatedText = ({
  children,
  size = TextSize.main,
  variant = Variant.body,
  href,
  className,
  prefixEl,
  postfixEl,
  ...rest
}: TextProps) => {
  return (
    <span
      className={combineClasses(
        className,
        href ? 'text-blue-700 underline' : 'text-gray-900 no-underline',
        getTextSize(variant, size),
        getTextWeight(variant, size),
        getTextSpacing(variant, size),
        size === TextSize.xsmall && variant === Variant.heading
          ? 'uppercase'
          : '',
        variant === Variant.mono ? 'font-mono' : 'font-regular',
        'inline-flex items-center gap-2',
      )}
      {...rest}
    >
      {prefixEl}
      {getElement(variant, size, href)(children)}
      {postfixEl}
    </span>
  );
};

export * from './types';
export default DeprecatedText;
