import type { GlobalPreferences, ViewConfig } from '@api/tdm';
import { Tool } from '@app/StudioPage/components/LFComposerToolbar/types';
import { emptyObj } from '@coral/empty';
import { ALL_LF_METRICS_OPTIONS, LFMetricNames } from '@core/types';
import type { RootState, Selector } from '@global/state/types';
import { memoizeOne } from '@global/state/utils/memoizeOne';
import pipe from '@utils/pipe';
import { TaskTypes } from '@utils/task_types';

import type { UserSettings } from './slice';

import { selectIsSingleDatapointView } from '../atoms/selectors';
import { selectFlags } from '../flags/selectors';
import { selectTaskConfig, selectTool } from '../task/selectors';

export const selectAll: Selector<UserSettings> = ({
  userSettings,
}: RootState) => userSettings.settings ?? emptyObj;

export const selectGlobalPreferences: Selector<GlobalPreferences> = pipe(
  selectAll,
  ({ global_preferences }: UserSettings) => global_preferences ?? emptyObj,
);

export const selectDagManipulationEnabled: Selector<
  UserSettings['dag_manipulation_enabled']
> = pipe(
  selectAll,
  ({ dag_manipulation_enabled }: UserSettings) => dag_manipulation_enabled,
);

export const selectLFPreferences: Selector<UserSettings['lf_preferences']> =
  pipe(selectAll, ({ lf_preferences }: UserSettings) => lf_preferences);

const DEFAULT_SELECTED_METRICS = [
  LFMetricNames.VOTED,
  LFMetricNames.PRECISION,
  LFMetricNames.COVERAGE,
];

const DEFAULT_SELECTED_METRICS_TSAD = [
  LFMetricNames.VOTED,
  LFMetricNames.PRECISION,
  // Replacing coverage with recall for anomaly detection
  LFMetricNames.RECALL,
];

const DEFAULT_SELECTED_METRICS_ESTIMATED = [
  LFMetricNames.VOTED,
  LFMetricNames.EST_PRECISION,
  LFMetricNames.COVERAGE,
];

const DEFAULT_SELECTED_METRICS_TSAD_ESTIMATED = [
  LFMetricNames.VOTED,
  LFMetricNames.EST_PRECISION,
  // Replacing coverage with recall for anomaly detection
  LFMetricNames.EST_RECALL,
];

function getDefaultSelectedMetrics(
  isAnomalyDetectionNode: boolean,
  estimatedLfStatsDefault: boolean,
) {
  const selectDefaultNotEstimatedMetrics = isAnomalyDetectionNode
    ? DEFAULT_SELECTED_METRICS_TSAD
    : DEFAULT_SELECTED_METRICS;
  const selectDefaultEstimatedMetrics = isAnomalyDetectionNode
    ? DEFAULT_SELECTED_METRICS_TSAD_ESTIMATED
    : DEFAULT_SELECTED_METRICS_ESTIMATED;
  const selectDefaultMetrics = estimatedLfStatsDefault
    ? selectDefaultEstimatedMetrics
    : selectDefaultNotEstimatedMetrics;

  return selectDefaultMetrics;
}

export const selectSelectedMetrics = (
  state: RootState,
  isAnomalyDetectionNode: boolean,
): string[] => {
  const preferences = selectLFPreferences(state);
  const estimatedLfStatsDefault =
    !!selectFlags(state).ESTIMATED_LF_STATS_DEFAULT;

  return (
    preferences?.selected_metrics ??
    getDefaultSelectedMetrics(isAnomalyDetectionNode, estimatedLfStatsDefault)
  );
};

const filterMetrics = (state: RootState, metrics: LFMetricNames[]) => {
  const { type: taskType = '' } = selectTaskConfig(state);

  const isModeSingleDatapointView = selectIsSingleDatapointView(state);
  const selectedTool = selectTool(state);

  const sequenceTaggingMetrics = [
    LFMetricNames.COUNT,
    LFMetricNames.EMP_SUPPORT,
  ];

  return metrics.filter(metric => {
    if (metric === LFMetricNames.VOTED) {
      return selectedTool === Tool.SEARCH && isModeSingleDatapointView;
    }

    return (
      taskType !== TaskTypes.SEQUENCE_TAGGING ||
      !sequenceTaggingMetrics.includes(metric)
    );
  }) as LFMetricNames[];
};

const memoizedFilterMetrics = memoizeOne(filterMetrics);

export const selectFilteredSelectedMetrics = (
  state: RootState,
  isAnomalyDetectionNode: boolean,
): LFMetricNames[] => {
  return memoizedFilterMetrics(
    state,
    selectSelectedMetrics(state, isAnomalyDetectionNode) as LFMetricNames[],
  );
};

export const selectFilteredMetrics: Selector<string[]> = (state: RootState) => {
  return memoizedFilterMetrics(state, ALL_LF_METRICS_OPTIONS);
};

export const selectModelPreferences: Selector<
  UserSettings['model_preferences']
> = pipe(selectAll, ({ model_preferences }: UserSettings) => model_preferences);

export const selectPlotGroundTruth: Selector<
  UserSettings['plot_ground_truth']
> = pipe(selectAll, ({ plot_ground_truth }: UserSettings) => plot_ground_truth);

export const selectStudioPreferences: Selector<
  UserSettings['studio_preferences']
> = pipe(
  selectAll,
  ({ studio_preferences }: UserSettings) => studio_preferences,
);

export const selectStudioViewConfig: Selector<ViewConfig | undefined> = pipe(
  selectAll,
  ({ studio_preferences }: UserSettings) => studio_preferences?.view_config,
);

export const selectWorkspaceType: Selector<UserSettings['workspace_type']> =
  pipe(selectAll, ({ workspace_type }: UserSettings) => workspace_type);

export const selectMTAPreferences: Selector<UserSettings['mta_preferences']> =
  pipe(selectAll, ({ mta_preferences }: UserSettings) => mta_preferences);

export const selectIsFetchingUserSettings: Selector<boolean> = ({
  userSettings,
}: RootState) => userSettings.isFetching;

export const selectCustomColors: Selector<UserSettings['custom_colors']> = pipe(
  selectAll,
  ({ custom_colors }: UserSettings) => custom_colors,
);

export const selectLabelColorScheme: Selector<
  UserSettings['label_color_scheme']
> = pipe(
  selectAll,
  ({ label_color_scheme }: UserSettings) => label_color_scheme,
);
