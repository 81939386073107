import type { AxiosResponse } from 'axios';
import Router from 'next/router';

import { calcApiUrl } from '@utils/calcApiUrl';
import { normalizeUrl } from '@utils/normalizeUrl';

import init from './init';

export interface RequestError extends Error {
  type: string;
  hasJSONError?: boolean;
  status: number;
  statusText: string | null;
  body: any;
  response?: Response | AxiosResponse;
}

export enum RequestStatus {
  IDLE,
  LOADING,
  ERROR,
  SUCCESS,
}

export const push = (path: string, normalize: boolean = true) => {
  if (normalize) {
    return Router.router?.push(normalizeUrl(path));
  }

  return Router.router?.push(path);
};

export const replace = (path: string) => {
  return Router.router?.replace(normalizeUrl(path), undefined, {
    shallow: true,
  });
};

export const clientFetch = async (
  path: string,
  data?: any,
  method?: string,
  options?: {
    [option: string]: any;
  },
) => {
  const apiUrl = calcApiUrl();

  const url = [apiUrl, path].join('/');

  const initialOptions = init(data, method, options);

  return fetch(url, initialOptions);
};

export const clientRequest = async (
  path: string,
  data?: any,
  method?: string,
  options?: {
    [option: string]: any;
  },
) => {
  const result = await clientFetch(path, data, method, options);

  if (result.status === 204) return {};

  try {
    let body = '';

    try {
      body = await result.json();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({ e });
    }

    if (result.status >= 400) {
      const error: RequestError = {
        ...new Error(),
        type: 'api-error',
        status: result.status,
        statusText: result.statusText,
        body,
      };

      throw error;
    }

    return body;
  } catch (e: any) {
    if (options?.handleError) {
      return e;
    }

    if (result.status >= 400) {
      throw e;
    }

    // eslint-disable-next-line no-console
    console.error(e);

    return undefined;
  }
};
