/* eslint-disable local-rules/require-data-cy */
import React, { useState } from 'react';

import Button, { ButtonThemes } from '@coral/components/Button';
import type { ButtonProps } from '@coral/components/Button';

interface DialogButtonProps extends ButtonProps {
  onClose?: Function;
  buttonText: string;
  isLoadingOverride?: boolean;
  buttonLoadingText?: string;
  buttonDataCy?: string;
  handleConfirm?: Function;
  syncHandleConfirm?: Function;
  disabled?: boolean;
}

export type DialogActionButtonProps = Omit<DialogButtonProps, 'children'>;

const DialogActionButtons = ({
  buttonText,
  buttonDataCy,
  onClose,
  isLoadingOverride,
  buttonLoadingText,
  disabled,
  syncHandleConfirm,
  handleConfirm,
  ...otherProps
}: DialogActionButtonProps): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);

  const onClickButton = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (e) e.stopPropagation();

    // only syncHandleConfirm or handleConfirm can be invoked
    if (syncHandleConfirm) {
      syncHandleConfirm();

      return;
    }

    if (!handleConfirm) {
      return;
    }

    setLoading(true);
    await handleConfirm();

    setLoading(false);

    if (e) {
      if (onClose) {
        onClose(e);
      }

      return;
    }

    if (onClose) {
      onClose();
    }
  };

  const isLoading = isLoadingOverride || loading;

  return (
    <Button
      type="submit"
      onClick={onClickButton}
      color="primary"
      theme={ButtonThemes.primary}
      disabled={disabled}
      autoFocus
      data-cy={buttonDataCy}
      {...otherProps}
      loading={isLoading}
    >
      {isLoading && buttonLoadingText ? buttonLoadingText : buttonText}
    </Button>
  );
};

export default DialogActionButtons;
