import type { FlagOverrides } from '@utils/getFlag/types';

/**
 * Returns flag status based on an override passed in flags config
 * @param flagOverrides
 */
const getFlagHostOverrides = (
  flagOverrides: FlagOverrides = {},
  hostname: string,
) => {
  const overrides = flagOverrides;

  let foundOverride: boolean | null = null;

  Object.entries(overrides).forEach(([hostRegex, enabled]) => {
    if (new RegExp(hostRegex).test(hostname)) {
      foundOverride = enabled;
    }
  });

  return foundOverride;
};

export default getFlagHostOverrides;
