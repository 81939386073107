import pipe from '@utils/pipe';

import type { User, UsersIdMap, UsersState } from './types';

import type { RootState, Selector } from '../../types';
import { memoizeOne } from '../../utils/memoizeOne';

export const selectAll: Selector<UsersState> = ({ users }: RootState) => users;

export const selectUsers: Selector<User[]> = pipe(
  selectAll,
  ({ users }: UsersState) => users,
);

export const selectIsUsersDataLoading: Selector<boolean> = pipe(
  selectAll,
  ({ isLoading }: UsersState) => isLoading,
);
export const selectWorkspaceUsers: Selector<User[]> = pipe(
  selectAll,
  ({ workspaceUsers }: UsersState) => workspaceUsers,
);

const calcUsersIdMap = (usersList: User[]): UsersIdMap =>
  usersList.reduce(
    (acc: UsersIdMap, user: User): UsersIdMap => ({
      ...acc,
      [user.user_uid]: user,
    }),
    {},
  );

const memoizedCalcUsersIdMap = memoizeOne(calcUsersIdMap);

export const selectUsersIdMap: Selector<UsersIdMap> = pipe(
  selectUsers,
  memoizedCalcUsersIdMap,
);

export const selectWorkspaceUsersIdMap: Selector<UsersIdMap> = pipe(
  selectWorkspaceUsers,
  memoizedCalcUsersIdMap,
);
