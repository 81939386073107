import { useSelector } from 'react-redux';

import * as selectors from '@global/state/selectors';

const useDatasetId = (): number => {
  const dataset_uid = useSelector(selectors.task.selectDatasetUid);
  const { datasetUid } = useSelector(
    selectors.nodeDetails.selectNodeDetailsApplication,
  );

  return datasetUid ?? dataset_uid;
};

export default useDatasetId;
