
export enum Flags {
  CONTROL_BROWSER_STORAGE = 'CONTROL_BROWSER_STORAGE',
  ESTIMATED_LF_STATS_DEFAULT = 'ESTIMATED_LF_STATS_DEFAULT',
  GT_ANNOTATION_POPOVER_SEARCH = 'GT_ANNOTATION_POPOVER_SEARCH',
  FULL_STORY = 'FULL_STORY',
  PRESET_APPS = 'PRESET_APPS',
  CONVO_TEMPLATE_VISIBLE = 'CONVO_TEMPLATE_VISIBLE',
  ZSL_LF_DEMO = 'ZSL_LF_DEMO',
  IMAGE_WORKFLOW_VISIBLE = 'IMAGE_WORKFLOW_VISIBLE',
  SF_IMAGES_RIPTIDE = 'SF_IMAGES_RIPTIDE',
  FAST_MODEL = 'FAST_MODEL',
  DOWNLOAD_ANNOTATIONS = 'DOWNLOAD_ANNOTATIONS',
  NO_DEV = 'NO_DEV',
  DSL_SEARCH = 'DSL_SEARCH',
  SEQ_TAGGING_CLUSTER_VIEW = 'SEQ_TAGGING_CLUSTER_VIEW',
  EMBEDDED_TABLE = 'EMBEDDED_TABLE',
  LICENSE_REVAMP = 'LICENSE_REVAMP',
  SHOW_CONTEXTUAL_ERRORS = 'SHOW_CONTEXTUAL_ERRORS',
  SHOW_TS_ID_SELECTOR = 'SHOW_TS_ID_SELECTOR',
  SHOW_LF_HIGHLIGHT_MODE = 'SHOW_LF_HIGHLIGHT_MODE',
  HIDE_API_ERRORS = 'HIDE_API_ERRORS',
  CV_ONBOARDING = 'CV_ONBOARDING',
  FM_MANAGEMENT = 'FM_MANAGEMENT',
  WORD_BASED_PDF = 'WORD_BASED_PDF',
  JETSTREAM_SIDEBAR_UX = 'JETSTREAM_SIDEBAR_UX'
}

export default Flags;
