import type {
  DetailedHTMLProps,
  Dispatch,
  HTMLAttributes,
  ReactNode,
  SetStateAction,
} from 'react';

import type { TextSize } from '@coral/components/Text';

export type AccordionDetailHTMLProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDetailsElement>,
  HTMLDetailsElement
>;

export enum AccordionTheme {
  Default = 'Default',
  Expandable = 'Expandable',
  Onboarding = 'Onboarding',
  OnboardingInner = 'OnboardingInner',
}

type BaseAccordionProps = {
  children: ReactNode;
  divideSummary?: boolean;
  open?: boolean;
  'data-cy'?: string;
  onClick?: VoidFunction;
  className?: string;
};

export type DefaultAccordionProps = BaseAccordionProps & {
  theme?: AccordionTheme.Default;
  summary: ReactNode;
  enablePadding?: boolean;
  iconStart?: boolean;
};

export type ExpandableAccordionMode = 'open' | 'closed' | 'expanded';

export type ExpandableAccordionProps = BaseAccordionProps & {
  theme: AccordionTheme.Expandable;
  summary: string;
  subtext?: string;
  control?: ReactNode;
  mode: ExpandableAccordionMode;
  setMode?: Dispatch<SetStateAction<ExpandableAccordionMode>>;
  isExpandEnabled?: boolean;
};

export type OnboardingAccordionProps = Omit<BaseAccordionProps, 'open'> & {
  theme: AccordionTheme.Onboarding;
  isLoading?: boolean;
  tooltipTitle?: string;
  withHeaderSpinner?: boolean;
  isDisabled?: boolean;
  summary: string;
  summaryStatus?: ReactNode;
  isOpen?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  dataCy?: string;
};

export type OnboardingInnerAccordionProps = BaseAccordionProps & {
  theme: AccordionTheme.OnboardingInner;
  summary: string;
  isDisabled?: boolean;
  hasBorder?: boolean;
  summaryTextSize?: TextSize.s | TextSize.l;
};

export type AccordionProps =
  | DefaultAccordionProps
  | ExpandableAccordionProps
  | OnboardingAccordionProps
  | OnboardingInnerAccordionProps;
