import type { ReducersMapObject, UnknownAction } from 'redux';

import type { Action } from '@utils/redux/types';

import * as actionTypes from './actionTypes';

export const init = (): Action => ({ type: actionTypes.init });

export const addReducers = (
  reducersMap: ReducersMapObject<any, UnknownAction>,
): Action<ReducersMapObject<any, UnknownAction>> => ({
  type: actionTypes.addReducers,
  payload: reducersMap,
});
