import type { ReactNode } from 'react';

import type { FBACFeature } from '@hooks/fbac/useFeatureAccessControl';
import useHasAccessToFeature from '@hooks/fbac/useHasAccessToFeature';

export type AuthorizationWrapperProps = Readonly<{
  requiredFeature: FBACFeature;
  children: ReactNode;
}>;

export function AuthorizationWrapper({
  requiredFeature,
  children,
}: AuthorizationWrapperProps) {
  const { hasAccessToFeature } = useHasAccessToFeature(requiredFeature);

  if (!hasAccessToFeature) {
    return null;
  }

  return <>{children}</>;
}
