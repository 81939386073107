import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from '@global/state/actions';
import type { UserSettings } from '@global/state/reducers/userSettings/slice';
import type { UserSettingsRequestParams } from '@hooks/useGetRequestParams';
import useGetRequestParams from '@hooks/useGetRequestParams';
import useRequest from '@hooks/useRequestWithLogging';
import useSettingsResolver from '@hooks/useSettingsResolver';
import { userSettingsApi } from '@utils/api/serverRequests';

const UserSettingsInitializer = () => {
  const request = useRequest();
  const dispatch = useDispatch();
  const requestParams = useGetRequestParams();
  const { settingsResolver } = useSettingsResolver();

  const fetchUserSettings = useCallback(
    async (
      nextRequestParams: UserSettingsRequestParams,
    ): Promise<UserSettings> => {
      const result = await request(
        userSettingsApi.getUserSettingsUserSettingsGet,
        {
          organization: nextRequestParams.organization,
          applicationUid: nextRequestParams.application_uid,
          nodeUid: nextRequestParams.node_uid,
          userUid: nextRequestParams.user_uid,
          datasetBatchUid: nextRequestParams.dataset_batch_uid,
        },
      );

      const nextSettings = settingsResolver(result);

      return nextSettings;
    },
    [request, settingsResolver],
  );

  useEffect(() => {
    let shouldIgnore = false;

    const fetchData = async () => {
      dispatch(actions.userSettings.updateIsFetchingUserSettings(true));
      const nextSettings = await fetchUserSettings(requestParams);

      if (!shouldIgnore) {
        dispatch(
          actions.userSettings.updateUserSettings({
            updatedState: nextSettings,
            requestParams,
          }),
        );
        dispatch(actions.userSettings.updateIsFetchingUserSettings(false));
      }
    };

    fetchData();

    return () => {
      shouldIgnore = true;
    };
  }, [dispatch, fetchUserSettings, requestParams, settingsResolver]);

  return null;
};

export default UserSettingsInitializer;
