import Link from 'next/link';
import type { ReactNode } from 'react';
import React, { forwardRef } from 'react';

import { ButtonSizes, ButtonThemes } from './types';
import getBorder from './utils/getBorder';
import getFilled from './utils/getFilled';
import getSize from './utils/getSize';

import combineClasses from '../../utils/combineClasses';
import type { Icons } from '../Icon';
import Icon from '../Icon';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSizes;
  theme?: ButtonThemes;
  rounded?: boolean;
  border?: boolean;
  loading?: boolean;
  filled?: boolean;
  blank?: boolean;
  children?: ReactNode;
  href?: string;
  fullWidth?: boolean;
  icon?: Icons;
  rightIcon?: Icons;
  truncate?: boolean;
  inline?: boolean;
  'data-cy'?: string;
}

const getSpinnerBorderColor = (filled: boolean, theme: ButtonThemes) => {
  if (theme !== ButtonThemes.default && filled) {
    return 'border-white';
  }

  if (theme === ButtonThemes.secondary && !filled) {
    return 'border-secondary';
  }

  return 'border-primary';
};

export const defaultButtonSpinnerTestId = 'button-loading-spinner';

const getButtonSpinnerClasses = (filled: boolean, theme: ButtonThemes) =>
  combineClasses(
    getSpinnerBorderColor(filled, theme),
    'min-w-3',
    'h-3',
    'border',
    'border-r-transparent',
    'rounded-full',
    'animate-spin',
    'mr-2',
  );

const Button = (props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const {
    size = ButtonSizes.large,
    children,
    blank = false,
    filled = true,
    rounded = true,
    loading = false,
    border = true,
    theme = ButtonThemes.default,
    href,
    fullWidth,
    icon,
    rightIcon,
    truncate,
    inline,
    'data-cy': dataCy,
    ...rest
  } = props;

  const { disabled, className = '' } = props;

  const getButtonClasses = () => {
    return combineClasses(
      className,
      getSize(size, rounded),
      getBorder(blank ? false : border, theme, disabled),
      getFilled({
        isFilled: blank ? false : filled,
        theme,
        isDisabled: disabled,
      }),
      fullWidth ? 'w-full' : '',
      disabled && (!filled || !blank) ? 'bg-gray-200' : '',
      disabled ? 'cursor-not-allowed' : '',
      truncate ? 'truncate' : '',
      'items-center',
      inline ? 'inline-flex' : 'flex',
      'outline-offset-0',
      'whitespace-nowrap',
      theme === ButtonThemes.primary ? 'focus:outline-offset-1' : '',
    );
  };

  const button = (
    // eslint-disable-next-line react/button-has-type
    <button
      {...rest}
      ref={ref}
      className={getButtonClasses()}
      aria-disabled={disabled}
      data-cy={dataCy}
    >
      {loading && (
        <div
          data-cy={defaultButtonSpinnerTestId}
          className={getButtonSpinnerClasses(filled, theme)}
        />
      )}
      {!loading && icon && (
        <Icon
          name={icon}
          className={`${children ? 'mr-2' : ''}`}
          inverted={theme === ButtonThemes.primary && filled && !disabled}
          disabled={disabled}
        />
      )}
      {children && Array.isArray(children) && children[0] && children[1] ? (
        <>
          <span className="mr-1 inline-flex h-buttonIcon items-center">
            {children[0]}
          </span>
          <span className="inline-flex items-center">{children[1]}</span>
        </>
      ) : (
        children
      )}
      {!loading && rightIcon && (
        <Icon
          name={rightIcon}
          className={`${children ? 'ml-2' : ''}`}
          inverted={theme === ButtonThemes.primary && filled && !disabled}
          disabled={disabled}
        />
      )}
    </button>
  );

  if (href) {
    return <Link href={href}>{button}</Link>;
  }

  return button;
};

export { ButtonSizes, ButtonThemes };

export default forwardRef(Button);
