import { useSelector } from 'react-redux';

import HorizontalNavigation from '@components/Navigation/HorizontalNavigation';
import SnorkelLink from '@components/SnorkelLink';
import Tooltip from '@coral/components/tooltips/Tooltip';
import type { ApplicationDetails } from '@core/types';
import NotificationIndicator from '@global/NavigationContainer/NavigationSidebarNav/NavList/NotificationIndicator';
import type { NavigationMetaData } from '@global/state/reducers/navigation/types';
import * as selectors from '@global/state/selectors';
import useSnorkelRouter from '@hooks/useSnorkelRouter';

import calcBreadcrumbNav from './util/calcBreadcrumbNav';

export const NavigationBreadcrumbId = 'navigation-breadcrumb';

const BREADCRUMB_MAX_LENGTH = 80;

const NavigationBreadcrumb = () => {
  const { asPath, query } = useSnorkelRouter();
  const meta = useSelector(
    selectors.navigation.selectMeta,
  ) as NavigationMetaData;
  const application: ApplicationDetails =
    useSelector(selectors.appDetails.selectAll) || {};
  const { name } = application;

  const navItems = calcBreadcrumbNav({
    appName: name,
    asPath,
    meta,
    query,
  });

  return (
    <HorizontalNavigation id={NavigationBreadcrumbId}>
      {navItems.map(({ href, title: routeName, notificationIndicator }) => (
        <SnorkelLink key={href} href={`/${href}`}>
          <Tooltip title={routeName}>
            {(routeName || '').length > BREADCRUMB_MAX_LENGTH
              ? `${(routeName || '').substring(0, BREADCRUMB_MAX_LENGTH)}...`
              : routeName}
            {notificationIndicator && <NotificationIndicator />}
          </Tooltip>
        </SnorkelLink>
      ))}
    </HorizontalNavigation>
  );
};

export default NavigationBreadcrumb;
