/* eslint-disable local-rules/no-layering-violations */
import React, { Fragment } from 'react';

import PageTitle from '@components/PageTitle';

type AttachHeadOptions = {
  title: string;
};

const attachHead =
  (Component: React.ComponentType<any>, { title }: AttachHeadOptions) =>
  (props: any) => (
    <>
      <PageTitle title={title} />
      <Component {...props} />
    </>
  );

export default attachHead;
