import type { AlertDispatchers } from '@components/PageRegion/Alert/types';

import useUseRequestContext from './useUseRequestContext';

import type { RequestError, UseRequestOptions } from '../types';
import getNotificationMetaData from '../utils/getNotificationMetaData';

const mapErrorMessageFromStatusCode = {
  500: 'An internal server error has occurred',
  502: 'Service unavailable',
  503: 'Bad gateway',
  504: 'Gateway timeout',
  542: 'Life, the universe, and everything',
};

const messageUnknownServerError = 'An unknown server error has happened';

const get500ErrorMessage = (statusCode: number, details?: string): string => {
  return typeof details === 'string' && details.length > 0
    ? details
    : (mapErrorMessageFromStatusCode[statusCode] ?? messageUnknownServerError);
};

const useMakeOnRequestFailed = (
  showErrorAlert: AlertDispatchers['showErrorAlert'],
) => {
  const { debugMode } = useUseRequestContext();

  return (rawOptions: UseRequestOptions) => (e: RequestError) => {
    const options = rawOptions ?? {};
    options.debugMode = debugMode;

    const displayErrorMessage = (message: string) => {
      const metaData = getNotificationMetaData(e, options);
      showErrorAlert({ message, metaData, origin: 'snorkel-api-call' });
    };

    if (options.ignoreAbort && options.signal?.aborted) {
      return options?.fallback;
    }

    if (options?.surfaceError) {
      throw e;
    }

    if (e.status >= 500) {
      if (!options?.hide500Error) {
        const message = `${get500ErrorMessage(
          e.status,
          e.body?.user_friendly_message || e.body?.detail,
        )}`;
        displayErrorMessage(message);
      }

      return options?.fallback;
    }

    if (e.message === 'invalid-token') {
      displayErrorMessage(
        'Authentication failed. Please re-login or create a new user.',
      );
    }

    if (e.status === 401 || options?.getSnackbarDataOnError === null) {
      return options && options.fallback;
    }

    if (e.type === 'api-error') {
      if (options && options.getSnackbarDataOnError) {
        const { message } = options.getSnackbarDataOnError(e);
        if (message) displayErrorMessage(message);
      } else {
        displayErrorMessage(e.body.user_friendly_message || e.body.detail);
      }
    } else if (options && options.getSnackbarDataOnError && e) {
      const { message } = options.getSnackbarDataOnError(e);
      displayErrorMessage(message);
    }

    return options?.fallback;
  };
};

export default useMakeOnRequestFailed;
