import type {
  AnnotationFilterStructureModel,
  AnnotatorAgreementFilterStructureModel,
  CommentFilterStructureModel,
  FieldFilterStructureModel,
  GroundTruthFilterStructureModel,
  SliceFilterStructureModel,
} from '@api/tdm';

/**
 * this is the structure sent from the BE
 */
export type FiltersInfo = {
  annotation: AnnotationFilterStructureModel;
  annotator_agreement: AnnotatorAgreementFilterStructureModel;
  field: FieldFilterStructureModel;
  ground_truth: GroundTruthFilterStructureModel;
  comment: CommentFilterStructureModel;
  slice: SliceFilterStructureModel;
};

// this is the structure sent to the BE
export type AnnotationFilterConfig = {
  filter_type: FilterTypes.ANNOTATIONS;
  label_schema_uid: number;
  label_schema?: string;
  voted?: string;
  annotator: string;
  vote_type?: string;
};

export type AgreementVote = 'correct' | 'incorrect';

// this is the structure sent to the BE
export type AnnotatorAgreementFilterConfig = {
  filter_type: FilterTypes.ANNOTATOR_AGREEMENT;
  label_schema_uid: number;
  label_schema?: string;
  voted: AgreementVote;
  annotator_uid_one: number;
  annotator_one?: string;
  annotator_uid_two: number;
  annotator_two?: string;
};

// this is the structure sent to the BE
export type FieldFilterConfig = {
  filter_type: FilterTypes.COLUMN;
  field: string;
  value: string;
  operator: string;
};

// this is the structure sent to the BE
export type GroundTruthFilterConfig = {
  filter_type: FilterTypes.GROUND_TRUTH;
  label_schema_uid: number;
  label_schema?: string;
  vote_type?: string;
  voted: string;
};

// this is the structure sent to the BE
export type CommentFilterConfig = {
  filter_type: FilterTypes.COMMENT;
  user_uids: string[];
  user_names: string[];
  body?: string;
};

// this is the structure sent to the BE
export type TagTypeFilterConfig = {
  filter_type: FilterTypes.TAG;
  tag_type_uid: number;
  tag_type_name: string;
  operator: string;
};

// this is the structure sent to the BE
export type SliceFilterConfig = {
  filter_type: FilterTypes.SLICE;
  slice_uid: number;
  slice_name: string;
  operator: string;
};

export type Filter =
  | AnnotationFilterConfig
  | AnnotatorAgreementFilterConfig
  | FieldFilterConfig
  | GroundTruthFilterConfig
  | CommentFilterConfig
  | TagTypeFilterConfig
  | SliceFilterConfig;

export type GraphCombiner = '$AND' | '$OR' | '$NOT';

export type FilterGraphElement = number | GraphType;

export type GraphType = [
  GraphCombiner,
  ...Array<number | Array<number | GraphCombiner>>,
];

export type FilterConfig = {
  filters: Filter[];
  // graph would be something like ["$OR", 0, 1, ["$AND", ["$NOT", 2], ["$NOT", 3]]]
  // where 0, 1, 2 are the indexes of the filters in the filters array
  graph: GraphType;
};
export type SimplifiedFilterTemplate = {
  filter_type: FilterTypes;
  voted?: VoteType | string;
};

export enum VoteType {
  ABSTAIN = -1,
  ABSENT = 0,
  PRESENT = 1,
  // Must match values in python/label_spaces/common/constants.py
  CORRECT = 'correct',
  PARTIALLY_CORRECT = 'partially correct',
  CORRECT_OR_UNKNOWN = 'correct or unknown',
  INCORRECT = 'incorrect',
  INCORRECT_OR_UNKNOWN = 'incorrect or unknown',
}

export enum FilterTypes {
  GROUND_TRUTH = 'ground_truth',
  ALL_LFS = 'lfs',
  NO_LFS = 'no_lfs',
  LF = 'lf',
  MODEL = 'model',
  TAG = 'tag_type',
  COMMENT = 'comment',
  TRAINING_SET = 'training_set',
  SELECTED_FIELDS = 'special_filter',
  COLUMN = 'field',
  ANNOTATIONS = 'annotation',
  LF_CONFLICT = 'lf_conflict',
  CLUSTER = 'cluster',
  MARGIN_DISTANCE = 'margin_distance',
  ANNOTATOR_AGREEMENT = 'annotator_agreement',
  SLICE = 'slice',
}

export type CustomOptionsInputProps = {
  title: string;
  value: string;
};

export type ProcessorInput = {
  is_optional?: boolean;
  input_name: string;
  options?: string[] | CustomOptionsInputProps[] | null;
  default?: any;
  component: string;
  size?: number;
  display_name?: string;
  auto_focus?: boolean;
  max_values?: number;
  placeholder?: string;
  tooltip?: string;
};

export type ProcessorInputs = ProcessorInput[];

export type FilterInfo = {
  filter_name: FilterTypes;
  description: string;
  structure: ProcessorInputs;
  name: string;
};
