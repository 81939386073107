/* eslint-disable local-rules/require-data-cy */
import type { MenuProps, PopoverActions } from '@material-ui/core';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import type { ReactElement, ReactNode } from 'react';
import { cloneElement, useEffect, useRef, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';

import Button from '@coral/components/Button';
import type { ButtonProps } from '@coral/components/Button';
import getButtonClasses from '@coral/components/MenuButton/getButtonClasses';
import OptionalTooltip from '@coral/components/tooltips/OptionalTooltip';
import useControlledState from '@coral/hooks/useControlledState';

const useStyles = makeStyles(() => ({
  paper: ({ popoverMaxHeight }: Pick<MenuButtonProps, 'popoverMaxHeight'>) => ({
    borderRadius: 4,
    flexDirection: 'column',
    display: 'flex',
    // Popovers with variable content height sometimes get cutoff if they expand longer than the page.
    // Using optional `maxHeight` on this container will prevent that.
    ...(popoverMaxHeight ? { maxHeight: popoverMaxHeight } : {}),
  }),
}));

export type MenuButtonMenuProps = Partial<MenuProps>;

export type MenuButtonProps = Omit<ButtonProps, 'onClick' | 'children'> & {
  menu: ReactElement<any>;
  active?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  children?: ReactNode;
  button?: ReactElement;
  menuProps?: MenuButtonMenuProps;
  keyMap?: Record<any, any>;
  openShortcutKey?: string;
  popoverMaxHeight?: number;
  isOpen?: boolean;
  tooltip?: string;
};

type ClonedElementProperties = {
  'aria-expanded': Boolean;
  open: Boolean;
  popoverActions?: PopoverActions;
  buttonRef?: React.RefObject<HTMLElement>;
  close?: () => void;
  actionRef?: React.RefObject<PopoverActions>;
};

/** @deprecated Use MenuButton in @coral/components instead */
const MenuButton = ({
  menu,
  active,
  children,
  onClose,
  onOpen,
  button,
  menuProps = {},
  openShortcutKey,
  keyMap,
  popoverMaxHeight,
  isOpen,
  tooltip,
  ...props
}: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles({ popoverMaxHeight });
  const refAnchor = useRef<HTMLElement | null>(null);

  const popoverActions = useRef<PopoverActions>(null);
  const [internalIsOpen] = useControlledState<boolean>(isOpen, false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.disabled) return;
    event.stopPropagation();
    event.preventDefault();
    if (onOpen) onOpen();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    setAnchorEl(null);
  };

  const handleCloseMenu = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    handleClose();
  };

  const buttonClasses = getButtonClasses(Boolean(anchorEl), active);

  const shortcutHandler = openShortcutKey
    ? {
        [openShortcutKey]: (e: KeyboardEvent | undefined) => {
          if (e) {
            e.preventDefault();
          }

          setAnchorEl(refAnchor.current);
        },
      }
    : null;

  useEffect(() => {
    if (internalIsOpen && refAnchor.current === null) {
      // Don't setAnchorEl if open and ref.current is null
      return;
    }

    setAnchorEl(internalIsOpen ? refAnchor.current : null);
  }, [internalIsOpen]);

  const calcClonedElement = (element: ReactElement<any>): ReactElement => {
    const properties: ClonedElementProperties = {
      'aria-expanded': Boolean(anchorEl),
      open: Boolean(anchorEl),
    };

    if (typeof element.type === 'function' && 'name' in element.type) {
      const { name } = element.type;

      if (name === 'SimpleFilterMenu') {
        properties.buttonRef = refAnchor;
      }

      if (!['VStack', 'SettingsItem'].includes(name)) {
        properties.close = handleClose;
      }

      if (name === 'SchemaSection' && popoverActions.current) {
        properties.popoverActions = popoverActions.current;
      }
    }

    return cloneElement(element, properties);
  };

  return (
    <>
      {shortcutHandler && keyMap && (
        <GlobalHotKeys
          handlers={shortcutHandler}
          keyMap={keyMap}
          allowChanges
        />
      )}
      <OptionalTooltip title={tooltip}>
        {button ? (
          cloneElement(button, {
            onClick: handleClick,
            active: anchorEl ?? undefined,
            ref: refAnchor,
            role: 'button',
            tabIndex: 0,
          })
        ) : (
          <Button
            onClick={handleClick}
            className={buttonClasses}
            ref={refAnchor as React.RefObject<HTMLButtonElement>}
            {...props}
          >
            {children}
          </Button>
        )}
      </OptionalTooltip>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        elevation={3}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        disableEnforceFocus
        PaperProps={{ className: classes.paper }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        action={popoverActions}
        data-cy="menu-button-popover"
        {...menuProps}
      >
        {calcClonedElement(menu)}
      </Popover>
    </>
  );
};

export default MenuButton;
