import type { LFVotesMap } from '@coral/types';
import type { LFs } from '@core/types';
import pipe from '@utils/pipe';

import type { LFsState, RestoreProgressMap } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<LFsState> = ({ lfs }: RootState) => lfs;

export const selectLFs: Selector<LFs> = pipe(
  selectAll,
  ({ lfs }: LFsState) => lfs,
);
export const selectVotesMap: Selector<LFVotesMap> = pipe(
  selectAll,
  ({ votesMap }: LFsState) => votesMap,
);
export const selectPopulateLFJobId: Selector<string> = pipe(
  selectAll,
  ({ populateLFJobId }: LFsState) => populateLFJobId,
);
export const selectIsPopulatingLFs: Selector<boolean> = pipe(
  selectAll,
  ({ isPopulatingLFs }: LFsState) => isPopulatingLFs,
);
export const selectPopulatingLFsConfig: Selector<
  LFsState['populatingLFsConfig']
> = pipe(selectAll, ({ populatingLFsConfig }: LFsState) => populatingLFsConfig);
export const selectIsLoading: Selector<boolean> = pipe(
  selectAll,
  ({ isLoading }: LFsState) => isLoading,
);

export const selectRestoreProgressMap: Selector<RestoreProgressMap> = pipe(
  selectAll,
  ({ restoreProgressMap }) => restoreProgressMap,
);

export default null;
