import { createReducerSlice } from '@utils/redux/reducer';

import type { TagsState } from './types';

export const initialState: TagsState = {
  currentTagId: -1,
  isSuggestLFMenuOpen: false,
  isTagsMenuOpen: false,
};

const reducerSlice = createReducerSlice('tags', initialState, {
  setIsTagsMenuOpen(state: TagsState, isTagsMenuOpen: boolean): TagsState {
    return isTagsMenuOpen === state.isTagsMenuOpen
      ? state
      : { ...state, isTagsMenuOpen };
  },

  setIsSuggestLFMenuOpen(
    state: TagsState,
    {
      isSuggestLFMenuOpen,
      tagId,
    }: { isSuggestLFMenuOpen: boolean; tagId: number },
  ): TagsState {
    return isSuggestLFMenuOpen === state.isSuggestLFMenuOpen &&
      tagId === state.currentTagId
      ? state
      : { ...state, isSuggestLFMenuOpen, currentTagId: tagId };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;
