import { useSelector } from 'react-redux';

import * as selectors from '@global/state/selectors';
import useCachedRequest from '@hooks/useCachedRequest';
import useUseRequestContext from '@hooks/useRequest/hooks/useUseRequestContext';
import { featureAccessControlApi } from '@utils/api/serverRequests';

import type { FBACFeature } from './useFeatureAccessControl';

const useHasAccessToFeature = (feature: FBACFeature) => {
  const { accessToken } = useUseRequestContext();

  const selectedWorkspace = useSelector(
    selectors.workspaces.selectSelectedWorkspace,
  );
  const workspaceUid = selectedWorkspace.workspace_uid ?? 0;

  const {
    data: isFeatureEnabled,
    isLoading,
    mutate,
  } = useCachedRequest(
    featureAccessControlApi.hasAccessToFeatureFeatureFeatureAccessGet,
    { feature, workspaceUid },
    { suspendFetch: !accessToken },
  );

  return {
    isLoading,
    hasAccessToFeature: !!isFeatureEnabled,
    mutate,
  };
};

export default useHasAccessToFeature;
