import type { EmbeddingsState, LLMType } from '@api/tdm';
import type { OnClickState } from '@coral/components/Toggle';

import type { ModelSelectorDropdownProps } from './components/PromptToolbar/ModelSelectorDropdown';

export type ToolInfoOptions = {
  title: string;
  description: string;
};

export enum PromptTemplateType {
  PREDICT = 'predict',
  KEYWORD_CUSTOM = 'keyword_custom',
  SINGLE_ENTITY = 'single_entity',
  MULTI_ENTITY = 'multi_entity',
  CUSTOM_SEQ = 'custom_seq',
}

export type PromptTask =
  | 'sequence_tagging'
  | 'classification'
  | 'pdf_extraction';

export type DropdownPrompt = {
  label: string;
  prompt: string;
  templateType: PromptTemplateType;
  supportedTasks: PromptTask[];
  mapperCode?: string;
};

export type ClientPromptInputModel = {
  modelType: LLMType;
  modelName: string;
  promptText: string;
  primaryTextField?: string;
  labelsToTargets?: Record<string, string>;
  outputCode?: string;
  useRag?: boolean;
};

export enum PromptDataState {
  NOT_STARTED = 'NOT_STARTED',
  ABORTED = 'ABORTED',
  COMPLETED = 'COMPLETED',
  RUNNING = 'RUNNING',
  STALE = 'STALE',
  FAILED = 'FAILED',
}

// These are properties that the FE maintains, derived
// from the PromptOutputData BE response for display in
// the modular data viewer (MDV)
export type TransformedPromptOutputData = {
  // Ground truth string, expected from the GT cell
  groundTruthLabel: string;
  label: string | Array<Array<number>>;
};

// This is a FE-only type adapted from the base generated
// PromptOutputData type from openAPI
export type ClientPromptOutputData = {
  // Stores prompt snippet text as a primary key col
  text: string;
  xUid: string;
  contextUid: string;
  output: string;
  confidence: number;
  rawLabel?: number | Array<Array<number>>;
  groundTruth: number | Array<Array<number>>;
} & TransformedPromptOutputData;

export type PromptToolbarProps = {
  resetFn: VoidFunction;
  promptDropdownOptions?: DropdownPrompt[];
  onPromptDropdownSelect?: (label: DropdownPrompt['label']) => void;
  onRagSettingsToggle?: (state: OnClickState) => void;
  onSettingsOpen?: VoidFunction;
  isRagSettingsToggled?: boolean;
  showRagSettingsButton?: boolean;
  ragState?: EmbeddingsState | null;
  isCodeMapperDisplayed?: boolean;
  setIsCodeMapperDisplayed?: VoidFunction;
  isCodeMapperButtonEnabled?: boolean;
} & ModelSelectorDropdownProps;
