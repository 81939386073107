import type { PropsWithChildren } from 'react';
import React from 'react';
import { SWRConfig } from 'swr';

const CacheProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <SWRConfig
      value={{
        dedupingInterval: 10_000,
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: true,
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default CacheProvider;
