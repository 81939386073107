import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import type { FooterItemContentProps } from './FooterItemContent';
import FooterItemContent from './FooterItemContent';

const SidebarMenuItem = forwardRef(
  (
    { children, className, ...props }: FooterItemContentProps,
    forwardedRef: ForwardedRef<HTMLLIElement>,
  ) => (
    <FooterItemContent
      ref={forwardedRef}
      {...props}
      className={`flex w-full cursor-pointer items-center px-2 py-3 text-xs ${className} hover:bg-gray-100`}
    >
      {children}
    </FooterItemContent>
  ),
);

export default SidebarMenuItem;
