const errorMessageAllowList = [
  {
    message:
      'Warning: findDOMNode is deprecated and will be removed in the next major release',
    description:
      'Material-UI uses findDOMNode which is marked as deprecated in React 18. We ignore this error until we replace material-ui. We cannot upgrade to React 19 until we replace material-ui. ',
  },
];

export default function patchConsoleError() {
  // eslint-disable-next-line no-console
  const originalError = console.error;

  // eslint-disable-next-line no-console
  console.error = function consoleError(...args: any[]) {
    const isAllowedError =
      args[0] &&
      typeof args[0] === 'string' &&
      errorMessageAllowList.some(allowedMessage =>
        (args[0] || '').includes(allowedMessage.message),
      );

    if (isAllowedError) {
      return;
    }

    originalError(...args);
  };
}
