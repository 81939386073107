import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Divider from '@coral/components/Divider';
import { Icons } from '@coral/components/Icon';
import { isTruthy } from '@coral/utils/isTruthy';
import { LLM_FINE_TUNING_NODE } from '@core/constants';
import { AuthorizationWrapper } from '@global/AuthorizationWrapper';
import * as selectors from '@global/state/selectors';
import useHasAccessToFeature from '@hooks/fbac/useHasAccessToFeature';
import useCachedRequest from '@hooks/useCachedRequest';
import useDatasetId from '@hooks/useDatasetId';
import useGetRequestParams from '@hooks/useGetRequestParams';
import useNodeId from '@hooks/useNodeId';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import { datasetsApi } from '@utils/api/serverRequests';
import { validateRequestParam } from '@utils/validateRequestParams';

import CurrentAppNav from './CurrentAppNav';
import NodeSelector from './NodeSelector';
import NotebookItem from './NotebookItem';
import calcAppNavItems from './utils/calcAppNavItems';
import calcAppNodeNavItems from './utils/calcAppNodeNavItems';
import WorkspaceSelector from './WorkspaceSelector';

import NavigationButton from '../common/NavigationButton';

// --------------------------------------------------------------------
type ItemConfig = Readonly<{
  tag: string;
  label: string;
  href: string;
  icon: Icons;
  cy: string;
}>;

const items: ItemConfig[] = [
  {
    tag: 'home',
    label: 'Home',
    href: '/',
    icon: Icons.HOUSE,
    cy: 'home-page-navigation-button',
  },
  {
    tag: 'files',
    label: 'Files',
    href: '/files',
    icon: Icons.DOCUMENTATION,
    cy: 'files-navigation-button',
  },
  {
    tag: 'datasets',
    label: 'Datasets',
    href: '/datasets',
    icon: Icons.DATASET,
    cy: 'datasets-navigation-button',
  },
  {
    tag: 'applications',
    label: 'Applications',
    href: '/applications',
    icon: Icons.OVERVIEW,
    cy: 'applications-navigation-button',
  },
  {
    tag: 'deployments',
    label: 'Deployments',
    href: '/deployments',
    icon: Icons.DEPLOY,
    cy: 'deployments-navigation-button',
  },
];

type ItemProps = {
  path: string;
  config: ItemConfig;
};

const Item = ({ path, config: { label, href, icon, cy } }: ItemProps) => (
  <NavigationButton href={href} selected={path === href} leftIcon={icon}>
    <span data-cy={cy} className="flex-1 truncate py-1">
      {label}
    </span>
  </NavigationButton>
);

// --------------------------------------------------------------------
const myWorkItem: ItemConfig = {
  tag: 'work',
  label: 'Annotation batches',
  href: '/work',
  icon: Icons.CHECKMARK__IN_CIRCLE,
  cy: 'my-work-nav-button',
};

type MyWorkItemProps = Readonly<{
  path: string;
}>;

const MyWorkItem = ({ path }: MyWorkItemProps) => (
  <Item key={myWorkItem.href} path={path} config={myWorkItem} />
);

// --------------------------------------------------------------------
const calcApplicationId = (
  appId: number | undefined,
  path: string,
): number | null => {
  if (appId) {
    return appId;
  }

  const newAppPathMatch = path.match(
    /^\/applications\/new\/(app|dataset)\/([0-9]+)$/,
  );

  if (newAppPathMatch && newAppPathMatch[2]) {
    return Number(newAppPathMatch[2]);
  }

  return null;
};

// --------------------------------------------------------------------
const NavigationSidebarNav = () => {
  const router = useSnorkelRouter();
  const { asPath, query } = router;

  const { application_uid } = useGetRequestParams();
  const datasetUid = useDatasetId();
  const { data: dataset } = useCachedRequest(
    datasetsApi.fetchDatasetByUidDatasetsDatasetUidGet,
    { datasetUid: datasetUid! },
    { suspendFetch: !datasetUid },
  );
  const appId = calcApplicationId(application_uid, router.asPath);
  const application = useSelector(selectors.appDetails.selectAll);

  const appDataExists =
    application && !isEmpty(application) && application.id === appId;
  const { dag: nodes = {} } = application || {};

  // look for nodeId from path (via router params first). If not there, use useNodeId
  const routeNodeId = validateRequestParam(query.nodeId);
  const nodeIdFromStore = useNodeId();
  const nodeId = routeNodeId || nodeIdFromStore;
  const [selectedNodeId, setSelectedNodeId] = useState<number>(nodeId);

  useEffect(() => {
    setSelectedNodeId(nodeId);
  }, [nodeId]);

  // get selected node from application data, or from nodeDetails in redux
  const savedNodeDetails = useSelector(
    selectors.nodeDetails.selectNodeDetailsNode,
  );

  const node = selectedNodeId ? nodes[selectedNodeId] : savedNodeDetails;
  const isLLMFineTuningNode = node?.node_cls === LLM_FINE_TUNING_NODE; // used to show/hide the evaluate link

  const { hasAccessToFeature: areDeploymentsEnabled } =
    useHasAccessToFeature('deployments');

  const { onboarding_settings } = node?.node_config || {};
  const isOnboardingComplete = isTruthy(onboarding_settings);
  const isAppPage = !!asPath.match(/^\/applications\/.+/);

  const isAppSetupPage = !!asPath.match(/^\/applications\/new\/app\/[0-9]+$/);

  const appNavItems =
    isAppPage && application
      ? calcAppNavItems({
          application,
          isOnboardingComplete,
          path: asPath,
        })
      : [];

  const nodeNavItems =
    (isAppPage || isAppSetupPage) && application
      ? calcAppNodeNavItems({
          application,
          nodeId: selectedNodeId,
          dataset,
          path: asPath,
          isLLMFineTuningNode,
        })
      : [];

  const shouldShowNodeSelector =
    isAppPage && application && application.isSetupComplete && !isEmpty(nodes);

  const path = asPath.split('?')[0];

  const enabledItems = useMemo(() => {
    return items.filter(item =>
      item.tag === 'deployments' ? areDeploymentsEnabled : true,
    );
  }, [areDeploymentsEnabled]);

  const handleNodeChange = (nextNodeId: number) => {
    setSelectedNodeId(nextNodeId);

    // use current path to determine where to navigate to
    // on the app/pipeline/dag page already: stay put
    if (path.match(/^\/applications\/[0-9]+\/nodes\/[0-9]+/)) {
      // on a node-specific page: navigate to the same page with the new node
      router.push(path.replace(/nodes\/[0-9]+/, `nodes/${nextNodeId}`));
    }
  };

  return (
    <div className="mt-4 flex-1">
      <WorkspaceSelector />
      {enabledItems.map(config => (
        <Item key={config.href} path={path} config={config} />
      ))}
      <AuthorizationWrapper requiredFeature="notebook">
        <NotebookItem />
      </AuthorizationWrapper>
      <MyWorkItem path={path} />
      {appDataExists ? <Divider className="my-3" /> : null}
      <nav className="flex flex-col gap-y-2">
        {appDataExists ? (
          <CurrentAppNav heading={application.name} navItems={appNavItems} />
        ) : null}
        {shouldShowNodeSelector ? (
          <NodeSelector
            application={application}
            value={selectedNodeId}
            onNodeChange={handleNodeChange}
          />
        ) : null}
        {nodeNavItems.length > 0 ? (
          <CurrentAppNav navItems={nodeNavItems} />
        ) : null}
      </nav>
    </div>
  );
};

export default NavigationSidebarNav;
