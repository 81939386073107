import { useState } from 'react';
import { useSelector } from 'react-redux';

import type { Workspace } from '@api/tdm';
import Dialog from '@components/Modals/Dialog';
import Icon, { Icons } from '@coral/components/Icon';
import SelectMenuButton from '@coral/components/SelectMenuButton';
import * as selectors from '@global/state/selectors';
import useDialogControl from '@hooks/useDialogControl';
import useSetWorkspaces from '@hooks/useSetWorkspaces';
import useSnorkelRouter from '@hooks/useSnorkelRouter';

import NavigationButton from '../common/NavigationButton';
import { ROUTES_SUPPORTING_WORKSPACE_SWITCHING } from '../NavigationSidebarFooter';

const kDefaultWorkspaceUid = 1;
const kDefaultWorkspaceLabel = 'Default Workspace';

const calcWorkspaceLabel = (workspace: Workspace | undefined): string => {
  return workspace?.workspace_uid === kDefaultWorkspaceUid
    ? kDefaultWorkspaceLabel
    : (workspace?.name ?? kDefaultWorkspaceLabel);
};

const WorkspaceSelector = () => {
  const router = useSnorkelRouter();

  const workspaces = useSelector(selectors.workspaces.selectWorkspaces);
  const selectedWorkspace = useSelector(
    selectors.workspaces.selectSelectedWorkspace,
  );
  const { handleDialogOpen, handleDialogClose, dialogOpen } =
    useDialogControl();
  const { updateSelectedWorkspace } = useSetWorkspaces();

  const [tempSelectedWorkspace, setTempSelectedWorkspace] =
    useState<Workspace | null>(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleWorkspaceChange = (nextWorkspaceName: string) => {
    const route = router.asPath.split('/').join('');
    const nextWorkspace =
      workspaces.find(ws => ws.name === nextWorkspaceName) ??
      workspaces.find(ws => ws.workspace_uid === kDefaultWorkspaceUid);

    if (!nextWorkspace) {
      handleDialogOpen();

      return;
    }

    setTempSelectedWorkspace(nextWorkspace);

    if (ROUTES_SUPPORTING_WORKSPACE_SWITCHING.includes(route)) {
      updateSelectedWorkspace(nextWorkspace);
    } else {
      handleDialogOpen();
    }
  };

  const goToHome = () => {
    const index = workspaces.findIndex(
      ({ workspace_uid }) =>
        tempSelectedWorkspace!.workspace_uid === workspace_uid,
    );

    if (index !== -1) {
      updateSelectedWorkspace(workspaces[index]);
      router.push('/');
    }
  };

  const handleOpenChange = (isOpen: boolean) => setIsSelectOpen(isOpen);

  return (
    <>
      <Dialog
        title={`Switch workspace${
          tempSelectedWorkspace?.name
            ? ` to ${tempSelectedWorkspace?.name}`
            : ''
        }`}
        message="Switching workspaces will take you to the applications page for that workspace and exit the current application"
        open={dialogOpen}
        handleClose={handleDialogClose}
        actionButtonProps={{
          buttonText: 'Confirm',
          handleConfirm: goToHome,
          buttonDataCy: 'confirm-workspace-switch',
        }}
      />
      <SelectMenuButton
        value={calcWorkspaceLabel(selectedWorkspace)}
        onValueChange={handleWorkspaceChange}
        data={workspaces.map(calcWorkspaceLabel)}
        searchable
        searchPlaceholder="Search for a workspace"
        button={
          <NavigationButton
            dataCy="workspaces-navigation-button"
            leftIcon={Icons.NOMINAL}
            className={`mb-1 rounded-full border border-gray-200 focus:outline-none ${isSelectOpen ? 'bg-gray-100' : ''}`}
          >
            <span className="flex-1 truncate whitespace-pre">
              {calcWorkspaceLabel(selectedWorkspace)}
            </span>

            <Icon
              name={Icons.CARET__DOWN}
              className={
                isSelectOpen ? '-rotate-180 transition-all' : 'transition-all'
              }
            />
          </NavigationButton>
        }
        variant="large"
        onOpenChange={handleOpenChange}
      />
    </>
  );
};

export default WorkspaceSelector;
