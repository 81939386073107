import type { LinkProps } from 'next/link';
import type { ReactElement } from 'react';
import React from 'react';

import SnorkelLink from '@components/SnorkelLink';

type HorizontalNavigationProps = {
  children: ReactElement<React.PropsWithChildren<LinkProps>>[];
  id?: string;
};

const HorizontalNavigation = ({ children, id }: HorizontalNavigationProps) => {
  return (
    <nav id={id} className="text-sm text-gray-text">
      <ol>
        {React.Children.map(
          children,
          (el: ReactElement<React.PropsWithChildren<LinkProps>>, i) => {
            const isLast = i === children.length - 1;

            return (
              <li key={i.toString()} className="inline">
                <span
                  className={`horizontal-link-item ${
                    isLast ? 'text-black' : ''
                  }`}
                >
                  <SnorkelLink
                    {...el.props}
                    href={el.props.href}
                    as={el.props.as}
                  />
                </span>
                {!isLast && (
                  <span aria-hidden="true" className="mx-4">
                    /
                  </span>
                )}
              </li>
            );
          },
        )}
      </ol>
    </nav>
  );
};

export default HorizontalNavigation;
