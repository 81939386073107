import { useSelector } from 'react-redux';

import { usePageRegionAlerts } from '@components/PageRegion';
import Icon, { Icons } from '@coral/components/Icon';
import SidebarMenu from '@global/NavigationContainer/NavigationSidebarFooter/common/SidebarMenu';
import * as selectors from '@global/state/selectors';
import { downloadUrlContents } from '@utils/downloadFile';
import { normalizeUrl } from '@utils/normalizeUrl';

import SidebarMenuItem from '../common/SidebarMenuItem';

const ResourcesMenu = () => {
  const objectStorageUrl = useSelector(
    selectors.navigation.selectMetaObjectStorageUrl,
  );

  const { showInfoAlert, showErrorAlert } = usePageRegionAlerts();

  const onClickDownloadLogs = async () => {
    try {
      showInfoAlert({
        message: 'Your download should start in a moment',
        origin: 'ResourcesMenu',
      });
      await downloadUrlContents(normalizeUrl('/api/bundled-logs'));
    } catch (e) {
      showErrorAlert({
        message: 'Download failed due to missing permissions',
        origin: 'ResourcesMenu',
      });
    }
  };

  return (
    <SidebarMenu>
      <SidebarMenuItem onClick={onClickDownloadLogs}>
        Download Encrypted Logs
      </SidebarMenuItem>
      <SidebarMenuItem
        href={objectStorageUrl}
        aria-label="takes you to MinIO's web app on a separate tab"
      >
        MinIO object storage <Icon name={Icons.LAUNCH} className="ml-1" />
      </SidebarMenuItem>
    </SidebarMenu>
  );
};

export default ResourcesMenu;
