import uniq from 'lodash/uniq';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageRegionAlerts } from '@components/PageRegion';
import { getItem, setItem } from '@coral/utils/localStorage';
import type { DataSource } from '@core/types';
import { SplitType } from '@core/types';
import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';
import useIsAnnotationMode from '@hooks/useIsAnnotationMode';
import useRequest from '@hooks/useRequestWithLogging';
import useSnorkelRouter from '@hooks/useSnorkelRouter';
import { batchesApi, nodesApi } from '@utils/api/serverRequests';
import getInitialSplit from '@utils/getInitialSplit';
import shouldDisableSplit from '@utils/shouldDisableSplit';
import { validateRequestParam } from '@utils/validateRequestParams';

export const getSplitLocalStorageKey = (nodeId: string | number) =>
  `${nodeId}-split`;

const useUpdateInitialSplit = () => {
  const dispatch = useDispatch();
  const nodeId = useSelector(selectors.task.selectNodeId);
  const split = useSelector(selectors.task.selectSplit);
  const request = useRequest();
  const { query } = useSnorkelRouter();
  const { showErrorAlert } = usePageRegionAlerts();
  const isAnnotationMode = useIsAnnotationMode();

  const batchUid = validateRequestParam(query?.batchUid);

  const setSplit = async (signal: AbortSignal) => {
    const batchData =
      !!batchUid && isAnnotationMode
        ? await request(batchesApi.getBatchBatchesBatchUidGet, {
            batchUid,
          })
        : undefined;

    const batchSplit = batchData?.split ?? '';

    const dataSources = await request(
      nodesApi.getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet,
      { nodeUid: nodeId },
    );

    if (!dataSources || signal.aborted) return;

    const splits: SplitType[] = uniq(
      dataSources
        .filter(
          ({
            is_active: isActive,
            supports_dev: supportsDev,
            split: splitName,
          }: DataSource) => {
            if (splitName === SplitType.TRAIN) {
              return supportsDev && isActive;
            }

            return isActive;
          },
        )
        .map(({ split: dsSplit }: DataSource) => dsSplit as SplitType),
    );

    if (!splits.length) {
      showErrorAlert({
        message:
          'No active data sources. Activate data sources and return to this page.',
        origin: 'useUpdateInitialSplit',
      });

      return;
    }

    const currentSplit =
      batchSplit ||
      (query.split as string) ||
      split ||
      (getItem(getSplitLocalStorageKey(nodeId), '') as string);

    const isCurrentSplitDisabled = shouldDisableSplit({
      currentSplit,
      splits,
      isAnnotationMode,
    });

    const newSplit = isCurrentSplitDisabled
      ? getInitialSplit(splits)
      : currentSplit;

    dispatch(actions.task.setSplit(newSplit as SplitType));
    dispatch(actions.task.setSplits(splits));
    dispatch(actions.task.setDataSources(dataSources));

    setItem(getSplitLocalStorageKey(nodeId), newSplit);
  };

  useEffect(() => {
    if (!nodeId) return () => {};

    const abortController = new AbortController();
    setSplit(abortController.signal);

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId]);
};

export default useUpdateInitialSplit;
