import type { AxiosResponse } from 'axios';
import type { SWRConfiguration, SWRResponse } from 'swr';
import useSWR from 'swr';

import useRequest from '@hooks/useRequestWithLogging';
import cacheKeyMiddleware from '@utils/requestCaching/cacheKeyMiddleware';
import type {
  CachedData,
  UseCachedRequestOptions,
} from '@utils/requestCaching/types';

type ExtendedSWROptions<
  TCustom,
  TResponse extends Promise<AxiosResponse<any>>,
> = SWRConfiguration<CachedData<TCustom, TResponse>> & { cacheKey?: string };

const useCachedRequest = <
  TCustom,
  TArgs extends Record<any, any>,
  TResponse extends Promise<AxiosResponse<any>> = Promise<AxiosResponse<any>>,
>(
  openApiPromise: (args: TArgs) => TResponse,
  args: TArgs,
  options: UseCachedRequestOptions<CachedData<TCustom, TResponse>> = {},
): SWRResponse<CachedData<TCustom, TResponse>> => {
  const { useSWROptions, suspendFetch, ...requestOptions } = options;

  const request = useRequest();

  return useSWR<CachedData<TCustom, TResponse>>(
    suspendFetch ? null : [openApiPromise, requestOptions, args],
    () =>
      request(
        openApiPromise,
        args,
        requestOptions,
        !!useSWROptions?.refreshInterval,
      ),
    {
      fallbackData: requestOptions?.fallback,
      use: [cacheKeyMiddleware],
      ...(useSWROptions ?? {}),
    } as ExtendedSWROptions<TCustom, TResponse>,
  );
};

export default useCachedRequest;
