import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from '@global/state/actions';
import { useFlags } from '@hooks/useFlag';
import getAllFlags from '@utils/getFlag/utils/getAllFlags';
import updateFlagBrowserStorageData from '@utils/getFlag/utils/updateFlagBrowserStorageData';
import getRouterQuery from '@utils/getRouterQuery';

/**
 * Initializes the feature flags after the initial render.
 * This is necessary to ensure that all the overrides from localStorage
 * get applied because during SSR we cannot access localStorage.
 */
export default function FeatureFlagInitializer() {
  const dispatch = useDispatch();
  const featureFlags = useFlags();

  useEffect(() => {
    dispatch(
      actions.flags.setFlags(
        getAllFlags(window.location.host, getRouterQuery()),
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    updateFlagBrowserStorageData(featureFlags);
  }, [featureFlags]);

  return null;
}
