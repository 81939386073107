import { useSelector } from 'react-redux';

import type { FlagsMap } from '@global/state/reducers/flags/types';
import * as selectors from '@global/state/selectors';
import type { Flags } from '@utils/getFlag';

export function useFlags(): FlagsMap {
  return useSelector(selectors.flags.selectFlags);
}

export function useFlag(flag: Flags): boolean {
  return !!useFlags()[flag];
}
