import { PublicRoutes } from '@core/constants';
import getIsLicenseValid from '@utils/auth/getIsLicenseValid';

type GetUnauthenticatedHomeRouteTestOptions = {
  disableUsers?: boolean;
};

const getUnauthenticatedHomeRoute = async (
  __testOptions: GetUnauthenticatedHomeRouteTestOptions = {},
) => {
  try {
    const { valid: isLicenseValid, hasUsers } = await getIsLicenseValid();

    if (!isLicenseValid) {
      return PublicRoutes.ADMIN;
    }

    if (!hasUsers) {
      return PublicRoutes.ADMIN_CREATE_USER;
    }
  } catch (e: any) {
    return PublicRoutes.ADMIN;
  }

  return PublicRoutes.ADMIN_LOGIN;
};

export default getUnauthenticatedHomeRoute;
