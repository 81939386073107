import type { AxiosError, AxiosResponse } from 'axios';

export const isFBACError = (error: {
  response?: any;
}): error is AxiosError<{ detail: string }> => {
  return (
    error.response?.status === 403 &&
    /^user: .+ does not have permission to perform action: .+ on the feature: .+$/.test(
      error.response.data.detail,
    )
  );
};

export const parseFeatureFromFBACError = (
  response: AxiosResponse<{ detail: string }>,
) => {
  const feature = response?.data.detail.match(/on the feature: (.+)$/)?.[1];

  return feature;
};
