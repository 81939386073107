import type { ThemeConfig } from 'tailwindcss/types/config';

import palette from './palette';

const augmentedPalette = {
  ...palette,
  coolGray: {
    ...palette.coolGray,
    // This is currently duplicated from 900, but only because the current
    // value for 900 is incorrect in relation to the current design system.
    // https://www.figma.com/design/SqNJZTvByLQHLsb24s1lAk/Coral-2021?node-id=110-141&m=dev
    1000: palette.coolGray['900'],
  },
  gray: {
    ...palette.gray,
    text: '#747B83',
    border: palette.coolGray['300'],
    background: palette.coolGray['100'],
  },
};

/**
 * https://www.figma.com/file/SqNJZTvByLQHLsb24s1lAk/Coral-2021?node-id=3385%3A7956
 */
// This object contains the underlying palette, but also contains color
// variables that are semantic to their purpose.
const colors = {
  ...augmentedPalette,
  transparent: 'transparent',
  current: 'currentColor',
  primary: augmentedPalette.blue['700'],
  secondary: augmentedPalette.purple['700'],
  tertiary: augmentedPalette.teal['700'],
  alert: '#FF00DB',
  highlighter: {
    deprecated: '#B4D5FE',
    yellow: '#FDFF32',
    greenYellowLight: '#E9FF32',
    greenYellow: '#D4FF32',
    greenLight: '#ABFF32',
    greenMed: '#8CFF32',
    greenDark: '#10FF00',
    blueGreen: '#00FFD9',
    red: '#FF0000',
    fuchia: '#FF00DB',
    purple: '#BD00FF',
  },
} satisfies ThemeConfig['colors'];

export default colors;
