import type { Config } from 'tailwindcss';
import type { ThemeConfig } from 'tailwindcss/types/config';

import colors from './colors';

type ConfigExtension = Omit<Config, 'content'>;

const fontSize = {
  xxs: ['0.625rem', '1rem'],
  button: ['0.875rem', '1.5rem'],
  xxl: '2rem', // to be deprecated in v2
  canvas: '12px',
  xsmall: ['0.625rem', '12px'],
  small: ['0.75rem', '16px'],
  medium: ['0.875rem', '24px'],
  large: ['1rem', '24px'],
  xlarge: ['1.25rem', '32px'],
  xxlarge: ['1.5rem', { lineHeight: '32px', letterSpacing: '-0.25%' }],
} satisfies ThemeConfig['fontSize'];

const tailwindConfig = {
  theme: {
    extend: {
      keyframes: {
        keypress: {
          '0%, 100%': { transform: 'scale(1)' },
          '50%': { transform: 'scale(0.8)' },
        },
        slideDown: {
          from: { height: '0px' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        slideUp: {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0px' },
        },
        indeterminateLoading: {
          from: { transform: 'translateX(-100%)' },
          to: {
            transform: 'translateX(100%)',
          },
        },
      },
      animation: {
        // for label button click
        keypress: 'keypress 0.2s ease-in-out',
        // for accordions open/close
        slideDown: 'slideDown 0.2s cubic-bezier(0.87, 0, 0.13, 1)',
        slideUp: 'slideUp 0.2s cubic-bezier(0.87, 0, 0.13, 1)',
        // For loading progress bars
        indeterminateLoading: 'indeterminateLoading 1.3s infinite ease-out',
      },
      zIndex: {
        backdrop: '1200',
        dialog: '1202',
        notifications: '9999',
        tooltips: '10000',
        vis: '4000',
      },
      fontSize,
      letterSpacing: {
        1: '0.01em', // to be deprecated
        2: '0.02em', // to be deprecated
        narrow1: '-0.03rem',
        narrow2: '-0.016rem',
        wide: '0.006rem',
        mono: '1%',
      },
      screens: {
        print: { raw: 'print' },
      },
      borderWidth: {
        '3/2': '1.5px',
      },
      opacity: {
        0: '0',
        25: '.25',
        50: '.5',
        75: '.75',
        10: '.1',
        20: '.2',
        30: '.3',
        40: '.4',
        60: '.6',
        70: '.7',
        80: '.8',
        90: '.9',
        100: '1',
      },
      spacing: {
        124: '31rem',
        92: '23rem',
        72: '18rem',
      },
      padding: {
        '1/2': '0.125rem',
        '5/2': '0.675rem',
        23: '4.5rem',
        28: '7rem',
        30: '7.5rem',
        50: '12.5rem',
        highlight: '1.5px',
        large: '3rem',
      },
      width: {
        form: '400px',
        'slim-modal': '336px',
        'slim-modal-content': '304px',
        'dialog-content': '600px',
        aside: '360px',
        4.5: '1.125rem',
        25: '6.25rem',
        28: '7rem',
        30: '7.5rem',
        36: '9rem',
        44: '11rem',
        50: '12.5rem',
        56: '14rem',
        '11/24': '45.833%',
        '13/24': '54.1%',
        60: '15rem',
        68: '17rem',
        72: '18rem',
        80: '20rem',
        84: '21rem',
        87.5: '21.875rem',
        88: '22rem',
        96: '24rem',
        100: '25rem',
        112: '28rem',
        128: '32rem',
        144: '36rem',
        160: '40rem',
        176: '44rem',
        '24/25': '96%',
        '1px': '1px',
        smallButton: '27px',
        max: 'max-content',
        buttonIcon: '1rem',
        clusterMap: '280px',
        lfAdvancedSettingsMenu: '292px',
      },
      height: {
        toolbar: '52px',
        studioToolbar: '79px',
        3: '0.75rem',
        4.5: '1.125rem',
        7: '1.75rem',
        7.5: '1.875rem',
        11: '2.75rem',
        12.5: '3.125rem',
        18: '4.5rem',
        30: '7.5rem',
        48: '12rem',
        '24/25': '96%',
        '1px': '1px',
        '40px': '40px',
        smallButton: '27px',
        input: '2.5rem',
        buttonIcon: '1rem',
        barChart: '300px',
        clusterMap: '280px',
      },
      lineHeight: {
        // 4.5: '1.125rem',
        small: '0.925rem',
      },
      minWidth: {
        2: '0.5rem',
        3: '0.75rem',
        8: '2rem',
        14: '3.5rem',
        20: '5rem',
        24: '6rem',
        25: '6.25rem',
        27.5: '6.875rem',
        32: '8rem',
        40: '10rem',
        50: '12.5rem',
        52: '15rem',
        76: '19rem',
        87.5: '21.875rem',
        aside: '350px',
        '1/2': '50%',
        'custom-new-lf-button': '9.75rem',
        popup: '360px',
        fit: 'fit-content',
      },
      minHeight: {
        input: '40px',
        8: '2rem',
        10: '2.5rem',
        12: '3rem',
        18: '4.5rem',
        toolbar: '52px',
        studioToolbar: '79px',
        32: '8rem',
        36: '9rem',
        40: '10rem',
        64: '16rem',
        80: '20rem',
        '75h': '75vh',
      },
      maxWidth: {
        'slim-modal': '336px',
        'prediction-item': '212px',
        50: '12.5rem',
        80: '18rem',
        DEFAULT: '1680px',
        p: '600px',
        utterance: '70%',
        conversation: '1000px',
        half: '50%',
      },
      maxHeight: {
        28: '7rem',
        40: '10rem',
        48: '12rem',
        52: '13rem',
        56: '14rem',
        76: '19rem',
        80: '20rem',
        '500px': '500px',
        '35h': '35vh',
        '45h': '45vh',
        '75h': '75vh',
      },
    },
    inset: {
      0: '0px',
      4: '4px',
      16: '16px',
      24: '24px',
      32: '32px',
      96: '96px', // 6rem
      112: '112px',
      128: '128px',
      144: '144px',
      160: '160px',
      176: '176px',
      192: '192px', // 12rem
      208: '208px',
    },

    boxShadow: {
      sm: '0px 6px 6px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.16), inset 0px 8px 24px -24px rgba(0, 0, 0, 0.02)',
      md: '0px 16px 20px rgba(0, 0, 0, 0.04), 0px 14px 16px rgba(0, 0, 0, 0.06), 0px 8px 8px rgba(0, 0, 0, 0.08), inset 0px 24px 24px -24px rgba(0, 0, 0, 0.02), 0px -8px 10px rgba(0, 0, 0, 0.04), 0px -7px 8px rgba(0, 0, 0, 0.06), 0px -4px 4px rgba(0, 0, 0, 0.08)',
      lg: '0px 26px 32px rgba(0, 0, 0, 0.04), 0px 24px 28px rgba(0, 0, 0, 0.04), 0px 16px 16px rgba(0, 0, 0, 0.08), 0px 12px 12px rgba(0, 0, 0, 0.08), inset 0px 8px 24px -24px rgba(0, 0, 0, 0.02)',
      banner: '0px 7px 32px 0px rgba(0, 0, 0, 0.35)',
    },
    colors,
    fontFamily: {
      mono: "'Roboto Mono', Monaco, Courier, monospace",
      regular: "'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    },
  },
  plugins: [
    // eslint-disable-next-line global-require
    require('@tailwindcss/forms'),
  ],
  variants: {
    extend: {
      textColor: ['disabled'],
      backgroundColor: ['disabled'],
    },
  },
} satisfies ConfigExtension;

export default tailwindConfig;
