import { normalizeUrl } from '@utils/normalizeUrl';

const LoginHeader = () => {
  return (
    <div className="mb-4">
      <img
        src={normalizeUrl('/static/logo_dark.png')}
        alt="bg"
        className="mx-auto h-11"
      />
    </div>
  );
};

export default LoginHeader;
