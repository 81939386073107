import type { SupportedFeatures } from './supportedFeatures';

// This one?
export type JWTResponse = {
  username: string;
  userUid: number;
  tokenType: string;
  exp: number;
  supported_features: SupportedFeatures;
  has_assigned_workspaces: boolean;
};

const parseJWTFromAccessToken = (accessToken: string): JWTResponse => {
  const {
    username,
    user_uid: userUid,
    token_type: tokenType,
    exp,
    supported_features,
    has_assigned_workspaces,
  } = JSON.parse(atob(accessToken.split('.')[1]));

  return {
    username,
    userUid,
    tokenType,
    exp,
    supported_features,
    has_assigned_workspaces,
  };
};

export default parseJWTFromAccessToken;
