import type { PropsWithChildren } from 'react';

import Tooltip from './tooltips/TooltipV2';

import { combineTwClasses } from '../utils/combineClasses';

type SpinnerProps = PropsWithChildren<{
  title?: string;
  dataCy?: string;
  size?: 's' | 'm';
  hAlign?: 'left' | 'center';
  padding?: 's' | 'm';
  color?: 'primary' | 'white';
  stretch?: boolean;
}>;

export const defaultSpinnerTestId = 'loading-spinner';

const Spinner = ({
  children,
  title,
  dataCy = defaultSpinnerTestId,
  size = 'm',
  hAlign = 'center',
  padding,
  stretch,
  color = 'primary',
}: SpinnerProps) => {
  return (
    <div
      className={combineTwClasses('flex items-center', {
        'justify-start': hAlign === 'left',
        'justify-center': hAlign === 'center',
        'h-full w-full': stretch,
      })}
    >
      <Tooltip title={title}>
        <div
          className={combineTwClasses('flex items-center gap-2', {
            'p-2': padding === 's',
            'p-8': padding === 'm',
          })}
          data-cy={dataCy}
          role="progressbar"
        >
          <div
            className={combineTwClasses(
              {
                'h-3 w-3 border': size === 's',
                'h-5 w-5 border': size === 'm',
                'border-primary': color === 'primary',
                'border-white': color === 'white',
              },
              'aspect-square animate-spin rounded-full border-r-transparent',
            )}
          />
          {children}
        </div>
      </Tooltip>
    </div>
  );
};

export default Spinner;
