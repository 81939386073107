import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { JobState } from '@api/tdm';
import type { RunningJob } from '@core/types';
import * as selectors from '@global/state/selectors';
import useJobDetailNotification from '@hooks/useJobDetailNotification';
import useJobFailureNotification from '@hooks/useJobFailureNotification';
import usePoller from '@hooks/usePoller';

const useGetStatus = (
  initialValue: RunningJob,
  uuid: string,
  onComplete: (job: RunningJob) => void,
  onStop?: (job: RunningJob) => void,
  hideError?: boolean,
  isStudioJob?: boolean,
  onFail?: (job: RunningJob) => void,
): [RunningJob, Function] => {
  const split = useSelector(selectors.task.selectSplit);
  const taskUid = useSelector(selectors.task.selectNodeId);

  const { status, setStatus, startPolling, stopPolling } = usePoller({
    initialValue,
    requestOptions: { hide500Error: true },
  });

  const { jobFailureNotification } = useJobFailureNotification();

  const { state, message, detail, failed, complete } = status;

  useEffect(() => {
    if (uuid) {
      const url = isStudioJob
        ? `nodes/${taskUid}/splits/${split}/studio-apply-jobs/${uuid}`
        : `jobs/${uuid}`;
      startPolling(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    const { state: initialState } = initialValue;
    if (initialState === JobState.Failed) return;

    if (!message) return;

    if (
      (JobState.Failed in status && failed) ||
      (state && state === JobState.Failed)
    ) {
      if (detail === undefined) {
        setStatus({ ...status, detail: '' });
      }

      if (!hideError) {
        jobFailureNotification(status);
      }

      stopPolling();

      onFail?.(status);
    }

    if (state && state !== JobState.Running) {
      stopPolling();

      onStop?.(status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useJobDetailNotification({ detail });

  useEffect(() => {
    if (state === JobState.Completed || complete) {
      onComplete(status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, complete]);

  return [status, stopPolling];
};

export default useGetStatus;
