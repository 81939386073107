/* eslint-disable local-rules/require-data-cy */
import type { ForwardedRef, MouseEventHandler, PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import SnorkelLink from '@components/SnorkelLink';
import type { Icons } from '@coral/components/Icon';
import Icon from '@coral/components/Icon';

type GetNavItemClassArgs = {
  selected: boolean;
  disabled?: boolean;
  rounded?: boolean;
};

const calcNavItemClass = ({
  selected,
  disabled,
  rounded = true,
}: GetNavItemClassArgs): string => {
  let className =
    'flex justify-between items-center px-3 pr-2 py-1.5 w-full text-left focus:outline-none';

  if (rounded) {
    className += ' rounded';
  }

  if (disabled) {
    className += ' text-gray-400 cursor-not-allowed';
  } else if (selected) {
    className += ' bg-gray-100 font-semibold';
  }

  return className;
};

type NavigationButtonProps = PropsWithChildren<
  {
    selected?: boolean;
    highlighted?: boolean;
    disabled?: boolean;
    className?: string;
    leftIcon?: Icons;
    isNotification?: boolean;
    dataCy?: string;
  } & (
    | {
        onClick?: MouseEventHandler<HTMLButtonElement>;
        href?: never;
      }
    | {
        onClick?: never;
        href?: string;
      }
  )
>;

const NavigationButton = forwardRef(
  (props: NavigationButtonProps, forwardedRef: ForwardedRef<any>) => {
    const {
      selected = false,
      disabled = false,
      children,
      onClick,
      href,
      className,
      leftIcon,
      isNotification = false,
      dataCy,
      ...restProps
    } = props;

    const shouldRenderNotificationIndicator = Boolean(
      isNotification && selected,
    );

    // When rendering a notification,  we override the selected state styling
    // from the selected style in calcNavItemClass to a custom indicator
    const selectedOverride = isNotification ? false : selected;

    const listClasses = calcNavItemClass({
      selected: selectedOverride,
      disabled,
    });

    const hoverState = 'hover:bg-gray-100';

    if (disabled) {
      return (
        <div ref={forwardedRef} data-cy={dataCy} className={listClasses}>
          {leftIcon && <Icon className="mr-2" name={leftIcon} />}
          {children}
        </div>
      );
    }

    if (!href) {
      return (
        <button
          {...restProps}
          ref={forwardedRef}
          data-cy={dataCy}
          className={`${listClasses} ${className || ''} ${hoverState}`}
          type="button"
          disabled={disabled}
          onClick={onClick}
        >
          {leftIcon && (
            <>
              <Icon name={leftIcon} />
              <div
                data-cy="notification-indicator"
                className={` ${
                  shouldRenderNotificationIndicator
                    ? 'mb-3 mr-2 inline-block h-2 w-2 rounded-xl bg-red-600'
                    : 'mb-3 mr-4'
                }`}
              />
            </>
          )}

          {children}
        </button>
      );
    }

    return (
      <SnorkelLink
        data-cy={dataCy}
        ref={forwardedRef}
        className={`${listClasses} ${className || ''} ${hoverState}`}
        role="button"
        type="button"
        href={disabled ? '' : href}
        {...(selected ? { 'aria-current': 'page' } : {})}
      >
        {leftIcon && (
          <Icon
            className={`${
              shouldRenderNotificationIndicator
                ? 'mr-2 inline-block h-2 w-2 rounded-xl bg-red-600'
                : 'mr-4'
            }`}
            name={leftIcon}
          />
        )}
        {children}
      </SnorkelLink>
    );
  },
);

export default NavigationButton;
