import flags from '@utils/getFlag/flags.json';
import getFlagHostOverrides from '@utils/getFlag/utils/getFlagHostOverrides';
import getFlagInMemoryOverride from '@utils/getFlag/utils/getFlagInMemoryOverride';

import type { Flags } from '../flags';

const getAllFlags = (
  hostname: string,
  query: Record<string, any>,
  flagList = flags,
): Partial<Record<Flags, boolean>> => {
  return Object.entries(flagList).reduce(
    (acc: Record<string, boolean>, [flag, flagData]) => ({
      ...acc,
      [flag as Flags]:
        getFlagInMemoryOverride(flag, query) ??
        getFlagHostOverrides((flagData as any).overrides, hostname) ??
        flagData.enabled,
    }),
    {},
  );
};

export default getAllFlags;
