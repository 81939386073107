/* eslint-disable local-rules/require-data-cy */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '@coral/components/Button';
import Icon, { Icons } from '@coral/components/Icon';
import Tooltip from '@coral/components/tooltips/TooltipV2';
import * as selectors from '@global/state/selectors';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import useRequest from '@hooks/useRequestWithLogging';
import { applicationsApi } from '@utils/api/serverRequests';

const NotebookLeftToolbar = () => {
  const [applicationName, setApplicationName] = useState<string>('');

  const { handleCopy } = useCopyToClipboard();
  const request = useRequest();
  const datasetName = useSelector(selectors.task.selectDatasetName);
  const nodeId = useSelector(selectors.task.selectNodeId);
  const applicationUid = useSelector(selectors.task.selectApplicationUid)!;

  // TODO get application name from context ch9366
  useEffect(() => {
    if (!applicationUid) {
      setApplicationName('');

      return undefined;
    }

    let shouldIgnore = false;

    const getApplicationUid = async () => {
      const application = await request(
        applicationsApi.getApplicationApplicationsApplicationUidGet,
        { applicationUid },
      );

      if (!shouldIgnore) {
        setApplicationName(application ? application.name : '');
      }
    };

    getApplicationUid();

    return () => {
      shouldIgnore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationUid]);

  return (
    <div className="ml-4 flex items-center text-sm text-gray-700">
      {datasetName && (
        <div className="flex items-center">
          {datasetName}
          <Tooltip title="copy dataset name">
            <Button
              border={false}
              className="mr-2"
              id="copy-application-name"
              onClick={() => handleCopy(datasetName)}
            >
              <Icon name={Icons.COPY__FILE} />
            </Button>
          </Tooltip>
        </div>
      )}
      {datasetName && applicationName && '/'}
      {applicationName && (
        <div className="ml-2 flex items-center">
          {applicationName}
          <Tooltip title="copy application name">
            <Button
              border={false}
              className="mr-2"
              id="copy-application-name"
              onClick={() => handleCopy(applicationName)}
            >
              <Icon name={Icons.COPY__FILE} />
            </Button>
          </Tooltip>
        </div>
      )}
      {applicationName && nodeId && '/'}
      {nodeId && (
        <div className="ml-2 flex items-center">
          Node {nodeId}
          <Tooltip title="copy node ID">
            <Button
              border={false}
              id="copy-node-name"
              onClick={() => handleCopy(nodeId.toString())}
            >
              <Icon name={Icons.COPY__FILE} />
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default NotebookLeftToolbar;
