import { useEffect } from 'react';

const useEffectAbortable = <Deps extends readonly any[]>(
  func: (signal: AbortSignal) => void,
  deps: Deps,
) => {
  useEffect(() => {
    let abortController: AbortController | null = new AbortController();
    func(abortController.signal);

    return () => {
      abortController?.abort('unknown');
      abortController = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useEffectAbortable;
