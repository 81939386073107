import type { Dispatch, Middleware, UnknownAction } from 'redux';

import { createActionWithPayload } from './reducer';
import type {
  Action,
  ActionType,
  Controller,
  ControllerAction,
  ControllerActionCreator,
  ControllerActionEmbeddedAction,
  ControllerActionEventHandlers,
  ControllerActionHandler,
  ControllerActionHandlerMap,
  ControllerActionHandlerPayloadType,
  ControllerActionHandlerResult,
  ControllerActionHandlerResultType,
  ControllerSlice,
  GetState,
} from './types';

// --------------------------------------------------------------------
type ControllerActionCreatorsFromHandlers<TState, TControllerActionHandlerMap> =
  {
    [K in keyof TControllerActionHandlerMap]: TControllerActionHandlerMap[K] extends ControllerActionHandler<
      TState,
      infer TPayload,
      infer TResult
    >
      ? ControllerActionCreator<TPayload, TResult>
      : never;
  };

// --------------------------------------------------------------------
const actionTypeController = 'controllerAction' as ActionType;

// --------------------------------------------------------------------
export const createControllerAction = <TPayload, TResult = undefined>(
  action: Action<TPayload>,
  eventHandlers: ControllerActionEventHandlers<TResult> = {},
): ControllerAction<TPayload, TResult> => ({
  type: actionTypeController,
  payload: { ...action, ...eventHandlers },
});

// --------------------------------------------------------------------
export const runControllerAction = <TPayload, TResult>(
  dispatch: Dispatch,
  action: Action<TPayload>,
): Promise<ControllerActionHandlerResult<TResult>> => {
  return new Promise(resolve => {
    dispatch(
      createControllerAction(action, {
        onSuccess(result: TResult) {
          resolve({ status: 'success', result });
        },
        onError(error: Error) {
          resolve({ status: 'error', error });
        },
      }),
    );
  });
};

// --------------------------------------------------------------------
export const createController = <TState>(
  sliceKey: string,
  handlers: ControllerActionHandlerMap<TState>,
) => {
  const controller = async (
    dispatch: Dispatch,
    getState: GetState<TState>,
    {
      type,
      payload,
      onSuccess,
      onError,
    }: ControllerActionEmbeddedAction<any, any>,
  ) => {
    const handler = handlers[type];

    if (handler) {
      const result = handler(dispatch, getState, payload);

      if (result === undefined) {
        onSuccess?.(result);

        return;
      }

      const response = await result;

      if (response?.status === 'success') {
        onSuccess?.(response.result);
      } else if (response?.status === 'error') {
        onError?.(response.error);
      }
    }
  };

  return controller;
};

// --------------------------------------------------------------------
const controllerActionTypeFromKey = (sliceKey: string, k: string): ActionType =>
  `${sliceKey}/${k}` as ActionType;

const createControllerActionTypes = <TState>(
  sliceKey: string,
  controllerActionHandlerMap: ControllerActionHandlerMap<TState>,
): Record<string, ActionType> => {
  const controllerActionTypes = Object.keys(controllerActionHandlerMap).reduce(
    (acc, k) => {
      return { ...acc, [k]: controllerActionTypeFromKey(sliceKey, k) };
    },
    {},
  );

  return controllerActionTypes;
};

export const controllerActionCreatorFromHandler = <TState, TPayload, TResult>(
  type: ActionType,
  actionHandler: ControllerActionHandler<TState, TPayload, TResult>,
): ControllerActionCreator<TPayload, TResult> => {
  const actionCreator = (
    payload: ControllerActionHandlerPayloadType<typeof actionHandler>,
    eventHandlers?: ControllerActionEventHandlers<
      ControllerActionHandlerResultType<typeof actionHandler>
    >,
  ) =>
    createControllerAction(
      createActionWithPayload(type, payload),
      eventHandlers,
    );

  return actionCreator;
};

const createControllerActionCreators = <
  TState,
  TControllerActionHandlerMap extends ControllerActionHandlerMap<TState>,
>(
  sliceKey: string,
  controllerActionHandlerMap: TControllerActionHandlerMap,
) => {
  const controllerActionCreators = Object.keys(
    controllerActionHandlerMap,
  ).reduce((acc, k) => {
    const type = controllerActionTypeFromKey(sliceKey, k);
    const handler = controllerActionHandlerMap[k];
    const actionCreator = controllerActionCreatorFromHandler(type, handler);

    return { ...acc, [k]: actionCreator };
  }, {});

  return controllerActionCreators as ControllerActionCreatorsFromHandlers<
    TState,
    TControllerActionHandlerMap
  >;
};

const createControllerHandlerMap = <TState>(
  sliceKey: string,
  controllerActionHandlerMap: ControllerActionHandlerMap<TState>,
) => {
  const handlerMap = Object.keys(controllerActionHandlerMap).reduce(
    (acc, k) => {
      const type = controllerActionTypeFromKey(sliceKey, k);

      return { ...acc, [type]: controllerActionHandlerMap[k] };
    },
    {},
  );

  return handlerMap as ControllerActionHandlerMap<TState>;
};

export const createControllerSlice = <
  TState,
  TControllerActionHandlerMap extends ControllerActionHandlerMap<TState>,
>(
  sliceKey: string,
  controllerActionHandlerMap: TControllerActionHandlerMap,
): ControllerSlice<
  TState,
  ControllerActionCreatorsFromHandlers<TState, TControllerActionHandlerMap>
> => {
  const actionTypes = createControllerActionTypes(
    sliceKey,
    controllerActionHandlerMap,
  );

  const actionCreators = createControllerActionCreators<
    TState,
    TControllerActionHandlerMap
  >(sliceKey, controllerActionHandlerMap);

  const handlerMap = createControllerHandlerMap(
    sliceKey,
    controllerActionHandlerMap,
  );
  const controller = createController<TState>(sliceKey, handlerMap);

  return { sliceKey, actionTypes, actionCreators, controller };
};

// --------------------------------------------------------------------
export const combineControllers = <TState>(
  controllers: Controller<TState>[],
): Controller<TState> => {
  const rootController = (
    dispatch: Dispatch,
    getState: GetState<TState>,
    action: Action<any>,
  ) => {
    controllers.forEach(controller => controller(dispatch, getState, action));
  };

  return rootController;
};

// --------------------------------------------------------------------

const isUnknownAction = (
  action: any,
): action is ControllerActionEmbeddedAction<any, any> =>
  action.type === actionTypeController && action.payload;

export const createControllerMiddleware = <TState>(
  rootController: Controller<TState>,
) => {
  const middleware: Middleware<{}, any, Dispatch<UnknownAction>> =
    ({ dispatch, getState }) =>
    next =>
    action => {
      if (isUnknownAction(action)) {
        rootController(dispatch, getState, action.payload);

        return getState();
      }

      return next(action);
    };

  return middleware;
};
