import { isNull } from 'lodash';

import type { StudioViewModes } from '@app/StudioPage/types';
import { MultiLabelStatusTypes } from '@coral/components/Labels/constants';
import { DEFAULT_MULTI_LABEL_KEY } from '@coral/constants';
import type { RawMultiLabelMap } from '@coral/types';
import type {
  InverseLabelMap,
  Label,
  LabelMap,
  LF,
  Operator,
  TemplateConfig,
} from '@core/types';
import { LFMetricNames } from '@core/types';
import getDefaultGraph from '@utils/getDefaultGraph';
import {
  ContentViewModes,
  GroupedContentViewModes,
} from '@wave/common/constants';

export const IMAGE_STUDIO_PREVIEW_LF_NAME = 'image-studio-preview-lf';
export const IMAGE_TEXT_COMPARATOR_LF_TEMPLATE_TYPE = 'image_text_comparator';
export const IMAGE_IMAGE_COMPARATOR_LF_TEMPLATE_TYPE = 'image_image_comparator';
export const IMAGE_PATCH_COMPARATOR_LF_TEMPLATE_TYPE = 'image_patch_comparator';
export const IMAGE_MODEL_BASED_LF_TEMPLATE_TYPE = 'image_model_based';
export const MULTIPOLAR_IMAGE_TEXT_COMPARATOR_LF_TEMPLATE_TYPE =
  'multipolar_image_text_comparator';
export const MULTIPOLAR_IMAGE_IMAGE_COMPARATOR_LF_TEMPLATE_TYPE =
  'multipolar_image_image_comparator';
export const MULTIPOLAR_IMAGE_PATCH_COMPARATOR_LF_TEMPLATE_TYPE =
  'multipolar_image_patch_comparator';
export const MULTIPOLAR_IMAGE_MODEL_BASED_LF_TEMPLATE_TYPE =
  'multipolar_image_model_based';

const MultipolarToUnipolarLFTemplate = {
  [MULTIPOLAR_IMAGE_TEXT_COMPARATOR_LF_TEMPLATE_TYPE]:
    IMAGE_TEXT_COMPARATOR_LF_TEMPLATE_TYPE,
  [MULTIPOLAR_IMAGE_IMAGE_COMPARATOR_LF_TEMPLATE_TYPE]:
    IMAGE_IMAGE_COMPARATOR_LF_TEMPLATE_TYPE,
  [MULTIPOLAR_IMAGE_PATCH_COMPARATOR_LF_TEMPLATE_TYPE]:
    IMAGE_PATCH_COMPARATOR_LF_TEMPLATE_TYPE,
  [MULTIPOLAR_IMAGE_MODEL_BASED_LF_TEMPLATE_TYPE]:
    IMAGE_MODEL_BASED_LF_TEMPLATE_TYPE,
};

// TODO(ENG-17694): Rename present/absent to positive/negative.
export const DEFAULT_PRESENT_THRESHOLD = 0.5;
export const DEFAULT_ABSENT_THRESHOLD = -0.5;
export const IMAGE_INITIAL_SIMILARITY = -1;

/** LFs */

export type GetImageTextLFTemplateConfigArgs = Readonly<{
  textQuery: string;
  imageEmbeddingField: string;
  operator?: Operator;
  minSimilarity?: number;
}>;

export const getImageTextLFTemplateConfig = ({
  textQuery,
  imageEmbeddingField,
  operator = '>=',
  minSimilarity = IMAGE_INITIAL_SIMILARITY,
}: GetImageTextLFTemplateConfigArgs): TemplateConfig => ({
  template_type: IMAGE_TEXT_COMPARATOR_LF_TEMPLATE_TYPE,
  image_embedding_field: imageEmbeddingField,
  text_query: textQuery,
  operator,
  min_similarity: minSimilarity,
});

export type GetImageImageLFTemplateConfigArgs = Readonly<{
  imagePath: string;
  imageEmbeddingField: string;
  operator?: Operator;
  minSimilarity?: number;
}>;

export const getImageImageLFTemplateConfig = ({
  imagePath,
  imageEmbeddingField,
  operator = '>=',
  minSimilarity = IMAGE_INITIAL_SIMILARITY,
}: GetImageImageLFTemplateConfigArgs): TemplateConfig => ({
  template_type: IMAGE_IMAGE_COMPARATOR_LF_TEMPLATE_TYPE,
  image_embedding_field: imageEmbeddingField,
  image_path: imagePath,
  operator,
  min_similarity: minSimilarity,
});

export type GetImagePatchLFTemplateConfigArgs =
  GetImageImageLFTemplateConfigArgs & Readonly<{ boundingBox: number[] }>;

export const getImagePatchLFTemplateConfig = ({
  imagePath,
  imageEmbeddingField,
  boundingBox,
  operator = '>=',
  minSimilarity = IMAGE_INITIAL_SIMILARITY,
}: GetImagePatchLFTemplateConfigArgs): TemplateConfig => ({
  template_type: IMAGE_PATCH_COMPARATOR_LF_TEMPLATE_TYPE,
  image_embedding_field: imageEmbeddingField,
  image_path: imagePath,
  bbox: boundingBox,
  operator,
  min_similarity: minSimilarity,
});

export type GetImageModelBasedLFTemplateConfigArgs = Readonly<{
  modelName: string;
  modelLabel: string;
  dirpath: string;
  labelMap: LabelMap;
  operator?: Operator;
  minSimilarity?: number;
}>;

export const getImageModelBasedLFTemplateConfig = ({
  modelName,
  modelLabel,
  dirpath,
  labelMap,
  operator = '>=',
  minSimilarity = IMAGE_INITIAL_SIMILARITY,
}: GetImageModelBasedLFTemplateConfigArgs): TemplateConfig => ({
  template_type: IMAGE_MODEL_BASED_LF_TEMPLATE_TYPE,
  model_name: modelName,
  model_label: modelLabel,
  dirpath,
  label_map: labelMap,
  operator,
  min_similarity: minSimilarity,
});

export type GetMultipolarImageTextLFTemplateConfigArgs = Readonly<{
  textQuery: string;
  imageEmbeddingField: string;
  label: string;
  presentThreshold?: number;
  absentThreshold?: number;
  invertVotes?: boolean;
}>;

export const getMultipolarImageTextLFTemplateConfig = ({
  textQuery,
  imageEmbeddingField,
  label,
  presentThreshold = DEFAULT_PRESENT_THRESHOLD,
  absentThreshold = DEFAULT_ABSENT_THRESHOLD,
  invertVotes = false,
}: GetMultipolarImageTextLFTemplateConfigArgs): TemplateConfig => ({
  template_type: MULTIPOLAR_IMAGE_TEXT_COMPARATOR_LF_TEMPLATE_TYPE,
  image_embedding_field: imageEmbeddingField,
  text_query: textQuery,
  label,
  pos_threshold: presentThreshold,
  neg_threshold: absentThreshold,
  invert_votes: invertVotes,
});

export type GetMultipolarImageImageLFTemplateConfigArgs = Readonly<{
  imagePath: string;
  imageEmbeddingField: string;
  label: string;
  presentThreshold?: number;
  absentThreshold?: number;
  invertVotes?: boolean;
}>;

export const getMultipolarImageImageLFTemplateConfig = ({
  imagePath,
  imageEmbeddingField,
  label,
  presentThreshold = DEFAULT_PRESENT_THRESHOLD,
  absentThreshold = DEFAULT_ABSENT_THRESHOLD,
  invertVotes = false,
}: GetMultipolarImageImageLFTemplateConfigArgs): TemplateConfig => ({
  template_type: MULTIPOLAR_IMAGE_IMAGE_COMPARATOR_LF_TEMPLATE_TYPE,
  image_embedding_field: imageEmbeddingField,
  image_path: imagePath,
  label,
  pos_threshold: presentThreshold,
  neg_threshold: absentThreshold,
  invert_votes: invertVotes,
});

export type GetMultipolarImagePatchLFTemplateConfigArgs =
  GetMultipolarImageImageLFTemplateConfigArgs &
    Readonly<{ boundingBox: number[] }>;

export const getMultipolarImagePatchLFTemplateConfig = ({
  imagePath,
  imageEmbeddingField,
  boundingBox,
  label,
  presentThreshold = DEFAULT_PRESENT_THRESHOLD,
  absentThreshold = DEFAULT_ABSENT_THRESHOLD,
  invertVotes = false,
}: GetMultipolarImagePatchLFTemplateConfigArgs): TemplateConfig => ({
  template_type: MULTIPOLAR_IMAGE_PATCH_COMPARATOR_LF_TEMPLATE_TYPE,
  image_embedding_field: imageEmbeddingField,
  image_path: imagePath,
  bbox: boundingBox,
  label,
  pos_threshold: presentThreshold,
  neg_threshold: absentThreshold,
  invert_votes: invertVotes,
});

export type GetMultipolarImageModelBasedLFTemplateConfigArgs = Readonly<{
  modelName: string;
  modelLabel: string;
  label: string;
  dirpath: string;
  labelMap: LabelMap;
  presentThreshold?: number;
  absentThreshold?: number;
  invertVotes?: boolean;
}>;

export const getMultipolarImageModelBasedLFTemplateConfig = ({
  modelName,
  modelLabel,
  label,
  dirpath,
  labelMap,
  presentThreshold = DEFAULT_PRESENT_THRESHOLD,
  absentThreshold = DEFAULT_ABSENT_THRESHOLD,
  invertVotes = false,
}: GetMultipolarImageModelBasedLFTemplateConfigArgs): TemplateConfig => ({
  template_type: MULTIPOLAR_IMAGE_MODEL_BASED_LF_TEMPLATE_TYPE,
  model_name: modelName,
  model_label: modelLabel,
  label,
  dirpath,
  label_map: labelMap,
  pos_threshold: presentThreshold,
  neg_threshold: absentThreshold,
  invert_votes: invertVotes,
});

export const isMultipolarImageLFTemplateConfig = (
  templateConfig: TemplateConfig,
) =>
  Object.keys(MultipolarToUnipolarLFTemplate).includes(
    templateConfig.template_type,
  );

export const isImageLF = (lf: LF) =>
  !!lf.multipolar_template &&
  isMultipolarImageLFTemplateConfig(lf.multipolar_template);

export const getImageLFConfig = (
  templateConfig: TemplateConfig,
  label: Label = 0,
): LF =>
  ({
    name: IMAGE_STUDIO_PREVIEW_LF_NAME,
    ...(isMultipolarImageLFTemplateConfig(templateConfig)
      ? { multipolar_template: templateConfig }
      : { templates: [templateConfig] }),
    graph: getDefaultGraph(1),
    label,
  }) as LF;

export const convertMultipolarToUnipolarImageLFTemplateConfig = (
  templateConfig: TemplateConfig,
) => {
  const newTemplateConfig = { ...templateConfig };
  newTemplateConfig.template_type =
    MultipolarToUnipolarLFTemplate[templateConfig.template_type];
  // Default the displayed dataset to the positive matches first.
  newTemplateConfig.min_similarity = templateConfig.pos_threshold;
  delete newTemplateConfig.pos_threshold;
  delete newTemplateConfig.neg_threshold;

  return newTemplateConfig;
};

export const getDefaultUnknownRawLabels = (
  inverseLabelMap: InverseLabelMap,
): RawMultiLabelMap =>
  Object.keys(inverseLabelMap).reduce(
    (acc, labelInt) => {
      acc[labelInt] = MultiLabelStatusTypes.Abstain;

      return acc;
    },
    { [DEFAULT_MULTI_LABEL_KEY]: MultiLabelStatusTypes.Abstain },
  );

/** Data views */

export const isContentViewMode = (viewMode: StudioViewModes) =>
  ContentViewModes.includes(viewMode);

export const isGroupedContentViewMode = (viewMode: StudioViewModes) =>
  GroupedContentViewModes.includes(viewMode);

/** Styles */

export const HIGH_PRECISION_THRESHOLD = 0.9;
export const LOW_PRECISION_THRESHOLD = 0.7;
export const HIGH_RECALL_THRESHOLD = 0.1;
export const LOW_RECALL_THRESHOLD = 0.01;
export const HIGH_THRESHOLD_CLASS_NAME = 'text-green-600';
export const LOW_THRESHOLD_CLASS_NAME = 'text-red-700';

export const getLFMetricClassName = (
  metricName: LFMetricNames,
  metricValue: number | null,
) => {
  if (isNull(metricValue)) return '';

  if (metricName === LFMetricNames.PRECISION) {
    if (metricValue > HIGH_PRECISION_THRESHOLD)
      return HIGH_THRESHOLD_CLASS_NAME;
    if (metricValue < LOW_PRECISION_THRESHOLD) return LOW_THRESHOLD_CLASS_NAME;
  }

  if (metricName === LFMetricNames.RECALL) {
    if (metricValue > HIGH_RECALL_THRESHOLD) return HIGH_THRESHOLD_CLASS_NAME;
    if (metricValue < LOW_RECALL_THRESHOLD) return LOW_THRESHOLD_CLASS_NAME;
  }

  return '';
};

export default null;
