import type { ApplicationConfig, AppLicenseType } from '@api/tdm';
import type { HydratedOperatorDag } from '@app/Applications/NewApplicationPage/types';
import { emptyObj } from '@core/empty';
import { createReducerSlice } from '@utils/redux/reducer';

import type { AppDetailsState } from './types';

type UpdateDagAndConfig = Readonly<{
  dag: HydratedOperatorDag;
  applicationConfig: ApplicationConfig;
}>;

export const initialState: AppDetailsState = emptyObj as AppDetailsState;

const reducerSlice = createReducerSlice('appDetails', initialState, {
  reset(): AppDetailsState {
    return initialState;
  },

  setApplication(
    prevState: AppDetailsState,
    nextState: AppDetailsState,
  ): AppDetailsState {
    return JSON.stringify(prevState) === JSON.stringify(nextState)
      ? prevState
      : nextState;
  },

  setName(state: AppDetailsState, name: string): AppDetailsState {
    state = name === state.name ? state : { ...state, name };

    return state;
  },

  setLicenseType(
    state: AppDetailsState,
    licenseType: AppLicenseType,
  ): AppDetailsState {
    let { licenseConfig } = state;
    if (licenseConfig.app_license_type === licenseType) return state;

    licenseConfig = { ...licenseConfig, app_license_type: licenseType };
    state = { ...state, licenseConfig };

    return state;
  },

  archive(state: AppDetailsState): AppDetailsState {
    let { licenseConfig } = state;
    if (licenseConfig.is_archived) return state;

    licenseConfig = { ...licenseConfig, is_archived: true };
    state = { ...state, licenseConfig };

    return state;
  },

  unarchive(state: AppDetailsState): AppDetailsState {
    let { licenseConfig } = state;
    if (!licenseConfig.is_archived) return state;

    licenseConfig = {
      ...licenseConfig,
      is_archived: false,
      last_archived_date: new Date().toISOString(),
    };
    state = { ...state, licenseConfig };

    return state;
  },

  updateDagAndConfig(
    state: AppDetailsState,
    { dag, applicationConfig }: UpdateDagAndConfig,
  ): AppDetailsState {
    if (
      JSON.stringify(dag) !== JSON.stringify(state?.dag) ||
      JSON.stringify(applicationConfig) !==
        JSON.stringify(state?.applicationConfig)
    ) {
      state = { ...state, dag, applicationConfig };
    }

    return state;
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;
