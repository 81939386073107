import Link from 'next/link';
import type { LinkProps } from 'next/link';
import type { HTMLProps, PropsWithChildren } from 'react';

import { normalizeUrl } from '@utils/normalizeUrl';

const SnorkelLink = ({
  href,
  ...props
}: PropsWithChildren<
  LinkProps & Omit<HTMLProps<HTMLAnchorElement>, 'href' | 'as'>
>) => <Link href={normalizeUrl(href.toString())} {...props} />;
export default SnorkelLink;
