import type { PreprocessedDatasourceResponse } from '@api/tdm';
import pipe from '@utils/pipe';

import type { DataState } from './types';

import type { RootState, Selector } from '../../types';

export const selectAll: Selector<DataState> = ({ data }: RootState) => data;

export const selectConfig: Selector<DataState['config']> = pipe(
  selectAll,
  ({ config }: DataState) => config,
);

export const selectViewIncorrect: Selector<boolean> = pipe(
  selectAll,
  ({ viewIncorrect }: DataState) => viewIncorrect,
);
export const selectExpanded: Selector<boolean> = pipe(
  selectAll,
  ({ expanded }: DataState) => expanded,
);
export const selectFieldsMap: Selector<Record<string, string>> = pipe(
  selectAll,
  ({ fieldsMap }: DataState) => fieldsMap,
);
export const selectFieldTypes: Selector<string[]> = pipe(
  selectAll,
  ({ field_types }: DataState) => field_types,
);
export const selectInitialLoad: Selector<boolean> = pipe(
  selectAll,
  ({ initialLoad }: DataState) => initialLoad,
);
export const selectSelectedIndex: Selector<number | null> = pipe(
  selectAll,
  ({ selectedIndex }: DataState) => selectedIndex,
);
export const selectTotalDevCount: Selector<number> = pipe(
  selectAll,
  ({ total_dev_count }: DataState) => total_dev_count,
);
export const selectTotalLabeledCount: Selector<number> = pipe(
  selectAll,
  ({ total_labeled_count }: DataState) => total_labeled_count,
);
export const selectActiveDataSources: Selector<
  PreprocessedDatasourceResponse[] | null
> = pipe(selectAll, ({ activeDataSources }: DataState) => activeDataSources);

export default null;
