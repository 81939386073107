import * as Select from '@radix-ui/react-select';
import type { ComponentProps, ReactElement, ReactNode } from 'react';
import { useMemo, useRef, useState } from 'react';

import combineClasses from '@coral/utils/combineClasses';

import SelectMenuItem from './SelectMenuItem';
import SelectMenuLabel from './SelectMenuLabel';

import Text, { TextSize, TextVariant } from '../Text';
import { TextColor } from '../Textv2';

const kWHConstraintsSmall = 'max-w-36 max-h-36';
const kWHConstraintsLarge = 'max-w-72 max-h-96';

type SelectMenuVariant = 'small' | 'large';

export type SelectMenuProps = Pick<
  ComponentProps<typeof Select.Root>,
  'onValueChange' | 'onOpenChange' | 'open' | 'value'
> & {
  data: string[];
  button?: ReactElement;
  disabled?: boolean;
  menu?: ReactNode;
  searchable?: boolean;
  searchPlaceholder?: string;
  variant?: SelectMenuVariant;
};

const SelectMenuButton = (props: SelectMenuProps) => {
  const {
    button,
    menu,
    disabled,
    data,
    searchable = false,
    searchPlaceholder,
    onOpenChange,
    variant = 'small',
    ...selectProps
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);

  const filteredData = useMemo(
    () =>
      data.filter(item =>
        item.toLowerCase().startsWith(searchTerm.toLowerCase()),
      ),
    [data, searchTerm],
  );

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      // Reset search term when the menu is closed
      setSearchTerm('');
    }

    onOpenChange?.(isOpen);
  };

  // ENG-26660: Force-focus on the search input to workaround radix/sidebar
  // hover issue.
  const focusOnSearchInput = () => {
    setTimeout(() => {
      searchInputRef.current?.focus();
    }, 0); // zero-timeout to delay till end of current event loop
  };

  const renderData = () => {
    if (searchable) {
      return filteredData.length ? (
        filteredData.map(item => (
          <SelectMenuItem
            key={item}
            value={item}
            data-cy={`select-${item}`}
            isSelected={item === selectProps.value}
          />
        ))
      ) : (
        <SelectMenuLabel
          label={
            <Text
              variant={TextVariant.body}
              size={TextSize.s}
              color={TextColor.GRAY}
            >
              No results
            </Text>
          }
        />
      );
    }

    return data.map(item => (
      <SelectMenuItem
        key={item}
        value={item}
        data-cy={`select-${item}`}
        isSelected={item === selectProps.value}
      />
    ));
  };

  const renderSearchInput = () => {
    return (
      <div className="mb-1 gap-1 border-b border-gray-200 align-middle">
        <input
          ref={searchInputRef}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          type="text"
          className="w-full border-none text-xs outline-none focus:border-none focus:outline-none"
          placeholder={searchPlaceholder ?? 'Search...'}
          onChange={e => setSearchTerm(e.target.value)}
          data-cy="select-menu-search-input"
        />
      </div>
    );
  };

  const whConstraints =
    variant === 'small' ? kWHConstraintsSmall : kWHConstraintsLarge;

  return (
    <Select.Root {...selectProps} onOpenChange={handleOpenChange}>
      <Select.Trigger
        className={combineClasses(button ? 'cursor-pointer' : 'cursor-default')}
        asChild={!!button}
        disabled={disabled}
        data-cy="select-menu-trigger"
        onClick={focusOnSearchInput}
      >
        {button || null}
      </Select.Trigger>

      <Select.Content
        className={combineClasses(
          'z-tooltips overflow-y-auto rounded-md bg-white px-2 py-4 text-left shadow-md',
          whConstraints,
        )}
      >
        {searchable ? renderSearchInput() : null}
        <Select.Viewport>
          <Select.Group className={whConstraints} data-cy="select-menu-group">
            {menu}
            {renderData()}
          </Select.Group>
        </Select.Viewport>
      </Select.Content>
    </Select.Root>
  );
};

export default SelectMenuButton;
