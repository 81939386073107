import { useEffect } from 'react';

import publicRuntimeConfig from '@utils/publicRuntimeConfig';

const EnvVarInspector = () => {
  const envVarsFromRuntimeConfig = publicRuntimeConfig;

  useEffect(() => {
    (window as any).sf = (window as any).sf ?? {};

    (window as any).sf.getEnvVars = () => ({
      envVarsFromRuntimeConfig,
      envVarsFromProcess:
        "Process.env isn't exposed in browser. Check this instance's Lighthouse config for env vars with the NEXT_PUBLIC prefix",
    });

    return () => {
      if ((window as any).sf) {
        (window as any).sf.getEnvVars = undefined;
      }
    };
  }, [envVarsFromRuntimeConfig]);

  return null;
};

export default EnvVarInspector;
