import type { PropsWithChildren } from 'react';

import useSnorkelRouter from '@hooks/useSnorkelRouter';

// Note that the strings provided in the arrays below should match the Next.js
// directory based routing paths. For example, when creating a new application,
// after you have selected the dataset, the actual url will be something like
// `/applications/new/dataset/18729`, but the path to the page component is
// `src/pages/applications/new/dataset/[...slug]/index.tsx`, and the pathname
// returned by `useSnorkelRouter` will be `/applications/new/dataset/[...slug]`,
// so you should add the literal `/applications/new/dataset/[...slug]` to the
// array below if that particular page component is responsive ready.
const kResponsiveReadyPageRoutes = ['/'];

const kMobileReadyPageRoutes: string[] = [];

const responsiveMinWidth = 'min-w-[768px]'; // tailwind 'md' breakpoint
const defaultMinWidth = 'min-w-[1300px]';

function calcMinWidthClass(pathname: string): string {
  if (kMobileReadyPageRoutes.includes(pathname)) return '';
  if (kResponsiveReadyPageRoutes.includes(pathname)) return responsiveMinWidth;

  return defaultMinWidth;
}

type PageContainerProps = PropsWithChildren<{}>;

const PageContainer = ({ children }: PageContainerProps) => {
  const { pathname } = useSnorkelRouter();

  const minWidthClass = calcMinWidthClass(pathname);

  return (
    <div className={`${minWidthClass} flex h-screen w-full flex-1`}>
      <div className="h-full w-full flex-1">{children}</div>
    </div>
  );
};

export default PageContainer;
