type FnProducer<TResult> = (...args: any[]) => TResult;

export const memoizeOne = <TResult>(
  fn: FnProducer<TResult>,
): FnProducer<TResult> => {
  let cachedArgs: any[] | null = null;
  let cachedResult: TResult;

  const memoizedFn = (...args: any[]): TResult => {
    if (
      !cachedArgs ||
      !args.every((arg, index) => arg === cachedArgs![index])
    ) {
      cachedArgs = args;
      cachedResult = fn(...args);
    }

    return cachedResult;
  };

  return memoizedFn;
};

export default null;
