export const APPLICATIONS = 'Applications';
export const APP_PRESET = 'Preset';
export const APP_SETUP = 'Application setup';
export const APP_TEMPLATE = 'Template';
export const OVERVIEW = 'Overview';
export const DEVELOP = 'Develop (Studio)';
export const ANNOTATE = 'Annotate';
export const BATCH = 'Batch';
export const BATCHES = 'Batches';
export const EVALUATE = 'Evaluate';
export const EVALUATE_ALL_REPORTS = 'All Reports';
export const PIPELINE = 'Pipeline (DAG)';
export const JOBS = 'Jobs';
export const DATASETS = 'Datasets';
export const FILES = 'Files';
export const SETTINGS = 'Settings';
export const LF_PACKAGES = 'LF Packages';
export const DEPLOYMENTS = 'Deployments';

export const PAGE_TITLES: Record<string, string> = {
  APPLICATIONS: 'Applications',
};
