import { UNKNOWN_ERROR_MESSAGE } from '@core/constants';
import type { UseRequestOptions } from '@hooks/useRequest/types';
import type { RequestError } from '@utils/api';
import getErrorMessage from '@utils/getErrorMessage';

import getNotificationMetaData from './getNotificationMetaData';

const mapErrorMessageFromStatusCode = {
  500: 'An internal server error has occurred',
  502: 'Service unavailable',
  503: 'Bad gateway',
  504: 'Gateway timeout',
  542: 'Life, the universe, and everything',
};

const get500ErrorMessage = (statusCode: number, details?: string): string => {
  return typeof details === 'string' && details.length > 0
    ? details
    : (mapErrorMessageFromStatusCode[statusCode] ?? UNKNOWN_ERROR_MESSAGE);
};

const makeMakeOnRequestFailed = (
  displayErrorMessage: (message: string, metaData, origin: string) => void,
) => {
  const onRequestFailed = (options: UseRequestOptions) => (e: RequestError) => {
    if (options.ignoreAbort && options.signal?.aborted) {
      return options?.fallback;
    }

    if (options?.surfaceError) throw e;

    const metaData = getNotificationMetaData(e, options);

    if (e.status >= 500) {
      if (!options?.hide500Error) {
        const message = getErrorMessage(e);
        displayErrorMessage(
          get500ErrorMessage(e.status, message),
          metaData,
          'onRequestFailed',
        );
      }

      return options?.fallback;
    }

    if (e.message === 'invalid-token') {
      displayErrorMessage(
        'Authentication failed. Please re-login or create a new user.',
        metaData,
        origin,
      );
    }

    if (e.status === 401 || options?.getSnackbarDataOnError === null) {
      return options?.fallback;
    }

    if (e.type === 'api-error') {
      if (options?.getSnackbarDataOnError) {
        const { message } = options.getSnackbarDataOnError(e);
        if (message) displayErrorMessage(message, metaData, 'onRequestFailed');
      } else {
        displayErrorMessage(getErrorMessage(e), metaData, 'onRequestFailed');
      }
    } else if (options?.getSnackbarDataOnError && e) {
      const { message } = options.getSnackbarDataOnError(e);
      displayErrorMessage(message, metaData, 'onRequestFailed');
    }

    return options?.fallback;
  };

  return onRequestFailed;
};

export default makeMakeOnRequestFailed;
