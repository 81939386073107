import React from 'react';

import DefaultAccordion from './__internal__/DefaultAccordion';
import ExpandableAccordion from './__internal__/ExpandableAccordion';
import OnboardingAccordion from './__internal__/OnboardingAccordion';
import OnboardingInnerAccordion from './__internal__/OnboardingInnerAccordion';
import { AccordionTheme } from './types';
import type { AccordionProps } from './types';

const mapComponentFromTheme = {
  [AccordionTheme.Expandable]: ExpandableAccordion,
  [AccordionTheme.Onboarding]: OnboardingAccordion,
  [AccordionTheme.OnboardingInner]: OnboardingInnerAccordion,
  [AccordionTheme.Default]: DefaultAccordion,
};

const Accordion = ({
  theme = AccordionTheme.Default,
  ...props
}: AccordionProps) => {
  const AccordionComponent = mapComponentFromTheme[theme] ?? DefaultAccordion;

  return <AccordionComponent {...(props as any)} />;
};

export type { AccordionProps };
export { AccordionTheme };

export default Accordion;
