import SnorkelLink from '@components/SnorkelLink';
import Icon from '@coral/components/Icon';
import Spinner from '@coral/components/Spinner';
import Tooltip from '@coral/components/tooltips/TooltipV2';

import type { NavigationItem } from '../types';

type NavItemProps = NavigationItem;

const NavItem = ({
  href,
  icon,
  title,
  isVisible,
  isDisabled = false,
  isSelected = false,
  isLoading = false,
  subItems = [],
  shouldPrefetch = false,
}: NavItemProps) => {
  const linkClasses = `flex select-none items-center gap-4 rounded-md px-3 py-1.5 ${
    isVisible ? '' : 'hidden'
  } ${
    isDisabled
      ? 'cursor-not-allowed text-gray-500 focus:outline-none'
      : 'hover:bg-gray-100'
  } ${isSelected ? 'bg-gray-100' : ''}`;

  return (
    <li>
      <SnorkelLink
        href={href}
        passHref
        prefetch={shouldPrefetch}
        className={linkClasses}
        onClick={e => {
          // <a> tag doesn't have a disabled attribute
          // so we need to use preventDefault as a workaround
          // we could also probably get away with pointer-events-none
          // but that does not allow adding custom cursors so went with this
          // approach to have a better user experience for disabled state
          if (isDisabled) e.preventDefault();
        }}
        // we don't want the link to be reachable via keyboard when its disabled
        tabIndex={isDisabled ? -1 : 0}
        aria-disabled={isDisabled}
        data-cy={`nav-sidebar-link-${title.toLowerCase()}`}
      >
        <Icon name={icon} />
        <span>{title}</span>
        {isLoading ? <Spinner /> : null}
      </SnorkelLink>
      {subItems.length > 0 ? (
        <ul className="pl-4">
          {subItems.map(item => (
            <NavItem key={item.id} {...item} />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

type CurrentAppNavProps = {
  heading?: string;
  navItems: NavigationItem[];
};

const CurrentAppNav = ({ heading, navItems }: CurrentAppNavProps) => {
  return (
    <section className="flex flex-col gap-2">
      {heading ? (
        <Tooltip title={heading}>
          <span
            className="overflow-hidden text-ellipsis text-nowrap px-3 text-gray-500"
            data-cy={`nav-sidebar-app-${heading}`}
          >
            {heading}
          </span>
        </Tooltip>
      ) : null}
      <ul className="space-y-2">
        {navItems.map(item => (
          <NavItem key={item.id} {...item} />
        ))}
      </ul>
    </section>
  );
};

export default CurrentAppNav;
