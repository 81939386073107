import type { Selector } from '@global/state/types';
import pipe from '@utils/pipe';

import type { AtomsState } from './types';

import { selectStudioPage } from '../selectStudioPage';
import type { StudioPageState } from '../types';

export const selectAll: Selector<AtomsState> = pipe(
  selectStudioPage,
  ({ atoms }: StudioPageState) => atoms,
);

export const selectPromptModelName: Selector<AtomsState['promptModelName']> =
  pipe(selectAll, ({ promptModelName }: AtomsState) => promptModelName);

export const selectPromptState: Selector<AtomsState['promptState']> = pipe(
  selectAll,
  ({ promptState }: AtomsState) => promptState,
);

export const selectPromptTemplateDropdownLabel: Selector<
  AtomsState['promptTemplateDropdownLabel']
> = pipe(
  selectAll,
  ({ promptTemplateDropdownLabel }: AtomsState) => promptTemplateDropdownLabel,
);

export default null;
