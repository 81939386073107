import isNil from 'lodash/isNil';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageRegionAlerts } from '@components/PageRegion';
import { isAfter } from '@coral/utils/date';
import * as actions from '@global/state/actions';
import * as selectors from '@global/state/selectors';
import getNotificationMetaData from '@hooks/useMakeOnRequestFailed/utils/getNotificationMetaData';
import useRequest from '@hooks/useRequestWithLogging';
import AccessToken from '@utils/AccessToken';
import { usersApi } from '@utils/api/serverRequests';
import getErrorMessage from '@utils/getErrorMessage';
import isExternalJwtLoginEnabled from '@utils/isJwtLoginEnabled';

import useUserLogout from './useUserLogout';

const THIRTY_SECONDS = 1000 * 30;

type AuthCredentials = Readonly<{
  accessToken: string;
  expiry: number;
}>;

const useConfirmCurrentUserAccess = (authCredentials?: AuthCredentials) => {
  const { accessToken } = authCredentials ?? {};
  const logout = useUserLogout();
  const dispatch = useDispatch();
  const currentWorkspace = useSelector(
    selectors.workspaces.selectSelectedWorkspace,
  );
  const user = useSelector(selectors.auth.selectUser);
  const request = useRequest();
  const { showErrorAlert } = usePageRegionAlerts();

  const refTimeout = useRef<null | ReturnType<typeof setTimeout>>();

  const handleCleanup = () => {
    if (refTimeout.current) {
      clearInterval(refTimeout.current);
      refTimeout.current = null;
    }
  };

  const getUserCheckExpired = async () => {
    try {
      const nextUser = await request(
        usersApi.getCurrentUserCurrentUserGet,
        {
          workspaceUid: currentWorkspace?.workspace_uid,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } },
      );

      if (nextUser) {
        dispatch(
          actions.auth.setUser(
            isNil(user) ? nextUser : { ...user, ...nextUser },
          ),
        );
      } else {
        logout();
      }
    } catch (e: any) {
      showErrorAlert({
        message: getErrorMessage(e),
        metaData: getNotificationMetaData(e, {}),
        origin: 'useConfirmCurrentUserAccess',
      });
      logout();
    }
  };

  const checkIfExpired = () => {
    // If external JWT login is enabled, then skip expiry check. This is because we
    // sometimes do not have the JWT saved in the context, meaning this check will fail
    if (isExternalJwtLoginEnabled()) return;

    const { expiry = 0 } = AccessToken.getInstance().getAccessToken() ?? {};

    if (accessToken && isAfter(new Date(), expiry)) {
      logout();
    }
  };

  useEffect(() => {
    handleCleanup();

    if (accessToken) {
      getUserCheckExpired();

      checkIfExpired();
      refTimeout.current = setInterval(checkIfExpired, THIRTY_SECONDS);
    }

    return handleCleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);
};

export default useConfirmCurrentUserAccess;
