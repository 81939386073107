// The palette is the underlying collection of common english names for simple
// color values that exist in the real world. These are not meant to be semantic
// in relation to their purpose, only in relation to what they look like.
//
// https://www.figma.com/design/3dFliOFNrWzkQhGnahbN5S/Elements?node-id=130-1&t=8AdSaoIyCjiSpr5D-1
const palette = {
  white: '#FFF',
  black: '#000',
  gray: {
    100: '#F4F4F4',
    200: '#E0E0E0',
    300: '#C6C6C6',
    400: '#A8A8A8',
    500: '#8D8D8D',
    600: '#6F6F6F',
    700: '#525252',
    800: '#393939',
    900: '#262626',
    1000: '#161616',
  },
  coolGray: {
    100: '#F2F4F8',
    200: '#DDE1E6',
    300: '#C1C7CD',
    400: '#A2A9B0',
    500: '#878D96',
    600: '#697077',
    700: '#343A3F',
    800: '#21272A',
    900: '#121619',
  },
  green: {
    100: '#DEFBE6',
    200: '#A7F0BA',
    300: '#6FDC8C',
    400: '#42BE65',
    500: '#24A148',
    600: '#198038',
    700: '#0E6027',
    800: '#044317',
    900: '#022D0D',
    1000: '#071908',
  },
  lightGreen: {
    100: '#F5F8EF',
    200: '#E0EBCE',
    300: '#CCDEAE',
    400: '#B8D18D',
    500: '#A3C46C',
    600: '#99BD5C',
    700: '#7A974A',
    800: '#5C7137',
    900: '#3D4C25',
    1000: '#1F2612',
  },
  teal: {
    100: '#D9FBFB',
    200: '#9EF0F0',
    300: '#3DDBD9',
    400: '#08BDBA',
    500: '#009D9A',
    600: '#007D79',
    700: '#005D5D',
    800: '#004144',
    900: '#022B30',
    1000: '#081A1C',
  },
  cyan: {
    100: '#E5F6FF',
    200: '#BAE6FF',
    300: '#82CFFF',
    400: '#33B1FF',
    500: '#1192E8',
    600: '#0072C3',
    700: '#00539A',
    800: '#003A6D',
    900: '#012749',
    1000: '#061727',
  },
  blue: {
    100: '#EDF5FF',
    200: '#D0E2FF',
    300: '#A6C8FF',
    400: '#78A9FF',
    500: '#4589FF',
    600: '#0F62FE',
    700: '#0043CE',
    800: '#002D9C',
    900: '#001D6C',
    1000: '#001141',
  },
  indigo: {
    100: '#ECEBF4',
    200: '#C7C2DD',
    300: '#A199C6',
    400: '#7B70AF',
    500: '#564798',
    600: '#43328D',
    700: '#362871',
    800: '#281E55',
    900: '#1B1438',
    1000: '#0D0A1C',
  },
  magenta: {
    100: '#FFF0F7',
    200: '#FFD6E8',
    300: '#FFAFD2',
    400: '#FF7EB6',
    500: '#EE5396',
    600: '#D12771',
    700: '#9F1853',
    800: '#740937',
    900: '#510224',
    1000: '#2A0A18',
  },
  red: {
    100: '#FFF1F1',
    200: '#FFD7D9',
    300: '#FFB3B8',
    400: '#FF8389',
    500: '#FA4D56',
    600: '#DA1E28',
    700: '#A2191F',
    800: '#750E13',
    900: '#520408',
    1000: '#2D0709',
  },
  orange: {
    100: '#FFF2E8',
    200: '#FFD9BE',
    300: '#FFB784',
    400: '#FF832B',
    500: '#EB6200',
    600: '#BA4E00',
    700: '#8A3800',
    800: '#5E2900',
    900: '#3E1A00',
    1000: '#231000',
  },
  purple: {
    100: '#F6F2FF',
    200: '#E8DAFF',
    300: '#D4BBFF',
    400: '#BE95FF',
    500: '#A56EFF',
    600: '#8A3FFC',
    700: '#6929C4',
    800: '#491D8B',
    900: '#31135E',
    1000: '#1C0F30',
  },
  chartreuse: {
    100: '#F9F8EC',
    200: '#EDEAC7',
    300: '#E1DDA2',
    400: '#D5CF7D',
    500: '#C9C158',
    600: '#C3BA45',
    700: '#9C9537',
    800: '#757029',
    900: '#4E4A1C',
    1000: '#27250E',
  },
  yellow: {
    100: '#FCF4D6',
    200: '#FDDC69',
    300: '#F1C21B',
    400: '#DDAA11',
    500: '#B28600',
    600: '#8E6A00',
    700: '#684E00',
    800: '#483700',
    900: '#302400',
    1000: '#1C1500',
  },
  blueGrey: {
    100: '#CFD8DC',
    200: '#B0BEC5',
    300: '#90A4AE',
    400: '#78909C',
    500: '#607D8B',
    600: '#546E7A',
    700: '#455A64',
  },
  brown: {
    100: '#D7CCC8',
    200: '#BCAAA4',
    300: '#A1887F',
    400: '#8D6E63',
    500: '#795548',
    600: '#6D4C41',
    700: '#5D4037',
  },
  navy: {
    100: '#E8EAF5',
    200: '#D2D6F2',
    300: '#A1A8D5',
    400: '#7B86C2',
    500: '#5E6BB2',
    600: '#4555A5',
    700: '#323F8E',
    800: '#242B86',
    900: '#14116C',
    1000: '#110842',
  },
};

export default palette;
