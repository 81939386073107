import { isEqual } from 'lodash';

import type { Flags } from '@utils/getFlag';
import { createReducerSlice } from '@utils/redux/reducer';

import type { FlagsMap, FlagsState } from './types';

export const initialState: FlagsState = {};

const reducerSlice = createReducerSlice('flags', initialState, {
  setFlags(state: FlagsState, flags: FlagsMap): FlagsState {
    const newFlags = { ...state, ...flags };
    if (isEqual(state, newFlags)) return state;

    return {
      ...state,
      ...flags,
    };
  },
  setFlag(
    state: FlagsState,
    { flag, value }: { flag: Flags; value: boolean },
  ): FlagsState {
    return value === state[flag] ? state : { ...state, [flag]: value };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;
