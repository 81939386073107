import { isEmpty } from 'lodash';

import { AnnotationRoutes, DevelopmentRoutes } from '@core/constants';
import getNextPageBasePath from '@utils/getNextPageBasePath';

import { push } from './api';

export enum GoToPagePages {
  LF_PACKAGES = 'lf-packages',
  ARCHIVED_LFS = 'archived-lfs',
  TRAINING_SETS = 'training-sets',
  ANALYSIS_OVERVIEW = 'analysis',
  TRAINED_MODELS = 'trained-models',
  GROUND_TRUTH_VERSIONS = 'ground-truth-versions',
}

export const Pages = {
  ...DevelopmentRoutes,
  ...AnnotationRoutes,
  ...GoToPagePages,
};

export const PageMap = {
  [Pages.STUDIO]: '/develop',
  [Pages.LF_PACKAGES]: '/overview/lf-packages',
  [Pages.MODELS]: '/train',
  [Pages.ANALYSIS]: '/analyze',
  [Pages.DATASOURCES]: '/overview/datasources',
  [Pages.GROUND_TRUTH_VERSIONS]: '/overview/ground-truth-versions',
  [Pages.BATCH]: '/batch',
  [Pages.TRAIN]: '/train',
  [Pages.MANAGER]: '/manager',
  [Pages.ANALYZE]: '/analyze',
  [Pages.ARCHIVED_LFS]: '/overview/archived-lfs',
  [Pages.TRAINING_SETS]: '/overview/training-sets',
  [Pages.DEVELOP]: '/develop',
  [Pages.ANNOTATION]: '/annotation',
  [Pages.TRAINED_MODELS]: '/overview/trained-models',
  // @ts-ignore
  [Pages.ANALYSIS_OVERVIEW]: '/overview/analysis',
};

export type PageValues = DevelopmentRoutes | AnnotationRoutes | GoToPagePages;

export const getPageUrl = (page: PageValues, query: string = '') => {
  const basePath = getNextPageBasePath();

  if (!basePath) {
    return '';
  }

  const path = PageMap[page];

  if (!path) {
    return '';
  }

  return `${basePath}${path}${isEmpty(query) ? '' : `?${query}`}`;
};

const goToPage = (page: PageValues, query: string = '') => {
  const url = getPageUrl(page, query);

  if (isEmpty(url)) {
    return;
  }

  push(url, false);
};

export default goToPage;
