/* tslint:disable */
/* eslint-disable */
/**
 * Snorkel TDM
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from './base';

/**
 * Used for the creation, validation, and deletion of user API keys  Implementation Note - API Keys do not have quite the same level of security as user passwords (which use rotating salts and updated hashes) because API keys are long random strings to begin with. There is no such thing as a lookup table attack against API keys. We simply hash them using the safe sha256 and only store the hash, which would prevent a leaked table from granting unauth\'d access (since the attacker would only have the hashes).
 * @export
 * @interface APIKey
 */
export interface APIKey {
  /**
   *
   * @type {string}
   * @memberof APIKey
   */
  api_key_peek?: string;
  /**
   *
   * @type {string}
   * @memberof APIKey
   */
  api_key_plaintext?: string;
  /**
   *
   * @type {number}
   * @memberof APIKey
   */
  api_key_uid?: number;
  /**
   *
   * @type {string}
   * @memberof APIKey
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof APIKey
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof APIKey
   */
  user_uid: number;
}
/**
 * Structure of the API key that is returned when listing API keys for an existing user, note that this does not include the plaintext as by this time the plaintext has been thrown away
 * @export
 * @interface APIKeyLimited
 */
export interface APIKeyLimited {
  /**
   *
   * @type {string}
   * @memberof APIKeyLimited
   */
  api_key_peek: string;
  /**
   *
   * @type {number}
   * @memberof APIKeyLimited
   */
  api_key_uid: number;
  /**
   *
   * @type {string}
   * @memberof APIKeyLimited
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof APIKeyLimited
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof APIKeyLimited
   */
  user_uid: number;
}
/**
 *
 * @export
 * @interface AUConstants
 */
export interface AUConstants {
  /**
   *
   * @type {number}
   * @memberof AUConstants
   */
  archive_cost_multipler?: number;
  /**
   *
   * @type {{ [key: string]: { [key: string]: { [key: string]: number; }; }; }}
   * @memberof AUConstants
   */
  template_cost_map?: {
    [key: string]: { [key: string]: { [key: string]: number } };
  };
}
/**
 *
 * @export
 * @interface AULimits
 */
export interface AULimits {
  /**
   *
   * @type {number}
   * @memberof AULimits
   */
  lab_limit?: number;
  /**
   *
   * @type {number}
   * @memberof AULimits
   */
  standard_limit?: number;
}
/**
 *
 * @export
 * @interface AUUsage
 */
export interface AUUsage {
  /**
   *
   * @type {number}
   * @memberof AUUsage
   */
  lab_usage?: number;
  /**
   *
   * @type {number}
   * @memberof AUUsage
   */
  standard_usage?: number;
}
/**
 *
 * @export
 * @interface AccessConfig
 */
export interface AccessConfig {
  /**
   *
   * @type {number}
   * @memberof AccessConfig
   */
  created_by_user_uid?: number;
  /**
   *
   * @type {boolean}
   * @memberof AccessConfig
   */
  is_public?: boolean;
}
/**
 *
 * @export
 * @interface AccountInfo
 */
export interface AccountInfo {
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  account_id: string;
  /**
   *
   * @type {number}
   * @memberof AccountInfo
   */
  application_limit?: number;
  /**
   *
   * @type {AUConstants}
   * @memberof AccountInfo
   */
  au_constants?: AUConstants;
  /**
   *
   * @type {AULimits}
   * @memberof AccountInfo
   */
  au_limits?: AULimits;
  /**
   *
   * @type {number}
   * @memberof AccountInfo
   */
  dataset_limit: number;
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  expiration_date: string;
  /**
   *
   * @type {number}
   * @memberof AccountInfo
   */
  seat_limit: number;
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  start_date?: string;
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  system_validation_key?: string;
  /**
   *
   * @type {TrialType}
   * @memberof AccountInfo
   */
  trial?: TrialType;
  /**
   *
   * @type {boolean}
   * @memberof AccountInfo
   */
  validate_system?: boolean;
}

/**
 *
 * @export
 * @interface AddExternalLLMConfigPayload
 */
export interface AddExternalLLMConfigPayload {
  /**
   *
   * @type {object}
   * @memberof AddExternalLLMConfigPayload
   */
  config: object;
  /**
   *
   * @type {string}
   * @memberof AddExternalLLMConfigPayload
   */
  model_name: string;
  /**
   *
   * @type {ExternalLLMProvider}
   * @memberof AddExternalLLMConfigPayload
   */
  model_provider: ExternalLLMProvider;
  /**
   *
   * @type {number}
   * @memberof AddExternalLLMConfigPayload
   */
  workspace_uid: number;
}

/**
 *
 * @export
 * @interface AddMetricRequest
 */
export interface AddMetricRequest {
  /**
   *
   * @type {string}
   * @memberof AddMetricRequest
   */
  metric_name: string;
  /**
   * Overwrite a custom metric of the same name if it exists
   * @type {boolean}
   * @memberof AddMetricRequest
   */
  overwrite?: boolean;
  /**
   *
   * @type {string}
   * @memberof AddMetricRequest
   */
  raw_code: string;
  /**
   *
   * @type {string}
   * @memberof AddMetricRequest
   */
  serialized_custom_metric_func: string;
}
/**
 *
 * @export
 * @interface AddMetricResponse
 */
export interface AddMetricResponse {
  /**
   *
   * @type {number}
   * @memberof AddMetricResponse
   */
  custom_metric_uid: number;
}
/**
 *
 * @export
 * @interface AddSourceParams
 */
export interface AddSourceParams {
  /**
   *
   * @type {object}
   * @memberof AddSourceParams
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof AddSourceParams
   */
  source_name?: string;
  /**
   *
   * @type {string}
   * @memberof AddSourceParams
   */
  source_type?: string;
  /**
   *
   * @type {number}
   * @memberof AddSourceParams
   */
  user_uid?: number;
  /**
   *
   * @type {number}
   * @memberof AddSourceParams
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface AddSourceResponse
 */
export interface AddSourceResponse {
  /**
   *
   * @type {Source}
   * @memberof AddSourceResponse
   */
  created_source: Source;
}
/**
 *
 * @export
 * @interface AddTagType
 */
export interface AddTagType {
  /**
   *
   * @type {string}
   * @memberof AddTagType
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof AddTagType
   */
  is_context_tag_type?: boolean;
  /**
   *
   * @type {string}
   * @memberof AddTagType
   */
  name: string;
}
/**
 *
 * @export
 * @interface AdmissionRoles
 */
export interface AdmissionRoles {
  /**
   *
   * @type {Array<string>}
   * @memberof AdmissionRoles
   */
  admission_roles: Array<string>;
  /**
   *
   * @type {string}
   * @memberof AdmissionRoles
   */
  admission_roles_claim?: string;
  /**
   *
   * @type {string}
   * @memberof AdmissionRoles
   */
  admission_roles_scope?: string;
}
/**
 *
 * @export
 * @interface AggregateAnnotationsParams
 */
export interface AggregateAnnotationsParams {
  /**
   *
   * @type {number}
   * @memberof AggregateAnnotationsParams
   */
  batch_uid: number;
  /**
   *
   * @type {Array<number>}
   * @memberof AggregateAnnotationsParams
   */
  source_uids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof AggregateAnnotationsParams
   */
  strategy?: string;
}
/**
 *
 * @export
 * @interface AggregateDatasetAnnotationsParams
 */
export interface AggregateDatasetAnnotationsParams {
  /**
   *
   * @type {number}
   * @memberof AggregateDatasetAnnotationsParams
   */
  dataset_batch_uid: number;
  /**
   *
   * @type {number}
   * @memberof AggregateDatasetAnnotationsParams
   */
  dataset_uid: number;
  /**
   *
   * @type {Array<number>}
   * @memberof AggregateDatasetAnnotationsParams
   */
  label_schema_uids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof AggregateDatasetAnnotationsParams
   */
  source_name?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof AggregateDatasetAnnotationsParams
   */
  source_uids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof AggregateDatasetAnnotationsParams
   */
  strategy?: string;
}
/**
 *
 * @export
 * @interface AlignGTsReq
 */
export interface AlignGTsReq {
  /**
   *
   * @type {Array<any>}
   * @memberof AlignGTsReq
   */
  labels: Array<any>;
  /**
   *
   * @type {string}
   * @memberof AlignGTsReq
   */
  scheduler?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AlignGTsReq
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface AnalyzeDataSourcesRequest
 */
export interface AnalyzeDataSourcesRequest {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof AnalyzeDataSourcesRequest
   */
  col_fill_values?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof AnalyzeDataSourcesRequest
   */
  credential_kwargs?: string;
  /**
   *
   * @type {number}
   * @memberof AnalyzeDataSourcesRequest
   */
  data_connector_config_uid?: number;
  /**
   *
   * @type {string}
   * @memberof AnalyzeDataSourcesRequest
   */
  datestamp?: string;
  /**
   *
   * @type {boolean}
   * @memberof AnalyzeDataSourcesRequest
   */
  generate_uid_col?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalyzeDataSourcesRequest
   */
  load_to_model_nodes?: boolean;
  /**
   *
   * @type {number}
   * @memberof AnalyzeDataSourcesRequest
   */
  parallel_core_fraction?: number;
  /**
   *
   * @type {Array<PathWithSplit>}
   * @memberof AnalyzeDataSourcesRequest
   */
  paths_with_split?: Array<PathWithSplit>;
  /**
   *
   * @type {string}
   * @memberof AnalyzeDataSourcesRequest
   */
  reader_kwargs?: string;
  /**
   *
   * @type {boolean}
   * @memberof AnalyzeDataSourcesRequest
   */
  run_async?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnalyzeDataSourcesRequest
   */
  run_datasource_checks?: boolean;
  /**
   *
   * @type {string}
   * @memberof AnalyzeDataSourcesRequest
   */
  scheduler?: string;
  /**
   *
   * @type {boolean}
   * @memberof AnalyzeDataSourcesRequest
   */
  skip_repartition?: boolean;
  /**
   *
   * @type {string}
   * @memberof AnalyzeDataSourcesRequest
   */
  snorkel_generated_uid_col?: string;
  /**
   *
   * @type {string}
   * @memberof AnalyzeDataSourcesRequest
   */
  source_type: string;
  /**
   *
   * @type {number}
   * @memberof AnalyzeDataSourcesRequest
   */
  source_uid?: number;
  /**
   *
   * @type {StaticAssetColInfo}
   * @memberof AnalyzeDataSourcesRequest
   */
  static_asset_col_info?: StaticAssetColInfo;
  /**
   *
   * @type {string}
   * @memberof AnalyzeDataSourcesRequest
   */
  uid_col?: string;
}
/**
 *
 * @export
 * @interface Annotation
 */
export interface Annotation {
  /**
   *
   * @type {number}
   * @memberof Annotation
   */
  annotation_uid: number;
  /**
   *
   * @type {any}
   * @memberof Annotation
   */
  label?: any;
  /**
   *
   * @type {object}
   * @memberof Annotation
   */
  metadata?: object;
  /**
   *
   * @type {number}
   * @memberof Annotation
   */
  node_uid: number;
  /**
   *
   * @type {number}
   * @memberof Annotation
   */
  source_uid: number;
  /**
   *
   * @type {string}
   * @memberof Annotation
   */
  split?: string;
  /**
   *
   * @type {string}
   * @memberof Annotation
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof Annotation
   */
  ts: string;
  /**
   *
   * @type {string}
   * @memberof Annotation
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface AnnotationBatchMetadata
 */
export interface AnnotationBatchMetadata {
  /**
   *
   * @type {number}
   * @memberof AnnotationBatchMetadata
   */
  annotation_uid: number;
  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationBatchMetadata
   */
  batch_uids: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof AnnotationBatchMetadata
   */
  names: Array<string>;
}
/**
 * A wrapper around data returned to the FE to render a Annotation Filter options.
 * @export
 * @interface AnnotationFilterStructureModel
 */
export interface AnnotationFilterStructureModel {
  /**
   *
   * @type {Array<OptionModel>}
   * @memberof AnnotationFilterStructureModel
   */
  annotators: Array<OptionModel>;
  /**
   *
   * @type {string}
   * @memberof AnnotationFilterStructureModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AnnotationFilterStructureModel
   */
  filter_type: string;
  /**
   *
   * @type {Array<LabelSchemaFilterModel>}
   * @memberof AnnotationFilterStructureModel
   */
  label_schemas: Array<LabelSchemaFilterModel>;
  /**
   *
   * @type {string}
   * @memberof AnnotationFilterStructureModel
   */
  name: string;
}
/**
 *
 * @export
 * @interface AnnotationRate
 */
export interface AnnotationRate {
  /**
   *
   * @type {Array<AnnotationRateData>}
   * @memberof AnnotationRate
   */
  data: Array<AnnotationRateData>;
  /**
   *
   * @type {string}
   * @memberof AnnotationRate
   */
  end_time: string;
  /**
   *
   * @type {string}
   * @memberof AnnotationRate
   */
  start_time: string;
}
/**
 *
 * @export
 * @interface AnnotationRateData
 */
export interface AnnotationRateData {
  /**
   *
   * @type {number}
   * @memberof AnnotationRateData
   */
  annotation_rate: number;
  /**
   *
   * @type {string}
   * @memberof AnnotationRateData
   */
  timestamp: string;
}
/**
 * A wrapper around data returned to the FE to render a Annotator Agreement Filter options.
 * @export
 * @interface AnnotatorAgreementFilterStructureModel
 */
export interface AnnotatorAgreementFilterStructureModel {
  /**
   *
   * @type {Array<OptionModel>}
   * @memberof AnnotatorAgreementFilterStructureModel
   */
  annotators: Array<OptionModel>;
  /**
   *
   * @type {string}
   * @memberof AnnotatorAgreementFilterStructureModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AnnotatorAgreementFilterStructureModel
   */
  filter_type: string;
  /**
   *
   * @type {Array<LabelSchemaFilterModel>}
   * @memberof AnnotatorAgreementFilterStructureModel
   */
  label_schemas: Array<LabelSchemaFilterModel>;
  /**
   *
   * @type {string}
   * @memberof AnnotatorAgreementFilterStructureModel
   */
  name: string;
}
/**
 *
 * @export
 * @interface AnnotatorNameID
 */
export interface AnnotatorNameID {
  /**
   *
   * @type {number}
   * @memberof AnnotatorNameID
   */
  user_uid: number;
  /**
   *
   * @type {string}
   * @memberof AnnotatorNameID
   */
  username: string;
}
/**
 *
 * @export
 * @interface AnnotatorOverviewStats
 */
export interface AnnotatorOverviewStats {
  /**
   *
   * @type {number}
   * @memberof AnnotatorOverviewStats
   */
  annotation_count: number;
  /**
   *
   * @type {number}
   * @memberof AnnotatorOverviewStats
   */
  annotation_target: number;
  /**
   *
   * @type {number}
   * @memberof AnnotatorOverviewStats
   */
  daily_rate: number;
  /**
   *
   * @type {number}
   * @memberof AnnotatorOverviewStats
   */
  user_uid?: number;
  /**
   *
   * @type {string}
   * @memberof AnnotatorOverviewStats
   */
  username: string;
}
/**
 *
 * @export
 * @interface AnnotatorStatistics
 */
export interface AnnotatorStatistics {
  /**
   *
   * @type {number}
   * @memberof AnnotatorStatistics
   */
  annotation_count: number;
  /**
   *
   * @type {number}
   * @memberof AnnotatorStatistics
   */
  annotation_target: number;
  /**
   *
   * @type {number}
   * @memberof AnnotatorStatistics
   */
  user_uid: number;
}
/**
 *
 * @export
 * @interface AppAUUsageResponse
 */
export interface AppAUUsageResponse {
  /**
   *
   * @type {ApplicationAUUsage}
   * @memberof AppAUUsageResponse
   */
  app_au_usage: ApplicationAUUsage;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const AppLicenseType = {
  Lab: 'lab',
  Standard: 'standard',
} as const;

export type AppLicenseType =
  (typeof AppLicenseType)[keyof typeof AppLicenseType];

/**
 *
 * @export
 * @interface AppModelSetupProgress
 */
export interface AppModelSetupProgress {
  /**
   *
   * @type {DataAndTaskTypeStep}
   * @memberof AppModelSetupProgress
   */
  data_and_task_type?: DataAndTaskTypeStep;
  /**
   *
   * @type {DevelopmentSettingsStep}
   * @memberof AppModelSetupProgress
   */
  development_settings?: DevelopmentSettingsStep;
  /**
   *
   * @type {LabelSchemaStep}
   * @memberof AppModelSetupProgress
   */
  label_schema?: LabelSchemaStep;
  /**
   *
   * @type {PreprocessingStep}
   * @memberof AppModelSetupProgress
   */
  preprocessing?: PreprocessingStep;
}
/**
 *
 * @export
 * @interface AppModelSetupProgressPayload
 */
export interface AppModelSetupProgressPayload {
  /**
   *
   * @type {DataAndTaskTypeStep}
   * @memberof AppModelSetupProgressPayload
   */
  data_and_task_type?: DataAndTaskTypeStep;
  /**
   *
   * @type {DevelopmentSettingsStep}
   * @memberof AppModelSetupProgressPayload
   */
  development_settings?: DevelopmentSettingsStep;
  /**
   *
   * @type {LabelSchemaStep}
   * @memberof AppModelSetupProgressPayload
   */
  label_schema?: LabelSchemaStep;
  /**
   *
   * @type {PreprocessingStepPayload}
   * @memberof AppModelSetupProgressPayload
   */
  preprocessing?: PreprocessingStepPayload;
  /**
   *
   * @type {SetupStepName}
   * @memberof AppModelSetupProgressPayload
   */
  step_name: SetupStepName;
}

/**
 * Class for saving and loading different versions of an application
 * @export
 * @interface AppVersion
 */
export interface AppVersion {
  /**
   *
   * @type {number}
   * @memberof AppVersion
   */
  app_version_uid?: number;
  /**
   *
   * @type {number}
   * @memberof AppVersion
   */
  application_uid: number;
  /**
   *
   * @type {string}
   * @memberof AppVersion
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof AppVersion
   */
  created_by: number;
  /**
   *
   * @type {DAGVersionInfo}
   * @memberof AppVersion
   */
  dag_version_info?: DAGVersionInfo;
  /**
   *
   * @type {string}
   * @memberof AppVersion
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof AppVersion
   */
  hidden?: boolean;
  /**
   *
   * @type {string}
   * @memberof AppVersion
   */
  name: string;
}
/**
 *
 * @export
 * @interface ApplicationAUUsage
 */
export interface ApplicationAUUsage {
  /**
   *
   * @type {number}
   * @memberof ApplicationAUUsage
   */
  application_uid: number;
  /**
   *
   * @type {AUUsage}
   * @memberof ApplicationAUUsage
   */
  au_usage: AUUsage;
  /**
   *
   * @type {Array<BlockAUUsage>}
   * @memberof ApplicationAUUsage
   */
  breakdown?: Array<BlockAUUsage>;
  /**
   *
   * @type {LicenseConfig}
   * @memberof ApplicationAUUsage
   */
  license_config: LicenseConfig;
}
/**
 *
 * @export
 * @interface ApplicationConfig
 */
export interface ApplicationConfig {
  /**
   *
   * @type {number}
   * @memberof ApplicationConfig
   */
  app_version_uid?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof ApplicationConfig
   */
  datasource_uids_to_load?: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationConfig
   */
  input_schema?: Array<string>;
}
/**
 * Application count.
 * @export
 * @interface ApplicationCount
 */
export interface ApplicationCount {
  /**
   *
   * @type {number}
   * @memberof ApplicationCount
   */
  application_count: number;
}
/**
 *
 * @export
 * @interface ApplicationDeployment
 */
export interface ApplicationDeployment {
  /**
   *
   * @type {number}
   * @memberof ApplicationDeployment
   */
  application_uid?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationDeployment
   */
  created_at: string;
  /**
   *
   * @type {object}
   * @memberof ApplicationDeployment
   */
  data_schema: object;
  /**
   *
   * @type {number}
   * @memberof ApplicationDeployment
   */
  deployment_uid: number;
  /**
   *
   * @type {DeploymentMetadata}
   * @memberof ApplicationDeployment
   */
  metadata?: DeploymentMetadata;
  /**
   *
   * @type {string}
   * @memberof ApplicationDeployment
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationDeployment
   */
  package_type?: string;
  /**
   *
   * @type {DeploymentStorageInfoConfig}
   * @memberof ApplicationDeployment
   */
  storage_information_config?: DeploymentStorageInfoConfig;
  /**
   *
   * @type {number}
   * @memberof ApplicationDeployment
   */
  user_uid?: number;
  /**
   *
   * @type {{ [key: string]: OperatorConfig; }}
   * @memberof ApplicationDeployment
   */
  workflow_config?: { [key: string]: OperatorConfig };
  /**
   *
   * @type {string}
   * @memberof ApplicationDeployment
   */
  workflow_package_dir?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationDeployment
   */
  workflow_uid: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationDeployment
   */
  workspace_uid?: number;
}
/**
 * Application metadata (omits dag, which may be unecessarily large).
 * @export
 * @interface ApplicationInfo
 */
export interface ApplicationInfo {
  /**
   *
   * @type {AccessConfig}
   * @memberof ApplicationInfo
   */
  access_config: AccessConfig;
  /**
   *
   * @type {ApplicationConfig}
   * @memberof ApplicationInfo
   */
  application_config: ApplicationConfig;
  /**
   *
   * @type {number}
   * @memberof ApplicationInfo
   */
  application_uid: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationInfo
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationInfo
   */
  created_by_username?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationInfo
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationInfo
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationInfo
   */
  is_setup_complete: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplicationInfo
   */
  last_viewed_at: string;
  /**
   *
   * @type {LicenseConfig}
   * @memberof ApplicationInfo
   */
  license_config?: LicenseConfig;
  /**
   *
   * @type {string}
   * @memberof ApplicationInfo
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationInfo
   */
  node_count: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationInfo
   */
  should_use_onboarding?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApplicationInfo
   */
  workflow_count: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationInfo
   */
  workspace_uid: number;
}
/**
 * Application metadata (omits dag, which may be unecessarily large).
 * @export
 * @interface ApplicationMetadata
 */
export interface ApplicationMetadata {
  /**
   *
   * @type {AccessConfig}
   * @memberof ApplicationMetadata
   */
  access_config: AccessConfig;
  /**
   *
   * @type {ApplicationConfig}
   * @memberof ApplicationMetadata
   */
  application_config: ApplicationConfig;
  /**
   *
   * @type {number}
   * @memberof ApplicationMetadata
   */
  application_uid: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationMetadata
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationMetadata
   */
  created_by_username?: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationMetadata
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationMetadata
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMetadata
   */
  is_setup_complete: boolean;
  /**
   *
   * @type {LicenseConfig}
   * @memberof ApplicationMetadata
   */
  license_config?: LicenseConfig;
  /**
   *
   * @type {string}
   * @memberof ApplicationMetadata
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ApplicationMetadata
   */
  node_count: number;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationMetadata
   */
  should_use_onboarding?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApplicationMetadata
   */
  workflow_count: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationMetadata
   */
  workspace_uid: number;
}
/**
 *
 * @export
 * @interface ApplicationTemplate
 */
export interface ApplicationTemplate {
  /**
   *
   * @type {ModelConfig}
   * @memberof ApplicationTemplate
   */
  default_fast_model_config?: ModelConfig;
  /**
   *
   * @type {Array<any>}
   * @memberof ApplicationTemplate
   */
  default_label_model_list?: Array<any>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplate
   */
  default_lf_categories?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApplicationTemplate
   */
  default_lf_suggester?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplate
   */
  default_lf_suggester_columns_to_hide?: Array<string>;
  /**
   *
   * @type {ModelConfig}
   * @memberof ApplicationTemplate
   */
  default_model_config?: ModelConfig;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplate
   */
  default_models?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApplicationTemplate
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationTemplate
   */
  details?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplate
   */
  exclude_lfs?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationTemplate
   */
  hidden?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplate
   */
  include_lfs?: Array<string>;
  /**
   *
   * @type {Array<InputFieldSpec>}
   * @memberof ApplicationTemplate
   */
  inputs?: Array<InputFieldSpec>;
  /**
   *
   * @type {TemplateMetadata}
   * @memberof ApplicationTemplate
   */
  metadata: TemplateMetadata;
  /**
   *
   * @type {string}
   * @memberof ApplicationTemplate
   */
  name: string;
  /**
   *
   * @type {{ [key: string]: MetagraphNode; }}
   * @memberof ApplicationTemplate
   */
  op_metagraph?: { [key: string]: MetagraphNode };
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplate
   */
  recommended_lfs?: Array<string>;
  /**
   *
   * @type {Array<SetupSetting>}
   * @memberof ApplicationTemplate
   */
  setup_settings?: Array<SetupSetting>;
  /**
   *
   * @type {string}
   * @memberof ApplicationTemplate
   */
  template_id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplate
   */
  valid_lf_suggesters?: Array<string>;
}
/**
 *
 * @export
 * @interface ApplicationTemplateBase
 */
export interface ApplicationTemplateBase {
  /**
   *
   * @type {ModelConfig}
   * @memberof ApplicationTemplateBase
   */
  default_fast_model_config?: ModelConfig;
  /**
   *
   * @type {Array<any>}
   * @memberof ApplicationTemplateBase
   */
  default_label_model_list?: Array<any>;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplateBase
   */
  default_lf_categories?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ApplicationTemplateBase
   */
  default_lf_suggester?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplateBase
   */
  default_lf_suggester_columns_to_hide?: Array<string>;
  /**
   *
   * @type {ModelConfig}
   * @memberof ApplicationTemplateBase
   */
  default_model_config?: ModelConfig;
  /**
   *
   * @type {string}
   * @memberof ApplicationTemplateBase
   */
  description: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplateBase
   */
  exclude_lfs?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationTemplateBase
   */
  hidden?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplateBase
   */
  include_lfs?: Array<string>;
  /**
   *
   * @type {Array<InputFieldSpec>}
   * @memberof ApplicationTemplateBase
   */
  inputs?: Array<InputFieldSpec>;
  /**
   *
   * @type {TemplateMetadata}
   * @memberof ApplicationTemplateBase
   */
  metadata: TemplateMetadata;
  /**
   *
   * @type {string}
   * @memberof ApplicationTemplateBase
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplateBase
   */
  recommended_lfs?: Array<string>;
  /**
   *
   * @type {Array<SetupSetting>}
   * @memberof ApplicationTemplateBase
   */
  setup_settings?: Array<SetupSetting>;
  /**
   *
   * @type {string}
   * @memberof ApplicationTemplateBase
   */
  template_id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationTemplateBase
   */
  valid_lf_suggesters?: Array<string>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ApplicationType = {
  Classification: 'classification',
  NamedEntityRecognition: 'named_entity_recognition',
  SentimentAnalysis: 'sentiment_analysis',
  DocumentClassification: 'document_classification',
  InformationExtraction: 'information_extraction',
  KnowledgeGraph: 'knowledge_graph',
  AnomalyDetection: 'anomaly_detection',
  ConversationClassification: 'conversation_classification',
  EntityLinking: 'entity_linking',
  LlmFineTuning: 'llm_fine_tuning',
} as const;

export type ApplicationType =
  (typeof ApplicationType)[keyof typeof ApplicationType];

/**
 *
 * @export
 * @interface ApplyDatasetTemplatePayload
 */
export interface ApplyDatasetTemplatePayload {
  /**
   *
   * @type {object}
   * @memberof ApplyDatasetTemplatePayload
   */
  params?: object;
  /**
   *
   * @type {string}
   * @memberof ApplyDatasetTemplatePayload
   */
  template_id: string;
}
/**
 *
 * @export
 * @interface ApplyPopulatorPayload
 */
export interface ApplyPopulatorPayload {
  /**
   *
   * @type {string}
   * @memberof ApplyPopulatorPayload
   */
  application_name: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplyPopulatorPayload
   */
  create_missing_users?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplyPopulatorPayload
   */
  dataset_name: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplyPopulatorPayload
   */
  enable_cache?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplyPopulatorPayload
   */
  import_feature_store?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApplyPopulatorPayload
   */
  notebook_api_url?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplyPopulatorPayload
   */
  overwrite_application?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApplyPopulatorPayload
   */
  overwrite_dataset?: boolean;
  /**
   *
   * @type {PopulatorConfig}
   * @memberof ApplyPopulatorPayload
   */
  populator_config?: PopulatorConfig;
  /**
   *
   * @type {PopulatorMetadata}
   * @memberof ApplyPopulatorPayload
   */
  populator_metadata?: PopulatorMetadata;
  /**
   *
   * @type {string}
   * @memberof ApplyPopulatorPayload
   */
  populator_path?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplyPopulatorPayload
   */
  use_shared_external_resources?: boolean;
  /**
   *
   * @type {number}
   * @memberof ApplyPopulatorPayload
   */
  user_uid?: number;
  /**
   *
   * @type {number}
   * @memberof ApplyPopulatorPayload
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface ApplyPopulatorResponse
 */
export interface ApplyPopulatorResponse {
  /**
   *
   * @type {string}
   * @memberof ApplyPopulatorResponse
   */
  job_id: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const AssetUploadType = {
  Pdf: 'pdf',
  Image: 'image',
} as const;

export type AssetUploadType =
  (typeof AssetUploadType)[keyof typeof AssetUploadType];

/**
 *
 * @export
 * @interface AssetUri
 */
export interface AssetUri {
  /**
   *
   * @type {any}
   * @memberof AssetUri
   */
  application_name: any;
  /**
   *
   * @type {any}
   * @memberof AssetUri
   */
  application_uid: any;
  /**
   *
   * @type {any}
   * @memberof AssetUri
   */
  comment_uid: any;
  /**
   *
   * @type {any}
   * @memberof AssetUri
   */
  node_uid: any;
  /**
   *
   * @type {any}
   * @memberof AssetUri
   */
  x_uid: any;
  /**
   *
   * @type {any}
   * @memberof AssetUri
   */
  batch_uid: any;
  /**
   *
   * @type {any}
   * @memberof AssetUri
   */
  user_uid: any;
  /**
   *
   * @type {any}
   * @memberof AssetUri
   */
  lf_uid: any;
}
/**
 *
 * @export
 * @interface AssignLabelSchemasToBatchParams
 */
export interface AssignLabelSchemasToBatchParams {
  /**
   *
   * @type {number}
   * @memberof AssignLabelSchemasToBatchParams
   */
  dataset_batch_uid: number;
  /**
   *
   * @type {Array<number>}
   * @memberof AssignLabelSchemasToBatchParams
   */
  label_schema_uids: Array<number>;
}
/**
 *
 * @export
 * @interface AsyncJobResponse
 */
export interface AsyncJobResponse {
  /**
   *
   * @type {string}
   * @memberof AsyncJobResponse
   */
  job_uid: string;
}
/**
 *
 * @export
 * @interface AuditEvent
 */
export interface AuditEvent {
  /**
   *
   * @type {string}
   * @memberof AuditEvent
   */
  authentication_method?: string;
  /**
   *
   * @type {object}
   * @memberof AuditEvent
   */
  event_details?: object;
  /**
   *
   * @type {number}
   * @memberof AuditEvent
   */
  event_id: number;
  /**
   *
   * @type {string}
   * @memberof AuditEvent
   */
  event_name: string;
  /**
   *
   * @type {string}
   * @memberof AuditEvent
   */
  event_time?: string;
  /**
   *
   * @type {EventType}
   * @memberof AuditEvent
   */
  event_type: EventType;
  /**
   *
   * @type {number}
   * @memberof AuditEvent
   */
  user_uid?: number;
}

/**
 *
 * @export
 * @interface AugmentDatasetRequest
 */
export interface AugmentDatasetRequest {
  /**
   *
   * @type {object}
   * @memberof AugmentDatasetRequest
   */
  cache_info?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof AugmentDatasetRequest
   */
  fields?: Array<string>;
  /**
   *
   * @type {object}
   * @memberof AugmentDatasetRequest
   */
  fm_hyperparameters?: object;
  /**
   *
   * @type {string}
   * @memberof AugmentDatasetRequest
   */
  model_name: string;
  /**
   *
   * @type {number}
   * @memberof AugmentDatasetRequest
   */
  num_runs?: number;
  /**
   *
   * @type {string}
   * @memberof AugmentDatasetRequest
   */
  prompt: string;
  /**
   *
   * @type {boolean}
   * @memberof AugmentDatasetRequest
   */
  use_engine?: boolean;
  /**
   *
   * @type {number}
   * @memberof AugmentDatasetRequest
   */
  workspace_uid: number;
  /**
   *
   * @type {Array<string>}
   * @memberof AugmentDatasetRequest
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface AuthSettings
 */
export interface AuthSettings {
  /**
   *
   * @type {number}
   * @memberof AuthSettings
   */
  inactivity_timeout_minutes: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const AuthorizationAuthTypesResourceType = {
  Application: 'application',
  Dataset: 'dataset',
  Node: 'node',
  Batch: 'batch',
  Workspace: 'workspace',
  Op: 'op',
  Deployment: 'deployment',
  Export: 'export',
  Workflow: 'workflow',
  Datasource: 'datasource',
  ModelRegistry: 'model_registry',
  Job: 'job',
  Task: 'task',
  Source: 'source',
  Template: 'template',
  Block: 'block',
  LabelSchema: 'label_schema',
  DataConnector: 'data_connector',
  DataConnectorConfig: 'data_connector_config',
  DatasetBatch: 'dataset_batch',
  DatasetView: 'dataset_view',
} as const;

export type AuthorizationAuthTypesResourceType =
  (typeof AuthorizationAuthTypesResourceType)[keyof typeof AuthorizationAuthTypesResourceType];

/**
 *
 * @export
 * @interface AutoMLTrainParams
 */
export interface AutoMLTrainParams {
  /**
   *
   * @type {Array<string>}
   * @memberof AutoMLTrainParams
   */
  feature_fields?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof AutoMLTrainParams
   */
  load_ground_truth?: boolean;
  /**
   *
   * @type {number}
   * @memberof AutoMLTrainParams
   */
  max_runs?: number;
  /**
   *
   * @type {string}
   * @memberof AutoMLTrainParams
   */
  model_architecture: string;
  /**
   *
   * @type {number}
   * @memberof AutoMLTrainParams
   */
  random_seed?: number;
  /**
   *
   * @type {SearchStrategy}
   * @memberof AutoMLTrainParams
   */
  search_strategy?: SearchStrategy;
}

/**
 *
 * @export
 * @interface AzureMLSecretsConfig
 */
export interface AzureMLSecretsConfig {
  /**
   *
   * @type {string}
   * @memberof AzureMLSecretsConfig
   */
  'azure::ml::api_key'?: string;
  /**
   *
   * @type {string}
   * @memberof AzureMLSecretsConfig
   */
  provider: AzureMLSecretsConfigProviderEnum;
}

export const AzureMLSecretsConfigProviderEnum = {
  AzureMl: 'azure_ml',
} as const;

export type AzureMLSecretsConfigProviderEnum =
  (typeof AzureMLSecretsConfigProviderEnum)[keyof typeof AzureMLSecretsConfigProviderEnum];

/**
 *
 * @export
 * @interface AzureOpenAISecretsConfig
 */
export interface AzureOpenAISecretsConfig {
  /**
   *
   * @type {string}
   * @memberof AzureOpenAISecretsConfig
   */
  azure_openai_api_key?: string;
  /**
   *
   * @type {string}
   * @memberof AzureOpenAISecretsConfig
   */
  provider: AzureOpenAISecretsConfigProviderEnum;
}

export const AzureOpenAISecretsConfigProviderEnum = {
  AzureOpenai: 'azure_openai',
} as const;

export type AzureOpenAISecretsConfigProviderEnum =
  (typeof AzureOpenAISecretsConfigProviderEnum)[keyof typeof AzureOpenAISecretsConfigProviderEnum];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const BackendFeatureFlagNames = {
  ArrowFmCaching: 'ARROW_FM_CACHING',
  DataSourceAccessControl: 'DATA_SOURCE_ACCESS_CONTROL',
  EnableUserNodeActor: 'ENABLE_USER_NODE_ACTOR',
  LfDisagreementMetrics: 'LF_DISAGREEMENT_METRICS',
  LlmEvaluation: 'LLM_EVALUATION',
  NaturalLanguageCodeBuilder: 'NATURAL_LANGUAGE_CODE_BUILDER',
  Onboarding: 'ONBOARDING',
  RayTrain: 'RAY_TRAIN',
  StreamingPreprocessing: 'STREAMING_PREPROCESSING',
  StudioDiskCache: 'STUDIO_DISK_CACHE',
  StudioMetadataCache: 'STUDIO_METADATA_CACHE',
  SummaryMaxCardinality: 'SUMMARY_MAX_CARDINALITY',
  SummaryMaxLfs: 'SUMMARY_MAX_LFS',
  WarmStartLf: 'WARM_START_LF',
} as const;

export type BackendFeatureFlagNames =
  (typeof BackendFeatureFlagNames)[keyof typeof BackendFeatureFlagNames];

/**
 *
 * @export
 * @interface BackendFeatureFlags
 */
export interface BackendFeatureFlags {
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  ARROW_FM_CACHING?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  DATA_SOURCE_ACCESS_CONTROL?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  ENABLE_USER_NODE_ACTOR?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  LF_DISAGREEMENT_METRICS?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  LLM_EVALUATION?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  NATURAL_LANGUAGE_CODE_BUILDER?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  ONBOARDING?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  RAY_TRAIN?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  STREAMING_PREPROCESSING?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  STUDIO_DISK_CACHE?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  STUDIO_METADATA_CACHE?: boolean;
  /**
   *
   * @type {number}
   * @memberof BackendFeatureFlags
   */
  SUMMARY_MAX_CARDINALITY?: number;
  /**
   *
   * @type {number}
   * @memberof BackendFeatureFlags
   */
  SUMMARY_MAX_LFS?: number;
  /**
   *
   * @type {boolean}
   * @memberof BackendFeatureFlags
   */
  WARM_START_LF?: boolean;
}
/**
 *
 * @export
 * @interface Backup
 */
export interface Backup {
  /**
   *
   * @type {string}
   * @memberof Backup
   */
  backup_id: string;
}
/**
 *
 * @export
 * @interface Batch
 */
export interface Batch {
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  application_name?: string;
  /**
   *
   * @type {number}
   * @memberof Batch
   */
  application_uid?: number;
  /**
   *
   * @type {number}
   * @memberof Batch
   */
  batch_uid: number;
  /**
   *
   * @type {number}
   * @memberof Batch
   */
  expert_source_uid?: number;
  /**
   *
   * @type {object}
   * @memberof Batch
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Batch
   */
  node_uid: number;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  split?: string;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  ts: string;
  /**
   *
   * @type {number}
   * @memberof Batch
   */
  user_uid: number;
  /**
   *
   * @type {number}
   * @memberof Batch
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface BatchAssignmentParams
 */
export interface BatchAssignmentParams {
  /**
   *
   * @type {Array<number>}
   * @memberof BatchAssignmentParams
   */
  user_uids: Array<number>;
}
/**
 *
 * @export
 * @interface BatchDataResponse
 */
export interface BatchDataResponse {
  /**
   *
   * @type {string}
   * @memberof BatchDataResponse
   */
  context_uid_field?: string;
  /**
   *
   * @type {string}
   * @memberof BatchDataResponse
   */
  context_x_uid_field?: string;
  /**
   *
   * @type {number}
   * @memberof BatchDataResponse
   */
  count: number;
  /**
   *
   * @type {Array<object>}
   * @memberof BatchDataResponse
   */
  data: Array<object>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof BatchDataResponse
   */
  field_docstrings: { [key: string]: string };
  /**
   *
   * @type {Array<string>}
   * @memberof BatchDataResponse
   */
  field_types: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BatchDataResponse
   */
  fields: Array<string>;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof BatchDataResponse
   */
  grouped_data?: { [key: string]: any };
  /**
   *
   * @type {number}
   * @memberof BatchDataResponse
   */
  offset: number;
  /**
   *
   * @type {string}
   * @memberof BatchDataResponse
   */
  rich_doc_field: string;
  /**
   *
   * @type {number}
   * @memberof BatchDataResponse
   */
  total_count: number;
  /**
   *
   * @type {Array<string>}
   * @memberof BatchDataResponse
   */
  total_index: Array<string>;
  /**
   *
   * @type {string}
   * @memberof BatchDataResponse
   */
  uid_field: string;
}
/**
 *
 * @export
 * @interface BatchXUIDResponse
 */
export interface BatchXUIDResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof BatchXUIDResponse
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface BlockAUUsage
 */
export interface BlockAUUsage {
  /**
   *
   * @type {AUUsage}
   * @memberof BlockAUUsage
   */
  au_usage: AUUsage;
  /**
   *
   * @type {number}
   * @memberof BlockAUUsage
   */
  block_uid: number;
  /**
   *
   * @type {Array<NodeAUUsage>}
   * @memberof BlockAUUsage
   */
  breakdown?: Array<NodeAUUsage>;
  /**
   *
   * @type {string}
   * @memberof BlockAUUsage
   */
  template_id: string;
}
/**
 *
 * @export
 * @interface BlockMetadata
 */
export interface BlockMetadata {
  /**
   *
   * @type {Array<number>}
   * @memberof BlockMetadata
   */
  datasource_uids_to_load?: Array<number>;
}
/**
 *
 * @export
 * @interface BuilderSettings
 */
export interface BuilderSettings {
  /**
   *
   * @type {boolean}
   * @memberof BuilderSettings
   */
  case_sensitive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BuilderSettings
   */
  dot_matches_newline?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BuilderSettings
   */
  regex?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BuilderSettings
   */
  split_keywords?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BuilderSettings
   */
  start_end_match_line?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BuilderSettings
   */
  strict_location_match?: boolean;
  /**
   *
   * @type {string}
   * @memberof BuilderSettings
   */
  tokenize?: string;
}
/**
 *
 * @export
 * @interface CPUProfilingParams
 */
export interface CPUProfilingParams {
  /**
   *
   * @type {string}
   * @memberof CPUProfilingParams
   */
  service: string;
}
/**
 *
 * @export
 * @interface CPUProfilingStatusResponse
 */
export interface CPUProfilingStatusResponse {
  /**
   *
   * @type {string}
   * @memberof CPUProfilingStatusResponse
   */
  service: string;
  /**
   *
   * @type {string}
   * @memberof CPUProfilingStatusResponse
   */
  status: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const CRUDAction = {
  Create: 'create',
  Read: 'read',
  Update: 'update',
  Delete: 'delete',
} as const;

export type CRUDAction = (typeof CRUDAction)[keyof typeof CRUDAction];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const CandidateIEType = {
  CustomEntity: 'Custom entity',
  Date: 'Date',
  EmailAddress: 'Email address',
  Currency: 'Currency',
} as const;

export type CandidateIEType =
  (typeof CandidateIEType)[keyof typeof CandidateIEType];

/**
 *
 * @export
 * @interface ChangePassword
 */
export interface ChangePassword {
  /**
   *
   * @type {string}
   * @memberof ChangePassword
   */
  new_password: string;
  /**
   *
   * @type {string}
   * @memberof ChangePassword
   */
  old_password: string;
  /**
   *
   * @type {string}
   * @memberof ChangePassword
   */
  username: string;
}
/**
 *
 * @export
 * @interface ClassLFQualitySummaries
 */
export interface ClassLFQualitySummaries {
  /**
   *
   * @type {{ [key: string]: ClassLFQualitySummary; }}
   * @memberof ClassLFQualitySummaries
   */
  qualities: { [key: string]: ClassLFQualitySummary };
}
/**
 *
 * @export
 * @interface ClassLFQualitySummary
 */
export interface ClassLFQualitySummary {
  /**
   *
   * @type {Array<string>}
   * @memberof ClassLFQualitySummary
   */
  lf_uids: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClassLFQualitySummary
   */
  uncovered: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClassLFQualitySummary
   */
  voted_both: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClassLFQualitySummary
   */
  voted_correct: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClassLFQualitySummary
   */
  voted_incorrect: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClassLFQualitySummary
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface ColumnMetadata
 */
export interface ColumnMetadata {
  /**
   *
   * @type {number}
   * @memberof ColumnMetadata
   */
  source_node_uid: number;
}
/**
 *
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  body: string;
  /**
   *
   * @type {number}
   * @memberof Comment
   */
  comment_uid: number;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  created_at: string;
  /**
   *
   * @type {boolean}
   * @memberof Comment
   */
  is_edited?: boolean;
  /**
   *
   * @type {number}
   * @memberof Comment
   */
  user_uid: number;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  x_uid: string;
}
/**
 * A wrapper around data returned to the FE to render comment Filter options.
 * @export
 * @interface CommentFilterStructureModel
 */
export interface CommentFilterStructureModel {
  /**
   *
   * @type {string}
   * @memberof CommentFilterStructureModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof CommentFilterStructureModel
   */
  filter_type: string;
  /**
   *
   * @type {string}
   * @memberof CommentFilterStructureModel
   */
  name: string;
  /**
   *
   * @type {Array<OptionModel>}
   * @memberof CommentFilterStructureModel
   */
  users: Array<OptionModel>;
}
/**
 * We want to have some sort of backcompat on asset_uri, setting this flag allows us to remove fields and not get schema errors
 * @export
 * @interface CommentTagAssets
 */
export interface CommentTagAssets {
  /**
   *
   * @type {string}
   * @memberof CommentTagAssets
   */
  application_name: string;
  /**
   *
   * @type {number}
   * @memberof CommentTagAssets
   */
  application_uid: number;
  /**
   *
   * @type {number}
   * @memberof CommentTagAssets
   */
  comment_uid: number;
  /**
   *
   * @type {number}
   * @memberof CommentTagAssets
   */
  node_uid: number;
  /**
   *
   * @type {string}
   * @memberof CommentTagAssets
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface CommitAnnotationsParams
 */
export interface CommitAnnotationsParams {
  /**
   *
   * @type {number}
   * @memberof CommitAnnotationsParams
   */
  batch_uid?: number;
  /**
   *
   * @type {number}
   * @memberof CommitAnnotationsParams
   */
  source_uid?: number;
}
/**
 *
 * @export
 * @interface CommitDatasetAnnotationParams
 */
export interface CommitDatasetAnnotationParams {
  /**
   *
   * @type {number}
   * @memberof CommitDatasetAnnotationParams
   */
  batch_uid: number;
  /**
   *
   * @type {number}
   * @memberof CommitDatasetAnnotationParams
   */
  dataset_uid: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CommitDatasetAnnotationParams
   */
  label_schema_uids?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CommitDatasetAnnotationParams
   */
  source_uid: number;
  /**
   *
   * @type {string}
   * @memberof CommitDatasetAnnotationParams
   */
  ts?: string;
}
/**
 * We want to have some sort of backcompat on asset_uri, setting this flag allows us to remove fields and not get schema errors
 * @export
 * @interface CommitGTAssets
 */
export interface CommitGTAssets {
  /**
   *
   * @type {number}
   * @memberof CommitGTAssets
   */
  application_uid: number;
  /**
   *
   * @type {number}
   * @memberof CommitGTAssets
   */
  node_uid: number;
}
/**
 * Base model used by Operators and OpVersions to hold and validate the input payload for creating Operators and OpVersions respectively
 * @export
 * @interface CommittedOperatorConfig
 */
export interface CommittedOperatorConfig {
  /**
   *
   * @type {object}
   * @memberof CommittedOperatorConfig
   */
  op_config: object;
  /**
   *
   * @type {string}
   * @memberof CommittedOperatorConfig
   */
  op_type: string;
}
/**
 *
 * @export
 * @interface CommittedOperatorPayload
 */
export interface CommittedOperatorPayload {
  /**
   *
   * @type {CommittedOperatorConfig}
   * @memberof CommittedOperatorPayload
   */
  config?: CommittedOperatorConfig;
  /**
   *
   * @type {number}
   * @memberof CommittedOperatorPayload
   */
  operator_uid?: number;
}
/**
 * We want to have some sort of backcompat on asset_uri, setting this flag allows us to remove fields and not get schema errors
 * @export
 * @interface CompleteBatchAnnotationAssets
 */
export interface CompleteBatchAnnotationAssets {
  /**
   *
   * @type {number}
   * @memberof CompleteBatchAnnotationAssets
   */
  application_uid: number;
  /**
   *
   * @type {number}
   * @memberof CompleteBatchAnnotationAssets
   */
  batch_uid: number;
  /**
   *
   * @type {number}
   * @memberof CompleteBatchAnnotationAssets
   */
  node_uid: number;
  /**
   *
   * @type {number}
   * @memberof CompleteBatchAnnotationAssets
   */
  user_uid: number;
}
/**
 * We want to have some sort of backcompat on asset_uri, setting this flag allows us to remove fields and not get schema errors
 * @export
 * @interface CompleteLongRunningLFAssets
 */
export interface CompleteLongRunningLFAssets {
  /**
   *
   * @type {number}
   * @memberof CompleteLongRunningLFAssets
   */
  application_uid: number;
  /**
   *
   * @type {number}
   * @memberof CompleteLongRunningLFAssets
   */
  lf_uid: number;
  /**
   *
   * @type {number}
   * @memberof CompleteLongRunningLFAssets
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface CompletionStatus
 */
export interface CompletionStatus {
  /**
   *
   * @type {number}
   * @memberof CompletionStatus
   */
  annotation_count: number;
  /**
   *
   * @type {number}
   * @memberof CompletionStatus
   */
  annotation_target: number;
}
/**
 *
 * @export
 * @interface ComputeEmbeddingsPayload
 */
export interface ComputeEmbeddingsPayload {
  /**
   *
   * @type {boolean}
   * @memberof ComputeEmbeddingsPayload
   */
  create_index?: boolean;
  /**
   *
   * @type {string}
   * @memberof ComputeEmbeddingsPayload
   */
  dataset_preprocessing_job_uid?: string;
  /**
   *
   * @type {string}
   * @memberof ComputeEmbeddingsPayload
   */
  field: string;
  /**
   *
   * @type {string}
   * @memberof ComputeEmbeddingsPayload
   */
  model_dirpath: string;
  /**
   *
   * @type {string}
   * @memberof ComputeEmbeddingsPayload
   */
  model_type: string;
  /**
   *
   * @type {boolean}
   * @memberof ComputeEmbeddingsPayload
   */
  run_on_train_sample?: boolean;
  /**
   *
   * @type {string}
   * @memberof ComputeEmbeddingsPayload
   */
  training_set_preprocessing_job_uid?: string;
}
/**
 *
 * @export
 * @interface ComputeEmbeddingsResponse
 */
export interface ComputeEmbeddingsResponse {
  /**
   *
   * @type {number}
   * @memberof ComputeEmbeddingsResponse
   */
  embeddings_uid: number;
}
/**
 *
 * @export
 * @interface ConfusionMatrixHistograms
 */
export interface ConfusionMatrixHistograms {
  /**
   *
   * @type {Array<number>}
   * @memberof ConfusionMatrixHistograms
   */
  fns: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof ConfusionMatrixHistograms
   */
  fps: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof ConfusionMatrixHistograms
   */
  histogram_labels: Array<string>;
}
/**
 *
 * @export
 * @interface ConfusionMatrixResponse
 */
export interface ConfusionMatrixResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof ConfusionMatrixResponse
   */
  labels: Array<string>;
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof ConfusionMatrixResponse
   */
  matrix: Array<Array<number>>;
}
/**
 *
 * @export
 * @interface ConfusionMatrixSortedResponse
 */
export interface ConfusionMatrixSortedResponse {
  /**
   *
   * @type {Array<number>}
   * @memberof ConfusionMatrixSortedResponse
   */
  error_counts: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof ConfusionMatrixSortedResponse
   */
  gt_labels: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ConfusionMatrixSortedResponse
   */
  preds_labels: Array<string>;
}
/**
 *
 * @export
 * @interface ContinueLongRunningLFPayload
 */
export interface ContinueLongRunningLFPayload {
  /**
   *
   * @type {Array<string>}
   * @memberof ContinueLongRunningLFPayload
   */
  inference_splits?: Array<string>;
}
/**
 *
 * @export
 * @interface ContinueLongRunningLFResponse
 */
export interface ContinueLongRunningLFResponse {
  /**
   *
   * @type {string}
   * @memberof ContinueLongRunningLFResponse
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface ConvertRawToUserParams
 */
export interface ConvertRawToUserParams {
  /**
   *
   * @type {boolean}
   * @memberof ConvertRawToUserParams
   */
  is_context?: boolean;
  /**
   *
   * @type {object}
   * @memberof ConvertRawToUserParams
   */
  raw_labels: object;
}
/**
 *
 * @export
 * @interface ConvertUserToRawParams
 */
export interface ConvertUserToRawParams {
  /**
   *
   * @type {boolean}
   * @memberof ConvertUserToRawParams
   */
  is_context?: boolean;
  /**
   *
   * @type {object}
   * @memberof ConvertUserToRawParams
   */
  user_labels: object;
}
/**
 *
 * @export
 * @interface CopyLFByNameParams
 */
export interface CopyLFByNameParams {
  /**
   *
   * @type {number}
   * @memberof CopyLFByNameParams
   */
  from_node_uid: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CopyLFByNameParams
   */
  lfs_to_copy?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CopyLFByNameParams
   */
  merge_type?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CopyLFByNameParams
   */
  renamed_labels?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface CopyLabelSchemaPayload
 */
export interface CopyLabelSchemaPayload {
  /**
   *
   * @type {string}
   * @memberof CopyLabelSchemaPayload
   */
  description?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CopyLabelSchemaPayload
   */
  label_descriptions?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CopyLabelSchemaPayload
   */
  label_map?: { [key: string]: number };
  /**
   *
   * @type {string}
   * @memberof CopyLabelSchemaPayload
   */
  name: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CopyLabelSchemaPayload
   */
  updated_label_schema?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface CreateAPIKeyParams
 */
export interface CreateAPIKeyParams {
  /**
   *
   * @type {string}
   * @memberof CreateAPIKeyParams
   */
  description?: string;
}
/**
 *
 * @export
 * @interface CreateAnnotationParams
 */
export interface CreateAnnotationParams {
  /**
   *
   * @type {number}
   * @memberof CreateAnnotationParams
   */
  annotation_uid?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateAnnotationParams
   */
  convert_to_raw_format?: boolean;
  /**
   *
   * @type {any}
   * @memberof CreateAnnotationParams
   */
  label?: any;
  /**
   *
   * @type {object}
   * @memberof CreateAnnotationParams
   */
  metadata?: object;
  /**
   *
   * @type {number}
   * @memberof CreateAnnotationParams
   */
  source_uid?: number;
  /**
   *
   * @type {string}
   * @memberof CreateAnnotationParams
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAnnotationParams
   */
  ts?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAnnotationParams
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface CreateAnnotationResponse
 */
export interface CreateAnnotationResponse {
  /**
   *
   * @type {number}
   * @memberof CreateAnnotationResponse
   */
  annotation_uid: number;
  /**
   *
   * @type {Source}
   * @memberof CreateAnnotationResponse
   */
  source?: Source;
}
/**
 *
 * @export
 * @interface CreateAnnotationsParams
 */
export interface CreateAnnotationsParams {
  /**
   *
   * @type {number}
   * @memberof CreateAnnotationsParams
   */
  annotation_uid?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateAnnotationsParams
   */
  convert_to_raw_format?: boolean;
  /**
   *
   * @type {any}
   * @memberof CreateAnnotationsParams
   */
  label?: any;
  /**
   *
   * @type {object}
   * @memberof CreateAnnotationsParams
   */
  metadata?: object;
  /**
   *
   * @type {number}
   * @memberof CreateAnnotationsParams
   */
  source_uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateAnnotationsParams
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAnnotationsParams
   */
  ts?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAnnotationsParams
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface CreateAppVersionParams
 */
export interface CreateAppVersionParams {
  /**
   *
   * @type {string}
   * @memberof CreateAppVersionParams
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAppVersionParams
   */
  name: string;
}
/**
 *
 * @export
 * @interface CreateAppVersionResponse
 */
export interface CreateAppVersionResponse {
  /**
   *
   * @type {number}
   * @memberof CreateAppVersionResponse
   */
  app_version_uid: number;
}
/**
 *
 * @export
 * @interface CreateApplicationDeploymentParams
 */
export interface CreateApplicationDeploymentParams {
  /**
   *
   * @type {number}
   * @memberof CreateApplicationDeploymentParams
   */
  application_uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateApplicationDeploymentParams
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateApplicationDeploymentParams
   */
  experiment_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateApplicationDeploymentParams
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof CreateApplicationDeploymentParams
   */
  node_uid?: number;
  /**
   *
   * @type {PackageType}
   * @memberof CreateApplicationDeploymentParams
   */
  package_type?: PackageType;
  /**
   *
   * @type {RegistrySelection}
   * @memberof CreateApplicationDeploymentParams
   */
  registry_selection?: RegistrySelection;
  /**
   *
   * @type {boolean}
   * @memberof CreateApplicationDeploymentParams
   */
  signature?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreateApplicationDeploymentParams
   */
  user_uid?: number;
  /**
   *
   * @type {WorkflowEnv}
   * @memberof CreateApplicationDeploymentParams
   */
  workflow_env?: WorkflowEnv;
  /**
   *
   * @type {number}
   * @memberof CreateApplicationDeploymentParams
   */
  workspace_uid?: number;
}

/**
 *
 * @export
 * @interface CreateApplicationPayload
 */
export interface CreateApplicationPayload {
  /**
   *
   * @type {AccessConfig}
   * @memberof CreateApplicationPayload
   */
  access_config?: AccessConfig;
  /**
   *
   * @type {AppLicenseType}
   * @memberof CreateApplicationPayload
   */
  app_license_type?: AppLicenseType;
  /**
   *
   * @type {ApplicationConfig}
   * @memberof CreateApplicationPayload
   */
  application_config?: ApplicationConfig;
  /**
   *
   * @type {boolean}
   * @memberof CreateApplicationPayload
   */
  auto_application_name?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreateApplicationPayload
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateApplicationPayload
   */
  description?: string;
  /**
   *
   * @type {DuplicateApplicationMetadata}
   * @memberof CreateApplicationPayload
   */
  duplicate_application_metadata?: DuplicateApplicationMetadata;
  /**
   *
   * @type {number}
   * @memberof CreateApplicationPayload
   */
  from_application_uid?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateApplicationPayload
   */
  is_onboarding?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateApplicationPayload
   */
  name: string;
}

/**
 *
 * @export
 * @interface CreateApplicationResponse
 */
export interface CreateApplicationResponse {
  /**
   *
   * @type {number}
   * @memberof CreateApplicationResponse
   */
  application_uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateApplicationResponse
   */
  dag_job_id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateApplicationResponse
   */
  job_ids?: Array<string>;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CreateApplicationResponse
   */
  translation_dict?: { [key: string]: number };
}
/**
 *
 * @export
 * @interface CreateBatchParams
 */
export interface CreateBatchParams {
  /**
   *
   * @type {Array<number>}
   * @memberof CreateBatchParams
   */
  assignee_uids?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CreateBatchParams
   */
  author_uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateBatchParams
   */
  batch_name?: string;
  /**
   *
   * @type {number}
   * @memberof CreateBatchParams
   */
  batch_size?: number;
  /**
   *
   * @type {number}
   * @memberof CreateBatchParams
   */
  datasource_uid?: number;
  /**
   *
   * @type {number}
   * @memberof CreateBatchParams
   */
  num_batches?: number;
  /**
   *
   * @type {number}
   * @memberof CreateBatchParams
   */
  parent_batch_uid?: number;
  /**
   *
   * @type {number}
   * @memberof CreateBatchParams
   */
  random_seed?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateBatchParams
   */
  randomize?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateBatchParams
   */
  sample_by_docs?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateBatchParams
   */
  selection_strategy?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBatchParams
   */
  split?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateBatchParams
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface CreateBatchResponse
 */
export interface CreateBatchResponse {
  /**
   *
   * @type {Array<HydratedBatch>}
   * @memberof CreateBatchResponse
   */
  created_batches: Array<HydratedBatch>;
}
/**
 *
 * @export
 * @interface CreateComment
 */
export interface CreateComment {
  /**
   *
   * @type {string}
   * @memberof CreateComment
   */
  body: string;
  /**
   *
   * @type {number}
   * @memberof CreateComment
   */
  user_uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateComment
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface CreateDatasetAnnotationParams
 */
export interface CreateDatasetAnnotationParams {
  /**
   *
   * @type {boolean}
   * @memberof CreateDatasetAnnotationParams
   */
  convert_to_raw_format?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreateDatasetAnnotationParams
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateDatasetAnnotationParams
   */
  freeform_text?: string;
  /**
   *
   * @type {any}
   * @memberof CreateDatasetAnnotationParams
   */
  label?: any;
  /**
   *
   * @type {number}
   * @memberof CreateDatasetAnnotationParams
   */
  label_schema_uid: number;
  /**
   *
   * @type {object}
   * @memberof CreateDatasetAnnotationParams
   */
  metadata?: object;
  /**
   *
   * @type {number}
   * @memberof CreateDatasetAnnotationParams
   */
  source_uid?: number;
  /**
   *
   * @type {string}
   * @memberof CreateDatasetAnnotationParams
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDatasetAnnotationParams
   */
  ts?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDatasetAnnotationParams
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface CreateDatasetAnnotationResponse
 */
export interface CreateDatasetAnnotationResponse {
  /**
   *
   * @type {number}
   * @memberof CreateDatasetAnnotationResponse
   */
  annotation_uid: number;
  /**
   *
   * @type {SvcSource}
   * @memberof CreateDatasetAnnotationResponse
   */
  source?: SvcSource;
}
/**
 *
 * @export
 * @interface CreateDatasetBatchPayload
 */
export interface CreateDatasetBatchPayload {
  /**
   *
   * @type {Array<number>}
   * @memberof CreateDatasetBatchPayload
   */
  assignees?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CreateDatasetBatchPayload
   */
  batch_size?: number;
  /**
   *
   * @type {number}
   * @memberof CreateDatasetBatchPayload
   */
  dataset_uid: number;
  /**
   *
   * @type {number}
   * @memberof CreateDatasetBatchPayload
   */
  datasource_uid?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateDatasetBatchPayload
   */
  divide_x_uids_evenly_to_assignees?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateDatasetBatchPayload
   */
  filter_by_unassigned_x_uids?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateDatasetBatchPayload
   */
  label_schema_uids?: Array<number>;
  /**
   *
   * @type {Metadata}
   * @memberof CreateDatasetBatchPayload
   */
  metadata?: Metadata;
  /**
   *
   * @type {string}
   * @memberof CreateDatasetBatchPayload
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof CreateDatasetBatchPayload
   */
  parent_batch_uid?: number;
  /**
   *
   * @type {number}
   * @memberof CreateDatasetBatchPayload
   */
  random_seed?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateDatasetBatchPayload
   */
  randomize?: boolean;
  /**
   *
   * @type {SelectionStrategy}
   * @memberof CreateDatasetBatchPayload
   */
  selection_strategy?: SelectionStrategy;
  /**
   *
   * @type {string}
   * @memberof CreateDatasetBatchPayload
   */
  split?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateDatasetBatchPayload
   */
  x_uids?: Array<string>;
}

/**
 *
 * @export
 * @interface CreateDatasetCommentParams
 */
export interface CreateDatasetCommentParams {
  /**
   *
   * @type {string}
   * @memberof CreateDatasetCommentParams
   */
  body: string;
  /**
   *
   * @type {number}
   * @memberof CreateDatasetCommentParams
   */
  dataset_uid: number;
  /**
   *
   * @type {number}
   * @memberof CreateDatasetCommentParams
   */
  user_uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateDatasetCommentParams
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface CreateDatasetTagTypeParams
 */
export interface CreateDatasetTagTypeParams {
  /**
   *
   * @type {number}
   * @memberof CreateDatasetTagTypeParams
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateDatasetTagTypeParams
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateDatasetTagTypeParams
   */
  is_context_tag_type?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateDatasetTagTypeParams
   */
  name: string;
}
/**
 *
 * @export
 * @interface CreateDatasetViewParams
 */
export interface CreateDatasetViewParams {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CreateDatasetViewParams
   */
  column_mapping: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof CreateDatasetViewParams
   */
  dataset_uid: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateDatasetViewParams
   */
  label_schema_uids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof CreateDatasetViewParams
   */
  name: string;
  /**
   *
   * @type {DatasetViewTypes}
   * @memberof CreateDatasetViewParams
   */
  view_type: DatasetViewTypes;
}

/**
 *
 * @export
 * @interface CreateGroundTruthVersionParams
 */
export interface CreateGroundTruthVersionParams {
  /**
   *
   * @type {string}
   * @memberof CreateGroundTruthVersionParams
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateGroundTruthVersionParams
   */
  name: string;
}
/**
 *
 * @export
 * @interface CreateGroundTruthVersionResponse
 */
export interface CreateGroundTruthVersionResponse {
  /**
   *
   * @type {number}
   * @memberof CreateGroundTruthVersionResponse
   */
  gt_version_uid: number;
}
/**
 *
 * @export
 * @interface CreateInviteParams
 */
export interface CreateInviteParams {
  /**
   *
   * @type {string}
   * @memberof CreateInviteParams
   */
  expiration: string;
  /**
   *
   * @type {number}
   * @memberof CreateInviteParams
   */
  max_users_allowed: number;
  /**
   *
   * @type {UserRole}
   * @memberof CreateInviteParams
   */
  role?: UserRole;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateInviteParams
   */
  roles?: Array<number>;
}

/**
 *
 * @export
 * @interface CreateLFTemplateResponse
 */
export interface CreateLFTemplateResponse {
  /**
   *
   * @type {number}
   * @memberof CreateLFTemplateResponse
   */
  lf_template_uid: number;
}
/**
 *
 * @export
 * @interface CreateLabelSchemaPayload
 */
export interface CreateLabelSchemaPayload {
  /**
   *
   * @type {DataType}
   * @memberof CreateLabelSchemaPayload
   */
  data_type: DataType;
  /**
   *
   * @type {number}
   * @memberof CreateLabelSchemaPayload
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateLabelSchemaPayload
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLabelSchemaPayload
   */
  image_field?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateLabelSchemaPayload
   */
  is_multi_label: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateLabelSchemaPayload
   */
  is_text_label?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateLabelSchemaPayload
   */
  label_column?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CreateLabelSchemaPayload
   */
  label_descriptions?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CreateLabelSchemaPayload
   */
  label_map: { [key: string]: number };
  /**
   *
   * @type {string}
   * @memberof CreateLabelSchemaPayload
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateLabelSchemaPayload
   */
  pdf_url_field?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLabelSchemaPayload
   */
  primary_field?: string;
  /**
   *
   * @type {TaskType}
   * @memberof CreateLabelSchemaPayload
   */
  task_type: TaskType;
}

/**
 *
 * @export
 * @interface CreateLabelSchemaResponse
 */
export interface CreateLabelSchemaResponse {
  /**
   *
   * @type {number}
   * @memberof CreateLabelSchemaResponse
   */
  label_schema_uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateLabelSchemaResponse
   */
  name: string;
}
/**
 *
 * @export
 * @interface CreateModelNodeHierarchyPayload
 */
export interface CreateModelNodeHierarchyPayload {
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof CreateModelNodeHierarchyPayload
   */
  hierarchy_config: { [key: string]: object };
}
/**
 *
 * @export
 * @interface CreateModelNodeHierarchyResponse
 */
export interface CreateModelNodeHierarchyResponse {
  /**
   *
   * @type {object}
   * @memberof CreateModelNodeHierarchyResponse
   */
  nodes_output_dict: object;
}
/**
 *
 * @export
 * @interface CreateModelRegistryParams
 */
export interface CreateModelRegistryParams {
  /**
   *
   * @type {RegistryAuthenticationCredentials}
   * @memberof CreateModelRegistryParams
   */
  auth_credentials?: RegistryAuthenticationCredentials;
  /**
   *
   * @type {string}
   * @memberof CreateModelRegistryParams
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateModelRegistryParams
   */
  name: string;
  /**
   *
   * @type {RegistryStorageCredentials}
   * @memberof CreateModelRegistryParams
   */
  storage_credentials?: RegistryStorageCredentials;
  /**
   *
   * @type {string}
   * @memberof CreateModelRegistryParams
   */
  tracking_server_uri: string;
  /**
   *
   * @type {number}
   * @memberof CreateModelRegistryParams
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface CreateNodeBlockPayload
 */
export interface CreateNodeBlockPayload {
  /**
   *
   * @type {ImportAssets}
   * @memberof CreateNodeBlockPayload
   */
  assets_to_import?: ImportAssets;
  /**
   *
   * @type {object}
   * @memberof CreateNodeBlockPayload
   */
  block_config?: object;
  /**
   *
   * @type {number}
   * @memberof CreateNodeBlockPayload
   */
  from_block_uid?: number;
  /**
   *
   * @type {number}
   * @memberof CreateNodeBlockPayload
   */
  input_node_uid: number;
  /**
   *
   * @type {number}
   * @memberof CreateNodeBlockPayload
   */
  label_schema_uid?: number;
  /**
   *
   * @type {string}
   * @memberof CreateNodeBlockPayload
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateNodeBlockPayload
   */
  template_id: string;
}
/**
 *
 * @export
 * @interface CreateNodeBlockResponse
 */
export interface CreateNodeBlockResponse {
  /**
   *
   * @type {number}
   * @memberof CreateNodeBlockResponse
   */
  block_uid: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateNodeBlockResponse
   */
  job_ids?: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateNodeBlockResponse
   */
  node_uids: Array<number>;
}
/**
 *
 * @export
 * @interface CreateNodePayload
 */
export interface CreateNodePayload {
  /**
   *
   * @type {boolean}
   * @memberof CreateNodePayload
   */
  add_to_parent_block?: boolean;
  /**
   *
   * @type {CommittedOperatorPayload}
   * @memberof CreateNodePayload
   */
  committed_operator_config?: CommittedOperatorPayload;
  /**
   *
   * @type {string}
   * @memberof CreateNodePayload
   */
  expected_op_type?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateNodePayload
   */
  input_node_uids: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CreateNodePayload
   */
  label_schema_uid?: number;
  /**
   *
   * @type {string}
   * @memberof CreateNodePayload
   */
  node_cls?: string;
  /**
   *
   * @type {object}
   * @memberof CreateNodePayload
   */
  node_config?: object;
  /**
   *
   * @type {number}
   * @memberof CreateNodePayload
   */
  output_node_uid?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateNodePayload
   */
  skip_validation?: boolean;
}
/**
 *
 * @export
 * @interface CreateNodeResponse
 */
export interface CreateNodeResponse {
  /**
   *
   * @type {number}
   * @memberof CreateNodeResponse
   */
  node_uid: number;
  /**
   *
   * @type {number}
   * @memberof CreateNodeResponse
   */
  op_version_uid?: number;
}
/**
 *
 * @export
 * @interface CreateOnboardingDataStepParams
 */
export interface CreateOnboardingDataStepParams {
  /**
   *
   * @type {number}
   * @memberof CreateOnboardingDataStepParams
   */
  application_uid: number;
}
/**
 *
 * @export
 * @interface CreateOpVersionAddOpPredictionsResponse
 */
export interface CreateOpVersionAddOpPredictionsResponse {
  /**
   *
   * @type {string}
   * @memberof CreateOpVersionAddOpPredictionsResponse
   */
  job_id?: string;
  /**
   *
   * @type {number}
   * @memberof CreateOpVersionAddOpPredictionsResponse
   */
  node_uid: number;
}
/**
 * Base model used by Operators and OpVersions to hold and validate the input payload for creating Operators and OpVersions respectively
 * @export
 * @interface CreateOpVersionPayload
 */
export interface CreateOpVersionPayload {
  /**
   *
   * @type {object}
   * @memberof CreateOpVersionPayload
   */
  op_config: object;
  /**
   *
   * @type {string}
   * @memberof CreateOpVersionPayload
   */
  op_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOpVersionPayload
   */
  op_type: string;
}
/**
 *
 * @export
 * @interface CreateOpVersionResponse
 */
export interface CreateOpVersionResponse {
  /**
   *
   * @type {number}
   * @memberof CreateOpVersionResponse
   */
  op_version_uid: number;
}
/**
 *
 * @export
 * @interface CreateOperatorResponse
 */
export interface CreateOperatorResponse {
  /**
   *
   * @type {number}
   * @memberof CreateOperatorResponse
   */
  op_uid: number;
}
/**
 *
 * @export
 * @interface CreatePopulatorPayload
 */
export interface CreatePopulatorPayload {
  /**
   *
   * @type {number}
   * @memberof CreatePopulatorPayload
   */
  application_uid: number;
  /**
   *
   * @type {boolean}
   * @memberof CreatePopulatorPayload
   */
  cache_training_set_labels?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreatePopulatorPayload
   */
  copy_s3_resources?: boolean;
  /**
   *
   * @type {string}
   * @memberof CreatePopulatorPayload
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreatePopulatorPayload
   */
  external_resource_columns?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreatePopulatorPayload
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CreatePopulatorPayload
   */
  notebook_paths?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreatePopulatorPayload
   */
  populator_path: string;
  /**
   *
   * @type {boolean}
   * @memberof CreatePopulatorPayload
   */
  save_all_derived_datasources?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreatePopulatorPayload
   */
  ttl_seconds?: number;
}
/**
 *
 * @export
 * @interface CreatePopulatorResponse
 */
export interface CreatePopulatorResponse {
  /**
   *
   * @type {string}
   * @memberof CreatePopulatorResponse
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface CreatePredictionAPIServiceParams
 */
export interface CreatePredictionAPIServiceParams {
  /**
   *
   * @type {number}
   * @memberof CreatePredictionAPIServiceParams
   */
  deployment_uid: number;
  /**
   *
   * @type {number}
   * @memberof CreatePredictionAPIServiceParams
   */
  n_cpus?: number;
  /**
   *
   * @type {number}
   * @memberof CreatePredictionAPIServiceParams
   */
  n_memory_gb?: number;
  /**
   *
   * @type {number}
   * @memberof CreatePredictionAPIServiceParams
   */
  n_replicas?: number;
  /**
   *
   * @type {number}
   * @memberof CreatePredictionAPIServiceParams
   */
  port?: number;
}
/**
 *
 * @export
 * @interface CreateTaskGroundTruthResponse
 */
export interface CreateTaskGroundTruthResponse {
  /**
   *
   * @type {string}
   * @memberof CreateTaskGroundTruthResponse
   */
  job?: string;
  /**
   *
   * @type {number}
   * @memberof CreateTaskGroundTruthResponse
   */
  n_labels: number;
  /**
   *
   * @type {number}
   * @memberof CreateTaskGroundTruthResponse
   */
  task: number;
  /**
   *
   * @type {string}
   * @memberof CreateTaskGroundTruthResponse
   */
  warning: string;
}
/**
 *
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  default_view?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  invite_link?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  password: string;
  /**
   *
   * @type {UserRole}
   * @memberof CreateUserRequest
   */
  role?: UserRole;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateUserRequest
   */
  roles?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof CreateUserRequest
   */
  username: string;
}

/**
 *
 * @export
 * @interface CreateWorkspacePayload
 */
export interface CreateWorkspacePayload {
  /**
   *
   * @type {WorkspaceConfig}
   * @memberof CreateWorkspacePayload
   */
  config: WorkspaceConfig;
  /**
   *
   * @type {string}
   * @memberof CreateWorkspacePayload
   */
  name: string;
}
/**
 *
 * @export
 * @interface CreateWorkspaceResponse
 */
export interface CreateWorkspaceResponse {
  /**
   *
   * @type {number}
   * @memberof CreateWorkspaceResponse
   */
  workspace_uid: number;
}
/**
 *
 * @export
 * @interface CustomInferenceServiceSecretsConfig
 */
export interface CustomInferenceServiceSecretsConfig {
  /**
   *
   * @type {string}
   * @memberof CustomInferenceServiceSecretsConfig
   */
  custom_inference_api_key?: string;
  /**
   *
   * @type {string}
   * @memberof CustomInferenceServiceSecretsConfig
   */
  provider: CustomInferenceServiceSecretsConfigProviderEnum;
}

export const CustomInferenceServiceSecretsConfigProviderEnum = {
  CustomInferenceService: 'custom_inference_service',
} as const;

export type CustomInferenceServiceSecretsConfigProviderEnum =
  (typeof CustomInferenceServiceSecretsConfigProviderEnum)[keyof typeof CustomInferenceServiceSecretsConfigProviderEnum];

/**
 *
 * @export
 * @interface CustomMetricParams
 */
export interface CustomMetricParams {
  /**
   *
   * @type {number}
   * @memberof CustomMetricParams
   */
  custom_metric_uid: number;
  /**
   *
   * @type {string}
   * @memberof CustomMetricParams
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof CustomMetricParams
   */
  needs_manual_recompile: boolean;
  /**
   *
   * @type {string}
   * @memberof CustomMetricParams
   */
  raw_code: string;
  /**
   *
   * @type {string}
   * @memberof CustomMetricParams
   */
  serialized_custom_metric_func: string;
}
/**
 *
 * @export
 * @interface CustomMetricsResponse
 */
export interface CustomMetricsResponse {
  /**
   *
   * @type {Array<CustomMetricParams>}
   * @memberof CustomMetricsResponse
   */
  custom_metrics: Array<CustomMetricParams>;
  /**
   *
   * @type {number}
   * @memberof CustomMetricsResponse
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface CustomModelInfo
 */
export interface CustomModelInfo {
  /**
   *
   * @type {object}
   * @memberof CustomModelInfo
   */
  code: object;
  /**
   *
   * @type {string}
   * @memberof CustomModelInfo
   */
  model_class_pickle: string;
}
/**
 *
 * @export
 * @interface CustomPipInstallParams
 */
export interface CustomPipInstallParams {
  /**
   *
   * @type {string}
   * @memberof CustomPipInstallParams
   */
  index_url?: string;
  /**
   *
   * @type {string}
   * @memberof CustomPipInstallParams
   */
  package: string;
  /**
   *
   * @type {string}
   * @memberof CustomPipInstallParams
   */
  version?: string;
}
/**
 *
 * @export
 * @interface CustomPipInstallResponse
 */
export interface CustomPipInstallResponse {
  /**
   *
   * @type {string}
   * @memberof CustomPipInstallResponse
   */
  warn_message?: string;
}
/**
 *
 * @export
 * @interface CustomPipInstallWheelParams
 */
export interface CustomPipInstallWheelParams {
  /**
   *
   * @type {string}
   * @memberof CustomPipInstallWheelParams
   */
  path: string;
}
/**
 *
 * @export
 * @interface CustomTrainedModelResponse
 */
export interface CustomTrainedModelResponse {
  /**
   *
   * @type {string}
   * @memberof CustomTrainedModelResponse
   */
  job_id: string;
  /**
   *
   * @type {number}
   * @memberof CustomTrainedModelResponse
   */
  node_uid: number;
}
/**
 * BaseModel for nodes in both the Metagraph for ApplicationTemplates and for nodes in an actual instantiated NodeDAG. Allows us to write algorithms that work on both actual DAGs and on DAG templates
 * @export
 * @interface DAGNode
 */
export interface DAGNode {
  /**
   *
   * @type {Array<number>}
   * @memberof DAGNode
   */
  input_ids: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof DAGNode
   */
  is_output?: boolean;
}
/**
 *
 * @export
 * @interface DAGVersionInfo
 */
export interface DAGVersionInfo {
  /**
   *
   * @type {{ [key: string]: DAGNode; }}
   * @memberof DAGVersionInfo
   */
  node_dag?: { [key: string]: DAGNode };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof DAGVersionInfo
   */
  node_op_versions?: { [key: string]: number };
}
/**
 * NOTE: the ordering of these matters!! We use the ordering to determine the rendering order in the UI. Having a linear ordering allows us to skip the complexity of adding yet another DAG here where the settings are connected by dependencies.  As an example, APP_IE_TYPE depends on TASK_TYPE so it\'s below it in the list. The relative ordering between APP_IE_TYPE and PDF_TYPE doesn\'t matter as they don\'t depend on each other.
 * @export
 * @enum {string}
 */

export const DataAndTaskSetting = {
  Dataset: 'Dataset',
  EnableAllDataSources: 'Enable all data sources',
} as const;

export type DataAndTaskSetting =
  (typeof DataAndTaskSetting)[keyof typeof DataAndTaskSetting];

/**
 *
 * @export
 * @interface DataAndTaskTypeStep
 */
export interface DataAndTaskTypeStep {
  /**
   *
   * @type {boolean}
   * @memberof DataAndTaskTypeStep
   */
  is_user_input_needed?: boolean;
  /**
   *
   * @type {SetupStepName}
   * @memberof DataAndTaskTypeStep
   */
  name?: SetupStepName;
  /**
   *
   * @type {Array<SetupSetting>}
   * @memberof DataAndTaskTypeStep
   */
  settings?: Array<SetupSetting>;
  /**
   *
   * @type {Array<InputWarning>}
   * @memberof DataAndTaskTypeStep
   */
  warnings?: Array<InputWarning>;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DataConnector = {
  CloudStorage: 'CloudStorage',
  FileUpload: 'FileUpload',
  Sqldb: 'SQLDB',
  DatabricksSql: 'DatabricksSQL',
  GoogleBigQuery: 'GoogleBigQuery',
  Snowflake: 'Snowflake',
} as const;

export type DataConnector = (typeof DataConnector)[keyof typeof DataConnector];

/**
 *
 * @export
 * @interface DataConnectorActivateRequest
 */
export interface DataConnectorActivateRequest {
  /**
   *
   * @type {DataConnector}
   * @memberof DataConnectorActivateRequest
   */
  data_connector: DataConnector;
}

/**
 *
 * @export
 * @interface DataConnectorConfig
 */
export interface DataConnectorConfig {
  /**
   *
   * @type {object}
   * @memberof DataConnectorConfig
   */
  config: object;
  /**
   *
   * @type {number}
   * @memberof DataConnectorConfig
   */
  data_connector_config_uid: number;
  /**
   *
   * @type {DataConnector}
   * @memberof DataConnectorConfig
   */
  data_connector_type: DataConnector;
  /**
   *
   * @type {string}
   * @memberof DataConnectorConfig
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof DataConnectorConfig
   */
  schema_version: number;
  /**
   *
   * @type {number}
   * @memberof DataConnectorConfig
   */
  workspace_uid: number;
}

/**
 *
 * @export
 * @interface DataConnectorConfigCreationRequest
 */
export interface DataConnectorConfigCreationRequest {
  /**
   *
   * @type {object}
   * @memberof DataConnectorConfigCreationRequest
   */
  config: object;
  /**
   *
   * @type {DataConnector}
   * @memberof DataConnectorConfigCreationRequest
   */
  data_connector_type: DataConnector;
  /**
   *
   * @type {string}
   * @memberof DataConnectorConfigCreationRequest
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof DataConnectorConfigCreationRequest
   */
  workspace_uid: number;
}

/**
 *
 * @export
 * @interface DataConnectorConfigUpdateParams
 */
export interface DataConnectorConfigUpdateParams {
  /**
   *
   * @type {object}
   * @memberof DataConnectorConfigUpdateParams
   */
  new_config: object;
  /**
   *
   * @type {string}
   * @memberof DataConnectorConfigUpdateParams
   */
  new_name: string;
}
/**
 *
 * @export
 * @interface DataConnectorRole
 */
export interface DataConnectorRole {
  /**
   *
   * @type {DataConnector}
   * @memberof DataConnectorRole
   */
  data_connector_type: DataConnector;
  /**
   *
   * @type {Array<CRUDAction>}
   * @memberof DataConnectorRole
   */
  permissions: Array<CRUDAction>;
  /**
   *
   * @type {number}
   * @memberof DataConnectorRole
   */
  role_uid: number;
  /**
   *
   * @type {Array<UserRole>}
   * @memberof DataConnectorRole
   */
  user_roles: Array<UserRole>;
  /**
   *
   * @type {string}
   * @memberof DataConnectorRole
   */
  workspace_name: string;
  /**
   *
   * @type {number}
   * @memberof DataConnectorRole
   */
  workspace_uid?: number;
}

/**
 *
 * @export
 * @interface DataConnectorRoleCreationRequest
 */
export interface DataConnectorRoleCreationRequest {
  /**
   *
   * @type {DataConnector}
   * @memberof DataConnectorRoleCreationRequest
   */
  data_connector_type: DataConnector;
  /**
   *
   * @type {Array<CRUDAction>}
   * @memberof DataConnectorRoleCreationRequest
   */
  permissions: Array<CRUDAction>;
  /**
   *
   * @type {Array<UserRole>}
   * @memberof DataConnectorRoleCreationRequest
   */
  user_roles: Array<UserRole>;
  /**
   *
   * @type {number}
   * @memberof DataConnectorRoleCreationRequest
   */
  workspace_uid?: number;
}

/**
 *
 * @export
 * @interface DataConnectorRoleUpdateParams
 */
export interface DataConnectorRoleUpdateParams {
  /**
   *
   * @type {Array<CRUDAction>}
   * @memberof DataConnectorRoleUpdateParams
   */
  new_permissions: Array<CRUDAction>;
  /**
   *
   * @type {Array<UserRole>}
   * @memberof DataConnectorRoleUpdateParams
   */
  new_user_roles: Array<UserRole>;
  /**
   *
   * @type {number}
   * @memberof DataConnectorRoleUpdateParams
   */
  new_workspace_uid?: number;
}
/**
 *
 * @export
 * @interface DataConnectorStateResponse
 */
export interface DataConnectorStateResponse {
  /**
   *
   * @type {boolean}
   * @memberof DataConnectorStateResponse
   */
  enabled: boolean;
}
/**
 * The data modalities that we display on the application template page.  This information is NOT used for any functional purpose. If you add a DataModality to an application template, it will not change how the application processes data, nor will it change how the Studio frontend operates.
 * @export
 * @enum {string}
 */

export const DataModality = {
  Text: 'text',
  Numeric: 'numeric',
  Semistructured: 'semistructured',
  Structured: 'structured',
  Image: 'image',
} as const;

export type DataModality = (typeof DataModality)[keyof typeof DataModality];

/**
 *
 * @export
 * @interface DataSourcePrepResponseModel
 */
export interface DataSourcePrepResponseModel {
  /**
   *
   * @type {number}
   * @memberof DataSourcePrepResponseModel
   */
  dataset_uid: number;
  /**
   *
   * @type {object}
   * @memberof DataSourcePrepResponseModel
   */
  datasource: object;
  /**
   *
   * @type {string}
   * @memberof DataSourcePrepResponseModel
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface DataSourceUniqueColumnValuesRequest
 */
export interface DataSourceUniqueColumnValuesRequest {
  /**
   *
   * @type {string}
   * @memberof DataSourceUniqueColumnValuesRequest
   */
  application_template_id?: string;
  /**
   *
   * @type {string}
   * @memberof DataSourceUniqueColumnValuesRequest
   */
  col_name: string;
  /**
   *
   * @type {Array<number>}
   * @memberof DataSourceUniqueColumnValuesRequest
   */
  datasource_uids: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof DataSourceUniqueColumnValuesRequest
   */
  filter_empty?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DataSourceUniqueColumnValuesRequest
   */
  is_multi_label?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataSourceUniqueColumnValuesRequest
   */
  task_type?: string;
}
/**
 *
 * @export
 * @interface DataSourceWithDatasetUid
 */
export interface DataSourceWithDatasetUid {
  /**
   *
   * @type {LoadConfig}
   * @memberof DataSourceWithDatasetUid
   */
  config: LoadConfig;
  /**
   *
   * @type {number}
   * @memberof DataSourceWithDatasetUid
   */
  dataset_uid?: number;
  /**
   *
   * @type {string}
   * @memberof DataSourceWithDatasetUid
   */
  datasource_name?: string;
  /**
   *
   * @type {number}
   * @memberof DataSourceWithDatasetUid
   */
  datasource_uid: number;
  /**
   *
   * @type {string}
   * @memberof DataSourceWithDatasetUid
   */
  ds: string;
  /**
   *
   * @type {DatasourceMetadataBase}
   * @memberof DataSourceWithDatasetUid
   */
  metadata: DatasourceMetadataBase;
  /**
   *
   * @type {object}
   * @memberof DataSourceWithDatasetUid
   */
  provenance?: object;
  /**
   *
   * @type {number}
   * @memberof DataSourceWithDatasetUid
   */
  source_uid?: number;
  /**
   *
   * @type {string}
   * @memberof DataSourceWithDatasetUid
   */
  split: string;
  /**
   *
   * @type {number}
   * @memberof DataSourceWithDatasetUid
   */
  type: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DataType = {
  Text: 'text',
  Pdf: 'pdf',
  Hocr: 'hocr',
  HocrNoOcr: 'hocr_no_ocr',
  Image: 'image',
  Conversation: 'conversation',
  TimeSeries: 'time_series',
} as const;

export type DataType = (typeof DataType)[keyof typeof DataType];

/**
 *
 * @export
 * @interface Dataset
 */
export interface Dataset {
  /**
   *
   * @type {string}
   * @memberof Dataset
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Dataset
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface DatasetAnnotationsOverviewResponse
 */
export interface DatasetAnnotationsOverviewResponse {
  /**
   *
   * @type {Array<AnnotatorOverviewStats>}
   * @memberof DatasetAnnotationsOverviewResponse
   */
  annotators_stats: Array<AnnotatorOverviewStats>;
  /**
   *
   * @type {CompletionStatus}
   * @memberof DatasetAnnotationsOverviewResponse
   */
  completion_status: CompletionStatus;
  /**
   *
   * @type {number}
   * @memberof DatasetAnnotationsOverviewResponse
   */
  dataset_uid: number;
  /**
   *
   * @type {Array<LabelStats>}
   * @memberof DatasetAnnotationsOverviewResponse
   */
  label_distribution: Array<LabelStats>;
  /**
   *
   * @type {number}
   * @memberof DatasetAnnotationsOverviewResponse
   */
  label_schema_uid: number;
  /**
   *
   * @type {Array<AnnotatorNameID>}
   * @memberof DatasetAnnotationsOverviewResponse
   */
  viewable_annotators: Array<AnnotatorNameID>;
}
/**
 *
 * @export
 * @interface DatasetAssets
 */
export interface DatasetAssets {
  /**
   *
   * @type {string}
   * @memberof DatasetAssets
   */
  dataset_annotations_path?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetAssets
   */
  dataset_batches_path?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetAssets
   */
  dataset_comments_path?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetAssets
   */
  dataset_ground_truth_path?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetAssets
   */
  dataset_slices_path?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetAssets
   */
  dataset_sources_path?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetAssets
   */
  dataset_tag_types_and_map_path?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetAssets
   */
  dataset_views_and_schemas_path?: string;
}
/**
 *
 * @export
 * @interface DatasetBatch
 */
export interface DatasetBatch {
  /**
   *
   * @type {Array<IndividualAnnotatorStatistics>}
   * @memberof DatasetBatch
   */
  annotator_progress?: Array<IndividualAnnotatorStatistics>;
  /**
   *
   * @type {Array<string>}
   * @memberof DatasetBatch
   */
  assignees?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof DatasetBatch
   */
  batch_size: number;
  /**
   *
   * @type {number}
   * @memberof DatasetBatch
   */
  batch_uid: number;
  /**
   *
   * @type {string}
   * @memberof DatasetBatch
   */
  dataset_name?: string;
  /**
   *
   * @type {number}
   * @memberof DatasetBatch
   */
  dataset_uid: number;
  /**
   *
   * @type {number}
   * @memberof DatasetBatch
   */
  expert_source_uid?: number;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof DatasetBatch
   */
  label_schemas?: { [key: string]: string };
  /**
   *
   * @type {Metadata}
   * @memberof DatasetBatch
   */
  metadata?: Metadata;
  /**
   *
   * @type {string}
   * @memberof DatasetBatch
   */
  name?: string;
  /**
   *
   * @type {Array<SvcSource>}
   * @memberof DatasetBatch
   */
  sources?: Array<SvcSource>;
  /**
   *
   * @type {string}
   * @memberof DatasetBatch
   */
  split: string;
  /**
   *
   * @type {string}
   * @memberof DatasetBatch
   */
  ts: string;
  /**
   *
   * @type {number}
   * @memberof DatasetBatch
   */
  user_uid?: number;
}
/**
 * A response model for all filter structures that should be rendered in the Dataset Batch Annotation page. Please make sure there\'s a one-to-one mapping between the fields declared here and DatasetBatchFilterTypes.
 * @export
 * @interface DatasetBatchFilterStructuresResponse
 */
export interface DatasetBatchFilterStructuresResponse {
  /**
   *
   * @type {AnnotationFilterStructureModel}
   * @memberof DatasetBatchFilterStructuresResponse
   */
  annotation: AnnotationFilterStructureModel;
  /**
   *
   * @type {AnnotatorAgreementFilterStructureModel}
   * @memberof DatasetBatchFilterStructuresResponse
   */
  annotator_agreement: AnnotatorAgreementFilterStructureModel;
  /**
   *
   * @type {CommentFilterStructureModel}
   * @memberof DatasetBatchFilterStructuresResponse
   */
  comment: CommentFilterStructureModel;
  /**
   *
   * @type {FieldFilterStructureModel}
   * @memberof DatasetBatchFilterStructuresResponse
   */
  field: FieldFilterStructureModel;
  /**
   *
   * @type {GroundTruthFilterStructureModel}
   * @memberof DatasetBatchFilterStructuresResponse
   */
  ground_truth: GroundTruthFilterStructureModel;
  /**
   *
   * @type {SliceFilterStructureModel}
   * @memberof DatasetBatchFilterStructuresResponse
   */
  slice: SliceFilterStructureModel;
}
/**
 *
 * @export
 * @interface DatasetBatchMetadata
 */
export interface DatasetBatchMetadata {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchMetadata
   */
  annotation_uid: number;
  /**
   *
   * @type {Array<number>}
   * @memberof DatasetBatchMetadata
   */
  batch_uids: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof DatasetBatchMetadata
   */
  names: Array<string>;
}
/**
 *
 * @export
 * @interface DatasetBatchXUIDResponse
 */
export interface DatasetBatchXUIDResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof DatasetBatchXUIDResponse
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface DatasetComment
 */
export interface DatasetComment {
  /**
   *
   * @type {string}
   * @memberof DatasetComment
   */
  body: string;
  /**
   *
   * @type {number}
   * @memberof DatasetComment
   */
  comment_uid: number;
  /**
   *
   * @type {string}
   * @memberof DatasetComment
   */
  created_at: string;
  /**
   *
   * @type {number}
   * @memberof DatasetComment
   */
  dataset_uid: number;
  /**
   *
   * @type {boolean}
   * @memberof DatasetComment
   */
  is_edited?: boolean;
  /**
   *
   * @type {number}
   * @memberof DatasetComment
   */
  user_uid: number;
  /**
   *
   * @type {string}
   * @memberof DatasetComment
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface DatasetCommentsByXuid
 */
export interface DatasetCommentsByXuid {
  /**
   *
   * @type {Array<DatasetComment>}
   * @memberof DatasetCommentsByXuid
   */
  comments: Array<DatasetComment>;
  /**
   *
   * @type {string}
   * @memberof DatasetCommentsByXuid
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface DatasetGroundTruth
 */
export interface DatasetGroundTruth {
  /**
   *
   * @type {number}
   * @memberof DatasetGroundTruth
   */
  committed_by?: number;
  /**
   *
   * @type {Array<LabelSchemaLabels>}
   * @memberof DatasetGroundTruth
   */
  labels: Array<LabelSchemaLabels>;
  /**
   *
   * @type {string}
   * @memberof DatasetGroundTruth
   */
  ts?: string;
  /**
   *
   * @type {string}
   * @memberof DatasetGroundTruth
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface DatasetInfo
 */
export interface DatasetInfo {
  /**
   *
   * @type {number}
   * @memberof DatasetInfo
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof DatasetInfo
   */
  last_viewed_at: string;
  /**
   *
   * @type {string}
   * @memberof DatasetInfo
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof DatasetInfo
   */
  total_size_bytes: number;
  /**
   *
   * @type {number}
   * @memberof DatasetInfo
   */
  workspace_uid: number;
}
/**
 *
 * @export
 * @interface DatasetMetadata
 */
export interface DatasetMetadata {
  /**
   *
   * @type {boolean}
   * @memberof DatasetMetadata
   */
  allow_generate_uid_col?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DatasetMetadata
   */
  enable_mta?: boolean;
  /**
   *
   * @type {number}
   * @memberof DatasetMetadata
   */
  num_datasources?: number;
}
/**
 *
 * @export
 * @interface DatasetResponse
 */
export interface DatasetResponse {
  /**
   *
   * @type {boolean}
   * @memberof DatasetResponse
   */
  are_all_datasources_arrow: boolean;
  /**
   *
   * @type {number}
   * @memberof DatasetResponse
   */
  dataset_uid: number;
  /**
   *
   * @type {number}
   * @memberof DatasetResponse
   */
  file_storage_config_uid?: number;
  /**
   *
   * @type {string}
   * @memberof DatasetResponse
   */
  first_created?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof DatasetResponse
   */
  hydrated_node_dag?: { [key: string]: object };
  /**
   *
   * @type {string}
   * @memberof DatasetResponse
   */
  last_opened?: string;
  /**
   *
   * @type {DatasetMetadata}
   * @memberof DatasetResponse
   */
  metadata?: DatasetMetadata;
  /**
   *
   * @type {string}
   * @memberof DatasetResponse
   */
  name: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof DatasetResponse
   */
  node_dag?: { [key: string]: object };
  /**
   *
   * @type {number}
   * @memberof DatasetResponse
   */
  total_size_bytes?: number;
  /**
   *
   * @type {number}
   * @memberof DatasetResponse
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface DatasetTagType
 */
export interface DatasetTagType {
  /**
   *
   * @type {number}
   * @memberof DatasetTagType
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof DatasetTagType
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof DatasetTagType
   */
  is_context_tag_type?: boolean;
  /**
   *
   * @type {string}
   * @memberof DatasetTagType
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof DatasetTagType
   */
  tag_type_uid: number;
}
/**
 * Base class for dataset templates.
 * @export
 * @interface DatasetTemplate
 */
export interface DatasetTemplate {
  /**
   *
   * @type {Array<string>}
   * @memberof DatasetTemplate
   */
  ops?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DatasetTemplate
   */
  template_id: string;
}
/**
 *
 * @export
 * @interface DatasetView
 */
export interface DatasetView {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof DatasetView
   */
  column_mapping: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof DatasetView
   */
  dataset_uid: number;
  /**
   *
   * @type {number}
   * @memberof DatasetView
   */
  dataset_view_uid: number;
  /**
   *
   * @type {Array<number>}
   * @memberof DatasetView
   */
  label_schema_uids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof DatasetView
   */
  name: string;
  /**
   *
   * @type {DatasetViewTypes}
   * @memberof DatasetView
   */
  view_type: DatasetViewTypes;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DatasetViewTypes = {
  SingleLlmResponseView: 'single_llm_response_view',
  RankingLlmResponsesView: 'ranking_llm_responses_view',
} as const;

export type DatasetViewTypes =
  (typeof DatasetViewTypes)[keyof typeof DatasetViewTypes];

/**
 *
 * @export
 * @interface DatasetViewUpdateParams
 */
export interface DatasetViewUpdateParams {
  /**
   *
   * @type {Array<number>}
   * @memberof DatasetViewUpdateParams
   */
  label_schema_uids?: Array<number>;
}
/**
 *
 * @export
 * @interface DatasourceAnalysisResponse
 */
export interface DatasourceAnalysisResponse {
  /**
   *
   * @type {boolean}
   * @memberof DatasourceAnalysisResponse
   */
  allow_generate_uid_col?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof DatasourceAnalysisResponse
   */
  column_map?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof DatasourceAnalysisResponse
   */
  datestamp: string;
  /**
   *
   * @type {Array<object>}
   * @memberof DatasourceAnalysisResponse
   */
  load_configs?: Array<object>;
  /**
   *
   * @type {Array<string>}
   * @memberof DatasourceAnalysisResponse
   */
  potential_uid_columns?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DatasourceAnalysisResponse
   */
  split: string;
  /**
   *
   * @type {Array<InputWarning>}
   * @memberof DatasourceAnalysisResponse
   */
  warnings?: Array<InputWarning>;
}
/**
 *
 * @export
 * @interface DatasourceMetadataBase
 */
export interface DatasourceMetadataBase {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof DatasourceMetadataBase
   */
  column_types?: { [key: string]: string };
  /**
   *
   * @type {Array<string>}
   * @memberof DatasourceMetadataBase
   */
  columns?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof DatasourceMetadataBase
   */
  migration_schema_version?: number;
  /**
   *
   * @type {number}
   * @memberof DatasourceMetadataBase
   */
  n_datapoints?: number;
  /**
   *
   * @type {number}
   * @memberof DatasourceMetadataBase
   */
  n_docs?: number;
  /**
   *
   * @type {number}
   * @memberof DatasourceMetadataBase
   */
  size_bytes?: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DatasourceRole = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type DatasourceRole =
  (typeof DatasourceRole)[keyof typeof DatasourceRole];

/**
 *
 * @export
 * @interface DeleteAnnotationsParams
 */
export interface DeleteAnnotationsParams {
  /**
   *
   * @type {Array<number>}
   * @memberof DeleteAnnotationsParams
   */
  annotation_uids: Array<number>;
}
/**
 *
 * @export
 * @interface DeleteApplicationResponse
 */
export interface DeleteApplicationResponse {
  /**
   *
   * @type {number}
   * @memberof DeleteApplicationResponse
   */
  application_uid: number;
  /**
   *
   * @type {string}
   * @memberof DeleteApplicationResponse
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface DeleteBatchParams
 */
export interface DeleteBatchParams {
  /**
   *
   * @type {Array<number>}
   * @memberof DeleteBatchParams
   */
  batch_uids: Array<number>;
}
/**
 *
 * @export
 * @interface DeleteDatasetAnnotationParams
 */
export interface DeleteDatasetAnnotationParams {
  /**
   *
   * @type {number}
   * @memberof DeleteDatasetAnnotationParams
   */
  annotation_uid: number;
  /**
   *
   * @type {number}
   * @memberof DeleteDatasetAnnotationParams
   */
  dataset_uid: number;
  /**
   *
   * @type {number}
   * @memberof DeleteDatasetAnnotationParams
   */
  label_schema_uid: number;
}
/**
 *
 * @export
 * @interface DeleteDatasetAnnotationsParams
 */
export interface DeleteDatasetAnnotationsParams {
  /**
   *
   * @type {Array<number>}
   * @memberof DeleteDatasetAnnotationsParams
   */
  annotation_uids: Array<number>;
  /**
   *
   * @type {number}
   * @memberof DeleteDatasetAnnotationsParams
   */
  dataset_uid: number;
  /**
   *
   * @type {number}
   * @memberof DeleteDatasetAnnotationsParams
   */
  label_schema_uid: number;
}
/**
 *
 * @export
 * @interface DeleteDatasetBatchesParams
 */
export interface DeleteDatasetBatchesParams {
  /**
   *
   * @type {Array<number>}
   * @memberof DeleteDatasetBatchesParams
   */
  batch_uids: Array<number>;
}
/**
 *
 * @export
 * @interface DeleteModelResponse
 */
export interface DeleteModelResponse {
  /**
   *
   * @type {string}
   * @memberof DeleteModelResponse
   */
  warning?: string;
}
/**
 *
 * @export
 * @interface DeleteNodePreprocessedDatasource
 */
export interface DeleteNodePreprocessedDatasource {
  /**
   *
   * @type {Array<number>}
   * @memberof DeleteNodePreprocessedDatasource
   */
  datasource_uids: Array<number>;
}
/**
 *
 * @export
 * @interface DeletePopulatorPayload
 */
export interface DeletePopulatorPayload {
  /**
   *
   * @type {string}
   * @memberof DeletePopulatorPayload
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DeletePopulatorPayload
   */
  path?: string;
}
/**
 *
 * @export
 * @interface DeleteSecretParams
 */
export interface DeleteSecretParams {
  /**
   *
   * @type {string}
   * @memberof DeleteSecretParams
   */
  key: string;
  /**
   *
   * @type {object}
   * @memberof DeleteSecretParams
   */
  kwargs?: object;
  /**
   *
   * @type {string}
   * @memberof DeleteSecretParams
   */
  secret_store?: string;
  /**
   *
   * @type {number}
   * @memberof DeleteSecretParams
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface DeleteUserSettingsRequest
 */
export interface DeleteUserSettingsRequest {
  /**
   *
   * @type {number}
   * @memberof DeleteUserSettingsRequest
   */
  application_uid?: number;
  /**
   *
   * @type {number}
   * @memberof DeleteUserSettingsRequest
   */
  dataset_batch_uid?: number;
  /**
   *
   * @type {number}
   * @memberof DeleteUserSettingsRequest
   */
  node_uid?: number;
  /**
   *
   * @type {number}
   * @memberof DeleteUserSettingsRequest
   */
  user_uid?: number;
}
/**
 *
 * @export
 * @interface DeploymentMetadata
 */
export interface DeploymentMetadata {
  /**
   *
   * @type {DeprecationInfo}
   * @memberof DeploymentMetadata
   */
  deprecation_info?: DeprecationInfo;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof DeploymentMetadata
   */
  model_classes?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof DeploymentMetadata
   */
  snorkelflow_version?: string;
}
/**
 * Used to serialize deployment storage information over the wire while maintaining class information
 * @export
 * @interface DeploymentStorageInfoConfig
 */
export interface DeploymentStorageInfoConfig {
  /**
   *
   * @type {string}
   * @memberof DeploymentStorageInfoConfig
   */
  cls_name: string;
  /**
   *
   * @type {object}
   * @memberof DeploymentStorageInfoConfig
   */
  kwargs: object;
}
/**
 *
 * @export
 * @interface DeprecationInfo
 */
export interface DeprecationInfo {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof DeprecationInfo
   */
  dependencies?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof DeprecationInfo
   */
  reason: string;
}
/**
 *
 * @export
 * @interface DevXBatchResponse
 */
export interface DevXBatchResponse {
  /**
   *
   * @type {number}
   * @memberof DevXBatchResponse
   */
  node_uid: number;
  /**
   *
   * @type {Array<string>}
   * @memberof DevXBatchResponse
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface DevXResponse
 */
export interface DevXResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof DevXResponse
   */
  dev_x_uids: Array<string>;
  /**
   *
   * @type {number}
   * @memberof DevXResponse
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface DevelopmentSettingsStep
 */
export interface DevelopmentSettingsStep {
  /**
   *
   * @type {boolean}
   * @memberof DevelopmentSettingsStep
   */
  compute_embeddings?: boolean;
  /**
   *
   * @type {string}
   * @memberof DevelopmentSettingsStep
   */
  embeddings_field?: string;
  /**
   *
   * @type {string}
   * @memberof DevelopmentSettingsStep
   */
  foundation_model?: string;
  /**
   *
   * @type {boolean}
   * @memberof DevelopmentSettingsStep
   */
  is_user_input_needed?: boolean;
  /**
   *
   * @type {SetupStepName}
   * @memberof DevelopmentSettingsStep
   */
  name?: SetupStepName;
  /**
   *
   * @type {string}
   * @memberof DevelopmentSettingsStep
   */
  primary_metric?: string;
  /**
   *
   * @type {boolean}
   * @memberof DevelopmentSettingsStep
   */
  run_base_foundation_model?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DevelopmentSettingsStep
   */
  run_smart_start_model?: boolean;
  /**
   *
   * @type {Array<SetupSetting>}
   * @memberof DevelopmentSettingsStep
   */
  settings?: Array<SetupSetting>;
  /**
   *
   * @type {Array<InputWarning>}
   * @memberof DevelopmentSettingsStep
   */
  warnings?: Array<InputWarning>;
}

/**
 *
 * @export
 * @interface DirectTrainingSetPayload
 */
export interface DirectTrainingSetPayload {
  /**
   *
   * @type {object}
   * @memberof DirectTrainingSetPayload
   */
  info?: object;
  /**
   *
   * @type {string}
   * @memberof DirectTrainingSetPayload
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof DirectTrainingSetPayload
   */
  pkg_uid?: number;
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof DirectTrainingSetPayload
   */
  probs: Array<Array<number>>;
  /**
   *
   * @type {Array<string>}
   * @memberof DirectTrainingSetPayload
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface DirectTrainingSetResponse
 */
export interface DirectTrainingSetResponse {
  /**
   *
   * @type {string}
   * @memberof DirectTrainingSetResponse
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof DirectTrainingSetResponse
   */
  training_set_uid: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DisplayType = {
  Default: 'default',
  Hidden: 'hidden',
  Advanced: 'advanced',
} as const;

export type DisplayType = (typeof DisplayType)[keyof typeof DisplayType];

/**
 *
 * @export
 * @interface DownloadRemoteObjectParams
 */
export interface DownloadRemoteObjectParams {
  /**
   *
   * @type {string}
   * @memberof DownloadRemoteObjectParams
   */
  access_key: string;
  /**
   *
   * @type {string}
   * @memberof DownloadRemoteObjectParams
   */
  bucket: string;
  /**
   *
   * @type {string}
   * @memberof DownloadRemoteObjectParams
   */
  obj: string;
  /**
   *
   * @type {string}
   * @memberof DownloadRemoteObjectParams
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof DownloadRemoteObjectParams
   */
  secret_key: string;
  /**
   *
   * @type {string}
   * @memberof DownloadRemoteObjectParams
   */
  url: string;
}
/**
 *
 * @export
 * @interface DuplicateApplicationMetadata
 */
export interface DuplicateApplicationMetadata {
  /**
   *
   * @type {ImportAssets}
   * @memberof DuplicateApplicationMetadata
   */
  assets_to_import?: ImportAssets;
  /**
   *
   * @type {Array<string>}
   * @memberof DuplicateApplicationMetadata
   */
  assignments_to_import?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DuplicateApplicationMetadata
   */
  context_gt_to_import?: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof DuplicateApplicationMetadata
   */
  datasource_uids_to_load?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof DuplicateApplicationMetadata
   */
  from_node_uid?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof DuplicateApplicationMetadata
   */
  gt_to_import?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof DuplicateApplicationMetadata
   */
  is_context_tag_type?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DuplicateApplicationMetadata
   */
  only_copy_committed_model?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof DuplicateApplicationMetadata
   */
  tag_types_to_import?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof DuplicateApplicationMetadata
   */
  transfer_dev_xs?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof DuplicateApplicationMetadata
   */
  updated_label_schema?: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof DuplicateApplicationMetadata
   */
  user_uid?: number;
}
/**
 * Information about an embedding node.  Attributes ---------- node_uid: int     The node_uid of the node that this embedding node is associated with.     When the embeddings originate from the new \"services.embeddings\" API,     this will be the same as the embeddings_uid. input_field: str     The name of the field that this embedding node is associated with. output_field: str     The name of the field that this embedding node will output. candidate_field: Optional[str]     The name of the field that this embedding node will use to find candidates. embeddings_uid: Optional[int]     The embeddings_uid of the embeddings from the \"services.embeddings\" API.
 * @export
 * @interface EmbeddingNodeInfo
 */
export interface EmbeddingNodeInfo {
  /**
   *
   * @type {string}
   * @memberof EmbeddingNodeInfo
   */
  candidate_field?: string;
  /**
   *
   * @type {number}
   * @memberof EmbeddingNodeInfo
   */
  embeddings_uid?: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingNodeInfo
   */
  input_field: string;
  /**
   *
   * @type {number}
   * @memberof EmbeddingNodeInfo
   */
  node_uid: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingNodeInfo
   */
  output_field: string;
}
/**
 *
 * @export
 * @interface EmbeddingsInfo
 */
export interface EmbeddingsInfo {
  /**
   *
   * @type {object}
   * @memberof EmbeddingsInfo
   */
  config: object;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsInfo
   */
  created_at: string;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsInfo
   */
  dataset_computed_at?: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsInfo
   */
  dataset_embeddings_path?: string;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsInfo
   */
  dataset_job_elapsed_time?: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsInfo
   */
  dataset_job_uid?: string;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsInfo
   */
  embeddings_uid: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsInfo
   */
  field: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsInfo
   */
  model_dirpath: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsInfo
   */
  model_type: string;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsInfo
   */
  node_uid: number;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsInfo
   */
  training_set_sample_computed_at?: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsInfo
   */
  training_set_sample_embeddings_path?: string;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsInfo
   */
  training_set_sample_job_elapsed_time?: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsInfo
   */
  training_set_sample_job_uid?: string;
}
/**
 *
 * @export
 * @interface EmbeddingsInputFieldInfo
 */
export interface EmbeddingsInputFieldInfo {
  /**
   *
   * @type {string}
   * @memberof EmbeddingsInputFieldInfo
   */
  field_name: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsInputFieldInfo
   */
  field_type: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const EmbeddingsState = {
  Pending: 'pending',
  Running: 'running',
  Ready: 'ready',
  Failed: 'failed',
  Cancelled: 'cancelled',
} as const;

export type EmbeddingsState =
  (typeof EmbeddingsState)[keyof typeof EmbeddingsState];

/**
 * Status of an embeddings job.  Attributes ---------- node_uid : int     The node UID. embeddings_uid : int     The embeddings UID. created_at : float     The time the job was created. field : str     The field name. output_field : str     The output field name. model_type : str     The model type. dataset_embeddings_job_uid : str, optional     The job UID for the dataset embeddings job. None if the job has not started. dataset_embeddings_job_progress : int     The progress of the dataset embeddings job. dataset_embeddings_state : EmbeddingsState     The state of the dataset embeddings job. dataset_embeddings_path : str     The path of the dataset embeddings dataframe. training_set_sample_embeddings_job_uid : str, optional     The job UID for the training set sample embeddings job.  None if the job has not started. training_set_sample_embeddings_job_progress : int     The progress of the training set sample embeddings job. training_set_sample_embeddings_state : EmbeddingsState     The state of the training set sample embeddings job. training_set_sample_embeddings_path : str     The path of the training set sample embeddings dataframe.
 * @export
 * @interface EmbeddingsStatus
 */
export interface EmbeddingsStatus {
  /**
   *
   * @type {number}
   * @memberof EmbeddingsStatus
   */
  created_at: number;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsStatus
   */
  dataset_embeddings_job_elapsed_time?: number;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsStatus
   */
  dataset_embeddings_job_progress: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsStatus
   */
  dataset_embeddings_job_uid?: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsStatus
   */
  dataset_embeddings_path?: string;
  /**
   *
   * @type {EmbeddingsState}
   * @memberof EmbeddingsStatus
   */
  dataset_embeddings_state: EmbeddingsState;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsStatus
   */
  embeddings_uid: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsStatus
   */
  field: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsStatus
   */
  model_type: string;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsStatus
   */
  node_uid: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsStatus
   */
  output_field: string;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsStatus
   */
  training_set_sample_embeddings_job_elapsed_time?: number;
  /**
   *
   * @type {number}
   * @memberof EmbeddingsStatus
   */
  training_set_sample_embeddings_job_progress: number;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsStatus
   */
  training_set_sample_embeddings_job_uid?: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddingsStatus
   */
  training_set_sample_embeddings_path?: string;
  /**
   *
   * @type {EmbeddingsState}
   * @memberof EmbeddingsStatus
   */
  training_set_sample_embeddings_state: EmbeddingsState;
}

/**
 *
 * @export
 * @interface EvalReportDataResponse
 */
export interface EvalReportDataResponse {
  /**
   *
   * @type {number}
   * @memberof EvalReportDataResponse
   */
  count: number;
  /**
   *
   * @type {Array<object>}
   * @memberof EvalReportDataResponse
   */
  data: Array<object>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof EvalReportDataResponse
   */
  field_docstrings: { [key: string]: string };
  /**
   *
   * @type {Array<string>}
   * @memberof EvalReportDataResponse
   */
  field_types: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof EvalReportDataResponse
   */
  fields: Array<string>;
  /**
   *
   * @type {string}
   * @memberof EvalReportDataResponse
   */
  gt_field?: string;
  /**
   *
   * @type {number}
   * @memberof EvalReportDataResponse
   */
  offset: number;
  /**
   *
   * @type {string}
   * @memberof EvalReportDataResponse
   */
  rich_doc_field: string;
  /**
   *
   * @type {string}
   * @memberof EvalReportDataResponse
   */
  suggest_field?: string;
  /**
   *
   * @type {number}
   * @memberof EvalReportDataResponse
   */
  total_count: number;
  /**
   *
   * @type {Array<string>}
   * @memberof EvalReportDataResponse
   */
  total_index: Array<string>;
  /**
   *
   * @type {string}
   * @memberof EvalReportDataResponse
   */
  uid_field: string;
}
/**
 *
 * @export
 * @interface EvaluationReportCreationRequest
 */
export interface EvaluationReportCreationRequest {
  /**
   *
   * @type {string}
   * @memberof EvaluationReportCreationRequest
   */
  additional_notes?: string;
  /**
   *
   * @type {number}
   * @memberof EvaluationReportCreationRequest
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof EvaluationReportCreationRequest
   */
  job_uid: string;
  /**
   *
   * @type {{ [key: string]: MetricResponse; }}
   * @memberof EvaluationReportCreationRequest
   */
  metrics: { [key: string]: MetricResponse };
  /**
   *
   * @type {{ [key: string]: ModelResponse; }}
   * @memberof EvaluationReportCreationRequest
   */
  models: { [key: string]: ModelResponse };
  /**
   *
   * @type {{ [key: string]: { [key: string]: { [key: string]: { [key: string]: number; }; }; }; }}
   * @memberof EvaluationReportCreationRequest
   */
  scores: {
    [key: string]: {
      [key: string]: { [key: string]: { [key: string]: number } };
    };
  };
  /**
   *
   * @type {{ [key: string]: SliceResponse; }}
   * @memberof EvaluationReportCreationRequest
   */
  slices: { [key: string]: SliceResponse };
}
/**
 *
 * @export
 * @interface EvaluationReportResponse
 */
export interface EvaluationReportResponse {
  /**
   *
   * @type {string}
   * @memberof EvaluationReportResponse
   */
  additional_notes?: string;
  /**
   *
   * @type {string}
   * @memberof EvaluationReportResponse
   */
  created_at: string;
  /**
   *
   * @type {number}
   * @memberof EvaluationReportResponse
   */
  dataset_uid: number;
  /**
   *
   * @type {number}
   * @memberof EvaluationReportResponse
   */
  evaluation_report_uid: number;
  /**
   *
   * @type {string}
   * @memberof EvaluationReportResponse
   */
  job_uid: string;
  /**
   *
   * @type {{ [key: string]: MetricResponse; }}
   * @memberof EvaluationReportResponse
   */
  metrics: { [key: string]: MetricResponse };
  /**
   *
   * @type {{ [key: string]: ModelResponse; }}
   * @memberof EvaluationReportResponse
   */
  models: { [key: string]: ModelResponse };
  /**
   *
   * @type {{ [key: string]: { [key: string]: { [key: string]: { [key: string]: number; }; }; }; }}
   * @memberof EvaluationReportResponse
   */
  scores: {
    [key: string]: {
      [key: string]: { [key: string]: { [key: string]: number } };
    };
  };
  /**
   *
   * @type {{ [key: string]: SliceResponse; }}
   * @memberof EvaluationReportResponse
   */
  slices: { [key: string]: SliceResponse };
  /**
   *
   * @type {number}
   * @memberof EvaluationReportResponse
   */
  user_uid: number;
}
/**
 *
 * @export
 * @interface EvaluationRequestParams
 */
export interface EvaluationRequestParams {
  /**
   *
   * @type {Array<number>}
   * @memberof EvaluationRequestParams
   */
  model_uids?: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof EvaluationRequestParams
   */
  serialized_metric_schema_list: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof EvaluationRequestParams
   */
  slice_uids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof EvaluationRequestParams
   */
  split?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const EventType = {
  Create: 'create',
  Read: 'read',
  Update: 'update',
  Delete: 'delete',
  Export: 'export',
} as const;

export type EventType = (typeof EventType)[keyof typeof EventType];

/**
 *
 * @export
 * @interface ExecuteGraphOnDataRequest
 */
export interface ExecuteGraphOnDataRequest {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof ExecuteGraphOnDataRequest
   */
  dfs_json?: { [key: string]: any };
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ExecuteGraphOnDataRequest
   */
  serialized_dfs?: { [key: string]: string };
  /**
   *
   * @type {Array<number>}
   * @memberof ExecuteGraphOnDataRequest
   */
  target_uids?: Array<number>;
}
/**
 *
 * @export
 * @interface ExecuteGraphOnDataResponse
 */
export interface ExecuteGraphOnDataResponse {
  /**
   *
   * @type {number}
   * @memberof ExecuteGraphOnDataResponse
   */
  application_uid: number;
  /**
   *
   * @type {string}
   * @memberof ExecuteGraphOnDataResponse
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface ExecuteOperatorsParams
 */
export interface ExecuteOperatorsParams {
  /**
   *
   * @type {boolean}
   * @memberof ExecuteOperatorsParams
   */
  pickle_output?: boolean;
  /**
   *
   * @type {Array<object>}
   * @memberof ExecuteOperatorsParams
   */
  processor_config?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof ExecuteOperatorsParams
   */
  serialized_df: string;
  /**
   *
   * @type {{ [key: string]: OperatorConfig; }}
   * @memberof ExecuteOperatorsParams
   */
  workflow_config?: { [key: string]: OperatorConfig };
  /**
   *
   * @type {number}
   * @memberof ExecuteOperatorsParams
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface ExecuteWorkflowFileParams
 */
export interface ExecuteWorkflowFileParams {
  /**
   *
   * @type {string}
   * @memberof ExecuteWorkflowFileParams
   */
  input_file: string;
  /**
   *
   * @type {string}
   * @memberof ExecuteWorkflowFileParams
   */
  input_file_format?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExecuteWorkflowFileParams
   */
  monitor?: boolean;
}
/**
 *
 * @export
 * @interface ExecuteWorkflowJSONParams
 */
export interface ExecuteWorkflowJSONParams {
  /**
   *
   * @type {Array<object>}
   * @memberof ExecuteWorkflowJSONParams
   */
  input_df_json: Array<object>;
  /**
   *
   * @type {boolean}
   * @memberof ExecuteWorkflowJSONParams
   */
  monitor?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExecuteWorkflowJSONParams
   */
  serialize_output?: boolean;
}
/**
 *
 * @export
 * @interface ExpireInviteParams
 */
export interface ExpireInviteParams {
  /**
   *
   * @type {string}
   * @memberof ExpireInviteParams
   */
  invite_link: string;
}
/**
 * See https://www.mlflow.org/docs/latest/tracking.html#logging-to-a-tracking-server for more information on these arguments
 * @export
 * @interface ExportMLflowModel
 */
export interface ExportMLflowModel {
  /**
   *
   * @type {RegistryStorageCredentials}
   * @memberof ExportMLflowModel
   */
  artifact_storage_access_config?: RegistryStorageCredentials;
  /**
   *
   * @type {RegistryAuthenticationCredentials}
   * @memberof ExportMLflowModel
   */
  auth_headers?: RegistryAuthenticationCredentials;
  /**
   *
   * @type {string}
   * @memberof ExportMLflowModel
   */
  experiment_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExportMLflowModel
   */
  insecure_tls?: boolean;
  /**
   *
   * @type {string}
   * @memberof ExportMLflowModel
   */
  model_name?: string;
  /**
   *
   * @type {string}
   * @memberof ExportMLflowModel
   */
  tracking_server_uri: string;
}
/**
 *
 * @export
 * @interface ExportWorkflowParams
 */
export interface ExportWorkflowParams {
  /**
   *
   * @type {boolean}
   * @memberof ExportWorkflowParams
   */
  include_postprocessors?: boolean;
  /**
   *
   * @type {PackageType}
   * @memberof ExportWorkflowParams
   */
  package_type?: PackageType;
  /**
   *
   * @type {boolean}
   * @memberof ExportWorkflowParams
   */
  preview?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExportWorkflowParams
   */
  prune_operators?: boolean;
  /**
   *
   * @type {WorkflowEnv}
   * @memberof ExportWorkflowParams
   */
  workflow_env?: WorkflowEnv;
}

/**
 *
 * @export
 * @interface ExternalLLMConfig
 */
export interface ExternalLLMConfig {
  /**
   *
   * @type {object}
   * @memberof ExternalLLMConfig
   */
  config?: object;
  /**
   *
   * @type {string}
   * @memberof ExternalLLMConfig
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof ExternalLLMConfig
   */
  external_llm_config_uid?: number;
  /**
   *
   * @type {string}
   * @memberof ExternalLLMConfig
   */
  model_name: string;
  /**
   *
   * @type {ExternalLLMProvider}
   * @memberof ExternalLLMConfig
   */
  model_provider: ExternalLLMProvider;
  /**
   *
   * @type {number}
   * @memberof ExternalLLMConfig
   */
  workspace_uid: number;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ExternalLLMProvider = {
  AzureMl: 'azure_ml',
  AzureOpenai: 'azure_openai',
  Huggingface: 'huggingface',
  Openai: 'openai',
  VertexaiLm: 'vertexai_lm',
  Sagemaker: 'sagemaker',
  LocalInferenceService: 'local_inference_service',
  CustomInferenceService: 'custom_inference_service',
} as const;

export type ExternalLLMProvider =
  (typeof ExternalLLMProvider)[keyof typeof ExternalLLMProvider];

/**
 *
 * @export
 * @interface ExternalModelPreferences
 */
export interface ExternalModelPreferences {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ExternalModelPreferences
   */
  model_to_endpoint_map?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface FMSecretsConfig
 */
export interface FMSecretsConfig {
  /**
   *
   * @type {FmSecretsConfig}
   * @memberof FMSecretsConfig
   */
  fm_secrets_config: FmSecretsConfig;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const FMType = {
  Text2text: 'text2text',
  Qa: 'qa',
  Docvqa: 'docvqa',
} as const;

export type FMType = (typeof FMType)[keyof typeof FMType];

/**
 *
 * @export
 * @interface Feature
 */
export interface Feature {}
/**
 *
 * @export
 * @interface FeatureAccessRole
 */
export interface FeatureAccessRole {
  /**
   *
   * @type {number}
   * @memberof FeatureAccessRole
   */
  mapping_uid: number;
  /**
   *
   * @type {Array<UserRole>}
   * @memberof FeatureAccessRole
   */
  user_roles: Array<UserRole>;
  /**
   *
   * @type {number}
   * @memberof FeatureAccessRole
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface FeatureAccessRoleCreationRequest
 */
export interface FeatureAccessRoleCreationRequest {
  /**
   *
   * @type {Array<UserRole>}
   * @memberof FeatureAccessRoleCreationRequest
   */
  user_roles: Array<UserRole>;
  /**
   *
   * @type {number}
   * @memberof FeatureAccessRoleCreationRequest
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface FeatureAccessRoleUpdateParams
 */
export interface FeatureAccessRoleUpdateParams {
  /**
   *
   * @type {Array<UserRole>}
   * @memberof FeatureAccessRoleUpdateParams
   */
  new_user_roles: Array<UserRole>;
  /**
   *
   * @type {number}
   * @memberof FeatureAccessRoleUpdateParams
   */
  new_workspace_uid?: number;
}
/**
 *
 * @export
 * @interface FeaturizerColumnsArgs
 */
export interface FeaturizerColumnsArgs {
  /**
   *
   * @type {object}
   * @memberof FeaturizerColumnsArgs
   */
  op_config: object;
  /**
   *
   * @type {string}
   * @memberof FeaturizerColumnsArgs
   */
  op_type: string;
  /**
   *
   * @type {number}
   * @memberof FeaturizerColumnsArgs
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface FetchDatasetColumnTypesResponse
 */
export interface FetchDatasetColumnTypesResponse {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof FetchDatasetColumnTypesResponse
   */
  column_types: { [key: string]: string };
}
/**
 *
 * @export
 * @interface FetchLFLabels
 */
export interface FetchLFLabels {
  /**
   *
   * @type {Array<number>}
   * @memberof FetchLFLabels
   */
  lf_uids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof FetchLFLabels
   */
  split?: string;
  /**
   *
   * @type {boolean}
   * @memberof FetchLFLabels
   */
  user_formatted?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof FetchLFLabels
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface FetchModelPredictions
 */
export interface FetchModelPredictions {
  /**
   *
   * @type {boolean}
   * @memberof FetchModelPredictions
   */
  apply_postprocessors?: boolean;
  /**
   *
   * @type {string}
   * @memberof FetchModelPredictions
   */
  end_date?: string;
  /**
   *
   * @type {boolean}
   * @memberof FetchModelPredictions
   */
  is_context?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FetchModelPredictions
   */
  return_confidences?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FetchModelPredictions
   */
  return_labels?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FetchModelPredictions
   */
  return_probs?: boolean;
  /**
   *
   * @type {string}
   * @memberof FetchModelPredictions
   */
  split?: string;
  /**
   *
   * @type {string}
   * @memberof FetchModelPredictions
   */
  start_date?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FetchModelPredictions
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface FetchModelPredictionsResponse
 */
export interface FetchModelPredictionsResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof FetchModelPredictionsResponse
   */
  model_predictions: Array<object>;
}
/**
 *
 * @export
 * @interface FetchModelResponse
 */
export interface FetchModelResponse {
  /**
   *
   * @type {object}
   * @memberof FetchModelResponse
   */
  model?: object;
}
/**
 *
 * @export
 * @interface FetchNodeResponse
 */
export interface FetchNodeResponse {
  /**
   *
   * @type {number}
   * @memberof FetchNodeResponse
   */
  application_uid?: number;
  /**
   *
   * @type {number}
   * @memberof FetchNodeResponse
   */
  committed_op_version_uid?: number;
  /**
   *
   * @type {string}
   * @memberof FetchNodeResponse
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof FetchNodeResponse
   */
  dataset_uid?: number;
  /**
   *
   * @type {string}
   * @memberof FetchNodeResponse
   */
  expected_op_type?: string;
  /**
   *
   * @type {LabelSchema}
   * @memberof FetchNodeResponse
   */
  label_schema?: LabelSchema;
  /**
   *
   * @type {string}
   * @memberof FetchNodeResponse
   */
  node_cls: string;
  /**
   *
   * @type {object}
   * @memberof FetchNodeResponse
   */
  node_config: object;
  /**
   *
   * @type {number}
   * @memberof FetchNodeResponse
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface FetchTrainingSetLabels
 */
export interface FetchTrainingSetLabels {
  /**
   *
   * @type {boolean}
   * @memberof FetchTrainingSetLabels
   */
  discretize_labels?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FetchTrainingSetLabels
   */
  filter_uncertain_labels?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FetchTrainingSetLabels
   */
  filter_unlabeled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FetchTrainingSetLabels
   */
  load_ground_truth?: boolean;
  /**
   *
   * @type {string}
   * @memberof FetchTrainingSetLabels
   */
  split?: string;
  /**
   *
   * @type {string}
   * @memberof FetchTrainingSetLabels
   */
  tie_break_policy?: string;
  /**
   *
   * @type {boolean}
   * @memberof FetchTrainingSetLabels
   */
  user_label?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof FetchTrainingSetLabels
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface FetchedDatasetAnnotation
 */
export interface FetchedDatasetAnnotation {
  /**
   *
   * @type {number}
   * @memberof FetchedDatasetAnnotation
   */
  annotation_uid: number;
  /**
   *
   * @type {number}
   * @memberof FetchedDatasetAnnotation
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof FetchedDatasetAnnotation
   */
  freeform_text?: string;
  /**
   *
   * @type {any}
   * @memberof FetchedDatasetAnnotation
   */
  label?: any;
  /**
   *
   * @type {LabelSchema}
   * @memberof FetchedDatasetAnnotation
   */
  label_schema: LabelSchema;
  /**
   *
   * @type {object}
   * @memberof FetchedDatasetAnnotation
   */
  metadata?: object;
  /**
   *
   * @type {SvcSource}
   * @memberof FetchedDatasetAnnotation
   */
  source: SvcSource;
  /**
   *
   * @type {string}
   * @memberof FetchedDatasetAnnotation
   */
  split?: string;
  /**
   *
   * @type {string}
   * @memberof FetchedDatasetAnnotation
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof FetchedDatasetAnnotation
   */
  ts: string;
  /**
   *
   * @type {string}
   * @memberof FetchedDatasetAnnotation
   */
  x_uid: string;
}
/**
 * A wrapper around data returned to the FE to render a Field Filter options.
 * @export
 * @interface FieldFilterStructureModel
 */
export interface FieldFilterStructureModel {
  /**
   *
   * @type {string}
   * @memberof FieldFilterStructureModel
   */
  description: string;
  /**
   *
   * @type {Array<FieldsModel>}
   * @memberof FieldFilterStructureModel
   */
  fields: Array<FieldsModel>;
  /**
   *
   * @type {string}
   * @memberof FieldFilterStructureModel
   */
  filter_type: string;
  /**
   *
   * @type {string}
   * @memberof FieldFilterStructureModel
   */
  name: string;
}
/**
 *
 * @export
 * @interface FieldsModel
 */
export interface FieldsModel {
  /**
   *
   * @type {string}
   * @memberof FieldsModel
   */
  field_name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FieldsModel
   */
  operators: Array<string>;
}
/**
 *
 * @export
 * @interface FileStorageConfig
 */
export interface FileStorageConfig {
  /**
   *
   * @type {string}
   * @memberof FileStorageConfig
   */
  base_path: string;
  /**
   *
   * @type {number}
   * @memberof FileStorageConfig
   */
  file_storage_config_uid: number;
  /**
   *
   * @type {boolean}
   * @memberof FileStorageConfig
   */
  is_default: boolean;
  /**
   *
   * @type {string}
   * @memberof FileStorageConfig
   */
  name: string;
}
/**
 *
 * @export
 * @interface FileStorageConfigCreate
 */
export interface FileStorageConfigCreate {
  /**
   *
   * @type {string}
   * @memberof FileStorageConfigCreate
   */
  base_path: string;
  /**
   *
   * @type {string}
   * @memberof FileStorageConfigCreate
   */
  name: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const FineTuningColumnType = {
  Instruction: 'instruction',
  Context: 'context',
  Response: 'response',
  PromptPrefix: 'prompt_prefix',
} as const;

export type FineTuningColumnType =
  (typeof FineTuningColumnType)[keyof typeof FineTuningColumnType];

/**
 *
 * @export
 * @interface FinetuneInferenceRequest
 */
export interface FinetuneInferenceRequest {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof FinetuneInferenceRequest
   */
  column_mapping?: { [key: string]: string };
  /**
   *
   * @type {Array<number>}
   * @memberof FinetuneInferenceRequest
   */
  datasource_uids: Array<number>;
  /**
   *
   * @type {object}
   * @memberof FinetuneInferenceRequest
   */
  deployment_configs: object;
  /**
   *
   * @type {string}
   * @memberof FinetuneInferenceRequest
   */
  external_model_name: string;
  /**
   *
   * @type {string}
   * @memberof FinetuneInferenceRequest
   */
  finetuning_provider_type: string;
  /**
   *
   * @type {object}
   * @memberof FinetuneInferenceRequest
   */
  generation_config: object;
  /**
   *
   * @type {string}
   * @memberof FinetuneInferenceRequest
   */
  prompt_template?: string;
  /**
   *
   * @type {number}
   * @memberof FinetuneInferenceRequest
   */
  workspace_uid?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof FinetuneInferenceRequest
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface FinetuneInferenceResponse
 */
export interface FinetuneInferenceResponse {
  /**
   *
   * @type {string}
   * @memberof FinetuneInferenceResponse
   */
  job_id?: string;
}
/**
 *
 * @export
 * @interface FinetuneModelRequest
 */
export interface FinetuneModelRequest {
  /**
   *
   * @type {string}
   * @memberof FinetuneModelRequest
   */
  base_model_id: string;
  /**
   *
   * @type {string}
   * @memberof FinetuneModelRequest
   */
  base_model_version: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof FinetuneModelRequest
   */
  column_mapping?: { [key: string]: string };
  /**
   *
   * @type {Array<number>}
   * @memberof FinetuneModelRequest
   */
  datasource_uids: Array<number>;
  /**
   *
   * @type {object}
   * @memberof FinetuneModelRequest
   */
  finetuning_configs: object;
  /**
   *
   * @type {string}
   * @memberof FinetuneModelRequest
   */
  finetuning_provider_type: string;
  /**
   *
   * @type {object}
   * @memberof FinetuneModelRequest
   */
  training_configs?: object;
  /**
   *
   * @type {number}
   * @memberof FinetuneModelRequest
   */
  workspace_uid?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof FinetuneModelRequest
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface FinetuneModelResponse
 */
export interface FinetuneModelResponse {
  /**
   *
   * @type {string}
   * @memberof FinetuneModelResponse
   */
  job_id?: string;
}
/**
 *
 * @export
 * @interface FitNodePayload
 */
export interface FitNodePayload {
  /**
   *
   * @type {object}
   * @memberof FitNodePayload
   */
  fit_config: object;
  /**
   *
   * @type {string}
   * @memberof FitNodePayload
   */
  op_type: string;
}
/**
 *
 * @export
 * @interface FlatGroundTruthFileModel
 */
export interface FlatGroundTruthFileModel {
  /**
   *
   * @type {SingleDataSourceIngestionRequest}
   * @memberof FlatGroundTruthFileModel
   */
  datasource: SingleDataSourceIngestionRequest;
}
/**
 *
 * @export
 * @interface FlatGroundTruthModel
 */
export interface FlatGroundTruthModel {
  /**
   *
   * @type {boolean}
   * @memberof FlatGroundTruthModel
   */
  align_labels?: boolean;
  /**
   *
   * @type {FlatGroundTruthFileModel}
   * @memberof FlatGroundTruthModel
   */
  file?: FlatGroundTruthFileModel;
  /**
   *
   * @type {FlatGroundTruthRawModel}
   * @memberof FlatGroundTruthModel
   */
  raw?: FlatGroundTruthRawModel;
  /**
   *
   * @type {boolean}
   * @memberof FlatGroundTruthModel
   */
  run_async?: boolean;
}
/**
 *
 * @export
 * @interface FlatGroundTruthRawModel
 */
export interface FlatGroundTruthRawModel {
  /**
   *
   * @type {object}
   * @memberof FlatGroundTruthRawModel
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof FlatGroundTruthRawModel
   */
  serialized_df: string;
}
/**
 * @type FmSecretsConfig
 * @export
 */
export type FmSecretsConfig =
  | ({ provider: 'azure_ml' } & AzureMLSecretsConfig)
  | ({ provider: 'azure_openai' } & AzureOpenAISecretsConfig)
  | ({
      provider: 'custom_inference_service';
    } & CustomInferenceServiceSecretsConfig)
  | ({ provider: 'huggingface' } & HuggingFaceSecretsConfig)
  | ({ provider: 'openai' } & OpenAISecretsConfig)
  | ({ provider: 'sagemaker' } & SagemakerSecretsConfig)
  | ({ provider: 'vertexai_lm' } & VertexAISecretsConfig);

/**
 *
 * @export
 * @interface GCSUploadCredentials
 */
export interface GCSUploadCredentials {
  /**
   *
   * @type {object}
   * @memberof GCSUploadCredentials
   */
  token: object;
}
/**
 *
 * @export
 * @interface GTStratificationParams
 */
export interface GTStratificationParams {
  /**
   *
   * @type {string}
   * @memberof GTStratificationParams
   */
  gt_col?: string;
  /**
   *
   * @type {any}
   * @memberof GTStratificationParams
   */
  unknown_gt_value?: any;
}
/**
 *
 * @export
 * @interface GarbageCollectDatasetApplicationParams
 */
export interface GarbageCollectDatasetApplicationParams {
  /**
   *
   * @type {number}
   * @memberof GarbageCollectDatasetApplicationParams
   */
  application_uid?: number;
  /**
   *
   * @type {number}
   * @memberof GarbageCollectDatasetApplicationParams
   */
  dataset_uid?: number;
  /**
   *
   * @type {number}
   * @memberof GarbageCollectDatasetApplicationParams
   */
  repeat_period?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof GarbageCollectDatasetApplicationParams
   */
  workspace_uids?: Array<number>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const GeneralizationInfoType = {
  Up: 'up',
  Down: 'down',
  Warning: 'warning',
} as const;

export type GeneralizationInfoType =
  (typeof GeneralizationInfoType)[keyof typeof GeneralizationInfoType];

/**
 *
 * @export
 * @interface GeneralizationMessage
 */
export interface GeneralizationMessage {
  /**
   *
   * @type {GeneralizationInfoType}
   * @memberof GeneralizationMessage
   */
  icon: GeneralizationInfoType;
  /**
   *
   * @type {string}
   * @memberof GeneralizationMessage
   */
  msg: string;
}

/**
 *
 * @export
 * @interface GetAccessTokenRequest
 */
export interface GetAccessTokenRequest {
  /**
   *
   * @type {string}
   * @memberof GetAccessTokenRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof GetAccessTokenRequest
   */
  username: string;
}
/**
 *
 * @export
 * @interface GetAnnotationMapParams
 */
export interface GetAnnotationMapParams {
  /**
   *
   * @type {Array<string>}
   * @memberof GetAnnotationMapParams
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface GetAnnotationMapResponse
 */
export interface GetAnnotationMapResponse {
  /**
   *
   * @type {object}
   * @memberof GetAnnotationMapResponse
   */
  annotation_map: object;
}
/**
 *
 * @export
 * @interface GetAnnotationRateResponse
 */
export interface GetAnnotationRateResponse {
  /**
   *
   * @type {AnnotationRate}
   * @memberof GetAnnotationRateResponse
   */
  annotation_rate: AnnotationRate;
  /**
   *
   * @type {number}
   * @memberof GetAnnotationRateResponse
   */
  dataset_uid: number;
}
/**
 *
 * @export
 * @interface GetAnnotationsByXUidResponse
 */
export interface GetAnnotationsByXUidResponse {
  /**
   *
   * @type {Array<LabelSchemaAnnotatorSummary>}
   * @memberof GetAnnotationsByXUidResponse
   */
  aggregated_annotators_per_label_schema: Array<LabelSchemaAnnotatorSummary>;
  /**
   *
   * @type {string}
   * @memberof GetAnnotationsByXUidResponse
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface GetAnnotationsOverview
 */
export interface GetAnnotationsOverview {
  /**
   *
   * @type {AnnotationRate}
   * @memberof GetAnnotationsOverview
   */
  annotation_rate: AnnotationRate;
  /**
   *
   * @type {Array<AnnotatorOverviewStats>}
   * @memberof GetAnnotationsOverview
   */
  annotators: Array<AnnotatorOverviewStats>;
  /**
   *
   * @type {CompletionStatus}
   * @memberof GetAnnotationsOverview
   */
  completion_status: CompletionStatus;
  /**
   *
   * @type {Array<LabelStats>}
   * @memberof GetAnnotationsOverview
   */
  label_distribution: Array<LabelStats>;
  /**
   *
   * @type {number}
   * @memberof GetAnnotationsOverview
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface GetAnnotationsRate
 */
export interface GetAnnotationsRate {
  /**
   *
   * @type {AnnotationRate}
   * @memberof GetAnnotationsRate
   */
  annotation_rate: AnnotationRate;
  /**
   *
   * @type {number}
   * @memberof GetAnnotationsRate
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface GetAnnotationsResponse
 */
export interface GetAnnotationsResponse {
  /**
   *
   * @type {Array<Annotation>}
   * @memberof GetAnnotationsResponse
   */
  annotations: Array<Annotation>;
  /**
   *
   * @type {Array<AnnotationBatchMetadata>}
   * @memberof GetAnnotationsResponse
   */
  batch_metadata?: Array<AnnotationBatchMetadata>;
  /**
   *
   * @type {Array<Source>}
   * @memberof GetAnnotationsResponse
   */
  sources: Array<Source>;
}
/**
 *
 * @export
 * @interface GetAuditLogsResponse
 */
export interface GetAuditLogsResponse {
  /**
   *
   * @type {Array<AuditEvent>}
   * @memberof GetAuditLogsResponse
   */
  events: Array<AuditEvent>;
  /**
   *
   * @type {number}
   * @memberof GetAuditLogsResponse
   */
  last_id: number;
}
/**
 *
 * @export
 * @interface GetBatchMapElement
 */
export interface GetBatchMapElement {
  /**
   *
   * @type {Array<Batch>}
   * @memberof GetBatchMapElement
   */
  batches: Array<Batch>;
  /**
   *
   * @type {string}
   * @memberof GetBatchMapElement
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface GetBatchMapRequest
 */
export interface GetBatchMapRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof GetBatchMapRequest
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface GetBatchesResponse
 */
export interface GetBatchesResponse {
  /**
   *
   * @type {Array<HydratedBatch>}
   * @memberof GetBatchesResponse
   */
  batches: Array<HydratedBatch>;
}
/**
 *
 * @export
 * @interface GetClarityMatrixResponse
 */
export interface GetClarityMatrixResponse {
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof GetClarityMatrixResponse
   */
  matrix: Array<Array<number>>;
  /**
   *
   * @type {Array<SuggestionModel>}
   * @memberof GetClarityMatrixResponse
   */
  suggestions: Array<SuggestionModel>;
}
/**
 *
 * @export
 * @interface GetCommentMapReq
 */
export interface GetCommentMapReq {
  /**
   *
   * @type {Array<string>}
   * @memberof GetCommentMapReq
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface GetCommentsByXuidParams
 */
export interface GetCommentsByXuidParams {
  /**
   *
   * @type {number}
   * @memberof GetCommentsByXuidParams
   */
  dataset_uid: number;
  /**
   *
   * @type {Array<string>}
   * @memberof GetCommentsByXuidParams
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface GetCurrentUserResponse
 */
export interface GetCurrentUserResponse {
  /**
   *
   * @type {UserView}
   * @memberof GetCurrentUserResponse
   */
  default_view: UserView;
  /**
   *
   * @type {UserRole}
   * @memberof GetCurrentUserResponse
   */
  role?: UserRole;
  /**
   *
   * @type {string}
   * @memberof GetCurrentUserResponse
   */
  timezone?: string;
  /**
   *
   * @type {Array<UserRole>}
   * @memberof GetCurrentUserResponse
   */
  user_roles: Array<UserRole>;
  /**
   *
   * @type {number}
   * @memberof GetCurrentUserResponse
   */
  user_uid: number;
  /**
   *
   * @type {string}
   * @memberof GetCurrentUserResponse
   */
  username: string;
}

/**
 *
 * @export
 * @interface GetDataSourcesResponseModel
 */
export interface GetDataSourcesResponseModel {
  /**
   *
   * @type {Array<DataSourceWithDatasetUid>}
   * @memberof GetDataSourcesResponseModel
   */
  data_sources: Array<DataSourceWithDatasetUid>;
}
/**
 *
 * @export
 * @interface GetDatasetAnnotationsResponse
 */
export interface GetDatasetAnnotationsResponse {
  /**
   *
   * @type {Array<LabelSchemaAnnotations>}
   * @memberof GetDatasetAnnotationsResponse
   */
  annotations_grouped_by_label_schema: Array<LabelSchemaAnnotations>;
}
/**
 *
 * @export
 * @interface GetDatasetDatapointsResponse
 */
export interface GetDatasetDatapointsResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof GetDatasetDatapointsResponse
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface GetEmbeddingsResponse
 */
export interface GetEmbeddingsResponse {
  /**
   *
   * @type {Array<EmbeddingsStatus>}
   * @memberof GetEmbeddingsResponse
   */
  embeddings: Array<EmbeddingsStatus>;
}
/**
 *
 * @export
 * @interface GetInvitesResponse
 */
export interface GetInvitesResponse {
  /**
   *
   * @type {Array<InviteResponse>}
   * @memberof GetInvitesResponse
   */
  invites: Array<InviteResponse>;
}
/**
 *
 * @export
 * @interface GetLFTemplatesResponse
 */
export interface GetLFTemplatesResponse {
  /**
   *
   * @type {object}
   * @memberof GetLFTemplatesResponse
   */
  code?: object;
  /**
   *
   * @type {string}
   * @memberof GetLFTemplatesResponse
   */
  template_name: string;
  /**
   *
   * @type {string}
   * @memberof GetLFTemplatesResponse
   */
  template_schema_name: string;
  /**
   *
   * @type {string}
   * @memberof GetLFTemplatesResponse
   */
  template_type: string;
  /**
   *
   * @type {number}
   * @memberof GetLFTemplatesResponse
   */
  template_uid: number;
  /**
   *
   * @type {number}
   * @memberof GetLFTemplatesResponse
   */
  workspace_uid: number;
}
/**
 *
 * @export
 * @interface GetLabelDistributionsResponse
 */
export interface GetLabelDistributionsResponse {
  /**
   *
   * @type {LabelDistributions}
   * @memberof GetLabelDistributionsResponse
   */
  class_counts: LabelDistributions;
  /**
   *
   * @type {Array<SuggestionModel>}
   * @memberof GetLabelDistributionsResponse
   */
  suggestions: Array<SuggestionModel>;
}
/**
 *
 * @export
 * @interface GetLfErrorCorrelationResponse
 */
export interface GetLfErrorCorrelationResponse {
  /**
   *
   * @type {Array<LabelFunctionErrors>}
   * @memberof GetLfErrorCorrelationResponse
   */
  lf_errors: Array<LabelFunctionErrors>;
  /**
   *
   * @type {Array<SuggestionModel>}
   * @memberof GetLfErrorCorrelationResponse
   */
  suggestions: Array<SuggestionModel>;
}
/**
 *
 * @export
 * @interface GetModelConfigsResponse
 */
export interface GetModelConfigsResponse {
  /**
   *
   * @type {Array<ModelConfig>}
   * @memberof GetModelConfigsResponse
   */
  model_configs?: Array<ModelConfig>;
}
/**
 *
 * @export
 * @interface GetModelGeneralizationInfoResponse
 */
export interface GetModelGeneralizationInfoResponse {
  /**
   *
   * @type {Array<GeneralizationMessage>}
   * @memberof GetModelGeneralizationInfoResponse
   */
  info: Array<GeneralizationMessage>;
  /**
   *
   * @type {Array<SuggestionModel>}
   * @memberof GetModelGeneralizationInfoResponse
   */
  suggestions: Array<SuggestionModel>;
}
/**
 *
 * @export
 * @interface GetOperatorResponse
 */
export interface GetOperatorResponse {
  /**
   *
   * @type {object}
   * @memberof GetOperatorResponse
   */
  code?: object;
  /**
   *
   * @type {number}
   * @memberof GetOperatorResponse
   */
  dataset_uid?: number;
  /**
   *
   * @type {object}
   * @memberof GetOperatorResponse
   */
  op_config?: object;
  /**
   *
   * @type {string}
   * @memberof GetOperatorResponse
   */
  op_type: string;
}
/**
 *
 * @export
 * @interface GetOperatorsResponseElement
 */
export interface GetOperatorsResponseElement {
  /**
   *
   * @type {object}
   * @memberof GetOperatorsResponseElement
   */
  code?: object;
  /**
   *
   * @type {string}
   * @memberof GetOperatorsResponseElement
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof GetOperatorsResponseElement
   */
  name: string;
  /**
   *
   * @type {object}
   * @memberof GetOperatorsResponseElement
   */
  op_config?: object;
  /**
   *
   * @type {string}
   * @memberof GetOperatorsResponseElement
   */
  op_type?: string;
  /**
   *
   * @type {number}
   * @memberof GetOperatorsResponseElement
   */
  op_uid: number;
  /**
   *
   * @type {string}
   * @memberof GetOperatorsResponseElement
   */
  updated_at: string;
}
/**
 *
 * @export
 * @interface GetPopulatorsResponse
 */
export interface GetPopulatorsResponse {
  /**
   *
   * @type {Array<PopulatorResponse>}
   * @memberof GetPopulatorsResponse
   */
  populators: Array<PopulatorResponse>;
}
/**
 *
 * @export
 * @interface GetSettingsResponse
 */
export interface GetSettingsResponse {
  /**
   *
   * @type {number}
   * @memberof GetSettingsResponse
   */
  node_uid: number;
  /**
   *
   * @type {object}
   * @memberof GetSettingsResponse
   */
  settings: object;
}
/**
 *
 * @export
 * @interface GetSliceMapRequest
 */
export interface GetSliceMapRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof GetSliceMapRequest
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface GetSliceMembershipRequest
 */
export interface GetSliceMembershipRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof GetSliceMembershipRequest
   */
  slice_uids: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSliceMembershipRequest
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface GetSourceSizeResponse
 */
export interface GetSourceSizeResponse {
  /**
   *
   * @type {number}
   * @memberof GetSourceSizeResponse
   */
  source_size: number;
}
/**
 *
 * @export
 * @interface GetSourcesResponse
 */
export interface GetSourcesResponse {
  /**
   *
   * @type {Array<Source>}
   * @memberof GetSourcesResponse
   */
  sources: Array<Source>;
}
/**
 *
 * @export
 * @interface GetSpanLevelGroundTruthConflictsResponse
 */
export interface GetSpanLevelGroundTruthConflictsResponse {
  /**
   *
   * @type {string}
   * @memberof GetSpanLevelGroundTruthConflictsResponse
   */
  job_id?: string;
  /**
   *
   * @type {number}
   * @memberof GetSpanLevelGroundTruthConflictsResponse
   */
  task: number;
}
/**
 *
 * @export
 * @interface GetSupportedPromptBuilderModelsResponse
 */
export interface GetSupportedPromptBuilderModelsResponse {
  /**
   *
   * @type {Array<SupportedLLM>}
   * @memberof GetSupportedPromptBuilderModelsResponse
   */
  supported_models: Array<SupportedLLM>;
}
/**
 *
 * @export
 * @interface GetTagMapReq
 */
export interface GetTagMapReq {
  /**
   *
   * @type {Array<number>}
   * @memberof GetTagMapReq
   */
  exclude_tag_type_uids?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof GetTagMapReq
   */
  include_tag_type_uids?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof GetTagMapReq
   */
  is_context_tag_type?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof GetTagMapReq
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface GetTaskCommentMapElement
 */
export interface GetTaskCommentMapElement {
  /**
   *
   * @type {Array<Comment>}
   * @memberof GetTaskCommentMapElement
   */
  comments: Array<Comment>;
  /**
   *
   * @type {string}
   * @memberof GetTaskCommentMapElement
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface GetWorkspaceResponse
 */
export interface GetWorkspaceResponse {
  /**
   *
   * @type {{ [key: string]: WorkspaceUserInfo; }}
   * @memberof GetWorkspaceResponse
   */
  user_info_mapping?: { [key: string]: WorkspaceUserInfo };
  /**
   *
   * @type {Workspace}
   * @memberof GetWorkspaceResponse
   */
  workspace: Workspace;
}
/**
 *
 * @export
 * @interface GlobalPreferences
 */
export interface GlobalPreferences {
  /**
   *
   * @type {boolean}
   * @memberof GlobalPreferences
   */
  created_by_me_filter?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GlobalPreferences
   */
  enable_notifications?: boolean;
  /**
   *
   * @type {number}
   * @memberof GlobalPreferences
   */
  saved_workspace?: number;
  /**
   *
   * @type {boolean}
   * @memberof GlobalPreferences
   */
  should_hide_inactive_lf_modal?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GlobalPreferences
   */
  should_hide_trust_lf_modal?: boolean;
}
/**
 *
 * @export
 * @interface GroundTruthFileModel
 */
export interface GroundTruthFileModel {
  /**
   *
   * @type {SingleDataSourceIngestionRequest}
   * @memberof GroundTruthFileModel
   */
  datasource: SingleDataSourceIngestionRequest;
  /**
   *
   * @type {string}
   * @memberof GroundTruthFileModel
   */
  label_col: string;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GroundTruthFileModel
   */
  label_map?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof GroundTruthFileModel
   */
  remap_labels?: { [key: string]: string };
}
/**
 * A wrapper around data returned to the FE to render a Ground Truth Filter options.
 * @export
 * @interface GroundTruthFilterStructureModel
 */
export interface GroundTruthFilterStructureModel {
  /**
   *
   * @type {string}
   * @memberof GroundTruthFilterStructureModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof GroundTruthFilterStructureModel
   */
  filter_type: string;
  /**
   *
   * @type {Array<LabelSchemaFilterModel>}
   * @memberof GroundTruthFilterStructureModel
   */
  label_schemas: Array<LabelSchemaFilterModel>;
  /**
   *
   * @type {string}
   * @memberof GroundTruthFilterStructureModel
   */
  name: string;
}
/**
 * Response model for the gt label distribution endpoint
 * @export
 * @interface GroundTruthLabelDistributionResponse
 */
export interface GroundTruthLabelDistributionResponse {
  /**
   *
   * @type {object}
   * @memberof GroundTruthLabelDistributionResponse
   */
  gt_label_distribution: object;
}
/**
 *
 * @export
 * @interface GroundTruthModel
 */
export interface GroundTruthModel {
  /**
   *
   * @type {boolean}
   * @memberof GroundTruthModel
   */
  convert_to_raw_format?: boolean;
  /**
   *
   * @type {GroundTruthFileModel}
   * @memberof GroundTruthModel
   */
  file?: GroundTruthFileModel;
  /**
   *
   * @type {boolean}
   * @memberof GroundTruthModel
   */
  is_context?: boolean;
  /**
   *
   * @type {string}
   * @memberof GroundTruthModel
   */
  merge_type?: string;
  /**
   *
   * @type {GroundTruthRawModel}
   * @memberof GroundTruthModel
   */
  raw?: GroundTruthRawModel;
  /**
   *
   * @type {boolean}
   * @memberof GroundTruthModel
   */
  replace_abstain_with_negative?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GroundTruthModel
   */
  run_async?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GroundTruthModel
   */
  skip_missing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GroundTruthModel
   */
  skip_unknown?: boolean;
}
/**
 *
 * @export
 * @interface GroundTruthRawModel
 */
export interface GroundTruthRawModel {
  /**
   *
   * @type {Array<any>}
   * @memberof GroundTruthRawModel
   */
  labels: Array<any>;
  /**
   *
   * @type {object}
   * @memberof GroundTruthRawModel
   */
  metadata?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof GroundTruthRawModel
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface GroundTruthSearchParams
 */
export interface GroundTruthSearchParams {
  /**
   *
   * @type {Array<string>}
   * @memberof GroundTruthSearchParams
   */
  x_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface GroundTruthVersionResponse
 */
export interface GroundTruthVersionResponse {
  /**
   *
   * @type {string}
   * @memberof GroundTruthVersionResponse
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof GroundTruthVersionResponse
   */
  created_by: string;
  /**
   *
   * @type {string}
   * @memberof GroundTruthVersionResponse
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof GroundTruthVersionResponse
   */
  gt_version_uid: number;
  /**
   *
   * @type {boolean}
   * @memberof GroundTruthVersionResponse
   */
  hidden: boolean;
  /**
   *
   * @type {string}
   * @memberof GroundTruthVersionResponse
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof GroundTruthVersionResponse
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface GtLabelDistribution
 */
export interface GtLabelDistribution {}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface HeaderTokenResponse
 */
export interface HeaderTokenResponse {
  /**
   *
   * @type {string}
   * @memberof HeaderTokenResponse
   */
  access_token: string;
}
/**
 *
 * @export
 * @interface HuggingFaceSecretsConfig
 */
export interface HuggingFaceSecretsConfig {
  /**
   *
   * @type {string}
   * @memberof HuggingFaceSecretsConfig
   */
  'huggingface::inference::api_token'?: string;
  /**
   *
   * @type {string}
   * @memberof HuggingFaceSecretsConfig
   */
  provider: HuggingFaceSecretsConfigProviderEnum;
}

export const HuggingFaceSecretsConfigProviderEnum = {
  Huggingface: 'huggingface',
} as const;

export type HuggingFaceSecretsConfigProviderEnum =
  (typeof HuggingFaceSecretsConfigProviderEnum)[keyof typeof HuggingFaceSecretsConfigProviderEnum];

/**
 *
 * @export
 * @interface HydrateApplicationDeploymentMetadata
 */
export interface HydrateApplicationDeploymentMetadata {
  /**
   *
   * @type {string}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  application_name?: string;
  /**
   *
   * @type {number}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  application_uid?: number;
  /**
   *
   * @type {string}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  created_by_username?: string;
  /**
   *
   * @type {object}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  data_schema: object;
  /**
   *
   * @type {number}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  deployment_uid: number;
  /**
   *
   * @type {DeploymentMetadata}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  metadata?: DeploymentMetadata;
  /**
   *
   * @type {string}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  package_type?: string;
  /**
   *
   * @type {DeploymentStorageInfoConfig}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  storage_information_config?: DeploymentStorageInfoConfig;
  /**
   *
   * @type {number}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  user_uid?: number;
  /**
   *
   * @type {string}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  workflow_package_dir?: string;
  /**
   *
   * @type {number}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  workflow_uid: number;
  /**
   *
   * @type {number}
   * @memberof HydrateApplicationDeploymentMetadata
   */
  workspace_uid?: number;
}
/**
 * Application with hydrated_node_dag, where each node has op node details.
 * @export
 * @interface HydratedApplication
 */
export interface HydratedApplication {
  /**
   *
   * @type {AccessConfig}
   * @memberof HydratedApplication
   */
  access_config?: AccessConfig;
  /**
   *
   * @type {ApplicationConfig}
   * @memberof HydratedApplication
   */
  application_config: ApplicationConfig;
  /**
   *
   * @type {number}
   * @memberof HydratedApplication
   */
  application_uid?: number;
  /**
   *
   * @type {string}
   * @memberof HydratedApplication
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof HydratedApplication
   */
  created_by_username?: string;
  /**
   *
   * @type {number}
   * @memberof HydratedApplication
   */
  dataset_uid: number;
  /**
   *
   * @type {Array<string>}
   * @memberof HydratedApplication
   */
  deploy_input_schema?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof HydratedApplication
   */
  description?: string;
  /**
   *
   * @type {{ [key: string]: HydratedDAGNode; }}
   * @memberof HydratedApplication
   */
  hydrated_node_dag: { [key: string]: HydratedDAGNode };
  /**
   *
   * @type {boolean}
   * @memberof HydratedApplication
   */
  is_setup_complete?: boolean;
  /**
   *
   * @type {LicenseConfig}
   * @memberof HydratedApplication
   */
  license_config?: LicenseConfig;
  /**
   *
   * @type {string}
   * @memberof HydratedApplication
   */
  name: string;
  /**
   *
   * @type {{ [key: string]: DAGNode; }}
   * @memberof HydratedApplication
   */
  node_dag?: { [key: string]: DAGNode };
  /**
   *
   * @type {number}
   * @memberof HydratedApplication
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface HydratedBatch
 */
export interface HydratedBatch {
  /**
   *
   * @type {Array<AnnotatorStatistics>}
   * @memberof HydratedBatch
   */
  annotators: Array<AnnotatorStatistics>;
  /**
   *
   * @type {string}
   * @memberof HydratedBatch
   */
  application_name?: string;
  /**
   *
   * @type {number}
   * @memberof HydratedBatch
   */
  application_uid?: number;
  /**
   *
   * @type {number}
   * @memberof HydratedBatch
   */
  batch_size: number;
  /**
   *
   * @type {number}
   * @memberof HydratedBatch
   */
  batch_uid: number;
  /**
   *
   * @type {number}
   * @memberof HydratedBatch
   */
  expert_source_uid?: number;
  /**
   *
   * @type {object}
   * @memberof HydratedBatch
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof HydratedBatch
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof HydratedBatch
   */
  node_uid: number;
  /**
   *
   * @type {Array<Source>}
   * @memberof HydratedBatch
   */
  sources: Array<Source>;
  /**
   *
   * @type {string}
   * @memberof HydratedBatch
   */
  split?: string;
  /**
   *
   * @type {string}
   * @memberof HydratedBatch
   */
  ts: string;
  /**
   *
   * @type {number}
   * @memberof HydratedBatch
   */
  user_uid: number;
  /**
   *
   * @type {number}
   * @memberof HydratedBatch
   */
  workspace_uid?: number;
}
/**
 * BaseModel for nodes in both the Metagraph for ApplicationTemplates and for nodes in an actual instantiated NodeDAG. Allows us to write algorithms that work on both actual DAGs and on DAG templates
 * @export
 * @interface HydratedDAGNode
 */
export interface HydratedDAGNode {
  /**
   *
   * @type {string}
   * @memberof HydratedDAGNode
   */
  expected_op_type?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof HydratedDAGNode
   */
  input_ids: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof HydratedDAGNode
   */
  input_ids_simplified?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof HydratedDAGNode
   */
  is_output?: boolean;
  /**
   *
   * @type {string}
   * @memberof HydratedDAGNode
   */
  node_cls: string;
  /**
   *
   * @type {object}
   * @memberof HydratedDAGNode
   */
  node_config?: object;
  /**
   *
   * @type {object}
   * @memberof HydratedDAGNode
   */
  op_config?: object;
  /**
   *
   * @type {string}
   * @memberof HydratedDAGNode
   */
  op_type?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof HydratedDAGNode
   */
  output_ids?: Array<number>;
}
/**
 *
 * @export
 * @interface ImportAnnotationsParams
 */
export interface ImportAnnotationsParams {
  /**
   *
   * @type {Array<CreateAnnotationsParams>}
   * @memberof ImportAnnotationsParams
   */
  annotations: Array<CreateAnnotationsParams>;
}
/**
 *
 * @export
 * @interface ImportAnnotationsResponse
 */
export interface ImportAnnotationsResponse {
  /**
   *
   * @type {Array<ImportAnnotationsResponseObject>}
   * @memberof ImportAnnotationsResponse
   */
  created_annotations: Array<ImportAnnotationsResponseObject>;
}
/**
 *
 * @export
 * @interface ImportAnnotationsResponseObject
 */
export interface ImportAnnotationsResponseObject {
  /**
   *
   * @type {number}
   * @memberof ImportAnnotationsResponseObject
   */
  annotation_uid: number;
  /**
   *
   * @type {number}
   * @memberof ImportAnnotationsResponseObject
   */
  source_uid: number;
  /**
   *
   * @type {string}
   * @memberof ImportAnnotationsResponseObject
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface ImportAssets
 */
export interface ImportAssets {
  /**
   *
   * @type {Array<string>}
   * @memberof ImportAssets
   */
  assignments_to_import?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ImportAssets
   */
  context_gt_to_import?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ImportAssets
   */
  from_node_uid?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ImportAssets
   */
  gt_to_import?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ImportAssets
   */
  is_context_tag_type?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ImportAssets
   */
  only_copy_committed_model?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ImportAssets
   */
  tag_types_to_import?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ImportAssets
   */
  transfer_dev_xs?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ImportAssets
   */
  updated_label_schema?: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof ImportAssets
   */
  user_uid?: number;
}
/**
 *
 * @export
 * @interface ImportComments
 */
export interface ImportComments {
  /**
   *
   * @type {MergeType}
   * @memberof ImportComments
   */
  merge_type?: MergeType;
  /**
   *
   * @type {Array<Comment>}
   * @memberof ImportComments
   */
  payload: Array<Comment>;
}

/**
 *
 * @export
 * @interface ImportCommentsResponse
 */
export interface ImportCommentsResponse {
  /**
   *
   * @type {number}
   * @memberof ImportCommentsResponse
   */
  imports_attempted: number;
  /**
   *
   * @type {number}
   * @memberof ImportCommentsResponse
   */
  imports_failed: number;
  /**
   *
   * @type {number}
   * @memberof ImportCommentsResponse
   */
  imports_succeeded: number;
}
/**
 *
 * @export
 * @interface ImportDatasetAnnotationsParams
 */
export interface ImportDatasetAnnotationsParams {
  /**
   *
   * @type {Array<CreateDatasetAnnotationParams>}
   * @memberof ImportDatasetAnnotationsParams
   */
  annotations: Array<CreateDatasetAnnotationParams>;
  /**
   *
   * @type {number}
   * @memberof ImportDatasetAnnotationsParams
   */
  dataset_uid: number;
  /**
   *
   * @type {number}
   * @memberof ImportDatasetAnnotationsParams
   */
  label_schema_uid: number;
}
/**
 *
 * @export
 * @interface ImportDatasetAnnotationsResponse
 */
export interface ImportDatasetAnnotationsResponse {
  /**
   *
   * @type {Array<ImportDatasetAnnotationsResponseObject>}
   * @memberof ImportDatasetAnnotationsResponse
   */
  created_annotations: Array<ImportDatasetAnnotationsResponseObject>;
}
/**
 *
 * @export
 * @interface ImportDatasetAnnotationsResponseObject
 */
export interface ImportDatasetAnnotationsResponseObject {
  /**
   *
   * @type {number}
   * @memberof ImportDatasetAnnotationsResponseObject
   */
  annotation_uid: number;
  /**
   *
   * @type {number}
   * @memberof ImportDatasetAnnotationsResponseObject
   */
  source_uid: number;
  /**
   *
   * @type {string}
   * @memberof ImportDatasetAnnotationsResponseObject
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface ImportLFs
 */
export interface ImportLFs {
  /**
   *
   * @type {number}
   * @memberof ImportLFs
   */
  from_node_uid?: number;
  /**
   *
   * @type {string}
   * @memberof ImportLFs
   */
  lfs_dump: string;
  /**
   *
   * @type {boolean}
   * @memberof ImportLFs
   */
  merge?: boolean;
  /**
   *
   * @type {string}
   * @memberof ImportLFs
   */
  merge_type?: string;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ImportLFs
   */
  renamed_labels?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: LFState; }}
   * @memberof ImportLFs
   */
  updated_lf_state_map?: { [key: string]: LFState };
  /**
   *
   * @type {number}
   * @memberof ImportLFs
   */
  user_uid?: number;
}
/**
 *
 * @export
 * @interface ImportTagTypes
 */
export interface ImportTagTypes {
  /**
   *
   * @type {object}
   * @memberof ImportTagTypes
   */
  all_tag_types_dict: object;
  /**
   *
   * @type {MergeType}
   * @memberof ImportTagTypes
   */
  merge_type?: MergeType;
  /**
   *
   * @type {Array<string>}
   * @memberof ImportTagTypes
   */
  tag_types_to_import?: Array<string>;
}

/**
 *
 * @export
 * @interface IndividualAnnotatorStatistics
 */
export interface IndividualAnnotatorStatistics {
  /**
   *
   * @type {number}
   * @memberof IndividualAnnotatorStatistics
   */
  annotation_count: number;
  /**
   *
   * @type {number}
   * @memberof IndividualAnnotatorStatistics
   */
  annotation_target: number;
  /**
   *
   * @type {number}
   * @memberof IndividualAnnotatorStatistics
   */
  source_uid: number;
  /**
   *
   * @type {string}
   * @memberof IndividualAnnotatorStatistics
   */
  user_name: string;
  /**
   *
   * @type {number}
   * @memberof IndividualAnnotatorStatistics
   */
  user_uid: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Industry = {
  All: 'all',
  FinancialServices: 'financial_services',
  TelecomAndCyber: 'telecom_and_cyber',
  Healthcare: 'healthcare',
  Insurance: 'insurance',
  Software: 'software',
  Retail: 'retail',
} as const;

export type Industry = (typeof Industry)[keyof typeof Industry];

/**
 *
 * @export
 * @interface IngestAllDataSourcesResponseModel
 */
export interface IngestAllDataSourcesResponseModel {
  /**
   *
   * @type {Array<object>}
   * @memberof IngestAllDataSourcesResponseModel
   */
  splitwise_response: Array<object>;
}
/**
 *
 * @export
 * @interface IngestAndSwapDatasourcePayload
 */
export interface IngestAndSwapDatasourcePayload {
  /**
   *
   * @type {string}
   * @memberof IngestAndSwapDatasourcePayload
   */
  new_datasource_path: string;
}
/**
 *
 * @export
 * @interface IngestAndSwapDatasourceResponse
 */
export interface IngestAndSwapDatasourceResponse {
  /**
   *
   * @type {string}
   * @memberof IngestAndSwapDatasourceResponse
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface InputFieldSpec
 */
export interface InputFieldSpec {
  /**
   *
   * @type {boolean}
   * @memberof InputFieldSpec
   */
  auto_focus?: boolean;
  /**
   *
   * @type {string}
   * @memberof InputFieldSpec
   */
  component: string;
  /**
   *
   * @type {any}
   * @memberof InputFieldSpec
   */
  default?: any;
  /**
   *
   * @type {string}
   * @memberof InputFieldSpec
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof InputFieldSpec
   */
  display_name?: string;
  /**
   *
   * @type {DisplayType}
   * @memberof InputFieldSpec
   */
  display_type?: DisplayType;
  /**
   *
   * @type {boolean}
   * @memberof InputFieldSpec
   */
  fill_width?: boolean;
  /**
   *
   * @type {string}
   * @memberof InputFieldSpec
   */
  input_name: string;
  /**
   *
   * @type {boolean}
   * @memberof InputFieldSpec
   */
  is_advanced?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InputFieldSpec
   */
  is_optional: boolean;
  /**
   *
   * @type {number}
   * @memberof InputFieldSpec
   */
  max_values?: number;
  /**
   *
   * @type {number}
   * @memberof InputFieldSpec
   */
  max_width?: number;
  /**
   *
   * @type {number}
   * @memberof InputFieldSpec
   */
  min_width?: number;
  /**
   *
   * @type {Array<any>}
   * @memberof InputFieldSpec
   */
  options?: Array<any>;
  /**
   *
   * @type {string}
   * @memberof InputFieldSpec
   */
  placeholder?: string;
  /**
   *
   * @type {string}
   * @memberof InputFieldSpec
   */
  tooltip?: string;
}

/**
 *
 * @export
 * @interface InputWarning
 */
export interface InputWarning {
  /**
   *
   * @type {WarningAction}
   * @memberof InputWarning
   */
  action?: WarningAction;
  /**
   *
   * @type {string}
   * @memberof InputWarning
   */
  column?: string;
  /**
   *
   * @type {WarningLevel}
   * @memberof InputWarning
   */
  level: WarningLevel;
  /**
   *
   * @type {string}
   * @memberof InputWarning
   */
  stacktrace?: string;
  /**
   *
   * @type {string}
   * @memberof InputWarning
   */
  text: string;
}

/**
 *
 * @export
 * @interface InstanceInformation
 */
export interface InstanceInformation {
  /**
   *
   * @type {string}
   * @memberof InstanceInformation
   */
  id: string;
}
/**
 *
 * @export
 * @interface InterAnnotatorAgreement
 */
export interface InterAnnotatorAgreement {
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof InterAnnotatorAgreement
   */
  matrix: Array<Array<number>>;
  /**
   *
   * @type {number}
   * @memberof InterAnnotatorAgreement
   */
  metric?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof InterAnnotatorAgreement
   */
  usernames: Array<string>;
}
/**
 *
 * @export
 * @interface InvalidResourceInfo
 */
export interface InvalidResourceInfo {
  /**
   *
   * @type {string}
   * @memberof InvalidResourceInfo
   */
  error_msg: string;
  /**
   *
   * @type {AuthorizationAuthTypesResourceType}
   * @memberof InvalidResourceInfo
   */
  resource_type: AuthorizationAuthTypesResourceType;
  /**
   *
   * @type {number}
   * @memberof InvalidResourceInfo
   */
  resource_uid?: number;
}

/**
 *
 * @export
 * @interface InviteResponse
 */
export interface InviteResponse {
  /**
   *
   * @type {string}
   * @memberof InviteResponse
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof InviteResponse
   */
  expiration: string;
  /**
   *
   * @type {string}
   * @memberof InviteResponse
   */
  invite_link: string;
  /**
   *
   * @type {string}
   * @memberof InviteResponse
   */
  manually_expired_at?: string;
  /**
   *
   * @type {number}
   * @memberof InviteResponse
   */
  max_users_allowed: number;
  /**
   *
   * @type {UserRole}
   * @memberof InviteResponse
   */
  role: UserRole;
  /**
   *
   * @type {Array<Role>}
   * @memberof InviteResponse
   */
  roles?: Array<Role>;
  /**
   *
   * @type {string}
   * @memberof InviteResponse
   */
  updated_at: string;
  /**
   *
   * @type {number}
   * @memberof InviteResponse
   */
  users_accepted: number;
}

/**
 *
 * @export
 * @interface JWTSigningInfo
 */
export interface JWTSigningInfo {
  /**
   *
   * @type {string}
   * @memberof JWTSigningInfo
   */
  algorithm: string;
  /**
   *
   * @type {string}
   * @memberof JWTSigningInfo
   */
  public_key: string;
}
/**
 *
 * @export
 * @interface JobGroup
 */
export interface JobGroup {
  /**
   *
   * @type {Array<RQJobId>}
   * @memberof JobGroup
   */
  jobs: Array<RQJobId>;
  /**
   *
   * @type {RQMetaJobId}
   * @memberof JobGroup
   */
  meta_job_id?: RQMetaJobId;
}
/**
 * Class used to track asynchronous engine jobs. Use data_models.engine.start_tdm_engine_job to get a RQJobId, and JobInfo.from_rq to get the full job info. The expectation is that once start_tdm_engine_job() is fired off, the FastAPI route returns the job ID that the client uses to poll until completion.
 * @export
 * @interface JobInfo
 */
export interface JobInfo {
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  application_name?: string;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  application_uid?: number;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  dataset_uid?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof JobInfo
   */
  dependency_job_ids?: Array<string>;
  /**
   *
   * @type {object}
   * @memberof JobInfo
   */
  detail?: object;
  /**
   * If null, job has not finished.
   * @type {string}
   * @memberof JobInfo
   */
  end_time: string;
  /**
   * We don\'t expect enqueued time to be null, but if it is, job has not been fully queued up yet
   * @type {string}
   * @memberof JobInfo
   */
  enqueued_time?: string;
  /**
   * If this field is null, job has not been picked up by a worker yet
   * @type {string}
   * @memberof JobInfo
   */
  execution_start_time: string;
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  function_name?: string;
  /**
   *
   * @type {JobGroup}
   * @memberof JobInfo
   */
  job_group?: JobGroup;
  /**
   *
   * @type {JobType}
   * @memberof JobInfo
   */
  job_type: JobType;
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  node_uid?: number;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  percent?: number;
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  pod_name?: string;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  process_id?: number;
  /**
   *
   * @type {JobState}
   * @memberof JobInfo
   */
  state: JobState;
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  sub_message?: string;
  /**
   *
   * @type {{ [key: string]: Timing; }}
   * @memberof JobInfo
   */
  timing?: { [key: string]: Timing };
  /**
   *
   * @type {string}
   * @memberof JobInfo
   */
  uid: string;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  user_uid?: number;
  /**
   *
   * @type {number}
   * @memberof JobInfo
   */
  workspace_uid?: number;
}

/**
 *
 * @export
 * @interface JobListResponse
 */
export interface JobListResponse {
  /**
   *
   * @type {Array<JobInfo>}
   * @memberof JobListResponse
   */
  jobs: Array<JobInfo>;
  /**
   * For pagination purposes. To go forwards one page, set start_time to this value and direction to \'older\'
   * @type {number}
   * @memberof JobListResponse
   */
  next_start_time: number;
  /**
   * For pagination purposes. To go backwards one page, set start_time to this value and direction to \'newer\'
   * @type {number}
   * @memberof JobListResponse
   */
  previous_start_time: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const JobState = {
  Running: 'running',
  Completed: 'completed',
  Failed: 'failed',
  Cancelled: 'cancelled',
} as const;

export type JobState = (typeof JobState)[keyof typeof JobState];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const JobType = {
  AnalyzeDatasource: 'analyze-datasource',
  ApplyPopulator: 'apply-populator',
  CreatePopulator: 'create-populator',
  GarbageCollectPopulators: 'garbage-collect-populators',
  CreateTask: 'create-task',
  ComputeCluster: 'compute-cluster',
  ComputeScores: 'compute-scores',
  ComputeOptimalThreshold: 'compute-optimal-threshold',
  AlignGroundTruth: 'align-ground-truth',
  AddGroundTruth: 'add-ground-truth',
  AddFlatGroundTruth: 'add-flat-ground-truth',
  PatchTask: 'patch-task',
  IngestData: 'ingest-data',
  DeleteDatasource: 'delete-datasource',
  IngestAndSwapDatasource: 'ingest-and-swap-datasource',
  ResplitData: 'resplit-data',
  PrepAndIngestData: 'prep-and-ingest-data',
  PreviewData: 'preview-data',
  SetTaskData: 'set-task-data',
  CreateApplicationDeployment: 'create-application-deployment',
  IngestReferenceDataset: 'ingest-reference-dataset',
  IdentityJob: 'identity-job',
  PredictionApiAsyncInference: 'prediction-api-async-inference',
  SetActivePlaceholderData: 'set-active-placeholder-data',
  SetActiveNodeData: 'set-active-node-data',
  CreateTrainSampleDatasource: 'create-train-sample-datasource',
  GetNodePreview: 'get-node-preview',
  LabelDataset: 'label-dataset',
  FitOperator: 'fit-operator',
  FitModelBasedFeaturizer: 'fit-model-based-featurizer',
  TrainModel: 'train-model',
  RegisterPredictModel: 'register-predict-model',
  PackageLabelTrain: 'package-label-train',
  RunPromptInference: 'run-prompt-inference',
  RunAllPromptInference: 'run-all-prompt-inference',
  PromptFm: 'prompt-fm',
  ContinueLongRunningLf: 'continue-long-running-lf',
  BuildReport: 'build-report',
  TakeBackup: 'take-backup',
  RestoreBackup: 'restore-backup',
  ListBackups: 'list-backups',
  IngestGroundTruth: 'ingest-ground-truth',
  LoadGroundTruthVersion: 'load-ground-truth-version',
  TransferAssets: 'transfer-assets',
  TransferGroundTruth: 'transfer-ground-truth',
  TransferLfs: 'transfer-lfs',
  DownloadRemoteObject: 'download-remote-object',
  GetSpanLevelGroundTruthConflicts: 'get-span-level-ground-truth-conflicts',
  DeleteApplication: 'delete-application',
  ExecuteGraphOnDataApplication: 'execute-graph-on-data-application',
  RefreshApplication: 'refresh-application',
  LoadDataset: 'load-dataset',
  RemoveDataset: 'remove-dataset',
  ProfileDataset: 'profile-dataset',
  PeekDatasourceColumns: 'peek-datasource-columns',
  FetchDatasetColumnTypes: 'fetch-dataset-column-types',
  FetchDataframeColumnTypes: 'fetch-dataframe-column-types',
  PeekDatasourceColumnMap: 'peek-datasource-column-map',
  CreateOpVersionAddOpPredictions: 'create-op-version-add-op-predictions',
  CreateSimpleLfSuggestionsJob: 'create-simple-lf-suggestions-job',
  PopulateLfSuggestions: 'populate-lf-suggestions',
  GarbageCollectDatasetApplicationNodes:
    'garbage-collect-dataset-application-nodes',
  RegisterIngestModel: 'register-ingest-model',
  ExportMlflowDeploymentExternalRegistry:
    'export-mlflow-deployment-external-registry',
  GarbageCollectDatasetApplication: 'garbage-collect-dataset-application',
  StoreFeatures: 'store-features',
  GetLfSuggestionsForStudioV2: 'get-lf-suggestions-for-studio-v2',
  ApplyLfsWrapper: 'apply-lfs-wrapper',
  CreatePredictionApiService: 'create-prediction-api-service',
  DeletePredictionApiService: 'delete-prediction-api-service',
  InteractiveSharedLfApply: 'interactive-shared-lf-apply',
  InteractiveDedicatedLfApply: 'interactive-dedicated-lf-apply',
  NoOp: 'no-op',
  MergeAssets: 'merge-assets',
  ExecuteBatch: 'execute-batch',
  LoadUserNodeActor: 'load-user-node-actor',
  DestroyUserNodeActor: 'destroy-user-node-actor',
  ComputeEmbeddings: 'compute-embeddings',
  UpdateStudioDfEmbeddings: 'update-studio-df-embeddings',
  ClearStudioDfCache: 'clear-studio-df-cache',
  DeleteNode: 'delete-node',
  ComputeMetrics: 'compute-metrics',
  ComputeDocMetrics: 'compute-doc-metrics',
  FetchDatasetColumns: 'fetch-dataset-columns',
  FetchDataframeColumns: 'fetch-dataframe-columns',
  RefreshActiveDatasources: 'refresh-active-datasources',
  ReadCandidates: 'read-candidates',
  CreateDeploymentFromWorkflow: 'create-deployment-from-workflow',
  ExecuteWorkflowFile: 'execute-workflow-file',
  ExecuteWorkflowDf: 'execute-workflow-df',
  ExecuteWorkflowConfig: 'execute-workflow-config',
  PostprocessModel: 'postprocess-model',
  GetUniqueValuesInColumn: 'get-unique-values-in-column',
  RemoveStudioNodeData: 'remove-studio-node-data',
  CleanDiskLruCache: 'clean-disk-lru-cache',
  SaveLfVotesToDb: 'save-lf-votes-to-db',
  AddModelPredToDb: 'add-model-pred-to-db',
  AddTsToDb: 'add-ts-to-db',
  CopyLabelSchema: 'copy-label-schema',
  UploadStaticAssets: 'upload-static-assets',
  FinetuneModel: 'finetune-model',
  PrepAndIngestFineTuningData: 'prep-and-ingest-fine-tuning-data',
  FinetuneModelInference: 'finetune-model-inference',
  EvaluationComputeMetrics: 'evaluation-compute-metrics',
  BackfillPredictions: 'backfill-predictions',
  SliceApply: 'slice-apply',
  PredictModel: 'predict-model',
} as const;

export type JobType = (typeof JobType)[keyof typeof JobType];

/**
 *
 * @export
 * @interface LFConfig
 */
export interface LFConfig {
  /**
   *
   * @type {object}
   * @memberof LFConfig
   */
  config: object;
  /**
   *
   * @type {string}
   * @memberof LFConfig
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof LFConfig
   */
  parent_lf_uid?: number;
  /**
   *
   * @type {LFState}
   * @memberof LFConfig
   */
  state?: LFState;
  /**
   *
   * @type {number}
   * @memberof LFConfig
   */
  user_uid?: number;
}

/**
 *
 * @export
 * @interface LFLabels
 */
export interface LFLabels {
  /**
   *
   * @type {Array<any>}
   * @memberof LFLabels
   */
  labels: Array<any>;
  /**
   *
   * @type {Array<number>}
   * @memberof LFLabels
   */
  lf_uids: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof LFLabels
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface LFPackageModel
 */
export interface LFPackageModel {
  /**
   *
   * @type {string}
   * @memberof LFPackageModel
   */
  data: string;
  /**
   *
   * @type {boolean}
   * @memberof LFPackageModel
   */
  is_active?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof LFPackageModel
   */
  lf_uids: Array<number>;
  /**
   *
   * @type {object}
   * @memberof LFPackageModel
   */
  metadata: object;
  /**
   *
   * @type {string}
   * @memberof LFPackageModel
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof LFPackageModel
   */
  pkg_uid?: number;
  /**
   *
   * @type {number}
   * @memberof LFPackageModel
   */
  user_uid?: number;
}
/**
 *
 * @export
 * @interface LFPreferences
 */
export interface LFPreferences {
  /**
   *
   * @type {BuilderSettings}
   * @memberof LFPreferences
   */
  builder_settings?: BuilderSettings;
  /**
   *
   * @type {Array<string>}
   * @memberof LFPreferences
   */
  selected_columns?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof LFPreferences
   */
  selected_field?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof LFPreferences
   */
  selected_metrics?: Array<string>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LFState = {
  Active: 'active',
  Edited: 'edited',
  Archived: 'archived',
  Suggested: 'suggested',
  Rejected: 'rejected',
  InProgress: 'in_progress',
} as const;

export type LFState = (typeof LFState)[keyof typeof LFState];

/**
 *
 * @export
 * @interface LFTemplatePayload
 */
export interface LFTemplatePayload {
  /**
   *
   * @type {object}
   * @memberof LFTemplatePayload
   */
  code: object;
  /**
   *
   * @type {boolean}
   * @memberof LFTemplatePayload
   */
  overwrite?: boolean;
  /**
   *
   * @type {object}
   * @memberof LFTemplatePayload
   */
  template_config: object;
  /**
   *
   * @type {number}
   * @memberof LFTemplatePayload
   */
  workspace_uid?: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LLMType = {
  TaskHead: 'task_head',
  Setfit: 'setfit',
  Sdnet: 'sdnet',
  Entailment: 'entailment',
  MaskedLmZeroShot: 'masked_lm_zero_shot',
  MaskedLmFewShot: 'masked_lm_few_shot',
  Qa: 'qa',
  Text2text: 'text2text',
  Text2textMultiLabel: 'text2text_multi_label',
  DocVqa: 'doc_vqa',
  Text2textQa: 'text2text_qa',
  RagPromptEnrichmentDocClsPredictor: 'rag_prompt_enrichment_doc_cls_predictor',
  RagPromptEnrichmentSeqTagPredictor: 'rag_prompt_enrichment_seq_tag_predictor',
  PdfText2textExtractor: 'pdf_text2text_extractor',
  WordText2textQa: 'word_text2text_qa',
} as const;

export type LLMType = (typeof LLMType)[keyof typeof LLMType];

/**
 *
 * @export
 * @interface LabelColorObject
 */
export interface LabelColorObject {
  /**
   *
   * @type {string}
   * @memberof LabelColorObject
   */
  label_background_color?: string;
  /**
   *
   * @type {string}
   * @memberof LabelColorObject
   */
  label_text_color?: string;
}
/**
 *
 * @export
 * @interface LabelColorScheme
 */
export interface LabelColorScheme {}
/**
 *
 * @export
 * @interface LabelDistributions
 */
export interface LabelDistributions {
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof LabelDistributions
   */
  ground_truth: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof LabelDistributions
   */
  lfs: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof LabelDistributions
   */
  model: { [key: string]: number };
}
/**
 *
 * @export
 * @interface LabelFunctionErrors
 */
export interface LabelFunctionErrors {
  /**
   *
   * @type {number}
   * @memberof LabelFunctionErrors
   */
  lf_uid: number;
  /**
   *
   * @type {number}
   * @memberof LabelFunctionErrors
   */
  num_errors: number;
}
/**
 *
 * @export
 * @interface LabelJob
 */
export interface LabelJob {
  /**
   *
   * @type {boolean}
   * @memberof LabelJob
   */
  create_training_set?: boolean;
  /**
   *
   * @type {string}
   * @memberof LabelJob
   */
  end_date?: string;
  /**
   *
   * @type {Array<any>}
   * @memberof LabelJob
   */
  label_model_list?: Array<any>;
  /**
   *
   * @type {number}
   * @memberof LabelJob
   */
  lf_package_uid?: number;
  /**
   *
   * @type {number}
   * @memberof LabelJob
   */
  max_ds_per_apply?: number;
  /**
   *
   * @type {number}
   * @memberof LabelJob
   */
  max_train_rows?: number;
  /**
   *
   * @type {number}
   * @memberof LabelJob
   */
  parallel_core_fraction?: number;
  /**
   *
   * @type {number}
   * @memberof LabelJob
   */
  partition_size_mb?: number;
  /**
   *
   * @type {boolean}
   * @memberof LabelJob
   */
  register_model?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LabelJob
   */
  register_model_replace_abstain_with_negative?: boolean;
  /**
   *
   * @type {string}
   * @memberof LabelJob
   */
  scheduler?: string;
  /**
   *
   * @type {string}
   * @memberof LabelJob
   */
  start_date?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof LabelJob
   */
  tuning_splits?: Array<string>;
}
/**
 *
 * @export
 * @interface LabelSchema
 */
export interface LabelSchema {
  /**
   *
   * @type {DataType}
   * @memberof LabelSchema
   */
  data_type: DataType;
  /**
   *
   * @type {number}
   * @memberof LabelSchema
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof LabelSchema
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof LabelSchema
   */
  has_associated_assets?: boolean;
  /**
   *
   * @type {string}
   * @memberof LabelSchema
   */
  image_field?: string;
  /**
   *
   * @type {boolean}
   * @memberof LabelSchema
   */
  is_multi_label: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LabelSchema
   */
  is_text_label?: boolean;
  /**
   *
   * @type {string}
   * @memberof LabelSchema
   */
  label_column?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof LabelSchema
   */
  label_descriptions?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof LabelSchema
   */
  label_map: { [key: string]: number };
  /**
   *
   * @type {number}
   * @memberof LabelSchema
   */
  label_schema_uid: number;
  /**
   *
   * @type {string}
   * @memberof LabelSchema
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof LabelSchema
   */
  pdf_url_field?: string;
  /**
   *
   * @type {string}
   * @memberof LabelSchema
   */
  primary_field?: string;
  /**
   *
   * @type {TaskType}
   * @memberof LabelSchema
   */
  task_type: TaskType;
}

/**
 *
 * @export
 * @interface LabelSchemaAnnotations
 */
export interface LabelSchemaAnnotations {
  /**
   *
   * @type {Array<FetchedDatasetAnnotation>}
   * @memberof LabelSchemaAnnotations
   */
  annotations: Array<FetchedDatasetAnnotation>;
  /**
   *
   * @type {Array<DatasetBatchMetadata>}
   * @memberof LabelSchemaAnnotations
   */
  batch_metadata?: Array<DatasetBatchMetadata>;
  /**
   *
   * @type {number}
   * @memberof LabelSchemaAnnotations
   */
  label_schema_uid: number;
  /**
   *
   * @type {Array<SvcSource>}
   * @memberof LabelSchemaAnnotations
   */
  sources: Array<SvcSource>;
}
/**
 *
 * @export
 * @interface LabelSchemaAnnotatorSummary
 */
export interface LabelSchemaAnnotatorSummary {
  /**
   *
   * @type {number}
   * @memberof LabelSchemaAnnotatorSummary
   */
  label_schema_uid: number;
  /**
   *
   * @type {LabelType}
   * @memberof LabelSchemaAnnotatorSummary
   */
  label_type: LabelType;
  /**
   *
   * @type {{ [key: string]: LabelWithAggregatedAnnotators; }}
   * @memberof LabelSchemaAnnotatorSummary
   */
  labels_annotators: { [key: string]: LabelWithAggregatedAnnotators };
  /**
   *
   * @type {string}
   * @memberof LabelSchemaAnnotatorSummary
   */
  name: string;
}

/**
 *
 * @export
 * @interface LabelSchemaFilterModel
 */
export interface LabelSchemaFilterModel {
  /**
   *
   * @type {number}
   * @memberof LabelSchemaFilterModel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof LabelSchemaFilterModel
   */
  name: string;
  /**
   *
   * @type {Array<OptionModel>}
   * @memberof LabelSchemaFilterModel
   */
  vote_type_options?: Array<OptionModel>;
  /**
   *
   * @type {Array<OptionModel>}
   * @memberof LabelSchemaFilterModel
   */
  voted_options: Array<OptionModel>;
}
/**
 *
 * @export
 * @interface LabelSchemaLabels
 */
export interface LabelSchemaLabels {
  /**
   *
   * @type {any}
   * @memberof LabelSchemaLabels
   */
  label?: any;
  /**
   *
   * @type {number}
   * @memberof LabelSchemaLabels
   */
  label_schema_uid: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LabelSchemaSetting = {
  DataType: 'Data type',
  TaskType: 'Task type',
  IeType: 'IE type',
  PdfType: 'PDF type',
  PrimaryTextField: 'Primary text field',
  PdfUrlField: 'PDF URL field',
  ImageField: 'Image field',
  LabelSchema: 'Label schema',
  LabelSchemaDescription: 'Label schema description',
  SkipDataPointsCausingErrors: 'Skip data points causing errors',
  FilterOutDocs10Mb: 'Filter out docs > 10MB',
  LabelType: 'Label Type',
  LabelColumn: 'Label Column',
} as const;

export type LabelSchemaSetting =
  (typeof LabelSchemaSetting)[keyof typeof LabelSchemaSetting];

/**
 *
 * @export
 * @interface LabelSchemaStep
 */
export interface LabelSchemaStep {
  /**
   *
   * @type {boolean}
   * @memberof LabelSchemaStep
   */
  is_user_input_needed?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof LabelSchemaStep
   */
  label_descriptions?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof LabelSchemaStep
   */
  label_map?: { [key: string]: number };
  /**
   *
   * @type {SetupStepName}
   * @memberof LabelSchemaStep
   */
  name?: SetupStepName;
  /**
   *
   * @type {Array<SetupSetting>}
   * @memberof LabelSchemaStep
   */
  settings?: Array<SetupSetting>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof LabelSchemaStep
   */
  updated_label_schema?: { [key: string]: string };
  /**
   *
   * @type {Array<InputWarning>}
   * @memberof LabelSchemaStep
   */
  warnings?: Array<InputWarning>;
}

/**
 *
 * @export
 * @interface LabelSpaceConfig
 */
export interface LabelSpaceConfig {
  /**
   *
   * @type {string}
   * @memberof LabelSpaceConfig
   */
  cls_name: string;
  /**
   *
   * @type {object}
   * @memberof LabelSpaceConfig
   */
  kwargs: object;
}
/**
 *
 * @export
 * @interface LabelSpaceMixinsResponse
 */
export interface LabelSpaceMixinsResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof LabelSpaceMixinsResponse
   */
  mixins?: Array<string>;
}
/**
 *
 * @export
 * @interface LabelStats
 */
export interface LabelStats {
  /**
   *
   * @type {number}
   * @memberof LabelStats
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof LabelStats
   */
  distribution: number;
  /**
   *
   * @type {string}
   * @memberof LabelStats
   */
  label: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LabelType = {
  Single: 'single',
  Multi: 'multi',
  Sequence: 'sequence',
  Text: 'text',
  Word: 'word',
} as const;

export type LabelType = (typeof LabelType)[keyof typeof LabelType];

/**
 *
 * @export
 * @interface LabelWithAggregatedAnnotators
 */
export interface LabelWithAggregatedAnnotators {
  /**
   *
   * @type {number}
   * @memberof LabelWithAggregatedAnnotators
   */
  label_int: number;
  /**
   *
   * @type {string}
   * @memberof LabelWithAggregatedAnnotators
   */
  label_name: string;
  /**
   *
   * @type {Array<UserLabelInfo>}
   * @memberof LabelWithAggregatedAnnotators
   */
  labeled_absent_users?: Array<UserLabelInfo>;
  /**
   *
   * @type {Array<UserLabelInfo>}
   * @memberof LabelWithAggregatedAnnotators
   */
  labeled_by_users?: Array<UserLabelInfo>;
  /**
   *
   * @type {Array<UserLabelInfo>}
   * @memberof LabelWithAggregatedAnnotators
   */
  labeled_present_users?: Array<UserLabelInfo>;
  /**
   *
   * @type {Array<UserLabelInfo>}
   * @memberof LabelWithAggregatedAnnotators
   */
  labeled_unknown_users?: Array<UserLabelInfo>;
}
/**
 *
 * @export
 * @interface LicenseConfig
 */
export interface LicenseConfig {
  /**
   *
   * @type {AppLicenseType}
   * @memberof LicenseConfig
   */
  app_license_type?: AppLicenseType;
  /**
   *
   * @type {boolean}
   * @memberof LicenseConfig
   */
  is_archived?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LicenseConfig
   */
  is_excluded_from_au_usage?: boolean;
  /**
   *
   * @type {string}
   * @memberof LicenseConfig
   */
  last_archived_date?: string;
}

/**
 *
 * @export
 * @interface LicenseKey
 */
export interface LicenseKey {
  /**
   *
   * @type {AccountInfo}
   * @memberof LicenseKey
   */
  account_info: AccountInfo;
  /**
   *
   * @type {string}
   * @memberof LicenseKey
   */
  signature: string;
}
/**
 *
 * @export
 * @interface ListDatasetsResponse
 */
export interface ListDatasetsResponse {
  /**
   *
   * @type {boolean}
   * @memberof ListDatasetsResponse
   */
  are_all_datasources_arrow: boolean;
  /**
   *
   * @type {number}
   * @memberof ListDatasetsResponse
   */
  dataset_uid: number;
  /**
   *
   * @type {number}
   * @memberof ListDatasetsResponse
   */
  file_storage_config_uid?: number;
  /**
   *
   * @type {string}
   * @memberof ListDatasetsResponse
   */
  first_created?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof ListDatasetsResponse
   */
  hydrated_node_dag?: { [key: string]: object };
  /**
   *
   * @type {string}
   * @memberof ListDatasetsResponse
   */
  last_opened?: string;
  /**
   *
   * @type {DatasetMetadata}
   * @memberof ListDatasetsResponse
   */
  metadata?: DatasetMetadata;
  /**
   *
   * @type {string}
   * @memberof ListDatasetsResponse
   */
  name: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof ListDatasetsResponse
   */
  node_dag?: { [key: string]: object };
  /**
   *
   * @type {number}
   * @memberof ListDatasetsResponse
   */
  total_size_bytes?: number;
  /**
   *
   * @type {number}
   * @memberof ListDatasetsResponse
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface ListExternalLLMConfigsResponse
 */
export interface ListExternalLLMConfigsResponse {
  /**
   *
   * @type {Array<ExternalLLMConfig>}
   * @memberof ListExternalLLMConfigsResponse
   */
  external_llm_configs: Array<ExternalLLMConfig>;
}
/**
 *
 * @export
 * @interface ListIntegrationsParams
 */
export interface ListIntegrationsParams {
  /**
   *
   * @type {object}
   * @memberof ListIntegrationsParams
   */
  kwargs?: object;
  /**
   *
   * @type {string}
   * @memberof ListIntegrationsParams
   */
  secret_store?: string;
  /**
   *
   * @type {number}
   * @memberof ListIntegrationsParams
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface ListIntegrationsResponse
 */
export interface ListIntegrationsResponse {
  /**
   *
   * @type {{ [key: string]: { [key: string]: boolean; }; }}
   * @memberof ListIntegrationsResponse
   */
  integrations: { [key: string]: { [key: string]: boolean } };
}
/**
 *
 * @export
 * @interface ListNotificationsResponse
 */
export interface ListNotificationsResponse {
  /**
   *
   * @type {Array<Notification>}
   * @memberof ListNotificationsResponse
   */
  notifications: Array<Notification>;
}
/**
 *
 * @export
 * @interface ListSecretParams
 */
export interface ListSecretParams {
  /**
   *
   * @type {object}
   * @memberof ListSecretParams
   */
  kwargs?: object;
  /**
   *
   * @type {string}
   * @memberof ListSecretParams
   */
  secret_store?: string;
  /**
   *
   * @type {number}
   * @memberof ListSecretParams
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface ListSecretResponse
 */
export interface ListSecretResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof ListSecretResponse
   */
  keys: Array<string>;
}
/**
 *
 * @export
 * @interface ListUserResponse
 */
export interface ListUserResponse {
  /**
   *
   * @type {UserView}
   * @memberof ListUserResponse
   */
  default_view: UserView;
  /**
   *
   * @type {string}
   * @memberof ListUserResponse
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof ListUserResponse
   */
  is_active: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ListUserResponse
   */
  is_locked: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ListUserResponse
   */
  is_superadmin: boolean;
  /**
   *
   * @type {UserRole}
   * @memberof ListUserResponse
   */
  role: UserRole;
  /**
   *
   * @type {string}
   * @memberof ListUserResponse
   */
  timezone?: string;
  /**
   *
   * @type {number}
   * @memberof ListUserResponse
   */
  user_uid: number;
  /**
   *
   * @type {string}
   * @memberof ListUserResponse
   */
  username: string;
}

/**
 *
 * @export
 * @interface ListWorkspaceResponse
 */
export interface ListWorkspaceResponse {
  /**
   *
   * @type {Array<Workspace>}
   * @memberof ListWorkspaceResponse
   */
  workspaces: Array<Workspace>;
}
/**
 *
 * @export
 * @interface LoadConfig
 */
export interface LoadConfig {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof LoadConfig
   */
  col_types?: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof LoadConfig
   */
  context_datasource_uid?: number;
  /**
   *
   * @type {number}
   * @memberof LoadConfig
   */
  ds_schema_version?: number;
  /**
   *
   * @type {number}
   * @memberof LoadConfig
   */
  parent_datasource_uid?: number;
  /**
   *
   * @type {string}
   * @memberof LoadConfig
   */
  path: string;
  /**
   *
   * @type {object}
   * @memberof LoadConfig
   */
  reader_kwargs?: object;
  /**
   *
   * @type {Array<number>}
   * @memberof LoadConfig
   */
  references?: Array<number>;
  /**
   *
   * @type {SourceType}
   * @memberof LoadConfig
   */
  type?: SourceType;
  /**
   *
   * @type {string}
   * @memberof LoadConfig
   */
  uid_col?: string;
}

/**
 *
 * @export
 * @interface MTAPreferences
 */
export interface MTAPreferences {
  /**
   *
   * @type {string}
   * @memberof MTAPreferences
   */
  default_multi_label_class?: string;
  /**
   *
   * @type {boolean}
   * @memberof MTAPreferences
   */
  is_reviewer_mode?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof MTAPreferences
   */
  selected_fields?: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof MTAPreferences
   */
  selected_label_schemas?: Array<number>;
  /**
   *
   * @type {ViewConfig}
   * @memberof MTAPreferences
   */
  view_config?: ViewConfig;
}
/**
 *
 * @export
 * @interface MaxF1MacroGrowthByTagsResponse
 */
export interface MaxF1MacroGrowthByTagsResponse {
  /**
   *
   * @type {object}
   * @memberof MaxF1MacroGrowthByTagsResponse
   */
  tag_max_f1_macro_growth: object;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Membership = {
  Include: 'include',
  Exclude: 'exclude',
} as const;

export type Membership = (typeof Membership)[keyof typeof Membership];

/**
 *
 * @export
 * @interface MemoryProfile
 */
export interface MemoryProfile {
  /**
   *
   * @type {string}
   * @memberof MemoryProfile
   */
  profile: string;
  /**
   *
   * @type {string}
   * @memberof MemoryProfile
   */
  timestamp: string;
}
/**
 *
 * @export
 * @interface MemoryProfilingParams
 */
export interface MemoryProfilingParams {
  /**
   *
   * @type {PersistenceMode}
   * @memberof MemoryProfilingParams
   */
  persistence_mode?: PersistenceMode;
  /**
   *
   * @type {string}
   * @memberof MemoryProfilingParams
   */
  service: string;
}

/**
 *
 * @export
 * @interface MemoryProfilingStatusResponse
 */
export interface MemoryProfilingStatusResponse {
  /**
   *
   * @type {string}
   * @memberof MemoryProfilingStatusResponse
   */
  service: string;
  /**
   *
   * @type {string}
   * @memberof MemoryProfilingStatusResponse
   */
  status: string;
}
/**
 *
 * @export
 * @interface MemoryProfilingTraceResponse
 */
export interface MemoryProfilingTraceResponse {
  /**
   *
   * @type {Array<MemoryProfile>}
   * @memberof MemoryProfilingTraceResponse
   */
  profiles: Array<MemoryProfile>;
}
/**
 *
 * @export
 * @interface MergeAsset
 */
export interface MergeAsset {
  /**
   *
   * @type {TransferAnnotationsParams}
   * @memberof MergeAsset
   */
  annotation_param?: TransferAnnotationsParams;
  /**
   *
   * @type {TransferComments}
   * @memberof MergeAsset
   */
  comments_param?: TransferComments;
  /**
   *
   * @type {number}
   * @memberof MergeAsset
   */
  from_node_uid: number;
  /**
   *
   * @type {TransferGTs}
   * @memberof MergeAsset
   */
  gt_param?: TransferGTs;
  /**
   *
   * @type {TransferLFs}
   * @memberof MergeAsset
   */
  lf_param?: TransferLFs;
  /**
   *
   * @type {boolean}
   * @memberof MergeAsset
   */
  save_state?: boolean;
  /**
   *
   * @type {TransferTags}
   * @memberof MergeAsset
   */
  tags_param?: TransferTags;
  /**
   *
   * @type {number}
   * @memberof MergeAsset
   */
  user_uid: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const MergeType = {
  From: 'FROM',
  To: 'TO',
  Union: 'UNION',
} as const;

export type MergeType = (typeof MergeType)[keyof typeof MergeType];

/**
 *
 * @export
 * @interface Metadata
 */
export interface Metadata {
  /**
   *
   * @type {boolean}
   * @memberof Metadata
   */
  randomize?: boolean;
  /**
   *
   * @type {SelectionStrategy}
   * @memberof Metadata
   */
  selection_strategy?: SelectionStrategy;
  /**
   *
   * @type {SourceId}
   * @memberof Metadata
   */
  source_id?: SourceId;
  /**
   *
   * @type {string}
   * @memberof Metadata
   */
  source_type?: string;
}

/**
 * BaseModel for nodes in both the Metagraph for ApplicationTemplates and for nodes in an actual instantiated NodeDAG. Allows us to write algorithms that work on both actual DAGs and on DAG templates
 * @export
 * @interface MetagraphNode
 */
export interface MetagraphNode {
  /**
   *
   * @type {string}
   * @memberof MetagraphNode
   */
  committed_op_type?: string;
  /**
   *
   * @type {string}
   * @memberof MetagraphNode
   */
  expected_op_type: string;
  /**
   *
   * @type {Array<number>}
   * @memberof MetagraphNode
   */
  input_ids: Array<number>;
  /**
   *
   * @type {string}
   * @memberof MetagraphNode
   */
  node_cls?: string;
  /**
   *
   * @type {object}
   * @memberof MetagraphNode
   */
  node_config_overrides?: object;
  /**
   *
   * @type {boolean}
   * @memberof MetagraphNode
   */
  required?: boolean;
}
/**
 *
 * @export
 * @interface MetricComputeJobResponse
 */
export interface MetricComputeJobResponse {
  /**
   *
   * @type {MetricsComputeResponse}
   * @memberof MetricComputeJobResponse
   */
  detail?: MetricsComputeResponse;
  /**
   *
   * @type {string}
   * @memberof MetricComputeJobResponse
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface MetricResponse
 */
export interface MetricResponse {
  /**
   *
   * @type {string}
   * @memberof MetricResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof MetricResponse
   */
  display_name: string;
  /**
   *
   * @type {string}
   * @memberof MetricResponse
   */
  display_style: string;
  /**
   *
   * @type {string}
   * @memberof MetricResponse
   */
  id: string;
}
/**
 *
 * @export
 * @interface MetricsByTagsResponse
 */
export interface MetricsByTagsResponse {
  /**
   *
   * @type {object}
   * @memberof MetricsByTagsResponse
   */
  tag_metrics: object;
}
/**
 *
 * @export
 * @interface MetricsComputeResponse
 */
export interface MetricsComputeResponse {
  /**
   *
   * @type {number}
   * @memberof MetricsComputeResponse
   */
  dataset_uid: number;
  /**
   *
   * @type {{ [key: string]: MetricResponse; }}
   * @memberof MetricsComputeResponse
   */
  metrics: { [key: string]: MetricResponse };
  /**
   *
   * @type {{ [key: string]: ModelResponse; }}
   * @memberof MetricsComputeResponse
   */
  models: { [key: string]: ModelResponse };
  /**
   *
   * @type {{ [key: string]: { [key: string]: { [key: string]: { [key: string]: number; }; }; }; }}
   * @memberof MetricsComputeResponse
   */
  scores: {
    [key: string]: {
      [key: string]: { [key: string]: { [key: string]: number } };
    };
  };
  /**
   *
   * @type {{ [key: string]: SliceResponse; }}
   * @memberof MetricsComputeResponse
   */
  slices: { [key: string]: SliceResponse };
}
/**
 *
 * @export
 * @interface MissingPredictionMetadata
 */
export interface MissingPredictionMetadata {
  /**
   *
   * @type {string}
   * @memberof MissingPredictionMetadata
   */
  type?: MissingPredictionMetadataTypeEnum;
}

export const MissingPredictionMetadataTypeEnum = {
  Missing: 'missing',
} as const;

export type MissingPredictionMetadataTypeEnum =
  (typeof MissingPredictionMetadataTypeEnum)[keyof typeof MissingPredictionMetadataTypeEnum];

/**
 *
 * @export
 * @interface ModelConfig
 */
export interface ModelConfig {
  /**
   *
   * @type {string}
   * @memberof ModelConfig
   */
  config_name: string;
  /**
   *
   * @type {boolean}
   * @memberof ModelConfig
   */
  is_large?: boolean;
  /**
   *
   * @type {string}
   * @memberof ModelConfig
   */
  model_cls: string;
  /**
   *
   * @type {object}
   * @memberof ModelConfig
   */
  options: object;
}
/**
 *
 * @export
 * @interface ModelInfo
 */
export interface ModelInfo {
  /**
   *
   * @type {ModelMetadataConfig}
   * @memberof ModelInfo
   */
  config: ModelMetadataConfig;
  /**
   *
   * @type {boolean}
   * @memberof ModelInfo
   */
  is_active: boolean;
  /**
   *
   * @type {string}
   * @memberof ModelInfo
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ModelInfo
   */
  node_uid: number;
  /**
   *
   * @type {string}
   * @memberof ModelInfo
   */
  ts: string;
  /**
   *
   * @type {number}
   * @memberof ModelInfo
   */
  uid: number;
  /**
   *
   * @type {number}
   * @memberof ModelInfo
   */
  user_uid?: number;
}
/**
 *
 * @export
 * @interface ModelInputPayload
 */
export interface ModelInputPayload {
  /**
   *
   * @type {ModelMetadataConfig}
   * @memberof ModelInputPayload
   */
  config?: ModelMetadataConfig;
  /**
   *
   * @type {boolean}
   * @memberof ModelInputPayload
   */
  is_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof ModelInputPayload
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ModelInputPayload
   */
  user_uid?: number;
}
/**
 *
 * @export
 * @interface ModelMetadataConfig
 */
export interface ModelMetadataConfig {
  /**
   *
   * @type {CustomModelInfo}
   * @memberof ModelMetadataConfig
   */
  custom_model_info?: CustomModelInfo;
  /**
   *
   * @type {DeprecationInfo}
   * @memberof ModelMetadataConfig
   */
  deprecation_info?: DeprecationInfo;
  /**
   *
   * @type {string}
   * @memberof ModelMetadataConfig
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof ModelMetadataConfig
   */
  gt_version_uid?: number;
  /**
   *
   * @type {string}
   * @memberof ModelMetadataConfig
   */
  model_start_time?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ModelMetadataConfig
   */
  node_uids?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof ModelMetadataConfig
   */
  op_version_uid?: number;
  /**
   *
   * @type {boolean}
   * @memberof ModelMetadataConfig
   */
  register_model?: boolean;
  /**
   *
   * @type {object}
   * @memberof ModelMetadataConfig
   */
  sampler_config?: object;
  /**
   *
   * @type {string}
   * @memberof ModelMetadataConfig
   */
  saved_model_dir?: string;
  /**
   *
   * @type {object}
   * @memberof ModelMetadataConfig
   */
  search_space?: object;
  /**
   *
   * @type {string}
   * @memberof ModelMetadataConfig
   */
  snorkelflow_version?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ModelMetadataConfig
   */
  subset_classes?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof ModelMetadataConfig
   */
  task_uids?: Array<number>;
  /**
   *
   * @type {object}
   * @memberof ModelMetadataConfig
   */
  train_config?: object;
  /**
   *
   * @type {object}
   * @memberof ModelMetadataConfig
   */
  training_config?: object;
  /**
   *
   * @type {Array<number>}
   * @memberof ModelMetadataConfig
   */
  training_sets?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof ModelMetadataConfig
   */
  workflow_uids?: Array<number>;
}
/**
 *
 * @export
 * @interface ModelPreferences
 */
export interface ModelPreferences {
  /**
   *
   * @type {boolean}
   * @memberof ModelPreferences
   */
  auto_train?: boolean;
  /**
   *
   * @type {number}
   * @memberof ModelPreferences
   */
  sample_size?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ModelPreferences
   */
  selected_metrics?: Array<string>;
}
/**
 *
 * @export
 * @interface ModelRegistryListResponse
 */
export interface ModelRegistryListResponse {
  /**
   *
   * @type {Array<ModelRegistryMetadata>}
   * @memberof ModelRegistryListResponse
   */
  model_registries: Array<ModelRegistryMetadata>;
}
/**
 * For response from APIs that excludes sensitive credential information
 * @export
 * @interface ModelRegistryMetadata
 */
export interface ModelRegistryMetadata {
  /**
   *
   * @type {string}
   * @memberof ModelRegistryMetadata
   */
  creation_timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof ModelRegistryMetadata
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof ModelRegistryMetadata
   */
  model_registry_uid?: number;
  /**
   *
   * @type {string}
   * @memberof ModelRegistryMetadata
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ModelRegistryMetadata
   */
  tracking_server_uri: string;
  /**
   *
   * @type {number}
   * @memberof ModelRegistryMetadata
   */
  workspace_uid?: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ModelRegistryType = {
  Internal: 'INTERNAL',
  External: 'EXTERNAL',
} as const;

export type ModelRegistryType =
  (typeof ModelRegistryType)[keyof typeof ModelRegistryType];

/**
 *
 * @export
 * @interface ModelResponse
 */
export interface ModelResponse {
  /**
   *
   * @type {number}
   * @memberof ModelResponse
   */
  coverage: number;
  /**
   *
   * @type {string}
   * @memberof ModelResponse
   */
  display_name: string;
  /**
   *
   * @type {string}
   * @memberof ModelResponse
   */
  id: string;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ModelResponse
   */
  sample_size: { [key: string]: number };
}
/**
 *
 * @export
 * @interface ModelSupportStatusInput
 */
export interface ModelSupportStatusInput {
  /**
   *
   * @type {string}
   * @memberof ModelSupportStatusInput
   */
  model_name: string;
  /**
   *
   * @type {string}
   * @memberof ModelSupportStatusInput
   */
  warm_start_method: string;
  /**
   *
   * @type {number}
   * @memberof ModelSupportStatusInput
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface ModelSupportStatusResponse
 */
export interface ModelSupportStatusResponse {
  /**
   *
   * @type {SupportStatus}
   * @memberof ModelSupportStatusResponse
   */
  status: SupportStatus;
}

/**
 *
 * @export
 * @interface ModelTrainingResponse
 */
export interface ModelTrainingResponse {
  /**
   *
   * @type {string}
   * @memberof ModelTrainingResponse
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof ModelTrainingResponse
   */
  model_identifier: string;
  /**
   *
   * @type {string}
   * @memberof ModelTrainingResponse
   */
  model_start_time: string;
  /**
   *
   * @type {string}
   * @memberof ModelTrainingResponse
   */
  model_uuid: string;
  /**
   *
   * @type {number}
   * @memberof ModelTrainingResponse
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface ModelType
 */
export interface ModelType {
  /**
   *
   * @type {string}
   * @memberof ModelType
   */
  name: string;
}
/**
 *
 * @export
 * @interface ModifySliceRequest
 */
export interface ModifySliceRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ModifySliceRequest
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface MultiDataSourcesIngestionRequest
 */
export interface MultiDataSourcesIngestionRequest {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof MultiDataSourcesIngestionRequest
   */
  col_fill_values?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof MultiDataSourcesIngestionRequest
   */
  credential_kwargs?: string;
  /**
   *
   * @type {number}
   * @memberof MultiDataSourcesIngestionRequest
   */
  data_connector_config_uid?: number;
  /**
   *
   * @type {string}
   * @memberof MultiDataSourcesIngestionRequest
   */
  datestamp?: string;
  /**
   *
   * @type {boolean}
   * @memberof MultiDataSourcesIngestionRequest
   */
  generate_uid_col?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MultiDataSourcesIngestionRequest
   */
  load_to_model_nodes?: boolean;
  /**
   *
   * @type {number}
   * @memberof MultiDataSourcesIngestionRequest
   */
  parallel_core_fraction?: number;
  /**
   *
   * @type {Array<PathWithSplit>}
   * @memberof MultiDataSourcesIngestionRequest
   */
  paths_with_split?: Array<PathWithSplit>;
  /**
   *
   * @type {string}
   * @memberof MultiDataSourcesIngestionRequest
   */
  reader_kwargs?: string;
  /**
   *
   * @type {boolean}
   * @memberof MultiDataSourcesIngestionRequest
   */
  run_async?: boolean;
  /**
   *
   * @type {string}
   * @memberof MultiDataSourcesIngestionRequest
   */
  scheduler?: string;
  /**
   *
   * @type {boolean}
   * @memberof MultiDataSourcesIngestionRequest
   */
  skip_repartition?: boolean;
  /**
   *
   * @type {string}
   * @memberof MultiDataSourcesIngestionRequest
   */
  snorkel_generated_uid_col?: string;
  /**
   *
   * @type {string}
   * @memberof MultiDataSourcesIngestionRequest
   */
  source_type: string;
  /**
   *
   * @type {number}
   * @memberof MultiDataSourcesIngestionRequest
   */
  source_uid?: number;
  /**
   *
   * @type {StaticAssetColInfo}
   * @memberof MultiDataSourcesIngestionRequest
   */
  static_asset_col_info?: StaticAssetColInfo;
  /**
   *
   * @type {string}
   * @memberof MultiDataSourcesIngestionRequest
   */
  uid_col?: string;
}
/**
 *
 * @export
 * @interface MultiLabelPredictionMetadata
 */
export interface MultiLabelPredictionMetadata {
  /**
   *
   * @type {Array<number>}
   * @memberof MultiLabelPredictionMetadata
   */
  confidence: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof MultiLabelPredictionMetadata
   */
  entropy: Array<number>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof MultiLabelPredictionMetadata
   */
  prediction: { [key: string]: string };
  /**
   *
   * @type {Array<number>}
   * @memberof MultiLabelPredictionMetadata
   */
  probability_distribution?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof MultiLabelPredictionMetadata
   */
  type?: MultiLabelPredictionMetadataTypeEnum;
}

export const MultiLabelPredictionMetadataTypeEnum = {
  MultiLabel: 'multi_label',
} as const;

export type MultiLabelPredictionMetadataTypeEnum =
  (typeof MultiLabelPredictionMetadataTypeEnum)[keyof typeof MultiLabelPredictionMetadataTypeEnum];

/**
 *
 * @export
 * @interface Name
 */
export interface Name {}
/**
 * Base class to populate node_types dict.
 * @export
 * @interface Node
 */
export interface Node {
  /**
   *
   * @type {number}
   * @memberof Node
   */
  committed_op_version_uid?: number;
  /**
   *
   * @type {string}
   * @memberof Node
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Node
   */
  expected_op_type?: string;
  /**
   *
   * @type {LabelSchema}
   * @memberof Node
   */
  label_schema?: LabelSchema;
  /**
   *
   * @type {NodeConfig}
   * @memberof Node
   */
  node_config?: NodeConfig;
  /**
   *
   * @type {number}
   * @memberof Node
   */
  node_uid?: number;
}
/**
 *
 * @export
 * @interface NodeAUUsage
 */
export interface NodeAUUsage {
  /**
   *
   * @type {AUUsage}
   * @memberof NodeAUUsage
   */
  au_usage: AUUsage;
  /**
   *
   * @type {number}
   * @memberof NodeAUUsage
   */
  cardinality: number;
  /**
   *
   * @type {number}
   * @memberof NodeAUUsage
   */
  cost_per_class: number;
  /**
   *
   * @type {number}
   * @memberof NodeAUUsage
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface NodeAssets
 */
export interface NodeAssets {
  /**
   *
   * @type {string}
   * @memberof NodeAssets
   */
  all_xs_path?: string;
  /**
   *
   * @type {string}
   * @memberof NodeAssets
   */
  annotations_path?: string;
  /**
   *
   * @type {string}
   * @memberof NodeAssets
   */
  dev_xs_path?: string;
  /**
   *
   * @type {string}
   * @memberof NodeAssets
   */
  gt_path?: string;
  /**
   *
   * @type {string}
   * @memberof NodeAssets
   */
  lfs_path?: string;
  /**
   *
   * @type {string}
   * @memberof NodeAssets
   */
  models_path?: string;
  /**
   *
   * @type {string}
   * @memberof NodeAssets
   */
  tags_path?: string;
  /**
   *
   * @type {string}
   * @memberof NodeAssets
   */
  training_sets_path?: string;
}
/**
 * Class for saving, deleting, and manipulating Blocks of Nodes. Blocks are templated groups of nodes that are semantically related in some pre-defined way.
 * @export
 * @interface NodeBlock
 */
export interface NodeBlock {
  /**
   *
   * @type {number}
   * @memberof NodeBlock
   */
  application_uid: number;
  /**
   *
   * @type {BlockMetadata}
   * @memberof NodeBlock
   */
  block_metadata?: BlockMetadata;
  /**
   *
   * @type {number}
   * @memberof NodeBlock
   */
  block_uid?: number;
  /**
   *
   * @type {string}
   * @memberof NodeBlock
   */
  name: string;
  /**
   *
   * @type {Array<number>}
   * @memberof NodeBlock
   */
  node_uids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof NodeBlock
   */
  template_id: string;
}
/**
 * Data-only base class for Block.
 * @export
 * @interface NodeBlockBase
 */
export interface NodeBlockBase {
  /**
   *
   * @type {number}
   * @memberof NodeBlockBase
   */
  application_uid: number;
  /**
   *
   * @type {BlockMetadata}
   * @memberof NodeBlockBase
   */
  block_metadata?: BlockMetadata;
  /**
   *
   * @type {number}
   * @memberof NodeBlockBase
   */
  block_uid?: number;
  /**
   *
   * @type {string}
   * @memberof NodeBlockBase
   */
  name: string;
  /**
   *
   * @type {Array<number>}
   * @memberof NodeBlockBase
   */
  node_uids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof NodeBlockBase
   */
  template_id: string;
}
/**
 * Base model for Block Config. Actual block configs should subclass from this.
 * @export
 * @interface NodeConfig
 */
export interface NodeConfig {
  /**
   *
   * @type {Array<LabelSpaceConfig>}
   * @memberof NodeConfig
   */
  annotation_label_spaces?: Array<LabelSpaceConfig>;
  /**
   *
   * @type {boolean}
   * @memberof NodeConfig
   */
  auto_generate_negative_ground_truth_labels?: boolean;
  /**
   *
   * @type {{ [key: string]: ColumnMetadata; }}
   * @memberof NodeConfig
   */
  columns_metadata?: { [key: string]: ColumnMetadata };
  /**
   *
   * @type {string}
   * @memberof NodeConfig
   */
  context_datapoint_cls?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof NodeConfig
   */
  context_datapoint_cols?: Array<string>;
  /**
   *
   * @type {CandidateIEType}
   * @memberof NodeConfig
   */
  data_to_extract?: CandidateIEType;
  /**
   *
   * @type {string}
   * @memberof NodeConfig
   */
  datapoint_cls?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof NodeConfig
   */
  datapoint_cols?: Array<string>;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof NodeConfig
   */
  gt_parent_version_map?: { [key: string]: number };
  /**
   *
   * @type {number}
   * @memberof NodeConfig
   */
  gt_version_uid?: number;
  /**
   *
   * @type {boolean}
   * @memberof NodeConfig
   */
  hidden?: boolean;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof NodeConfig
   */
  label_map?: { [key: string]: number };
  /**
   *
   * @type {object}
   * @memberof NodeConfig
   */
  label_space_config?: object;
  /**
   *
   * @type {object}
   * @memberof NodeConfig
   */
  misc_node_info?: object;
  /**
   *
   * @type {string}
   * @memberof NodeConfig
   */
  node_name?: string;
  /**
   *
   * @type {OnboardingSettings}
   * @memberof NodeConfig
   */
  onboarding_settings?: OnboardingSettings;
  /**
   *
   * @type {boolean}
   * @memberof NodeConfig
   */
  read_initial_labels?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NodeConfig
   */
  safe_to_delete?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof NodeConfig
   */
  special_columns?: { [key: string]: string };
}

/**
 *
 * @export
 * @interface NodeDatasourceActivation
 */
export interface NodeDatasourceActivation {
  /**
   *
   * @type {number}
   * @memberof NodeDatasourceActivation
   */
  datasource_uid: number;
  /**
   *
   * @type {boolean}
   * @memberof NodeDatasourceActivation
   */
  is_active: boolean;
  /**
   *
   * @type {number}
   * @memberof NodeDatasourceActivation
   */
  node_uid: number;
  /**
   *
   * @type {boolean}
   * @memberof NodeDatasourceActivation
   */
  supports_dev: boolean;
}
/**
 *
 * @export
 * @interface NodeJobResponse
 */
export interface NodeJobResponse {
  /**
   *
   * @type {string}
   * @memberof NodeJobResponse
   */
  job_id: string;
  /**
   *
   * @type {number}
   * @memberof NodeJobResponse
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface NotebookSettings
 */
export interface NotebookSettings {
  /**
   *
   * @type {boolean}
   * @memberof NotebookSettings
   */
  export_enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotebookSettings
   */
  package_manager_enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotebookSettings
   */
  terminals_enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof NotebookSettings
   */
  version: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Notebooks = {
  Default: 'default',
  Explore: 'explore',
} as const;

export type Notebooks = (typeof Notebooks)[keyof typeof Notebooks];

/**
 *
 * @export
 * @interface Notification
 */
export interface Notification {
  /**
   *
   * @type {NotificationBody}
   * @memberof Notification
   */
  body: NotificationBody;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  created_at: string;
  /**
   *
   * @type {boolean}
   * @memberof Notification
   */
  is_read?: boolean;
  /**
   *
   * @type {NotificationType}
   * @memberof Notification
   */
  notification_type: NotificationType;
  /**
   *
   * @type {number}
   * @memberof Notification
   */
  notification_uid: number;
  /**
   *
   * @type {number}
   * @memberof Notification
   */
  user_uid: number;
}

/**
 *
 * @export
 * @interface NotificationBody
 */
export interface NotificationBody {
  /**
   *
   * @type {AssetUri}
   * @memberof NotificationBody
   */
  asset_uri: AssetUri;
  /**
   *
   * @type {string}
   * @memberof NotificationBody
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationBody
   */
  title: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const NotificationType = {
  CommentTag: 'COMMENT_TAG',
  CommitGt: 'COMMIT_GT',
  CompleteBatchAnnotation: 'COMPLETE_BATCH_ANNOTATION',
  CompleteLongRunningLf: 'COMPLETE_LONG_RUNNING_LF',
  CompleteModelTrainingJob: 'COMPLETE_MODEL_TRAINING_JOB',
} as const;

export type NotificationType =
  (typeof NotificationType)[keyof typeof NotificationType];

/**
 *
 * @export
 * @interface ObjectStorageResponse
 */
export interface ObjectStorageResponse {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ObjectStorageResponse
   */
  url: { [key: string]: string };
}
/**
 *
 * @export
 * @interface OidcCallbackResponseData
 */
export interface OidcCallbackResponseData {
  /**
   *
   * @type {string}
   * @memberof OidcCallbackResponseData
   */
  access_token: string;
  /**
   *
   * @type {string}
   * @memberof OidcCallbackResponseData
   */
  redirect_to?: string;
  /**
   *
   * @type {string}
   * @memberof OidcCallbackResponseData
   */
  refresh_token: string;
}
/**
 *
 * @export
 * @interface OidcClientSettings
 */
export interface OidcClientSettings {
  /**
   *
   * @type {string}
   * @memberof OidcClientSettings
   */
  authorization_endpoint?: string;
  /**
   *
   * @type {string}
   * @memberof OidcClientSettings
   */
  client_id?: string;
  /**
   *
   * @type {string}
   * @memberof OidcClientSettings
   */
  client_secret?: string;
  /**
   *
   * @type {string}
   * @memberof OidcClientSettings
   */
  issuer?: string;
  /**
   *
   * @type {string}
   * @memberof OidcClientSettings
   */
  jwks_uri?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OidcClientSettings
   */
  redirect_uris?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof OidcClientSettings
   */
  resource_id?: string;
  /**
   *
   * @type {string}
   * @memberof OidcClientSettings
   */
  token_endpoint?: string;
  /**
   *
   * @type {string}
   * @memberof OidcClientSettings
   */
  userinfo_endpoint?: string;
  /**
   *
   * @type {string}
   * @memberof OidcClientSettings
   */
  userinfo_handler?: string;
  /**
   *
   * @type {string}
   * @memberof OidcClientSettings
   */
  userinfo_uid?: string;
}
/**
 *
 * @export
 * @interface OidcStartSso
 */
export interface OidcStartSso {
  /**
   *
   * @type {string}
   * @memberof OidcStartSso
   */
  login: string;
  /**
   *
   * @type {string}
   * @memberof OidcStartSso
   */
  state: string;
}
/**
 *
 * @export
 * @interface OnDemandBackupRequest
 */
export interface OnDemandBackupRequest {
  /**
   *
   * @type {boolean}
   * @memberof OnDemandBackupRequest
   */
  sync?: boolean;
}
/**
 *
 * @export
 * @interface OnboardingPreferences
 */
export interface OnboardingPreferences {
  /**
   *
   * @type {boolean}
   * @memberof OnboardingPreferences
   */
  onboarded_suggested_lfs?: boolean;
}
/**
 *
 * @export
 * @interface OnboardingSettings
 */
export interface OnboardingSettings {
  /**
   *
   * @type {boolean}
   * @memberof OnboardingSettings
   */
  has_user_entered_studio?: boolean;
  /**
   *
   * @type {string}
   * @memberof OnboardingSettings
   */
  ie_type?: string;
  /**
   *
   * @type {string}
   * @memberof OnboardingSettings
   */
  image_field?: string;
  /**
   *
   * @type {SetupPDFType}
   * @memberof OnboardingSettings
   */
  pdf_type?: SetupPDFType;
  /**
   *
   * @type {string}
   * @memberof OnboardingSettings
   */
  pdf_url_field?: string;
  /**
   *
   * @type {string}
   * @memberof OnboardingSettings
   */
  primary_field?: string;
}

/**
 * Class for saving, deleting, and manipulating versions of Nodes, e.g. different model configs hyperparameters are stored as op-versions, until the \"best\" model is committed to the node
 * @export
 * @interface OpVersion
 */
export interface OpVersion {
  /**
   *
   * @type {string}
   * @memberof OpVersion
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof OpVersion
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof OpVersion
   */
  node_uid: number;
  /**
   *
   * @type {object}
   * @memberof OpVersion
   */
  op_config: object;
  /**
   *
   * @type {string}
   * @memberof OpVersion
   */
  op_type: string;
  /**
   *
   * @type {number}
   * @memberof OpVersion
   */
  op_version_uid?: number;
}
/**
 *
 * @export
 * @interface OpVersionPredictionsResponse
 */
export interface OpVersionPredictionsResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof OpVersionPredictionsResponse
   */
  predictions: Array<object>;
}
/**
 *
 * @export
 * @interface OpenAISecretsConfig
 */
export interface OpenAISecretsConfig {
  /**
   *
   * @type {string}
   * @memberof OpenAISecretsConfig
   */
  openai_api_key?: string;
  /**
   *
   * @type {string}
   * @memberof OpenAISecretsConfig
   */
  provider: OpenAISecretsConfigProviderEnum;
}

export const OpenAISecretsConfigProviderEnum = {
  Openai: 'openai',
} as const;

export type OpenAISecretsConfigProviderEnum =
  (typeof OpenAISecretsConfigProviderEnum)[keyof typeof OpenAISecretsConfigProviderEnum];

/**
 *
 * @export
 * @interface OperatorBasicInfo
 */
export interface OperatorBasicInfo {
  /**
   *
   * @type {string}
   * @memberof OperatorBasicInfo
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorBasicInfo
   */
  expected_op_type: string;
  /**
   *
   * @type {boolean}
   * @memberof OperatorBasicInfo
   */
  is_op_committed?: boolean;
  /**
   *
   * @type {string}
   * @memberof OperatorBasicInfo
   */
  long_description?: string;
  /**
   *
   * @type {number}
   * @memberof OperatorBasicInfo
   */
  node_uid: number;
  /**
   *
   * @type {string}
   * @memberof OperatorBasicInfo
   */
  operator_name_or_expected_type: string;
}
/**
 *
 * @export
 * @interface OperatorClassConfig
 */
export interface OperatorClassConfig {
  /**
   *
   * @type {string}
   * @memberof OperatorClassConfig
   */
  description?: string;
  /**
   *
   * @type {Array<OperatorExample>}
   * @memberof OperatorClassConfig
   */
  examples?: Array<OperatorExample>;
  /**
   *
   * @type {string}
   * @memberof OperatorClassConfig
   */
  expected_op_type?: string;
  /**
   *
   * @type {Array<InputFieldSpec>}
   * @memberof OperatorClassConfig
   */
  fit_inputs?: Array<InputFieldSpec>;
  /**
   *
   * @type {Array<InputFieldSpec>}
   * @memberof OperatorClassConfig
   */
  inputs?: Array<InputFieldSpec>;
  /**
   *
   * @type {string}
   * @memberof OperatorClassConfig
   */
  long_description?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorClassConfig
   */
  operator_name: string;
  /**
   *
   * @type {number}
   * @memberof OperatorClassConfig
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface OperatorClassPayload
 */
export interface OperatorClassPayload {
  /**
   *
   * @type {object}
   * @memberof OperatorClassPayload
   */
  code: object;
  /**
   *
   * @type {number}
   * @memberof OperatorClassPayload
   */
  dataset_uid?: number;
  /**
   *
   * @type {string}
   * @memberof OperatorClassPayload
   */
  name: string;
  /**
   *
   * @type {object}
   * @memberof OperatorClassPayload
   */
  op_config: object;
  /**
   *
   * @type {string}
   * @memberof OperatorClassPayload
   */
  op_type: string;
  /**
   *
   * @type {boolean}
   * @memberof OperatorClassPayload
   */
  overwrite?: boolean;
  /**
   *
   * @type {string}
   * @memberof OperatorClassPayload
   */
  task_type?: string;
  /**
   *
   * @type {number}
   * @memberof OperatorClassPayload
   */
  workspace_uid?: number;
}
/**
 * Data needed to initialize an operator.
 * @export
 * @interface OperatorConfig
 */
export interface OperatorConfig {
  /**
   *
   * @type {Array<number>}
   * @memberof OperatorConfig
   */
  input_ids: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof OperatorConfig
   */
  is_output?: boolean;
  /**
   *
   * @type {object}
   * @memberof OperatorConfig
   */
  op_asset?: object;
  /**
   *
   * @type {object}
   * @memberof OperatorConfig
   */
  op_config: object;
  /**
   *
   * @type {number}
   * @memberof OperatorConfig
   */
  op_impl_version?: number;
  /**
   *
   * @type {string}
   * @memberof OperatorConfig
   */
  op_type: string;
}
/**
 *
 * @export
 * @interface OperatorExample
 */
export interface OperatorExample {
  /**
   *
   * @type {string}
   * @memberof OperatorExample
   */
  description?: string;
  /**
   *
   * @type {object}
   * @memberof OperatorExample
   */
  input_df: object;
  /**
   *
   * @type {object}
   * @memberof OperatorExample
   */
  kwargs: object;
}
/**
 * Base model used by Operators and OpVersions to hold and validate the input payload for creating Operators and OpVersions respectively
 * @export
 * @interface OperatorPayload
 */
export interface OperatorPayload {
  /**
   *
   * @type {object}
   * @memberof OperatorPayload
   */
  code: object;
  /**
   *
   * @type {number}
   * @memberof OperatorPayload
   */
  dataset_uid?: number;
  /**
   *
   * @type {string}
   * @memberof OperatorPayload
   */
  name: string;
  /**
   *
   * @type {object}
   * @memberof OperatorPayload
   */
  op_config: object;
  /**
   *
   * @type {string}
   * @memberof OperatorPayload
   */
  op_type: string;
  /**
   *
   * @type {boolean}
   * @memberof OperatorPayload
   */
  overwrite?: boolean;
  /**
   *
   * @type {string}
   * @memberof OperatorPayload
   */
  task_type?: string;
  /**
   *
   * @type {number}
   * @memberof OperatorPayload
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface OptimalThresholdRequest
 */
export interface OptimalThresholdRequest {
  /**
   *
   * @type {number}
   * @memberof OptimalThresholdRequest
   */
  node_uid: number;
  /**
   *
   * @type {Array<any>}
   * @memberof OptimalThresholdRequest
   */
  predicted_labels: Array<any>;
  /**
   *
   * @type {Array<any>}
   * @memberof OptimalThresholdRequest
   */
  predicted_probs: Array<any>;
  /**
   *
   * @type {Array<string>}
   * @memberof OptimalThresholdRequest
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface OptimalThresholdResponse
 */
export interface OptimalThresholdResponse {
  /**
   *
   * @type {string}
   * @memberof OptimalThresholdResponse
   */
  job_id?: string;
  /**
   *
   * @type {number}
   * @memberof OptimalThresholdResponse
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface OptionModel
 */
export interface OptionModel {
  /**
   *
   * @type {string}
   * @memberof OptionModel
   */
  display_name: string;
  /**
   *
   * @type {Value}
   * @memberof OptionModel
   */
  value: Value;
}
/**
 *
 * @export
 * @interface PackageLabelTrainApiModel
 */
export interface PackageLabelTrainApiModel {
  /**
   *
   * @type {string}
   * @memberof PackageLabelTrainApiModel
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PackageLabelTrainApiModel
   */
  neg_pos_ratio?: number;
  /**
   *
   * @type {number}
   * @memberof PackageLabelTrainApiModel
   */
  sample_size?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof PackageLabelTrainApiModel
   */
  subset_classes?: Array<number>;
}
/**
 *
 * @export
 * @interface PackageLabelTrainResponse
 */
export interface PackageLabelTrainResponse {
  /**
   *
   * @type {string}
   * @memberof PackageLabelTrainResponse
   */
  job_id: string;
  /**
   *
   * @type {number}
   * @memberof PackageLabelTrainResponse
   */
  node_uid: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const PackageType = {
  SnorkelFlowPackage: 'SnorkelFlowPackage',
  MLflowModel: 'MLflowModel',
} as const;

export type PackageType = (typeof PackageType)[keyof typeof PackageType];

/**
 *
 * @export
 * @interface PatchBlockPayload
 */
export interface PatchBlockPayload {
  /**
   *
   * @type {string}
   * @memberof PatchBlockPayload
   */
  name?: string;
}
/**
 *
 * @export
 * @interface PatchDatasourcePayload
 */
export interface PatchDatasourcePayload {
  /**
   *
   * @type {string}
   * @memberof PatchDatasourcePayload
   */
  datasource_name: string;
}
/**
 *
 * @export
 * @interface PatchExportPayload
 */
export interface PatchExportPayload {
  /**
   *
   * @type {string}
   * @memberof PatchExportPayload
   */
  name?: string;
}
/**
 *
 * @export
 * @interface PatchNodePayload
 */
export interface PatchNodePayload {
  /**
   *
   * @type {object}
   * @memberof PatchNodePayload
   */
  patch_node_config: object;
}
/**
 *
 * @export
 * @interface PatchNodeResponse
 */
export interface PatchNodeResponse {
  /**
   *
   * @type {object}
   * @memberof PatchNodeResponse
   */
  node_config: object;
}
/**
 *
 * @export
 * @interface PatchTaskDatasourceParams
 */
export interface PatchTaskDatasourceParams {
  /**
   *
   * @type {boolean}
   * @memberof PatchTaskDatasourceParams
   */
  is_active?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchTaskDatasourceParams
   */
  supports_dev?: boolean;
}
/**
 *
 * @export
 * @interface PatchWorkspaceRolesPayload
 */
export interface PatchWorkspaceRolesPayload {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof PatchWorkspaceRolesPayload
   */
  user_role_mapping?: { [key: string]: any };
}
/**
 *
 * @export
 * @interface PathWithSplit
 */
export interface PathWithSplit {
  /**
   *
   * @type {string}
   * @memberof PathWithSplit
   */
  path: string;
  /**
   *
   * @type {string}
   * @memberof PathWithSplit
   */
  split?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const PersistenceMode = {
  Disk: 'disk',
  Inmemory: 'inmemory',
} as const;

export type PersistenceMode =
  (typeof PersistenceMode)[keyof typeof PersistenceMode];

/**
 *
 * @export
 * @interface PipPackage
 */
export interface PipPackage {
  /**
   *
   * @type {string}
   * @memberof PipPackage
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PipPackage
   */
  version: string;
}
/**
 *
 * @export
 * @interface PopulateDatasource
 */
export interface PopulateDatasource {
  /**
   *
   * @type {number}
   * @memberof PopulateDatasource
   */
  datasource_uid: number;
  /**
   *
   * @type {string}
   * @memberof PopulateDatasource
   */
  path: string;
  /**
   *
   * @type {object}
   * @memberof PopulateDatasource
   */
  reader_kwargs?: object;
  /**
   *
   * @type {number}
   * @memberof PopulateDatasource
   */
  source_type: number;
  /**
   *
   * @type {number}
   * @memberof PopulateDatasource
   */
  source_uid?: number;
  /**
   *
   * @type {string}
   * @memberof PopulateDatasource
   */
  split: string;
  /**
   *
   * @type {string}
   * @memberof PopulateDatasource
   */
  uid_col?: string;
}
/**
 *
 * @export
 * @interface PopulateDerivedDatasource
 */
export interface PopulateDerivedDatasource {
  /**
   *
   * @type {LoadConfig}
   * @memberof PopulateDerivedDatasource
   */
  config: LoadConfig;
  /**
   *
   * @type {number}
   * @memberof PopulateDerivedDatasource
   */
  datasource_uid: number;
  /**
   *
   * @type {DatasourceMetadataBase}
   * @memberof PopulateDerivedDatasource
   */
  metadata: DatasourceMetadataBase;
  /**
   *
   * @type {Provenance}
   * @memberof PopulateDerivedDatasource
   */
  provenance: Provenance;
  /**
   *
   * @type {number}
   * @memberof PopulateDerivedDatasource
   */
  source_type: number;
  /**
   *
   * @type {string}
   * @memberof PopulateDerivedDatasource
   */
  split: string;
}
/**
 *
 * @export
 * @interface PopulatorConfig
 */
export interface PopulatorConfig {
  /**
   *
   * @type {ApplicationConfig}
   * @memberof PopulatorConfig
   */
  application_config?: ApplicationConfig;
  /**
   *
   * @type {Array<NodeBlockBase>}
   * @memberof PopulatorConfig
   */
  blocks?: Array<NodeBlockBase>;
  /**
   *
   * @type {Array<object>}
   * @memberof PopulatorConfig
   */
  custom_operators?: Array<object>;
  /**
   *
   * @type {DatasetAssets}
   * @memberof PopulatorConfig
   */
  dataset_assets?: DatasetAssets;
  /**
   *
   * @type {{ [key: string]: HydratedDAGNode; }}
   * @memberof PopulatorConfig
   */
  dataset_hydrated_dag?: { [key: string]: HydratedDAGNode };
  /**
   *
   * @type {DatasetMetadata}
   * @memberof PopulatorConfig
   */
  dataset_metadata?: DatasetMetadata;
  /**
   *
   * @type {Array<PopulateDatasource>}
   * @memberof PopulatorConfig
   */
  datasources: Array<PopulateDatasource>;
  /**
   *
   * @type {Array<PopulateDerivedDatasource>}
   * @memberof PopulatorConfig
   */
  derived_datasources?: Array<PopulateDerivedDatasource>;
  /**
   *
   * @type {Array<EmbeddingsInfo>}
   * @memberof PopulatorConfig
   */
  embeddings_configs?: Array<EmbeddingsInfo>;
  /**
   *
   * @type {Array<EvaluationReportCreationRequest>}
   * @memberof PopulatorConfig
   */
  evaluation_reports?: Array<EvaluationReportCreationRequest>;
  /**
   *
   * @type {Array<string>}
   * @memberof PopulatorConfig
   */
  external_resource_columns?: Array<string>;
  /**
   *
   * @type {LoadConfig}
   * @memberof PopulatorConfig
   */
  feature_store_dump_config?: LoadConfig;
  /**
   *
   * @type {{ [key: string]: HydratedDAGNode; }}
   * @memberof PopulatorConfig
   */
  hydrated_dag: { [key: string]: HydratedDAGNode };
  /**
   *
   * @type {{ [key: string]: NodeAssets; }}
   * @memberof PopulatorConfig
   */
  node_assets?: { [key: string]: NodeAssets };
  /**
   *
   * @type {Array<NodeDatasourceActivation>}
   * @memberof PopulatorConfig
   */
  node_datasources?: Array<NodeDatasourceActivation>;
  /**
   *
   * @type {Array<string>}
   * @memberof PopulatorConfig
   */
  notebook_paths?: Array<string>;
}
/**
 *
 * @export
 * @interface PopulatorMetadata
 */
export interface PopulatorMetadata {
  /**
   *
   * @type {string}
   * @memberof PopulatorMetadata
   */
  application_type: string;
  /**
   *
   * @type {string}
   * @memberof PopulatorMetadata
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof PopulatorMetadata
   */
  created_by?: string;
  /**
   *
   * @type {string}
   * @memberof PopulatorMetadata
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PopulatorMetadata
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PopulatorMetadata
   */
  populator_path_at_creation?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PopulatorMetadata
   */
  supported_sf_versions?: Array<string>;
  /**
   *
   * @type {PopulatorTags}
   * @memberof PopulatorMetadata
   */
  tags?: PopulatorTags;
  /**
   *
   * @type {number}
   * @memberof PopulatorMetadata
   */
  ttl_seconds?: number;
}
/**
 *
 * @export
 * @interface PopulatorResponse
 */
export interface PopulatorResponse {
  /**
   *
   * @type {string}
   * @memberof PopulatorResponse
   */
  application_type: string;
  /**
   *
   * @type {string}
   * @memberof PopulatorResponse
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof PopulatorResponse
   */
  created_by: string;
  /**
   *
   * @type {string}
   * @memberof PopulatorResponse
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof PopulatorResponse
   */
  expired: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PopulatorResponse
   */
  is_compatible_with_sf_version: boolean;
  /**
   *
   * @type {string}
   * @memberof PopulatorResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PopulatorResponse
   */
  path: string;
  /**
   *
   * @type {PopulatorTags}
   * @memberof PopulatorResponse
   */
  tags: PopulatorTags;
}
/**
 *
 * @export
 * @interface PopulatorTags
 */
export interface PopulatorTags {
  /**
   *
   * @type {Array<string>}
   * @memberof PopulatorTags
   */
  label_spaces?: Array<string>;
}
/**
 *
 * @export
 * @interface PostTrainSampleDatasourceParams
 */
export interface PostTrainSampleDatasourceParams {
  /**
   *
   * @type {Array<number>}
   * @memberof PostTrainSampleDatasourceParams
   */
  datasource_uids_to_load?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof PostTrainSampleDatasourceParams
   */
  parallel_core_fraction?: number;
  /**
   *
   * @type {boolean}
   * @memberof PostTrainSampleDatasourceParams
   */
  read_initial_labels?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PostTrainSampleDatasourceParams
   */
  resample_size_check?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PostTrainSampleDatasourceParams
   */
  sample_by_docs?: boolean;
  /**
   *
   * @type {number}
   * @memberof PostTrainSampleDatasourceParams
   */
  sample_max_labeled?: number;
  /**
   *
   * @type {number}
   * @memberof PostTrainSampleDatasourceParams
   */
  sample_max_total?: number;
  /**
   *
   * @type {number}
   * @memberof PostTrainSampleDatasourceParams
   */
  sample_min_per_class?: number;
  /**
   *
   * @type {number}
   * @memberof PostTrainSampleDatasourceParams
   */
  sample_seed?: number;
  /**
   *
   * @type {string}
   * @memberof PostTrainSampleDatasourceParams
   */
  scheduler?: string;
}
/**
 *
 * @export
 * @interface PrecisionRecallCurveResponse
 */
export interface PrecisionRecallCurveResponse {
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof PrecisionRecallCurveResponse
   */
  precision_recall_curve: { [key: string]: any };
}
/**
 * Very simple class used to query which Prediction API services are running directly from the K8s API. We always attempt to query the K8s API to figure out what services are running rather than storing this information e.g. in Redis or Postgres as, even though this might be less efficient, we won\'t have to worry about falling out of sync.
 * @export
 * @interface PredictionAPI
 */
export interface PredictionAPI {
  /**
   *
   * @type {string}
   * @memberof PredictionAPI
   */
  creation_timestamp: string;
  /**
   *
   * @type {number}
   * @memberof PredictionAPI
   */
  deployment_uid: number;
  /**
   *
   * @type {string}
   * @memberof PredictionAPI
   */
  in_cluster_url: string;
  /**
   *
   * @type {string}
   * @memberof PredictionAPI
   */
  service_name: string;
}
/**
 *
 * @export
 * @interface PredictionAPIListResponse
 */
export interface PredictionAPIListResponse {
  /**
   *
   * @type {Array<PredictionAPI>}
   * @memberof PredictionAPIListResponse
   */
  prediction_apis: Array<PredictionAPI>;
}
/**
 *
 * @export
 * @interface Predictions
 */
export interface Predictions {
  /**
   *
   * @type {Array<any>}
   * @memberof Predictions
   */
  ground_truth_labels?: Array<any>;
  /**
   *
   * @type {number}
   * @memberof Predictions
   */
  model_uid?: number;
  /**
   *
   * @type {Array<any>}
   * @memberof Predictions
   */
  predicted_labels?: Array<any>;
  /**
   *
   * @type {Array<any>}
   * @memberof Predictions
   */
  predicted_probs?: Array<any>;
  /**
   *
   * @type {Array<string>}
   * @memberof Predictions
   */
  x_uids: Array<string>;
}
/**
 *
 * @export
 * @interface PrepAndIngestDatasourceRequest
 */
export interface PrepAndIngestDatasourceRequest {
  /**
   *
   * @type {string}
   * @memberof PrepAndIngestDatasourceRequest
   */
  input_type?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PrepAndIngestDatasourceRequest
   */
  paths: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof PrepAndIngestDatasourceRequest
   */
  run_datasource_checks?: boolean;
  /**
   *
   * @type {string}
   * @memberof PrepAndIngestDatasourceRequest
   */
  split?: string;
}
/**
 *
 * @export
 * @interface PrepAndIngestFinetuningDatasourceRequest
 */
export interface PrepAndIngestFinetuningDatasourceRequest {
  /**
   *
   * @type {{ [key: string]: FineTuningColumnType; }}
   * @memberof PrepAndIngestFinetuningDatasourceRequest
   */
  col_mappings: { [key: string]: FineTuningColumnType };
  /**
   *
   * @type {number}
   * @memberof PrepAndIngestFinetuningDatasourceRequest
   */
  dataset_uid: number;
  /**
   *
   * @type {SingleDataSourceIngestionRequest}
   * @memberof PrepAndIngestFinetuningDatasourceRequest
   */
  datasource_ingestion_request: SingleDataSourceIngestionRequest;
  /**
   *
   * @type {string}
   * @memberof PrepAndIngestFinetuningDatasourceRequest
   */
  prompt_template: string;
  /**
   *
   * @type {number}
   * @memberof PrepAndIngestFinetuningDatasourceRequest
   */
  source_uid: number;
}
/**
 * The response to preprocessing datasources  Parameters ---------- train_sample_ds_job_uid: Optional[str]     the job uid for the train sample datasource preprocessing, if we     create one active_datasources_job_uid: str     the job uid for the active datasource preprocessing (depends on the train sample preprocessing)
 * @export
 * @interface PreprocessDatasourcesResponse
 */
export interface PreprocessDatasourcesResponse {
  /**
   *
   * @type {string}
   * @memberof PreprocessDatasourcesResponse
   */
  active_datasources_job_uid: string;
  /**
   *
   * @type {string}
   * @memberof PreprocessDatasourcesResponse
   */
  train_sample_ds_job_uid?: string;
}
/**
 *
 * @export
 * @interface PreprocessFinetuningDatasourceResponseModel
 */
export interface PreprocessFinetuningDatasourceResponseModel {
  /**
   *
   * @type {string}
   * @memberof PreprocessFinetuningDatasourceResponseModel
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface PreprocessedDatasourceResponse
 */
export interface PreprocessedDatasourceResponse {
  /**
   *
   * @type {Array<Array<PreprocessedDatasourceResponseBumpedOpsAndPreprocessedVersionInnerInner>>}
   * @memberof PreprocessedDatasourceResponse
   */
  bumped_ops_and_preprocessed_version?: Array<
    Array<PreprocessedDatasourceResponseBumpedOpsAndPreprocessedVersionInnerInner>
  >;
  /**
   *
   * @type {LoadConfig}
   * @memberof PreprocessedDatasourceResponse
   */
  config: LoadConfig;
  /**
   *
   * @type {number}
   * @memberof PreprocessedDatasourceResponse
   */
  datasource_uid: number;
  /**
   *
   * @type {string}
   * @memberof PreprocessedDatasourceResponse
   */
  ds: string;
  /**
   *
   * @type {DatasourceRole}
   * @memberof PreprocessedDatasourceResponse
   */
  ds_role: DatasourceRole;
  /**
   *
   * @type {boolean}
   * @memberof PreprocessedDatasourceResponse
   */
  is_active: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PreprocessedDatasourceResponse
   */
  is_purely_op_impl_version_mismatch?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PreprocessedDatasourceResponse
   */
  is_stale?: boolean;
  /**
   *
   * @type {number}
   * @memberof PreprocessedDatasourceResponse
   */
  n_datapoints?: number;
  /**
   *
   * @type {number}
   * @memberof PreprocessedDatasourceResponse
   */
  n_docs?: number;
  /**
   *
   * @type {number}
   * @memberof PreprocessedDatasourceResponse
   */
  n_gt_labels?: number;
  /**
   *
   * @type {string}
   * @memberof PreprocessedDatasourceResponse
   */
  split: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PreprocessedDatasourceResponse
   */
  stale_reasons?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof PreprocessedDatasourceResponse
   */
  supports_dev: boolean;
  /**
   *
   * @type {number}
   * @memberof PreprocessedDatasourceResponse
   */
  type: number;
}

/**
 *
 * @export
 * @interface PreprocessedDatasourceResponseBumpedOpsAndPreprocessedVersionInnerInner
 */
export interface PreprocessedDatasourceResponseBumpedOpsAndPreprocessedVersionInnerInner {}
/**
 *
 * @export
 * @interface PreprocessingIssues
 */
export interface PreprocessingIssues {
  /**
   *
   * @type {Array<PreprocessingIssuesResponse>}
   * @memberof PreprocessingIssues
   */
  skipped_datapoints: Array<PreprocessingIssuesResponse>;
  /**
   *
   * @type {string}
   * @memberof PreprocessingIssues
   */
  warning: string;
}
/**
 *
 * @export
 * @interface PreprocessingIssuesResponse
 */
export interface PreprocessingIssuesResponse {
  /**
   *
   * @type {string}
   * @memberof PreprocessingIssuesResponse
   */
  check_type?: string;
  /**
   *
   * @type {string}
   * @memberof PreprocessingIssuesResponse
   */
  datapoint: string;
  /**
   *
   * @type {string}
   * @memberof PreprocessingIssuesResponse
   */
  error_msg?: string;
  /**
   *
   * @type {string}
   * @memberof PreprocessingIssuesResponse
   */
  node?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const PreprocessingSetting = {
  WhatDataWouldYouLikeToExtract: 'What data would you like to extract?',
  SplitDocsIntoPages: 'Split docs into pages',
} as const;

export type PreprocessingSetting =
  (typeof PreprocessingSetting)[keyof typeof PreprocessingSetting];

/**
 *
 * @export
 * @interface PreprocessingStep
 */
export interface PreprocessingStep {
  /**
   *
   * @type {Array<OperatorBasicInfo>}
   * @memberof PreprocessingStep
   */
  all_preprocessors?: Array<OperatorBasicInfo>;
  /**
   *
   * @type {boolean}
   * @memberof PreprocessingStep
   */
  is_user_input_needed?: boolean;
  /**
   *
   * @type {SetupStepName}
   * @memberof PreprocessingStep
   */
  name?: SetupStepName;
  /**
   *
   * @type {{ [key: string]: HydratedDAGNode; }}
   * @memberof PreprocessingStep
   */
  node_uid_to_committed_operator?: { [key: string]: HydratedDAGNode };
  /**
   *
   * @type {{ [key: string]: { [key: string]: OperatorClassConfig; }; }}
   * @memberof PreprocessingStep
   */
  node_uid_to_possible_operators?: {
    [key: string]: { [key: string]: OperatorClassConfig };
  };
  /**
   *
   * @type {Array<SetupSetting>}
   * @memberof PreprocessingStep
   */
  settings?: Array<SetupSetting>;
  /**
   *
   * @type {Array<InputWarning>}
   * @memberof PreprocessingStep
   */
  warnings?: Array<InputWarning>;
}

/**
 *
 * @export
 * @interface PreprocessingStepPayload
 */
export interface PreprocessingStepPayload {
  /**
   *
   * @type {Array<OperatorBasicInfo>}
   * @memberof PreprocessingStepPayload
   */
  all_preprocessors?: Array<OperatorBasicInfo>;
  /**
   *
   * @type {object}
   * @memberof PreprocessingStepPayload
   */
  extractor_payload?: object;
  /**
   *
   * @type {boolean}
   * @memberof PreprocessingStepPayload
   */
  is_user_input_needed?: boolean;
  /**
   *
   * @type {SetupStepName}
   * @memberof PreprocessingStepPayload
   */
  name?: SetupStepName;
  /**
   *
   * @type {{ [key: string]: HydratedDAGNode; }}
   * @memberof PreprocessingStepPayload
   */
  node_uid_to_committed_operator?: { [key: string]: HydratedDAGNode };
  /**
   *
   * @type {{ [key: string]: { [key: string]: OperatorClassConfig; }; }}
   * @memberof PreprocessingStepPayload
   */
  node_uid_to_possible_operators?: {
    [key: string]: { [key: string]: OperatorClassConfig };
  };
  /**
   *
   * @type {Array<SetupSetting>}
   * @memberof PreprocessingStepPayload
   */
  settings?: Array<SetupSetting>;
  /**
   *
   * @type {Array<InputWarning>}
   * @memberof PreprocessingStepPayload
   */
  warnings?: Array<InputWarning>;
}

/**
 * The response to the next preprocessing step
 * @export
 * @interface PreprocessingStepResponse
 */
export interface PreprocessingStepResponse {
  /**
   *
   * @type {Array<OperatorBasicInfo>}
   * @memberof PreprocessingStepResponse
   */
  all_preprocessors?: Array<OperatorBasicInfo>;
  /**
   *
   * @type {Array<InputFieldSpec>}
   * @memberof PreprocessingStepResponse
   */
  inputs_for_settings?: Array<InputFieldSpec>;
  /**
   *
   * @type {boolean}
   * @memberof PreprocessingStepResponse
   */
  is_user_input_needed?: boolean;
  /**
   *
   * @type {SetupStepName}
   * @memberof PreprocessingStepResponse
   */
  name?: SetupStepName;
  /**
   *
   * @type {{ [key: string]: HydratedDAGNode; }}
   * @memberof PreprocessingStepResponse
   */
  node_uid_to_committed_operator?: { [key: string]: HydratedDAGNode };
  /**
   *
   * @type {{ [key: string]: { [key: string]: OperatorClassConfig; }; }}
   * @memberof PreprocessingStepResponse
   */
  node_uid_to_possible_operators?: {
    [key: string]: { [key: string]: OperatorClassConfig };
  };
  /**
   *
   * @type {Array<SetupSetting>}
   * @memberof PreprocessingStepResponse
   */
  settings?: Array<SetupSetting>;
  /**
   *
   * @type {Array<InputWarning>}
   * @memberof PreprocessingStepResponse
   */
  warnings?: Array<InputWarning>;
}

/**
 *
 * @export
 * @interface PromptFMDatasetRequest
 */
export interface PromptFMDatasetRequest {
  /**
   *
   * @type {object}
   * @memberof PromptFMDatasetRequest
   */
  cache_info?: object;
  /**
   *
   * @type {object}
   * @memberof PromptFMDatasetRequest
   */
  fm_hyperparameters?: object;
  /**
   *
   * @type {string}
   * @memberof PromptFMDatasetRequest
   */
  model_name: string;
  /**
   *
   * @type {LLMType}
   * @memberof PromptFMDatasetRequest
   */
  model_type?: LLMType;
  /**
   *
   * @type {number}
   * @memberof PromptFMDatasetRequest
   */
  num_runs?: number;
  /**
   *
   * @type {string}
   * @memberof PromptFMDatasetRequest
   */
  prompt_template: string;
  /**
   *
   * @type {boolean}
   * @memberof PromptFMDatasetRequest
   */
  use_engine?: boolean;
  /**
   *
   * @type {number}
   * @memberof PromptFMDatasetRequest
   */
  workspace_uid: number;
  /**
   *
   * @type {Array<string>}
   * @memberof PromptFMDatasetRequest
   */
  x_uids: Array<string>;
}

/**
 *
 * @export
 * @interface PromptFMRequest
 */
export interface PromptFMRequest {
  /**
   *
   * @type {object}
   * @memberof PromptFMRequest
   */
  cache_info?: object;
  /**
   *
   * @type {object}
   * @memberof PromptFMRequest
   */
  fm_hyperparameters?: object;
  /**
   *
   * @type {Array<PromptFMRequestInputDataInner>}
   * @memberof PromptFMRequest
   */
  input_data: Array<PromptFMRequestInputDataInner>;
  /**
   *
   * @type {string}
   * @memberof PromptFMRequest
   */
  model_name: string;
  /**
   *
   * @type {LLMType}
   * @memberof PromptFMRequest
   */
  model_type?: LLMType;
  /**
   *
   * @type {number}
   * @memberof PromptFMRequest
   */
  num_runs?: number;
  /**
   *
   * @type {string}
   * @memberof PromptFMRequest
   */
  prompt_init_param?: string;
  /**
   *
   * @type {boolean}
   * @memberof PromptFMRequest
   */
  use_engine?: boolean;
  /**
   *
   * @type {number}
   * @memberof PromptFMRequest
   */
  workspace_uid: number;
}

/**
 *
 * @export
 * @interface PromptFMRequestInputDataInner
 */
export interface PromptFMRequestInputDataInner {}
/**
 *
 * @export
 * @interface PromptFMResponse
 */
export interface PromptFMResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof PromptFMResponse
   */
  data?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof PromptFMResponse
   */
  job_id?: string;
}
/**
 * Params used by get_predictor to retrieve a BaseLLMPredictor  Paramaters: ----------- model_type: str     The type of FM setup to use, e.g. setfit, sdnet, text2text, etc. model_name: str     The unique name of the FM to use, e.g. bigscience/T0pp prompt_text: str     The unformatted prompt to the FM primary_text_field: str     The name of the field to format the prompt with labels_to_targets: Dict[str, str]     A mapping of labels to targets, e.g. {\"SPAM\": \"0\", \"HAM\": \"1\"} output_code: str     Code used to map the FM output to a label, e.g. \"def map_output(output): return \'SPAM\' if \"win\" in output else \'HAM\'\" max_input_tokens: int     After this many tokens, the input will be truncated. use_rag: bool     Whether to use RAG for document chunking. Only supported for text2textqa for now. fuzzy_string_match_threshold: int     The threshold for fuzzy string matching between 0(no match) and 100(exact match). Only supported for pdf_text2text_extractor for now.
 * @export
 * @interface PromptInputModel
 */
export interface PromptInputModel {
  /**
   *
   * @type {number}
   * @memberof PromptInputModel
   */
  fuzzy_string_match_threshold?: number;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof PromptInputModel
   */
  labels_to_targets?: { [key: string]: string };
  /**
   *
   * @type {object}
   * @memberof PromptInputModel
   */
  model_hyperparameters?: object;
  /**
   *
   * @type {string}
   * @memberof PromptInputModel
   */
  model_name: string;
  /**
   *
   * @type {LLMType}
   * @memberof PromptInputModel
   */
  model_type: LLMType;
  /**
   *
   * @type {string}
   * @memberof PromptInputModel
   */
  output_code?: string;
  /**
   *
   * @type {string}
   * @memberof PromptInputModel
   */
  primary_text_field?: string;
  /**
   *
   * @type {string}
   * @memberof PromptInputModel
   */
  prompt_text: string;
  /**
   *
   * @type {boolean}
   * @memberof PromptInputModel
   */
  use_rag?: boolean;
}

/**
 * Workflow config used to produce a datasource.
 * @export
 * @interface Provenance
 */
export interface Provenance {
  /**
   *
   * @type {number}
   * @memberof Provenance
   */
  datasource_op_id: number;
  /**
   *
   * @type {{ [key: string]: OperatorConfig; }}
   * @memberof Provenance
   */
  workflow_config: { [key: string]: OperatorConfig };
}
/**
 *
 * @export
 * @interface PutDatasource
 */
export interface PutDatasource {
  /**
   *
   * @type {string}
   * @memberof PutDatasource
   */
  split: string;
}
/**
 *
 * @export
 * @interface PutDatasourceResponse
 */
export interface PutDatasourceResponse {
  /**
   *
   * @type {Array<number>}
   * @memberof PutDatasourceResponse
   */
  tasks: Array<number>;
}
/**
 *
 * @export
 * @interface PutNodePreprocessedDatasource
 */
export interface PutNodePreprocessedDatasource {
  /**
   *
   * @type {boolean}
   * @memberof PutNodePreprocessedDatasource
   */
  is_active?: boolean;
}
/**
 *
 * @export
 * @interface PutPostprocessorPayload
 */
export interface PutPostprocessorPayload {
  /**
   *
   * @type {Array<object>}
   * @memberof PutPostprocessorPayload
   */
  postprocessor_config: Array<object>;
}
/**
 *
 * @export
 * @interface PutWorkspacePayload
 */
export interface PutWorkspacePayload {
  /**
   *
   * @type {WorkspaceConfig}
   * @memberof PutWorkspacePayload
   */
  config: WorkspaceConfig;
  /**
   *
   * @type {string}
   * @memberof PutWorkspacePayload
   */
  name: string;
}
/**
 *
 * @export
 * @interface QueryPayload
 */
export interface QueryPayload {
  /**
   *
   * @type {string}
   * @memberof QueryPayload
   */
  query: string;
}
/**
 *
 * @export
 * @interface QueryResponse
 */
export interface QueryResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof QueryResponse
   */
  rows: Array<object>;
}
/**
 *
 * @export
 * @interface RQJobId
 */
export interface RQJobId {
  /**
   *
   * @type {string}
   * @memberof RQJobId
   */
  uid: string;
}
/**
 *
 * @export
 * @interface RQMetaJobId
 */
export interface RQMetaJobId {
  /**
   *
   * @type {string}
   * @memberof RQMetaJobId
   */
  uid: string;
}
/**
 *
 * @export
 * @interface RawLabels
 */
export interface RawLabels {}
/**
 *
 * @export
 * @interface RawUserSettingsJsons
 */
export interface RawUserSettingsJsons {
  /**
   *
   * @type {Array<UserSettingsJson>}
   * @memberof RawUserSettingsJsons
   */
  settings: Array<UserSettingsJson>;
}
/**
 *
 * @export
 * @interface RayMemory
 */
export interface RayMemory {
  /**
   *
   * @type {string}
   * @memberof RayMemory
   */
  memory_stats: string;
}
/**
 *
 * @export
 * @interface RecommendedWarmStartModel
 */
export interface RecommendedWarmStartModel {
  /**
   *
   * @type {string}
   * @memberof RecommendedWarmStartModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof RecommendedWarmStartModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RecommendedWarmStartModel
   */
  user_friendly_name: string;
}
/**
 *
 * @export
 * @interface RecommendedWarmStartModels
 */
export interface RecommendedWarmStartModels {
  /**
   *
   * @type {Array<RecommendedWarmStartModel>}
   * @memberof RecommendedWarmStartModels
   */
  models: Array<RecommendedWarmStartModel>;
  /**
   *
   * @type {string}
   * @memberof RecommendedWarmStartModels
   */
  note: string;
}
/**
 *
 * @export
 * @interface RecommendedWarmStartModelsResults
 */
export interface RecommendedWarmStartModelsResults {
  /**
   *
   * @type {{ [key: string]: RecommendedWarmStartModels; }}
   * @memberof RecommendedWarmStartModelsResults
   */
  results: { [key: string]: RecommendedWarmStartModels };
}
/**
 *
 * @export
 * @interface RecomputeEmbeddingsPayload
 */
export interface RecomputeEmbeddingsPayload {
  /**
   *
   * @type {Array<string>}
   * @memberof RecomputeEmbeddingsPayload
   */
  splits: Array<string>;
}
/**
 *
 * @export
 * @interface RefreshApplicationsParams
 */
export interface RefreshApplicationsParams {
  /**
   *
   * @type {Array<number>}
   * @memberof RefreshApplicationsParams
   */
  application_uids?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof RefreshApplicationsParams
   */
  dryrun?: boolean;
  /**
   *
   * @type {string}
   * @memberof RefreshApplicationsParams
   */
  end_timestamp?: string;
  /**
   *
   * @type {number}
   * @memberof RefreshApplicationsParams
   */
  num_apps?: number;
  /**
   *
   * @type {string}
   * @memberof RefreshApplicationsParams
   */
  start_timestamp?: string;
  /**
   *
   * @type {number}
   * @memberof RefreshApplicationsParams
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface RefreshApplicationsResponse
 */
export interface RefreshApplicationsResponse {
  /**
   *
   * @type {string}
   * @memberof RefreshApplicationsResponse
   */
  job_id?: string;
}
/**
 *
 * @export
 * @interface RefreshNodeDataParams
 */
export interface RefreshNodeDataParams {
  /**
   *
   * @type {boolean}
   * @memberof RefreshNodeDataParams
   */
  enable_caching?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RefreshNodeDataParams
   */
  read_initial_labels?: boolean;
  /**
   *
   * @type {string}
   * @memberof RefreshNodeDataParams
   */
  scheduler?: string;
  /**
   *
   * @type {boolean}
   * @memberof RefreshNodeDataParams
   */
  skip_repartition?: boolean;
}
/**
 *
 * @export
 * @interface RefreshToken
 */
export interface RefreshToken {
  /**
   *
   * @type {string}
   * @memberof RefreshToken
   */
  refresh_token: string;
}
/**
 *
 * @export
 * @interface RefreshTokenResponse
 */
export interface RefreshTokenResponse {
  /**
   *
   * @type {string}
   * @memberof RefreshTokenResponse
   */
  access_token: string;
  /**
   *
   * @type {number}
   * @memberof RefreshTokenResponse
   */
  expires_in: number;
  /**
   *
   * @type {string}
   * @memberof RefreshTokenResponse
   */
  refresh_token: string;
  /**
   *
   * @type {string}
   * @memberof RefreshTokenResponse
   */
  token_type: string;
}
/**
 *
 * @export
 * @interface RegisterIngestModelParams
 */
export interface RegisterIngestModelParams {
  /**
   *
   * @type {string}
   * @memberof RegisterIngestModelParams
   */
  model_id: string;
}
/**
 *
 * @export
 * @interface RegisterPredictModelJson
 */
export interface RegisterPredictModelJson {
  /**
   *
   * @type {object}
   * @memberof RegisterPredictModelJson
   */
  config: object;
  /**
   *
   * @type {string}
   * @memberof RegisterPredictModelJson
   */
  name: string;
}
/**
 *
 * @export
 * @interface RegistryAuthenticationCredentials
 */
export interface RegistryAuthenticationCredentials {
  /**
   *
   * @type {string}
   * @memberof RegistryAuthenticationCredentials
   */
  bearer_token?: string;
  /**
   *
   * @type {string}
   * @memberof RegistryAuthenticationCredentials
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof RegistryAuthenticationCredentials
   */
  username?: string;
}
/**
 * Provided by the user during creation of MLflowModel deployments to inform us on which registry to use for storage (either the default internal one or one of (possibly many) external ones
 * @export
 * @interface RegistrySelection
 */
export interface RegistrySelection {
  /**
   *
   * @type {number}
   * @memberof RegistrySelection
   */
  model_registry_uid?: number;
  /**
   *
   * @type {ModelRegistryType}
   * @memberof RegistrySelection
   */
  registry_type: ModelRegistryType;
}

/**
 * When running an MLflow model registry, one has the option of using an S3-compatible service for artifact storage. The registry can either proxy uploads to storage or can have the client upload directly. If we are to upload directly, we need the same access credentials as the server (i.e. we are not given signed URLs). The access key and secret key can be used to provide access to S3, and the S3 endpoint url can be used to point us to, for example, MinIO (or other S3-compatible storage)
 * @export
 * @interface RegistryStorageCredentials
 */
export interface RegistryStorageCredentials {
  /**
   *
   * @type {string}
   * @memberof RegistryStorageCredentials
   */
  aws_access_key_id?: string;
  /**
   *
   * @type {string}
   * @memberof RegistryStorageCredentials
   */
  aws_secret_access_key?: string;
  /**
   *
   * @type {string}
   * @memberof RegistryStorageCredentials
   */
  mlflow_s3_endpoint_url?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const RemoteStorageType = {
  Gcs: 'gcs',
  S3: 's3',
  Minio: 'minio',
} as const;

export type RemoteStorageType =
  (typeof RemoteStorageType)[keyof typeof RemoteStorageType];

/**
 *
 * @export
 * @interface RemoteUploadCredentials
 */
export interface RemoteUploadCredentials {
  /**
   *
   * @type {GCSUploadCredentials}
   * @memberof RemoteUploadCredentials
   */
  gcs?: GCSUploadCredentials;
  /**
   *
   * @type {S3UploadCredentials}
   * @memberof RemoteUploadCredentials
   */
  s3?: S3UploadCredentials;
}
/**
 *
 * @export
 * @interface RemoveDatasetRequest
 */
export interface RemoveDatasetRequest {
  /**
   *
   * @type {boolean}
   * @memberof RemoveDatasetRequest
   */
  force?: boolean;
}
/**
 *
 * @export
 * @interface RemoveDatasetResponse
 */
export interface RemoveDatasetResponse {
  /**
   *
   * @type {number}
   * @memberof RemoveDatasetResponse
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof RemoveDatasetResponse
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface RemoveDatasourceRequest
 */
export interface RemoveDatasourceRequest {
  /**
   *
   * @type {boolean}
   * @memberof RemoveDatasourceRequest
   */
  force?: boolean;
}
/**
 *
 * @export
 * @interface RemoveDatasourceResponse
 */
export interface RemoveDatasourceResponse {
  /**
   *
   * @type {number}
   * @memberof RemoveDatasourceResponse
   */
  datasource_uid: number;
  /**
   *
   * @type {string}
   * @memberof RemoveDatasourceResponse
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface ResetPasswordParams
 */
export interface ResetPasswordParams {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordParams
   */
  new_password: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordParams
   */
  username: string;
}
/**
 *
 * @export
 * @interface ResplitDataSourcesRequest
 */
export interface ResplitDataSourcesRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof ResplitDataSourcesRequest
   */
  datasource_uids: Array<number>;
  /**
   *
   * @type {SplitWiseDistribution}
   * @memberof ResplitDataSourcesRequest
   */
  split_pct?: SplitWiseDistribution;
  /**
   *
   * @type {number}
   * @memberof ResplitDataSourcesRequest
   */
  split_random_seed?: number;
}
/**
 *
 * @export
 * @interface ResplitDataSourcesResponseModel
 */
export interface ResplitDataSourcesResponseModel {
  /**
   *
   * @type {string}
   * @memberof ResplitDataSourcesResponseModel
   */
  job_uid: string;
  /**
   *
   * @type {string}
   * @memberof ResplitDataSourcesResponseModel
   */
  new_application_name: string;
  /**
   *
   * @type {string}
   * @memberof ResplitDataSourcesResponseModel
   */
  new_dataset_name: string;
}
/**
 *
 * @export
 * @interface ResponseFetchGroundTruthNodesNodeUidGroundTruthGet
 */
export interface ResponseFetchGroundTruthNodesNodeUidGroundTruthGet {}
/**
 *
 * @export
 * @interface ResponseFetchGroundTruthNodesNodeUidGroundTruthGetAnyOfValue
 */
export interface ResponseFetchGroundTruthNodesNodeUidGroundTruthGetAnyOfValue {}
/**
 *
 * @export
 * @interface ResponseFetchGroundTruthNodesNodeUidGroundTruthGetAnyOfValue1
 */
export interface ResponseFetchGroundTruthNodesNodeUidGroundTruthGetAnyOfValue1 {}
/**
 *
 * @export
 * @interface ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
 */
export interface ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet {
  /**
   *
   * @type {any}
   * @memberof ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  labels: any;
  /**
   *
   * @type {any}
   * @memberof ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  matrix: any;
  /**
   *
   * @type {any}
   * @memberof ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  error_counts: any;
  /**
   *
   * @type {any}
   * @memberof ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  gt_labels: any;
  /**
   *
   * @type {any}
   * @memberof ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  preds_labels: any;
  /**
   *
   * @type {any}
   * @memberof ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  fns: any;
  /**
   *
   * @type {any}
   * @memberof ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  fps: any;
  /**
   *
   * @type {any}
   * @memberof ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  histogram_labels: any;
}
/**
 *
 * @export
 * @interface ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet
 */
export interface ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet {
  /**
   *
   * @type {any}
   * @memberof ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet
   */
  confidence: any;
  /**
   *
   * @type {any}
   * @memberof ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet
   */
  entropy: any;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet
   */
  prediction: { [key: string]: any };
  /**
   *
   * @type {any}
   * @memberof ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet
   */
  probability_distribution: any;
  /**
   *
   * @type {any}
   * @memberof ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet
   */
  type?: ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGetTypeEnum;
}

export const ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGetTypeEnum =
  {
    SingleLabel: 'single_label',
    MultiLabel: 'multi_label',
    Missing: 'missing',
  } as const;

export type ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGetTypeEnum =
  (typeof ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGetTypeEnum)[keyof typeof ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGetTypeEnum];

/**
 *
 * @export
 * @interface RestoreBackupRequest
 */
export interface RestoreBackupRequest {
  /**
   *
   * @type {string}
   * @memberof RestoreBackupRequest
   */
  backup_id: string;
  /**
   *
   * @type {boolean}
   * @memberof RestoreBackupRequest
   */
  sync?: boolean;
}
/**
 *
 * @export
 * @interface Role
 */
export interface Role {
  /**
   *
   * @type {UserRole}
   * @memberof Role
   */
  role_type: UserRole;
  /**
   *
   * @type {number}
   * @memberof Role
   */
  role_uid: number;
  /**
   *
   * @type {number}
   * @memberof Role
   */
  workspace_uid?: number;
}

/**
 *
 * @export
 * @interface RunAllPromptInferenceRequest
 */
export interface RunAllPromptInferenceRequest {
  /**
   *
   * @type {boolean}
   * @memberof RunAllPromptInferenceRequest
   */
  generate_name?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof RunAllPromptInferenceRequest
   */
  inference_splits?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof RunAllPromptInferenceRequest
   */
  name: string;
  /**
   *
   * @type {PromptInputModel}
   * @memberof RunAllPromptInferenceRequest
   */
  prompt_input: PromptInputModel;
  /**
   *
   * @type {number}
   * @memberof RunAllPromptInferenceRequest
   */
  threshold?: number;
}
/**
 *
 * @export
 * @interface RunPromptOutputResponse
 */
export interface RunPromptOutputResponse {
  /**
   *
   * @type {string}
   * @memberof RunPromptOutputResponse
   */
  job_id: string;
}
/**
 *
 * @export
 * @interface S3UploadCredentials
 */
export interface S3UploadCredentials {
  /**
   *
   * @type {string}
   * @memberof S3UploadCredentials
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof S3UploadCredentials
   */
  secret: string;
  /**
   *
   * @type {string}
   * @memberof S3UploadCredentials
   */
  token?: string;
}
/**
 *
 * @export
 * @interface SagemakerSecretsConfig
 */
export interface SagemakerSecretsConfig {
  /**
   *
   * @type {string}
   * @memberof SagemakerSecretsConfig
   */
  'aws::finetuning::access_key_id'?: string;
  /**
   *
   * @type {string}
   * @memberof SagemakerSecretsConfig
   */
  'aws::finetuning::region'?: string;
  /**
   *
   * @type {string}
   * @memberof SagemakerSecretsConfig
   */
  'aws::finetuning::sagemaker_execution_role'?: string;
  /**
   *
   * @type {string}
   * @memberof SagemakerSecretsConfig
   */
  'aws::finetuning::secret_access_key'?: string;
  /**
   *
   * @type {string}
   * @memberof SagemakerSecretsConfig
   */
  provider: SagemakerSecretsConfigProviderEnum;
}

export const SagemakerSecretsConfigProviderEnum = {
  Sagemaker: 'sagemaker',
} as const;

export type SagemakerSecretsConfigProviderEnum =
  (typeof SagemakerSecretsConfigProviderEnum)[keyof typeof SagemakerSecretsConfigProviderEnum];

/**
 *
 * @export
 * @interface SamlIdpSettings
 */
export interface SamlIdpSettings {
  /**
   *
   * @type {string}
   * @memberof SamlIdpSettings
   */
  entity_id?: string;
  /**
   *
   * @type {string}
   * @memberof SamlIdpSettings
   */
  sso_binding?: string;
  /**
   *
   * @type {string}
   * @memberof SamlIdpSettings
   */
  sso_url?: string;
  /**
   *
   * @type {string}
   * @memberof SamlIdpSettings
   */
  x509_cert?: string;
}
/**
 * Includes metadata about the SAML response as well as access/refresh tokens for the Snorkel Flow UI.
 * @export
 * @interface SamlResponseData
 */
export interface SamlResponseData {
  /**
   *
   * @type {string}
   * @memberof SamlResponseData
   */
  access_token: string;
  /**
   *
   * @type {string}
   * @memberof SamlResponseData
   */
  redirect_to?: string;
  /**
   *
   * @type {string}
   * @memberof SamlResponseData
   */
  refresh_token: string;
}
/**
 *
 * @export
 * @interface SamlSettingsResponse
 */
export interface SamlSettingsResponse {
  /**
   *
   * @type {SamlIdpSettings}
   * @memberof SamlSettingsResponse
   */
  idp_settings: SamlIdpSettings;
  /**
   *
   * @type {SamlSpSettings}
   * @memberof SamlSettingsResponse
   */
  sp_settings: SamlSpSettings;
}
/**
 *
 * @export
 * @interface SamlSpSettings
 */
export interface SamlSpSettings {
  /**
   *
   * @type {string}
   * @memberof SamlSpSettings
   */
  acs_binding: string;
  /**
   *
   * @type {string}
   * @memberof SamlSpSettings
   */
  acs_url: string;
  /**
   *
   * @type {string}
   * @memberof SamlSpSettings
   */
  entity_id: string;
  /**
   *
   * @type {string}
   * @memberof SamlSpSettings
   */
  name_id_format: string;
  /**
   *
   * @type {boolean}
   * @memberof SamlSpSettings
   */
  signed_response: boolean;
  /**
   *
   * @type {string}
   * @memberof SamlSpSettings
   */
  x509_cert: string;
}
/**
 *
 * @export
 * @interface SamlStartSso
 */
export interface SamlStartSso {
  /**
   *
   * @type {string}
   * @memberof SamlStartSso
   */
  login: string;
}
/**
 * The response to saving the setup progress
 * @export
 * @interface SaveSetupProgressResponse
 */
export interface SaveSetupProgressResponse {
  /**
   *
   * @type {number}
   * @memberof SaveSetupProgressResponse
   */
  embedding_uid?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SaveSetupProgressResponse
   */
  job_uids?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof SaveSetupProgressResponse
   */
  success?: boolean;
  /**
   *
   * @type {Array<InputWarning>}
   * @memberof SaveSetupProgressResponse
   */
  warnings?: Array<InputWarning>;
}
/**
 *
 * @export
 * @interface ScoreAnnotationsParams
 */
export interface ScoreAnnotationsParams {
  /**
   *
   * @type {number}
   * @memberof ScoreAnnotationsParams
   */
  batch_uid?: number;
  /**
   *
   * @type {number}
   * @memberof ScoreAnnotationsParams
   */
  expert_source_uid?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof ScoreAnnotationsParams
   */
  source_uids: Array<number>;
}
/**
 *
 * @export
 * @interface ScoreAnnotationsResponse
 */
export interface ScoreAnnotationsResponse {
  /**
   *
   * @type {{ [key: string]: ScoreType; }}
   * @memberof ScoreAnnotationsResponse
   */
  scores: { [key: string]: ScoreType };
}
/**
 *
 * @export
 * @interface ScoreRequest
 */
export interface ScoreRequest {
  /**
   *
   * @type {string}
   * @memberof ScoreRequest
   */
  df_serialize?: string;
  /**
   *
   * @type {object}
   * @memberof ScoreRequest
   */
  ground_truth?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof ScoreRequest
   */
  metrics: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ScoreRequest
   */
  op_version_uid?: number;
  /**
   *
   * @type {object}
   * @memberof ScoreRequest
   */
  prediction?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof ScoreRequest
   */
  splits?: Array<string>;
}
/**
 *
 * @export
 * @interface ScoreResponse
 */
export interface ScoreResponse {
  /**
   *
   * @type {string}
   * @memberof ScoreResponse
   */
  job_id?: string;
  /**
   *
   * @type {number}
   * @memberof ScoreResponse
   */
  node_uid: number;
}
/**
 *
 * @export
 * @interface ScoreType
 */
export interface ScoreType {
  /**
   *
   * @type {number}
   * @memberof ScoreType
   */
  score: number;
  /**
   *
   * @type {string}
   * @memberof ScoreType
   */
  score_type: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SearchStrategy = {
  Grid: 'grid',
  Bayes: 'bayes',
} as const;

export type SearchStrategy =
  (typeof SearchStrategy)[keyof typeof SearchStrategy];

/**
 *
 * @export
 * @interface SearchTrainingSetResponse
 */
export interface SearchTrainingSetResponse {
  /**
   *
   * @type {RawLabels}
   * @memberof SearchTrainingSetResponse
   */
  raw_labels?: RawLabels;
  /**
   *
   * @type {Array<ResponseFetchGroundTruthNodesNodeUidGroundTruthGetAnyOfValue1>}
   * @memberof SearchTrainingSetResponse
   */
  user_labels?: Array<ResponseFetchGroundTruthNodesNodeUidGroundTruthGetAnyOfValue1>;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchTrainingSetResponse
   */
  x_uids: Array<string>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SelectionStrategy = {
  Random: 'random',
  ModelConfidence: 'model_confidence',
  ModelEntropy: 'model_entropy',
} as const;

export type SelectionStrategy =
  (typeof SelectionStrategy)[keyof typeof SelectionStrategy];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ServiceType = {
  FlowUi: 'flow-ui',
  StudioApi: 'studio-api',
  StudioDask: 'studio-dask',
  TdmApi: 'tdm-api',
  Engine: 'engine',
  EngineTiny: 'engine-tiny',
  ModelTrainer: 'model-trainer',
  Notebook: 'notebook',
  Redis: 'redis',
  Db: 'db',
  Minio: 'minio',
  Influxdb: 'influxdb',
  Telegraf: 'telegraf',
  Grafana: 'grafana',
  EnvoyFrontProxy: 'envoy-front-proxy',
  PredictionApi: 'prediction-api',
  ModelRegistry: 'model-registry',
  Jupyterhub: 'jupyterhub',
  JupyterhubProxy: 'jupyterhub-proxy',
  RayHead: 'ray-head',
  RayWorker: 'ray-worker',
  RayGpuWorker: 'ray-gpu-worker',
  StudioRayHead: 'studio-ray-head',
  StudioRayWorker: 'studio-ray-worker',
  AuthorizationApi: 'authorization-api',
  StorageApi: 'storage-api',
  Admin: 'admin',
  EngineDask: 'engine-dask',
  EngineDaskScheduler: 'engine-dask-scheduler',
  InferenceService: 'inference-service',
} as const;

export type ServiceType = (typeof ServiceType)[keyof typeof ServiceType];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ServicesRbacModelsResourceType = {
  DataConnector: 'data_connector',
  DataConnectorConfigSnowflake: 'data_connector_config_snowflake',
  DataConnectorConfigDatabricksSql: 'data_connector_config_databricks_sql',
  DataConnectorConfigSqldb: 'data_connector_config_sqldb',
  DataConnectorConfigGoogleBigquery: 'data_connector_config_google_bigquery',
  Node: 'node',
  Application: 'application',
  Deployment: 'deployment',
  Dataset: 'dataset',
  StaticAssetUploadMethod: 'static_asset_upload_method',
  Logs: 'logs',
  WorkspaceScopedFeature: 'workspace_scoped_feature',
  SystemScopedFeature: 'system_scoped_feature',
} as const;

export type ServicesRbacModelsResourceType =
  (typeof ServicesRbacModelsResourceType)[keyof typeof ServicesRbacModelsResourceType];

/**
 *
 * @export
 * @interface SetDefaultFileStorageConfigParams
 */
export interface SetDefaultFileStorageConfigParams {
  /**
   *
   * @type {number}
   * @memberof SetDefaultFileStorageConfigParams
   */
  file_storage_config_uid: number;
}
/**
 *
 * @export
 * @interface SetIntegrationParams
 */
export interface SetIntegrationParams {
  /**
   *
   * @type {object}
   * @memberof SetIntegrationParams
   */
  kwargs?: object;
  /**
   *
   * @type {string}
   * @memberof SetIntegrationParams
   */
  secret_store?: string;
  /**
   *
   * @type {FMSecretsConfig}
   * @memberof SetIntegrationParams
   */
  secrets: FMSecretsConfig;
  /**
   *
   * @type {number}
   * @memberof SetIntegrationParams
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface SetNodeDataParams
 */
export interface SetNodeDataParams {
  /**
   *
   * @type {Array<number>}
   * @memberof SetNodeDataParams
   */
  datasource_uids_to_load?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof SetNodeDataParams
   */
  enable_caching?: boolean;
  /**
   *
   * @type {string}
   * @memberof SetNodeDataParams
   */
  scheduler?: string;
}
/**
 *
 * @export
 * @interface SetSecretParams
 */
export interface SetSecretParams {
  /**
   *
   * @type {string}
   * @memberof SetSecretParams
   */
  key: string;
  /**
   *
   * @type {object}
   * @memberof SetSecretParams
   */
  kwargs?: object;
  /**
   *
   * @type {string}
   * @memberof SetSecretParams
   */
  secret_store?: string;
  /**
   *
   * @type {Value1}
   * @memberof SetSecretParams
   */
  value: Value1;
  /**
   *
   * @type {number}
   * @memberof SetSecretParams
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface SettingValue
 */
export interface SettingValue {
  /**
   *
   * @type {any}
   * @memberof SettingValue
   */
  value?: any;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SetupPDFType = {
  NativePdf: 'Native PDF',
  ScannedPdfNeedToRunOcr: 'Scanned PDF, need to run OCR',
  ScannedPdfNoNeedToRunOcr: 'Scanned PDF, no need to run OCR',
} as const;

export type SetupPDFType = (typeof SetupPDFType)[keyof typeof SetupPDFType];

/**
 *
 * @export
 * @interface SetupSetting
 */
export interface SetupSetting {
  /**
   *
   * @type {InputFieldSpec}
   * @memberof SetupSetting
   */
  input_field_spec?: InputFieldSpec;
  /**
   *
   * @type {Name}
   * @memberof SetupSetting
   */
  name: Name;
  /**
   *
   * @type {Value2}
   * @memberof SetupSetting
   */
  value?: Value2;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SetupStepName = {
  DataTaskType: 'Data & Task Type',
  Preprocessing: 'Preprocessing',
  LabelSchema: 'Label Schema',
  DevelopmentSettings: 'Development Settings',
} as const;

export type SetupStepName = (typeof SetupStepName)[keyof typeof SetupStepName];

/**
 *
 * @export
 * @interface SingleDataSourceIngestionRequest
 */
export interface SingleDataSourceIngestionRequest {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof SingleDataSourceIngestionRequest
   */
  col_fill_values?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof SingleDataSourceIngestionRequest
   */
  credential_kwargs?: string;
  /**
   *
   * @type {number}
   * @memberof SingleDataSourceIngestionRequest
   */
  data_connector_config_uid?: number;
  /**
   *
   * @type {string}
   * @memberof SingleDataSourceIngestionRequest
   */
  datestamp?: string;
  /**
   *
   * @type {boolean}
   * @memberof SingleDataSourceIngestionRequest
   */
  generate_uid_col?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SingleDataSourceIngestionRequest
   */
  load_to_model_nodes?: boolean;
  /**
   *
   * @type {string}
   * @memberof SingleDataSourceIngestionRequest
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof SingleDataSourceIngestionRequest
   */
  parallel_core_fraction?: number;
  /**
   *
   * @type {string}
   * @memberof SingleDataSourceIngestionRequest
   */
  path: string;
  /**
   *
   * @type {string}
   * @memberof SingleDataSourceIngestionRequest
   */
  reader_kwargs?: string;
  /**
   *
   * @type {boolean}
   * @memberof SingleDataSourceIngestionRequest
   */
  run_async?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SingleDataSourceIngestionRequest
   */
  run_datasource_checks?: boolean;
  /**
   *
   * @type {string}
   * @memberof SingleDataSourceIngestionRequest
   */
  scheduler?: string;
  /**
   *
   * @type {boolean}
   * @memberof SingleDataSourceIngestionRequest
   */
  skip_repartition?: boolean;
  /**
   *
   * @type {string}
   * @memberof SingleDataSourceIngestionRequest
   */
  snorkel_generated_uid_col?: string;
  /**
   *
   * @type {string}
   * @memberof SingleDataSourceIngestionRequest
   */
  source_type: string;
  /**
   *
   * @type {number}
   * @memberof SingleDataSourceIngestionRequest
   */
  source_uid?: number;
  /**
   *
   * @type {string}
   * @memberof SingleDataSourceIngestionRequest
   */
  split?: string;
  /**
   *
   * @type {StaticAssetColInfo}
   * @memberof SingleDataSourceIngestionRequest
   */
  static_asset_col_info?: StaticAssetColInfo;
  /**
   *
   * @type {string}
   * @memberof SingleDataSourceIngestionRequest
   */
  uid_col?: string;
}
/**
 *
 * @export
 * @interface SingleLabelPredictionMetadata
 */
export interface SingleLabelPredictionMetadata {
  /**
   *
   * @type {number}
   * @memberof SingleLabelPredictionMetadata
   */
  confidence: number;
  /**
   *
   * @type {number}
   * @memberof SingleLabelPredictionMetadata
   */
  entropy: number;
  /**
   *
   * @type {string}
   * @memberof SingleLabelPredictionMetadata
   */
  prediction: string;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof SingleLabelPredictionMetadata
   */
  probability_distribution: { [key: string]: number };
  /**
   *
   * @type {string}
   * @memberof SingleLabelPredictionMetadata
   */
  type?: SingleLabelPredictionMetadataTypeEnum;
}

export const SingleLabelPredictionMetadataTypeEnum = {
  SingleLabel: 'single_label',
} as const;

export type SingleLabelPredictionMetadataTypeEnum =
  (typeof SingleLabelPredictionMetadataTypeEnum)[keyof typeof SingleLabelPredictionMetadataTypeEnum];

/**
 *
 * @export
 * @interface Slice
 */
export interface Slice {
  /**
   *
   * @type {SliceConfig}
   * @memberof Slice
   */
  config?: SliceConfig;
  /**
   *
   * @type {string}
   * @memberof Slice
   */
  config_updated_at: string;
  /**
   *
   * @type {number}
   * @memberof Slice
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof Slice
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Slice
   */
  display_name: string;
  /**
   *
   * @type {object}
   * @memberof Slice
   */
  metadata?: object;
  /**
   *
   * @type {number}
   * @memberof Slice
   */
  slice_uid: number;
  /**
   *
   * @type {number}
   * @memberof Slice
   */
  user_uid?: number;
}
/**
 *
 * @export
 * @interface SliceApplyParams
 */
export interface SliceApplyParams {
  /**
   *
   * @type {number}
   * @memberof SliceApplyParams
   */
  dataset_uid: number;
  /**
   *
   * @type {number}
   * @memberof SliceApplyParams
   */
  node_uid?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof SliceApplyParams
   */
  slice_uids: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof SliceApplyParams
   */
  splits?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SliceApplyParams
   */
  user_uid?: number;
}
/**
 *
 * @export
 * @interface SliceConfig
 */
export interface SliceConfig {
  /**
   *
   * @type {Array<any>}
   * @memberof SliceConfig
   */
  graph: Array<any>;
  /**
   *
   * @type {Array<object>}
   * @memberof SliceConfig
   */
  templates: Array<object>;
}
/**
 *
 * @export
 * @interface SliceCreationRequest
 */
export interface SliceCreationRequest {
  /**
   *
   * @type {SliceConfig}
   * @memberof SliceCreationRequest
   */
  config?: SliceConfig;
  /**
   *
   * @type {number}
   * @memberof SliceCreationRequest
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof SliceCreationRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SliceCreationRequest
   */
  display_name: string;
  /**
   *
   * @type {object}
   * @memberof SliceCreationRequest
   */
  metadata?: object;
  /**
   *
   * @type {number}
   * @memberof SliceCreationRequest
   */
  user_uid?: number;
}
/**
 * A wrapper around data returned to the FE to render slice Filter options.
 * @export
 * @interface SliceFilterStructureModel
 */
export interface SliceFilterStructureModel {
  /**
   *
   * @type {string}
   * @memberof SliceFilterStructureModel
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof SliceFilterStructureModel
   */
  filter_type: string;
  /**
   *
   * @type {string}
   * @memberof SliceFilterStructureModel
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SliceFilterStructureModel
   */
  operators: Array<string>;
  /**
   *
   * @type {Array<OptionModel>}
   * @memberof SliceFilterStructureModel
   */
  slices: Array<OptionModel>;
}
/**
 *
 * @export
 * @interface SliceMembershipInfo
 */
export interface SliceMembershipInfo {
  /**
   *
   * @type {Membership}
   * @memberof SliceMembershipInfo
   */
  override?: Membership;
  /**
   *
   * @type {Membership}
   * @memberof SliceMembershipInfo
   */
  programmatic?: Membership;
  /**
   *
   * @type {string}
   * @memberof SliceMembershipInfo
   */
  slice_name: string;
  /**
   *
   * @type {number}
   * @memberof SliceMembershipInfo
   */
  slice_uid: number;
}

/**
 *
 * @export
 * @interface SliceObject
 */
export interface SliceObject {
  /**
   *
   * @type {SliceConfig}
   * @memberof SliceObject
   */
  config?: SliceConfig;
  /**
   *
   * @type {string}
   * @memberof SliceObject
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SliceObject
   */
  display_name: string;
  /**
   *
   * @type {string}
   * @memberof SliceObject
   */
  id: string;
}
/**
 *
 * @export
 * @interface SliceOverrideDelete
 */
export interface SliceOverrideDelete {
  /**
   *
   * @type {number}
   * @memberof SliceOverrideDelete
   */
  slice_uid: number;
  /**
   *
   * @type {string}
   * @memberof SliceOverrideDelete
   */
  x_uid: string;
}
/**
 *
 * @export
 * @interface SliceResponse
 */
export interface SliceResponse {
  /**
   *
   * @type {string}
   * @memberof SliceResponse
   */
  display_name: string;
  /**
   *
   * @type {string}
   * @memberof SliceResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SliceResponse
   */
  reserved_slice_type?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SortOrder = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const;

export type SortOrder = (typeof SortOrder)[keyof typeof SortOrder];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SortableApplicationColumns = {
  Name: 'name',
  CreatedAt: 'created_at',
  CreatedByUsername: 'created_by_username',
} as const;

export type SortableApplicationColumns =
  (typeof SortableApplicationColumns)[keyof typeof SortableApplicationColumns];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SortableDeploymentColumns = {
  Name: 'name',
  ApplicationName: 'application_name',
  CreatedAt: 'created_at',
  CreatedByUsername: 'created_by_username',
} as const;

export type SortableDeploymentColumns =
  (typeof SortableDeploymentColumns)[keyof typeof SortableDeploymentColumns];

/**
 *
 * @export
 * @interface Source
 */
export interface Source {
  /**
   *
   * @type {object}
   * @memberof Source
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof Source
   */
  source_name: string;
  /**
   *
   * @type {string}
   * @memberof Source
   */
  source_type: string;
  /**
   *
   * @type {number}
   * @memberof Source
   */
  source_uid: number;
  /**
   *
   * @type {number}
   * @memberof Source
   */
  user_uid?: number;
  /**
   *
   * @type {number}
   * @memberof Source
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface SourceId
 */
export interface SourceId {}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SourceType = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
} as const;

export type SourceType = (typeof SourceType)[keyof typeof SourceType];

/**
 *
 * @export
 * @interface SplitDataSourcesRequest
 */
export interface SplitDataSourcesRequest {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof SplitDataSourcesRequest
   */
  col_fill_values?: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof SplitDataSourcesRequest
   */
  credential_kwargs?: string;
  /**
   *
   * @type {number}
   * @memberof SplitDataSourcesRequest
   */
  data_connector_config_uid?: number;
  /**
   *
   * @type {string}
   * @memberof SplitDataSourcesRequest
   */
  datestamp?: string;
  /**
   *
   * @type {boolean}
   * @memberof SplitDataSourcesRequest
   */
  generate_uid_col?: boolean;
  /**
   *
   * @type {GTStratificationParams}
   * @memberof SplitDataSourcesRequest
   */
  gt_stratification_params?: GTStratificationParams;
  /**
   *
   * @type {boolean}
   * @memberof SplitDataSourcesRequest
   */
  load_to_model_nodes?: boolean;
  /**
   *
   * @type {number}
   * @memberof SplitDataSourcesRequest
   */
  parallel_core_fraction?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SplitDataSourcesRequest
   */
  paths: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SplitDataSourcesRequest
   */
  reader_kwargs?: string;
  /**
   *
   * @type {boolean}
   * @memberof SplitDataSourcesRequest
   */
  run_async?: boolean;
  /**
   *
   * @type {string}
   * @memberof SplitDataSourcesRequest
   */
  scheduler?: string;
  /**
   *
   * @type {boolean}
   * @memberof SplitDataSourcesRequest
   */
  skip_repartition?: boolean;
  /**
   *
   * @type {string}
   * @memberof SplitDataSourcesRequest
   */
  snorkel_generated_uid_col?: string;
  /**
   *
   * @type {string}
   * @memberof SplitDataSourcesRequest
   */
  source_type: string;
  /**
   *
   * @type {number}
   * @memberof SplitDataSourcesRequest
   */
  source_uid?: number;
  /**
   *
   * @type {SplitWiseDistribution}
   * @memberof SplitDataSourcesRequest
   */
  split_pct?: SplitWiseDistribution;
  /**
   *
   * @type {number}
   * @memberof SplitDataSourcesRequest
   */
  split_random_seed?: number;
  /**
   *
   * @type {StaticAssetColInfo}
   * @memberof SplitDataSourcesRequest
   */
  static_asset_col_info?: StaticAssetColInfo;
  /**
   *
   * @type {string}
   * @memberof SplitDataSourcesRequest
   */
  uid_col?: string;
}
/**
 *
 * @export
 * @interface SplitDataSourcesResponseModel
 */
export interface SplitDataSourcesResponseModel {
  /**
   *
   * @type {number}
   * @memberof SplitDataSourcesResponseModel
   */
  dataset_uid: number;
  /**
   *
   * @type {string}
   * @memberof SplitDataSourcesResponseModel
   */
  job_uid: string;
}
/**
 *
 * @export
 * @interface SplitWiseDistribution
 */
export interface SplitWiseDistribution {
  /**
   *
   * @type {number}
   * @memberof SplitWiseDistribution
   */
  test: number;
  /**
   *
   * @type {number}
   * @memberof SplitWiseDistribution
   */
  train: number;
  /**
   *
   * @type {number}
   * @memberof SplitWiseDistribution
   */
  valid: number;
}
/**
 *
 * @export
 * @interface SsoSettings
 */
export interface SsoSettings {
  /**
   *
   * @type {boolean}
   * @memberof SsoSettings
   */
  sso_enabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SsoSettings
   */
  sso_required: boolean;
  /**
   *
   * @type {SsoType}
   * @memberof SsoSettings
   */
  sso_type?: SsoType;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SsoType = {
  Saml: 'SAML',
  Oidc: 'OIDC',
} as const;

export type SsoType = (typeof SsoType)[keyof typeof SsoType];

/**
 *
 * @export
 * @interface StaleModelValuesResponse
 */
export interface StaleModelValuesResponse {
  /**
   *
   * @type {boolean}
   * @memberof StaleModelValuesResponse
   */
  dev: boolean;
  /**
   *
   * @type {boolean}
   * @memberof StaleModelValuesResponse
   */
  test: boolean;
  /**
   *
   * @type {boolean}
   * @memberof StaleModelValuesResponse
   */
  valid: boolean;
}
/**
 *
 * @export
 * @interface StartTraceMalloc
 */
export interface StartTraceMalloc {
  /**
   *
   * @type {number}
   * @memberof StartTraceMalloc
   */
  nframe?: number;
}
/**
 *
 * @export
 * @interface StaticAssetColInfo
 */
export interface StaticAssetColInfo {
  /**
   *
   * @type {string}
   * @memberof StaticAssetColInfo
   */
  datasource_reference_col_name: string;
  /**
   *
   * @type {RemoteStorageType}
   * @memberof StaticAssetColInfo
   */
  remote_storage_type: RemoteStorageType;
  /**
   *
   * @type {boolean}
   * @memberof StaticAssetColInfo
   */
  resize_image: boolean;
  /**
   *
   * @type {string}
   * @memberof StaticAssetColInfo
   */
  static_asset_col_name: string;
  /**
   *
   * @type {AssetUploadType}
   * @memberof StaticAssetColInfo
   */
  static_asset_file_type: AssetUploadType;
  /**
   *
   * @type {string}
   * @memberof StaticAssetColInfo
   */
  storage_path: string;
}

/**
 *
 * @export
 * @interface StaticAssetFileMetadata
 */
export interface StaticAssetFileMetadata {
  /**
   *
   * @type {string}
   * @memberof StaticAssetFileMetadata
   */
  last_modified: string;
  /**
   *
   * @type {string}
   * @memberof StaticAssetFileMetadata
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof StaticAssetFileMetadata
   */
  size_bytes: number;
}
/**
 *
 * @export
 * @interface StaticAssetFolderMetadata
 */
export interface StaticAssetFolderMetadata {
  /**
   *
   * @type {Array<string>}
   * @memberof StaticAssetFolderMetadata
   */
  asset_types: Array<string>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StaticAssetUploadMethod = {
  RemoteS3: 'remote_s3',
  RemoteGcs: 'remote_gcs',
  Local: 'local',
} as const;

export type StaticAssetUploadMethod =
  (typeof StaticAssetUploadMethod)[keyof typeof StaticAssetUploadMethod];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StaticAssetUploadMethodAction = {
  Read: 'read',
} as const;

export type StaticAssetUploadMethodAction =
  (typeof StaticAssetUploadMethodAction)[keyof typeof StaticAssetUploadMethodAction];

/**
 *
 * @export
 * @interface StaticAssetUploadMethodActivateRequest
 */
export interface StaticAssetUploadMethodActivateRequest {
  /**
   *
   * @type {StaticAssetUploadMethod}
   * @memberof StaticAssetUploadMethodActivateRequest
   */
  static_asset_upload_method: StaticAssetUploadMethod;
}

/**
 *
 * @export
 * @interface StaticAssetUploadMethodRole
 */
export interface StaticAssetUploadMethodRole {
  /**
   *
   * @type {Array<StaticAssetUploadMethodAction>}
   * @memberof StaticAssetUploadMethodRole
   */
  permissions: Array<StaticAssetUploadMethodAction>;
  /**
   *
   * @type {number}
   * @memberof StaticAssetUploadMethodRole
   */
  role_uid: number;
  /**
   *
   * @type {StaticAssetUploadMethod}
   * @memberof StaticAssetUploadMethodRole
   */
  static_asset_upload_method_type: StaticAssetUploadMethod;
  /**
   *
   * @type {Array<UserRole>}
   * @memberof StaticAssetUploadMethodRole
   */
  user_roles: Array<UserRole>;
  /**
   *
   * @type {string}
   * @memberof StaticAssetUploadMethodRole
   */
  workspace_name: string;
  /**
   *
   * @type {number}
   * @memberof StaticAssetUploadMethodRole
   */
  workspace_uid?: number;
}

/**
 *
 * @export
 * @interface StaticAssetUploadMethodRoleCreationRequest
 */
export interface StaticAssetUploadMethodRoleCreationRequest {
  /**
   *
   * @type {Array<StaticAssetUploadMethodAction>}
   * @memberof StaticAssetUploadMethodRoleCreationRequest
   */
  permissions: Array<StaticAssetUploadMethodAction>;
  /**
   *
   * @type {StaticAssetUploadMethod}
   * @memberof StaticAssetUploadMethodRoleCreationRequest
   */
  static_asset_upload_method_type: StaticAssetUploadMethod;
  /**
   *
   * @type {Array<UserRole>}
   * @memberof StaticAssetUploadMethodRoleCreationRequest
   */
  user_roles: Array<UserRole>;
  /**
   *
   * @type {number}
   * @memberof StaticAssetUploadMethodRoleCreationRequest
   */
  workspace_uid?: number;
}

/**
 *
 * @export
 * @interface StaticAssetUploadMethodStateResponse
 */
export interface StaticAssetUploadMethodStateResponse {
  /**
   *
   * @type {boolean}
   * @memberof StaticAssetUploadMethodStateResponse
   */
  enabled: boolean;
}
/**
 *
 * @export
 * @interface StudioPreferences
 */
export interface StudioPreferences {
  /**
   *
   * @type {Array<string>}
   * @memberof StudioPreferences
   */
  context_fields?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof StudioPreferences
   */
  default_multi_label_class?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof StudioPreferences
   */
  expanded_fields?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof StudioPreferences
   */
  should_show_modular_dataviewer?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof StudioPreferences
   */
  span_fields?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof StudioPreferences
   */
  table_fields?: Array<string>;
  /**
   *
   * @type {ViewConfig}
   * @memberof StudioPreferences
   */
  view_config?: ViewConfig;
}
/**
 *
 * @export
 * @interface SuggestionModel
 */
export interface SuggestionModel {
  /**
   *
   * @type {string}
   * @memberof SuggestionModel
   */
  key?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SuggestionModel
   */
  lf_names?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SuggestionModel
   */
  lvl: number;
  /**
   *
   * @type {string}
   * @memberof SuggestionModel
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof SuggestionModel
   */
  page?: string;
  /**
   *
   * @type {any}
   * @memberof SuggestionModel
   */
  page_options?: any;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SupportStatus = {
  Supported: 'SUPPORTED',
  NotSupported: 'NOT_SUPPORTED',
  Unsure: 'UNSURE',
} as const;

export type SupportStatus = (typeof SupportStatus)[keyof typeof SupportStatus];

/**
 *
 * @export
 * @interface SupportedLLM
 */
export interface SupportedLLM {
  /**
   *
   * @type {FMType}
   * @memberof SupportedLLM
   */
  fm_type: FMType;
  /**
   *
   * @type {string}
   * @memberof SupportedLLM
   */
  model_name: string;
  /**
   *
   * @type {ExternalLLMProvider}
   * @memberof SupportedLLM
   */
  provider: ExternalLLMProvider;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SupportedSortColumns = {
  SourceName: 'source_name',
  Label: 'label',
  DateCreated: 'date_created',
} as const;

export type SupportedSortColumns =
  (typeof SupportedSortColumns)[keyof typeof SupportedSortColumns];

/**
 *
 * @export
 * @interface SupportedWarmStartMethods
 */
export interface SupportedWarmStartMethods {
  /**
   *
   * @type {string}
   * @memberof SupportedWarmStartMethods
   */
  error?: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof SupportedWarmStartMethods
   */
  results?: { [key: string]: any };
}
/**
 *
 * @export
 * @interface SvcSource
 */
export interface SvcSource {
  /**
   *
   * @type {object}
   * @memberof SvcSource
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof SvcSource
   */
  source_name: string;
  /**
   *
   * @type {SvcSourceType}
   * @memberof SvcSource
   */
  source_type: SvcSourceType;
  /**
   *
   * @type {number}
   * @memberof SvcSource
   */
  source_uid: number;
  /**
   *
   * @type {number}
   * @memberof SvcSource
   */
  user_uid?: number;
  /**
   *
   * @type {number}
   * @memberof SvcSource
   */
  workspace_uid?: number;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SvcSourceType = {
  User: 'user',
  Machine: 'machine',
  Model: 'model',
  Userinput: 'userinput',
  Aggregation: 'aggregation',
} as const;

export type SvcSourceType = (typeof SvcSourceType)[keyof typeof SvcSourceType];

/**
 *
 * @export
 * @interface SwapDatasetParams
 */
export interface SwapDatasetParams {
  /**
   *
   * @type {number}
   * @memberof SwapDatasetParams
   */
  dataset_uid: number;
  /**
   *
   * @type {Array<number>}
   * @memberof SwapDatasetParams
   */
  datasource_uids_to_load?: Array<number>;
}
/**
 *
 * @export
 * @interface SwapDatasetResponse
 */
export interface SwapDatasetResponse {
  /**
   *
   * @type {number}
   * @memberof SwapDatasetResponse
   */
  application_uid: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SystemScopedFeature = {
  Notebook: 'notebook',
} as const;

export type SystemScopedFeature =
  (typeof SystemScopedFeature)[keyof typeof SystemScopedFeature];

/**
 *
 * @export
 * @interface TagSummary
 */
export interface TagSummary {
  /**
   *
   * @type {number}
   * @memberof TagSummary
   */
  correct?: number;
  /**
   *
   * @type {number}
   * @memberof TagSummary
   */
  incorrect?: number;
  /**
   *
   * @type {string}
   * @memberof TagSummary
   */
  tag_name?: string;
  /**
   *
   * @type {number}
   * @memberof TagSummary
   */
  tag_uid?: number;
  /**
   *
   * @type {number}
   * @memberof TagSummary
   */
  unknown_gt?: number;
}
/**
 *
 * @export
 * @interface TagSummaryResponse
 */
export interface TagSummaryResponse {
  /**
   *
   * @type {{ [key: string]: TagSummary; }}
   * @memberof TagSummaryResponse
   */
  details: { [key: string]: TagSummary };
  /**
   *
   * @type {number}
   * @memberof TagSummaryResponse
   */
  total: number;
}
/**
 *
 * @export
 * @interface TagType
 */
export interface TagType {
  /**
   *
   * @type {string}
   * @memberof TagType
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof TagType
   */
  is_context_tag_type?: boolean;
  /**
   *
   * @type {string}
   * @memberof TagType
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof TagType
   */
  tag_type_uid: number;
}
/**
 *
 * @export
 * @interface TaskDatasourceBulkUpdateParams
 */
export interface TaskDatasourceBulkUpdateParams {
  /**
   *
   * @type {Array<number>}
   * @memberof TaskDatasourceBulkUpdateParams
   */
  datasource_uids: Array<number>;
}
/**
 *
 * @export
 * @interface TaskDatasourcesResponse
 */
export interface TaskDatasourcesResponse {
  /**
   *
   * @type {object}
   * @memberof TaskDatasourcesResponse
   */
  config: object;
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesResponse
   */
  datasource_uid: number;
  /**
   *
   * @type {string}
   * @memberof TaskDatasourcesResponse
   */
  ds: string;
  /**
   *
   * @type {boolean}
   * @memberof TaskDatasourcesResponse
   */
  is_active: boolean;
  /**
   *
   * @type {object}
   * @memberof TaskDatasourcesResponse
   */
  metadata: object;
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesResponse
   */
  n_datapoints?: number;
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesResponse
   */
  n_docs?: number;
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesResponse
   */
  n_gt_labels?: number;
  /**
   *
   * @type {object}
   * @memberof TaskDatasourcesResponse
   */
  provenance?: object;
  /**
   *
   * @type {string}
   * @memberof TaskDatasourcesResponse
   */
  split: string;
  /**
   *
   * @type {boolean}
   * @memberof TaskDatasourcesResponse
   */
  supports_dev: boolean;
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesResponse
   */
  type: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const TaskType = {
  Classification: 'classification',
  CandidateExtraction: 'candidate_extraction',
  EntityClassification: 'entity_classification',
  SequenceTagging: 'sequence_tagging',
  WordClassification: 'word_classification',
  AnomalyDetection: 'anomaly_detection',
} as const;

export type TaskType = (typeof TaskType)[keyof typeof TaskType];

/**
 *
 * @export
 * @interface TemplateMetadata
 */
export interface TemplateMetadata {
  /**
   *
   * @type {ApplicationType}
   * @memberof TemplateMetadata
   */
  application_type?: ApplicationType;
  /**
   *
   * @type {Array<DataModality>}
   * @memberof TemplateMetadata
   */
  data_modalities?: Array<DataModality>;
  /**
   *
   * @type {Industry}
   * @memberof TemplateMetadata
   */
  industry?: Industry;
  /**
   *
   * @type {string}
   * @memberof TemplateMetadata
   */
  model_label_space_cls: string;
}

/**
 *
 * @export
 * @interface Timing
 */
export interface Timing {
  /**
   *
   * @type {number}
   * @memberof Timing
   */
  max_rss_mb?: number;
  /**
   *
   * @type {{ [key: string]: Timing; }}
   * @memberof Timing
   */
  sub_timings?: { [key: string]: Timing };
  /**
   *
   * @type {number}
   * @memberof Timing
   */
  total_ms?: number;
}
/**
 *
 * @export
 * @interface TokenPair
 */
export interface TokenPair {
  /**
   *
   * @type {string}
   * @memberof TokenPair
   */
  access_token: string;
  /**
   *
   * @type {number}
   * @memberof TokenPair
   */
  expires_in: number;
  /**
   *
   * @type {string}
   * @memberof TokenPair
   */
  refresh_token: string;
  /**
   *
   * @type {string}
   * @memberof TokenPair
   */
  token_type: string;
  /**
   *
   * @type {number}
   * @memberof TokenPair
   */
  user_uid: number;
}
/**
 *
 * @export
 * @interface TrainModelArgs
 */
export interface TrainModelArgs {
  /**
   *
   * @type {boolean}
   * @memberof TrainModelArgs
   */
  apply_postprocessors?: boolean;
  /**
   *
   * @type {string}
   * @memberof TrainModelArgs
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof TrainModelArgs
   */
  is_automl?: boolean;
  /**
   *
   * @type {number}
   * @memberof TrainModelArgs
   */
  max_runs?: number;
  /**
   *
   * @type {string}
   * @memberof TrainModelArgs
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof TrainModelArgs
   */
  random_seed?: number;
  /**
   *
   * @type {string}
   * @memberof TrainModelArgs
   */
  scheduler?: string;
  /**
   *
   * @type {object}
   * @memberof TrainModelArgs
   */
  search_config?: object;
  /**
   *
   * @type {SearchStrategy}
   * @memberof TrainModelArgs
   */
  search_strategy?: SearchStrategy;
}

/**
 *
 * @export
 * @interface TrainingSet
 */
export interface TrainingSet {
  /**
   *
   * @type {Array<boolean>}
   * @memberof TrainingSet
   */
  abstains?: Array<boolean>;
  /**
   *
   * @type {object}
   * @memberof TrainingSet
   */
  label_model_info: object;
  /**
   *
   * @type {string}
   * @memberof TrainingSet
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof TrainingSet
   */
  pkg_uid: number;
  /**
   *
   * @type {Array<any>}
   * @memberof TrainingSet
   */
  predicted_probs: Array<any>;
  /**
   *
   * @type {Array<any>}
   * @memberof TrainingSet
   */
  subdatapoint_abstains?: Array<any>;
  /**
   *
   * @type {number}
   * @memberof TrainingSet
   */
  training_set_uid?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof TrainingSet
   */
  x_uids: Array<string>;
}
/**
 * Training set correctness.
 * @export
 * @interface TrainingSetCorrectnessBreakdown
 */
export interface TrainingSetCorrectnessBreakdown {
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof TrainingSetCorrectnessBreakdown
   */
  correctness_map: { [key: string]: boolean };
}
/**
 *
 * @export
 * @interface TrainingSetMetadataResponse
 */
export interface TrainingSetMetadataResponse {
  /**
   *
   * @type {boolean}
   * @memberof TrainingSetMetadataResponse
   */
  is_active?: boolean;
  /**
   *
   * @type {object}
   * @memberof TrainingSetMetadataResponse
   */
  label_model_info?: object;
  /**
   *
   * @type {string}
   * @memberof TrainingSetMetadataResponse
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof TrainingSetMetadataResponse
   */
  node_uid: number;
  /**
   *
   * @type {number}
   * @memberof TrainingSetMetadataResponse
   */
  pkg_uid: number;
  /**
   *
   * @type {number}
   * @memberof TrainingSetMetadataResponse
   */
  training_set_uid: number;
  /**
   *
   * @type {string}
   * @memberof TrainingSetMetadataResponse
   */
  ts: string;
}
/**
 *
 * @export
 * @interface TrainingSetResponse
 */
export interface TrainingSetResponse {
  /**
   *
   * @type {string}
   * @memberof TrainingSetResponse
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof TrainingSetResponse
   */
  training_set_uid: number;
}
/**
 *
 * @export
 * @interface TrainingSetStats
 */
export interface TrainingSetStats {
  /**
   *
   * @type {object}
   * @memberof TrainingSetStats
   */
  est_label_dist: object;
  /**
   *
   * @type {number}
   * @memberof TrainingSetStats
   */
  est_labels_precision: number;
  /**
   *
   * @type {object}
   * @memberof TrainingSetStats
   */
  gt_label_dist: object;
  /**
   *
   * @type {number}
   * @memberof TrainingSetStats
   */
  num_data_points: number;
  /**
   *
   * @type {number}
   * @memberof TrainingSetStats
   */
  num_est_labels: number;
  /**
   *
   * @type {number}
   * @memberof TrainingSetStats
   */
  num_gt_labels: number;
  /**
   *
   * @type {object}
   * @memberof TrainingSetStats
   */
  random_sample: object;
}
/**
 *
 * @export
 * @interface TransferAnnotationsParams
 */
export interface TransferAnnotationsParams {
  /**
   *
   * @type {number}
   * @memberof TransferAnnotationsParams
   */
  from_node_uid: number;
  /**
   *
   * @type {string}
   * @memberof TransferAnnotationsParams
   */
  merge_type?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof TransferAnnotationsParams
   */
  updated_label_schema?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface TransferBatchesParams
 */
export interface TransferBatchesParams {
  /**
   *
   * @type {number}
   * @memberof TransferBatchesParams
   */
  from_node_uid: number;
}
/**
 *
 * @export
 * @interface TransferBatchesResponse
 */
export interface TransferBatchesResponse {
  /**
   *
   * @type {Array<Batch>}
   * @memberof TransferBatchesResponse
   */
  created_batches: Array<Batch>;
}
/**
 *
 * @export
 * @interface TransferComments
 */
export interface TransferComments {
  /**
   *
   * @type {number}
   * @memberof TransferComments
   */
  from_node_uid: number;
  /**
   *
   * @type {string}
   * @memberof TransferComments
   */
  merge_type?: string;
}
/**
 *
 * @export
 * @interface TransferGTs
 */
export interface TransferGTs {
  /**
   *
   * @type {Array<string>}
   * @memberof TransferGTs
   */
  context_gt_to_import?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof TransferGTs
   */
  from_node_uid: number;
  /**
   *
   * @type {Array<string>}
   * @memberof TransferGTs
   */
  gt_to_import?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof TransferGTs
   */
  merge_type?: string;
  /**
   *
   * @type {boolean}
   * @memberof TransferGTs
   */
  overwrite_unknown_labels?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof TransferGTs
   */
  updated_label_schema?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface TransferLFs
 */
export interface TransferLFs {
  /**
   *
   * @type {number}
   * @memberof TransferLFs
   */
  from_node_uid: number;
  /**
   *
   * @type {boolean}
   * @memberof TransferLFs
   */
  merge?: boolean;
  /**
   *
   * @type {string}
   * @memberof TransferLFs
   */
  merge_type?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof TransferLFs
   */
  updated_label_schema?: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof TransferLFs
   */
  user_uid?: number;
}
/**
 *
 * @export
 * @interface TransferTags
 */
export interface TransferTags {
  /**
   *
   * @type {number}
   * @memberof TransferTags
   */
  from_node_uid: number;
  /**
   *
   * @type {boolean}
   * @memberof TransferTags
   */
  is_context_tag_type?: boolean;
  /**
   *
   * @type {string}
   * @memberof TransferTags
   */
  merge_type?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TransferTags
   */
  tag_types_to_import?: Array<string>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const TrialType = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
} as const;

export type TrialType = (typeof TrialType)[keyof typeof TrialType];

/**
 *
 * @export
 * @interface UnifiedFlagResponse
 */
export interface UnifiedFlagResponse {
  /**
   *
   * @type {string}
   * @memberof UnifiedFlagResponse
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof UnifiedFlagResponse
   */
  enabled: boolean;
}
/**
 *
 * @export
 * @interface UniqueLabelsResponse
 */
export interface UniqueLabelsResponse {
  /**
   *
   * @type {Array<number>}
   * @memberof UniqueLabelsResponse
   */
  label_counts: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof UniqueLabelsResponse
   */
  labels: Array<string>;
  /**
   *
   * @type {Array<InputWarning>}
   * @memberof UniqueLabelsResponse
   */
  warnings: Array<InputWarning>;
}
/**
 *
 * @export
 * @interface UpdateAccessConfig
 */
export interface UpdateAccessConfig {
  /**
   *
   * @type {boolean}
   * @memberof UpdateAccessConfig
   */
  is_public: boolean;
}
/**
 *
 * @export
 * @interface UpdateAnnotationParams
 */
export interface UpdateAnnotationParams {
  /**
   *
   * @type {any}
   * @memberof UpdateAnnotationParams
   */
  label?: any;
  /**
   *
   * @type {object}
   * @memberof UpdateAnnotationParams
   */
  metadata?: object;
}
/**
 *
 * @export
 * @interface UpdateApplicationPayload
 */
export interface UpdateApplicationPayload {
  /**
   *
   * @type {UpdateAccessConfig}
   * @memberof UpdateApplicationPayload
   */
  access_config?: UpdateAccessConfig;
  /**
   *
   * @type {ApplicationConfig}
   * @memberof UpdateApplicationPayload
   */
  application_config?: ApplicationConfig;
  /**
   *
   * @type {string}
   * @memberof UpdateApplicationPayload
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateApplicationPayload
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UpdateBackendFeatureFlagsRequest
 */
export interface UpdateBackendFeatureFlagsRequest {
  /**
   *
   * @type {BackendFeatureFlags}
   * @memberof UpdateBackendFeatureFlagsRequest
   */
  backend_feature_flags: BackendFeatureFlags;
  /**
   *
   * @type {boolean}
   * @memberof UpdateBackendFeatureFlagsRequest
   */
  organization?: boolean;
  /**
   *
   * @type {number}
   * @memberof UpdateBackendFeatureFlagsRequest
   */
  user_uid?: number;
}
/**
 *
 * @export
 * @interface UpdateBatchParams
 */
export interface UpdateBatchParams {
  /**
   *
   * @type {string}
   * @memberof UpdateBatchParams
   */
  batch_name?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateBatchParams
   */
  expert_source_uid?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateBatchParams
   */
  user_uids?: Array<number>;
}
/**
 *
 * @export
 * @interface UpdateComment
 */
export interface UpdateComment {
  /**
   *
   * @type {string}
   * @memberof UpdateComment
   */
  body: string;
}
/**
 *
 * @export
 * @interface UpdateDatasetAnnotationParams
 */
export interface UpdateDatasetAnnotationParams {
  /**
   *
   * @type {number}
   * @memberof UpdateDatasetAnnotationParams
   */
  annotation_uid: number;
  /**
   *
   * @type {string}
   * @memberof UpdateDatasetAnnotationParams
   */
  freeform_text?: string;
  /**
   *
   * @type {any}
   * @memberof UpdateDatasetAnnotationParams
   */
  label?: any;
  /**
   *
   * @type {object}
   * @memberof UpdateDatasetAnnotationParams
   */
  metadata?: object;
}
/**
 *
 * @export
 * @interface UpdateDatasetBatchParams
 */
export interface UpdateDatasetBatchParams {
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateDatasetBatchParams
   */
  assignees?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof UpdateDatasetBatchParams
   */
  batch_name?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateDatasetBatchParams
   */
  expert_source_uid?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateDatasetBatchParams
   */
  unassign_expert_source_uid?: number;
}
/**
 * Updates fields on an evaluation report. ≥1 of these attributes must be specified, each represents a field on an EvaluationReportResponse.
 * @export
 * @interface UpdateEvaluationReport
 */
export interface UpdateEvaluationReport {
  /**
   *
   * @type {string}
   * @memberof UpdateEvaluationReport
   */
  additional_notes?: string;
}
/**
 *
 * @export
 * @interface UpdateExternalLLMConfigPayload
 */
export interface UpdateExternalLLMConfigPayload {
  /**
   *
   * @type {object}
   * @memberof UpdateExternalLLMConfigPayload
   */
  config: object;
}
/**
 *
 * @export
 * @interface UpdateLFPackageParams
 */
export interface UpdateLFPackageParams {
  /**
   *
   * @type {boolean}
   * @memberof UpdateLFPackageParams
   */
  is_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateLFPackageParams
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UpdateLabelSchemaPayload
 */
export interface UpdateLabelSchemaPayload {
  /**
   *
   * @type {string}
   * @memberof UpdateLabelSchemaPayload
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLabelSchemaPayload
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UpdateModelConfigParams
 */
export interface UpdateModelConfigParams {
  /**
   *
   * @type {string}
   * @memberof UpdateModelConfigParams
   */
  description: string;
}
/**
 *
 * @export
 * @interface UpdateModelParams
 */
export interface UpdateModelParams {
  /**
   *
   * @type {boolean}
   * @memberof UpdateModelParams
   */
  is_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateModelParams
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UpdateNodeLabelSpacePayload
 */
export interface UpdateNodeLabelSpacePayload {
  /**
   *
   * @type {object}
   * @memberof UpdateNodeLabelSpacePayload
   */
  label_space_config: object;
}
/**
 *
 * @export
 * @interface UpdateNodeLabelSpaceResponse
 */
export interface UpdateNodeLabelSpaceResponse {
  /**
   *
   * @type {object}
   * @memberof UpdateNodeLabelSpaceResponse
   */
  node_config: object;
}
/**
 * One must either include - A referenced Operator UID, in which case we grab that op_type/op_config and make   a new OpVersion, then commit that OpVersion to the Node - A referenced OpVersion UID, in which case we simply commit that version to the Node - A group of op_type/op_config values, in which case we make a new OpVersion and commit it   to the Node - If none of those are supplied (i.e. all keys are missing or they all map to None), we assume the user is   trying to uncommit any version from the Node entirely (i.e. set op_type/op_config to None)
 * @export
 * @interface UpdateNodePayload
 */
export interface UpdateNodePayload {
  /**
   *
   * @type {CommittedOperatorConfig}
   * @memberof UpdateNodePayload
   */
  config?: CommittedOperatorConfig;
  /**
   *
   * @type {number}
   * @memberof UpdateNodePayload
   */
  op_version_uid?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateNodePayload
   */
  operator_uid?: number;
}
/**
 *
 * @export
 * @interface UpdateNodeResponse
 */
export interface UpdateNodeResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateNodeResponse
   */
  op_version_uid?: number;
}
/**
 *
 * @export
 * @interface UpdateNotificationPayload
 */
export interface UpdateNotificationPayload {
  /**
   *
   * @type {boolean}
   * @memberof UpdateNotificationPayload
   */
  is_read?: boolean;
}
/**
 *
 * @export
 * @interface UpdateSliceRequest
 */
export interface UpdateSliceRequest {
  /**
   *
   * @type {Slice}
   * @memberof UpdateSliceRequest
   */
  slice: Slice;
}
/**
 *
 * @export
 * @interface UpdateSourceParams
 */
export interface UpdateSourceParams {
  /**
   *
   * @type {object}
   * @memberof UpdateSourceParams
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof UpdateSourceParams
   */
  source_name?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const UpdateSuperadminAction = {
  Assign: 'assign',
  Remove: 'remove',
} as const;

export type UpdateSuperadminAction =
  (typeof UpdateSuperadminAction)[keyof typeof UpdateSuperadminAction];

/**
 *
 * @export
 * @interface UpdateTagMapReq
 */
export interface UpdateTagMapReq {
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateTagMapReq
   */
  add_tag_type_uids?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateTagMapReq
   */
  remove_tag_type_uids?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof UpdateTagMapReq
   */
  skip_missing?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateTagMapReq
   */
  tag_type_uids?: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateTagMapReq
   */
  x_uids?: Array<string>;
}
/**
 * Updates fields on a tag_type. ≥1 of these attributes must be specified, each represents a field on a tag_type.
 * @export
 * @interface UpdateTagType
 */
export interface UpdateTagType {
  /**
   *
   * @type {string}
   * @memberof UpdateTagType
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTagType
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UpdateTrainingSetParams
 */
export interface UpdateTrainingSetParams {
  /**
   *
   * @type {boolean}
   * @memberof UpdateTrainingSetParams
   */
  is_active?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateTrainingSetParams
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UpdateUserEmail
 */
export interface UpdateUserEmail {
  /**
   *
   * @type {string}
   * @memberof UpdateUserEmail
   */
  email: string;
}
/**
 *
 * @export
 * @interface UpdateUserPayload
 */
export interface UpdateUserPayload {
  /**
   *
   * @type {UpdateSuperadminAction}
   * @memberof UpdateUserPayload
   */
  update_superadmin?: UpdateSuperadminAction;
}

/**
 *
 * @export
 * @interface UpdateUserRole
 */
export interface UpdateUserRole {
  /**
   *
   * @type {UserRole}
   * @memberof UpdateUserRole
   */
  role: UserRole;
}

/**
 *
 * @export
 * @interface UpdateUserSettingsRequest
 */
export interface UpdateUserSettingsRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateUserSettingsRequest
   */
  application_uid?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateUserSettingsRequest
   */
  dataset_batch_uid?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateUserSettingsRequest
   */
  node_uid?: number;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserSettingsRequest
   */
  organization?: boolean;
  /**
   *
   * @type {UserSettingsJson}
   * @memberof UpdateUserSettingsRequest
   */
  settings: UserSettingsJson;
  /**
   *
   * @type {number}
   * @memberof UpdateUserSettingsRequest
   */
  user_uid?: number;
}
/**
 *
 * @export
 * @interface UpdateUserTimezone
 */
export interface UpdateUserTimezone {
  /**
   *
   * @type {string}
   * @memberof UpdateUserTimezone
   */
  timezone: string;
}
/**
 *
 * @export
 * @interface UpdateUserView
 */
export interface UpdateUserView {
  /**
   *
   * @type {UserView}
   * @memberof UpdateUserView
   */
  default_view: UserView;
}

/**
 *
 * @export
 * @interface UpdatedStateParams
 */
export interface UpdatedStateParams {
  /**
   *
   * @type {LFState}
   * @memberof UpdatedStateParams
   */
  state: LFState;
}

/**
 *
 * @export
 * @interface UploadDatasourceResponseModel
 */
export interface UploadDatasourceResponseModel {
  /**
   *
   * @type {string}
   * @memberof UploadDatasourceResponseModel
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof UploadDatasourceResponseModel
   */
  minio_path: string;
}
/**
 *
 * @export
 * @interface UploadFileResponseModel
 */
export interface UploadFileResponseModel {
  /**
   *
   * @type {string}
   * @memberof UploadFileResponseModel
   */
  minio_path: string;
}
/**
 *
 * @export
 * @interface UploadLocalFileResponseModel
 */
export interface UploadLocalFileResponseModel {
  /**
   *
   * @type {string}
   * @memberof UploadLocalFileResponseModel
   */
  csv_file_path: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UploadLocalFileResponseModel
   */
  file_paths: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UploadLocalFileResponseModel
   */
  resized_file_paths: Array<string>;
}
/**
 *
 * @export
 * @interface UploadRemoteObjectsParams
 */
export interface UploadRemoteObjectsParams {
  /**
   *
   * @type {Array<string>}
   * @memberof UploadRemoteObjectsParams
   */
  bucket_files?: Array<string>;
  /**
   *
   * @type {RemoteStorageType}
   * @memberof UploadRemoteObjectsParams
   */
  bucket_type: RemoteStorageType;
  /**
   *
   * @type {RemoteUploadCredentials}
   * @memberof UploadRemoteObjectsParams
   */
  credentials?: RemoteUploadCredentials;
  /**
   *
   * @type {AssetUploadType}
   * @memberof UploadRemoteObjectsParams
   */
  file_type: AssetUploadType;
  /**
   *
   * @type {number}
   * @memberof UploadRemoteObjectsParams
   */
  max_num_processes?: number;
  /**
   *
   * @type {boolean}
   * @memberof UploadRemoteObjectsParams
   */
  overwrite_existing?: boolean;
  /**
   *
   * @type {number}
   * @memberof UploadRemoteObjectsParams
   */
  page_size?: number;
  /**
   *
   * @type {string}
   * @memberof UploadRemoteObjectsParams
   */
  remote_path?: string;
  /**
   *
   * @type {string}
   * @memberof UploadRemoteObjectsParams
   */
  target_path: string;
}

/**
 *
 * @export
 * @interface UserLabelInfo
 */
export interface UserLabelInfo {
  /**
   *
   * @type {Array<any>}
   * @memberof UserLabelInfo
   */
  sequence_tag_spans?: Array<any>;
  /**
   *
   * @type {number}
   * @memberof UserLabelInfo
   */
  user_uid: number;
  /**
   *
   * @type {string}
   * @memberof UserLabelInfo
   */
  username: string;
}
/**
 *
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
  /**
   *
   * @type {UserView}
   * @memberof UserResponse
   */
  default_view: UserView;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  is_active: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  is_locked: boolean;
  /**
   *
   * @type {UserRole}
   * @memberof UserResponse
   */
  role: UserRole;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  timezone?: string;
  /**
   *
   * @type {number}
   * @memberof UserResponse
   */
  user_uid: number;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  username: string;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const UserRole = {
  Standard: 'standard',
  Admin: 'admin',
  Reviewer: 'reviewer',
  Labeler: 'labeler',
  Superadmin: 'superadmin',
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];

/**
 *
 * @export
 * @interface UserSettingsJson
 */
export interface UserSettingsJson {
  /**
   *
   * @type {BackendFeatureFlags}
   * @memberof UserSettingsJson
   */
  backend_feature_flags?: BackendFeatureFlags;
  /**
   *
   * @type {Array<LabelColorObject>}
   * @memberof UserSettingsJson
   */
  custom_colors?: Array<LabelColorObject>;
  /**
   *
   * @type {boolean}
   * @memberof UserSettingsJson
   */
  dag_manipulation_enabled?: boolean;
  /**
   *
   * @type {ExternalModelPreferences}
   * @memberof UserSettingsJson
   */
  external_model_preferences?: ExternalModelPreferences;
  /**
   *
   * @type {boolean}
   * @memberof UserSettingsJson
   */
  force_studio_v2?: boolean;
  /**
   *
   * @type {GlobalPreferences}
   * @memberof UserSettingsJson
   */
  global_preferences?: GlobalPreferences;
  /**
   *
   * @type {boolean}
   * @memberof UserSettingsJson
   */
  is_studio_v2?: boolean;
  /**
   *
   * @type {object}
   * @memberof UserSettingsJson
   */
  label_color_scheme?: object;
  /**
   *
   * @type {LFPreferences}
   * @memberof UserSettingsJson
   */
  lf_preferences?: LFPreferences;
  /**
   *
   * @type {ModelPreferences}
   * @memberof UserSettingsJson
   */
  model_preferences?: ModelPreferences;
  /**
   *
   * @type {MTAPreferences}
   * @memberof UserSettingsJson
   */
  mta_preferences?: MTAPreferences;
  /**
   *
   * @type {OnboardingPreferences}
   * @memberof UserSettingsJson
   */
  onboarding_preferences?: OnboardingPreferences;
  /**
   *
   * @type {string}
   * @memberof UserSettingsJson
   */
  plot_ground_truth?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserSettingsJson
   */
  should_use_onboarding?: boolean;
  /**
   *
   * @type {StudioPreferences}
   * @memberof UserSettingsJson
   */
  studio_preferences?: StudioPreferences;
  /**
   *
   * @type {boolean}
   * @memberof UserSettingsJson
   */
  test_setting_1?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserSettingsJson
   */
  workspace_type?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const UserView = {
  Standard: 'standard',
  Annotation: 'annotation',
} as const;

export type UserView = (typeof UserView)[keyof typeof UserView];

/**
 *
 * @export
 * @interface ValidateAPIKeyParams
 */
export interface ValidateAPIKeyParams {
  /**
   *
   * @type {string}
   * @memberof ValidateAPIKeyParams
   */
  api_key_plaintext: string;
}
/**
 *
 * @export
 * @interface ValidateInviteResponse
 */
export interface ValidateInviteResponse {
  /**
   *
   * @type {boolean}
   * @memberof ValidateInviteResponse
   */
  valid: boolean;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<PreprocessedDatasourceResponseBumpedOpsAndPreprocessedVersionInnerInner>}
   * @memberof ValidationError
   */
  loc: Array<PreprocessedDatasourceResponseBumpedOpsAndPreprocessedVersionInnerInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}
/**
 *
 * @export
 * @interface Value
 */
export interface Value {}
/**
 *
 * @export
 * @interface Value1
 */
export interface Value1 {}
/**
 *
 * @export
 * @interface Value2
 */
export interface Value2 {}
/**
 *
 * @export
 * @interface VertexAISecretsConfig
 */
export interface VertexAISecretsConfig {
  /**
   *
   * @type {string}
   * @memberof VertexAISecretsConfig
   */
  provider: VertexAISecretsConfigProviderEnum;
  /**
   *
   * @type {string}
   * @memberof VertexAISecretsConfig
   */
  vertexai_lm_credentials_json?: string;
  /**
   *
   * @type {string}
   * @memberof VertexAISecretsConfig
   */
  vertexai_lm_location?: string;
  /**
   *
   * @type {string}
   * @memberof VertexAISecretsConfig
   */
  vertexai_lm_project_id?: string;
}

export const VertexAISecretsConfigProviderEnum = {
  VertexaiLm: 'vertexai_lm',
} as const;

export type VertexAISecretsConfigProviderEnum =
  (typeof VertexAISecretsConfigProviderEnum)[keyof typeof VertexAISecretsConfigProviderEnum];

/**
 *
 * @export
 * @interface ViewConfig
 */
export interface ViewConfig {
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  keyboard_shortcuts?: boolean;
  /**
   *
   * @type {string}
   * @memberof ViewConfig
   */
  label_mode?: string;
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  label_schema_edit_mode?: boolean;
  /**
   *
   * @type {EmbeddingNodeInfo}
   * @memberof ViewConfig
   */
  selected_embedding_info?: EmbeddingNodeInfo;
  /**
   *
   * @type {string}
   * @memberof ViewConfig
   */
  selected_gt_highlights?: string;
  /**
   *
   * @type {string}
   * @memberof ViewConfig
   */
  selected_lf_votes?: string;
  /**
   *
   * @type {number}
   * @memberof ViewConfig
   */
  selected_model_predictions?: number;
  /**
   *
   * @type {string}
   * @memberof ViewConfig
   */
  selected_model_predictions_label_type?: string;
  /**
   *
   * @type {string}
   * @memberof ViewConfig
   */
  selected_training_set_label_type?: string;
  /**
   *
   * @type {string}
   * @memberof ViewConfig
   */
  selected_training_set_labels?: string;
  /**
   *
   * @type {number}
   * @memberof ViewConfig
   */
  selected_training_set_votes?: number;
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  show_gt_highlights?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  show_lf_votes?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  show_model_predictions?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  show_multi_highlight_legend?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  show_other_users_gt_highlights?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  show_training_set_votes?: boolean;
  /**
   *
   * @type {string}
   * @memberof ViewConfig
   */
  text_direction?: string;
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  with_auto_advance?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  with_hyperlink_urls?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  with_sandbox_permissions?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ViewConfig
   */
  with_space_marker?: boolean;
}
/**
 *
 * @export
 * @interface WarmStartMethodsResults
 */
export interface WarmStartMethodsResults {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof WarmStartMethodsResults
   */
  methods: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof WarmStartMethodsResults
   */
  note: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const WarningAction = {
  ReplaceMissingValues: 'replace_missing_values',
} as const;

export type WarningAction = (typeof WarningAction)[keyof typeof WarningAction];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const WarningLevel = {
  Critical: 'critical',
  Warning: 'warning',
} as const;

export type WarningLevel = (typeof WarningLevel)[keyof typeof WarningLevel];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const WorkflowEnv = {
  None: 'none',
  FirstParty: 'first_party',
  Full: 'full',
} as const;

export type WorkflowEnv = (typeof WorkflowEnv)[keyof typeof WorkflowEnv];

/**
 *
 * @export
 * @interface Workspace
 */
export interface Workspace {
  /**
   *
   * @type {WorkspaceConfig}
   * @memberof Workspace
   */
  config: WorkspaceConfig;
  /**
   *
   * @type {string}
   * @memberof Workspace
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Workspace
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Workspace
   */
  workspace_uid?: number;
}
/**
 *
 * @export
 * @interface WorkspaceAUResourceAuditReport
 */
export interface WorkspaceAUResourceAuditReport {
  /**
   *
   * @type {Array<InvalidResourceInfo>}
   * @memberof WorkspaceAUResourceAuditReport
   */
  invalid_resources?: Array<InvalidResourceInfo>;
  /**
   *
   * @type {number}
   * @memberof WorkspaceAUResourceAuditReport
   */
  workspace_uid: number;
}
/**
 *
 * @export
 * @interface WorkspaceAUUsage
 */
export interface WorkspaceAUUsage {
  /**
   *
   * @type {AUUsage}
   * @memberof WorkspaceAUUsage
   */
  au_usage: AUUsage;
  /**
   *
   * @type {Array<ApplicationAUUsage>}
   * @memberof WorkspaceAUUsage
   */
  breakdown?: Array<ApplicationAUUsage>;
  /**
   *
   * @type {number}
   * @memberof WorkspaceAUUsage
   */
  workspace_uid: number;
}
/**
 *
 * @export
 * @interface WorkspaceAUUsageAuditResponse
 */
export interface WorkspaceAUUsageAuditResponse {
  /**
   *
   * @type {Array<WorkspaceAUResourceAuditReport>}
   * @memberof WorkspaceAUUsageAuditResponse
   */
  audit_reports?: Array<WorkspaceAUResourceAuditReport>;
}
/**
 *
 * @export
 * @interface WorkspaceAUUsagesResponse
 */
export interface WorkspaceAUUsagesResponse {
  /**
   *
   * @type {Array<WorkspaceAUUsage>}
   * @memberof WorkspaceAUUsagesResponse
   */
  workspace_au_usages?: Array<WorkspaceAUUsage>;
}
/**
 *
 * @export
 * @interface WorkspaceConfig
 */
export interface WorkspaceConfig {
  /**
   *
   * @type {number}
   * @memberof WorkspaceConfig
   */
  application_limit?: number;
  /**
   *
   * @type {AULimits}
   * @memberof WorkspaceConfig
   */
  au_limits?: AULimits;
  /**
   *
   * @type {number}
   * @memberof WorkspaceConfig
   */
  dataset_limit: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const WorkspaceScopedFeature = {
  Deployments: 'deployments',
  CustomOperators: 'custom_operators',
  CustomMetrics: 'custom_metrics',
  ExternalLlms: 'external_llms',
  Finetuning: 'finetuning',
} as const;

export type WorkspaceScopedFeature =
  (typeof WorkspaceScopedFeature)[keyof typeof WorkspaceScopedFeature];

/**
 *
 * @export
 * @interface WorkspaceSettings
 */
export interface WorkspaceSettings {
  /**
   *
   * @type {boolean}
   * @memberof WorkspaceSettings
   */
  auto_add_to_default_workspace: boolean;
}
/**
 *
 * @export
 * @interface WorkspaceUserInfo
 */
export interface WorkspaceUserInfo {
  /**
   *
   * @type {Array<UserRole>}
   * @memberof WorkspaceUserInfo
   */
  roles: Array<UserRole>;
  /**
   *
   * @type {string}
   * @memberof WorkspaceUserInfo
   */
  username: string;
}
/**
 *
 * @export
 * @interface XUids
 */
export interface XUids {
  /**
   *
   * @type {Array<string>}
   * @memberof XUids
   */
  x_uids: Array<string>;
}

/**
 * AnalysisApi - axios parameter creator
 * @export
 */
export const AnalysisApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Return \'confusion\' matrix indicating correct/incorrect/abstain outputs of Label Model vs End Model. <pre> +-------------------------+---------------------+ |                         |     End Model       | |                         +-----------+---------+ |                         | Incorrect | Correct | +-------------+-----------+-----------+---------+ |             | Incorrect |    #      |    #    | |             +-----------+-----------+---------+ | Label Model | Correct   |    #      |    #    | |             +-----------+-----------+---------+ |             | Abstain   |    #      |    #    | +-------------+-----------+-----------+---------+ </pre>
     * @summary Get Clarity Matrix
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {Array<string>} [split]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {number} [classInt]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet: async (
      nodeUid: number,
      modelUid: number,
      split?: Array<string>,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      classInt?: number,
      ignoreCache?: boolean,
      applyPostprocessors?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'getClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet',
        'modelUid',
        modelUid,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}/clarity-matrix`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split) {
        localVarQueryParameter['split'] = split;
      }

      if (includeTagTypeUid) {
        localVarQueryParameter['include_tag_type_uid'] = includeTagTypeUid;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (classInt !== undefined) {
        localVarQueryParameter['class_int'] = classInt;
      }

      if (ignoreCache !== undefined) {
        localVarQueryParameter['ignore_cache'] = ignoreCache;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Confusion Matrix
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {Array<string>} [split]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {number} [classInt]
     * @param {number} [histogramClass]
     * @param {boolean} [ignoreCache]
     * @param {string} [outputFormat]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet: async (
      nodeUid: number,
      modelUid: number,
      split?: Array<string>,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      classInt?: number,
      histogramClass?: number,
      ignoreCache?: boolean,
      outputFormat?: string,
      applyPostprocessors?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'getConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet',
        'modelUid',
        modelUid,
      );
      const localVarPath =
        `/nodes/{node_uid}/models/{model_uid}/confusion-matrix`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split) {
        localVarQueryParameter['split'] = split;
      }

      if (includeTagTypeUid) {
        localVarQueryParameter['include_tag_type_uid'] = includeTagTypeUid;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (classInt !== undefined) {
        localVarQueryParameter['class_int'] = classInt;
      }

      if (histogramClass !== undefined) {
        localVarQueryParameter['histogram_class'] = histogramClass;
      }

      if (ignoreCache !== undefined) {
        localVarQueryParameter['ignore_cache'] = ignoreCache;
      }

      if (outputFormat !== undefined) {
        localVarQueryParameter['output_format'] = outputFormat;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch label distributions empirically (over true GT), in training set, and in model outputs.  In the response, each dict is a map of label (int) => count (int) for the different distributions.
     * @summary Get Label Distributions
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {Array<string>} [split]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {number} [classInt]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet: async (
      nodeUid: number,
      modelUid: number,
      split?: Array<string>,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      classInt?: number,
      ignoreCache?: boolean,
      applyPostprocessors?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'getLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet',
        'modelUid',
        modelUid,
      );
      const localVarPath =
        `/nodes/{node_uid}/models/{model_uid}/label-distribution`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split) {
        localVarQueryParameter['split'] = split;
      }

      if (includeTagTypeUid) {
        localVarQueryParameter['include_tag_type_uid'] = includeTagTypeUid;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (classInt !== undefined) {
        localVarQueryParameter['class_int'] = classInt;
      }

      if (ignoreCache !== undefined) {
        localVarQueryParameter['ignore_cache'] = ignoreCache;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return ranked list of LFs based on how much each contributed to model\'s errors.
     * @summary Get Lf Error Correlation
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {Array<string>} [split]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {number} [classInt]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [useLatestLfPackage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet: async (
      nodeUid: number,
      modelUid: number,
      split?: Array<string>,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      classInt?: number,
      ignoreCache?: boolean,
      useLatestLfPackage?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'getLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet',
        'modelUid',
        modelUid,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}/lf-errors`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split) {
        localVarQueryParameter['split'] = split;
      }

      if (includeTagTypeUid) {
        localVarQueryParameter['include_tag_type_uid'] = includeTagTypeUid;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (classInt !== undefined) {
        localVarQueryParameter['class_int'] = classInt;
      }

      if (ignoreCache !== undefined) {
        localVarQueryParameter['ignore_cache'] = ignoreCache;
      }

      if (useLatestLfPackage !== undefined) {
        localVarQueryParameter['use_latest_lf_package'] = useLatestLfPackage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Lf Quality Waffle
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {boolean} [useLatestLfPackage]
     * @param {string} [split]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet: async (
      nodeUid: number,
      modelUid: number,
      useLatestLfPackage?: boolean,
      split?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'getLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet',
        'modelUid',
        modelUid,
      );
      const localVarPath =
        `/nodes/{node_uid}/models/{model_uid}/class-lf-summary`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (useLatestLfPackage !== undefined) {
        localVarQueryParameter['use_latest_lf_package'] = useLatestLfPackage;
      }

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Generate a list of messages about how the model has generalized.
     * @summary Get Model Generalization Info
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet:
      async (
        nodeUid: number,
        modelUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'modelUid' is not null or undefined
        assertParamExists(
          'getModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet',
          'modelUid',
          modelUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/models/{model_uid}/generalization`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Precision Recall Curve
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {Array<string>} [split]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {number} [classInt]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet:
      async (
        nodeUid: number,
        modelUid: number,
        split?: Array<string>,
        includeTagTypeUid?: Array<number>,
        excludeTagTypeUid?: Array<number>,
        classInt?: number,
        ignoreCache?: boolean,
        applyPostprocessors?: boolean,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'modelUid' is not null or undefined
        assertParamExists(
          'getPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet',
          'modelUid',
          modelUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/models/{model_uid}/precision-recall-curve`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (split) {
          localVarQueryParameter['split'] = split;
        }

        if (includeTagTypeUid) {
          localVarQueryParameter['include_tag_type_uid'] = includeTagTypeUid;
        }

        if (excludeTagTypeUid) {
          localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
        }

        if (classInt !== undefined) {
          localVarQueryParameter['class_int'] = classInt;
        }

        if (ignoreCache !== undefined) {
          localVarQueryParameter['ignore_cache'] = ignoreCache;
        }

        if (applyPostprocessors !== undefined) {
          localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * AnalysisApi - functional programming interface
 * @export
 */
export const AnalysisApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AnalysisApiAxiosParamCreator(configuration);
  return {
    /**
     * Return \'confusion\' matrix indicating correct/incorrect/abstain outputs of Label Model vs End Model. <pre> +-------------------------+---------------------+ |                         |     End Model       | |                         +-----------+---------+ |                         | Incorrect | Correct | +-------------+-----------+-----------+---------+ |             | Incorrect |    #      |    #    | |             +-----------+-----------+---------+ | Label Model | Correct   |    #      |    #    | |             +-----------+-----------+---------+ |             | Abstain   |    #      |    #    | +-------------+-----------+-----------+---------+ </pre>
     * @summary Get Clarity Matrix
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {Array<string>} [split]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {number} [classInt]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet(
      nodeUid: number,
      modelUid: number,
      split?: Array<string>,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      classInt?: number,
      ignoreCache?: boolean,
      applyPostprocessors?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetClarityMatrixResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet(
          nodeUid,
          modelUid,
          split,
          includeTagTypeUid,
          excludeTagTypeUid,
          classInt,
          ignoreCache,
          applyPostprocessors,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnalysisApi.getClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Confusion Matrix
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {Array<string>} [split]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {number} [classInt]
     * @param {number} [histogramClass]
     * @param {boolean} [ignoreCache]
     * @param {string} [outputFormat]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet(
      nodeUid: number,
      modelUid: number,
      split?: Array<string>,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      classInt?: number,
      histogramClass?: number,
      ignoreCache?: boolean,
      outputFormat?: string,
      applyPostprocessors?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet(
          nodeUid,
          modelUid,
          split,
          includeTagTypeUid,
          excludeTagTypeUid,
          classInt,
          histogramClass,
          ignoreCache,
          outputFormat,
          applyPostprocessors,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnalysisApi.getConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch label distributions empirically (over true GT), in training set, and in model outputs.  In the response, each dict is a map of label (int) => count (int) for the different distributions.
     * @summary Get Label Distributions
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {Array<string>} [split]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {number} [classInt]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet(
      nodeUid: number,
      modelUid: number,
      split?: Array<string>,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      classInt?: number,
      ignoreCache?: boolean,
      applyPostprocessors?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetLabelDistributionsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet(
          nodeUid,
          modelUid,
          split,
          includeTagTypeUid,
          excludeTagTypeUid,
          classInt,
          ignoreCache,
          applyPostprocessors,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnalysisApi.getLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return ranked list of LFs based on how much each contributed to model\'s errors.
     * @summary Get Lf Error Correlation
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {Array<string>} [split]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {number} [classInt]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [useLatestLfPackage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet(
      nodeUid: number,
      modelUid: number,
      split?: Array<string>,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      classInt?: number,
      ignoreCache?: boolean,
      useLatestLfPackage?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetLfErrorCorrelationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet(
          nodeUid,
          modelUid,
          split,
          includeTagTypeUid,
          excludeTagTypeUid,
          classInt,
          ignoreCache,
          useLatestLfPackage,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnalysisApi.getLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Lf Quality Waffle
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {boolean} [useLatestLfPackage]
     * @param {string} [split]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet(
      nodeUid: number,
      modelUid: number,
      useLatestLfPackage?: boolean,
      split?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ClassLFQualitySummaries>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet(
          nodeUid,
          modelUid,
          useLatestLfPackage,
          split,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnalysisApi.getLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Generate a list of messages about how the model has generalized.
     * @summary Get Model Generalization Info
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet(
      nodeUid: number,
      modelUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetModelGeneralizationInfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet(
          nodeUid,
          modelUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnalysisApi.getModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Precision Recall Curve
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {Array<string>} [split]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {number} [classInt]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet(
      nodeUid: number,
      modelUid: number,
      split?: Array<string>,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      classInt?: number,
      ignoreCache?: boolean,
      applyPostprocessors?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PrecisionRecallCurveResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet(
          nodeUid,
          modelUid,
          split,
          includeTagTypeUid,
          excludeTagTypeUid,
          classInt,
          ignoreCache,
          applyPostprocessors,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnalysisApi.getPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AnalysisApi - factory interface
 * @export
 */
export const AnalysisApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AnalysisApiFp(configuration);
  return {
    /**
     * Return \'confusion\' matrix indicating correct/incorrect/abstain outputs of Label Model vs End Model. <pre> +-------------------------+---------------------+ |                         |     End Model       | |                         +-----------+---------+ |                         | Incorrect | Correct | +-------------+-----------+-----------+---------+ |             | Incorrect |    #      |    #    | |             +-----------+-----------+---------+ | Label Model | Correct   |    #      |    #    | |             +-----------+-----------+---------+ |             | Abstain   |    #      |    #    | +-------------+-----------+-----------+---------+ </pre>
     * @summary Get Clarity Matrix
     * @param {AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet(
      requestParameters: AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetClarityMatrixResponse> {
      return localVarFp
        .getClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.includeTagTypeUid,
          requestParameters.excludeTagTypeUid,
          requestParameters.classInt,
          requestParameters.ignoreCache,
          requestParameters.applyPostprocessors,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Confusion Matrix
     * @param {AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet(
      requestParameters: AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ResponseGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet> {
      return localVarFp
        .getConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.includeTagTypeUid,
          requestParameters.excludeTagTypeUid,
          requestParameters.classInt,
          requestParameters.histogramClass,
          requestParameters.ignoreCache,
          requestParameters.outputFormat,
          requestParameters.applyPostprocessors,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch label distributions empirically (over true GT), in training set, and in model outputs.  In the response, each dict is a map of label (int) => count (int) for the different distributions.
     * @summary Get Label Distributions
     * @param {AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet(
      requestParameters: AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetLabelDistributionsResponse> {
      return localVarFp
        .getLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.includeTagTypeUid,
          requestParameters.excludeTagTypeUid,
          requestParameters.classInt,
          requestParameters.ignoreCache,
          requestParameters.applyPostprocessors,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Return ranked list of LFs based on how much each contributed to model\'s errors.
     * @summary Get Lf Error Correlation
     * @param {AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet(
      requestParameters: AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetLfErrorCorrelationResponse> {
      return localVarFp
        .getLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.includeTagTypeUid,
          requestParameters.excludeTagTypeUid,
          requestParameters.classInt,
          requestParameters.ignoreCache,
          requestParameters.useLatestLfPackage,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Lf Quality Waffle
     * @param {AnalysisApiGetLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet(
      requestParameters: AnalysisApiGetLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ClassLFQualitySummaries> {
      return localVarFp
        .getLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.useLatestLfPackage,
          requestParameters.split,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Generate a list of messages about how the model has generalized.
     * @summary Get Model Generalization Info
     * @param {AnalysisApiGetModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet(
      requestParameters: AnalysisApiGetModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetModelGeneralizationInfoResponse> {
      return localVarFp
        .getModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Precision Recall Curve
     * @param {AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet(
      requestParameters: AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PrecisionRecallCurveResponse> {
      return localVarFp
        .getPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.includeTagTypeUid,
          requestParameters.excludeTagTypeUid,
          requestParameters.classInt,
          requestParameters.ignoreCache,
          requestParameters.applyPostprocessors,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet operation in AnalysisApi.
 * @export
 * @interface AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGetRequest
 */
export interface AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {Array<string>}
   * @memberof AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet
   */
  readonly split?: Array<string>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet
   */
  readonly includeTagTypeUid?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet
   */
  readonly excludeTagTypeUid?: Array<number>;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet
   */
  readonly classInt?: number;

  /**
   *
   * @type {boolean}
   * @memberof AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet
   */
  readonly ignoreCache?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet
   */
  readonly applyPostprocessors?: boolean;
}

/**
 * Request parameters for getConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet operation in AnalysisApi.
 * @export
 * @interface AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGetRequest
 */
export interface AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {Array<string>}
   * @memberof AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  readonly split?: Array<string>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  readonly includeTagTypeUid?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  readonly excludeTagTypeUid?: Array<number>;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  readonly classInt?: number;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  readonly histogramClass?: number;

  /**
   *
   * @type {boolean}
   * @memberof AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  readonly ignoreCache?: boolean;

  /**
   *
   * @type {string}
   * @memberof AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  readonly outputFormat?: string;

  /**
   *
   * @type {boolean}
   * @memberof AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet
   */
  readonly applyPostprocessors?: boolean;
}

/**
 * Request parameters for getLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet operation in AnalysisApi.
 * @export
 * @interface AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGetRequest
 */
export interface AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {Array<string>}
   * @memberof AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet
   */
  readonly split?: Array<string>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet
   */
  readonly includeTagTypeUid?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet
   */
  readonly excludeTagTypeUid?: Array<number>;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet
   */
  readonly classInt?: number;

  /**
   *
   * @type {boolean}
   * @memberof AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet
   */
  readonly ignoreCache?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet
   */
  readonly applyPostprocessors?: boolean;
}

/**
 * Request parameters for getLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet operation in AnalysisApi.
 * @export
 * @interface AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGetRequest
 */
export interface AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {Array<string>}
   * @memberof AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet
   */
  readonly split?: Array<string>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet
   */
  readonly includeTagTypeUid?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet
   */
  readonly excludeTagTypeUid?: Array<number>;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet
   */
  readonly classInt?: number;

  /**
   *
   * @type {boolean}
   * @memberof AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet
   */
  readonly ignoreCache?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet
   */
  readonly useLatestLfPackage?: boolean;
}

/**
 * Request parameters for getLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet operation in AnalysisApi.
 * @export
 * @interface AnalysisApiGetLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGetRequest
 */
export interface AnalysisApiGetLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {boolean}
   * @memberof AnalysisApiGetLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet
   */
  readonly useLatestLfPackage?: boolean;

  /**
   *
   * @type {string}
   * @memberof AnalysisApiGetLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet
   */
  readonly split?: string;
}

/**
 * Request parameters for getModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet operation in AnalysisApi.
 * @export
 * @interface AnalysisApiGetModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGetRequest
 */
export interface AnalysisApiGetModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet
   */
  readonly modelUid: number;
}

/**
 * Request parameters for getPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet operation in AnalysisApi.
 * @export
 * @interface AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGetRequest
 */
export interface AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {Array<string>}
   * @memberof AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet
   */
  readonly split?: Array<string>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet
   */
  readonly includeTagTypeUid?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet
   */
  readonly excludeTagTypeUid?: Array<number>;

  /**
   *
   * @type {number}
   * @memberof AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet
   */
  readonly classInt?: number;

  /**
   *
   * @type {boolean}
   * @memberof AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet
   */
  readonly ignoreCache?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet
   */
  readonly applyPostprocessors?: boolean;
}

/**
 * AnalysisApi - object-oriented interface
 * @export
 * @class AnalysisApi
 * @extends {BaseAPI}
 */
export class AnalysisApi extends BaseAPI {
  /**
   * Return \'confusion\' matrix indicating correct/incorrect/abstain outputs of Label Model vs End Model. <pre> +-------------------------+---------------------+ |                         |     End Model       | |                         +-----------+---------+ |                         | Incorrect | Correct | +-------------+-----------+-----------+---------+ |             | Incorrect |    #      |    #    | |             +-----------+-----------+---------+ | Label Model | Correct   |    #      |    #    | |             +-----------+-----------+---------+ |             | Abstain   |    #      |    #    | +-------------+-----------+-----------+---------+ </pre>
   * @summary Get Clarity Matrix
   * @param {AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnalysisApi
   */
  public getClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet(
    requestParameters: AnalysisApiGetClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnalysisApiFp(this.configuration)
      .getClarityMatrixNodesNodeUidModelsModelUidClarityMatrixGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.includeTagTypeUid,
        requestParameters.excludeTagTypeUid,
        requestParameters.classInt,
        requestParameters.ignoreCache,
        requestParameters.applyPostprocessors,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Confusion Matrix
   * @param {AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnalysisApi
   */
  public getConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet(
    requestParameters: AnalysisApiGetConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnalysisApiFp(this.configuration)
      .getConfusionMatrixNodesNodeUidModelsModelUidConfusionMatrixGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.includeTagTypeUid,
        requestParameters.excludeTagTypeUid,
        requestParameters.classInt,
        requestParameters.histogramClass,
        requestParameters.ignoreCache,
        requestParameters.outputFormat,
        requestParameters.applyPostprocessors,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch label distributions empirically (over true GT), in training set, and in model outputs.  In the response, each dict is a map of label (int) => count (int) for the different distributions.
   * @summary Get Label Distributions
   * @param {AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnalysisApi
   */
  public getLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet(
    requestParameters: AnalysisApiGetLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnalysisApiFp(this.configuration)
      .getLabelDistributionsNodesNodeUidModelsModelUidLabelDistributionGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.includeTagTypeUid,
        requestParameters.excludeTagTypeUid,
        requestParameters.classInt,
        requestParameters.ignoreCache,
        requestParameters.applyPostprocessors,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Return ranked list of LFs based on how much each contributed to model\'s errors.
   * @summary Get Lf Error Correlation
   * @param {AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnalysisApi
   */
  public getLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet(
    requestParameters: AnalysisApiGetLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnalysisApiFp(this.configuration)
      .getLfErrorCorrelationNodesNodeUidModelsModelUidLfErrorsGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.includeTagTypeUid,
        requestParameters.excludeTagTypeUid,
        requestParameters.classInt,
        requestParameters.ignoreCache,
        requestParameters.useLatestLfPackage,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Lf Quality Waffle
   * @param {AnalysisApiGetLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnalysisApi
   */
  public getLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet(
    requestParameters: AnalysisApiGetLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnalysisApiFp(this.configuration)
      .getLfQualityWaffleNodesNodeUidModelsModelUidClassLfSummaryGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.useLatestLfPackage,
        requestParameters.split,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Generate a list of messages about how the model has generalized.
   * @summary Get Model Generalization Info
   * @param {AnalysisApiGetModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnalysisApi
   */
  public getModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet(
    requestParameters: AnalysisApiGetModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnalysisApiFp(this.configuration)
      .getModelGeneralizationInfoNodesNodeUidModelsModelUidGeneralizationGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Precision Recall Curve
   * @param {AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnalysisApi
   */
  public getPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet(
    requestParameters: AnalysisApiGetPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnalysisApiFp(this.configuration)
      .getPrecisionRecallCurveNodesNodeUidModelsModelUidPrecisionRecallCurveGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.includeTagTypeUid,
        requestParameters.excludeTagTypeUid,
        requestParameters.classInt,
        requestParameters.ignoreCache,
        requestParameters.applyPostprocessors,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AnnotationApi - axios parameter creator
 * @export
 */
export const AnnotationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Annotation
     * @param {CreateDatasetAnnotationParams} createDatasetAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAnnotationDatasetAnnotationPost: async (
      createDatasetAnnotationParams: CreateDatasetAnnotationParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createDatasetAnnotationParams' is not null or undefined
      assertParamExists(
        'addAnnotationDatasetAnnotationPost',
        'createDatasetAnnotationParams',
        createDatasetAnnotationParams,
      );
      const localVarPath = `/dataset-annotation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDatasetAnnotationParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Aggregate Annotations
     * @param {AggregateDatasetAnnotationsParams} aggregateDatasetAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    aggregateAnnotationsAggregateDatasetAnnotationsPost: async (
      aggregateDatasetAnnotationsParams: AggregateDatasetAnnotationsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'aggregateDatasetAnnotationsParams' is not null or undefined
      assertParamExists(
        'aggregateAnnotationsAggregateDatasetAnnotationsPost',
        'aggregateDatasetAnnotationsParams',
        aggregateDatasetAnnotationsParams,
      );
      const localVarPath = `/aggregate/dataset-annotations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        aggregateDatasetAnnotationsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Aggregate Annotations By X Uid
     * @param {number} datasetUid
     * @param {number} datasetBatchUid
     * @param {string} xUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet: async (
      datasetUid: number,
      datasetBatchUid: number,
      xUid: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'datasetBatchUid' is not null or undefined
      assertParamExists(
        'aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet',
        'datasetBatchUid',
        datasetBatchUid,
      );
      // verify required parameter 'xUid' is not null or undefined
      assertParamExists(
        'aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet',
        'xUid',
        xUid,
      );
      const localVarPath = `/aggregate/dataset-annotations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasetUid !== undefined) {
        localVarQueryParameter['dataset_uid'] = datasetUid;
      }

      if (datasetBatchUid !== undefined) {
        localVarQueryParameter['dataset_batch_uid'] = datasetBatchUid;
      }

      if (xUid !== undefined) {
        localVarQueryParameter['x_uid'] = xUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Commit Annotation
     * @param {CommitDatasetAnnotationParams} commitDatasetAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitAnnotationCommitDatasetAnnotationPost: async (
      commitDatasetAnnotationParams: CommitDatasetAnnotationParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'commitDatasetAnnotationParams' is not null or undefined
      assertParamExists(
        'commitAnnotationCommitDatasetAnnotationPost',
        'commitDatasetAnnotationParams',
        commitDatasetAnnotationParams,
      );
      const localVarPath = `/commit/dataset-annotation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        commitDatasetAnnotationParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Annotation
     * @param {DeleteDatasetAnnotationParams} deleteDatasetAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAnnotationDatasetAnnotationDelete: async (
      deleteDatasetAnnotationParams: DeleteDatasetAnnotationParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteDatasetAnnotationParams' is not null or undefined
      assertParamExists(
        'deleteAnnotationDatasetAnnotationDelete',
        'deleteDatasetAnnotationParams',
        deleteDatasetAnnotationParams,
      );
      const localVarPath = `/dataset-annotation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteDatasetAnnotationParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Annotations
     * @param {DeleteDatasetAnnotationsParams} deleteDatasetAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAnnotationsDatasetAnnotationsDelete: async (
      deleteDatasetAnnotationsParams: DeleteDatasetAnnotationsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteDatasetAnnotationsParams' is not null or undefined
      assertParamExists(
        'deleteAnnotationsDatasetAnnotationsDelete',
        'deleteDatasetAnnotationsParams',
        deleteDatasetAnnotationsParams,
      );
      const localVarPath = `/dataset-annotations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteDatasetAnnotationsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Annotation Rate
     * @param {number} datasetUid
     * @param {number} labelSchemaUid
     * @param {string} unit
     * @param {number} last
     * @param {Array<number>} [userUids]
     * @param {boolean} [showAllAnnotations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationRateAnnotationsRateGet: async (
      datasetUid: number,
      labelSchemaUid: number,
      unit: string,
      last: number,
      userUids?: Array<number>,
      showAllAnnotations?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getAnnotationRateAnnotationsRateGet',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'labelSchemaUid' is not null or undefined
      assertParamExists(
        'getAnnotationRateAnnotationsRateGet',
        'labelSchemaUid',
        labelSchemaUid,
      );
      // verify required parameter 'unit' is not null or undefined
      assertParamExists('getAnnotationRateAnnotationsRateGet', 'unit', unit);
      // verify required parameter 'last' is not null or undefined
      assertParamExists('getAnnotationRateAnnotationsRateGet', 'last', last);
      const localVarPath = `/annotations-rate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasetUid !== undefined) {
        localVarQueryParameter['dataset_uid'] = datasetUid;
      }

      if (labelSchemaUid !== undefined) {
        localVarQueryParameter['label_schema_uid'] = labelSchemaUid;
      }

      if (unit !== undefined) {
        localVarQueryParameter['unit'] = unit;
      }

      if (last !== undefined) {
        localVarQueryParameter['last'] = last;
      }

      if (userUids) {
        localVarQueryParameter['user_uids'] = userUids;
      }

      if (showAllAnnotations !== undefined) {
        localVarQueryParameter['show_all_annotations'] = showAllAnnotations;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Annotations
     * @param {number} datasetUid
     * @param {number} [userUid]
     * @param {Array<number>} [userUids]
     * @param {Array<string>} [xUids]
     * @param {any} [label]
     * @param {number} [sourceUid]
     * @param {boolean} [includeBatchMetadata]
     * @param {Array<number>} [labelSchemaUids]
     * @param {boolean} [userFormattedLabel]
     * @param {SupportedSortColumns} [sortColumn]
     * @param {boolean} [ascending]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsDatasetAnnotationsGet: async (
      datasetUid: number,
      userUid?: number,
      userUids?: Array<number>,
      xUids?: Array<string>,
      label?: any,
      sourceUid?: number,
      includeBatchMetadata?: boolean,
      labelSchemaUids?: Array<number>,
      userFormattedLabel?: boolean,
      sortColumn?: SupportedSortColumns,
      ascending?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getAnnotationsDatasetAnnotationsGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/dataset-annotations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasetUid !== undefined) {
        localVarQueryParameter['dataset_uid'] = datasetUid;
      }

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      if (userUids) {
        localVarQueryParameter['user_uids'] = userUids;
      }

      if (xUids) {
        localVarQueryParameter['x_uids'] = xUids;
      }

      if (label !== undefined) {
        for (const [key, value] of Object.entries(label)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (sourceUid !== undefined) {
        localVarQueryParameter['source_uid'] = sourceUid;
      }

      if (includeBatchMetadata !== undefined) {
        localVarQueryParameter['include_batch_metadata'] = includeBatchMetadata;
      }

      if (labelSchemaUids) {
        localVarQueryParameter['label_schema_uids'] = labelSchemaUids;
      }

      if (userFormattedLabel !== undefined) {
        localVarQueryParameter['user_formatted_label'] = userFormattedLabel;
      }

      if (sortColumn !== undefined) {
        localVarQueryParameter['sort_column'] = sortColumn;
      }

      if (ascending !== undefined) {
        localVarQueryParameter['ascending'] = ascending;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Annotations Overview
     * @param {number} datasetUid
     * @param {number} labelSchemaUid
     * @param {Array<number>} [userUids]
     * @param {boolean} [showAllAnnotations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsOverviewAnnotationsOverviewGet: async (
      datasetUid: number,
      labelSchemaUid: number,
      userUids?: Array<number>,
      showAllAnnotations?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getAnnotationsOverviewAnnotationsOverviewGet',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'labelSchemaUid' is not null or undefined
      assertParamExists(
        'getAnnotationsOverviewAnnotationsOverviewGet',
        'labelSchemaUid',
        labelSchemaUid,
      );
      const localVarPath = `/annotations-overview`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasetUid !== undefined) {
        localVarQueryParameter['dataset_uid'] = datasetUid;
      }

      if (labelSchemaUid !== undefined) {
        localVarQueryParameter['label_schema_uid'] = labelSchemaUid;
      }

      if (userUids) {
        localVarQueryParameter['user_uids'] = userUids;
      }

      if (showAllAnnotations !== undefined) {
        localVarQueryParameter['show_all_annotations'] = showAllAnnotations;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Interannotator Agreement
     * @param {number} datasetUid
     * @param {number} labelSchemaUid
     * @param {Array<number>} [userUids]
     * @param {Array<number>} [batchUids]
     * @param {number} [classValue]
     * @param {string} [metric]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInterannotatorAgreementAnnotatorAgreementGet: async (
      datasetUid: number,
      labelSchemaUid: number,
      userUids?: Array<number>,
      batchUids?: Array<number>,
      classValue?: number,
      metric?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getInterannotatorAgreementAnnotatorAgreementGet',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'labelSchemaUid' is not null or undefined
      assertParamExists(
        'getInterannotatorAgreementAnnotatorAgreementGet',
        'labelSchemaUid',
        labelSchemaUid,
      );
      const localVarPath = `/annotator-agreement`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasetUid !== undefined) {
        localVarQueryParameter['dataset_uid'] = datasetUid;
      }

      if (labelSchemaUid !== undefined) {
        localVarQueryParameter['label_schema_uid'] = labelSchemaUid;
      }

      if (userUids) {
        localVarQueryParameter['user_uids'] = userUids;
      }

      if (batchUids) {
        localVarQueryParameter['batch_uids'] = batchUids;
      }

      if (classValue !== undefined) {
        localVarQueryParameter['class_value'] = classValue;
      }

      if (metric !== undefined) {
        localVarQueryParameter['metric'] = metric;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import Annotations
     * @param {ImportDatasetAnnotationsParams} importDatasetAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importAnnotationsDatasetAnnotationsPost: async (
      importDatasetAnnotationsParams: ImportDatasetAnnotationsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'importDatasetAnnotationsParams' is not null or undefined
      assertParamExists(
        'importAnnotationsDatasetAnnotationsPost',
        'importDatasetAnnotationsParams',
        importDatasetAnnotationsParams,
      );
      const localVarPath = `/dataset-annotations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        importDatasetAnnotationsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Annotation
     * @param {UpdateDatasetAnnotationParams} updateDatasetAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAnnotationDatasetAnnotationPut: async (
      updateDatasetAnnotationParams: UpdateDatasetAnnotationParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDatasetAnnotationParams' is not null or undefined
      assertParamExists(
        'updateAnnotationDatasetAnnotationPut',
        'updateDatasetAnnotationParams',
        updateDatasetAnnotationParams,
      );
      const localVarPath = `/dataset-annotation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDatasetAnnotationParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AnnotationApi - functional programming interface
 * @export
 */
export const AnnotationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AnnotationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Annotation
     * @param {CreateDatasetAnnotationParams} createDatasetAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addAnnotationDatasetAnnotationPost(
      createDatasetAnnotationParams: CreateDatasetAnnotationParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateDatasetAnnotationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addAnnotationDatasetAnnotationPost(
          createDatasetAnnotationParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.addAnnotationDatasetAnnotationPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Aggregate Annotations
     * @param {AggregateDatasetAnnotationsParams} aggregateDatasetAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async aggregateAnnotationsAggregateDatasetAnnotationsPost(
      aggregateDatasetAnnotationsParams: AggregateDatasetAnnotationsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.aggregateAnnotationsAggregateDatasetAnnotationsPost(
          aggregateDatasetAnnotationsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.aggregateAnnotationsAggregateDatasetAnnotationsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Aggregate Annotations By X Uid
     * @param {number} datasetUid
     * @param {number} datasetBatchUid
     * @param {string} xUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet(
      datasetUid: number,
      datasetBatchUid: number,
      xUid: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAnnotationsByXUidResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet(
          datasetUid,
          datasetBatchUid,
          xUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Commit Annotation
     * @param {CommitDatasetAnnotationParams} commitDatasetAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commitAnnotationCommitDatasetAnnotationPost(
      commitDatasetAnnotationParams: CommitDatasetAnnotationParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commitAnnotationCommitDatasetAnnotationPost(
          commitDatasetAnnotationParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.commitAnnotationCommitDatasetAnnotationPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Annotation
     * @param {DeleteDatasetAnnotationParams} deleteDatasetAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAnnotationDatasetAnnotationDelete(
      deleteDatasetAnnotationParams: DeleteDatasetAnnotationParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAnnotationDatasetAnnotationDelete(
          deleteDatasetAnnotationParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.deleteAnnotationDatasetAnnotationDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Annotations
     * @param {DeleteDatasetAnnotationsParams} deleteDatasetAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAnnotationsDatasetAnnotationsDelete(
      deleteDatasetAnnotationsParams: DeleteDatasetAnnotationsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAnnotationsDatasetAnnotationsDelete(
          deleteDatasetAnnotationsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.deleteAnnotationsDatasetAnnotationsDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Annotation Rate
     * @param {number} datasetUid
     * @param {number} labelSchemaUid
     * @param {string} unit
     * @param {number} last
     * @param {Array<number>} [userUids]
     * @param {boolean} [showAllAnnotations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnnotationRateAnnotationsRateGet(
      datasetUid: number,
      labelSchemaUid: number,
      unit: string,
      last: number,
      userUids?: Array<number>,
      showAllAnnotations?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAnnotationRateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAnnotationRateAnnotationsRateGet(
          datasetUid,
          labelSchemaUid,
          unit,
          last,
          userUids,
          showAllAnnotations,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.getAnnotationRateAnnotationsRateGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Annotations
     * @param {number} datasetUid
     * @param {number} [userUid]
     * @param {Array<number>} [userUids]
     * @param {Array<string>} [xUids]
     * @param {any} [label]
     * @param {number} [sourceUid]
     * @param {boolean} [includeBatchMetadata]
     * @param {Array<number>} [labelSchemaUids]
     * @param {boolean} [userFormattedLabel]
     * @param {SupportedSortColumns} [sortColumn]
     * @param {boolean} [ascending]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnnotationsDatasetAnnotationsGet(
      datasetUid: number,
      userUid?: number,
      userUids?: Array<number>,
      xUids?: Array<string>,
      label?: any,
      sourceUid?: number,
      includeBatchMetadata?: boolean,
      labelSchemaUids?: Array<number>,
      userFormattedLabel?: boolean,
      sortColumn?: SupportedSortColumns,
      ascending?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDatasetAnnotationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAnnotationsDatasetAnnotationsGet(
          datasetUid,
          userUid,
          userUids,
          xUids,
          label,
          sourceUid,
          includeBatchMetadata,
          labelSchemaUids,
          userFormattedLabel,
          sortColumn,
          ascending,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.getAnnotationsDatasetAnnotationsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Annotations Overview
     * @param {number} datasetUid
     * @param {number} labelSchemaUid
     * @param {Array<number>} [userUids]
     * @param {boolean} [showAllAnnotations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnnotationsOverviewAnnotationsOverviewGet(
      datasetUid: number,
      labelSchemaUid: number,
      userUids?: Array<number>,
      showAllAnnotations?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DatasetAnnotationsOverviewResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAnnotationsOverviewAnnotationsOverviewGet(
          datasetUid,
          labelSchemaUid,
          userUids,
          showAllAnnotations,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.getAnnotationsOverviewAnnotationsOverviewGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Interannotator Agreement
     * @param {number} datasetUid
     * @param {number} labelSchemaUid
     * @param {Array<number>} [userUids]
     * @param {Array<number>} [batchUids]
     * @param {number} [classValue]
     * @param {string} [metric]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInterannotatorAgreementAnnotatorAgreementGet(
      datasetUid: number,
      labelSchemaUid: number,
      userUids?: Array<number>,
      batchUids?: Array<number>,
      classValue?: number,
      metric?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InterAnnotatorAgreement>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInterannotatorAgreementAnnotatorAgreementGet(
          datasetUid,
          labelSchemaUid,
          userUids,
          batchUids,
          classValue,
          metric,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.getInterannotatorAgreementAnnotatorAgreementGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Import Annotations
     * @param {ImportDatasetAnnotationsParams} importDatasetAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importAnnotationsDatasetAnnotationsPost(
      importDatasetAnnotationsParams: ImportDatasetAnnotationsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ImportDatasetAnnotationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.importAnnotationsDatasetAnnotationsPost(
          importDatasetAnnotationsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.importAnnotationsDatasetAnnotationsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Annotation
     * @param {UpdateDatasetAnnotationParams} updateDatasetAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAnnotationDatasetAnnotationPut(
      updateDatasetAnnotationParams: UpdateDatasetAnnotationParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAnnotationDatasetAnnotationPut(
          updateDatasetAnnotationParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationApi.updateAnnotationDatasetAnnotationPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AnnotationApi - factory interface
 * @export
 */
export const AnnotationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AnnotationApiFp(configuration);
  return {
    /**
     *
     * @summary Add Annotation
     * @param {AnnotationApiAddAnnotationDatasetAnnotationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAnnotationDatasetAnnotationPost(
      requestParameters: AnnotationApiAddAnnotationDatasetAnnotationPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateDatasetAnnotationResponse> {
      return localVarFp
        .addAnnotationDatasetAnnotationPost(
          requestParameters.createDatasetAnnotationParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Aggregate Annotations
     * @param {AnnotationApiAggregateAnnotationsAggregateDatasetAnnotationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    aggregateAnnotationsAggregateDatasetAnnotationsPost(
      requestParameters: AnnotationApiAggregateAnnotationsAggregateDatasetAnnotationsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .aggregateAnnotationsAggregateDatasetAnnotationsPost(
          requestParameters.aggregateDatasetAnnotationsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Aggregate Annotations By X Uid
     * @param {AnnotationApiAggregateAnnotationsByXUidAggregateDatasetAnnotationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet(
      requestParameters: AnnotationApiAggregateAnnotationsByXUidAggregateDatasetAnnotationsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetAnnotationsByXUidResponse> {
      return localVarFp
        .aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet(
          requestParameters.datasetUid,
          requestParameters.datasetBatchUid,
          requestParameters.xUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Commit Annotation
     * @param {AnnotationApiCommitAnnotationCommitDatasetAnnotationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitAnnotationCommitDatasetAnnotationPost(
      requestParameters: AnnotationApiCommitAnnotationCommitDatasetAnnotationPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .commitAnnotationCommitDatasetAnnotationPost(
          requestParameters.commitDatasetAnnotationParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Annotation
     * @param {AnnotationApiDeleteAnnotationDatasetAnnotationDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAnnotationDatasetAnnotationDelete(
      requestParameters: AnnotationApiDeleteAnnotationDatasetAnnotationDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteAnnotationDatasetAnnotationDelete(
          requestParameters.deleteDatasetAnnotationParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Annotations
     * @param {AnnotationApiDeleteAnnotationsDatasetAnnotationsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAnnotationsDatasetAnnotationsDelete(
      requestParameters: AnnotationApiDeleteAnnotationsDatasetAnnotationsDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteAnnotationsDatasetAnnotationsDelete(
          requestParameters.deleteDatasetAnnotationsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Annotation Rate
     * @param {AnnotationApiGetAnnotationRateAnnotationsRateGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationRateAnnotationsRateGet(
      requestParameters: AnnotationApiGetAnnotationRateAnnotationsRateGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetAnnotationRateResponse> {
      return localVarFp
        .getAnnotationRateAnnotationsRateGet(
          requestParameters.datasetUid,
          requestParameters.labelSchemaUid,
          requestParameters.unit,
          requestParameters.last,
          requestParameters.userUids,
          requestParameters.showAllAnnotations,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Annotations
     * @param {AnnotationApiGetAnnotationsDatasetAnnotationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsDatasetAnnotationsGet(
      requestParameters: AnnotationApiGetAnnotationsDatasetAnnotationsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetDatasetAnnotationsResponse> {
      return localVarFp
        .getAnnotationsDatasetAnnotationsGet(
          requestParameters.datasetUid,
          requestParameters.userUid,
          requestParameters.userUids,
          requestParameters.xUids,
          requestParameters.label,
          requestParameters.sourceUid,
          requestParameters.includeBatchMetadata,
          requestParameters.labelSchemaUids,
          requestParameters.userFormattedLabel,
          requestParameters.sortColumn,
          requestParameters.ascending,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Annotations Overview
     * @param {AnnotationApiGetAnnotationsOverviewAnnotationsOverviewGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsOverviewAnnotationsOverviewGet(
      requestParameters: AnnotationApiGetAnnotationsOverviewAnnotationsOverviewGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetAnnotationsOverviewResponse> {
      return localVarFp
        .getAnnotationsOverviewAnnotationsOverviewGet(
          requestParameters.datasetUid,
          requestParameters.labelSchemaUid,
          requestParameters.userUids,
          requestParameters.showAllAnnotations,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Interannotator Agreement
     * @param {AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInterannotatorAgreementAnnotatorAgreementGet(
      requestParameters: AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<InterAnnotatorAgreement> {
      return localVarFp
        .getInterannotatorAgreementAnnotatorAgreementGet(
          requestParameters.datasetUid,
          requestParameters.labelSchemaUid,
          requestParameters.userUids,
          requestParameters.batchUids,
          requestParameters.classValue,
          requestParameters.metric,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Import Annotations
     * @param {AnnotationApiImportAnnotationsDatasetAnnotationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importAnnotationsDatasetAnnotationsPost(
      requestParameters: AnnotationApiImportAnnotationsDatasetAnnotationsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ImportDatasetAnnotationsResponse> {
      return localVarFp
        .importAnnotationsDatasetAnnotationsPost(
          requestParameters.importDatasetAnnotationsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Annotation
     * @param {AnnotationApiUpdateAnnotationDatasetAnnotationPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAnnotationDatasetAnnotationPut(
      requestParameters: AnnotationApiUpdateAnnotationDatasetAnnotationPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateAnnotationDatasetAnnotationPut(
          requestParameters.updateDatasetAnnotationParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addAnnotationDatasetAnnotationPost operation in AnnotationApi.
 * @export
 * @interface AnnotationApiAddAnnotationDatasetAnnotationPostRequest
 */
export interface AnnotationApiAddAnnotationDatasetAnnotationPostRequest {
  /**
   *
   * @type {CreateDatasetAnnotationParams}
   * @memberof AnnotationApiAddAnnotationDatasetAnnotationPost
   */
  readonly createDatasetAnnotationParams: CreateDatasetAnnotationParams;
}

/**
 * Request parameters for aggregateAnnotationsAggregateDatasetAnnotationsPost operation in AnnotationApi.
 * @export
 * @interface AnnotationApiAggregateAnnotationsAggregateDatasetAnnotationsPostRequest
 */
export interface AnnotationApiAggregateAnnotationsAggregateDatasetAnnotationsPostRequest {
  /**
   *
   * @type {AggregateDatasetAnnotationsParams}
   * @memberof AnnotationApiAggregateAnnotationsAggregateDatasetAnnotationsPost
   */
  readonly aggregateDatasetAnnotationsParams: AggregateDatasetAnnotationsParams;
}

/**
 * Request parameters for aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet operation in AnnotationApi.
 * @export
 * @interface AnnotationApiAggregateAnnotationsByXUidAggregateDatasetAnnotationsGetRequest
 */
export interface AnnotationApiAggregateAnnotationsByXUidAggregateDatasetAnnotationsGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationApiAggregateAnnotationsByXUidAggregateDatasetAnnotationsGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof AnnotationApiAggregateAnnotationsByXUidAggregateDatasetAnnotationsGet
   */
  readonly datasetBatchUid: number;

  /**
   *
   * @type {string}
   * @memberof AnnotationApiAggregateAnnotationsByXUidAggregateDatasetAnnotationsGet
   */
  readonly xUid: string;
}

/**
 * Request parameters for commitAnnotationCommitDatasetAnnotationPost operation in AnnotationApi.
 * @export
 * @interface AnnotationApiCommitAnnotationCommitDatasetAnnotationPostRequest
 */
export interface AnnotationApiCommitAnnotationCommitDatasetAnnotationPostRequest {
  /**
   *
   * @type {CommitDatasetAnnotationParams}
   * @memberof AnnotationApiCommitAnnotationCommitDatasetAnnotationPost
   */
  readonly commitDatasetAnnotationParams: CommitDatasetAnnotationParams;
}

/**
 * Request parameters for deleteAnnotationDatasetAnnotationDelete operation in AnnotationApi.
 * @export
 * @interface AnnotationApiDeleteAnnotationDatasetAnnotationDeleteRequest
 */
export interface AnnotationApiDeleteAnnotationDatasetAnnotationDeleteRequest {
  /**
   *
   * @type {DeleteDatasetAnnotationParams}
   * @memberof AnnotationApiDeleteAnnotationDatasetAnnotationDelete
   */
  readonly deleteDatasetAnnotationParams: DeleteDatasetAnnotationParams;
}

/**
 * Request parameters for deleteAnnotationsDatasetAnnotationsDelete operation in AnnotationApi.
 * @export
 * @interface AnnotationApiDeleteAnnotationsDatasetAnnotationsDeleteRequest
 */
export interface AnnotationApiDeleteAnnotationsDatasetAnnotationsDeleteRequest {
  /**
   *
   * @type {DeleteDatasetAnnotationsParams}
   * @memberof AnnotationApiDeleteAnnotationsDatasetAnnotationsDelete
   */
  readonly deleteDatasetAnnotationsParams: DeleteDatasetAnnotationsParams;
}

/**
 * Request parameters for getAnnotationRateAnnotationsRateGet operation in AnnotationApi.
 * @export
 * @interface AnnotationApiGetAnnotationRateAnnotationsRateGetRequest
 */
export interface AnnotationApiGetAnnotationRateAnnotationsRateGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationApiGetAnnotationRateAnnotationsRateGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof AnnotationApiGetAnnotationRateAnnotationsRateGet
   */
  readonly labelSchemaUid: number;

  /**
   *
   * @type {string}
   * @memberof AnnotationApiGetAnnotationRateAnnotationsRateGet
   */
  readonly unit: string;

  /**
   *
   * @type {number}
   * @memberof AnnotationApiGetAnnotationRateAnnotationsRateGet
   */
  readonly last: number;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationApiGetAnnotationRateAnnotationsRateGet
   */
  readonly userUids?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof AnnotationApiGetAnnotationRateAnnotationsRateGet
   */
  readonly showAllAnnotations?: boolean;
}

/**
 * Request parameters for getAnnotationsDatasetAnnotationsGet operation in AnnotationApi.
 * @export
 * @interface AnnotationApiGetAnnotationsDatasetAnnotationsGetRequest
 */
export interface AnnotationApiGetAnnotationsDatasetAnnotationsGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationApiGetAnnotationsDatasetAnnotationsGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof AnnotationApiGetAnnotationsDatasetAnnotationsGet
   */
  readonly userUid?: number;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationApiGetAnnotationsDatasetAnnotationsGet
   */
  readonly userUids?: Array<number>;

  /**
   *
   * @type {Array<string>}
   * @memberof AnnotationApiGetAnnotationsDatasetAnnotationsGet
   */
  readonly xUids?: Array<string>;

  /**
   *
   * @type {any}
   * @memberof AnnotationApiGetAnnotationsDatasetAnnotationsGet
   */
  readonly label?: any;

  /**
   *
   * @type {number}
   * @memberof AnnotationApiGetAnnotationsDatasetAnnotationsGet
   */
  readonly sourceUid?: number;

  /**
   *
   * @type {boolean}
   * @memberof AnnotationApiGetAnnotationsDatasetAnnotationsGet
   */
  readonly includeBatchMetadata?: boolean;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationApiGetAnnotationsDatasetAnnotationsGet
   */
  readonly labelSchemaUids?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof AnnotationApiGetAnnotationsDatasetAnnotationsGet
   */
  readonly userFormattedLabel?: boolean;

  /**
   *
   * @type {SupportedSortColumns}
   * @memberof AnnotationApiGetAnnotationsDatasetAnnotationsGet
   */
  readonly sortColumn?: SupportedSortColumns;

  /**
   *
   * @type {boolean}
   * @memberof AnnotationApiGetAnnotationsDatasetAnnotationsGet
   */
  readonly ascending?: boolean;
}

/**
 * Request parameters for getAnnotationsOverviewAnnotationsOverviewGet operation in AnnotationApi.
 * @export
 * @interface AnnotationApiGetAnnotationsOverviewAnnotationsOverviewGetRequest
 */
export interface AnnotationApiGetAnnotationsOverviewAnnotationsOverviewGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationApiGetAnnotationsOverviewAnnotationsOverviewGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof AnnotationApiGetAnnotationsOverviewAnnotationsOverviewGet
   */
  readonly labelSchemaUid: number;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationApiGetAnnotationsOverviewAnnotationsOverviewGet
   */
  readonly userUids?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof AnnotationApiGetAnnotationsOverviewAnnotationsOverviewGet
   */
  readonly showAllAnnotations?: boolean;
}

/**
 * Request parameters for getInterannotatorAgreementAnnotatorAgreementGet operation in AnnotationApi.
 * @export
 * @interface AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGetRequest
 */
export interface AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGet
   */
  readonly labelSchemaUid: number;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGet
   */
  readonly userUids?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGet
   */
  readonly batchUids?: Array<number>;

  /**
   *
   * @type {number}
   * @memberof AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGet
   */
  readonly classValue?: number;

  /**
   *
   * @type {string}
   * @memberof AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGet
   */
  readonly metric?: string;
}

/**
 * Request parameters for importAnnotationsDatasetAnnotationsPost operation in AnnotationApi.
 * @export
 * @interface AnnotationApiImportAnnotationsDatasetAnnotationsPostRequest
 */
export interface AnnotationApiImportAnnotationsDatasetAnnotationsPostRequest {
  /**
   *
   * @type {ImportDatasetAnnotationsParams}
   * @memberof AnnotationApiImportAnnotationsDatasetAnnotationsPost
   */
  readonly importDatasetAnnotationsParams: ImportDatasetAnnotationsParams;
}

/**
 * Request parameters for updateAnnotationDatasetAnnotationPut operation in AnnotationApi.
 * @export
 * @interface AnnotationApiUpdateAnnotationDatasetAnnotationPutRequest
 */
export interface AnnotationApiUpdateAnnotationDatasetAnnotationPutRequest {
  /**
   *
   * @type {UpdateDatasetAnnotationParams}
   * @memberof AnnotationApiUpdateAnnotationDatasetAnnotationPut
   */
  readonly updateDatasetAnnotationParams: UpdateDatasetAnnotationParams;
}

/**
 * AnnotationApi - object-oriented interface
 * @export
 * @class AnnotationApi
 * @extends {BaseAPI}
 */
export class AnnotationApi extends BaseAPI {
  /**
   *
   * @summary Add Annotation
   * @param {AnnotationApiAddAnnotationDatasetAnnotationPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public addAnnotationDatasetAnnotationPost(
    requestParameters: AnnotationApiAddAnnotationDatasetAnnotationPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .addAnnotationDatasetAnnotationPost(
        requestParameters.createDatasetAnnotationParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Aggregate Annotations
   * @param {AnnotationApiAggregateAnnotationsAggregateDatasetAnnotationsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public aggregateAnnotationsAggregateDatasetAnnotationsPost(
    requestParameters: AnnotationApiAggregateAnnotationsAggregateDatasetAnnotationsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .aggregateAnnotationsAggregateDatasetAnnotationsPost(
        requestParameters.aggregateDatasetAnnotationsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Aggregate Annotations By X Uid
   * @param {AnnotationApiAggregateAnnotationsByXUidAggregateDatasetAnnotationsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet(
    requestParameters: AnnotationApiAggregateAnnotationsByXUidAggregateDatasetAnnotationsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .aggregateAnnotationsByXUidAggregateDatasetAnnotationsGet(
        requestParameters.datasetUid,
        requestParameters.datasetBatchUid,
        requestParameters.xUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Commit Annotation
   * @param {AnnotationApiCommitAnnotationCommitDatasetAnnotationPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public commitAnnotationCommitDatasetAnnotationPost(
    requestParameters: AnnotationApiCommitAnnotationCommitDatasetAnnotationPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .commitAnnotationCommitDatasetAnnotationPost(
        requestParameters.commitDatasetAnnotationParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Annotation
   * @param {AnnotationApiDeleteAnnotationDatasetAnnotationDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public deleteAnnotationDatasetAnnotationDelete(
    requestParameters: AnnotationApiDeleteAnnotationDatasetAnnotationDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .deleteAnnotationDatasetAnnotationDelete(
        requestParameters.deleteDatasetAnnotationParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Annotations
   * @param {AnnotationApiDeleteAnnotationsDatasetAnnotationsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public deleteAnnotationsDatasetAnnotationsDelete(
    requestParameters: AnnotationApiDeleteAnnotationsDatasetAnnotationsDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .deleteAnnotationsDatasetAnnotationsDelete(
        requestParameters.deleteDatasetAnnotationsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Annotation Rate
   * @param {AnnotationApiGetAnnotationRateAnnotationsRateGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public getAnnotationRateAnnotationsRateGet(
    requestParameters: AnnotationApiGetAnnotationRateAnnotationsRateGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .getAnnotationRateAnnotationsRateGet(
        requestParameters.datasetUid,
        requestParameters.labelSchemaUid,
        requestParameters.unit,
        requestParameters.last,
        requestParameters.userUids,
        requestParameters.showAllAnnotations,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Annotations
   * @param {AnnotationApiGetAnnotationsDatasetAnnotationsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public getAnnotationsDatasetAnnotationsGet(
    requestParameters: AnnotationApiGetAnnotationsDatasetAnnotationsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .getAnnotationsDatasetAnnotationsGet(
        requestParameters.datasetUid,
        requestParameters.userUid,
        requestParameters.userUids,
        requestParameters.xUids,
        requestParameters.label,
        requestParameters.sourceUid,
        requestParameters.includeBatchMetadata,
        requestParameters.labelSchemaUids,
        requestParameters.userFormattedLabel,
        requestParameters.sortColumn,
        requestParameters.ascending,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Annotations Overview
   * @param {AnnotationApiGetAnnotationsOverviewAnnotationsOverviewGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public getAnnotationsOverviewAnnotationsOverviewGet(
    requestParameters: AnnotationApiGetAnnotationsOverviewAnnotationsOverviewGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .getAnnotationsOverviewAnnotationsOverviewGet(
        requestParameters.datasetUid,
        requestParameters.labelSchemaUid,
        requestParameters.userUids,
        requestParameters.showAllAnnotations,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Interannotator Agreement
   * @param {AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public getInterannotatorAgreementAnnotatorAgreementGet(
    requestParameters: AnnotationApiGetInterannotatorAgreementAnnotatorAgreementGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .getInterannotatorAgreementAnnotatorAgreementGet(
        requestParameters.datasetUid,
        requestParameters.labelSchemaUid,
        requestParameters.userUids,
        requestParameters.batchUids,
        requestParameters.classValue,
        requestParameters.metric,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import Annotations
   * @param {AnnotationApiImportAnnotationsDatasetAnnotationsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public importAnnotationsDatasetAnnotationsPost(
    requestParameters: AnnotationApiImportAnnotationsDatasetAnnotationsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .importAnnotationsDatasetAnnotationsPost(
        requestParameters.importDatasetAnnotationsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Annotation
   * @param {AnnotationApiUpdateAnnotationDatasetAnnotationPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationApi
   */
  public updateAnnotationDatasetAnnotationPut(
    requestParameters: AnnotationApiUpdateAnnotationDatasetAnnotationPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationApiFp(this.configuration)
      .updateAnnotationDatasetAnnotationPut(
        requestParameters.updateDatasetAnnotationParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AnnotationsApi - axios parameter creator
 * @export
 */
export const AnnotationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Annotation
     * @param {number} nodeUid
     * @param {CreateAnnotationParams} createAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAnnotationNodesNodeUidAnnotationPost: async (
      nodeUid: number,
      createAnnotationParams: CreateAnnotationParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addAnnotationNodesNodeUidAnnotationPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'createAnnotationParams' is not null or undefined
      assertParamExists(
        'addAnnotationNodesNodeUidAnnotationPost',
        'createAnnotationParams',
        createAnnotationParams,
      );
      const localVarPath = `/nodes/{node_uid}/annotation`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAnnotationParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add Annotations
     * @param {number} nodeUid
     * @param {ImportAnnotationsParams} importAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAnnotationsNodesNodeUidAnnotationsPost: async (
      nodeUid: number,
      importAnnotationsParams: ImportAnnotationsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addAnnotationsNodesNodeUidAnnotationsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'importAnnotationsParams' is not null or undefined
      assertParamExists(
        'addAnnotationsNodesNodeUidAnnotationsPost',
        'importAnnotationsParams',
        importAnnotationsParams,
      );
      const localVarPath = `/nodes/{node_uid}/annotations`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        importAnnotationsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Aggregate Annotations
     * @param {number} nodeUid
     * @param {AggregateAnnotationsParams} aggregateAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    aggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost: async (
      nodeUid: number,
      aggregateAnnotationsParams: AggregateAnnotationsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'aggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'aggregateAnnotationsParams' is not null or undefined
      assertParamExists(
        'aggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost',
        'aggregateAnnotationsParams',
        aggregateAnnotationsParams,
      );
      const localVarPath =
        `/nodes/{node_uid}/annotations/aggregate-annotations`.replace(
          `{${'node_uid'}}`,
          encodeURIComponent(String(nodeUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        aggregateAnnotationsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Commit Annotations
     * @param {number} nodeUid
     * @param {CommitAnnotationsParams} commitAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitAnnotationsNodesNodeUidCommitAnnotationsPost: async (
      nodeUid: number,
      commitAnnotationsParams: CommitAnnotationsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'commitAnnotationsNodesNodeUidCommitAnnotationsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'commitAnnotationsParams' is not null or undefined
      assertParamExists(
        'commitAnnotationsNodesNodeUidCommitAnnotationsPost',
        'commitAnnotationsParams',
        commitAnnotationsParams,
      );
      const localVarPath = `/nodes/{node_uid}/commit-annotations`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        commitAnnotationsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Annotation
     * @param {number} nodeUid
     * @param {number} annotationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete: async (
      nodeUid: number,
      annotationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'deleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'annotationUid' is not null or undefined
      assertParamExists(
        'deleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete',
        'annotationUid',
        annotationUid,
      );
      const localVarPath = `/nodes/{node_uid}/annotations/{annotation_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'annotation_uid'}}`,
          encodeURIComponent(String(annotationUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Annotations
     * @param {number} nodeUid
     * @param {DeleteAnnotationsParams} deleteAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAnnotationsNodesNodeUidAnnotationsDelete: async (
      nodeUid: number,
      deleteAnnotationsParams: DeleteAnnotationsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'deleteAnnotationsNodesNodeUidAnnotationsDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'deleteAnnotationsParams' is not null or undefined
      assertParamExists(
        'deleteAnnotationsNodesNodeUidAnnotationsDelete',
        'deleteAnnotationsParams',
        deleteAnnotationsParams,
      );
      const localVarPath = `/nodes/{node_uid}/annotations`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteAnnotationsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export Annotations
     * @param {number} nodeUid
     * @param {string} unit
     * @param {number} last
     * @param {Array<number>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAnnotationsNodesNodeUidExportAnnotationsGet: async (
      nodeUid: number,
      unit: string,
      last: number,
      requestBody?: Array<number>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'exportAnnotationsNodesNodeUidExportAnnotationsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'unit' is not null or undefined
      assertParamExists(
        'exportAnnotationsNodesNodeUidExportAnnotationsGet',
        'unit',
        unit,
      );
      // verify required parameter 'last' is not null or undefined
      assertParamExists(
        'exportAnnotationsNodesNodeUidExportAnnotationsGet',
        'last',
        last,
      );
      const localVarPath = `/nodes/{node_uid}/export-annotations`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (unit !== undefined) {
        localVarQueryParameter['unit'] = unit;
      }

      if (last !== undefined) {
        localVarQueryParameter['last'] = last;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Annotations For Batches
     * @param {number} nodeUid
     * @param {Array<number>} [batchUids]
     * @param {Array<number>} [userUids]
     * @param {string} [sourceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet: async (
      nodeUid: number,
      batchUids?: Array<number>,
      userUids?: Array<number>,
      sourceType?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/annotations-for-batches`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (batchUids) {
        localVarQueryParameter['batch_uids'] = batchUids;
      }

      if (userUids) {
        localVarQueryParameter['user_uids'] = userUids;
      }

      if (sourceType !== undefined) {
        localVarQueryParameter['source_type'] = sourceType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Annotations
     * @param {number} nodeUid
     * @param {number} [userUid]
     * @param {Array<number>} [userUids]
     * @param {Array<string>} [xUids]
     * @param {string} [label]
     * @param {number} [sourceUid]
     * @param {Array<number>} [batchUids]
     * @param {boolean} [includeBatchMetadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsNodesNodeUidAnnotationsGet: async (
      nodeUid: number,
      userUid?: number,
      userUids?: Array<number>,
      xUids?: Array<string>,
      label?: string,
      sourceUid?: number,
      batchUids?: Array<number>,
      includeBatchMetadata?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getAnnotationsNodesNodeUidAnnotationsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/annotations`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      if (userUids) {
        localVarQueryParameter['user_uids'] = userUids;
      }

      if (xUids) {
        localVarQueryParameter['x_uids'] = xUids;
      }

      if (label !== undefined) {
        localVarQueryParameter['label'] = label;
      }

      if (sourceUid !== undefined) {
        localVarQueryParameter['source_uid'] = sourceUid;
      }

      if (batchUids) {
        localVarQueryParameter['batch_uids'] = batchUids;
      }

      if (includeBatchMetadata !== undefined) {
        localVarQueryParameter['include_batch_metadata'] = includeBatchMetadata;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Annotations Overview
     * @param {number} nodeUid
     * @param {Array<number>} [userUids]
     * @param {boolean} [showAllAnnotations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet: async (
      nodeUid: number,
      userUids?: Array<number>,
      showAllAnnotations?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/annotations-overview`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userUids) {
        localVarQueryParameter['user_uids'] = userUids;
      }

      if (showAllAnnotations !== undefined) {
        localVarQueryParameter['show_all_annotations'] = showAllAnnotations;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Annotations Rate
     * @param {number} nodeUid
     * @param {string} unit
     * @param {number} last
     * @param {Array<number>} [userUids]
     * @param {boolean} [showAllAnnotations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsRateNodesNodeUidAnnotationsRateGet: async (
      nodeUid: number,
      unit: string,
      last: number,
      userUids?: Array<number>,
      showAllAnnotations?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getAnnotationsRateNodesNodeUidAnnotationsRateGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'unit' is not null or undefined
      assertParamExists(
        'getAnnotationsRateNodesNodeUidAnnotationsRateGet',
        'unit',
        unit,
      );
      // verify required parameter 'last' is not null or undefined
      assertParamExists(
        'getAnnotationsRateNodesNodeUidAnnotationsRateGet',
        'last',
        last,
      );
      const localVarPath = `/nodes/{node_uid}/annotations-rate`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (unit !== undefined) {
        localVarQueryParameter['unit'] = unit;
      }

      if (last !== undefined) {
        localVarQueryParameter['last'] = last;
      }

      if (userUids) {
        localVarQueryParameter['user_uids'] = userUids;
      }

      if (showAllAnnotations !== undefined) {
        localVarQueryParameter['show_all_annotations'] = showAllAnnotations;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Interannotator Agreement
     * @param {number} nodeUid
     * @param {number} [classValue]
     * @param {Array<number>} [batchUids]
     * @param {string} [metric]
     * @param {Array<number>} [userUids]
     * @param {boolean} [ignoreCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet: async (
      nodeUid: number,
      classValue?: number,
      batchUids?: Array<number>,
      metric?: string,
      userUids?: Array<number>,
      ignoreCache?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/annotator-agreement`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (classValue !== undefined) {
        localVarQueryParameter['class_value'] = classValue;
      }

      if (batchUids) {
        localVarQueryParameter['batch_uids'] = batchUids;
      }

      if (metric !== undefined) {
        localVarQueryParameter['metric'] = metric;
      }

      if (userUids) {
        localVarQueryParameter['user_uids'] = userUids;
      }

      if (ignoreCache !== undefined) {
        localVarQueryParameter['ignore_cache'] = ignoreCache;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Get Annotations
     * @param {number} nodeUid
     * @param {GetAnnotationMapParams} getAnnotationMapParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGetAnnotationsNodesNodeUidGetAnnotationMapPost: async (
      nodeUid: number,
      getAnnotationMapParams: GetAnnotationMapParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'postGetAnnotationsNodesNodeUidGetAnnotationMapPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'getAnnotationMapParams' is not null or undefined
      assertParamExists(
        'postGetAnnotationsNodesNodeUidGetAnnotationMapPost',
        'getAnnotationMapParams',
        getAnnotationMapParams,
      );
      const localVarPath = `/nodes/{node_uid}/get-annotation-map`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getAnnotationMapParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Score Annotations
     * @param {number} nodeUid
     * @param {ScoreAnnotationsParams} scoreAnnotationsParams
     * @param {boolean} [ignoreCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost: async (
      nodeUid: number,
      scoreAnnotationsParams: ScoreAnnotationsParams,
      ignoreCache?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'scoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'scoreAnnotationsParams' is not null or undefined
      assertParamExists(
        'scoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost',
        'scoreAnnotationsParams',
        scoreAnnotationsParams,
      );
      const localVarPath =
        `/nodes/{node_uid}/annotations/score-annotations`.replace(
          `{${'node_uid'}}`,
          encodeURIComponent(String(nodeUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (ignoreCache !== undefined) {
        localVarQueryParameter['ignore_cache'] = ignoreCache;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        scoreAnnotationsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Annotation
     * @param {number} nodeUid
     * @param {number} annotationUid
     * @param {UpdateAnnotationParams} updateAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut: async (
      nodeUid: number,
      annotationUid: number,
      updateAnnotationParams: UpdateAnnotationParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'annotationUid' is not null or undefined
      assertParamExists(
        'updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut',
        'annotationUid',
        annotationUid,
      );
      // verify required parameter 'updateAnnotationParams' is not null or undefined
      assertParamExists(
        'updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut',
        'updateAnnotationParams',
        updateAnnotationParams,
      );
      const localVarPath = `/nodes/{node_uid}/annotations/{annotation_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'annotation_uid'}}`,
          encodeURIComponent(String(annotationUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAnnotationParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AnnotationsApi - functional programming interface
 * @export
 */
export const AnnotationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AnnotationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Annotation
     * @param {number} nodeUid
     * @param {CreateAnnotationParams} createAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addAnnotationNodesNodeUidAnnotationPost(
      nodeUid: number,
      createAnnotationParams: CreateAnnotationParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateAnnotationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addAnnotationNodesNodeUidAnnotationPost(
          nodeUid,
          createAnnotationParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.addAnnotationNodesNodeUidAnnotationPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Add Annotations
     * @param {number} nodeUid
     * @param {ImportAnnotationsParams} importAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addAnnotationsNodesNodeUidAnnotationsPost(
      nodeUid: number,
      importAnnotationsParams: ImportAnnotationsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ImportAnnotationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addAnnotationsNodesNodeUidAnnotationsPost(
          nodeUid,
          importAnnotationsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.addAnnotationsNodesNodeUidAnnotationsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Aggregate Annotations
     * @param {number} nodeUid
     * @param {AggregateAnnotationsParams} aggregateAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async aggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost(
      nodeUid: number,
      aggregateAnnotationsParams: AggregateAnnotationsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ImportAnnotationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.aggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost(
          nodeUid,
          aggregateAnnotationsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.aggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Commit Annotations
     * @param {number} nodeUid
     * @param {CommitAnnotationsParams} commitAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commitAnnotationsNodesNodeUidCommitAnnotationsPost(
      nodeUid: number,
      commitAnnotationsParams: CommitAnnotationsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commitAnnotationsNodesNodeUidCommitAnnotationsPost(
          nodeUid,
          commitAnnotationsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.commitAnnotationsNodesNodeUidCommitAnnotationsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Annotation
     * @param {number} nodeUid
     * @param {number} annotationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete(
      nodeUid: number,
      annotationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete(
          nodeUid,
          annotationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.deleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Annotations
     * @param {number} nodeUid
     * @param {DeleteAnnotationsParams} deleteAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAnnotationsNodesNodeUidAnnotationsDelete(
      nodeUid: number,
      deleteAnnotationsParams: DeleteAnnotationsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAnnotationsNodesNodeUidAnnotationsDelete(
          nodeUid,
          deleteAnnotationsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.deleteAnnotationsNodesNodeUidAnnotationsDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Export Annotations
     * @param {number} nodeUid
     * @param {string} unit
     * @param {number} last
     * @param {Array<number>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAnnotationsNodesNodeUidExportAnnotationsGet(
      nodeUid: number,
      unit: string,
      last: number,
      requestBody?: Array<number>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAnnotationsNodesNodeUidExportAnnotationsGet(
          nodeUid,
          unit,
          last,
          requestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.exportAnnotationsNodesNodeUidExportAnnotationsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Annotations For Batches
     * @param {number} nodeUid
     * @param {Array<number>} [batchUids]
     * @param {Array<number>} [userUids]
     * @param {string} [sourceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet(
      nodeUid: number,
      batchUids?: Array<number>,
      userUids?: Array<number>,
      sourceType?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAnnotationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet(
          nodeUid,
          batchUids,
          userUids,
          sourceType,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.getAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Annotations
     * @param {number} nodeUid
     * @param {number} [userUid]
     * @param {Array<number>} [userUids]
     * @param {Array<string>} [xUids]
     * @param {string} [label]
     * @param {number} [sourceUid]
     * @param {Array<number>} [batchUids]
     * @param {boolean} [includeBatchMetadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnnotationsNodesNodeUidAnnotationsGet(
      nodeUid: number,
      userUid?: number,
      userUids?: Array<number>,
      xUids?: Array<string>,
      label?: string,
      sourceUid?: number,
      batchUids?: Array<number>,
      includeBatchMetadata?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAnnotationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAnnotationsNodesNodeUidAnnotationsGet(
          nodeUid,
          userUid,
          userUids,
          xUids,
          label,
          sourceUid,
          batchUids,
          includeBatchMetadata,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.getAnnotationsNodesNodeUidAnnotationsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Annotations Overview
     * @param {number} nodeUid
     * @param {Array<number>} [userUids]
     * @param {boolean} [showAllAnnotations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet(
      nodeUid: number,
      userUids?: Array<number>,
      showAllAnnotations?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAnnotationsOverview>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet(
          nodeUid,
          userUids,
          showAllAnnotations,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.getAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Annotations Rate
     * @param {number} nodeUid
     * @param {string} unit
     * @param {number} last
     * @param {Array<number>} [userUids]
     * @param {boolean} [showAllAnnotations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnnotationsRateNodesNodeUidAnnotationsRateGet(
      nodeUid: number,
      unit: string,
      last: number,
      userUids?: Array<number>,
      showAllAnnotations?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAnnotationsRate>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAnnotationsRateNodesNodeUidAnnotationsRateGet(
          nodeUid,
          unit,
          last,
          userUids,
          showAllAnnotations,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.getAnnotationsRateNodesNodeUidAnnotationsRateGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Interannotator Agreement
     * @param {number} nodeUid
     * @param {number} [classValue]
     * @param {Array<number>} [batchUids]
     * @param {string} [metric]
     * @param {Array<number>} [userUids]
     * @param {boolean} [ignoreCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet(
      nodeUid: number,
      classValue?: number,
      batchUids?: Array<number>,
      metric?: string,
      userUids?: Array<number>,
      ignoreCache?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InterAnnotatorAgreement>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet(
          nodeUid,
          classValue,
          batchUids,
          metric,
          userUids,
          ignoreCache,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.getInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Get Annotations
     * @param {number} nodeUid
     * @param {GetAnnotationMapParams} getAnnotationMapParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postGetAnnotationsNodesNodeUidGetAnnotationMapPost(
      nodeUid: number,
      getAnnotationMapParams: GetAnnotationMapParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAnnotationMapResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postGetAnnotationsNodesNodeUidGetAnnotationMapPost(
          nodeUid,
          getAnnotationMapParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.postGetAnnotationsNodesNodeUidGetAnnotationMapPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Score Annotations
     * @param {number} nodeUid
     * @param {ScoreAnnotationsParams} scoreAnnotationsParams
     * @param {boolean} [ignoreCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost(
      nodeUid: number,
      scoreAnnotationsParams: ScoreAnnotationsParams,
      ignoreCache?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ScoreAnnotationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.scoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost(
          nodeUid,
          scoreAnnotationsParams,
          ignoreCache,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.scoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Annotation
     * @param {number} nodeUid
     * @param {number} annotationUid
     * @param {UpdateAnnotationParams} updateAnnotationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut(
      nodeUid: number,
      annotationUid: number,
      updateAnnotationParams: UpdateAnnotationParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut(
          nodeUid,
          annotationUid,
          updateAnnotationParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'AnnotationsApi.updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AnnotationsApi - factory interface
 * @export
 */
export const AnnotationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AnnotationsApiFp(configuration);
  return {
    /**
     *
     * @summary Add Annotation
     * @param {AnnotationsApiAddAnnotationNodesNodeUidAnnotationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAnnotationNodesNodeUidAnnotationPost(
      requestParameters: AnnotationsApiAddAnnotationNodesNodeUidAnnotationPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateAnnotationResponse> {
      return localVarFp
        .addAnnotationNodesNodeUidAnnotationPost(
          requestParameters.nodeUid,
          requestParameters.createAnnotationParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Add Annotations
     * @param {AnnotationsApiAddAnnotationsNodesNodeUidAnnotationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAnnotationsNodesNodeUidAnnotationsPost(
      requestParameters: AnnotationsApiAddAnnotationsNodesNodeUidAnnotationsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ImportAnnotationsResponse> {
      return localVarFp
        .addAnnotationsNodesNodeUidAnnotationsPost(
          requestParameters.nodeUid,
          requestParameters.importAnnotationsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Aggregate Annotations
     * @param {AnnotationsApiAggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    aggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost(
      requestParameters: AnnotationsApiAggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ImportAnnotationsResponse> {
      return localVarFp
        .aggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost(
          requestParameters.nodeUid,
          requestParameters.aggregateAnnotationsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Commit Annotations
     * @param {AnnotationsApiCommitAnnotationsNodesNodeUidCommitAnnotationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitAnnotationsNodesNodeUidCommitAnnotationsPost(
      requestParameters: AnnotationsApiCommitAnnotationsNodesNodeUidCommitAnnotationsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .commitAnnotationsNodesNodeUidCommitAnnotationsPost(
          requestParameters.nodeUid,
          requestParameters.commitAnnotationsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Annotation
     * @param {AnnotationsApiDeleteAnnotationNodesNodeUidAnnotationsAnnotationUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete(
      requestParameters: AnnotationsApiDeleteAnnotationNodesNodeUidAnnotationsAnnotationUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete(
          requestParameters.nodeUid,
          requestParameters.annotationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Annotations
     * @param {AnnotationsApiDeleteAnnotationsNodesNodeUidAnnotationsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAnnotationsNodesNodeUidAnnotationsDelete(
      requestParameters: AnnotationsApiDeleteAnnotationsNodesNodeUidAnnotationsDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteAnnotationsNodesNodeUidAnnotationsDelete(
          requestParameters.nodeUid,
          requestParameters.deleteAnnotationsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Export Annotations
     * @param {AnnotationsApiExportAnnotationsNodesNodeUidExportAnnotationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAnnotationsNodesNodeUidExportAnnotationsGet(
      requestParameters: AnnotationsApiExportAnnotationsNodesNodeUidExportAnnotationsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .exportAnnotationsNodesNodeUidExportAnnotationsGet(
          requestParameters.nodeUid,
          requestParameters.unit,
          requestParameters.last,
          requestParameters.requestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Annotations For Batches
     * @param {AnnotationsApiGetAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet(
      requestParameters: AnnotationsApiGetAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetAnnotationsResponse> {
      return localVarFp
        .getAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet(
          requestParameters.nodeUid,
          requestParameters.batchUids,
          requestParameters.userUids,
          requestParameters.sourceType,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Annotations
     * @param {AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsNodesNodeUidAnnotationsGet(
      requestParameters: AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetAnnotationsResponse> {
      return localVarFp
        .getAnnotationsNodesNodeUidAnnotationsGet(
          requestParameters.nodeUid,
          requestParameters.userUid,
          requestParameters.userUids,
          requestParameters.xUids,
          requestParameters.label,
          requestParameters.sourceUid,
          requestParameters.batchUids,
          requestParameters.includeBatchMetadata,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Annotations Overview
     * @param {AnnotationsApiGetAnnotationsOverviewNodesNodeUidAnnotationsOverviewGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet(
      requestParameters: AnnotationsApiGetAnnotationsOverviewNodesNodeUidAnnotationsOverviewGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetAnnotationsOverview> {
      return localVarFp
        .getAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet(
          requestParameters.nodeUid,
          requestParameters.userUids,
          requestParameters.showAllAnnotations,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Annotations Rate
     * @param {AnnotationsApiGetAnnotationsRateNodesNodeUidAnnotationsRateGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotationsRateNodesNodeUidAnnotationsRateGet(
      requestParameters: AnnotationsApiGetAnnotationsRateNodesNodeUidAnnotationsRateGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetAnnotationsRate> {
      return localVarFp
        .getAnnotationsRateNodesNodeUidAnnotationsRateGet(
          requestParameters.nodeUid,
          requestParameters.unit,
          requestParameters.last,
          requestParameters.userUids,
          requestParameters.showAllAnnotations,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Interannotator Agreement
     * @param {AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet(
      requestParameters: AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<InterAnnotatorAgreement> {
      return localVarFp
        .getInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet(
          requestParameters.nodeUid,
          requestParameters.classValue,
          requestParameters.batchUids,
          requestParameters.metric,
          requestParameters.userUids,
          requestParameters.ignoreCache,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Post Get Annotations
     * @param {AnnotationsApiPostGetAnnotationsNodesNodeUidGetAnnotationMapPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postGetAnnotationsNodesNodeUidGetAnnotationMapPost(
      requestParameters: AnnotationsApiPostGetAnnotationsNodesNodeUidGetAnnotationMapPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetAnnotationMapResponse> {
      return localVarFp
        .postGetAnnotationsNodesNodeUidGetAnnotationMapPost(
          requestParameters.nodeUid,
          requestParameters.getAnnotationMapParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Score Annotations
     * @param {AnnotationsApiScoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost(
      requestParameters: AnnotationsApiScoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ScoreAnnotationsResponse> {
      return localVarFp
        .scoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost(
          requestParameters.nodeUid,
          requestParameters.scoreAnnotationsParams,
          requestParameters.ignoreCache,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Annotation
     * @param {AnnotationsApiUpdateAnnotationNodesNodeUidAnnotationsAnnotationUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut(
      requestParameters: AnnotationsApiUpdateAnnotationNodesNodeUidAnnotationsAnnotationUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut(
          requestParameters.nodeUid,
          requestParameters.annotationUid,
          requestParameters.updateAnnotationParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addAnnotationNodesNodeUidAnnotationPost operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiAddAnnotationNodesNodeUidAnnotationPostRequest
 */
export interface AnnotationsApiAddAnnotationNodesNodeUidAnnotationPostRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiAddAnnotationNodesNodeUidAnnotationPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {CreateAnnotationParams}
   * @memberof AnnotationsApiAddAnnotationNodesNodeUidAnnotationPost
   */
  readonly createAnnotationParams: CreateAnnotationParams;
}

/**
 * Request parameters for addAnnotationsNodesNodeUidAnnotationsPost operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiAddAnnotationsNodesNodeUidAnnotationsPostRequest
 */
export interface AnnotationsApiAddAnnotationsNodesNodeUidAnnotationsPostRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiAddAnnotationsNodesNodeUidAnnotationsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ImportAnnotationsParams}
   * @memberof AnnotationsApiAddAnnotationsNodesNodeUidAnnotationsPost
   */
  readonly importAnnotationsParams: ImportAnnotationsParams;
}

/**
 * Request parameters for aggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiAggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPostRequest
 */
export interface AnnotationsApiAggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPostRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiAggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {AggregateAnnotationsParams}
   * @memberof AnnotationsApiAggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost
   */
  readonly aggregateAnnotationsParams: AggregateAnnotationsParams;
}

/**
 * Request parameters for commitAnnotationsNodesNodeUidCommitAnnotationsPost operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiCommitAnnotationsNodesNodeUidCommitAnnotationsPostRequest
 */
export interface AnnotationsApiCommitAnnotationsNodesNodeUidCommitAnnotationsPostRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiCommitAnnotationsNodesNodeUidCommitAnnotationsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {CommitAnnotationsParams}
   * @memberof AnnotationsApiCommitAnnotationsNodesNodeUidCommitAnnotationsPost
   */
  readonly commitAnnotationsParams: CommitAnnotationsParams;
}

/**
 * Request parameters for deleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiDeleteAnnotationNodesNodeUidAnnotationsAnnotationUidDeleteRequest
 */
export interface AnnotationsApiDeleteAnnotationNodesNodeUidAnnotationsAnnotationUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiDeleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof AnnotationsApiDeleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete
   */
  readonly annotationUid: number;
}

/**
 * Request parameters for deleteAnnotationsNodesNodeUidAnnotationsDelete operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiDeleteAnnotationsNodesNodeUidAnnotationsDeleteRequest
 */
export interface AnnotationsApiDeleteAnnotationsNodesNodeUidAnnotationsDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiDeleteAnnotationsNodesNodeUidAnnotationsDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {DeleteAnnotationsParams}
   * @memberof AnnotationsApiDeleteAnnotationsNodesNodeUidAnnotationsDelete
   */
  readonly deleteAnnotationsParams: DeleteAnnotationsParams;
}

/**
 * Request parameters for exportAnnotationsNodesNodeUidExportAnnotationsGet operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiExportAnnotationsNodesNodeUidExportAnnotationsGetRequest
 */
export interface AnnotationsApiExportAnnotationsNodesNodeUidExportAnnotationsGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiExportAnnotationsNodesNodeUidExportAnnotationsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof AnnotationsApiExportAnnotationsNodesNodeUidExportAnnotationsGet
   */
  readonly unit: string;

  /**
   *
   * @type {number}
   * @memberof AnnotationsApiExportAnnotationsNodesNodeUidExportAnnotationsGet
   */
  readonly last: number;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationsApiExportAnnotationsNodesNodeUidExportAnnotationsGet
   */
  readonly requestBody?: Array<number>;
}

/**
 * Request parameters for getAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiGetAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGetRequest
 */
export interface AnnotationsApiGetAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiGetAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationsApiGetAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet
   */
  readonly batchUids?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationsApiGetAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet
   */
  readonly userUids?: Array<number>;

  /**
   *
   * @type {string}
   * @memberof AnnotationsApiGetAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet
   */
  readonly sourceType?: string;
}

/**
 * Request parameters for getAnnotationsNodesNodeUidAnnotationsGet operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGetRequest
 */
export interface AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGet
   */
  readonly userUid?: number;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGet
   */
  readonly userUids?: Array<number>;

  /**
   *
   * @type {Array<string>}
   * @memberof AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGet
   */
  readonly xUids?: Array<string>;

  /**
   *
   * @type {string}
   * @memberof AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGet
   */
  readonly label?: string;

  /**
   *
   * @type {number}
   * @memberof AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGet
   */
  readonly sourceUid?: number;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGet
   */
  readonly batchUids?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGet
   */
  readonly includeBatchMetadata?: boolean;
}

/**
 * Request parameters for getAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiGetAnnotationsOverviewNodesNodeUidAnnotationsOverviewGetRequest
 */
export interface AnnotationsApiGetAnnotationsOverviewNodesNodeUidAnnotationsOverviewGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiGetAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationsApiGetAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet
   */
  readonly userUids?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof AnnotationsApiGetAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet
   */
  readonly showAllAnnotations?: boolean;
}

/**
 * Request parameters for getAnnotationsRateNodesNodeUidAnnotationsRateGet operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiGetAnnotationsRateNodesNodeUidAnnotationsRateGetRequest
 */
export interface AnnotationsApiGetAnnotationsRateNodesNodeUidAnnotationsRateGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiGetAnnotationsRateNodesNodeUidAnnotationsRateGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof AnnotationsApiGetAnnotationsRateNodesNodeUidAnnotationsRateGet
   */
  readonly unit: string;

  /**
   *
   * @type {number}
   * @memberof AnnotationsApiGetAnnotationsRateNodesNodeUidAnnotationsRateGet
   */
  readonly last: number;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationsApiGetAnnotationsRateNodesNodeUidAnnotationsRateGet
   */
  readonly userUids?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof AnnotationsApiGetAnnotationsRateNodesNodeUidAnnotationsRateGet
   */
  readonly showAllAnnotations?: boolean;
}

/**
 * Request parameters for getInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGetRequest
 */
export interface AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGetRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet
   */
  readonly classValue?: number;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet
   */
  readonly batchUids?: Array<number>;

  /**
   *
   * @type {string}
   * @memberof AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet
   */
  readonly metric?: string;

  /**
   *
   * @type {Array<number>}
   * @memberof AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet
   */
  readonly userUids?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet
   */
  readonly ignoreCache?: boolean;
}

/**
 * Request parameters for postGetAnnotationsNodesNodeUidGetAnnotationMapPost operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiPostGetAnnotationsNodesNodeUidGetAnnotationMapPostRequest
 */
export interface AnnotationsApiPostGetAnnotationsNodesNodeUidGetAnnotationMapPostRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiPostGetAnnotationsNodesNodeUidGetAnnotationMapPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {GetAnnotationMapParams}
   * @memberof AnnotationsApiPostGetAnnotationsNodesNodeUidGetAnnotationMapPost
   */
  readonly getAnnotationMapParams: GetAnnotationMapParams;
}

/**
 * Request parameters for scoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiScoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPostRequest
 */
export interface AnnotationsApiScoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPostRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiScoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ScoreAnnotationsParams}
   * @memberof AnnotationsApiScoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost
   */
  readonly scoreAnnotationsParams: ScoreAnnotationsParams;

  /**
   *
   * @type {boolean}
   * @memberof AnnotationsApiScoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost
   */
  readonly ignoreCache?: boolean;
}

/**
 * Request parameters for updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut operation in AnnotationsApi.
 * @export
 * @interface AnnotationsApiUpdateAnnotationNodesNodeUidAnnotationsAnnotationUidPutRequest
 */
export interface AnnotationsApiUpdateAnnotationNodesNodeUidAnnotationsAnnotationUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof AnnotationsApiUpdateAnnotationNodesNodeUidAnnotationsAnnotationUidPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof AnnotationsApiUpdateAnnotationNodesNodeUidAnnotationsAnnotationUidPut
   */
  readonly annotationUid: number;

  /**
   *
   * @type {UpdateAnnotationParams}
   * @memberof AnnotationsApiUpdateAnnotationNodesNodeUidAnnotationsAnnotationUidPut
   */
  readonly updateAnnotationParams: UpdateAnnotationParams;
}

/**
 * AnnotationsApi - object-oriented interface
 * @export
 * @class AnnotationsApi
 * @extends {BaseAPI}
 */
export class AnnotationsApi extends BaseAPI {
  /**
   *
   * @summary Add Annotation
   * @param {AnnotationsApiAddAnnotationNodesNodeUidAnnotationPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public addAnnotationNodesNodeUidAnnotationPost(
    requestParameters: AnnotationsApiAddAnnotationNodesNodeUidAnnotationPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .addAnnotationNodesNodeUidAnnotationPost(
        requestParameters.nodeUid,
        requestParameters.createAnnotationParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add Annotations
   * @param {AnnotationsApiAddAnnotationsNodesNodeUidAnnotationsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public addAnnotationsNodesNodeUidAnnotationsPost(
    requestParameters: AnnotationsApiAddAnnotationsNodesNodeUidAnnotationsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .addAnnotationsNodesNodeUidAnnotationsPost(
        requestParameters.nodeUid,
        requestParameters.importAnnotationsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Aggregate Annotations
   * @param {AnnotationsApiAggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public aggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost(
    requestParameters: AnnotationsApiAggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .aggregateAnnotationsNodesNodeUidAnnotationsAggregateAnnotationsPost(
        requestParameters.nodeUid,
        requestParameters.aggregateAnnotationsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Commit Annotations
   * @param {AnnotationsApiCommitAnnotationsNodesNodeUidCommitAnnotationsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public commitAnnotationsNodesNodeUidCommitAnnotationsPost(
    requestParameters: AnnotationsApiCommitAnnotationsNodesNodeUidCommitAnnotationsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .commitAnnotationsNodesNodeUidCommitAnnotationsPost(
        requestParameters.nodeUid,
        requestParameters.commitAnnotationsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Annotation
   * @param {AnnotationsApiDeleteAnnotationNodesNodeUidAnnotationsAnnotationUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public deleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete(
    requestParameters: AnnotationsApiDeleteAnnotationNodesNodeUidAnnotationsAnnotationUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .deleteAnnotationNodesNodeUidAnnotationsAnnotationUidDelete(
        requestParameters.nodeUid,
        requestParameters.annotationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Annotations
   * @param {AnnotationsApiDeleteAnnotationsNodesNodeUidAnnotationsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public deleteAnnotationsNodesNodeUidAnnotationsDelete(
    requestParameters: AnnotationsApiDeleteAnnotationsNodesNodeUidAnnotationsDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .deleteAnnotationsNodesNodeUidAnnotationsDelete(
        requestParameters.nodeUid,
        requestParameters.deleteAnnotationsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export Annotations
   * @param {AnnotationsApiExportAnnotationsNodesNodeUidExportAnnotationsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public exportAnnotationsNodesNodeUidExportAnnotationsGet(
    requestParameters: AnnotationsApiExportAnnotationsNodesNodeUidExportAnnotationsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .exportAnnotationsNodesNodeUidExportAnnotationsGet(
        requestParameters.nodeUid,
        requestParameters.unit,
        requestParameters.last,
        requestParameters.requestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Annotations For Batches
   * @param {AnnotationsApiGetAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public getAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet(
    requestParameters: AnnotationsApiGetAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .getAnnotationsForBatchesNodesNodeUidAnnotationsForBatchesGet(
        requestParameters.nodeUid,
        requestParameters.batchUids,
        requestParameters.userUids,
        requestParameters.sourceType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Annotations
   * @param {AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public getAnnotationsNodesNodeUidAnnotationsGet(
    requestParameters: AnnotationsApiGetAnnotationsNodesNodeUidAnnotationsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .getAnnotationsNodesNodeUidAnnotationsGet(
        requestParameters.nodeUid,
        requestParameters.userUid,
        requestParameters.userUids,
        requestParameters.xUids,
        requestParameters.label,
        requestParameters.sourceUid,
        requestParameters.batchUids,
        requestParameters.includeBatchMetadata,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Annotations Overview
   * @param {AnnotationsApiGetAnnotationsOverviewNodesNodeUidAnnotationsOverviewGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public getAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet(
    requestParameters: AnnotationsApiGetAnnotationsOverviewNodesNodeUidAnnotationsOverviewGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .getAnnotationsOverviewNodesNodeUidAnnotationsOverviewGet(
        requestParameters.nodeUid,
        requestParameters.userUids,
        requestParameters.showAllAnnotations,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Annotations Rate
   * @param {AnnotationsApiGetAnnotationsRateNodesNodeUidAnnotationsRateGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public getAnnotationsRateNodesNodeUidAnnotationsRateGet(
    requestParameters: AnnotationsApiGetAnnotationsRateNodesNodeUidAnnotationsRateGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .getAnnotationsRateNodesNodeUidAnnotationsRateGet(
        requestParameters.nodeUid,
        requestParameters.unit,
        requestParameters.last,
        requestParameters.userUids,
        requestParameters.showAllAnnotations,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Interannotator Agreement
   * @param {AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public getInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet(
    requestParameters: AnnotationsApiGetInterannotatorAgreementNodesNodeUidAnnotatorAgreementGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .getInterannotatorAgreementNodesNodeUidAnnotatorAgreementGet(
        requestParameters.nodeUid,
        requestParameters.classValue,
        requestParameters.batchUids,
        requestParameters.metric,
        requestParameters.userUids,
        requestParameters.ignoreCache,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Get Annotations
   * @param {AnnotationsApiPostGetAnnotationsNodesNodeUidGetAnnotationMapPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public postGetAnnotationsNodesNodeUidGetAnnotationMapPost(
    requestParameters: AnnotationsApiPostGetAnnotationsNodesNodeUidGetAnnotationMapPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .postGetAnnotationsNodesNodeUidGetAnnotationMapPost(
        requestParameters.nodeUid,
        requestParameters.getAnnotationMapParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Score Annotations
   * @param {AnnotationsApiScoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public scoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost(
    requestParameters: AnnotationsApiScoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .scoreAnnotationsNodesNodeUidAnnotationsScoreAnnotationsPost(
        requestParameters.nodeUid,
        requestParameters.scoreAnnotationsParams,
        requestParameters.ignoreCache,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Annotation
   * @param {AnnotationsApiUpdateAnnotationNodesNodeUidAnnotationsAnnotationUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnnotationsApi
   */
  public updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut(
    requestParameters: AnnotationsApiUpdateAnnotationNodesNodeUidAnnotationsAnnotationUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return AnnotationsApiFp(this.configuration)
      .updateAnnotationNodesNodeUidAnnotationsAnnotationUidPut(
        requestParameters.nodeUid,
        requestParameters.annotationUid,
        requestParameters.updateAnnotationParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ApiKeysApi - axios parameter creator
 * @export
 */
export const ApiKeysApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Api Key
     * @param {number} userUid
     * @param {CreateAPIKeyParams} createAPIKeyParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApiKeyUsersUserUidApiKeysPost: async (
      userUid: number,
      createAPIKeyParams: CreateAPIKeyParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'createApiKeyUsersUserUidApiKeysPost',
        'userUid',
        userUid,
      );
      // verify required parameter 'createAPIKeyParams' is not null or undefined
      assertParamExists(
        'createApiKeyUsersUserUidApiKeysPost',
        'createAPIKeyParams',
        createAPIKeyParams,
      );
      const localVarPath = `/users/{user_uid}/api-keys`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAPIKeyParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Api Key
     * @param {number} userUid
     * @param {number} apiKeyUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApiKeyUsersUserUidApiKeysApiKeyUidDelete: async (
      userUid: number,
      apiKeyUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'deleteApiKeyUsersUserUidApiKeysApiKeyUidDelete',
        'userUid',
        userUid,
      );
      // verify required parameter 'apiKeyUid' is not null or undefined
      assertParamExists(
        'deleteApiKeyUsersUserUidApiKeysApiKeyUidDelete',
        'apiKeyUid',
        apiKeyUid,
      );
      const localVarPath = `/users/{user_uid}/api-keys/{api_key_uid}`
        .replace(`{${'user_uid'}}`, encodeURIComponent(String(userUid)))
        .replace(`{${'api_key_uid'}}`, encodeURIComponent(String(apiKeyUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Endpoint Validate Api Key
     * @param {ValidateAPIKeyParams} validateAPIKeyParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endpointValidateApiKeyValidateApiKeyPost: async (
      validateAPIKeyParams: ValidateAPIKeyParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'validateAPIKeyParams' is not null or undefined
      assertParamExists(
        'endpointValidateApiKeyValidateApiKeyPost',
        'validateAPIKeyParams',
        validateAPIKeyParams,
      );
      const localVarPath = `/validate-api-key`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        validateAPIKeyParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Api Key
     * @param {number} userUid
     * @param {number} apiKeyUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiKeyUsersUserUidApiKeysApiKeyUidGet: async (
      userUid: number,
      apiKeyUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'getApiKeyUsersUserUidApiKeysApiKeyUidGet',
        'userUid',
        userUid,
      );
      // verify required parameter 'apiKeyUid' is not null or undefined
      assertParamExists(
        'getApiKeyUsersUserUidApiKeysApiKeyUidGet',
        'apiKeyUid',
        apiKeyUid,
      );
      const localVarPath = `/users/{user_uid}/api-keys/{api_key_uid}`
        .replace(`{${'user_uid'}}`, encodeURIComponent(String(userUid)))
        .replace(`{${'api_key_uid'}}`, encodeURIComponent(String(apiKeyUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Api Keys
     * @param {number} userUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listApiKeysUsersUserUidApiKeysGet: async (
      userUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'listApiKeysUsersUserUidApiKeysGet',
        'userUid',
        userUid,
      );
      const localVarPath = `/users/{user_uid}/api-keys`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApiKeysApi - functional programming interface
 * @export
 */
export const ApiKeysApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ApiKeysApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Api Key
     * @param {number} userUid
     * @param {CreateAPIKeyParams} createAPIKeyParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createApiKeyUsersUserUidApiKeysPost(
      userUid: number,
      createAPIKeyParams: CreateAPIKeyParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIKey>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createApiKeyUsersUserUidApiKeysPost(
          userUid,
          createAPIKeyParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ApiKeysApi.createApiKeyUsersUserUidApiKeysPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Api Key
     * @param {number} userUid
     * @param {number} apiKeyUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteApiKeyUsersUserUidApiKeysApiKeyUidDelete(
      userUid: number,
      apiKeyUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteApiKeyUsersUserUidApiKeysApiKeyUidDelete(
          userUid,
          apiKeyUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApiKeysApi.deleteApiKeyUsersUserUidApiKeysApiKeyUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Endpoint Validate Api Key
     * @param {ValidateAPIKeyParams} validateAPIKeyParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async endpointValidateApiKeyValidateApiKeyPost(
      validateAPIKeyParams: ValidateAPIKeyParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenPair>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.endpointValidateApiKeyValidateApiKeyPost(
          validateAPIKeyParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApiKeysApi.endpointValidateApiKeyValidateApiKeyPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Api Key
     * @param {number} userUid
     * @param {number} apiKeyUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApiKeyUsersUserUidApiKeysApiKeyUidGet(
      userUid: number,
      apiKeyUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIKeyLimited>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getApiKeyUsersUserUidApiKeysApiKeyUidGet(
          userUid,
          apiKeyUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApiKeysApi.getApiKeyUsersUserUidApiKeysApiKeyUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Api Keys
     * @param {number} userUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listApiKeysUsersUserUidApiKeysGet(
      userUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<APIKeyLimited>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listApiKeysUsersUserUidApiKeysGet(
          userUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ApiKeysApi.listApiKeysUsersUserUidApiKeysGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ApiKeysApi - factory interface
 * @export
 */
export const ApiKeysApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ApiKeysApiFp(configuration);
  return {
    /**
     *
     * @summary Create Api Key
     * @param {ApiKeysApiCreateApiKeyUsersUserUidApiKeysPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApiKeyUsersUserUidApiKeysPost(
      requestParameters: ApiKeysApiCreateApiKeyUsersUserUidApiKeysPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<APIKey> {
      return localVarFp
        .createApiKeyUsersUserUidApiKeysPost(
          requestParameters.userUid,
          requestParameters.createAPIKeyParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Api Key
     * @param {ApiKeysApiDeleteApiKeyUsersUserUidApiKeysApiKeyUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApiKeyUsersUserUidApiKeysApiKeyUidDelete(
      requestParameters: ApiKeysApiDeleteApiKeyUsersUserUidApiKeysApiKeyUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteApiKeyUsersUserUidApiKeysApiKeyUidDelete(
          requestParameters.userUid,
          requestParameters.apiKeyUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Endpoint Validate Api Key
     * @param {ApiKeysApiEndpointValidateApiKeyValidateApiKeyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endpointValidateApiKeyValidateApiKeyPost(
      requestParameters: ApiKeysApiEndpointValidateApiKeyValidateApiKeyPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TokenPair> {
      return localVarFp
        .endpointValidateApiKeyValidateApiKeyPost(
          requestParameters.validateAPIKeyParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Api Key
     * @param {ApiKeysApiGetApiKeyUsersUserUidApiKeysApiKeyUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiKeyUsersUserUidApiKeysApiKeyUidGet(
      requestParameters: ApiKeysApiGetApiKeyUsersUserUidApiKeysApiKeyUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<APIKeyLimited> {
      return localVarFp
        .getApiKeyUsersUserUidApiKeysApiKeyUidGet(
          requestParameters.userUid,
          requestParameters.apiKeyUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Api Keys
     * @param {ApiKeysApiListApiKeysUsersUserUidApiKeysGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listApiKeysUsersUserUidApiKeysGet(
      requestParameters: ApiKeysApiListApiKeysUsersUserUidApiKeysGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<APIKeyLimited>> {
      return localVarFp
        .listApiKeysUsersUserUidApiKeysGet(requestParameters.userUid, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createApiKeyUsersUserUidApiKeysPost operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiCreateApiKeyUsersUserUidApiKeysPostRequest
 */
export interface ApiKeysApiCreateApiKeyUsersUserUidApiKeysPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApiKeysApiCreateApiKeyUsersUserUidApiKeysPost
   */
  readonly userUid: number;

  /**
   *
   * @type {CreateAPIKeyParams}
   * @memberof ApiKeysApiCreateApiKeyUsersUserUidApiKeysPost
   */
  readonly createAPIKeyParams: CreateAPIKeyParams;
}

/**
 * Request parameters for deleteApiKeyUsersUserUidApiKeysApiKeyUidDelete operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiDeleteApiKeyUsersUserUidApiKeysApiKeyUidDeleteRequest
 */
export interface ApiKeysApiDeleteApiKeyUsersUserUidApiKeysApiKeyUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof ApiKeysApiDeleteApiKeyUsersUserUidApiKeysApiKeyUidDelete
   */
  readonly userUid: number;

  /**
   *
   * @type {number}
   * @memberof ApiKeysApiDeleteApiKeyUsersUserUidApiKeysApiKeyUidDelete
   */
  readonly apiKeyUid: number;
}

/**
 * Request parameters for endpointValidateApiKeyValidateApiKeyPost operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiEndpointValidateApiKeyValidateApiKeyPostRequest
 */
export interface ApiKeysApiEndpointValidateApiKeyValidateApiKeyPostRequest {
  /**
   *
   * @type {ValidateAPIKeyParams}
   * @memberof ApiKeysApiEndpointValidateApiKeyValidateApiKeyPost
   */
  readonly validateAPIKeyParams: ValidateAPIKeyParams;
}

/**
 * Request parameters for getApiKeyUsersUserUidApiKeysApiKeyUidGet operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiGetApiKeyUsersUserUidApiKeysApiKeyUidGetRequest
 */
export interface ApiKeysApiGetApiKeyUsersUserUidApiKeysApiKeyUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApiKeysApiGetApiKeyUsersUserUidApiKeysApiKeyUidGet
   */
  readonly userUid: number;

  /**
   *
   * @type {number}
   * @memberof ApiKeysApiGetApiKeyUsersUserUidApiKeysApiKeyUidGet
   */
  readonly apiKeyUid: number;
}

/**
 * Request parameters for listApiKeysUsersUserUidApiKeysGet operation in ApiKeysApi.
 * @export
 * @interface ApiKeysApiListApiKeysUsersUserUidApiKeysGetRequest
 */
export interface ApiKeysApiListApiKeysUsersUserUidApiKeysGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApiKeysApiListApiKeysUsersUserUidApiKeysGet
   */
  readonly userUid: number;
}

/**
 * ApiKeysApi - object-oriented interface
 * @export
 * @class ApiKeysApi
 * @extends {BaseAPI}
 */
export class ApiKeysApi extends BaseAPI {
  /**
   *
   * @summary Create Api Key
   * @param {ApiKeysApiCreateApiKeyUsersUserUidApiKeysPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiKeysApi
   */
  public createApiKeyUsersUserUidApiKeysPost(
    requestParameters: ApiKeysApiCreateApiKeyUsersUserUidApiKeysPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApiKeysApiFp(this.configuration)
      .createApiKeyUsersUserUidApiKeysPost(
        requestParameters.userUid,
        requestParameters.createAPIKeyParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Api Key
   * @param {ApiKeysApiDeleteApiKeyUsersUserUidApiKeysApiKeyUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiKeysApi
   */
  public deleteApiKeyUsersUserUidApiKeysApiKeyUidDelete(
    requestParameters: ApiKeysApiDeleteApiKeyUsersUserUidApiKeysApiKeyUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApiKeysApiFp(this.configuration)
      .deleteApiKeyUsersUserUidApiKeysApiKeyUidDelete(
        requestParameters.userUid,
        requestParameters.apiKeyUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Endpoint Validate Api Key
   * @param {ApiKeysApiEndpointValidateApiKeyValidateApiKeyPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiKeysApi
   */
  public endpointValidateApiKeyValidateApiKeyPost(
    requestParameters: ApiKeysApiEndpointValidateApiKeyValidateApiKeyPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApiKeysApiFp(this.configuration)
      .endpointValidateApiKeyValidateApiKeyPost(
        requestParameters.validateAPIKeyParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Api Key
   * @param {ApiKeysApiGetApiKeyUsersUserUidApiKeysApiKeyUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiKeysApi
   */
  public getApiKeyUsersUserUidApiKeysApiKeyUidGet(
    requestParameters: ApiKeysApiGetApiKeyUsersUserUidApiKeysApiKeyUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApiKeysApiFp(this.configuration)
      .getApiKeyUsersUserUidApiKeysApiKeyUidGet(
        requestParameters.userUid,
        requestParameters.apiKeyUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Api Keys
   * @param {ApiKeysApiListApiKeysUsersUserUidApiKeysGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApiKeysApi
   */
  public listApiKeysUsersUserUidApiKeysGet(
    requestParameters: ApiKeysApiListApiKeysUsersUserUidApiKeysGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApiKeysApiFp(this.configuration)
      .listApiKeysUsersUserUidApiKeysGet(requestParameters.userUid, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ApplicationTemplatesApi - axios parameter creator
 * @export
 */
export const ApplicationTemplatesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Fetch Application Template
     * @param {string} clsName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchApplicationTemplateApplicationTemplatesClsNameGet: async (
      clsName: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'clsName' is not null or undefined
      assertParamExists(
        'fetchApplicationTemplateApplicationTemplatesClsNameGet',
        'clsName',
        clsName,
      );
      const localVarPath = `/application-templates/{cls_name}`.replace(
        `{${'cls_name'}}`,
        encodeURIComponent(String(clsName)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Application Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchApplicationTemplatesApplicationTemplatesGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/application-templates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApplicationTemplatesApi - functional programming interface
 * @export
 */
export const ApplicationTemplatesApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ApplicationTemplatesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Fetch Application Template
     * @param {string} clsName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchApplicationTemplateApplicationTemplatesClsNameGet(
      clsName: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationTemplate>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchApplicationTemplateApplicationTemplatesClsNameGet(
          clsName,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationTemplatesApi.fetchApplicationTemplateApplicationTemplatesClsNameGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Application Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchApplicationTemplatesApplicationTemplatesGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ApplicationTemplateBase>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchApplicationTemplatesApplicationTemplatesGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationTemplatesApi.fetchApplicationTemplatesApplicationTemplatesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ApplicationTemplatesApi - factory interface
 * @export
 */
export const ApplicationTemplatesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ApplicationTemplatesApiFp(configuration);
  return {
    /**
     *
     * @summary Fetch Application Template
     * @param {ApplicationTemplatesApiFetchApplicationTemplateApplicationTemplatesClsNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchApplicationTemplateApplicationTemplatesClsNameGet(
      requestParameters: ApplicationTemplatesApiFetchApplicationTemplateApplicationTemplatesClsNameGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ApplicationTemplate> {
      return localVarFp
        .fetchApplicationTemplateApplicationTemplatesClsNameGet(
          requestParameters.clsName,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Application Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchApplicationTemplatesApplicationTemplatesGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ApplicationTemplateBase>> {
      return localVarFp
        .fetchApplicationTemplatesApplicationTemplatesGet(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for fetchApplicationTemplateApplicationTemplatesClsNameGet operation in ApplicationTemplatesApi.
 * @export
 * @interface ApplicationTemplatesApiFetchApplicationTemplateApplicationTemplatesClsNameGetRequest
 */
export interface ApplicationTemplatesApiFetchApplicationTemplateApplicationTemplatesClsNameGetRequest {
  /**
   *
   * @type {string}
   * @memberof ApplicationTemplatesApiFetchApplicationTemplateApplicationTemplatesClsNameGet
   */
  readonly clsName: string;
}

/**
 * ApplicationTemplatesApi - object-oriented interface
 * @export
 * @class ApplicationTemplatesApi
 * @extends {BaseAPI}
 */
export class ApplicationTemplatesApi extends BaseAPI {
  /**
   *
   * @summary Fetch Application Template
   * @param {ApplicationTemplatesApiFetchApplicationTemplateApplicationTemplatesClsNameGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationTemplatesApi
   */
  public fetchApplicationTemplateApplicationTemplatesClsNameGet(
    requestParameters: ApplicationTemplatesApiFetchApplicationTemplateApplicationTemplatesClsNameGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationTemplatesApiFp(this.configuration)
      .fetchApplicationTemplateApplicationTemplatesClsNameGet(
        requestParameters.clsName,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Application Templates
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationTemplatesApi
   */
  public fetchApplicationTemplatesApplicationTemplatesGet(
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationTemplatesApiFp(this.configuration)
      .fetchApplicationTemplatesApplicationTemplatesGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ApplicationsApi - axios parameter creator
 * @export
 */
export const ApplicationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * If there is a PageSplitter in the app DAG, updates it with the new config. Otherwise, create a new node and commit the PageSplitter to it.
     * @summary Add Or Modify Page Splitter
     * @param {number} applicationUid
     * @param {CommittedOperatorPayload} committedOperatorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOrModifyPageSplitterApplicationApplicationUidPageSplitterPost: async (
      applicationUid: number,
      committedOperatorPayload: CommittedOperatorPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'addOrModifyPageSplitterApplicationApplicationUidPageSplitterPost',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'committedOperatorPayload' is not null or undefined
      assertParamExists(
        'addOrModifyPageSplitterApplicationApplicationUidPageSplitterPost',
        'committedOperatorPayload',
        committedOperatorPayload,
      );
      const localVarPath =
        `/application/{application_uid}/page-splitter`.replace(
          `{${'application_uid'}}`,
          encodeURIComponent(String(applicationUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        committedOperatorPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Archive App Version
     * @param {number} applicationUid
     * @param {number} appVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost:
      async (
        applicationUid: number,
        appVersionUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'applicationUid' is not null or undefined
        assertParamExists(
          'archiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost',
          'applicationUid',
          applicationUid,
        );
        // verify required parameter 'appVersionUid' is not null or undefined
        assertParamExists(
          'archiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost',
          'appVersionUid',
          appVersionUid,
        );
        const localVarPath =
          `/applications/{application_uid}/app-versions/{app_version_uid}/archive`
            .replace(
              `{${'application_uid'}}`,
              encodeURIComponent(String(applicationUid)),
            )
            .replace(
              `{${'app_version_uid'}}`,
              encodeURIComponent(String(appVersionUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Archive an application. Archived applications consume a fraction of their original AU usage but cannot be modified.
     * @summary Archive Application
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveApplicationApplicationApplicationUidArchivePut: async (
      applicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'archiveApplicationApplicationApplicationUidArchivePut',
        'applicationUid',
        applicationUid,
      );
      const localVarPath = `/application/{application_uid}/archive`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new dag version for the application.
     * @summary Create App Version Route
     * @param {number} applicationUid
     * @param {CreateAppVersionParams} createAppVersionParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAppVersionRouteApplicationsApplicationUidAppVersionsPost: async (
      applicationUid: number,
      createAppVersionParams: CreateAppVersionParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'createAppVersionRouteApplicationsApplicationUidAppVersionsPost',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'createAppVersionParams' is not null or undefined
      assertParamExists(
        'createAppVersionRouteApplicationsApplicationUidAppVersionsPost',
        'createAppVersionParams',
        createAppVersionParams,
      );
      const localVarPath =
        `/applications/{application_uid}/app-versions`.replace(
          `{${'application_uid'}}`,
          encodeURIComponent(String(applicationUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAppVersionParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Application
     * @param {CreateApplicationPayload} createApplicationPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApplicationApplicationsPost: async (
      createApplicationPayload: CreateApplicationPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createApplicationPayload' is not null or undefined
      assertParamExists(
        'createApplicationApplicationsPost',
        'createApplicationPayload',
        createApplicationPayload,
      );
      const localVarPath = `/applications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createApplicationPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create New Application With Resplit Datasources
     * @param {number} applicationUid
     * @param {ResplitDataSourcesRequest} resplitDataSourcesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost:
      async (
        applicationUid: number,
        resplitDataSourcesRequest: ResplitDataSourcesRequest,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'applicationUid' is not null or undefined
        assertParamExists(
          'createNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost',
          'applicationUid',
          applicationUid,
        );
        // verify required parameter 'resplitDataSourcesRequest' is not null or undefined
        assertParamExists(
          'createNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost',
          'resplitDataSourcesRequest',
          resplitDataSourcesRequest,
        );
        const localVarPath =
          `/applications/{application_uid}/resplit-data-sources`.replace(
            `{${'application_uid'}}`,
            encodeURIComponent(String(applicationUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          resplitDataSourcesRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Delete application by application_uid.
     * @summary Delete Application
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApplicationApplicationsApplicationUidDelete: async (
      applicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'deleteApplicationApplicationsApplicationUidDelete',
        'applicationUid',
        applicationUid,
      );
      const localVarPath = `/applications/{application_uid}`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Exclude an app from AU calculations. Temporary API that will be used to ignore duplicate apps until we have full featured app versioning in the platform
     * @summary Exclude In Au Usage
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excludeInAuUsageApplicationApplicationUidAuUsageExcludePut: async (
      applicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'excludeInAuUsageApplicationApplicationUidAuUsageExcludePut',
        'applicationUid',
        applicationUid,
      );
      const localVarPath =
        `/application/{application_uid}/au-usage/exclude`.replace(
          `{${'application_uid'}}`,
          encodeURIComponent(String(applicationUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Execute Graph On Data
     * @param {number} applicationUid
     * @param {ExecuteGraphOnDataRequest} executeGraphOnDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeGraphOnDataApplicationsApplicationUidExecuteGraphPost: async (
      applicationUid: number,
      executeGraphOnDataRequest: ExecuteGraphOnDataRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'executeGraphOnDataApplicationsApplicationUidExecuteGraphPost',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'executeGraphOnDataRequest' is not null or undefined
      assertParamExists(
        'executeGraphOnDataApplicationsApplicationUidExecuteGraphPost',
        'executeGraphOnDataRequest',
        executeGraphOnDataRequest,
      );
      const localVarPath =
        `/applications/{application_uid}/execute-graph`.replace(
          `{${'application_uid'}}`,
          encodeURIComponent(String(applicationUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        executeGraphOnDataRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch an application\'s AU usage information
     * @summary Get App Au Usage
     * @param {number} applicationUid
     * @param {boolean} [showBreakdown]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppAuUsageApplicationApplicationUidAuUsageGet: async (
      applicationUid: number,
      showBreakdown?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'getAppAuUsageApplicationApplicationUidAuUsageGet',
        'applicationUid',
        applicationUid,
      );
      const localVarPath = `/application/{application_uid}/au-usage`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (showBreakdown !== undefined) {
        localVarQueryParameter['show_breakdown'] = showBreakdown;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Given the app progress so far, returns the template id that uniquely identifies the template or raises an error if not template is found or it isn\'t unique
     * @summary Get App Template Id
     * @param {AppModelSetupProgressPayload} appModelSetupProgressPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppTemplateIdApplicationTemplateIdPost: async (
      appModelSetupProgressPayload: AppModelSetupProgressPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appModelSetupProgressPayload' is not null or undefined
      assertParamExists(
        'getAppTemplateIdApplicationTemplateIdPost',
        'appModelSetupProgressPayload',
        appModelSetupProgressPayload,
      );
      const localVarPath = `/application/template-id`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        appModelSetupProgressPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch application details (incl. dag) for application_uid.
     * @summary Get Application
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationApplicationsApplicationUidGet: async (
      applicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'getApplicationApplicationsApplicationUidGet',
        'applicationUid',
        applicationUid,
      );
      const localVarPath = `/applications/{application_uid}`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch all application metadata (excluding possibly large op node dag).
     * @summary Get Application Count
     * @param {number} [datasetUid]
     * @param {number} [createdByUserUid]
     * @param {string} [applicationName]
     * @param {string} [queryApplicationName]
     * @param {boolean} [includePublic]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationCountApplicationCountGet: async (
      datasetUid?: number,
      createdByUserUid?: number,
      applicationName?: string,
      queryApplicationName?: string,
      includePublic?: boolean,
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/application-count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasetUid !== undefined) {
        localVarQueryParameter['dataset_uid'] = datasetUid;
      }

      if (createdByUserUid !== undefined) {
        localVarQueryParameter['created_by_user_uid'] = createdByUserUid;
      }

      if (applicationName !== undefined) {
        localVarQueryParameter['application_name'] = applicationName;
      }

      if (queryApplicationName !== undefined) {
        localVarQueryParameter['query_application_name'] = queryApplicationName;
      }

      if (includePublic !== undefined) {
        localVarQueryParameter['include_public'] = includePublic;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Application Required Input Schema
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGet:
      async (
        applicationUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'applicationUid' is not null or undefined
        assertParamExists(
          'getApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGet',
          'applicationUid',
          applicationUid,
        );
        const localVarPath =
          `/applications/{application_uid}/required-input-schema`.replace(
            `{${'application_uid'}}`,
            encodeURIComponent(String(applicationUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Fetch all application metadata (excluding possibly large op node dag).
     * @summary Get Applications
     * @param {number} [datasetUid]
     * @param {number} [createdByUserUid]
     * @param {string} [applicationName]
     * @param {string} [queryApplicationName]
     * @param {boolean} [includePublic]
     * @param {number} [pageSize]
     * @param {number} [pageNum]
     * @param {number} [workspaceUid]
     * @param {SortableApplicationColumns} [sortColumn]
     * @param {SortOrder} [sortDirection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationsApplicationsGet: async (
      datasetUid?: number,
      createdByUserUid?: number,
      applicationName?: string,
      queryApplicationName?: string,
      includePublic?: boolean,
      pageSize?: number,
      pageNum?: number,
      workspaceUid?: number,
      sortColumn?: SortableApplicationColumns,
      sortDirection?: SortOrder,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/applications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasetUid !== undefined) {
        localVarQueryParameter['dataset_uid'] = datasetUid;
      }

      if (createdByUserUid !== undefined) {
        localVarQueryParameter['created_by_user_uid'] = createdByUserUid;
      }

      if (applicationName !== undefined) {
        localVarQueryParameter['application_name'] = applicationName;
      }

      if (queryApplicationName !== undefined) {
        localVarQueryParameter['query_application_name'] = queryApplicationName;
      }

      if (includePublic !== undefined) {
        localVarQueryParameter['include_public'] = includePublic;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter['page_num'] = pageNum;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      if (sortColumn !== undefined) {
        localVarQueryParameter['sort_column'] = sortColumn;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter['sort_direction'] = sortDirection;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Given the app progress so far + the current step, return the next settings to be filled in
     * @summary Get Data Task Step Next State
     * @param {AppModelSetupProgressPayload} appModelSetupProgressPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataTaskStepNextStateApplicationSetupNextSettingsPost: async (
      appModelSetupProgressPayload: AppModelSetupProgressPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'appModelSetupProgressPayload' is not null or undefined
      assertParamExists(
        'getDataTaskStepNextStateApplicationSetupNextSettingsPost',
        'appModelSetupProgressPayload',
        appModelSetupProgressPayload,
      );
      const localVarPath = `/application/setup/next-settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        appModelSetupProgressPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Migrated Application Ground Truth
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGet:
      async (
        applicationUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'applicationUid' is not null or undefined
        assertParamExists(
          'getMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGet',
          'applicationUid',
          applicationUid,
        );
        const localVarPath =
          `/applications/{application_uid}/migrated-ground-truth`.replace(
            `{${'application_uid'}}`,
            encodeURIComponent(String(applicationUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Preprocessing Settings
     * @param {number} applicationUid
     * @param {AppModelSetupProgressPayload} appModelSetupProgressPayload
     * @param {number} [workspaceUid]
     * @param {number} [modelUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost:
      async (
        applicationUid: number,
        appModelSetupProgressPayload: AppModelSetupProgressPayload,
        workspaceUid?: number,
        modelUid?: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'applicationUid' is not null or undefined
        assertParamExists(
          'getPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost',
          'applicationUid',
          applicationUid,
        );
        // verify required parameter 'appModelSetupProgressPayload' is not null or undefined
        assertParamExists(
          'getPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost',
          'appModelSetupProgressPayload',
          appModelSetupProgressPayload,
        );
        const localVarPath =
          `/application/{application_uid}/setup/preprocessing-settings`.replace(
            `{${'application_uid'}}`,
            encodeURIComponent(String(applicationUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (workspaceUid !== undefined) {
          localVarQueryParameter['workspace_uid'] = workspaceUid;
        }

        if (modelUid !== undefined) {
          localVarQueryParameter['model_uid'] = modelUid;
        }

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          appModelSetupProgressPayload,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Return the app setup progress
     * @summary Get Setup Progress
     * @param {number} applicationUid
     * @param {number} [modelNodeUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSetupProgressApplicationApplicationUidSetupGet: async (
      applicationUid: number,
      modelNodeUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'getSetupProgressApplicationApplicationUidSetupGet',
        'applicationUid',
        applicationUid,
      );
      const localVarPath = `/application/{application_uid}/setup`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelNodeUid !== undefined) {
        localVarQueryParameter['model_node_uid'] = modelNodeUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Include an app in AU calculations.
     * @summary Include In Au Usage
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    includeInAuUsageApplicationApplicationUidAuUsageIncludePut: async (
      applicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'includeInAuUsageApplicationApplicationUidAuUsageIncludePut',
        'applicationUid',
        applicationUid,
      );
      const localVarPath =
        `/application/{application_uid}/au-usage/include`.replace(
          `{${'application_uid'}}`,
          encodeURIComponent(String(applicationUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List App Versions Route
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAppVersionsRouteApplicationsApplicationUidAppVersionsGet: async (
      applicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'listAppVersionsRouteApplicationsApplicationUidAppVersionsGet',
        'applicationUid',
        applicationUid,
      );
      const localVarPath =
        `/applications/{application_uid}/app-versions`.replace(
          `{${'application_uid'}}`,
          encodeURIComponent(String(applicationUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Load a dag version for the application.
     * @summary Load App Version Route
     * @param {number} applicationUid
     * @param {number} appVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost:
      async (
        applicationUid: number,
        appVersionUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'applicationUid' is not null or undefined
        assertParamExists(
          'loadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost',
          'applicationUid',
          applicationUid,
        );
        // verify required parameter 'appVersionUid' is not null or undefined
        assertParamExists(
          'loadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost',
          'appVersionUid',
          appVersionUid,
        );
        const localVarPath =
          `/applications/{application_uid}/app-versions/{app_version_uid}/load`
            .replace(
              `{${'application_uid'}}`,
              encodeURIComponent(String(applicationUid)),
            )
            .replace(
              `{${'app_version_uid'}}`,
              encodeURIComponent(String(appVersionUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Kick off preprocessing of the optionally of the train sample, and of the whole dataset - preprocessing on train sample depends on ingestion for dataset being done - preprocessing on whole dataset should come after the preprocessing on train sample   if create_train_sample = True, otherwise it only depends on dataset ingestion
     * @summary Preprocess App Data
     * @param {number} applicationUid
     * @param {number} [modelNodeUid]
     * @param {boolean} [createTrainSample]
     * @param {ImportAssets} [importAssets]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preprocessAppDataApplicationApplicationUidPreprocessPost: async (
      applicationUid: number,
      modelNodeUid?: number,
      createTrainSample?: boolean,
      importAssets?: ImportAssets,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'preprocessAppDataApplicationApplicationUidPreprocessPost',
        'applicationUid',
        applicationUid,
      );
      const localVarPath = `/application/{application_uid}/preprocess`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelNodeUid !== undefined) {
        localVarQueryParameter['model_node_uid'] = modelNodeUid;
      }

      if (createTrainSample !== undefined) {
        localVarQueryParameter['create_train_sample'] = createTrainSample;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        importAssets,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Promote and application from Lab to Standard.
     * @summary Promote App
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promoteAppApplicationApplicationUidPromotePut: async (
      applicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'promoteAppApplicationApplicationUidPromotePut',
        'applicationUid',
        applicationUid,
      );
      const localVarPath = `/application/{application_uid}/promote`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Refresh Applications
     * @param {RefreshApplicationsParams} refreshApplicationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshApplicationsRefreshApplicationsPost: async (
      refreshApplicationsParams: RefreshApplicationsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'refreshApplicationsParams' is not null or undefined
      assertParamExists(
        'refreshApplicationsRefreshApplicationsPost',
        'refreshApplicationsParams',
        refreshApplicationsParams,
      );
      const localVarPath = `/refresh-applications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        refreshApplicationsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Saves the progress the user has made in the setup process
     * @summary Save Setup Progress
     * @param {number} applicationUid
     * @param {AppModelSetupProgressPayload} appModelSetupProgressPayload
     * @param {number} [modelNodeUid]
     * @param {boolean} [runPreprocessing]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSetupProgressApplicationApplicationUidSetupPost: async (
      applicationUid: number,
      appModelSetupProgressPayload: AppModelSetupProgressPayload,
      modelNodeUid?: number,
      runPreprocessing?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'saveSetupProgressApplicationApplicationUidSetupPost',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'appModelSetupProgressPayload' is not null or undefined
      assertParamExists(
        'saveSetupProgressApplicationApplicationUidSetupPost',
        'appModelSetupProgressPayload',
        appModelSetupProgressPayload,
      );
      const localVarPath = `/application/{application_uid}/setup`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelNodeUid !== undefined) {
        localVarQueryParameter['model_node_uid'] = modelNodeUid;
      }

      if (runPreprocessing !== undefined) {
        localVarQueryParameter['run_preprocessing'] = runPreprocessing;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        appModelSetupProgressPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Swap Dataset
     * @param {number} applicationUid
     * @param {SwapDatasetParams} swapDatasetParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swapDatasetApplicationsApplicationUidSwapDatasetPost: async (
      applicationUid: number,
      swapDatasetParams: SwapDatasetParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'swapDatasetApplicationsApplicationUidSwapDatasetPost',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'swapDatasetParams' is not null or undefined
      assertParamExists(
        'swapDatasetApplicationsApplicationUidSwapDatasetPost',
        'swapDatasetParams',
        swapDatasetParams,
      );
      const localVarPath =
        `/applications/{application_uid}/swap-dataset`.replace(
          `{${'application_uid'}}`,
          encodeURIComponent(String(applicationUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        swapDatasetParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Archive an application. Archived applications consume a fraction of their original AU usage but cannot be modified.
     * @summary Unarchive App
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unarchiveAppApplicationApplicationUidUnarchivePut: async (
      applicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'unarchiveAppApplicationApplicationUidUnarchivePut',
        'applicationUid',
        applicationUid,
      );
      const localVarPath = `/application/{application_uid}/unarchive`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update specific mutable properites of the Application (like the name)
     * @summary Update Application
     * @param {number} applicationUid
     * @param {UpdateApplicationPayload} updateApplicationPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApplicationApplicationsApplicationUidPut: async (
      applicationUid: number,
      updateApplicationPayload: UpdateApplicationPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'updateApplicationApplicationsApplicationUidPut',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'updateApplicationPayload' is not null or undefined
      assertParamExists(
        'updateApplicationApplicationsApplicationUidPut',
        'updateApplicationPayload',
        updateApplicationPayload,
      );
      const localVarPath = `/applications/{application_uid}`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateApplicationPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApplicationsApi - functional programming interface
 * @export
 */
export const ApplicationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ApplicationsApiAxiosParamCreator(configuration);
  return {
    /**
     * If there is a PageSplitter in the app DAG, updates it with the new config. Otherwise, create a new node and commit the PageSplitter to it.
     * @summary Add Or Modify Page Splitter
     * @param {number} applicationUid
     * @param {CommittedOperatorPayload} committedOperatorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addOrModifyPageSplitterApplicationApplicationUidPageSplitterPost(
      applicationUid: number,
      committedOperatorPayload: CommittedOperatorPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addOrModifyPageSplitterApplicationApplicationUidPageSplitterPost(
          applicationUid,
          committedOperatorPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.addOrModifyPageSplitterApplicationApplicationUidPageSplitterPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Archive App Version
     * @param {number} applicationUid
     * @param {number} appVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost(
      applicationUid: number,
      appVersionUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.archiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost(
          applicationUid,
          appVersionUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.archiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Archive an application. Archived applications consume a fraction of their original AU usage but cannot be modified.
     * @summary Archive Application
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveApplicationApplicationApplicationUidArchivePut(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.archiveApplicationApplicationApplicationUidArchivePut(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.archiveApplicationApplicationApplicationUidArchivePut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Create a new dag version for the application.
     * @summary Create App Version Route
     * @param {number} applicationUid
     * @param {CreateAppVersionParams} createAppVersionParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAppVersionRouteApplicationsApplicationUidAppVersionsPost(
      applicationUid: number,
      createAppVersionParams: CreateAppVersionParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateAppVersionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createAppVersionRouteApplicationsApplicationUidAppVersionsPost(
          applicationUid,
          createAppVersionParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.createAppVersionRouteApplicationsApplicationUidAppVersionsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Create Application
     * @param {CreateApplicationPayload} createApplicationPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createApplicationApplicationsPost(
      createApplicationPayload: CreateApplicationPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateApplicationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createApplicationApplicationsPost(
          createApplicationPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.createApplicationApplicationsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Create New Application With Resplit Datasources
     * @param {number} applicationUid
     * @param {ResplitDataSourcesRequest} resplitDataSourcesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost(
      applicationUid: number,
      resplitDataSourcesRequest: ResplitDataSourcesRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResplitDataSourcesResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost(
          applicationUid,
          resplitDataSourcesRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.createNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete application by application_uid.
     * @summary Delete Application
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteApplicationApplicationsApplicationUidDelete(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteApplicationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteApplicationApplicationsApplicationUidDelete(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.deleteApplicationApplicationsApplicationUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Exclude an app from AU calculations. Temporary API that will be used to ignore duplicate apps until we have full featured app versioning in the platform
     * @summary Exclude In Au Usage
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async excludeInAuUsageApplicationApplicationUidAuUsageExcludePut(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.excludeInAuUsageApplicationApplicationUidAuUsageExcludePut(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.excludeInAuUsageApplicationApplicationUidAuUsageExcludePut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Execute Graph On Data
     * @param {number} applicationUid
     * @param {ExecuteGraphOnDataRequest} executeGraphOnDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async executeGraphOnDataApplicationsApplicationUidExecuteGraphPost(
      applicationUid: number,
      executeGraphOnDataRequest: ExecuteGraphOnDataRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ExecuteGraphOnDataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.executeGraphOnDataApplicationsApplicationUidExecuteGraphPost(
          applicationUid,
          executeGraphOnDataRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.executeGraphOnDataApplicationsApplicationUidExecuteGraphPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch an application\'s AU usage information
     * @summary Get App Au Usage
     * @param {number} applicationUid
     * @param {boolean} [showBreakdown]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppAuUsageApplicationApplicationUidAuUsageGet(
      applicationUid: number,
      showBreakdown?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppAUUsageResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAppAuUsageApplicationApplicationUidAuUsageGet(
          applicationUid,
          showBreakdown,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.getAppAuUsageApplicationApplicationUidAuUsageGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Given the app progress so far, returns the template id that uniquely identifies the template or raises an error if not template is found or it isn\'t unique
     * @summary Get App Template Id
     * @param {AppModelSetupProgressPayload} appModelSetupProgressPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppTemplateIdApplicationTemplateIdPost(
      appModelSetupProgressPayload: AppModelSetupProgressPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAppTemplateIdApplicationTemplateIdPost(
          appModelSetupProgressPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.getAppTemplateIdApplicationTemplateIdPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch application details (incl. dag) for application_uid.
     * @summary Get Application
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplicationApplicationsApplicationUidGet(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedApplication>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getApplicationApplicationsApplicationUidGet(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.getApplicationApplicationsApplicationUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch all application metadata (excluding possibly large op node dag).
     * @summary Get Application Count
     * @param {number} [datasetUid]
     * @param {number} [createdByUserUid]
     * @param {string} [applicationName]
     * @param {string} [queryApplicationName]
     * @param {boolean} [includePublic]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplicationCountApplicationCountGet(
      datasetUid?: number,
      createdByUserUid?: number,
      applicationName?: string,
      queryApplicationName?: string,
      includePublic?: boolean,
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationCount>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getApplicationCountApplicationCountGet(
          datasetUid,
          createdByUserUid,
          applicationName,
          queryApplicationName,
          includePublic,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.getApplicationCountApplicationCountGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Application Required Input Schema
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGet(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGet(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.getApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch all application metadata (excluding possibly large op node dag).
     * @summary Get Applications
     * @param {number} [datasetUid]
     * @param {number} [createdByUserUid]
     * @param {string} [applicationName]
     * @param {string} [queryApplicationName]
     * @param {boolean} [includePublic]
     * @param {number} [pageSize]
     * @param {number} [pageNum]
     * @param {number} [workspaceUid]
     * @param {SortableApplicationColumns} [sortColumn]
     * @param {SortOrder} [sortDirection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplicationsApplicationsGet(
      datasetUid?: number,
      createdByUserUid?: number,
      applicationName?: string,
      queryApplicationName?: string,
      includePublic?: boolean,
      pageSize?: number,
      pageNum?: number,
      workspaceUid?: number,
      sortColumn?: SortableApplicationColumns,
      sortDirection?: SortOrder,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ApplicationMetadata>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getApplicationsApplicationsGet(
          datasetUid,
          createdByUserUid,
          applicationName,
          queryApplicationName,
          includePublic,
          pageSize,
          pageNum,
          workspaceUid,
          sortColumn,
          sortDirection,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ApplicationsApi.getApplicationsApplicationsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Given the app progress so far + the current step, return the next settings to be filled in
     * @summary Get Data Task Step Next State
     * @param {AppModelSetupProgressPayload} appModelSetupProgressPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataTaskStepNextStateApplicationSetupNextSettingsPost(
      appModelSetupProgressPayload: AppModelSetupProgressPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<InputFieldSpec>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDataTaskStepNextStateApplicationSetupNextSettingsPost(
          appModelSetupProgressPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.getDataTaskStepNextStateApplicationSetupNextSettingsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Migrated Application Ground Truth
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGet(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGet(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.getMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Preprocessing Settings
     * @param {number} applicationUid
     * @param {AppModelSetupProgressPayload} appModelSetupProgressPayload
     * @param {number} [workspaceUid]
     * @param {number} [modelUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost(
      applicationUid: number,
      appModelSetupProgressPayload: AppModelSetupProgressPayload,
      workspaceUid?: number,
      modelUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PreprocessingStepResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost(
          applicationUid,
          appModelSetupProgressPayload,
          workspaceUid,
          modelUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.getPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return the app setup progress
     * @summary Get Setup Progress
     * @param {number} applicationUid
     * @param {number} [modelNodeUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSetupProgressApplicationApplicationUidSetupGet(
      applicationUid: number,
      modelNodeUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AppModelSetupProgress>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSetupProgressApplicationApplicationUidSetupGet(
          applicationUid,
          modelNodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.getSetupProgressApplicationApplicationUidSetupGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Include an app in AU calculations.
     * @summary Include In Au Usage
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async includeInAuUsageApplicationApplicationUidAuUsageIncludePut(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.includeInAuUsageApplicationApplicationUidAuUsageIncludePut(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.includeInAuUsageApplicationApplicationUidAuUsageIncludePut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List App Versions Route
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listAppVersionsRouteApplicationsApplicationUidAppVersionsGet(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AppVersion>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listAppVersionsRouteApplicationsApplicationUidAppVersionsGet(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.listAppVersionsRouteApplicationsApplicationUidAppVersionsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Load a dag version for the application.
     * @summary Load App Version Route
     * @param {number} applicationUid
     * @param {number} appVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost(
      applicationUid: number,
      appVersionUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.loadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost(
          applicationUid,
          appVersionUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.loadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Kick off preprocessing of the optionally of the train sample, and of the whole dataset - preprocessing on train sample depends on ingestion for dataset being done - preprocessing on whole dataset should come after the preprocessing on train sample   if create_train_sample = True, otherwise it only depends on dataset ingestion
     * @summary Preprocess App Data
     * @param {number} applicationUid
     * @param {number} [modelNodeUid]
     * @param {boolean} [createTrainSample]
     * @param {ImportAssets} [importAssets]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async preprocessAppDataApplicationApplicationUidPreprocessPost(
      applicationUid: number,
      modelNodeUid?: number,
      createTrainSample?: boolean,
      importAssets?: ImportAssets,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PreprocessDatasourcesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.preprocessAppDataApplicationApplicationUidPreprocessPost(
          applicationUid,
          modelNodeUid,
          createTrainSample,
          importAssets,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.preprocessAppDataApplicationApplicationUidPreprocessPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Promote and application from Lab to Standard.
     * @summary Promote App
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async promoteAppApplicationApplicationUidPromotePut(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.promoteAppApplicationApplicationUidPromotePut(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.promoteAppApplicationApplicationUidPromotePut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Refresh Applications
     * @param {RefreshApplicationsParams} refreshApplicationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshApplicationsRefreshApplicationsPost(
      refreshApplicationsParams: RefreshApplicationsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RefreshApplicationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.refreshApplicationsRefreshApplicationsPost(
          refreshApplicationsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.refreshApplicationsRefreshApplicationsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Saves the progress the user has made in the setup process
     * @summary Save Setup Progress
     * @param {number} applicationUid
     * @param {AppModelSetupProgressPayload} appModelSetupProgressPayload
     * @param {number} [modelNodeUid]
     * @param {boolean} [runPreprocessing]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveSetupProgressApplicationApplicationUidSetupPost(
      applicationUid: number,
      appModelSetupProgressPayload: AppModelSetupProgressPayload,
      modelNodeUid?: number,
      runPreprocessing?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SaveSetupProgressResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.saveSetupProgressApplicationApplicationUidSetupPost(
          applicationUid,
          appModelSetupProgressPayload,
          modelNodeUid,
          runPreprocessing,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.saveSetupProgressApplicationApplicationUidSetupPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Swap Dataset
     * @param {number} applicationUid
     * @param {SwapDatasetParams} swapDatasetParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async swapDatasetApplicationsApplicationUidSwapDatasetPost(
      applicationUid: number,
      swapDatasetParams: SwapDatasetParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SwapDatasetResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.swapDatasetApplicationsApplicationUidSwapDatasetPost(
          applicationUid,
          swapDatasetParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.swapDatasetApplicationsApplicationUidSwapDatasetPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Archive an application. Archived applications consume a fraction of their original AU usage but cannot be modified.
     * @summary Unarchive App
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unarchiveAppApplicationApplicationUidUnarchivePut(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.unarchiveAppApplicationApplicationUidUnarchivePut(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.unarchiveAppApplicationApplicationUidUnarchivePut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update specific mutable properites of the Application (like the name)
     * @summary Update Application
     * @param {number} applicationUid
     * @param {UpdateApplicationPayload} updateApplicationPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateApplicationApplicationsApplicationUidPut(
      applicationUid: number,
      updateApplicationPayload: UpdateApplicationPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HydratedApplication>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateApplicationApplicationsApplicationUidPut(
          applicationUid,
          updateApplicationPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ApplicationsApi.updateApplicationApplicationsApplicationUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ApplicationsApi - factory interface
 * @export
 */
export const ApplicationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ApplicationsApiFp(configuration);
  return {
    /**
     * If there is a PageSplitter in the app DAG, updates it with the new config. Otherwise, create a new node and commit the PageSplitter to it.
     * @summary Add Or Modify Page Splitter
     * @param {ApplicationsApiAddOrModifyPageSplitterApplicationApplicationUidPageSplitterPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOrModifyPageSplitterApplicationApplicationUidPageSplitterPost(
      requestParameters: ApplicationsApiAddOrModifyPageSplitterApplicationApplicationUidPageSplitterPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .addOrModifyPageSplitterApplicationApplicationUidPageSplitterPost(
          requestParameters.applicationUid,
          requestParameters.committedOperatorPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Archive App Version
     * @param {ApplicationsApiArchiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost(
      requestParameters: ApplicationsApiArchiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .archiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost(
          requestParameters.applicationUid,
          requestParameters.appVersionUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Archive an application. Archived applications consume a fraction of their original AU usage but cannot be modified.
     * @summary Archive Application
     * @param {ApplicationsApiArchiveApplicationApplicationApplicationUidArchivePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveApplicationApplicationApplicationUidArchivePut(
      requestParameters: ApplicationsApiArchiveApplicationApplicationApplicationUidArchivePutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .archiveApplicationApplicationApplicationUidArchivePut(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Create a new dag version for the application.
     * @summary Create App Version Route
     * @param {ApplicationsApiCreateAppVersionRouteApplicationsApplicationUidAppVersionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAppVersionRouteApplicationsApplicationUidAppVersionsPost(
      requestParameters: ApplicationsApiCreateAppVersionRouteApplicationsApplicationUidAppVersionsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateAppVersionResponse> {
      return localVarFp
        .createAppVersionRouteApplicationsApplicationUidAppVersionsPost(
          requestParameters.applicationUid,
          requestParameters.createAppVersionParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create Application
     * @param {ApplicationsApiCreateApplicationApplicationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApplicationApplicationsPost(
      requestParameters: ApplicationsApiCreateApplicationApplicationsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateApplicationResponse> {
      return localVarFp
        .createApplicationApplicationsPost(
          requestParameters.createApplicationPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create New Application With Resplit Datasources
     * @param {ApplicationsApiCreateNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost(
      requestParameters: ApplicationsApiCreateNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ResplitDataSourcesResponseModel> {
      return localVarFp
        .createNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost(
          requestParameters.applicationUid,
          requestParameters.resplitDataSourcesRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Delete application by application_uid.
     * @summary Delete Application
     * @param {ApplicationsApiDeleteApplicationApplicationsApplicationUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApplicationApplicationsApplicationUidDelete(
      requestParameters: ApplicationsApiDeleteApplicationApplicationsApplicationUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DeleteApplicationResponse> {
      return localVarFp
        .deleteApplicationApplicationsApplicationUidDelete(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Exclude an app from AU calculations. Temporary API that will be used to ignore duplicate apps until we have full featured app versioning in the platform
     * @summary Exclude In Au Usage
     * @param {ApplicationsApiExcludeInAuUsageApplicationApplicationUidAuUsageExcludePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excludeInAuUsageApplicationApplicationUidAuUsageExcludePut(
      requestParameters: ApplicationsApiExcludeInAuUsageApplicationApplicationUidAuUsageExcludePutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .excludeInAuUsageApplicationApplicationUidAuUsageExcludePut(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Execute Graph On Data
     * @param {ApplicationsApiExecuteGraphOnDataApplicationsApplicationUidExecuteGraphPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeGraphOnDataApplicationsApplicationUidExecuteGraphPost(
      requestParameters: ApplicationsApiExecuteGraphOnDataApplicationsApplicationUidExecuteGraphPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ExecuteGraphOnDataResponse> {
      return localVarFp
        .executeGraphOnDataApplicationsApplicationUidExecuteGraphPost(
          requestParameters.applicationUid,
          requestParameters.executeGraphOnDataRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch an application\'s AU usage information
     * @summary Get App Au Usage
     * @param {ApplicationsApiGetAppAuUsageApplicationApplicationUidAuUsageGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppAuUsageApplicationApplicationUidAuUsageGet(
      requestParameters: ApplicationsApiGetAppAuUsageApplicationApplicationUidAuUsageGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AppAUUsageResponse> {
      return localVarFp
        .getAppAuUsageApplicationApplicationUidAuUsageGet(
          requestParameters.applicationUid,
          requestParameters.showBreakdown,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Given the app progress so far, returns the template id that uniquely identifies the template or raises an error if not template is found or it isn\'t unique
     * @summary Get App Template Id
     * @param {ApplicationsApiGetAppTemplateIdApplicationTemplateIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppTemplateIdApplicationTemplateIdPost(
      requestParameters: ApplicationsApiGetAppTemplateIdApplicationTemplateIdPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<string> {
      return localVarFp
        .getAppTemplateIdApplicationTemplateIdPost(
          requestParameters.appModelSetupProgressPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch application details (incl. dag) for application_uid.
     * @summary Get Application
     * @param {ApplicationsApiGetApplicationApplicationsApplicationUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationApplicationsApplicationUidGet(
      requestParameters: ApplicationsApiGetApplicationApplicationsApplicationUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HydratedApplication> {
      return localVarFp
        .getApplicationApplicationsApplicationUidGet(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch all application metadata (excluding possibly large op node dag).
     * @summary Get Application Count
     * @param {ApplicationsApiGetApplicationCountApplicationCountGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationCountApplicationCountGet(
      requestParameters: ApplicationsApiGetApplicationCountApplicationCountGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ApplicationCount> {
      return localVarFp
        .getApplicationCountApplicationCountGet(
          requestParameters.datasetUid,
          requestParameters.createdByUserUid,
          requestParameters.applicationName,
          requestParameters.queryApplicationName,
          requestParameters.includePublic,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Application Required Input Schema
     * @param {ApplicationsApiGetApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGet(
      requestParameters: ApplicationsApiGetApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .getApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGet(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch all application metadata (excluding possibly large op node dag).
     * @summary Get Applications
     * @param {ApplicationsApiGetApplicationsApplicationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationsApplicationsGet(
      requestParameters: ApplicationsApiGetApplicationsApplicationsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ApplicationMetadata>> {
      return localVarFp
        .getApplicationsApplicationsGet(
          requestParameters.datasetUid,
          requestParameters.createdByUserUid,
          requestParameters.applicationName,
          requestParameters.queryApplicationName,
          requestParameters.includePublic,
          requestParameters.pageSize,
          requestParameters.pageNum,
          requestParameters.workspaceUid,
          requestParameters.sortColumn,
          requestParameters.sortDirection,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Given the app progress so far + the current step, return the next settings to be filled in
     * @summary Get Data Task Step Next State
     * @param {ApplicationsApiGetDataTaskStepNextStateApplicationSetupNextSettingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataTaskStepNextStateApplicationSetupNextSettingsPost(
      requestParameters: ApplicationsApiGetDataTaskStepNextStateApplicationSetupNextSettingsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<InputFieldSpec>> {
      return localVarFp
        .getDataTaskStepNextStateApplicationSetupNextSettingsPost(
          requestParameters.appModelSetupProgressPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Migrated Application Ground Truth
     * @param {ApplicationsApiGetMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGet(
      requestParameters: ApplicationsApiGetMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<object>> {
      return localVarFp
        .getMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGet(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Preprocessing Settings
     * @param {ApplicationsApiGetPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost(
      requestParameters: ApplicationsApiGetPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PreprocessingStepResponse> {
      return localVarFp
        .getPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost(
          requestParameters.applicationUid,
          requestParameters.appModelSetupProgressPayload,
          requestParameters.workspaceUid,
          requestParameters.modelUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Return the app setup progress
     * @summary Get Setup Progress
     * @param {ApplicationsApiGetSetupProgressApplicationApplicationUidSetupGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSetupProgressApplicationApplicationUidSetupGet(
      requestParameters: ApplicationsApiGetSetupProgressApplicationApplicationUidSetupGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AppModelSetupProgress> {
      return localVarFp
        .getSetupProgressApplicationApplicationUidSetupGet(
          requestParameters.applicationUid,
          requestParameters.modelNodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Include an app in AU calculations.
     * @summary Include In Au Usage
     * @param {ApplicationsApiIncludeInAuUsageApplicationApplicationUidAuUsageIncludePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    includeInAuUsageApplicationApplicationUidAuUsageIncludePut(
      requestParameters: ApplicationsApiIncludeInAuUsageApplicationApplicationUidAuUsageIncludePutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .includeInAuUsageApplicationApplicationUidAuUsageIncludePut(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List App Versions Route
     * @param {ApplicationsApiListAppVersionsRouteApplicationsApplicationUidAppVersionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAppVersionsRouteApplicationsApplicationUidAppVersionsGet(
      requestParameters: ApplicationsApiListAppVersionsRouteApplicationsApplicationUidAppVersionsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<AppVersion>> {
      return localVarFp
        .listAppVersionsRouteApplicationsApplicationUidAppVersionsGet(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Load a dag version for the application.
     * @summary Load App Version Route
     * @param {ApplicationsApiLoadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost(
      requestParameters: ApplicationsApiLoadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .loadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost(
          requestParameters.applicationUid,
          requestParameters.appVersionUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Kick off preprocessing of the optionally of the train sample, and of the whole dataset - preprocessing on train sample depends on ingestion for dataset being done - preprocessing on whole dataset should come after the preprocessing on train sample   if create_train_sample = True, otherwise it only depends on dataset ingestion
     * @summary Preprocess App Data
     * @param {ApplicationsApiPreprocessAppDataApplicationApplicationUidPreprocessPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preprocessAppDataApplicationApplicationUidPreprocessPost(
      requestParameters: ApplicationsApiPreprocessAppDataApplicationApplicationUidPreprocessPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PreprocessDatasourcesResponse> {
      return localVarFp
        .preprocessAppDataApplicationApplicationUidPreprocessPost(
          requestParameters.applicationUid,
          requestParameters.modelNodeUid,
          requestParameters.createTrainSample,
          requestParameters.importAssets,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Promote and application from Lab to Standard.
     * @summary Promote App
     * @param {ApplicationsApiPromoteAppApplicationApplicationUidPromotePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promoteAppApplicationApplicationUidPromotePut(
      requestParameters: ApplicationsApiPromoteAppApplicationApplicationUidPromotePutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .promoteAppApplicationApplicationUidPromotePut(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Refresh Applications
     * @param {ApplicationsApiRefreshApplicationsRefreshApplicationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshApplicationsRefreshApplicationsPost(
      requestParameters: ApplicationsApiRefreshApplicationsRefreshApplicationsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RefreshApplicationsResponse> {
      return localVarFp
        .refreshApplicationsRefreshApplicationsPost(
          requestParameters.refreshApplicationsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Saves the progress the user has made in the setup process
     * @summary Save Setup Progress
     * @param {ApplicationsApiSaveSetupProgressApplicationApplicationUidSetupPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSetupProgressApplicationApplicationUidSetupPost(
      requestParameters: ApplicationsApiSaveSetupProgressApplicationApplicationUidSetupPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SaveSetupProgressResponse> {
      return localVarFp
        .saveSetupProgressApplicationApplicationUidSetupPost(
          requestParameters.applicationUid,
          requestParameters.appModelSetupProgressPayload,
          requestParameters.modelNodeUid,
          requestParameters.runPreprocessing,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Swap Dataset
     * @param {ApplicationsApiSwapDatasetApplicationsApplicationUidSwapDatasetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swapDatasetApplicationsApplicationUidSwapDatasetPost(
      requestParameters: ApplicationsApiSwapDatasetApplicationsApplicationUidSwapDatasetPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SwapDatasetResponse> {
      return localVarFp
        .swapDatasetApplicationsApplicationUidSwapDatasetPost(
          requestParameters.applicationUid,
          requestParameters.swapDatasetParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Archive an application. Archived applications consume a fraction of their original AU usage but cannot be modified.
     * @summary Unarchive App
     * @param {ApplicationsApiUnarchiveAppApplicationApplicationUidUnarchivePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unarchiveAppApplicationApplicationUidUnarchivePut(
      requestParameters: ApplicationsApiUnarchiveAppApplicationApplicationUidUnarchivePutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .unarchiveAppApplicationApplicationUidUnarchivePut(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Update specific mutable properites of the Application (like the name)
     * @summary Update Application
     * @param {ApplicationsApiUpdateApplicationApplicationsApplicationUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateApplicationApplicationsApplicationUidPut(
      requestParameters: ApplicationsApiUpdateApplicationApplicationsApplicationUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HydratedApplication> {
      return localVarFp
        .updateApplicationApplicationsApplicationUidPut(
          requestParameters.applicationUid,
          requestParameters.updateApplicationPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addOrModifyPageSplitterApplicationApplicationUidPageSplitterPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiAddOrModifyPageSplitterApplicationApplicationUidPageSplitterPostRequest
 */
export interface ApplicationsApiAddOrModifyPageSplitterApplicationApplicationUidPageSplitterPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiAddOrModifyPageSplitterApplicationApplicationUidPageSplitterPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {CommittedOperatorPayload}
   * @memberof ApplicationsApiAddOrModifyPageSplitterApplicationApplicationUidPageSplitterPost
   */
  readonly committedOperatorPayload: CommittedOperatorPayload;
}

/**
 * Request parameters for archiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiArchiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePostRequest
 */
export interface ApplicationsApiArchiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiArchiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiArchiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost
   */
  readonly appVersionUid: number;
}

/**
 * Request parameters for archiveApplicationApplicationApplicationUidArchivePut operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiArchiveApplicationApplicationApplicationUidArchivePutRequest
 */
export interface ApplicationsApiArchiveApplicationApplicationApplicationUidArchivePutRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiArchiveApplicationApplicationApplicationUidArchivePut
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for createAppVersionRouteApplicationsApplicationUidAppVersionsPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiCreateAppVersionRouteApplicationsApplicationUidAppVersionsPostRequest
 */
export interface ApplicationsApiCreateAppVersionRouteApplicationsApplicationUidAppVersionsPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiCreateAppVersionRouteApplicationsApplicationUidAppVersionsPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {CreateAppVersionParams}
   * @memberof ApplicationsApiCreateAppVersionRouteApplicationsApplicationUidAppVersionsPost
   */
  readonly createAppVersionParams: CreateAppVersionParams;
}

/**
 * Request parameters for createApplicationApplicationsPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiCreateApplicationApplicationsPostRequest
 */
export interface ApplicationsApiCreateApplicationApplicationsPostRequest {
  /**
   *
   * @type {CreateApplicationPayload}
   * @memberof ApplicationsApiCreateApplicationApplicationsPost
   */
  readonly createApplicationPayload: CreateApplicationPayload;
}

/**
 * Request parameters for createNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiCreateNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPostRequest
 */
export interface ApplicationsApiCreateNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiCreateNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {ResplitDataSourcesRequest}
   * @memberof ApplicationsApiCreateNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost
   */
  readonly resplitDataSourcesRequest: ResplitDataSourcesRequest;
}

/**
 * Request parameters for deleteApplicationApplicationsApplicationUidDelete operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiDeleteApplicationApplicationsApplicationUidDeleteRequest
 */
export interface ApplicationsApiDeleteApplicationApplicationsApplicationUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiDeleteApplicationApplicationsApplicationUidDelete
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for excludeInAuUsageApplicationApplicationUidAuUsageExcludePut operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiExcludeInAuUsageApplicationApplicationUidAuUsageExcludePutRequest
 */
export interface ApplicationsApiExcludeInAuUsageApplicationApplicationUidAuUsageExcludePutRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiExcludeInAuUsageApplicationApplicationUidAuUsageExcludePut
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for executeGraphOnDataApplicationsApplicationUidExecuteGraphPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiExecuteGraphOnDataApplicationsApplicationUidExecuteGraphPostRequest
 */
export interface ApplicationsApiExecuteGraphOnDataApplicationsApplicationUidExecuteGraphPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiExecuteGraphOnDataApplicationsApplicationUidExecuteGraphPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {ExecuteGraphOnDataRequest}
   * @memberof ApplicationsApiExecuteGraphOnDataApplicationsApplicationUidExecuteGraphPost
   */
  readonly executeGraphOnDataRequest: ExecuteGraphOnDataRequest;
}

/**
 * Request parameters for getAppAuUsageApplicationApplicationUidAuUsageGet operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiGetAppAuUsageApplicationApplicationUidAuUsageGetRequest
 */
export interface ApplicationsApiGetAppAuUsageApplicationApplicationUidAuUsageGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetAppAuUsageApplicationApplicationUidAuUsageGet
   */
  readonly applicationUid: number;

  /**
   *
   * @type {boolean}
   * @memberof ApplicationsApiGetAppAuUsageApplicationApplicationUidAuUsageGet
   */
  readonly showBreakdown?: boolean;
}

/**
 * Request parameters for getAppTemplateIdApplicationTemplateIdPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiGetAppTemplateIdApplicationTemplateIdPostRequest
 */
export interface ApplicationsApiGetAppTemplateIdApplicationTemplateIdPostRequest {
  /**
   *
   * @type {AppModelSetupProgressPayload}
   * @memberof ApplicationsApiGetAppTemplateIdApplicationTemplateIdPost
   */
  readonly appModelSetupProgressPayload: AppModelSetupProgressPayload;
}

/**
 * Request parameters for getApplicationApplicationsApplicationUidGet operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiGetApplicationApplicationsApplicationUidGetRequest
 */
export interface ApplicationsApiGetApplicationApplicationsApplicationUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetApplicationApplicationsApplicationUidGet
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for getApplicationCountApplicationCountGet operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiGetApplicationCountApplicationCountGetRequest
 */
export interface ApplicationsApiGetApplicationCountApplicationCountGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetApplicationCountApplicationCountGet
   */
  readonly datasetUid?: number;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetApplicationCountApplicationCountGet
   */
  readonly createdByUserUid?: number;

  /**
   *
   * @type {string}
   * @memberof ApplicationsApiGetApplicationCountApplicationCountGet
   */
  readonly applicationName?: string;

  /**
   *
   * @type {string}
   * @memberof ApplicationsApiGetApplicationCountApplicationCountGet
   */
  readonly queryApplicationName?: string;

  /**
   *
   * @type {boolean}
   * @memberof ApplicationsApiGetApplicationCountApplicationCountGet
   */
  readonly includePublic?: boolean;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetApplicationCountApplicationCountGet
   */
  readonly workspaceUid?: number;
}

/**
 * Request parameters for getApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGet operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiGetApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGetRequest
 */
export interface ApplicationsApiGetApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGet
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for getApplicationsApplicationsGet operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiGetApplicationsApplicationsGetRequest
 */
export interface ApplicationsApiGetApplicationsApplicationsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetApplicationsApplicationsGet
   */
  readonly datasetUid?: number;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetApplicationsApplicationsGet
   */
  readonly createdByUserUid?: number;

  /**
   *
   * @type {string}
   * @memberof ApplicationsApiGetApplicationsApplicationsGet
   */
  readonly applicationName?: string;

  /**
   *
   * @type {string}
   * @memberof ApplicationsApiGetApplicationsApplicationsGet
   */
  readonly queryApplicationName?: string;

  /**
   *
   * @type {boolean}
   * @memberof ApplicationsApiGetApplicationsApplicationsGet
   */
  readonly includePublic?: boolean;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetApplicationsApplicationsGet
   */
  readonly pageSize?: number;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetApplicationsApplicationsGet
   */
  readonly pageNum?: number;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetApplicationsApplicationsGet
   */
  readonly workspaceUid?: number;

  /**
   *
   * @type {SortableApplicationColumns}
   * @memberof ApplicationsApiGetApplicationsApplicationsGet
   */
  readonly sortColumn?: SortableApplicationColumns;

  /**
   *
   * @type {SortOrder}
   * @memberof ApplicationsApiGetApplicationsApplicationsGet
   */
  readonly sortDirection?: SortOrder;
}

/**
 * Request parameters for getDataTaskStepNextStateApplicationSetupNextSettingsPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiGetDataTaskStepNextStateApplicationSetupNextSettingsPostRequest
 */
export interface ApplicationsApiGetDataTaskStepNextStateApplicationSetupNextSettingsPostRequest {
  /**
   *
   * @type {AppModelSetupProgressPayload}
   * @memberof ApplicationsApiGetDataTaskStepNextStateApplicationSetupNextSettingsPost
   */
  readonly appModelSetupProgressPayload: AppModelSetupProgressPayload;
}

/**
 * Request parameters for getMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGet operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiGetMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGetRequest
 */
export interface ApplicationsApiGetMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGet
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for getPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiGetPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPostRequest
 */
export interface ApplicationsApiGetPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {AppModelSetupProgressPayload}
   * @memberof ApplicationsApiGetPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost
   */
  readonly appModelSetupProgressPayload: AppModelSetupProgressPayload;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost
   */
  readonly workspaceUid?: number;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost
   */
  readonly modelUid?: number;
}

/**
 * Request parameters for getSetupProgressApplicationApplicationUidSetupGet operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiGetSetupProgressApplicationApplicationUidSetupGetRequest
 */
export interface ApplicationsApiGetSetupProgressApplicationApplicationUidSetupGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetSetupProgressApplicationApplicationUidSetupGet
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiGetSetupProgressApplicationApplicationUidSetupGet
   */
  readonly modelNodeUid?: number;
}

/**
 * Request parameters for includeInAuUsageApplicationApplicationUidAuUsageIncludePut operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiIncludeInAuUsageApplicationApplicationUidAuUsageIncludePutRequest
 */
export interface ApplicationsApiIncludeInAuUsageApplicationApplicationUidAuUsageIncludePutRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiIncludeInAuUsageApplicationApplicationUidAuUsageIncludePut
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for listAppVersionsRouteApplicationsApplicationUidAppVersionsGet operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiListAppVersionsRouteApplicationsApplicationUidAppVersionsGetRequest
 */
export interface ApplicationsApiListAppVersionsRouteApplicationsApplicationUidAppVersionsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiListAppVersionsRouteApplicationsApplicationUidAppVersionsGet
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for loadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiLoadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPostRequest
 */
export interface ApplicationsApiLoadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiLoadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiLoadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost
   */
  readonly appVersionUid: number;
}

/**
 * Request parameters for preprocessAppDataApplicationApplicationUidPreprocessPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiPreprocessAppDataApplicationApplicationUidPreprocessPostRequest
 */
export interface ApplicationsApiPreprocessAppDataApplicationApplicationUidPreprocessPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiPreprocessAppDataApplicationApplicationUidPreprocessPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiPreprocessAppDataApplicationApplicationUidPreprocessPost
   */
  readonly modelNodeUid?: number;

  /**
   *
   * @type {boolean}
   * @memberof ApplicationsApiPreprocessAppDataApplicationApplicationUidPreprocessPost
   */
  readonly createTrainSample?: boolean;

  /**
   *
   * @type {ImportAssets}
   * @memberof ApplicationsApiPreprocessAppDataApplicationApplicationUidPreprocessPost
   */
  readonly importAssets?: ImportAssets;
}

/**
 * Request parameters for promoteAppApplicationApplicationUidPromotePut operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiPromoteAppApplicationApplicationUidPromotePutRequest
 */
export interface ApplicationsApiPromoteAppApplicationApplicationUidPromotePutRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiPromoteAppApplicationApplicationUidPromotePut
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for refreshApplicationsRefreshApplicationsPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiRefreshApplicationsRefreshApplicationsPostRequest
 */
export interface ApplicationsApiRefreshApplicationsRefreshApplicationsPostRequest {
  /**
   *
   * @type {RefreshApplicationsParams}
   * @memberof ApplicationsApiRefreshApplicationsRefreshApplicationsPost
   */
  readonly refreshApplicationsParams: RefreshApplicationsParams;
}

/**
 * Request parameters for saveSetupProgressApplicationApplicationUidSetupPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiSaveSetupProgressApplicationApplicationUidSetupPostRequest
 */
export interface ApplicationsApiSaveSetupProgressApplicationApplicationUidSetupPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiSaveSetupProgressApplicationApplicationUidSetupPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {AppModelSetupProgressPayload}
   * @memberof ApplicationsApiSaveSetupProgressApplicationApplicationUidSetupPost
   */
  readonly appModelSetupProgressPayload: AppModelSetupProgressPayload;

  /**
   *
   * @type {number}
   * @memberof ApplicationsApiSaveSetupProgressApplicationApplicationUidSetupPost
   */
  readonly modelNodeUid?: number;

  /**
   *
   * @type {boolean}
   * @memberof ApplicationsApiSaveSetupProgressApplicationApplicationUidSetupPost
   */
  readonly runPreprocessing?: boolean;
}

/**
 * Request parameters for swapDatasetApplicationsApplicationUidSwapDatasetPost operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiSwapDatasetApplicationsApplicationUidSwapDatasetPostRequest
 */
export interface ApplicationsApiSwapDatasetApplicationsApplicationUidSwapDatasetPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiSwapDatasetApplicationsApplicationUidSwapDatasetPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {SwapDatasetParams}
   * @memberof ApplicationsApiSwapDatasetApplicationsApplicationUidSwapDatasetPost
   */
  readonly swapDatasetParams: SwapDatasetParams;
}

/**
 * Request parameters for unarchiveAppApplicationApplicationUidUnarchivePut operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiUnarchiveAppApplicationApplicationUidUnarchivePutRequest
 */
export interface ApplicationsApiUnarchiveAppApplicationApplicationUidUnarchivePutRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiUnarchiveAppApplicationApplicationUidUnarchivePut
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for updateApplicationApplicationsApplicationUidPut operation in ApplicationsApi.
 * @export
 * @interface ApplicationsApiUpdateApplicationApplicationsApplicationUidPutRequest
 */
export interface ApplicationsApiUpdateApplicationApplicationsApplicationUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof ApplicationsApiUpdateApplicationApplicationsApplicationUidPut
   */
  readonly applicationUid: number;

  /**
   *
   * @type {UpdateApplicationPayload}
   * @memberof ApplicationsApiUpdateApplicationApplicationsApplicationUidPut
   */
  readonly updateApplicationPayload: UpdateApplicationPayload;
}

/**
 * ApplicationsApi - object-oriented interface
 * @export
 * @class ApplicationsApi
 * @extends {BaseAPI}
 */
export class ApplicationsApi extends BaseAPI {
  /**
   * If there is a PageSplitter in the app DAG, updates it with the new config. Otherwise, create a new node and commit the PageSplitter to it.
   * @summary Add Or Modify Page Splitter
   * @param {ApplicationsApiAddOrModifyPageSplitterApplicationApplicationUidPageSplitterPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public addOrModifyPageSplitterApplicationApplicationUidPageSplitterPost(
    requestParameters: ApplicationsApiAddOrModifyPageSplitterApplicationApplicationUidPageSplitterPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .addOrModifyPageSplitterApplicationApplicationUidPageSplitterPost(
        requestParameters.applicationUid,
        requestParameters.committedOperatorPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Archive App Version
   * @param {ApplicationsApiArchiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public archiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost(
    requestParameters: ApplicationsApiArchiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .archiveAppVersionApplicationsApplicationUidAppVersionsAppVersionUidArchivePost(
        requestParameters.applicationUid,
        requestParameters.appVersionUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Archive an application. Archived applications consume a fraction of their original AU usage but cannot be modified.
   * @summary Archive Application
   * @param {ApplicationsApiArchiveApplicationApplicationApplicationUidArchivePutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public archiveApplicationApplicationApplicationUidArchivePut(
    requestParameters: ApplicationsApiArchiveApplicationApplicationApplicationUidArchivePutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .archiveApplicationApplicationApplicationUidArchivePut(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Create a new dag version for the application.
   * @summary Create App Version Route
   * @param {ApplicationsApiCreateAppVersionRouteApplicationsApplicationUidAppVersionsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public createAppVersionRouteApplicationsApplicationUidAppVersionsPost(
    requestParameters: ApplicationsApiCreateAppVersionRouteApplicationsApplicationUidAppVersionsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .createAppVersionRouteApplicationsApplicationUidAppVersionsPost(
        requestParameters.applicationUid,
        requestParameters.createAppVersionParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Application
   * @param {ApplicationsApiCreateApplicationApplicationsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public createApplicationApplicationsPost(
    requestParameters: ApplicationsApiCreateApplicationApplicationsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .createApplicationApplicationsPost(
        requestParameters.createApplicationPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create New Application With Resplit Datasources
   * @param {ApplicationsApiCreateNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public createNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost(
    requestParameters: ApplicationsApiCreateNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .createNewApplicationWithResplitDatasourcesApplicationsApplicationUidResplitDataSourcesPost(
        requestParameters.applicationUid,
        requestParameters.resplitDataSourcesRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete application by application_uid.
   * @summary Delete Application
   * @param {ApplicationsApiDeleteApplicationApplicationsApplicationUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public deleteApplicationApplicationsApplicationUidDelete(
    requestParameters: ApplicationsApiDeleteApplicationApplicationsApplicationUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .deleteApplicationApplicationsApplicationUidDelete(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Exclude an app from AU calculations. Temporary API that will be used to ignore duplicate apps until we have full featured app versioning in the platform
   * @summary Exclude In Au Usage
   * @param {ApplicationsApiExcludeInAuUsageApplicationApplicationUidAuUsageExcludePutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public excludeInAuUsageApplicationApplicationUidAuUsageExcludePut(
    requestParameters: ApplicationsApiExcludeInAuUsageApplicationApplicationUidAuUsageExcludePutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .excludeInAuUsageApplicationApplicationUidAuUsageExcludePut(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Execute Graph On Data
   * @param {ApplicationsApiExecuteGraphOnDataApplicationsApplicationUidExecuteGraphPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public executeGraphOnDataApplicationsApplicationUidExecuteGraphPost(
    requestParameters: ApplicationsApiExecuteGraphOnDataApplicationsApplicationUidExecuteGraphPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .executeGraphOnDataApplicationsApplicationUidExecuteGraphPost(
        requestParameters.applicationUid,
        requestParameters.executeGraphOnDataRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch an application\'s AU usage information
   * @summary Get App Au Usage
   * @param {ApplicationsApiGetAppAuUsageApplicationApplicationUidAuUsageGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public getAppAuUsageApplicationApplicationUidAuUsageGet(
    requestParameters: ApplicationsApiGetAppAuUsageApplicationApplicationUidAuUsageGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .getAppAuUsageApplicationApplicationUidAuUsageGet(
        requestParameters.applicationUid,
        requestParameters.showBreakdown,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Given the app progress so far, returns the template id that uniquely identifies the template or raises an error if not template is found or it isn\'t unique
   * @summary Get App Template Id
   * @param {ApplicationsApiGetAppTemplateIdApplicationTemplateIdPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public getAppTemplateIdApplicationTemplateIdPost(
    requestParameters: ApplicationsApiGetAppTemplateIdApplicationTemplateIdPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .getAppTemplateIdApplicationTemplateIdPost(
        requestParameters.appModelSetupProgressPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch application details (incl. dag) for application_uid.
   * @summary Get Application
   * @param {ApplicationsApiGetApplicationApplicationsApplicationUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public getApplicationApplicationsApplicationUidGet(
    requestParameters: ApplicationsApiGetApplicationApplicationsApplicationUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .getApplicationApplicationsApplicationUidGet(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch all application metadata (excluding possibly large op node dag).
   * @summary Get Application Count
   * @param {ApplicationsApiGetApplicationCountApplicationCountGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public getApplicationCountApplicationCountGet(
    requestParameters: ApplicationsApiGetApplicationCountApplicationCountGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .getApplicationCountApplicationCountGet(
        requestParameters.datasetUid,
        requestParameters.createdByUserUid,
        requestParameters.applicationName,
        requestParameters.queryApplicationName,
        requestParameters.includePublic,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Application Required Input Schema
   * @param {ApplicationsApiGetApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public getApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGet(
    requestParameters: ApplicationsApiGetApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .getApplicationRequiredInputSchemaApplicationsApplicationUidRequiredInputSchemaGet(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch all application metadata (excluding possibly large op node dag).
   * @summary Get Applications
   * @param {ApplicationsApiGetApplicationsApplicationsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public getApplicationsApplicationsGet(
    requestParameters: ApplicationsApiGetApplicationsApplicationsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .getApplicationsApplicationsGet(
        requestParameters.datasetUid,
        requestParameters.createdByUserUid,
        requestParameters.applicationName,
        requestParameters.queryApplicationName,
        requestParameters.includePublic,
        requestParameters.pageSize,
        requestParameters.pageNum,
        requestParameters.workspaceUid,
        requestParameters.sortColumn,
        requestParameters.sortDirection,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Given the app progress so far + the current step, return the next settings to be filled in
   * @summary Get Data Task Step Next State
   * @param {ApplicationsApiGetDataTaskStepNextStateApplicationSetupNextSettingsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public getDataTaskStepNextStateApplicationSetupNextSettingsPost(
    requestParameters: ApplicationsApiGetDataTaskStepNextStateApplicationSetupNextSettingsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .getDataTaskStepNextStateApplicationSetupNextSettingsPost(
        requestParameters.appModelSetupProgressPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Migrated Application Ground Truth
   * @param {ApplicationsApiGetMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public getMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGet(
    requestParameters: ApplicationsApiGetMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .getMigratedApplicationGroundTruthApplicationsApplicationUidMigratedGroundTruthGet(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Preprocessing Settings
   * @param {ApplicationsApiGetPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public getPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost(
    requestParameters: ApplicationsApiGetPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .getPreprocessingSettingsApplicationApplicationUidSetupPreprocessingSettingsPost(
        requestParameters.applicationUid,
        requestParameters.appModelSetupProgressPayload,
        requestParameters.workspaceUid,
        requestParameters.modelUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Return the app setup progress
   * @summary Get Setup Progress
   * @param {ApplicationsApiGetSetupProgressApplicationApplicationUidSetupGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public getSetupProgressApplicationApplicationUidSetupGet(
    requestParameters: ApplicationsApiGetSetupProgressApplicationApplicationUidSetupGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .getSetupProgressApplicationApplicationUidSetupGet(
        requestParameters.applicationUid,
        requestParameters.modelNodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Include an app in AU calculations.
   * @summary Include In Au Usage
   * @param {ApplicationsApiIncludeInAuUsageApplicationApplicationUidAuUsageIncludePutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public includeInAuUsageApplicationApplicationUidAuUsageIncludePut(
    requestParameters: ApplicationsApiIncludeInAuUsageApplicationApplicationUidAuUsageIncludePutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .includeInAuUsageApplicationApplicationUidAuUsageIncludePut(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List App Versions Route
   * @param {ApplicationsApiListAppVersionsRouteApplicationsApplicationUidAppVersionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public listAppVersionsRouteApplicationsApplicationUidAppVersionsGet(
    requestParameters: ApplicationsApiListAppVersionsRouteApplicationsApplicationUidAppVersionsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .listAppVersionsRouteApplicationsApplicationUidAppVersionsGet(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Load a dag version for the application.
   * @summary Load App Version Route
   * @param {ApplicationsApiLoadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public loadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost(
    requestParameters: ApplicationsApiLoadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .loadAppVersionRouteApplicationsApplicationUidAppVersionsAppVersionUidLoadPost(
        requestParameters.applicationUid,
        requestParameters.appVersionUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Kick off preprocessing of the optionally of the train sample, and of the whole dataset - preprocessing on train sample depends on ingestion for dataset being done - preprocessing on whole dataset should come after the preprocessing on train sample   if create_train_sample = True, otherwise it only depends on dataset ingestion
   * @summary Preprocess App Data
   * @param {ApplicationsApiPreprocessAppDataApplicationApplicationUidPreprocessPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public preprocessAppDataApplicationApplicationUidPreprocessPost(
    requestParameters: ApplicationsApiPreprocessAppDataApplicationApplicationUidPreprocessPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .preprocessAppDataApplicationApplicationUidPreprocessPost(
        requestParameters.applicationUid,
        requestParameters.modelNodeUid,
        requestParameters.createTrainSample,
        requestParameters.importAssets,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Promote and application from Lab to Standard.
   * @summary Promote App
   * @param {ApplicationsApiPromoteAppApplicationApplicationUidPromotePutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public promoteAppApplicationApplicationUidPromotePut(
    requestParameters: ApplicationsApiPromoteAppApplicationApplicationUidPromotePutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .promoteAppApplicationApplicationUidPromotePut(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Refresh Applications
   * @param {ApplicationsApiRefreshApplicationsRefreshApplicationsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public refreshApplicationsRefreshApplicationsPost(
    requestParameters: ApplicationsApiRefreshApplicationsRefreshApplicationsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .refreshApplicationsRefreshApplicationsPost(
        requestParameters.refreshApplicationsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Saves the progress the user has made in the setup process
   * @summary Save Setup Progress
   * @param {ApplicationsApiSaveSetupProgressApplicationApplicationUidSetupPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public saveSetupProgressApplicationApplicationUidSetupPost(
    requestParameters: ApplicationsApiSaveSetupProgressApplicationApplicationUidSetupPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .saveSetupProgressApplicationApplicationUidSetupPost(
        requestParameters.applicationUid,
        requestParameters.appModelSetupProgressPayload,
        requestParameters.modelNodeUid,
        requestParameters.runPreprocessing,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Swap Dataset
   * @param {ApplicationsApiSwapDatasetApplicationsApplicationUidSwapDatasetPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public swapDatasetApplicationsApplicationUidSwapDatasetPost(
    requestParameters: ApplicationsApiSwapDatasetApplicationsApplicationUidSwapDatasetPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .swapDatasetApplicationsApplicationUidSwapDatasetPost(
        requestParameters.applicationUid,
        requestParameters.swapDatasetParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Archive an application. Archived applications consume a fraction of their original AU usage but cannot be modified.
   * @summary Unarchive App
   * @param {ApplicationsApiUnarchiveAppApplicationApplicationUidUnarchivePutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public unarchiveAppApplicationApplicationUidUnarchivePut(
    requestParameters: ApplicationsApiUnarchiveAppApplicationApplicationUidUnarchivePutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .unarchiveAppApplicationApplicationUidUnarchivePut(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Update specific mutable properites of the Application (like the name)
   * @summary Update Application
   * @param {ApplicationsApiUpdateApplicationApplicationsApplicationUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationsApi
   */
  public updateApplicationApplicationsApplicationUidPut(
    requestParameters: ApplicationsApiUpdateApplicationApplicationsApplicationUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ApplicationsApiFp(this.configuration)
      .updateApplicationApplicationsApplicationUidPut(
        requestParameters.applicationUid,
        requestParameters.updateApplicationPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AuditLogsApi - axios parameter creator
 * @export
 */
export const AuditLogsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Audit Logs
     * @param {number} [limit]
     * @param {number} [lastId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuditLogsAuditLogsGet: async (
      limit?: number,
      lastId?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/audit-logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (lastId !== undefined) {
        localVarQueryParameter['last_id'] = lastId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuditLogsApi - functional programming interface
 * @export
 */
export const AuditLogsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AuditLogsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Audit Logs
     * @param {number} [limit]
     * @param {number} [lastId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAuditLogsAuditLogsGet(
      limit?: number,
      lastId?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAuditLogsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAuditLogsAuditLogsGet(
          limit,
          lastId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['AuditLogsApi.getAuditLogsAuditLogsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AuditLogsApi - factory interface
 * @export
 */
export const AuditLogsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuditLogsApiFp(configuration);
  return {
    /**
     *
     * @summary Get Audit Logs
     * @param {AuditLogsApiGetAuditLogsAuditLogsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuditLogsAuditLogsGet(
      requestParameters: AuditLogsApiGetAuditLogsAuditLogsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetAuditLogsResponse> {
      return localVarFp
        .getAuditLogsAuditLogsGet(
          requestParameters.limit,
          requestParameters.lastId,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getAuditLogsAuditLogsGet operation in AuditLogsApi.
 * @export
 * @interface AuditLogsApiGetAuditLogsAuditLogsGetRequest
 */
export interface AuditLogsApiGetAuditLogsAuditLogsGetRequest {
  /**
   *
   * @type {number}
   * @memberof AuditLogsApiGetAuditLogsAuditLogsGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof AuditLogsApiGetAuditLogsAuditLogsGet
   */
  readonly lastId?: number;
}

/**
 * AuditLogsApi - object-oriented interface
 * @export
 * @class AuditLogsApi
 * @extends {BaseAPI}
 */
export class AuditLogsApi extends BaseAPI {
  /**
   *
   * @summary Get Audit Logs
   * @param {AuditLogsApiGetAuditLogsAuditLogsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditLogsApi
   */
  public getAuditLogsAuditLogsGet(
    requestParameters: AuditLogsApiGetAuditLogsAuditLogsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return AuditLogsApiFp(this.configuration)
      .getAuditLogsAuditLogsGet(
        requestParameters.limit,
        requestParameters.lastId,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * BackupApi - axios parameter creator
 * @export
 */
export const BackupApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Backups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackupsBackupsGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/backups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary On Demand Backup
     * @param {OnDemandBackupRequest} [onDemandBackupRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onDemandBackupOnDemandBackupPost: async (
      onDemandBackupRequest?: OnDemandBackupRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/on-demand-backup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        onDemandBackupRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Restore From Backup
     * @param {RestoreBackupRequest} restoreBackupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreFromBackupRestoreBackupPost: async (
      restoreBackupRequest: RestoreBackupRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'restoreBackupRequest' is not null or undefined
      assertParamExists(
        'restoreFromBackupRestoreBackupPost',
        'restoreBackupRequest',
        restoreBackupRequest,
      );
      const localVarPath = `/restore-backup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        restoreBackupRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BackupApi - functional programming interface
 * @export
 */
export const BackupApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BackupApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Backups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBackupsBackupsGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Backup>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBackupsBackupsGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['BackupApi.getBackupsBackupsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary On Demand Backup
     * @param {OnDemandBackupRequest} [onDemandBackupRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async onDemandBackupOnDemandBackupPost(
      onDemandBackupRequest?: OnDemandBackupRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.onDemandBackupOnDemandBackupPost(
          onDemandBackupRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['BackupApi.onDemandBackupOnDemandBackupPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Restore From Backup
     * @param {RestoreBackupRequest} restoreBackupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restoreFromBackupRestoreBackupPost(
      restoreBackupRequest: RestoreBackupRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.restoreFromBackupRestoreBackupPost(
          restoreBackupRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['BackupApi.restoreFromBackupRestoreBackupPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * BackupApi - factory interface
 * @export
 */
export const BackupApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BackupApiFp(configuration);
  return {
    /**
     *
     * @summary Get Backups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackupsBackupsGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<Backup>> {
      return localVarFp
        .getBackupsBackupsGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary On Demand Backup
     * @param {BackupApiOnDemandBackupOnDemandBackupPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onDemandBackupOnDemandBackupPost(
      requestParameters: BackupApiOnDemandBackupOnDemandBackupPostRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<string> {
      return localVarFp
        .onDemandBackupOnDemandBackupPost(
          requestParameters.onDemandBackupRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Restore From Backup
     * @param {BackupApiRestoreFromBackupRestoreBackupPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreFromBackupRestoreBackupPost(
      requestParameters: BackupApiRestoreFromBackupRestoreBackupPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<string> {
      return localVarFp
        .restoreFromBackupRestoreBackupPost(
          requestParameters.restoreBackupRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for onDemandBackupOnDemandBackupPost operation in BackupApi.
 * @export
 * @interface BackupApiOnDemandBackupOnDemandBackupPostRequest
 */
export interface BackupApiOnDemandBackupOnDemandBackupPostRequest {
  /**
   *
   * @type {OnDemandBackupRequest}
   * @memberof BackupApiOnDemandBackupOnDemandBackupPost
   */
  readonly onDemandBackupRequest?: OnDemandBackupRequest;
}

/**
 * Request parameters for restoreFromBackupRestoreBackupPost operation in BackupApi.
 * @export
 * @interface BackupApiRestoreFromBackupRestoreBackupPostRequest
 */
export interface BackupApiRestoreFromBackupRestoreBackupPostRequest {
  /**
   *
   * @type {RestoreBackupRequest}
   * @memberof BackupApiRestoreFromBackupRestoreBackupPost
   */
  readonly restoreBackupRequest: RestoreBackupRequest;
}

/**
 * BackupApi - object-oriented interface
 * @export
 * @class BackupApi
 * @extends {BaseAPI}
 */
export class BackupApi extends BaseAPI {
  /**
   *
   * @summary Get Backups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackupApi
   */
  public getBackupsBackupsGet(options?: RawAxiosRequestConfig) {
    return BackupApiFp(this.configuration)
      .getBackupsBackupsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary On Demand Backup
   * @param {BackupApiOnDemandBackupOnDemandBackupPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackupApi
   */
  public onDemandBackupOnDemandBackupPost(
    requestParameters: BackupApiOnDemandBackupOnDemandBackupPostRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return BackupApiFp(this.configuration)
      .onDemandBackupOnDemandBackupPost(
        requestParameters.onDemandBackupRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Restore From Backup
   * @param {BackupApiRestoreFromBackupRestoreBackupPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BackupApi
   */
  public restoreFromBackupRestoreBackupPost(
    requestParameters: BackupApiRestoreFromBackupRestoreBackupPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BackupApiFp(this.configuration)
      .restoreFromBackupRestoreBackupPost(
        requestParameters.restoreBackupRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * BatchesApi - axios parameter creator
 * @export
 */
export const BatchesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Assignments
     * @param {number} batchUid
     * @param {BatchAssignmentParams} batchAssignmentParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAssignmentsBatchesBatchUidAssignmentsPost: async (
      batchUid: number,
      batchAssignmentParams: BatchAssignmentParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'batchUid' is not null or undefined
      assertParamExists(
        'addAssignmentsBatchesBatchUidAssignmentsPost',
        'batchUid',
        batchUid,
      );
      // verify required parameter 'batchAssignmentParams' is not null or undefined
      assertParamExists(
        'addAssignmentsBatchesBatchUidAssignmentsPost',
        'batchAssignmentParams',
        batchAssignmentParams,
      );
      const localVarPath = `/batches/{batch_uid}/assignments`.replace(
        `{${'batch_uid'}}`,
        encodeURIComponent(String(batchUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        batchAssignmentParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add Batch
     * @param {number} nodeUid
     * @param {CreateBatchParams} createBatchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBatchNodesNodeUidBatchPost: async (
      nodeUid: number,
      createBatchParams: CreateBatchParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('addBatchNodesNodeUidBatchPost', 'nodeUid', nodeUid);
      // verify required parameter 'createBatchParams' is not null or undefined
      assertParamExists(
        'addBatchNodesNodeUidBatchPost',
        'createBatchParams',
        createBatchParams,
      );
      const localVarPath = `/nodes/{node_uid}/batch`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createBatchParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Bulk Delete Batch
     * @param {DeleteBatchParams} deleteBatchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkDeleteBatchBatchesDelete: async (
      deleteBatchParams: DeleteBatchParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteBatchParams' is not null or undefined
      assertParamExists(
        'bulkDeleteBatchBatchesDelete',
        'deleteBatchParams',
        deleteBatchParams,
      );
      const localVarPath = `/batches`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteBatchParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Batch
     * @param {number} batchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBatchBatchesBatchUidDelete: async (
      batchUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'batchUid' is not null or undefined
      assertParamExists(
        'deleteBatchBatchesBatchUidDelete',
        'batchUid',
        batchUid,
      );
      const localVarPath = `/batches/{batch_uid}`.replace(
        `{${'batch_uid'}}`,
        encodeURIComponent(String(batchUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Annotator Statistics
     * @param {number} batchUid
     * @param {number} annotatorUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet: async (
      batchUid: number,
      annotatorUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'batchUid' is not null or undefined
      assertParamExists(
        'getAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet',
        'batchUid',
        batchUid,
      );
      // verify required parameter 'annotatorUid' is not null or undefined
      assertParamExists(
        'getAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet',
        'annotatorUid',
        annotatorUid,
      );
      const localVarPath = `/batches/{batch_uid}/annotators/{annotator_uid}`
        .replace(`{${'batch_uid'}}`, encodeURIComponent(String(batchUid)))
        .replace(
          `{${'annotator_uid'}}`,
          encodeURIComponent(String(annotatorUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Batch
     * @param {number} batchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchBatchesBatchUidGet: async (
      batchUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'batchUid' is not null or undefined
      assertParamExists('getBatchBatchesBatchUidGet', 'batchUid', batchUid);
      const localVarPath = `/batches/{batch_uid}`.replace(
        `{${'batch_uid'}}`,
        encodeURIComponent(String(batchUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Batch Map
     * @param {number} nodeUid
     * @param {GetBatchMapRequest} getBatchMapRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchMapNodesNodeUidGetBatchMapPost: async (
      nodeUid: number,
      getBatchMapRequest: GetBatchMapRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getBatchMapNodesNodeUidGetBatchMapPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'getBatchMapRequest' is not null or undefined
      assertParamExists(
        'getBatchMapNodesNodeUidGetBatchMapPost',
        'getBatchMapRequest',
        getBatchMapRequest,
      );
      const localVarPath = `/nodes/{node_uid}/get-batch-map`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getBatchMapRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Batch X Uids
     * @param {number} batchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchXUidsBatchesBatchUidXUidsGet: async (
      batchUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'batchUid' is not null or undefined
      assertParamExists(
        'getBatchXUidsBatchesBatchUidXUidsGet',
        'batchUid',
        batchUid,
      );
      const localVarPath = `/batches/{batch_uid}/x-uids`.replace(
        `{${'batch_uid'}}`,
        encodeURIComponent(String(batchUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Batches
     * @param {number} [userUid]
     * @param {number} [assigneeUid]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchesBatchesGet: async (
      userUid?: number,
      assigneeUid?: number,
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/batches`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      if (assigneeUid !== undefined) {
        localVarQueryParameter['assignee_uid'] = assigneeUid;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Batches For Node
     * @param {number} nodeUid
     * @param {number} [userUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchesForNodeNodesNodeUidBatchesGet: async (
      nodeUid: number,
      userUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getBatchesForNodeNodesNodeUidBatchesGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/batches`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Source Size
     * @param {number} nodeUid
     * @param {string} [sourceType]
     * @param {string} [sourceUid]
     * @param {boolean} [sampleByDocs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSourceSizeNodesNodeUidBatchesSourceSizeGet: async (
      nodeUid: number,
      sourceType?: string,
      sourceUid?: string,
      sampleByDocs?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getSourceSizeNodesNodeUidBatchesSourceSizeGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/batches/source-size`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sourceType !== undefined) {
        localVarQueryParameter['source_type'] = sourceType;
      }

      if (sourceUid !== undefined) {
        localVarQueryParameter['source_uid'] = sourceUid;
      }

      if (sampleByDocs !== undefined) {
        localVarQueryParameter['sample_by_docs'] = sampleByDocs;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove Assignments
     * @param {number} batchUid
     * @param {BatchAssignmentParams} batchAssignmentParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeAssignmentsBatchesBatchUidAssignmentsDelete: async (
      batchUid: number,
      batchAssignmentParams: BatchAssignmentParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'batchUid' is not null or undefined
      assertParamExists(
        'removeAssignmentsBatchesBatchUidAssignmentsDelete',
        'batchUid',
        batchUid,
      );
      // verify required parameter 'batchAssignmentParams' is not null or undefined
      assertParamExists(
        'removeAssignmentsBatchesBatchUidAssignmentsDelete',
        'batchAssignmentParams',
        batchAssignmentParams,
      );
      const localVarPath = `/batches/{batch_uid}/assignments`.replace(
        `{${'batch_uid'}}`,
        encodeURIComponent(String(batchUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        batchAssignmentParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Batch
     * @param {number} batchUid
     * @param {UpdateBatchParams} updateBatchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBatchBatchesBatchUidPut: async (
      batchUid: number,
      updateBatchParams: UpdateBatchParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'batchUid' is not null or undefined
      assertParamExists('updateBatchBatchesBatchUidPut', 'batchUid', batchUid);
      // verify required parameter 'updateBatchParams' is not null or undefined
      assertParamExists(
        'updateBatchBatchesBatchUidPut',
        'updateBatchParams',
        updateBatchParams,
      );
      const localVarPath = `/batches/{batch_uid}`.replace(
        `{${'batch_uid'}}`,
        encodeURIComponent(String(batchUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateBatchParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BatchesApi - functional programming interface
 * @export
 */
export const BatchesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BatchesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Assignments
     * @param {number} batchUid
     * @param {BatchAssignmentParams} batchAssignmentParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addAssignmentsBatchesBatchUidAssignmentsPost(
      batchUid: number,
      batchAssignmentParams: BatchAssignmentParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HydratedBatch>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addAssignmentsBatchesBatchUidAssignmentsPost(
          batchUid,
          batchAssignmentParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'BatchesApi.addAssignmentsBatchesBatchUidAssignmentsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Add Batch
     * @param {number} nodeUid
     * @param {CreateBatchParams} createBatchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addBatchNodesNodeUidBatchPost(
      nodeUid: number,
      createBatchParams: CreateBatchParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateBatchResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addBatchNodesNodeUidBatchPost(
          nodeUid,
          createBatchParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['BatchesApi.addBatchNodesNodeUidBatchPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Bulk Delete Batch
     * @param {DeleteBatchParams} deleteBatchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkDeleteBatchBatchesDelete(
      deleteBatchParams: DeleteBatchParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.bulkDeleteBatchBatchesDelete(
          deleteBatchParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['BatchesApi.bulkDeleteBatchBatchesDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Batch
     * @param {number} batchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBatchBatchesBatchUidDelete(
      batchUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteBatchBatchesBatchUidDelete(
          batchUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['BatchesApi.deleteBatchBatchesBatchUidDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Annotator Statistics
     * @param {number} batchUid
     * @param {number} annotatorUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet(
      batchUid: number,
      annotatorUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AnnotatorStatistics>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet(
          batchUid,
          annotatorUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'BatchesApi.getAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Batch
     * @param {number} batchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchBatchesBatchUidGet(
      batchUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HydratedBatch>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchBatchesBatchUidGet(
          batchUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['BatchesApi.getBatchBatchesBatchUidGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Batch Map
     * @param {number} nodeUid
     * @param {GetBatchMapRequest} getBatchMapRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchMapNodesNodeUidGetBatchMapPost(
      nodeUid: number,
      getBatchMapRequest: GetBatchMapRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetBatchMapElement>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchMapNodesNodeUidGetBatchMapPost(
          nodeUid,
          getBatchMapRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'BatchesApi.getBatchMapNodesNodeUidGetBatchMapPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Batch X Uids
     * @param {number} batchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchXUidsBatchesBatchUidXUidsGet(
      batchUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BatchXUIDResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchXUidsBatchesBatchUidXUidsGet(
          batchUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['BatchesApi.getBatchXUidsBatchesBatchUidXUidsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Batches
     * @param {number} [userUid]
     * @param {number} [assigneeUid]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchesBatchesGet(
      userUid?: number,
      assigneeUid?: number,
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetBatchesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchesBatchesGet(
          userUid,
          assigneeUid,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['BatchesApi.getBatchesBatchesGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Batches For Node
     * @param {number} nodeUid
     * @param {number} [userUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchesForNodeNodesNodeUidBatchesGet(
      nodeUid: number,
      userUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetBatchesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchesForNodeNodesNodeUidBatchesGet(
          nodeUid,
          userUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'BatchesApi.getBatchesForNodeNodesNodeUidBatchesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Source Size
     * @param {number} nodeUid
     * @param {string} [sourceType]
     * @param {string} [sourceUid]
     * @param {boolean} [sampleByDocs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSourceSizeNodesNodeUidBatchesSourceSizeGet(
      nodeUid: number,
      sourceType?: string,
      sourceUid?: string,
      sampleByDocs?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSourceSizeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSourceSizeNodesNodeUidBatchesSourceSizeGet(
          nodeUid,
          sourceType,
          sourceUid,
          sampleByDocs,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'BatchesApi.getSourceSizeNodesNodeUidBatchesSourceSizeGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Remove Assignments
     * @param {number} batchUid
     * @param {BatchAssignmentParams} batchAssignmentParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeAssignmentsBatchesBatchUidAssignmentsDelete(
      batchUid: number,
      batchAssignmentParams: BatchAssignmentParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HydratedBatch>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeAssignmentsBatchesBatchUidAssignmentsDelete(
          batchUid,
          batchAssignmentParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'BatchesApi.removeAssignmentsBatchesBatchUidAssignmentsDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Batch
     * @param {number} batchUid
     * @param {UpdateBatchParams} updateBatchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBatchBatchesBatchUidPut(
      batchUid: number,
      updateBatchParams: UpdateBatchParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HydratedBatch>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateBatchBatchesBatchUidPut(
          batchUid,
          updateBatchParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['BatchesApi.updateBatchBatchesBatchUidPut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * BatchesApi - factory interface
 * @export
 */
export const BatchesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BatchesApiFp(configuration);
  return {
    /**
     *
     * @summary Add Assignments
     * @param {BatchesApiAddAssignmentsBatchesBatchUidAssignmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAssignmentsBatchesBatchUidAssignmentsPost(
      requestParameters: BatchesApiAddAssignmentsBatchesBatchUidAssignmentsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HydratedBatch> {
      return localVarFp
        .addAssignmentsBatchesBatchUidAssignmentsPost(
          requestParameters.batchUid,
          requestParameters.batchAssignmentParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Add Batch
     * @param {BatchesApiAddBatchNodesNodeUidBatchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBatchNodesNodeUidBatchPost(
      requestParameters: BatchesApiAddBatchNodesNodeUidBatchPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateBatchResponse> {
      return localVarFp
        .addBatchNodesNodeUidBatchPost(
          requestParameters.nodeUid,
          requestParameters.createBatchParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Bulk Delete Batch
     * @param {BatchesApiBulkDeleteBatchBatchesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkDeleteBatchBatchesDelete(
      requestParameters: BatchesApiBulkDeleteBatchBatchesDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .bulkDeleteBatchBatchesDelete(
          requestParameters.deleteBatchParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Batch
     * @param {BatchesApiDeleteBatchBatchesBatchUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBatchBatchesBatchUidDelete(
      requestParameters: BatchesApiDeleteBatchBatchesBatchUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteBatchBatchesBatchUidDelete(requestParameters.batchUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Annotator Statistics
     * @param {BatchesApiGetAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet(
      requestParameters: BatchesApiGetAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AnnotatorStatistics> {
      return localVarFp
        .getAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet(
          requestParameters.batchUid,
          requestParameters.annotatorUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Batch
     * @param {BatchesApiGetBatchBatchesBatchUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchBatchesBatchUidGet(
      requestParameters: BatchesApiGetBatchBatchesBatchUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HydratedBatch> {
      return localVarFp
        .getBatchBatchesBatchUidGet(requestParameters.batchUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Batch Map
     * @param {BatchesApiGetBatchMapNodesNodeUidGetBatchMapPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchMapNodesNodeUidGetBatchMapPost(
      requestParameters: BatchesApiGetBatchMapNodesNodeUidGetBatchMapPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<GetBatchMapElement>> {
      return localVarFp
        .getBatchMapNodesNodeUidGetBatchMapPost(
          requestParameters.nodeUid,
          requestParameters.getBatchMapRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Batch X Uids
     * @param {BatchesApiGetBatchXUidsBatchesBatchUidXUidsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchXUidsBatchesBatchUidXUidsGet(
      requestParameters: BatchesApiGetBatchXUidsBatchesBatchUidXUidsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<BatchXUIDResponse> {
      return localVarFp
        .getBatchXUidsBatchesBatchUidXUidsGet(
          requestParameters.batchUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Batches
     * @param {BatchesApiGetBatchesBatchesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchesBatchesGet(
      requestParameters: BatchesApiGetBatchesBatchesGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetBatchesResponse> {
      return localVarFp
        .getBatchesBatchesGet(
          requestParameters.userUid,
          requestParameters.assigneeUid,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Batches For Node
     * @param {BatchesApiGetBatchesForNodeNodesNodeUidBatchesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchesForNodeNodesNodeUidBatchesGet(
      requestParameters: BatchesApiGetBatchesForNodeNodesNodeUidBatchesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetBatchesResponse> {
      return localVarFp
        .getBatchesForNodeNodesNodeUidBatchesGet(
          requestParameters.nodeUid,
          requestParameters.userUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Source Size
     * @param {BatchesApiGetSourceSizeNodesNodeUidBatchesSourceSizeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSourceSizeNodesNodeUidBatchesSourceSizeGet(
      requestParameters: BatchesApiGetSourceSizeNodesNodeUidBatchesSourceSizeGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetSourceSizeResponse> {
      return localVarFp
        .getSourceSizeNodesNodeUidBatchesSourceSizeGet(
          requestParameters.nodeUid,
          requestParameters.sourceType,
          requestParameters.sourceUid,
          requestParameters.sampleByDocs,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Remove Assignments
     * @param {BatchesApiRemoveAssignmentsBatchesBatchUidAssignmentsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeAssignmentsBatchesBatchUidAssignmentsDelete(
      requestParameters: BatchesApiRemoveAssignmentsBatchesBatchUidAssignmentsDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HydratedBatch> {
      return localVarFp
        .removeAssignmentsBatchesBatchUidAssignmentsDelete(
          requestParameters.batchUid,
          requestParameters.batchAssignmentParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Batch
     * @param {BatchesApiUpdateBatchBatchesBatchUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBatchBatchesBatchUidPut(
      requestParameters: BatchesApiUpdateBatchBatchesBatchUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HydratedBatch> {
      return localVarFp
        .updateBatchBatchesBatchUidPut(
          requestParameters.batchUid,
          requestParameters.updateBatchParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addAssignmentsBatchesBatchUidAssignmentsPost operation in BatchesApi.
 * @export
 * @interface BatchesApiAddAssignmentsBatchesBatchUidAssignmentsPostRequest
 */
export interface BatchesApiAddAssignmentsBatchesBatchUidAssignmentsPostRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiAddAssignmentsBatchesBatchUidAssignmentsPost
   */
  readonly batchUid: number;

  /**
   *
   * @type {BatchAssignmentParams}
   * @memberof BatchesApiAddAssignmentsBatchesBatchUidAssignmentsPost
   */
  readonly batchAssignmentParams: BatchAssignmentParams;
}

/**
 * Request parameters for addBatchNodesNodeUidBatchPost operation in BatchesApi.
 * @export
 * @interface BatchesApiAddBatchNodesNodeUidBatchPostRequest
 */
export interface BatchesApiAddBatchNodesNodeUidBatchPostRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiAddBatchNodesNodeUidBatchPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {CreateBatchParams}
   * @memberof BatchesApiAddBatchNodesNodeUidBatchPost
   */
  readonly createBatchParams: CreateBatchParams;
}

/**
 * Request parameters for bulkDeleteBatchBatchesDelete operation in BatchesApi.
 * @export
 * @interface BatchesApiBulkDeleteBatchBatchesDeleteRequest
 */
export interface BatchesApiBulkDeleteBatchBatchesDeleteRequest {
  /**
   *
   * @type {DeleteBatchParams}
   * @memberof BatchesApiBulkDeleteBatchBatchesDelete
   */
  readonly deleteBatchParams: DeleteBatchParams;
}

/**
 * Request parameters for deleteBatchBatchesBatchUidDelete operation in BatchesApi.
 * @export
 * @interface BatchesApiDeleteBatchBatchesBatchUidDeleteRequest
 */
export interface BatchesApiDeleteBatchBatchesBatchUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiDeleteBatchBatchesBatchUidDelete
   */
  readonly batchUid: number;
}

/**
 * Request parameters for getAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet operation in BatchesApi.
 * @export
 * @interface BatchesApiGetAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGetRequest
 */
export interface BatchesApiGetAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiGetAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet
   */
  readonly batchUid: number;

  /**
   *
   * @type {number}
   * @memberof BatchesApiGetAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet
   */
  readonly annotatorUid: number;
}

/**
 * Request parameters for getBatchBatchesBatchUidGet operation in BatchesApi.
 * @export
 * @interface BatchesApiGetBatchBatchesBatchUidGetRequest
 */
export interface BatchesApiGetBatchBatchesBatchUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiGetBatchBatchesBatchUidGet
   */
  readonly batchUid: number;
}

/**
 * Request parameters for getBatchMapNodesNodeUidGetBatchMapPost operation in BatchesApi.
 * @export
 * @interface BatchesApiGetBatchMapNodesNodeUidGetBatchMapPostRequest
 */
export interface BatchesApiGetBatchMapNodesNodeUidGetBatchMapPostRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiGetBatchMapNodesNodeUidGetBatchMapPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {GetBatchMapRequest}
   * @memberof BatchesApiGetBatchMapNodesNodeUidGetBatchMapPost
   */
  readonly getBatchMapRequest: GetBatchMapRequest;
}

/**
 * Request parameters for getBatchXUidsBatchesBatchUidXUidsGet operation in BatchesApi.
 * @export
 * @interface BatchesApiGetBatchXUidsBatchesBatchUidXUidsGetRequest
 */
export interface BatchesApiGetBatchXUidsBatchesBatchUidXUidsGetRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiGetBatchXUidsBatchesBatchUidXUidsGet
   */
  readonly batchUid: number;
}

/**
 * Request parameters for getBatchesBatchesGet operation in BatchesApi.
 * @export
 * @interface BatchesApiGetBatchesBatchesGetRequest
 */
export interface BatchesApiGetBatchesBatchesGetRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiGetBatchesBatchesGet
   */
  readonly userUid?: number;

  /**
   *
   * @type {number}
   * @memberof BatchesApiGetBatchesBatchesGet
   */
  readonly assigneeUid?: number;

  /**
   *
   * @type {number}
   * @memberof BatchesApiGetBatchesBatchesGet
   */
  readonly workspaceUid?: number;
}

/**
 * Request parameters for getBatchesForNodeNodesNodeUidBatchesGet operation in BatchesApi.
 * @export
 * @interface BatchesApiGetBatchesForNodeNodesNodeUidBatchesGetRequest
 */
export interface BatchesApiGetBatchesForNodeNodesNodeUidBatchesGetRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiGetBatchesForNodeNodesNodeUidBatchesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof BatchesApiGetBatchesForNodeNodesNodeUidBatchesGet
   */
  readonly userUid?: number;
}

/**
 * Request parameters for getSourceSizeNodesNodeUidBatchesSourceSizeGet operation in BatchesApi.
 * @export
 * @interface BatchesApiGetSourceSizeNodesNodeUidBatchesSourceSizeGetRequest
 */
export interface BatchesApiGetSourceSizeNodesNodeUidBatchesSourceSizeGetRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiGetSourceSizeNodesNodeUidBatchesSourceSizeGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof BatchesApiGetSourceSizeNodesNodeUidBatchesSourceSizeGet
   */
  readonly sourceType?: string;

  /**
   *
   * @type {string}
   * @memberof BatchesApiGetSourceSizeNodesNodeUidBatchesSourceSizeGet
   */
  readonly sourceUid?: string;

  /**
   *
   * @type {boolean}
   * @memberof BatchesApiGetSourceSizeNodesNodeUidBatchesSourceSizeGet
   */
  readonly sampleByDocs?: boolean;
}

/**
 * Request parameters for removeAssignmentsBatchesBatchUidAssignmentsDelete operation in BatchesApi.
 * @export
 * @interface BatchesApiRemoveAssignmentsBatchesBatchUidAssignmentsDeleteRequest
 */
export interface BatchesApiRemoveAssignmentsBatchesBatchUidAssignmentsDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiRemoveAssignmentsBatchesBatchUidAssignmentsDelete
   */
  readonly batchUid: number;

  /**
   *
   * @type {BatchAssignmentParams}
   * @memberof BatchesApiRemoveAssignmentsBatchesBatchUidAssignmentsDelete
   */
  readonly batchAssignmentParams: BatchAssignmentParams;
}

/**
 * Request parameters for updateBatchBatchesBatchUidPut operation in BatchesApi.
 * @export
 * @interface BatchesApiUpdateBatchBatchesBatchUidPutRequest
 */
export interface BatchesApiUpdateBatchBatchesBatchUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof BatchesApiUpdateBatchBatchesBatchUidPut
   */
  readonly batchUid: number;

  /**
   *
   * @type {UpdateBatchParams}
   * @memberof BatchesApiUpdateBatchBatchesBatchUidPut
   */
  readonly updateBatchParams: UpdateBatchParams;
}

/**
 * BatchesApi - object-oriented interface
 * @export
 * @class BatchesApi
 * @extends {BaseAPI}
 */
export class BatchesApi extends BaseAPI {
  /**
   *
   * @summary Add Assignments
   * @param {BatchesApiAddAssignmentsBatchesBatchUidAssignmentsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public addAssignmentsBatchesBatchUidAssignmentsPost(
    requestParameters: BatchesApiAddAssignmentsBatchesBatchUidAssignmentsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .addAssignmentsBatchesBatchUidAssignmentsPost(
        requestParameters.batchUid,
        requestParameters.batchAssignmentParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add Batch
   * @param {BatchesApiAddBatchNodesNodeUidBatchPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public addBatchNodesNodeUidBatchPost(
    requestParameters: BatchesApiAddBatchNodesNodeUidBatchPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .addBatchNodesNodeUidBatchPost(
        requestParameters.nodeUid,
        requestParameters.createBatchParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Bulk Delete Batch
   * @param {BatchesApiBulkDeleteBatchBatchesDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public bulkDeleteBatchBatchesDelete(
    requestParameters: BatchesApiBulkDeleteBatchBatchesDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .bulkDeleteBatchBatchesDelete(
        requestParameters.deleteBatchParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Batch
   * @param {BatchesApiDeleteBatchBatchesBatchUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public deleteBatchBatchesBatchUidDelete(
    requestParameters: BatchesApiDeleteBatchBatchesBatchUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .deleteBatchBatchesBatchUidDelete(requestParameters.batchUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Annotator Statistics
   * @param {BatchesApiGetAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public getAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet(
    requestParameters: BatchesApiGetAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .getAnnotatorStatisticsBatchesBatchUidAnnotatorsAnnotatorUidGet(
        requestParameters.batchUid,
        requestParameters.annotatorUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Batch
   * @param {BatchesApiGetBatchBatchesBatchUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public getBatchBatchesBatchUidGet(
    requestParameters: BatchesApiGetBatchBatchesBatchUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .getBatchBatchesBatchUidGet(requestParameters.batchUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Batch Map
   * @param {BatchesApiGetBatchMapNodesNodeUidGetBatchMapPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public getBatchMapNodesNodeUidGetBatchMapPost(
    requestParameters: BatchesApiGetBatchMapNodesNodeUidGetBatchMapPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .getBatchMapNodesNodeUidGetBatchMapPost(
        requestParameters.nodeUid,
        requestParameters.getBatchMapRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Batch X Uids
   * @param {BatchesApiGetBatchXUidsBatchesBatchUidXUidsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public getBatchXUidsBatchesBatchUidXUidsGet(
    requestParameters: BatchesApiGetBatchXUidsBatchesBatchUidXUidsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .getBatchXUidsBatchesBatchUidXUidsGet(requestParameters.batchUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Batches
   * @param {BatchesApiGetBatchesBatchesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public getBatchesBatchesGet(
    requestParameters: BatchesApiGetBatchesBatchesGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .getBatchesBatchesGet(
        requestParameters.userUid,
        requestParameters.assigneeUid,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Batches For Node
   * @param {BatchesApiGetBatchesForNodeNodesNodeUidBatchesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public getBatchesForNodeNodesNodeUidBatchesGet(
    requestParameters: BatchesApiGetBatchesForNodeNodesNodeUidBatchesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .getBatchesForNodeNodesNodeUidBatchesGet(
        requestParameters.nodeUid,
        requestParameters.userUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Source Size
   * @param {BatchesApiGetSourceSizeNodesNodeUidBatchesSourceSizeGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public getSourceSizeNodesNodeUidBatchesSourceSizeGet(
    requestParameters: BatchesApiGetSourceSizeNodesNodeUidBatchesSourceSizeGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .getSourceSizeNodesNodeUidBatchesSourceSizeGet(
        requestParameters.nodeUid,
        requestParameters.sourceType,
        requestParameters.sourceUid,
        requestParameters.sampleByDocs,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Remove Assignments
   * @param {BatchesApiRemoveAssignmentsBatchesBatchUidAssignmentsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public removeAssignmentsBatchesBatchUidAssignmentsDelete(
    requestParameters: BatchesApiRemoveAssignmentsBatchesBatchUidAssignmentsDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .removeAssignmentsBatchesBatchUidAssignmentsDelete(
        requestParameters.batchUid,
        requestParameters.batchAssignmentParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Batch
   * @param {BatchesApiUpdateBatchBatchesBatchUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public updateBatchBatchesBatchUidPut(
    requestParameters: BatchesApiUpdateBatchBatchesBatchUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BatchesApiFp(this.configuration)
      .updateBatchBatchesBatchUidPut(
        requestParameters.batchUid,
        requestParameters.updateBatchParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * BlocksApi - axios parameter creator
 * @export
 */
export const BlocksApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Fetch the provided template from the list of defined application templates, parse it to determine its structure, add the specified Nodes to the DAG, then add a block that references this new group of Nodes.
     * @summary Create Node Block
     * @param {number} applicationUid
     * @param {CreateNodeBlockPayload} createNodeBlockPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNodeBlockApplicationsApplicationUidBlocksPost: async (
      applicationUid: number,
      createNodeBlockPayload: CreateNodeBlockPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'createNodeBlockApplicationsApplicationUidBlocksPost',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'createNodeBlockPayload' is not null or undefined
      assertParamExists(
        'createNodeBlockApplicationsApplicationUidBlocksPost',
        'createNodeBlockPayload',
        createNodeBlockPayload,
      );
      const localVarPath = `/applications/{application_uid}/blocks`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createNodeBlockPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Operator Blocks
     * @param {number} applicationUid
     * @param {number} blockUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete: async (
      applicationUid: number,
      blockUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'deleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'blockUid' is not null or undefined
      assertParamExists(
        'deleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete',
        'blockUid',
        blockUid,
      );
      const localVarPath = `/applications/{application_uid}/blocks/{block_uid}`
        .replace(
          `{${'application_uid'}}`,
          encodeURIComponent(String(applicationUid)),
        )
        .replace(`{${'block_uid'}}`, encodeURIComponent(String(blockUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Operator Block
     * @param {number} applicationUid
     * @param {number} blockUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet: async (
      applicationUid: number,
      blockUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'fetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'blockUid' is not null or undefined
      assertParamExists(
        'fetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet',
        'blockUid',
        blockUid,
      );
      const localVarPath = `/applications/{application_uid}/blocks/{block_uid}`
        .replace(
          `{${'application_uid'}}`,
          encodeURIComponent(String(applicationUid)),
        )
        .replace(`{${'block_uid'}}`, encodeURIComponent(String(blockUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Operator Blocks
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOperatorBlocksApplicationsApplicationUidBlocksGet: async (
      applicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'fetchOperatorBlocksApplicationsApplicationUidBlocksGet',
        'applicationUid',
        applicationUid,
      );
      const localVarPath = `/applications/{application_uid}/blocks`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Operator Block
     * @param {number} applicationUid
     * @param {number} blockUid
     * @param {PatchBlockPayload} patchBlockPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch: async (
      applicationUid: number,
      blockUid: number,
      patchBlockPayload: PatchBlockPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'blockUid' is not null or undefined
      assertParamExists(
        'patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch',
        'blockUid',
        blockUid,
      );
      // verify required parameter 'patchBlockPayload' is not null or undefined
      assertParamExists(
        'patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch',
        'patchBlockPayload',
        patchBlockPayload,
      );
      const localVarPath = `/applications/{application_uid}/blocks/{block_uid}`
        .replace(
          `{${'application_uid'}}`,
          encodeURIComponent(String(applicationUid)),
        )
        .replace(`{${'block_uid'}}`, encodeURIComponent(String(blockUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchBlockPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BlocksApi - functional programming interface
 * @export
 */
export const BlocksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BlocksApiAxiosParamCreator(configuration);
  return {
    /**
     * Fetch the provided template from the list of defined application templates, parse it to determine its structure, add the specified Nodes to the DAG, then add a block that references this new group of Nodes.
     * @summary Create Node Block
     * @param {number} applicationUid
     * @param {CreateNodeBlockPayload} createNodeBlockPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNodeBlockApplicationsApplicationUidBlocksPost(
      applicationUid: number,
      createNodeBlockPayload: CreateNodeBlockPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateNodeBlockResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createNodeBlockApplicationsApplicationUidBlocksPost(
          applicationUid,
          createNodeBlockPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'BlocksApi.createNodeBlockApplicationsApplicationUidBlocksPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Operator Blocks
     * @param {number} applicationUid
     * @param {number} blockUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete(
      applicationUid: number,
      blockUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete(
          applicationUid,
          blockUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'BlocksApi.deleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Operator Block
     * @param {number} applicationUid
     * @param {number} blockUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet(
      applicationUid: number,
      blockUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeBlock>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet(
          applicationUid,
          blockUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'BlocksApi.fetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Operator Blocks
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchOperatorBlocksApplicationsApplicationUidBlocksGet(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<NodeBlock>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchOperatorBlocksApplicationsApplicationUidBlocksGet(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'BlocksApi.fetchOperatorBlocksApplicationsApplicationUidBlocksGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Operator Block
     * @param {number} applicationUid
     * @param {number} blockUid
     * @param {PatchBlockPayload} patchBlockPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch(
      applicationUid: number,
      blockUid: number,
      patchBlockPayload: PatchBlockPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeBlock>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch(
          applicationUid,
          blockUid,
          patchBlockPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'BlocksApi.patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * BlocksApi - factory interface
 * @export
 */
export const BlocksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BlocksApiFp(configuration);
  return {
    /**
     * Fetch the provided template from the list of defined application templates, parse it to determine its structure, add the specified Nodes to the DAG, then add a block that references this new group of Nodes.
     * @summary Create Node Block
     * @param {BlocksApiCreateNodeBlockApplicationsApplicationUidBlocksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNodeBlockApplicationsApplicationUidBlocksPost(
      requestParameters: BlocksApiCreateNodeBlockApplicationsApplicationUidBlocksPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateNodeBlockResponse> {
      return localVarFp
        .createNodeBlockApplicationsApplicationUidBlocksPost(
          requestParameters.applicationUid,
          requestParameters.createNodeBlockPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Operator Blocks
     * @param {BlocksApiDeleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete(
      requestParameters: BlocksApiDeleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete(
          requestParameters.applicationUid,
          requestParameters.blockUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Operator Block
     * @param {BlocksApiFetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet(
      requestParameters: BlocksApiFetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<NodeBlock> {
      return localVarFp
        .fetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet(
          requestParameters.applicationUid,
          requestParameters.blockUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Operator Blocks
     * @param {BlocksApiFetchOperatorBlocksApplicationsApplicationUidBlocksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOperatorBlocksApplicationsApplicationUidBlocksGet(
      requestParameters: BlocksApiFetchOperatorBlocksApplicationsApplicationUidBlocksGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<NodeBlock>> {
      return localVarFp
        .fetchOperatorBlocksApplicationsApplicationUidBlocksGet(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Operator Block
     * @param {BlocksApiPatchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch(
      requestParameters: BlocksApiPatchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<NodeBlock> {
      return localVarFp
        .patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch(
          requestParameters.applicationUid,
          requestParameters.blockUid,
          requestParameters.patchBlockPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createNodeBlockApplicationsApplicationUidBlocksPost operation in BlocksApi.
 * @export
 * @interface BlocksApiCreateNodeBlockApplicationsApplicationUidBlocksPostRequest
 */
export interface BlocksApiCreateNodeBlockApplicationsApplicationUidBlocksPostRequest {
  /**
   *
   * @type {number}
   * @memberof BlocksApiCreateNodeBlockApplicationsApplicationUidBlocksPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {CreateNodeBlockPayload}
   * @memberof BlocksApiCreateNodeBlockApplicationsApplicationUidBlocksPost
   */
  readonly createNodeBlockPayload: CreateNodeBlockPayload;
}

/**
 * Request parameters for deleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete operation in BlocksApi.
 * @export
 * @interface BlocksApiDeleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDeleteRequest
 */
export interface BlocksApiDeleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof BlocksApiDeleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof BlocksApiDeleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete
   */
  readonly blockUid: number;
}

/**
 * Request parameters for fetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet operation in BlocksApi.
 * @export
 * @interface BlocksApiFetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGetRequest
 */
export interface BlocksApiFetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof BlocksApiFetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof BlocksApiFetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet
   */
  readonly blockUid: number;
}

/**
 * Request parameters for fetchOperatorBlocksApplicationsApplicationUidBlocksGet operation in BlocksApi.
 * @export
 * @interface BlocksApiFetchOperatorBlocksApplicationsApplicationUidBlocksGetRequest
 */
export interface BlocksApiFetchOperatorBlocksApplicationsApplicationUidBlocksGetRequest {
  /**
   *
   * @type {number}
   * @memberof BlocksApiFetchOperatorBlocksApplicationsApplicationUidBlocksGet
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch operation in BlocksApi.
 * @export
 * @interface BlocksApiPatchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatchRequest
 */
export interface BlocksApiPatchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatchRequest {
  /**
   *
   * @type {number}
   * @memberof BlocksApiPatchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof BlocksApiPatchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch
   */
  readonly blockUid: number;

  /**
   *
   * @type {PatchBlockPayload}
   * @memberof BlocksApiPatchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch
   */
  readonly patchBlockPayload: PatchBlockPayload;
}

/**
 * BlocksApi - object-oriented interface
 * @export
 * @class BlocksApi
 * @extends {BaseAPI}
 */
export class BlocksApi extends BaseAPI {
  /**
   * Fetch the provided template from the list of defined application templates, parse it to determine its structure, add the specified Nodes to the DAG, then add a block that references this new group of Nodes.
   * @summary Create Node Block
   * @param {BlocksApiCreateNodeBlockApplicationsApplicationUidBlocksPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlocksApi
   */
  public createNodeBlockApplicationsApplicationUidBlocksPost(
    requestParameters: BlocksApiCreateNodeBlockApplicationsApplicationUidBlocksPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BlocksApiFp(this.configuration)
      .createNodeBlockApplicationsApplicationUidBlocksPost(
        requestParameters.applicationUid,
        requestParameters.createNodeBlockPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Operator Blocks
   * @param {BlocksApiDeleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlocksApi
   */
  public deleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete(
    requestParameters: BlocksApiDeleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BlocksApiFp(this.configuration)
      .deleteOperatorBlocksApplicationsApplicationUidBlocksBlockUidDelete(
        requestParameters.applicationUid,
        requestParameters.blockUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Operator Block
   * @param {BlocksApiFetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlocksApi
   */
  public fetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet(
    requestParameters: BlocksApiFetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BlocksApiFp(this.configuration)
      .fetchOperatorBlockApplicationsApplicationUidBlocksBlockUidGet(
        requestParameters.applicationUid,
        requestParameters.blockUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Operator Blocks
   * @param {BlocksApiFetchOperatorBlocksApplicationsApplicationUidBlocksGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlocksApi
   */
  public fetchOperatorBlocksApplicationsApplicationUidBlocksGet(
    requestParameters: BlocksApiFetchOperatorBlocksApplicationsApplicationUidBlocksGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BlocksApiFp(this.configuration)
      .fetchOperatorBlocksApplicationsApplicationUidBlocksGet(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Operator Block
   * @param {BlocksApiPatchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlocksApi
   */
  public patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch(
    requestParameters: BlocksApiPatchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return BlocksApiFp(this.configuration)
      .patchOperatorBlockApplicationsApplicationUidBlocksBlockUidPatch(
        requestParameters.applicationUid,
        requestParameters.blockUid,
        requestParameters.patchBlockPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CommentsApi - axios parameter creator
 * @export
 */
export const CommentsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Comment
     * @param {number} nodeUid
     * @param {CreateComment} createComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentNodesNodeUidCommentsPost: async (
      nodeUid: number,
      createComment: CreateComment,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'createCommentNodesNodeUidCommentsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'createComment' is not null or undefined
      assertParamExists(
        'createCommentNodesNodeUidCommentsPost',
        'createComment',
        createComment,
      );
      const localVarPath = `/nodes/{node_uid}/comments`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createComment,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Comment
     * @param {number} nodeUid
     * @param {number} commentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommentNodesNodeUidCommentsCommentUidDelete: async (
      nodeUid: number,
      commentUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'deleteCommentNodesNodeUidCommentsCommentUidDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'commentUid' is not null or undefined
      assertParamExists(
        'deleteCommentNodesNodeUidCommentsCommentUidDelete',
        'commentUid',
        commentUid,
      );
      const localVarPath = `/nodes/{node_uid}/comments/{comment_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'comment_uid'}}`, encodeURIComponent(String(commentUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit Comment
     * @param {number} nodeUid
     * @param {number} commentUid
     * @param {UpdateComment} updateComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editCommentNodesNodeUidCommentsCommentUidPut: async (
      nodeUid: number,
      commentUid: number,
      updateComment: UpdateComment,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'editCommentNodesNodeUidCommentsCommentUidPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'commentUid' is not null or undefined
      assertParamExists(
        'editCommentNodesNodeUidCommentsCommentUidPut',
        'commentUid',
        commentUid,
      );
      // verify required parameter 'updateComment' is not null or undefined
      assertParamExists(
        'editCommentNodesNodeUidCommentsCommentUidPut',
        'updateComment',
        updateComment,
      );
      const localVarPath = `/nodes/{node_uid}/comments/{comment_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'comment_uid'}}`, encodeURIComponent(String(commentUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateComment,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export Comments
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportCommentsNodesNodeUidExportCommentsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'exportCommentsNodesNodeUidExportCommentsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/export-comments`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Comment Map
     * @param {number} nodeUid
     * @param {GetCommentMapReq} getCommentMapReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentMapNodesNodeUidGetCommentMapPost: async (
      nodeUid: number,
      getCommentMapReq: GetCommentMapReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getCommentMapNodesNodeUidGetCommentMapPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'getCommentMapReq' is not null or undefined
      assertParamExists(
        'getCommentMapNodesNodeUidGetCommentMapPost',
        'getCommentMapReq',
        getCommentMapReq,
      );
      const localVarPath = `/nodes/{node_uid}/get-comment-map`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCommentMapReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve the comment specified by pkey=(node_uid, comment_uid), returning a full comment object and raising 404 if the comment_uid does not exist
     * @summary Get Comment
     * @param {number} nodeUid
     * @param {number} commentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentNodesNodeUidCommentsCommentUidGet: async (
      nodeUid: number,
      commentUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getCommentNodesNodeUidCommentsCommentUidGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'commentUid' is not null or undefined
      assertParamExists(
        'getCommentNodesNodeUidCommentsCommentUidGet',
        'commentUid',
        commentUid,
      );
      const localVarPath = `/nodes/{node_uid}/comments/{comment_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'comment_uid'}}`, encodeURIComponent(String(commentUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import Comments
     * @param {number} nodeUid
     * @param {ImportComments} importComments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importCommentsNodesNodeUidImportCommentsPost: async (
      nodeUid: number,
      importComments: ImportComments,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'importCommentsNodesNodeUidImportCommentsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'importComments' is not null or undefined
      assertParamExists(
        'importCommentsNodesNodeUidImportCommentsPost',
        'importComments',
        importComments,
      );
      const localVarPath = `/nodes/{node_uid}/import-comments`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        importComments,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Comments
     * @param {number} nodeUid
     * @param {number} [userUid]
     * @param {string} [body]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCommentsNodesNodeUidCommentsGet: async (
      nodeUid: number,
      userUid?: number,
      body?: string,
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listCommentsNodesNodeUidCommentsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/comments`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      if (body !== undefined) {
        localVarQueryParameter['body'] = body;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommentsApi - functional programming interface
 * @export
 */
export const CommentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Comment
     * @param {number} nodeUid
     * @param {CreateComment} createComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCommentNodesNodeUidCommentsPost(
      nodeUid: number,
      createComment: CreateComment,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createCommentNodesNodeUidCommentsPost(
          nodeUid,
          createComment,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CommentsApi.createCommentNodesNodeUidCommentsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Comment
     * @param {number} nodeUid
     * @param {number} commentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCommentNodesNodeUidCommentsCommentUidDelete(
      nodeUid: number,
      commentUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteCommentNodesNodeUidCommentsCommentUidDelete(
          nodeUid,
          commentUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CommentsApi.deleteCommentNodesNodeUidCommentsCommentUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Edit Comment
     * @param {number} nodeUid
     * @param {number} commentUid
     * @param {UpdateComment} updateComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editCommentNodesNodeUidCommentsCommentUidPut(
      nodeUid: number,
      commentUid: number,
      updateComment: UpdateComment,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.editCommentNodesNodeUidCommentsCommentUidPut(
          nodeUid,
          commentUid,
          updateComment,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CommentsApi.editCommentNodesNodeUidCommentsCommentUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Export Comments
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportCommentsNodesNodeUidExportCommentsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Comment>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportCommentsNodesNodeUidExportCommentsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CommentsApi.exportCommentsNodesNodeUidExportCommentsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Comment Map
     * @param {number} nodeUid
     * @param {GetCommentMapReq} getCommentMapReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommentMapNodesNodeUidGetCommentMapPost(
      nodeUid: number,
      getCommentMapReq: GetCommentMapReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetTaskCommentMapElement>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommentMapNodesNodeUidGetCommentMapPost(
          nodeUid,
          getCommentMapReq,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CommentsApi.getCommentMapNodesNodeUidGetCommentMapPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Retrieve the comment specified by pkey=(node_uid, comment_uid), returning a full comment object and raising 404 if the comment_uid does not exist
     * @summary Get Comment
     * @param {number} nodeUid
     * @param {number} commentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommentNodesNodeUidCommentsCommentUidGet(
      nodeUid: number,
      commentUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommentNodesNodeUidCommentsCommentUidGet(
          nodeUid,
          commentUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CommentsApi.getCommentNodesNodeUidCommentsCommentUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Import Comments
     * @param {number} nodeUid
     * @param {ImportComments} importComments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importCommentsNodesNodeUidImportCommentsPost(
      nodeUid: number,
      importComments: ImportComments,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ImportCommentsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.importCommentsNodesNodeUidImportCommentsPost(
          nodeUid,
          importComments,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CommentsApi.importCommentsNodesNodeUidImportCommentsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Comments
     * @param {number} nodeUid
     * @param {number} [userUid]
     * @param {string} [body]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCommentsNodesNodeUidCommentsGet(
      nodeUid: number,
      userUid?: number,
      body?: string,
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Comment>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listCommentsNodesNodeUidCommentsGet(
          nodeUid,
          userUid,
          body,
          limit,
          offset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['CommentsApi.listCommentsNodesNodeUidCommentsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CommentsApi - factory interface
 * @export
 */
export const CommentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CommentsApiFp(configuration);
  return {
    /**
     *
     * @summary Create Comment
     * @param {CommentsApiCreateCommentNodesNodeUidCommentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentNodesNodeUidCommentsPost(
      requestParameters: CommentsApiCreateCommentNodesNodeUidCommentsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Comment> {
      return localVarFp
        .createCommentNodesNodeUidCommentsPost(
          requestParameters.nodeUid,
          requestParameters.createComment,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Comment
     * @param {CommentsApiDeleteCommentNodesNodeUidCommentsCommentUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommentNodesNodeUidCommentsCommentUidDelete(
      requestParameters: CommentsApiDeleteCommentNodesNodeUidCommentsCommentUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteCommentNodesNodeUidCommentsCommentUidDelete(
          requestParameters.nodeUid,
          requestParameters.commentUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Edit Comment
     * @param {CommentsApiEditCommentNodesNodeUidCommentsCommentUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editCommentNodesNodeUidCommentsCommentUidPut(
      requestParameters: CommentsApiEditCommentNodesNodeUidCommentsCommentUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Comment> {
      return localVarFp
        .editCommentNodesNodeUidCommentsCommentUidPut(
          requestParameters.nodeUid,
          requestParameters.commentUid,
          requestParameters.updateComment,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Export Comments
     * @param {CommentsApiExportCommentsNodesNodeUidExportCommentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportCommentsNodesNodeUidExportCommentsGet(
      requestParameters: CommentsApiExportCommentsNodesNodeUidExportCommentsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<Comment>> {
      return localVarFp
        .exportCommentsNodesNodeUidExportCommentsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Comment Map
     * @param {CommentsApiGetCommentMapNodesNodeUidGetCommentMapPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentMapNodesNodeUidGetCommentMapPost(
      requestParameters: CommentsApiGetCommentMapNodesNodeUidGetCommentMapPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<GetTaskCommentMapElement>> {
      return localVarFp
        .getCommentMapNodesNodeUidGetCommentMapPost(
          requestParameters.nodeUid,
          requestParameters.getCommentMapReq,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Retrieve the comment specified by pkey=(node_uid, comment_uid), returning a full comment object and raising 404 if the comment_uid does not exist
     * @summary Get Comment
     * @param {CommentsApiGetCommentNodesNodeUidCommentsCommentUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentNodesNodeUidCommentsCommentUidGet(
      requestParameters: CommentsApiGetCommentNodesNodeUidCommentsCommentUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Comment> {
      return localVarFp
        .getCommentNodesNodeUidCommentsCommentUidGet(
          requestParameters.nodeUid,
          requestParameters.commentUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Import Comments
     * @param {CommentsApiImportCommentsNodesNodeUidImportCommentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importCommentsNodesNodeUidImportCommentsPost(
      requestParameters: CommentsApiImportCommentsNodesNodeUidImportCommentsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ImportCommentsResponse> {
      return localVarFp
        .importCommentsNodesNodeUidImportCommentsPost(
          requestParameters.nodeUid,
          requestParameters.importComments,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Comments
     * @param {CommentsApiListCommentsNodesNodeUidCommentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCommentsNodesNodeUidCommentsGet(
      requestParameters: CommentsApiListCommentsNodesNodeUidCommentsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<Comment>> {
      return localVarFp
        .listCommentsNodesNodeUidCommentsGet(
          requestParameters.nodeUid,
          requestParameters.userUid,
          requestParameters.body,
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createCommentNodesNodeUidCommentsPost operation in CommentsApi.
 * @export
 * @interface CommentsApiCreateCommentNodesNodeUidCommentsPostRequest
 */
export interface CommentsApiCreateCommentNodesNodeUidCommentsPostRequest {
  /**
   *
   * @type {number}
   * @memberof CommentsApiCreateCommentNodesNodeUidCommentsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {CreateComment}
   * @memberof CommentsApiCreateCommentNodesNodeUidCommentsPost
   */
  readonly createComment: CreateComment;
}

/**
 * Request parameters for deleteCommentNodesNodeUidCommentsCommentUidDelete operation in CommentsApi.
 * @export
 * @interface CommentsApiDeleteCommentNodesNodeUidCommentsCommentUidDeleteRequest
 */
export interface CommentsApiDeleteCommentNodesNodeUidCommentsCommentUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof CommentsApiDeleteCommentNodesNodeUidCommentsCommentUidDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof CommentsApiDeleteCommentNodesNodeUidCommentsCommentUidDelete
   */
  readonly commentUid: number;
}

/**
 * Request parameters for editCommentNodesNodeUidCommentsCommentUidPut operation in CommentsApi.
 * @export
 * @interface CommentsApiEditCommentNodesNodeUidCommentsCommentUidPutRequest
 */
export interface CommentsApiEditCommentNodesNodeUidCommentsCommentUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof CommentsApiEditCommentNodesNodeUidCommentsCommentUidPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof CommentsApiEditCommentNodesNodeUidCommentsCommentUidPut
   */
  readonly commentUid: number;

  /**
   *
   * @type {UpdateComment}
   * @memberof CommentsApiEditCommentNodesNodeUidCommentsCommentUidPut
   */
  readonly updateComment: UpdateComment;
}

/**
 * Request parameters for exportCommentsNodesNodeUidExportCommentsGet operation in CommentsApi.
 * @export
 * @interface CommentsApiExportCommentsNodesNodeUidExportCommentsGetRequest
 */
export interface CommentsApiExportCommentsNodesNodeUidExportCommentsGetRequest {
  /**
   *
   * @type {number}
   * @memberof CommentsApiExportCommentsNodesNodeUidExportCommentsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for getCommentMapNodesNodeUidGetCommentMapPost operation in CommentsApi.
 * @export
 * @interface CommentsApiGetCommentMapNodesNodeUidGetCommentMapPostRequest
 */
export interface CommentsApiGetCommentMapNodesNodeUidGetCommentMapPostRequest {
  /**
   *
   * @type {number}
   * @memberof CommentsApiGetCommentMapNodesNodeUidGetCommentMapPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {GetCommentMapReq}
   * @memberof CommentsApiGetCommentMapNodesNodeUidGetCommentMapPost
   */
  readonly getCommentMapReq: GetCommentMapReq;
}

/**
 * Request parameters for getCommentNodesNodeUidCommentsCommentUidGet operation in CommentsApi.
 * @export
 * @interface CommentsApiGetCommentNodesNodeUidCommentsCommentUidGetRequest
 */
export interface CommentsApiGetCommentNodesNodeUidCommentsCommentUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof CommentsApiGetCommentNodesNodeUidCommentsCommentUidGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof CommentsApiGetCommentNodesNodeUidCommentsCommentUidGet
   */
  readonly commentUid: number;
}

/**
 * Request parameters for importCommentsNodesNodeUidImportCommentsPost operation in CommentsApi.
 * @export
 * @interface CommentsApiImportCommentsNodesNodeUidImportCommentsPostRequest
 */
export interface CommentsApiImportCommentsNodesNodeUidImportCommentsPostRequest {
  /**
   *
   * @type {number}
   * @memberof CommentsApiImportCommentsNodesNodeUidImportCommentsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ImportComments}
   * @memberof CommentsApiImportCommentsNodesNodeUidImportCommentsPost
   */
  readonly importComments: ImportComments;
}

/**
 * Request parameters for listCommentsNodesNodeUidCommentsGet operation in CommentsApi.
 * @export
 * @interface CommentsApiListCommentsNodesNodeUidCommentsGetRequest
 */
export interface CommentsApiListCommentsNodesNodeUidCommentsGetRequest {
  /**
   *
   * @type {number}
   * @memberof CommentsApiListCommentsNodesNodeUidCommentsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof CommentsApiListCommentsNodesNodeUidCommentsGet
   */
  readonly userUid?: number;

  /**
   *
   * @type {string}
   * @memberof CommentsApiListCommentsNodesNodeUidCommentsGet
   */
  readonly body?: string;

  /**
   *
   * @type {number}
   * @memberof CommentsApiListCommentsNodesNodeUidCommentsGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof CommentsApiListCommentsNodesNodeUidCommentsGet
   */
  readonly offset?: number;
}

/**
 * CommentsApi - object-oriented interface
 * @export
 * @class CommentsApi
 * @extends {BaseAPI}
 */
export class CommentsApi extends BaseAPI {
  /**
   *
   * @summary Create Comment
   * @param {CommentsApiCreateCommentNodesNodeUidCommentsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApi
   */
  public createCommentNodesNodeUidCommentsPost(
    requestParameters: CommentsApiCreateCommentNodesNodeUidCommentsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CommentsApiFp(this.configuration)
      .createCommentNodesNodeUidCommentsPost(
        requestParameters.nodeUid,
        requestParameters.createComment,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Comment
   * @param {CommentsApiDeleteCommentNodesNodeUidCommentsCommentUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApi
   */
  public deleteCommentNodesNodeUidCommentsCommentUidDelete(
    requestParameters: CommentsApiDeleteCommentNodesNodeUidCommentsCommentUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CommentsApiFp(this.configuration)
      .deleteCommentNodesNodeUidCommentsCommentUidDelete(
        requestParameters.nodeUid,
        requestParameters.commentUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Edit Comment
   * @param {CommentsApiEditCommentNodesNodeUidCommentsCommentUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApi
   */
  public editCommentNodesNodeUidCommentsCommentUidPut(
    requestParameters: CommentsApiEditCommentNodesNodeUidCommentsCommentUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CommentsApiFp(this.configuration)
      .editCommentNodesNodeUidCommentsCommentUidPut(
        requestParameters.nodeUid,
        requestParameters.commentUid,
        requestParameters.updateComment,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export Comments
   * @param {CommentsApiExportCommentsNodesNodeUidExportCommentsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApi
   */
  public exportCommentsNodesNodeUidExportCommentsGet(
    requestParameters: CommentsApiExportCommentsNodesNodeUidExportCommentsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CommentsApiFp(this.configuration)
      .exportCommentsNodesNodeUidExportCommentsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Comment Map
   * @param {CommentsApiGetCommentMapNodesNodeUidGetCommentMapPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApi
   */
  public getCommentMapNodesNodeUidGetCommentMapPost(
    requestParameters: CommentsApiGetCommentMapNodesNodeUidGetCommentMapPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CommentsApiFp(this.configuration)
      .getCommentMapNodesNodeUidGetCommentMapPost(
        requestParameters.nodeUid,
        requestParameters.getCommentMapReq,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Retrieve the comment specified by pkey=(node_uid, comment_uid), returning a full comment object and raising 404 if the comment_uid does not exist
   * @summary Get Comment
   * @param {CommentsApiGetCommentNodesNodeUidCommentsCommentUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApi
   */
  public getCommentNodesNodeUidCommentsCommentUidGet(
    requestParameters: CommentsApiGetCommentNodesNodeUidCommentsCommentUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CommentsApiFp(this.configuration)
      .getCommentNodesNodeUidCommentsCommentUidGet(
        requestParameters.nodeUid,
        requestParameters.commentUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import Comments
   * @param {CommentsApiImportCommentsNodesNodeUidImportCommentsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApi
   */
  public importCommentsNodesNodeUidImportCommentsPost(
    requestParameters: CommentsApiImportCommentsNodesNodeUidImportCommentsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CommentsApiFp(this.configuration)
      .importCommentsNodesNodeUidImportCommentsPost(
        requestParameters.nodeUid,
        requestParameters.importComments,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Comments
   * @param {CommentsApiListCommentsNodesNodeUidCommentsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApi
   */
  public listCommentsNodesNodeUidCommentsGet(
    requestParameters: CommentsApiListCommentsNodesNodeUidCommentsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CommentsApiFp(this.configuration)
      .listCommentsNodesNodeUidCommentsGet(
        requestParameters.nodeUid,
        requestParameters.userUid,
        requestParameters.body,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CpuProfilingApi - axios parameter creator
 * @export
 */
export const CpuProfilingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearProfilingCpuProfileClearPost: async (
      cPUProfilingParams: CPUProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cPUProfilingParams' is not null or undefined
      assertParamExists(
        'clearProfilingCpuProfileClearPost',
        'cPUProfilingParams',
        cPUProfilingParams,
      );
      const localVarPath = `/cpu-profile/clear`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cPUProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the Profile backtrace from the Redis
     * @summary Profile
     * @param {string} service
     * @param {boolean} [outputAsTree]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileCpuProfileGet: async (
      service: string,
      outputAsTree?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'service' is not null or undefined
      assertParamExists('profileCpuProfileGet', 'service', service);
      const localVarPath = `/cpu-profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (service !== undefined) {
        localVarQueryParameter['service'] = service;
      }

      if (outputAsTree !== undefined) {
        localVarQueryParameter['output_as_tree'] = outputAsTree;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startProfilingCpuProfileStartPost: async (
      cPUProfilingParams: CPUProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cPUProfilingParams' is not null or undefined
      assertParamExists(
        'startProfilingCpuProfileStartPost',
        'cPUProfilingParams',
        cPUProfilingParams,
      );
      const localVarPath = `/cpu-profile/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cPUProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopProfilingCpuProfileStopPost: async (
      cPUProfilingParams: CPUProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cPUProfilingParams' is not null or undefined
      assertParamExists(
        'stopProfilingCpuProfileStopPost',
        'cPUProfilingParams',
        cPUProfilingParams,
      );
      const localVarPath = `/cpu-profile/stop`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cPUProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CpuProfilingApi - functional programming interface
 * @export
 */
export const CpuProfilingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CpuProfilingApiAxiosParamCreator(configuration);
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearProfilingCpuProfileClearPost(
      cPUProfilingParams: CPUProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CPUProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.clearProfilingCpuProfileClearPost(
          cPUProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CpuProfilingApi.clearProfilingCpuProfileClearPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get the Profile backtrace from the Redis
     * @summary Profile
     * @param {string} service
     * @param {boolean} [outputAsTree]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async profileCpuProfileGet(
      service: string,
      outputAsTree?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.profileCpuProfileGet(
          service,
          outputAsTree,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['CpuProfilingApi.profileCpuProfileGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startProfilingCpuProfileStartPost(
      cPUProfilingParams: CPUProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CPUProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.startProfilingCpuProfileStartPost(
          cPUProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CpuProfilingApi.startProfilingCpuProfileStartPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {CPUProfilingParams} cPUProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopProfilingCpuProfileStopPost(
      cPUProfilingParams: CPUProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CPUProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stopProfilingCpuProfileStopPost(
          cPUProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['CpuProfilingApi.stopProfilingCpuProfileStopPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CpuProfilingApi - factory interface
 * @export
 */
export const CpuProfilingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CpuProfilingApiFp(configuration);
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {CpuProfilingApiClearProfilingCpuProfileClearPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearProfilingCpuProfileClearPost(
      requestParameters: CpuProfilingApiClearProfilingCpuProfileClearPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CPUProfilingStatusResponse> {
      return localVarFp
        .clearProfilingCpuProfileClearPost(
          requestParameters.cPUProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Get the Profile backtrace from the Redis
     * @summary Profile
     * @param {CpuProfilingApiProfileCpuProfileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileCpuProfileGet(
      requestParameters: CpuProfilingApiProfileCpuProfileGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<object> {
      return localVarFp
        .profileCpuProfileGet(
          requestParameters.service,
          requestParameters.outputAsTree,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {CpuProfilingApiStartProfilingCpuProfileStartPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startProfilingCpuProfileStartPost(
      requestParameters: CpuProfilingApiStartProfilingCpuProfileStartPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CPUProfilingStatusResponse> {
      return localVarFp
        .startProfilingCpuProfileStartPost(
          requestParameters.cPUProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {CpuProfilingApiStopProfilingCpuProfileStopPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopProfilingCpuProfileStopPost(
      requestParameters: CpuProfilingApiStopProfilingCpuProfileStopPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CPUProfilingStatusResponse> {
      return localVarFp
        .stopProfilingCpuProfileStopPost(
          requestParameters.cPUProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for clearProfilingCpuProfileClearPost operation in CpuProfilingApi.
 * @export
 * @interface CpuProfilingApiClearProfilingCpuProfileClearPostRequest
 */
export interface CpuProfilingApiClearProfilingCpuProfileClearPostRequest {
  /**
   *
   * @type {CPUProfilingParams}
   * @memberof CpuProfilingApiClearProfilingCpuProfileClearPost
   */
  readonly cPUProfilingParams: CPUProfilingParams;
}

/**
 * Request parameters for profileCpuProfileGet operation in CpuProfilingApi.
 * @export
 * @interface CpuProfilingApiProfileCpuProfileGetRequest
 */
export interface CpuProfilingApiProfileCpuProfileGetRequest {
  /**
   *
   * @type {string}
   * @memberof CpuProfilingApiProfileCpuProfileGet
   */
  readonly service: string;

  /**
   *
   * @type {boolean}
   * @memberof CpuProfilingApiProfileCpuProfileGet
   */
  readonly outputAsTree?: boolean;
}

/**
 * Request parameters for startProfilingCpuProfileStartPost operation in CpuProfilingApi.
 * @export
 * @interface CpuProfilingApiStartProfilingCpuProfileStartPostRequest
 */
export interface CpuProfilingApiStartProfilingCpuProfileStartPostRequest {
  /**
   *
   * @type {CPUProfilingParams}
   * @memberof CpuProfilingApiStartProfilingCpuProfileStartPost
   */
  readonly cPUProfilingParams: CPUProfilingParams;
}

/**
 * Request parameters for stopProfilingCpuProfileStopPost operation in CpuProfilingApi.
 * @export
 * @interface CpuProfilingApiStopProfilingCpuProfileStopPostRequest
 */
export interface CpuProfilingApiStopProfilingCpuProfileStopPostRequest {
  /**
   *
   * @type {CPUProfilingParams}
   * @memberof CpuProfilingApiStopProfilingCpuProfileStopPost
   */
  readonly cPUProfilingParams: CPUProfilingParams;
}

/**
 * CpuProfilingApi - object-oriented interface
 * @export
 * @class CpuProfilingApi
 * @extends {BaseAPI}
 */
export class CpuProfilingApi extends BaseAPI {
  /**
   * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
   * @summary Clear Profiling
   * @param {CpuProfilingApiClearProfilingCpuProfileClearPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CpuProfilingApi
   */
  public clearProfilingCpuProfileClearPost(
    requestParameters: CpuProfilingApiClearProfilingCpuProfileClearPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CpuProfilingApiFp(this.configuration)
      .clearProfilingCpuProfileClearPost(
        requestParameters.cPUProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get the Profile backtrace from the Redis
   * @summary Profile
   * @param {CpuProfilingApiProfileCpuProfileGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CpuProfilingApi
   */
  public profileCpuProfileGet(
    requestParameters: CpuProfilingApiProfileCpuProfileGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CpuProfilingApiFp(this.configuration)
      .profileCpuProfileGet(
        requestParameters.service,
        requestParameters.outputAsTree,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Start profiling
   * @summary Start Profiling
   * @param {CpuProfilingApiStartProfilingCpuProfileStartPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CpuProfilingApi
   */
  public startProfilingCpuProfileStartPost(
    requestParameters: CpuProfilingApiStartProfilingCpuProfileStartPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CpuProfilingApiFp(this.configuration)
      .startProfilingCpuProfileStartPost(
        requestParameters.cPUProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Stop profiling
   * @summary Stop Profiling
   * @param {CpuProfilingApiStopProfilingCpuProfileStopPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CpuProfilingApi
   */
  public stopProfilingCpuProfileStopPost(
    requestParameters: CpuProfilingApiStopProfilingCpuProfileStopPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CpuProfilingApiFp(this.configuration)
      .stopProfilingCpuProfileStopPost(
        requestParameters.cPUProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CustomMetricsApi - axios parameter creator
 * @export
 */
export const CustomMetricsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Custom Metrics Registered With Node
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/node/{node_uid}/custom-metrics`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomMetricsApi - functional programming interface
 * @export
 */
export const CustomMetricsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CustomMetricsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Custom Metrics Registered With Node
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CustomMetricsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CustomMetricsApi.getCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CustomMetricsApi - factory interface
 * @export
 */
export const CustomMetricsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomMetricsApiFp(configuration);
  return {
    /**
     *
     * @summary Get Custom Metrics Registered With Node
     * @param {CustomMetricsApiGetCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGet(
      requestParameters: CustomMetricsApiGetCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CustomMetricsResponse> {
      return localVarFp
        .getCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGet operation in CustomMetricsApi.
 * @export
 * @interface CustomMetricsApiGetCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGetRequest
 */
export interface CustomMetricsApiGetCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGetRequest {
  /**
   *
   * @type {number}
   * @memberof CustomMetricsApiGetCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGet
   */
  readonly nodeUid: number;
}

/**
 * CustomMetricsApi - object-oriented interface
 * @export
 * @class CustomMetricsApi
 * @extends {BaseAPI}
 */
export class CustomMetricsApi extends BaseAPI {
  /**
   *
   * @summary Get Custom Metrics Registered With Node
   * @param {CustomMetricsApiGetCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomMetricsApi
   */
  public getCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGet(
    requestParameters: CustomMetricsApiGetCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomMetricsApiFp(this.configuration)
      .getCustomMetricsRegisteredWithNodeNodeNodeUidCustomMetricsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CustomPipApi - axios parameter creator
 * @export
 */
export const CustomPipApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Custom Pip Install
     * @param {CustomPipInstallParams} customPipInstallParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customPipInstallCustomPipInstallPost: async (
      customPipInstallParams: CustomPipInstallParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customPipInstallParams' is not null or undefined
      assertParamExists(
        'customPipInstallCustomPipInstallPost',
        'customPipInstallParams',
        customPipInstallParams,
      );
      const localVarPath = `/custom-pip-install`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        customPipInstallParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Custom Pip Install Wheel
     * @param {CustomPipInstallWheelParams} customPipInstallWheelParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customPipInstallWheelCustomPipInstallWheelPost: async (
      customPipInstallWheelParams: CustomPipInstallWheelParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customPipInstallWheelParams' is not null or undefined
      assertParamExists(
        'customPipInstallWheelCustomPipInstallWheelPost',
        'customPipInstallWheelParams',
        customPipInstallWheelParams,
      );
      const localVarPath = `/custom-pip-install/wheel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        customPipInstallWheelParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Custom Pip Installs Reset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customPipInstallsResetCustomPipInstallsDelete: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/custom-pip-installs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Custom Pip Installs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCustomPipInstallsListCustomPipInstallsGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/list-custom-pip-installs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomPipApi - functional programming interface
 * @export
 */
export const CustomPipApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CustomPipApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Custom Pip Install
     * @param {CustomPipInstallParams} customPipInstallParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customPipInstallCustomPipInstallPost(
      customPipInstallParams: CustomPipInstallParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CustomPipInstallResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.customPipInstallCustomPipInstallPost(
          customPipInstallParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CustomPipApi.customPipInstallCustomPipInstallPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Custom Pip Install Wheel
     * @param {CustomPipInstallWheelParams} customPipInstallWheelParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customPipInstallWheelCustomPipInstallWheelPost(
      customPipInstallWheelParams: CustomPipInstallWheelParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.customPipInstallWheelCustomPipInstallWheelPost(
          customPipInstallWheelParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CustomPipApi.customPipInstallWheelCustomPipInstallWheelPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Custom Pip Installs Reset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customPipInstallsResetCustomPipInstallsDelete(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.customPipInstallsResetCustomPipInstallsDelete(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CustomPipApi.customPipInstallsResetCustomPipInstallsDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Custom Pip Installs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCustomPipInstallsListCustomPipInstallsGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<PipPackage>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listCustomPipInstallsListCustomPipInstallsGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'CustomPipApi.listCustomPipInstallsListCustomPipInstallsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CustomPipApi - factory interface
 * @export
 */
export const CustomPipApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomPipApiFp(configuration);
  return {
    /**
     *
     * @summary Custom Pip Install
     * @param {CustomPipApiCustomPipInstallCustomPipInstallPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customPipInstallCustomPipInstallPost(
      requestParameters: CustomPipApiCustomPipInstallCustomPipInstallPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CustomPipInstallResponse> {
      return localVarFp
        .customPipInstallCustomPipInstallPost(
          requestParameters.customPipInstallParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Custom Pip Install Wheel
     * @param {CustomPipApiCustomPipInstallWheelCustomPipInstallWheelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customPipInstallWheelCustomPipInstallWheelPost(
      requestParameters: CustomPipApiCustomPipInstallWheelCustomPipInstallWheelPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .customPipInstallWheelCustomPipInstallWheelPost(
          requestParameters.customPipInstallWheelParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Custom Pip Installs Reset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customPipInstallsResetCustomPipInstallsDelete(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .customPipInstallsResetCustomPipInstallsDelete(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Custom Pip Installs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCustomPipInstallsListCustomPipInstallsGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PipPackage>> {
      return localVarFp
        .listCustomPipInstallsListCustomPipInstallsGet(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for customPipInstallCustomPipInstallPost operation in CustomPipApi.
 * @export
 * @interface CustomPipApiCustomPipInstallCustomPipInstallPostRequest
 */
export interface CustomPipApiCustomPipInstallCustomPipInstallPostRequest {
  /**
   *
   * @type {CustomPipInstallParams}
   * @memberof CustomPipApiCustomPipInstallCustomPipInstallPost
   */
  readonly customPipInstallParams: CustomPipInstallParams;
}

/**
 * Request parameters for customPipInstallWheelCustomPipInstallWheelPost operation in CustomPipApi.
 * @export
 * @interface CustomPipApiCustomPipInstallWheelCustomPipInstallWheelPostRequest
 */
export interface CustomPipApiCustomPipInstallWheelCustomPipInstallWheelPostRequest {
  /**
   *
   * @type {CustomPipInstallWheelParams}
   * @memberof CustomPipApiCustomPipInstallWheelCustomPipInstallWheelPost
   */
  readonly customPipInstallWheelParams: CustomPipInstallWheelParams;
}

/**
 * CustomPipApi - object-oriented interface
 * @export
 * @class CustomPipApi
 * @extends {BaseAPI}
 */
export class CustomPipApi extends BaseAPI {
  /**
   *
   * @summary Custom Pip Install
   * @param {CustomPipApiCustomPipInstallCustomPipInstallPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomPipApi
   */
  public customPipInstallCustomPipInstallPost(
    requestParameters: CustomPipApiCustomPipInstallCustomPipInstallPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomPipApiFp(this.configuration)
      .customPipInstallCustomPipInstallPost(
        requestParameters.customPipInstallParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Custom Pip Install Wheel
   * @param {CustomPipApiCustomPipInstallWheelCustomPipInstallWheelPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomPipApi
   */
  public customPipInstallWheelCustomPipInstallWheelPost(
    requestParameters: CustomPipApiCustomPipInstallWheelCustomPipInstallWheelPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomPipApiFp(this.configuration)
      .customPipInstallWheelCustomPipInstallWheelPost(
        requestParameters.customPipInstallWheelParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Custom Pip Installs Reset
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomPipApi
   */
  public customPipInstallsResetCustomPipInstallsDelete(
    options?: RawAxiosRequestConfig,
  ) {
    return CustomPipApiFp(this.configuration)
      .customPipInstallsResetCustomPipInstallsDelete(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Custom Pip Installs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomPipApi
   */
  public listCustomPipInstallsListCustomPipInstallsGet(
    options?: RawAxiosRequestConfig,
  ) {
    return CustomPipApiFp(this.configuration)
      .listCustomPipInstallsListCustomPipInstallsGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DataConnectorApi - axios parameter creator
 * @export
 */
export const DataConnectorApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Activate Data Connector
     * @param {DataConnectorActivateRequest} dataConnectorActivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateDataConnectorDataConnectorsActivatePost: async (
      dataConnectorActivateRequest: DataConnectorActivateRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dataConnectorActivateRequest' is not null or undefined
      assertParamExists(
        'activateDataConnectorDataConnectorsActivatePost',
        'dataConnectorActivateRequest',
        dataConnectorActivateRequest,
      );
      const localVarPath = `/data-connectors-activate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataConnectorActivateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deactivate Data Connector
     * @param {DataConnectorActivateRequest} dataConnectorActivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateDataConnectorDataConnectorsDeactivatePost: async (
      dataConnectorActivateRequest: DataConnectorActivateRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dataConnectorActivateRequest' is not null or undefined
      assertParamExists(
        'deactivateDataConnectorDataConnectorsDeactivatePost',
        'dataConnectorActivateRequest',
        dataConnectorActivateRequest,
      );
      const localVarPath = `/data-connectors-deactivate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataConnectorActivateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Data Connector State
     * @param {DataConnector} dataConnector
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorStateDataConnectorStatesDataConnectorGet: async (
      dataConnector: DataConnector,
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dataConnector' is not null or undefined
      assertParamExists(
        'getDataConnectorStateDataConnectorStatesDataConnectorGet',
        'dataConnector',
        dataConnector,
      );
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'getDataConnectorStateDataConnectorStatesDataConnectorGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/data-connector-states/{data_connector}`.replace(
        `{${'data_connector'}}`,
        encodeURIComponent(String(dataConnector)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Data Connector State
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDataConnectorStateDataConnectorStatesGet: async (
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'listDataConnectorStateDataConnectorStatesGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/data-connector-states`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DataConnectorApi - functional programming interface
 * @export
 */
export const DataConnectorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DataConnectorApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Activate Data Connector
     * @param {DataConnectorActivateRequest} dataConnectorActivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateDataConnectorDataConnectorsActivatePost(
      dataConnectorActivateRequest: DataConnectorActivateRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.activateDataConnectorDataConnectorsActivatePost(
          dataConnectorActivateRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorApi.activateDataConnectorDataConnectorsActivatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Deactivate Data Connector
     * @param {DataConnectorActivateRequest} dataConnectorActivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deactivateDataConnectorDataConnectorsDeactivatePost(
      dataConnectorActivateRequest: DataConnectorActivateRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deactivateDataConnectorDataConnectorsDeactivatePost(
          dataConnectorActivateRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorApi.deactivateDataConnectorDataConnectorsDeactivatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Data Connector State
     * @param {DataConnector} dataConnector
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataConnectorStateDataConnectorStatesDataConnectorGet(
      dataConnector: DataConnector,
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DataConnectorStateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDataConnectorStateDataConnectorStatesDataConnectorGet(
          dataConnector,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorApi.getDataConnectorStateDataConnectorStatesDataConnectorGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Data Connector State
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listDataConnectorStateDataConnectorStatesGet(
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: DataConnectorStateResponse }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listDataConnectorStateDataConnectorStatesGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorApi.listDataConnectorStateDataConnectorStatesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DataConnectorApi - factory interface
 * @export
 */
export const DataConnectorApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DataConnectorApiFp(configuration);
  return {
    /**
     *
     * @summary Activate Data Connector
     * @param {DataConnectorApiActivateDataConnectorDataConnectorsActivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateDataConnectorDataConnectorsActivatePost(
      requestParameters: DataConnectorApiActivateDataConnectorDataConnectorsActivatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .activateDataConnectorDataConnectorsActivatePost(
          requestParameters.dataConnectorActivateRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Deactivate Data Connector
     * @param {DataConnectorApiDeactivateDataConnectorDataConnectorsDeactivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateDataConnectorDataConnectorsDeactivatePost(
      requestParameters: DataConnectorApiDeactivateDataConnectorDataConnectorsDeactivatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deactivateDataConnectorDataConnectorsDeactivatePost(
          requestParameters.dataConnectorActivateRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Data Connector State
     * @param {DataConnectorApiGetDataConnectorStateDataConnectorStatesDataConnectorGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorStateDataConnectorStatesDataConnectorGet(
      requestParameters: DataConnectorApiGetDataConnectorStateDataConnectorStatesDataConnectorGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DataConnectorStateResponse> {
      return localVarFp
        .getDataConnectorStateDataConnectorStatesDataConnectorGet(
          requestParameters.dataConnector,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Data Connector State
     * @param {DataConnectorApiListDataConnectorStateDataConnectorStatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDataConnectorStateDataConnectorStatesGet(
      requestParameters: DataConnectorApiListDataConnectorStateDataConnectorStatesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: DataConnectorStateResponse }> {
      return localVarFp
        .listDataConnectorStateDataConnectorStatesGet(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for activateDataConnectorDataConnectorsActivatePost operation in DataConnectorApi.
 * @export
 * @interface DataConnectorApiActivateDataConnectorDataConnectorsActivatePostRequest
 */
export interface DataConnectorApiActivateDataConnectorDataConnectorsActivatePostRequest {
  /**
   *
   * @type {DataConnectorActivateRequest}
   * @memberof DataConnectorApiActivateDataConnectorDataConnectorsActivatePost
   */
  readonly dataConnectorActivateRequest: DataConnectorActivateRequest;
}

/**
 * Request parameters for deactivateDataConnectorDataConnectorsDeactivatePost operation in DataConnectorApi.
 * @export
 * @interface DataConnectorApiDeactivateDataConnectorDataConnectorsDeactivatePostRequest
 */
export interface DataConnectorApiDeactivateDataConnectorDataConnectorsDeactivatePostRequest {
  /**
   *
   * @type {DataConnectorActivateRequest}
   * @memberof DataConnectorApiDeactivateDataConnectorDataConnectorsDeactivatePost
   */
  readonly dataConnectorActivateRequest: DataConnectorActivateRequest;
}

/**
 * Request parameters for getDataConnectorStateDataConnectorStatesDataConnectorGet operation in DataConnectorApi.
 * @export
 * @interface DataConnectorApiGetDataConnectorStateDataConnectorStatesDataConnectorGetRequest
 */
export interface DataConnectorApiGetDataConnectorStateDataConnectorStatesDataConnectorGetRequest {
  /**
   *
   * @type {DataConnector}
   * @memberof DataConnectorApiGetDataConnectorStateDataConnectorStatesDataConnectorGet
   */
  readonly dataConnector: DataConnector;

  /**
   *
   * @type {number}
   * @memberof DataConnectorApiGetDataConnectorStateDataConnectorStatesDataConnectorGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for listDataConnectorStateDataConnectorStatesGet operation in DataConnectorApi.
 * @export
 * @interface DataConnectorApiListDataConnectorStateDataConnectorStatesGetRequest
 */
export interface DataConnectorApiListDataConnectorStateDataConnectorStatesGetRequest {
  /**
   *
   * @type {number}
   * @memberof DataConnectorApiListDataConnectorStateDataConnectorStatesGet
   */
  readonly workspaceUid: number;
}

/**
 * DataConnectorApi - object-oriented interface
 * @export
 * @class DataConnectorApi
 * @extends {BaseAPI}
 */
export class DataConnectorApi extends BaseAPI {
  /**
   *
   * @summary Activate Data Connector
   * @param {DataConnectorApiActivateDataConnectorDataConnectorsActivatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorApi
   */
  public activateDataConnectorDataConnectorsActivatePost(
    requestParameters: DataConnectorApiActivateDataConnectorDataConnectorsActivatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorApiFp(this.configuration)
      .activateDataConnectorDataConnectorsActivatePost(
        requestParameters.dataConnectorActivateRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deactivate Data Connector
   * @param {DataConnectorApiDeactivateDataConnectorDataConnectorsDeactivatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorApi
   */
  public deactivateDataConnectorDataConnectorsDeactivatePost(
    requestParameters: DataConnectorApiDeactivateDataConnectorDataConnectorsDeactivatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorApiFp(this.configuration)
      .deactivateDataConnectorDataConnectorsDeactivatePost(
        requestParameters.dataConnectorActivateRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Data Connector State
   * @param {DataConnectorApiGetDataConnectorStateDataConnectorStatesDataConnectorGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorApi
   */
  public getDataConnectorStateDataConnectorStatesDataConnectorGet(
    requestParameters: DataConnectorApiGetDataConnectorStateDataConnectorStatesDataConnectorGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorApiFp(this.configuration)
      .getDataConnectorStateDataConnectorStatesDataConnectorGet(
        requestParameters.dataConnector,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Data Connector State
   * @param {DataConnectorApiListDataConnectorStateDataConnectorStatesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorApi
   */
  public listDataConnectorStateDataConnectorStatesGet(
    requestParameters: DataConnectorApiListDataConnectorStateDataConnectorStatesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorApiFp(this.configuration)
      .listDataConnectorStateDataConnectorStatesGet(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DataConnectorConfigApi - axios parameter creator
 * @export
 */
export const DataConnectorConfigApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Data Connector Config
     * @param {DataConnectorConfigCreationRequest} dataConnectorConfigCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataConnectorConfigDataConnectorConfigsPost: async (
      dataConnectorConfigCreationRequest: DataConnectorConfigCreationRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dataConnectorConfigCreationRequest' is not null or undefined
      assertParamExists(
        'createDataConnectorConfigDataConnectorConfigsPost',
        'dataConnectorConfigCreationRequest',
        dataConnectorConfigCreationRequest,
      );
      const localVarPath = `/data-connector-configs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataConnectorConfigCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Data Connector Config
     * @param {number} dataConnectorConfigUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDelete:
      async (
        dataConnectorConfigUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'dataConnectorConfigUid' is not null or undefined
        assertParamExists(
          'deleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDelete',
          'dataConnectorConfigUid',
          dataConnectorConfigUid,
        );
        const localVarPath =
          `/data-connector-configs/{data_connector_config_uid}`.replace(
            `{${'data_connector_config_uid'}}`,
            encodeURIComponent(String(dataConnectorConfigUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'DELETE',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Data Connector Config Data Connector Permissions
     * @param {DataConnector} dataConnector
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet:
      async (
        dataConnector: DataConnector,
        workspaceUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'dataConnector' is not null or undefined
        assertParamExists(
          'getDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet',
          'dataConnector',
          dataConnector,
        );
        // verify required parameter 'workspaceUid' is not null or undefined
        assertParamExists(
          'getDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet',
          'workspaceUid',
          workspaceUid,
        );
        const localVarPath =
          `/data-connector-configs-permissions/{data_connector}`.replace(
            `{${'data_connector'}}`,
            encodeURIComponent(String(dataConnector)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (workspaceUid !== undefined) {
          localVarQueryParameter['workspace_uid'] = workspaceUid;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Data Connector Config Permissions
     * @param {number} dataConnectorConfigUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGet:
      async (
        dataConnectorConfigUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'dataConnectorConfigUid' is not null or undefined
        assertParamExists(
          'getDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGet',
          'dataConnectorConfigUid',
          dataConnectorConfigUid,
        );
        const localVarPath =
          `/data-connector-configs/{data_connector_config_uid}/permissions`.replace(
            `{${'data_connector_config_uid'}}`,
            encodeURIComponent(String(dataConnectorConfigUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Data Connector Configuration
     * @param {number} dataConnectorConfigUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGet:
      async (
        dataConnectorConfigUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'dataConnectorConfigUid' is not null or undefined
        assertParamExists(
          'getDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGet',
          'dataConnectorConfigUid',
          dataConnectorConfigUid,
        );
        const localVarPath =
          `/data-connector-configs/{data_connector_config_uid}`.replace(
            `{${'data_connector_config_uid'}}`,
            encodeURIComponent(String(dataConnectorConfigUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Data Connector Configurations
     * @param {DataConnector} dataConnector
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorConfigurationsDataConnectorConfigsGet: async (
      dataConnector: DataConnector,
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dataConnector' is not null or undefined
      assertParamExists(
        'getDataConnectorConfigurationsDataConnectorConfigsGet',
        'dataConnector',
        dataConnector,
      );
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'getDataConnectorConfigurationsDataConnectorConfigsGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/data-connector-configs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dataConnector !== undefined) {
        localVarQueryParameter['data_connector'] = dataConnector;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Data Connector Config Data Connector Permissions
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGet:
      async (
        workspaceUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'workspaceUid' is not null or undefined
        assertParamExists(
          'listDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGet',
          'workspaceUid',
          workspaceUid,
        );
        const localVarPath = `/data-connector-configs-permissions`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (workspaceUid !== undefined) {
          localVarQueryParameter['workspace_uid'] = workspaceUid;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Update Data Connector Config
     * @param {number} dataConnectorConfigUid
     * @param {DataConnectorConfigUpdateParams} dataConnectorConfigUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut:
      async (
        dataConnectorConfigUid: number,
        dataConnectorConfigUpdateParams: DataConnectorConfigUpdateParams,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'dataConnectorConfigUid' is not null or undefined
        assertParamExists(
          'updateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut',
          'dataConnectorConfigUid',
          dataConnectorConfigUid,
        );
        // verify required parameter 'dataConnectorConfigUpdateParams' is not null or undefined
        assertParamExists(
          'updateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut',
          'dataConnectorConfigUpdateParams',
          dataConnectorConfigUpdateParams,
        );
        const localVarPath =
          `/data-connector-configs/{data_connector_config_uid}`.replace(
            `{${'data_connector_config_uid'}}`,
            encodeURIComponent(String(dataConnectorConfigUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          dataConnectorConfigUpdateParams,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * DataConnectorConfigApi - functional programming interface
 * @export
 */
export const DataConnectorConfigApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    DataConnectorConfigApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Data Connector Config
     * @param {DataConnectorConfigCreationRequest} dataConnectorConfigCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDataConnectorConfigDataConnectorConfigsPost(
      dataConnectorConfigCreationRequest: DataConnectorConfigCreationRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createDataConnectorConfigDataConnectorConfigsPost(
          dataConnectorConfigCreationRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorConfigApi.createDataConnectorConfigDataConnectorConfigsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Data Connector Config
     * @param {number} dataConnectorConfigUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDelete(
      dataConnectorConfigUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDelete(
          dataConnectorConfigUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorConfigApi.deleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Data Connector Config Data Connector Permissions
     * @param {DataConnector} dataConnector
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet(
      dataConnector: DataConnector,
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CRUDAction>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet(
          dataConnector,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorConfigApi.getDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Data Connector Config Permissions
     * @param {number} dataConnectorConfigUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGet(
      dataConnectorConfigUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CRUDAction>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGet(
          dataConnectorConfigUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorConfigApi.getDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Data Connector Configuration
     * @param {number} dataConnectorConfigUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGet(
      dataConnectorConfigUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DataConnectorConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGet(
          dataConnectorConfigUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorConfigApi.getDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Data Connector Configurations
     * @param {DataConnector} dataConnector
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataConnectorConfigurationsDataConnectorConfigsGet(
      dataConnector: DataConnector,
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DataConnectorConfig>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDataConnectorConfigurationsDataConnectorConfigsGet(
          dataConnector,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorConfigApi.getDataConnectorConfigurationsDataConnectorConfigsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Data Connector Config Data Connector Permissions
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGet(
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: any }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorConfigApi.listDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Data Connector Config
     * @param {number} dataConnectorConfigUid
     * @param {DataConnectorConfigUpdateParams} dataConnectorConfigUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut(
      dataConnectorConfigUid: number,
      dataConnectorConfigUpdateParams: DataConnectorConfigUpdateParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut(
          dataConnectorConfigUid,
          dataConnectorConfigUpdateParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorConfigApi.updateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DataConnectorConfigApi - factory interface
 * @export
 */
export const DataConnectorConfigApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DataConnectorConfigApiFp(configuration);
  return {
    /**
     *
     * @summary Create Data Connector Config
     * @param {DataConnectorConfigApiCreateDataConnectorConfigDataConnectorConfigsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataConnectorConfigDataConnectorConfigsPost(
      requestParameters: DataConnectorConfigApiCreateDataConnectorConfigDataConnectorConfigsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<number> {
      return localVarFp
        .createDataConnectorConfigDataConnectorConfigsPost(
          requestParameters.dataConnectorConfigCreationRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Data Connector Config
     * @param {DataConnectorConfigApiDeleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDelete(
      requestParameters: DataConnectorConfigApiDeleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDelete(
          requestParameters.dataConnectorConfigUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Data Connector Config Data Connector Permissions
     * @param {DataConnectorConfigApiGetDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet(
      requestParameters: DataConnectorConfigApiGetDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<CRUDAction>> {
      return localVarFp
        .getDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet(
          requestParameters.dataConnector,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Data Connector Config Permissions
     * @param {DataConnectorConfigApiGetDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGet(
      requestParameters: DataConnectorConfigApiGetDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<CRUDAction>> {
      return localVarFp
        .getDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGet(
          requestParameters.dataConnectorConfigUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Data Connector Configuration
     * @param {DataConnectorConfigApiGetDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGet(
      requestParameters: DataConnectorConfigApiGetDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DataConnectorConfig> {
      return localVarFp
        .getDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGet(
          requestParameters.dataConnectorConfigUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Data Connector Configurations
     * @param {DataConnectorConfigApiGetDataConnectorConfigurationsDataConnectorConfigsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorConfigurationsDataConnectorConfigsGet(
      requestParameters: DataConnectorConfigApiGetDataConnectorConfigurationsDataConnectorConfigsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DataConnectorConfig>> {
      return localVarFp
        .getDataConnectorConfigurationsDataConnectorConfigsGet(
          requestParameters.dataConnector,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Data Connector Config Data Connector Permissions
     * @param {DataConnectorConfigApiListDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGet(
      requestParameters: DataConnectorConfigApiListDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: any }> {
      return localVarFp
        .listDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGet(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Data Connector Config
     * @param {DataConnectorConfigApiUpdateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut(
      requestParameters: DataConnectorConfigApiUpdateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut(
          requestParameters.dataConnectorConfigUid,
          requestParameters.dataConnectorConfigUpdateParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createDataConnectorConfigDataConnectorConfigsPost operation in DataConnectorConfigApi.
 * @export
 * @interface DataConnectorConfigApiCreateDataConnectorConfigDataConnectorConfigsPostRequest
 */
export interface DataConnectorConfigApiCreateDataConnectorConfigDataConnectorConfigsPostRequest {
  /**
   *
   * @type {DataConnectorConfigCreationRequest}
   * @memberof DataConnectorConfigApiCreateDataConnectorConfigDataConnectorConfigsPost
   */
  readonly dataConnectorConfigCreationRequest: DataConnectorConfigCreationRequest;
}

/**
 * Request parameters for deleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDelete operation in DataConnectorConfigApi.
 * @export
 * @interface DataConnectorConfigApiDeleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDeleteRequest
 */
export interface DataConnectorConfigApiDeleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof DataConnectorConfigApiDeleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDelete
   */
  readonly dataConnectorConfigUid: number;
}

/**
 * Request parameters for getDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet operation in DataConnectorConfigApi.
 * @export
 * @interface DataConnectorConfigApiGetDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGetRequest
 */
export interface DataConnectorConfigApiGetDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGetRequest {
  /**
   *
   * @type {DataConnector}
   * @memberof DataConnectorConfigApiGetDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet
   */
  readonly dataConnector: DataConnector;

  /**
   *
   * @type {number}
   * @memberof DataConnectorConfigApiGetDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for getDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGet operation in DataConnectorConfigApi.
 * @export
 * @interface DataConnectorConfigApiGetDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGetRequest
 */
export interface DataConnectorConfigApiGetDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DataConnectorConfigApiGetDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGet
   */
  readonly dataConnectorConfigUid: number;
}

/**
 * Request parameters for getDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGet operation in DataConnectorConfigApi.
 * @export
 * @interface DataConnectorConfigApiGetDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGetRequest
 */
export interface DataConnectorConfigApiGetDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof DataConnectorConfigApiGetDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGet
   */
  readonly dataConnectorConfigUid: number;
}

/**
 * Request parameters for getDataConnectorConfigurationsDataConnectorConfigsGet operation in DataConnectorConfigApi.
 * @export
 * @interface DataConnectorConfigApiGetDataConnectorConfigurationsDataConnectorConfigsGetRequest
 */
export interface DataConnectorConfigApiGetDataConnectorConfigurationsDataConnectorConfigsGetRequest {
  /**
   *
   * @type {DataConnector}
   * @memberof DataConnectorConfigApiGetDataConnectorConfigurationsDataConnectorConfigsGet
   */
  readonly dataConnector: DataConnector;

  /**
   *
   * @type {number}
   * @memberof DataConnectorConfigApiGetDataConnectorConfigurationsDataConnectorConfigsGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for listDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGet operation in DataConnectorConfigApi.
 * @export
 * @interface DataConnectorConfigApiListDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGetRequest
 */
export interface DataConnectorConfigApiListDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DataConnectorConfigApiListDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for updateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut operation in DataConnectorConfigApi.
 * @export
 * @interface DataConnectorConfigApiUpdateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPutRequest
 */
export interface DataConnectorConfigApiUpdateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof DataConnectorConfigApiUpdateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut
   */
  readonly dataConnectorConfigUid: number;

  /**
   *
   * @type {DataConnectorConfigUpdateParams}
   * @memberof DataConnectorConfigApiUpdateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut
   */
  readonly dataConnectorConfigUpdateParams: DataConnectorConfigUpdateParams;
}

/**
 * DataConnectorConfigApi - object-oriented interface
 * @export
 * @class DataConnectorConfigApi
 * @extends {BaseAPI}
 */
export class DataConnectorConfigApi extends BaseAPI {
  /**
   *
   * @summary Create Data Connector Config
   * @param {DataConnectorConfigApiCreateDataConnectorConfigDataConnectorConfigsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorConfigApi
   */
  public createDataConnectorConfigDataConnectorConfigsPost(
    requestParameters: DataConnectorConfigApiCreateDataConnectorConfigDataConnectorConfigsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorConfigApiFp(this.configuration)
      .createDataConnectorConfigDataConnectorConfigsPost(
        requestParameters.dataConnectorConfigCreationRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Data Connector Config
   * @param {DataConnectorConfigApiDeleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorConfigApi
   */
  public deleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDelete(
    requestParameters: DataConnectorConfigApiDeleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorConfigApiFp(this.configuration)
      .deleteDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidDelete(
        requestParameters.dataConnectorConfigUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Data Connector Config Data Connector Permissions
   * @param {DataConnectorConfigApiGetDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorConfigApi
   */
  public getDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet(
    requestParameters: DataConnectorConfigApiGetDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorConfigApiFp(this.configuration)
      .getDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsDataConnectorGet(
        requestParameters.dataConnector,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Data Connector Config Permissions
   * @param {DataConnectorConfigApiGetDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorConfigApi
   */
  public getDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGet(
    requestParameters: DataConnectorConfigApiGetDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorConfigApiFp(this.configuration)
      .getDataConnectorConfigPermissionsDataConnectorConfigsDataConnectorConfigUidPermissionsGet(
        requestParameters.dataConnectorConfigUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Data Connector Configuration
   * @param {DataConnectorConfigApiGetDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorConfigApi
   */
  public getDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGet(
    requestParameters: DataConnectorConfigApiGetDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorConfigApiFp(this.configuration)
      .getDataConnectorConfigurationDataConnectorConfigsDataConnectorConfigUidGet(
        requestParameters.dataConnectorConfigUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Data Connector Configurations
   * @param {DataConnectorConfigApiGetDataConnectorConfigurationsDataConnectorConfigsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorConfigApi
   */
  public getDataConnectorConfigurationsDataConnectorConfigsGet(
    requestParameters: DataConnectorConfigApiGetDataConnectorConfigurationsDataConnectorConfigsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorConfigApiFp(this.configuration)
      .getDataConnectorConfigurationsDataConnectorConfigsGet(
        requestParameters.dataConnector,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Data Connector Config Data Connector Permissions
   * @param {DataConnectorConfigApiListDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorConfigApi
   */
  public listDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGet(
    requestParameters: DataConnectorConfigApiListDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorConfigApiFp(this.configuration)
      .listDataConnectorConfigDataConnectorPermissionsDataConnectorConfigsPermissionsGet(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Data Connector Config
   * @param {DataConnectorConfigApiUpdateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorConfigApi
   */
  public updateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut(
    requestParameters: DataConnectorConfigApiUpdateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorConfigApiFp(this.configuration)
      .updateDataConnectorConfigDataConnectorConfigsDataConnectorConfigUidPut(
        requestParameters.dataConnectorConfigUid,
        requestParameters.dataConnectorConfigUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DataConnectorRolesRouterApi - axios parameter creator
 * @export
 */
export const DataConnectorRolesRouterApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Data Connector Role
     * @param {DataConnectorRoleCreationRequest} dataConnectorRoleCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataConnectorRoleDataConnectorRolesPost: async (
      dataConnectorRoleCreationRequest: DataConnectorRoleCreationRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dataConnectorRoleCreationRequest' is not null or undefined
      assertParamExists(
        'createDataConnectorRoleDataConnectorRolesPost',
        'dataConnectorRoleCreationRequest',
        dataConnectorRoleCreationRequest,
      );
      const localVarPath = `/data-connector-roles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataConnectorRoleCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Data Connector Role
     * @param {number} roleUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataConnectorRoleDataConnectorRolesRoleUidDelete: async (
      roleUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'roleUid' is not null or undefined
      assertParamExists(
        'deleteDataConnectorRoleDataConnectorRolesRoleUidDelete',
        'roleUid',
        roleUid,
      );
      const localVarPath = `/data-connector-roles/{role_uid}`.replace(
        `{${'role_uid'}}`,
        encodeURIComponent(String(roleUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Data Connector Role
     * @param {number} roleUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorRoleDataConnectorRolesRoleUidGet: async (
      roleUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'roleUid' is not null or undefined
      assertParamExists(
        'getDataConnectorRoleDataConnectorRolesRoleUidGet',
        'roleUid',
        roleUid,
      );
      const localVarPath = `/data-connector-roles/{role_uid}`.replace(
        `{${'role_uid'}}`,
        encodeURIComponent(String(roleUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Data Connector Roles
     * @param {DataConnector} [dataConnector]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDataConnectorRolesDataConnectorRolesGet: async (
      dataConnector?: DataConnector,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/data-connector-roles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dataConnector !== undefined) {
        localVarQueryParameter['data_connector'] = dataConnector;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Data Connector Role
     * @param {number} roleUid
     * @param {DataConnectorRoleUpdateParams} dataConnectorRoleUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataConnectorRoleDataConnectorRolesRoleUidPut: async (
      roleUid: number,
      dataConnectorRoleUpdateParams: DataConnectorRoleUpdateParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'roleUid' is not null or undefined
      assertParamExists(
        'updateDataConnectorRoleDataConnectorRolesRoleUidPut',
        'roleUid',
        roleUid,
      );
      // verify required parameter 'dataConnectorRoleUpdateParams' is not null or undefined
      assertParamExists(
        'updateDataConnectorRoleDataConnectorRolesRoleUidPut',
        'dataConnectorRoleUpdateParams',
        dataConnectorRoleUpdateParams,
      );
      const localVarPath = `/data-connector-roles/{role_uid}`.replace(
        `{${'role_uid'}}`,
        encodeURIComponent(String(roleUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataConnectorRoleUpdateParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DataConnectorRolesRouterApi - functional programming interface
 * @export
 */
export const DataConnectorRolesRouterApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    DataConnectorRolesRouterApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Data Connector Role
     * @param {DataConnectorRoleCreationRequest} dataConnectorRoleCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDataConnectorRoleDataConnectorRolesPost(
      dataConnectorRoleCreationRequest: DataConnectorRoleCreationRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createDataConnectorRoleDataConnectorRolesPost(
          dataConnectorRoleCreationRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorRolesRouterApi.createDataConnectorRoleDataConnectorRolesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Data Connector Role
     * @param {number} roleUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDataConnectorRoleDataConnectorRolesRoleUidDelete(
      roleUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteDataConnectorRoleDataConnectorRolesRoleUidDelete(
          roleUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorRolesRouterApi.deleteDataConnectorRoleDataConnectorRolesRoleUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Data Connector Role
     * @param {number} roleUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataConnectorRoleDataConnectorRolesRoleUidGet(
      roleUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DataConnectorRole>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDataConnectorRoleDataConnectorRolesRoleUidGet(
          roleUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorRolesRouterApi.getDataConnectorRoleDataConnectorRolesRoleUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Data Connector Roles
     * @param {DataConnector} [dataConnector]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listDataConnectorRolesDataConnectorRolesGet(
      dataConnector?: DataConnector,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: any }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listDataConnectorRolesDataConnectorRolesGet(
          dataConnector,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorRolesRouterApi.listDataConnectorRolesDataConnectorRolesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Data Connector Role
     * @param {number} roleUid
     * @param {DataConnectorRoleUpdateParams} dataConnectorRoleUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDataConnectorRoleDataConnectorRolesRoleUidPut(
      roleUid: number,
      dataConnectorRoleUpdateParams: DataConnectorRoleUpdateParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDataConnectorRoleDataConnectorRolesRoleUidPut(
          roleUid,
          dataConnectorRoleUpdateParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DataConnectorRolesRouterApi.updateDataConnectorRoleDataConnectorRolesRoleUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DataConnectorRolesRouterApi - factory interface
 * @export
 */
export const DataConnectorRolesRouterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DataConnectorRolesRouterApiFp(configuration);
  return {
    /**
     *
     * @summary Create Data Connector Role
     * @param {DataConnectorRolesRouterApiCreateDataConnectorRoleDataConnectorRolesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataConnectorRoleDataConnectorRolesPost(
      requestParameters: DataConnectorRolesRouterApiCreateDataConnectorRoleDataConnectorRolesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<number> {
      return localVarFp
        .createDataConnectorRoleDataConnectorRolesPost(
          requestParameters.dataConnectorRoleCreationRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Data Connector Role
     * @param {DataConnectorRolesRouterApiDeleteDataConnectorRoleDataConnectorRolesRoleUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataConnectorRoleDataConnectorRolesRoleUidDelete(
      requestParameters: DataConnectorRolesRouterApiDeleteDataConnectorRoleDataConnectorRolesRoleUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteDataConnectorRoleDataConnectorRolesRoleUidDelete(
          requestParameters.roleUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Data Connector Role
     * @param {DataConnectorRolesRouterApiGetDataConnectorRoleDataConnectorRolesRoleUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataConnectorRoleDataConnectorRolesRoleUidGet(
      requestParameters: DataConnectorRolesRouterApiGetDataConnectorRoleDataConnectorRolesRoleUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DataConnectorRole> {
      return localVarFp
        .getDataConnectorRoleDataConnectorRolesRoleUidGet(
          requestParameters.roleUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Data Connector Roles
     * @param {DataConnectorRolesRouterApiListDataConnectorRolesDataConnectorRolesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDataConnectorRolesDataConnectorRolesGet(
      requestParameters: DataConnectorRolesRouterApiListDataConnectorRolesDataConnectorRolesGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: any }> {
      return localVarFp
        .listDataConnectorRolesDataConnectorRolesGet(
          requestParameters.dataConnector,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Data Connector Role
     * @param {DataConnectorRolesRouterApiUpdateDataConnectorRoleDataConnectorRolesRoleUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataConnectorRoleDataConnectorRolesRoleUidPut(
      requestParameters: DataConnectorRolesRouterApiUpdateDataConnectorRoleDataConnectorRolesRoleUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateDataConnectorRoleDataConnectorRolesRoleUidPut(
          requestParameters.roleUid,
          requestParameters.dataConnectorRoleUpdateParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createDataConnectorRoleDataConnectorRolesPost operation in DataConnectorRolesRouterApi.
 * @export
 * @interface DataConnectorRolesRouterApiCreateDataConnectorRoleDataConnectorRolesPostRequest
 */
export interface DataConnectorRolesRouterApiCreateDataConnectorRoleDataConnectorRolesPostRequest {
  /**
   *
   * @type {DataConnectorRoleCreationRequest}
   * @memberof DataConnectorRolesRouterApiCreateDataConnectorRoleDataConnectorRolesPost
   */
  readonly dataConnectorRoleCreationRequest: DataConnectorRoleCreationRequest;
}

/**
 * Request parameters for deleteDataConnectorRoleDataConnectorRolesRoleUidDelete operation in DataConnectorRolesRouterApi.
 * @export
 * @interface DataConnectorRolesRouterApiDeleteDataConnectorRoleDataConnectorRolesRoleUidDeleteRequest
 */
export interface DataConnectorRolesRouterApiDeleteDataConnectorRoleDataConnectorRolesRoleUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof DataConnectorRolesRouterApiDeleteDataConnectorRoleDataConnectorRolesRoleUidDelete
   */
  readonly roleUid: number;
}

/**
 * Request parameters for getDataConnectorRoleDataConnectorRolesRoleUidGet operation in DataConnectorRolesRouterApi.
 * @export
 * @interface DataConnectorRolesRouterApiGetDataConnectorRoleDataConnectorRolesRoleUidGetRequest
 */
export interface DataConnectorRolesRouterApiGetDataConnectorRoleDataConnectorRolesRoleUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof DataConnectorRolesRouterApiGetDataConnectorRoleDataConnectorRolesRoleUidGet
   */
  readonly roleUid: number;
}

/**
 * Request parameters for listDataConnectorRolesDataConnectorRolesGet operation in DataConnectorRolesRouterApi.
 * @export
 * @interface DataConnectorRolesRouterApiListDataConnectorRolesDataConnectorRolesGetRequest
 */
export interface DataConnectorRolesRouterApiListDataConnectorRolesDataConnectorRolesGetRequest {
  /**
   *
   * @type {DataConnector}
   * @memberof DataConnectorRolesRouterApiListDataConnectorRolesDataConnectorRolesGet
   */
  readonly dataConnector?: DataConnector;
}

/**
 * Request parameters for updateDataConnectorRoleDataConnectorRolesRoleUidPut operation in DataConnectorRolesRouterApi.
 * @export
 * @interface DataConnectorRolesRouterApiUpdateDataConnectorRoleDataConnectorRolesRoleUidPutRequest
 */
export interface DataConnectorRolesRouterApiUpdateDataConnectorRoleDataConnectorRolesRoleUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof DataConnectorRolesRouterApiUpdateDataConnectorRoleDataConnectorRolesRoleUidPut
   */
  readonly roleUid: number;

  /**
   *
   * @type {DataConnectorRoleUpdateParams}
   * @memberof DataConnectorRolesRouterApiUpdateDataConnectorRoleDataConnectorRolesRoleUidPut
   */
  readonly dataConnectorRoleUpdateParams: DataConnectorRoleUpdateParams;
}

/**
 * DataConnectorRolesRouterApi - object-oriented interface
 * @export
 * @class DataConnectorRolesRouterApi
 * @extends {BaseAPI}
 */
export class DataConnectorRolesRouterApi extends BaseAPI {
  /**
   *
   * @summary Create Data Connector Role
   * @param {DataConnectorRolesRouterApiCreateDataConnectorRoleDataConnectorRolesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorRolesRouterApi
   */
  public createDataConnectorRoleDataConnectorRolesPost(
    requestParameters: DataConnectorRolesRouterApiCreateDataConnectorRoleDataConnectorRolesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorRolesRouterApiFp(this.configuration)
      .createDataConnectorRoleDataConnectorRolesPost(
        requestParameters.dataConnectorRoleCreationRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Data Connector Role
   * @param {DataConnectorRolesRouterApiDeleteDataConnectorRoleDataConnectorRolesRoleUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorRolesRouterApi
   */
  public deleteDataConnectorRoleDataConnectorRolesRoleUidDelete(
    requestParameters: DataConnectorRolesRouterApiDeleteDataConnectorRoleDataConnectorRolesRoleUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorRolesRouterApiFp(this.configuration)
      .deleteDataConnectorRoleDataConnectorRolesRoleUidDelete(
        requestParameters.roleUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Data Connector Role
   * @param {DataConnectorRolesRouterApiGetDataConnectorRoleDataConnectorRolesRoleUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorRolesRouterApi
   */
  public getDataConnectorRoleDataConnectorRolesRoleUidGet(
    requestParameters: DataConnectorRolesRouterApiGetDataConnectorRoleDataConnectorRolesRoleUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorRolesRouterApiFp(this.configuration)
      .getDataConnectorRoleDataConnectorRolesRoleUidGet(
        requestParameters.roleUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Data Connector Roles
   * @param {DataConnectorRolesRouterApiListDataConnectorRolesDataConnectorRolesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorRolesRouterApi
   */
  public listDataConnectorRolesDataConnectorRolesGet(
    requestParameters: DataConnectorRolesRouterApiListDataConnectorRolesDataConnectorRolesGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorRolesRouterApiFp(this.configuration)
      .listDataConnectorRolesDataConnectorRolesGet(
        requestParameters.dataConnector,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Data Connector Role
   * @param {DataConnectorRolesRouterApiUpdateDataConnectorRoleDataConnectorRolesRoleUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConnectorRolesRouterApi
   */
  public updateDataConnectorRoleDataConnectorRolesRoleUidPut(
    requestParameters: DataConnectorRolesRouterApiUpdateDataConnectorRoleDataConnectorRolesRoleUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DataConnectorRolesRouterApiFp(this.configuration)
      .updateDataConnectorRoleDataConnectorRolesRoleUidPut(
        requestParameters.roleUid,
        requestParameters.dataConnectorRoleUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DatapointApi - axios parameter creator
 * @export
 */
export const DatapointApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Datapoints
     * @param {number} datasetUid
     * @param {string} split
     * @param {string} [filterConfigStr]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatapointsDatasetsDatasetUidDatapointsGet: async (
      datasetUid: number,
      split: string,
      filterConfigStr?: string,
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getDatapointsDatasetsDatasetUidDatapointsGet',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getDatapointsDatasetsDatasetUidDatapointsGet',
        'split',
        split,
      );
      const localVarPath = `/datasets/{dataset_uid}/datapoints`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (filterConfigStr !== undefined) {
        localVarQueryParameter['filter_config_str'] = filterConfigStr;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatapointApi - functional programming interface
 * @export
 */
export const DatapointApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DatapointApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Datapoints
     * @param {number} datasetUid
     * @param {string} split
     * @param {string} [filterConfigStr]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatapointsDatasetsDatasetUidDatapointsGet(
      datasetUid: number,
      split: string,
      filterConfigStr?: string,
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDatasetDatapointsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDatapointsDatasetsDatasetUidDatapointsGet(
          datasetUid,
          split,
          filterConfigStr,
          limit,
          offset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatapointApi.getDatapointsDatasetsDatasetUidDatapointsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DatapointApi - factory interface
 * @export
 */
export const DatapointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DatapointApiFp(configuration);
  return {
    /**
     *
     * @summary Get Datapoints
     * @param {DatapointApiGetDatapointsDatasetsDatasetUidDatapointsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatapointsDatasetsDatasetUidDatapointsGet(
      requestParameters: DatapointApiGetDatapointsDatasetsDatasetUidDatapointsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetDatasetDatapointsResponse> {
      return localVarFp
        .getDatapointsDatasetsDatasetUidDatapointsGet(
          requestParameters.datasetUid,
          requestParameters.split,
          requestParameters.filterConfigStr,
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getDatapointsDatasetsDatasetUidDatapointsGet operation in DatapointApi.
 * @export
 * @interface DatapointApiGetDatapointsDatasetsDatasetUidDatapointsGetRequest
 */
export interface DatapointApiGetDatapointsDatasetsDatasetUidDatapointsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatapointApiGetDatapointsDatasetsDatasetUidDatapointsGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {string}
   * @memberof DatapointApiGetDatapointsDatasetsDatasetUidDatapointsGet
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof DatapointApiGetDatapointsDatasetsDatasetUidDatapointsGet
   */
  readonly filterConfigStr?: string;

  /**
   *
   * @type {number}
   * @memberof DatapointApiGetDatapointsDatasetsDatasetUidDatapointsGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof DatapointApiGetDatapointsDatasetsDatasetUidDatapointsGet
   */
  readonly offset?: number;
}

/**
 * DatapointApi - object-oriented interface
 * @export
 * @class DatapointApi
 * @extends {BaseAPI}
 */
export class DatapointApi extends BaseAPI {
  /**
   *
   * @summary Get Datapoints
   * @param {DatapointApiGetDatapointsDatasetsDatasetUidDatapointsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatapointApi
   */
  public getDatapointsDatasetsDatasetUidDatapointsGet(
    requestParameters: DatapointApiGetDatapointsDatasetsDatasetUidDatapointsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatapointApiFp(this.configuration)
      .getDatapointsDatasetsDatasetUidDatapointsGet(
        requestParameters.datasetUid,
        requestParameters.split,
        requestParameters.filterConfigStr,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DatasetBatchApi - axios parameter creator
 * @export
 */
export const DatasetBatchApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Batch
     * @param {number} datasetUid
     * @param {CreateDatasetBatchPayload} createDatasetBatchPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBatchDatasetsDatasetUidBatchPost: async (
      datasetUid: number,
      createDatasetBatchPayload: CreateDatasetBatchPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'addBatchDatasetsDatasetUidBatchPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'createDatasetBatchPayload' is not null or undefined
      assertParamExists(
        'addBatchDatasetsDatasetUidBatchPost',
        'createDatasetBatchPayload',
        createDatasetBatchPayload,
      );
      const localVarPath = `/datasets/{dataset_uid}/batch`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDatasetBatchPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add Label Schemas To Batch
     * @param {number} datasetBatchUid
     * @param {AssignLabelSchemasToBatchParams} assignLabelSchemasToBatchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost: async (
      datasetBatchUid: number,
      assignLabelSchemasToBatchParams: AssignLabelSchemasToBatchParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetBatchUid' is not null or undefined
      assertParamExists(
        'addLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost',
        'datasetBatchUid',
        datasetBatchUid,
      );
      // verify required parameter 'assignLabelSchemasToBatchParams' is not null or undefined
      assertParamExists(
        'addLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost',
        'assignLabelSchemasToBatchParams',
        assignLabelSchemasToBatchParams,
      );
      const localVarPath =
        `/dataset-batches/{dataset_batch_uid}/label-schemas`.replace(
          `{${'dataset_batch_uid'}}`,
          encodeURIComponent(String(datasetBatchUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        assignLabelSchemasToBatchParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Batch
     * @param {number} datasetBatchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBatchDatasetBatchesDatasetBatchUidDelete: async (
      datasetBatchUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetBatchUid' is not null or undefined
      assertParamExists(
        'deleteBatchDatasetBatchesDatasetBatchUidDelete',
        'datasetBatchUid',
        datasetBatchUid,
      );
      const localVarPath = `/dataset-batches/{dataset_batch_uid}`.replace(
        `{${'dataset_batch_uid'}}`,
        encodeURIComponent(String(datasetBatchUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Batches
     * @param {number} datasetUid
     * @param {DeleteDatasetBatchesParams} deleteDatasetBatchesParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBatchesDatasetBatchesDelete: async (
      datasetUid: number,
      deleteDatasetBatchesParams: DeleteDatasetBatchesParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'deleteBatchesDatasetBatchesDelete',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'deleteDatasetBatchesParams' is not null or undefined
      assertParamExists(
        'deleteBatchesDatasetBatchesDelete',
        'deleteDatasetBatchesParams',
        deleteDatasetBatchesParams,
      );
      const localVarPath = `/dataset-batches`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasetUid !== undefined) {
        localVarQueryParameter['dataset_uid'] = datasetUid;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteDatasetBatchesParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export Batches
     * @param {number} datasetUid
     * @param {Array<number>} [batchUids]
     * @param {Array<number>} [labelSchemaUids]
     * @param {Array<string>} [selectedFields]
     * @param {boolean} [includeAnnotations]
     * @param {boolean} [includeGroundTruth]
     * @param {number} [startIndex]
     * @param {number} [maxDatapointsToExport]
     * @param {number} [maxCharsPerColumn]
     * @param {string} [csvDelimiter]
     * @param {string} [quoteChar]
     * @param {string} [escapeChar]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportBatchesDatasetBatchesDatasetUidExportBatchesGet: async (
      datasetUid: number,
      batchUids?: Array<number>,
      labelSchemaUids?: Array<number>,
      selectedFields?: Array<string>,
      includeAnnotations?: boolean,
      includeGroundTruth?: boolean,
      startIndex?: number,
      maxDatapointsToExport?: number,
      maxCharsPerColumn?: number,
      csvDelimiter?: string,
      quoteChar?: string,
      escapeChar?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'exportBatchesDatasetBatchesDatasetUidExportBatchesGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath =
        `/dataset-batches/{dataset_uid}/export-batches`.replace(
          `{${'dataset_uid'}}`,
          encodeURIComponent(String(datasetUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (batchUids) {
        localVarQueryParameter['batch_uids'] = batchUids;
      }

      if (labelSchemaUids) {
        localVarQueryParameter['label_schema_uids'] = labelSchemaUids;
      }

      if (selectedFields) {
        localVarQueryParameter['selected_fields'] = selectedFields;
      }

      if (includeAnnotations !== undefined) {
        localVarQueryParameter['include_annotations'] = includeAnnotations;
      }

      if (includeGroundTruth !== undefined) {
        localVarQueryParameter['include_ground_truth'] = includeGroundTruth;
      }

      if (startIndex !== undefined) {
        localVarQueryParameter['start_index'] = startIndex;
      }

      if (maxDatapointsToExport !== undefined) {
        localVarQueryParameter['max_datapoints_to_export'] =
          maxDatapointsToExport;
      }

      if (maxCharsPerColumn !== undefined) {
        localVarQueryParameter['max_chars_per_column'] = maxCharsPerColumn;
      }

      if (csvDelimiter !== undefined) {
        localVarQueryParameter['csv_delimiter'] = csvDelimiter;
      }

      if (quoteChar !== undefined) {
        localVarQueryParameter['quote_char'] = quoteChar;
      }

      if (escapeChar !== undefined) {
        localVarQueryParameter['escape_char'] = escapeChar;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Batch Data
     * @param {number} datasetBatchUid
     * @param {string} [filterConfig]
     * @param {boolean} [ascending]
     * @param {number} [limit]
     * @param {Array<string>} [groupBy]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchDataDatasetBatchesDatasetBatchUidBatchDataGet: async (
      datasetBatchUid: number,
      filterConfig?: string,
      ascending?: boolean,
      limit?: number,
      groupBy?: Array<string>,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetBatchUid' is not null or undefined
      assertParamExists(
        'getBatchDataDatasetBatchesDatasetBatchUidBatchDataGet',
        'datasetBatchUid',
        datasetBatchUid,
      );
      const localVarPath =
        `/dataset-batches/{dataset_batch_uid}/batch-data`.replace(
          `{${'dataset_batch_uid'}}`,
          encodeURIComponent(String(datasetBatchUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filterConfig !== undefined) {
        localVarQueryParameter['filter_config'] = filterConfig;
      }

      if (ascending !== undefined) {
        localVarQueryParameter['ascending'] = ascending;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (groupBy) {
        localVarQueryParameter['group_by'] = groupBy;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the first page of docs. Use this endpoint only for PDF.
     * @summary Get Batch Data Docs
     * @param {number} datasetBatchUid
     * @param {string} [filterConfig]
     * @param {boolean} [ascending]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet: async (
      datasetBatchUid: number,
      filterConfig?: string,
      ascending?: boolean,
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetBatchUid' is not null or undefined
      assertParamExists(
        'getBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet',
        'datasetBatchUid',
        datasetBatchUid,
      );
      const localVarPath =
        `/dataset-batches/{dataset_batch_uid}/batch-data/docs`.replace(
          `{${'dataset_batch_uid'}}`,
          encodeURIComponent(String(datasetBatchUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filterConfig !== undefined) {
        localVarQueryParameter['filter_config'] = filterConfig;
      }

      if (ascending !== undefined) {
        localVarQueryParameter['ascending'] = ascending;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get pages of a particular doc. Use this endpoint only for PDF.
     * @summary Get Batch Data Pages
     * @param {number} datasetBatchUid
     * @param {number} contextUid
     * @param {string} [filterConfig]
     * @param {boolean} [ascending]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet:
      async (
        datasetBatchUid: number,
        contextUid: number,
        filterConfig?: string,
        ascending?: boolean,
        limit?: number,
        offset?: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'datasetBatchUid' is not null or undefined
        assertParamExists(
          'getBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet',
          'datasetBatchUid',
          datasetBatchUid,
        );
        // verify required parameter 'contextUid' is not null or undefined
        assertParamExists(
          'getBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet',
          'contextUid',
          contextUid,
        );
        const localVarPath =
          `/dataset-batches/{dataset_batch_uid}/batch-data/docs/{context_uid}/pages`
            .replace(
              `{${'dataset_batch_uid'}}`,
              encodeURIComponent(String(datasetBatchUid)),
            )
            .replace(
              `{${'context_uid'}}`,
              encodeURIComponent(String(contextUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (filterConfig !== undefined) {
          localVarQueryParameter['filter_config'] = filterConfig;
        }

        if (ascending !== undefined) {
          localVarQueryParameter['ascending'] = ascending;
        }

        if (limit !== undefined) {
          localVarQueryParameter['limit'] = limit;
        }

        if (offset !== undefined) {
          localVarQueryParameter['offset'] = offset;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Batch
     * @param {number} datasetBatchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchDatasetBatchesDatasetBatchUidGet: async (
      datasetBatchUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetBatchUid' is not null or undefined
      assertParamExists(
        'getBatchDatasetBatchesDatasetBatchUidGet',
        'datasetBatchUid',
        datasetBatchUid,
      );
      const localVarPath = `/dataset-batches/{dataset_batch_uid}`.replace(
        `{${'dataset_batch_uid'}}`,
        encodeURIComponent(String(datasetBatchUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Batch X Uids
     * @param {number} datasetBatchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchXUidsDatasetBatchesDatasetBatchUidXUidsGet: async (
      datasetBatchUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetBatchUid' is not null or undefined
      assertParamExists(
        'getBatchXUidsDatasetBatchesDatasetBatchUidXUidsGet',
        'datasetBatchUid',
        datasetBatchUid,
      );
      const localVarPath =
        `/dataset-batches/{dataset_batch_uid}/x-uids`.replace(
          `{${'dataset_batch_uid'}}`,
          encodeURIComponent(String(datasetBatchUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Batches For Dataset
     * @param {number} datasetUid
     * @param {number} [userUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchesForDatasetDatasetsDatasetUidBatchesGet: async (
      datasetUid: number,
      userUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getBatchesForDatasetDatasetsDatasetUidBatchesGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/datasets/{dataset_uid}/batches`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Dataset Batches
     * @param {number} [datasetUid]
     * @param {number} [userUid]
     * @param {number} [workspaceUid]
     * @param {number} [assigneeUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetBatchesDatasetBatchesGet: async (
      datasetUid?: number,
      userUid?: number,
      workspaceUid?: number,
      assigneeUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dataset-batches`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasetUid !== undefined) {
        localVarQueryParameter['dataset_uid'] = datasetUid;
      }

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      if (assigneeUid !== undefined) {
        localVarQueryParameter['assignee_uid'] = assigneeUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Populated Filters Info
     * @param {number} datasetBatchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGet:
      async (
        datasetBatchUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'datasetBatchUid' is not null or undefined
        assertParamExists(
          'getPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGet',
          'datasetBatchUid',
          datasetBatchUid,
        );
        const localVarPath =
          `/dataset-batches/{dataset_batch_uid}/populated-filters-info`.replace(
            `{${'dataset_batch_uid'}}`,
            encodeURIComponent(String(datasetBatchUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Source Size From Dataset
     * @param {number} datasetUid
     * @param {string} [sourceType]
     * @param {string} [sourceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet: async (
      datasetUid: number,
      sourceType?: string,
      sourceUid?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/batches-source-size`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sourceType !== undefined) {
        localVarQueryParameter['source_type'] = sourceType;
      }

      if (sourceUid !== undefined) {
        localVarQueryParameter['source_uid'] = sourceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Batch
     * @param {number} datasetBatchUid
     * @param {UpdateDatasetBatchParams} updateDatasetBatchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBatchDatasetBatchesDatasetBatchUidPut: async (
      datasetBatchUid: number,
      updateDatasetBatchParams: UpdateDatasetBatchParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetBatchUid' is not null or undefined
      assertParamExists(
        'updateBatchDatasetBatchesDatasetBatchUidPut',
        'datasetBatchUid',
        datasetBatchUid,
      );
      // verify required parameter 'updateDatasetBatchParams' is not null or undefined
      assertParamExists(
        'updateBatchDatasetBatchesDatasetBatchUidPut',
        'updateDatasetBatchParams',
        updateDatasetBatchParams,
      );
      const localVarPath = `/dataset-batches/{dataset_batch_uid}`.replace(
        `{${'dataset_batch_uid'}}`,
        encodeURIComponent(String(datasetBatchUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDatasetBatchParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatasetBatchApi - functional programming interface
 * @export
 */
export const DatasetBatchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DatasetBatchApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Batch
     * @param {number} datasetUid
     * @param {CreateDatasetBatchPayload} createDatasetBatchPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addBatchDatasetsDatasetUidBatchPost(
      datasetUid: number,
      createDatasetBatchPayload: CreateDatasetBatchPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasetBatch>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addBatchDatasetsDatasetUidBatchPost(
          datasetUid,
          createDatasetBatchPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.addBatchDatasetsDatasetUidBatchPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Add Label Schemas To Batch
     * @param {number} datasetBatchUid
     * @param {AssignLabelSchemasToBatchParams} assignLabelSchemasToBatchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost(
      datasetBatchUid: number,
      assignLabelSchemasToBatchParams: AssignLabelSchemasToBatchParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost(
          datasetBatchUid,
          assignLabelSchemasToBatchParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.addLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Batch
     * @param {number} datasetBatchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBatchDatasetBatchesDatasetBatchUidDelete(
      datasetBatchUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteBatchDatasetBatchesDatasetBatchUidDelete(
          datasetBatchUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.deleteBatchDatasetBatchesDatasetBatchUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Batches
     * @param {number} datasetUid
     * @param {DeleteDatasetBatchesParams} deleteDatasetBatchesParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBatchesDatasetBatchesDelete(
      datasetUid: number,
      deleteDatasetBatchesParams: DeleteDatasetBatchesParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteBatchesDatasetBatchesDelete(
          datasetUid,
          deleteDatasetBatchesParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.deleteBatchesDatasetBatchesDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Export Batches
     * @param {number} datasetUid
     * @param {Array<number>} [batchUids]
     * @param {Array<number>} [labelSchemaUids]
     * @param {Array<string>} [selectedFields]
     * @param {boolean} [includeAnnotations]
     * @param {boolean} [includeGroundTruth]
     * @param {number} [startIndex]
     * @param {number} [maxDatapointsToExport]
     * @param {number} [maxCharsPerColumn]
     * @param {string} [csvDelimiter]
     * @param {string} [quoteChar]
     * @param {string} [escapeChar]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportBatchesDatasetBatchesDatasetUidExportBatchesGet(
      datasetUid: number,
      batchUids?: Array<number>,
      labelSchemaUids?: Array<number>,
      selectedFields?: Array<string>,
      includeAnnotations?: boolean,
      includeGroundTruth?: boolean,
      startIndex?: number,
      maxDatapointsToExport?: number,
      maxCharsPerColumn?: number,
      csvDelimiter?: string,
      quoteChar?: string,
      escapeChar?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportBatchesDatasetBatchesDatasetUidExportBatchesGet(
          datasetUid,
          batchUids,
          labelSchemaUids,
          selectedFields,
          includeAnnotations,
          includeGroundTruth,
          startIndex,
          maxDatapointsToExport,
          maxCharsPerColumn,
          csvDelimiter,
          quoteChar,
          escapeChar,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.exportBatchesDatasetBatchesDatasetUidExportBatchesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Batch Data
     * @param {number} datasetBatchUid
     * @param {string} [filterConfig]
     * @param {boolean} [ascending]
     * @param {number} [limit]
     * @param {Array<string>} [groupBy]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchDataDatasetBatchesDatasetBatchUidBatchDataGet(
      datasetBatchUid: number,
      filterConfig?: string,
      ascending?: boolean,
      limit?: number,
      groupBy?: Array<string>,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BatchDataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchDataDatasetBatchesDatasetBatchUidBatchDataGet(
          datasetBatchUid,
          filterConfig,
          ascending,
          limit,
          groupBy,
          offset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.getBatchDataDatasetBatchesDatasetBatchUidBatchDataGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get the first page of docs. Use this endpoint only for PDF.
     * @summary Get Batch Data Docs
     * @param {number} datasetBatchUid
     * @param {string} [filterConfig]
     * @param {boolean} [ascending]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet(
      datasetBatchUid: number,
      filterConfig?: string,
      ascending?: boolean,
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BatchDataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet(
          datasetBatchUid,
          filterConfig,
          ascending,
          limit,
          offset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.getBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get pages of a particular doc. Use this endpoint only for PDF.
     * @summary Get Batch Data Pages
     * @param {number} datasetBatchUid
     * @param {number} contextUid
     * @param {string} [filterConfig]
     * @param {boolean} [ascending]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet(
      datasetBatchUid: number,
      contextUid: number,
      filterConfig?: string,
      ascending?: boolean,
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BatchDataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet(
          datasetBatchUid,
          contextUid,
          filterConfig,
          ascending,
          limit,
          offset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.getBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Batch
     * @param {number} datasetBatchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchDatasetBatchesDatasetBatchUidGet(
      datasetBatchUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetBatch>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchDatasetBatchesDatasetBatchUidGet(
          datasetBatchUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.getBatchDatasetBatchesDatasetBatchUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Batch X Uids
     * @param {number} datasetBatchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchXUidsDatasetBatchesDatasetBatchUidXUidsGet(
      datasetBatchUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DatasetBatchXUIDResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchXUidsDatasetBatchesDatasetBatchUidXUidsGet(
          datasetBatchUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.getBatchXUidsDatasetBatchesDatasetBatchUidXUidsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Batches For Dataset
     * @param {number} datasetUid
     * @param {number} [userUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatchesForDatasetDatasetsDatasetUidBatchesGet(
      datasetUid: number,
      userUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasetBatch>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBatchesForDatasetDatasetsDatasetUidBatchesGet(
          datasetUid,
          userUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.getBatchesForDatasetDatasetsDatasetUidBatchesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Dataset Batches
     * @param {number} [datasetUid]
     * @param {number} [userUid]
     * @param {number} [workspaceUid]
     * @param {number} [assigneeUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatasetBatchesDatasetBatchesGet(
      datasetUid?: number,
      userUid?: number,
      workspaceUid?: number,
      assigneeUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasetBatch>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDatasetBatchesDatasetBatchesGet(
          datasetUid,
          userUid,
          workspaceUid,
          assigneeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.getDatasetBatchesDatasetBatchesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Populated Filters Info
     * @param {number} datasetBatchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGet(
      datasetBatchUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DatasetBatchFilterStructuresResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGet(
          datasetBatchUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.getPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Source Size From Dataset
     * @param {number} datasetUid
     * @param {string} [sourceType]
     * @param {string} [sourceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet(
      datasetUid: number,
      sourceType?: string,
      sourceUid?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSourceSizeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet(
          datasetUid,
          sourceType,
          sourceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.getSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Batch
     * @param {number} datasetBatchUid
     * @param {UpdateDatasetBatchParams} updateDatasetBatchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBatchDatasetBatchesDatasetBatchUidPut(
      datasetBatchUid: number,
      updateDatasetBatchParams: UpdateDatasetBatchParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetBatch>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateBatchDatasetBatchesDatasetBatchUidPut(
          datasetBatchUid,
          updateDatasetBatchParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetBatchApi.updateBatchDatasetBatchesDatasetBatchUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DatasetBatchApi - factory interface
 * @export
 */
export const DatasetBatchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DatasetBatchApiFp(configuration);
  return {
    /**
     *
     * @summary Add Batch
     * @param {DatasetBatchApiAddBatchDatasetsDatasetUidBatchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBatchDatasetsDatasetUidBatchPost(
      requestParameters: DatasetBatchApiAddBatchDatasetsDatasetUidBatchPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasetBatch>> {
      return localVarFp
        .addBatchDatasetsDatasetUidBatchPost(
          requestParameters.datasetUid,
          requestParameters.createDatasetBatchPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Add Label Schemas To Batch
     * @param {DatasetBatchApiAddLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost(
      requestParameters: DatasetBatchApiAddLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .addLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost(
          requestParameters.datasetBatchUid,
          requestParameters.assignLabelSchemasToBatchParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Batch
     * @param {DatasetBatchApiDeleteBatchDatasetBatchesDatasetBatchUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBatchDatasetBatchesDatasetBatchUidDelete(
      requestParameters: DatasetBatchApiDeleteBatchDatasetBatchesDatasetBatchUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteBatchDatasetBatchesDatasetBatchUidDelete(
          requestParameters.datasetBatchUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Batches
     * @param {DatasetBatchApiDeleteBatchesDatasetBatchesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBatchesDatasetBatchesDelete(
      requestParameters: DatasetBatchApiDeleteBatchesDatasetBatchesDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteBatchesDatasetBatchesDelete(
          requestParameters.datasetUid,
          requestParameters.deleteDatasetBatchesParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Export Batches
     * @param {DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportBatchesDatasetBatchesDatasetUidExportBatchesGet(
      requestParameters: DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .exportBatchesDatasetBatchesDatasetUidExportBatchesGet(
          requestParameters.datasetUid,
          requestParameters.batchUids,
          requestParameters.labelSchemaUids,
          requestParameters.selectedFields,
          requestParameters.includeAnnotations,
          requestParameters.includeGroundTruth,
          requestParameters.startIndex,
          requestParameters.maxDatapointsToExport,
          requestParameters.maxCharsPerColumn,
          requestParameters.csvDelimiter,
          requestParameters.quoteChar,
          requestParameters.escapeChar,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Batch Data
     * @param {DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchDataDatasetBatchesDatasetBatchUidBatchDataGet(
      requestParameters: DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<BatchDataResponse> {
      return localVarFp
        .getBatchDataDatasetBatchesDatasetBatchUidBatchDataGet(
          requestParameters.datasetBatchUid,
          requestParameters.filterConfig,
          requestParameters.ascending,
          requestParameters.limit,
          requestParameters.groupBy,
          requestParameters.offset,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Get the first page of docs. Use this endpoint only for PDF.
     * @summary Get Batch Data Docs
     * @param {DatasetBatchApiGetBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet(
      requestParameters: DatasetBatchApiGetBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<BatchDataResponse> {
      return localVarFp
        .getBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet(
          requestParameters.datasetBatchUid,
          requestParameters.filterConfig,
          requestParameters.ascending,
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Get pages of a particular doc. Use this endpoint only for PDF.
     * @summary Get Batch Data Pages
     * @param {DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet(
      requestParameters: DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<BatchDataResponse> {
      return localVarFp
        .getBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet(
          requestParameters.datasetBatchUid,
          requestParameters.contextUid,
          requestParameters.filterConfig,
          requestParameters.ascending,
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Batch
     * @param {DatasetBatchApiGetBatchDatasetBatchesDatasetBatchUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchDatasetBatchesDatasetBatchUidGet(
      requestParameters: DatasetBatchApiGetBatchDatasetBatchesDatasetBatchUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetBatch> {
      return localVarFp
        .getBatchDatasetBatchesDatasetBatchUidGet(
          requestParameters.datasetBatchUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Batch X Uids
     * @param {DatasetBatchApiGetBatchXUidsDatasetBatchesDatasetBatchUidXUidsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchXUidsDatasetBatchesDatasetBatchUidXUidsGet(
      requestParameters: DatasetBatchApiGetBatchXUidsDatasetBatchesDatasetBatchUidXUidsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetBatchXUIDResponse> {
      return localVarFp
        .getBatchXUidsDatasetBatchesDatasetBatchUidXUidsGet(
          requestParameters.datasetBatchUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Batches For Dataset
     * @param {DatasetBatchApiGetBatchesForDatasetDatasetsDatasetUidBatchesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatchesForDatasetDatasetsDatasetUidBatchesGet(
      requestParameters: DatasetBatchApiGetBatchesForDatasetDatasetsDatasetUidBatchesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasetBatch>> {
      return localVarFp
        .getBatchesForDatasetDatasetsDatasetUidBatchesGet(
          requestParameters.datasetUid,
          requestParameters.userUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Dataset Batches
     * @param {DatasetBatchApiGetDatasetBatchesDatasetBatchesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetBatchesDatasetBatchesGet(
      requestParameters: DatasetBatchApiGetDatasetBatchesDatasetBatchesGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasetBatch>> {
      return localVarFp
        .getDatasetBatchesDatasetBatchesGet(
          requestParameters.datasetUid,
          requestParameters.userUid,
          requestParameters.workspaceUid,
          requestParameters.assigneeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Populated Filters Info
     * @param {DatasetBatchApiGetPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGet(
      requestParameters: DatasetBatchApiGetPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetBatchFilterStructuresResponse> {
      return localVarFp
        .getPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGet(
          requestParameters.datasetBatchUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Source Size From Dataset
     * @param {DatasetBatchApiGetSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet(
      requestParameters: DatasetBatchApiGetSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetSourceSizeResponse> {
      return localVarFp
        .getSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet(
          requestParameters.datasetUid,
          requestParameters.sourceType,
          requestParameters.sourceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Batch
     * @param {DatasetBatchApiUpdateBatchDatasetBatchesDatasetBatchUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBatchDatasetBatchesDatasetBatchUidPut(
      requestParameters: DatasetBatchApiUpdateBatchDatasetBatchesDatasetBatchUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetBatch> {
      return localVarFp
        .updateBatchDatasetBatchesDatasetBatchUidPut(
          requestParameters.datasetBatchUid,
          requestParameters.updateDatasetBatchParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addBatchDatasetsDatasetUidBatchPost operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiAddBatchDatasetsDatasetUidBatchPostRequest
 */
export interface DatasetBatchApiAddBatchDatasetsDatasetUidBatchPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiAddBatchDatasetsDatasetUidBatchPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {CreateDatasetBatchPayload}
   * @memberof DatasetBatchApiAddBatchDatasetsDatasetUidBatchPost
   */
  readonly createDatasetBatchPayload: CreateDatasetBatchPayload;
}

/**
 * Request parameters for addLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiAddLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPostRequest
 */
export interface DatasetBatchApiAddLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiAddLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost
   */
  readonly datasetBatchUid: number;

  /**
   *
   * @type {AssignLabelSchemasToBatchParams}
   * @memberof DatasetBatchApiAddLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost
   */
  readonly assignLabelSchemasToBatchParams: AssignLabelSchemasToBatchParams;
}

/**
 * Request parameters for deleteBatchDatasetBatchesDatasetBatchUidDelete operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiDeleteBatchDatasetBatchesDatasetBatchUidDeleteRequest
 */
export interface DatasetBatchApiDeleteBatchDatasetBatchesDatasetBatchUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiDeleteBatchDatasetBatchesDatasetBatchUidDelete
   */
  readonly datasetBatchUid: number;
}

/**
 * Request parameters for deleteBatchesDatasetBatchesDelete operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiDeleteBatchesDatasetBatchesDeleteRequest
 */
export interface DatasetBatchApiDeleteBatchesDatasetBatchesDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiDeleteBatchesDatasetBatchesDelete
   */
  readonly datasetUid: number;

  /**
   *
   * @type {DeleteDatasetBatchesParams}
   * @memberof DatasetBatchApiDeleteBatchesDatasetBatchesDelete
   */
  readonly deleteDatasetBatchesParams: DeleteDatasetBatchesParams;
}

/**
 * Request parameters for exportBatchesDatasetBatchesDatasetUidExportBatchesGet operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGetRequest
 */
export interface DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {Array<number>}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly batchUids?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly labelSchemaUids?: Array<number>;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly selectedFields?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly includeAnnotations?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly includeGroundTruth?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly startIndex?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly maxDatapointsToExport?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly maxCharsPerColumn?: number;

  /**
   *
   * @type {string}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly csvDelimiter?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly quoteChar?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGet
   */
  readonly escapeChar?: string;
}

/**
 * Request parameters for getBatchDataDatasetBatchesDatasetBatchUidBatchDataGet operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGetRequest
 */
export interface DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGet
   */
  readonly datasetBatchUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGet
   */
  readonly filterConfig?: string;

  /**
   *
   * @type {boolean}
   * @memberof DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGet
   */
  readonly ascending?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGet
   */
  readonly limit?: number;

  /**
   *
   * @type {Array<string>}
   * @memberof DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGet
   */
  readonly groupBy?: Array<string>;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGet
   */
  readonly offset?: number;
}

/**
 * Request parameters for getBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiGetBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGetRequest
 */
export interface DatasetBatchApiGetBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet
   */
  readonly datasetBatchUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetBatchApiGetBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet
   */
  readonly filterConfig?: string;

  /**
   *
   * @type {boolean}
   * @memberof DatasetBatchApiGetBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet
   */
  readonly ascending?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet
   */
  readonly offset?: number;
}

/**
 * Request parameters for getBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGetRequest
 */
export interface DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet
   */
  readonly datasetBatchUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet
   */
  readonly contextUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet
   */
  readonly filterConfig?: string;

  /**
   *
   * @type {boolean}
   * @memberof DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet
   */
  readonly ascending?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet
   */
  readonly offset?: number;
}

/**
 * Request parameters for getBatchDatasetBatchesDatasetBatchUidGet operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiGetBatchDatasetBatchesDatasetBatchUidGetRequest
 */
export interface DatasetBatchApiGetBatchDatasetBatchesDatasetBatchUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchDatasetBatchesDatasetBatchUidGet
   */
  readonly datasetBatchUid: number;
}

/**
 * Request parameters for getBatchXUidsDatasetBatchesDatasetBatchUidXUidsGet operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiGetBatchXUidsDatasetBatchesDatasetBatchUidXUidsGetRequest
 */
export interface DatasetBatchApiGetBatchXUidsDatasetBatchesDatasetBatchUidXUidsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchXUidsDatasetBatchesDatasetBatchUidXUidsGet
   */
  readonly datasetBatchUid: number;
}

/**
 * Request parameters for getBatchesForDatasetDatasetsDatasetUidBatchesGet operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiGetBatchesForDatasetDatasetsDatasetUidBatchesGetRequest
 */
export interface DatasetBatchApiGetBatchesForDatasetDatasetsDatasetUidBatchesGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchesForDatasetDatasetsDatasetUidBatchesGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetBatchesForDatasetDatasetsDatasetUidBatchesGet
   */
  readonly userUid?: number;
}

/**
 * Request parameters for getDatasetBatchesDatasetBatchesGet operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiGetDatasetBatchesDatasetBatchesGetRequest
 */
export interface DatasetBatchApiGetDatasetBatchesDatasetBatchesGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetDatasetBatchesDatasetBatchesGet
   */
  readonly datasetUid?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetDatasetBatchesDatasetBatchesGet
   */
  readonly userUid?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetDatasetBatchesDatasetBatchesGet
   */
  readonly workspaceUid?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetDatasetBatchesDatasetBatchesGet
   */
  readonly assigneeUid?: number;
}

/**
 * Request parameters for getPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGet operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiGetPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGetRequest
 */
export interface DatasetBatchApiGetPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGet
   */
  readonly datasetBatchUid: number;
}

/**
 * Request parameters for getSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiGetSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGetRequest
 */
export interface DatasetBatchApiGetSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiGetSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetBatchApiGetSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet
   */
  readonly sourceType?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetBatchApiGetSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet
   */
  readonly sourceUid?: string;
}

/**
 * Request parameters for updateBatchDatasetBatchesDatasetBatchUidPut operation in DatasetBatchApi.
 * @export
 * @interface DatasetBatchApiUpdateBatchDatasetBatchesDatasetBatchUidPutRequest
 */
export interface DatasetBatchApiUpdateBatchDatasetBatchesDatasetBatchUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetBatchApiUpdateBatchDatasetBatchesDatasetBatchUidPut
   */
  readonly datasetBatchUid: number;

  /**
   *
   * @type {UpdateDatasetBatchParams}
   * @memberof DatasetBatchApiUpdateBatchDatasetBatchesDatasetBatchUidPut
   */
  readonly updateDatasetBatchParams: UpdateDatasetBatchParams;
}

/**
 * DatasetBatchApi - object-oriented interface
 * @export
 * @class DatasetBatchApi
 * @extends {BaseAPI}
 */
export class DatasetBatchApi extends BaseAPI {
  /**
   *
   * @summary Add Batch
   * @param {DatasetBatchApiAddBatchDatasetsDatasetUidBatchPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public addBatchDatasetsDatasetUidBatchPost(
    requestParameters: DatasetBatchApiAddBatchDatasetsDatasetUidBatchPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .addBatchDatasetsDatasetUidBatchPost(
        requestParameters.datasetUid,
        requestParameters.createDatasetBatchPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add Label Schemas To Batch
   * @param {DatasetBatchApiAddLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public addLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost(
    requestParameters: DatasetBatchApiAddLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .addLabelSchemasToBatchDatasetBatchesDatasetBatchUidLabelSchemasPost(
        requestParameters.datasetBatchUid,
        requestParameters.assignLabelSchemasToBatchParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Batch
   * @param {DatasetBatchApiDeleteBatchDatasetBatchesDatasetBatchUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public deleteBatchDatasetBatchesDatasetBatchUidDelete(
    requestParameters: DatasetBatchApiDeleteBatchDatasetBatchesDatasetBatchUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .deleteBatchDatasetBatchesDatasetBatchUidDelete(
        requestParameters.datasetBatchUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Batches
   * @param {DatasetBatchApiDeleteBatchesDatasetBatchesDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public deleteBatchesDatasetBatchesDelete(
    requestParameters: DatasetBatchApiDeleteBatchesDatasetBatchesDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .deleteBatchesDatasetBatchesDelete(
        requestParameters.datasetUid,
        requestParameters.deleteDatasetBatchesParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export Batches
   * @param {DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public exportBatchesDatasetBatchesDatasetUidExportBatchesGet(
    requestParameters: DatasetBatchApiExportBatchesDatasetBatchesDatasetUidExportBatchesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .exportBatchesDatasetBatchesDatasetUidExportBatchesGet(
        requestParameters.datasetUid,
        requestParameters.batchUids,
        requestParameters.labelSchemaUids,
        requestParameters.selectedFields,
        requestParameters.includeAnnotations,
        requestParameters.includeGroundTruth,
        requestParameters.startIndex,
        requestParameters.maxDatapointsToExport,
        requestParameters.maxCharsPerColumn,
        requestParameters.csvDelimiter,
        requestParameters.quoteChar,
        requestParameters.escapeChar,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Batch Data
   * @param {DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public getBatchDataDatasetBatchesDatasetBatchUidBatchDataGet(
    requestParameters: DatasetBatchApiGetBatchDataDatasetBatchesDatasetBatchUidBatchDataGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .getBatchDataDatasetBatchesDatasetBatchUidBatchDataGet(
        requestParameters.datasetBatchUid,
        requestParameters.filterConfig,
        requestParameters.ascending,
        requestParameters.limit,
        requestParameters.groupBy,
        requestParameters.offset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get the first page of docs. Use this endpoint only for PDF.
   * @summary Get Batch Data Docs
   * @param {DatasetBatchApiGetBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public getBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet(
    requestParameters: DatasetBatchApiGetBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .getBatchDataDocsDatasetBatchesDatasetBatchUidBatchDataDocsGet(
        requestParameters.datasetBatchUid,
        requestParameters.filterConfig,
        requestParameters.ascending,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get pages of a particular doc. Use this endpoint only for PDF.
   * @summary Get Batch Data Pages
   * @param {DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public getBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet(
    requestParameters: DatasetBatchApiGetBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .getBatchDataPagesDatasetBatchesDatasetBatchUidBatchDataDocsContextUidPagesGet(
        requestParameters.datasetBatchUid,
        requestParameters.contextUid,
        requestParameters.filterConfig,
        requestParameters.ascending,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Batch
   * @param {DatasetBatchApiGetBatchDatasetBatchesDatasetBatchUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public getBatchDatasetBatchesDatasetBatchUidGet(
    requestParameters: DatasetBatchApiGetBatchDatasetBatchesDatasetBatchUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .getBatchDatasetBatchesDatasetBatchUidGet(
        requestParameters.datasetBatchUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Batch X Uids
   * @param {DatasetBatchApiGetBatchXUidsDatasetBatchesDatasetBatchUidXUidsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public getBatchXUidsDatasetBatchesDatasetBatchUidXUidsGet(
    requestParameters: DatasetBatchApiGetBatchXUidsDatasetBatchesDatasetBatchUidXUidsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .getBatchXUidsDatasetBatchesDatasetBatchUidXUidsGet(
        requestParameters.datasetBatchUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Batches For Dataset
   * @param {DatasetBatchApiGetBatchesForDatasetDatasetsDatasetUidBatchesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public getBatchesForDatasetDatasetsDatasetUidBatchesGet(
    requestParameters: DatasetBatchApiGetBatchesForDatasetDatasetsDatasetUidBatchesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .getBatchesForDatasetDatasetsDatasetUidBatchesGet(
        requestParameters.datasetUid,
        requestParameters.userUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Dataset Batches
   * @param {DatasetBatchApiGetDatasetBatchesDatasetBatchesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public getDatasetBatchesDatasetBatchesGet(
    requestParameters: DatasetBatchApiGetDatasetBatchesDatasetBatchesGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .getDatasetBatchesDatasetBatchesGet(
        requestParameters.datasetUid,
        requestParameters.userUid,
        requestParameters.workspaceUid,
        requestParameters.assigneeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Populated Filters Info
   * @param {DatasetBatchApiGetPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public getPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGet(
    requestParameters: DatasetBatchApiGetPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .getPopulatedFiltersInfoDatasetBatchesDatasetBatchUidPopulatedFiltersInfoGet(
        requestParameters.datasetBatchUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Source Size From Dataset
   * @param {DatasetBatchApiGetSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public getSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet(
    requestParameters: DatasetBatchApiGetSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .getSourceSizeFromDatasetDatasetDatasetUidBatchesSourceSizeGet(
        requestParameters.datasetUid,
        requestParameters.sourceType,
        requestParameters.sourceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Batch
   * @param {DatasetBatchApiUpdateBatchDatasetBatchesDatasetBatchUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetBatchApi
   */
  public updateBatchDatasetBatchesDatasetBatchUidPut(
    requestParameters: DatasetBatchApiUpdateBatchDatasetBatchesDatasetBatchUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetBatchApiFp(this.configuration)
      .updateBatchDatasetBatchesDatasetBatchUidPut(
        requestParameters.datasetBatchUid,
        requestParameters.updateDatasetBatchParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DatasetCommentsApi - axios parameter creator
 * @export
 */
export const DatasetCommentsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Comment
     * @param {number} datasetUid
     * @param {CreateDatasetCommentParams} createDatasetCommentParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentDatasetDatasetUidCommentsPost: async (
      datasetUid: number,
      createDatasetCommentParams: CreateDatasetCommentParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'createCommentDatasetDatasetUidCommentsPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'createDatasetCommentParams' is not null or undefined
      assertParamExists(
        'createCommentDatasetDatasetUidCommentsPost',
        'createDatasetCommentParams',
        createDatasetCommentParams,
      );
      const localVarPath = `/dataset/{dataset_uid}/comments`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDatasetCommentParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Comment
     * @param {number} datasetUid
     * @param {number} commentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommentDatasetDatasetUidCommentsCommentUidDelete: async (
      datasetUid: number,
      commentUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'deleteCommentDatasetDatasetUidCommentsCommentUidDelete',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'commentUid' is not null or undefined
      assertParamExists(
        'deleteCommentDatasetDatasetUidCommentsCommentUidDelete',
        'commentUid',
        commentUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/comments/{comment_uid}`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'comment_uid'}}`, encodeURIComponent(String(commentUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Comment
     * @param {number} datasetUid
     * @param {number} commentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentDatasetDatasetUidCommentsCommentUidGet: async (
      datasetUid: number,
      commentUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getCommentDatasetDatasetUidCommentsCommentUidGet',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'commentUid' is not null or undefined
      assertParamExists(
        'getCommentDatasetDatasetUidCommentsCommentUidGet',
        'commentUid',
        commentUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/comments/{comment_uid}`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'comment_uid'}}`, encodeURIComponent(String(commentUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Comment Map
     * @param {number} datasetUid
     * @param {GetCommentsByXuidParams} getCommentsByXuidParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentMapDatasetDatasetUidGetCommentMapPost: async (
      datasetUid: number,
      getCommentsByXuidParams: GetCommentsByXuidParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getCommentMapDatasetDatasetUidGetCommentMapPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'getCommentsByXuidParams' is not null or undefined
      assertParamExists(
        'getCommentMapDatasetDatasetUidGetCommentMapPost',
        'getCommentsByXuidParams',
        getCommentsByXuidParams,
      );
      const localVarPath = `/dataset/{dataset_uid}/get-comment-map`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCommentsByXuidParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Comments
     * @param {number} datasetUid
     * @param {number} [userUid]
     * @param {string} [body]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCommentsDatasetDatasetUidCommentsGet: async (
      datasetUid: number,
      userUid?: number,
      body?: string,
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'listCommentsDatasetDatasetUidCommentsGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/comments`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      if (body !== undefined) {
        localVarQueryParameter['body'] = body;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Comment
     * @param {number} datasetUid
     * @param {number} commentUid
     * @param {string} updatedBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCommentDatasetDatasetUidCommentsCommentUidPut: async (
      datasetUid: number,
      commentUid: number,
      updatedBody: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'updateCommentDatasetDatasetUidCommentsCommentUidPut',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'commentUid' is not null or undefined
      assertParamExists(
        'updateCommentDatasetDatasetUidCommentsCommentUidPut',
        'commentUid',
        commentUid,
      );
      // verify required parameter 'updatedBody' is not null or undefined
      assertParamExists(
        'updateCommentDatasetDatasetUidCommentsCommentUidPut',
        'updatedBody',
        updatedBody,
      );
      const localVarPath = `/dataset/{dataset_uid}/comments/{comment_uid}`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'comment_uid'}}`, encodeURIComponent(String(commentUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (updatedBody !== undefined) {
        localVarQueryParameter['updated_body'] = updatedBody;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatasetCommentsApi - functional programming interface
 * @export
 */
export const DatasetCommentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DatasetCommentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Comment
     * @param {number} datasetUid
     * @param {CreateDatasetCommentParams} createDatasetCommentParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCommentDatasetDatasetUidCommentsPost(
      datasetUid: number,
      createDatasetCommentParams: CreateDatasetCommentParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetComment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createCommentDatasetDatasetUidCommentsPost(
          datasetUid,
          createDatasetCommentParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetCommentsApi.createCommentDatasetDatasetUidCommentsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Comment
     * @param {number} datasetUid
     * @param {number} commentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCommentDatasetDatasetUidCommentsCommentUidDelete(
      datasetUid: number,
      commentUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteCommentDatasetDatasetUidCommentsCommentUidDelete(
          datasetUid,
          commentUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetCommentsApi.deleteCommentDatasetDatasetUidCommentsCommentUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Comment
     * @param {number} datasetUid
     * @param {number} commentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommentDatasetDatasetUidCommentsCommentUidGet(
      datasetUid: number,
      commentUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetComment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommentDatasetDatasetUidCommentsCommentUidGet(
          datasetUid,
          commentUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetCommentsApi.getCommentDatasetDatasetUidCommentsCommentUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Comment Map
     * @param {number} datasetUid
     * @param {GetCommentsByXuidParams} getCommentsByXuidParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommentMapDatasetDatasetUidGetCommentMapPost(
      datasetUid: number,
      getCommentsByXuidParams: GetCommentsByXuidParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasetCommentsByXuid>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommentMapDatasetDatasetUidGetCommentMapPost(
          datasetUid,
          getCommentsByXuidParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetCommentsApi.getCommentMapDatasetDatasetUidGetCommentMapPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Comments
     * @param {number} datasetUid
     * @param {number} [userUid]
     * @param {string} [body]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCommentsDatasetDatasetUidCommentsGet(
      datasetUid: number,
      userUid?: number,
      body?: string,
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasetComment>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listCommentsDatasetDatasetUidCommentsGet(
          datasetUid,
          userUid,
          body,
          limit,
          offset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetCommentsApi.listCommentsDatasetDatasetUidCommentsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Comment
     * @param {number} datasetUid
     * @param {number} commentUid
     * @param {string} updatedBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCommentDatasetDatasetUidCommentsCommentUidPut(
      datasetUid: number,
      commentUid: number,
      updatedBody: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetComment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateCommentDatasetDatasetUidCommentsCommentUidPut(
          datasetUid,
          commentUid,
          updatedBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetCommentsApi.updateCommentDatasetDatasetUidCommentsCommentUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DatasetCommentsApi - factory interface
 * @export
 */
export const DatasetCommentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DatasetCommentsApiFp(configuration);
  return {
    /**
     *
     * @summary Create Comment
     * @param {DatasetCommentsApiCreateCommentDatasetDatasetUidCommentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentDatasetDatasetUidCommentsPost(
      requestParameters: DatasetCommentsApiCreateCommentDatasetDatasetUidCommentsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetComment> {
      return localVarFp
        .createCommentDatasetDatasetUidCommentsPost(
          requestParameters.datasetUid,
          requestParameters.createDatasetCommentParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Comment
     * @param {DatasetCommentsApiDeleteCommentDatasetDatasetUidCommentsCommentUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCommentDatasetDatasetUidCommentsCommentUidDelete(
      requestParameters: DatasetCommentsApiDeleteCommentDatasetDatasetUidCommentsCommentUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteCommentDatasetDatasetUidCommentsCommentUidDelete(
          requestParameters.datasetUid,
          requestParameters.commentUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Comment
     * @param {DatasetCommentsApiGetCommentDatasetDatasetUidCommentsCommentUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentDatasetDatasetUidCommentsCommentUidGet(
      requestParameters: DatasetCommentsApiGetCommentDatasetDatasetUidCommentsCommentUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetComment> {
      return localVarFp
        .getCommentDatasetDatasetUidCommentsCommentUidGet(
          requestParameters.datasetUid,
          requestParameters.commentUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Comment Map
     * @param {DatasetCommentsApiGetCommentMapDatasetDatasetUidGetCommentMapPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentMapDatasetDatasetUidGetCommentMapPost(
      requestParameters: DatasetCommentsApiGetCommentMapDatasetDatasetUidGetCommentMapPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasetCommentsByXuid>> {
      return localVarFp
        .getCommentMapDatasetDatasetUidGetCommentMapPost(
          requestParameters.datasetUid,
          requestParameters.getCommentsByXuidParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Comments
     * @param {DatasetCommentsApiListCommentsDatasetDatasetUidCommentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCommentsDatasetDatasetUidCommentsGet(
      requestParameters: DatasetCommentsApiListCommentsDatasetDatasetUidCommentsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasetComment>> {
      return localVarFp
        .listCommentsDatasetDatasetUidCommentsGet(
          requestParameters.datasetUid,
          requestParameters.userUid,
          requestParameters.body,
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Comment
     * @param {DatasetCommentsApiUpdateCommentDatasetDatasetUidCommentsCommentUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCommentDatasetDatasetUidCommentsCommentUidPut(
      requestParameters: DatasetCommentsApiUpdateCommentDatasetDatasetUidCommentsCommentUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetComment> {
      return localVarFp
        .updateCommentDatasetDatasetUidCommentsCommentUidPut(
          requestParameters.datasetUid,
          requestParameters.commentUid,
          requestParameters.updatedBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createCommentDatasetDatasetUidCommentsPost operation in DatasetCommentsApi.
 * @export
 * @interface DatasetCommentsApiCreateCommentDatasetDatasetUidCommentsPostRequest
 */
export interface DatasetCommentsApiCreateCommentDatasetDatasetUidCommentsPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiCreateCommentDatasetDatasetUidCommentsPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {CreateDatasetCommentParams}
   * @memberof DatasetCommentsApiCreateCommentDatasetDatasetUidCommentsPost
   */
  readonly createDatasetCommentParams: CreateDatasetCommentParams;
}

/**
 * Request parameters for deleteCommentDatasetDatasetUidCommentsCommentUidDelete operation in DatasetCommentsApi.
 * @export
 * @interface DatasetCommentsApiDeleteCommentDatasetDatasetUidCommentsCommentUidDeleteRequest
 */
export interface DatasetCommentsApiDeleteCommentDatasetDatasetUidCommentsCommentUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiDeleteCommentDatasetDatasetUidCommentsCommentUidDelete
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiDeleteCommentDatasetDatasetUidCommentsCommentUidDelete
   */
  readonly commentUid: number;
}

/**
 * Request parameters for getCommentDatasetDatasetUidCommentsCommentUidGet operation in DatasetCommentsApi.
 * @export
 * @interface DatasetCommentsApiGetCommentDatasetDatasetUidCommentsCommentUidGetRequest
 */
export interface DatasetCommentsApiGetCommentDatasetDatasetUidCommentsCommentUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiGetCommentDatasetDatasetUidCommentsCommentUidGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiGetCommentDatasetDatasetUidCommentsCommentUidGet
   */
  readonly commentUid: number;
}

/**
 * Request parameters for getCommentMapDatasetDatasetUidGetCommentMapPost operation in DatasetCommentsApi.
 * @export
 * @interface DatasetCommentsApiGetCommentMapDatasetDatasetUidGetCommentMapPostRequest
 */
export interface DatasetCommentsApiGetCommentMapDatasetDatasetUidGetCommentMapPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiGetCommentMapDatasetDatasetUidGetCommentMapPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {GetCommentsByXuidParams}
   * @memberof DatasetCommentsApiGetCommentMapDatasetDatasetUidGetCommentMapPost
   */
  readonly getCommentsByXuidParams: GetCommentsByXuidParams;
}

/**
 * Request parameters for listCommentsDatasetDatasetUidCommentsGet operation in DatasetCommentsApi.
 * @export
 * @interface DatasetCommentsApiListCommentsDatasetDatasetUidCommentsGetRequest
 */
export interface DatasetCommentsApiListCommentsDatasetDatasetUidCommentsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiListCommentsDatasetDatasetUidCommentsGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiListCommentsDatasetDatasetUidCommentsGet
   */
  readonly userUid?: number;

  /**
   *
   * @type {string}
   * @memberof DatasetCommentsApiListCommentsDatasetDatasetUidCommentsGet
   */
  readonly body?: string;

  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiListCommentsDatasetDatasetUidCommentsGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiListCommentsDatasetDatasetUidCommentsGet
   */
  readonly offset?: number;
}

/**
 * Request parameters for updateCommentDatasetDatasetUidCommentsCommentUidPut operation in DatasetCommentsApi.
 * @export
 * @interface DatasetCommentsApiUpdateCommentDatasetDatasetUidCommentsCommentUidPutRequest
 */
export interface DatasetCommentsApiUpdateCommentDatasetDatasetUidCommentsCommentUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiUpdateCommentDatasetDatasetUidCommentsCommentUidPut
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetCommentsApiUpdateCommentDatasetDatasetUidCommentsCommentUidPut
   */
  readonly commentUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetCommentsApiUpdateCommentDatasetDatasetUidCommentsCommentUidPut
   */
  readonly updatedBody: string;
}

/**
 * DatasetCommentsApi - object-oriented interface
 * @export
 * @class DatasetCommentsApi
 * @extends {BaseAPI}
 */
export class DatasetCommentsApi extends BaseAPI {
  /**
   *
   * @summary Create Comment
   * @param {DatasetCommentsApiCreateCommentDatasetDatasetUidCommentsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetCommentsApi
   */
  public createCommentDatasetDatasetUidCommentsPost(
    requestParameters: DatasetCommentsApiCreateCommentDatasetDatasetUidCommentsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetCommentsApiFp(this.configuration)
      .createCommentDatasetDatasetUidCommentsPost(
        requestParameters.datasetUid,
        requestParameters.createDatasetCommentParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Comment
   * @param {DatasetCommentsApiDeleteCommentDatasetDatasetUidCommentsCommentUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetCommentsApi
   */
  public deleteCommentDatasetDatasetUidCommentsCommentUidDelete(
    requestParameters: DatasetCommentsApiDeleteCommentDatasetDatasetUidCommentsCommentUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetCommentsApiFp(this.configuration)
      .deleteCommentDatasetDatasetUidCommentsCommentUidDelete(
        requestParameters.datasetUid,
        requestParameters.commentUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Comment
   * @param {DatasetCommentsApiGetCommentDatasetDatasetUidCommentsCommentUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetCommentsApi
   */
  public getCommentDatasetDatasetUidCommentsCommentUidGet(
    requestParameters: DatasetCommentsApiGetCommentDatasetDatasetUidCommentsCommentUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetCommentsApiFp(this.configuration)
      .getCommentDatasetDatasetUidCommentsCommentUidGet(
        requestParameters.datasetUid,
        requestParameters.commentUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Comment Map
   * @param {DatasetCommentsApiGetCommentMapDatasetDatasetUidGetCommentMapPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetCommentsApi
   */
  public getCommentMapDatasetDatasetUidGetCommentMapPost(
    requestParameters: DatasetCommentsApiGetCommentMapDatasetDatasetUidGetCommentMapPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetCommentsApiFp(this.configuration)
      .getCommentMapDatasetDatasetUidGetCommentMapPost(
        requestParameters.datasetUid,
        requestParameters.getCommentsByXuidParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Comments
   * @param {DatasetCommentsApiListCommentsDatasetDatasetUidCommentsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetCommentsApi
   */
  public listCommentsDatasetDatasetUidCommentsGet(
    requestParameters: DatasetCommentsApiListCommentsDatasetDatasetUidCommentsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetCommentsApiFp(this.configuration)
      .listCommentsDatasetDatasetUidCommentsGet(
        requestParameters.datasetUid,
        requestParameters.userUid,
        requestParameters.body,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Comment
   * @param {DatasetCommentsApiUpdateCommentDatasetDatasetUidCommentsCommentUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetCommentsApi
   */
  public updateCommentDatasetDatasetUidCommentsCommentUidPut(
    requestParameters: DatasetCommentsApiUpdateCommentDatasetDatasetUidCommentsCommentUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetCommentsApiFp(this.configuration)
      .updateCommentDatasetDatasetUidCommentsCommentUidPut(
        requestParameters.datasetUid,
        requestParameters.commentUid,
        requestParameters.updatedBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DatasetGroundTruthApi - axios parameter creator
 * @export
 */
export const DatasetGroundTruthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Dataset Ground Truth
     * @param {number} datasetUid
     * @param {number} labelSchemaUid
     * @param {string} xUid
     * @param {number} committedBy
     * @param {any} label
     * @param {number} [batchUid]
     * @param {number} [sourceUid]
     * @param {string} [timezone]
     * @param {string} [ts]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDatasetGroundTruthDatasetDatasetUidGroundTruthPost: async (
      datasetUid: number,
      labelSchemaUid: number,
      xUid: string,
      committedBy: number,
      label: any,
      batchUid?: number,
      sourceUid?: number,
      timezone?: string,
      ts?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'createDatasetGroundTruthDatasetDatasetUidGroundTruthPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'labelSchemaUid' is not null or undefined
      assertParamExists(
        'createDatasetGroundTruthDatasetDatasetUidGroundTruthPost',
        'labelSchemaUid',
        labelSchemaUid,
      );
      // verify required parameter 'xUid' is not null or undefined
      assertParamExists(
        'createDatasetGroundTruthDatasetDatasetUidGroundTruthPost',
        'xUid',
        xUid,
      );
      // verify required parameter 'committedBy' is not null or undefined
      assertParamExists(
        'createDatasetGroundTruthDatasetDatasetUidGroundTruthPost',
        'committedBy',
        committedBy,
      );
      // verify required parameter 'label' is not null or undefined
      assertParamExists(
        'createDatasetGroundTruthDatasetDatasetUidGroundTruthPost',
        'label',
        label,
      );
      const localVarPath = `/dataset/{dataset_uid}/ground-truth`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (labelSchemaUid !== undefined) {
        localVarQueryParameter['label_schema_uid'] = labelSchemaUid;
      }

      if (xUid !== undefined) {
        localVarQueryParameter['x_uid'] = xUid;
      }

      if (committedBy !== undefined) {
        localVarQueryParameter['committed_by'] = committedBy;
      }

      if (label !== undefined) {
        for (const [key, value] of Object.entries(label)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (batchUid !== undefined) {
        localVarQueryParameter['batch_uid'] = batchUid;
      }

      if (sourceUid !== undefined) {
        localVarQueryParameter['source_uid'] = sourceUid;
      }

      if (timezone !== undefined) {
        localVarQueryParameter['timezone'] = timezone;
      }

      if (ts !== undefined) {
        localVarQueryParameter['ts'] =
          (ts as any) instanceof Date ? (ts as any).toISOString() : ts;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Dataset Ground Truth
     * @param {number} datasetUid
     * @param {Array<number>} [labelSchemaUids]
     * @param {boolean} [userFormatted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetGroundTruthDatasetGroundTruthGet: async (
      datasetUid: number,
      labelSchemaUids?: Array<number>,
      userFormatted?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getDatasetGroundTruthDatasetGroundTruthGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/dataset-ground-truth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasetUid !== undefined) {
        localVarQueryParameter['dataset_uid'] = datasetUid;
      }

      if (labelSchemaUids) {
        localVarQueryParameter['label_schema_uids'] = labelSchemaUids;
      }

      if (userFormatted !== undefined) {
        localVarQueryParameter['user_formatted'] = userFormatted;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatasetGroundTruthApi - functional programming interface
 * @export
 */
export const DatasetGroundTruthApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    DatasetGroundTruthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Dataset Ground Truth
     * @param {number} datasetUid
     * @param {number} labelSchemaUid
     * @param {string} xUid
     * @param {number} committedBy
     * @param {any} label
     * @param {number} [batchUid]
     * @param {number} [sourceUid]
     * @param {string} [timezone]
     * @param {string} [ts]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDatasetGroundTruthDatasetDatasetUidGroundTruthPost(
      datasetUid: number,
      labelSchemaUid: number,
      xUid: string,
      committedBy: number,
      label: any,
      batchUid?: number,
      sourceUid?: number,
      timezone?: string,
      ts?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DatasetGroundTruth>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createDatasetGroundTruthDatasetDatasetUidGroundTruthPost(
          datasetUid,
          labelSchemaUid,
          xUid,
          committedBy,
          label,
          batchUid,
          sourceUid,
          timezone,
          ts,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetGroundTruthApi.createDatasetGroundTruthDatasetDatasetUidGroundTruthPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Dataset Ground Truth
     * @param {number} datasetUid
     * @param {Array<number>} [labelSchemaUids]
     * @param {boolean} [userFormatted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatasetGroundTruthDatasetGroundTruthGet(
      datasetUid: number,
      labelSchemaUids?: Array<number>,
      userFormatted?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasetGroundTruth>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDatasetGroundTruthDatasetGroundTruthGet(
          datasetUid,
          labelSchemaUids,
          userFormatted,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetGroundTruthApi.getDatasetGroundTruthDatasetGroundTruthGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DatasetGroundTruthApi - factory interface
 * @export
 */
export const DatasetGroundTruthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DatasetGroundTruthApiFp(configuration);
  return {
    /**
     *
     * @summary Create Dataset Ground Truth
     * @param {DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDatasetGroundTruthDatasetDatasetUidGroundTruthPost(
      requestParameters: DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetGroundTruth> {
      return localVarFp
        .createDatasetGroundTruthDatasetDatasetUidGroundTruthPost(
          requestParameters.datasetUid,
          requestParameters.labelSchemaUid,
          requestParameters.xUid,
          requestParameters.committedBy,
          requestParameters.label,
          requestParameters.batchUid,
          requestParameters.sourceUid,
          requestParameters.timezone,
          requestParameters.ts,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Dataset Ground Truth
     * @param {DatasetGroundTruthApiGetDatasetGroundTruthDatasetGroundTruthGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetGroundTruthDatasetGroundTruthGet(
      requestParameters: DatasetGroundTruthApiGetDatasetGroundTruthDatasetGroundTruthGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasetGroundTruth>> {
      return localVarFp
        .getDatasetGroundTruthDatasetGroundTruthGet(
          requestParameters.datasetUid,
          requestParameters.labelSchemaUids,
          requestParameters.userFormatted,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createDatasetGroundTruthDatasetDatasetUidGroundTruthPost operation in DatasetGroundTruthApi.
 * @export
 * @interface DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPostRequest
 */
export interface DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPost
   */
  readonly labelSchemaUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPost
   */
  readonly xUid: string;

  /**
   *
   * @type {number}
   * @memberof DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPost
   */
  readonly committedBy: number;

  /**
   *
   * @type {any}
   * @memberof DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPost
   */
  readonly label: any;

  /**
   *
   * @type {number}
   * @memberof DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPost
   */
  readonly batchUid?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPost
   */
  readonly sourceUid?: number;

  /**
   *
   * @type {string}
   * @memberof DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPost
   */
  readonly timezone?: string;

  /**
   *
   * @type {string}
   * @memberof DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPost
   */
  readonly ts?: string;
}

/**
 * Request parameters for getDatasetGroundTruthDatasetGroundTruthGet operation in DatasetGroundTruthApi.
 * @export
 * @interface DatasetGroundTruthApiGetDatasetGroundTruthDatasetGroundTruthGetRequest
 */
export interface DatasetGroundTruthApiGetDatasetGroundTruthDatasetGroundTruthGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetGroundTruthApiGetDatasetGroundTruthDatasetGroundTruthGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {Array<number>}
   * @memberof DatasetGroundTruthApiGetDatasetGroundTruthDatasetGroundTruthGet
   */
  readonly labelSchemaUids?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof DatasetGroundTruthApiGetDatasetGroundTruthDatasetGroundTruthGet
   */
  readonly userFormatted?: boolean;
}

/**
 * DatasetGroundTruthApi - object-oriented interface
 * @export
 * @class DatasetGroundTruthApi
 * @extends {BaseAPI}
 */
export class DatasetGroundTruthApi extends BaseAPI {
  /**
   *
   * @summary Create Dataset Ground Truth
   * @param {DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetGroundTruthApi
   */
  public createDatasetGroundTruthDatasetDatasetUidGroundTruthPost(
    requestParameters: DatasetGroundTruthApiCreateDatasetGroundTruthDatasetDatasetUidGroundTruthPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetGroundTruthApiFp(this.configuration)
      .createDatasetGroundTruthDatasetDatasetUidGroundTruthPost(
        requestParameters.datasetUid,
        requestParameters.labelSchemaUid,
        requestParameters.xUid,
        requestParameters.committedBy,
        requestParameters.label,
        requestParameters.batchUid,
        requestParameters.sourceUid,
        requestParameters.timezone,
        requestParameters.ts,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Dataset Ground Truth
   * @param {DatasetGroundTruthApiGetDatasetGroundTruthDatasetGroundTruthGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetGroundTruthApi
   */
  public getDatasetGroundTruthDatasetGroundTruthGet(
    requestParameters: DatasetGroundTruthApiGetDatasetGroundTruthDatasetGroundTruthGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetGroundTruthApiFp(this.configuration)
      .getDatasetGroundTruthDatasetGroundTruthGet(
        requestParameters.datasetUid,
        requestParameters.labelSchemaUids,
        requestParameters.userFormatted,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DatasetTagTypesApi - axios parameter creator
 * @export
 */
export const DatasetTagTypesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Tag Type
     * @param {number} datasetUid
     * @param {CreateDatasetTagTypeParams} createDatasetTagTypeParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTagTypeDatasetDatasetUidTagTypePost: async (
      datasetUid: number,
      createDatasetTagTypeParams: CreateDatasetTagTypeParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'createTagTypeDatasetDatasetUidTagTypePost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'createDatasetTagTypeParams' is not null or undefined
      assertParamExists(
        'createTagTypeDatasetDatasetUidTagTypePost',
        'createDatasetTagTypeParams',
        createDatasetTagTypeParams,
      );
      const localVarPath = `/dataset/{dataset_uid}/tag-type`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDatasetTagTypeParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Tag Type
     * @param {number} datasetUid
     * @param {number} tagTypeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete: async (
      datasetUid: number,
      tagTypeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'deleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'tagTypeUid' is not null or undefined
      assertParamExists(
        'deleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete',
        'tagTypeUid',
        tagTypeUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/tag-type/{tag_type_uid}`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'tag_type_uid'}}`, encodeURIComponent(String(tagTypeUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Dataset Tag Mapping
     * @param {number} datasetUid
     * @param {GetTagMapReq} getTagMapReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost: async (
      datasetUid: number,
      getTagMapReq: GetTagMapReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'getTagMapReq' is not null or undefined
      assertParamExists(
        'getDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost',
        'getTagMapReq',
        getTagMapReq,
      );
      const localVarPath = `/dataset/{dataset_uid}/get-dataset-tag-map`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getTagMapReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Tag Type
     * @param {number} datasetUid
     * @param {number} tagTypeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagTypeDatasetDatasetUidTagTypeTagTypeUidGet: async (
      datasetUid: number,
      tagTypeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getTagTypeDatasetDatasetUidTagTypeTagTypeUidGet',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'tagTypeUid' is not null or undefined
      assertParamExists(
        'getTagTypeDatasetDatasetUidTagTypeTagTypeUidGet',
        'tagTypeUid',
        tagTypeUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/tag-type/{tag_type_uid}`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'tag_type_uid'}}`, encodeURIComponent(String(tagTypeUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Tag Types
     * @param {number} datasetUid
     * @param {boolean} [isContextTagType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagTypesDatasetDatasetUidTagTypeGet: async (
      datasetUid: number,
      isContextTagType?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getTagTypesDatasetDatasetUidTagTypeGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/tag-type`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isContextTagType !== undefined) {
        localVarQueryParameter['is_context_tag_type'] = isContextTagType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Dataset Tag Mapping
     * @param {number} datasetUid
     * @param {UpdateTagMapReq} updateTagMapReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost: async (
      datasetUid: number,
      updateTagMapReq: UpdateTagMapReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'updateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'updateTagMapReq' is not null or undefined
      assertParamExists(
        'updateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost',
        'updateTagMapReq',
        updateTagMapReq,
      );
      const localVarPath =
        `/dataset/{dataset_uid}/update-dataset-tag-map`.replace(
          `{${'dataset_uid'}}`,
          encodeURIComponent(String(datasetUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTagMapReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Tag Type
     * @param {number} datasetUid
     * @param {number} tagTypeUid
     * @param {UpdateTagType} updateTagType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut: async (
      datasetUid: number,
      tagTypeUid: number,
      updateTagType: UpdateTagType,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'tagTypeUid' is not null or undefined
      assertParamExists(
        'updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut',
        'tagTypeUid',
        tagTypeUid,
      );
      // verify required parameter 'updateTagType' is not null or undefined
      assertParamExists(
        'updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut',
        'updateTagType',
        updateTagType,
      );
      const localVarPath = `/dataset/{dataset_uid}/tag-type/{tag_type_uid}`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'tag_type_uid'}}`, encodeURIComponent(String(tagTypeUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTagType,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatasetTagTypesApi - functional programming interface
 * @export
 */
export const DatasetTagTypesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DatasetTagTypesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Tag Type
     * @param {number} datasetUid
     * @param {CreateDatasetTagTypeParams} createDatasetTagTypeParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTagTypeDatasetDatasetUidTagTypePost(
      datasetUid: number,
      createDatasetTagTypeParams: CreateDatasetTagTypeParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetTagType>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createTagTypeDatasetDatasetUidTagTypePost(
          datasetUid,
          createDatasetTagTypeParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetTagTypesApi.createTagTypeDatasetDatasetUidTagTypePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Tag Type
     * @param {number} datasetUid
     * @param {number} tagTypeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete(
      datasetUid: number,
      tagTypeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete(
          datasetUid,
          tagTypeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetTagTypesApi.deleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Dataset Tag Mapping
     * @param {number} datasetUid
     * @param {GetTagMapReq} getTagMapReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost(
      datasetUid: number,
      getTagMapReq: GetTagMapReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: any }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost(
          datasetUid,
          getTagMapReq,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetTagTypesApi.getDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Tag Type
     * @param {number} datasetUid
     * @param {number} tagTypeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTagTypeDatasetDatasetUidTagTypeTagTypeUidGet(
      datasetUid: number,
      tagTypeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetTagType>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTagTypeDatasetDatasetUidTagTypeTagTypeUidGet(
          datasetUid,
          tagTypeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetTagTypesApi.getTagTypeDatasetDatasetUidTagTypeTagTypeUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Tag Types
     * @param {number} datasetUid
     * @param {boolean} [isContextTagType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTagTypesDatasetDatasetUidTagTypeGet(
      datasetUid: number,
      isContextTagType?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasetTagType>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTagTypesDatasetDatasetUidTagTypeGet(
          datasetUid,
          isContextTagType,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetTagTypesApi.getTagTypesDatasetDatasetUidTagTypeGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Dataset Tag Mapping
     * @param {number} datasetUid
     * @param {UpdateTagMapReq} updateTagMapReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost(
      datasetUid: number,
      updateTagMapReq: UpdateTagMapReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost(
          datasetUid,
          updateTagMapReq,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetTagTypesApi.updateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Tag Type
     * @param {number} datasetUid
     * @param {number} tagTypeUid
     * @param {UpdateTagType} updateTagType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut(
      datasetUid: number,
      tagTypeUid: number,
      updateTagType: UpdateTagType,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetTagType>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut(
          datasetUid,
          tagTypeUid,
          updateTagType,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetTagTypesApi.updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DatasetTagTypesApi - factory interface
 * @export
 */
export const DatasetTagTypesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DatasetTagTypesApiFp(configuration);
  return {
    /**
     *
     * @summary Create Tag Type
     * @param {DatasetTagTypesApiCreateTagTypeDatasetDatasetUidTagTypePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTagTypeDatasetDatasetUidTagTypePost(
      requestParameters: DatasetTagTypesApiCreateTagTypeDatasetDatasetUidTagTypePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetTagType> {
      return localVarFp
        .createTagTypeDatasetDatasetUidTagTypePost(
          requestParameters.datasetUid,
          requestParameters.createDatasetTagTypeParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Tag Type
     * @param {DatasetTagTypesApiDeleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete(
      requestParameters: DatasetTagTypesApiDeleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete(
          requestParameters.datasetUid,
          requestParameters.tagTypeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Dataset Tag Mapping
     * @param {DatasetTagTypesApiGetDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost(
      requestParameters: DatasetTagTypesApiGetDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: any }> {
      return localVarFp
        .getDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost(
          requestParameters.datasetUid,
          requestParameters.getTagMapReq,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Tag Type
     * @param {DatasetTagTypesApiGetTagTypeDatasetDatasetUidTagTypeTagTypeUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagTypeDatasetDatasetUidTagTypeTagTypeUidGet(
      requestParameters: DatasetTagTypesApiGetTagTypeDatasetDatasetUidTagTypeTagTypeUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetTagType> {
      return localVarFp
        .getTagTypeDatasetDatasetUidTagTypeTagTypeUidGet(
          requestParameters.datasetUid,
          requestParameters.tagTypeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Tag Types
     * @param {DatasetTagTypesApiGetTagTypesDatasetDatasetUidTagTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagTypesDatasetDatasetUidTagTypeGet(
      requestParameters: DatasetTagTypesApiGetTagTypesDatasetDatasetUidTagTypeGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasetTagType>> {
      return localVarFp
        .getTagTypesDatasetDatasetUidTagTypeGet(
          requestParameters.datasetUid,
          requestParameters.isContextTagType,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Dataset Tag Mapping
     * @param {DatasetTagTypesApiUpdateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost(
      requestParameters: DatasetTagTypesApiUpdateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost(
          requestParameters.datasetUid,
          requestParameters.updateTagMapReq,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Tag Type
     * @param {DatasetTagTypesApiUpdateTagTypeDatasetDatasetUidTagTypeTagTypeUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut(
      requestParameters: DatasetTagTypesApiUpdateTagTypeDatasetDatasetUidTagTypeTagTypeUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetTagType> {
      return localVarFp
        .updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut(
          requestParameters.datasetUid,
          requestParameters.tagTypeUid,
          requestParameters.updateTagType,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createTagTypeDatasetDatasetUidTagTypePost operation in DatasetTagTypesApi.
 * @export
 * @interface DatasetTagTypesApiCreateTagTypeDatasetDatasetUidTagTypePostRequest
 */
export interface DatasetTagTypesApiCreateTagTypeDatasetDatasetUidTagTypePostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetTagTypesApiCreateTagTypeDatasetDatasetUidTagTypePost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {CreateDatasetTagTypeParams}
   * @memberof DatasetTagTypesApiCreateTagTypeDatasetDatasetUidTagTypePost
   */
  readonly createDatasetTagTypeParams: CreateDatasetTagTypeParams;
}

/**
 * Request parameters for deleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete operation in DatasetTagTypesApi.
 * @export
 * @interface DatasetTagTypesApiDeleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDeleteRequest
 */
export interface DatasetTagTypesApiDeleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetTagTypesApiDeleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetTagTypesApiDeleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete
   */
  readonly tagTypeUid: number;
}

/**
 * Request parameters for getDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost operation in DatasetTagTypesApi.
 * @export
 * @interface DatasetTagTypesApiGetDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPostRequest
 */
export interface DatasetTagTypesApiGetDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetTagTypesApiGetDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {GetTagMapReq}
   * @memberof DatasetTagTypesApiGetDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost
   */
  readonly getTagMapReq: GetTagMapReq;
}

/**
 * Request parameters for getTagTypeDatasetDatasetUidTagTypeTagTypeUidGet operation in DatasetTagTypesApi.
 * @export
 * @interface DatasetTagTypesApiGetTagTypeDatasetDatasetUidTagTypeTagTypeUidGetRequest
 */
export interface DatasetTagTypesApiGetTagTypeDatasetDatasetUidTagTypeTagTypeUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetTagTypesApiGetTagTypeDatasetDatasetUidTagTypeTagTypeUidGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetTagTypesApiGetTagTypeDatasetDatasetUidTagTypeTagTypeUidGet
   */
  readonly tagTypeUid: number;
}

/**
 * Request parameters for getTagTypesDatasetDatasetUidTagTypeGet operation in DatasetTagTypesApi.
 * @export
 * @interface DatasetTagTypesApiGetTagTypesDatasetDatasetUidTagTypeGetRequest
 */
export interface DatasetTagTypesApiGetTagTypesDatasetDatasetUidTagTypeGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetTagTypesApiGetTagTypesDatasetDatasetUidTagTypeGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {boolean}
   * @memberof DatasetTagTypesApiGetTagTypesDatasetDatasetUidTagTypeGet
   */
  readonly isContextTagType?: boolean;
}

/**
 * Request parameters for updateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost operation in DatasetTagTypesApi.
 * @export
 * @interface DatasetTagTypesApiUpdateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPostRequest
 */
export interface DatasetTagTypesApiUpdateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetTagTypesApiUpdateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {UpdateTagMapReq}
   * @memberof DatasetTagTypesApiUpdateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost
   */
  readonly updateTagMapReq: UpdateTagMapReq;
}

/**
 * Request parameters for updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut operation in DatasetTagTypesApi.
 * @export
 * @interface DatasetTagTypesApiUpdateTagTypeDatasetDatasetUidTagTypeTagTypeUidPutRequest
 */
export interface DatasetTagTypesApiUpdateTagTypeDatasetDatasetUidTagTypeTagTypeUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetTagTypesApiUpdateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasetTagTypesApiUpdateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut
   */
  readonly tagTypeUid: number;

  /**
   *
   * @type {UpdateTagType}
   * @memberof DatasetTagTypesApiUpdateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut
   */
  readonly updateTagType: UpdateTagType;
}

/**
 * DatasetTagTypesApi - object-oriented interface
 * @export
 * @class DatasetTagTypesApi
 * @extends {BaseAPI}
 */
export class DatasetTagTypesApi extends BaseAPI {
  /**
   *
   * @summary Create Tag Type
   * @param {DatasetTagTypesApiCreateTagTypeDatasetDatasetUidTagTypePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetTagTypesApi
   */
  public createTagTypeDatasetDatasetUidTagTypePost(
    requestParameters: DatasetTagTypesApiCreateTagTypeDatasetDatasetUidTagTypePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetTagTypesApiFp(this.configuration)
      .createTagTypeDatasetDatasetUidTagTypePost(
        requestParameters.datasetUid,
        requestParameters.createDatasetTagTypeParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Tag Type
   * @param {DatasetTagTypesApiDeleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetTagTypesApi
   */
  public deleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete(
    requestParameters: DatasetTagTypesApiDeleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetTagTypesApiFp(this.configuration)
      .deleteTagTypeDatasetDatasetUidTagTypeTagTypeUidDelete(
        requestParameters.datasetUid,
        requestParameters.tagTypeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Dataset Tag Mapping
   * @param {DatasetTagTypesApiGetDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetTagTypesApi
   */
  public getDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost(
    requestParameters: DatasetTagTypesApiGetDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetTagTypesApiFp(this.configuration)
      .getDatasetTagMappingDatasetDatasetUidGetDatasetTagMapPost(
        requestParameters.datasetUid,
        requestParameters.getTagMapReq,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Tag Type
   * @param {DatasetTagTypesApiGetTagTypeDatasetDatasetUidTagTypeTagTypeUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetTagTypesApi
   */
  public getTagTypeDatasetDatasetUidTagTypeTagTypeUidGet(
    requestParameters: DatasetTagTypesApiGetTagTypeDatasetDatasetUidTagTypeTagTypeUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetTagTypesApiFp(this.configuration)
      .getTagTypeDatasetDatasetUidTagTypeTagTypeUidGet(
        requestParameters.datasetUid,
        requestParameters.tagTypeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Tag Types
   * @param {DatasetTagTypesApiGetTagTypesDatasetDatasetUidTagTypeGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetTagTypesApi
   */
  public getTagTypesDatasetDatasetUidTagTypeGet(
    requestParameters: DatasetTagTypesApiGetTagTypesDatasetDatasetUidTagTypeGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetTagTypesApiFp(this.configuration)
      .getTagTypesDatasetDatasetUidTagTypeGet(
        requestParameters.datasetUid,
        requestParameters.isContextTagType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Dataset Tag Mapping
   * @param {DatasetTagTypesApiUpdateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetTagTypesApi
   */
  public updateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost(
    requestParameters: DatasetTagTypesApiUpdateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetTagTypesApiFp(this.configuration)
      .updateDatasetTagMappingDatasetDatasetUidUpdateDatasetTagMapPost(
        requestParameters.datasetUid,
        requestParameters.updateTagMapReq,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Tag Type
   * @param {DatasetTagTypesApiUpdateTagTypeDatasetDatasetUidTagTypeTagTypeUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetTagTypesApi
   */
  public updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut(
    requestParameters: DatasetTagTypesApiUpdateTagTypeDatasetDatasetUidTagTypeTagTypeUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetTagTypesApiFp(this.configuration)
      .updateTagTypeDatasetDatasetUidTagTypeTagTypeUidPut(
        requestParameters.datasetUid,
        requestParameters.tagTypeUid,
        requestParameters.updateTagType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DatasetTemplateApi - axios parameter creator
 * @export
 */
export const DatasetTemplateApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Apply Dataset Template
     * @param {number} datasetUid
     * @param {ApplyDatasetTemplatePayload} applyDatasetTemplatePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyDatasetTemplateDatasetsDatasetUidApplyTemplatePost: async (
      datasetUid: number,
      applyDatasetTemplatePayload: ApplyDatasetTemplatePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'applyDatasetTemplateDatasetsDatasetUidApplyTemplatePost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'applyDatasetTemplatePayload' is not null or undefined
      assertParamExists(
        'applyDatasetTemplateDatasetsDatasetUidApplyTemplatePost',
        'applyDatasetTemplatePayload',
        applyDatasetTemplatePayload,
      );
      const localVarPath = `/datasets/{dataset_uid}/apply-template`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applyDatasetTemplatePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Dataset Templates
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetTemplatesDatasetTemplatesGet: async (
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dataset-templates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatasetTemplateApi - functional programming interface
 * @export
 */
export const DatasetTemplateApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DatasetTemplateApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Apply Dataset Template
     * @param {number} datasetUid
     * @param {ApplyDatasetTemplatePayload} applyDatasetTemplatePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyDatasetTemplateDatasetsDatasetUidApplyTemplatePost(
      datasetUid: number,
      applyDatasetTemplatePayload: ApplyDatasetTemplatePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyDatasetTemplateDatasetsDatasetUidApplyTemplatePost(
          datasetUid,
          applyDatasetTemplatePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetTemplateApi.applyDatasetTemplateDatasetsDatasetUidApplyTemplatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Dataset Templates
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatasetTemplatesDatasetTemplatesGet(
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasetTemplate>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDatasetTemplatesDatasetTemplatesGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetTemplateApi.getDatasetTemplatesDatasetTemplatesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DatasetTemplateApi - factory interface
 * @export
 */
export const DatasetTemplateApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DatasetTemplateApiFp(configuration);
  return {
    /**
     *
     * @summary Apply Dataset Template
     * @param {DatasetTemplateApiApplyDatasetTemplateDatasetsDatasetUidApplyTemplatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyDatasetTemplateDatasetsDatasetUidApplyTemplatePost(
      requestParameters: DatasetTemplateApiApplyDatasetTemplateDatasetsDatasetUidApplyTemplatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<object> {
      return localVarFp
        .applyDatasetTemplateDatasetsDatasetUidApplyTemplatePost(
          requestParameters.datasetUid,
          requestParameters.applyDatasetTemplatePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Dataset Templates
     * @param {DatasetTemplateApiGetDatasetTemplatesDatasetTemplatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetTemplatesDatasetTemplatesGet(
      requestParameters: DatasetTemplateApiGetDatasetTemplatesDatasetTemplatesGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasetTemplate>> {
      return localVarFp
        .getDatasetTemplatesDatasetTemplatesGet(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for applyDatasetTemplateDatasetsDatasetUidApplyTemplatePost operation in DatasetTemplateApi.
 * @export
 * @interface DatasetTemplateApiApplyDatasetTemplateDatasetsDatasetUidApplyTemplatePostRequest
 */
export interface DatasetTemplateApiApplyDatasetTemplateDatasetsDatasetUidApplyTemplatePostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetTemplateApiApplyDatasetTemplateDatasetsDatasetUidApplyTemplatePost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {ApplyDatasetTemplatePayload}
   * @memberof DatasetTemplateApiApplyDatasetTemplateDatasetsDatasetUidApplyTemplatePost
   */
  readonly applyDatasetTemplatePayload: ApplyDatasetTemplatePayload;
}

/**
 * Request parameters for getDatasetTemplatesDatasetTemplatesGet operation in DatasetTemplateApi.
 * @export
 * @interface DatasetTemplateApiGetDatasetTemplatesDatasetTemplatesGetRequest
 */
export interface DatasetTemplateApiGetDatasetTemplatesDatasetTemplatesGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetTemplateApiGetDatasetTemplatesDatasetTemplatesGet
   */
  readonly workspaceUid?: number;
}

/**
 * DatasetTemplateApi - object-oriented interface
 * @export
 * @class DatasetTemplateApi
 * @extends {BaseAPI}
 */
export class DatasetTemplateApi extends BaseAPI {
  /**
   *
   * @summary Apply Dataset Template
   * @param {DatasetTemplateApiApplyDatasetTemplateDatasetsDatasetUidApplyTemplatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetTemplateApi
   */
  public applyDatasetTemplateDatasetsDatasetUidApplyTemplatePost(
    requestParameters: DatasetTemplateApiApplyDatasetTemplateDatasetsDatasetUidApplyTemplatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetTemplateApiFp(this.configuration)
      .applyDatasetTemplateDatasetsDatasetUidApplyTemplatePost(
        requestParameters.datasetUid,
        requestParameters.applyDatasetTemplatePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Dataset Templates
   * @param {DatasetTemplateApiGetDatasetTemplatesDatasetTemplatesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetTemplateApi
   */
  public getDatasetTemplatesDatasetTemplatesGet(
    requestParameters: DatasetTemplateApiGetDatasetTemplatesDatasetTemplatesGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetTemplateApiFp(this.configuration)
      .getDatasetTemplatesDatasetTemplatesGet(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DatasetViewsApi - axios parameter creator
 * @export
 */
export const DatasetViewsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Dataset View
     * @param {number} datasetUid
     * @param {CreateDatasetViewParams} createDatasetViewParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDatasetViewDatasetDatasetUidViewsPost: async (
      datasetUid: number,
      createDatasetViewParams: CreateDatasetViewParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'createDatasetViewDatasetDatasetUidViewsPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'createDatasetViewParams' is not null or undefined
      assertParamExists(
        'createDatasetViewDatasetDatasetUidViewsPost',
        'createDatasetViewParams',
        createDatasetViewParams,
      );
      const localVarPath = `/dataset/{dataset_uid}/views`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDatasetViewParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Dataset View
     * @param {number} datasetViewUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDatasetViewDatasetViewsDatasetViewUidDelete: async (
      datasetViewUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetViewUid' is not null or undefined
      assertParamExists(
        'deleteDatasetViewDatasetViewsDatasetViewUidDelete',
        'datasetViewUid',
        datasetViewUid,
      );
      const localVarPath = `/dataset/views/{dataset_view_uid}`.replace(
        `{${'dataset_view_uid'}}`,
        encodeURIComponent(String(datasetViewUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Dataset View
     * @param {number} datasetViewUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetViewDatasetViewsDatasetViewUidGet: async (
      datasetViewUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetViewUid' is not null or undefined
      assertParamExists(
        'getDatasetViewDatasetViewsDatasetViewUidGet',
        'datasetViewUid',
        datasetViewUid,
      );
      const localVarPath = `/dataset/views/{dataset_view_uid}`.replace(
        `{${'dataset_view_uid'}}`,
        encodeURIComponent(String(datasetViewUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Dataset Views
     * @param {number} datasetUid
     * @param {Array<number>} [labelSchemaUids]
     * @param {boolean} [addViewsWithoutLs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetViewsDatasetDatasetUidViewsGet: async (
      datasetUid: number,
      labelSchemaUids?: Array<number>,
      addViewsWithoutLs?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getDatasetViewsDatasetDatasetUidViewsGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/views`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (labelSchemaUids) {
        localVarQueryParameter['label_schema_uids'] = labelSchemaUids;
      }

      if (addViewsWithoutLs !== undefined) {
        localVarQueryParameter['add_views_without_ls'] = addViewsWithoutLs;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Dataset View
     * @param {number} datasetViewUid
     * @param {DatasetViewUpdateParams} datasetViewUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDatasetViewDatasetViewsDatasetViewUidPut: async (
      datasetViewUid: number,
      datasetViewUpdateParams: DatasetViewUpdateParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetViewUid' is not null or undefined
      assertParamExists(
        'updateDatasetViewDatasetViewsDatasetViewUidPut',
        'datasetViewUid',
        datasetViewUid,
      );
      // verify required parameter 'datasetViewUpdateParams' is not null or undefined
      assertParamExists(
        'updateDatasetViewDatasetViewsDatasetViewUidPut',
        'datasetViewUpdateParams',
        datasetViewUpdateParams,
      );
      const localVarPath = `/dataset/views/{dataset_view_uid}`.replace(
        `{${'dataset_view_uid'}}`,
        encodeURIComponent(String(datasetViewUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        datasetViewUpdateParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatasetViewsApi - functional programming interface
 * @export
 */
export const DatasetViewsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DatasetViewsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Dataset View
     * @param {number} datasetUid
     * @param {CreateDatasetViewParams} createDatasetViewParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDatasetViewDatasetDatasetUidViewsPost(
      datasetUid: number,
      createDatasetViewParams: CreateDatasetViewParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetView>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createDatasetViewDatasetDatasetUidViewsPost(
          datasetUid,
          createDatasetViewParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetViewsApi.createDatasetViewDatasetDatasetUidViewsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Dataset View
     * @param {number} datasetViewUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDatasetViewDatasetViewsDatasetViewUidDelete(
      datasetViewUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteDatasetViewDatasetViewsDatasetViewUidDelete(
          datasetViewUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetViewsApi.deleteDatasetViewDatasetViewsDatasetViewUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Dataset View
     * @param {number} datasetViewUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatasetViewDatasetViewsDatasetViewUidGet(
      datasetViewUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetView>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDatasetViewDatasetViewsDatasetViewUidGet(
          datasetViewUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetViewsApi.getDatasetViewDatasetViewsDatasetViewUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Dataset Views
     * @param {number} datasetUid
     * @param {Array<number>} [labelSchemaUids]
     * @param {boolean} [addViewsWithoutLs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatasetViewsDatasetDatasetUidViewsGet(
      datasetUid: number,
      labelSchemaUids?: Array<number>,
      addViewsWithoutLs?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasetView>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDatasetViewsDatasetDatasetUidViewsGet(
          datasetUid,
          labelSchemaUids,
          addViewsWithoutLs,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetViewsApi.getDatasetViewsDatasetDatasetUidViewsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Dataset View
     * @param {number} datasetViewUid
     * @param {DatasetViewUpdateParams} datasetViewUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDatasetViewDatasetViewsDatasetViewUidPut(
      datasetViewUid: number,
      datasetViewUpdateParams: DatasetViewUpdateParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDatasetViewDatasetViewsDatasetViewUidPut(
          datasetViewUid,
          datasetViewUpdateParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetViewsApi.updateDatasetViewDatasetViewsDatasetViewUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DatasetViewsApi - factory interface
 * @export
 */
export const DatasetViewsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DatasetViewsApiFp(configuration);
  return {
    /**
     *
     * @summary Create Dataset View
     * @param {DatasetViewsApiCreateDatasetViewDatasetDatasetUidViewsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDatasetViewDatasetDatasetUidViewsPost(
      requestParameters: DatasetViewsApiCreateDatasetViewDatasetDatasetUidViewsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetView> {
      return localVarFp
        .createDatasetViewDatasetDatasetUidViewsPost(
          requestParameters.datasetUid,
          requestParameters.createDatasetViewParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Dataset View
     * @param {DatasetViewsApiDeleteDatasetViewDatasetViewsDatasetViewUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDatasetViewDatasetViewsDatasetViewUidDelete(
      requestParameters: DatasetViewsApiDeleteDatasetViewDatasetViewsDatasetViewUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteDatasetViewDatasetViewsDatasetViewUidDelete(
          requestParameters.datasetViewUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Dataset View
     * @param {DatasetViewsApiGetDatasetViewDatasetViewsDatasetViewUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetViewDatasetViewsDatasetViewUidGet(
      requestParameters: DatasetViewsApiGetDatasetViewDatasetViewsDatasetViewUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetView> {
      return localVarFp
        .getDatasetViewDatasetViewsDatasetViewUidGet(
          requestParameters.datasetViewUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Dataset Views
     * @param {DatasetViewsApiGetDatasetViewsDatasetDatasetUidViewsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasetViewsDatasetDatasetUidViewsGet(
      requestParameters: DatasetViewsApiGetDatasetViewsDatasetDatasetUidViewsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasetView>> {
      return localVarFp
        .getDatasetViewsDatasetDatasetUidViewsGet(
          requestParameters.datasetUid,
          requestParameters.labelSchemaUids,
          requestParameters.addViewsWithoutLs,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Dataset View
     * @param {DatasetViewsApiUpdateDatasetViewDatasetViewsDatasetViewUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDatasetViewDatasetViewsDatasetViewUidPut(
      requestParameters: DatasetViewsApiUpdateDatasetViewDatasetViewsDatasetViewUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateDatasetViewDatasetViewsDatasetViewUidPut(
          requestParameters.datasetViewUid,
          requestParameters.datasetViewUpdateParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createDatasetViewDatasetDatasetUidViewsPost operation in DatasetViewsApi.
 * @export
 * @interface DatasetViewsApiCreateDatasetViewDatasetDatasetUidViewsPostRequest
 */
export interface DatasetViewsApiCreateDatasetViewDatasetDatasetUidViewsPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetViewsApiCreateDatasetViewDatasetDatasetUidViewsPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {CreateDatasetViewParams}
   * @memberof DatasetViewsApiCreateDatasetViewDatasetDatasetUidViewsPost
   */
  readonly createDatasetViewParams: CreateDatasetViewParams;
}

/**
 * Request parameters for deleteDatasetViewDatasetViewsDatasetViewUidDelete operation in DatasetViewsApi.
 * @export
 * @interface DatasetViewsApiDeleteDatasetViewDatasetViewsDatasetViewUidDeleteRequest
 */
export interface DatasetViewsApiDeleteDatasetViewDatasetViewsDatasetViewUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetViewsApiDeleteDatasetViewDatasetViewsDatasetViewUidDelete
   */
  readonly datasetViewUid: number;
}

/**
 * Request parameters for getDatasetViewDatasetViewsDatasetViewUidGet operation in DatasetViewsApi.
 * @export
 * @interface DatasetViewsApiGetDatasetViewDatasetViewsDatasetViewUidGetRequest
 */
export interface DatasetViewsApiGetDatasetViewDatasetViewsDatasetViewUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetViewsApiGetDatasetViewDatasetViewsDatasetViewUidGet
   */
  readonly datasetViewUid: number;
}

/**
 * Request parameters for getDatasetViewsDatasetDatasetUidViewsGet operation in DatasetViewsApi.
 * @export
 * @interface DatasetViewsApiGetDatasetViewsDatasetDatasetUidViewsGetRequest
 */
export interface DatasetViewsApiGetDatasetViewsDatasetDatasetUidViewsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetViewsApiGetDatasetViewsDatasetDatasetUidViewsGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {Array<number>}
   * @memberof DatasetViewsApiGetDatasetViewsDatasetDatasetUidViewsGet
   */
  readonly labelSchemaUids?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof DatasetViewsApiGetDatasetViewsDatasetDatasetUidViewsGet
   */
  readonly addViewsWithoutLs?: boolean;
}

/**
 * Request parameters for updateDatasetViewDatasetViewsDatasetViewUidPut operation in DatasetViewsApi.
 * @export
 * @interface DatasetViewsApiUpdateDatasetViewDatasetViewsDatasetViewUidPutRequest
 */
export interface DatasetViewsApiUpdateDatasetViewDatasetViewsDatasetViewUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetViewsApiUpdateDatasetViewDatasetViewsDatasetViewUidPut
   */
  readonly datasetViewUid: number;

  /**
   *
   * @type {DatasetViewUpdateParams}
   * @memberof DatasetViewsApiUpdateDatasetViewDatasetViewsDatasetViewUidPut
   */
  readonly datasetViewUpdateParams: DatasetViewUpdateParams;
}

/**
 * DatasetViewsApi - object-oriented interface
 * @export
 * @class DatasetViewsApi
 * @extends {BaseAPI}
 */
export class DatasetViewsApi extends BaseAPI {
  /**
   *
   * @summary Create Dataset View
   * @param {DatasetViewsApiCreateDatasetViewDatasetDatasetUidViewsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetViewsApi
   */
  public createDatasetViewDatasetDatasetUidViewsPost(
    requestParameters: DatasetViewsApiCreateDatasetViewDatasetDatasetUidViewsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetViewsApiFp(this.configuration)
      .createDatasetViewDatasetDatasetUidViewsPost(
        requestParameters.datasetUid,
        requestParameters.createDatasetViewParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Dataset View
   * @param {DatasetViewsApiDeleteDatasetViewDatasetViewsDatasetViewUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetViewsApi
   */
  public deleteDatasetViewDatasetViewsDatasetViewUidDelete(
    requestParameters: DatasetViewsApiDeleteDatasetViewDatasetViewsDatasetViewUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetViewsApiFp(this.configuration)
      .deleteDatasetViewDatasetViewsDatasetViewUidDelete(
        requestParameters.datasetViewUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Dataset View
   * @param {DatasetViewsApiGetDatasetViewDatasetViewsDatasetViewUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetViewsApi
   */
  public getDatasetViewDatasetViewsDatasetViewUidGet(
    requestParameters: DatasetViewsApiGetDatasetViewDatasetViewsDatasetViewUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetViewsApiFp(this.configuration)
      .getDatasetViewDatasetViewsDatasetViewUidGet(
        requestParameters.datasetViewUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Dataset Views
   * @param {DatasetViewsApiGetDatasetViewsDatasetDatasetUidViewsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetViewsApi
   */
  public getDatasetViewsDatasetDatasetUidViewsGet(
    requestParameters: DatasetViewsApiGetDatasetViewsDatasetDatasetUidViewsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetViewsApiFp(this.configuration)
      .getDatasetViewsDatasetDatasetUidViewsGet(
        requestParameters.datasetUid,
        requestParameters.labelSchemaUids,
        requestParameters.addViewsWithoutLs,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Dataset View
   * @param {DatasetViewsApiUpdateDatasetViewDatasetViewsDatasetViewUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetViewsApi
   */
  public updateDatasetViewDatasetViewsDatasetViewUidPut(
    requestParameters: DatasetViewsApiUpdateDatasetViewDatasetViewsDatasetViewUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetViewsApiFp(this.configuration)
      .updateDatasetViewDatasetViewsDatasetViewUidPut(
        requestParameters.datasetViewUid,
        requestParameters.datasetViewUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DatasetsApi - axios parameter creator
 * @export
 */
export const DatasetsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Add dataset to datasets table.
     * @summary Create Dataset
     * @param {Dataset} dataset
     * @param {boolean} [enableMta]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataset: async (
      dataset: Dataset,
      enableMta?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dataset' is not null or undefined
      assertParamExists('createDataset', 'dataset', dataset);
      const localVarPath = `/datasets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (enableMta !== undefined) {
        localVarQueryParameter['enable_mta'] = enableMta;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataset,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete dataset and corresponding tables.
     * @summary Remove Dataset
     * @param {number} datasetUid
     * @param {RemoveDatasetRequest} removeDatasetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataset: async (
      datasetUid: number,
      removeDatasetRequest: RemoveDatasetRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists('deleteDataset', 'datasetUid', datasetUid);
      // verify required parameter 'removeDatasetRequest' is not null or undefined
      assertParamExists(
        'deleteDataset',
        'removeDatasetRequest',
        removeDatasetRequest,
      );
      const localVarPath = `/datasets/{dataset_uid}`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        removeDatasetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update editable fields in the dataset metadata, currently only the name
     * @summary Edit Dataset
     * @param {number} datasetUid
     * @param {Dataset} dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDataset: async (
      datasetUid: number,
      dataset: Dataset,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists('editDataset', 'datasetUid', datasetUid);
      // verify required parameter 'dataset' is not null or undefined
      assertParamExists('editDataset', 'dataset', dataset);
      const localVarPath = `/datasets/{dataset_uid}`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataset,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch a dataset by its UID.
     * @summary Fetch Dataset By Uid
     * @param {number} datasetUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDatasetByUidDatasetsDatasetUidGet: async (
      datasetUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'fetchDatasetByUidDatasetsDatasetUidGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/datasets/{dataset_uid}`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Dataset Column Types
     * @param {number} datasetUid
     * @param {boolean} [intersection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet: async (
      datasetUid: number,
      intersection?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'fetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath =
        `/datasets/{dataset_uid}/dataframe-column-types`.replace(
          `{${'dataset_uid'}}`,
          encodeURIComponent(String(datasetUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (intersection !== undefined) {
        localVarQueryParameter['intersection'] = intersection;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List column names of the dataset. If the use_preprocessed_dataset is True, then it returns the dataset after preprocessing if the dataset has preprocessing set up. Otherwise it returns the original dataset columns.
     * @summary Fetch Dataset Columns
     * @param {number} datasetUid
     * @param {boolean} [intersection]
     * @param {boolean} [usePreprocessedDataset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet: async (
      datasetUid: number,
      intersection?: boolean,
      usePreprocessedDataset?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'fetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/datasets/{dataset_uid}/dataframe-columns`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (intersection !== undefined) {
        localVarQueryParameter['intersection'] = intersection;
      }

      if (usePreprocessedDataset !== undefined) {
        localVarQueryParameter['use_preprocessed_dataset'] =
          usePreprocessedDataset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete dataset and corresponding tables.
     * @summary Garbage Collect Datasets Applications
     * @param {GarbageCollectDatasetApplicationParams} garbageCollectDatasetApplicationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    garbageCollectDatasetApplications: async (
      garbageCollectDatasetApplicationParams: GarbageCollectDatasetApplicationParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'garbageCollectDatasetApplicationParams' is not null or undefined
      assertParamExists(
        'garbageCollectDatasetApplications',
        'garbageCollectDatasetApplicationParams',
        garbageCollectDatasetApplicationParams,
      );
      const localVarPath = `/datasets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        garbageCollectDatasetApplicationParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List all datasets the requesting user has access to.
     * @summary List Datasets
     * @param {string} [name]
     * @param {number} [workspaceUid]
     * @param {boolean} [excludeNonArrowDatasets]
     * @param {number} [nextCursor]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [search]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasets: async (
      name?: string,
      workspaceUid?: number,
      excludeNonArrowDatasets?: boolean,
      nextCursor?: number,
      limit?: number,
      offset?: number,
      search?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/datasets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      if (excludeNonArrowDatasets !== undefined) {
        localVarQueryParameter['exclude_non_arrow_datasets'] =
          excludeNonArrowDatasets;
      }

      if (nextCursor !== undefined) {
        localVarQueryParameter['next_cursor'] = nextCursor;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List all dataset splits for given dataset.
     * @summary List Dataset Data Splits
     * @param {number} datasetUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDatasetDataSplitsDatasetsDatasetUidSplitsGet: async (
      datasetUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'listDatasetDataSplitsDatasetsDatasetUidSplitsGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/datasets/{dataset_uid}/splits`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatasetsApi - functional programming interface
 * @export
 */
export const DatasetsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DatasetsApiAxiosParamCreator(configuration);
  return {
    /**
     * Add dataset to datasets table.
     * @summary Create Dataset
     * @param {Dataset} dataset
     * @param {boolean} [enableMta]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDataset(
      dataset: Dataset,
      enableMta?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DatasetResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDataset(
        dataset,
        enableMta,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DatasetsApi.createDataset']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete dataset and corresponding tables.
     * @summary Remove Dataset
     * @param {number} datasetUid
     * @param {RemoveDatasetRequest} removeDatasetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDataset(
      datasetUid: number,
      removeDatasetRequest: RemoveDatasetRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RemoveDatasetResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDataset(
        datasetUid,
        removeDatasetRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DatasetsApi.deleteDataset']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update editable fields in the dataset metadata, currently only the name
     * @summary Edit Dataset
     * @param {number} datasetUid
     * @param {Dataset} dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editDataset(
      datasetUid: number,
      dataset: Dataset,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editDataset(
        datasetUid,
        dataset,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DatasetsApi.editDataset']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch a dataset by its UID.
     * @summary Fetch Dataset By Uid
     * @param {number} datasetUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDatasetByUidDatasetsDatasetUidGet(
      datasetUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DatasetResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchDatasetByUidDatasetsDatasetUidGet(
          datasetUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetsApi.fetchDatasetByUidDatasetsDatasetUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Dataset Column Types
     * @param {number} datasetUid
     * @param {boolean} [intersection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet(
      datasetUid: number,
      intersection?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FetchDatasetColumnTypesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet(
          datasetUid,
          intersection,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetsApi.fetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List column names of the dataset. If the use_preprocessed_dataset is True, then it returns the dataset after preprocessing if the dataset has preprocessing set up. Otherwise it returns the original dataset columns.
     * @summary Fetch Dataset Columns
     * @param {number} datasetUid
     * @param {boolean} [intersection]
     * @param {boolean} [usePreprocessedDataset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet(
      datasetUid: number,
      intersection?: boolean,
      usePreprocessedDataset?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet(
          datasetUid,
          intersection,
          usePreprocessedDataset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetsApi.fetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete dataset and corresponding tables.
     * @summary Garbage Collect Datasets Applications
     * @param {GarbageCollectDatasetApplicationParams} garbageCollectDatasetApplicationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async garbageCollectDatasetApplications(
      garbageCollectDatasetApplicationParams: GarbageCollectDatasetApplicationParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RemoveDatasetResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.garbageCollectDatasetApplications(
          garbageCollectDatasetApplicationParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DatasetsApi.garbageCollectDatasetApplications']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List all datasets the requesting user has access to.
     * @summary List Datasets
     * @param {string} [name]
     * @param {number} [workspaceUid]
     * @param {boolean} [excludeNonArrowDatasets]
     * @param {number} [nextCursor]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [search]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatasets(
      name?: string,
      workspaceUid?: number,
      excludeNonArrowDatasets?: boolean,
      nextCursor?: number,
      limit?: number,
      offset?: number,
      search?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ListDatasetsResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasets(
        name,
        workspaceUid,
        excludeNonArrowDatasets,
        nextCursor,
        limit,
        offset,
        search,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DatasetsApi.getDatasets']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List all dataset splits for given dataset.
     * @summary List Dataset Data Splits
     * @param {number} datasetUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listDatasetDataSplitsDatasetsDatasetUidSplitsGet(
      datasetUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listDatasetDataSplitsDatasetsDatasetUidSplitsGet(
          datasetUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasetsApi.listDatasetDataSplitsDatasetsDatasetUidSplitsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DatasetsApi - factory interface
 * @export
 */
export const DatasetsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DatasetsApiFp(configuration);
  return {
    /**
     * Add dataset to datasets table.
     * @summary Create Dataset
     * @param {DatasetsApiCreateDatasetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataset(
      requestParameters: DatasetsApiCreateDatasetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetResponse> {
      return localVarFp
        .createDataset(
          requestParameters.dataset,
          requestParameters.enableMta,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Delete dataset and corresponding tables.
     * @summary Remove Dataset
     * @param {DatasetsApiDeleteDatasetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataset(
      requestParameters: DatasetsApiDeleteDatasetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RemoveDatasetResponse> {
      return localVarFp
        .deleteDataset(
          requestParameters.datasetUid,
          requestParameters.removeDatasetRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Update editable fields in the dataset metadata, currently only the name
     * @summary Edit Dataset
     * @param {DatasetsApiEditDatasetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editDataset(
      requestParameters: DatasetsApiEditDatasetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .editDataset(
          requestParameters.datasetUid,
          requestParameters.dataset,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch a dataset by its UID.
     * @summary Fetch Dataset By Uid
     * @param {DatasetsApiFetchDatasetByUidDatasetsDatasetUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDatasetByUidDatasetsDatasetUidGet(
      requestParameters: DatasetsApiFetchDatasetByUidDatasetsDatasetUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasetResponse> {
      return localVarFp
        .fetchDatasetByUidDatasetsDatasetUidGet(
          requestParameters.datasetUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Dataset Column Types
     * @param {DatasetsApiFetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet(
      requestParameters: DatasetsApiFetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FetchDatasetColumnTypesResponse> {
      return localVarFp
        .fetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet(
          requestParameters.datasetUid,
          requestParameters.intersection,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List column names of the dataset. If the use_preprocessed_dataset is True, then it returns the dataset after preprocessing if the dataset has preprocessing set up. Otherwise it returns the original dataset columns.
     * @summary Fetch Dataset Columns
     * @param {DatasetsApiFetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet(
      requestParameters: DatasetsApiFetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet(
          requestParameters.datasetUid,
          requestParameters.intersection,
          requestParameters.usePreprocessedDataset,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Delete dataset and corresponding tables.
     * @summary Garbage Collect Datasets Applications
     * @param {DatasetsApiGarbageCollectDatasetApplicationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    garbageCollectDatasetApplications(
      requestParameters: DatasetsApiGarbageCollectDatasetApplicationsRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RemoveDatasetResponse> {
      return localVarFp
        .garbageCollectDatasetApplications(
          requestParameters.garbageCollectDatasetApplicationParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List all datasets the requesting user has access to.
     * @summary List Datasets
     * @param {DatasetsApiGetDatasetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasets(
      requestParameters: DatasetsApiGetDatasetsRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ListDatasetsResponse>> {
      return localVarFp
        .getDatasets(
          requestParameters.name,
          requestParameters.workspaceUid,
          requestParameters.excludeNonArrowDatasets,
          requestParameters.nextCursor,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.search,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List all dataset splits for given dataset.
     * @summary List Dataset Data Splits
     * @param {DatasetsApiListDatasetDataSplitsDatasetsDatasetUidSplitsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDatasetDataSplitsDatasetsDatasetUidSplitsGet(
      requestParameters: DatasetsApiListDatasetDataSplitsDatasetsDatasetUidSplitsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .listDatasetDataSplitsDatasetsDatasetUidSplitsGet(
          requestParameters.datasetUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createDataset operation in DatasetsApi.
 * @export
 * @interface DatasetsApiCreateDatasetRequest
 */
export interface DatasetsApiCreateDatasetRequest {
  /**
   *
   * @type {Dataset}
   * @memberof DatasetsApiCreateDataset
   */
  readonly dataset: Dataset;

  /**
   *
   * @type {boolean}
   * @memberof DatasetsApiCreateDataset
   */
  readonly enableMta?: boolean;
}

/**
 * Request parameters for deleteDataset operation in DatasetsApi.
 * @export
 * @interface DatasetsApiDeleteDatasetRequest
 */
export interface DatasetsApiDeleteDatasetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetsApiDeleteDataset
   */
  readonly datasetUid: number;

  /**
   *
   * @type {RemoveDatasetRequest}
   * @memberof DatasetsApiDeleteDataset
   */
  readonly removeDatasetRequest: RemoveDatasetRequest;
}

/**
 * Request parameters for editDataset operation in DatasetsApi.
 * @export
 * @interface DatasetsApiEditDatasetRequest
 */
export interface DatasetsApiEditDatasetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetsApiEditDataset
   */
  readonly datasetUid: number;

  /**
   *
   * @type {Dataset}
   * @memberof DatasetsApiEditDataset
   */
  readonly dataset: Dataset;
}

/**
 * Request parameters for fetchDatasetByUidDatasetsDatasetUidGet operation in DatasetsApi.
 * @export
 * @interface DatasetsApiFetchDatasetByUidDatasetsDatasetUidGetRequest
 */
export interface DatasetsApiFetchDatasetByUidDatasetsDatasetUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetsApiFetchDatasetByUidDatasetsDatasetUidGet
   */
  readonly datasetUid: number;
}

/**
 * Request parameters for fetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet operation in DatasetsApi.
 * @export
 * @interface DatasetsApiFetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGetRequest
 */
export interface DatasetsApiFetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetsApiFetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {boolean}
   * @memberof DatasetsApiFetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet
   */
  readonly intersection?: boolean;
}

/**
 * Request parameters for fetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet operation in DatasetsApi.
 * @export
 * @interface DatasetsApiFetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGetRequest
 */
export interface DatasetsApiFetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetsApiFetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {boolean}
   * @memberof DatasetsApiFetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet
   */
  readonly intersection?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasetsApiFetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet
   */
  readonly usePreprocessedDataset?: boolean;
}

/**
 * Request parameters for garbageCollectDatasetApplications operation in DatasetsApi.
 * @export
 * @interface DatasetsApiGarbageCollectDatasetApplicationsRequest
 */
export interface DatasetsApiGarbageCollectDatasetApplicationsRequest {
  /**
   *
   * @type {GarbageCollectDatasetApplicationParams}
   * @memberof DatasetsApiGarbageCollectDatasetApplications
   */
  readonly garbageCollectDatasetApplicationParams: GarbageCollectDatasetApplicationParams;
}

/**
 * Request parameters for getDatasets operation in DatasetsApi.
 * @export
 * @interface DatasetsApiGetDatasetsRequest
 */
export interface DatasetsApiGetDatasetsRequest {
  /**
   *
   * @type {string}
   * @memberof DatasetsApiGetDatasets
   */
  readonly name?: string;

  /**
   *
   * @type {number}
   * @memberof DatasetsApiGetDatasets
   */
  readonly workspaceUid?: number;

  /**
   *
   * @type {boolean}
   * @memberof DatasetsApiGetDatasets
   */
  readonly excludeNonArrowDatasets?: boolean;

  /**
   *
   * @type {number}
   * @memberof DatasetsApiGetDatasets
   */
  readonly nextCursor?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetsApiGetDatasets
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof DatasetsApiGetDatasets
   */
  readonly offset?: number;

  /**
   *
   * @type {string}
   * @memberof DatasetsApiGetDatasets
   */
  readonly search?: string;
}

/**
 * Request parameters for listDatasetDataSplitsDatasetsDatasetUidSplitsGet operation in DatasetsApi.
 * @export
 * @interface DatasetsApiListDatasetDataSplitsDatasetsDatasetUidSplitsGetRequest
 */
export interface DatasetsApiListDatasetDataSplitsDatasetsDatasetUidSplitsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasetsApiListDatasetDataSplitsDatasetsDatasetUidSplitsGet
   */
  readonly datasetUid: number;
}

/**
 * DatasetsApi - object-oriented interface
 * @export
 * @class DatasetsApi
 * @extends {BaseAPI}
 */
export class DatasetsApi extends BaseAPI {
  /**
   * Add dataset to datasets table.
   * @summary Create Dataset
   * @param {DatasetsApiCreateDatasetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetsApi
   */
  public createDataset(
    requestParameters: DatasetsApiCreateDatasetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetsApiFp(this.configuration)
      .createDataset(
        requestParameters.dataset,
        requestParameters.enableMta,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete dataset and corresponding tables.
   * @summary Remove Dataset
   * @param {DatasetsApiDeleteDatasetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetsApi
   */
  public deleteDataset(
    requestParameters: DatasetsApiDeleteDatasetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetsApiFp(this.configuration)
      .deleteDataset(
        requestParameters.datasetUid,
        requestParameters.removeDatasetRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Update editable fields in the dataset metadata, currently only the name
   * @summary Edit Dataset
   * @param {DatasetsApiEditDatasetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetsApi
   */
  public editDataset(
    requestParameters: DatasetsApiEditDatasetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetsApiFp(this.configuration)
      .editDataset(
        requestParameters.datasetUid,
        requestParameters.dataset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch a dataset by its UID.
   * @summary Fetch Dataset By Uid
   * @param {DatasetsApiFetchDatasetByUidDatasetsDatasetUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetsApi
   */
  public fetchDatasetByUidDatasetsDatasetUidGet(
    requestParameters: DatasetsApiFetchDatasetByUidDatasetsDatasetUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetsApiFp(this.configuration)
      .fetchDatasetByUidDatasetsDatasetUidGet(
        requestParameters.datasetUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Dataset Column Types
   * @param {DatasetsApiFetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetsApi
   */
  public fetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet(
    requestParameters: DatasetsApiFetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetsApiFp(this.configuration)
      .fetchDatasetColumnTypesDatasetsDatasetUidDataframeColumnTypesGet(
        requestParameters.datasetUid,
        requestParameters.intersection,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List column names of the dataset. If the use_preprocessed_dataset is True, then it returns the dataset after preprocessing if the dataset has preprocessing set up. Otherwise it returns the original dataset columns.
   * @summary Fetch Dataset Columns
   * @param {DatasetsApiFetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetsApi
   */
  public fetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet(
    requestParameters: DatasetsApiFetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetsApiFp(this.configuration)
      .fetchDatasetColumnsDatasetsDatasetUidDataframeColumnsGet(
        requestParameters.datasetUid,
        requestParameters.intersection,
        requestParameters.usePreprocessedDataset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete dataset and corresponding tables.
   * @summary Garbage Collect Datasets Applications
   * @param {DatasetsApiGarbageCollectDatasetApplicationsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetsApi
   */
  public garbageCollectDatasetApplications(
    requestParameters: DatasetsApiGarbageCollectDatasetApplicationsRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetsApiFp(this.configuration)
      .garbageCollectDatasetApplications(
        requestParameters.garbageCollectDatasetApplicationParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List all datasets the requesting user has access to.
   * @summary List Datasets
   * @param {DatasetsApiGetDatasetsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetsApi
   */
  public getDatasets(
    requestParameters: DatasetsApiGetDatasetsRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetsApiFp(this.configuration)
      .getDatasets(
        requestParameters.name,
        requestParameters.workspaceUid,
        requestParameters.excludeNonArrowDatasets,
        requestParameters.nextCursor,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.search,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List all dataset splits for given dataset.
   * @summary List Dataset Data Splits
   * @param {DatasetsApiListDatasetDataSplitsDatasetsDatasetUidSplitsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasetsApi
   */
  public listDatasetDataSplitsDatasetsDatasetUidSplitsGet(
    requestParameters: DatasetsApiListDatasetDataSplitsDatasetsDatasetUidSplitsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasetsApiFp(this.configuration)
      .listDatasetDataSplitsDatasetsDatasetUidSplitsGet(
        requestParameters.datasetUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DatasourcesApi - axios parameter creator
 * @export
 */
export const DatasourcesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Analyzes all the passed data sources by making an engine job for each data source, and then doing between data sources check at the end.  Because we show errors/warnings per datasource, we can\'t have the engine jobs error out on exception. Instead, we catch the errors and put them in the output as \"critical warnings\".
     * @summary Analyze Datasources
     * @param {number} datasetUid
     * @param {AnalyzeDataSourcesRequest} analyzeDataSourcesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost: async (
      datasetUid: number,
      analyzeDataSourcesRequest: AnalyzeDataSourcesRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'analyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'analyzeDataSourcesRequest' is not null or undefined
      assertParamExists(
        'analyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost',
        'analyzeDataSourcesRequest',
        analyzeDataSourcesRequest,
      );
      const localVarPath =
        `/datasets/{dataset_uid}/analyze-data-sources`.replace(
          `{${'dataset_uid'}}`,
          encodeURIComponent(String(datasetUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        analyzeDataSourcesRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Datasource Unique Values
     * @param {number} datasetUid
     * @param {DataSourceUniqueColumnValuesRequest} dataSourceUniqueColumnValuesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    datasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost: async (
      datasetUid: number,
      dataSourceUniqueColumnValuesRequest: DataSourceUniqueColumnValuesRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'datasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'dataSourceUniqueColumnValuesRequest' is not null or undefined
      assertParamExists(
        'datasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost',
        'dataSourceUniqueColumnValuesRequest',
        dataSourceUniqueColumnValuesRequest,
      );
      const localVarPath =
        `/datasets/{dataset_uid}/data-source-label-values`.replace(
          `{${'dataset_uid'}}`,
          encodeURIComponent(String(datasetUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataSourceUniqueColumnValuesRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a data source.  The operation will fail if any tasks are using the data source.  Parameters ---------- dataset_uid     UID of the dataset datasource_uid     UID of the data source to delete
     * @summary Remove Datasource
     * @param {number} datasetUid
     * @param {number} datasourceUid
     * @param {RemoveDatasourceRequest} removeDatasourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDatasource: async (
      datasetUid: number,
      datasourceUid: number,
      removeDatasourceRequest: RemoveDatasourceRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists('deleteDatasource', 'datasetUid', datasetUid);
      // verify required parameter 'datasourceUid' is not null or undefined
      assertParamExists('deleteDatasource', 'datasourceUid', datasourceUid);
      // verify required parameter 'removeDatasourceRequest' is not null or undefined
      assertParamExists(
        'deleteDatasource',
        'removeDatasourceRequest',
        removeDatasourceRequest,
      );
      const localVarPath =
        `/datasets/{dataset_uid}/data-sources/{datasource_uid}`
          .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
          .replace(
            `{${'datasource_uid'}}`,
            encodeURIComponent(String(datasourceUid)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        removeDatasourceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Datasource Metadata
     * @param {number} datasourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasourceMetadataDataSourcesDatasourceUidMetadataGet: async (
      datasourceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasourceUid' is not null or undefined
      assertParamExists(
        'getDatasourceMetadataDataSourcesDatasourceUidMetadataGet',
        'datasourceUid',
        datasourceUid,
      );
      const localVarPath = `/data-sources/{datasource_uid}/metadata`.replace(
        `{${'datasource_uid'}}`,
        encodeURIComponent(String(datasourceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ingest All Datasources
     * @param {number} datasetUid
     * @param {MultiDataSourcesIngestionRequest} multiDataSourcesIngestionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ingestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost: async (
      datasetUid: number,
      multiDataSourcesIngestionRequest: MultiDataSourcesIngestionRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'ingestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'multiDataSourcesIngestionRequest' is not null or undefined
      assertParamExists(
        'ingestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost',
        'multiDataSourcesIngestionRequest',
        multiDataSourcesIngestionRequest,
      );
      const localVarPath = `/datasets/{dataset_uid}/multi-data-sources`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        multiDataSourcesIngestionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ingest Datasource
     * @param {number} datasetUid
     * @param {SingleDataSourceIngestionRequest} singleDataSourceIngestionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ingestDatasourceDatasetsDatasetUidDataSourcesPost: async (
      datasetUid: number,
      singleDataSourceIngestionRequest: SingleDataSourceIngestionRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'ingestDatasourceDatasetsDatasetUidDataSourcesPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'singleDataSourceIngestionRequest' is not null or undefined
      assertParamExists(
        'ingestDatasourceDatasetsDatasetUidDataSourcesPost',
        'singleDataSourceIngestionRequest',
        singleDataSourceIngestionRequest,
      );
      const localVarPath = `/datasets/{dataset_uid}/data-sources`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        singleDataSourceIngestionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Annotation Datasources
     * @param {number} datasetUid
     * @param {string} [split]
     * @param {string} [datasourceName]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet: async (
      datasetUid: number,
      split?: string,
      datasourceName?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'listAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath =
        `/datasets/{dataset_uid}/annotation-data-sources`.replace(
          `{${'dataset_uid'}}`,
          encodeURIComponent(String(datasetUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (datasourceName !== undefined) {
        localVarQueryParameter['datasource_name'] = datasourceName;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Dataset Datasources
     * @param {number} datasetUid
     * @param {string} [split]
     * @param {string} [datasourceName]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDatasetDatasourcesDatasetsDatasetUidDataSourcesGet: async (
      datasetUid: number,
      split?: string,
      datasourceName?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'listDatasetDatasourcesDatasetsDatasetUidDataSourcesGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/datasets/{dataset_uid}/data-sources`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (datasourceName !== undefined) {
        localVarQueryParameter['datasource_name'] = datasourceName;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a list of data sources
     * @summary List Datasources
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDatasourcesDataSourcesGet: async (
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/data-sources`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Datasource
     * @param {number} datasourceUid
     * @param {PatchDatasourcePayload} patchDatasourcePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchDatasourceDatasourcesDatasourceUidPatch: async (
      datasourceUid: number,
      patchDatasourcePayload: PatchDatasourcePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasourceUid' is not null or undefined
      assertParamExists(
        'patchDatasourceDatasourcesDatasourceUidPatch',
        'datasourceUid',
        datasourceUid,
      );
      // verify required parameter 'patchDatasourcePayload' is not null or undefined
      assertParamExists(
        'patchDatasourceDatasourcesDatasourceUidPatch',
        'patchDatasourcePayload',
        patchDatasourcePayload,
      );
      const localVarPath = `/datasources/{datasource_uid}`.replace(
        `{${'datasource_uid'}}`,
        encodeURIComponent(String(datasourceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchDatasourcePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Peek Datasource By Path
     * @param {string} [sourceType]
     * @param {string} [path]
     * @param {boolean} [truncateTexts]
     * @param {boolean} [returnSnorkelGenUidAsCol]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    peekDatasourceByPathPeekDataSourceGet: async (
      sourceType?: string,
      path?: string,
      truncateTexts?: boolean,
      returnSnorkelGenUidAsCol?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/peek-data-source`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sourceType !== undefined) {
        localVarQueryParameter['source_type'] = sourceType;
      }

      if (path !== undefined) {
        localVarQueryParameter['path'] = path;
      }

      if (truncateTexts !== undefined) {
        localVarQueryParameter['truncate_texts'] = truncateTexts;
      }

      if (returnSnorkelGenUidAsCol !== undefined) {
        localVarQueryParameter['return_snorkel_gen_uid_as_col'] =
          returnSnorkelGenUidAsCol;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Peek Datasource
     * @param {number} datasourceUid
     * @param {boolean} [truncateTexts]
     * @param {boolean} [returnSnorkelGenUidAsCol]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    peekDatasourceDataSourcesDatasourceUidPeekGet: async (
      datasourceUid: number,
      truncateTexts?: boolean,
      returnSnorkelGenUidAsCol?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasourceUid' is not null or undefined
      assertParamExists(
        'peekDatasourceDataSourcesDatasourceUidPeekGet',
        'datasourceUid',
        datasourceUid,
      );
      const localVarPath = `/data-sources/{datasource_uid}/peek`.replace(
        `{${'datasource_uid'}}`,
        encodeURIComponent(String(datasourceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (truncateTexts !== undefined) {
        localVarQueryParameter['truncate_texts'] = truncateTexts;
      }

      if (returnSnorkelGenUidAsCol !== undefined) {
        localVarQueryParameter['return_snorkel_gen_uid_as_col'] =
          returnSnorkelGenUidAsCol;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Prep And Ingest Datasource
     * @param {number} datasetUid
     * @param {PrepAndIngestDatasourceRequest} prepAndIngestDatasourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost: async (
      datasetUid: number,
      prepAndIngestDatasourceRequest: PrepAndIngestDatasourceRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'prepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'prepAndIngestDatasourceRequest' is not null or undefined
      assertParamExists(
        'prepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost',
        'prepAndIngestDatasourceRequest',
        prepAndIngestDatasourceRequest,
      );
      const localVarPath = `/datasets/{dataset_uid}/prep-data-sources`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        prepAndIngestDatasourceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Prep And Ingest Fine Tuning Datasource
     * @param {number} datasetUid
     * @param {PrepAndIngestFinetuningDatasourceRequest} prepAndIngestFinetuningDatasourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost:
      async (
        datasetUid: number,
        prepAndIngestFinetuningDatasourceRequest: PrepAndIngestFinetuningDatasourceRequest,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'datasetUid' is not null or undefined
        assertParamExists(
          'prepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost',
          'datasetUid',
          datasetUid,
        );
        // verify required parameter 'prepAndIngestFinetuningDatasourceRequest' is not null or undefined
        assertParamExists(
          'prepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost',
          'prepAndIngestFinetuningDatasourceRequest',
          prepAndIngestFinetuningDatasourceRequest,
        );
        const localVarPath =
          `/datasets/{dataset_uid}/fine-tuning-data-sources`.replace(
            `{${'dataset_uid'}}`,
            encodeURIComponent(String(datasetUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          prepAndIngestFinetuningDatasourceRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Put Datasource
     * @param {number} datasourceUid
     * @param {PutDatasource} putDatasource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putDatasourceDataSourcesDatasourceUidPut: async (
      datasourceUid: number,
      putDatasource: PutDatasource,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasourceUid' is not null or undefined
      assertParamExists(
        'putDatasourceDataSourcesDatasourceUidPut',
        'datasourceUid',
        datasourceUid,
      );
      // verify required parameter 'putDatasource' is not null or undefined
      assertParamExists(
        'putDatasourceDataSourcesDatasourceUidPut',
        'putDatasource',
        putDatasource,
      );
      const localVarPath = `/data-sources/{datasource_uid}`.replace(
        `{${'datasource_uid'}}`,
        encodeURIComponent(String(datasourceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putDatasource,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Split Datasources
     * @param {number} datasetUid
     * @param {SplitDataSourcesRequest} splitDataSourcesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    splitDatasourcesDatasetsDatasetUidSplitDataSourcesPost: async (
      datasetUid: number,
      splitDataSourcesRequest: SplitDataSourcesRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'splitDatasourcesDatasetsDatasetUidSplitDataSourcesPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'splitDataSourcesRequest' is not null or undefined
      assertParamExists(
        'splitDatasourcesDatasetsDatasetUidSplitDataSourcesPost',
        'splitDataSourcesRequest',
        splitDataSourcesRequest,
      );
      const localVarPath = `/datasets/{dataset_uid}/split-data-sources`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        splitDataSourcesRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Swap Datasource
     * @param {number} datasetUid
     * @param {number} datasourceUid
     * @param {IngestAndSwapDatasourcePayload} ingestAndSwapDatasourcePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost: async (
      datasetUid: number,
      datasourceUid: number,
      ingestAndSwapDatasourcePayload: IngestAndSwapDatasourcePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'datasourceUid' is not null or undefined
      assertParamExists(
        'swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost',
        'datasourceUid',
        datasourceUid,
      );
      // verify required parameter 'ingestAndSwapDatasourcePayload' is not null or undefined
      assertParamExists(
        'swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost',
        'ingestAndSwapDatasourcePayload',
        ingestAndSwapDatasourcePayload,
      );
      const localVarPath =
        `/datasets/{dataset_uid}/datasources/{datasource_uid}/swap`
          .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
          .replace(
            `{${'datasource_uid'}}`,
            encodeURIComponent(String(datasourceUid)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        ingestAndSwapDatasourcePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Datasource
     * @param {number} datasetUid
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadDatasourceDatasetsDatasetUidUploadPost: async (
      datasetUid: number,
      file: File,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'uploadDatasourceDatasetsDatasetUidUploadPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'file' is not null or undefined
      assertParamExists(
        'uploadDatasourceDatasetsDatasetUidUploadPost',
        'file',
        file,
      );
      const localVarPath = `/datasets/{dataset_uid}/upload`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DatasourcesApi - functional programming interface
 * @export
 */
export const DatasourcesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DatasourcesApiAxiosParamCreator(configuration);
  return {
    /**
     * Analyzes all the passed data sources by making an engine job for each data source, and then doing between data sources check at the end.  Because we show errors/warnings per datasource, we can\'t have the engine jobs error out on exception. Instead, we catch the errors and put them in the output as \"critical warnings\".
     * @summary Analyze Datasources
     * @param {number} datasetUid
     * @param {AnalyzeDataSourcesRequest} analyzeDataSourcesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async analyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost(
      datasetUid: number,
      analyzeDataSourcesRequest: AnalyzeDataSourcesRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasourceAnalysisResponse>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.analyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost(
          datasetUid,
          analyzeDataSourcesRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.analyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Datasource Unique Values
     * @param {number} datasetUid
     * @param {DataSourceUniqueColumnValuesRequest} dataSourceUniqueColumnValuesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async datasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost(
      datasetUid: number,
      dataSourceUniqueColumnValuesRequest: DataSourceUniqueColumnValuesRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UniqueLabelsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.datasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost(
          datasetUid,
          dataSourceUniqueColumnValuesRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.datasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete a data source.  The operation will fail if any tasks are using the data source.  Parameters ---------- dataset_uid     UID of the dataset datasource_uid     UID of the data source to delete
     * @summary Remove Datasource
     * @param {number} datasetUid
     * @param {number} datasourceUid
     * @param {RemoveDatasourceRequest} removeDatasourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDatasource(
      datasetUid: number,
      datasourceUid: number,
      removeDatasourceRequest: RemoveDatasourceRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RemoveDatasourceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteDatasource(
          datasetUid,
          datasourceUid,
          removeDatasourceRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DatasourcesApi.deleteDatasource']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Datasource Metadata
     * @param {number} datasourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDatasourceMetadataDataSourcesDatasourceUidMetadataGet(
      datasourceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DatasourceMetadataBase>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDatasourceMetadataDataSourcesDatasourceUidMetadataGet(
          datasourceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.getDatasourceMetadataDataSourcesDatasourceUidMetadataGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Ingest All Datasources
     * @param {number} datasetUid
     * @param {MultiDataSourcesIngestionRequest} multiDataSourcesIngestionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ingestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost(
      datasetUid: number,
      multiDataSourcesIngestionRequest: MultiDataSourcesIngestionRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IngestAllDataSourcesResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.ingestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost(
          datasetUid,
          multiDataSourcesIngestionRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.ingestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Ingest Datasource
     * @param {number} datasetUid
     * @param {SingleDataSourceIngestionRequest} singleDataSourceIngestionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ingestDatasourceDatasetsDatasetUidDataSourcesPost(
      datasetUid: number,
      singleDataSourceIngestionRequest: SingleDataSourceIngestionRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.ingestDatasourceDatasetsDatasetUidDataSourcesPost(
          datasetUid,
          singleDataSourceIngestionRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.ingestDatasourceDatasetsDatasetUidDataSourcesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Annotation Datasources
     * @param {number} datasetUid
     * @param {string} [split]
     * @param {string} [datasourceName]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet(
      datasetUid: number,
      split?: string,
      datasourceName?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet(
          datasetUid,
          split,
          datasourceName,
          startDate,
          endDate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.listAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Dataset Datasources
     * @param {number} datasetUid
     * @param {string} [split]
     * @param {string} [datasourceName]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listDatasetDatasourcesDatasetsDatasetUidDataSourcesGet(
      datasetUid: number,
      split?: string,
      datasourceName?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listDatasetDatasourcesDatasetsDatasetUidDataSourcesGet(
          datasetUid,
          split,
          datasourceName,
          startDate,
          endDate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.listDatasetDatasourcesDatasetsDatasetUidDataSourcesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets a list of data sources
     * @summary List Datasources
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listDatasourcesDataSourcesGet(
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDataSourcesResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listDatasourcesDataSourcesGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DatasourcesApi.listDatasourcesDataSourcesGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Datasource
     * @param {number} datasourceUid
     * @param {PatchDatasourcePayload} patchDatasourcePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchDatasourceDatasourcesDatasourceUidPatch(
      datasourceUid: number,
      patchDatasourcePayload: PatchDatasourcePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchDatasourceDatasourcesDatasourceUidPatch(
          datasourceUid,
          patchDatasourcePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.patchDatasourceDatasourcesDatasourceUidPatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Peek Datasource By Path
     * @param {string} [sourceType]
     * @param {string} [path]
     * @param {boolean} [truncateTexts]
     * @param {boolean} [returnSnorkelGenUidAsCol]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async peekDatasourceByPathPeekDataSourceGet(
      sourceType?: string,
      path?: string,
      truncateTexts?: boolean,
      returnSnorkelGenUidAsCol?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.peekDatasourceByPathPeekDataSourceGet(
          sourceType,
          path,
          truncateTexts,
          returnSnorkelGenUidAsCol,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.peekDatasourceByPathPeekDataSourceGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Peek Datasource
     * @param {number} datasourceUid
     * @param {boolean} [truncateTexts]
     * @param {boolean} [returnSnorkelGenUidAsCol]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async peekDatasourceDataSourcesDatasourceUidPeekGet(
      datasourceUid: number,
      truncateTexts?: boolean,
      returnSnorkelGenUidAsCol?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.peekDatasourceDataSourcesDatasourceUidPeekGet(
          datasourceUid,
          truncateTexts,
          returnSnorkelGenUidAsCol,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.peekDatasourceDataSourcesDatasourceUidPeekGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Prep And Ingest Datasource
     * @param {number} datasetUid
     * @param {PrepAndIngestDatasourceRequest} prepAndIngestDatasourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async prepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost(
      datasetUid: number,
      prepAndIngestDatasourceRequest: PrepAndIngestDatasourceRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DataSourcePrepResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.prepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost(
          datasetUid,
          prepAndIngestDatasourceRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.prepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Prep And Ingest Fine Tuning Datasource
     * @param {number} datasetUid
     * @param {PrepAndIngestFinetuningDatasourceRequest} prepAndIngestFinetuningDatasourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async prepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost(
      datasetUid: number,
      prepAndIngestFinetuningDatasourceRequest: PrepAndIngestFinetuningDatasourceRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PreprocessFinetuningDatasourceResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.prepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost(
          datasetUid,
          prepAndIngestFinetuningDatasourceRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.prepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Put Datasource
     * @param {number} datasourceUid
     * @param {PutDatasource} putDatasource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putDatasourceDataSourcesDatasourceUidPut(
      datasourceUid: number,
      putDatasource: PutDatasource,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PutDatasourceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putDatasourceDataSourcesDatasourceUidPut(
          datasourceUid,
          putDatasource,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.putDatasourceDataSourcesDatasourceUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Split Datasources
     * @param {number} datasetUid
     * @param {SplitDataSourcesRequest} splitDataSourcesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async splitDatasourcesDatasetsDatasetUidSplitDataSourcesPost(
      datasetUid: number,
      splitDataSourcesRequest: SplitDataSourcesRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SplitDataSourcesResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.splitDatasourcesDatasetsDatasetUidSplitDataSourcesPost(
          datasetUid,
          splitDataSourcesRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.splitDatasourcesDatasetsDatasetUidSplitDataSourcesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Swap Datasource
     * @param {number} datasetUid
     * @param {number} datasourceUid
     * @param {IngestAndSwapDatasourcePayload} ingestAndSwapDatasourcePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost(
      datasetUid: number,
      datasourceUid: number,
      ingestAndSwapDatasourcePayload: IngestAndSwapDatasourcePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IngestAndSwapDatasourceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost(
          datasetUid,
          datasourceUid,
          ingestAndSwapDatasourcePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Upload Datasource
     * @param {number} datasetUid
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadDatasourceDatasetsDatasetUidUploadPost(
      datasetUid: number,
      file: File,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadDatasourceResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadDatasourceDatasetsDatasetUidUploadPost(
          datasetUid,
          file,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DatasourcesApi.uploadDatasourceDatasetsDatasetUidUploadPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DatasourcesApi - factory interface
 * @export
 */
export const DatasourcesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DatasourcesApiFp(configuration);
  return {
    /**
     * Analyzes all the passed data sources by making an engine job for each data source, and then doing between data sources check at the end.  Because we show errors/warnings per datasource, we can\'t have the engine jobs error out on exception. Instead, we catch the errors and put them in the output as \"critical warnings\".
     * @summary Analyze Datasources
     * @param {DatasourcesApiAnalyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost(
      requestParameters: DatasourcesApiAnalyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasourceAnalysisResponse>> {
      return localVarFp
        .analyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost(
          requestParameters.datasetUid,
          requestParameters.analyzeDataSourcesRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Datasource Unique Values
     * @param {DatasourcesApiDatasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    datasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost(
      requestParameters: DatasourcesApiDatasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UniqueLabelsResponse> {
      return localVarFp
        .datasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost(
          requestParameters.datasetUid,
          requestParameters.dataSourceUniqueColumnValuesRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Delete a data source.  The operation will fail if any tasks are using the data source.  Parameters ---------- dataset_uid     UID of the dataset datasource_uid     UID of the data source to delete
     * @summary Remove Datasource
     * @param {DatasourcesApiDeleteDatasourceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDatasource(
      requestParameters: DatasourcesApiDeleteDatasourceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RemoveDatasourceResponse> {
      return localVarFp
        .deleteDatasource(
          requestParameters.datasetUid,
          requestParameters.datasourceUid,
          requestParameters.removeDatasourceRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Datasource Metadata
     * @param {DatasourcesApiGetDatasourceMetadataDataSourcesDatasourceUidMetadataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDatasourceMetadataDataSourcesDatasourceUidMetadataGet(
      requestParameters: DatasourcesApiGetDatasourceMetadataDataSourcesDatasourceUidMetadataGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DatasourceMetadataBase> {
      return localVarFp
        .getDatasourceMetadataDataSourcesDatasourceUidMetadataGet(
          requestParameters.datasourceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Ingest All Datasources
     * @param {DatasourcesApiIngestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ingestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost(
      requestParameters: DatasourcesApiIngestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<IngestAllDataSourcesResponseModel> {
      return localVarFp
        .ingestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost(
          requestParameters.datasetUid,
          requestParameters.multiDataSourcesIngestionRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Ingest Datasource
     * @param {DatasourcesApiIngestDatasourceDatasetsDatasetUidDataSourcesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ingestDatasourceDatasetsDatasetUidDataSourcesPost(
      requestParameters: DatasourcesApiIngestDatasourceDatasetsDatasetUidDataSourcesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .ingestDatasourceDatasetsDatasetUidDataSourcesPost(
          requestParameters.datasetUid,
          requestParameters.singleDataSourceIngestionRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Annotation Datasources
     * @param {DatasourcesApiListAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet(
      requestParameters: DatasourcesApiListAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .listAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet(
          requestParameters.datasetUid,
          requestParameters.split,
          requestParameters.datasourceName,
          requestParameters.startDate,
          requestParameters.endDate,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Dataset Datasources
     * @param {DatasourcesApiListDatasetDatasourcesDatasetsDatasetUidDataSourcesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDatasetDatasourcesDatasetsDatasetUidDataSourcesGet(
      requestParameters: DatasourcesApiListDatasetDatasourcesDatasetsDatasetUidDataSourcesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .listDatasetDatasourcesDatasetsDatasetUidDataSourcesGet(
          requestParameters.datasetUid,
          requestParameters.split,
          requestParameters.datasourceName,
          requestParameters.startDate,
          requestParameters.endDate,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Gets a list of data sources
     * @summary List Datasources
     * @param {DatasourcesApiListDatasourcesDataSourcesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDatasourcesDataSourcesGet(
      requestParameters: DatasourcesApiListDatasourcesDataSourcesGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetDataSourcesResponseModel> {
      return localVarFp
        .listDatasourcesDataSourcesGet(requestParameters.workspaceUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Datasource
     * @param {DatasourcesApiPatchDatasourceDatasourcesDatasourceUidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchDatasourceDatasourcesDatasourceUidPatch(
      requestParameters: DatasourcesApiPatchDatasourceDatasourcesDatasourceUidPatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .patchDatasourceDatasourcesDatasourceUidPatch(
          requestParameters.datasourceUid,
          requestParameters.patchDatasourcePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Peek Datasource By Path
     * @param {DatasourcesApiPeekDatasourceByPathPeekDataSourceGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    peekDatasourceByPathPeekDataSourceGet(
      requestParameters: DatasourcesApiPeekDatasourceByPathPeekDataSourceGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .peekDatasourceByPathPeekDataSourceGet(
          requestParameters.sourceType,
          requestParameters.path,
          requestParameters.truncateTexts,
          requestParameters.returnSnorkelGenUidAsCol,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Peek Datasource
     * @param {DatasourcesApiPeekDatasourceDataSourcesDatasourceUidPeekGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    peekDatasourceDataSourcesDatasourceUidPeekGet(
      requestParameters: DatasourcesApiPeekDatasourceDataSourcesDatasourceUidPeekGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .peekDatasourceDataSourcesDatasourceUidPeekGet(
          requestParameters.datasourceUid,
          requestParameters.truncateTexts,
          requestParameters.returnSnorkelGenUidAsCol,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Prep And Ingest Datasource
     * @param {DatasourcesApiPrepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost(
      requestParameters: DatasourcesApiPrepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DataSourcePrepResponseModel> {
      return localVarFp
        .prepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost(
          requestParameters.datasetUid,
          requestParameters.prepAndIngestDatasourceRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Prep And Ingest Fine Tuning Datasource
     * @param {DatasourcesApiPrepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost(
      requestParameters: DatasourcesApiPrepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PreprocessFinetuningDatasourceResponseModel> {
      return localVarFp
        .prepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost(
          requestParameters.datasetUid,
          requestParameters.prepAndIngestFinetuningDatasourceRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Put Datasource
     * @param {DatasourcesApiPutDatasourceDataSourcesDatasourceUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putDatasourceDataSourcesDatasourceUidPut(
      requestParameters: DatasourcesApiPutDatasourceDataSourcesDatasourceUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PutDatasourceResponse> {
      return localVarFp
        .putDatasourceDataSourcesDatasourceUidPut(
          requestParameters.datasourceUid,
          requestParameters.putDatasource,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Split Datasources
     * @param {DatasourcesApiSplitDatasourcesDatasetsDatasetUidSplitDataSourcesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    splitDatasourcesDatasetsDatasetUidSplitDataSourcesPost(
      requestParameters: DatasourcesApiSplitDatasourcesDatasetsDatasetUidSplitDataSourcesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SplitDataSourcesResponseModel> {
      return localVarFp
        .splitDatasourcesDatasetsDatasetUidSplitDataSourcesPost(
          requestParameters.datasetUid,
          requestParameters.splitDataSourcesRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Swap Datasource
     * @param {DatasourcesApiSwapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost(
      requestParameters: DatasourcesApiSwapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<IngestAndSwapDatasourceResponse> {
      return localVarFp
        .swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost(
          requestParameters.datasetUid,
          requestParameters.datasourceUid,
          requestParameters.ingestAndSwapDatasourcePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Datasource
     * @param {DatasourcesApiUploadDatasourceDatasetsDatasetUidUploadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadDatasourceDatasetsDatasetUidUploadPost(
      requestParameters: DatasourcesApiUploadDatasourceDatasetsDatasetUidUploadPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UploadDatasourceResponseModel> {
      return localVarFp
        .uploadDatasourceDatasetsDatasetUidUploadPost(
          requestParameters.datasetUid,
          requestParameters.file,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for analyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiAnalyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPostRequest
 */
export interface DatasourcesApiAnalyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiAnalyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {AnalyzeDataSourcesRequest}
   * @memberof DatasourcesApiAnalyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost
   */
  readonly analyzeDataSourcesRequest: AnalyzeDataSourcesRequest;
}

/**
 * Request parameters for datasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiDatasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPostRequest
 */
export interface DatasourcesApiDatasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiDatasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {DataSourceUniqueColumnValuesRequest}
   * @memberof DatasourcesApiDatasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost
   */
  readonly dataSourceUniqueColumnValuesRequest: DataSourceUniqueColumnValuesRequest;
}

/**
 * Request parameters for deleteDatasource operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiDeleteDatasourceRequest
 */
export interface DatasourcesApiDeleteDatasourceRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiDeleteDatasource
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasourcesApiDeleteDatasource
   */
  readonly datasourceUid: number;

  /**
   *
   * @type {RemoveDatasourceRequest}
   * @memberof DatasourcesApiDeleteDatasource
   */
  readonly removeDatasourceRequest: RemoveDatasourceRequest;
}

/**
 * Request parameters for getDatasourceMetadataDataSourcesDatasourceUidMetadataGet operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiGetDatasourceMetadataDataSourcesDatasourceUidMetadataGetRequest
 */
export interface DatasourcesApiGetDatasourceMetadataDataSourcesDatasourceUidMetadataGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiGetDatasourceMetadataDataSourcesDatasourceUidMetadataGet
   */
  readonly datasourceUid: number;
}

/**
 * Request parameters for ingestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiIngestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPostRequest
 */
export interface DatasourcesApiIngestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiIngestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {MultiDataSourcesIngestionRequest}
   * @memberof DatasourcesApiIngestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost
   */
  readonly multiDataSourcesIngestionRequest: MultiDataSourcesIngestionRequest;
}

/**
 * Request parameters for ingestDatasourceDatasetsDatasetUidDataSourcesPost operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiIngestDatasourceDatasetsDatasetUidDataSourcesPostRequest
 */
export interface DatasourcesApiIngestDatasourceDatasetsDatasetUidDataSourcesPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiIngestDatasourceDatasetsDatasetUidDataSourcesPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {SingleDataSourceIngestionRequest}
   * @memberof DatasourcesApiIngestDatasourceDatasetsDatasetUidDataSourcesPost
   */
  readonly singleDataSourceIngestionRequest: SingleDataSourceIngestionRequest;
}

/**
 * Request parameters for listAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiListAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGetRequest
 */
export interface DatasourcesApiListAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiListAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasourcesApiListAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof DatasourcesApiListAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet
   */
  readonly datasourceName?: string;

  /**
   *
   * @type {string}
   * @memberof DatasourcesApiListAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof DatasourcesApiListAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet
   */
  readonly endDate?: string;
}

/**
 * Request parameters for listDatasetDatasourcesDatasetsDatasetUidDataSourcesGet operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiListDatasetDatasourcesDatasetsDatasetUidDataSourcesGetRequest
 */
export interface DatasourcesApiListDatasetDatasourcesDatasetsDatasetUidDataSourcesGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiListDatasetDatasourcesDatasetsDatasetUidDataSourcesGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {string}
   * @memberof DatasourcesApiListDatasetDatasourcesDatasetsDatasetUidDataSourcesGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof DatasourcesApiListDatasetDatasourcesDatasetsDatasetUidDataSourcesGet
   */
  readonly datasourceName?: string;

  /**
   *
   * @type {string}
   * @memberof DatasourcesApiListDatasetDatasourcesDatasetsDatasetUidDataSourcesGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof DatasourcesApiListDatasetDatasourcesDatasetsDatasetUidDataSourcesGet
   */
  readonly endDate?: string;
}

/**
 * Request parameters for listDatasourcesDataSourcesGet operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiListDatasourcesDataSourcesGetRequest
 */
export interface DatasourcesApiListDatasourcesDataSourcesGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiListDatasourcesDataSourcesGet
   */
  readonly workspaceUid?: number;
}

/**
 * Request parameters for patchDatasourceDatasourcesDatasourceUidPatch operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiPatchDatasourceDatasourcesDatasourceUidPatchRequest
 */
export interface DatasourcesApiPatchDatasourceDatasourcesDatasourceUidPatchRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiPatchDatasourceDatasourcesDatasourceUidPatch
   */
  readonly datasourceUid: number;

  /**
   *
   * @type {PatchDatasourcePayload}
   * @memberof DatasourcesApiPatchDatasourceDatasourcesDatasourceUidPatch
   */
  readonly patchDatasourcePayload: PatchDatasourcePayload;
}

/**
 * Request parameters for peekDatasourceByPathPeekDataSourceGet operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiPeekDatasourceByPathPeekDataSourceGetRequest
 */
export interface DatasourcesApiPeekDatasourceByPathPeekDataSourceGetRequest {
  /**
   *
   * @type {string}
   * @memberof DatasourcesApiPeekDatasourceByPathPeekDataSourceGet
   */
  readonly sourceType?: string;

  /**
   *
   * @type {string}
   * @memberof DatasourcesApiPeekDatasourceByPathPeekDataSourceGet
   */
  readonly path?: string;

  /**
   *
   * @type {boolean}
   * @memberof DatasourcesApiPeekDatasourceByPathPeekDataSourceGet
   */
  readonly truncateTexts?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasourcesApiPeekDatasourceByPathPeekDataSourceGet
   */
  readonly returnSnorkelGenUidAsCol?: boolean;
}

/**
 * Request parameters for peekDatasourceDataSourcesDatasourceUidPeekGet operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiPeekDatasourceDataSourcesDatasourceUidPeekGetRequest
 */
export interface DatasourcesApiPeekDatasourceDataSourcesDatasourceUidPeekGetRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiPeekDatasourceDataSourcesDatasourceUidPeekGet
   */
  readonly datasourceUid: number;

  /**
   *
   * @type {boolean}
   * @memberof DatasourcesApiPeekDatasourceDataSourcesDatasourceUidPeekGet
   */
  readonly truncateTexts?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof DatasourcesApiPeekDatasourceDataSourcesDatasourceUidPeekGet
   */
  readonly returnSnorkelGenUidAsCol?: boolean;
}

/**
 * Request parameters for prepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiPrepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPostRequest
 */
export interface DatasourcesApiPrepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiPrepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {PrepAndIngestDatasourceRequest}
   * @memberof DatasourcesApiPrepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost
   */
  readonly prepAndIngestDatasourceRequest: PrepAndIngestDatasourceRequest;
}

/**
 * Request parameters for prepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiPrepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPostRequest
 */
export interface DatasourcesApiPrepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiPrepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {PrepAndIngestFinetuningDatasourceRequest}
   * @memberof DatasourcesApiPrepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost
   */
  readonly prepAndIngestFinetuningDatasourceRequest: PrepAndIngestFinetuningDatasourceRequest;
}

/**
 * Request parameters for putDatasourceDataSourcesDatasourceUidPut operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiPutDatasourceDataSourcesDatasourceUidPutRequest
 */
export interface DatasourcesApiPutDatasourceDataSourcesDatasourceUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiPutDatasourceDataSourcesDatasourceUidPut
   */
  readonly datasourceUid: number;

  /**
   *
   * @type {PutDatasource}
   * @memberof DatasourcesApiPutDatasourceDataSourcesDatasourceUidPut
   */
  readonly putDatasource: PutDatasource;
}

/**
 * Request parameters for splitDatasourcesDatasetsDatasetUidSplitDataSourcesPost operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiSplitDatasourcesDatasetsDatasetUidSplitDataSourcesPostRequest
 */
export interface DatasourcesApiSplitDatasourcesDatasetsDatasetUidSplitDataSourcesPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiSplitDatasourcesDatasetsDatasetUidSplitDataSourcesPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {SplitDataSourcesRequest}
   * @memberof DatasourcesApiSplitDatasourcesDatasetsDatasetUidSplitDataSourcesPost
   */
  readonly splitDataSourcesRequest: SplitDataSourcesRequest;
}

/**
 * Request parameters for swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiSwapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPostRequest
 */
export interface DatasourcesApiSwapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiSwapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof DatasourcesApiSwapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost
   */
  readonly datasourceUid: number;

  /**
   *
   * @type {IngestAndSwapDatasourcePayload}
   * @memberof DatasourcesApiSwapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost
   */
  readonly ingestAndSwapDatasourcePayload: IngestAndSwapDatasourcePayload;
}

/**
 * Request parameters for uploadDatasourceDatasetsDatasetUidUploadPost operation in DatasourcesApi.
 * @export
 * @interface DatasourcesApiUploadDatasourceDatasetsDatasetUidUploadPostRequest
 */
export interface DatasourcesApiUploadDatasourceDatasetsDatasetUidUploadPostRequest {
  /**
   *
   * @type {number}
   * @memberof DatasourcesApiUploadDatasourceDatasetsDatasetUidUploadPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {File}
   * @memberof DatasourcesApiUploadDatasourceDatasetsDatasetUidUploadPost
   */
  readonly file: File;
}

/**
 * DatasourcesApi - object-oriented interface
 * @export
 * @class DatasourcesApi
 * @extends {BaseAPI}
 */
export class DatasourcesApi extends BaseAPI {
  /**
   * Analyzes all the passed data sources by making an engine job for each data source, and then doing between data sources check at the end.  Because we show errors/warnings per datasource, we can\'t have the engine jobs error out on exception. Instead, we catch the errors and put them in the output as \"critical warnings\".
   * @summary Analyze Datasources
   * @param {DatasourcesApiAnalyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public analyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost(
    requestParameters: DatasourcesApiAnalyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .analyzeDatasourcesDatasetsDatasetUidAnalyzeDataSourcesPost(
        requestParameters.datasetUid,
        requestParameters.analyzeDataSourcesRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Datasource Unique Values
   * @param {DatasourcesApiDatasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public datasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost(
    requestParameters: DatasourcesApiDatasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .datasourceUniqueValuesDatasetsDatasetUidDataSourceLabelValuesPost(
        requestParameters.datasetUid,
        requestParameters.dataSourceUniqueColumnValuesRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete a data source.  The operation will fail if any tasks are using the data source.  Parameters ---------- dataset_uid     UID of the dataset datasource_uid     UID of the data source to delete
   * @summary Remove Datasource
   * @param {DatasourcesApiDeleteDatasourceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public deleteDatasource(
    requestParameters: DatasourcesApiDeleteDatasourceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .deleteDatasource(
        requestParameters.datasetUid,
        requestParameters.datasourceUid,
        requestParameters.removeDatasourceRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Datasource Metadata
   * @param {DatasourcesApiGetDatasourceMetadataDataSourcesDatasourceUidMetadataGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public getDatasourceMetadataDataSourcesDatasourceUidMetadataGet(
    requestParameters: DatasourcesApiGetDatasourceMetadataDataSourcesDatasourceUidMetadataGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .getDatasourceMetadataDataSourcesDatasourceUidMetadataGet(
        requestParameters.datasourceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ingest All Datasources
   * @param {DatasourcesApiIngestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public ingestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost(
    requestParameters: DatasourcesApiIngestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .ingestAllDatasourcesDatasetsDatasetUidMultiDataSourcesPost(
        requestParameters.datasetUid,
        requestParameters.multiDataSourcesIngestionRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ingest Datasource
   * @param {DatasourcesApiIngestDatasourceDatasetsDatasetUidDataSourcesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public ingestDatasourceDatasetsDatasetUidDataSourcesPost(
    requestParameters: DatasourcesApiIngestDatasourceDatasetsDatasetUidDataSourcesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .ingestDatasourceDatasetsDatasetUidDataSourcesPost(
        requestParameters.datasetUid,
        requestParameters.singleDataSourceIngestionRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Annotation Datasources
   * @param {DatasourcesApiListAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public listAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet(
    requestParameters: DatasourcesApiListAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .listAnnotationDatasourcesDatasetsDatasetUidAnnotationDataSourcesGet(
        requestParameters.datasetUid,
        requestParameters.split,
        requestParameters.datasourceName,
        requestParameters.startDate,
        requestParameters.endDate,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Dataset Datasources
   * @param {DatasourcesApiListDatasetDatasourcesDatasetsDatasetUidDataSourcesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public listDatasetDatasourcesDatasetsDatasetUidDataSourcesGet(
    requestParameters: DatasourcesApiListDatasetDatasourcesDatasetsDatasetUidDataSourcesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .listDatasetDatasourcesDatasetsDatasetUidDataSourcesGet(
        requestParameters.datasetUid,
        requestParameters.split,
        requestParameters.datasourceName,
        requestParameters.startDate,
        requestParameters.endDate,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Gets a list of data sources
   * @summary List Datasources
   * @param {DatasourcesApiListDatasourcesDataSourcesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public listDatasourcesDataSourcesGet(
    requestParameters: DatasourcesApiListDatasourcesDataSourcesGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .listDatasourcesDataSourcesGet(requestParameters.workspaceUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Datasource
   * @param {DatasourcesApiPatchDatasourceDatasourcesDatasourceUidPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public patchDatasourceDatasourcesDatasourceUidPatch(
    requestParameters: DatasourcesApiPatchDatasourceDatasourcesDatasourceUidPatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .patchDatasourceDatasourcesDatasourceUidPatch(
        requestParameters.datasourceUid,
        requestParameters.patchDatasourcePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Peek Datasource By Path
   * @param {DatasourcesApiPeekDatasourceByPathPeekDataSourceGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public peekDatasourceByPathPeekDataSourceGet(
    requestParameters: DatasourcesApiPeekDatasourceByPathPeekDataSourceGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .peekDatasourceByPathPeekDataSourceGet(
        requestParameters.sourceType,
        requestParameters.path,
        requestParameters.truncateTexts,
        requestParameters.returnSnorkelGenUidAsCol,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Peek Datasource
   * @param {DatasourcesApiPeekDatasourceDataSourcesDatasourceUidPeekGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public peekDatasourceDataSourcesDatasourceUidPeekGet(
    requestParameters: DatasourcesApiPeekDatasourceDataSourcesDatasourceUidPeekGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .peekDatasourceDataSourcesDatasourceUidPeekGet(
        requestParameters.datasourceUid,
        requestParameters.truncateTexts,
        requestParameters.returnSnorkelGenUidAsCol,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Prep And Ingest Datasource
   * @param {DatasourcesApiPrepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public prepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost(
    requestParameters: DatasourcesApiPrepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .prepAndIngestDatasourceDatasetsDatasetUidPrepDataSourcesPost(
        requestParameters.datasetUid,
        requestParameters.prepAndIngestDatasourceRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Prep And Ingest Fine Tuning Datasource
   * @param {DatasourcesApiPrepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public prepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost(
    requestParameters: DatasourcesApiPrepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .prepAndIngestFineTuningDatasourceDatasetsDatasetUidFineTuningDataSourcesPost(
        requestParameters.datasetUid,
        requestParameters.prepAndIngestFinetuningDatasourceRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Put Datasource
   * @param {DatasourcesApiPutDatasourceDataSourcesDatasourceUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public putDatasourceDataSourcesDatasourceUidPut(
    requestParameters: DatasourcesApiPutDatasourceDataSourcesDatasourceUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .putDatasourceDataSourcesDatasourceUidPut(
        requestParameters.datasourceUid,
        requestParameters.putDatasource,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Split Datasources
   * @param {DatasourcesApiSplitDatasourcesDatasetsDatasetUidSplitDataSourcesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public splitDatasourcesDatasetsDatasetUidSplitDataSourcesPost(
    requestParameters: DatasourcesApiSplitDatasourcesDatasetsDatasetUidSplitDataSourcesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .splitDatasourcesDatasetsDatasetUidSplitDataSourcesPost(
        requestParameters.datasetUid,
        requestParameters.splitDataSourcesRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Swap Datasource
   * @param {DatasourcesApiSwapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost(
    requestParameters: DatasourcesApiSwapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .swapDatasourceDatasetsDatasetUidDatasourcesDatasourceUidSwapPost(
        requestParameters.datasetUid,
        requestParameters.datasourceUid,
        requestParameters.ingestAndSwapDatasourcePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Datasource
   * @param {DatasourcesApiUploadDatasourceDatasetsDatasetUidUploadPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DatasourcesApi
   */
  public uploadDatasourceDatasetsDatasetUidUploadPost(
    requestParameters: DatasourcesApiUploadDatasourceDatasetsDatasetUidUploadPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DatasourcesApiFp(this.configuration)
      .uploadDatasourceDatasetsDatasetUidUploadPost(
        requestParameters.datasetUid,
        requestParameters.file,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DbgApi - axios parameter creator
 * @export
 */
export const DbgApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Run Db Query
     * @param {QueryPayload} queryPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runDbQueryQueryPost: async (
      queryPayload: QueryPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'queryPayload' is not null or undefined
      assertParamExists('runDbQueryQueryPost', 'queryPayload', queryPayload);
      const localVarPath = `/query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DbgApi - functional programming interface
 * @export
 */
export const DbgApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DbgApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Run Db Query
     * @param {QueryPayload} queryPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runDbQueryQueryPost(
      queryPayload: QueryPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.runDbQueryQueryPost(
          queryPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DbgApi.runDbQueryQueryPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DbgApi - factory interface
 * @export
 */
export const DbgApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DbgApiFp(configuration);
  return {
    /**
     *
     * @summary Run Db Query
     * @param {DbgApiRunDbQueryQueryPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runDbQueryQueryPost(
      requestParameters: DbgApiRunDbQueryQueryPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<QueryResponse> {
      return localVarFp
        .runDbQueryQueryPost(requestParameters.queryPayload, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for runDbQueryQueryPost operation in DbgApi.
 * @export
 * @interface DbgApiRunDbQueryQueryPostRequest
 */
export interface DbgApiRunDbQueryQueryPostRequest {
  /**
   *
   * @type {QueryPayload}
   * @memberof DbgApiRunDbQueryQueryPost
   */
  readonly queryPayload: QueryPayload;
}

/**
 * DbgApi - object-oriented interface
 * @export
 * @class DbgApi
 * @extends {BaseAPI}
 */
export class DbgApi extends BaseAPI {
  /**
   *
   * @summary Run Db Query
   * @param {DbgApiRunDbQueryQueryPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbgApi
   */
  public runDbQueryQueryPost(
    requestParameters: DbgApiRunDbQueryQueryPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DbgApiFp(this.configuration)
      .runDbQueryQueryPost(requestParameters.queryPayload, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Documentation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentationDocsGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/docs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBasic required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Open Api Endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenApiEndpointApiV1OpenapiJsonGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/openapi.json`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication HTTPBasic required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersionVersionGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/version`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Home
     * @param {number} [timeoutSecs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    homeGet: async (
      timeoutSecs?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (timeoutSecs !== undefined) {
        localVarQueryParameter['timeout_secs'] = timeoutSecs;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Documentation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocumentationDocsGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDocumentationDocsGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.getDocumentationDocsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Open Api Endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOpenApiEndpointApiV1OpenapiJsonGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOpenApiEndpointApiV1OpenapiJsonGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DefaultApi.getOpenApiEndpointApiV1OpenapiJsonGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVersionVersionGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getVersionVersionGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.getVersionVersionGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Home
     * @param {number} [timeoutSecs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async homeGet(
      timeoutSecs?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.homeGet(
        timeoutSecs,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.homeGet']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Get Documentation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentationDocsGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getDocumentationDocsGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Open Api Endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenApiEndpointApiV1OpenapiJsonGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getOpenApiEndpointApiV1OpenapiJsonGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersionVersionGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<string> {
      return localVarFp
        .getVersionVersionGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Home
     * @param {DefaultApiHomeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    homeGet(
      requestParameters: DefaultApiHomeGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .homeGet(requestParameters.timeoutSecs, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for homeGet operation in DefaultApi.
 * @export
 * @interface DefaultApiHomeGetRequest
 */
export interface DefaultApiHomeGetRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiHomeGet
   */
  readonly timeoutSecs?: number;
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Get Documentation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getDocumentationDocsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getDocumentationDocsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Open Api Endpoint
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getOpenApiEndpointApiV1OpenapiJsonGet(
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getOpenApiEndpointApiV1OpenapiJsonGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getVersionVersionGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getVersionVersionGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Home
   * @param {DefaultApiHomeGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public homeGet(
    requestParameters: DefaultApiHomeGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .homeGet(requestParameters.timeoutSecs, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DefaultsApi - axios parameter creator
 * @export
 */
export const DefaultsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Fetch Operator Defaults
     * @param {string} [opType]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOperatorDefaultsOperatorDefaultsGet: async (
      opType?: string,
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/operator-defaults`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (opType !== undefined) {
        localVarQueryParameter['op_type'] = opType;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Label Space Mixins
     * @param {string} labelSpaceCls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGet: async (
      labelSpaceCls: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'labelSpaceCls' is not null or undefined
      assertParamExists(
        'getLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGet',
        'labelSpaceCls',
        labelSpaceCls,
      );
      const localVarPath = `/label-spaces/{label_space_cls}/mixins`.replace(
        `{${'label_space_cls'}}`,
        encodeURIComponent(String(labelSpaceCls)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return all static information about LF types and their properties.  Returns information for just one LF if a template name is specified, otherwise returns a information for all LFs.
     * @summary Get Lf Info
     * @param {string} [templateType]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfInfoDefaultsLfInfoGet: async (
      templateType?: string,
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/defaults/lf_info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (templateType !== undefined) {
        localVarQueryParameter['template_type'] = templateType;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return a list of default configs for all available models in the backend
     * @summary List Default Model Configs
     * @param {string} taskType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDefaultModelConfigsDefaultsTaskTypeModelConfigsGet: async (
      taskType: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskType' is not null or undefined
      assertParamExists(
        'listDefaultModelConfigsDefaultsTaskTypeModelConfigsGet',
        'taskType',
        taskType,
      );
      const localVarPath = `/defaults/{task_type}/model_configs`.replace(
        `{${'task_type'}}`,
        encodeURIComponent(String(taskType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Postprocessor Settings
     * @param {string} taskType
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPostprocessorSettingsDefaultsTaskTypePostprocessorsGet: async (
      taskType: string,
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskType' is not null or undefined
      assertParamExists(
        'listPostprocessorSettingsDefaultsTaskTypePostprocessorsGet',
        'taskType',
        taskType,
      );
      const localVarPath = `/defaults/{task_type}/postprocessors`.replace(
        `{${'task_type'}}`,
        encodeURIComponent(String(taskType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Preprocessor Settings
     * @param {string} taskType
     * @param {boolean} [featurizersOnly]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPreprocessorSettingsDefaultsTaskTypePreprocessorsGet: async (
      taskType: string,
      featurizersOnly?: boolean,
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskType' is not null or undefined
      assertParamExists(
        'listPreprocessorSettingsDefaultsTaskTypePreprocessorsGet',
        'taskType',
        taskType,
      );
      const localVarPath = `/defaults/{task_type}/preprocessors`.replace(
        `{${'task_type'}}`,
        encodeURIComponent(String(taskType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (featurizersOnly !== undefined) {
        localVarQueryParameter['featurizers_only'] = featurizersOnly;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return a list of columns associated with extraction tasks
     * @summary List Span Columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSpanColumnsDefaultsSpanColumnsGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/defaults/span_columns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultsApi - functional programming interface
 * @export
 */
export const DefaultsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Fetch Operator Defaults
     * @param {string} [opType]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchOperatorDefaultsOperatorDefaultsGet(
      opType?: string,
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: OperatorClassConfig }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchOperatorDefaultsOperatorDefaultsGet(
          opType,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DefaultsApi.fetchOperatorDefaultsOperatorDefaultsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Label Space Mixins
     * @param {string} labelSpaceCls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGet(
      labelSpaceCls: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<LabelSpaceMixinsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGet(
          labelSpaceCls,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DefaultsApi.getLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return all static information about LF types and their properties.  Returns information for just one LF if a template name is specified, otherwise returns a information for all LFs.
     * @summary Get Lf Info
     * @param {string} [templateType]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLfInfoDefaultsLfInfoGet(
      templateType?: string,
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLfInfoDefaultsLfInfoGet(
          templateType,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultsApi.getLfInfoDefaultsLfInfoGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return a list of default configs for all available models in the backend
     * @summary List Default Model Configs
     * @param {string} taskType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listDefaultModelConfigsDefaultsTaskTypeModelConfigsGet(
      taskType: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listDefaultModelConfigsDefaultsTaskTypeModelConfigsGet(
          taskType,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DefaultsApi.listDefaultModelConfigsDefaultsTaskTypeModelConfigsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Postprocessor Settings
     * @param {string} taskType
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listPostprocessorSettingsDefaultsTaskTypePostprocessorsGet(
      taskType: string,
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: OperatorClassConfig }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listPostprocessorSettingsDefaultsTaskTypePostprocessorsGet(
          taskType,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DefaultsApi.listPostprocessorSettingsDefaultsTaskTypePostprocessorsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Preprocessor Settings
     * @param {string} taskType
     * @param {boolean} [featurizersOnly]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listPreprocessorSettingsDefaultsTaskTypePreprocessorsGet(
      taskType: string,
      featurizersOnly?: boolean,
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: OperatorClassConfig }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listPreprocessorSettingsDefaultsTaskTypePreprocessorsGet(
          taskType,
          featurizersOnly,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DefaultsApi.listPreprocessorSettingsDefaultsTaskTypePreprocessorsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return a list of columns associated with extraction tasks
     * @summary List Span Columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listSpanColumnsDefaultsSpanColumnsGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listSpanColumnsDefaultsSpanColumnsGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DefaultsApi.listSpanColumnsDefaultsSpanColumnsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DefaultsApi - factory interface
 * @export
 */
export const DefaultsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultsApiFp(configuration);
  return {
    /**
     *
     * @summary Fetch Operator Defaults
     * @param {DefaultsApiFetchOperatorDefaultsOperatorDefaultsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOperatorDefaultsOperatorDefaultsGet(
      requestParameters: DefaultsApiFetchOperatorDefaultsOperatorDefaultsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: OperatorClassConfig }> {
      return localVarFp
        .fetchOperatorDefaultsOperatorDefaultsGet(
          requestParameters.opType,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Label Space Mixins
     * @param {DefaultsApiGetLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGet(
      requestParameters: DefaultsApiGetLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LabelSpaceMixinsResponse> {
      return localVarFp
        .getLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGet(
          requestParameters.labelSpaceCls,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Return all static information about LF types and their properties.  Returns information for just one LF if a template name is specified, otherwise returns a information for all LFs.
     * @summary Get Lf Info
     * @param {DefaultsApiGetLfInfoDefaultsLfInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfInfoDefaultsLfInfoGet(
      requestParameters: DefaultsApiGetLfInfoDefaultsLfInfoGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getLfInfoDefaultsLfInfoGet(
          requestParameters.templateType,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Return a list of default configs for all available models in the backend
     * @summary List Default Model Configs
     * @param {DefaultsApiListDefaultModelConfigsDefaultsTaskTypeModelConfigsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDefaultModelConfigsDefaultsTaskTypeModelConfigsGet(
      requestParameters: DefaultsApiListDefaultModelConfigsDefaultsTaskTypeModelConfigsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .listDefaultModelConfigsDefaultsTaskTypeModelConfigsGet(
          requestParameters.taskType,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Postprocessor Settings
     * @param {DefaultsApiListPostprocessorSettingsDefaultsTaskTypePostprocessorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPostprocessorSettingsDefaultsTaskTypePostprocessorsGet(
      requestParameters: DefaultsApiListPostprocessorSettingsDefaultsTaskTypePostprocessorsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: OperatorClassConfig }> {
      return localVarFp
        .listPostprocessorSettingsDefaultsTaskTypePostprocessorsGet(
          requestParameters.taskType,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Preprocessor Settings
     * @param {DefaultsApiListPreprocessorSettingsDefaultsTaskTypePreprocessorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPreprocessorSettingsDefaultsTaskTypePreprocessorsGet(
      requestParameters: DefaultsApiListPreprocessorSettingsDefaultsTaskTypePreprocessorsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: OperatorClassConfig }> {
      return localVarFp
        .listPreprocessorSettingsDefaultsTaskTypePreprocessorsGet(
          requestParameters.taskType,
          requestParameters.featurizersOnly,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Return a list of columns associated with extraction tasks
     * @summary List Span Columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSpanColumnsDefaultsSpanColumnsGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .listSpanColumnsDefaultsSpanColumnsGet(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for fetchOperatorDefaultsOperatorDefaultsGet operation in DefaultsApi.
 * @export
 * @interface DefaultsApiFetchOperatorDefaultsOperatorDefaultsGetRequest
 */
export interface DefaultsApiFetchOperatorDefaultsOperatorDefaultsGetRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultsApiFetchOperatorDefaultsOperatorDefaultsGet
   */
  readonly opType?: string;

  /**
   *
   * @type {number}
   * @memberof DefaultsApiFetchOperatorDefaultsOperatorDefaultsGet
   */
  readonly workspaceUid?: number;
}

/**
 * Request parameters for getLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGet operation in DefaultsApi.
 * @export
 * @interface DefaultsApiGetLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGetRequest
 */
export interface DefaultsApiGetLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGetRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultsApiGetLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGet
   */
  readonly labelSpaceCls: string;
}

/**
 * Request parameters for getLfInfoDefaultsLfInfoGet operation in DefaultsApi.
 * @export
 * @interface DefaultsApiGetLfInfoDefaultsLfInfoGetRequest
 */
export interface DefaultsApiGetLfInfoDefaultsLfInfoGetRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultsApiGetLfInfoDefaultsLfInfoGet
   */
  readonly templateType?: string;

  /**
   *
   * @type {number}
   * @memberof DefaultsApiGetLfInfoDefaultsLfInfoGet
   */
  readonly workspaceUid?: number;
}

/**
 * Request parameters for listDefaultModelConfigsDefaultsTaskTypeModelConfigsGet operation in DefaultsApi.
 * @export
 * @interface DefaultsApiListDefaultModelConfigsDefaultsTaskTypeModelConfigsGetRequest
 */
export interface DefaultsApiListDefaultModelConfigsDefaultsTaskTypeModelConfigsGetRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultsApiListDefaultModelConfigsDefaultsTaskTypeModelConfigsGet
   */
  readonly taskType: string;
}

/**
 * Request parameters for listPostprocessorSettingsDefaultsTaskTypePostprocessorsGet operation in DefaultsApi.
 * @export
 * @interface DefaultsApiListPostprocessorSettingsDefaultsTaskTypePostprocessorsGetRequest
 */
export interface DefaultsApiListPostprocessorSettingsDefaultsTaskTypePostprocessorsGetRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultsApiListPostprocessorSettingsDefaultsTaskTypePostprocessorsGet
   */
  readonly taskType: string;

  /**
   *
   * @type {number}
   * @memberof DefaultsApiListPostprocessorSettingsDefaultsTaskTypePostprocessorsGet
   */
  readonly workspaceUid?: number;
}

/**
 * Request parameters for listPreprocessorSettingsDefaultsTaskTypePreprocessorsGet operation in DefaultsApi.
 * @export
 * @interface DefaultsApiListPreprocessorSettingsDefaultsTaskTypePreprocessorsGetRequest
 */
export interface DefaultsApiListPreprocessorSettingsDefaultsTaskTypePreprocessorsGetRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultsApiListPreprocessorSettingsDefaultsTaskTypePreprocessorsGet
   */
  readonly taskType: string;

  /**
   *
   * @type {boolean}
   * @memberof DefaultsApiListPreprocessorSettingsDefaultsTaskTypePreprocessorsGet
   */
  readonly featurizersOnly?: boolean;

  /**
   *
   * @type {number}
   * @memberof DefaultsApiListPreprocessorSettingsDefaultsTaskTypePreprocessorsGet
   */
  readonly workspaceUid?: number;
}

/**
 * DefaultsApi - object-oriented interface
 * @export
 * @class DefaultsApi
 * @extends {BaseAPI}
 */
export class DefaultsApi extends BaseAPI {
  /**
   *
   * @summary Fetch Operator Defaults
   * @param {DefaultsApiFetchOperatorDefaultsOperatorDefaultsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultsApi
   */
  public fetchOperatorDefaultsOperatorDefaultsGet(
    requestParameters: DefaultsApiFetchOperatorDefaultsOperatorDefaultsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultsApiFp(this.configuration)
      .fetchOperatorDefaultsOperatorDefaultsGet(
        requestParameters.opType,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Label Space Mixins
   * @param {DefaultsApiGetLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultsApi
   */
  public getLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGet(
    requestParameters: DefaultsApiGetLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultsApiFp(this.configuration)
      .getLabelSpaceMixinsLabelSpacesLabelSpaceClsMixinsGet(
        requestParameters.labelSpaceCls,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Return all static information about LF types and their properties.  Returns information for just one LF if a template name is specified, otherwise returns a information for all LFs.
   * @summary Get Lf Info
   * @param {DefaultsApiGetLfInfoDefaultsLfInfoGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultsApi
   */
  public getLfInfoDefaultsLfInfoGet(
    requestParameters: DefaultsApiGetLfInfoDefaultsLfInfoGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultsApiFp(this.configuration)
      .getLfInfoDefaultsLfInfoGet(
        requestParameters.templateType,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Return a list of default configs for all available models in the backend
   * @summary List Default Model Configs
   * @param {DefaultsApiListDefaultModelConfigsDefaultsTaskTypeModelConfigsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultsApi
   */
  public listDefaultModelConfigsDefaultsTaskTypeModelConfigsGet(
    requestParameters: DefaultsApiListDefaultModelConfigsDefaultsTaskTypeModelConfigsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultsApiFp(this.configuration)
      .listDefaultModelConfigsDefaultsTaskTypeModelConfigsGet(
        requestParameters.taskType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Postprocessor Settings
   * @param {DefaultsApiListPostprocessorSettingsDefaultsTaskTypePostprocessorsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultsApi
   */
  public listPostprocessorSettingsDefaultsTaskTypePostprocessorsGet(
    requestParameters: DefaultsApiListPostprocessorSettingsDefaultsTaskTypePostprocessorsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultsApiFp(this.configuration)
      .listPostprocessorSettingsDefaultsTaskTypePostprocessorsGet(
        requestParameters.taskType,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Preprocessor Settings
   * @param {DefaultsApiListPreprocessorSettingsDefaultsTaskTypePreprocessorsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultsApi
   */
  public listPreprocessorSettingsDefaultsTaskTypePreprocessorsGet(
    requestParameters: DefaultsApiListPreprocessorSettingsDefaultsTaskTypePreprocessorsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultsApiFp(this.configuration)
      .listPreprocessorSettingsDefaultsTaskTypePreprocessorsGet(
        requestParameters.taskType,
        requestParameters.featurizersOnly,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Return a list of columns associated with extraction tasks
   * @summary List Span Columns
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultsApi
   */
  public listSpanColumnsDefaultsSpanColumnsGet(
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultsApiFp(this.configuration)
      .listSpanColumnsDefaultsSpanColumnsGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DeployApi - axios parameter creator
 * @export
 */
export const DeployApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Application Deployment
     * @param {CreateApplicationDeploymentParams} createApplicationDeploymentParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApplicationDeploymentDeploymentsPost: async (
      createApplicationDeploymentParams: CreateApplicationDeploymentParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createApplicationDeploymentParams' is not null or undefined
      assertParamExists(
        'createApplicationDeploymentDeploymentsPost',
        'createApplicationDeploymentParams',
        createApplicationDeploymentParams,
      );
      const localVarPath = `/deployments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createApplicationDeploymentParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete specific exported application.
     * @summary Delete Application Export
     * @param {number} exportUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApplicationExportExportsExportUidDelete: async (
      exportUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'exportUid' is not null or undefined
      assertParamExists(
        'deleteApplicationExportExportsExportUidDelete',
        'exportUid',
        exportUid,
      );
      const localVarPath = `/exports/{export_uid}`.replace(
        `{${'export_uid'}}`,
        encodeURIComponent(String(exportUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * NB: It\'s possible to write this logic such that for MLflowModel deployments, we download the model from the model registry into temporary storage and then stream the model out from there. The issue with this is that these packages can be very large, potentially GBs in size (for BERT e.g.), and it would block the webserver for unacceptably long periods of time to download this ourselves and then stream it out.  Instead, when writing a package to the model registry, we also archive the model in the data volume and persist a reference to that archive. That way, on download requests, we can stream directly from this pre-archived file already on the data volume. This is not ideal as this breaks encapsulation - we\'re now persisting data outside of the model registry. However, we have to do this for now to ensure we don\'t choke when downloading large models.  To do this, for future reference, you would do somthing like archive = MLflowModel(...).download(.., archive=True) return StreamingResponse(     seq_chunks(archive, chunk_size), media_type=\"application/zip\" )
     * @summary Download Exported Workflow
     * @param {number} exportUid
     * @param {number} [chunkSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadExportedWorkflowExportsExportUidDownloadGet: async (
      exportUid: number,
      chunkSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'exportUid' is not null or undefined
      assertParamExists(
        'downloadExportedWorkflowExportsExportUidDownloadGet',
        'exportUid',
        exportUid,
      );
      const localVarPath = `/exports/{export_uid}/download`.replace(
        `{${'export_uid'}}`,
        encodeURIComponent(String(exportUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (chunkSize !== undefined) {
        localVarQueryParameter['chunk_size'] = chunkSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch specific exported application.
     * @summary Get Application Export
     * @param {number} exportUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationExportExportsExportUidGet: async (
      exportUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'exportUid' is not null or undefined
      assertParamExists(
        'getApplicationExportExportsExportUidGet',
        'exportUid',
        exportUid,
      );
      const localVarPath = `/exports/{export_uid}`.replace(
        `{${'export_uid'}}`,
        encodeURIComponent(String(exportUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch exported versions of an application.
     * @summary Get Application Exports
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationExportsApplicationsApplicationUidExportsGet: async (
      applicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'getApplicationExportsApplicationsApplicationUidExportsGet',
        'applicationUid',
        applicationUid,
      );
      const localVarPath = `/applications/{application_uid}/exports`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Patch metadata of an exported application.
     * @summary Patch Application Export
     * @param {number} exportUid
     * @param {PatchExportPayload} patchExportPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchApplicationExportExportsExportUidPatch: async (
      exportUid: number,
      patchExportPayload: PatchExportPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'exportUid' is not null or undefined
      assertParamExists(
        'patchApplicationExportExportsExportUidPatch',
        'exportUid',
        exportUid,
      );
      // verify required parameter 'patchExportPayload' is not null or undefined
      assertParamExists(
        'patchApplicationExportExportsExportUidPatch',
        'patchExportPayload',
        patchExportPayload,
      );
      const localVarPath = `/exports/{export_uid}`.replace(
        `{${'export_uid'}}`,
        encodeURIComponent(String(exportUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchExportPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register Deployment
     * @param {number} deploymentUid
     * @param {ExportMLflowModel} exportMLflowModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerDeploymentDeploymentsDeploymentUidRegisterPost: async (
      deploymentUid: number,
      exportMLflowModel: ExportMLflowModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deploymentUid' is not null or undefined
      assertParamExists(
        'registerDeploymentDeploymentsDeploymentUidRegisterPost',
        'deploymentUid',
        deploymentUid,
      );
      // verify required parameter 'exportMLflowModel' is not null or undefined
      assertParamExists(
        'registerDeploymentDeploymentsDeploymentUidRegisterPost',
        'exportMLflowModel',
        exportMLflowModel,
      );
      const localVarPath = `/deployments/{deployment_uid}/register`.replace(
        `{${'deployment_uid'}}`,
        encodeURIComponent(String(deploymentUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        exportMLflowModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeployApi - functional programming interface
 * @export
 */
export const DeployApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DeployApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Application Deployment
     * @param {CreateApplicationDeploymentParams} createApplicationDeploymentParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createApplicationDeploymentDeploymentsPost(
      createApplicationDeploymentParams: CreateApplicationDeploymentParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createApplicationDeploymentDeploymentsPost(
          createApplicationDeploymentParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DeployApi.createApplicationDeploymentDeploymentsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete specific exported application.
     * @summary Delete Application Export
     * @param {number} exportUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteApplicationExportExportsExportUidDelete(
      exportUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteApplicationExportExportsExportUidDelete(
          exportUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DeployApi.deleteApplicationExportExportsExportUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * NB: It\'s possible to write this logic such that for MLflowModel deployments, we download the model from the model registry into temporary storage and then stream the model out from there. The issue with this is that these packages can be very large, potentially GBs in size (for BERT e.g.), and it would block the webserver for unacceptably long periods of time to download this ourselves and then stream it out.  Instead, when writing a package to the model registry, we also archive the model in the data volume and persist a reference to that archive. That way, on download requests, we can stream directly from this pre-archived file already on the data volume. This is not ideal as this breaks encapsulation - we\'re now persisting data outside of the model registry. However, we have to do this for now to ensure we don\'t choke when downloading large models.  To do this, for future reference, you would do somthing like archive = MLflowModel(...).download(.., archive=True) return StreamingResponse(     seq_chunks(archive, chunk_size), media_type=\"application/zip\" )
     * @summary Download Exported Workflow
     * @param {number} exportUid
     * @param {number} [chunkSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadExportedWorkflowExportsExportUidDownloadGet(
      exportUid: number,
      chunkSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.downloadExportedWorkflowExportsExportUidDownloadGet(
          exportUid,
          chunkSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DeployApi.downloadExportedWorkflowExportsExportUidDownloadGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch specific exported application.
     * @summary Get Application Export
     * @param {number} exportUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplicationExportExportsExportUidGet(
      exportUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationDeployment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getApplicationExportExportsExportUidGet(
          exportUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DeployApi.getApplicationExportExportsExportUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch exported versions of an application.
     * @summary Get Application Exports
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplicationExportsApplicationsApplicationUidExportsGet(
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<HydrateApplicationDeploymentMetadata>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getApplicationExportsApplicationsApplicationUidExportsGet(
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DeployApi.getApplicationExportsApplicationsApplicationUidExportsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Patch metadata of an exported application.
     * @summary Patch Application Export
     * @param {number} exportUid
     * @param {PatchExportPayload} patchExportPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchApplicationExportExportsExportUidPatch(
      exportUid: number,
      patchExportPayload: PatchExportPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationDeployment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchApplicationExportExportsExportUidPatch(
          exportUid,
          patchExportPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DeployApi.patchApplicationExportExportsExportUidPatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Register Deployment
     * @param {number} deploymentUid
     * @param {ExportMLflowModel} exportMLflowModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerDeploymentDeploymentsDeploymentUidRegisterPost(
      deploymentUid: number,
      exportMLflowModel: ExportMLflowModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.registerDeploymentDeploymentsDeploymentUidRegisterPost(
          deploymentUid,
          exportMLflowModel,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DeployApi.registerDeploymentDeploymentsDeploymentUidRegisterPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DeployApi - factory interface
 * @export
 */
export const DeployApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DeployApiFp(configuration);
  return {
    /**
     *
     * @summary Create Application Deployment
     * @param {DeployApiCreateApplicationDeploymentDeploymentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApplicationDeploymentDeploymentsPost(
      requestParameters: DeployApiCreateApplicationDeploymentDeploymentsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .createApplicationDeploymentDeploymentsPost(
          requestParameters.createApplicationDeploymentParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Delete specific exported application.
     * @summary Delete Application Export
     * @param {DeployApiDeleteApplicationExportExportsExportUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteApplicationExportExportsExportUidDelete(
      requestParameters: DeployApiDeleteApplicationExportExportsExportUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteApplicationExportExportsExportUidDelete(
          requestParameters.exportUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * NB: It\'s possible to write this logic such that for MLflowModel deployments, we download the model from the model registry into temporary storage and then stream the model out from there. The issue with this is that these packages can be very large, potentially GBs in size (for BERT e.g.), and it would block the webserver for unacceptably long periods of time to download this ourselves and then stream it out.  Instead, when writing a package to the model registry, we also archive the model in the data volume and persist a reference to that archive. That way, on download requests, we can stream directly from this pre-archived file already on the data volume. This is not ideal as this breaks encapsulation - we\'re now persisting data outside of the model registry. However, we have to do this for now to ensure we don\'t choke when downloading large models.  To do this, for future reference, you would do somthing like archive = MLflowModel(...).download(.., archive=True) return StreamingResponse(     seq_chunks(archive, chunk_size), media_type=\"application/zip\" )
     * @summary Download Exported Workflow
     * @param {DeployApiDownloadExportedWorkflowExportsExportUidDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadExportedWorkflowExportsExportUidDownloadGet(
      requestParameters: DeployApiDownloadExportedWorkflowExportsExportUidDownloadGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .downloadExportedWorkflowExportsExportUidDownloadGet(
          requestParameters.exportUid,
          requestParameters.chunkSize,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch specific exported application.
     * @summary Get Application Export
     * @param {DeployApiGetApplicationExportExportsExportUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationExportExportsExportUidGet(
      requestParameters: DeployApiGetApplicationExportExportsExportUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ApplicationDeployment> {
      return localVarFp
        .getApplicationExportExportsExportUidGet(
          requestParameters.exportUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch exported versions of an application.
     * @summary Get Application Exports
     * @param {DeployApiGetApplicationExportsApplicationsApplicationUidExportsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationExportsApplicationsApplicationUidExportsGet(
      requestParameters: DeployApiGetApplicationExportsApplicationsApplicationUidExportsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<HydrateApplicationDeploymentMetadata>> {
      return localVarFp
        .getApplicationExportsApplicationsApplicationUidExportsGet(
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Patch metadata of an exported application.
     * @summary Patch Application Export
     * @param {DeployApiPatchApplicationExportExportsExportUidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchApplicationExportExportsExportUidPatch(
      requestParameters: DeployApiPatchApplicationExportExportsExportUidPatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ApplicationDeployment> {
      return localVarFp
        .patchApplicationExportExportsExportUidPatch(
          requestParameters.exportUid,
          requestParameters.patchExportPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Register Deployment
     * @param {DeployApiRegisterDeploymentDeploymentsDeploymentUidRegisterPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerDeploymentDeploymentsDeploymentUidRegisterPost(
      requestParameters: DeployApiRegisterDeploymentDeploymentsDeploymentUidRegisterPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .registerDeploymentDeploymentsDeploymentUidRegisterPost(
          requestParameters.deploymentUid,
          requestParameters.exportMLflowModel,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createApplicationDeploymentDeploymentsPost operation in DeployApi.
 * @export
 * @interface DeployApiCreateApplicationDeploymentDeploymentsPostRequest
 */
export interface DeployApiCreateApplicationDeploymentDeploymentsPostRequest {
  /**
   *
   * @type {CreateApplicationDeploymentParams}
   * @memberof DeployApiCreateApplicationDeploymentDeploymentsPost
   */
  readonly createApplicationDeploymentParams: CreateApplicationDeploymentParams;
}

/**
 * Request parameters for deleteApplicationExportExportsExportUidDelete operation in DeployApi.
 * @export
 * @interface DeployApiDeleteApplicationExportExportsExportUidDeleteRequest
 */
export interface DeployApiDeleteApplicationExportExportsExportUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof DeployApiDeleteApplicationExportExportsExportUidDelete
   */
  readonly exportUid: number;
}

/**
 * Request parameters for downloadExportedWorkflowExportsExportUidDownloadGet operation in DeployApi.
 * @export
 * @interface DeployApiDownloadExportedWorkflowExportsExportUidDownloadGetRequest
 */
export interface DeployApiDownloadExportedWorkflowExportsExportUidDownloadGetRequest {
  /**
   *
   * @type {number}
   * @memberof DeployApiDownloadExportedWorkflowExportsExportUidDownloadGet
   */
  readonly exportUid: number;

  /**
   *
   * @type {number}
   * @memberof DeployApiDownloadExportedWorkflowExportsExportUidDownloadGet
   */
  readonly chunkSize?: number;
}

/**
 * Request parameters for getApplicationExportExportsExportUidGet operation in DeployApi.
 * @export
 * @interface DeployApiGetApplicationExportExportsExportUidGetRequest
 */
export interface DeployApiGetApplicationExportExportsExportUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof DeployApiGetApplicationExportExportsExportUidGet
   */
  readonly exportUid: number;
}

/**
 * Request parameters for getApplicationExportsApplicationsApplicationUidExportsGet operation in DeployApi.
 * @export
 * @interface DeployApiGetApplicationExportsApplicationsApplicationUidExportsGetRequest
 */
export interface DeployApiGetApplicationExportsApplicationsApplicationUidExportsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DeployApiGetApplicationExportsApplicationsApplicationUidExportsGet
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for patchApplicationExportExportsExportUidPatch operation in DeployApi.
 * @export
 * @interface DeployApiPatchApplicationExportExportsExportUidPatchRequest
 */
export interface DeployApiPatchApplicationExportExportsExportUidPatchRequest {
  /**
   *
   * @type {number}
   * @memberof DeployApiPatchApplicationExportExportsExportUidPatch
   */
  readonly exportUid: number;

  /**
   *
   * @type {PatchExportPayload}
   * @memberof DeployApiPatchApplicationExportExportsExportUidPatch
   */
  readonly patchExportPayload: PatchExportPayload;
}

/**
 * Request parameters for registerDeploymentDeploymentsDeploymentUidRegisterPost operation in DeployApi.
 * @export
 * @interface DeployApiRegisterDeploymentDeploymentsDeploymentUidRegisterPostRequest
 */
export interface DeployApiRegisterDeploymentDeploymentsDeploymentUidRegisterPostRequest {
  /**
   *
   * @type {number}
   * @memberof DeployApiRegisterDeploymentDeploymentsDeploymentUidRegisterPost
   */
  readonly deploymentUid: number;

  /**
   *
   * @type {ExportMLflowModel}
   * @memberof DeployApiRegisterDeploymentDeploymentsDeploymentUidRegisterPost
   */
  readonly exportMLflowModel: ExportMLflowModel;
}

/**
 * DeployApi - object-oriented interface
 * @export
 * @class DeployApi
 * @extends {BaseAPI}
 */
export class DeployApi extends BaseAPI {
  /**
   *
   * @summary Create Application Deployment
   * @param {DeployApiCreateApplicationDeploymentDeploymentsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeployApi
   */
  public createApplicationDeploymentDeploymentsPost(
    requestParameters: DeployApiCreateApplicationDeploymentDeploymentsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DeployApiFp(this.configuration)
      .createApplicationDeploymentDeploymentsPost(
        requestParameters.createApplicationDeploymentParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete specific exported application.
   * @summary Delete Application Export
   * @param {DeployApiDeleteApplicationExportExportsExportUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeployApi
   */
  public deleteApplicationExportExportsExportUidDelete(
    requestParameters: DeployApiDeleteApplicationExportExportsExportUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DeployApiFp(this.configuration)
      .deleteApplicationExportExportsExportUidDelete(
        requestParameters.exportUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * NB: It\'s possible to write this logic such that for MLflowModel deployments, we download the model from the model registry into temporary storage and then stream the model out from there. The issue with this is that these packages can be very large, potentially GBs in size (for BERT e.g.), and it would block the webserver for unacceptably long periods of time to download this ourselves and then stream it out.  Instead, when writing a package to the model registry, we also archive the model in the data volume and persist a reference to that archive. That way, on download requests, we can stream directly from this pre-archived file already on the data volume. This is not ideal as this breaks encapsulation - we\'re now persisting data outside of the model registry. However, we have to do this for now to ensure we don\'t choke when downloading large models.  To do this, for future reference, you would do somthing like archive = MLflowModel(...).download(.., archive=True) return StreamingResponse(     seq_chunks(archive, chunk_size), media_type=\"application/zip\" )
   * @summary Download Exported Workflow
   * @param {DeployApiDownloadExportedWorkflowExportsExportUidDownloadGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeployApi
   */
  public downloadExportedWorkflowExportsExportUidDownloadGet(
    requestParameters: DeployApiDownloadExportedWorkflowExportsExportUidDownloadGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DeployApiFp(this.configuration)
      .downloadExportedWorkflowExportsExportUidDownloadGet(
        requestParameters.exportUid,
        requestParameters.chunkSize,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch specific exported application.
   * @summary Get Application Export
   * @param {DeployApiGetApplicationExportExportsExportUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeployApi
   */
  public getApplicationExportExportsExportUidGet(
    requestParameters: DeployApiGetApplicationExportExportsExportUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DeployApiFp(this.configuration)
      .getApplicationExportExportsExportUidGet(
        requestParameters.exportUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch exported versions of an application.
   * @summary Get Application Exports
   * @param {DeployApiGetApplicationExportsApplicationsApplicationUidExportsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeployApi
   */
  public getApplicationExportsApplicationsApplicationUidExportsGet(
    requestParameters: DeployApiGetApplicationExportsApplicationsApplicationUidExportsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DeployApiFp(this.configuration)
      .getApplicationExportsApplicationsApplicationUidExportsGet(
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Patch metadata of an exported application.
   * @summary Patch Application Export
   * @param {DeployApiPatchApplicationExportExportsExportUidPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeployApi
   */
  public patchApplicationExportExportsExportUidPatch(
    requestParameters: DeployApiPatchApplicationExportExportsExportUidPatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DeployApiFp(this.configuration)
      .patchApplicationExportExportsExportUidPatch(
        requestParameters.exportUid,
        requestParameters.patchExportPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Register Deployment
   * @param {DeployApiRegisterDeploymentDeploymentsDeploymentUidRegisterPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeployApi
   */
  public registerDeploymentDeploymentsDeploymentUidRegisterPost(
    requestParameters: DeployApiRegisterDeploymentDeploymentsDeploymentUidRegisterPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DeployApiFp(this.configuration)
      .registerDeploymentDeploymentsDeploymentUidRegisterPost(
        requestParameters.deploymentUid,
        requestParameters.exportMLflowModel,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DevXsApi - axios parameter creator
 * @export
 */
export const DevXsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Dev Xs Batch
     * @param {number} nodeUid
     * @param {XUids} xUids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDevXsBatchNodesNodeUidDevXsBatchPost: async (
      nodeUid: number,
      xUids: XUids,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'createDevXsBatchNodesNodeUidDevXsBatchPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'xUids' is not null or undefined
      assertParamExists(
        'createDevXsBatchNodesNodeUidDevXsBatchPost',
        'xUids',
        xUids,
      );
      const localVarPath = `/nodes/{node_uid}/dev-xs/batch`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        xUids,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Dev Xs
     * @param {number} nodeUid
     * @param {boolean} [isContext]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDevXsNodesNodeUidDevXsGet: async (
      nodeUid: number,
      isContext?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('fetchDevXsNodesNodeUidDevXsGet', 'nodeUid', nodeUid);
      const localVarPath = `/nodes/{node_uid}/dev-xs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isContext !== undefined) {
        localVarQueryParameter['is_context'] = isContext;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove Dev Xs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeDevXsNodesNodeUidDevXsDelete: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'removeDevXsNodesNodeUidDevXsDelete',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/dev-xs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DevXsApi - functional programming interface
 * @export
 */
export const DevXsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DevXsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Dev Xs Batch
     * @param {number} nodeUid
     * @param {XUids} xUids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDevXsBatchNodesNodeUidDevXsBatchPost(
      nodeUid: number,
      xUids: XUids,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DevXBatchResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createDevXsBatchNodesNodeUidDevXsBatchPost(
          nodeUid,
          xUids,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DevXsApi.createDevXsBatchNodesNodeUidDevXsBatchPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Dev Xs
     * @param {number} nodeUid
     * @param {boolean} [isContext]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDevXsNodesNodeUidDevXsGet(
      nodeUid: number,
      isContext?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevXResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchDevXsNodesNodeUidDevXsGet(
          nodeUid,
          isContext,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DevXsApi.fetchDevXsNodesNodeUidDevXsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Remove Dev Xs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeDevXsNodesNodeUidDevXsDelete(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeDevXsNodesNodeUidDevXsDelete(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DevXsApi.removeDevXsNodesNodeUidDevXsDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DevXsApi - factory interface
 * @export
 */
export const DevXsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DevXsApiFp(configuration);
  return {
    /**
     *
     * @summary Create Dev Xs Batch
     * @param {DevXsApiCreateDevXsBatchNodesNodeUidDevXsBatchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDevXsBatchNodesNodeUidDevXsBatchPost(
      requestParameters: DevXsApiCreateDevXsBatchNodesNodeUidDevXsBatchPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DevXBatchResponse> {
      return localVarFp
        .createDevXsBatchNodesNodeUidDevXsBatchPost(
          requestParameters.nodeUid,
          requestParameters.xUids,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Dev Xs
     * @param {DevXsApiFetchDevXsNodesNodeUidDevXsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDevXsNodesNodeUidDevXsGet(
      requestParameters: DevXsApiFetchDevXsNodesNodeUidDevXsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DevXResponse> {
      return localVarFp
        .fetchDevXsNodesNodeUidDevXsGet(
          requestParameters.nodeUid,
          requestParameters.isContext,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Remove Dev Xs
     * @param {DevXsApiRemoveDevXsNodesNodeUidDevXsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeDevXsNodesNodeUidDevXsDelete(
      requestParameters: DevXsApiRemoveDevXsNodesNodeUidDevXsDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .removeDevXsNodesNodeUidDevXsDelete(requestParameters.nodeUid, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createDevXsBatchNodesNodeUidDevXsBatchPost operation in DevXsApi.
 * @export
 * @interface DevXsApiCreateDevXsBatchNodesNodeUidDevXsBatchPostRequest
 */
export interface DevXsApiCreateDevXsBatchNodesNodeUidDevXsBatchPostRequest {
  /**
   *
   * @type {number}
   * @memberof DevXsApiCreateDevXsBatchNodesNodeUidDevXsBatchPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {XUids}
   * @memberof DevXsApiCreateDevXsBatchNodesNodeUidDevXsBatchPost
   */
  readonly xUids: XUids;
}

/**
 * Request parameters for fetchDevXsNodesNodeUidDevXsGet operation in DevXsApi.
 * @export
 * @interface DevXsApiFetchDevXsNodesNodeUidDevXsGetRequest
 */
export interface DevXsApiFetchDevXsNodesNodeUidDevXsGetRequest {
  /**
   *
   * @type {number}
   * @memberof DevXsApiFetchDevXsNodesNodeUidDevXsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof DevXsApiFetchDevXsNodesNodeUidDevXsGet
   */
  readonly isContext?: boolean;
}

/**
 * Request parameters for removeDevXsNodesNodeUidDevXsDelete operation in DevXsApi.
 * @export
 * @interface DevXsApiRemoveDevXsNodesNodeUidDevXsDeleteRequest
 */
export interface DevXsApiRemoveDevXsNodesNodeUidDevXsDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof DevXsApiRemoveDevXsNodesNodeUidDevXsDelete
   */
  readonly nodeUid: number;
}

/**
 * DevXsApi - object-oriented interface
 * @export
 * @class DevXsApi
 * @extends {BaseAPI}
 */
export class DevXsApi extends BaseAPI {
  /**
   *
   * @summary Create Dev Xs Batch
   * @param {DevXsApiCreateDevXsBatchNodesNodeUidDevXsBatchPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DevXsApi
   */
  public createDevXsBatchNodesNodeUidDevXsBatchPost(
    requestParameters: DevXsApiCreateDevXsBatchNodesNodeUidDevXsBatchPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DevXsApiFp(this.configuration)
      .createDevXsBatchNodesNodeUidDevXsBatchPost(
        requestParameters.nodeUid,
        requestParameters.xUids,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Dev Xs
   * @param {DevXsApiFetchDevXsNodesNodeUidDevXsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DevXsApi
   */
  public fetchDevXsNodesNodeUidDevXsGet(
    requestParameters: DevXsApiFetchDevXsNodesNodeUidDevXsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DevXsApiFp(this.configuration)
      .fetchDevXsNodesNodeUidDevXsGet(
        requestParameters.nodeUid,
        requestParameters.isContext,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Remove Dev Xs
   * @param {DevXsApiRemoveDevXsNodesNodeUidDevXsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DevXsApi
   */
  public removeDevXsNodesNodeUidDevXsDelete(
    requestParameters: DevXsApiRemoveDevXsNodesNodeUidDevXsDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DevXsApiFp(this.configuration)
      .removeDevXsNodesNodeUidDevXsDelete(requestParameters.nodeUid, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EmbeddingsApi - axios parameter creator
 * @export
 */
export const EmbeddingsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Compute Embeddings Request
     * @param {number} nodeUid
     * @param {ComputeEmbeddingsPayload} computeEmbeddingsPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeEmbeddingsRequestNodesNodeUidEmbeddingsPost: async (
      nodeUid: number,
      computeEmbeddingsPayload: ComputeEmbeddingsPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'computeEmbeddingsRequestNodesNodeUidEmbeddingsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'computeEmbeddingsPayload' is not null or undefined
      assertParamExists(
        'computeEmbeddingsRequestNodesNodeUidEmbeddingsPost',
        'computeEmbeddingsPayload',
        computeEmbeddingsPayload,
      );
      const localVarPath = `/nodes/{node_uid}/embeddings`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        computeEmbeddingsPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes embeddings for the specified embeddings_uid.
     * @summary Delete Embeddings Request
     * @param {number} nodeUid
     * @param {number} embeddingsUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete: async (
      nodeUid: number,
      embeddingsUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'deleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'embeddingsUid' is not null or undefined
      assertParamExists(
        'deleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete',
        'embeddingsUid',
        embeddingsUid,
      );
      const localVarPath = `/nodes/{node_uid}/embeddings/{embeddings_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'embeddings_uid'}}`,
          encodeURIComponent(String(embeddingsUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the list of models that can be used to compute embeddings for the specified model node.
     * @summary List Embedding Models
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEmbeddingModelsNodesNodeUidEmbeddingsModelsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listEmbeddingModelsNodesNodeUidEmbeddingsModelsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/embeddings-models`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the list of fields that can be used to compute embeddings for the specified model node.
     * @summary List Embeddings Fields Request
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/embeddings-fields`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Lists available embeddings.
     * @summary List Embeddings Request
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEmbeddingsRequestNodesNodeUidEmbeddingsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listEmbeddingsRequestNodesNodeUidEmbeddingsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/embeddings`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Recomputes embeddings for the specified splits. Used when an embedding job is canceled or fails.
     * @summary Recompute Embeddings Request
     * @param {number} nodeUid
     * @param {number} embeddingsUid
     * @param {RecomputeEmbeddingsPayload} recomputeEmbeddingsPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost:
      async (
        nodeUid: number,
        embeddingsUid: number,
        recomputeEmbeddingsPayload: RecomputeEmbeddingsPayload,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'embeddingsUid' is not null or undefined
        assertParamExists(
          'recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost',
          'embeddingsUid',
          embeddingsUid,
        );
        // verify required parameter 'recomputeEmbeddingsPayload' is not null or undefined
        assertParamExists(
          'recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost',
          'recomputeEmbeddingsPayload',
          recomputeEmbeddingsPayload,
        );
        const localVarPath =
          `/nodes/{node_uid}/embeddings/{embeddings_uid}/recompute`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(
              `{${'embeddings_uid'}}`,
              encodeURIComponent(String(embeddingsUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          recomputeEmbeddingsPayload,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * EmbeddingsApi - functional programming interface
 * @export
 */
export const EmbeddingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    EmbeddingsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Compute Embeddings Request
     * @param {number} nodeUid
     * @param {ComputeEmbeddingsPayload} computeEmbeddingsPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async computeEmbeddingsRequestNodesNodeUidEmbeddingsPost(
      nodeUid: number,
      computeEmbeddingsPayload: ComputeEmbeddingsPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ComputeEmbeddingsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.computeEmbeddingsRequestNodesNodeUidEmbeddingsPost(
          nodeUid,
          computeEmbeddingsPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EmbeddingsApi.computeEmbeddingsRequestNodesNodeUidEmbeddingsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Deletes embeddings for the specified embeddings_uid.
     * @summary Delete Embeddings Request
     * @param {number} nodeUid
     * @param {number} embeddingsUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete(
      nodeUid: number,
      embeddingsUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete(
          nodeUid,
          embeddingsUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EmbeddingsApi.deleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Returns the list of models that can be used to compute embeddings for the specified model node.
     * @summary List Embedding Models
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listEmbeddingModelsNodesNodeUidEmbeddingsModelsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ModelType>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listEmbeddingModelsNodesNodeUidEmbeddingsModelsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EmbeddingsApi.listEmbeddingModelsNodesNodeUidEmbeddingsModelsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Returns the list of fields that can be used to compute embeddings for the specified model node.
     * @summary List Embeddings Fields Request
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<EmbeddingsInputFieldInfo>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EmbeddingsApi.listEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Lists available embeddings.
     * @summary List Embeddings Request
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listEmbeddingsRequestNodesNodeUidEmbeddingsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetEmbeddingsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listEmbeddingsRequestNodesNodeUidEmbeddingsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EmbeddingsApi.listEmbeddingsRequestNodesNodeUidEmbeddingsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Recomputes embeddings for the specified splits. Used when an embedding job is canceled or fails.
     * @summary Recompute Embeddings Request
     * @param {number} nodeUid
     * @param {number} embeddingsUid
     * @param {RecomputeEmbeddingsPayload} recomputeEmbeddingsPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost(
      nodeUid: number,
      embeddingsUid: number,
      recomputeEmbeddingsPayload: RecomputeEmbeddingsPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost(
          nodeUid,
          embeddingsUid,
          recomputeEmbeddingsPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EmbeddingsApi.recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * EmbeddingsApi - factory interface
 * @export
 */
export const EmbeddingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EmbeddingsApiFp(configuration);
  return {
    /**
     *
     * @summary Compute Embeddings Request
     * @param {EmbeddingsApiComputeEmbeddingsRequestNodesNodeUidEmbeddingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeEmbeddingsRequestNodesNodeUidEmbeddingsPost(
      requestParameters: EmbeddingsApiComputeEmbeddingsRequestNodesNodeUidEmbeddingsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ComputeEmbeddingsResponse> {
      return localVarFp
        .computeEmbeddingsRequestNodesNodeUidEmbeddingsPost(
          requestParameters.nodeUid,
          requestParameters.computeEmbeddingsPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Deletes embeddings for the specified embeddings_uid.
     * @summary Delete Embeddings Request
     * @param {EmbeddingsApiDeleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete(
      requestParameters: EmbeddingsApiDeleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete(
          requestParameters.nodeUid,
          requestParameters.embeddingsUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Returns the list of models that can be used to compute embeddings for the specified model node.
     * @summary List Embedding Models
     * @param {EmbeddingsApiListEmbeddingModelsNodesNodeUidEmbeddingsModelsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEmbeddingModelsNodesNodeUidEmbeddingsModelsGet(
      requestParameters: EmbeddingsApiListEmbeddingModelsNodesNodeUidEmbeddingsModelsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ModelType>> {
      return localVarFp
        .listEmbeddingModelsNodesNodeUidEmbeddingsModelsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Returns the list of fields that can be used to compute embeddings for the specified model node.
     * @summary List Embeddings Fields Request
     * @param {EmbeddingsApiListEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGet(
      requestParameters: EmbeddingsApiListEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<EmbeddingsInputFieldInfo>> {
      return localVarFp
        .listEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Lists available embeddings.
     * @summary List Embeddings Request
     * @param {EmbeddingsApiListEmbeddingsRequestNodesNodeUidEmbeddingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listEmbeddingsRequestNodesNodeUidEmbeddingsGet(
      requestParameters: EmbeddingsApiListEmbeddingsRequestNodesNodeUidEmbeddingsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetEmbeddingsResponse> {
      return localVarFp
        .listEmbeddingsRequestNodesNodeUidEmbeddingsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Recomputes embeddings for the specified splits. Used when an embedding job is canceled or fails.
     * @summary Recompute Embeddings Request
     * @param {EmbeddingsApiRecomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost(
      requestParameters: EmbeddingsApiRecomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost(
          requestParameters.nodeUid,
          requestParameters.embeddingsUid,
          requestParameters.recomputeEmbeddingsPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for computeEmbeddingsRequestNodesNodeUidEmbeddingsPost operation in EmbeddingsApi.
 * @export
 * @interface EmbeddingsApiComputeEmbeddingsRequestNodesNodeUidEmbeddingsPostRequest
 */
export interface EmbeddingsApiComputeEmbeddingsRequestNodesNodeUidEmbeddingsPostRequest {
  /**
   *
   * @type {number}
   * @memberof EmbeddingsApiComputeEmbeddingsRequestNodesNodeUidEmbeddingsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ComputeEmbeddingsPayload}
   * @memberof EmbeddingsApiComputeEmbeddingsRequestNodesNodeUidEmbeddingsPost
   */
  readonly computeEmbeddingsPayload: ComputeEmbeddingsPayload;
}

/**
 * Request parameters for deleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete operation in EmbeddingsApi.
 * @export
 * @interface EmbeddingsApiDeleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDeleteRequest
 */
export interface EmbeddingsApiDeleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof EmbeddingsApiDeleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof EmbeddingsApiDeleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete
   */
  readonly embeddingsUid: number;
}

/**
 * Request parameters for listEmbeddingModelsNodesNodeUidEmbeddingsModelsGet operation in EmbeddingsApi.
 * @export
 * @interface EmbeddingsApiListEmbeddingModelsNodesNodeUidEmbeddingsModelsGetRequest
 */
export interface EmbeddingsApiListEmbeddingModelsNodesNodeUidEmbeddingsModelsGetRequest {
  /**
   *
   * @type {number}
   * @memberof EmbeddingsApiListEmbeddingModelsNodesNodeUidEmbeddingsModelsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for listEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGet operation in EmbeddingsApi.
 * @export
 * @interface EmbeddingsApiListEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGetRequest
 */
export interface EmbeddingsApiListEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGetRequest {
  /**
   *
   * @type {number}
   * @memberof EmbeddingsApiListEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for listEmbeddingsRequestNodesNodeUidEmbeddingsGet operation in EmbeddingsApi.
 * @export
 * @interface EmbeddingsApiListEmbeddingsRequestNodesNodeUidEmbeddingsGetRequest
 */
export interface EmbeddingsApiListEmbeddingsRequestNodesNodeUidEmbeddingsGetRequest {
  /**
   *
   * @type {number}
   * @memberof EmbeddingsApiListEmbeddingsRequestNodesNodeUidEmbeddingsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost operation in EmbeddingsApi.
 * @export
 * @interface EmbeddingsApiRecomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePostRequest
 */
export interface EmbeddingsApiRecomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePostRequest {
  /**
   *
   * @type {number}
   * @memberof EmbeddingsApiRecomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof EmbeddingsApiRecomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost
   */
  readonly embeddingsUid: number;

  /**
   *
   * @type {RecomputeEmbeddingsPayload}
   * @memberof EmbeddingsApiRecomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost
   */
  readonly recomputeEmbeddingsPayload: RecomputeEmbeddingsPayload;
}

/**
 * EmbeddingsApi - object-oriented interface
 * @export
 * @class EmbeddingsApi
 * @extends {BaseAPI}
 */
export class EmbeddingsApi extends BaseAPI {
  /**
   *
   * @summary Compute Embeddings Request
   * @param {EmbeddingsApiComputeEmbeddingsRequestNodesNodeUidEmbeddingsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmbeddingsApi
   */
  public computeEmbeddingsRequestNodesNodeUidEmbeddingsPost(
    requestParameters: EmbeddingsApiComputeEmbeddingsRequestNodesNodeUidEmbeddingsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EmbeddingsApiFp(this.configuration)
      .computeEmbeddingsRequestNodesNodeUidEmbeddingsPost(
        requestParameters.nodeUid,
        requestParameters.computeEmbeddingsPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Deletes embeddings for the specified embeddings_uid.
   * @summary Delete Embeddings Request
   * @param {EmbeddingsApiDeleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmbeddingsApi
   */
  public deleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete(
    requestParameters: EmbeddingsApiDeleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EmbeddingsApiFp(this.configuration)
      .deleteEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidDelete(
        requestParameters.nodeUid,
        requestParameters.embeddingsUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Returns the list of models that can be used to compute embeddings for the specified model node.
   * @summary List Embedding Models
   * @param {EmbeddingsApiListEmbeddingModelsNodesNodeUidEmbeddingsModelsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmbeddingsApi
   */
  public listEmbeddingModelsNodesNodeUidEmbeddingsModelsGet(
    requestParameters: EmbeddingsApiListEmbeddingModelsNodesNodeUidEmbeddingsModelsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EmbeddingsApiFp(this.configuration)
      .listEmbeddingModelsNodesNodeUidEmbeddingsModelsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Returns the list of fields that can be used to compute embeddings for the specified model node.
   * @summary List Embeddings Fields Request
   * @param {EmbeddingsApiListEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmbeddingsApi
   */
  public listEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGet(
    requestParameters: EmbeddingsApiListEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EmbeddingsApiFp(this.configuration)
      .listEmbeddingsFieldsRequestNodesNodeUidEmbeddingsFieldsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Lists available embeddings.
   * @summary List Embeddings Request
   * @param {EmbeddingsApiListEmbeddingsRequestNodesNodeUidEmbeddingsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmbeddingsApi
   */
  public listEmbeddingsRequestNodesNodeUidEmbeddingsGet(
    requestParameters: EmbeddingsApiListEmbeddingsRequestNodesNodeUidEmbeddingsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EmbeddingsApiFp(this.configuration)
      .listEmbeddingsRequestNodesNodeUidEmbeddingsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Recomputes embeddings for the specified splits. Used when an embedding job is canceled or fails.
   * @summary Recompute Embeddings Request
   * @param {EmbeddingsApiRecomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmbeddingsApi
   */
  public recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost(
    requestParameters: EmbeddingsApiRecomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EmbeddingsApiFp(this.configuration)
      .recomputeEmbeddingsRequestNodesNodeUidEmbeddingsEmbeddingsUidRecomputePost(
        requestParameters.nodeUid,
        requestParameters.embeddingsUid,
        requestParameters.recomputeEmbeddingsPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EvaluationApi - axios parameter creator
 * @export
 */
export const EvaluationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Evaluation Report
     * @param {number} datasetUid
     * @param {EvaluationReportCreationRequest} evaluationReportCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEvaluationReportDatasetDatasetUidEvaluationReportPost: async (
      datasetUid: number,
      evaluationReportCreationRequest: EvaluationReportCreationRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'createEvaluationReportDatasetDatasetUidEvaluationReportPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'evaluationReportCreationRequest' is not null or undefined
      assertParamExists(
        'createEvaluationReportDatasetDatasetUidEvaluationReportPost',
        'evaluationReportCreationRequest',
        evaluationReportCreationRequest,
      );
      const localVarPath = `/dataset/{dataset_uid}/evaluation-report`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        evaluationReportCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Evaluation Report
     * @param {number} datasetUid
     * @param {number} evaluationReportUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete:
      async (
        datasetUid: number,
        evaluationReportUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'datasetUid' is not null or undefined
        assertParamExists(
          'deleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete',
          'datasetUid',
          datasetUid,
        );
        // verify required parameter 'evaluationReportUid' is not null or undefined
        assertParamExists(
          'deleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete',
          'evaluationReportUid',
          evaluationReportUid,
        );
        const localVarPath =
          `/dataset/{dataset_uid}/evaluation-report/{evaluation_report_uid}`
            .replace(
              `{${'dataset_uid'}}`,
              encodeURIComponent(String(datasetUid)),
            )
            .replace(
              `{${'evaluation_report_uid'}}`,
              encodeURIComponent(String(evaluationReportUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'DELETE',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Evaluation Report Data
     * @param {number} datasetUid
     * @param {string} split
     * @param {number} modelUid
     * @param {string} sliceName
     * @param {number} [nodeUid]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {Array<number>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet: async (
      datasetUid: number,
      split: string,
      modelUid: number,
      sliceName: string,
      nodeUid?: number,
      limit?: number,
      offset?: number,
      requestBody?: Array<number>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet',
        'split',
        split,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet',
        'modelUid',
        modelUid,
      );
      // verify required parameter 'sliceName' is not null or undefined
      assertParamExists(
        'getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet',
        'sliceName',
        sliceName,
      );
      const localVarPath =
        `/dataset/{dataset_uid}/evaluation-report-data`.replace(
          `{${'dataset_uid'}}`,
          encodeURIComponent(String(datasetUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (modelUid !== undefined) {
        localVarQueryParameter['model_uid'] = modelUid;
      }

      if (sliceName !== undefined) {
        localVarQueryParameter['slice_name'] = sliceName;
      }

      if (nodeUid !== undefined) {
        localVarQueryParameter['node_uid'] = nodeUid;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Evaluation Report
     * @param {number} datasetUid
     * @param {number} evaluationReportUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet:
      async (
        datasetUid: number,
        evaluationReportUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'datasetUid' is not null or undefined
        assertParamExists(
          'getEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet',
          'datasetUid',
          datasetUid,
        );
        // verify required parameter 'evaluationReportUid' is not null or undefined
        assertParamExists(
          'getEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet',
          'evaluationReportUid',
          evaluationReportUid,
        );
        const localVarPath =
          `/dataset/{dataset_uid}/evaluation-report/{evaluation_report_uid}`
            .replace(
              `{${'dataset_uid'}}`,
              encodeURIComponent(String(datasetUid)),
            )
            .replace(
              `{${'evaluation_report_uid'}}`,
              encodeURIComponent(String(evaluationReportUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Evaluation Reports For Dataset
     * @param {number} datasetUid
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet: async (
      datasetUid: number,
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/evaluation-report`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start Evaluation Job
     * @param {number} datasetUid
     * @param {EvaluationRequestParams} evaluationRequestParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startEvaluationJobDatasetDatasetUidEvaluatePost: async (
      datasetUid: number,
      evaluationRequestParams: EvaluationRequestParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'startEvaluationJobDatasetDatasetUidEvaluatePost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'evaluationRequestParams' is not null or undefined
      assertParamExists(
        'startEvaluationJobDatasetDatasetUidEvaluatePost',
        'evaluationRequestParams',
        evaluationRequestParams,
      );
      const localVarPath = `/dataset/{dataset_uid}/evaluate`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        evaluationRequestParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Evaluation Report
     * @param {number} datasetUid
     * @param {number} evaluationReportUid
     * @param {UpdateEvaluationReport} updateEvaluationReport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut:
      async (
        datasetUid: number,
        evaluationReportUid: number,
        updateEvaluationReport: UpdateEvaluationReport,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'datasetUid' is not null or undefined
        assertParamExists(
          'updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut',
          'datasetUid',
          datasetUid,
        );
        // verify required parameter 'evaluationReportUid' is not null or undefined
        assertParamExists(
          'updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut',
          'evaluationReportUid',
          evaluationReportUid,
        );
        // verify required parameter 'updateEvaluationReport' is not null or undefined
        assertParamExists(
          'updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut',
          'updateEvaluationReport',
          updateEvaluationReport,
        );
        const localVarPath =
          `/dataset/{dataset_uid}/evaluation-report/{evaluation_report_uid}`
            .replace(
              `{${'dataset_uid'}}`,
              encodeURIComponent(String(datasetUid)),
            )
            .replace(
              `{${'evaluation_report_uid'}}`,
              encodeURIComponent(String(evaluationReportUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          updateEvaluationReport,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * EvaluationApi - functional programming interface
 * @export
 */
export const EvaluationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    EvaluationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Evaluation Report
     * @param {number} datasetUid
     * @param {EvaluationReportCreationRequest} evaluationReportCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEvaluationReportDatasetDatasetUidEvaluationReportPost(
      datasetUid: number,
      evaluationReportCreationRequest: EvaluationReportCreationRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EvaluationReportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createEvaluationReportDatasetDatasetUidEvaluationReportPost(
          datasetUid,
          evaluationReportCreationRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EvaluationApi.createEvaluationReportDatasetDatasetUidEvaluationReportPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Evaluation Report
     * @param {number} datasetUid
     * @param {number} evaluationReportUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete(
      datasetUid: number,
      evaluationReportUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete(
          datasetUid,
          evaluationReportUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EvaluationApi.deleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Evaluation Report Data
     * @param {number} datasetUid
     * @param {string} split
     * @param {number} modelUid
     * @param {string} sliceName
     * @param {number} [nodeUid]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {Array<number>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet(
      datasetUid: number,
      split: string,
      modelUid: number,
      sliceName: string,
      nodeUid?: number,
      limit?: number,
      offset?: number,
      requestBody?: Array<number>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EvalReportDataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet(
          datasetUid,
          split,
          modelUid,
          sliceName,
          nodeUid,
          limit,
          offset,
          requestBody,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EvaluationApi.getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Evaluation Report
     * @param {number} datasetUid
     * @param {number} evaluationReportUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet(
      datasetUid: number,
      evaluationReportUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EvaluationReportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet(
          datasetUid,
          evaluationReportUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EvaluationApi.getEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Evaluation Reports For Dataset
     * @param {number} datasetUid
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet(
      datasetUid: number,
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<EvaluationReportResponse>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet(
          datasetUid,
          limit,
          offset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EvaluationApi.getEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Start Evaluation Job
     * @param {number} datasetUid
     * @param {EvaluationRequestParams} evaluationRequestParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startEvaluationJobDatasetDatasetUidEvaluatePost(
      datasetUid: number,
      evaluationRequestParams: EvaluationRequestParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MetricComputeJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.startEvaluationJobDatasetDatasetUidEvaluatePost(
          datasetUid,
          evaluationRequestParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EvaluationApi.startEvaluationJobDatasetDatasetUidEvaluatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Evaluation Report
     * @param {number} datasetUid
     * @param {number} evaluationReportUid
     * @param {UpdateEvaluationReport} updateEvaluationReport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut(
      datasetUid: number,
      evaluationReportUid: number,
      updateEvaluationReport: UpdateEvaluationReport,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EvaluationReportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut(
          datasetUid,
          evaluationReportUid,
          updateEvaluationReport,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'EvaluationApi.updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * EvaluationApi - factory interface
 * @export
 */
export const EvaluationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EvaluationApiFp(configuration);
  return {
    /**
     *
     * @summary Create Evaluation Report
     * @param {EvaluationApiCreateEvaluationReportDatasetDatasetUidEvaluationReportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEvaluationReportDatasetDatasetUidEvaluationReportPost(
      requestParameters: EvaluationApiCreateEvaluationReportDatasetDatasetUidEvaluationReportPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<EvaluationReportResponse> {
      return localVarFp
        .createEvaluationReportDatasetDatasetUidEvaluationReportPost(
          requestParameters.datasetUid,
          requestParameters.evaluationReportCreationRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Evaluation Report
     * @param {EvaluationApiDeleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete(
      requestParameters: EvaluationApiDeleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete(
          requestParameters.datasetUid,
          requestParameters.evaluationReportUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Evaluation Report Data
     * @param {EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet(
      requestParameters: EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<EvalReportDataResponse> {
      return localVarFp
        .getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet(
          requestParameters.datasetUid,
          requestParameters.split,
          requestParameters.modelUid,
          requestParameters.sliceName,
          requestParameters.nodeUid,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.requestBody,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Evaluation Report
     * @param {EvaluationApiGetEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet(
      requestParameters: EvaluationApiGetEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<EvaluationReportResponse> {
      return localVarFp
        .getEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet(
          requestParameters.datasetUid,
          requestParameters.evaluationReportUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Evaluation Reports For Dataset
     * @param {EvaluationApiGetEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet(
      requestParameters: EvaluationApiGetEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<EvaluationReportResponse>> {
      return localVarFp
        .getEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet(
          requestParameters.datasetUid,
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Start Evaluation Job
     * @param {EvaluationApiStartEvaluationJobDatasetDatasetUidEvaluatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startEvaluationJobDatasetDatasetUidEvaluatePost(
      requestParameters: EvaluationApiStartEvaluationJobDatasetDatasetUidEvaluatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MetricComputeJobResponse> {
      return localVarFp
        .startEvaluationJobDatasetDatasetUidEvaluatePost(
          requestParameters.datasetUid,
          requestParameters.evaluationRequestParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Evaluation Report
     * @param {EvaluationApiUpdateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut(
      requestParameters: EvaluationApiUpdateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<EvaluationReportResponse> {
      return localVarFp
        .updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut(
          requestParameters.datasetUid,
          requestParameters.evaluationReportUid,
          requestParameters.updateEvaluationReport,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createEvaluationReportDatasetDatasetUidEvaluationReportPost operation in EvaluationApi.
 * @export
 * @interface EvaluationApiCreateEvaluationReportDatasetDatasetUidEvaluationReportPostRequest
 */
export interface EvaluationApiCreateEvaluationReportDatasetDatasetUidEvaluationReportPostRequest {
  /**
   *
   * @type {number}
   * @memberof EvaluationApiCreateEvaluationReportDatasetDatasetUidEvaluationReportPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {EvaluationReportCreationRequest}
   * @memberof EvaluationApiCreateEvaluationReportDatasetDatasetUidEvaluationReportPost
   */
  readonly evaluationReportCreationRequest: EvaluationReportCreationRequest;
}

/**
 * Request parameters for deleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete operation in EvaluationApi.
 * @export
 * @interface EvaluationApiDeleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDeleteRequest
 */
export interface EvaluationApiDeleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof EvaluationApiDeleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof EvaluationApiDeleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete
   */
  readonly evaluationReportUid: number;
}

/**
 * Request parameters for getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet operation in EvaluationApi.
 * @export
 * @interface EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGetRequest
 */
export interface EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGetRequest {
  /**
   *
   * @type {number}
   * @memberof EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {string}
   * @memberof EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet
   */
  readonly split: string;

  /**
   *
   * @type {number}
   * @memberof EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {string}
   * @memberof EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet
   */
  readonly sliceName: string;

  /**
   *
   * @type {number}
   * @memberof EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet
   */
  readonly nodeUid?: number;

  /**
   *
   * @type {number}
   * @memberof EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet
   */
  readonly offset?: number;

  /**
   *
   * @type {Array<number>}
   * @memberof EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet
   */
  readonly requestBody?: Array<number>;
}

/**
 * Request parameters for getEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet operation in EvaluationApi.
 * @export
 * @interface EvaluationApiGetEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGetRequest
 */
export interface EvaluationApiGetEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof EvaluationApiGetEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof EvaluationApiGetEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet
   */
  readonly evaluationReportUid: number;
}

/**
 * Request parameters for getEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet operation in EvaluationApi.
 * @export
 * @interface EvaluationApiGetEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGetRequest
 */
export interface EvaluationApiGetEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGetRequest {
  /**
   *
   * @type {number}
   * @memberof EvaluationApiGetEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof EvaluationApiGetEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof EvaluationApiGetEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet
   */
  readonly offset?: number;
}

/**
 * Request parameters for startEvaluationJobDatasetDatasetUidEvaluatePost operation in EvaluationApi.
 * @export
 * @interface EvaluationApiStartEvaluationJobDatasetDatasetUidEvaluatePostRequest
 */
export interface EvaluationApiStartEvaluationJobDatasetDatasetUidEvaluatePostRequest {
  /**
   *
   * @type {number}
   * @memberof EvaluationApiStartEvaluationJobDatasetDatasetUidEvaluatePost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {EvaluationRequestParams}
   * @memberof EvaluationApiStartEvaluationJobDatasetDatasetUidEvaluatePost
   */
  readonly evaluationRequestParams: EvaluationRequestParams;
}

/**
 * Request parameters for updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut operation in EvaluationApi.
 * @export
 * @interface EvaluationApiUpdateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPutRequest
 */
export interface EvaluationApiUpdateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof EvaluationApiUpdateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof EvaluationApiUpdateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut
   */
  readonly evaluationReportUid: number;

  /**
   *
   * @type {UpdateEvaluationReport}
   * @memberof EvaluationApiUpdateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut
   */
  readonly updateEvaluationReport: UpdateEvaluationReport;
}

/**
 * EvaluationApi - object-oriented interface
 * @export
 * @class EvaluationApi
 * @extends {BaseAPI}
 */
export class EvaluationApi extends BaseAPI {
  /**
   *
   * @summary Create Evaluation Report
   * @param {EvaluationApiCreateEvaluationReportDatasetDatasetUidEvaluationReportPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvaluationApi
   */
  public createEvaluationReportDatasetDatasetUidEvaluationReportPost(
    requestParameters: EvaluationApiCreateEvaluationReportDatasetDatasetUidEvaluationReportPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EvaluationApiFp(this.configuration)
      .createEvaluationReportDatasetDatasetUidEvaluationReportPost(
        requestParameters.datasetUid,
        requestParameters.evaluationReportCreationRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Evaluation Report
   * @param {EvaluationApiDeleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvaluationApi
   */
  public deleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete(
    requestParameters: EvaluationApiDeleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EvaluationApiFp(this.configuration)
      .deleteEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidDelete(
        requestParameters.datasetUid,
        requestParameters.evaluationReportUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Evaluation Report Data
   * @param {EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvaluationApi
   */
  public getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet(
    requestParameters: EvaluationApiGetEvaluationReportDataDatasetDatasetUidEvaluationReportDataGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EvaluationApiFp(this.configuration)
      .getEvaluationReportDataDatasetDatasetUidEvaluationReportDataGet(
        requestParameters.datasetUid,
        requestParameters.split,
        requestParameters.modelUid,
        requestParameters.sliceName,
        requestParameters.nodeUid,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.requestBody,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Evaluation Report
   * @param {EvaluationApiGetEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvaluationApi
   */
  public getEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet(
    requestParameters: EvaluationApiGetEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EvaluationApiFp(this.configuration)
      .getEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidGet(
        requestParameters.datasetUid,
        requestParameters.evaluationReportUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Evaluation Reports For Dataset
   * @param {EvaluationApiGetEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvaluationApi
   */
  public getEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet(
    requestParameters: EvaluationApiGetEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EvaluationApiFp(this.configuration)
      .getEvaluationReportsForDatasetDatasetDatasetUidEvaluationReportGet(
        requestParameters.datasetUid,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start Evaluation Job
   * @param {EvaluationApiStartEvaluationJobDatasetDatasetUidEvaluatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvaluationApi
   */
  public startEvaluationJobDatasetDatasetUidEvaluatePost(
    requestParameters: EvaluationApiStartEvaluationJobDatasetDatasetUidEvaluatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EvaluationApiFp(this.configuration)
      .startEvaluationJobDatasetDatasetUidEvaluatePost(
        requestParameters.datasetUid,
        requestParameters.evaluationRequestParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Evaluation Report
   * @param {EvaluationApiUpdateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EvaluationApi
   */
  public updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut(
    requestParameters: EvaluationApiUpdateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EvaluationApiFp(this.configuration)
      .updateEvaluationReportDatasetDatasetUidEvaluationReportEvaluationReportUidPut(
        requestParameters.datasetUid,
        requestParameters.evaluationReportUid,
        requestParameters.updateEvaluationReport,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Fetch Event Metrics
     * @param {string} jobId
     * @param {number} [timeRangeMinutes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchEventMetricsEventsJobIdGet: async (
      jobId: string,
      timeRangeMinutes?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('fetchEventMetricsEventsJobIdGet', 'jobId', jobId);
      const localVarPath = `/events/{job_id}`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (timeRangeMinutes !== undefined) {
        localVarQueryParameter['time_range_minutes'] = timeRangeMinutes;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Fetch Event Metrics
     * @param {string} jobId
     * @param {number} [timeRangeMinutes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchEventMetricsEventsJobIdGet(
      jobId: string,
      timeRangeMinutes?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchEventMetricsEventsJobIdGet(
          jobId,
          timeRangeMinutes,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['EventsApi.fetchEventMetricsEventsJobIdGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EventsApiFp(configuration);
  return {
    /**
     *
     * @summary Fetch Event Metrics
     * @param {EventsApiFetchEventMetricsEventsJobIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchEventMetricsEventsJobIdGet(
      requestParameters: EventsApiFetchEventMetricsEventsJobIdGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchEventMetricsEventsJobIdGet(
          requestParameters.jobId,
          requestParameters.timeRangeMinutes,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for fetchEventMetricsEventsJobIdGet operation in EventsApi.
 * @export
 * @interface EventsApiFetchEventMetricsEventsJobIdGetRequest
 */
export interface EventsApiFetchEventMetricsEventsJobIdGetRequest {
  /**
   *
   * @type {string}
   * @memberof EventsApiFetchEventMetricsEventsJobIdGet
   */
  readonly jobId: string;

  /**
   *
   * @type {number}
   * @memberof EventsApiFetchEventMetricsEventsJobIdGet
   */
  readonly timeRangeMinutes?: number;
}

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
  /**
   *
   * @summary Fetch Event Metrics
   * @param {EventsApiFetchEventMetricsEventsJobIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public fetchEventMetricsEventsJobIdGet(
    requestParameters: EventsApiFetchEventMetricsEventsJobIdGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return EventsApiFp(this.configuration)
      .fetchEventMetricsEventsJobIdGet(
        requestParameters.jobId,
        requestParameters.timeRangeMinutes,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ExternalLlmConfigsApi - axios parameter creator
 * @export
 */
export const ExternalLlmConfigsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add External Llm Config
     * @param {AddExternalLLMConfigPayload} addExternalLLMConfigPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addExternalLlmConfigExternalLlmConfigsPost: async (
      addExternalLLMConfigPayload: AddExternalLLMConfigPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addExternalLLMConfigPayload' is not null or undefined
      assertParamExists(
        'addExternalLlmConfigExternalLlmConfigsPost',
        'addExternalLLMConfigPayload',
        addExternalLLMConfigPayload,
      );
      const localVarPath = `/external-llm-configs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addExternalLLMConfigPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete External Llm Config
     * @param {number} externalLlmConfigUid
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete: async (
      externalLlmConfigUid: number,
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalLlmConfigUid' is not null or undefined
      assertParamExists(
        'deleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete',
        'externalLlmConfigUid',
        externalLlmConfigUid,
      );
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'deleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath =
        `/external-llm-configs/{external_llm_config_uid}`.replace(
          `{${'external_llm_config_uid'}}`,
          encodeURIComponent(String(externalLlmConfigUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get External Llm Config
     * @param {number} externalLlmConfigUid
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet: async (
      externalLlmConfigUid: number,
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalLlmConfigUid' is not null or undefined
      assertParamExists(
        'getExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet',
        'externalLlmConfigUid',
        externalLlmConfigUid,
      );
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'getExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath =
        `/external-llm-configs/{external_llm_config_uid}`.replace(
          `{${'external_llm_config_uid'}}`,
          encodeURIComponent(String(externalLlmConfigUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get External Llm Configs
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalLlmConfigsExternalLlmConfigsGet: async (
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'getExternalLlmConfigsExternalLlmConfigsGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/external-llm-configs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update External Llm Config
     * @param {number} externalLlmConfigUid
     * @param {number} workspaceUid
     * @param {UpdateExternalLLMConfigPayload} updateExternalLLMConfigPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut: async (
      externalLlmConfigUid: number,
      workspaceUid: number,
      updateExternalLLMConfigPayload: UpdateExternalLLMConfigPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalLlmConfigUid' is not null or undefined
      assertParamExists(
        'updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut',
        'externalLlmConfigUid',
        externalLlmConfigUid,
      );
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut',
        'workspaceUid',
        workspaceUid,
      );
      // verify required parameter 'updateExternalLLMConfigPayload' is not null or undefined
      assertParamExists(
        'updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut',
        'updateExternalLLMConfigPayload',
        updateExternalLLMConfigPayload,
      );
      const localVarPath =
        `/external-llm-configs/{external_llm_config_uid}`.replace(
          `{${'external_llm_config_uid'}}`,
          encodeURIComponent(String(externalLlmConfigUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateExternalLLMConfigPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExternalLlmConfigsApi - functional programming interface
 * @export
 */
export const ExternalLlmConfigsApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ExternalLlmConfigsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add External Llm Config
     * @param {AddExternalLLMConfigPayload} addExternalLLMConfigPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addExternalLlmConfigExternalLlmConfigsPost(
      addExternalLLMConfigPayload: AddExternalLLMConfigPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ExternalLLMConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addExternalLlmConfigExternalLlmConfigsPost(
          addExternalLLMConfigPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ExternalLlmConfigsApi.addExternalLlmConfigExternalLlmConfigsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete External Llm Config
     * @param {number} externalLlmConfigUid
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete(
      externalLlmConfigUid: number,
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete(
          externalLlmConfigUid,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ExternalLlmConfigsApi.deleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get External Llm Config
     * @param {number} externalLlmConfigUid
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet(
      externalLlmConfigUid: number,
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ExternalLLMConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet(
          externalLlmConfigUid,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ExternalLlmConfigsApi.getExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get External Llm Configs
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExternalLlmConfigsExternalLlmConfigsGet(
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ListExternalLLMConfigsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getExternalLlmConfigsExternalLlmConfigsGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ExternalLlmConfigsApi.getExternalLlmConfigsExternalLlmConfigsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update External Llm Config
     * @param {number} externalLlmConfigUid
     * @param {number} workspaceUid
     * @param {UpdateExternalLLMConfigPayload} updateExternalLLMConfigPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut(
      externalLlmConfigUid: number,
      workspaceUid: number,
      updateExternalLLMConfigPayload: UpdateExternalLLMConfigPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ExternalLLMConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut(
          externalLlmConfigUid,
          workspaceUid,
          updateExternalLLMConfigPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ExternalLlmConfigsApi.updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ExternalLlmConfigsApi - factory interface
 * @export
 */
export const ExternalLlmConfigsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExternalLlmConfigsApiFp(configuration);
  return {
    /**
     *
     * @summary Add External Llm Config
     * @param {ExternalLlmConfigsApiAddExternalLlmConfigExternalLlmConfigsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addExternalLlmConfigExternalLlmConfigsPost(
      requestParameters: ExternalLlmConfigsApiAddExternalLlmConfigExternalLlmConfigsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ExternalLLMConfig> {
      return localVarFp
        .addExternalLlmConfigExternalLlmConfigsPost(
          requestParameters.addExternalLLMConfigPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete External Llm Config
     * @param {ExternalLlmConfigsApiDeleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete(
      requestParameters: ExternalLlmConfigsApiDeleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete(
          requestParameters.externalLlmConfigUid,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get External Llm Config
     * @param {ExternalLlmConfigsApiGetExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet(
      requestParameters: ExternalLlmConfigsApiGetExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ExternalLLMConfig> {
      return localVarFp
        .getExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet(
          requestParameters.externalLlmConfigUid,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get External Llm Configs
     * @param {ExternalLlmConfigsApiGetExternalLlmConfigsExternalLlmConfigsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalLlmConfigsExternalLlmConfigsGet(
      requestParameters: ExternalLlmConfigsApiGetExternalLlmConfigsExternalLlmConfigsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ListExternalLLMConfigsResponse> {
      return localVarFp
        .getExternalLlmConfigsExternalLlmConfigsGet(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update External Llm Config
     * @param {ExternalLlmConfigsApiUpdateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut(
      requestParameters: ExternalLlmConfigsApiUpdateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ExternalLLMConfig> {
      return localVarFp
        .updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut(
          requestParameters.externalLlmConfigUid,
          requestParameters.workspaceUid,
          requestParameters.updateExternalLLMConfigPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addExternalLlmConfigExternalLlmConfigsPost operation in ExternalLlmConfigsApi.
 * @export
 * @interface ExternalLlmConfigsApiAddExternalLlmConfigExternalLlmConfigsPostRequest
 */
export interface ExternalLlmConfigsApiAddExternalLlmConfigExternalLlmConfigsPostRequest {
  /**
   *
   * @type {AddExternalLLMConfigPayload}
   * @memberof ExternalLlmConfigsApiAddExternalLlmConfigExternalLlmConfigsPost
   */
  readonly addExternalLLMConfigPayload: AddExternalLLMConfigPayload;
}

/**
 * Request parameters for deleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete operation in ExternalLlmConfigsApi.
 * @export
 * @interface ExternalLlmConfigsApiDeleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDeleteRequest
 */
export interface ExternalLlmConfigsApiDeleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof ExternalLlmConfigsApiDeleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete
   */
  readonly externalLlmConfigUid: number;

  /**
   *
   * @type {number}
   * @memberof ExternalLlmConfigsApiDeleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for getExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet operation in ExternalLlmConfigsApi.
 * @export
 * @interface ExternalLlmConfigsApiGetExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGetRequest
 */
export interface ExternalLlmConfigsApiGetExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof ExternalLlmConfigsApiGetExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet
   */
  readonly externalLlmConfigUid: number;

  /**
   *
   * @type {number}
   * @memberof ExternalLlmConfigsApiGetExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for getExternalLlmConfigsExternalLlmConfigsGet operation in ExternalLlmConfigsApi.
 * @export
 * @interface ExternalLlmConfigsApiGetExternalLlmConfigsExternalLlmConfigsGetRequest
 */
export interface ExternalLlmConfigsApiGetExternalLlmConfigsExternalLlmConfigsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ExternalLlmConfigsApiGetExternalLlmConfigsExternalLlmConfigsGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut operation in ExternalLlmConfigsApi.
 * @export
 * @interface ExternalLlmConfigsApiUpdateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPutRequest
 */
export interface ExternalLlmConfigsApiUpdateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof ExternalLlmConfigsApiUpdateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut
   */
  readonly externalLlmConfigUid: number;

  /**
   *
   * @type {number}
   * @memberof ExternalLlmConfigsApiUpdateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut
   */
  readonly workspaceUid: number;

  /**
   *
   * @type {UpdateExternalLLMConfigPayload}
   * @memberof ExternalLlmConfigsApiUpdateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut
   */
  readonly updateExternalLLMConfigPayload: UpdateExternalLLMConfigPayload;
}

/**
 * ExternalLlmConfigsApi - object-oriented interface
 * @export
 * @class ExternalLlmConfigsApi
 * @extends {BaseAPI}
 */
export class ExternalLlmConfigsApi extends BaseAPI {
  /**
   *
   * @summary Add External Llm Config
   * @param {ExternalLlmConfigsApiAddExternalLlmConfigExternalLlmConfigsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalLlmConfigsApi
   */
  public addExternalLlmConfigExternalLlmConfigsPost(
    requestParameters: ExternalLlmConfigsApiAddExternalLlmConfigExternalLlmConfigsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ExternalLlmConfigsApiFp(this.configuration)
      .addExternalLlmConfigExternalLlmConfigsPost(
        requestParameters.addExternalLLMConfigPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete External Llm Config
   * @param {ExternalLlmConfigsApiDeleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalLlmConfigsApi
   */
  public deleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete(
    requestParameters: ExternalLlmConfigsApiDeleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ExternalLlmConfigsApiFp(this.configuration)
      .deleteExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidDelete(
        requestParameters.externalLlmConfigUid,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get External Llm Config
   * @param {ExternalLlmConfigsApiGetExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalLlmConfigsApi
   */
  public getExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet(
    requestParameters: ExternalLlmConfigsApiGetExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ExternalLlmConfigsApiFp(this.configuration)
      .getExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidGet(
        requestParameters.externalLlmConfigUid,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get External Llm Configs
   * @param {ExternalLlmConfigsApiGetExternalLlmConfigsExternalLlmConfigsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalLlmConfigsApi
   */
  public getExternalLlmConfigsExternalLlmConfigsGet(
    requestParameters: ExternalLlmConfigsApiGetExternalLlmConfigsExternalLlmConfigsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ExternalLlmConfigsApiFp(this.configuration)
      .getExternalLlmConfigsExternalLlmConfigsGet(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update External Llm Config
   * @param {ExternalLlmConfigsApiUpdateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalLlmConfigsApi
   */
  public updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut(
    requestParameters: ExternalLlmConfigsApiUpdateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ExternalLlmConfigsApiFp(this.configuration)
      .updateExternalLlmConfigExternalLlmConfigsExternalLlmConfigUidPut(
        requestParameters.externalLlmConfigUid,
        requestParameters.workspaceUid,
        requestParameters.updateExternalLLMConfigPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FeatureAccessControlApi - axios parameter creator
 * @export
 */
export const FeatureAccessControlApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Activate Feature
     * @param {Feature} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateFeatureFeatureFeatureActivatePost: async (
      feature: Feature,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feature' is not null or undefined
      assertParamExists(
        'activateFeatureFeatureFeatureActivatePost',
        'feature',
        feature,
      );
      const localVarPath = `/feature/{feature}/activate`.replace(
        `{${'feature'}}`,
        encodeURIComponent(String(feature)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add Feature User Role Mapping
     * @param {WorkspaceScopedFeature} feature
     * @param {FeatureAccessRoleCreationRequest} featureAccessRoleCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFeatureUserRoleMappingFeatureFeatureRolePost: async (
      feature: WorkspaceScopedFeature,
      featureAccessRoleCreationRequest: FeatureAccessRoleCreationRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feature' is not null or undefined
      assertParamExists(
        'addFeatureUserRoleMappingFeatureFeatureRolePost',
        'feature',
        feature,
      );
      // verify required parameter 'featureAccessRoleCreationRequest' is not null or undefined
      assertParamExists(
        'addFeatureUserRoleMappingFeatureFeatureRolePost',
        'featureAccessRoleCreationRequest',
        featureAccessRoleCreationRequest,
      );
      const localVarPath = `/feature/{feature}/role`.replace(
        `{${'feature'}}`,
        encodeURIComponent(String(feature)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        featureAccessRoleCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deactivate Feature
     * @param {Feature} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateFeatureFeatureFeatureDeactivatePost: async (
      feature: Feature,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feature' is not null or undefined
      assertParamExists(
        'deactivateFeatureFeatureFeatureDeactivatePost',
        'feature',
        feature,
      );
      const localVarPath = `/feature/{feature}/deactivate`.replace(
        `{${'feature'}}`,
        encodeURIComponent(String(feature)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Feature User Role Mapping
     * @param {WorkspaceScopedFeature} feature
     * @param {number} mappingUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete: async (
      feature: WorkspaceScopedFeature,
      mappingUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feature' is not null or undefined
      assertParamExists(
        'deleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete',
        'feature',
        feature,
      );
      // verify required parameter 'mappingUid' is not null or undefined
      assertParamExists(
        'deleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete',
        'mappingUid',
        mappingUid,
      );
      const localVarPath = `/feature/{feature}/role/{mapping_uid}`
        .replace(`{${'feature'}}`, encodeURIComponent(String(feature)))
        .replace(`{${'mapping_uid'}}`, encodeURIComponent(String(mappingUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Has Access To Feature
     * @param {Feature} feature
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hasAccessToFeatureFeatureFeatureAccessGet: async (
      feature: Feature,
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feature' is not null or undefined
      assertParamExists(
        'hasAccessToFeatureFeatureFeatureAccessGet',
        'feature',
        feature,
      );
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'hasAccessToFeatureFeatureFeatureAccessGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/feature/{feature}/access`.replace(
        `{${'feature'}}`,
        encodeURIComponent(String(feature)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Is Feature Enabled
     * @param {Feature} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isFeatureEnabledFeatureFeatureGet: async (
      feature: Feature,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feature' is not null or undefined
      assertParamExists(
        'isFeatureEnabledFeatureFeatureGet',
        'feature',
        feature,
      );
      const localVarPath = `/feature/{feature}`.replace(
        `{${'feature'}}`,
        encodeURIComponent(String(feature)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Feature User Role Mappings
     * @param {WorkspaceScopedFeature} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFeatureUserRoleMappingsFeatureFeatureRolesGet: async (
      feature: WorkspaceScopedFeature,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feature' is not null or undefined
      assertParamExists(
        'listFeatureUserRoleMappingsFeatureFeatureRolesGet',
        'feature',
        feature,
      );
      const localVarPath = `/feature/{feature}/roles`.replace(
        `{${'feature'}}`,
        encodeURIComponent(String(feature)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Feature User Role Mapping
     * @param {WorkspaceScopedFeature} feature
     * @param {number} mappingUid
     * @param {FeatureAccessRoleUpdateParams} featureAccessRoleUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut: async (
      feature: WorkspaceScopedFeature,
      mappingUid: number,
      featureAccessRoleUpdateParams: FeatureAccessRoleUpdateParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feature' is not null or undefined
      assertParamExists(
        'updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut',
        'feature',
        feature,
      );
      // verify required parameter 'mappingUid' is not null or undefined
      assertParamExists(
        'updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut',
        'mappingUid',
        mappingUid,
      );
      // verify required parameter 'featureAccessRoleUpdateParams' is not null or undefined
      assertParamExists(
        'updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut',
        'featureAccessRoleUpdateParams',
        featureAccessRoleUpdateParams,
      );
      const localVarPath = `/feature/{feature}/role/{mapping_uid}`
        .replace(`{${'feature'}}`, encodeURIComponent(String(feature)))
        .replace(`{${'mapping_uid'}}`, encodeURIComponent(String(mappingUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        featureAccessRoleUpdateParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FeatureAccessControlApi - functional programming interface
 * @export
 */
export const FeatureAccessControlApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    FeatureAccessControlApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Activate Feature
     * @param {Feature} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateFeatureFeatureFeatureActivatePost(
      feature: Feature,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.activateFeatureFeatureFeatureActivatePost(
          feature,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FeatureAccessControlApi.activateFeatureFeatureFeatureActivatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Add Feature User Role Mapping
     * @param {WorkspaceScopedFeature} feature
     * @param {FeatureAccessRoleCreationRequest} featureAccessRoleCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addFeatureUserRoleMappingFeatureFeatureRolePost(
      feature: WorkspaceScopedFeature,
      featureAccessRoleCreationRequest: FeatureAccessRoleCreationRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addFeatureUserRoleMappingFeatureFeatureRolePost(
          feature,
          featureAccessRoleCreationRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FeatureAccessControlApi.addFeatureUserRoleMappingFeatureFeatureRolePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Deactivate Feature
     * @param {Feature} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deactivateFeatureFeatureFeatureDeactivatePost(
      feature: Feature,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deactivateFeatureFeatureFeatureDeactivatePost(
          feature,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FeatureAccessControlApi.deactivateFeatureFeatureFeatureDeactivatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Feature User Role Mapping
     * @param {WorkspaceScopedFeature} feature
     * @param {number} mappingUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete(
      feature: WorkspaceScopedFeature,
      mappingUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete(
          feature,
          mappingUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FeatureAccessControlApi.deleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Has Access To Feature
     * @param {Feature} feature
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async hasAccessToFeatureFeatureFeatureAccessGet(
      feature: Feature,
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.hasAccessToFeatureFeatureFeatureAccessGet(
          feature,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FeatureAccessControlApi.hasAccessToFeatureFeatureFeatureAccessGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Is Feature Enabled
     * @param {Feature} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isFeatureEnabledFeatureFeatureGet(
      feature: Feature,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.isFeatureEnabledFeatureFeatureGet(
          feature,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FeatureAccessControlApi.isFeatureEnabledFeatureFeatureGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Feature User Role Mappings
     * @param {WorkspaceScopedFeature} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listFeatureUserRoleMappingsFeatureFeatureRolesGet(
      feature: WorkspaceScopedFeature,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<FeatureAccessRole>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listFeatureUserRoleMappingsFeatureFeatureRolesGet(
          feature,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FeatureAccessControlApi.listFeatureUserRoleMappingsFeatureFeatureRolesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Feature User Role Mapping
     * @param {WorkspaceScopedFeature} feature
     * @param {number} mappingUid
     * @param {FeatureAccessRoleUpdateParams} featureAccessRoleUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut(
      feature: WorkspaceScopedFeature,
      mappingUid: number,
      featureAccessRoleUpdateParams: FeatureAccessRoleUpdateParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut(
          feature,
          mappingUid,
          featureAccessRoleUpdateParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FeatureAccessControlApi.updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FeatureAccessControlApi - factory interface
 * @export
 */
export const FeatureAccessControlApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FeatureAccessControlApiFp(configuration);
  return {
    /**
     *
     * @summary Activate Feature
     * @param {FeatureAccessControlApiActivateFeatureFeatureFeatureActivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateFeatureFeatureFeatureActivatePost(
      requestParameters: FeatureAccessControlApiActivateFeatureFeatureFeatureActivatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .activateFeatureFeatureFeatureActivatePost(
          requestParameters.feature,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Add Feature User Role Mapping
     * @param {FeatureAccessControlApiAddFeatureUserRoleMappingFeatureFeatureRolePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFeatureUserRoleMappingFeatureFeatureRolePost(
      requestParameters: FeatureAccessControlApiAddFeatureUserRoleMappingFeatureFeatureRolePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<number> {
      return localVarFp
        .addFeatureUserRoleMappingFeatureFeatureRolePost(
          requestParameters.feature,
          requestParameters.featureAccessRoleCreationRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Deactivate Feature
     * @param {FeatureAccessControlApiDeactivateFeatureFeatureFeatureDeactivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateFeatureFeatureFeatureDeactivatePost(
      requestParameters: FeatureAccessControlApiDeactivateFeatureFeatureFeatureDeactivatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deactivateFeatureFeatureFeatureDeactivatePost(
          requestParameters.feature,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Feature User Role Mapping
     * @param {FeatureAccessControlApiDeleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete(
      requestParameters: FeatureAccessControlApiDeleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete(
          requestParameters.feature,
          requestParameters.mappingUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Has Access To Feature
     * @param {FeatureAccessControlApiHasAccessToFeatureFeatureFeatureAccessGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hasAccessToFeatureFeatureFeatureAccessGet(
      requestParameters: FeatureAccessControlApiHasAccessToFeatureFeatureFeatureAccessGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .hasAccessToFeatureFeatureFeatureAccessGet(
          requestParameters.feature,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Is Feature Enabled
     * @param {FeatureAccessControlApiIsFeatureEnabledFeatureFeatureGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isFeatureEnabledFeatureFeatureGet(
      requestParameters: FeatureAccessControlApiIsFeatureEnabledFeatureFeatureGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .isFeatureEnabledFeatureFeatureGet(requestParameters.feature, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Feature User Role Mappings
     * @param {FeatureAccessControlApiListFeatureUserRoleMappingsFeatureFeatureRolesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFeatureUserRoleMappingsFeatureFeatureRolesGet(
      requestParameters: FeatureAccessControlApiListFeatureUserRoleMappingsFeatureFeatureRolesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<FeatureAccessRole>> {
      return localVarFp
        .listFeatureUserRoleMappingsFeatureFeatureRolesGet(
          requestParameters.feature,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Feature User Role Mapping
     * @param {FeatureAccessControlApiUpdateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut(
      requestParameters: FeatureAccessControlApiUpdateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut(
          requestParameters.feature,
          requestParameters.mappingUid,
          requestParameters.featureAccessRoleUpdateParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for activateFeatureFeatureFeatureActivatePost operation in FeatureAccessControlApi.
 * @export
 * @interface FeatureAccessControlApiActivateFeatureFeatureFeatureActivatePostRequest
 */
export interface FeatureAccessControlApiActivateFeatureFeatureFeatureActivatePostRequest {
  /**
   *
   * @type {Feature}
   * @memberof FeatureAccessControlApiActivateFeatureFeatureFeatureActivatePost
   */
  readonly feature: Feature;
}

/**
 * Request parameters for addFeatureUserRoleMappingFeatureFeatureRolePost operation in FeatureAccessControlApi.
 * @export
 * @interface FeatureAccessControlApiAddFeatureUserRoleMappingFeatureFeatureRolePostRequest
 */
export interface FeatureAccessControlApiAddFeatureUserRoleMappingFeatureFeatureRolePostRequest {
  /**
   *
   * @type {WorkspaceScopedFeature}
   * @memberof FeatureAccessControlApiAddFeatureUserRoleMappingFeatureFeatureRolePost
   */
  readonly feature: WorkspaceScopedFeature;

  /**
   *
   * @type {FeatureAccessRoleCreationRequest}
   * @memberof FeatureAccessControlApiAddFeatureUserRoleMappingFeatureFeatureRolePost
   */
  readonly featureAccessRoleCreationRequest: FeatureAccessRoleCreationRequest;
}

/**
 * Request parameters for deactivateFeatureFeatureFeatureDeactivatePost operation in FeatureAccessControlApi.
 * @export
 * @interface FeatureAccessControlApiDeactivateFeatureFeatureFeatureDeactivatePostRequest
 */
export interface FeatureAccessControlApiDeactivateFeatureFeatureFeatureDeactivatePostRequest {
  /**
   *
   * @type {Feature}
   * @memberof FeatureAccessControlApiDeactivateFeatureFeatureFeatureDeactivatePost
   */
  readonly feature: Feature;
}

/**
 * Request parameters for deleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete operation in FeatureAccessControlApi.
 * @export
 * @interface FeatureAccessControlApiDeleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDeleteRequest
 */
export interface FeatureAccessControlApiDeleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDeleteRequest {
  /**
   *
   * @type {WorkspaceScopedFeature}
   * @memberof FeatureAccessControlApiDeleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete
   */
  readonly feature: WorkspaceScopedFeature;

  /**
   *
   * @type {number}
   * @memberof FeatureAccessControlApiDeleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete
   */
  readonly mappingUid: number;
}

/**
 * Request parameters for hasAccessToFeatureFeatureFeatureAccessGet operation in FeatureAccessControlApi.
 * @export
 * @interface FeatureAccessControlApiHasAccessToFeatureFeatureFeatureAccessGetRequest
 */
export interface FeatureAccessControlApiHasAccessToFeatureFeatureFeatureAccessGetRequest {
  /**
   *
   * @type {Feature}
   * @memberof FeatureAccessControlApiHasAccessToFeatureFeatureFeatureAccessGet
   */
  readonly feature: Feature;

  /**
   *
   * @type {number}
   * @memberof FeatureAccessControlApiHasAccessToFeatureFeatureFeatureAccessGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for isFeatureEnabledFeatureFeatureGet operation in FeatureAccessControlApi.
 * @export
 * @interface FeatureAccessControlApiIsFeatureEnabledFeatureFeatureGetRequest
 */
export interface FeatureAccessControlApiIsFeatureEnabledFeatureFeatureGetRequest {
  /**
   *
   * @type {Feature}
   * @memberof FeatureAccessControlApiIsFeatureEnabledFeatureFeatureGet
   */
  readonly feature: Feature;
}

/**
 * Request parameters for listFeatureUserRoleMappingsFeatureFeatureRolesGet operation in FeatureAccessControlApi.
 * @export
 * @interface FeatureAccessControlApiListFeatureUserRoleMappingsFeatureFeatureRolesGetRequest
 */
export interface FeatureAccessControlApiListFeatureUserRoleMappingsFeatureFeatureRolesGetRequest {
  /**
   *
   * @type {WorkspaceScopedFeature}
   * @memberof FeatureAccessControlApiListFeatureUserRoleMappingsFeatureFeatureRolesGet
   */
  readonly feature: WorkspaceScopedFeature;
}

/**
 * Request parameters for updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut operation in FeatureAccessControlApi.
 * @export
 * @interface FeatureAccessControlApiUpdateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPutRequest
 */
export interface FeatureAccessControlApiUpdateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPutRequest {
  /**
   *
   * @type {WorkspaceScopedFeature}
   * @memberof FeatureAccessControlApiUpdateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut
   */
  readonly feature: WorkspaceScopedFeature;

  /**
   *
   * @type {number}
   * @memberof FeatureAccessControlApiUpdateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut
   */
  readonly mappingUid: number;

  /**
   *
   * @type {FeatureAccessRoleUpdateParams}
   * @memberof FeatureAccessControlApiUpdateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut
   */
  readonly featureAccessRoleUpdateParams: FeatureAccessRoleUpdateParams;
}

/**
 * FeatureAccessControlApi - object-oriented interface
 * @export
 * @class FeatureAccessControlApi
 * @extends {BaseAPI}
 */
export class FeatureAccessControlApi extends BaseAPI {
  /**
   *
   * @summary Activate Feature
   * @param {FeatureAccessControlApiActivateFeatureFeatureFeatureActivatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureAccessControlApi
   */
  public activateFeatureFeatureFeatureActivatePost(
    requestParameters: FeatureAccessControlApiActivateFeatureFeatureFeatureActivatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FeatureAccessControlApiFp(this.configuration)
      .activateFeatureFeatureFeatureActivatePost(
        requestParameters.feature,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add Feature User Role Mapping
   * @param {FeatureAccessControlApiAddFeatureUserRoleMappingFeatureFeatureRolePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureAccessControlApi
   */
  public addFeatureUserRoleMappingFeatureFeatureRolePost(
    requestParameters: FeatureAccessControlApiAddFeatureUserRoleMappingFeatureFeatureRolePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FeatureAccessControlApiFp(this.configuration)
      .addFeatureUserRoleMappingFeatureFeatureRolePost(
        requestParameters.feature,
        requestParameters.featureAccessRoleCreationRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deactivate Feature
   * @param {FeatureAccessControlApiDeactivateFeatureFeatureFeatureDeactivatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureAccessControlApi
   */
  public deactivateFeatureFeatureFeatureDeactivatePost(
    requestParameters: FeatureAccessControlApiDeactivateFeatureFeatureFeatureDeactivatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FeatureAccessControlApiFp(this.configuration)
      .deactivateFeatureFeatureFeatureDeactivatePost(
        requestParameters.feature,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Feature User Role Mapping
   * @param {FeatureAccessControlApiDeleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureAccessControlApi
   */
  public deleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete(
    requestParameters: FeatureAccessControlApiDeleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FeatureAccessControlApiFp(this.configuration)
      .deleteFeatureUserRoleMappingFeatureFeatureRoleMappingUidDelete(
        requestParameters.feature,
        requestParameters.mappingUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Has Access To Feature
   * @param {FeatureAccessControlApiHasAccessToFeatureFeatureFeatureAccessGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureAccessControlApi
   */
  public hasAccessToFeatureFeatureFeatureAccessGet(
    requestParameters: FeatureAccessControlApiHasAccessToFeatureFeatureFeatureAccessGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FeatureAccessControlApiFp(this.configuration)
      .hasAccessToFeatureFeatureFeatureAccessGet(
        requestParameters.feature,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Is Feature Enabled
   * @param {FeatureAccessControlApiIsFeatureEnabledFeatureFeatureGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureAccessControlApi
   */
  public isFeatureEnabledFeatureFeatureGet(
    requestParameters: FeatureAccessControlApiIsFeatureEnabledFeatureFeatureGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FeatureAccessControlApiFp(this.configuration)
      .isFeatureEnabledFeatureFeatureGet(requestParameters.feature, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Feature User Role Mappings
   * @param {FeatureAccessControlApiListFeatureUserRoleMappingsFeatureFeatureRolesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureAccessControlApi
   */
  public listFeatureUserRoleMappingsFeatureFeatureRolesGet(
    requestParameters: FeatureAccessControlApiListFeatureUserRoleMappingsFeatureFeatureRolesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FeatureAccessControlApiFp(this.configuration)
      .listFeatureUserRoleMappingsFeatureFeatureRolesGet(
        requestParameters.feature,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Feature User Role Mapping
   * @param {FeatureAccessControlApiUpdateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureAccessControlApi
   */
  public updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut(
    requestParameters: FeatureAccessControlApiUpdateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FeatureAccessControlApiFp(this.configuration)
      .updateFeatureUserRoleMappingFeatureFeatureRoleMappingUidPut(
        requestParameters.feature,
        requestParameters.mappingUid,
        requestParameters.featureAccessRoleUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FileStorageConfigsApi - axios parameter creator
 * @export
 */
export const FileStorageConfigsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create File Storage Config
     * @param {FileStorageConfigCreate} fileStorageConfigCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFileStorageConfigFileStorageConfigsPost: async (
      fileStorageConfigCreate: FileStorageConfigCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileStorageConfigCreate' is not null or undefined
      assertParamExists(
        'createFileStorageConfigFileStorageConfigsPost',
        'fileStorageConfigCreate',
        fileStorageConfigCreate,
      );
      const localVarPath = `/file-storage-configs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fileStorageConfigCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete File Storage Config
     * @param {number} fileStorageConfigUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDelete: async (
      fileStorageConfigUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileStorageConfigUid' is not null or undefined
      assertParamExists(
        'deleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDelete',
        'fileStorageConfigUid',
        fileStorageConfigUid,
      );
      const localVarPath =
        `/file-storage-configs/{file_storage_config_uid}`.replace(
          `{${'file_storage_config_uid'}}`,
          encodeURIComponent(String(fileStorageConfigUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get File Storage Config
     * @param {number} fileStorageConfigUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileStorageConfigFileStorageConfigsFileStorageConfigUidGet: async (
      fileStorageConfigUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileStorageConfigUid' is not null or undefined
      assertParamExists(
        'getFileStorageConfigFileStorageConfigsFileStorageConfigUidGet',
        'fileStorageConfigUid',
        fileStorageConfigUid,
      );
      const localVarPath =
        `/file-storage-configs/{file_storage_config_uid}`.replace(
          `{${'file_storage_config_uid'}}`,
          encodeURIComponent(String(fileStorageConfigUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List File Storage Configs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFileStorageConfigsFileStorageConfigsGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/file-storage-configs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Default File Storage Config
     * @param {SetDefaultFileStorageConfigParams} setDefaultFileStorageConfigParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setDefaultFileStorageConfigFileStorageConfigsSetDefaultPut: async (
      setDefaultFileStorageConfigParams: SetDefaultFileStorageConfigParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'setDefaultFileStorageConfigParams' is not null or undefined
      assertParamExists(
        'setDefaultFileStorageConfigFileStorageConfigsSetDefaultPut',
        'setDefaultFileStorageConfigParams',
        setDefaultFileStorageConfigParams,
      );
      const localVarPath = `/file-storage-configs/set-default`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        setDefaultFileStorageConfigParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FileStorageConfigsApi - functional programming interface
 * @export
 */
export const FileStorageConfigsApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    FileStorageConfigsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create File Storage Config
     * @param {FileStorageConfigCreate} fileStorageConfigCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFileStorageConfigFileStorageConfigsPost(
      fileStorageConfigCreate: FileStorageConfigCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileStorageConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createFileStorageConfigFileStorageConfigsPost(
          fileStorageConfigCreate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FileStorageConfigsApi.createFileStorageConfigFileStorageConfigsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete File Storage Config
     * @param {number} fileStorageConfigUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDelete(
      fileStorageConfigUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDelete(
          fileStorageConfigUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FileStorageConfigsApi.deleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get File Storage Config
     * @param {number} fileStorageConfigUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFileStorageConfigFileStorageConfigsFileStorageConfigUidGet(
      fileStorageConfigUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileStorageConfig>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFileStorageConfigFileStorageConfigsFileStorageConfigUidGet(
          fileStorageConfigUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FileStorageConfigsApi.getFileStorageConfigFileStorageConfigsFileStorageConfigUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List File Storage Configs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listFileStorageConfigsFileStorageConfigsGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<FileStorageConfig>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listFileStorageConfigsFileStorageConfigsGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FileStorageConfigsApi.listFileStorageConfigsFileStorageConfigsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Set Default File Storage Config
     * @param {SetDefaultFileStorageConfigParams} setDefaultFileStorageConfigParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setDefaultFileStorageConfigFileStorageConfigsSetDefaultPut(
      setDefaultFileStorageConfigParams: SetDefaultFileStorageConfigParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setDefaultFileStorageConfigFileStorageConfigsSetDefaultPut(
          setDefaultFileStorageConfigParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FileStorageConfigsApi.setDefaultFileStorageConfigFileStorageConfigsSetDefaultPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FileStorageConfigsApi - factory interface
 * @export
 */
export const FileStorageConfigsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FileStorageConfigsApiFp(configuration);
  return {
    /**
     *
     * @summary Create File Storage Config
     * @param {FileStorageConfigsApiCreateFileStorageConfigFileStorageConfigsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFileStorageConfigFileStorageConfigsPost(
      requestParameters: FileStorageConfigsApiCreateFileStorageConfigFileStorageConfigsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FileStorageConfig> {
      return localVarFp
        .createFileStorageConfigFileStorageConfigsPost(
          requestParameters.fileStorageConfigCreate,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete File Storage Config
     * @param {FileStorageConfigsApiDeleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDelete(
      requestParameters: FileStorageConfigsApiDeleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDelete(
          requestParameters.fileStorageConfigUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get File Storage Config
     * @param {FileStorageConfigsApiGetFileStorageConfigFileStorageConfigsFileStorageConfigUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileStorageConfigFileStorageConfigsFileStorageConfigUidGet(
      requestParameters: FileStorageConfigsApiGetFileStorageConfigFileStorageConfigsFileStorageConfigUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FileStorageConfig> {
      return localVarFp
        .getFileStorageConfigFileStorageConfigsFileStorageConfigUidGet(
          requestParameters.fileStorageConfigUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List File Storage Configs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listFileStorageConfigsFileStorageConfigsGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<FileStorageConfig>> {
      return localVarFp
        .listFileStorageConfigsFileStorageConfigsGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Set Default File Storage Config
     * @param {FileStorageConfigsApiSetDefaultFileStorageConfigFileStorageConfigsSetDefaultPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setDefaultFileStorageConfigFileStorageConfigsSetDefaultPut(
      requestParameters: FileStorageConfigsApiSetDefaultFileStorageConfigFileStorageConfigsSetDefaultPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .setDefaultFileStorageConfigFileStorageConfigsSetDefaultPut(
          requestParameters.setDefaultFileStorageConfigParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createFileStorageConfigFileStorageConfigsPost operation in FileStorageConfigsApi.
 * @export
 * @interface FileStorageConfigsApiCreateFileStorageConfigFileStorageConfigsPostRequest
 */
export interface FileStorageConfigsApiCreateFileStorageConfigFileStorageConfigsPostRequest {
  /**
   *
   * @type {FileStorageConfigCreate}
   * @memberof FileStorageConfigsApiCreateFileStorageConfigFileStorageConfigsPost
   */
  readonly fileStorageConfigCreate: FileStorageConfigCreate;
}

/**
 * Request parameters for deleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDelete operation in FileStorageConfigsApi.
 * @export
 * @interface FileStorageConfigsApiDeleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDeleteRequest
 */
export interface FileStorageConfigsApiDeleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof FileStorageConfigsApiDeleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDelete
   */
  readonly fileStorageConfigUid: number;
}

/**
 * Request parameters for getFileStorageConfigFileStorageConfigsFileStorageConfigUidGet operation in FileStorageConfigsApi.
 * @export
 * @interface FileStorageConfigsApiGetFileStorageConfigFileStorageConfigsFileStorageConfigUidGetRequest
 */
export interface FileStorageConfigsApiGetFileStorageConfigFileStorageConfigsFileStorageConfigUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof FileStorageConfigsApiGetFileStorageConfigFileStorageConfigsFileStorageConfigUidGet
   */
  readonly fileStorageConfigUid: number;
}

/**
 * Request parameters for setDefaultFileStorageConfigFileStorageConfigsSetDefaultPut operation in FileStorageConfigsApi.
 * @export
 * @interface FileStorageConfigsApiSetDefaultFileStorageConfigFileStorageConfigsSetDefaultPutRequest
 */
export interface FileStorageConfigsApiSetDefaultFileStorageConfigFileStorageConfigsSetDefaultPutRequest {
  /**
   *
   * @type {SetDefaultFileStorageConfigParams}
   * @memberof FileStorageConfigsApiSetDefaultFileStorageConfigFileStorageConfigsSetDefaultPut
   */
  readonly setDefaultFileStorageConfigParams: SetDefaultFileStorageConfigParams;
}

/**
 * FileStorageConfigsApi - object-oriented interface
 * @export
 * @class FileStorageConfigsApi
 * @extends {BaseAPI}
 */
export class FileStorageConfigsApi extends BaseAPI {
  /**
   *
   * @summary Create File Storage Config
   * @param {FileStorageConfigsApiCreateFileStorageConfigFileStorageConfigsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileStorageConfigsApi
   */
  public createFileStorageConfigFileStorageConfigsPost(
    requestParameters: FileStorageConfigsApiCreateFileStorageConfigFileStorageConfigsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FileStorageConfigsApiFp(this.configuration)
      .createFileStorageConfigFileStorageConfigsPost(
        requestParameters.fileStorageConfigCreate,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete File Storage Config
   * @param {FileStorageConfigsApiDeleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileStorageConfigsApi
   */
  public deleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDelete(
    requestParameters: FileStorageConfigsApiDeleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FileStorageConfigsApiFp(this.configuration)
      .deleteFileStorageConfigFileStorageConfigsFileStorageConfigUidDelete(
        requestParameters.fileStorageConfigUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get File Storage Config
   * @param {FileStorageConfigsApiGetFileStorageConfigFileStorageConfigsFileStorageConfigUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileStorageConfigsApi
   */
  public getFileStorageConfigFileStorageConfigsFileStorageConfigUidGet(
    requestParameters: FileStorageConfigsApiGetFileStorageConfigFileStorageConfigsFileStorageConfigUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FileStorageConfigsApiFp(this.configuration)
      .getFileStorageConfigFileStorageConfigsFileStorageConfigUidGet(
        requestParameters.fileStorageConfigUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List File Storage Configs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileStorageConfigsApi
   */
  public listFileStorageConfigsFileStorageConfigsGet(
    options?: RawAxiosRequestConfig,
  ) {
    return FileStorageConfigsApiFp(this.configuration)
      .listFileStorageConfigsFileStorageConfigsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Default File Storage Config
   * @param {FileStorageConfigsApiSetDefaultFileStorageConfigFileStorageConfigsSetDefaultPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileStorageConfigsApi
   */
  public setDefaultFileStorageConfigFileStorageConfigsSetDefaultPut(
    requestParameters: FileStorageConfigsApiSetDefaultFileStorageConfigFileStorageConfigsSetDefaultPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FileStorageConfigsApiFp(this.configuration)
      .setDefaultFileStorageConfigFileStorageConfigsSetDefaultPut(
        requestParameters.setDefaultFileStorageConfigParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FinetuneApi - axios parameter creator
 * @export
 */
export const FinetuneApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Check if workspace has external finetuning service creds setup correctly
     * @summary Check Finetuning Provider Auth
     * @param {string} finetuningProviderType
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkFinetuningProviderAuthCheckFinetuningProviderAuthGet: async (
      finetuningProviderType: string,
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'finetuningProviderType' is not null or undefined
      assertParamExists(
        'checkFinetuningProviderAuthCheckFinetuningProviderAuthGet',
        'finetuningProviderType',
        finetuningProviderType,
      );
      const localVarPath = `/check-finetuning-provider-auth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (finetuningProviderType !== undefined) {
        localVarQueryParameter['finetuning_provider_type'] =
          finetuningProviderType;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Finetune (instruction tune) LLMs locally or using external providers.
     * @summary Finetune Model
     * @param {FinetuneModelRequest} finetuneModelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finetuneModelFinetunePost: async (
      finetuneModelRequest: FinetuneModelRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'finetuneModelRequest' is not null or undefined
      assertParamExists(
        'finetuneModelFinetunePost',
        'finetuneModelRequest',
        finetuneModelRequest,
      );
      const localVarPath = `/finetune`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        finetuneModelRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Generate responses on a fine-tuned LLM and save the results as new datasource(s)
     * @summary Finetune Model Inference
     * @param {FinetuneInferenceRequest} finetuneInferenceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finetuneModelInferenceInferencePost: async (
      finetuneInferenceRequest: FinetuneInferenceRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'finetuneInferenceRequest' is not null or undefined
      assertParamExists(
        'finetuneModelInferenceInferencePost',
        'finetuneInferenceRequest',
        finetuneInferenceRequest,
      );
      const localVarPath = `/inference`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        finetuneInferenceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FinetuneApi - functional programming interface
 * @export
 */
export const FinetuneApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FinetuneApiAxiosParamCreator(configuration);
  return {
    /**
     * Check if workspace has external finetuning service creds setup correctly
     * @summary Check Finetuning Provider Auth
     * @param {string} finetuningProviderType
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkFinetuningProviderAuthCheckFinetuningProviderAuthGet(
      finetuningProviderType: string,
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkFinetuningProviderAuthCheckFinetuningProviderAuthGet(
          finetuningProviderType,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FinetuneApi.checkFinetuningProviderAuthCheckFinetuningProviderAuthGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Finetune (instruction tune) LLMs locally or using external providers.
     * @summary Finetune Model
     * @param {FinetuneModelRequest} finetuneModelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async finetuneModelFinetunePost(
      finetuneModelRequest: FinetuneModelRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FinetuneModelResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.finetuneModelFinetunePost(
          finetuneModelRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FinetuneApi.finetuneModelFinetunePost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Generate responses on a fine-tuned LLM and save the results as new datasource(s)
     * @summary Finetune Model Inference
     * @param {FinetuneInferenceRequest} finetuneInferenceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async finetuneModelInferenceInferencePost(
      finetuneInferenceRequest: FinetuneInferenceRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FinetuneInferenceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.finetuneModelInferenceInferencePost(
          finetuneInferenceRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FinetuneApi.finetuneModelInferenceInferencePost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FinetuneApi - factory interface
 * @export
 */
export const FinetuneApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FinetuneApiFp(configuration);
  return {
    /**
     * Check if workspace has external finetuning service creds setup correctly
     * @summary Check Finetuning Provider Auth
     * @param {FinetuneApiCheckFinetuningProviderAuthCheckFinetuningProviderAuthGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkFinetuningProviderAuthCheckFinetuningProviderAuthGet(
      requestParameters: FinetuneApiCheckFinetuningProviderAuthCheckFinetuningProviderAuthGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .checkFinetuningProviderAuthCheckFinetuningProviderAuthGet(
          requestParameters.finetuningProviderType,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Finetune (instruction tune) LLMs locally or using external providers.
     * @summary Finetune Model
     * @param {FinetuneApiFinetuneModelFinetunePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finetuneModelFinetunePost(
      requestParameters: FinetuneApiFinetuneModelFinetunePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FinetuneModelResponse> {
      return localVarFp
        .finetuneModelFinetunePost(
          requestParameters.finetuneModelRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Generate responses on a fine-tuned LLM and save the results as new datasource(s)
     * @summary Finetune Model Inference
     * @param {FinetuneApiFinetuneModelInferenceInferencePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finetuneModelInferenceInferencePost(
      requestParameters: FinetuneApiFinetuneModelInferenceInferencePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FinetuneInferenceResponse> {
      return localVarFp
        .finetuneModelInferenceInferencePost(
          requestParameters.finetuneInferenceRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for checkFinetuningProviderAuthCheckFinetuningProviderAuthGet operation in FinetuneApi.
 * @export
 * @interface FinetuneApiCheckFinetuningProviderAuthCheckFinetuningProviderAuthGetRequest
 */
export interface FinetuneApiCheckFinetuningProviderAuthCheckFinetuningProviderAuthGetRequest {
  /**
   *
   * @type {string}
   * @memberof FinetuneApiCheckFinetuningProviderAuthCheckFinetuningProviderAuthGet
   */
  readonly finetuningProviderType: string;

  /**
   *
   * @type {number}
   * @memberof FinetuneApiCheckFinetuningProviderAuthCheckFinetuningProviderAuthGet
   */
  readonly workspaceUid?: number;
}

/**
 * Request parameters for finetuneModelFinetunePost operation in FinetuneApi.
 * @export
 * @interface FinetuneApiFinetuneModelFinetunePostRequest
 */
export interface FinetuneApiFinetuneModelFinetunePostRequest {
  /**
   *
   * @type {FinetuneModelRequest}
   * @memberof FinetuneApiFinetuneModelFinetunePost
   */
  readonly finetuneModelRequest: FinetuneModelRequest;
}

/**
 * Request parameters for finetuneModelInferenceInferencePost operation in FinetuneApi.
 * @export
 * @interface FinetuneApiFinetuneModelInferenceInferencePostRequest
 */
export interface FinetuneApiFinetuneModelInferenceInferencePostRequest {
  /**
   *
   * @type {FinetuneInferenceRequest}
   * @memberof FinetuneApiFinetuneModelInferenceInferencePost
   */
  readonly finetuneInferenceRequest: FinetuneInferenceRequest;
}

/**
 * FinetuneApi - object-oriented interface
 * @export
 * @class FinetuneApi
 * @extends {BaseAPI}
 */
export class FinetuneApi extends BaseAPI {
  /**
   * Check if workspace has external finetuning service creds setup correctly
   * @summary Check Finetuning Provider Auth
   * @param {FinetuneApiCheckFinetuningProviderAuthCheckFinetuningProviderAuthGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinetuneApi
   */
  public checkFinetuningProviderAuthCheckFinetuningProviderAuthGet(
    requestParameters: FinetuneApiCheckFinetuningProviderAuthCheckFinetuningProviderAuthGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FinetuneApiFp(this.configuration)
      .checkFinetuningProviderAuthCheckFinetuningProviderAuthGet(
        requestParameters.finetuningProviderType,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Finetune (instruction tune) LLMs locally or using external providers.
   * @summary Finetune Model
   * @param {FinetuneApiFinetuneModelFinetunePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinetuneApi
   */
  public finetuneModelFinetunePost(
    requestParameters: FinetuneApiFinetuneModelFinetunePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FinetuneApiFp(this.configuration)
      .finetuneModelFinetunePost(
        requestParameters.finetuneModelRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Generate responses on a fine-tuned LLM and save the results as new datasource(s)
   * @summary Finetune Model Inference
   * @param {FinetuneApiFinetuneModelInferenceInferencePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinetuneApi
   */
  public finetuneModelInferenceInferencePost(
    requestParameters: FinetuneApiFinetuneModelInferenceInferencePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FinetuneApiFp(this.configuration)
      .finetuneModelInferenceInferencePost(
        requestParameters.finetuneInferenceRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FmIntegrationsApi - axios parameter creator
 * @export
 */
export const FmIntegrationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Integration
     * @param {ExternalLLMProvider} provider
     * @param {string} [secretStore]
     * @param {number} [workspaceUid]
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteIntegrationFmIntegrationsProviderDelete: async (
      provider: ExternalLLMProvider,
      secretStore?: string,
      workspaceUid?: number,
      body?: object,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'provider' is not null or undefined
      assertParamExists(
        'deleteIntegrationFmIntegrationsProviderDelete',
        'provider',
        provider,
      );
      const localVarPath = `/fm-integrations/{provider}`.replace(
        `{${'provider'}}`,
        encodeURIComponent(String(provider)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (secretStore !== undefined) {
        localVarQueryParameter['secret_store'] = secretStore;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Integrations
     * @param {ListIntegrationsParams} listIntegrationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listIntegrationsFmIntegrationsPut: async (
      listIntegrationsParams: ListIntegrationsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'listIntegrationsParams' is not null or undefined
      assertParamExists(
        'listIntegrationsFmIntegrationsPut',
        'listIntegrationsParams',
        listIntegrationsParams,
      );
      const localVarPath = `/fm-integrations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        listIntegrationsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Integration
     * @param {ExternalLLMProvider} provider
     * @param {SetIntegrationParams} setIntegrationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setIntegrationFmIntegrationsProviderPost: async (
      provider: ExternalLLMProvider,
      setIntegrationParams: SetIntegrationParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'provider' is not null or undefined
      assertParamExists(
        'setIntegrationFmIntegrationsProviderPost',
        'provider',
        provider,
      );
      // verify required parameter 'setIntegrationParams' is not null or undefined
      assertParamExists(
        'setIntegrationFmIntegrationsProviderPost',
        'setIntegrationParams',
        setIntegrationParams,
      );
      const localVarPath = `/fm-integrations/{provider}`.replace(
        `{${'provider'}}`,
        encodeURIComponent(String(provider)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        setIntegrationParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FmIntegrationsApi - functional programming interface
 * @export
 */
export const FmIntegrationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    FmIntegrationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Integration
     * @param {ExternalLLMProvider} provider
     * @param {string} [secretStore]
     * @param {number} [workspaceUid]
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteIntegrationFmIntegrationsProviderDelete(
      provider: ExternalLLMProvider,
      secretStore?: string,
      workspaceUid?: number,
      body?: object,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteIntegrationFmIntegrationsProviderDelete(
          provider,
          secretStore,
          workspaceUid,
          body,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FmIntegrationsApi.deleteIntegrationFmIntegrationsProviderDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Integrations
     * @param {ListIntegrationsParams} listIntegrationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listIntegrationsFmIntegrationsPut(
      listIntegrationsParams: ListIntegrationsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ListIntegrationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listIntegrationsFmIntegrationsPut(
          listIntegrationsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FmIntegrationsApi.listIntegrationsFmIntegrationsPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Set Integration
     * @param {ExternalLLMProvider} provider
     * @param {SetIntegrationParams} setIntegrationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setIntegrationFmIntegrationsProviderPost(
      provider: ExternalLLMProvider,
      setIntegrationParams: SetIntegrationParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setIntegrationFmIntegrationsProviderPost(
          provider,
          setIntegrationParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FmIntegrationsApi.setIntegrationFmIntegrationsProviderPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FmIntegrationsApi - factory interface
 * @export
 */
export const FmIntegrationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FmIntegrationsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Integration
     * @param {FmIntegrationsApiDeleteIntegrationFmIntegrationsProviderDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteIntegrationFmIntegrationsProviderDelete(
      requestParameters: FmIntegrationsApiDeleteIntegrationFmIntegrationsProviderDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteIntegrationFmIntegrationsProviderDelete(
          requestParameters.provider,
          requestParameters.secretStore,
          requestParameters.workspaceUid,
          requestParameters.body,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Integrations
     * @param {FmIntegrationsApiListIntegrationsFmIntegrationsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listIntegrationsFmIntegrationsPut(
      requestParameters: FmIntegrationsApiListIntegrationsFmIntegrationsPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ListIntegrationsResponse> {
      return localVarFp
        .listIntegrationsFmIntegrationsPut(
          requestParameters.listIntegrationsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Set Integration
     * @param {FmIntegrationsApiSetIntegrationFmIntegrationsProviderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setIntegrationFmIntegrationsProviderPost(
      requestParameters: FmIntegrationsApiSetIntegrationFmIntegrationsProviderPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .setIntegrationFmIntegrationsProviderPost(
          requestParameters.provider,
          requestParameters.setIntegrationParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteIntegrationFmIntegrationsProviderDelete operation in FmIntegrationsApi.
 * @export
 * @interface FmIntegrationsApiDeleteIntegrationFmIntegrationsProviderDeleteRequest
 */
export interface FmIntegrationsApiDeleteIntegrationFmIntegrationsProviderDeleteRequest {
  /**
   *
   * @type {ExternalLLMProvider}
   * @memberof FmIntegrationsApiDeleteIntegrationFmIntegrationsProviderDelete
   */
  readonly provider: ExternalLLMProvider;

  /**
   *
   * @type {string}
   * @memberof FmIntegrationsApiDeleteIntegrationFmIntegrationsProviderDelete
   */
  readonly secretStore?: string;

  /**
   *
   * @type {number}
   * @memberof FmIntegrationsApiDeleteIntegrationFmIntegrationsProviderDelete
   */
  readonly workspaceUid?: number;

  /**
   *
   * @type {object}
   * @memberof FmIntegrationsApiDeleteIntegrationFmIntegrationsProviderDelete
   */
  readonly body?: object;
}

/**
 * Request parameters for listIntegrationsFmIntegrationsPut operation in FmIntegrationsApi.
 * @export
 * @interface FmIntegrationsApiListIntegrationsFmIntegrationsPutRequest
 */
export interface FmIntegrationsApiListIntegrationsFmIntegrationsPutRequest {
  /**
   *
   * @type {ListIntegrationsParams}
   * @memberof FmIntegrationsApiListIntegrationsFmIntegrationsPut
   */
  readonly listIntegrationsParams: ListIntegrationsParams;
}

/**
 * Request parameters for setIntegrationFmIntegrationsProviderPost operation in FmIntegrationsApi.
 * @export
 * @interface FmIntegrationsApiSetIntegrationFmIntegrationsProviderPostRequest
 */
export interface FmIntegrationsApiSetIntegrationFmIntegrationsProviderPostRequest {
  /**
   *
   * @type {ExternalLLMProvider}
   * @memberof FmIntegrationsApiSetIntegrationFmIntegrationsProviderPost
   */
  readonly provider: ExternalLLMProvider;

  /**
   *
   * @type {SetIntegrationParams}
   * @memberof FmIntegrationsApiSetIntegrationFmIntegrationsProviderPost
   */
  readonly setIntegrationParams: SetIntegrationParams;
}

/**
 * FmIntegrationsApi - object-oriented interface
 * @export
 * @class FmIntegrationsApi
 * @extends {BaseAPI}
 */
export class FmIntegrationsApi extends BaseAPI {
  /**
   *
   * @summary Delete Integration
   * @param {FmIntegrationsApiDeleteIntegrationFmIntegrationsProviderDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FmIntegrationsApi
   */
  public deleteIntegrationFmIntegrationsProviderDelete(
    requestParameters: FmIntegrationsApiDeleteIntegrationFmIntegrationsProviderDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FmIntegrationsApiFp(this.configuration)
      .deleteIntegrationFmIntegrationsProviderDelete(
        requestParameters.provider,
        requestParameters.secretStore,
        requestParameters.workspaceUid,
        requestParameters.body,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Integrations
   * @param {FmIntegrationsApiListIntegrationsFmIntegrationsPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FmIntegrationsApi
   */
  public listIntegrationsFmIntegrationsPut(
    requestParameters: FmIntegrationsApiListIntegrationsFmIntegrationsPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FmIntegrationsApiFp(this.configuration)
      .listIntegrationsFmIntegrationsPut(
        requestParameters.listIntegrationsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Integration
   * @param {FmIntegrationsApiSetIntegrationFmIntegrationsProviderPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FmIntegrationsApi
   */
  public setIntegrationFmIntegrationsProviderPost(
    requestParameters: FmIntegrationsApiSetIntegrationFmIntegrationsProviderPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FmIntegrationsApiFp(this.configuration)
      .setIntegrationFmIntegrationsProviderPost(
        requestParameters.provider,
        requestParameters.setIntegrationParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FmPromptingApi - axios parameter creator
 * @export
 */
export const FmPromptingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Augment Dataset
     * @param {number} datasetUid
     * @param {AugmentDatasetRequest} augmentDatasetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    augmentDatasetAugmentDatasetDatasetUidPost: async (
      datasetUid: number,
      augmentDatasetRequest: AugmentDatasetRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'augmentDatasetAugmentDatasetDatasetUidPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'augmentDatasetRequest' is not null or undefined
      assertParamExists(
        'augmentDatasetAugmentDatasetDatasetUidPost',
        'augmentDatasetRequest',
        augmentDatasetRequest,
      );
      const localVarPath = `/augment-dataset/{dataset_uid}`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        augmentDatasetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Prompt Fm Over Dataset
     * @param {number} datasetUid
     * @param {PromptFMDatasetRequest} promptFMDatasetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promptFmOverDatasetPromptFmDatasetUidPost: async (
      datasetUid: number,
      promptFMDatasetRequest: PromptFMDatasetRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'promptFmOverDatasetPromptFmDatasetUidPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'promptFMDatasetRequest' is not null or undefined
      assertParamExists(
        'promptFmOverDatasetPromptFmDatasetUidPost',
        'promptFMDatasetRequest',
        promptFMDatasetRequest,
      );
      const localVarPath = `/prompt-fm/{dataset_uid}`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        promptFMDatasetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Prompt Fm
     * @param {PromptFMRequest} promptFMRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promptFmPromptFmPost: async (
      promptFMRequest: PromptFMRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'promptFMRequest' is not null or undefined
      assertParamExists(
        'promptFmPromptFmPost',
        'promptFMRequest',
        promptFMRequest,
      );
      const localVarPath = `/prompt-fm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        promptFMRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FmPromptingApi - functional programming interface
 * @export
 */
export const FmPromptingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    FmPromptingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Augment Dataset
     * @param {number} datasetUid
     * @param {AugmentDatasetRequest} augmentDatasetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async augmentDatasetAugmentDatasetDatasetUidPost(
      datasetUid: number,
      augmentDatasetRequest: AugmentDatasetRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PromptFMResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.augmentDatasetAugmentDatasetDatasetUidPost(
          datasetUid,
          augmentDatasetRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FmPromptingApi.augmentDatasetAugmentDatasetDatasetUidPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Prompt Fm Over Dataset
     * @param {number} datasetUid
     * @param {PromptFMDatasetRequest} promptFMDatasetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async promptFmOverDatasetPromptFmDatasetUidPost(
      datasetUid: number,
      promptFMDatasetRequest: PromptFMDatasetRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PromptFMResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.promptFmOverDatasetPromptFmDatasetUidPost(
          datasetUid,
          promptFMDatasetRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'FmPromptingApi.promptFmOverDatasetPromptFmDatasetUidPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Prompt Fm
     * @param {PromptFMRequest} promptFMRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async promptFmPromptFmPost(
      promptFMRequest: PromptFMRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PromptFMResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.promptFmPromptFmPost(
          promptFMRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FmPromptingApi.promptFmPromptFmPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FmPromptingApi - factory interface
 * @export
 */
export const FmPromptingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FmPromptingApiFp(configuration);
  return {
    /**
     *
     * @summary Augment Dataset
     * @param {FmPromptingApiAugmentDatasetAugmentDatasetDatasetUidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    augmentDatasetAugmentDatasetDatasetUidPost(
      requestParameters: FmPromptingApiAugmentDatasetAugmentDatasetDatasetUidPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PromptFMResponse> {
      return localVarFp
        .augmentDatasetAugmentDatasetDatasetUidPost(
          requestParameters.datasetUid,
          requestParameters.augmentDatasetRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Prompt Fm Over Dataset
     * @param {FmPromptingApiPromptFmOverDatasetPromptFmDatasetUidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promptFmOverDatasetPromptFmDatasetUidPost(
      requestParameters: FmPromptingApiPromptFmOverDatasetPromptFmDatasetUidPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PromptFMResponse> {
      return localVarFp
        .promptFmOverDatasetPromptFmDatasetUidPost(
          requestParameters.datasetUid,
          requestParameters.promptFMDatasetRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Prompt Fm
     * @param {FmPromptingApiPromptFmPromptFmPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promptFmPromptFmPost(
      requestParameters: FmPromptingApiPromptFmPromptFmPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PromptFMResponse> {
      return localVarFp
        .promptFmPromptFmPost(requestParameters.promptFMRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for augmentDatasetAugmentDatasetDatasetUidPost operation in FmPromptingApi.
 * @export
 * @interface FmPromptingApiAugmentDatasetAugmentDatasetDatasetUidPostRequest
 */
export interface FmPromptingApiAugmentDatasetAugmentDatasetDatasetUidPostRequest {
  /**
   *
   * @type {number}
   * @memberof FmPromptingApiAugmentDatasetAugmentDatasetDatasetUidPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {AugmentDatasetRequest}
   * @memberof FmPromptingApiAugmentDatasetAugmentDatasetDatasetUidPost
   */
  readonly augmentDatasetRequest: AugmentDatasetRequest;
}

/**
 * Request parameters for promptFmOverDatasetPromptFmDatasetUidPost operation in FmPromptingApi.
 * @export
 * @interface FmPromptingApiPromptFmOverDatasetPromptFmDatasetUidPostRequest
 */
export interface FmPromptingApiPromptFmOverDatasetPromptFmDatasetUidPostRequest {
  /**
   *
   * @type {number}
   * @memberof FmPromptingApiPromptFmOverDatasetPromptFmDatasetUidPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {PromptFMDatasetRequest}
   * @memberof FmPromptingApiPromptFmOverDatasetPromptFmDatasetUidPost
   */
  readonly promptFMDatasetRequest: PromptFMDatasetRequest;
}

/**
 * Request parameters for promptFmPromptFmPost operation in FmPromptingApi.
 * @export
 * @interface FmPromptingApiPromptFmPromptFmPostRequest
 */
export interface FmPromptingApiPromptFmPromptFmPostRequest {
  /**
   *
   * @type {PromptFMRequest}
   * @memberof FmPromptingApiPromptFmPromptFmPost
   */
  readonly promptFMRequest: PromptFMRequest;
}

/**
 * FmPromptingApi - object-oriented interface
 * @export
 * @class FmPromptingApi
 * @extends {BaseAPI}
 */
export class FmPromptingApi extends BaseAPI {
  /**
   *
   * @summary Augment Dataset
   * @param {FmPromptingApiAugmentDatasetAugmentDatasetDatasetUidPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FmPromptingApi
   */
  public augmentDatasetAugmentDatasetDatasetUidPost(
    requestParameters: FmPromptingApiAugmentDatasetAugmentDatasetDatasetUidPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FmPromptingApiFp(this.configuration)
      .augmentDatasetAugmentDatasetDatasetUidPost(
        requestParameters.datasetUid,
        requestParameters.augmentDatasetRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Prompt Fm Over Dataset
   * @param {FmPromptingApiPromptFmOverDatasetPromptFmDatasetUidPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FmPromptingApi
   */
  public promptFmOverDatasetPromptFmDatasetUidPost(
    requestParameters: FmPromptingApiPromptFmOverDatasetPromptFmDatasetUidPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FmPromptingApiFp(this.configuration)
      .promptFmOverDatasetPromptFmDatasetUidPost(
        requestParameters.datasetUid,
        requestParameters.promptFMDatasetRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Prompt Fm
   * @param {FmPromptingApiPromptFmPromptFmPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FmPromptingApi
   */
  public promptFmPromptFmPost(
    requestParameters: FmPromptingApiPromptFmPromptFmPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FmPromptingApiFp(this.configuration)
      .promptFmPromptFmPost(requestParameters.promptFMRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GroundTruthApi - axios parameter creator
 * @export
 */
export const GroundTruthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Flat Ground Truth Route
     * @param {number} nodeUid
     * @param {FlatGroundTruthModel} flatGroundTruthModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost: async (
      nodeUid: number,
      flatGroundTruthModel: FlatGroundTruthModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'flatGroundTruthModel' is not null or undefined
      assertParamExists(
        'addFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost',
        'flatGroundTruthModel',
        flatGroundTruthModel,
      );
      const localVarPath = `/nodes/{node_uid}/flat-ground-truth`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        flatGroundTruthModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add Ground Truth Route
     * @param {number} nodeUid
     * @param {GroundTruthModel} groundTruthModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addGroundTruthRouteNodesNodeUidGroundTruthPost: async (
      nodeUid: number,
      groundTruthModel: GroundTruthModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addGroundTruthRouteNodesNodeUidGroundTruthPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'groundTruthModel' is not null or undefined
      assertParamExists(
        'addGroundTruthRouteNodesNodeUidGroundTruthPost',
        'groundTruthModel',
        groundTruthModel,
      );
      const localVarPath = `/nodes/{node_uid}/ground-truth`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        groundTruthModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Validate label space for node_uid, only works for sequence label space
     * @summary Align Ground Truth
     * @param {number} nodeUid
     * @param {AlignGTsReq} alignGTsReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alignGroundTruthNodesNodeUidAlignGroundTruthPost: async (
      nodeUid: number,
      alignGTsReq: AlignGTsReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'alignGroundTruthNodesNodeUidAlignGroundTruthPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'alignGTsReq' is not null or undefined
      assertParamExists(
        'alignGroundTruthNodesNodeUidAlignGroundTruthPost',
        'alignGTsReq',
        alignGTsReq,
      );
      const localVarPath = `/nodes/{node_uid}/align-ground-truth`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        alignGTsReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Convert Raw To User
     * @param {number} nodeUid
     * @param {ConvertRawToUserParams} convertRawToUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    convertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost: async (
      nodeUid: number,
      convertRawToUserParams: ConvertRawToUserParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'convertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'convertRawToUserParams' is not null or undefined
      assertParamExists(
        'convertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost',
        'convertRawToUserParams',
        convertRawToUserParams,
      );
      const localVarPath =
        `/node/{node_uid}/convert-raw-labels-to-user-labels`.replace(
          `{${'node_uid'}}`,
          encodeURIComponent(String(nodeUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        convertRawToUserParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Convert User To Raw
     * @param {number} nodeUid
     * @param {ConvertUserToRawParams} convertUserToRawParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    convertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost: async (
      nodeUid: number,
      convertUserToRawParams: ConvertUserToRawParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'convertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'convertUserToRawParams' is not null or undefined
      assertParamExists(
        'convertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost',
        'convertUserToRawParams',
        convertUserToRawParams,
      );
      const localVarPath =
        `/node/{node_uid}/convert-user-labels-to-raw-labels`.replace(
          `{${'node_uid'}}`,
          encodeURIComponent(String(nodeUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        convertUserToRawParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Gt Version Route
     * @param {number} nodeUid
     * @param {CreateGroundTruthVersionParams} createGroundTruthVersionParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGtVersionRouteNodesNodeUidGtVersionPost: async (
      nodeUid: number,
      createGroundTruthVersionParams: CreateGroundTruthVersionParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'createGtVersionRouteNodesNodeUidGtVersionPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'createGroundTruthVersionParams' is not null or undefined
      assertParamExists(
        'createGtVersionRouteNodesNodeUidGtVersionPost',
        'createGroundTruthVersionParams',
        createGroundTruthVersionParams,
      );
      const localVarPath = `/nodes/{node_uid}/gt-version`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createGroundTruthVersionParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Ground Truth
     * @param {number} nodeUid
     * @param {boolean} [isContext]
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [userFormatted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchGroundTruthNodesNodeUidGroundTruthGet: async (
      nodeUid: number,
      isContext?: boolean,
      split?: string,
      startDate?: string,
      endDate?: string,
      userFormatted?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchGroundTruthNodesNodeUidGroundTruthGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/ground-truth`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isContext !== undefined) {
        localVarQueryParameter['is_context'] = isContext;
      }

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (userFormatted !== undefined) {
        localVarQueryParameter['user_formatted'] = userFormatted;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Default Label
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultLabelNodeNodeUidGetRawUnknownLabelGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getDefaultLabelNodeNodeUidGetRawUnknownLabelGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/node/{node_uid}/get-raw-unknown-label`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Span Level Ground Truth Conflicts
     * @param {number} nodeUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet:
      async (
        nodeUid: number,
        split?: string,
        startDate?: string,
        endDate?: string,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet',
          'nodeUid',
          nodeUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/get-span-level-ground-truth-conflicts`.replace(
            `{${'node_uid'}}`,
            encodeURIComponent(String(nodeUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (split !== undefined) {
          localVarQueryParameter['split'] = split;
        }

        if (startDate !== undefined) {
          localVarQueryParameter['start_date'] = startDate;
        }

        if (endDate !== undefined) {
          localVarQueryParameter['end_date'] = endDate;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Get the label distribution for the ground truth of a node. Will use the app template\'s ApplicationType to determine if we should get gt on the context label space instead.
     * @summary Ground Truth Label Distribution
     * @param {number} nodeUid
     * @param {boolean} [userFormatted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet:
      async (
        nodeUid: number,
        userFormatted?: boolean,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'groundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet',
          'nodeUid',
          nodeUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/ground-truth-label-distribution`.replace(
            `{${'node_uid'}}`,
            encodeURIComponent(String(nodeUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (userFormatted !== undefined) {
          localVarQueryParameter['user_formatted'] = userFormatted;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Hide Gt Version
     * @param {number} nodeUid
     * @param {number} gtVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost: async (
      nodeUid: number,
      gtVersionUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'hideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'gtVersionUid' is not null or undefined
      assertParamExists(
        'hideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost',
        'gtVersionUid',
        gtVersionUid,
      );
      const localVarPath = `/nodes/{node_uid}/gt-version/{gt_version_uid}/hide`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'gt_version_uid'}}`,
          encodeURIComponent(String(gtVersionUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Gt Versions Route
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listGtVersionsRouteNodesNodeUidGtVersionsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listGtVersionsRouteNodesNodeUidGtVersionsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/gt-versions`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Load Gt Version
     * @param {number} nodeUid
     * @param {number} gtVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost: async (
      nodeUid: number,
      gtVersionUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'loadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'gtVersionUid' is not null or undefined
      assertParamExists(
        'loadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost',
        'gtVersionUid',
        gtVersionUid,
      );
      const localVarPath = `/nodes/{node_uid}/gt-version/{gt_version_uid}/load`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'gt_version_uid'}}`,
          encodeURIComponent(String(gtVersionUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a map of x_uids to ground truth for the given x_uids
     * @summary Search Ground Truth
     * @param {number} nodeUid
     * @param {GroundTruthSearchParams} groundTruthSearchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGroundTruthNodesNodeUidSearchGroundTruthPost: async (
      nodeUid: number,
      groundTruthSearchParams: GroundTruthSearchParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'searchGroundTruthNodesNodeUidSearchGroundTruthPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'groundTruthSearchParams' is not null or undefined
      assertParamExists(
        'searchGroundTruthNodesNodeUidSearchGroundTruthPost',
        'groundTruthSearchParams',
        groundTruthSearchParams,
      );
      const localVarPath = `/nodes/{node_uid}/search-ground-truth`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        groundTruthSearchParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GroundTruthApi - functional programming interface
 * @export
 */
export const GroundTruthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    GroundTruthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Flat Ground Truth Route
     * @param {number} nodeUid
     * @param {FlatGroundTruthModel} flatGroundTruthModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost(
      nodeUid: number,
      flatGroundTruthModel: FlatGroundTruthModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateTaskGroundTruthResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost(
          nodeUid,
          flatGroundTruthModel,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.addFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Add Ground Truth Route
     * @param {number} nodeUid
     * @param {GroundTruthModel} groundTruthModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addGroundTruthRouteNodesNodeUidGroundTruthPost(
      nodeUid: number,
      groundTruthModel: GroundTruthModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateTaskGroundTruthResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addGroundTruthRouteNodesNodeUidGroundTruthPost(
          nodeUid,
          groundTruthModel,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.addGroundTruthRouteNodesNodeUidGroundTruthPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Validate label space for node_uid, only works for sequence label space
     * @summary Align Ground Truth
     * @param {number} nodeUid
     * @param {AlignGTsReq} alignGTsReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async alignGroundTruthNodesNodeUidAlignGroundTruthPost(
      nodeUid: number,
      alignGTsReq: AlignGTsReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.alignGroundTruthNodesNodeUidAlignGroundTruthPost(
          nodeUid,
          alignGTsReq,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.alignGroundTruthNodesNodeUidAlignGroundTruthPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Convert Raw To User
     * @param {number} nodeUid
     * @param {ConvertRawToUserParams} convertRawToUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async convertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost(
      nodeUid: number,
      convertRawToUserParams: ConvertRawToUserParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.convertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost(
          nodeUid,
          convertRawToUserParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.convertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Convert User To Raw
     * @param {number} nodeUid
     * @param {ConvertUserToRawParams} convertUserToRawParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async convertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost(
      nodeUid: number,
      convertUserToRawParams: ConvertUserToRawParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.convertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost(
          nodeUid,
          convertUserToRawParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.convertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Create Gt Version Route
     * @param {number} nodeUid
     * @param {CreateGroundTruthVersionParams} createGroundTruthVersionParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createGtVersionRouteNodesNodeUidGtVersionPost(
      nodeUid: number,
      createGroundTruthVersionParams: CreateGroundTruthVersionParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateGroundTruthVersionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createGtVersionRouteNodesNodeUidGtVersionPost(
          nodeUid,
          createGroundTruthVersionParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.createGtVersionRouteNodesNodeUidGtVersionPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Ground Truth
     * @param {number} nodeUid
     * @param {boolean} [isContext]
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [userFormatted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchGroundTruthNodesNodeUidGroundTruthGet(
      nodeUid: number,
      isContext?: boolean,
      split?: string,
      startDate?: string,
      endDate?: string,
      userFormatted?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseFetchGroundTruthNodesNodeUidGroundTruthGet>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchGroundTruthNodesNodeUidGroundTruthGet(
          nodeUid,
          isContext,
          split,
          startDate,
          endDate,
          userFormatted,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.fetchGroundTruthNodesNodeUidGroundTruthGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Default Label
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDefaultLabelNodeNodeUidGetRawUnknownLabelGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDefaultLabelNodeNodeUidGetRawUnknownLabelGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.getDefaultLabelNodeNodeUidGetRawUnknownLabelGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Span Level Ground Truth Conflicts
     * @param {number} nodeUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet(
      nodeUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSpanLevelGroundTruthConflictsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet(
          nodeUid,
          split,
          startDate,
          endDate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.getSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get the label distribution for the ground truth of a node. Will use the app template\'s ApplicationType to determine if we should get gt on the context label space instead.
     * @summary Ground Truth Label Distribution
     * @param {number} nodeUid
     * @param {boolean} [userFormatted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async groundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet(
      nodeUid: number,
      userFormatted?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GroundTruthLabelDistributionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.groundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet(
          nodeUid,
          userFormatted,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.groundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Hide Gt Version
     * @param {number} nodeUid
     * @param {number} gtVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async hideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost(
      nodeUid: number,
      gtVersionUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.hideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost(
          nodeUid,
          gtVersionUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.hideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Gt Versions Route
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listGtVersionsRouteNodesNodeUidGtVersionsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GroundTruthVersionResponse>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listGtVersionsRouteNodesNodeUidGtVersionsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.listGtVersionsRouteNodesNodeUidGtVersionsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Load Gt Version
     * @param {number} nodeUid
     * @param {number} gtVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost(
      nodeUid: number,
      gtVersionUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.loadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost(
          nodeUid,
          gtVersionUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.loadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Returns a map of x_uids to ground truth for the given x_uids
     * @summary Search Ground Truth
     * @param {number} nodeUid
     * @param {GroundTruthSearchParams} groundTruthSearchParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchGroundTruthNodesNodeUidSearchGroundTruthPost(
      nodeUid: number,
      groundTruthSearchParams: GroundTruthSearchParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.searchGroundTruthNodesNodeUidSearchGroundTruthPost(
          nodeUid,
          groundTruthSearchParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'GroundTruthApi.searchGroundTruthNodesNodeUidSearchGroundTruthPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * GroundTruthApi - factory interface
 * @export
 */
export const GroundTruthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GroundTruthApiFp(configuration);
  return {
    /**
     *
     * @summary Add Flat Ground Truth Route
     * @param {GroundTruthApiAddFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost(
      requestParameters: GroundTruthApiAddFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateTaskGroundTruthResponse> {
      return localVarFp
        .addFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost(
          requestParameters.nodeUid,
          requestParameters.flatGroundTruthModel,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Add Ground Truth Route
     * @param {GroundTruthApiAddGroundTruthRouteNodesNodeUidGroundTruthPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addGroundTruthRouteNodesNodeUidGroundTruthPost(
      requestParameters: GroundTruthApiAddGroundTruthRouteNodesNodeUidGroundTruthPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateTaskGroundTruthResponse> {
      return localVarFp
        .addGroundTruthRouteNodesNodeUidGroundTruthPost(
          requestParameters.nodeUid,
          requestParameters.groundTruthModel,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Validate label space for node_uid, only works for sequence label space
     * @summary Align Ground Truth
     * @param {GroundTruthApiAlignGroundTruthNodesNodeUidAlignGroundTruthPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alignGroundTruthNodesNodeUidAlignGroundTruthPost(
      requestParameters: GroundTruthApiAlignGroundTruthNodesNodeUidAlignGroundTruthPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .alignGroundTruthNodesNodeUidAlignGroundTruthPost(
          requestParameters.nodeUid,
          requestParameters.alignGTsReq,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Convert Raw To User
     * @param {GroundTruthApiConvertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    convertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost(
      requestParameters: GroundTruthApiConvertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<object> {
      return localVarFp
        .convertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost(
          requestParameters.nodeUid,
          requestParameters.convertRawToUserParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Convert User To Raw
     * @param {GroundTruthApiConvertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    convertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost(
      requestParameters: GroundTruthApiConvertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<object> {
      return localVarFp
        .convertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost(
          requestParameters.nodeUid,
          requestParameters.convertUserToRawParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create Gt Version Route
     * @param {GroundTruthApiCreateGtVersionRouteNodesNodeUidGtVersionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGtVersionRouteNodesNodeUidGtVersionPost(
      requestParameters: GroundTruthApiCreateGtVersionRouteNodesNodeUidGtVersionPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateGroundTruthVersionResponse> {
      return localVarFp
        .createGtVersionRouteNodesNodeUidGtVersionPost(
          requestParameters.nodeUid,
          requestParameters.createGroundTruthVersionParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Ground Truth
     * @param {GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchGroundTruthNodesNodeUidGroundTruthGet(
      requestParameters: GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ResponseFetchGroundTruthNodesNodeUidGroundTruthGet> {
      return localVarFp
        .fetchGroundTruthNodesNodeUidGroundTruthGet(
          requestParameters.nodeUid,
          requestParameters.isContext,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          requestParameters.userFormatted,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Default Label
     * @param {GroundTruthApiGetDefaultLabelNodeNodeUidGetRawUnknownLabelGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultLabelNodeNodeUidGetRawUnknownLabelGet(
      requestParameters: GroundTruthApiGetDefaultLabelNodeNodeUidGetRawUnknownLabelGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getDefaultLabelNodeNodeUidGetRawUnknownLabelGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Span Level Ground Truth Conflicts
     * @param {GroundTruthApiGetSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet(
      requestParameters: GroundTruthApiGetSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetSpanLevelGroundTruthConflictsResponse> {
      return localVarFp
        .getSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Get the label distribution for the ground truth of a node. Will use the app template\'s ApplicationType to determine if we should get gt on the context label space instead.
     * @summary Ground Truth Label Distribution
     * @param {GroundTruthApiGroundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    groundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet(
      requestParameters: GroundTruthApiGroundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GroundTruthLabelDistributionResponse> {
      return localVarFp
        .groundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet(
          requestParameters.nodeUid,
          requestParameters.userFormatted,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Hide Gt Version
     * @param {GroundTruthApiHideGtVersionNodesNodeUidGtVersionGtVersionUidHidePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost(
      requestParameters: GroundTruthApiHideGtVersionNodesNodeUidGtVersionGtVersionUidHidePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .hideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost(
          requestParameters.nodeUid,
          requestParameters.gtVersionUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Gt Versions Route
     * @param {GroundTruthApiListGtVersionsRouteNodesNodeUidGtVersionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listGtVersionsRouteNodesNodeUidGtVersionsGet(
      requestParameters: GroundTruthApiListGtVersionsRouteNodesNodeUidGtVersionsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<GroundTruthVersionResponse>> {
      return localVarFp
        .listGtVersionsRouteNodesNodeUidGtVersionsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Load Gt Version
     * @param {GroundTruthApiLoadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost(
      requestParameters: GroundTruthApiLoadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .loadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost(
          requestParameters.nodeUid,
          requestParameters.gtVersionUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Returns a map of x_uids to ground truth for the given x_uids
     * @summary Search Ground Truth
     * @param {GroundTruthApiSearchGroundTruthNodesNodeUidSearchGroundTruthPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchGroundTruthNodesNodeUidSearchGroundTruthPost(
      requestParameters: GroundTruthApiSearchGroundTruthNodesNodeUidSearchGroundTruthPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<object> {
      return localVarFp
        .searchGroundTruthNodesNodeUidSearchGroundTruthPost(
          requestParameters.nodeUid,
          requestParameters.groundTruthSearchParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiAddFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPostRequest
 */
export interface GroundTruthApiAddFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPostRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiAddFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {FlatGroundTruthModel}
   * @memberof GroundTruthApiAddFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost
   */
  readonly flatGroundTruthModel: FlatGroundTruthModel;
}

/**
 * Request parameters for addGroundTruthRouteNodesNodeUidGroundTruthPost operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiAddGroundTruthRouteNodesNodeUidGroundTruthPostRequest
 */
export interface GroundTruthApiAddGroundTruthRouteNodesNodeUidGroundTruthPostRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiAddGroundTruthRouteNodesNodeUidGroundTruthPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {GroundTruthModel}
   * @memberof GroundTruthApiAddGroundTruthRouteNodesNodeUidGroundTruthPost
   */
  readonly groundTruthModel: GroundTruthModel;
}

/**
 * Request parameters for alignGroundTruthNodesNodeUidAlignGroundTruthPost operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiAlignGroundTruthNodesNodeUidAlignGroundTruthPostRequest
 */
export interface GroundTruthApiAlignGroundTruthNodesNodeUidAlignGroundTruthPostRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiAlignGroundTruthNodesNodeUidAlignGroundTruthPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {AlignGTsReq}
   * @memberof GroundTruthApiAlignGroundTruthNodesNodeUidAlignGroundTruthPost
   */
  readonly alignGTsReq: AlignGTsReq;
}

/**
 * Request parameters for convertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiConvertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPostRequest
 */
export interface GroundTruthApiConvertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPostRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiConvertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ConvertRawToUserParams}
   * @memberof GroundTruthApiConvertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost
   */
  readonly convertRawToUserParams: ConvertRawToUserParams;
}

/**
 * Request parameters for convertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiConvertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPostRequest
 */
export interface GroundTruthApiConvertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPostRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiConvertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ConvertUserToRawParams}
   * @memberof GroundTruthApiConvertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost
   */
  readonly convertUserToRawParams: ConvertUserToRawParams;
}

/**
 * Request parameters for createGtVersionRouteNodesNodeUidGtVersionPost operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiCreateGtVersionRouteNodesNodeUidGtVersionPostRequest
 */
export interface GroundTruthApiCreateGtVersionRouteNodesNodeUidGtVersionPostRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiCreateGtVersionRouteNodesNodeUidGtVersionPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {CreateGroundTruthVersionParams}
   * @memberof GroundTruthApiCreateGtVersionRouteNodesNodeUidGtVersionPost
   */
  readonly createGroundTruthVersionParams: CreateGroundTruthVersionParams;
}

/**
 * Request parameters for fetchGroundTruthNodesNodeUidGroundTruthGet operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGetRequest
 */
export interface GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGetRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGet
   */
  readonly isContext?: boolean;

  /**
   *
   * @type {string}
   * @memberof GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGet
   */
  readonly endDate?: string;

  /**
   *
   * @type {boolean}
   * @memberof GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGet
   */
  readonly userFormatted?: boolean;
}

/**
 * Request parameters for getDefaultLabelNodeNodeUidGetRawUnknownLabelGet operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiGetDefaultLabelNodeNodeUidGetRawUnknownLabelGetRequest
 */
export interface GroundTruthApiGetDefaultLabelNodeNodeUidGetRawUnknownLabelGetRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiGetDefaultLabelNodeNodeUidGetRawUnknownLabelGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for getSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiGetSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGetRequest
 */
export interface GroundTruthApiGetSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGetRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiGetSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof GroundTruthApiGetSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof GroundTruthApiGetSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof GroundTruthApiGetSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet
   */
  readonly endDate?: string;
}

/**
 * Request parameters for groundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiGroundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGetRequest
 */
export interface GroundTruthApiGroundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGetRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiGroundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof GroundTruthApiGroundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet
   */
  readonly userFormatted?: boolean;
}

/**
 * Request parameters for hideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiHideGtVersionNodesNodeUidGtVersionGtVersionUidHidePostRequest
 */
export interface GroundTruthApiHideGtVersionNodesNodeUidGtVersionGtVersionUidHidePostRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiHideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof GroundTruthApiHideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost
   */
  readonly gtVersionUid: number;
}

/**
 * Request parameters for listGtVersionsRouteNodesNodeUidGtVersionsGet operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiListGtVersionsRouteNodesNodeUidGtVersionsGetRequest
 */
export interface GroundTruthApiListGtVersionsRouteNodesNodeUidGtVersionsGetRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiListGtVersionsRouteNodesNodeUidGtVersionsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for loadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiLoadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPostRequest
 */
export interface GroundTruthApiLoadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPostRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiLoadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof GroundTruthApiLoadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost
   */
  readonly gtVersionUid: number;
}

/**
 * Request parameters for searchGroundTruthNodesNodeUidSearchGroundTruthPost operation in GroundTruthApi.
 * @export
 * @interface GroundTruthApiSearchGroundTruthNodesNodeUidSearchGroundTruthPostRequest
 */
export interface GroundTruthApiSearchGroundTruthNodesNodeUidSearchGroundTruthPostRequest {
  /**
   *
   * @type {number}
   * @memberof GroundTruthApiSearchGroundTruthNodesNodeUidSearchGroundTruthPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {GroundTruthSearchParams}
   * @memberof GroundTruthApiSearchGroundTruthNodesNodeUidSearchGroundTruthPost
   */
  readonly groundTruthSearchParams: GroundTruthSearchParams;
}

/**
 * GroundTruthApi - object-oriented interface
 * @export
 * @class GroundTruthApi
 * @extends {BaseAPI}
 */
export class GroundTruthApi extends BaseAPI {
  /**
   *
   * @summary Add Flat Ground Truth Route
   * @param {GroundTruthApiAddFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public addFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost(
    requestParameters: GroundTruthApiAddFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .addFlatGroundTruthRouteNodesNodeUidFlatGroundTruthPost(
        requestParameters.nodeUid,
        requestParameters.flatGroundTruthModel,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add Ground Truth Route
   * @param {GroundTruthApiAddGroundTruthRouteNodesNodeUidGroundTruthPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public addGroundTruthRouteNodesNodeUidGroundTruthPost(
    requestParameters: GroundTruthApiAddGroundTruthRouteNodesNodeUidGroundTruthPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .addGroundTruthRouteNodesNodeUidGroundTruthPost(
        requestParameters.nodeUid,
        requestParameters.groundTruthModel,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Validate label space for node_uid, only works for sequence label space
   * @summary Align Ground Truth
   * @param {GroundTruthApiAlignGroundTruthNodesNodeUidAlignGroundTruthPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public alignGroundTruthNodesNodeUidAlignGroundTruthPost(
    requestParameters: GroundTruthApiAlignGroundTruthNodesNodeUidAlignGroundTruthPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .alignGroundTruthNodesNodeUidAlignGroundTruthPost(
        requestParameters.nodeUid,
        requestParameters.alignGTsReq,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Convert Raw To User
   * @param {GroundTruthApiConvertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public convertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost(
    requestParameters: GroundTruthApiConvertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .convertRawToUserNodeNodeUidConvertRawLabelsToUserLabelsPost(
        requestParameters.nodeUid,
        requestParameters.convertRawToUserParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Convert User To Raw
   * @param {GroundTruthApiConvertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public convertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost(
    requestParameters: GroundTruthApiConvertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .convertUserToRawNodeNodeUidConvertUserLabelsToRawLabelsPost(
        requestParameters.nodeUid,
        requestParameters.convertUserToRawParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Gt Version Route
   * @param {GroundTruthApiCreateGtVersionRouteNodesNodeUidGtVersionPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public createGtVersionRouteNodesNodeUidGtVersionPost(
    requestParameters: GroundTruthApiCreateGtVersionRouteNodesNodeUidGtVersionPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .createGtVersionRouteNodesNodeUidGtVersionPost(
        requestParameters.nodeUid,
        requestParameters.createGroundTruthVersionParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Ground Truth
   * @param {GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public fetchGroundTruthNodesNodeUidGroundTruthGet(
    requestParameters: GroundTruthApiFetchGroundTruthNodesNodeUidGroundTruthGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .fetchGroundTruthNodesNodeUidGroundTruthGet(
        requestParameters.nodeUid,
        requestParameters.isContext,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.userFormatted,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Default Label
   * @param {GroundTruthApiGetDefaultLabelNodeNodeUidGetRawUnknownLabelGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public getDefaultLabelNodeNodeUidGetRawUnknownLabelGet(
    requestParameters: GroundTruthApiGetDefaultLabelNodeNodeUidGetRawUnknownLabelGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .getDefaultLabelNodeNodeUidGetRawUnknownLabelGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Span Level Ground Truth Conflicts
   * @param {GroundTruthApiGetSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public getSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet(
    requestParameters: GroundTruthApiGetSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .getSpanLevelGroundTruthConflictsNodesNodeUidGetSpanLevelGroundTruthConflictsGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get the label distribution for the ground truth of a node. Will use the app template\'s ApplicationType to determine if we should get gt on the context label space instead.
   * @summary Ground Truth Label Distribution
   * @param {GroundTruthApiGroundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public groundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet(
    requestParameters: GroundTruthApiGroundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .groundTruthLabelDistributionNodesNodeUidGroundTruthLabelDistributionGet(
        requestParameters.nodeUid,
        requestParameters.userFormatted,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Hide Gt Version
   * @param {GroundTruthApiHideGtVersionNodesNodeUidGtVersionGtVersionUidHidePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public hideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost(
    requestParameters: GroundTruthApiHideGtVersionNodesNodeUidGtVersionGtVersionUidHidePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .hideGtVersionNodesNodeUidGtVersionGtVersionUidHidePost(
        requestParameters.nodeUid,
        requestParameters.gtVersionUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Gt Versions Route
   * @param {GroundTruthApiListGtVersionsRouteNodesNodeUidGtVersionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public listGtVersionsRouteNodesNodeUidGtVersionsGet(
    requestParameters: GroundTruthApiListGtVersionsRouteNodesNodeUidGtVersionsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .listGtVersionsRouteNodesNodeUidGtVersionsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Load Gt Version
   * @param {GroundTruthApiLoadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public loadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost(
    requestParameters: GroundTruthApiLoadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .loadGtVersionNodesNodeUidGtVersionGtVersionUidLoadPost(
        requestParameters.nodeUid,
        requestParameters.gtVersionUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Returns a map of x_uids to ground truth for the given x_uids
   * @summary Search Ground Truth
   * @param {GroundTruthApiSearchGroundTruthNodesNodeUidSearchGroundTruthPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroundTruthApi
   */
  public searchGroundTruthNodesNodeUidSearchGroundTruthPost(
    requestParameters: GroundTruthApiSearchGroundTruthNodesNodeUidSearchGroundTruthPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return GroundTruthApiFp(this.configuration)
      .searchGroundTruthNodesNodeUidSearchGroundTruthPost(
        requestParameters.nodeUid,
        requestParameters.groundTruthSearchParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * InvitesApi - axios parameter creator
 * @export
 */
export const InvitesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Create Invite Link
     * @summary Create Invite
     * @param {CreateInviteParams} createInviteParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInviteInvitePost: async (
      createInviteParams: CreateInviteParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createInviteParams' is not null or undefined
      assertParamExists(
        'createInviteInvitePost',
        'createInviteParams',
        createInviteParams,
      );
      const localVarPath = `/invite`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createInviteParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Expire Invite Link
     * @summary Expire Invite
     * @param {ExpireInviteParams} expireInviteParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expireInviteInviteDelete: async (
      expireInviteParams: ExpireInviteParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'expireInviteParams' is not null or undefined
      assertParamExists(
        'expireInviteInviteDelete',
        'expireInviteParams',
        expireInviteParams,
      );
      const localVarPath = `/invite`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        expireInviteParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List Invite Links
     * @summary Get List Invites
     * @param {boolean} [showExpired]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListInvitesInvitesGet: async (
      showExpired?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/invites`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (showExpired !== undefined) {
        localVarQueryParameter['show_expired'] = showExpired;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List Invite Links
     * @summary Validate Invite
     * @param {string} inviteLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateInviteInviteValidateGet: async (
      inviteLink: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'inviteLink' is not null or undefined
      assertParamExists(
        'validateInviteInviteValidateGet',
        'inviteLink',
        inviteLink,
      );
      const localVarPath = `/invite/validate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (inviteLink !== undefined) {
        localVarQueryParameter['invite_link'] = inviteLink;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InvitesApi - functional programming interface
 * @export
 */
export const InvitesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InvitesApiAxiosParamCreator(configuration);
  return {
    /**
     * Create Invite Link
     * @summary Create Invite
     * @param {CreateInviteParams} createInviteParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInviteInvitePost(
      createInviteParams: CreateInviteParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createInviteInvitePost(
          createInviteParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['InvitesApi.createInviteInvitePost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Expire Invite Link
     * @summary Expire Invite
     * @param {ExpireInviteParams} expireInviteParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async expireInviteInviteDelete(
      expireInviteParams: ExpireInviteParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.expireInviteInviteDelete(
          expireInviteParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['InvitesApi.expireInviteInviteDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List Invite Links
     * @summary Get List Invites
     * @param {boolean} [showExpired]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListInvitesInvitesGet(
      showExpired?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetInvitesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getListInvitesInvitesGet(
          showExpired,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['InvitesApi.getListInvitesInvitesGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List Invite Links
     * @summary Validate Invite
     * @param {string} inviteLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateInviteInviteValidateGet(
      inviteLink: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ValidateInviteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.validateInviteInviteValidateGet(
          inviteLink,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['InvitesApi.validateInviteInviteValidateGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * InvitesApi - factory interface
 * @export
 */
export const InvitesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InvitesApiFp(configuration);
  return {
    /**
     * Create Invite Link
     * @summary Create Invite
     * @param {InvitesApiCreateInviteInvitePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInviteInvitePost(
      requestParameters: InvitesApiCreateInviteInvitePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<InviteResponse> {
      return localVarFp
        .createInviteInvitePost(requestParameters.createInviteParams, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Expire Invite Link
     * @summary Expire Invite
     * @param {InvitesApiExpireInviteInviteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expireInviteInviteDelete(
      requestParameters: InvitesApiExpireInviteInviteDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .expireInviteInviteDelete(requestParameters.expireInviteParams, options)
        .then(request => request(axios, basePath));
    },
    /**
     * List Invite Links
     * @summary Get List Invites
     * @param {InvitesApiGetListInvitesInvitesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListInvitesInvitesGet(
      requestParameters: InvitesApiGetListInvitesInvitesGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetInvitesResponse> {
      return localVarFp
        .getListInvitesInvitesGet(requestParameters.showExpired, options)
        .then(request => request(axios, basePath));
    },
    /**
     * List Invite Links
     * @summary Validate Invite
     * @param {InvitesApiValidateInviteInviteValidateGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateInviteInviteValidateGet(
      requestParameters: InvitesApiValidateInviteInviteValidateGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ValidateInviteResponse> {
      return localVarFp
        .validateInviteInviteValidateGet(requestParameters.inviteLink, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createInviteInvitePost operation in InvitesApi.
 * @export
 * @interface InvitesApiCreateInviteInvitePostRequest
 */
export interface InvitesApiCreateInviteInvitePostRequest {
  /**
   *
   * @type {CreateInviteParams}
   * @memberof InvitesApiCreateInviteInvitePost
   */
  readonly createInviteParams: CreateInviteParams;
}

/**
 * Request parameters for expireInviteInviteDelete operation in InvitesApi.
 * @export
 * @interface InvitesApiExpireInviteInviteDeleteRequest
 */
export interface InvitesApiExpireInviteInviteDeleteRequest {
  /**
   *
   * @type {ExpireInviteParams}
   * @memberof InvitesApiExpireInviteInviteDelete
   */
  readonly expireInviteParams: ExpireInviteParams;
}

/**
 * Request parameters for getListInvitesInvitesGet operation in InvitesApi.
 * @export
 * @interface InvitesApiGetListInvitesInvitesGetRequest
 */
export interface InvitesApiGetListInvitesInvitesGetRequest {
  /**
   *
   * @type {boolean}
   * @memberof InvitesApiGetListInvitesInvitesGet
   */
  readonly showExpired?: boolean;
}

/**
 * Request parameters for validateInviteInviteValidateGet operation in InvitesApi.
 * @export
 * @interface InvitesApiValidateInviteInviteValidateGetRequest
 */
export interface InvitesApiValidateInviteInviteValidateGetRequest {
  /**
   *
   * @type {string}
   * @memberof InvitesApiValidateInviteInviteValidateGet
   */
  readonly inviteLink: string;
}

/**
 * InvitesApi - object-oriented interface
 * @export
 * @class InvitesApi
 * @extends {BaseAPI}
 */
export class InvitesApi extends BaseAPI {
  /**
   * Create Invite Link
   * @summary Create Invite
   * @param {InvitesApiCreateInviteInvitePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvitesApi
   */
  public createInviteInvitePost(
    requestParameters: InvitesApiCreateInviteInvitePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return InvitesApiFp(this.configuration)
      .createInviteInvitePost(requestParameters.createInviteParams, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Expire Invite Link
   * @summary Expire Invite
   * @param {InvitesApiExpireInviteInviteDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvitesApi
   */
  public expireInviteInviteDelete(
    requestParameters: InvitesApiExpireInviteInviteDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return InvitesApiFp(this.configuration)
      .expireInviteInviteDelete(requestParameters.expireInviteParams, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List Invite Links
   * @summary Get List Invites
   * @param {InvitesApiGetListInvitesInvitesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvitesApi
   */
  public getListInvitesInvitesGet(
    requestParameters: InvitesApiGetListInvitesInvitesGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return InvitesApiFp(this.configuration)
      .getListInvitesInvitesGet(requestParameters.showExpired, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List Invite Links
   * @summary Validate Invite
   * @param {InvitesApiValidateInviteInviteValidateGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvitesApi
   */
  public validateInviteInviteValidateGet(
    requestParameters: InvitesApiValidateInviteInviteValidateGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return InvitesApiFp(this.configuration)
      .validateInviteInviteValidateGet(requestParameters.inviteLink, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Cancel Job
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelJobJobsJobUidCancelPost: async (
      jobUid: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobUid' is not null or undefined
      assertParamExists('cancelJobJobsJobUidCancelPost', 'jobUid', jobUid);
      const localVarPath = `/jobs/{job_uid}/cancel`.replace(
        `{${'job_uid'}}`,
        encodeURIComponent(String(jobUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Job
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteJobJobsJobUidDeletePost: async (
      jobUid: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobUid' is not null or undefined
      assertParamExists('deleteJobJobsJobUidDeletePost', 'jobUid', jobUid);
      const localVarPath = `/jobs/{job_uid}/delete`.replace(
        `{${'job_uid'}}`,
        encodeURIComponent(String(jobUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Job For Uid
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobForUidJobsJobUidGet: async (
      jobUid: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobUid' is not null or undefined
      assertParamExists('getJobForUidJobsJobUidGet', 'jobUid', jobUid);
      const localVarPath = `/jobs/{job_uid}`.replace(
        `{${'job_uid'}}`,
        encodeURIComponent(String(jobUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Jobs
     * @param {JobType} [jobType]
     * @param {number} [datasetId]
     * @param {number} [taskId]
     * @param {number} [workspaceId]
     * @param {JobState} [state]
     * @param {number} [startTime]
     * @param {string} [direction]
     * @param {number} [limit]
     * @param {boolean} [details]
     * @param {number} [userUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listJobsJobsGet: async (
      jobType?: JobType,
      datasetId?: number,
      taskId?: number,
      workspaceId?: number,
      state?: JobState,
      startTime?: number,
      direction?: string,
      limit?: number,
      details?: boolean,
      userUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/jobs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (jobType !== undefined) {
        localVarQueryParameter['job_type'] = jobType;
      }

      if (datasetId !== undefined) {
        localVarQueryParameter['dataset_id'] = datasetId;
      }

      if (taskId !== undefined) {
        localVarQueryParameter['task_id'] = taskId;
      }

      if (workspaceId !== undefined) {
        localVarQueryParameter['workspace_id'] = workspaceId;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      if (startTime !== undefined) {
        localVarQueryParameter['start_time'] = startTime;
      }

      if (direction !== undefined) {
        localVarQueryParameter['direction'] = direction;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (details !== undefined) {
        localVarQueryParameter['details'] = details;
      }

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Cancel Job
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelJobJobsJobUidCancelPost(
      jobUid: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelJobJobsJobUidCancelPost(
          jobUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['JobsApi.cancelJobJobsJobUidCancelPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Job
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteJobJobsJobUidDeletePost(
      jobUid: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteJobJobsJobUidDeletePost(
          jobUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['JobsApi.deleteJobJobsJobUidDeletePost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Job For Uid
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJobForUidJobsJobUidGet(
      jobUid: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getJobForUidJobsJobUidGet(
          jobUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['JobsApi.getJobForUidJobsJobUidGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Jobs
     * @param {JobType} [jobType]
     * @param {number} [datasetId]
     * @param {number} [taskId]
     * @param {number} [workspaceId]
     * @param {JobState} [state]
     * @param {number} [startTime]
     * @param {string} [direction]
     * @param {number} [limit]
     * @param {boolean} [details]
     * @param {number} [userUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listJobsJobsGet(
      jobType?: JobType,
      datasetId?: number,
      taskId?: number,
      workspaceId?: number,
      state?: JobState,
      startTime?: number,
      direction?: string,
      limit?: number,
      details?: boolean,
      userUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JobListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listJobsJobsGet(
        jobType,
        datasetId,
        taskId,
        workspaceId,
        state,
        startTime,
        direction,
        limit,
        details,
        userUid,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['JobsApi.listJobsJobsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = JobsApiFp(configuration);
  return {
    /**
     *
     * @summary Cancel Job
     * @param {JobsApiCancelJobJobsJobUidCancelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelJobJobsJobUidCancelPost(
      requestParameters: JobsApiCancelJobJobsJobUidCancelPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .cancelJobJobsJobUidCancelPost(requestParameters.jobUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Job
     * @param {JobsApiDeleteJobJobsJobUidDeletePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteJobJobsJobUidDeletePost(
      requestParameters: JobsApiDeleteJobJobsJobUidDeletePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteJobJobsJobUidDeletePost(requestParameters.jobUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Job For Uid
     * @param {JobsApiGetJobForUidJobsJobUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobForUidJobsJobUidGet(
      requestParameters: JobsApiGetJobForUidJobsJobUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<JobInfo> {
      return localVarFp
        .getJobForUidJobsJobUidGet(requestParameters.jobUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Jobs
     * @param {JobsApiListJobsJobsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listJobsJobsGet(
      requestParameters: JobsApiListJobsJobsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<JobListResponse> {
      return localVarFp
        .listJobsJobsGet(
          requestParameters.jobType,
          requestParameters.datasetId,
          requestParameters.taskId,
          requestParameters.workspaceId,
          requestParameters.state,
          requestParameters.startTime,
          requestParameters.direction,
          requestParameters.limit,
          requestParameters.details,
          requestParameters.userUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelJobJobsJobUidCancelPost operation in JobsApi.
 * @export
 * @interface JobsApiCancelJobJobsJobUidCancelPostRequest
 */
export interface JobsApiCancelJobJobsJobUidCancelPostRequest {
  /**
   *
   * @type {string}
   * @memberof JobsApiCancelJobJobsJobUidCancelPost
   */
  readonly jobUid: string;
}

/**
 * Request parameters for deleteJobJobsJobUidDeletePost operation in JobsApi.
 * @export
 * @interface JobsApiDeleteJobJobsJobUidDeletePostRequest
 */
export interface JobsApiDeleteJobJobsJobUidDeletePostRequest {
  /**
   *
   * @type {string}
   * @memberof JobsApiDeleteJobJobsJobUidDeletePost
   */
  readonly jobUid: string;
}

/**
 * Request parameters for getJobForUidJobsJobUidGet operation in JobsApi.
 * @export
 * @interface JobsApiGetJobForUidJobsJobUidGetRequest
 */
export interface JobsApiGetJobForUidJobsJobUidGetRequest {
  /**
   *
   * @type {string}
   * @memberof JobsApiGetJobForUidJobsJobUidGet
   */
  readonly jobUid: string;
}

/**
 * Request parameters for listJobsJobsGet operation in JobsApi.
 * @export
 * @interface JobsApiListJobsJobsGetRequest
 */
export interface JobsApiListJobsJobsGetRequest {
  /**
   *
   * @type {JobType}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly jobType?: JobType;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly datasetId?: number;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly taskId?: number;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly workspaceId?: number;

  /**
   *
   * @type {JobState}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly state?: JobState;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly startTime?: number;

  /**
   *
   * @type {string}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly direction?: string;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly limit?: number;

  /**
   *
   * @type {boolean}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly details?: boolean;

  /**
   *
   * @type {number}
   * @memberof JobsApiListJobsJobsGet
   */
  readonly userUid?: number;
}

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
  /**
   *
   * @summary Cancel Job
   * @param {JobsApiCancelJobJobsJobUidCancelPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public cancelJobJobsJobUidCancelPost(
    requestParameters: JobsApiCancelJobJobsJobUidCancelPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .cancelJobJobsJobUidCancelPost(requestParameters.jobUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Job
   * @param {JobsApiDeleteJobJobsJobUidDeletePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public deleteJobJobsJobUidDeletePost(
    requestParameters: JobsApiDeleteJobJobsJobUidDeletePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .deleteJobJobsJobUidDeletePost(requestParameters.jobUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Job For Uid
   * @param {JobsApiGetJobForUidJobsJobUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public getJobForUidJobsJobUidGet(
    requestParameters: JobsApiGetJobForUidJobsJobUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .getJobForUidJobsJobUidGet(requestParameters.jobUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Jobs
   * @param {JobsApiListJobsJobsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public listJobsJobsGet(
    requestParameters: JobsApiListJobsJobsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return JobsApiFp(this.configuration)
      .listJobsJobsGet(
        requestParameters.jobType,
        requestParameters.datasetId,
        requestParameters.taskId,
        requestParameters.workspaceId,
        requestParameters.state,
        requestParameters.startTime,
        requestParameters.direction,
        requestParameters.limit,
        requestParameters.details,
        requestParameters.userUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LabelMatricesApi - axios parameter creator
 * @export
 */
export const LabelMatricesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Fetch L matrix from database.
     * @summary Get Label Matrix
     * @param {number} nodeUid
     * @param {number} lfPackageUid
     * @param {string} split
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelMatrixNodesNodeUidLabelMatricesGet: async (
      nodeUid: number,
      lfPackageUid: number,
      split: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getLabelMatrixNodesNodeUidLabelMatricesGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lfPackageUid' is not null or undefined
      assertParamExists(
        'getLabelMatrixNodesNodeUidLabelMatricesGet',
        'lfPackageUid',
        lfPackageUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getLabelMatrixNodesNodeUidLabelMatricesGet',
        'split',
        split,
      );
      const localVarPath = `/nodes/{node_uid}/label-matrices`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lfPackageUid !== undefined) {
        localVarQueryParameter['lf_package_uid'] = lfPackageUid;
      }

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LabelMatricesApi - functional programming interface
 * @export
 */
export const LabelMatricesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LabelMatricesApiAxiosParamCreator(configuration);
  return {
    /**
     * Fetch L matrix from database.
     * @summary Get Label Matrix
     * @param {number} nodeUid
     * @param {number} lfPackageUid
     * @param {string} split
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLabelMatrixNodesNodeUidLabelMatricesGet(
      nodeUid: number,
      lfPackageUid: number,
      split: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLabelMatrixNodesNodeUidLabelMatricesGet(
          nodeUid,
          lfPackageUid,
          split,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LabelMatricesApi.getLabelMatrixNodesNodeUidLabelMatricesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LabelMatricesApi - factory interface
 * @export
 */
export const LabelMatricesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LabelMatricesApiFp(configuration);
  return {
    /**
     * Fetch L matrix from database.
     * @summary Get Label Matrix
     * @param {LabelMatricesApiGetLabelMatrixNodesNodeUidLabelMatricesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelMatrixNodesNodeUidLabelMatricesGet(
      requestParameters: LabelMatricesApiGetLabelMatrixNodesNodeUidLabelMatricesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getLabelMatrixNodesNodeUidLabelMatricesGet(
          requestParameters.nodeUid,
          requestParameters.lfPackageUid,
          requestParameters.split,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getLabelMatrixNodesNodeUidLabelMatricesGet operation in LabelMatricesApi.
 * @export
 * @interface LabelMatricesApiGetLabelMatrixNodesNodeUidLabelMatricesGetRequest
 */
export interface LabelMatricesApiGetLabelMatrixNodesNodeUidLabelMatricesGetRequest {
  /**
   *
   * @type {number}
   * @memberof LabelMatricesApiGetLabelMatrixNodesNodeUidLabelMatricesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LabelMatricesApiGetLabelMatrixNodesNodeUidLabelMatricesGet
   */
  readonly lfPackageUid: number;

  /**
   *
   * @type {string}
   * @memberof LabelMatricesApiGetLabelMatrixNodesNodeUidLabelMatricesGet
   */
  readonly split: string;
}

/**
 * LabelMatricesApi - object-oriented interface
 * @export
 * @class LabelMatricesApi
 * @extends {BaseAPI}
 */
export class LabelMatricesApi extends BaseAPI {
  /**
   * Fetch L matrix from database.
   * @summary Get Label Matrix
   * @param {LabelMatricesApiGetLabelMatrixNodesNodeUidLabelMatricesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelMatricesApi
   */
  public getLabelMatrixNodesNodeUidLabelMatricesGet(
    requestParameters: LabelMatricesApiGetLabelMatrixNodesNodeUidLabelMatricesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LabelMatricesApiFp(this.configuration)
      .getLabelMatrixNodesNodeUidLabelMatricesGet(
        requestParameters.nodeUid,
        requestParameters.lfPackageUid,
        requestParameters.split,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LabelSchemasApi - axios parameter creator
 * @export
 */
export const LabelSchemasApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Copy Label Schema
     * @param {number} labelSchemaUid
     * @param {CopyLabelSchemaPayload} copyLabelSchemaPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyLabelSchemaLabelSchemasLabelSchemaUidPost: async (
      labelSchemaUid: number,
      copyLabelSchemaPayload: CopyLabelSchemaPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'labelSchemaUid' is not null or undefined
      assertParamExists(
        'copyLabelSchemaLabelSchemasLabelSchemaUidPost',
        'labelSchemaUid',
        labelSchemaUid,
      );
      // verify required parameter 'copyLabelSchemaPayload' is not null or undefined
      assertParamExists(
        'copyLabelSchemaLabelSchemasLabelSchemaUidPost',
        'copyLabelSchemaPayload',
        copyLabelSchemaPayload,
      );
      const localVarPath = `/label-schemas/{label_schema_uid}`.replace(
        `{${'label_schema_uid'}}`,
        encodeURIComponent(String(labelSchemaUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        copyLabelSchemaPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add label schema to dim_label_schema table.
     * @summary Create Label Schema
     * @param {CreateLabelSchemaPayload} createLabelSchemaPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLabelSchemaLabelSchemasPost: async (
      createLabelSchemaPayload: CreateLabelSchemaPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createLabelSchemaPayload' is not null or undefined
      assertParamExists(
        'createLabelSchemaLabelSchemasPost',
        'createLabelSchemaPayload',
        createLabelSchemaPayload,
      );
      const localVarPath = `/label-schemas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLabelSchemaPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete label_schemas and corresponding tables.
     * @summary Remove Label Schema
     * @param {number} labelSchemaUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLabelSchema: async (
      labelSchemaUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'labelSchemaUid' is not null or undefined
      assertParamExists('deleteLabelSchema', 'labelSchemaUid', labelSchemaUid);
      const localVarPath = `/label-schemas/{label_schema_uid}`.replace(
        `{${'label_schema_uid'}}`,
        encodeURIComponent(String(labelSchemaUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List all label schemas by batch
     * @summary List Label Schemas By Batch
     * @param {number} datasetBatchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGet: async (
      datasetBatchUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetBatchUid' is not null or undefined
      assertParamExists(
        'listLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGet',
        'datasetBatchUid',
        datasetBatchUid,
      );
      const localVarPath = `/batch/{dataset_batch_uid}/label-schemas`.replace(
        `{${'dataset_batch_uid'}}`,
        encodeURIComponent(String(datasetBatchUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List all label schemas by name/label_schema_uid and/or dataset_uid.
     * @summary List Label Schemas
     * @param {string} [name]
     * @param {number} [labelSchemaUid]
     * @param {number} [datasetUid]
     * @param {boolean} [includeMetadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listLabelSchemasLabelSchemasGet: async (
      name?: string,
      labelSchemaUid?: number,
      datasetUid?: number,
      includeMetadata?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/label-schemas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (labelSchemaUid !== undefined) {
        localVarQueryParameter['label_schema_uid'] = labelSchemaUid;
      }

      if (datasetUid !== undefined) {
        localVarQueryParameter['dataset_uid'] = datasetUid;
      }

      if (includeMetadata !== undefined) {
        localVarQueryParameter['include_metadata'] = includeMetadata;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Label Schema
     * @param {number} labelSchemaUid
     * @param {UpdateLabelSchemaPayload} updateLabelSchemaPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabelSchemaLabelSchemasLabelSchemaUidPut: async (
      labelSchemaUid: number,
      updateLabelSchemaPayload: UpdateLabelSchemaPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'labelSchemaUid' is not null or undefined
      assertParamExists(
        'updateLabelSchemaLabelSchemasLabelSchemaUidPut',
        'labelSchemaUid',
        labelSchemaUid,
      );
      // verify required parameter 'updateLabelSchemaPayload' is not null or undefined
      assertParamExists(
        'updateLabelSchemaLabelSchemasLabelSchemaUidPut',
        'updateLabelSchemaPayload',
        updateLabelSchemaPayload,
      );
      const localVarPath = `/label-schemas/{label_schema_uid}`.replace(
        `{${'label_schema_uid'}}`,
        encodeURIComponent(String(labelSchemaUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateLabelSchemaPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LabelSchemasApi - functional programming interface
 * @export
 */
export const LabelSchemasApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LabelSchemasApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Copy Label Schema
     * @param {number} labelSchemaUid
     * @param {CopyLabelSchemaPayload} copyLabelSchemaPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async copyLabelSchemaLabelSchemasLabelSchemaUidPost(
      labelSchemaUid: number,
      copyLabelSchemaPayload: CopyLabelSchemaPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.copyLabelSchemaLabelSchemasLabelSchemaUidPost(
          labelSchemaUid,
          copyLabelSchemaPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LabelSchemasApi.copyLabelSchemaLabelSchemasLabelSchemaUidPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Add label schema to dim_label_schema table.
     * @summary Create Label Schema
     * @param {CreateLabelSchemaPayload} createLabelSchemaPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createLabelSchemaLabelSchemasPost(
      createLabelSchemaPayload: CreateLabelSchemaPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateLabelSchemaResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createLabelSchemaLabelSchemasPost(
          createLabelSchemaPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LabelSchemasApi.createLabelSchemaLabelSchemasPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete label_schemas and corresponding tables.
     * @summary Remove Label Schema
     * @param {number} labelSchemaUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteLabelSchema(
      labelSchemaUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteLabelSchema(
          labelSchemaUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LabelSchemasApi.deleteLabelSchema']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List all label schemas by batch
     * @summary List Label Schemas By Batch
     * @param {number} datasetBatchUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGet(
      datasetBatchUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LabelSchema>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGet(
          datasetBatchUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LabelSchemasApi.listLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List all label schemas by name/label_schema_uid and/or dataset_uid.
     * @summary List Label Schemas
     * @param {string} [name]
     * @param {number} [labelSchemaUid]
     * @param {number} [datasetUid]
     * @param {boolean} [includeMetadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listLabelSchemasLabelSchemasGet(
      name?: string,
      labelSchemaUid?: number,
      datasetUid?: number,
      includeMetadata?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LabelSchema>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listLabelSchemasLabelSchemasGet(
          name,
          labelSchemaUid,
          datasetUid,
          includeMetadata,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LabelSchemasApi.listLabelSchemasLabelSchemasGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Label Schema
     * @param {number} labelSchemaUid
     * @param {UpdateLabelSchemaPayload} updateLabelSchemaPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLabelSchemaLabelSchemasLabelSchemaUidPut(
      labelSchemaUid: number,
      updateLabelSchemaPayload: UpdateLabelSchemaPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabelSchema>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateLabelSchemaLabelSchemasLabelSchemaUidPut(
          labelSchemaUid,
          updateLabelSchemaPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LabelSchemasApi.updateLabelSchemaLabelSchemasLabelSchemaUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LabelSchemasApi - factory interface
 * @export
 */
export const LabelSchemasApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LabelSchemasApiFp(configuration);
  return {
    /**
     *
     * @summary Copy Label Schema
     * @param {LabelSchemasApiCopyLabelSchemaLabelSchemasLabelSchemaUidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyLabelSchemaLabelSchemasLabelSchemaUidPost(
      requestParameters: LabelSchemasApiCopyLabelSchemaLabelSchemasLabelSchemaUidPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<string> {
      return localVarFp
        .copyLabelSchemaLabelSchemasLabelSchemaUidPost(
          requestParameters.labelSchemaUid,
          requestParameters.copyLabelSchemaPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Add label schema to dim_label_schema table.
     * @summary Create Label Schema
     * @param {LabelSchemasApiCreateLabelSchemaLabelSchemasPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLabelSchemaLabelSchemasPost(
      requestParameters: LabelSchemasApiCreateLabelSchemaLabelSchemasPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateLabelSchemaResponse> {
      return localVarFp
        .createLabelSchemaLabelSchemasPost(
          requestParameters.createLabelSchemaPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Delete label_schemas and corresponding tables.
     * @summary Remove Label Schema
     * @param {LabelSchemasApiDeleteLabelSchemaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLabelSchema(
      requestParameters: LabelSchemasApiDeleteLabelSchemaRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteLabelSchema(requestParameters.labelSchemaUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     * List all label schemas by batch
     * @summary List Label Schemas By Batch
     * @param {LabelSchemasApiListLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGet(
      requestParameters: LabelSchemasApiListLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<LabelSchema>> {
      return localVarFp
        .listLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGet(
          requestParameters.datasetBatchUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List all label schemas by name/label_schema_uid and/or dataset_uid.
     * @summary List Label Schemas
     * @param {LabelSchemasApiListLabelSchemasLabelSchemasGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listLabelSchemasLabelSchemasGet(
      requestParameters: LabelSchemasApiListLabelSchemasLabelSchemasGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<LabelSchema>> {
      return localVarFp
        .listLabelSchemasLabelSchemasGet(
          requestParameters.name,
          requestParameters.labelSchemaUid,
          requestParameters.datasetUid,
          requestParameters.includeMetadata,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Label Schema
     * @param {LabelSchemasApiUpdateLabelSchemaLabelSchemasLabelSchemaUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabelSchemaLabelSchemasLabelSchemaUidPut(
      requestParameters: LabelSchemasApiUpdateLabelSchemaLabelSchemasLabelSchemaUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<LabelSchema> {
      return localVarFp
        .updateLabelSchemaLabelSchemasLabelSchemaUidPut(
          requestParameters.labelSchemaUid,
          requestParameters.updateLabelSchemaPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for copyLabelSchemaLabelSchemasLabelSchemaUidPost operation in LabelSchemasApi.
 * @export
 * @interface LabelSchemasApiCopyLabelSchemaLabelSchemasLabelSchemaUidPostRequest
 */
export interface LabelSchemasApiCopyLabelSchemaLabelSchemasLabelSchemaUidPostRequest {
  /**
   *
   * @type {number}
   * @memberof LabelSchemasApiCopyLabelSchemaLabelSchemasLabelSchemaUidPost
   */
  readonly labelSchemaUid: number;

  /**
   *
   * @type {CopyLabelSchemaPayload}
   * @memberof LabelSchemasApiCopyLabelSchemaLabelSchemasLabelSchemaUidPost
   */
  readonly copyLabelSchemaPayload: CopyLabelSchemaPayload;
}

/**
 * Request parameters for createLabelSchemaLabelSchemasPost operation in LabelSchemasApi.
 * @export
 * @interface LabelSchemasApiCreateLabelSchemaLabelSchemasPostRequest
 */
export interface LabelSchemasApiCreateLabelSchemaLabelSchemasPostRequest {
  /**
   *
   * @type {CreateLabelSchemaPayload}
   * @memberof LabelSchemasApiCreateLabelSchemaLabelSchemasPost
   */
  readonly createLabelSchemaPayload: CreateLabelSchemaPayload;
}

/**
 * Request parameters for deleteLabelSchema operation in LabelSchemasApi.
 * @export
 * @interface LabelSchemasApiDeleteLabelSchemaRequest
 */
export interface LabelSchemasApiDeleteLabelSchemaRequest {
  /**
   *
   * @type {number}
   * @memberof LabelSchemasApiDeleteLabelSchema
   */
  readonly labelSchemaUid: number;
}

/**
 * Request parameters for listLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGet operation in LabelSchemasApi.
 * @export
 * @interface LabelSchemasApiListLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGetRequest
 */
export interface LabelSchemasApiListLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGetRequest {
  /**
   *
   * @type {number}
   * @memberof LabelSchemasApiListLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGet
   */
  readonly datasetBatchUid: number;
}

/**
 * Request parameters for listLabelSchemasLabelSchemasGet operation in LabelSchemasApi.
 * @export
 * @interface LabelSchemasApiListLabelSchemasLabelSchemasGetRequest
 */
export interface LabelSchemasApiListLabelSchemasLabelSchemasGetRequest {
  /**
   *
   * @type {string}
   * @memberof LabelSchemasApiListLabelSchemasLabelSchemasGet
   */
  readonly name?: string;

  /**
   *
   * @type {number}
   * @memberof LabelSchemasApiListLabelSchemasLabelSchemasGet
   */
  readonly labelSchemaUid?: number;

  /**
   *
   * @type {number}
   * @memberof LabelSchemasApiListLabelSchemasLabelSchemasGet
   */
  readonly datasetUid?: number;

  /**
   *
   * @type {boolean}
   * @memberof LabelSchemasApiListLabelSchemasLabelSchemasGet
   */
  readonly includeMetadata?: boolean;
}

/**
 * Request parameters for updateLabelSchemaLabelSchemasLabelSchemaUidPut operation in LabelSchemasApi.
 * @export
 * @interface LabelSchemasApiUpdateLabelSchemaLabelSchemasLabelSchemaUidPutRequest
 */
export interface LabelSchemasApiUpdateLabelSchemaLabelSchemasLabelSchemaUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof LabelSchemasApiUpdateLabelSchemaLabelSchemasLabelSchemaUidPut
   */
  readonly labelSchemaUid: number;

  /**
   *
   * @type {UpdateLabelSchemaPayload}
   * @memberof LabelSchemasApiUpdateLabelSchemaLabelSchemasLabelSchemaUidPut
   */
  readonly updateLabelSchemaPayload: UpdateLabelSchemaPayload;
}

/**
 * LabelSchemasApi - object-oriented interface
 * @export
 * @class LabelSchemasApi
 * @extends {BaseAPI}
 */
export class LabelSchemasApi extends BaseAPI {
  /**
   *
   * @summary Copy Label Schema
   * @param {LabelSchemasApiCopyLabelSchemaLabelSchemasLabelSchemaUidPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelSchemasApi
   */
  public copyLabelSchemaLabelSchemasLabelSchemaUidPost(
    requestParameters: LabelSchemasApiCopyLabelSchemaLabelSchemasLabelSchemaUidPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LabelSchemasApiFp(this.configuration)
      .copyLabelSchemaLabelSchemasLabelSchemaUidPost(
        requestParameters.labelSchemaUid,
        requestParameters.copyLabelSchemaPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Add label schema to dim_label_schema table.
   * @summary Create Label Schema
   * @param {LabelSchemasApiCreateLabelSchemaLabelSchemasPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelSchemasApi
   */
  public createLabelSchemaLabelSchemasPost(
    requestParameters: LabelSchemasApiCreateLabelSchemaLabelSchemasPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LabelSchemasApiFp(this.configuration)
      .createLabelSchemaLabelSchemasPost(
        requestParameters.createLabelSchemaPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete label_schemas and corresponding tables.
   * @summary Remove Label Schema
   * @param {LabelSchemasApiDeleteLabelSchemaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelSchemasApi
   */
  public deleteLabelSchema(
    requestParameters: LabelSchemasApiDeleteLabelSchemaRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LabelSchemasApiFp(this.configuration)
      .deleteLabelSchema(requestParameters.labelSchemaUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List all label schemas by batch
   * @summary List Label Schemas By Batch
   * @param {LabelSchemasApiListLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelSchemasApi
   */
  public listLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGet(
    requestParameters: LabelSchemasApiListLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LabelSchemasApiFp(this.configuration)
      .listLabelSchemasByBatchBatchDatasetBatchUidLabelSchemasGet(
        requestParameters.datasetBatchUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List all label schemas by name/label_schema_uid and/or dataset_uid.
   * @summary List Label Schemas
   * @param {LabelSchemasApiListLabelSchemasLabelSchemasGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelSchemasApi
   */
  public listLabelSchemasLabelSchemasGet(
    requestParameters: LabelSchemasApiListLabelSchemasLabelSchemasGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return LabelSchemasApiFp(this.configuration)
      .listLabelSchemasLabelSchemasGet(
        requestParameters.name,
        requestParameters.labelSchemaUid,
        requestParameters.datasetUid,
        requestParameters.includeMetadata,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Label Schema
   * @param {LabelSchemasApiUpdateLabelSchemaLabelSchemasLabelSchemaUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelSchemasApi
   */
  public updateLabelSchemaLabelSchemasLabelSchemaUidPut(
    requestParameters: LabelSchemasApiUpdateLabelSchemaLabelSchemasLabelSchemaUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LabelSchemasApiFp(this.configuration)
      .updateLabelSchemaLabelSchemasLabelSchemaUidPut(
        requestParameters.labelSchemaUid,
        requestParameters.updateLabelSchemaPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LfPackagesApi - axios parameter creator
 * @export
 */
export const LfPackagesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Add lf package info to database.
     * @summary Add Lf Package
     * @param {number} nodeUid
     * @param {LFPackageModel} lFPackageModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLfPackageNodesNodeUidLfPackagesPost: async (
      nodeUid: number,
      lFPackageModel: LFPackageModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addLfPackageNodesNodeUidLfPackagesPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lFPackageModel' is not null or undefined
      assertParamExists(
        'addLfPackageNodesNodeUidLfPackagesPost',
        'lFPackageModel',
        lFPackageModel,
      );
      const localVarPath = `/nodes/{node_uid}/lf-packages`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        lFPackageModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List overall and per-lf coverage for given task version (default to latest).
     * @summary Fetch Lf Package Data
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet: async (
      nodeUid: number,
      pkgUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'pkgUid' is not null or undefined
      assertParamExists(
        'fetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet',
        'pkgUid',
        pkgUid,
      );
      const localVarPath = `/nodes/{node_uid}/lf-packages/{pkg_uid}/data`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'pkg_uid'}}`, encodeURIComponent(String(pkgUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List overall and per-lf coverage for given task version (default to latest).
     * @summary Fetch Lf Package Lfs
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet: async (
      nodeUid: number,
      pkgUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'pkgUid' is not null or undefined
      assertParamExists(
        'fetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet',
        'pkgUid',
        pkgUid,
      );
      const localVarPath = `/nodes/{node_uid}/lf-packages/{pkg_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'pkg_uid'}}`, encodeURIComponent(String(pkgUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Lf Package Contents
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet: async (
      nodeUid: number,
      pkgUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'pkgUid' is not null or undefined
      assertParamExists(
        'getLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet',
        'pkgUid',
        pkgUid,
      );
      const localVarPath = `/nodes/{node_uid}/lf-packages/{pkg_uid}/package`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'pkg_uid'}}`, encodeURIComponent(String(pkgUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List info for given lf package name: pkg_uid, num_lfs, max datestamp, name, is_active.
     * @summary List Lf Package Info
     * @param {number} nodeUid
     * @param {boolean} [isActive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listLfPackageInfoNodesNodeUidLfPackagesGet: async (
      nodeUid: number,
      isActive?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listLfPackageInfoNodesNodeUidLfPackagesGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/lf-packages`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isActive !== undefined) {
        localVarQueryParameter['is_active'] = isActive;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List overall and per-lf accuracies estimated by label model task version (default to latest).
     * @summary List Package Accuracies Info
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet: async (
      nodeUid: number,
      pkgUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'pkgUid' is not null or undefined
      assertParamExists(
        'listPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet',
        'pkgUid',
        pkgUid,
      );
      const localVarPath = `/nodes/{node_uid}/lf-packages/{pkg_uid}/accuracies`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'pkg_uid'}}`, encodeURIComponent(String(pkgUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List overall and per-lf coverage for given task version (default to latest).
     * @summary List Package Coverage Info
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet: async (
      nodeUid: number,
      pkgUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'pkgUid' is not null or undefined
      assertParamExists(
        'listPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet',
        'pkgUid',
        pkgUid,
      );
      const localVarPath = `/nodes/{node_uid}/lf-packages/{pkg_uid}/coverage`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'pkg_uid'}}`, encodeURIComponent(String(pkgUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List dicts mapping \"slice_label\" to lf name and \"accuracies\" to accuracies over the lf\'s covered slice.
     * @summary List Slice Accuracies
     * @param {number} task
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet: async (
      task: number,
      pkgUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'task' is not null or undefined
      assertParamExists(
        'listSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet',
        'task',
        task,
      );
      // verify required parameter 'pkgUid' is not null or undefined
      assertParamExists(
        'listSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet',
        'pkgUid',
        pkgUid,
      );
      const localVarPath =
        `/nodes/{task}/lf-packages/{pkg_uid}/slice-accuracies`
          .replace(`{${'task'}}`, encodeURIComponent(String(task)))
          .replace(`{${'pkg_uid'}}`, encodeURIComponent(String(pkgUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add lf package info to database.
     * @summary Remove Lf Package
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeLfPackageNodesNodeUidLfPackagesPkgUidDelete: async (
      nodeUid: number,
      pkgUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'removeLfPackageNodesNodeUidLfPackagesPkgUidDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'pkgUid' is not null or undefined
      assertParamExists(
        'removeLfPackageNodesNodeUidLfPackagesPkgUidDelete',
        'pkgUid',
        pkgUid,
      );
      const localVarPath = `/nodes/{node_uid}/lf-packages/{pkg_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'pkg_uid'}}`, encodeURIComponent(String(pkgUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Lf Package
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {UpdateLFPackageParams} updateLFPackageParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLfPackageNodesNodeUidLfPackagesPkgUidPut: async (
      nodeUid: number,
      pkgUid: number,
      updateLFPackageParams: UpdateLFPackageParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'updateLfPackageNodesNodeUidLfPackagesPkgUidPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'pkgUid' is not null or undefined
      assertParamExists(
        'updateLfPackageNodesNodeUidLfPackagesPkgUidPut',
        'pkgUid',
        pkgUid,
      );
      // verify required parameter 'updateLFPackageParams' is not null or undefined
      assertParamExists(
        'updateLfPackageNodesNodeUidLfPackagesPkgUidPut',
        'updateLFPackageParams',
        updateLFPackageParams,
      );
      const localVarPath = `/nodes/{node_uid}/lf-packages/{pkg_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'pkg_uid'}}`, encodeURIComponent(String(pkgUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateLFPackageParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LfPackagesApi - functional programming interface
 * @export
 */
export const LfPackagesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LfPackagesApiAxiosParamCreator(configuration);
  return {
    /**
     * Add lf package info to database.
     * @summary Add Lf Package
     * @param {number} nodeUid
     * @param {LFPackageModel} lFPackageModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addLfPackageNodesNodeUidLfPackagesPost(
      nodeUid: number,
      lFPackageModel: LFPackageModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addLfPackageNodesNodeUidLfPackagesPost(
          nodeUid,
          lFPackageModel,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfPackagesApi.addLfPackageNodesNodeUidLfPackagesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List overall and per-lf coverage for given task version (default to latest).
     * @summary Fetch Lf Package Data
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet(
      nodeUid: number,
      pkgUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet(
          nodeUid,
          pkgUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfPackagesApi.fetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List overall and per-lf coverage for given task version (default to latest).
     * @summary Fetch Lf Package Lfs
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet(
      nodeUid: number,
      pkgUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet(
          nodeUid,
          pkgUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfPackagesApi.fetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Lf Package Contents
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet(
      nodeUid: number,
      pkgUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet(
          nodeUid,
          pkgUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfPackagesApi.getLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List info for given lf package name: pkg_uid, num_lfs, max datestamp, name, is_active.
     * @summary List Lf Package Info
     * @param {number} nodeUid
     * @param {boolean} [isActive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listLfPackageInfoNodesNodeUidLfPackagesGet(
      nodeUid: number,
      isActive?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listLfPackageInfoNodesNodeUidLfPackagesGet(
          nodeUid,
          isActive,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfPackagesApi.listLfPackageInfoNodesNodeUidLfPackagesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List overall and per-lf accuracies estimated by label model task version (default to latest).
     * @summary List Package Accuracies Info
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet(
      nodeUid: number,
      pkgUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet(
          nodeUid,
          pkgUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfPackagesApi.listPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List overall and per-lf coverage for given task version (default to latest).
     * @summary List Package Coverage Info
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet(
      nodeUid: number,
      pkgUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet(
          nodeUid,
          pkgUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfPackagesApi.listPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List dicts mapping \"slice_label\" to lf name and \"accuracies\" to accuracies over the lf\'s covered slice.
     * @summary List Slice Accuracies
     * @param {number} task
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet(
      task: number,
      pkgUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet(
          task,
          pkgUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfPackagesApi.listSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Add lf package info to database.
     * @summary Remove Lf Package
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeLfPackageNodesNodeUidLfPackagesPkgUidDelete(
      nodeUid: number,
      pkgUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeLfPackageNodesNodeUidLfPackagesPkgUidDelete(
          nodeUid,
          pkgUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfPackagesApi.removeLfPackageNodesNodeUidLfPackagesPkgUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Lf Package
     * @param {number} nodeUid
     * @param {number} pkgUid
     * @param {UpdateLFPackageParams} updateLFPackageParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLfPackageNodesNodeUidLfPackagesPkgUidPut(
      nodeUid: number,
      pkgUid: number,
      updateLFPackageParams: UpdateLFPackageParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateLfPackageNodesNodeUidLfPackagesPkgUidPut(
          nodeUid,
          pkgUid,
          updateLFPackageParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfPackagesApi.updateLfPackageNodesNodeUidLfPackagesPkgUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LfPackagesApi - factory interface
 * @export
 */
export const LfPackagesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LfPackagesApiFp(configuration);
  return {
    /**
     * Add lf package info to database.
     * @summary Add Lf Package
     * @param {LfPackagesApiAddLfPackageNodesNodeUidLfPackagesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLfPackageNodesNodeUidLfPackagesPost(
      requestParameters: LfPackagesApiAddLfPackageNodesNodeUidLfPackagesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .addLfPackageNodesNodeUidLfPackagesPost(
          requestParameters.nodeUid,
          requestParameters.lFPackageModel,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List overall and per-lf coverage for given task version (default to latest).
     * @summary Fetch Lf Package Data
     * @param {LfPackagesApiFetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet(
      requestParameters: LfPackagesApiFetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<any>> {
      return localVarFp
        .fetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet(
          requestParameters.nodeUid,
          requestParameters.pkgUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List overall and per-lf coverage for given task version (default to latest).
     * @summary Fetch Lf Package Lfs
     * @param {LfPackagesApiFetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet(
      requestParameters: LfPackagesApiFetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet(
          requestParameters.nodeUid,
          requestParameters.pkgUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Lf Package Contents
     * @param {LfPackagesApiGetLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet(
      requestParameters: LfPackagesApiGetLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet(
          requestParameters.nodeUid,
          requestParameters.pkgUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List info for given lf package name: pkg_uid, num_lfs, max datestamp, name, is_active.
     * @summary List Lf Package Info
     * @param {LfPackagesApiListLfPackageInfoNodesNodeUidLfPackagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listLfPackageInfoNodesNodeUidLfPackagesGet(
      requestParameters: LfPackagesApiListLfPackageInfoNodesNodeUidLfPackagesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .listLfPackageInfoNodesNodeUidLfPackagesGet(
          requestParameters.nodeUid,
          requestParameters.isActive,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List overall and per-lf accuracies estimated by label model task version (default to latest).
     * @summary List Package Accuracies Info
     * @param {LfPackagesApiListPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet(
      requestParameters: LfPackagesApiListPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .listPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet(
          requestParameters.nodeUid,
          requestParameters.pkgUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List overall and per-lf coverage for given task version (default to latest).
     * @summary List Package Coverage Info
     * @param {LfPackagesApiListPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet(
      requestParameters: LfPackagesApiListPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .listPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet(
          requestParameters.nodeUid,
          requestParameters.pkgUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List dicts mapping \"slice_label\" to lf name and \"accuracies\" to accuracies over the lf\'s covered slice.
     * @summary List Slice Accuracies
     * @param {LfPackagesApiListSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet(
      requestParameters: LfPackagesApiListSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .listSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet(
          requestParameters.task,
          requestParameters.pkgUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Add lf package info to database.
     * @summary Remove Lf Package
     * @param {LfPackagesApiRemoveLfPackageNodesNodeUidLfPackagesPkgUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeLfPackageNodesNodeUidLfPackagesPkgUidDelete(
      requestParameters: LfPackagesApiRemoveLfPackageNodesNodeUidLfPackagesPkgUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .removeLfPackageNodesNodeUidLfPackagesPkgUidDelete(
          requestParameters.nodeUid,
          requestParameters.pkgUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Lf Package
     * @param {LfPackagesApiUpdateLfPackageNodesNodeUidLfPackagesPkgUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLfPackageNodesNodeUidLfPackagesPkgUidPut(
      requestParameters: LfPackagesApiUpdateLfPackageNodesNodeUidLfPackagesPkgUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateLfPackageNodesNodeUidLfPackagesPkgUidPut(
          requestParameters.nodeUid,
          requestParameters.pkgUid,
          requestParameters.updateLFPackageParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addLfPackageNodesNodeUidLfPackagesPost operation in LfPackagesApi.
 * @export
 * @interface LfPackagesApiAddLfPackageNodesNodeUidLfPackagesPostRequest
 */
export interface LfPackagesApiAddLfPackageNodesNodeUidLfPackagesPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfPackagesApiAddLfPackageNodesNodeUidLfPackagesPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {LFPackageModel}
   * @memberof LfPackagesApiAddLfPackageNodesNodeUidLfPackagesPost
   */
  readonly lFPackageModel: LFPackageModel;
}

/**
 * Request parameters for fetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet operation in LfPackagesApi.
 * @export
 * @interface LfPackagesApiFetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGetRequest
 */
export interface LfPackagesApiFetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfPackagesApiFetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfPackagesApiFetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet
   */
  readonly pkgUid: number;
}

/**
 * Request parameters for fetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet operation in LfPackagesApi.
 * @export
 * @interface LfPackagesApiFetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGetRequest
 */
export interface LfPackagesApiFetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfPackagesApiFetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfPackagesApiFetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet
   */
  readonly pkgUid: number;
}

/**
 * Request parameters for getLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet operation in LfPackagesApi.
 * @export
 * @interface LfPackagesApiGetLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGetRequest
 */
export interface LfPackagesApiGetLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfPackagesApiGetLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfPackagesApiGetLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet
   */
  readonly pkgUid: number;
}

/**
 * Request parameters for listLfPackageInfoNodesNodeUidLfPackagesGet operation in LfPackagesApi.
 * @export
 * @interface LfPackagesApiListLfPackageInfoNodesNodeUidLfPackagesGetRequest
 */
export interface LfPackagesApiListLfPackageInfoNodesNodeUidLfPackagesGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfPackagesApiListLfPackageInfoNodesNodeUidLfPackagesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof LfPackagesApiListLfPackageInfoNodesNodeUidLfPackagesGet
   */
  readonly isActive?: boolean;
}

/**
 * Request parameters for listPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet operation in LfPackagesApi.
 * @export
 * @interface LfPackagesApiListPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGetRequest
 */
export interface LfPackagesApiListPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfPackagesApiListPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfPackagesApiListPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet
   */
  readonly pkgUid: number;
}

/**
 * Request parameters for listPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet operation in LfPackagesApi.
 * @export
 * @interface LfPackagesApiListPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGetRequest
 */
export interface LfPackagesApiListPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfPackagesApiListPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfPackagesApiListPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet
   */
  readonly pkgUid: number;
}

/**
 * Request parameters for listSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet operation in LfPackagesApi.
 * @export
 * @interface LfPackagesApiListSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGetRequest
 */
export interface LfPackagesApiListSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfPackagesApiListSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet
   */
  readonly task: number;

  /**
   *
   * @type {number}
   * @memberof LfPackagesApiListSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet
   */
  readonly pkgUid: number;
}

/**
 * Request parameters for removeLfPackageNodesNodeUidLfPackagesPkgUidDelete operation in LfPackagesApi.
 * @export
 * @interface LfPackagesApiRemoveLfPackageNodesNodeUidLfPackagesPkgUidDeleteRequest
 */
export interface LfPackagesApiRemoveLfPackageNodesNodeUidLfPackagesPkgUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof LfPackagesApiRemoveLfPackageNodesNodeUidLfPackagesPkgUidDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfPackagesApiRemoveLfPackageNodesNodeUidLfPackagesPkgUidDelete
   */
  readonly pkgUid: number;
}

/**
 * Request parameters for updateLfPackageNodesNodeUidLfPackagesPkgUidPut operation in LfPackagesApi.
 * @export
 * @interface LfPackagesApiUpdateLfPackageNodesNodeUidLfPackagesPkgUidPutRequest
 */
export interface LfPackagesApiUpdateLfPackageNodesNodeUidLfPackagesPkgUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof LfPackagesApiUpdateLfPackageNodesNodeUidLfPackagesPkgUidPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfPackagesApiUpdateLfPackageNodesNodeUidLfPackagesPkgUidPut
   */
  readonly pkgUid: number;

  /**
   *
   * @type {UpdateLFPackageParams}
   * @memberof LfPackagesApiUpdateLfPackageNodesNodeUidLfPackagesPkgUidPut
   */
  readonly updateLFPackageParams: UpdateLFPackageParams;
}

/**
 * LfPackagesApi - object-oriented interface
 * @export
 * @class LfPackagesApi
 * @extends {BaseAPI}
 */
export class LfPackagesApi extends BaseAPI {
  /**
   * Add lf package info to database.
   * @summary Add Lf Package
   * @param {LfPackagesApiAddLfPackageNodesNodeUidLfPackagesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfPackagesApi
   */
  public addLfPackageNodesNodeUidLfPackagesPost(
    requestParameters: LfPackagesApiAddLfPackageNodesNodeUidLfPackagesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfPackagesApiFp(this.configuration)
      .addLfPackageNodesNodeUidLfPackagesPost(
        requestParameters.nodeUid,
        requestParameters.lFPackageModel,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List overall and per-lf coverage for given task version (default to latest).
   * @summary Fetch Lf Package Data
   * @param {LfPackagesApiFetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfPackagesApi
   */
  public fetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet(
    requestParameters: LfPackagesApiFetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfPackagesApiFp(this.configuration)
      .fetchLfPackageDataNodesNodeUidLfPackagesPkgUidDataGet(
        requestParameters.nodeUid,
        requestParameters.pkgUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List overall and per-lf coverage for given task version (default to latest).
   * @summary Fetch Lf Package Lfs
   * @param {LfPackagesApiFetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfPackagesApi
   */
  public fetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet(
    requestParameters: LfPackagesApiFetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfPackagesApiFp(this.configuration)
      .fetchLfPackageLfsNodesNodeUidLfPackagesPkgUidGet(
        requestParameters.nodeUid,
        requestParameters.pkgUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Lf Package Contents
   * @param {LfPackagesApiGetLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfPackagesApi
   */
  public getLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet(
    requestParameters: LfPackagesApiGetLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfPackagesApiFp(this.configuration)
      .getLfPackageContentsNodesNodeUidLfPackagesPkgUidPackageGet(
        requestParameters.nodeUid,
        requestParameters.pkgUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List info for given lf package name: pkg_uid, num_lfs, max datestamp, name, is_active.
   * @summary List Lf Package Info
   * @param {LfPackagesApiListLfPackageInfoNodesNodeUidLfPackagesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfPackagesApi
   */
  public listLfPackageInfoNodesNodeUidLfPackagesGet(
    requestParameters: LfPackagesApiListLfPackageInfoNodesNodeUidLfPackagesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfPackagesApiFp(this.configuration)
      .listLfPackageInfoNodesNodeUidLfPackagesGet(
        requestParameters.nodeUid,
        requestParameters.isActive,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List overall and per-lf accuracies estimated by label model task version (default to latest).
   * @summary List Package Accuracies Info
   * @param {LfPackagesApiListPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfPackagesApi
   */
  public listPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet(
    requestParameters: LfPackagesApiListPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfPackagesApiFp(this.configuration)
      .listPackageAccuraciesInfoNodesNodeUidLfPackagesPkgUidAccuraciesGet(
        requestParameters.nodeUid,
        requestParameters.pkgUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List overall and per-lf coverage for given task version (default to latest).
   * @summary List Package Coverage Info
   * @param {LfPackagesApiListPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfPackagesApi
   */
  public listPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet(
    requestParameters: LfPackagesApiListPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfPackagesApiFp(this.configuration)
      .listPackageCoverageInfoNodesNodeUidLfPackagesPkgUidCoverageGet(
        requestParameters.nodeUid,
        requestParameters.pkgUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List dicts mapping \"slice_label\" to lf name and \"accuracies\" to accuracies over the lf\'s covered slice.
   * @summary List Slice Accuracies
   * @param {LfPackagesApiListSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfPackagesApi
   */
  public listSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet(
    requestParameters: LfPackagesApiListSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfPackagesApiFp(this.configuration)
      .listSliceAccuraciesNodesTaskLfPackagesPkgUidSliceAccuraciesGet(
        requestParameters.task,
        requestParameters.pkgUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Add lf package info to database.
   * @summary Remove Lf Package
   * @param {LfPackagesApiRemoveLfPackageNodesNodeUidLfPackagesPkgUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfPackagesApi
   */
  public removeLfPackageNodesNodeUidLfPackagesPkgUidDelete(
    requestParameters: LfPackagesApiRemoveLfPackageNodesNodeUidLfPackagesPkgUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfPackagesApiFp(this.configuration)
      .removeLfPackageNodesNodeUidLfPackagesPkgUidDelete(
        requestParameters.nodeUid,
        requestParameters.pkgUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Lf Package
   * @param {LfPackagesApiUpdateLfPackageNodesNodeUidLfPackagesPkgUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfPackagesApi
   */
  public updateLfPackageNodesNodeUidLfPackagesPkgUidPut(
    requestParameters: LfPackagesApiUpdateLfPackageNodesNodeUidLfPackagesPkgUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfPackagesApiFp(this.configuration)
      .updateLfPackageNodesNodeUidLfPackagesPkgUidPut(
        requestParameters.nodeUid,
        requestParameters.pkgUid,
        requestParameters.updateLFPackageParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LfsApi - axios parameter creator
 * @export
 */
export const LfsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Lf Labels
     * @param {number} nodeUid
     * @param {LFLabels} lFLabels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLfLabelsNodesNodeUidLfsLabelsPost: async (
      nodeUid: number,
      lFLabels: LFLabels,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addLfLabelsNodesNodeUidLfsLabelsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lFLabels' is not null or undefined
      assertParamExists(
        'addLfLabelsNodesNodeUidLfsLabelsPost',
        'lFLabels',
        lFLabels,
      );
      const localVarPath = `/nodes/{node_uid}/lfs/labels`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        lFLabels,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add LF to database. If this request is coming from Studio the `user_uid` will be set in the POST body (because user identification is dropped from headers at interservice barrier). If this request is coming directly from an end user we will get the user_uid from the Request.state field (it will have been set by the auth middleware)
     * @summary Add Lf
     * @param {number} nodeUid
     * @param {LFConfig} lFConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLfNodesNodeUidLfsPost: async (
      nodeUid: number,
      lFConfig: LFConfig,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('addLfNodesNodeUidLfsPost', 'nodeUid', nodeUid);
      // verify required parameter 'lFConfig' is not null or undefined
      assertParamExists('addLfNodesNodeUidLfsPost', 'lFConfig', lFConfig);
      const localVarPath = `/nodes/{node_uid}/lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        lFConfig,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Adds a new custom LF template and returns its UID
     * @summary Add Lf Template Class
     * @param {LFTemplatePayload} lFTemplatePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLfTemplateClassLfTemplatesPost: async (
      lFTemplatePayload: LFTemplatePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'lFTemplatePayload' is not null or undefined
      assertParamExists(
        'addLfTemplateClassLfTemplatesPost',
        'lFTemplatePayload',
        lFTemplatePayload,
      );
      const localVarPath = `/lf-templates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        lFTemplatePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Archive Active Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveActiveLfNodesNodeUidActiveLfsLfUidDelete: async (
      nodeUid: number,
      lfUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'archiveActiveLfNodesNodeUidActiveLfsLfUidDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists(
        'archiveActiveLfNodesNodeUidActiveLfsLfUidDelete',
        'lfUid',
        lfUid,
      );
      const localVarPath = `/nodes/{node_uid}/active-lfs/{lf_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Archive Active Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveActiveLfsNodesNodeUidActiveLfsDelete: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'archiveActiveLfsNodesNodeUidActiveLfsDelete',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/active-lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Continue Long Running Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {ContinueLongRunningLFPayload} continueLongRunningLFPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    continueLongRunningLfNodesNodeUidLfsLfUidContinuePost: async (
      nodeUid: number,
      lfUid: number,
      continueLongRunningLFPayload: ContinueLongRunningLFPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'continueLongRunningLfNodesNodeUidLfsLfUidContinuePost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists(
        'continueLongRunningLfNodesNodeUidLfsLfUidContinuePost',
        'lfUid',
        lfUid,
      );
      // verify required parameter 'continueLongRunningLFPayload' is not null or undefined
      assertParamExists(
        'continueLongRunningLfNodesNodeUidLfsLfUidContinuePost',
        'continueLongRunningLFPayload',
        continueLongRunningLFPayload,
      );
      const localVarPath = `/nodes/{node_uid}/lfs/{lf_uid}/continue`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        continueLongRunningLFPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * First, filter lfs_dump from from_node_uid task to contain only the LFs that users want to import, then call import_lfs function.  TODO: Allow it to copy non-active LFs too
     * @summary Copy Lf By Name
     * @param {number} nodeUid
     * @param {CopyLFByNameParams} copyLFByNameParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyLfByNameNodesNodeUidImportLfsByNamePost: async (
      nodeUid: number,
      copyLFByNameParams: CopyLFByNameParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'copyLfByNameNodesNodeUidImportLfsByNamePost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'copyLFByNameParams' is not null or undefined
      assertParamExists(
        'copyLfByNameNodesNodeUidImportLfsByNamePost',
        'copyLFByNameParams',
        copyLFByNameParams,
      );
      const localVarPath = `/nodes/{node_uid}/import-lfs-by-name`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        copyLFByNameParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete In Progress Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete: async (
      nodeUid: number,
      lfUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'deleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists(
        'deleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete',
        'lfUid',
        lfUid,
      );
      const localVarPath = `/nodes/{node_uid}/in-progress-lfs/{lf_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLfNodesNodeUidLfsLfUidDelete: async (
      nodeUid: number,
      lfUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'deleteLfNodesNodeUidLfsLfUidDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists('deleteLfNodesNodeUidLfsLfUidDelete', 'lfUid', lfUid);
      const localVarPath = `/nodes/{node_uid}/lfs/{lf_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Lf Template
     * @param {number} templateUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLfTemplateLfTemplatesTemplateUidDelete: async (
      templateUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'templateUid' is not null or undefined
      assertParamExists(
        'deleteLfTemplateLfTemplatesTemplateUidDelete',
        'templateUid',
        templateUid,
      );
      const localVarPath = `/lf-templates/{template_uid}`.replace(
        `{${'template_uid'}}`,
        encodeURIComponent(String(templateUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export Lfs
     * @param {number} nodeUid
     * @param {boolean} [activeOnly]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportLfsNodesNodeUidExportLfsGet: async (
      nodeUid: number,
      activeOnly?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'exportLfsNodesNodeUidExportLfsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/export-lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (activeOnly !== undefined) {
        localVarQueryParameter['active_only'] = activeOnly;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Active Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchActiveLfNodesNodeUidActiveLfsLfUidGet: async (
      nodeUid: number,
      lfUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchActiveLfNodesNodeUidActiveLfsLfUidGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists(
        'fetchActiveLfNodesNodeUidActiveLfsLfUidGet',
        'lfUid',
        lfUid,
      );
      const localVarPath = `/nodes/{node_uid}/active-lfs/{lf_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Active Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchActiveLfsNodesNodeUidActiveLfsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchActiveLfsNodesNodeUidActiveLfsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/active-lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Archived Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchArchivedLfsNodesNodeUidArchivedLfsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchArchivedLfsNodesNodeUidArchivedLfsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/archived-lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch In Progress Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchInProgressLfsNodesNodeUidInProgressLfsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchInProgressLfsNodesNodeUidInProgressLfsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/in-progress-lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Lf Labels
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfLabelsNodesNodeUidLfsLfUidLabelsGet: async (
      nodeUid: number,
      lfUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchLfLabelsNodesNodeUidLfsLfUidLabelsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists(
        'fetchLfLabelsNodesNodeUidLfsLfUidLabelsGet',
        'lfUid',
        lfUid,
      );
      const localVarPath = `/nodes/{node_uid}/lfs/{lf_uid}/labels`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Lfs Labels Get
     * @param {number} nodeUid
     * @param {Array<number>} [lfUid]
     * @param {Array<string>} [xUid]
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfsLabelsGetNodesNodeUidLfLabelsGet: async (
      nodeUid: number,
      lfUid?: Array<number>,
      xUid?: Array<string>,
      split?: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchLfsLabelsGetNodesNodeUidLfLabelsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/lf-labels`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lfUid) {
        localVarQueryParameter['lf_uid'] = lfUid;
      }

      if (xUid) {
        localVarQueryParameter['x_uid'] = xUid;
      }

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Lfs Labels
     * @param {number} nodeUid
     * @param {FetchLFLabels} fetchLFLabels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfsLabelsNodesNodeUidFetchLfLabelsPost: async (
      nodeUid: number,
      fetchLFLabels: FetchLFLabels,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchLfsLabelsNodesNodeUidFetchLfLabelsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'fetchLFLabels' is not null or undefined
      assertParamExists(
        'fetchLfsLabelsNodesNodeUidFetchLfLabelsPost',
        'fetchLFLabels',
        fetchLFLabels,
      );
      const localVarPath = `/nodes/{node_uid}/fetch-lf-labels`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fetchLFLabels,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch an LF by its uid.
     * @summary Get Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfNodesNodeUidLfsLfUidGet: async (
      nodeUid: number,
      lfUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('getLfNodesNodeUidLfsLfUidGet', 'nodeUid', nodeUid);
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists('getLfNodesNodeUidLfsLfUidGet', 'lfUid', lfUid);
      const localVarPath = `/nodes/{node_uid}/lfs/{lf_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch custom LF templates with optional filters on template uid, template name, template type and schema name
     * @summary Get Lf Templates
     * @param {number} [templateUid]
     * @param {string} [templateName]
     * @param {string} [templateType]
     * @param {string} [templateSchemaName]
     * @param {boolean} [includeCode]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfTemplatesLfTemplatesGet: async (
      templateUid?: number,
      templateName?: string,
      templateType?: string,
      templateSchemaName?: string,
      includeCode?: boolean,
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/lf-templates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (templateUid !== undefined) {
        localVarQueryParameter['template_uid'] = templateUid;
      }

      if (templateName !== undefined) {
        localVarQueryParameter['template_name'] = templateName;
      }

      if (templateType !== undefined) {
        localVarQueryParameter['template_type'] = templateType;
      }

      if (templateSchemaName !== undefined) {
        localVarQueryParameter['template_schema_name'] = templateSchemaName;
      }

      if (includeCode !== undefined) {
        localVarQueryParameter['include_code'] = includeCode;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch all (lf_uid, config) tuples in a given task.
     * @summary Get Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfsNodesNodeUidLfsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('getLfsNodesNodeUidLfsGet', 'nodeUid', nodeUid);
      const localVarPath = `/nodes/{node_uid}/lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import Lfs
     * @param {number} nodeUid
     * @param {ImportLFs} importLFs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importLfsNodesNodeUidImportLfsPost: async (
      nodeUid: number,
      importLFs: ImportLFs,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'importLfsNodesNodeUidImportLfsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'importLFs' is not null or undefined
      assertParamExists(
        'importLfsNodesNodeUidImportLfsPost',
        'importLFs',
        importLFs,
      );
      const localVarPath = `/nodes/{node_uid}/import-lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        importLFs,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Lf State
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {UpdatedStateParams} updatedStateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLfStateNodesNodeUidActiveLfsLfUidPut: async (
      nodeUid: number,
      lfUid: number,
      updatedStateParams: UpdatedStateParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'updateLfStateNodesNodeUidActiveLfsLfUidPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'lfUid' is not null or undefined
      assertParamExists(
        'updateLfStateNodesNodeUidActiveLfsLfUidPut',
        'lfUid',
        lfUid,
      );
      // verify required parameter 'updatedStateParams' is not null or undefined
      assertParamExists(
        'updateLfStateNodesNodeUidActiveLfsLfUidPut',
        'updatedStateParams',
        updatedStateParams,
      );
      const localVarPath = `/nodes/{node_uid}/active-lfs/{lf_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'lf_uid'}}`, encodeURIComponent(String(lfUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatedStateParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LfsApi - functional programming interface
 * @export
 */
export const LfsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LfsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Lf Labels
     * @param {number} nodeUid
     * @param {LFLabels} lFLabels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addLfLabelsNodesNodeUidLfsLabelsPost(
      nodeUid: number,
      lFLabels: LFLabels,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addLfLabelsNodesNodeUidLfsLabelsPost(
          nodeUid,
          lFLabels,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.addLfLabelsNodesNodeUidLfsLabelsPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Add LF to database. If this request is coming from Studio the `user_uid` will be set in the POST body (because user identification is dropped from headers at interservice barrier). If this request is coming directly from an end user we will get the user_uid from the Request.state field (it will have been set by the auth middleware)
     * @summary Add Lf
     * @param {number} nodeUid
     * @param {LFConfig} lFConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addLfNodesNodeUidLfsPost(
      nodeUid: number,
      lFConfig: LFConfig,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addLfNodesNodeUidLfsPost(
          nodeUid,
          lFConfig,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.addLfNodesNodeUidLfsPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Adds a new custom LF template and returns its UID
     * @summary Add Lf Template Class
     * @param {LFTemplatePayload} lFTemplatePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addLfTemplateClassLfTemplatesPost(
      lFTemplatePayload: LFTemplatePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateLFTemplateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addLfTemplateClassLfTemplatesPost(
          lFTemplatePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.addLfTemplateClassLfTemplatesPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Archive Active Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveActiveLfNodesNodeUidActiveLfsLfUidDelete(
      nodeUid: number,
      lfUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.archiveActiveLfNodesNodeUidActiveLfsLfUidDelete(
          nodeUid,
          lfUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.archiveActiveLfNodesNodeUidActiveLfsLfUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Archive Active Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveActiveLfsNodesNodeUidActiveLfsDelete(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.archiveActiveLfsNodesNodeUidActiveLfsDelete(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.archiveActiveLfsNodesNodeUidActiveLfsDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Continue Long Running Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {ContinueLongRunningLFPayload} continueLongRunningLFPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async continueLongRunningLfNodesNodeUidLfsLfUidContinuePost(
      nodeUid: number,
      lfUid: number,
      continueLongRunningLFPayload: ContinueLongRunningLFPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ContinueLongRunningLFResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.continueLongRunningLfNodesNodeUidLfsLfUidContinuePost(
          nodeUid,
          lfUid,
          continueLongRunningLFPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.continueLongRunningLfNodesNodeUidLfsLfUidContinuePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * First, filter lfs_dump from from_node_uid task to contain only the LFs that users want to import, then call import_lfs function.  TODO: Allow it to copy non-active LFs too
     * @summary Copy Lf By Name
     * @param {number} nodeUid
     * @param {CopyLFByNameParams} copyLFByNameParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async copyLfByNameNodesNodeUidImportLfsByNamePost(
      nodeUid: number,
      copyLFByNameParams: CopyLFByNameParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.copyLfByNameNodesNodeUidImportLfsByNamePost(
          nodeUid,
          copyLFByNameParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.copyLfByNameNodesNodeUidImportLfsByNamePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete In Progress Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete(
      nodeUid: number,
      lfUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete(
          nodeUid,
          lfUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.deleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteLfNodesNodeUidLfsLfUidDelete(
      nodeUid: number,
      lfUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteLfNodesNodeUidLfsLfUidDelete(
          nodeUid,
          lfUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.deleteLfNodesNodeUidLfsLfUidDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Lf Template
     * @param {number} templateUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteLfTemplateLfTemplatesTemplateUidDelete(
      templateUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteLfTemplateLfTemplatesTemplateUidDelete(
          templateUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.deleteLfTemplateLfTemplatesTemplateUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Export Lfs
     * @param {number} nodeUid
     * @param {boolean} [activeOnly]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportLfsNodesNodeUidExportLfsGet(
      nodeUid: number,
      activeOnly?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportLfsNodesNodeUidExportLfsGet(
          nodeUid,
          activeOnly,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.exportLfsNodesNodeUidExportLfsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Active Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchActiveLfNodesNodeUidActiveLfsLfUidGet(
      nodeUid: number,
      lfUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchActiveLfNodesNodeUidActiveLfsLfUidGet(
          nodeUid,
          lfUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.fetchActiveLfNodesNodeUidActiveLfsLfUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Active Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchActiveLfsNodesNodeUidActiveLfsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchActiveLfsNodesNodeUidActiveLfsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.fetchActiveLfsNodesNodeUidActiveLfsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Archived Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchArchivedLfsNodesNodeUidArchivedLfsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchArchivedLfsNodesNodeUidArchivedLfsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.fetchArchivedLfsNodesNodeUidArchivedLfsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch In Progress Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchInProgressLfsNodesNodeUidInProgressLfsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchInProgressLfsNodesNodeUidInProgressLfsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.fetchInProgressLfsNodesNodeUidInProgressLfsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Lf Labels
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchLfLabelsNodesNodeUidLfsLfUidLabelsGet(
      nodeUid: number,
      lfUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchLfLabelsNodesNodeUidLfsLfUidLabelsGet(
          nodeUid,
          lfUid,
          split,
          startDate,
          endDate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.fetchLfLabelsNodesNodeUidLfsLfUidLabelsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Lfs Labels Get
     * @param {number} nodeUid
     * @param {Array<number>} [lfUid]
     * @param {Array<string>} [xUid]
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchLfsLabelsGetNodesNodeUidLfLabelsGet(
      nodeUid: number,
      lfUid?: Array<number>,
      xUid?: Array<string>,
      split?: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchLfsLabelsGetNodesNodeUidLfLabelsGet(
          nodeUid,
          lfUid,
          xUid,
          split,
          startDate,
          endDate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.fetchLfsLabelsGetNodesNodeUidLfLabelsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Lfs Labels
     * @param {number} nodeUid
     * @param {FetchLFLabels} fetchLFLabels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchLfsLabelsNodesNodeUidFetchLfLabelsPost(
      nodeUid: number,
      fetchLFLabels: FetchLFLabels,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchLfsLabelsNodesNodeUidFetchLfLabelsPost(
          nodeUid,
          fetchLFLabels,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.fetchLfsLabelsNodesNodeUidFetchLfLabelsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch an LF by its uid.
     * @summary Get Lf
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLfNodesNodeUidLfsLfUidGet(
      nodeUid: number,
      lfUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLfNodesNodeUidLfsLfUidGet(
          nodeUid,
          lfUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.getLfNodesNodeUidLfsLfUidGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch custom LF templates with optional filters on template uid, template name, template type and schema name
     * @summary Get Lf Templates
     * @param {number} [templateUid]
     * @param {string} [templateName]
     * @param {string} [templateType]
     * @param {string} [templateSchemaName]
     * @param {boolean} [includeCode]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLfTemplatesLfTemplatesGet(
      templateUid?: number,
      templateName?: string,
      templateType?: string,
      templateSchemaName?: string,
      includeCode?: boolean,
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetLFTemplatesResponse>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLfTemplatesLfTemplatesGet(
          templateUid,
          templateName,
          templateType,
          templateSchemaName,
          includeCode,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.getLfTemplatesLfTemplatesGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch all (lf_uid, config) tuples in a given task.
     * @summary Get Lfs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLfsNodesNodeUidLfsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLfsNodesNodeUidLfsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.getLfsNodesNodeUidLfsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Import Lfs
     * @param {number} nodeUid
     * @param {ImportLFs} importLFs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importLfsNodesNodeUidImportLfsPost(
      nodeUid: number,
      importLFs: ImportLFs,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.importLfsNodesNodeUidImportLfsPost(
          nodeUid,
          importLFs,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LfsApi.importLfsNodesNodeUidImportLfsPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Lf State
     * @param {number} nodeUid
     * @param {number} lfUid
     * @param {UpdatedStateParams} updatedStateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLfStateNodesNodeUidActiveLfsLfUidPut(
      nodeUid: number,
      lfUid: number,
      updatedStateParams: UpdatedStateParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateLfStateNodesNodeUidActiveLfsLfUidPut(
          nodeUid,
          lfUid,
          updatedStateParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LfsApi.updateLfStateNodesNodeUidActiveLfsLfUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LfsApi - factory interface
 * @export
 */
export const LfsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LfsApiFp(configuration);
  return {
    /**
     *
     * @summary Add Lf Labels
     * @param {LfsApiAddLfLabelsNodesNodeUidLfsLabelsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLfLabelsNodesNodeUidLfsLabelsPost(
      requestParameters: LfsApiAddLfLabelsNodesNodeUidLfsLabelsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .addLfLabelsNodesNodeUidLfsLabelsPost(
          requestParameters.nodeUid,
          requestParameters.lFLabels,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Add LF to database. If this request is coming from Studio the `user_uid` will be set in the POST body (because user identification is dropped from headers at interservice barrier). If this request is coming directly from an end user we will get the user_uid from the Request.state field (it will have been set by the auth middleware)
     * @summary Add Lf
     * @param {LfsApiAddLfNodesNodeUidLfsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLfNodesNodeUidLfsPost(
      requestParameters: LfsApiAddLfNodesNodeUidLfsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .addLfNodesNodeUidLfsPost(
          requestParameters.nodeUid,
          requestParameters.lFConfig,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Adds a new custom LF template and returns its UID
     * @summary Add Lf Template Class
     * @param {LfsApiAddLfTemplateClassLfTemplatesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLfTemplateClassLfTemplatesPost(
      requestParameters: LfsApiAddLfTemplateClassLfTemplatesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateLFTemplateResponse> {
      return localVarFp
        .addLfTemplateClassLfTemplatesPost(
          requestParameters.lFTemplatePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Archive Active Lf
     * @param {LfsApiArchiveActiveLfNodesNodeUidActiveLfsLfUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveActiveLfNodesNodeUidActiveLfsLfUidDelete(
      requestParameters: LfsApiArchiveActiveLfNodesNodeUidActiveLfsLfUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .archiveActiveLfNodesNodeUidActiveLfsLfUidDelete(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Archive Active Lfs
     * @param {LfsApiArchiveActiveLfsNodesNodeUidActiveLfsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveActiveLfsNodesNodeUidActiveLfsDelete(
      requestParameters: LfsApiArchiveActiveLfsNodesNodeUidActiveLfsDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .archiveActiveLfsNodesNodeUidActiveLfsDelete(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Continue Long Running Lf
     * @param {LfsApiContinueLongRunningLfNodesNodeUidLfsLfUidContinuePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    continueLongRunningLfNodesNodeUidLfsLfUidContinuePost(
      requestParameters: LfsApiContinueLongRunningLfNodesNodeUidLfsLfUidContinuePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ContinueLongRunningLFResponse> {
      return localVarFp
        .continueLongRunningLfNodesNodeUidLfsLfUidContinuePost(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          requestParameters.continueLongRunningLFPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * First, filter lfs_dump from from_node_uid task to contain only the LFs that users want to import, then call import_lfs function.  TODO: Allow it to copy non-active LFs too
     * @summary Copy Lf By Name
     * @param {LfsApiCopyLfByNameNodesNodeUidImportLfsByNamePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    copyLfByNameNodesNodeUidImportLfsByNamePost(
      requestParameters: LfsApiCopyLfByNameNodesNodeUidImportLfsByNamePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .copyLfByNameNodesNodeUidImportLfsByNamePost(
          requestParameters.nodeUid,
          requestParameters.copyLFByNameParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete In Progress Lf
     * @param {LfsApiDeleteInProgressLfNodesNodeUidInProgressLfsLfUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete(
      requestParameters: LfsApiDeleteInProgressLfNodesNodeUidInProgressLfsLfUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Lf
     * @param {LfsApiDeleteLfNodesNodeUidLfsLfUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLfNodesNodeUidLfsLfUidDelete(
      requestParameters: LfsApiDeleteLfNodesNodeUidLfsLfUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteLfNodesNodeUidLfsLfUidDelete(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Lf Template
     * @param {LfsApiDeleteLfTemplateLfTemplatesTemplateUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLfTemplateLfTemplatesTemplateUidDelete(
      requestParameters: LfsApiDeleteLfTemplateLfTemplatesTemplateUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteLfTemplateLfTemplatesTemplateUidDelete(
          requestParameters.templateUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Export Lfs
     * @param {LfsApiExportLfsNodesNodeUidExportLfsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportLfsNodesNodeUidExportLfsGet(
      requestParameters: LfsApiExportLfsNodesNodeUidExportLfsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .exportLfsNodesNodeUidExportLfsGet(
          requestParameters.nodeUid,
          requestParameters.activeOnly,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Active Lf
     * @param {LfsApiFetchActiveLfNodesNodeUidActiveLfsLfUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchActiveLfNodesNodeUidActiveLfsLfUidGet(
      requestParameters: LfsApiFetchActiveLfNodesNodeUidActiveLfsLfUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchActiveLfNodesNodeUidActiveLfsLfUidGet(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Active Lfs
     * @param {LfsApiFetchActiveLfsNodesNodeUidActiveLfsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchActiveLfsNodesNodeUidActiveLfsGet(
      requestParameters: LfsApiFetchActiveLfsNodesNodeUidActiveLfsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchActiveLfsNodesNodeUidActiveLfsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Archived Lfs
     * @param {LfsApiFetchArchivedLfsNodesNodeUidArchivedLfsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchArchivedLfsNodesNodeUidArchivedLfsGet(
      requestParameters: LfsApiFetchArchivedLfsNodesNodeUidArchivedLfsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchArchivedLfsNodesNodeUidArchivedLfsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch In Progress Lfs
     * @param {LfsApiFetchInProgressLfsNodesNodeUidInProgressLfsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchInProgressLfsNodesNodeUidInProgressLfsGet(
      requestParameters: LfsApiFetchInProgressLfsNodesNodeUidInProgressLfsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchInProgressLfsNodesNodeUidInProgressLfsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Lf Labels
     * @param {LfsApiFetchLfLabelsNodesNodeUidLfsLfUidLabelsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfLabelsNodesNodeUidLfsLfUidLabelsGet(
      requestParameters: LfsApiFetchLfLabelsNodesNodeUidLfsLfUidLabelsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchLfLabelsNodesNodeUidLfsLfUidLabelsGet(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Lfs Labels Get
     * @param {LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfsLabelsGetNodesNodeUidLfLabelsGet(
      requestParameters: LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchLfsLabelsGetNodesNodeUidLfLabelsGet(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          requestParameters.xUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Lfs Labels
     * @param {LfsApiFetchLfsLabelsNodesNodeUidFetchLfLabelsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchLfsLabelsNodesNodeUidFetchLfLabelsPost(
      requestParameters: LfsApiFetchLfsLabelsNodesNodeUidFetchLfLabelsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchLfsLabelsNodesNodeUidFetchLfLabelsPost(
          requestParameters.nodeUid,
          requestParameters.fetchLFLabels,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch an LF by its uid.
     * @summary Get Lf
     * @param {LfsApiGetLfNodesNodeUidLfsLfUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfNodesNodeUidLfsLfUidGet(
      requestParameters: LfsApiGetLfNodesNodeUidLfsLfUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getLfNodesNodeUidLfsLfUidGet(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch custom LF templates with optional filters on template uid, template name, template type and schema name
     * @summary Get Lf Templates
     * @param {LfsApiGetLfTemplatesLfTemplatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfTemplatesLfTemplatesGet(
      requestParameters: LfsApiGetLfTemplatesLfTemplatesGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<GetLFTemplatesResponse>> {
      return localVarFp
        .getLfTemplatesLfTemplatesGet(
          requestParameters.templateUid,
          requestParameters.templateName,
          requestParameters.templateType,
          requestParameters.templateSchemaName,
          requestParameters.includeCode,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch all (lf_uid, config) tuples in a given task.
     * @summary Get Lfs
     * @param {LfsApiGetLfsNodesNodeUidLfsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLfsNodesNodeUidLfsGet(
      requestParameters: LfsApiGetLfsNodesNodeUidLfsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getLfsNodesNodeUidLfsGet(requestParameters.nodeUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Import Lfs
     * @param {LfsApiImportLfsNodesNodeUidImportLfsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importLfsNodesNodeUidImportLfsPost(
      requestParameters: LfsApiImportLfsNodesNodeUidImportLfsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .importLfsNodesNodeUidImportLfsPost(
          requestParameters.nodeUid,
          requestParameters.importLFs,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Lf State
     * @param {LfsApiUpdateLfStateNodesNodeUidActiveLfsLfUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLfStateNodesNodeUidActiveLfsLfUidPut(
      requestParameters: LfsApiUpdateLfStateNodesNodeUidActiveLfsLfUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .updateLfStateNodesNodeUidActiveLfsLfUidPut(
          requestParameters.nodeUid,
          requestParameters.lfUid,
          requestParameters.updatedStateParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addLfLabelsNodesNodeUidLfsLabelsPost operation in LfsApi.
 * @export
 * @interface LfsApiAddLfLabelsNodesNodeUidLfsLabelsPostRequest
 */
export interface LfsApiAddLfLabelsNodesNodeUidLfsLabelsPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiAddLfLabelsNodesNodeUidLfsLabelsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {LFLabels}
   * @memberof LfsApiAddLfLabelsNodesNodeUidLfsLabelsPost
   */
  readonly lFLabels: LFLabels;
}

/**
 * Request parameters for addLfNodesNodeUidLfsPost operation in LfsApi.
 * @export
 * @interface LfsApiAddLfNodesNodeUidLfsPostRequest
 */
export interface LfsApiAddLfNodesNodeUidLfsPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiAddLfNodesNodeUidLfsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {LFConfig}
   * @memberof LfsApiAddLfNodesNodeUidLfsPost
   */
  readonly lFConfig: LFConfig;
}

/**
 * Request parameters for addLfTemplateClassLfTemplatesPost operation in LfsApi.
 * @export
 * @interface LfsApiAddLfTemplateClassLfTemplatesPostRequest
 */
export interface LfsApiAddLfTemplateClassLfTemplatesPostRequest {
  /**
   *
   * @type {LFTemplatePayload}
   * @memberof LfsApiAddLfTemplateClassLfTemplatesPost
   */
  readonly lFTemplatePayload: LFTemplatePayload;
}

/**
 * Request parameters for archiveActiveLfNodesNodeUidActiveLfsLfUidDelete operation in LfsApi.
 * @export
 * @interface LfsApiArchiveActiveLfNodesNodeUidActiveLfsLfUidDeleteRequest
 */
export interface LfsApiArchiveActiveLfNodesNodeUidActiveLfsLfUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiArchiveActiveLfNodesNodeUidActiveLfsLfUidDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfsApiArchiveActiveLfNodesNodeUidActiveLfsLfUidDelete
   */
  readonly lfUid: number;
}

/**
 * Request parameters for archiveActiveLfsNodesNodeUidActiveLfsDelete operation in LfsApi.
 * @export
 * @interface LfsApiArchiveActiveLfsNodesNodeUidActiveLfsDeleteRequest
 */
export interface LfsApiArchiveActiveLfsNodesNodeUidActiveLfsDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiArchiveActiveLfsNodesNodeUidActiveLfsDelete
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for continueLongRunningLfNodesNodeUidLfsLfUidContinuePost operation in LfsApi.
 * @export
 * @interface LfsApiContinueLongRunningLfNodesNodeUidLfsLfUidContinuePostRequest
 */
export interface LfsApiContinueLongRunningLfNodesNodeUidLfsLfUidContinuePostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiContinueLongRunningLfNodesNodeUidLfsLfUidContinuePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfsApiContinueLongRunningLfNodesNodeUidLfsLfUidContinuePost
   */
  readonly lfUid: number;

  /**
   *
   * @type {ContinueLongRunningLFPayload}
   * @memberof LfsApiContinueLongRunningLfNodesNodeUidLfsLfUidContinuePost
   */
  readonly continueLongRunningLFPayload: ContinueLongRunningLFPayload;
}

/**
 * Request parameters for copyLfByNameNodesNodeUidImportLfsByNamePost operation in LfsApi.
 * @export
 * @interface LfsApiCopyLfByNameNodesNodeUidImportLfsByNamePostRequest
 */
export interface LfsApiCopyLfByNameNodesNodeUidImportLfsByNamePostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiCopyLfByNameNodesNodeUidImportLfsByNamePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {CopyLFByNameParams}
   * @memberof LfsApiCopyLfByNameNodesNodeUidImportLfsByNamePost
   */
  readonly copyLFByNameParams: CopyLFByNameParams;
}

/**
 * Request parameters for deleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete operation in LfsApi.
 * @export
 * @interface LfsApiDeleteInProgressLfNodesNodeUidInProgressLfsLfUidDeleteRequest
 */
export interface LfsApiDeleteInProgressLfNodesNodeUidInProgressLfsLfUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiDeleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfsApiDeleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete
   */
  readonly lfUid: number;
}

/**
 * Request parameters for deleteLfNodesNodeUidLfsLfUidDelete operation in LfsApi.
 * @export
 * @interface LfsApiDeleteLfNodesNodeUidLfsLfUidDeleteRequest
 */
export interface LfsApiDeleteLfNodesNodeUidLfsLfUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiDeleteLfNodesNodeUidLfsLfUidDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfsApiDeleteLfNodesNodeUidLfsLfUidDelete
   */
  readonly lfUid: number;
}

/**
 * Request parameters for deleteLfTemplateLfTemplatesTemplateUidDelete operation in LfsApi.
 * @export
 * @interface LfsApiDeleteLfTemplateLfTemplatesTemplateUidDeleteRequest
 */
export interface LfsApiDeleteLfTemplateLfTemplatesTemplateUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiDeleteLfTemplateLfTemplatesTemplateUidDelete
   */
  readonly templateUid: number;
}

/**
 * Request parameters for exportLfsNodesNodeUidExportLfsGet operation in LfsApi.
 * @export
 * @interface LfsApiExportLfsNodesNodeUidExportLfsGetRequest
 */
export interface LfsApiExportLfsNodesNodeUidExportLfsGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiExportLfsNodesNodeUidExportLfsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof LfsApiExportLfsNodesNodeUidExportLfsGet
   */
  readonly activeOnly?: boolean;
}

/**
 * Request parameters for fetchActiveLfNodesNodeUidActiveLfsLfUidGet operation in LfsApi.
 * @export
 * @interface LfsApiFetchActiveLfNodesNodeUidActiveLfsLfUidGetRequest
 */
export interface LfsApiFetchActiveLfNodesNodeUidActiveLfsLfUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiFetchActiveLfNodesNodeUidActiveLfsLfUidGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfsApiFetchActiveLfNodesNodeUidActiveLfsLfUidGet
   */
  readonly lfUid: number;
}

/**
 * Request parameters for fetchActiveLfsNodesNodeUidActiveLfsGet operation in LfsApi.
 * @export
 * @interface LfsApiFetchActiveLfsNodesNodeUidActiveLfsGetRequest
 */
export interface LfsApiFetchActiveLfsNodesNodeUidActiveLfsGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiFetchActiveLfsNodesNodeUidActiveLfsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for fetchArchivedLfsNodesNodeUidArchivedLfsGet operation in LfsApi.
 * @export
 * @interface LfsApiFetchArchivedLfsNodesNodeUidArchivedLfsGetRequest
 */
export interface LfsApiFetchArchivedLfsNodesNodeUidArchivedLfsGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiFetchArchivedLfsNodesNodeUidArchivedLfsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for fetchInProgressLfsNodesNodeUidInProgressLfsGet operation in LfsApi.
 * @export
 * @interface LfsApiFetchInProgressLfsNodesNodeUidInProgressLfsGetRequest
 */
export interface LfsApiFetchInProgressLfsNodesNodeUidInProgressLfsGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiFetchInProgressLfsNodesNodeUidInProgressLfsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for fetchLfLabelsNodesNodeUidLfsLfUidLabelsGet operation in LfsApi.
 * @export
 * @interface LfsApiFetchLfLabelsNodesNodeUidLfsLfUidLabelsGetRequest
 */
export interface LfsApiFetchLfLabelsNodesNodeUidLfsLfUidLabelsGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiFetchLfLabelsNodesNodeUidLfsLfUidLabelsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfsApiFetchLfLabelsNodesNodeUidLfsLfUidLabelsGet
   */
  readonly lfUid: number;

  /**
   *
   * @type {string}
   * @memberof LfsApiFetchLfLabelsNodesNodeUidLfsLfUidLabelsGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof LfsApiFetchLfLabelsNodesNodeUidLfsLfUidLabelsGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof LfsApiFetchLfLabelsNodesNodeUidLfsLfUidLabelsGet
   */
  readonly endDate?: string;
}

/**
 * Request parameters for fetchLfsLabelsGetNodesNodeUidLfLabelsGet operation in LfsApi.
 * @export
 * @interface LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGetRequest
 */
export interface LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {Array<number>}
   * @memberof LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGet
   */
  readonly lfUid?: Array<number>;

  /**
   *
   * @type {Array<string>}
   * @memberof LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGet
   */
  readonly xUid?: Array<string>;

  /**
   *
   * @type {string}
   * @memberof LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGet
   */
  readonly endDate?: string;
}

/**
 * Request parameters for fetchLfsLabelsNodesNodeUidFetchLfLabelsPost operation in LfsApi.
 * @export
 * @interface LfsApiFetchLfsLabelsNodesNodeUidFetchLfLabelsPostRequest
 */
export interface LfsApiFetchLfsLabelsNodesNodeUidFetchLfLabelsPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiFetchLfsLabelsNodesNodeUidFetchLfLabelsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {FetchLFLabels}
   * @memberof LfsApiFetchLfsLabelsNodesNodeUidFetchLfLabelsPost
   */
  readonly fetchLFLabels: FetchLFLabels;
}

/**
 * Request parameters for getLfNodesNodeUidLfsLfUidGet operation in LfsApi.
 * @export
 * @interface LfsApiGetLfNodesNodeUidLfsLfUidGetRequest
 */
export interface LfsApiGetLfNodesNodeUidLfsLfUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiGetLfNodesNodeUidLfsLfUidGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfsApiGetLfNodesNodeUidLfsLfUidGet
   */
  readonly lfUid: number;
}

/**
 * Request parameters for getLfTemplatesLfTemplatesGet operation in LfsApi.
 * @export
 * @interface LfsApiGetLfTemplatesLfTemplatesGetRequest
 */
export interface LfsApiGetLfTemplatesLfTemplatesGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiGetLfTemplatesLfTemplatesGet
   */
  readonly templateUid?: number;

  /**
   *
   * @type {string}
   * @memberof LfsApiGetLfTemplatesLfTemplatesGet
   */
  readonly templateName?: string;

  /**
   *
   * @type {string}
   * @memberof LfsApiGetLfTemplatesLfTemplatesGet
   */
  readonly templateType?: string;

  /**
   *
   * @type {string}
   * @memberof LfsApiGetLfTemplatesLfTemplatesGet
   */
  readonly templateSchemaName?: string;

  /**
   *
   * @type {boolean}
   * @memberof LfsApiGetLfTemplatesLfTemplatesGet
   */
  readonly includeCode?: boolean;

  /**
   *
   * @type {number}
   * @memberof LfsApiGetLfTemplatesLfTemplatesGet
   */
  readonly workspaceUid?: number;
}

/**
 * Request parameters for getLfsNodesNodeUidLfsGet operation in LfsApi.
 * @export
 * @interface LfsApiGetLfsNodesNodeUidLfsGetRequest
 */
export interface LfsApiGetLfsNodesNodeUidLfsGetRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiGetLfsNodesNodeUidLfsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for importLfsNodesNodeUidImportLfsPost operation in LfsApi.
 * @export
 * @interface LfsApiImportLfsNodesNodeUidImportLfsPostRequest
 */
export interface LfsApiImportLfsNodesNodeUidImportLfsPostRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiImportLfsNodesNodeUidImportLfsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ImportLFs}
   * @memberof LfsApiImportLfsNodesNodeUidImportLfsPost
   */
  readonly importLFs: ImportLFs;
}

/**
 * Request parameters for updateLfStateNodesNodeUidActiveLfsLfUidPut operation in LfsApi.
 * @export
 * @interface LfsApiUpdateLfStateNodesNodeUidActiveLfsLfUidPutRequest
 */
export interface LfsApiUpdateLfStateNodesNodeUidActiveLfsLfUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof LfsApiUpdateLfStateNodesNodeUidActiveLfsLfUidPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof LfsApiUpdateLfStateNodesNodeUidActiveLfsLfUidPut
   */
  readonly lfUid: number;

  /**
   *
   * @type {UpdatedStateParams}
   * @memberof LfsApiUpdateLfStateNodesNodeUidActiveLfsLfUidPut
   */
  readonly updatedStateParams: UpdatedStateParams;
}

/**
 * LfsApi - object-oriented interface
 * @export
 * @class LfsApi
 * @extends {BaseAPI}
 */
export class LfsApi extends BaseAPI {
  /**
   *
   * @summary Add Lf Labels
   * @param {LfsApiAddLfLabelsNodesNodeUidLfsLabelsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public addLfLabelsNodesNodeUidLfsLabelsPost(
    requestParameters: LfsApiAddLfLabelsNodesNodeUidLfsLabelsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .addLfLabelsNodesNodeUidLfsLabelsPost(
        requestParameters.nodeUid,
        requestParameters.lFLabels,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Add LF to database. If this request is coming from Studio the `user_uid` will be set in the POST body (because user identification is dropped from headers at interservice barrier). If this request is coming directly from an end user we will get the user_uid from the Request.state field (it will have been set by the auth middleware)
   * @summary Add Lf
   * @param {LfsApiAddLfNodesNodeUidLfsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public addLfNodesNodeUidLfsPost(
    requestParameters: LfsApiAddLfNodesNodeUidLfsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .addLfNodesNodeUidLfsPost(
        requestParameters.nodeUid,
        requestParameters.lFConfig,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Adds a new custom LF template and returns its UID
   * @summary Add Lf Template Class
   * @param {LfsApiAddLfTemplateClassLfTemplatesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public addLfTemplateClassLfTemplatesPost(
    requestParameters: LfsApiAddLfTemplateClassLfTemplatesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .addLfTemplateClassLfTemplatesPost(
        requestParameters.lFTemplatePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Archive Active Lf
   * @param {LfsApiArchiveActiveLfNodesNodeUidActiveLfsLfUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public archiveActiveLfNodesNodeUidActiveLfsLfUidDelete(
    requestParameters: LfsApiArchiveActiveLfNodesNodeUidActiveLfsLfUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .archiveActiveLfNodesNodeUidActiveLfsLfUidDelete(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Archive Active Lfs
   * @param {LfsApiArchiveActiveLfsNodesNodeUidActiveLfsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public archiveActiveLfsNodesNodeUidActiveLfsDelete(
    requestParameters: LfsApiArchiveActiveLfsNodesNodeUidActiveLfsDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .archiveActiveLfsNodesNodeUidActiveLfsDelete(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Continue Long Running Lf
   * @param {LfsApiContinueLongRunningLfNodesNodeUidLfsLfUidContinuePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public continueLongRunningLfNodesNodeUidLfsLfUidContinuePost(
    requestParameters: LfsApiContinueLongRunningLfNodesNodeUidLfsLfUidContinuePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .continueLongRunningLfNodesNodeUidLfsLfUidContinuePost(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        requestParameters.continueLongRunningLFPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * First, filter lfs_dump from from_node_uid task to contain only the LFs that users want to import, then call import_lfs function.  TODO: Allow it to copy non-active LFs too
   * @summary Copy Lf By Name
   * @param {LfsApiCopyLfByNameNodesNodeUidImportLfsByNamePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public copyLfByNameNodesNodeUidImportLfsByNamePost(
    requestParameters: LfsApiCopyLfByNameNodesNodeUidImportLfsByNamePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .copyLfByNameNodesNodeUidImportLfsByNamePost(
        requestParameters.nodeUid,
        requestParameters.copyLFByNameParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete In Progress Lf
   * @param {LfsApiDeleteInProgressLfNodesNodeUidInProgressLfsLfUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public deleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete(
    requestParameters: LfsApiDeleteInProgressLfNodesNodeUidInProgressLfsLfUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .deleteInProgressLfNodesNodeUidInProgressLfsLfUidDelete(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Lf
   * @param {LfsApiDeleteLfNodesNodeUidLfsLfUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public deleteLfNodesNodeUidLfsLfUidDelete(
    requestParameters: LfsApiDeleteLfNodesNodeUidLfsLfUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .deleteLfNodesNodeUidLfsLfUidDelete(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Lf Template
   * @param {LfsApiDeleteLfTemplateLfTemplatesTemplateUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public deleteLfTemplateLfTemplatesTemplateUidDelete(
    requestParameters: LfsApiDeleteLfTemplateLfTemplatesTemplateUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .deleteLfTemplateLfTemplatesTemplateUidDelete(
        requestParameters.templateUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export Lfs
   * @param {LfsApiExportLfsNodesNodeUidExportLfsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public exportLfsNodesNodeUidExportLfsGet(
    requestParameters: LfsApiExportLfsNodesNodeUidExportLfsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .exportLfsNodesNodeUidExportLfsGet(
        requestParameters.nodeUid,
        requestParameters.activeOnly,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Active Lf
   * @param {LfsApiFetchActiveLfNodesNodeUidActiveLfsLfUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public fetchActiveLfNodesNodeUidActiveLfsLfUidGet(
    requestParameters: LfsApiFetchActiveLfNodesNodeUidActiveLfsLfUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .fetchActiveLfNodesNodeUidActiveLfsLfUidGet(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Active Lfs
   * @param {LfsApiFetchActiveLfsNodesNodeUidActiveLfsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public fetchActiveLfsNodesNodeUidActiveLfsGet(
    requestParameters: LfsApiFetchActiveLfsNodesNodeUidActiveLfsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .fetchActiveLfsNodesNodeUidActiveLfsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Archived Lfs
   * @param {LfsApiFetchArchivedLfsNodesNodeUidArchivedLfsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public fetchArchivedLfsNodesNodeUidArchivedLfsGet(
    requestParameters: LfsApiFetchArchivedLfsNodesNodeUidArchivedLfsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .fetchArchivedLfsNodesNodeUidArchivedLfsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch In Progress Lfs
   * @param {LfsApiFetchInProgressLfsNodesNodeUidInProgressLfsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public fetchInProgressLfsNodesNodeUidInProgressLfsGet(
    requestParameters: LfsApiFetchInProgressLfsNodesNodeUidInProgressLfsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .fetchInProgressLfsNodesNodeUidInProgressLfsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Lf Labels
   * @param {LfsApiFetchLfLabelsNodesNodeUidLfsLfUidLabelsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public fetchLfLabelsNodesNodeUidLfsLfUidLabelsGet(
    requestParameters: LfsApiFetchLfLabelsNodesNodeUidLfsLfUidLabelsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .fetchLfLabelsNodesNodeUidLfsLfUidLabelsGet(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Lfs Labels Get
   * @param {LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public fetchLfsLabelsGetNodesNodeUidLfLabelsGet(
    requestParameters: LfsApiFetchLfsLabelsGetNodesNodeUidLfLabelsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .fetchLfsLabelsGetNodesNodeUidLfLabelsGet(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        requestParameters.xUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Lfs Labels
   * @param {LfsApiFetchLfsLabelsNodesNodeUidFetchLfLabelsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public fetchLfsLabelsNodesNodeUidFetchLfLabelsPost(
    requestParameters: LfsApiFetchLfsLabelsNodesNodeUidFetchLfLabelsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .fetchLfsLabelsNodesNodeUidFetchLfLabelsPost(
        requestParameters.nodeUid,
        requestParameters.fetchLFLabels,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch an LF by its uid.
   * @summary Get Lf
   * @param {LfsApiGetLfNodesNodeUidLfsLfUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public getLfNodesNodeUidLfsLfUidGet(
    requestParameters: LfsApiGetLfNodesNodeUidLfsLfUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .getLfNodesNodeUidLfsLfUidGet(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch custom LF templates with optional filters on template uid, template name, template type and schema name
   * @summary Get Lf Templates
   * @param {LfsApiGetLfTemplatesLfTemplatesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public getLfTemplatesLfTemplatesGet(
    requestParameters: LfsApiGetLfTemplatesLfTemplatesGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .getLfTemplatesLfTemplatesGet(
        requestParameters.templateUid,
        requestParameters.templateName,
        requestParameters.templateType,
        requestParameters.templateSchemaName,
        requestParameters.includeCode,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch all (lf_uid, config) tuples in a given task.
   * @summary Get Lfs
   * @param {LfsApiGetLfsNodesNodeUidLfsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public getLfsNodesNodeUidLfsGet(
    requestParameters: LfsApiGetLfsNodesNodeUidLfsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .getLfsNodesNodeUidLfsGet(requestParameters.nodeUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import Lfs
   * @param {LfsApiImportLfsNodesNodeUidImportLfsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public importLfsNodesNodeUidImportLfsPost(
    requestParameters: LfsApiImportLfsNodesNodeUidImportLfsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .importLfsNodesNodeUidImportLfsPost(
        requestParameters.nodeUid,
        requestParameters.importLFs,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Lf State
   * @param {LfsApiUpdateLfStateNodesNodeUidActiveLfsLfUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LfsApi
   */
  public updateLfStateNodesNodeUidActiveLfsLfUidPut(
    requestParameters: LfsApiUpdateLfStateNodesNodeUidActiveLfsLfUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LfsApiFp(this.configuration)
      .updateLfStateNodesNodeUidActiveLfsLfUidPut(
        requestParameters.nodeUid,
        requestParameters.lfUid,
        requestParameters.updatedStateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LicenseCheckApi - axios parameter creator
 * @export
 */
export const LicenseCheckApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Instance Information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceInformationLicenseInstanceInfoGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/license/instance-info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get License Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLicenseInfoLicenseGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/license`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get System Key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSystemKeyLicenseSystemValidationKeyGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/license/system-validation-key`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Put License
     * @param {LicenseKey} licenseKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putLicenseLicensePut: async (
      licenseKey: LicenseKey,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'licenseKey' is not null or undefined
      assertParamExists('putLicenseLicensePut', 'licenseKey', licenseKey);
      const localVarPath = `/license`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        licenseKey,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Validate the license key by decrypting the data signed with the public key.
     * @summary Validate License
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateLicenseLicenseValidateGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/license/validate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LicenseCheckApi - functional programming interface
 * @export
 */
export const LicenseCheckApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    LicenseCheckApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Instance Information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstanceInformationLicenseInstanceInfoGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InstanceInformation>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInstanceInformationLicenseInstanceInfoGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LicenseCheckApi.getInstanceInformationLicenseInstanceInfoGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get License Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLicenseInfoLicenseGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLicenseInfoLicenseGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LicenseCheckApi.getLicenseInfoLicenseGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get System Key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSystemKeyLicenseSystemValidationKeyGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSystemKeyLicenseSystemValidationKeyGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LicenseCheckApi.getSystemKeyLicenseSystemValidationKeyGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Put License
     * @param {LicenseKey} licenseKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putLicenseLicensePut(
      licenseKey: LicenseKey,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putLicenseLicensePut(
          licenseKey,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LicenseCheckApi.putLicenseLicensePut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Validate the license key by decrypting the data signed with the public key.
     * @summary Validate License
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateLicenseLicenseValidateGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.validateLicenseLicenseValidateGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'LicenseCheckApi.validateLicenseLicenseValidateGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LicenseCheckApi - factory interface
 * @export
 */
export const LicenseCheckApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LicenseCheckApiFp(configuration);
  return {
    /**
     *
     * @summary Get Instance Information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceInformationLicenseInstanceInfoGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<InstanceInformation> {
      return localVarFp
        .getInstanceInformationLicenseInstanceInfoGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get License Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLicenseInfoLicenseGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getLicenseInfoLicenseGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get System Key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSystemKeyLicenseSystemValidationKeyGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getSystemKeyLicenseSystemValidationKeyGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Put License
     * @param {LicenseCheckApiPutLicenseLicensePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putLicenseLicensePut(
      requestParameters: LicenseCheckApiPutLicenseLicensePutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .putLicenseLicensePut(requestParameters.licenseKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Validate the license key by decrypting the data signed with the public key.
     * @summary Validate License
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateLicenseLicenseValidateGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .validateLicenseLicenseValidateGet(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for putLicenseLicensePut operation in LicenseCheckApi.
 * @export
 * @interface LicenseCheckApiPutLicenseLicensePutRequest
 */
export interface LicenseCheckApiPutLicenseLicensePutRequest {
  /**
   *
   * @type {LicenseKey}
   * @memberof LicenseCheckApiPutLicenseLicensePut
   */
  readonly licenseKey: LicenseKey;
}

/**
 * LicenseCheckApi - object-oriented interface
 * @export
 * @class LicenseCheckApi
 * @extends {BaseAPI}
 */
export class LicenseCheckApi extends BaseAPI {
  /**
   *
   * @summary Get Instance Information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LicenseCheckApi
   */
  public getInstanceInformationLicenseInstanceInfoGet(
    options?: RawAxiosRequestConfig,
  ) {
    return LicenseCheckApiFp(this.configuration)
      .getInstanceInformationLicenseInstanceInfoGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get License Info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LicenseCheckApi
   */
  public getLicenseInfoLicenseGet(options?: RawAxiosRequestConfig) {
    return LicenseCheckApiFp(this.configuration)
      .getLicenseInfoLicenseGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get System Key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LicenseCheckApi
   */
  public getSystemKeyLicenseSystemValidationKeyGet(
    options?: RawAxiosRequestConfig,
  ) {
    return LicenseCheckApiFp(this.configuration)
      .getSystemKeyLicenseSystemValidationKeyGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Put License
   * @param {LicenseCheckApiPutLicenseLicensePutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LicenseCheckApi
   */
  public putLicenseLicensePut(
    requestParameters: LicenseCheckApiPutLicenseLicensePutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LicenseCheckApiFp(this.configuration)
      .putLicenseLicensePut(requestParameters.licenseKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Validate the license key by decrypting the data signed with the public key.
   * @summary Validate License
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LicenseCheckApi
   */
  public validateLicenseLicenseValidateGet(options?: RawAxiosRequestConfig) {
    return LicenseCheckApiFp(this.configuration)
      .validateLicenseLicenseValidateGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Bundled Logs
     * @param {Array<ServiceType>} [services]
     * @param {Array<string>} [grepRegexes]
     * @param {number} [workspaceUid]
     * @param {number} [numberOfLines]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBundledLogsBundledLogsGet: async (
      services?: Array<ServiceType>,
      grepRegexes?: Array<string>,
      workspaceUid?: number,
      numberOfLines?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/bundled-logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (services) {
        localVarQueryParameter['services'] = services;
      }

      if (grepRegexes) {
        localVarQueryParameter['grep_regexes'] = grepRegexes;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      if (numberOfLines !== undefined) {
        localVarQueryParameter['number_of_lines'] = numberOfLines;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Job Logs
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobLogsLogsJobJobIdGet: async (
      jobId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('getJobLogsLogsJobJobIdGet', 'jobId', jobId);
      const localVarPath = `/logs/job/{job_id}`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Service Logs
     * @param {Array<string>} [grepRegex]
     * @param {number} [workspaceUid]
     * @param {number} [tailN]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLogsLogsGet: async (
      grepRegex?: Array<string>,
      workspaceUid?: number,
      tailN?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (grepRegex) {
        localVarQueryParameter['grep_regex'] = grepRegex;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      if (tailN !== undefined) {
        localVarQueryParameter['tail_n'] = tailN;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Service Logs V2
     * @param {string} [startTime]
     * @param {string} [endTime]
     * @param {string} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLogsV2LogsV2Get: async (
      startTime?: string,
      endTime?: string,
      jobId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/logs-v2`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startTime !== undefined) {
        localVarQueryParameter['start_time'] = startTime;
      }

      if (endTime !== undefined) {
        localVarQueryParameter['end_time'] = endTime;
      }

      if (jobId !== undefined) {
        localVarQueryParameter['job_id'] = jobId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LogsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Bundled Logs
     * @param {Array<ServiceType>} [services]
     * @param {Array<string>} [grepRegexes]
     * @param {number} [workspaceUid]
     * @param {number} [numberOfLines]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBundledLogsBundledLogsGet(
      services?: Array<ServiceType>,
      grepRegexes?: Array<string>,
      workspaceUid?: number,
      numberOfLines?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBundledLogsBundledLogsGet(
          services,
          grepRegexes,
          workspaceUid,
          numberOfLines,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LogsApi.getBundledLogsBundledLogsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Job Logs
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJobLogsLogsJobJobIdGet(
      jobId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getJobLogsLogsJobJobIdGet(
          jobId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LogsApi.getJobLogsLogsJobJobIdGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Service Logs
     * @param {Array<string>} [grepRegex]
     * @param {number} [workspaceUid]
     * @param {number} [tailN]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceLogsLogsGet(
      grepRegex?: Array<string>,
      workspaceUid?: number,
      tailN?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getServiceLogsLogsGet(
          grepRegex,
          workspaceUid,
          tailN,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LogsApi.getServiceLogsLogsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Service Logs V2
     * @param {string} [startTime]
     * @param {string} [endTime]
     * @param {string} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceLogsV2LogsV2Get(
      startTime?: string,
      endTime?: string,
      jobId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getServiceLogsV2LogsV2Get(
          startTime,
          endTime,
          jobId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['LogsApi.getServiceLogsV2LogsV2Get']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = LogsApiFp(configuration);
  return {
    /**
     *
     * @summary Get Bundled Logs
     * @param {LogsApiGetBundledLogsBundledLogsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBundledLogsBundledLogsGet(
      requestParameters: LogsApiGetBundledLogsBundledLogsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getBundledLogsBundledLogsGet(
          requestParameters.services,
          requestParameters.grepRegexes,
          requestParameters.workspaceUid,
          requestParameters.numberOfLines,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Job Logs
     * @param {LogsApiGetJobLogsLogsJobJobIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobLogsLogsJobJobIdGet(
      requestParameters: LogsApiGetJobLogsLogsJobJobIdGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getJobLogsLogsJobJobIdGet(requestParameters.jobId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Service Logs
     * @param {LogsApiGetServiceLogsLogsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLogsLogsGet(
      requestParameters: LogsApiGetServiceLogsLogsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getServiceLogsLogsGet(
          requestParameters.grepRegex,
          requestParameters.workspaceUid,
          requestParameters.tailN,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Service Logs V2
     * @param {LogsApiGetServiceLogsV2LogsV2GetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLogsV2LogsV2Get(
      requestParameters: LogsApiGetServiceLogsV2LogsV2GetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getServiceLogsV2LogsV2Get(
          requestParameters.startTime,
          requestParameters.endTime,
          requestParameters.jobId,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getBundledLogsBundledLogsGet operation in LogsApi.
 * @export
 * @interface LogsApiGetBundledLogsBundledLogsGetRequest
 */
export interface LogsApiGetBundledLogsBundledLogsGetRequest {
  /**
   *
   * @type {Array<ServiceType>}
   * @memberof LogsApiGetBundledLogsBundledLogsGet
   */
  readonly services?: Array<ServiceType>;

  /**
   *
   * @type {Array<string>}
   * @memberof LogsApiGetBundledLogsBundledLogsGet
   */
  readonly grepRegexes?: Array<string>;

  /**
   *
   * @type {number}
   * @memberof LogsApiGetBundledLogsBundledLogsGet
   */
  readonly workspaceUid?: number;

  /**
   *
   * @type {number}
   * @memberof LogsApiGetBundledLogsBundledLogsGet
   */
  readonly numberOfLines?: number;
}

/**
 * Request parameters for getJobLogsLogsJobJobIdGet operation in LogsApi.
 * @export
 * @interface LogsApiGetJobLogsLogsJobJobIdGetRequest
 */
export interface LogsApiGetJobLogsLogsJobJobIdGetRequest {
  /**
   *
   * @type {string}
   * @memberof LogsApiGetJobLogsLogsJobJobIdGet
   */
  readonly jobId: string;
}

/**
 * Request parameters for getServiceLogsLogsGet operation in LogsApi.
 * @export
 * @interface LogsApiGetServiceLogsLogsGetRequest
 */
export interface LogsApiGetServiceLogsLogsGetRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof LogsApiGetServiceLogsLogsGet
   */
  readonly grepRegex?: Array<string>;

  /**
   *
   * @type {number}
   * @memberof LogsApiGetServiceLogsLogsGet
   */
  readonly workspaceUid?: number;

  /**
   *
   * @type {number}
   * @memberof LogsApiGetServiceLogsLogsGet
   */
  readonly tailN?: number;
}

/**
 * Request parameters for getServiceLogsV2LogsV2Get operation in LogsApi.
 * @export
 * @interface LogsApiGetServiceLogsV2LogsV2GetRequest
 */
export interface LogsApiGetServiceLogsV2LogsV2GetRequest {
  /**
   *
   * @type {string}
   * @memberof LogsApiGetServiceLogsV2LogsV2Get
   */
  readonly startTime?: string;

  /**
   *
   * @type {string}
   * @memberof LogsApiGetServiceLogsV2LogsV2Get
   */
  readonly endTime?: string;

  /**
   *
   * @type {string}
   * @memberof LogsApiGetServiceLogsV2LogsV2Get
   */
  readonly jobId?: string;
}

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
  /**
   *
   * @summary Get Bundled Logs
   * @param {LogsApiGetBundledLogsBundledLogsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogsApi
   */
  public getBundledLogsBundledLogsGet(
    requestParameters: LogsApiGetBundledLogsBundledLogsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return LogsApiFp(this.configuration)
      .getBundledLogsBundledLogsGet(
        requestParameters.services,
        requestParameters.grepRegexes,
        requestParameters.workspaceUid,
        requestParameters.numberOfLines,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Job Logs
   * @param {LogsApiGetJobLogsLogsJobJobIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogsApi
   */
  public getJobLogsLogsJobJobIdGet(
    requestParameters: LogsApiGetJobLogsLogsJobJobIdGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return LogsApiFp(this.configuration)
      .getJobLogsLogsJobJobIdGet(requestParameters.jobId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Service Logs
   * @param {LogsApiGetServiceLogsLogsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogsApi
   */
  public getServiceLogsLogsGet(
    requestParameters: LogsApiGetServiceLogsLogsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return LogsApiFp(this.configuration)
      .getServiceLogsLogsGet(
        requestParameters.grepRegex,
        requestParameters.workspaceUid,
        requestParameters.tailN,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Service Logs V2
   * @param {LogsApiGetServiceLogsV2LogsV2GetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogsApi
   */
  public getServiceLogsV2LogsV2Get(
    requestParameters: LogsApiGetServiceLogsV2LogsV2GetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return LogsApiFp(this.configuration)
      .getServiceLogsV2LogsV2Get(
        requestParameters.startTime,
        requestParameters.endTime,
        requestParameters.jobId,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * MemoryProfilingApi - axios parameter creator
 * @export
 */
export const MemoryProfilingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearProfilingMemoryProfileClearPost: async (
      memoryProfilingParams: MemoryProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memoryProfilingParams' is not null or undefined
      assertParamExists(
        'clearProfilingMemoryProfileClearPost',
        'memoryProfilingParams',
        memoryProfilingParams,
      );
      const localVarPath = `/memory-profile/clear`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        memoryProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Tracemalloc Profile
     * @param {number} [topK]
     * @param {number} [depth]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTracemallocProfileTracemallocGet: async (
      topK?: number,
      depth?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tracemalloc`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (topK !== undefined) {
        localVarQueryParameter['top_k'] = topK;
      }

      if (depth !== undefined) {
        localVarQueryParameter['depth'] = depth;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the Profile backtrace from Redis/disk
     * @summary Memory Profile
     * @param {string} service
     * @param {string} [pid]
     * @param {PersistenceMode} [persistenceMode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    memoryProfileMemoryProfileGet: async (
      service: string,
      pid?: string,
      persistenceMode?: PersistenceMode,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'service' is not null or undefined
      assertParamExists('memoryProfileMemoryProfileGet', 'service', service);
      const localVarPath = `/memory-profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (service !== undefined) {
        localVarQueryParameter['service'] = service;
      }

      if (pid !== undefined) {
        localVarQueryParameter['pid'] = pid;
      }

      if (persistenceMode !== undefined) {
        localVarQueryParameter['persistence_mode'] = persistenceMode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startProfilingMemoryProfileStartPost: async (
      memoryProfilingParams: MemoryProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memoryProfilingParams' is not null or undefined
      assertParamExists(
        'startProfilingMemoryProfileStartPost',
        'memoryProfilingParams',
        memoryProfilingParams,
      );
      const localVarPath = `/memory-profile/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        memoryProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start Tracemalloc
     * @param {StartTraceMalloc} startTraceMalloc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startTracemallocTracemallocStartPost: async (
      startTraceMalloc: StartTraceMalloc,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'startTraceMalloc' is not null or undefined
      assertParamExists(
        'startTracemallocTracemallocStartPost',
        'startTraceMalloc',
        startTraceMalloc,
      );
      const localVarPath = `/tracemalloc/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        startTraceMalloc,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopProfilingMemoryProfileStopPost: async (
      memoryProfilingParams: MemoryProfilingParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'memoryProfilingParams' is not null or undefined
      assertParamExists(
        'stopProfilingMemoryProfileStopPost',
        'memoryProfilingParams',
        memoryProfilingParams,
      );
      const localVarPath = `/memory-profile/stop`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        memoryProfilingParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stop Tracemalloc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopTracemallocTracemallocStopPost: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tracemalloc/stop`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MemoryProfilingApi - functional programming interface
 * @export
 */
export const MemoryProfilingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MemoryProfilingApiAxiosParamCreator(configuration);
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clearProfilingMemoryProfileClearPost(
      memoryProfilingParams: MemoryProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MemoryProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.clearProfilingMemoryProfileClearPost(
          memoryProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.clearProfilingMemoryProfileClearPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Tracemalloc Profile
     * @param {number} [topK]
     * @param {number} [depth]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTracemallocProfileTracemallocGet(
      topK?: number,
      depth?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTracemallocProfileTracemallocGet(
          topK,
          depth,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.getTracemallocProfileTracemallocGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get the Profile backtrace from Redis/disk
     * @summary Memory Profile
     * @param {string} service
     * @param {string} [pid]
     * @param {PersistenceMode} [persistenceMode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async memoryProfileMemoryProfileGet(
      service: string,
      pid?: string,
      persistenceMode?: PersistenceMode,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MemoryProfilingTraceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.memoryProfileMemoryProfileGet(
          service,
          pid,
          persistenceMode,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.memoryProfileMemoryProfileGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startProfilingMemoryProfileStartPost(
      memoryProfilingParams: MemoryProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MemoryProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.startProfilingMemoryProfileStartPost(
          memoryProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.startProfilingMemoryProfileStartPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Start Tracemalloc
     * @param {StartTraceMalloc} startTraceMalloc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startTracemallocTracemallocStartPost(
      startTraceMalloc: StartTraceMalloc,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.startTracemallocTracemallocStartPost(
          startTraceMalloc,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.startTracemallocTracemallocStartPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {MemoryProfilingParams} memoryProfilingParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopProfilingMemoryProfileStopPost(
      memoryProfilingParams: MemoryProfilingParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MemoryProfilingStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stopProfilingMemoryProfileStopPost(
          memoryProfilingParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.stopProfilingMemoryProfileStopPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Stop Tracemalloc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopTracemallocTracemallocStopPost(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stopTracemallocTracemallocStopPost(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MemoryProfilingApi.stopTracemallocTracemallocStopPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * MemoryProfilingApi - factory interface
 * @export
 */
export const MemoryProfilingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MemoryProfilingApiFp(configuration);
  return {
    /**
     * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
     * @summary Clear Profiling
     * @param {MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearProfilingMemoryProfileClearPost(
      requestParameters: MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MemoryProfilingStatusResponse> {
      return localVarFp
        .clearProfilingMemoryProfileClearPost(
          requestParameters.memoryProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Tracemalloc Profile
     * @param {MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTracemallocProfileTracemallocGet(
      requestParameters: MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<string> {
      return localVarFp
        .getTracemallocProfileTracemallocGet(
          requestParameters.topK,
          requestParameters.depth,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Get the Profile backtrace from Redis/disk
     * @summary Memory Profile
     * @param {MemoryProfilingApiMemoryProfileMemoryProfileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    memoryProfileMemoryProfileGet(
      requestParameters: MemoryProfilingApiMemoryProfileMemoryProfileGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MemoryProfilingTraceResponse> {
      return localVarFp
        .memoryProfileMemoryProfileGet(
          requestParameters.service,
          requestParameters.pid,
          requestParameters.persistenceMode,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Start profiling
     * @summary Start Profiling
     * @param {MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startProfilingMemoryProfileStartPost(
      requestParameters: MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MemoryProfilingStatusResponse> {
      return localVarFp
        .startProfilingMemoryProfileStartPost(
          requestParameters.memoryProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Start Tracemalloc
     * @param {MemoryProfilingApiStartTracemallocTracemallocStartPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startTracemallocTracemallocStartPost(
      requestParameters: MemoryProfilingApiStartTracemallocTracemallocStartPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .startTracemallocTracemallocStartPost(
          requestParameters.startTraceMalloc,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Stop profiling
     * @summary Stop Profiling
     * @param {MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopProfilingMemoryProfileStopPost(
      requestParameters: MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MemoryProfilingStatusResponse> {
      return localVarFp
        .stopProfilingMemoryProfileStopPost(
          requestParameters.memoryProfilingParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Stop Tracemalloc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopTracemallocTracemallocStopPost(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .stopTracemallocTracemallocStopPost(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for clearProfilingMemoryProfileClearPost operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest
 */
export interface MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest {
  /**
   *
   * @type {MemoryProfilingParams}
   * @memberof MemoryProfilingApiClearProfilingMemoryProfileClearPost
   */
  readonly memoryProfilingParams: MemoryProfilingParams;
}

/**
 * Request parameters for getTracemallocProfileTracemallocGet operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest
 */
export interface MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest {
  /**
   *
   * @type {number}
   * @memberof MemoryProfilingApiGetTracemallocProfileTracemallocGet
   */
  readonly topK?: number;

  /**
   *
   * @type {number}
   * @memberof MemoryProfilingApiGetTracemallocProfileTracemallocGet
   */
  readonly depth?: number;
}

/**
 * Request parameters for memoryProfileMemoryProfileGet operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiMemoryProfileMemoryProfileGetRequest
 */
export interface MemoryProfilingApiMemoryProfileMemoryProfileGetRequest {
  /**
   *
   * @type {string}
   * @memberof MemoryProfilingApiMemoryProfileMemoryProfileGet
   */
  readonly service: string;

  /**
   *
   * @type {string}
   * @memberof MemoryProfilingApiMemoryProfileMemoryProfileGet
   */
  readonly pid?: string;

  /**
   *
   * @type {PersistenceMode}
   * @memberof MemoryProfilingApiMemoryProfileMemoryProfileGet
   */
  readonly persistenceMode?: PersistenceMode;
}

/**
 * Request parameters for startProfilingMemoryProfileStartPost operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest
 */
export interface MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest {
  /**
   *
   * @type {MemoryProfilingParams}
   * @memberof MemoryProfilingApiStartProfilingMemoryProfileStartPost
   */
  readonly memoryProfilingParams: MemoryProfilingParams;
}

/**
 * Request parameters for startTracemallocTracemallocStartPost operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiStartTracemallocTracemallocStartPostRequest
 */
export interface MemoryProfilingApiStartTracemallocTracemallocStartPostRequest {
  /**
   *
   * @type {StartTraceMalloc}
   * @memberof MemoryProfilingApiStartTracemallocTracemallocStartPost
   */
  readonly startTraceMalloc: StartTraceMalloc;
}

/**
 * Request parameters for stopProfilingMemoryProfileStopPost operation in MemoryProfilingApi.
 * @export
 * @interface MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest
 */
export interface MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest {
  /**
   *
   * @type {MemoryProfilingParams}
   * @memberof MemoryProfilingApiStopProfilingMemoryProfileStopPost
   */
  readonly memoryProfilingParams: MemoryProfilingParams;
}

/**
 * MemoryProfilingApi - object-oriented interface
 * @export
 * @class MemoryProfilingApi
 * @extends {BaseAPI}
 */
export class MemoryProfilingApi extends BaseAPI {
  /**
   * Clear profiling clears the profiling stacktrace. The profiler will continue to profile if it is enabled. It has no impact on the profiler status.
   * @summary Clear Profiling
   * @param {MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public clearProfilingMemoryProfileClearPost(
    requestParameters: MemoryProfilingApiClearProfilingMemoryProfileClearPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .clearProfilingMemoryProfileClearPost(
        requestParameters.memoryProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Tracemalloc Profile
   * @param {MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public getTracemallocProfileTracemallocGet(
    requestParameters: MemoryProfilingApiGetTracemallocProfileTracemallocGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .getTracemallocProfileTracemallocGet(
        requestParameters.topK,
        requestParameters.depth,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get the Profile backtrace from Redis/disk
   * @summary Memory Profile
   * @param {MemoryProfilingApiMemoryProfileMemoryProfileGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public memoryProfileMemoryProfileGet(
    requestParameters: MemoryProfilingApiMemoryProfileMemoryProfileGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .memoryProfileMemoryProfileGet(
        requestParameters.service,
        requestParameters.pid,
        requestParameters.persistenceMode,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Start profiling
   * @summary Start Profiling
   * @param {MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public startProfilingMemoryProfileStartPost(
    requestParameters: MemoryProfilingApiStartProfilingMemoryProfileStartPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .startProfilingMemoryProfileStartPost(
        requestParameters.memoryProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start Tracemalloc
   * @param {MemoryProfilingApiStartTracemallocTracemallocStartPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public startTracemallocTracemallocStartPost(
    requestParameters: MemoryProfilingApiStartTracemallocTracemallocStartPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .startTracemallocTracemallocStartPost(
        requestParameters.startTraceMalloc,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Stop profiling
   * @summary Stop Profiling
   * @param {MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public stopProfilingMemoryProfileStopPost(
    requestParameters: MemoryProfilingApiStopProfilingMemoryProfileStopPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MemoryProfilingApiFp(this.configuration)
      .stopProfilingMemoryProfileStopPost(
        requestParameters.memoryProfilingParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stop Tracemalloc
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MemoryProfilingApi
   */
  public stopTracemallocTracemallocStopPost(options?: RawAxiosRequestConfig) {
    return MemoryProfilingApiFp(this.configuration)
      .stopTracemallocTracemallocStopPost(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Adds a user-defined metric to the DB.
     * @summary Add Metric
     * @param {number} nodeUid
     * @param {AddMetricRequest} addMetricRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addMetricNodesNodeUidAvailableMetricsPost: async (
      nodeUid: number,
      addMetricRequest: AddMetricRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addMetricNodesNodeUidAvailableMetricsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'addMetricRequest' is not null or undefined
      assertParamExists(
        'addMetricNodesNodeUidAvailableMetricsPost',
        'addMetricRequest',
        addMetricRequest,
      );
      const localVarPath = `/nodes/{node_uid}/available-metrics`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addMetricRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Compute Max F1 Macro Growth By Tag
     * @param {number} nodeUid
     * @param {number} [modelUid]
     * @param {string} [split]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {boolean} [applyPostprocessors]
     * @param {string} [label]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet: async (
      nodeUid: number,
      modelUid?: number,
      split?: string,
      excludeTagTypeUid?: Array<number>,
      applyPostprocessors?: boolean,
      label?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'computeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/max-f1-macro-growth`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelUid !== undefined) {
        localVarQueryParameter['model_uid'] = modelUid;
      }

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      if (label !== undefined) {
        localVarQueryParameter['label'] = label;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Compute Metrics By Tag
     * @param {number} nodeUid
     * @param {number} [modelUid]
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {Array<string>} [metric]
     * @param {boolean} [ignoreCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeMetricsByTagNodesNodeUidMetricsByTagGet: async (
      nodeUid: number,
      modelUid?: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      excludeTagTypeUid?: Array<number>,
      metric?: Array<string>,
      ignoreCache?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'computeMetricsByTagNodesNodeUidMetricsByTagGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/metrics-by-tag`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelUid !== undefined) {
        localVarQueryParameter['model_uid'] = modelUid;
      }

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (metric) {
        localVarQueryParameter['metric'] = metric;
      }

      if (ignoreCache !== undefined) {
        localVarQueryParameter['ignore_cache'] = ignoreCache;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Compute Metrics
     * @param {number} nodeUid
     * @param {number} [modelUid]
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {Array<string>} [metric]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [includeFullTrainSplit]
     * @param {boolean} [applyPostprocessors]
     * @param {number} [maxModelsToCompute]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeMetricsNodesNodeUidMetricsGet: async (
      nodeUid: number,
      modelUid?: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      metric?: Array<string>,
      ignoreCache?: boolean,
      includeFullTrainSplit?: boolean,
      applyPostprocessors?: boolean,
      maxModelsToCompute?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'computeMetricsNodesNodeUidMetricsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/metrics`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelUid !== undefined) {
        localVarQueryParameter['model_uid'] = modelUid;
      }

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (includeTagTypeUid) {
        localVarQueryParameter['include_tag_type_uid'] = includeTagTypeUid;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (metric) {
        localVarQueryParameter['metric'] = metric;
      }

      if (ignoreCache !== undefined) {
        localVarQueryParameter['ignore_cache'] = ignoreCache;
      }

      if (includeFullTrainSplit !== undefined) {
        localVarQueryParameter['include_full_train_split'] =
          includeFullTrainSplit;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      if (maxModelsToCompute !== undefined) {
        localVarQueryParameter['max_models_to_compute'] = maxModelsToCompute;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Doc Metrics
     * @param {number} nodeUid
     * @param {number} [modelUid]
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {Array<string>} [metric]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [includeFullTrainSplit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDocMetricsNodesNodeUidDocMetricsGet: async (
      nodeUid: number,
      modelUid?: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      metric?: Array<string>,
      ignoreCache?: boolean,
      includeFullTrainSplit?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchDocMetricsNodesNodeUidDocMetricsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/doc-metrics`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelUid !== undefined) {
        localVarQueryParameter['model_uid'] = modelUid;
      }

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (includeTagTypeUid) {
        localVarQueryParameter['include_tag_type_uid'] = includeTagTypeUid;
      }

      if (excludeTagTypeUid) {
        localVarQueryParameter['exclude_tag_type_uid'] = excludeTagTypeUid;
      }

      if (metric) {
        localVarQueryParameter['metric'] = metric;
      }

      if (ignoreCache !== undefined) {
        localVarQueryParameter['ignore_cache'] = ignoreCache;
      }

      if (includeFullTrainSplit !== undefined) {
        localVarQueryParameter['include_full_train_split'] =
          includeFullTrainSplit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Optimal Threshold And Prediciton
     * @param {number} nodeUid
     * @param {OptimalThresholdRequest} optimalThresholdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost:
      async (
        nodeUid: number,
        optimalThresholdRequest: OptimalThresholdRequest,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'optimalThresholdRequest' is not null or undefined
        assertParamExists(
          'getOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost',
          'optimalThresholdRequest',
          optimalThresholdRequest,
        );
        const localVarPath =
          `/nodes/{node_uid}/optimal-threshold-prediction`.replace(
            `{${'node_uid'}}`,
            encodeURIComponent(String(nodeUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          optimalThresholdRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Returns the list of available metrics which can be computed. There are some metrics which only work for binary tasks, and will return None as their value in multiclass tasks.
     * @summary List Available Metrics
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAvailableMetricsNodesNodeUidAvailableMetricsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listAvailableMetricsNodesNodeUidAvailableMetricsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/available-metrics`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Score
     * @param {number} nodeUid
     * @param {ScoreRequest} scoreRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scoreNodesNodeUidScorePost: async (
      nodeUid: number,
      scoreRequest: ScoreRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('scoreNodesNodeUidScorePost', 'nodeUid', nodeUid);
      // verify required parameter 'scoreRequest' is not null or undefined
      assertParamExists(
        'scoreNodesNodeUidScorePost',
        'scoreRequest',
        scoreRequest,
      );
      const localVarPath = `/nodes/{node_uid}/score`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        scoreRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration);
  return {
    /**
     * Adds a user-defined metric to the DB.
     * @summary Add Metric
     * @param {number} nodeUid
     * @param {AddMetricRequest} addMetricRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addMetricNodesNodeUidAvailableMetricsPost(
      nodeUid: number,
      addMetricRequest: AddMetricRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AddMetricResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addMetricNodesNodeUidAvailableMetricsPost(
          nodeUid,
          addMetricRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MetricsApi.addMetricNodesNodeUidAvailableMetricsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Compute Max F1 Macro Growth By Tag
     * @param {number} nodeUid
     * @param {number} [modelUid]
     * @param {string} [split]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {boolean} [applyPostprocessors]
     * @param {string} [label]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async computeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet(
      nodeUid: number,
      modelUid?: number,
      split?: string,
      excludeTagTypeUid?: Array<number>,
      applyPostprocessors?: boolean,
      label?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MaxF1MacroGrowthByTagsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.computeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet(
          nodeUid,
          modelUid,
          split,
          excludeTagTypeUid,
          applyPostprocessors,
          label,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MetricsApi.computeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Compute Metrics By Tag
     * @param {number} nodeUid
     * @param {number} [modelUid]
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {Array<string>} [metric]
     * @param {boolean} [ignoreCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async computeMetricsByTagNodesNodeUidMetricsByTagGet(
      nodeUid: number,
      modelUid?: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      excludeTagTypeUid?: Array<number>,
      metric?: Array<string>,
      ignoreCache?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MetricsByTagsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.computeMetricsByTagNodesNodeUidMetricsByTagGet(
          nodeUid,
          modelUid,
          split,
          startDate,
          endDate,
          excludeTagTypeUid,
          metric,
          ignoreCache,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MetricsApi.computeMetricsByTagNodesNodeUidMetricsByTagGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Compute Metrics
     * @param {number} nodeUid
     * @param {number} [modelUid]
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {Array<string>} [metric]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [includeFullTrainSplit]
     * @param {boolean} [applyPostprocessors]
     * @param {number} [maxModelsToCompute]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async computeMetricsNodesNodeUidMetricsGet(
      nodeUid: number,
      modelUid?: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      metric?: Array<string>,
      ignoreCache?: boolean,
      includeFullTrainSplit?: boolean,
      applyPostprocessors?: boolean,
      maxModelsToCompute?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.computeMetricsNodesNodeUidMetricsGet(
          nodeUid,
          modelUid,
          split,
          startDate,
          endDate,
          includeTagTypeUid,
          excludeTagTypeUid,
          metric,
          ignoreCache,
          includeFullTrainSplit,
          applyPostprocessors,
          maxModelsToCompute,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['MetricsApi.computeMetricsNodesNodeUidMetricsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Doc Metrics
     * @param {number} nodeUid
     * @param {number} [modelUid]
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<number>} [includeTagTypeUid]
     * @param {Array<number>} [excludeTagTypeUid]
     * @param {Array<string>} [metric]
     * @param {boolean} [ignoreCache]
     * @param {boolean} [includeFullTrainSplit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDocMetricsNodesNodeUidDocMetricsGet(
      nodeUid: number,
      modelUid?: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      includeTagTypeUid?: Array<number>,
      excludeTagTypeUid?: Array<number>,
      metric?: Array<string>,
      ignoreCache?: boolean,
      includeFullTrainSplit?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchDocMetricsNodesNodeUidDocMetricsGet(
          nodeUid,
          modelUid,
          split,
          startDate,
          endDate,
          includeTagTypeUid,
          excludeTagTypeUid,
          metric,
          ignoreCache,
          includeFullTrainSplit,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MetricsApi.fetchDocMetricsNodesNodeUidDocMetricsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Optimal Threshold And Prediciton
     * @param {number} nodeUid
     * @param {OptimalThresholdRequest} optimalThresholdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost(
      nodeUid: number,
      optimalThresholdRequest: OptimalThresholdRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OptimalThresholdResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost(
          nodeUid,
          optimalThresholdRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MetricsApi.getOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Returns the list of available metrics which can be computed. There are some metrics which only work for binary tasks, and will return None as their value in multiclass tasks.
     * @summary List Available Metrics
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listAvailableMetricsNodesNodeUidAvailableMetricsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listAvailableMetricsNodesNodeUidAvailableMetricsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'MetricsApi.listAvailableMetricsNodesNodeUidAvailableMetricsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Score
     * @param {number} nodeUid
     * @param {ScoreRequest} scoreRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scoreNodesNodeUidScorePost(
      nodeUid: number,
      scoreRequest: ScoreRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScoreResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.scoreNodesNodeUidScorePost(
          nodeUid,
          scoreRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['MetricsApi.scoreNodesNodeUidScorePost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MetricsApiFp(configuration);
  return {
    /**
     * Adds a user-defined metric to the DB.
     * @summary Add Metric
     * @param {MetricsApiAddMetricNodesNodeUidAvailableMetricsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addMetricNodesNodeUidAvailableMetricsPost(
      requestParameters: MetricsApiAddMetricNodesNodeUidAvailableMetricsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AddMetricResponse> {
      return localVarFp
        .addMetricNodesNodeUidAvailableMetricsPost(
          requestParameters.nodeUid,
          requestParameters.addMetricRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Compute Max F1 Macro Growth By Tag
     * @param {MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet(
      requestParameters: MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MaxF1MacroGrowthByTagsResponse> {
      return localVarFp
        .computeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.excludeTagTypeUid,
          requestParameters.applyPostprocessors,
          requestParameters.label,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Compute Metrics By Tag
     * @param {MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeMetricsByTagNodesNodeUidMetricsByTagGet(
      requestParameters: MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<MetricsByTagsResponse> {
      return localVarFp
        .computeMetricsByTagNodesNodeUidMetricsByTagGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          requestParameters.excludeTagTypeUid,
          requestParameters.metric,
          requestParameters.ignoreCache,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Compute Metrics
     * @param {MetricsApiComputeMetricsNodesNodeUidMetricsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    computeMetricsNodesNodeUidMetricsGet(
      requestParameters: MetricsApiComputeMetricsNodesNodeUidMetricsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .computeMetricsNodesNodeUidMetricsGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          requestParameters.includeTagTypeUid,
          requestParameters.excludeTagTypeUid,
          requestParameters.metric,
          requestParameters.ignoreCache,
          requestParameters.includeFullTrainSplit,
          requestParameters.applyPostprocessors,
          requestParameters.maxModelsToCompute,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Doc Metrics
     * @param {MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDocMetricsNodesNodeUidDocMetricsGet(
      requestParameters: MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchDocMetricsNodesNodeUidDocMetricsGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          requestParameters.includeTagTypeUid,
          requestParameters.excludeTagTypeUid,
          requestParameters.metric,
          requestParameters.ignoreCache,
          requestParameters.includeFullTrainSplit,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Optimal Threshold And Prediciton
     * @param {MetricsApiGetOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost(
      requestParameters: MetricsApiGetOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<OptimalThresholdResponse> {
      return localVarFp
        .getOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost(
          requestParameters.nodeUid,
          requestParameters.optimalThresholdRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Returns the list of available metrics which can be computed. There are some metrics which only work for binary tasks, and will return None as their value in multiclass tasks.
     * @summary List Available Metrics
     * @param {MetricsApiListAvailableMetricsNodesNodeUidAvailableMetricsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAvailableMetricsNodesNodeUidAvailableMetricsGet(
      requestParameters: MetricsApiListAvailableMetricsNodesNodeUidAvailableMetricsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .listAvailableMetricsNodesNodeUidAvailableMetricsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Score
     * @param {MetricsApiScoreNodesNodeUidScorePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scoreNodesNodeUidScorePost(
      requestParameters: MetricsApiScoreNodesNodeUidScorePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ScoreResponse> {
      return localVarFp
        .scoreNodesNodeUidScorePost(
          requestParameters.nodeUid,
          requestParameters.scoreRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addMetricNodesNodeUidAvailableMetricsPost operation in MetricsApi.
 * @export
 * @interface MetricsApiAddMetricNodesNodeUidAvailableMetricsPostRequest
 */
export interface MetricsApiAddMetricNodesNodeUidAvailableMetricsPostRequest {
  /**
   *
   * @type {number}
   * @memberof MetricsApiAddMetricNodesNodeUidAvailableMetricsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {AddMetricRequest}
   * @memberof MetricsApiAddMetricNodesNodeUidAvailableMetricsPost
   */
  readonly addMetricRequest: AddMetricRequest;
}

/**
 * Request parameters for computeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet operation in MetricsApi.
 * @export
 * @interface MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGetRequest
 */
export interface MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGetRequest {
  /**
   *
   * @type {number}
   * @memberof MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet
   */
  readonly modelUid?: number;

  /**
   *
   * @type {string}
   * @memberof MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet
   */
  readonly split?: string;

  /**
   *
   * @type {Array<number>}
   * @memberof MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet
   */
  readonly excludeTagTypeUid?: Array<number>;

  /**
   *
   * @type {boolean}
   * @memberof MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet
   */
  readonly applyPostprocessors?: boolean;

  /**
   *
   * @type {string}
   * @memberof MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet
   */
  readonly label?: string;
}

/**
 * Request parameters for computeMetricsByTagNodesNodeUidMetricsByTagGet operation in MetricsApi.
 * @export
 * @interface MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGetRequest
 */
export interface MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGetRequest {
  /**
   *
   * @type {number}
   * @memberof MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGet
   */
  readonly modelUid?: number;

  /**
   *
   * @type {string}
   * @memberof MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGet
   */
  readonly endDate?: string;

  /**
   *
   * @type {Array<number>}
   * @memberof MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGet
   */
  readonly excludeTagTypeUid?: Array<number>;

  /**
   *
   * @type {Array<string>}
   * @memberof MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGet
   */
  readonly metric?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGet
   */
  readonly ignoreCache?: boolean;
}

/**
 * Request parameters for computeMetricsNodesNodeUidMetricsGet operation in MetricsApi.
 * @export
 * @interface MetricsApiComputeMetricsNodesNodeUidMetricsGetRequest
 */
export interface MetricsApiComputeMetricsNodesNodeUidMetricsGetRequest {
  /**
   *
   * @type {number}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly modelUid?: number;

  /**
   *
   * @type {string}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly endDate?: string;

  /**
   *
   * @type {Array<number>}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly includeTagTypeUid?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly excludeTagTypeUid?: Array<number>;

  /**
   *
   * @type {Array<string>}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly metric?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly ignoreCache?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly includeFullTrainSplit?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly applyPostprocessors?: boolean;

  /**
   *
   * @type {number}
   * @memberof MetricsApiComputeMetricsNodesNodeUidMetricsGet
   */
  readonly maxModelsToCompute?: number;
}

/**
 * Request parameters for fetchDocMetricsNodesNodeUidDocMetricsGet operation in MetricsApi.
 * @export
 * @interface MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGetRequest
 */
export interface MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGetRequest {
  /**
   *
   * @type {number}
   * @memberof MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGet
   */
  readonly modelUid?: number;

  /**
   *
   * @type {string}
   * @memberof MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGet
   */
  readonly endDate?: string;

  /**
   *
   * @type {Array<number>}
   * @memberof MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGet
   */
  readonly includeTagTypeUid?: Array<number>;

  /**
   *
   * @type {Array<number>}
   * @memberof MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGet
   */
  readonly excludeTagTypeUid?: Array<number>;

  /**
   *
   * @type {Array<string>}
   * @memberof MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGet
   */
  readonly metric?: Array<string>;

  /**
   *
   * @type {boolean}
   * @memberof MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGet
   */
  readonly ignoreCache?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGet
   */
  readonly includeFullTrainSplit?: boolean;
}

/**
 * Request parameters for getOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost operation in MetricsApi.
 * @export
 * @interface MetricsApiGetOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPostRequest
 */
export interface MetricsApiGetOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPostRequest {
  /**
   *
   * @type {number}
   * @memberof MetricsApiGetOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {OptimalThresholdRequest}
   * @memberof MetricsApiGetOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost
   */
  readonly optimalThresholdRequest: OptimalThresholdRequest;
}

/**
 * Request parameters for listAvailableMetricsNodesNodeUidAvailableMetricsGet operation in MetricsApi.
 * @export
 * @interface MetricsApiListAvailableMetricsNodesNodeUidAvailableMetricsGetRequest
 */
export interface MetricsApiListAvailableMetricsNodesNodeUidAvailableMetricsGetRequest {
  /**
   *
   * @type {number}
   * @memberof MetricsApiListAvailableMetricsNodesNodeUidAvailableMetricsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for scoreNodesNodeUidScorePost operation in MetricsApi.
 * @export
 * @interface MetricsApiScoreNodesNodeUidScorePostRequest
 */
export interface MetricsApiScoreNodesNodeUidScorePostRequest {
  /**
   *
   * @type {number}
   * @memberof MetricsApiScoreNodesNodeUidScorePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ScoreRequest}
   * @memberof MetricsApiScoreNodesNodeUidScorePost
   */
  readonly scoreRequest: ScoreRequest;
}

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
  /**
   * Adds a user-defined metric to the DB.
   * @summary Add Metric
   * @param {MetricsApiAddMetricNodesNodeUidAvailableMetricsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricsApi
   */
  public addMetricNodesNodeUidAvailableMetricsPost(
    requestParameters: MetricsApiAddMetricNodesNodeUidAvailableMetricsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MetricsApiFp(this.configuration)
      .addMetricNodesNodeUidAvailableMetricsPost(
        requestParameters.nodeUid,
        requestParameters.addMetricRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Compute Max F1 Macro Growth By Tag
   * @param {MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricsApi
   */
  public computeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet(
    requestParameters: MetricsApiComputeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MetricsApiFp(this.configuration)
      .computeMaxF1MacroGrowthByTagNodesNodeUidMaxF1MacroGrowthGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.excludeTagTypeUid,
        requestParameters.applyPostprocessors,
        requestParameters.label,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Compute Metrics By Tag
   * @param {MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricsApi
   */
  public computeMetricsByTagNodesNodeUidMetricsByTagGet(
    requestParameters: MetricsApiComputeMetricsByTagNodesNodeUidMetricsByTagGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MetricsApiFp(this.configuration)
      .computeMetricsByTagNodesNodeUidMetricsByTagGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.excludeTagTypeUid,
        requestParameters.metric,
        requestParameters.ignoreCache,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Compute Metrics
   * @param {MetricsApiComputeMetricsNodesNodeUidMetricsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricsApi
   */
  public computeMetricsNodesNodeUidMetricsGet(
    requestParameters: MetricsApiComputeMetricsNodesNodeUidMetricsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MetricsApiFp(this.configuration)
      .computeMetricsNodesNodeUidMetricsGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.includeTagTypeUid,
        requestParameters.excludeTagTypeUid,
        requestParameters.metric,
        requestParameters.ignoreCache,
        requestParameters.includeFullTrainSplit,
        requestParameters.applyPostprocessors,
        requestParameters.maxModelsToCompute,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Doc Metrics
   * @param {MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricsApi
   */
  public fetchDocMetricsNodesNodeUidDocMetricsGet(
    requestParameters: MetricsApiFetchDocMetricsNodesNodeUidDocMetricsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MetricsApiFp(this.configuration)
      .fetchDocMetricsNodesNodeUidDocMetricsGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.includeTagTypeUid,
        requestParameters.excludeTagTypeUid,
        requestParameters.metric,
        requestParameters.ignoreCache,
        requestParameters.includeFullTrainSplit,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Optimal Threshold And Prediciton
   * @param {MetricsApiGetOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricsApi
   */
  public getOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost(
    requestParameters: MetricsApiGetOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MetricsApiFp(this.configuration)
      .getOptimalThresholdAndPredicitonNodesNodeUidOptimalThresholdPredictionPost(
        requestParameters.nodeUid,
        requestParameters.optimalThresholdRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Returns the list of available metrics which can be computed. There are some metrics which only work for binary tasks, and will return None as their value in multiclass tasks.
   * @summary List Available Metrics
   * @param {MetricsApiListAvailableMetricsNodesNodeUidAvailableMetricsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricsApi
   */
  public listAvailableMetricsNodesNodeUidAvailableMetricsGet(
    requestParameters: MetricsApiListAvailableMetricsNodesNodeUidAvailableMetricsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MetricsApiFp(this.configuration)
      .listAvailableMetricsNodesNodeUidAvailableMetricsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Score
   * @param {MetricsApiScoreNodesNodeUidScorePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetricsApi
   */
  public scoreNodesNodeUidScorePost(
    requestParameters: MetricsApiScoreNodesNodeUidScorePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MetricsApiFp(this.configuration)
      .scoreNodesNodeUidScorePost(
        requestParameters.nodeUid,
        requestParameters.scoreRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ModelRegistryApi - axios parameter creator
 * @export
 */
export const ModelRegistryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Model Registry
     * @param {CreateModelRegistryParams} createModelRegistryParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModelRegistryModelRegistriesPost: async (
      createModelRegistryParams: CreateModelRegistryParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createModelRegistryParams' is not null or undefined
      assertParamExists(
        'createModelRegistryModelRegistriesPost',
        'createModelRegistryParams',
        createModelRegistryParams,
      );
      const localVarPath = `/model-registries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createModelRegistryParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Model Registry
     * @param {number} modelRegistryUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModelRegistryModelRegistriesModelRegistryUidDelete: async (
      modelRegistryUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelRegistryUid' is not null or undefined
      assertParamExists(
        'deleteModelRegistryModelRegistriesModelRegistryUidDelete',
        'modelRegistryUid',
        modelRegistryUid,
      );
      const localVarPath = `/model-registries/{model_registry_uid}`.replace(
        `{${'model_registry_uid'}}`,
        encodeURIComponent(String(modelRegistryUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Model Registry
     * @param {number} modelRegistryUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelRegistryModelRegistriesModelRegistryUidGet: async (
      modelRegistryUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelRegistryUid' is not null or undefined
      assertParamExists(
        'getModelRegistryModelRegistriesModelRegistryUidGet',
        'modelRegistryUid',
        modelRegistryUid,
      );
      const localVarPath = `/model-registries/{model_registry_uid}`.replace(
        `{${'model_registry_uid'}}`,
        encodeURIComponent(String(modelRegistryUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Model Registries
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listModelRegistriesModelRegistriesGet: async (
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/model-registries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModelRegistryApi - functional programming interface
 * @export
 */
export const ModelRegistryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ModelRegistryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Model Registry
     * @param {CreateModelRegistryParams} createModelRegistryParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createModelRegistryModelRegistriesPost(
      createModelRegistryParams: CreateModelRegistryParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ModelRegistryMetadata>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createModelRegistryModelRegistriesPost(
          createModelRegistryParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelRegistryApi.createModelRegistryModelRegistriesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Model Registry
     * @param {number} modelRegistryUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteModelRegistryModelRegistriesModelRegistryUidDelete(
      modelRegistryUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteModelRegistryModelRegistriesModelRegistryUidDelete(
          modelRegistryUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelRegistryApi.deleteModelRegistryModelRegistriesModelRegistryUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Model Registry
     * @param {number} modelRegistryUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModelRegistryModelRegistriesModelRegistryUidGet(
      modelRegistryUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ModelRegistryMetadata>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getModelRegistryModelRegistriesModelRegistryUidGet(
          modelRegistryUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelRegistryApi.getModelRegistryModelRegistriesModelRegistryUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Model Registries
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listModelRegistriesModelRegistriesGet(
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ModelRegistryListResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listModelRegistriesModelRegistriesGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelRegistryApi.listModelRegistriesModelRegistriesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ModelRegistryApi - factory interface
 * @export
 */
export const ModelRegistryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ModelRegistryApiFp(configuration);
  return {
    /**
     *
     * @summary Create Model Registry
     * @param {ModelRegistryApiCreateModelRegistryModelRegistriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModelRegistryModelRegistriesPost(
      requestParameters: ModelRegistryApiCreateModelRegistryModelRegistriesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ModelRegistryMetadata> {
      return localVarFp
        .createModelRegistryModelRegistriesPost(
          requestParameters.createModelRegistryParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Model Registry
     * @param {ModelRegistryApiDeleteModelRegistryModelRegistriesModelRegistryUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModelRegistryModelRegistriesModelRegistryUidDelete(
      requestParameters: ModelRegistryApiDeleteModelRegistryModelRegistriesModelRegistryUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteModelRegistryModelRegistriesModelRegistryUidDelete(
          requestParameters.modelRegistryUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Model Registry
     * @param {ModelRegistryApiGetModelRegistryModelRegistriesModelRegistryUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelRegistryModelRegistriesModelRegistryUidGet(
      requestParameters: ModelRegistryApiGetModelRegistryModelRegistriesModelRegistryUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ModelRegistryMetadata> {
      return localVarFp
        .getModelRegistryModelRegistriesModelRegistryUidGet(
          requestParameters.modelRegistryUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Model Registries
     * @param {ModelRegistryApiListModelRegistriesModelRegistriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listModelRegistriesModelRegistriesGet(
      requestParameters: ModelRegistryApiListModelRegistriesModelRegistriesGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ModelRegistryListResponse> {
      return localVarFp
        .listModelRegistriesModelRegistriesGet(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createModelRegistryModelRegistriesPost operation in ModelRegistryApi.
 * @export
 * @interface ModelRegistryApiCreateModelRegistryModelRegistriesPostRequest
 */
export interface ModelRegistryApiCreateModelRegistryModelRegistriesPostRequest {
  /**
   *
   * @type {CreateModelRegistryParams}
   * @memberof ModelRegistryApiCreateModelRegistryModelRegistriesPost
   */
  readonly createModelRegistryParams: CreateModelRegistryParams;
}

/**
 * Request parameters for deleteModelRegistryModelRegistriesModelRegistryUidDelete operation in ModelRegistryApi.
 * @export
 * @interface ModelRegistryApiDeleteModelRegistryModelRegistriesModelRegistryUidDeleteRequest
 */
export interface ModelRegistryApiDeleteModelRegistryModelRegistriesModelRegistryUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof ModelRegistryApiDeleteModelRegistryModelRegistriesModelRegistryUidDelete
   */
  readonly modelRegistryUid: number;
}

/**
 * Request parameters for getModelRegistryModelRegistriesModelRegistryUidGet operation in ModelRegistryApi.
 * @export
 * @interface ModelRegistryApiGetModelRegistryModelRegistriesModelRegistryUidGetRequest
 */
export interface ModelRegistryApiGetModelRegistryModelRegistriesModelRegistryUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof ModelRegistryApiGetModelRegistryModelRegistriesModelRegistryUidGet
   */
  readonly modelRegistryUid: number;
}

/**
 * Request parameters for listModelRegistriesModelRegistriesGet operation in ModelRegistryApi.
 * @export
 * @interface ModelRegistryApiListModelRegistriesModelRegistriesGetRequest
 */
export interface ModelRegistryApiListModelRegistriesModelRegistriesGetRequest {
  /**
   *
   * @type {number}
   * @memberof ModelRegistryApiListModelRegistriesModelRegistriesGet
   */
  readonly workspaceUid?: number;
}

/**
 * ModelRegistryApi - object-oriented interface
 * @export
 * @class ModelRegistryApi
 * @extends {BaseAPI}
 */
export class ModelRegistryApi extends BaseAPI {
  /**
   *
   * @summary Create Model Registry
   * @param {ModelRegistryApiCreateModelRegistryModelRegistriesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelRegistryApi
   */
  public createModelRegistryModelRegistriesPost(
    requestParameters: ModelRegistryApiCreateModelRegistryModelRegistriesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelRegistryApiFp(this.configuration)
      .createModelRegistryModelRegistriesPost(
        requestParameters.createModelRegistryParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Model Registry
   * @param {ModelRegistryApiDeleteModelRegistryModelRegistriesModelRegistryUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelRegistryApi
   */
  public deleteModelRegistryModelRegistriesModelRegistryUidDelete(
    requestParameters: ModelRegistryApiDeleteModelRegistryModelRegistriesModelRegistryUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelRegistryApiFp(this.configuration)
      .deleteModelRegistryModelRegistriesModelRegistryUidDelete(
        requestParameters.modelRegistryUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Model Registry
   * @param {ModelRegistryApiGetModelRegistryModelRegistriesModelRegistryUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelRegistryApi
   */
  public getModelRegistryModelRegistriesModelRegistryUidGet(
    requestParameters: ModelRegistryApiGetModelRegistryModelRegistriesModelRegistryUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelRegistryApiFp(this.configuration)
      .getModelRegistryModelRegistriesModelRegistryUidGet(
        requestParameters.modelRegistryUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Model Registries
   * @param {ModelRegistryApiListModelRegistriesModelRegistriesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelRegistryApi
   */
  public listModelRegistriesModelRegistriesGet(
    requestParameters: ModelRegistryApiListModelRegistriesModelRegistriesGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return ModelRegistryApiFp(this.configuration)
      .listModelRegistriesModelRegistriesGet(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ModelsApi - axios parameter creator
 * @export
 */
export const ModelsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Backfill Predictions
     * @param {number} nodeUid
     * @param {Array<number>} datasourceUids The UIDs of the datasources to backfill
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    backfillPredictionsNodesNodeUidModelsBackfillPredictionsPost: async (
      nodeUid: number,
      datasourceUids: Array<number>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'backfillPredictionsNodesNodeUidModelsBackfillPredictionsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'datasourceUids' is not null or undefined
      assertParamExists(
        'backfillPredictionsNodesNodeUidModelsBackfillPredictionsPost',
        'datasourceUids',
        datasourceUids,
      );
      const localVarPath =
        `/nodes/{node_uid}/models-backfill-predictions`.replace(
          `{${'node_uid'}}`,
          encodeURIComponent(String(nodeUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (datasourceUids) {
        localVarQueryParameter['datasource_uids'] = datasourceUids;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Commit a model_uid as the \'best\' model for a task.
     * @summary Commit Model Endpoint
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitModelEndpointNodesNodeUidModelsModelUidCommitPost: async (
      nodeUid: number,
      modelUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'commitModelEndpointNodesNodeUidModelsModelUidCommitPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'commitModelEndpointNodesNodeUidModelsModelUidCommitPost',
        'modelUid',
        modelUid,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}/commit`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Automl Trained Configs
     * @param {number} nodeUid
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet: async (
      nodeUid: number,
      modelId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists(
        'fetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet',
        'modelId',
        modelId,
      );
      const localVarPath = `/nodes/{node_uid}/automl_trained_configs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelId !== undefined) {
        localVarQueryParameter['model_id'] = modelId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch a single model based on a unique identifier
     * @summary Fetch Model
     * @param {number} nodeUid
     * @param {number} [modelUid]
     * @param {string} [modelName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchModelNodesNodeUidModelGet: async (
      nodeUid: number,
      modelUid?: number,
      modelName?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('fetchModelNodesNodeUidModelGet', 'nodeUid', nodeUid);
      const localVarPath = `/nodes/{node_uid}/model`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelUid !== undefined) {
        localVarQueryParameter['model_uid'] = modelUid;
      }

      if (modelName !== undefined) {
        localVarQueryParameter['model_name'] = modelName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Models
     * @param {number} nodeUid
     * @param {boolean} [isActive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchModelsNodesNodeUidModelsGet: async (
      nodeUid: number,
      isActive?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('fetchModelsNodesNodeUidModelsGet', 'nodeUid', nodeUid);
      const localVarPath = `/nodes/{node_uid}/models`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isActive !== undefined) {
        localVarQueryParameter['is_active'] = isActive;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generate Automl Model And Train
     * @param {number} nodeUid
     * @param {AutoMLTrainParams} autoMLTrainParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateAutomlModelAndTrainNodesNodeUidModelsAutomlPost: async (
      nodeUid: number,
      autoMLTrainParams: AutoMLTrainParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'generateAutomlModelAndTrainNodesNodeUidModelsAutomlPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'autoMLTrainParams' is not null or undefined
      assertParamExists(
        'generateAutomlModelAndTrainNodesNodeUidModelsAutomlPost',
        'autoMLTrainParams',
        autoMLTrainParams,
      );
      const localVarPath = `/nodes/{node_uid}/models/automl`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        autoMLTrainParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch the committed model for a particular task.
     * @summary Get Committed Model
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommittedModelNodesNodeUidCommittedModelGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getCommittedModelNodesNodeUidCommittedModelGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/committed-model`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Featurizer Columns
     * @param {FeaturizerColumnsArgs} featurizerColumnsArgs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeaturizerColumnsFeaturizerColumnsPost: async (
      featurizerColumnsArgs: FeaturizerColumnsArgs,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'featurizerColumnsArgs' is not null or undefined
      assertParamExists(
        'getFeaturizerColumnsFeaturizerColumnsPost',
        'featurizerColumnsArgs',
        featurizerColumnsArgs,
      );
      const localVarPath = `/featurizer-columns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        featurizerColumnsArgs,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Predict
     * @param {number} nodeUid
     * @param {RegisterPredictModelJson} registerPredictModelJson
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    predictNodesNodeUidModelsPredictPost: async (
      nodeUid: number,
      registerPredictModelJson: RegisterPredictModelJson,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'predictNodesNodeUidModelsPredictPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'registerPredictModelJson' is not null or undefined
      assertParamExists(
        'predictNodesNodeUidModelsPredictPost',
        'registerPredictModelJson',
        registerPredictModelJson,
      );
      const localVarPath = `/nodes/{node_uid}/models-predict`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerPredictModelJson,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register Ingest Model
     * @param {number} nodeUid
     * @param {RegisterIngestModelParams} registerIngestModelParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerIngestModelNodesNodeUidRegisterIngestModelPost: async (
      nodeUid: number,
      registerIngestModelParams: RegisterIngestModelParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'registerIngestModelNodesNodeUidRegisterIngestModelPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'registerIngestModelParams' is not null or undefined
      assertParamExists(
        'registerIngestModelNodesNodeUidRegisterIngestModelPost',
        'registerIngestModelParams',
        registerIngestModelParams,
      );
      const localVarPath = `/nodes/{node_uid}/register_ingest_model`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerIngestModelParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register Model
     * @param {number} nodeUid
     * @param {ModelInputPayload} modelInputPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerModelNodesNodeUidModelsPost: async (
      nodeUid: number,
      modelInputPayload: ModelInputPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'registerModelNodesNodeUidModelsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelInputPayload' is not null or undefined
      assertParamExists(
        'registerModelNodesNodeUidModelsPost',
        'modelInputPayload',
        modelInputPayload,
      );
      const localVarPath = `/nodes/{node_uid}/models`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        modelInputPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register Predict
     * @param {number} nodeUid
     * @param {RegisterPredictModelJson} registerPredictModelJson
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerPredictNodesNodeUidModelsRegisterPredictPost: async (
      nodeUid: number,
      registerPredictModelJson: RegisterPredictModelJson,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'registerPredictNodesNodeUidModelsRegisterPredictPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'registerPredictModelJson' is not null or undefined
      assertParamExists(
        'registerPredictNodesNodeUidModelsRegisterPredictPost',
        'registerPredictModelJson',
        registerPredictModelJson,
      );
      const localVarPath = `/nodes/{node_uid}/models/register_predict`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerPredictModelJson,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove a model for given task.
     * @summary Remove Model
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeModelNodesNodeUidModelsModelUidDelete: async (
      nodeUid: number,
      modelUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'removeModelNodesNodeUidModelsModelUidDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'removeModelNodesNodeUidModelsModelUidDelete',
        'modelUid',
        modelUid,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrain Model
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrainModelNodesNodeUidModelsModelUidRetrainPost: async (
      nodeUid: number,
      modelUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'retrainModelNodesNodeUidModelsModelUidRetrainPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'retrainModelNodesNodeUidModelsModelUidRetrainPost',
        'modelUid',
        modelUid,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}/retrain`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stale Model Values
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staleModelValuesNodesNodeUidModelsModelUidStalenessGet: async (
      nodeUid: number,
      modelUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'staleModelValuesNodesNodeUidModelsModelUidStalenessGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'staleModelValuesNodesNodeUidModelsModelUidStalenessGet',
        'modelUid',
        modelUid,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}/staleness`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * ... \"featurizers\": [     {         \"op_type\": \"StandardScaler\",         \"op_config\": {             \"field\": \"Income\",             \"target_field\": \"Income_scaled\",             \"mean\": 0,             \"std\": 1         },         \"trainable\": false,     },     {         \"op_type\": \"StandardScaler\",         \"fit_config\": {             \"field\": \"Income\",             \"target_field\": \"Income_scaled\",         },         \"trainable\": true,     }, ]
     * @summary Train Register Predict
     * @param {number} nodeUid
     * @param {TrainModelArgs} trainModelArgs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trainRegisterPredictNodesNodeUidModelsTrainPost: async (
      nodeUid: number,
      trainModelArgs: TrainModelArgs,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'trainRegisterPredictNodesNodeUidModelsTrainPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'trainModelArgs' is not null or undefined
      assertParamExists(
        'trainRegisterPredictNodesNodeUidModelsTrainPost',
        'trainModelArgs',
        trainModelArgs,
      );
      const localVarPath = `/nodes/{node_uid}/models/train`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        trainModelArgs,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Model Config
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {UpdateModelConfigParams} updateModelConfigParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModelConfigNodesNodeUidModelsModelUidPatch: async (
      nodeUid: number,
      modelUid: number,
      updateModelConfigParams: UpdateModelConfigParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'updateModelConfigNodesNodeUidModelsModelUidPatch',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'updateModelConfigNodesNodeUidModelsModelUidPatch',
        'modelUid',
        modelUid,
      );
      // verify required parameter 'updateModelConfigParams' is not null or undefined
      assertParamExists(
        'updateModelConfigNodesNodeUidModelsModelUidPatch',
        'updateModelConfigParams',
        updateModelConfigParams,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateModelConfigParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Model
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {UpdateModelParams} updateModelParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModelNodesNodeUidModelsModelUidPut: async (
      nodeUid: number,
      modelUid: number,
      updateModelParams: UpdateModelParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'updateModelNodesNodeUidModelsModelUidPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'updateModelNodesNodeUidModelsModelUidPut',
        'modelUid',
        modelUid,
      );
      // verify required parameter 'updateModelParams' is not null or undefined
      assertParamExists(
        'updateModelNodesNodeUidModelsModelUidPut',
        'updateModelParams',
        updateModelParams,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateModelParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModelsApi - functional programming interface
 * @export
 */
export const ModelsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ModelsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Backfill Predictions
     * @param {number} nodeUid
     * @param {Array<number>} datasourceUids The UIDs of the datasources to backfill
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async backfillPredictionsNodesNodeUidModelsBackfillPredictionsPost(
      nodeUid: number,
      datasourceUids: Array<number>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.backfillPredictionsNodesNodeUidModelsBackfillPredictionsPost(
          nodeUid,
          datasourceUids,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.backfillPredictionsNodesNodeUidModelsBackfillPredictionsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Commit a model_uid as the \'best\' model for a task.
     * @summary Commit Model Endpoint
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commitModelEndpointNodesNodeUidModelsModelUidCommitPost(
      nodeUid: number,
      modelUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commitModelEndpointNodesNodeUidModelsModelUidCommitPost(
          nodeUid,
          modelUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.commitModelEndpointNodesNodeUidModelsModelUidCommitPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Automl Trained Configs
     * @param {number} nodeUid
     * @param {string} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet(
      nodeUid: number,
      modelId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: object }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet(
          nodeUid,
          modelId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.fetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch a single model based on a unique identifier
     * @summary Fetch Model
     * @param {number} nodeUid
     * @param {number} [modelUid]
     * @param {string} [modelName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchModelNodesNodeUidModelGet(
      nodeUid: number,
      modelUid?: number,
      modelName?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FetchModelResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchModelNodesNodeUidModelGet(
          nodeUid,
          modelUid,
          modelName,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ModelsApi.fetchModelNodesNodeUidModelGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Models
     * @param {number} nodeUid
     * @param {boolean} [isActive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchModelsNodesNodeUidModelsGet(
      nodeUid: number,
      isActive?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ModelInfo>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchModelsNodesNodeUidModelsGet(
          nodeUid,
          isActive,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ModelsApi.fetchModelsNodesNodeUidModelsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Generate Automl Model And Train
     * @param {number} nodeUid
     * @param {AutoMLTrainParams} autoMLTrainParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateAutomlModelAndTrainNodesNodeUidModelsAutomlPost(
      nodeUid: number,
      autoMLTrainParams: AutoMLTrainParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.generateAutomlModelAndTrainNodesNodeUidModelsAutomlPost(
          nodeUid,
          autoMLTrainParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.generateAutomlModelAndTrainNodesNodeUidModelsAutomlPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch the committed model for a particular task.
     * @summary Get Committed Model
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommittedModelNodesNodeUidCommittedModelGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCommittedModelNodesNodeUidCommittedModelGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.getCommittedModelNodesNodeUidCommittedModelGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Featurizer Columns
     * @param {FeaturizerColumnsArgs} featurizerColumnsArgs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFeaturizerColumnsFeaturizerColumnsPost(
      featurizerColumnsArgs: FeaturizerColumnsArgs,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFeaturizerColumnsFeaturizerColumnsPost(
          featurizerColumnsArgs,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.getFeaturizerColumnsFeaturizerColumnsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Predict
     * @param {number} nodeUid
     * @param {RegisterPredictModelJson} registerPredictModelJson
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async predictNodesNodeUidModelsPredictPost(
      nodeUid: number,
      registerPredictModelJson: RegisterPredictModelJson,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.predictNodesNodeUidModelsPredictPost(
          nodeUid,
          registerPredictModelJson,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ModelsApi.predictNodesNodeUidModelsPredictPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Register Ingest Model
     * @param {number} nodeUid
     * @param {RegisterIngestModelParams} registerIngestModelParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerIngestModelNodesNodeUidRegisterIngestModelPost(
      nodeUid: number,
      registerIngestModelParams: RegisterIngestModelParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.registerIngestModelNodesNodeUidRegisterIngestModelPost(
          nodeUid,
          registerIngestModelParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.registerIngestModelNodesNodeUidRegisterIngestModelPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Register Model
     * @param {number} nodeUid
     * @param {ModelInputPayload} modelInputPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerModelNodesNodeUidModelsPost(
      nodeUid: number,
      modelInputPayload: ModelInputPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.registerModelNodesNodeUidModelsPost(
          nodeUid,
          modelInputPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['ModelsApi.registerModelNodesNodeUidModelsPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Register Predict
     * @param {number} nodeUid
     * @param {RegisterPredictModelJson} registerPredictModelJson
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerPredictNodesNodeUidModelsRegisterPredictPost(
      nodeUid: number,
      registerPredictModelJson: RegisterPredictModelJson,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CustomTrainedModelResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.registerPredictNodesNodeUidModelsRegisterPredictPost(
          nodeUid,
          registerPredictModelJson,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.registerPredictNodesNodeUidModelsRegisterPredictPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Remove a model for given task.
     * @summary Remove Model
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeModelNodesNodeUidModelsModelUidDelete(
      nodeUid: number,
      modelUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteModelResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeModelNodesNodeUidModelsModelUidDelete(
          nodeUid,
          modelUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.removeModelNodesNodeUidModelsModelUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Retrain Model
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrainModelNodesNodeUidModelsModelUidRetrainPost(
      nodeUid: number,
      modelUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ModelTrainingResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.retrainModelNodesNodeUidModelsModelUidRetrainPost(
          nodeUid,
          modelUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.retrainModelNodesNodeUidModelsModelUidRetrainPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Stale Model Values
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staleModelValuesNodesNodeUidModelsModelUidStalenessGet(
      nodeUid: number,
      modelUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StaleModelValuesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.staleModelValuesNodesNodeUidModelsModelUidStalenessGet(
          nodeUid,
          modelUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.staleModelValuesNodesNodeUidModelsModelUidStalenessGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * ... \"featurizers\": [     {         \"op_type\": \"StandardScaler\",         \"op_config\": {             \"field\": \"Income\",             \"target_field\": \"Income_scaled\",             \"mean\": 0,             \"std\": 1         },         \"trainable\": false,     },     {         \"op_type\": \"StandardScaler\",         \"fit_config\": {             \"field\": \"Income\",             \"target_field\": \"Income_scaled\",         },         \"trainable\": true,     }, ]
     * @summary Train Register Predict
     * @param {number} nodeUid
     * @param {TrainModelArgs} trainModelArgs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trainRegisterPredictNodesNodeUidModelsTrainPost(
      nodeUid: number,
      trainModelArgs: TrainModelArgs,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ModelTrainingResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.trainRegisterPredictNodesNodeUidModelsTrainPost(
          nodeUid,
          trainModelArgs,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.trainRegisterPredictNodesNodeUidModelsTrainPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Model Config
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {UpdateModelConfigParams} updateModelConfigParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateModelConfigNodesNodeUidModelsModelUidPatch(
      nodeUid: number,
      modelUid: number,
      updateModelConfigParams: UpdateModelConfigParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateModelConfigNodesNodeUidModelsModelUidPatch(
          nodeUid,
          modelUid,
          updateModelConfigParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.updateModelConfigNodesNodeUidModelsModelUidPatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Model
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {UpdateModelParams} updateModelParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateModelNodesNodeUidModelsModelUidPut(
      nodeUid: number,
      modelUid: number,
      updateModelParams: UpdateModelParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateModelNodesNodeUidModelsModelUidPut(
          nodeUid,
          modelUid,
          updateModelParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ModelsApi.updateModelNodesNodeUidModelsModelUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ModelsApi - factory interface
 * @export
 */
export const ModelsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ModelsApiFp(configuration);
  return {
    /**
     *
     * @summary Backfill Predictions
     * @param {ModelsApiBackfillPredictionsNodesNodeUidModelsBackfillPredictionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    backfillPredictionsNodesNodeUidModelsBackfillPredictionsPost(
      requestParameters: ModelsApiBackfillPredictionsNodesNodeUidModelsBackfillPredictionsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .backfillPredictionsNodesNodeUidModelsBackfillPredictionsPost(
          requestParameters.nodeUid,
          requestParameters.datasourceUids,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Commit a model_uid as the \'best\' model for a task.
     * @summary Commit Model Endpoint
     * @param {ModelsApiCommitModelEndpointNodesNodeUidModelsModelUidCommitPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitModelEndpointNodesNodeUidModelsModelUidCommitPost(
      requestParameters: ModelsApiCommitModelEndpointNodesNodeUidModelsModelUidCommitPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .commitModelEndpointNodesNodeUidModelsModelUidCommitPost(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Automl Trained Configs
     * @param {ModelsApiFetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet(
      requestParameters: ModelsApiFetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: object }> {
      return localVarFp
        .fetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet(
          requestParameters.nodeUid,
          requestParameters.modelId,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch a single model based on a unique identifier
     * @summary Fetch Model
     * @param {ModelsApiFetchModelNodesNodeUidModelGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchModelNodesNodeUidModelGet(
      requestParameters: ModelsApiFetchModelNodesNodeUidModelGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FetchModelResponse> {
      return localVarFp
        .fetchModelNodesNodeUidModelGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.modelName,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Models
     * @param {ModelsApiFetchModelsNodesNodeUidModelsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchModelsNodesNodeUidModelsGet(
      requestParameters: ModelsApiFetchModelsNodesNodeUidModelsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ModelInfo>> {
      return localVarFp
        .fetchModelsNodesNodeUidModelsGet(
          requestParameters.nodeUid,
          requestParameters.isActive,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Generate Automl Model And Train
     * @param {ModelsApiGenerateAutomlModelAndTrainNodesNodeUidModelsAutomlPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateAutomlModelAndTrainNodesNodeUidModelsAutomlPost(
      requestParameters: ModelsApiGenerateAutomlModelAndTrainNodesNodeUidModelsAutomlPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .generateAutomlModelAndTrainNodesNodeUidModelsAutomlPost(
          requestParameters.nodeUid,
          requestParameters.autoMLTrainParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch the committed model for a particular task.
     * @summary Get Committed Model
     * @param {ModelsApiGetCommittedModelNodesNodeUidCommittedModelGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommittedModelNodesNodeUidCommittedModelGet(
      requestParameters: ModelsApiGetCommittedModelNodesNodeUidCommittedModelGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getCommittedModelNodesNodeUidCommittedModelGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Featurizer Columns
     * @param {ModelsApiGetFeaturizerColumnsFeaturizerColumnsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeaturizerColumnsFeaturizerColumnsPost(
      requestParameters: ModelsApiGetFeaturizerColumnsFeaturizerColumnsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .getFeaturizerColumnsFeaturizerColumnsPost(
          requestParameters.featurizerColumnsArgs,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Predict
     * @param {ModelsApiPredictNodesNodeUidModelsPredictPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    predictNodesNodeUidModelsPredictPost(
      requestParameters: ModelsApiPredictNodesNodeUidModelsPredictPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .predictNodesNodeUidModelsPredictPost(
          requestParameters.nodeUid,
          requestParameters.registerPredictModelJson,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Register Ingest Model
     * @param {ModelsApiRegisterIngestModelNodesNodeUidRegisterIngestModelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerIngestModelNodesNodeUidRegisterIngestModelPost(
      requestParameters: ModelsApiRegisterIngestModelNodesNodeUidRegisterIngestModelPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<string> {
      return localVarFp
        .registerIngestModelNodesNodeUidRegisterIngestModelPost(
          requestParameters.nodeUid,
          requestParameters.registerIngestModelParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Register Model
     * @param {ModelsApiRegisterModelNodesNodeUidModelsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerModelNodesNodeUidModelsPost(
      requestParameters: ModelsApiRegisterModelNodesNodeUidModelsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .registerModelNodesNodeUidModelsPost(
          requestParameters.nodeUid,
          requestParameters.modelInputPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Register Predict
     * @param {ModelsApiRegisterPredictNodesNodeUidModelsRegisterPredictPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerPredictNodesNodeUidModelsRegisterPredictPost(
      requestParameters: ModelsApiRegisterPredictNodesNodeUidModelsRegisterPredictPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CustomTrainedModelResponse> {
      return localVarFp
        .registerPredictNodesNodeUidModelsRegisterPredictPost(
          requestParameters.nodeUid,
          requestParameters.registerPredictModelJson,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Remove a model for given task.
     * @summary Remove Model
     * @param {ModelsApiRemoveModelNodesNodeUidModelsModelUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeModelNodesNodeUidModelsModelUidDelete(
      requestParameters: ModelsApiRemoveModelNodesNodeUidModelsModelUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DeleteModelResponse> {
      return localVarFp
        .removeModelNodesNodeUidModelsModelUidDelete(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Retrain Model
     * @param {ModelsApiRetrainModelNodesNodeUidModelsModelUidRetrainPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrainModelNodesNodeUidModelsModelUidRetrainPost(
      requestParameters: ModelsApiRetrainModelNodesNodeUidModelsModelUidRetrainPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ModelTrainingResponse> {
      return localVarFp
        .retrainModelNodesNodeUidModelsModelUidRetrainPost(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Stale Model Values
     * @param {ModelsApiStaleModelValuesNodesNodeUidModelsModelUidStalenessGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staleModelValuesNodesNodeUidModelsModelUidStalenessGet(
      requestParameters: ModelsApiStaleModelValuesNodesNodeUidModelsModelUidStalenessGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<StaleModelValuesResponse> {
      return localVarFp
        .staleModelValuesNodesNodeUidModelsModelUidStalenessGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * ... \"featurizers\": [     {         \"op_type\": \"StandardScaler\",         \"op_config\": {             \"field\": \"Income\",             \"target_field\": \"Income_scaled\",             \"mean\": 0,             \"std\": 1         },         \"trainable\": false,     },     {         \"op_type\": \"StandardScaler\",         \"fit_config\": {             \"field\": \"Income\",             \"target_field\": \"Income_scaled\",         },         \"trainable\": true,     }, ]
     * @summary Train Register Predict
     * @param {ModelsApiTrainRegisterPredictNodesNodeUidModelsTrainPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trainRegisterPredictNodesNodeUidModelsTrainPost(
      requestParameters: ModelsApiTrainRegisterPredictNodesNodeUidModelsTrainPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ModelTrainingResponse> {
      return localVarFp
        .trainRegisterPredictNodesNodeUidModelsTrainPost(
          requestParameters.nodeUid,
          requestParameters.trainModelArgs,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Model Config
     * @param {ModelsApiUpdateModelConfigNodesNodeUidModelsModelUidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModelConfigNodesNodeUidModelsModelUidPatch(
      requestParameters: ModelsApiUpdateModelConfigNodesNodeUidModelsModelUidPatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .updateModelConfigNodesNodeUidModelsModelUidPatch(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.updateModelConfigParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Model
     * @param {ModelsApiUpdateModelNodesNodeUidModelsModelUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModelNodesNodeUidModelsModelUidPut(
      requestParameters: ModelsApiUpdateModelNodesNodeUidModelsModelUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateModelNodesNodeUidModelsModelUidPut(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.updateModelParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for backfillPredictionsNodesNodeUidModelsBackfillPredictionsPost operation in ModelsApi.
 * @export
 * @interface ModelsApiBackfillPredictionsNodesNodeUidModelsBackfillPredictionsPostRequest
 */
export interface ModelsApiBackfillPredictionsNodesNodeUidModelsBackfillPredictionsPostRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiBackfillPredictionsNodesNodeUidModelsBackfillPredictionsPost
   */
  readonly nodeUid: number;

  /**
   * The UIDs of the datasources to backfill
   * @type {Array<number>}
   * @memberof ModelsApiBackfillPredictionsNodesNodeUidModelsBackfillPredictionsPost
   */
  readonly datasourceUids: Array<number>;
}

/**
 * Request parameters for commitModelEndpointNodesNodeUidModelsModelUidCommitPost operation in ModelsApi.
 * @export
 * @interface ModelsApiCommitModelEndpointNodesNodeUidModelsModelUidCommitPostRequest
 */
export interface ModelsApiCommitModelEndpointNodesNodeUidModelsModelUidCommitPostRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiCommitModelEndpointNodesNodeUidModelsModelUidCommitPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof ModelsApiCommitModelEndpointNodesNodeUidModelsModelUidCommitPost
   */
  readonly modelUid: number;
}

/**
 * Request parameters for fetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet operation in ModelsApi.
 * @export
 * @interface ModelsApiFetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGetRequest
 */
export interface ModelsApiFetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiFetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ModelsApiFetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet
   */
  readonly modelId: string;
}

/**
 * Request parameters for fetchModelNodesNodeUidModelGet operation in ModelsApi.
 * @export
 * @interface ModelsApiFetchModelNodesNodeUidModelGetRequest
 */
export interface ModelsApiFetchModelNodesNodeUidModelGetRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiFetchModelNodesNodeUidModelGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof ModelsApiFetchModelNodesNodeUidModelGet
   */
  readonly modelUid?: number;

  /**
   *
   * @type {string}
   * @memberof ModelsApiFetchModelNodesNodeUidModelGet
   */
  readonly modelName?: string;
}

/**
 * Request parameters for fetchModelsNodesNodeUidModelsGet operation in ModelsApi.
 * @export
 * @interface ModelsApiFetchModelsNodesNodeUidModelsGetRequest
 */
export interface ModelsApiFetchModelsNodesNodeUidModelsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiFetchModelsNodesNodeUidModelsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof ModelsApiFetchModelsNodesNodeUidModelsGet
   */
  readonly isActive?: boolean;
}

/**
 * Request parameters for generateAutomlModelAndTrainNodesNodeUidModelsAutomlPost operation in ModelsApi.
 * @export
 * @interface ModelsApiGenerateAutomlModelAndTrainNodesNodeUidModelsAutomlPostRequest
 */
export interface ModelsApiGenerateAutomlModelAndTrainNodesNodeUidModelsAutomlPostRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiGenerateAutomlModelAndTrainNodesNodeUidModelsAutomlPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {AutoMLTrainParams}
   * @memberof ModelsApiGenerateAutomlModelAndTrainNodesNodeUidModelsAutomlPost
   */
  readonly autoMLTrainParams: AutoMLTrainParams;
}

/**
 * Request parameters for getCommittedModelNodesNodeUidCommittedModelGet operation in ModelsApi.
 * @export
 * @interface ModelsApiGetCommittedModelNodesNodeUidCommittedModelGetRequest
 */
export interface ModelsApiGetCommittedModelNodesNodeUidCommittedModelGetRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiGetCommittedModelNodesNodeUidCommittedModelGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for getFeaturizerColumnsFeaturizerColumnsPost operation in ModelsApi.
 * @export
 * @interface ModelsApiGetFeaturizerColumnsFeaturizerColumnsPostRequest
 */
export interface ModelsApiGetFeaturizerColumnsFeaturizerColumnsPostRequest {
  /**
   *
   * @type {FeaturizerColumnsArgs}
   * @memberof ModelsApiGetFeaturizerColumnsFeaturizerColumnsPost
   */
  readonly featurizerColumnsArgs: FeaturizerColumnsArgs;
}

/**
 * Request parameters for predictNodesNodeUidModelsPredictPost operation in ModelsApi.
 * @export
 * @interface ModelsApiPredictNodesNodeUidModelsPredictPostRequest
 */
export interface ModelsApiPredictNodesNodeUidModelsPredictPostRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiPredictNodesNodeUidModelsPredictPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {RegisterPredictModelJson}
   * @memberof ModelsApiPredictNodesNodeUidModelsPredictPost
   */
  readonly registerPredictModelJson: RegisterPredictModelJson;
}

/**
 * Request parameters for registerIngestModelNodesNodeUidRegisterIngestModelPost operation in ModelsApi.
 * @export
 * @interface ModelsApiRegisterIngestModelNodesNodeUidRegisterIngestModelPostRequest
 */
export interface ModelsApiRegisterIngestModelNodesNodeUidRegisterIngestModelPostRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiRegisterIngestModelNodesNodeUidRegisterIngestModelPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {RegisterIngestModelParams}
   * @memberof ModelsApiRegisterIngestModelNodesNodeUidRegisterIngestModelPost
   */
  readonly registerIngestModelParams: RegisterIngestModelParams;
}

/**
 * Request parameters for registerModelNodesNodeUidModelsPost operation in ModelsApi.
 * @export
 * @interface ModelsApiRegisterModelNodesNodeUidModelsPostRequest
 */
export interface ModelsApiRegisterModelNodesNodeUidModelsPostRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiRegisterModelNodesNodeUidModelsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ModelInputPayload}
   * @memberof ModelsApiRegisterModelNodesNodeUidModelsPost
   */
  readonly modelInputPayload: ModelInputPayload;
}

/**
 * Request parameters for registerPredictNodesNodeUidModelsRegisterPredictPost operation in ModelsApi.
 * @export
 * @interface ModelsApiRegisterPredictNodesNodeUidModelsRegisterPredictPostRequest
 */
export interface ModelsApiRegisterPredictNodesNodeUidModelsRegisterPredictPostRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiRegisterPredictNodesNodeUidModelsRegisterPredictPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {RegisterPredictModelJson}
   * @memberof ModelsApiRegisterPredictNodesNodeUidModelsRegisterPredictPost
   */
  readonly registerPredictModelJson: RegisterPredictModelJson;
}

/**
 * Request parameters for removeModelNodesNodeUidModelsModelUidDelete operation in ModelsApi.
 * @export
 * @interface ModelsApiRemoveModelNodesNodeUidModelsModelUidDeleteRequest
 */
export interface ModelsApiRemoveModelNodesNodeUidModelsModelUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiRemoveModelNodesNodeUidModelsModelUidDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof ModelsApiRemoveModelNodesNodeUidModelsModelUidDelete
   */
  readonly modelUid: number;
}

/**
 * Request parameters for retrainModelNodesNodeUidModelsModelUidRetrainPost operation in ModelsApi.
 * @export
 * @interface ModelsApiRetrainModelNodesNodeUidModelsModelUidRetrainPostRequest
 */
export interface ModelsApiRetrainModelNodesNodeUidModelsModelUidRetrainPostRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiRetrainModelNodesNodeUidModelsModelUidRetrainPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof ModelsApiRetrainModelNodesNodeUidModelsModelUidRetrainPost
   */
  readonly modelUid: number;
}

/**
 * Request parameters for staleModelValuesNodesNodeUidModelsModelUidStalenessGet operation in ModelsApi.
 * @export
 * @interface ModelsApiStaleModelValuesNodesNodeUidModelsModelUidStalenessGetRequest
 */
export interface ModelsApiStaleModelValuesNodesNodeUidModelsModelUidStalenessGetRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiStaleModelValuesNodesNodeUidModelsModelUidStalenessGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof ModelsApiStaleModelValuesNodesNodeUidModelsModelUidStalenessGet
   */
  readonly modelUid: number;
}

/**
 * Request parameters for trainRegisterPredictNodesNodeUidModelsTrainPost operation in ModelsApi.
 * @export
 * @interface ModelsApiTrainRegisterPredictNodesNodeUidModelsTrainPostRequest
 */
export interface ModelsApiTrainRegisterPredictNodesNodeUidModelsTrainPostRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiTrainRegisterPredictNodesNodeUidModelsTrainPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TrainModelArgs}
   * @memberof ModelsApiTrainRegisterPredictNodesNodeUidModelsTrainPost
   */
  readonly trainModelArgs: TrainModelArgs;
}

/**
 * Request parameters for updateModelConfigNodesNodeUidModelsModelUidPatch operation in ModelsApi.
 * @export
 * @interface ModelsApiUpdateModelConfigNodesNodeUidModelsModelUidPatchRequest
 */
export interface ModelsApiUpdateModelConfigNodesNodeUidModelsModelUidPatchRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiUpdateModelConfigNodesNodeUidModelsModelUidPatch
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof ModelsApiUpdateModelConfigNodesNodeUidModelsModelUidPatch
   */
  readonly modelUid: number;

  /**
   *
   * @type {UpdateModelConfigParams}
   * @memberof ModelsApiUpdateModelConfigNodesNodeUidModelsModelUidPatch
   */
  readonly updateModelConfigParams: UpdateModelConfigParams;
}

/**
 * Request parameters for updateModelNodesNodeUidModelsModelUidPut operation in ModelsApi.
 * @export
 * @interface ModelsApiUpdateModelNodesNodeUidModelsModelUidPutRequest
 */
export interface ModelsApiUpdateModelNodesNodeUidModelsModelUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof ModelsApiUpdateModelNodesNodeUidModelsModelUidPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof ModelsApiUpdateModelNodesNodeUidModelsModelUidPut
   */
  readonly modelUid: number;

  /**
   *
   * @type {UpdateModelParams}
   * @memberof ModelsApiUpdateModelNodesNodeUidModelsModelUidPut
   */
  readonly updateModelParams: UpdateModelParams;
}

/**
 * ModelsApi - object-oriented interface
 * @export
 * @class ModelsApi
 * @extends {BaseAPI}
 */
export class ModelsApi extends BaseAPI {
  /**
   *
   * @summary Backfill Predictions
   * @param {ModelsApiBackfillPredictionsNodesNodeUidModelsBackfillPredictionsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public backfillPredictionsNodesNodeUidModelsBackfillPredictionsPost(
    requestParameters: ModelsApiBackfillPredictionsNodesNodeUidModelsBackfillPredictionsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .backfillPredictionsNodesNodeUidModelsBackfillPredictionsPost(
        requestParameters.nodeUid,
        requestParameters.datasourceUids,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Commit a model_uid as the \'best\' model for a task.
   * @summary Commit Model Endpoint
   * @param {ModelsApiCommitModelEndpointNodesNodeUidModelsModelUidCommitPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public commitModelEndpointNodesNodeUidModelsModelUidCommitPost(
    requestParameters: ModelsApiCommitModelEndpointNodesNodeUidModelsModelUidCommitPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .commitModelEndpointNodesNodeUidModelsModelUidCommitPost(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Automl Trained Configs
   * @param {ModelsApiFetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public fetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet(
    requestParameters: ModelsApiFetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .fetchAutomlTrainedConfigsNodesNodeUidAutomlTrainedConfigsGet(
        requestParameters.nodeUid,
        requestParameters.modelId,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch a single model based on a unique identifier
   * @summary Fetch Model
   * @param {ModelsApiFetchModelNodesNodeUidModelGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public fetchModelNodesNodeUidModelGet(
    requestParameters: ModelsApiFetchModelNodesNodeUidModelGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .fetchModelNodesNodeUidModelGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.modelName,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Models
   * @param {ModelsApiFetchModelsNodesNodeUidModelsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public fetchModelsNodesNodeUidModelsGet(
    requestParameters: ModelsApiFetchModelsNodesNodeUidModelsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .fetchModelsNodesNodeUidModelsGet(
        requestParameters.nodeUid,
        requestParameters.isActive,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generate Automl Model And Train
   * @param {ModelsApiGenerateAutomlModelAndTrainNodesNodeUidModelsAutomlPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public generateAutomlModelAndTrainNodesNodeUidModelsAutomlPost(
    requestParameters: ModelsApiGenerateAutomlModelAndTrainNodesNodeUidModelsAutomlPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .generateAutomlModelAndTrainNodesNodeUidModelsAutomlPost(
        requestParameters.nodeUid,
        requestParameters.autoMLTrainParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch the committed model for a particular task.
   * @summary Get Committed Model
   * @param {ModelsApiGetCommittedModelNodesNodeUidCommittedModelGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public getCommittedModelNodesNodeUidCommittedModelGet(
    requestParameters: ModelsApiGetCommittedModelNodesNodeUidCommittedModelGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .getCommittedModelNodesNodeUidCommittedModelGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Featurizer Columns
   * @param {ModelsApiGetFeaturizerColumnsFeaturizerColumnsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public getFeaturizerColumnsFeaturizerColumnsPost(
    requestParameters: ModelsApiGetFeaturizerColumnsFeaturizerColumnsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .getFeaturizerColumnsFeaturizerColumnsPost(
        requestParameters.featurizerColumnsArgs,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Predict
   * @param {ModelsApiPredictNodesNodeUidModelsPredictPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public predictNodesNodeUidModelsPredictPost(
    requestParameters: ModelsApiPredictNodesNodeUidModelsPredictPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .predictNodesNodeUidModelsPredictPost(
        requestParameters.nodeUid,
        requestParameters.registerPredictModelJson,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Register Ingest Model
   * @param {ModelsApiRegisterIngestModelNodesNodeUidRegisterIngestModelPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public registerIngestModelNodesNodeUidRegisterIngestModelPost(
    requestParameters: ModelsApiRegisterIngestModelNodesNodeUidRegisterIngestModelPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .registerIngestModelNodesNodeUidRegisterIngestModelPost(
        requestParameters.nodeUid,
        requestParameters.registerIngestModelParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Register Model
   * @param {ModelsApiRegisterModelNodesNodeUidModelsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public registerModelNodesNodeUidModelsPost(
    requestParameters: ModelsApiRegisterModelNodesNodeUidModelsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .registerModelNodesNodeUidModelsPost(
        requestParameters.nodeUid,
        requestParameters.modelInputPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Register Predict
   * @param {ModelsApiRegisterPredictNodesNodeUidModelsRegisterPredictPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public registerPredictNodesNodeUidModelsRegisterPredictPost(
    requestParameters: ModelsApiRegisterPredictNodesNodeUidModelsRegisterPredictPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .registerPredictNodesNodeUidModelsRegisterPredictPost(
        requestParameters.nodeUid,
        requestParameters.registerPredictModelJson,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Remove a model for given task.
   * @summary Remove Model
   * @param {ModelsApiRemoveModelNodesNodeUidModelsModelUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public removeModelNodesNodeUidModelsModelUidDelete(
    requestParameters: ModelsApiRemoveModelNodesNodeUidModelsModelUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .removeModelNodesNodeUidModelsModelUidDelete(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrain Model
   * @param {ModelsApiRetrainModelNodesNodeUidModelsModelUidRetrainPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public retrainModelNodesNodeUidModelsModelUidRetrainPost(
    requestParameters: ModelsApiRetrainModelNodesNodeUidModelsModelUidRetrainPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .retrainModelNodesNodeUidModelsModelUidRetrainPost(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stale Model Values
   * @param {ModelsApiStaleModelValuesNodesNodeUidModelsModelUidStalenessGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public staleModelValuesNodesNodeUidModelsModelUidStalenessGet(
    requestParameters: ModelsApiStaleModelValuesNodesNodeUidModelsModelUidStalenessGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .staleModelValuesNodesNodeUidModelsModelUidStalenessGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * ... \"featurizers\": [     {         \"op_type\": \"StandardScaler\",         \"op_config\": {             \"field\": \"Income\",             \"target_field\": \"Income_scaled\",             \"mean\": 0,             \"std\": 1         },         \"trainable\": false,     },     {         \"op_type\": \"StandardScaler\",         \"fit_config\": {             \"field\": \"Income\",             \"target_field\": \"Income_scaled\",         },         \"trainable\": true,     }, ]
   * @summary Train Register Predict
   * @param {ModelsApiTrainRegisterPredictNodesNodeUidModelsTrainPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public trainRegisterPredictNodesNodeUidModelsTrainPost(
    requestParameters: ModelsApiTrainRegisterPredictNodesNodeUidModelsTrainPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .trainRegisterPredictNodesNodeUidModelsTrainPost(
        requestParameters.nodeUid,
        requestParameters.trainModelArgs,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Model Config
   * @param {ModelsApiUpdateModelConfigNodesNodeUidModelsModelUidPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public updateModelConfigNodesNodeUidModelsModelUidPatch(
    requestParameters: ModelsApiUpdateModelConfigNodesNodeUidModelsModelUidPatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .updateModelConfigNodesNodeUidModelsModelUidPatch(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.updateModelConfigParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Model
   * @param {ModelsApiUpdateModelNodesNodeUidModelsModelUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public updateModelNodesNodeUidModelsModelUidPut(
    requestParameters: ModelsApiUpdateModelNodesNodeUidModelsModelUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .updateModelNodesNodeUidModelsModelUidPut(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.updateModelParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * NodesApi - axios parameter creator
 * @export
 */
export const NodesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Active Datasources
     * @param {number} nodeUid
     * @param {SetNodeDataParams} setNodeDataParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addActiveDatasourcesNodesNodeUidActiveDatasourcesPost: async (
      nodeUid: number,
      setNodeDataParams: SetNodeDataParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addActiveDatasourcesNodesNodeUidActiveDatasourcesPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'setNodeDataParams' is not null or undefined
      assertParamExists(
        'addActiveDatasourcesNodesNodeUidActiveDatasourcesPost',
        'setNodeDataParams',
        setNodeDataParams,
      );
      const localVarPath = `/nodes/{node_uid}/active-datasources`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        setNodeDataParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Commit an OpVersion to this Node or uncommit any committed OpVersions from this Node. Depending on the payload we either - Make a new OpVersion and commit it to this Node - Commit an existing OpVersion to this Node - Uncommit any committed OpVersions from this Node  See the UpdateNodePayload class for specifics on payload semantics
     * @summary Commit Node Route
     * @param {number} nodeUid
     * @param {UpdateNodePayload} updateNodePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitNodeRouteNodesNodeUidPut: async (
      nodeUid: number,
      updateNodePayload: UpdateNodePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('commitNodeRouteNodesNodeUidPut', 'nodeUid', nodeUid);
      // verify required parameter 'updateNodePayload' is not null or undefined
      assertParamExists(
        'commitNodeRouteNodesNodeUidPut',
        'updateNodePayload',
        updateNodePayload,
      );
      const localVarPath = `/nodes/{node_uid}`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateNodePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create an Node and place it in the Dataset DAG at the appropriate spot as determined by the input_node_uids and (optionally) the output_node_uid. If the user specifies op_type/op_config, make an OpVersion and commit that version to the new node in the DAG.
     * @summary Create Dataset Node
     * @param {number} datasetUid
     * @param {CreateNodePayload} createNodePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDatasetNodeDatasetsDatasetUidNodesPost: async (
      datasetUid: number,
      createNodePayload: CreateNodePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'createDatasetNodeDatasetsDatasetUidNodesPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'createNodePayload' is not null or undefined
      assertParamExists(
        'createDatasetNodeDatasetsDatasetUidNodesPost',
        'createNodePayload',
        createNodePayload,
      );
      const localVarPath = `/datasets/{dataset_uid}/nodes`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createNodePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Node Hierarchy Route
     * @param {number} applicationUid
     * @param {number} nodeUid
     * @param {CreateModelNodeHierarchyPayload} createModelNodeHierarchyPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost:
      async (
        applicationUid: number,
        nodeUid: number,
        createModelNodeHierarchyPayload: CreateModelNodeHierarchyPayload,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'applicationUid' is not null or undefined
        assertParamExists(
          'createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost',
          'applicationUid',
          applicationUid,
        );
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'createModelNodeHierarchyPayload' is not null or undefined
        assertParamExists(
          'createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost',
          'createModelNodeHierarchyPayload',
          createModelNodeHierarchyPayload,
        );
        const localVarPath =
          `/applications/{application_uid}/nodes/{node_uid}/hierarchy`
            .replace(
              `{${'application_uid'}}`,
              encodeURIComponent(String(applicationUid)),
            )
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          createModelNodeHierarchyPayload,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Create an Node and place it in the Application DAG at the appropriate spot as determined by the input_node_uids and (optionally) the output_node_uid. If the user specifies op_type/op_config, make an OpVersion and commit that version to the new node in the DAG.
     * @summary Create Node Route
     * @param {number} applicationUid
     * @param {CreateNodePayload} createNodePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNodeRouteApplicationsApplicationUidNodesPost: async (
      applicationUid: number,
      createNodePayload: CreateNodePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'createNodeRouteApplicationsApplicationUidNodesPost',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'createNodePayload' is not null or undefined
      assertParamExists(
        'createNodeRouteApplicationsApplicationUidNodesPost',
        'createNodePayload',
        createNodePayload,
      );
      const localVarPath = `/applications/{application_uid}/nodes`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createNodePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Train Sample Datasource
     * @param {number} nodeUid
     * @param {PostTrainSampleDatasourceParams} postTrainSampleDatasourceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost: async (
      nodeUid: number,
      postTrainSampleDatasourceParams: PostTrainSampleDatasourceParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'createTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'postTrainSampleDatasourceParams' is not null or undefined
      assertParamExists(
        'createTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost',
        'postTrainSampleDatasourceParams',
        postTrainSampleDatasourceParams,
      );
      const localVarPath = `/nodes/{node_uid}/train-sample-datasources`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postTrainSampleDatasourceParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Active Node Datasource
     * @param {number} nodeUid
     * @param {DeleteNodePreprocessedDatasource} deleteNodePreprocessedDatasource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete: async (
      nodeUid: number,
      deleteNodePreprocessedDatasource: DeleteNodePreprocessedDatasource,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'deleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'deleteNodePreprocessedDatasource' is not null or undefined
      assertParamExists(
        'deleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete',
        'deleteNodePreprocessedDatasource',
        deleteNodePreprocessedDatasource,
      );
      const localVarPath = `/nodes/{node_uid}/active-datasources`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteNodePreprocessedDatasource,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete the node from the database and remove it from the DAG. This operation will remove any leaf node but will only remove internal nodes that maintain the structure of the underlying DAG, e.g. that the number of inputs to all nodes remains the same before and after deletion.
     * @summary Delete Node
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNodeNodesNodeUidDelete: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('deleteNodeNodesNodeUidDelete', 'nodeUid', nodeUid);
      const localVarPath = `/nodes/{node_uid}`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Node Input Cols Route
     * @param {number} nodeUid
     * @param {boolean} [intersection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchNodeInputColsRouteNodesNodeUidInputColumnsGet: async (
      nodeUid: number,
      intersection?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchNodeInputColsRouteNodesNodeUidInputColumnsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/input-columns`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (intersection !== undefined) {
        localVarQueryParameter['intersection'] = intersection;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Node Input Cols Types
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/input-columns-types`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Node
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchNodeNodesNodeUidGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('fetchNodeNodesNodeUidGet', 'nodeUid', nodeUid);
      const localVarPath = `/nodes/{node_uid}`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Node Output Cols Route
     * @param {number} nodeUid
     * @param {boolean} [intersection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet: async (
      nodeUid: number,
      intersection?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/output-columns`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (intersection !== undefined) {
        localVarQueryParameter['intersection'] = intersection;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fit an Operator and return the fitted kwargs Fitted kwargs will then be populated as arguments in the previous modal (op_config edit)
     * @summary Fit Node
     * @param {number} applicationUid
     * @param {number} nodeUid
     * @param {FitNodePayload} fitNodePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fitNodeApplicationsApplicationUidNodesNodeUidFitPost: async (
      applicationUid: number,
      nodeUid: number,
      fitNodePayload: FitNodePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'fitNodeApplicationsApplicationUidNodesNodeUidFitPost',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fitNodeApplicationsApplicationUidNodesNodeUidFitPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'fitNodePayload' is not null or undefined
      assertParamExists(
        'fitNodeApplicationsApplicationUidNodesNodeUidFitPost',
        'fitNodePayload',
        fitNodePayload,
      );
      const localVarPath =
        `/applications/{application_uid}/nodes/{node_uid}/fit`
          .replace(
            `{${'application_uid'}}`,
            encodeURIComponent(String(applicationUid)),
          )
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fitNodePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Mergeable Nodes
     * @param {number} nodeUid
     * @param {number} destinationApplicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMergeableNodesNodesNodeUidMergeableNodesGet: async (
      nodeUid: number,
      destinationApplicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getMergeableNodesNodesNodeUidMergeableNodesGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'destinationApplicationUid' is not null or undefined
      assertParamExists(
        'getMergeableNodesNodesNodeUidMergeableNodesGet',
        'destinationApplicationUid',
        destinationApplicationUid,
      );
      const localVarPath = `/nodes/{node_uid}/mergeable-nodes`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (destinationApplicationUid !== undefined) {
        localVarQueryParameter['destination_application_uid'] =
          destinationApplicationUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Node Active Datasources
     * @param {number} nodeUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [isActive]
     * @param {DatasourceRole} [dsRole]
     * @param {boolean} [computeStaleness]
     * @param {boolean} [computeStatistics]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet: async (
      nodeUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      isActive?: boolean,
      dsRole?: DatasourceRole,
      computeStaleness?: boolean,
      computeStatistics?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/active-datasources`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (isActive !== undefined) {
        localVarQueryParameter['is_active'] = isActive;
      }

      if (dsRole !== undefined) {
        localVarQueryParameter['ds_role'] = dsRole;
      }

      if (computeStaleness !== undefined) {
        localVarQueryParameter['compute_staleness'] = computeStaleness;
      }

      if (computeStatistics !== undefined) {
        localVarQueryParameter['compute_statistics'] = computeStatistics;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Node Preview
     * @param {number} applicationUid
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet: async (
      applicationUid: number,
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'getNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet',
        'applicationUid',
        applicationUid,
      );
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath =
        `/applications/{application_uid}/nodes/{node_uid}/preview`
          .replace(
            `{${'application_uid'}}`,
            encodeURIComponent(String(applicationUid)),
          )
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the skipped datapoints (and optionally the corresponding error message) when preproceprocessing up to node_uid (not including it) for each type of data check.
     * @summary Get Preprocessing Issues For Node
     * @param {number} applicationUid
     * @param {number} nodeUid
     * @param {boolean} [onPreviewSample]
     * @param {boolean} [includeErrorMsg]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet:
      async (
        applicationUid: number,
        nodeUid: number,
        onPreviewSample?: boolean,
        includeErrorMsg?: boolean,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'applicationUid' is not null or undefined
        assertParamExists(
          'getPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet',
          'applicationUid',
          applicationUid,
        );
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet',
          'nodeUid',
          nodeUid,
        );
        const localVarPath =
          `/applications/{application_uid}/nodes/{node_uid}/preprocessing-issues`
            .replace(
              `{${'application_uid'}}`,
              encodeURIComponent(String(applicationUid)),
            )
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (onPreviewSample !== undefined) {
          localVarQueryParameter['on_preview_sample'] = onPreviewSample;
        }

        if (includeErrorMsg !== undefined) {
          localVarQueryParameter['include_error_msg'] = includeErrorMsg;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Fetch the requested setting value for the node.
     * @summary Get Setting
     * @param {number} nodeUid
     * @param {string} setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettingNodesNodeUidSettingsSettingGet: async (
      nodeUid: number,
      setting: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getSettingNodesNodeUidSettingsSettingGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'setting' is not null or undefined
      assertParamExists(
        'getSettingNodesNodeUidSettingsSettingGet',
        'setting',
        setting,
      );
      const localVarPath = `/nodes/{node_uid}/settings/{setting}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'setting'}}`, encodeURIComponent(String(setting)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch all settings for the task.
     * @summary Get Settings
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettingsNodesNodeUidSettingsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getSettingsNodesNodeUidSettingsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/settings`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch the model configs for a given task
     * @summary Get Task Model Configs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskModelConfigsNodesNodeUidModelConfigsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getTaskModelConfigsNodesNodeUidModelConfigsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/model_configs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Training Selected Feature Fields
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGet:
      async (
        nodeUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGet',
          'nodeUid',
          nodeUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/training_selected_feature_fields`.replace(
            `{${'node_uid'}}`,
            encodeURIComponent(String(nodeUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary List Dataset Nodes
     * @param {number} datasetUid
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDatasetNodesDatasetsDatasetUidNodesGet: async (
      datasetUid: number,
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'listDatasetNodesDatasetsDatasetUidNodesGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/datasets/{dataset_uid}/nodes`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Nodes
     * @param {number} applicationUid
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listNodesApplicationsApplicationUidNodesGet: async (
      applicationUid: number,
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'listNodesApplicationsApplicationUidNodesGet',
        'applicationUid',
        applicationUid,
      );
      const localVarPath = `/applications/{application_uid}/nodes`.replace(
        `{${'application_uid'}}`,
        encodeURIComponent(String(applicationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Put Node Datasource
     * @param {number} nodeUid
     * @param {number} datasourceUid
     * @param {PutNodePreprocessedDatasource} putNodePreprocessedDatasource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut: async (
      nodeUid: number,
      datasourceUid: number,
      putNodePreprocessedDatasource: PutNodePreprocessedDatasource,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'datasourceUid' is not null or undefined
      assertParamExists(
        'putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut',
        'datasourceUid',
        datasourceUid,
      );
      // verify required parameter 'putNodePreprocessedDatasource' is not null or undefined
      assertParamExists(
        'putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut',
        'putNodePreprocessedDatasource',
        putNodePreprocessedDatasource,
      );
      const localVarPath =
        `/nodes/{node_uid}/active-datasources/{datasource_uid}`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(
            `{${'datasource_uid'}}`,
            encodeURIComponent(String(datasourceUid)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putNodePreprocessedDatasource,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Put Setting
     * @param {number} nodeUid
     * @param {string} setting
     * @param {SettingValue} settingValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putSettingNodesNodeUidSettingsSettingPut: async (
      nodeUid: number,
      setting: string,
      settingValue: SettingValue,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'putSettingNodesNodeUidSettingsSettingPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'setting' is not null or undefined
      assertParamExists(
        'putSettingNodesNodeUidSettingsSettingPut',
        'setting',
        setting,
      );
      // verify required parameter 'settingValue' is not null or undefined
      assertParamExists(
        'putSettingNodesNodeUidSettingsSettingPut',
        'settingValue',
        settingValue,
      );
      const localVarPath = `/nodes/{node_uid}/settings/{setting}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'setting'}}`, encodeURIComponent(String(setting)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        settingValue,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Refresh Active Datasources
     * @param {number} nodeUid
     * @param {RefreshNodeDataParams} refreshNodeDataParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshActiveDatasourcesNodesNodeUidRefreshPost: async (
      nodeUid: number,
      refreshNodeDataParams: RefreshNodeDataParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'refreshActiveDatasourcesNodesNodeUidRefreshPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'refreshNodeDataParams' is not null or undefined
      assertParamExists(
        'refreshActiveDatasourcesNodesNodeUidRefreshPost',
        'refreshNodeDataParams',
        refreshNodeDataParams,
      );
      const localVarPath = `/nodes/{node_uid}/refresh`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        refreshNodeDataParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the label_space for an existing Node  The only time we can guarantee that it\'s safe to update the label_space for a node is if the node does not already have a label_space set.
     * @summary Update Label Space
     * @param {number} nodeUid
     * @param {UpdateNodeLabelSpacePayload} updateNodeLabelSpacePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabelSpaceNodesNodeUidLabelSpacePut: async (
      nodeUid: number,
      updateNodeLabelSpacePayload: UpdateNodeLabelSpacePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'updateLabelSpaceNodesNodeUidLabelSpacePut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'updateNodeLabelSpacePayload' is not null or undefined
      assertParamExists(
        'updateLabelSpaceNodesNodeUidLabelSpacePut',
        'updateNodeLabelSpacePayload',
        updateNodeLabelSpacePayload,
      );
      const localVarPath = `/nodes/{node_uid}/label-space`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateNodeLabelSpacePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Node
     * @param {number} nodeUid
     * @param {PatchNodePayload} patchNodePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNodeNodesNodeUidPatch: async (
      nodeUid: number,
      patchNodePayload: PatchNodePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('updateNodeNodesNodeUidPatch', 'nodeUid', nodeUid);
      // verify required parameter 'patchNodePayload' is not null or undefined
      assertParamExists(
        'updateNodeNodesNodeUidPatch',
        'patchNodePayload',
        patchNodePayload,
      );
      const localVarPath = `/nodes/{node_uid}`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchNodePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NodesApi - functional programming interface
 * @export
 */
export const NodesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NodesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Active Datasources
     * @param {number} nodeUid
     * @param {SetNodeDataParams} setNodeDataParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addActiveDatasourcesNodesNodeUidActiveDatasourcesPost(
      nodeUid: number,
      setNodeDataParams: SetNodeDataParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NodeJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addActiveDatasourcesNodesNodeUidActiveDatasourcesPost(
          nodeUid,
          setNodeDataParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.addActiveDatasourcesNodesNodeUidActiveDatasourcesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Commit an OpVersion to this Node or uncommit any committed OpVersions from this Node. Depending on the payload we either - Make a new OpVersion and commit it to this Node - Commit an existing OpVersion to this Node - Uncommit any committed OpVersions from this Node  See the UpdateNodePayload class for specifics on payload semantics
     * @summary Commit Node Route
     * @param {number} nodeUid
     * @param {UpdateNodePayload} updateNodePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commitNodeRouteNodesNodeUidPut(
      nodeUid: number,
      updateNodePayload: UpdateNodePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateNodeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commitNodeRouteNodesNodeUidPut(
          nodeUid,
          updateNodePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['NodesApi.commitNodeRouteNodesNodeUidPut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Create an Node and place it in the Dataset DAG at the appropriate spot as determined by the input_node_uids and (optionally) the output_node_uid. If the user specifies op_type/op_config, make an OpVersion and commit that version to the new node in the DAG.
     * @summary Create Dataset Node
     * @param {number} datasetUid
     * @param {CreateNodePayload} createNodePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDatasetNodeDatasetsDatasetUidNodesPost(
      datasetUid: number,
      createNodePayload: CreateNodePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateNodeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createDatasetNodeDatasetsDatasetUidNodesPost(
          datasetUid,
          createNodePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.createDatasetNodeDatasetsDatasetUidNodesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Create Node Hierarchy Route
     * @param {number} applicationUid
     * @param {number} nodeUid
     * @param {CreateModelNodeHierarchyPayload} createModelNodeHierarchyPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost(
      applicationUid: number,
      nodeUid: number,
      createModelNodeHierarchyPayload: CreateModelNodeHierarchyPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateModelNodeHierarchyResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost(
          applicationUid,
          nodeUid,
          createModelNodeHierarchyPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Create an Node and place it in the Application DAG at the appropriate spot as determined by the input_node_uids and (optionally) the output_node_uid. If the user specifies op_type/op_config, make an OpVersion and commit that version to the new node in the DAG.
     * @summary Create Node Route
     * @param {number} applicationUid
     * @param {CreateNodePayload} createNodePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNodeRouteApplicationsApplicationUidNodesPost(
      applicationUid: number,
      createNodePayload: CreateNodePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateNodeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createNodeRouteApplicationsApplicationUidNodesPost(
          applicationUid,
          createNodePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.createNodeRouteApplicationsApplicationUidNodesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Create Train Sample Datasource
     * @param {number} nodeUid
     * @param {PostTrainSampleDatasourceParams} postTrainSampleDatasourceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost(
      nodeUid: number,
      postTrainSampleDatasourceParams: PostTrainSampleDatasourceParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NodeJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost(
          nodeUid,
          postTrainSampleDatasourceParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.createTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Active Node Datasource
     * @param {number} nodeUid
     * @param {DeleteNodePreprocessedDatasource} deleteNodePreprocessedDatasource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete(
      nodeUid: number,
      deleteNodePreprocessedDatasource: DeleteNodePreprocessedDatasource,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete(
          nodeUid,
          deleteNodePreprocessedDatasource,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.deleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete the node from the database and remove it from the DAG. This operation will remove any leaf node but will only remove internal nodes that maintain the structure of the underlying DAG, e.g. that the number of inputs to all nodes remains the same before and after deletion.
     * @summary Delete Node
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNodeNodesNodeUidDelete(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteNodeNodesNodeUidDelete(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['NodesApi.deleteNodeNodesNodeUidDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Node Input Cols Route
     * @param {number} nodeUid
     * @param {boolean} [intersection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchNodeInputColsRouteNodesNodeUidInputColumnsGet(
      nodeUid: number,
      intersection?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchNodeInputColsRouteNodesNodeUidInputColumnsGet(
          nodeUid,
          intersection,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.fetchNodeInputColsRouteNodesNodeUidInputColumnsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Node Input Cols Types
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.fetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Node
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchNodeNodesNodeUidGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FetchNodeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchNodeNodesNodeUidGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['NodesApi.fetchNodeNodesNodeUidGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Node Output Cols Route
     * @param {number} nodeUid
     * @param {boolean} [intersection]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet(
      nodeUid: number,
      intersection?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet(
          nodeUid,
          intersection,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.fetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fit an Operator and return the fitted kwargs Fitted kwargs will then be populated as arguments in the previous modal (op_config edit)
     * @summary Fit Node
     * @param {number} applicationUid
     * @param {number} nodeUid
     * @param {FitNodePayload} fitNodePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fitNodeApplicationsApplicationUidNodesNodeUidFitPost(
      applicationUid: number,
      nodeUid: number,
      fitNodePayload: FitNodePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NodeJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fitNodeApplicationsApplicationUidNodesNodeUidFitPost(
          applicationUid,
          nodeUid,
          fitNodePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.fitNodeApplicationsApplicationUidNodesNodeUidFitPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Mergeable Nodes
     * @param {number} nodeUid
     * @param {number} destinationApplicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMergeableNodesNodesNodeUidMergeableNodesGet(
      nodeUid: number,
      destinationApplicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMergeableNodesNodesNodeUidMergeableNodesGet(
          nodeUid,
          destinationApplicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.getMergeableNodesNodesNodeUidMergeableNodesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Node Active Datasources
     * @param {number} nodeUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [isActive]
     * @param {DatasourceRole} [dsRole]
     * @param {boolean} [computeStaleness]
     * @param {boolean} [computeStatistics]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet(
      nodeUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      isActive?: boolean,
      dsRole?: DatasourceRole,
      computeStaleness?: boolean,
      computeStatistics?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<PreprocessedDatasourceResponse>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet(
          nodeUid,
          split,
          startDate,
          endDate,
          isActive,
          dsRole,
          computeStaleness,
          computeStatistics,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Node Preview
     * @param {number} applicationUid
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet(
      applicationUid: number,
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet(
          applicationUid,
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.getNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Returns the skipped datapoints (and optionally the corresponding error message) when preproceprocessing up to node_uid (not including it) for each type of data check.
     * @summary Get Preprocessing Issues For Node
     * @param {number} applicationUid
     * @param {number} nodeUid
     * @param {boolean} [onPreviewSample]
     * @param {boolean} [includeErrorMsg]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet(
      applicationUid: number,
      nodeUid: number,
      onPreviewSample?: boolean,
      includeErrorMsg?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: PreprocessingIssues }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet(
          applicationUid,
          nodeUid,
          onPreviewSample,
          includeErrorMsg,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.getPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch the requested setting value for the node.
     * @summary Get Setting
     * @param {number} nodeUid
     * @param {string} setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSettingNodesNodeUidSettingsSettingGet(
      nodeUid: number,
      setting: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSettingNodesNodeUidSettingsSettingGet(
          nodeUid,
          setting,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.getSettingNodesNodeUidSettingsSettingGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch all settings for the task.
     * @summary Get Settings
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSettingsNodesNodeUidSettingsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSettingsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSettingsNodesNodeUidSettingsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['NodesApi.getSettingsNodesNodeUidSettingsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch the model configs for a given task
     * @summary Get Task Model Configs
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTaskModelConfigsNodesNodeUidModelConfigsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetModelConfigsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTaskModelConfigsNodesNodeUidModelConfigsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.getTaskModelConfigsNodesNodeUidModelConfigsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Training Selected Feature Fields
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.getTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Dataset Nodes
     * @param {number} datasetUid
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listDatasetNodesDatasetsDatasetUidNodesGet(
      datasetUid: number,
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Node>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listDatasetNodesDatasetsDatasetUidNodesGet(
          datasetUid,
          limit,
          offset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.listDatasetNodesDatasetsDatasetUidNodesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Nodes
     * @param {number} applicationUid
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listNodesApplicationsApplicationUidNodesGet(
      applicationUid: number,
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Node>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listNodesApplicationsApplicationUidNodesGet(
          applicationUid,
          limit,
          offset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.listNodesApplicationsApplicationUidNodesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Put Node Datasource
     * @param {number} nodeUid
     * @param {number} datasourceUid
     * @param {PutNodePreprocessedDatasource} putNodePreprocessedDatasource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut(
      nodeUid: number,
      datasourceUid: number,
      putNodePreprocessedDatasource: PutNodePreprocessedDatasource,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut(
          nodeUid,
          datasourceUid,
          putNodePreprocessedDatasource,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Put Setting
     * @param {number} nodeUid
     * @param {string} setting
     * @param {SettingValue} settingValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putSettingNodesNodeUidSettingsSettingPut(
      nodeUid: number,
      setting: string,
      settingValue: SettingValue,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putSettingNodesNodeUidSettingsSettingPut(
          nodeUid,
          setting,
          settingValue,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.putSettingNodesNodeUidSettingsSettingPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Refresh Active Datasources
     * @param {number} nodeUid
     * @param {RefreshNodeDataParams} refreshNodeDataParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshActiveDatasourcesNodesNodeUidRefreshPost(
      nodeUid: number,
      refreshNodeDataParams: RefreshNodeDataParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NodeJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.refreshActiveDatasourcesNodesNodeUidRefreshPost(
          nodeUid,
          refreshNodeDataParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.refreshActiveDatasourcesNodesNodeUidRefreshPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update the label_space for an existing Node  The only time we can guarantee that it\'s safe to update the label_space for a node is if the node does not already have a label_space set.
     * @summary Update Label Space
     * @param {number} nodeUid
     * @param {UpdateNodeLabelSpacePayload} updateNodeLabelSpacePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLabelSpaceNodesNodeUidLabelSpacePut(
      nodeUid: number,
      updateNodeLabelSpacePayload: UpdateNodeLabelSpacePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateNodeLabelSpaceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateLabelSpaceNodesNodeUidLabelSpacePut(
          nodeUid,
          updateNodeLabelSpacePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NodesApi.updateLabelSpaceNodesNodeUidLabelSpacePut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Node
     * @param {number} nodeUid
     * @param {PatchNodePayload} patchNodePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNodeNodesNodeUidPatch(
      nodeUid: number,
      patchNodePayload: PatchNodePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PatchNodeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateNodeNodesNodeUidPatch(
          nodeUid,
          patchNodePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['NodesApi.updateNodeNodesNodeUidPatch']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * NodesApi - factory interface
 * @export
 */
export const NodesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NodesApiFp(configuration);
  return {
    /**
     *
     * @summary Add Active Datasources
     * @param {NodesApiAddActiveDatasourcesNodesNodeUidActiveDatasourcesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addActiveDatasourcesNodesNodeUidActiveDatasourcesPost(
      requestParameters: NodesApiAddActiveDatasourcesNodesNodeUidActiveDatasourcesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<NodeJobResponse> {
      return localVarFp
        .addActiveDatasourcesNodesNodeUidActiveDatasourcesPost(
          requestParameters.nodeUid,
          requestParameters.setNodeDataParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Commit an OpVersion to this Node or uncommit any committed OpVersions from this Node. Depending on the payload we either - Make a new OpVersion and commit it to this Node - Commit an existing OpVersion to this Node - Uncommit any committed OpVersions from this Node  See the UpdateNodePayload class for specifics on payload semantics
     * @summary Commit Node Route
     * @param {NodesApiCommitNodeRouteNodesNodeUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitNodeRouteNodesNodeUidPut(
      requestParameters: NodesApiCommitNodeRouteNodesNodeUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UpdateNodeResponse> {
      return localVarFp
        .commitNodeRouteNodesNodeUidPut(
          requestParameters.nodeUid,
          requestParameters.updateNodePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Create an Node and place it in the Dataset DAG at the appropriate spot as determined by the input_node_uids and (optionally) the output_node_uid. If the user specifies op_type/op_config, make an OpVersion and commit that version to the new node in the DAG.
     * @summary Create Dataset Node
     * @param {NodesApiCreateDatasetNodeDatasetsDatasetUidNodesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDatasetNodeDatasetsDatasetUidNodesPost(
      requestParameters: NodesApiCreateDatasetNodeDatasetsDatasetUidNodesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateNodeResponse> {
      return localVarFp
        .createDatasetNodeDatasetsDatasetUidNodesPost(
          requestParameters.datasetUid,
          requestParameters.createNodePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create Node Hierarchy Route
     * @param {NodesApiCreateNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost(
      requestParameters: NodesApiCreateNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateModelNodeHierarchyResponse> {
      return localVarFp
        .createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost(
          requestParameters.applicationUid,
          requestParameters.nodeUid,
          requestParameters.createModelNodeHierarchyPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Create an Node and place it in the Application DAG at the appropriate spot as determined by the input_node_uids and (optionally) the output_node_uid. If the user specifies op_type/op_config, make an OpVersion and commit that version to the new node in the DAG.
     * @summary Create Node Route
     * @param {NodesApiCreateNodeRouteApplicationsApplicationUidNodesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNodeRouteApplicationsApplicationUidNodesPost(
      requestParameters: NodesApiCreateNodeRouteApplicationsApplicationUidNodesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateNodeResponse> {
      return localVarFp
        .createNodeRouteApplicationsApplicationUidNodesPost(
          requestParameters.applicationUid,
          requestParameters.createNodePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create Train Sample Datasource
     * @param {NodesApiCreateTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost(
      requestParameters: NodesApiCreateTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<NodeJobResponse> {
      return localVarFp
        .createTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost(
          requestParameters.nodeUid,
          requestParameters.postTrainSampleDatasourceParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Active Node Datasource
     * @param {NodesApiDeleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete(
      requestParameters: NodesApiDeleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete(
          requestParameters.nodeUid,
          requestParameters.deleteNodePreprocessedDatasource,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Delete the node from the database and remove it from the DAG. This operation will remove any leaf node but will only remove internal nodes that maintain the structure of the underlying DAG, e.g. that the number of inputs to all nodes remains the same before and after deletion.
     * @summary Delete Node
     * @param {NodesApiDeleteNodeNodesNodeUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNodeNodesNodeUidDelete(
      requestParameters: NodesApiDeleteNodeNodesNodeUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteNodeNodesNodeUidDelete(requestParameters.nodeUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Node Input Cols Route
     * @param {NodesApiFetchNodeInputColsRouteNodesNodeUidInputColumnsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchNodeInputColsRouteNodesNodeUidInputColumnsGet(
      requestParameters: NodesApiFetchNodeInputColsRouteNodesNodeUidInputColumnsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .fetchNodeInputColsRouteNodesNodeUidInputColumnsGet(
          requestParameters.nodeUid,
          requestParameters.intersection,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Node Input Cols Types
     * @param {NodesApiFetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGet(
      requestParameters: NodesApiFetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .fetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Node
     * @param {NodesApiFetchNodeNodesNodeUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchNodeNodesNodeUidGet(
      requestParameters: NodesApiFetchNodeNodesNodeUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FetchNodeResponse> {
      return localVarFp
        .fetchNodeNodesNodeUidGet(requestParameters.nodeUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Node Output Cols Route
     * @param {NodesApiFetchNodeOutputColsRouteNodesNodeUidOutputColumnsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet(
      requestParameters: NodesApiFetchNodeOutputColsRouteNodesNodeUidOutputColumnsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .fetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet(
          requestParameters.nodeUid,
          requestParameters.intersection,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fit an Operator and return the fitted kwargs Fitted kwargs will then be populated as arguments in the previous modal (op_config edit)
     * @summary Fit Node
     * @param {NodesApiFitNodeApplicationsApplicationUidNodesNodeUidFitPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fitNodeApplicationsApplicationUidNodesNodeUidFitPost(
      requestParameters: NodesApiFitNodeApplicationsApplicationUidNodesNodeUidFitPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<NodeJobResponse> {
      return localVarFp
        .fitNodeApplicationsApplicationUidNodesNodeUidFitPost(
          requestParameters.applicationUid,
          requestParameters.nodeUid,
          requestParameters.fitNodePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Mergeable Nodes
     * @param {NodesApiGetMergeableNodesNodesNodeUidMergeableNodesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMergeableNodesNodesNodeUidMergeableNodesGet(
      requestParameters: NodesApiGetMergeableNodesNodesNodeUidMergeableNodesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<number>> {
      return localVarFp
        .getMergeableNodesNodesNodeUidMergeableNodesGet(
          requestParameters.nodeUid,
          requestParameters.destinationApplicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Node Active Datasources
     * @param {NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet(
      requestParameters: NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PreprocessedDatasourceResponse>> {
      return localVarFp
        .getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          requestParameters.isActive,
          requestParameters.dsRole,
          requestParameters.computeStaleness,
          requestParameters.computeStatistics,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Node Preview
     * @param {NodesApiGetNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet(
      requestParameters: NodesApiGetNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .getNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet(
          requestParameters.applicationUid,
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Returns the skipped datapoints (and optionally the corresponding error message) when preproceprocessing up to node_uid (not including it) for each type of data check.
     * @summary Get Preprocessing Issues For Node
     * @param {NodesApiGetPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet(
      requestParameters: NodesApiGetPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: PreprocessingIssues }> {
      return localVarFp
        .getPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet(
          requestParameters.applicationUid,
          requestParameters.nodeUid,
          requestParameters.onPreviewSample,
          requestParameters.includeErrorMsg,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch the requested setting value for the node.
     * @summary Get Setting
     * @param {NodesApiGetSettingNodesNodeUidSettingsSettingGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettingNodesNodeUidSettingsSettingGet(
      requestParameters: NodesApiGetSettingNodesNodeUidSettingsSettingGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getSettingNodesNodeUidSettingsSettingGet(
          requestParameters.nodeUid,
          requestParameters.setting,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch all settings for the task.
     * @summary Get Settings
     * @param {NodesApiGetSettingsNodesNodeUidSettingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettingsNodesNodeUidSettingsGet(
      requestParameters: NodesApiGetSettingsNodesNodeUidSettingsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetSettingsResponse> {
      return localVarFp
        .getSettingsNodesNodeUidSettingsGet(requestParameters.nodeUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch the model configs for a given task
     * @summary Get Task Model Configs
     * @param {NodesApiGetTaskModelConfigsNodesNodeUidModelConfigsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskModelConfigsNodesNodeUidModelConfigsGet(
      requestParameters: NodesApiGetTaskModelConfigsNodesNodeUidModelConfigsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetModelConfigsResponse> {
      return localVarFp
        .getTaskModelConfigsNodesNodeUidModelConfigsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Training Selected Feature Fields
     * @param {NodesApiGetTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGet(
      requestParameters: NodesApiGetTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .getTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Dataset Nodes
     * @param {NodesApiListDatasetNodesDatasetsDatasetUidNodesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDatasetNodesDatasetsDatasetUidNodesGet(
      requestParameters: NodesApiListDatasetNodesDatasetsDatasetUidNodesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<Node>> {
      return localVarFp
        .listDatasetNodesDatasetsDatasetUidNodesGet(
          requestParameters.datasetUid,
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Nodes
     * @param {NodesApiListNodesApplicationsApplicationUidNodesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listNodesApplicationsApplicationUidNodesGet(
      requestParameters: NodesApiListNodesApplicationsApplicationUidNodesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<Node>> {
      return localVarFp
        .listNodesApplicationsApplicationUidNodesGet(
          requestParameters.applicationUid,
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Put Node Datasource
     * @param {NodesApiPutNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut(
      requestParameters: NodesApiPutNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut(
          requestParameters.nodeUid,
          requestParameters.datasourceUid,
          requestParameters.putNodePreprocessedDatasource,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Put Setting
     * @param {NodesApiPutSettingNodesNodeUidSettingsSettingPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putSettingNodesNodeUidSettingsSettingPut(
      requestParameters: NodesApiPutSettingNodesNodeUidSettingsSettingPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .putSettingNodesNodeUidSettingsSettingPut(
          requestParameters.nodeUid,
          requestParameters.setting,
          requestParameters.settingValue,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Refresh Active Datasources
     * @param {NodesApiRefreshActiveDatasourcesNodesNodeUidRefreshPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshActiveDatasourcesNodesNodeUidRefreshPost(
      requestParameters: NodesApiRefreshActiveDatasourcesNodesNodeUidRefreshPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<NodeJobResponse> {
      return localVarFp
        .refreshActiveDatasourcesNodesNodeUidRefreshPost(
          requestParameters.nodeUid,
          requestParameters.refreshNodeDataParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Update the label_space for an existing Node  The only time we can guarantee that it\'s safe to update the label_space for a node is if the node does not already have a label_space set.
     * @summary Update Label Space
     * @param {NodesApiUpdateLabelSpaceNodesNodeUidLabelSpacePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabelSpaceNodesNodeUidLabelSpacePut(
      requestParameters: NodesApiUpdateLabelSpaceNodesNodeUidLabelSpacePutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UpdateNodeLabelSpaceResponse> {
      return localVarFp
        .updateLabelSpaceNodesNodeUidLabelSpacePut(
          requestParameters.nodeUid,
          requestParameters.updateNodeLabelSpacePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Node
     * @param {NodesApiUpdateNodeNodesNodeUidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNodeNodesNodeUidPatch(
      requestParameters: NodesApiUpdateNodeNodesNodeUidPatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PatchNodeResponse> {
      return localVarFp
        .updateNodeNodesNodeUidPatch(
          requestParameters.nodeUid,
          requestParameters.patchNodePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addActiveDatasourcesNodesNodeUidActiveDatasourcesPost operation in NodesApi.
 * @export
 * @interface NodesApiAddActiveDatasourcesNodesNodeUidActiveDatasourcesPostRequest
 */
export interface NodesApiAddActiveDatasourcesNodesNodeUidActiveDatasourcesPostRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiAddActiveDatasourcesNodesNodeUidActiveDatasourcesPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {SetNodeDataParams}
   * @memberof NodesApiAddActiveDatasourcesNodesNodeUidActiveDatasourcesPost
   */
  readonly setNodeDataParams: SetNodeDataParams;
}

/**
 * Request parameters for commitNodeRouteNodesNodeUidPut operation in NodesApi.
 * @export
 * @interface NodesApiCommitNodeRouteNodesNodeUidPutRequest
 */
export interface NodesApiCommitNodeRouteNodesNodeUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiCommitNodeRouteNodesNodeUidPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {UpdateNodePayload}
   * @memberof NodesApiCommitNodeRouteNodesNodeUidPut
   */
  readonly updateNodePayload: UpdateNodePayload;
}

/**
 * Request parameters for createDatasetNodeDatasetsDatasetUidNodesPost operation in NodesApi.
 * @export
 * @interface NodesApiCreateDatasetNodeDatasetsDatasetUidNodesPostRequest
 */
export interface NodesApiCreateDatasetNodeDatasetsDatasetUidNodesPostRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiCreateDatasetNodeDatasetsDatasetUidNodesPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {CreateNodePayload}
   * @memberof NodesApiCreateDatasetNodeDatasetsDatasetUidNodesPost
   */
  readonly createNodePayload: CreateNodePayload;
}

/**
 * Request parameters for createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost operation in NodesApi.
 * @export
 * @interface NodesApiCreateNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPostRequest
 */
export interface NodesApiCreateNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPostRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiCreateNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof NodesApiCreateNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {CreateModelNodeHierarchyPayload}
   * @memberof NodesApiCreateNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost
   */
  readonly createModelNodeHierarchyPayload: CreateModelNodeHierarchyPayload;
}

/**
 * Request parameters for createNodeRouteApplicationsApplicationUidNodesPost operation in NodesApi.
 * @export
 * @interface NodesApiCreateNodeRouteApplicationsApplicationUidNodesPostRequest
 */
export interface NodesApiCreateNodeRouteApplicationsApplicationUidNodesPostRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiCreateNodeRouteApplicationsApplicationUidNodesPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {CreateNodePayload}
   * @memberof NodesApiCreateNodeRouteApplicationsApplicationUidNodesPost
   */
  readonly createNodePayload: CreateNodePayload;
}

/**
 * Request parameters for createTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost operation in NodesApi.
 * @export
 * @interface NodesApiCreateTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPostRequest
 */
export interface NodesApiCreateTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPostRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiCreateTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {PostTrainSampleDatasourceParams}
   * @memberof NodesApiCreateTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost
   */
  readonly postTrainSampleDatasourceParams: PostTrainSampleDatasourceParams;
}

/**
 * Request parameters for deleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete operation in NodesApi.
 * @export
 * @interface NodesApiDeleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDeleteRequest
 */
export interface NodesApiDeleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiDeleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {DeleteNodePreprocessedDatasource}
   * @memberof NodesApiDeleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete
   */
  readonly deleteNodePreprocessedDatasource: DeleteNodePreprocessedDatasource;
}

/**
 * Request parameters for deleteNodeNodesNodeUidDelete operation in NodesApi.
 * @export
 * @interface NodesApiDeleteNodeNodesNodeUidDeleteRequest
 */
export interface NodesApiDeleteNodeNodesNodeUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiDeleteNodeNodesNodeUidDelete
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for fetchNodeInputColsRouteNodesNodeUidInputColumnsGet operation in NodesApi.
 * @export
 * @interface NodesApiFetchNodeInputColsRouteNodesNodeUidInputColumnsGetRequest
 */
export interface NodesApiFetchNodeInputColsRouteNodesNodeUidInputColumnsGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiFetchNodeInputColsRouteNodesNodeUidInputColumnsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof NodesApiFetchNodeInputColsRouteNodesNodeUidInputColumnsGet
   */
  readonly intersection?: boolean;
}

/**
 * Request parameters for fetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGet operation in NodesApi.
 * @export
 * @interface NodesApiFetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGetRequest
 */
export interface NodesApiFetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiFetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for fetchNodeNodesNodeUidGet operation in NodesApi.
 * @export
 * @interface NodesApiFetchNodeNodesNodeUidGetRequest
 */
export interface NodesApiFetchNodeNodesNodeUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiFetchNodeNodesNodeUidGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for fetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet operation in NodesApi.
 * @export
 * @interface NodesApiFetchNodeOutputColsRouteNodesNodeUidOutputColumnsGetRequest
 */
export interface NodesApiFetchNodeOutputColsRouteNodesNodeUidOutputColumnsGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiFetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof NodesApiFetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet
   */
  readonly intersection?: boolean;
}

/**
 * Request parameters for fitNodeApplicationsApplicationUidNodesNodeUidFitPost operation in NodesApi.
 * @export
 * @interface NodesApiFitNodeApplicationsApplicationUidNodesNodeUidFitPostRequest
 */
export interface NodesApiFitNodeApplicationsApplicationUidNodesNodeUidFitPostRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiFitNodeApplicationsApplicationUidNodesNodeUidFitPost
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof NodesApiFitNodeApplicationsApplicationUidNodesNodeUidFitPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {FitNodePayload}
   * @memberof NodesApiFitNodeApplicationsApplicationUidNodesNodeUidFitPost
   */
  readonly fitNodePayload: FitNodePayload;
}

/**
 * Request parameters for getMergeableNodesNodesNodeUidMergeableNodesGet operation in NodesApi.
 * @export
 * @interface NodesApiGetMergeableNodesNodesNodeUidMergeableNodesGetRequest
 */
export interface NodesApiGetMergeableNodesNodesNodeUidMergeableNodesGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiGetMergeableNodesNodesNodeUidMergeableNodesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof NodesApiGetMergeableNodesNodesNodeUidMergeableNodesGet
   */
  readonly destinationApplicationUid: number;
}

/**
 * Request parameters for getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet operation in NodesApi.
 * @export
 * @interface NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGetRequest
 */
export interface NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet
   */
  readonly endDate?: string;

  /**
   *
   * @type {boolean}
   * @memberof NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet
   */
  readonly isActive?: boolean;

  /**
   *
   * @type {DatasourceRole}
   * @memberof NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet
   */
  readonly dsRole?: DatasourceRole;

  /**
   *
   * @type {boolean}
   * @memberof NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet
   */
  readonly computeStaleness?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet
   */
  readonly computeStatistics?: boolean;
}

/**
 * Request parameters for getNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet operation in NodesApi.
 * @export
 * @interface NodesApiGetNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGetRequest
 */
export interface NodesApiGetNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiGetNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof NodesApiGetNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for getPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet operation in NodesApi.
 * @export
 * @interface NodesApiGetPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGetRequest
 */
export interface NodesApiGetPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiGetPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof NodesApiGetPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof NodesApiGetPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet
   */
  readonly onPreviewSample?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof NodesApiGetPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet
   */
  readonly includeErrorMsg?: boolean;
}

/**
 * Request parameters for getSettingNodesNodeUidSettingsSettingGet operation in NodesApi.
 * @export
 * @interface NodesApiGetSettingNodesNodeUidSettingsSettingGetRequest
 */
export interface NodesApiGetSettingNodesNodeUidSettingsSettingGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiGetSettingNodesNodeUidSettingsSettingGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof NodesApiGetSettingNodesNodeUidSettingsSettingGet
   */
  readonly setting: string;
}

/**
 * Request parameters for getSettingsNodesNodeUidSettingsGet operation in NodesApi.
 * @export
 * @interface NodesApiGetSettingsNodesNodeUidSettingsGetRequest
 */
export interface NodesApiGetSettingsNodesNodeUidSettingsGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiGetSettingsNodesNodeUidSettingsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for getTaskModelConfigsNodesNodeUidModelConfigsGet operation in NodesApi.
 * @export
 * @interface NodesApiGetTaskModelConfigsNodesNodeUidModelConfigsGetRequest
 */
export interface NodesApiGetTaskModelConfigsNodesNodeUidModelConfigsGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiGetTaskModelConfigsNodesNodeUidModelConfigsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for getTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGet operation in NodesApi.
 * @export
 * @interface NodesApiGetTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGetRequest
 */
export interface NodesApiGetTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiGetTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for listDatasetNodesDatasetsDatasetUidNodesGet operation in NodesApi.
 * @export
 * @interface NodesApiListDatasetNodesDatasetsDatasetUidNodesGetRequest
 */
export interface NodesApiListDatasetNodesDatasetsDatasetUidNodesGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiListDatasetNodesDatasetsDatasetUidNodesGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof NodesApiListDatasetNodesDatasetsDatasetUidNodesGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof NodesApiListDatasetNodesDatasetsDatasetUidNodesGet
   */
  readonly offset?: number;
}

/**
 * Request parameters for listNodesApplicationsApplicationUidNodesGet operation in NodesApi.
 * @export
 * @interface NodesApiListNodesApplicationsApplicationUidNodesGetRequest
 */
export interface NodesApiListNodesApplicationsApplicationUidNodesGetRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiListNodesApplicationsApplicationUidNodesGet
   */
  readonly applicationUid: number;

  /**
   *
   * @type {number}
   * @memberof NodesApiListNodesApplicationsApplicationUidNodesGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof NodesApiListNodesApplicationsApplicationUidNodesGet
   */
  readonly offset?: number;
}

/**
 * Request parameters for putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut operation in NodesApi.
 * @export
 * @interface NodesApiPutNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPutRequest
 */
export interface NodesApiPutNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiPutNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof NodesApiPutNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut
   */
  readonly datasourceUid: number;

  /**
   *
   * @type {PutNodePreprocessedDatasource}
   * @memberof NodesApiPutNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut
   */
  readonly putNodePreprocessedDatasource: PutNodePreprocessedDatasource;
}

/**
 * Request parameters for putSettingNodesNodeUidSettingsSettingPut operation in NodesApi.
 * @export
 * @interface NodesApiPutSettingNodesNodeUidSettingsSettingPutRequest
 */
export interface NodesApiPutSettingNodesNodeUidSettingsSettingPutRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiPutSettingNodesNodeUidSettingsSettingPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof NodesApiPutSettingNodesNodeUidSettingsSettingPut
   */
  readonly setting: string;

  /**
   *
   * @type {SettingValue}
   * @memberof NodesApiPutSettingNodesNodeUidSettingsSettingPut
   */
  readonly settingValue: SettingValue;
}

/**
 * Request parameters for refreshActiveDatasourcesNodesNodeUidRefreshPost operation in NodesApi.
 * @export
 * @interface NodesApiRefreshActiveDatasourcesNodesNodeUidRefreshPostRequest
 */
export interface NodesApiRefreshActiveDatasourcesNodesNodeUidRefreshPostRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiRefreshActiveDatasourcesNodesNodeUidRefreshPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {RefreshNodeDataParams}
   * @memberof NodesApiRefreshActiveDatasourcesNodesNodeUidRefreshPost
   */
  readonly refreshNodeDataParams: RefreshNodeDataParams;
}

/**
 * Request parameters for updateLabelSpaceNodesNodeUidLabelSpacePut operation in NodesApi.
 * @export
 * @interface NodesApiUpdateLabelSpaceNodesNodeUidLabelSpacePutRequest
 */
export interface NodesApiUpdateLabelSpaceNodesNodeUidLabelSpacePutRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiUpdateLabelSpaceNodesNodeUidLabelSpacePut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {UpdateNodeLabelSpacePayload}
   * @memberof NodesApiUpdateLabelSpaceNodesNodeUidLabelSpacePut
   */
  readonly updateNodeLabelSpacePayload: UpdateNodeLabelSpacePayload;
}

/**
 * Request parameters for updateNodeNodesNodeUidPatch operation in NodesApi.
 * @export
 * @interface NodesApiUpdateNodeNodesNodeUidPatchRequest
 */
export interface NodesApiUpdateNodeNodesNodeUidPatchRequest {
  /**
   *
   * @type {number}
   * @memberof NodesApiUpdateNodeNodesNodeUidPatch
   */
  readonly nodeUid: number;

  /**
   *
   * @type {PatchNodePayload}
   * @memberof NodesApiUpdateNodeNodesNodeUidPatch
   */
  readonly patchNodePayload: PatchNodePayload;
}

/**
 * NodesApi - object-oriented interface
 * @export
 * @class NodesApi
 * @extends {BaseAPI}
 */
export class NodesApi extends BaseAPI {
  /**
   *
   * @summary Add Active Datasources
   * @param {NodesApiAddActiveDatasourcesNodesNodeUidActiveDatasourcesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public addActiveDatasourcesNodesNodeUidActiveDatasourcesPost(
    requestParameters: NodesApiAddActiveDatasourcesNodesNodeUidActiveDatasourcesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .addActiveDatasourcesNodesNodeUidActiveDatasourcesPost(
        requestParameters.nodeUid,
        requestParameters.setNodeDataParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Commit an OpVersion to this Node or uncommit any committed OpVersions from this Node. Depending on the payload we either - Make a new OpVersion and commit it to this Node - Commit an existing OpVersion to this Node - Uncommit any committed OpVersions from this Node  See the UpdateNodePayload class for specifics on payload semantics
   * @summary Commit Node Route
   * @param {NodesApiCommitNodeRouteNodesNodeUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public commitNodeRouteNodesNodeUidPut(
    requestParameters: NodesApiCommitNodeRouteNodesNodeUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .commitNodeRouteNodesNodeUidPut(
        requestParameters.nodeUid,
        requestParameters.updateNodePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Create an Node and place it in the Dataset DAG at the appropriate spot as determined by the input_node_uids and (optionally) the output_node_uid. If the user specifies op_type/op_config, make an OpVersion and commit that version to the new node in the DAG.
   * @summary Create Dataset Node
   * @param {NodesApiCreateDatasetNodeDatasetsDatasetUidNodesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public createDatasetNodeDatasetsDatasetUidNodesPost(
    requestParameters: NodesApiCreateDatasetNodeDatasetsDatasetUidNodesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .createDatasetNodeDatasetsDatasetUidNodesPost(
        requestParameters.datasetUid,
        requestParameters.createNodePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Node Hierarchy Route
   * @param {NodesApiCreateNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost(
    requestParameters: NodesApiCreateNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .createNodeHierarchyRouteApplicationsApplicationUidNodesNodeUidHierarchyPost(
        requestParameters.applicationUid,
        requestParameters.nodeUid,
        requestParameters.createModelNodeHierarchyPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Create an Node and place it in the Application DAG at the appropriate spot as determined by the input_node_uids and (optionally) the output_node_uid. If the user specifies op_type/op_config, make an OpVersion and commit that version to the new node in the DAG.
   * @summary Create Node Route
   * @param {NodesApiCreateNodeRouteApplicationsApplicationUidNodesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public createNodeRouteApplicationsApplicationUidNodesPost(
    requestParameters: NodesApiCreateNodeRouteApplicationsApplicationUidNodesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .createNodeRouteApplicationsApplicationUidNodesPost(
        requestParameters.applicationUid,
        requestParameters.createNodePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Train Sample Datasource
   * @param {NodesApiCreateTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public createTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost(
    requestParameters: NodesApiCreateTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .createTrainSampleDatasourceNodesNodeUidTrainSampleDatasourcesPost(
        requestParameters.nodeUid,
        requestParameters.postTrainSampleDatasourceParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Active Node Datasource
   * @param {NodesApiDeleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public deleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete(
    requestParameters: NodesApiDeleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .deleteActiveNodeDatasourceNodesNodeUidActiveDatasourcesDelete(
        requestParameters.nodeUid,
        requestParameters.deleteNodePreprocessedDatasource,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete the node from the database and remove it from the DAG. This operation will remove any leaf node but will only remove internal nodes that maintain the structure of the underlying DAG, e.g. that the number of inputs to all nodes remains the same before and after deletion.
   * @summary Delete Node
   * @param {NodesApiDeleteNodeNodesNodeUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public deleteNodeNodesNodeUidDelete(
    requestParameters: NodesApiDeleteNodeNodesNodeUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .deleteNodeNodesNodeUidDelete(requestParameters.nodeUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Node Input Cols Route
   * @param {NodesApiFetchNodeInputColsRouteNodesNodeUidInputColumnsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public fetchNodeInputColsRouteNodesNodeUidInputColumnsGet(
    requestParameters: NodesApiFetchNodeInputColsRouteNodesNodeUidInputColumnsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .fetchNodeInputColsRouteNodesNodeUidInputColumnsGet(
        requestParameters.nodeUid,
        requestParameters.intersection,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Node Input Cols Types
   * @param {NodesApiFetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public fetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGet(
    requestParameters: NodesApiFetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .fetchNodeInputColsTypesNodesNodeUidInputColumnsTypesGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Node
   * @param {NodesApiFetchNodeNodesNodeUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public fetchNodeNodesNodeUidGet(
    requestParameters: NodesApiFetchNodeNodesNodeUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .fetchNodeNodesNodeUidGet(requestParameters.nodeUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Node Output Cols Route
   * @param {NodesApiFetchNodeOutputColsRouteNodesNodeUidOutputColumnsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public fetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet(
    requestParameters: NodesApiFetchNodeOutputColsRouteNodesNodeUidOutputColumnsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .fetchNodeOutputColsRouteNodesNodeUidOutputColumnsGet(
        requestParameters.nodeUid,
        requestParameters.intersection,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fit an Operator and return the fitted kwargs Fitted kwargs will then be populated as arguments in the previous modal (op_config edit)
   * @summary Fit Node
   * @param {NodesApiFitNodeApplicationsApplicationUidNodesNodeUidFitPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public fitNodeApplicationsApplicationUidNodesNodeUidFitPost(
    requestParameters: NodesApiFitNodeApplicationsApplicationUidNodesNodeUidFitPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .fitNodeApplicationsApplicationUidNodesNodeUidFitPost(
        requestParameters.applicationUid,
        requestParameters.nodeUid,
        requestParameters.fitNodePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Mergeable Nodes
   * @param {NodesApiGetMergeableNodesNodesNodeUidMergeableNodesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public getMergeableNodesNodesNodeUidMergeableNodesGet(
    requestParameters: NodesApiGetMergeableNodesNodesNodeUidMergeableNodesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .getMergeableNodesNodesNodeUidMergeableNodesGet(
        requestParameters.nodeUid,
        requestParameters.destinationApplicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Node Active Datasources
   * @param {NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet(
    requestParameters: NodesApiGetNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .getNodeActiveDatasourcesNodesNodeUidActiveDatasourcesGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.isActive,
        requestParameters.dsRole,
        requestParameters.computeStaleness,
        requestParameters.computeStatistics,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Node Preview
   * @param {NodesApiGetNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public getNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet(
    requestParameters: NodesApiGetNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .getNodePreviewApplicationsApplicationUidNodesNodeUidPreviewGet(
        requestParameters.applicationUid,
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Returns the skipped datapoints (and optionally the corresponding error message) when preproceprocessing up to node_uid (not including it) for each type of data check.
   * @summary Get Preprocessing Issues For Node
   * @param {NodesApiGetPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public getPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet(
    requestParameters: NodesApiGetPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .getPreprocessingIssuesForNodeApplicationsApplicationUidNodesNodeUidPreprocessingIssuesGet(
        requestParameters.applicationUid,
        requestParameters.nodeUid,
        requestParameters.onPreviewSample,
        requestParameters.includeErrorMsg,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch the requested setting value for the node.
   * @summary Get Setting
   * @param {NodesApiGetSettingNodesNodeUidSettingsSettingGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public getSettingNodesNodeUidSettingsSettingGet(
    requestParameters: NodesApiGetSettingNodesNodeUidSettingsSettingGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .getSettingNodesNodeUidSettingsSettingGet(
        requestParameters.nodeUid,
        requestParameters.setting,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch all settings for the task.
   * @summary Get Settings
   * @param {NodesApiGetSettingsNodesNodeUidSettingsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public getSettingsNodesNodeUidSettingsGet(
    requestParameters: NodesApiGetSettingsNodesNodeUidSettingsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .getSettingsNodesNodeUidSettingsGet(requestParameters.nodeUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch the model configs for a given task
   * @summary Get Task Model Configs
   * @param {NodesApiGetTaskModelConfigsNodesNodeUidModelConfigsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public getTaskModelConfigsNodesNodeUidModelConfigsGet(
    requestParameters: NodesApiGetTaskModelConfigsNodesNodeUidModelConfigsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .getTaskModelConfigsNodesNodeUidModelConfigsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Training Selected Feature Fields
   * @param {NodesApiGetTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public getTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGet(
    requestParameters: NodesApiGetTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .getTrainingSelectedFeatureFieldsNodesNodeUidTrainingSelectedFeatureFieldsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Dataset Nodes
   * @param {NodesApiListDatasetNodesDatasetsDatasetUidNodesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public listDatasetNodesDatasetsDatasetUidNodesGet(
    requestParameters: NodesApiListDatasetNodesDatasetsDatasetUidNodesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .listDatasetNodesDatasetsDatasetUidNodesGet(
        requestParameters.datasetUid,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Nodes
   * @param {NodesApiListNodesApplicationsApplicationUidNodesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public listNodesApplicationsApplicationUidNodesGet(
    requestParameters: NodesApiListNodesApplicationsApplicationUidNodesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .listNodesApplicationsApplicationUidNodesGet(
        requestParameters.applicationUid,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Put Node Datasource
   * @param {NodesApiPutNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut(
    requestParameters: NodesApiPutNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .putNodeDatasourceNodesNodeUidActiveDatasourcesDatasourceUidPut(
        requestParameters.nodeUid,
        requestParameters.datasourceUid,
        requestParameters.putNodePreprocessedDatasource,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Put Setting
   * @param {NodesApiPutSettingNodesNodeUidSettingsSettingPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public putSettingNodesNodeUidSettingsSettingPut(
    requestParameters: NodesApiPutSettingNodesNodeUidSettingsSettingPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .putSettingNodesNodeUidSettingsSettingPut(
        requestParameters.nodeUid,
        requestParameters.setting,
        requestParameters.settingValue,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Refresh Active Datasources
   * @param {NodesApiRefreshActiveDatasourcesNodesNodeUidRefreshPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public refreshActiveDatasourcesNodesNodeUidRefreshPost(
    requestParameters: NodesApiRefreshActiveDatasourcesNodesNodeUidRefreshPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .refreshActiveDatasourcesNodesNodeUidRefreshPost(
        requestParameters.nodeUid,
        requestParameters.refreshNodeDataParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Update the label_space for an existing Node  The only time we can guarantee that it\'s safe to update the label_space for a node is if the node does not already have a label_space set.
   * @summary Update Label Space
   * @param {NodesApiUpdateLabelSpaceNodesNodeUidLabelSpacePutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public updateLabelSpaceNodesNodeUidLabelSpacePut(
    requestParameters: NodesApiUpdateLabelSpaceNodesNodeUidLabelSpacePutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .updateLabelSpaceNodesNodeUidLabelSpacePut(
        requestParameters.nodeUid,
        requestParameters.updateNodeLabelSpacePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Node
   * @param {NodesApiUpdateNodeNodesNodeUidPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NodesApi
   */
  public updateNodeNodesNodeUidPatch(
    requestParameters: NodesApiUpdateNodeNodesNodeUidPatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NodesApiFp(this.configuration)
      .updateNodeNodesNodeUidPatch(
        requestParameters.nodeUid,
        requestParameters.patchNodePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * NotebookSettingsApi - axios parameter creator
 * @export
 */
export const NotebookSettingsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Notebook Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotebookSettingsNotebookSettingsGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notebook-settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Notebook Settings
     * @param {NotebookSettings} notebookSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotebookSettingsNotebookSettingsPost: async (
      notebookSettings: NotebookSettings,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notebookSettings' is not null or undefined
      assertParamExists(
        'updateNotebookSettingsNotebookSettingsPost',
        'notebookSettings',
        notebookSettings,
      );
      const localVarPath = `/notebook-settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notebookSettings,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotebookSettingsApi - functional programming interface
 * @export
 */
export const NotebookSettingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    NotebookSettingsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Notebook Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotebookSettingsNotebookSettingsGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NotebookSettings>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNotebookSettingsNotebookSettingsGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotebookSettingsApi.getNotebookSettingsNotebookSettingsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Notebook Settings
     * @param {NotebookSettings} notebookSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNotebookSettingsNotebookSettingsPost(
      notebookSettings: NotebookSettings,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateNotebookSettingsNotebookSettingsPost(
          notebookSettings,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotebookSettingsApi.updateNotebookSettingsNotebookSettingsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * NotebookSettingsApi - factory interface
 * @export
 */
export const NotebookSettingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotebookSettingsApiFp(configuration);
  return {
    /**
     *
     * @summary Get Notebook Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotebookSettingsNotebookSettingsGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<NotebookSettings> {
      return localVarFp
        .getNotebookSettingsNotebookSettingsGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Notebook Settings
     * @param {NotebookSettingsApiUpdateNotebookSettingsNotebookSettingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotebookSettingsNotebookSettingsPost(
      requestParameters: NotebookSettingsApiUpdateNotebookSettingsNotebookSettingsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateNotebookSettingsNotebookSettingsPost(
          requestParameters.notebookSettings,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for updateNotebookSettingsNotebookSettingsPost operation in NotebookSettingsApi.
 * @export
 * @interface NotebookSettingsApiUpdateNotebookSettingsNotebookSettingsPostRequest
 */
export interface NotebookSettingsApiUpdateNotebookSettingsNotebookSettingsPostRequest {
  /**
   *
   * @type {NotebookSettings}
   * @memberof NotebookSettingsApiUpdateNotebookSettingsNotebookSettingsPost
   */
  readonly notebookSettings: NotebookSettings;
}

/**
 * NotebookSettingsApi - object-oriented interface
 * @export
 * @class NotebookSettingsApi
 * @extends {BaseAPI}
 */
export class NotebookSettingsApi extends BaseAPI {
  /**
   *
   * @summary Get Notebook Settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotebookSettingsApi
   */
  public getNotebookSettingsNotebookSettingsGet(
    options?: RawAxiosRequestConfig,
  ) {
    return NotebookSettingsApiFp(this.configuration)
      .getNotebookSettingsNotebookSettingsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Notebook Settings
   * @param {NotebookSettingsApiUpdateNotebookSettingsNotebookSettingsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotebookSettingsApi
   */
  public updateNotebookSettingsNotebookSettingsPost(
    requestParameters: NotebookSettingsApiUpdateNotebookSettingsNotebookSettingsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NotebookSettingsApiFp(this.configuration)
      .updateNotebookSettingsNotebookSettingsPost(
        requestParameters.notebookSettings,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * NotebooksApi - axios parameter creator
 * @export
 */
export const NotebooksApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Notebook
     * @param {number} userUid
     * @param {string} username
     * @param {Notebooks} [notebook]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotebookNotebookUserUserUidDelete: async (
      userUid: number,
      username: string,
      notebook?: Notebooks,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'deleteNotebookNotebookUserUserUidDelete',
        'userUid',
        userUid,
      );
      // verify required parameter 'username' is not null or undefined
      assertParamExists(
        'deleteNotebookNotebookUserUserUidDelete',
        'username',
        username,
      );
      const localVarPath = `/notebook/user/{user_uid}`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (username !== undefined) {
        localVarQueryParameter['username'] = username;
      }

      if (notebook !== undefined) {
        localVarQueryParameter['notebook'] = notebook;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Notebook Url
     * @param {number} userUid
     * @param {string} username
     * @param {Notebooks} [notebook]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotebookUrlNotebookUserUserUidGet: async (
      userUid: number,
      username: string,
      notebook?: Notebooks,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'getNotebookUrlNotebookUserUserUidGet',
        'userUid',
        userUid,
      );
      // verify required parameter 'username' is not null or undefined
      assertParamExists(
        'getNotebookUrlNotebookUserUserUidGet',
        'username',
        username,
      );
      const localVarPath = `/notebook/user/{user_uid}`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (username !== undefined) {
        localVarQueryParameter['username'] = username;
      }

      if (notebook !== undefined) {
        localVarQueryParameter['notebook'] = notebook;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Singleruser User Notebook
     * @param {number} userUid
     * @param {string} username
     * @param {Notebooks} [notebook]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSingleruserUserNotebookSingleuserNotebookUserUserUidGet: async (
      userUid: number,
      username: string,
      notebook?: Notebooks,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'getSingleruserUserNotebookSingleuserNotebookUserUserUidGet',
        'userUid',
        userUid,
      );
      // verify required parameter 'username' is not null or undefined
      assertParamExists(
        'getSingleruserUserNotebookSingleuserNotebookUserUserUidGet',
        'username',
        username,
      );
      const localVarPath = `/singleuser-notebook/user/{user_uid}`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (username !== undefined) {
        localVarQueryParameter['username'] = username;
      }

      if (notebook !== undefined) {
        localVarQueryParameter['notebook'] = notebook;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch full notebook url.
     * @summary Get Singleuser User Notebook Dir
     * @param {number} userUid
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet: async (
      userUid: number,
      username: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet',
        'userUid',
        userUid,
      );
      // verify required parameter 'username' is not null or undefined
      assertParamExists(
        'getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet',
        'username',
        username,
      );
      const localVarPath = `/singleuser-notebook/user/{user_uid}/dir`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (username !== undefined) {
        localVarQueryParameter['username'] = username;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get User Notebook Dir
     * @param {number} userUid
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserNotebookDirNotebookUserUserUidDirGet: async (
      userUid: number,
      username: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'getUserNotebookDirNotebookUserUserUidDirGet',
        'userUid',
        userUid,
      );
      // verify required parameter 'username' is not null or undefined
      assertParamExists(
        'getUserNotebookDirNotebookUserUserUidDirGet',
        'username',
        username,
      );
      const localVarPath = `/notebook/user/{user_uid}/dir`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (username !== undefined) {
        localVarQueryParameter['username'] = username;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Notebook For User
     * @param {number} userUid
     * @param {string} destPath
     * @param {File} notebook
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadNotebookForUserNotebookUserUserUidPost: async (
      userUid: number,
      destPath: string,
      notebook: File,
      username: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'uploadNotebookForUserNotebookUserUserUidPost',
        'userUid',
        userUid,
      );
      // verify required parameter 'destPath' is not null or undefined
      assertParamExists(
        'uploadNotebookForUserNotebookUserUserUidPost',
        'destPath',
        destPath,
      );
      // verify required parameter 'notebook' is not null or undefined
      assertParamExists(
        'uploadNotebookForUserNotebookUserUserUidPost',
        'notebook',
        notebook,
      );
      // verify required parameter 'username' is not null or undefined
      assertParamExists(
        'uploadNotebookForUserNotebookUserUserUidPost',
        'username',
        username,
      );
      const localVarPath = `/notebook/user/{user_uid}`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (destPath !== undefined) {
        localVarFormParams.append('dest_path', destPath as any);
      }

      if (notebook !== undefined) {
        localVarFormParams.append('notebook', notebook as any);
      }

      if (username !== undefined) {
        localVarFormParams.append('username', username as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotebooksApi - functional programming interface
 * @export
 */
export const NotebooksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    NotebooksApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Notebook
     * @param {number} userUid
     * @param {string} username
     * @param {Notebooks} [notebook]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNotebookNotebookUserUserUidDelete(
      userUid: number,
      username: string,
      notebook?: Notebooks,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteNotebookNotebookUserUserUidDelete(
          userUid,
          username,
          notebook,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotebooksApi.deleteNotebookNotebookUserUserUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Notebook Url
     * @param {number} userUid
     * @param {string} username
     * @param {Notebooks} [notebook]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotebookUrlNotebookUserUserUidGet(
      userUid: number,
      username: string,
      notebook?: Notebooks,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNotebookUrlNotebookUserUserUidGet(
          userUid,
          username,
          notebook,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotebooksApi.getNotebookUrlNotebookUserUserUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Singleruser User Notebook
     * @param {number} userUid
     * @param {string} username
     * @param {Notebooks} [notebook]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSingleruserUserNotebookSingleuserNotebookUserUserUidGet(
      userUid: number,
      username: string,
      notebook?: Notebooks,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSingleruserUserNotebookSingleuserNotebookUserUserUidGet(
          userUid,
          username,
          notebook,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotebooksApi.getSingleruserUserNotebookSingleuserNotebookUserUserUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch full notebook url.
     * @summary Get Singleuser User Notebook Dir
     * @param {number} userUid
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet(
      userUid: number,
      username: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet(
          userUid,
          username,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotebooksApi.getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get User Notebook Dir
     * @param {number} userUid
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserNotebookDirNotebookUserUserUidDirGet(
      userUid: number,
      username: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserNotebookDirNotebookUserUserUidDirGet(
          userUid,
          username,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotebooksApi.getUserNotebookDirNotebookUserUserUidDirGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Upload Notebook For User
     * @param {number} userUid
     * @param {string} destPath
     * @param {File} notebook
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadNotebookForUserNotebookUserUserUidPost(
      userUid: number,
      destPath: string,
      notebook: File,
      username: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadNotebookForUserNotebookUserUserUidPost(
          userUid,
          destPath,
          notebook,
          username,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotebooksApi.uploadNotebookForUserNotebookUserUserUidPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * NotebooksApi - factory interface
 * @export
 */
export const NotebooksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotebooksApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Notebook
     * @param {NotebooksApiDeleteNotebookNotebookUserUserUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotebookNotebookUserUserUidDelete(
      requestParameters: NotebooksApiDeleteNotebookNotebookUserUserUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deleteNotebookNotebookUserUserUidDelete(
          requestParameters.userUid,
          requestParameters.username,
          requestParameters.notebook,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Notebook Url
     * @param {NotebooksApiGetNotebookUrlNotebookUserUserUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotebookUrlNotebookUserUserUidGet(
      requestParameters: NotebooksApiGetNotebookUrlNotebookUserUserUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getNotebookUrlNotebookUserUserUidGet(
          requestParameters.userUid,
          requestParameters.username,
          requestParameters.notebook,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Singleruser User Notebook
     * @param {NotebooksApiGetSingleruserUserNotebookSingleuserNotebookUserUserUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSingleruserUserNotebookSingleuserNotebookUserUserUidGet(
      requestParameters: NotebooksApiGetSingleruserUserNotebookSingleuserNotebookUserUserUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getSingleruserUserNotebookSingleuserNotebookUserUserUidGet(
          requestParameters.userUid,
          requestParameters.username,
          requestParameters.notebook,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch full notebook url.
     * @summary Get Singleuser User Notebook Dir
     * @param {NotebooksApiGetSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet(
      requestParameters: NotebooksApiGetSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet(
          requestParameters.userUid,
          requestParameters.username,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get User Notebook Dir
     * @param {NotebooksApiGetUserNotebookDirNotebookUserUserUidDirGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserNotebookDirNotebookUserUserUidDirGet(
      requestParameters: NotebooksApiGetUserNotebookDirNotebookUserUserUidDirGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getUserNotebookDirNotebookUserUserUidDirGet(
          requestParameters.userUid,
          requestParameters.username,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Notebook For User
     * @param {NotebooksApiUploadNotebookForUserNotebookUserUserUidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadNotebookForUserNotebookUserUserUidPost(
      requestParameters: NotebooksApiUploadNotebookForUserNotebookUserUserUidPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .uploadNotebookForUserNotebookUserUserUidPost(
          requestParameters.userUid,
          requestParameters.destPath,
          requestParameters.notebook,
          requestParameters.username,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteNotebookNotebookUserUserUidDelete operation in NotebooksApi.
 * @export
 * @interface NotebooksApiDeleteNotebookNotebookUserUserUidDeleteRequest
 */
export interface NotebooksApiDeleteNotebookNotebookUserUserUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof NotebooksApiDeleteNotebookNotebookUserUserUidDelete
   */
  readonly userUid: number;

  /**
   *
   * @type {string}
   * @memberof NotebooksApiDeleteNotebookNotebookUserUserUidDelete
   */
  readonly username: string;

  /**
   *
   * @type {Notebooks}
   * @memberof NotebooksApiDeleteNotebookNotebookUserUserUidDelete
   */
  readonly notebook?: Notebooks;
}

/**
 * Request parameters for getNotebookUrlNotebookUserUserUidGet operation in NotebooksApi.
 * @export
 * @interface NotebooksApiGetNotebookUrlNotebookUserUserUidGetRequest
 */
export interface NotebooksApiGetNotebookUrlNotebookUserUserUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof NotebooksApiGetNotebookUrlNotebookUserUserUidGet
   */
  readonly userUid: number;

  /**
   *
   * @type {string}
   * @memberof NotebooksApiGetNotebookUrlNotebookUserUserUidGet
   */
  readonly username: string;

  /**
   *
   * @type {Notebooks}
   * @memberof NotebooksApiGetNotebookUrlNotebookUserUserUidGet
   */
  readonly notebook?: Notebooks;
}

/**
 * Request parameters for getSingleruserUserNotebookSingleuserNotebookUserUserUidGet operation in NotebooksApi.
 * @export
 * @interface NotebooksApiGetSingleruserUserNotebookSingleuserNotebookUserUserUidGetRequest
 */
export interface NotebooksApiGetSingleruserUserNotebookSingleuserNotebookUserUserUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof NotebooksApiGetSingleruserUserNotebookSingleuserNotebookUserUserUidGet
   */
  readonly userUid: number;

  /**
   *
   * @type {string}
   * @memberof NotebooksApiGetSingleruserUserNotebookSingleuserNotebookUserUserUidGet
   */
  readonly username: string;

  /**
   *
   * @type {Notebooks}
   * @memberof NotebooksApiGetSingleruserUserNotebookSingleuserNotebookUserUserUidGet
   */
  readonly notebook?: Notebooks;
}

/**
 * Request parameters for getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet operation in NotebooksApi.
 * @export
 * @interface NotebooksApiGetSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGetRequest
 */
export interface NotebooksApiGetSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGetRequest {
  /**
   *
   * @type {number}
   * @memberof NotebooksApiGetSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet
   */
  readonly userUid: number;

  /**
   *
   * @type {string}
   * @memberof NotebooksApiGetSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet
   */
  readonly username: string;
}

/**
 * Request parameters for getUserNotebookDirNotebookUserUserUidDirGet operation in NotebooksApi.
 * @export
 * @interface NotebooksApiGetUserNotebookDirNotebookUserUserUidDirGetRequest
 */
export interface NotebooksApiGetUserNotebookDirNotebookUserUserUidDirGetRequest {
  /**
   *
   * @type {number}
   * @memberof NotebooksApiGetUserNotebookDirNotebookUserUserUidDirGet
   */
  readonly userUid: number;

  /**
   *
   * @type {string}
   * @memberof NotebooksApiGetUserNotebookDirNotebookUserUserUidDirGet
   */
  readonly username: string;
}

/**
 * Request parameters for uploadNotebookForUserNotebookUserUserUidPost operation in NotebooksApi.
 * @export
 * @interface NotebooksApiUploadNotebookForUserNotebookUserUserUidPostRequest
 */
export interface NotebooksApiUploadNotebookForUserNotebookUserUserUidPostRequest {
  /**
   *
   * @type {number}
   * @memberof NotebooksApiUploadNotebookForUserNotebookUserUserUidPost
   */
  readonly userUid: number;

  /**
   *
   * @type {string}
   * @memberof NotebooksApiUploadNotebookForUserNotebookUserUserUidPost
   */
  readonly destPath: string;

  /**
   *
   * @type {File}
   * @memberof NotebooksApiUploadNotebookForUserNotebookUserUserUidPost
   */
  readonly notebook: File;

  /**
   *
   * @type {string}
   * @memberof NotebooksApiUploadNotebookForUserNotebookUserUserUidPost
   */
  readonly username: string;
}

/**
 * NotebooksApi - object-oriented interface
 * @export
 * @class NotebooksApi
 * @extends {BaseAPI}
 */
export class NotebooksApi extends BaseAPI {
  /**
   *
   * @summary Delete Notebook
   * @param {NotebooksApiDeleteNotebookNotebookUserUserUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotebooksApi
   */
  public deleteNotebookNotebookUserUserUidDelete(
    requestParameters: NotebooksApiDeleteNotebookNotebookUserUserUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NotebooksApiFp(this.configuration)
      .deleteNotebookNotebookUserUserUidDelete(
        requestParameters.userUid,
        requestParameters.username,
        requestParameters.notebook,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Notebook Url
   * @param {NotebooksApiGetNotebookUrlNotebookUserUserUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotebooksApi
   */
  public getNotebookUrlNotebookUserUserUidGet(
    requestParameters: NotebooksApiGetNotebookUrlNotebookUserUserUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NotebooksApiFp(this.configuration)
      .getNotebookUrlNotebookUserUserUidGet(
        requestParameters.userUid,
        requestParameters.username,
        requestParameters.notebook,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Singleruser User Notebook
   * @param {NotebooksApiGetSingleruserUserNotebookSingleuserNotebookUserUserUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotebooksApi
   */
  public getSingleruserUserNotebookSingleuserNotebookUserUserUidGet(
    requestParameters: NotebooksApiGetSingleruserUserNotebookSingleuserNotebookUserUserUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NotebooksApiFp(this.configuration)
      .getSingleruserUserNotebookSingleuserNotebookUserUserUidGet(
        requestParameters.userUid,
        requestParameters.username,
        requestParameters.notebook,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch full notebook url.
   * @summary Get Singleuser User Notebook Dir
   * @param {NotebooksApiGetSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotebooksApi
   */
  public getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet(
    requestParameters: NotebooksApiGetSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NotebooksApiFp(this.configuration)
      .getSingleuserUserNotebookDirSingleuserNotebookUserUserUidDirGet(
        requestParameters.userUid,
        requestParameters.username,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get User Notebook Dir
   * @param {NotebooksApiGetUserNotebookDirNotebookUserUserUidDirGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotebooksApi
   */
  public getUserNotebookDirNotebookUserUserUidDirGet(
    requestParameters: NotebooksApiGetUserNotebookDirNotebookUserUserUidDirGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NotebooksApiFp(this.configuration)
      .getUserNotebookDirNotebookUserUserUidDirGet(
        requestParameters.userUid,
        requestParameters.username,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Notebook For User
   * @param {NotebooksApiUploadNotebookForUserNotebookUserUserUidPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotebooksApi
   */
  public uploadNotebookForUserNotebookUserUserUidPost(
    requestParameters: NotebooksApiUploadNotebookForUserNotebookUserUserUidPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NotebooksApiFp(this.configuration)
      .uploadNotebookForUserNotebookUserUserUidPost(
        requestParameters.userUid,
        requestParameters.destPath,
        requestParameters.notebook,
        requestParameters.username,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Delete a notification for a user given the notification uid.
     * @summary Delete Notification
     * @param {number} notificationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificationNotificationsNotificationUidDelete: async (
      notificationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notificationUid' is not null or undefined
      assertParamExists(
        'deleteNotificationNotificationsNotificationUidDelete',
        'notificationUid',
        notificationUid,
      );
      const localVarPath = `/notifications/{notification_uid}`.replace(
        `{${'notification_uid'}}`,
        encodeURIComponent(String(notificationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete all notifications for a user.
     * @summary Delete Notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificationsNotificationsDelete: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch the notification for a user given the notification uid.
     * @summary Get Notification
     * @param {number} notificationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationNotificationsNotificationUidGet: async (
      notificationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notificationUid' is not null or undefined
      assertParamExists(
        'getNotificationNotificationsNotificationUidGet',
        'notificationUid',
        notificationUid,
      );
      const localVarPath = `/notifications/{notification_uid}`.replace(
        `{${'notification_uid'}}`,
        encodeURIComponent(String(notificationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch the notifications for a user.
     * @summary List Notifications
     * @param {number} [max]
     * @param {string} [startTimestamp]
     * @param {string} [endTimestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listNotificationsNotificationsGet: async (
      max?: number,
      startTimestamp?: string,
      endTimestamp?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (max !== undefined) {
        localVarQueryParameter['max'] = max;
      }

      if (startTimestamp !== undefined) {
        localVarQueryParameter['start_timestamp'] =
          (startTimestamp as any) instanceof Date
            ? (startTimestamp as any).toISOString()
            : startTimestamp;
      }

      if (endTimestamp !== undefined) {
        localVarQueryParameter['end_timestamp'] =
          (endTimestamp as any) instanceof Date
            ? (endTimestamp as any).toISOString()
            : endTimestamp;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the status of a notification given the notification uid.
     * @summary Update Notification Status
     * @param {number} notificationUid
     * @param {UpdateNotificationPayload} updateNotificationPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationStatusNotificationsNotificationUidPatch: async (
      notificationUid: number,
      updateNotificationPayload: UpdateNotificationPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notificationUid' is not null or undefined
      assertParamExists(
        'updateNotificationStatusNotificationsNotificationUidPatch',
        'notificationUid',
        notificationUid,
      );
      // verify required parameter 'updateNotificationPayload' is not null or undefined
      assertParamExists(
        'updateNotificationStatusNotificationsNotificationUidPatch',
        'updateNotificationPayload',
        updateNotificationPayload,
      );
      const localVarPath = `/notifications/{notification_uid}`.replace(
        `{${'notification_uid'}}`,
        encodeURIComponent(String(notificationUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateNotificationPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    NotificationsApiAxiosParamCreator(configuration);
  return {
    /**
     * Delete a notification for a user given the notification uid.
     * @summary Delete Notification
     * @param {number} notificationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNotificationNotificationsNotificationUidDelete(
      notificationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteNotificationNotificationsNotificationUidDelete(
          notificationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotificationsApi.deleteNotificationNotificationsNotificationUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete all notifications for a user.
     * @summary Delete Notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNotificationsNotificationsDelete(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteNotificationsNotificationsDelete(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotificationsApi.deleteNotificationsNotificationsDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch the notification for a user given the notification uid.
     * @summary Get Notification
     * @param {number} notificationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotificationNotificationsNotificationUidGet(
      notificationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNotificationNotificationsNotificationUidGet(
          notificationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotificationsApi.getNotificationNotificationsNotificationUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch the notifications for a user.
     * @summary List Notifications
     * @param {number} [max]
     * @param {string} [startTimestamp]
     * @param {string} [endTimestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listNotificationsNotificationsGet(
      max?: number,
      startTimestamp?: string,
      endTimestamp?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ListNotificationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listNotificationsNotificationsGet(
          max,
          startTimestamp,
          endTimestamp,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotificationsApi.listNotificationsNotificationsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update the status of a notification given the notification uid.
     * @summary Update Notification Status
     * @param {number} notificationUid
     * @param {UpdateNotificationPayload} updateNotificationPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNotificationStatusNotificationsNotificationUidPatch(
      notificationUid: number,
      updateNotificationPayload: UpdateNotificationPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateNotificationStatusNotificationsNotificationUidPatch(
          notificationUid,
          updateNotificationPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'NotificationsApi.updateNotificationStatusNotificationsNotificationUidPatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotificationsApiFp(configuration);
  return {
    /**
     * Delete a notification for a user given the notification uid.
     * @summary Delete Notification
     * @param {NotificationsApiDeleteNotificationNotificationsNotificationUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificationNotificationsNotificationUidDelete(
      requestParameters: NotificationsApiDeleteNotificationNotificationsNotificationUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteNotificationNotificationsNotificationUidDelete(
          requestParameters.notificationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Delete all notifications for a user.
     * @summary Delete Notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificationsNotificationsDelete(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteNotificationsNotificationsDelete(options)
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch the notification for a user given the notification uid.
     * @summary Get Notification
     * @param {NotificationsApiGetNotificationNotificationsNotificationUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationNotificationsNotificationUidGet(
      requestParameters: NotificationsApiGetNotificationNotificationsNotificationUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Notification> {
      return localVarFp
        .getNotificationNotificationsNotificationUidGet(
          requestParameters.notificationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch the notifications for a user.
     * @summary List Notifications
     * @param {NotificationsApiListNotificationsNotificationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listNotificationsNotificationsGet(
      requestParameters: NotificationsApiListNotificationsNotificationsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ListNotificationsResponse> {
      return localVarFp
        .listNotificationsNotificationsGet(
          requestParameters.max,
          requestParameters.startTimestamp,
          requestParameters.endTimestamp,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Update the status of a notification given the notification uid.
     * @summary Update Notification Status
     * @param {NotificationsApiUpdateNotificationStatusNotificationsNotificationUidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationStatusNotificationsNotificationUidPatch(
      requestParameters: NotificationsApiUpdateNotificationStatusNotificationsNotificationUidPatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateNotificationStatusNotificationsNotificationUidPatch(
          requestParameters.notificationUid,
          requestParameters.updateNotificationPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteNotificationNotificationsNotificationUidDelete operation in NotificationsApi.
 * @export
 * @interface NotificationsApiDeleteNotificationNotificationsNotificationUidDeleteRequest
 */
export interface NotificationsApiDeleteNotificationNotificationsNotificationUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof NotificationsApiDeleteNotificationNotificationsNotificationUidDelete
   */
  readonly notificationUid: number;
}

/**
 * Request parameters for getNotificationNotificationsNotificationUidGet operation in NotificationsApi.
 * @export
 * @interface NotificationsApiGetNotificationNotificationsNotificationUidGetRequest
 */
export interface NotificationsApiGetNotificationNotificationsNotificationUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof NotificationsApiGetNotificationNotificationsNotificationUidGet
   */
  readonly notificationUid: number;
}

/**
 * Request parameters for listNotificationsNotificationsGet operation in NotificationsApi.
 * @export
 * @interface NotificationsApiListNotificationsNotificationsGetRequest
 */
export interface NotificationsApiListNotificationsNotificationsGetRequest {
  /**
   *
   * @type {number}
   * @memberof NotificationsApiListNotificationsNotificationsGet
   */
  readonly max?: number;

  /**
   *
   * @type {string}
   * @memberof NotificationsApiListNotificationsNotificationsGet
   */
  readonly startTimestamp?: string;

  /**
   *
   * @type {string}
   * @memberof NotificationsApiListNotificationsNotificationsGet
   */
  readonly endTimestamp?: string;
}

/**
 * Request parameters for updateNotificationStatusNotificationsNotificationUidPatch operation in NotificationsApi.
 * @export
 * @interface NotificationsApiUpdateNotificationStatusNotificationsNotificationUidPatchRequest
 */
export interface NotificationsApiUpdateNotificationStatusNotificationsNotificationUidPatchRequest {
  /**
   *
   * @type {number}
   * @memberof NotificationsApiUpdateNotificationStatusNotificationsNotificationUidPatch
   */
  readonly notificationUid: number;

  /**
   *
   * @type {UpdateNotificationPayload}
   * @memberof NotificationsApiUpdateNotificationStatusNotificationsNotificationUidPatch
   */
  readonly updateNotificationPayload: UpdateNotificationPayload;
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
  /**
   * Delete a notification for a user given the notification uid.
   * @summary Delete Notification
   * @param {NotificationsApiDeleteNotificationNotificationsNotificationUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public deleteNotificationNotificationsNotificationUidDelete(
    requestParameters: NotificationsApiDeleteNotificationNotificationsNotificationUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NotificationsApiFp(this.configuration)
      .deleteNotificationNotificationsNotificationUidDelete(
        requestParameters.notificationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete all notifications for a user.
   * @summary Delete Notifications
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public deleteNotificationsNotificationsDelete(
    options?: RawAxiosRequestConfig,
  ) {
    return NotificationsApiFp(this.configuration)
      .deleteNotificationsNotificationsDelete(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch the notification for a user given the notification uid.
   * @summary Get Notification
   * @param {NotificationsApiGetNotificationNotificationsNotificationUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public getNotificationNotificationsNotificationUidGet(
    requestParameters: NotificationsApiGetNotificationNotificationsNotificationUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NotificationsApiFp(this.configuration)
      .getNotificationNotificationsNotificationUidGet(
        requestParameters.notificationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch the notifications for a user.
   * @summary List Notifications
   * @param {NotificationsApiListNotificationsNotificationsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public listNotificationsNotificationsGet(
    requestParameters: NotificationsApiListNotificationsNotificationsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return NotificationsApiFp(this.configuration)
      .listNotificationsNotificationsGet(
        requestParameters.max,
        requestParameters.startTimestamp,
        requestParameters.endTimestamp,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Update the status of a notification given the notification uid.
   * @summary Update Notification Status
   * @param {NotificationsApiUpdateNotificationStatusNotificationsNotificationUidPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public updateNotificationStatusNotificationsNotificationUidPatch(
    requestParameters: NotificationsApiUpdateNotificationStatusNotificationsNotificationUidPatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return NotificationsApiFp(this.configuration)
      .updateNotificationStatusNotificationsNotificationUidPatch(
        requestParameters.notificationUid,
        requestParameters.updateNotificationPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ObjectStorageApi - axios parameter creator
 * @export
 */
export const ObjectStorageApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Download Remote Object
     * @param {DownloadRemoteObjectParams} downloadRemoteObjectParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadRemoteObjectDownloadRemoteObjectPost: async (
      downloadRemoteObjectParams: DownloadRemoteObjectParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'downloadRemoteObjectParams' is not null or undefined
      assertParamExists(
        'downloadRemoteObjectDownloadRemoteObjectPost',
        'downloadRemoteObjectParams',
        downloadRemoteObjectParams,
      );
      const localVarPath = `/download-remote-object`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        downloadRemoteObjectParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Object Storage Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getObjectStorageUrlObjectStorageGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/object-storage`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ObjectStorageApi - functional programming interface
 * @export
 */
export const ObjectStorageApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ObjectStorageApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Download Remote Object
     * @param {DownloadRemoteObjectParams} downloadRemoteObjectParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadRemoteObjectDownloadRemoteObjectPost(
      downloadRemoteObjectParams: DownloadRemoteObjectParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.downloadRemoteObjectDownloadRemoteObjectPost(
          downloadRemoteObjectParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ObjectStorageApi.downloadRemoteObjectDownloadRemoteObjectPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Object Storage Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getObjectStorageUrlObjectStorageGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ObjectStorageResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getObjectStorageUrlObjectStorageGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ObjectStorageApi.getObjectStorageUrlObjectStorageGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ObjectStorageApi - factory interface
 * @export
 */
export const ObjectStorageApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ObjectStorageApiFp(configuration);
  return {
    /**
     *
     * @summary Download Remote Object
     * @param {ObjectStorageApiDownloadRemoteObjectDownloadRemoteObjectPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadRemoteObjectDownloadRemoteObjectPost(
      requestParameters: ObjectStorageApiDownloadRemoteObjectDownloadRemoteObjectPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .downloadRemoteObjectDownloadRemoteObjectPost(
          requestParameters.downloadRemoteObjectParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Object Storage Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getObjectStorageUrlObjectStorageGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ObjectStorageResponse> {
      return localVarFp
        .getObjectStorageUrlObjectStorageGet(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for downloadRemoteObjectDownloadRemoteObjectPost operation in ObjectStorageApi.
 * @export
 * @interface ObjectStorageApiDownloadRemoteObjectDownloadRemoteObjectPostRequest
 */
export interface ObjectStorageApiDownloadRemoteObjectDownloadRemoteObjectPostRequest {
  /**
   *
   * @type {DownloadRemoteObjectParams}
   * @memberof ObjectStorageApiDownloadRemoteObjectDownloadRemoteObjectPost
   */
  readonly downloadRemoteObjectParams: DownloadRemoteObjectParams;
}

/**
 * ObjectStorageApi - object-oriented interface
 * @export
 * @class ObjectStorageApi
 * @extends {BaseAPI}
 */
export class ObjectStorageApi extends BaseAPI {
  /**
   *
   * @summary Download Remote Object
   * @param {ObjectStorageApiDownloadRemoteObjectDownloadRemoteObjectPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ObjectStorageApi
   */
  public downloadRemoteObjectDownloadRemoteObjectPost(
    requestParameters: ObjectStorageApiDownloadRemoteObjectDownloadRemoteObjectPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ObjectStorageApiFp(this.configuration)
      .downloadRemoteObjectDownloadRemoteObjectPost(
        requestParameters.downloadRemoteObjectParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Object Storage Url
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ObjectStorageApi
   */
  public getObjectStorageUrlObjectStorageGet(options?: RawAxiosRequestConfig) {
    return ObjectStorageApiFp(this.configuration)
      .getObjectStorageUrlObjectStorageGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * OidcProviderApi - axios parameter creator
 * @export
 */
export const OidcProviderApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorizationOidcopAuthorizationGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oidcop/authorization`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Jwks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jwksOidcopJwksGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oidcop/jwks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationOidcopRegistrationPost: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oidcop/registration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenOidcopTokenPost: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oidcop/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Userinfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userinfoOidcopUserinfoGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oidcop/userinfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OidcProviderApi - functional programming interface
 * @export
 */
export const OidcProviderApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OidcProviderApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authorizationOidcopAuthorizationGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authorizationOidcopAuthorizationGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OidcProviderApi.authorizationOidcopAuthorizationGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Jwks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jwksOidcopJwksGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.jwksOidcopJwksGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['OidcProviderApi.jwksOidcopJwksGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registrationOidcopRegistrationPost(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.registrationOidcopRegistrationPost(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OidcProviderApi.registrationOidcopRegistrationPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenOidcopTokenPost(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tokenOidcopTokenPost(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['OidcProviderApi.tokenOidcopTokenPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Userinfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userinfoOidcopUserinfoGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userinfoOidcopUserinfoGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['OidcProviderApi.userinfoOidcopUserinfoGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OidcProviderApi - factory interface
 * @export
 */
export const OidcProviderApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OidcProviderApiFp(configuration);
  return {
    /**
     *
     * @summary Authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorizationOidcopAuthorizationGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .authorizationOidcopAuthorizationGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Jwks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jwksOidcopJwksGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
      return localVarFp
        .jwksOidcopJwksGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationOidcopRegistrationPost(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .registrationOidcopRegistrationPost(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenOidcopTokenPost(options?: RawAxiosRequestConfig): AxiosPromise<any> {
      return localVarFp
        .tokenOidcopTokenPost(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Userinfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userinfoOidcopUserinfoGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .userinfoOidcopUserinfoGet(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * OidcProviderApi - object-oriented interface
 * @export
 * @class OidcProviderApi
 * @extends {BaseAPI}
 */
export class OidcProviderApi extends BaseAPI {
  /**
   *
   * @summary Authorization
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OidcProviderApi
   */
  public authorizationOidcopAuthorizationGet(options?: RawAxiosRequestConfig) {
    return OidcProviderApiFp(this.configuration)
      .authorizationOidcopAuthorizationGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Jwks
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OidcProviderApi
   */
  public jwksOidcopJwksGet(options?: RawAxiosRequestConfig) {
    return OidcProviderApiFp(this.configuration)
      .jwksOidcopJwksGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Registration
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OidcProviderApi
   */
  public registrationOidcopRegistrationPost(options?: RawAxiosRequestConfig) {
    return OidcProviderApiFp(this.configuration)
      .registrationOidcopRegistrationPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OidcProviderApi
   */
  public tokenOidcopTokenPost(options?: RawAxiosRequestConfig) {
    return OidcProviderApiFp(this.configuration)
      .tokenOidcopTokenPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Userinfo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OidcProviderApi
   */
  public userinfoOidcopUserinfoGet(options?: RawAxiosRequestConfig) {
    return OidcProviderApiFp(this.configuration)
      .userinfoOidcopUserinfoGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * OnboardingApi - axios parameter creator
 * @export
 */
export const OnboardingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Onboard Data Step
     * @param {CreateOnboardingDataStepParams} createOnboardingDataStepParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnboardDataStepOnboardingDataStepGet: async (
      createOnboardingDataStepParams: CreateOnboardingDataStepParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createOnboardingDataStepParams' is not null or undefined
      assertParamExists(
        'getOnboardDataStepOnboardingDataStepGet',
        'createOnboardingDataStepParams',
        createOnboardingDataStepParams,
      );
      const localVarPath = `/onboarding/data-step`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOnboardingDataStepParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Onboard Data Step
     * @param {CreateOnboardingDataStepParams} createOnboardingDataStepParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onboardDataStepOnboardingDataStepPost: async (
      createOnboardingDataStepParams: CreateOnboardingDataStepParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createOnboardingDataStepParams' is not null or undefined
      assertParamExists(
        'onboardDataStepOnboardingDataStepPost',
        'createOnboardingDataStepParams',
        createOnboardingDataStepParams,
      );
      const localVarPath = `/onboarding/data-step`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOnboardingDataStepParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OnboardingApi - functional programming interface
 * @export
 */
export const OnboardingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OnboardingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Onboard Data Step
     * @param {CreateOnboardingDataStepParams} createOnboardingDataStepParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOnboardDataStepOnboardingDataStepGet(
      createOnboardingDataStepParams: CreateOnboardingDataStepParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOnboardDataStepOnboardingDataStepGet(
          createOnboardingDataStepParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OnboardingApi.getOnboardDataStepOnboardingDataStepGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Onboard Data Step
     * @param {CreateOnboardingDataStepParams} createOnboardingDataStepParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async onboardDataStepOnboardingDataStepPost(
      createOnboardingDataStepParams: CreateOnboardingDataStepParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.onboardDataStepOnboardingDataStepPost(
          createOnboardingDataStepParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OnboardingApi.onboardDataStepOnboardingDataStepPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OnboardingApi - factory interface
 * @export
 */
export const OnboardingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OnboardingApiFp(configuration);
  return {
    /**
     *
     * @summary Get Onboard Data Step
     * @param {OnboardingApiGetOnboardDataStepOnboardingDataStepGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnboardDataStepOnboardingDataStepGet(
      requestParameters: OnboardingApiGetOnboardDataStepOnboardingDataStepGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .getOnboardDataStepOnboardingDataStepGet(
          requestParameters.createOnboardingDataStepParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Onboard Data Step
     * @param {OnboardingApiOnboardDataStepOnboardingDataStepPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onboardDataStepOnboardingDataStepPost(
      requestParameters: OnboardingApiOnboardDataStepOnboardingDataStepPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .onboardDataStepOnboardingDataStepPost(
          requestParameters.createOnboardingDataStepParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getOnboardDataStepOnboardingDataStepGet operation in OnboardingApi.
 * @export
 * @interface OnboardingApiGetOnboardDataStepOnboardingDataStepGetRequest
 */
export interface OnboardingApiGetOnboardDataStepOnboardingDataStepGetRequest {
  /**
   *
   * @type {CreateOnboardingDataStepParams}
   * @memberof OnboardingApiGetOnboardDataStepOnboardingDataStepGet
   */
  readonly createOnboardingDataStepParams: CreateOnboardingDataStepParams;
}

/**
 * Request parameters for onboardDataStepOnboardingDataStepPost operation in OnboardingApi.
 * @export
 * @interface OnboardingApiOnboardDataStepOnboardingDataStepPostRequest
 */
export interface OnboardingApiOnboardDataStepOnboardingDataStepPostRequest {
  /**
   *
   * @type {CreateOnboardingDataStepParams}
   * @memberof OnboardingApiOnboardDataStepOnboardingDataStepPost
   */
  readonly createOnboardingDataStepParams: CreateOnboardingDataStepParams;
}

/**
 * OnboardingApi - object-oriented interface
 * @export
 * @class OnboardingApi
 * @extends {BaseAPI}
 */
export class OnboardingApi extends BaseAPI {
  /**
   *
   * @summary Get Onboard Data Step
   * @param {OnboardingApiGetOnboardDataStepOnboardingDataStepGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnboardingApi
   */
  public getOnboardDataStepOnboardingDataStepGet(
    requestParameters: OnboardingApiGetOnboardDataStepOnboardingDataStepGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OnboardingApiFp(this.configuration)
      .getOnboardDataStepOnboardingDataStepGet(
        requestParameters.createOnboardingDataStepParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Onboard Data Step
   * @param {OnboardingApiOnboardDataStepOnboardingDataStepPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnboardingApi
   */
  public onboardDataStepOnboardingDataStepPost(
    requestParameters: OnboardingApiOnboardDataStepOnboardingDataStepPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OnboardingApiFp(this.configuration)
      .onboardDataStepOnboardingDataStepPost(
        requestParameters.createOnboardingDataStepParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * OpVersionsApi - axios parameter creator
 * @export
 */
export const OpVersionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Create a new Operator Version and add prediction of the operator for given node
     * @summary Create Op Version Add Op Predictions Endpoint
     * @param {number} nodeUid
     * @param {CreateOpVersionPayload} createOpVersionPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost:
      async (
        nodeUid: number,
        createOpVersionPayload: CreateOpVersionPayload,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'createOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'createOpVersionPayload' is not null or undefined
        assertParamExists(
          'createOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost',
          'createOpVersionPayload',
          createOpVersionPayload,
        );
        const localVarPath = `/nodes/{node_uid}/op-versions-pred`.replace(
          `{${'node_uid'}}`,
          encodeURIComponent(String(nodeUid)),
        );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          createOpVersionPayload,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Create a new Operator Version for given node node.
     * @summary Create Op Version Endpoint
     * @param {number} nodeUid
     * @param {CreateOpVersionPayload} createOpVersionPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOpVersionEndpointNodesNodeUidOpVersionsPost: async (
      nodeUid: number,
      createOpVersionPayload: CreateOpVersionPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'createOpVersionEndpointNodesNodeUidOpVersionsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'createOpVersionPayload' is not null or undefined
      assertParamExists(
        'createOpVersionEndpointNodesNodeUidOpVersionsPost',
        'createOpVersionPayload',
        createOpVersionPayload,
      );
      const localVarPath = `/nodes/{node_uid}/op-versions`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOpVersionPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Op Version
     * @param {number} nodeUid
     * @param {number} opVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete: async (
      nodeUid: number,
      opVersionUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'deleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'opVersionUid' is not null or undefined
      assertParamExists(
        'deleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete',
        'opVersionUid',
        opVersionUid,
      );
      const localVarPath = `/nodes/{node_uid}/op-versions/{op_version_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'op_version_uid'}}`,
          encodeURIComponent(String(opVersionUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Op Predictions
     * @param {number} nodeUid
     * @param {number} opVersionUid
     * @param {string} [split]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet: async (
      nodeUid: number,
      opVersionUid: number,
      split?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'opVersionUid' is not null or undefined
      assertParamExists(
        'fetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet',
        'opVersionUid',
        opVersionUid,
      );
      const localVarPath = `/nodes/{node_uid}/predictions/{op_version_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'op_version_uid'}}`,
          encodeURIComponent(String(opVersionUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Op Version
     * @param {number} nodeUid
     * @param {number} opVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet: async (
      nodeUid: number,
      opVersionUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'opVersionUid' is not null or undefined
      assertParamExists(
        'fetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet',
        'opVersionUid',
        opVersionUid,
      );
      const localVarPath = `/nodes/{node_uid}/op-versions/{op_version_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'op_version_uid'}}`,
          encodeURIComponent(String(opVersionUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Op Versions
     * @param {number} nodeUid
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOpVersionsNodesNodeUidOpVersionsGet: async (
      nodeUid: number,
      limit?: number,
      offset?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listOpVersionsNodesNodeUidOpVersionsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/op-versions`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OpVersionsApi - functional programming interface
 * @export
 */
export const OpVersionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OpVersionsApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a new Operator Version and add prediction of the operator for given node
     * @summary Create Op Version Add Op Predictions Endpoint
     * @param {number} nodeUid
     * @param {CreateOpVersionPayload} createOpVersionPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost(
      nodeUid: number,
      createOpVersionPayload: CreateOpVersionPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateOpVersionAddOpPredictionsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost(
          nodeUid,
          createOpVersionPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OpVersionsApi.createOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Create a new Operator Version for given node node.
     * @summary Create Op Version Endpoint
     * @param {number} nodeUid
     * @param {CreateOpVersionPayload} createOpVersionPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOpVersionEndpointNodesNodeUidOpVersionsPost(
      nodeUid: number,
      createOpVersionPayload: CreateOpVersionPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateOpVersionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createOpVersionEndpointNodesNodeUidOpVersionsPost(
          nodeUid,
          createOpVersionPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OpVersionsApi.createOpVersionEndpointNodesNodeUidOpVersionsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Op Version
     * @param {number} nodeUid
     * @param {number} opVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete(
      nodeUid: number,
      opVersionUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete(
          nodeUid,
          opVersionUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OpVersionsApi.deleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Op Predictions
     * @param {number} nodeUid
     * @param {number} opVersionUid
     * @param {string} [split]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet(
      nodeUid: number,
      opVersionUid: number,
      split?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OpVersionPredictionsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet(
          nodeUid,
          opVersionUid,
          split,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OpVersionsApi.fetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Op Version
     * @param {number} nodeUid
     * @param {number} opVersionUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet(
      nodeUid: number,
      opVersionUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpVersion>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet(
          nodeUid,
          opVersionUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OpVersionsApi.fetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Op Versions
     * @param {number} nodeUid
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listOpVersionsNodesNodeUidOpVersionsGet(
      nodeUid: number,
      limit?: number,
      offset?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listOpVersionsNodesNodeUidOpVersionsGet(
          nodeUid,
          limit,
          offset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OpVersionsApi.listOpVersionsNodesNodeUidOpVersionsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OpVersionsApi - factory interface
 * @export
 */
export const OpVersionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OpVersionsApiFp(configuration);
  return {
    /**
     * Create a new Operator Version and add prediction of the operator for given node
     * @summary Create Op Version Add Op Predictions Endpoint
     * @param {OpVersionsApiCreateOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost(
      requestParameters: OpVersionsApiCreateOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateOpVersionAddOpPredictionsResponse> {
      return localVarFp
        .createOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost(
          requestParameters.nodeUid,
          requestParameters.createOpVersionPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Create a new Operator Version for given node node.
     * @summary Create Op Version Endpoint
     * @param {OpVersionsApiCreateOpVersionEndpointNodesNodeUidOpVersionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOpVersionEndpointNodesNodeUidOpVersionsPost(
      requestParameters: OpVersionsApiCreateOpVersionEndpointNodesNodeUidOpVersionsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateOpVersionResponse> {
      return localVarFp
        .createOpVersionEndpointNodesNodeUidOpVersionsPost(
          requestParameters.nodeUid,
          requestParameters.createOpVersionPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Op Version
     * @param {OpVersionsApiDeleteOpVersionNodesNodeUidOpVersionsOpVersionUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete(
      requestParameters: OpVersionsApiDeleteOpVersionNodesNodeUidOpVersionsOpVersionUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete(
          requestParameters.nodeUid,
          requestParameters.opVersionUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Op Predictions
     * @param {OpVersionsApiFetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet(
      requestParameters: OpVersionsApiFetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<OpVersionPredictionsResponse> {
      return localVarFp
        .fetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet(
          requestParameters.nodeUid,
          requestParameters.opVersionUid,
          requestParameters.split,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Op Version
     * @param {OpVersionsApiFetchOpVersionNodesNodeUidOpVersionsOpVersionUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet(
      requestParameters: OpVersionsApiFetchOpVersionNodesNodeUidOpVersionsOpVersionUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<OpVersion> {
      return localVarFp
        .fetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet(
          requestParameters.nodeUid,
          requestParameters.opVersionUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Op Versions
     * @param {OpVersionsApiListOpVersionsNodesNodeUidOpVersionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listOpVersionsNodesNodeUidOpVersionsGet(
      requestParameters: OpVersionsApiListOpVersionsNodesNodeUidOpVersionsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<number>> {
      return localVarFp
        .listOpVersionsNodesNodeUidOpVersionsGet(
          requestParameters.nodeUid,
          requestParameters.limit,
          requestParameters.offset,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost operation in OpVersionsApi.
 * @export
 * @interface OpVersionsApiCreateOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPostRequest
 */
export interface OpVersionsApiCreateOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPostRequest {
  /**
   *
   * @type {number}
   * @memberof OpVersionsApiCreateOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {CreateOpVersionPayload}
   * @memberof OpVersionsApiCreateOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost
   */
  readonly createOpVersionPayload: CreateOpVersionPayload;
}

/**
 * Request parameters for createOpVersionEndpointNodesNodeUidOpVersionsPost operation in OpVersionsApi.
 * @export
 * @interface OpVersionsApiCreateOpVersionEndpointNodesNodeUidOpVersionsPostRequest
 */
export interface OpVersionsApiCreateOpVersionEndpointNodesNodeUidOpVersionsPostRequest {
  /**
   *
   * @type {number}
   * @memberof OpVersionsApiCreateOpVersionEndpointNodesNodeUidOpVersionsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {CreateOpVersionPayload}
   * @memberof OpVersionsApiCreateOpVersionEndpointNodesNodeUidOpVersionsPost
   */
  readonly createOpVersionPayload: CreateOpVersionPayload;
}

/**
 * Request parameters for deleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete operation in OpVersionsApi.
 * @export
 * @interface OpVersionsApiDeleteOpVersionNodesNodeUidOpVersionsOpVersionUidDeleteRequest
 */
export interface OpVersionsApiDeleteOpVersionNodesNodeUidOpVersionsOpVersionUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof OpVersionsApiDeleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof OpVersionsApiDeleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete
   */
  readonly opVersionUid: number;
}

/**
 * Request parameters for fetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet operation in OpVersionsApi.
 * @export
 * @interface OpVersionsApiFetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGetRequest
 */
export interface OpVersionsApiFetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof OpVersionsApiFetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof OpVersionsApiFetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet
   */
  readonly opVersionUid: number;

  /**
   *
   * @type {string}
   * @memberof OpVersionsApiFetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet
   */
  readonly split?: string;
}

/**
 * Request parameters for fetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet operation in OpVersionsApi.
 * @export
 * @interface OpVersionsApiFetchOpVersionNodesNodeUidOpVersionsOpVersionUidGetRequest
 */
export interface OpVersionsApiFetchOpVersionNodesNodeUidOpVersionsOpVersionUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof OpVersionsApiFetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof OpVersionsApiFetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet
   */
  readonly opVersionUid: number;
}

/**
 * Request parameters for listOpVersionsNodesNodeUidOpVersionsGet operation in OpVersionsApi.
 * @export
 * @interface OpVersionsApiListOpVersionsNodesNodeUidOpVersionsGetRequest
 */
export interface OpVersionsApiListOpVersionsNodesNodeUidOpVersionsGetRequest {
  /**
   *
   * @type {number}
   * @memberof OpVersionsApiListOpVersionsNodesNodeUidOpVersionsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof OpVersionsApiListOpVersionsNodesNodeUidOpVersionsGet
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof OpVersionsApiListOpVersionsNodesNodeUidOpVersionsGet
   */
  readonly offset?: number;
}

/**
 * OpVersionsApi - object-oriented interface
 * @export
 * @class OpVersionsApi
 * @extends {BaseAPI}
 */
export class OpVersionsApi extends BaseAPI {
  /**
   * Create a new Operator Version and add prediction of the operator for given node
   * @summary Create Op Version Add Op Predictions Endpoint
   * @param {OpVersionsApiCreateOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpVersionsApi
   */
  public createOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost(
    requestParameters: OpVersionsApiCreateOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OpVersionsApiFp(this.configuration)
      .createOpVersionAddOpPredictionsEndpointNodesNodeUidOpVersionsPredPost(
        requestParameters.nodeUid,
        requestParameters.createOpVersionPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Create a new Operator Version for given node node.
   * @summary Create Op Version Endpoint
   * @param {OpVersionsApiCreateOpVersionEndpointNodesNodeUidOpVersionsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpVersionsApi
   */
  public createOpVersionEndpointNodesNodeUidOpVersionsPost(
    requestParameters: OpVersionsApiCreateOpVersionEndpointNodesNodeUidOpVersionsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OpVersionsApiFp(this.configuration)
      .createOpVersionEndpointNodesNodeUidOpVersionsPost(
        requestParameters.nodeUid,
        requestParameters.createOpVersionPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Op Version
   * @param {OpVersionsApiDeleteOpVersionNodesNodeUidOpVersionsOpVersionUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpVersionsApi
   */
  public deleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete(
    requestParameters: OpVersionsApiDeleteOpVersionNodesNodeUidOpVersionsOpVersionUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OpVersionsApiFp(this.configuration)
      .deleteOpVersionNodesNodeUidOpVersionsOpVersionUidDelete(
        requestParameters.nodeUid,
        requestParameters.opVersionUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Op Predictions
   * @param {OpVersionsApiFetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpVersionsApi
   */
  public fetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet(
    requestParameters: OpVersionsApiFetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OpVersionsApiFp(this.configuration)
      .fetchOpPredictionsNodesNodeUidPredictionsOpVersionUidGet(
        requestParameters.nodeUid,
        requestParameters.opVersionUid,
        requestParameters.split,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Op Version
   * @param {OpVersionsApiFetchOpVersionNodesNodeUidOpVersionsOpVersionUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpVersionsApi
   */
  public fetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet(
    requestParameters: OpVersionsApiFetchOpVersionNodesNodeUidOpVersionsOpVersionUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OpVersionsApiFp(this.configuration)
      .fetchOpVersionNodesNodeUidOpVersionsOpVersionUidGet(
        requestParameters.nodeUid,
        requestParameters.opVersionUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Op Versions
   * @param {OpVersionsApiListOpVersionsNodesNodeUidOpVersionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpVersionsApi
   */
  public listOpVersionsNodesNodeUidOpVersionsGet(
    requestParameters: OpVersionsApiListOpVersionsNodesNodeUidOpVersionsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OpVersionsApiFp(this.configuration)
      .listOpVersionsNodesNodeUidOpVersionsGet(
        requestParameters.nodeUid,
        requestParameters.limit,
        requestParameters.offset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * OperatorsApi - axios parameter creator
 * @export
 */
export const OperatorsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Adds a new custom trainable operator and returns its UID
     * @summary Add Operator Class
     * @param {OperatorClassPayload} operatorClassPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOperatorClassOperatorClassesPost: async (
      operatorClassPayload: OperatorClassPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'operatorClassPayload' is not null or undefined
      assertParamExists(
        'addOperatorClassOperatorClassesPost',
        'operatorClassPayload',
        operatorClassPayload,
      );
      const localVarPath = `/operator_classes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        operatorClassPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Adds a new operator and returns its UID
     * @summary Add Operator
     * @param {OperatorPayload} operatorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOperatorOperatorsPost: async (
      operatorPayload: OperatorPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'operatorPayload' is not null or undefined
      assertParamExists(
        'addOperatorOperatorsPost',
        'operatorPayload',
        operatorPayload,
      );
      const localVarPath = `/operators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        operatorPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Operator
     * @param {number} opUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperatorOperatorsOpUidDelete: async (
      opUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'opUid' is not null or undefined
      assertParamExists('deleteOperatorOperatorsOpUidDelete', 'opUid', opUid);
      const localVarPath = `/operators/{op_uid}`.replace(
        `{${'op_uid'}}`,
        encodeURIComponent(String(opUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Dataset Operators
     * @param {number} datasetUid
     * @param {string} [name]
     * @param {string} [taskType]
     * @param {boolean} [includeCode]
     * @param {boolean} [featurizersOnly]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDatasetOperatorsDatasetsDatasetUidOperatorsGet: async (
      datasetUid: number,
      name?: string,
      taskType?: string,
      includeCode?: boolean,
      featurizersOnly?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'fetchDatasetOperatorsDatasetsDatasetUidOperatorsGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/datasets/{dataset_uid}/operators`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (taskType !== undefined) {
        localVarQueryParameter['task_type'] = taskType;
      }

      if (includeCode !== undefined) {
        localVarQueryParameter['include_code'] = includeCode;
      }

      if (featurizersOnly !== undefined) {
        localVarQueryParameter['featurizers_only'] = featurizersOnly;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Operator
     * @param {number} opUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOperatorOperatorsOpUidGet: async (
      opUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'opUid' is not null or undefined
      assertParamExists('fetchOperatorOperatorsOpUidGet', 'opUid', opUid);
      const localVarPath = `/operators/{op_uid}`.replace(
        `{${'op_uid'}}`,
        encodeURIComponent(String(opUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Workspace Operators
     * @param {number} workspaceUid
     * @param {string} [name]
     * @param {boolean} [includeCode]
     * @param {boolean} [featurizersOnly]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet: async (
      workspaceUid: number,
      name?: string,
      includeCode?: boolean,
      featurizersOnly?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'fetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/workspaces/{workspace_uid}/operators`.replace(
        `{${'workspace_uid'}}`,
        encodeURIComponent(String(workspaceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (includeCode !== undefined) {
        localVarQueryParameter['include_code'] = includeCode;
      }

      if (featurizersOnly !== undefined) {
        localVarQueryParameter['featurizers_only'] = featurizersOnly;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OperatorsApi - functional programming interface
 * @export
 */
export const OperatorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OperatorsApiAxiosParamCreator(configuration);
  return {
    /**
     * Adds a new custom trainable operator and returns its UID
     * @summary Add Operator Class
     * @param {OperatorClassPayload} operatorClassPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addOperatorClassOperatorClassesPost(
      operatorClassPayload: OperatorClassPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateOperatorResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addOperatorClassOperatorClassesPost(
          operatorClassPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OperatorsApi.addOperatorClassOperatorClassesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Adds a new operator and returns its UID
     * @summary Add Operator
     * @param {OperatorPayload} operatorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addOperatorOperatorsPost(
      operatorPayload: OperatorPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateOperatorResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addOperatorOperatorsPost(
          operatorPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['OperatorsApi.addOperatorOperatorsPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Operator
     * @param {number} opUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOperatorOperatorsOpUidDelete(
      opUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOperatorOperatorsOpUidDelete(
          opUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['OperatorsApi.deleteOperatorOperatorsOpUidDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Dataset Operators
     * @param {number} datasetUid
     * @param {string} [name]
     * @param {string} [taskType]
     * @param {boolean} [includeCode]
     * @param {boolean} [featurizersOnly]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDatasetOperatorsDatasetsDatasetUidOperatorsGet(
      datasetUid: number,
      name?: string,
      taskType?: string,
      includeCode?: boolean,
      featurizersOnly?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetOperatorsResponseElement>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchDatasetOperatorsDatasetsDatasetUidOperatorsGet(
          datasetUid,
          name,
          taskType,
          includeCode,
          featurizersOnly,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OperatorsApi.fetchDatasetOperatorsDatasetsDatasetUidOperatorsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Operator
     * @param {number} opUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchOperatorOperatorsOpUidGet(
      opUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetOperatorResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchOperatorOperatorsOpUidGet(
          opUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['OperatorsApi.fetchOperatorOperatorsOpUidGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Workspace Operators
     * @param {number} workspaceUid
     * @param {string} [name]
     * @param {boolean} [includeCode]
     * @param {boolean} [featurizersOnly]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet(
      workspaceUid: number,
      name?: string,
      includeCode?: boolean,
      featurizersOnly?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetOperatorsResponseElement>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet(
          workspaceUid,
          name,
          includeCode,
          featurizersOnly,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'OperatorsApi.fetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OperatorsApi - factory interface
 * @export
 */
export const OperatorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OperatorsApiFp(configuration);
  return {
    /**
     * Adds a new custom trainable operator and returns its UID
     * @summary Add Operator Class
     * @param {OperatorsApiAddOperatorClassOperatorClassesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOperatorClassOperatorClassesPost(
      requestParameters: OperatorsApiAddOperatorClassOperatorClassesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateOperatorResponse> {
      return localVarFp
        .addOperatorClassOperatorClassesPost(
          requestParameters.operatorClassPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Adds a new operator and returns its UID
     * @summary Add Operator
     * @param {OperatorsApiAddOperatorOperatorsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOperatorOperatorsPost(
      requestParameters: OperatorsApiAddOperatorOperatorsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateOperatorResponse> {
      return localVarFp
        .addOperatorOperatorsPost(requestParameters.operatorPayload, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Operator
     * @param {OperatorsApiDeleteOperatorOperatorsOpUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperatorOperatorsOpUidDelete(
      requestParameters: OperatorsApiDeleteOperatorOperatorsOpUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteOperatorOperatorsOpUidDelete(requestParameters.opUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Dataset Operators
     * @param {OperatorsApiFetchDatasetOperatorsDatasetsDatasetUidOperatorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDatasetOperatorsDatasetsDatasetUidOperatorsGet(
      requestParameters: OperatorsApiFetchDatasetOperatorsDatasetsDatasetUidOperatorsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<GetOperatorsResponseElement>> {
      return localVarFp
        .fetchDatasetOperatorsDatasetsDatasetUidOperatorsGet(
          requestParameters.datasetUid,
          requestParameters.name,
          requestParameters.taskType,
          requestParameters.includeCode,
          requestParameters.featurizersOnly,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Operator
     * @param {OperatorsApiFetchOperatorOperatorsOpUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchOperatorOperatorsOpUidGet(
      requestParameters: OperatorsApiFetchOperatorOperatorsOpUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetOperatorResponse> {
      return localVarFp
        .fetchOperatorOperatorsOpUidGet(requestParameters.opUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Workspace Operators
     * @param {OperatorsApiFetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet(
      requestParameters: OperatorsApiFetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<GetOperatorsResponseElement>> {
      return localVarFp
        .fetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet(
          requestParameters.workspaceUid,
          requestParameters.name,
          requestParameters.includeCode,
          requestParameters.featurizersOnly,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addOperatorClassOperatorClassesPost operation in OperatorsApi.
 * @export
 * @interface OperatorsApiAddOperatorClassOperatorClassesPostRequest
 */
export interface OperatorsApiAddOperatorClassOperatorClassesPostRequest {
  /**
   *
   * @type {OperatorClassPayload}
   * @memberof OperatorsApiAddOperatorClassOperatorClassesPost
   */
  readonly operatorClassPayload: OperatorClassPayload;
}

/**
 * Request parameters for addOperatorOperatorsPost operation in OperatorsApi.
 * @export
 * @interface OperatorsApiAddOperatorOperatorsPostRequest
 */
export interface OperatorsApiAddOperatorOperatorsPostRequest {
  /**
   *
   * @type {OperatorPayload}
   * @memberof OperatorsApiAddOperatorOperatorsPost
   */
  readonly operatorPayload: OperatorPayload;
}

/**
 * Request parameters for deleteOperatorOperatorsOpUidDelete operation in OperatorsApi.
 * @export
 * @interface OperatorsApiDeleteOperatorOperatorsOpUidDeleteRequest
 */
export interface OperatorsApiDeleteOperatorOperatorsOpUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof OperatorsApiDeleteOperatorOperatorsOpUidDelete
   */
  readonly opUid: number;
}

/**
 * Request parameters for fetchDatasetOperatorsDatasetsDatasetUidOperatorsGet operation in OperatorsApi.
 * @export
 * @interface OperatorsApiFetchDatasetOperatorsDatasetsDatasetUidOperatorsGetRequest
 */
export interface OperatorsApiFetchDatasetOperatorsDatasetsDatasetUidOperatorsGetRequest {
  /**
   *
   * @type {number}
   * @memberof OperatorsApiFetchDatasetOperatorsDatasetsDatasetUidOperatorsGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {string}
   * @memberof OperatorsApiFetchDatasetOperatorsDatasetsDatasetUidOperatorsGet
   */
  readonly name?: string;

  /**
   *
   * @type {string}
   * @memberof OperatorsApiFetchDatasetOperatorsDatasetsDatasetUidOperatorsGet
   */
  readonly taskType?: string;

  /**
   *
   * @type {boolean}
   * @memberof OperatorsApiFetchDatasetOperatorsDatasetsDatasetUidOperatorsGet
   */
  readonly includeCode?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof OperatorsApiFetchDatasetOperatorsDatasetsDatasetUidOperatorsGet
   */
  readonly featurizersOnly?: boolean;
}

/**
 * Request parameters for fetchOperatorOperatorsOpUidGet operation in OperatorsApi.
 * @export
 * @interface OperatorsApiFetchOperatorOperatorsOpUidGetRequest
 */
export interface OperatorsApiFetchOperatorOperatorsOpUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof OperatorsApiFetchOperatorOperatorsOpUidGet
   */
  readonly opUid: number;
}

/**
 * Request parameters for fetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet operation in OperatorsApi.
 * @export
 * @interface OperatorsApiFetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGetRequest
 */
export interface OperatorsApiFetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGetRequest {
  /**
   *
   * @type {number}
   * @memberof OperatorsApiFetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet
   */
  readonly workspaceUid: number;

  /**
   *
   * @type {string}
   * @memberof OperatorsApiFetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet
   */
  readonly name?: string;

  /**
   *
   * @type {boolean}
   * @memberof OperatorsApiFetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet
   */
  readonly includeCode?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof OperatorsApiFetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet
   */
  readonly featurizersOnly?: boolean;
}

/**
 * OperatorsApi - object-oriented interface
 * @export
 * @class OperatorsApi
 * @extends {BaseAPI}
 */
export class OperatorsApi extends BaseAPI {
  /**
   * Adds a new custom trainable operator and returns its UID
   * @summary Add Operator Class
   * @param {OperatorsApiAddOperatorClassOperatorClassesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorsApi
   */
  public addOperatorClassOperatorClassesPost(
    requestParameters: OperatorsApiAddOperatorClassOperatorClassesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OperatorsApiFp(this.configuration)
      .addOperatorClassOperatorClassesPost(
        requestParameters.operatorClassPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Adds a new operator and returns its UID
   * @summary Add Operator
   * @param {OperatorsApiAddOperatorOperatorsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorsApi
   */
  public addOperatorOperatorsPost(
    requestParameters: OperatorsApiAddOperatorOperatorsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OperatorsApiFp(this.configuration)
      .addOperatorOperatorsPost(requestParameters.operatorPayload, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Operator
   * @param {OperatorsApiDeleteOperatorOperatorsOpUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorsApi
   */
  public deleteOperatorOperatorsOpUidDelete(
    requestParameters: OperatorsApiDeleteOperatorOperatorsOpUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OperatorsApiFp(this.configuration)
      .deleteOperatorOperatorsOpUidDelete(requestParameters.opUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Dataset Operators
   * @param {OperatorsApiFetchDatasetOperatorsDatasetsDatasetUidOperatorsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorsApi
   */
  public fetchDatasetOperatorsDatasetsDatasetUidOperatorsGet(
    requestParameters: OperatorsApiFetchDatasetOperatorsDatasetsDatasetUidOperatorsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OperatorsApiFp(this.configuration)
      .fetchDatasetOperatorsDatasetsDatasetUidOperatorsGet(
        requestParameters.datasetUid,
        requestParameters.name,
        requestParameters.taskType,
        requestParameters.includeCode,
        requestParameters.featurizersOnly,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Operator
   * @param {OperatorsApiFetchOperatorOperatorsOpUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorsApi
   */
  public fetchOperatorOperatorsOpUidGet(
    requestParameters: OperatorsApiFetchOperatorOperatorsOpUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OperatorsApiFp(this.configuration)
      .fetchOperatorOperatorsOpUidGet(requestParameters.opUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Workspace Operators
   * @param {OperatorsApiFetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorsApi
   */
  public fetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet(
    requestParameters: OperatorsApiFetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return OperatorsApiFp(this.configuration)
      .fetchWorkspaceOperatorsWorkspacesWorkspaceUidOperatorsGet(
        requestParameters.workspaceUid,
        requestParameters.name,
        requestParameters.includeCode,
        requestParameters.featurizersOnly,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PopulatorsApi - axios parameter creator
 * @export
 */
export const PopulatorsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Apply Populator
     * @param {ApplyPopulatorPayload} applyPopulatorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyPopulatorApplyPopulatorPost: async (
      applyPopulatorPayload: ApplyPopulatorPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applyPopulatorPayload' is not null or undefined
      assertParamExists(
        'applyPopulatorApplyPopulatorPost',
        'applyPopulatorPayload',
        applyPopulatorPayload,
      );
      const localVarPath = `/apply-populator`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applyPopulatorPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Populator
     * @param {CreatePopulatorPayload} createPopulatorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPopulatorPopulatorsPost: async (
      createPopulatorPayload: CreatePopulatorPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPopulatorPayload' is not null or undefined
      assertParamExists(
        'createPopulatorPopulatorsPost',
        'createPopulatorPayload',
        createPopulatorPayload,
      );
      const localVarPath = `/populators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPopulatorPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete populators.  If name is specified, delete all populators with that name. If path is specified, delete popualtor at that path. In either case, delete all populators whose creation_time + ttl_seconds < current_time
     * @summary Delete Populators
     * @param {DeletePopulatorPayload} deletePopulatorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePopulatorsPopulatorsDelete: async (
      deletePopulatorPayload: DeletePopulatorPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deletePopulatorPayload' is not null or undefined
      assertParamExists(
        'deletePopulatorsPopulatorsDelete',
        'deletePopulatorPayload',
        deletePopulatorPayload,
      );
      const localVarPath = `/populators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deletePopulatorPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch all populators in shared S3 bucket.
     * @summary Get Populators
     * @param {boolean} [ignoreCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopulatorsPopulatorsGet: async (
      ignoreCache?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/populators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (ignoreCache !== undefined) {
        localVarQueryParameter['ignore_cache'] = ignoreCache;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PopulatorsApi - functional programming interface
 * @export
 */
export const PopulatorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PopulatorsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Apply Populator
     * @param {ApplyPopulatorPayload} applyPopulatorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyPopulatorApplyPopulatorPost(
      applyPopulatorPayload: ApplyPopulatorPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplyPopulatorResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyPopulatorApplyPopulatorPost(
          applyPopulatorPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PopulatorsApi.applyPopulatorApplyPopulatorPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Create Populator
     * @param {CreatePopulatorPayload} createPopulatorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPopulatorPopulatorsPost(
      createPopulatorPayload: CreatePopulatorPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreatePopulatorResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createPopulatorPopulatorsPost(
          createPopulatorPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PopulatorsApi.createPopulatorPopulatorsPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete populators.  If name is specified, delete all populators with that name. If path is specified, delete popualtor at that path. In either case, delete all populators whose creation_time + ttl_seconds < current_time
     * @summary Delete Populators
     * @param {DeletePopulatorPayload} deletePopulatorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePopulatorsPopulatorsDelete(
      deletePopulatorPayload: DeletePopulatorPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deletePopulatorsPopulatorsDelete(
          deletePopulatorPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PopulatorsApi.deletePopulatorsPopulatorsDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch all populators in shared S3 bucket.
     * @summary Get Populators
     * @param {boolean} [ignoreCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPopulatorsPopulatorsGet(
      ignoreCache?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPopulatorsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPopulatorsPopulatorsGet(
          ignoreCache,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PopulatorsApi.getPopulatorsPopulatorsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PopulatorsApi - factory interface
 * @export
 */
export const PopulatorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PopulatorsApiFp(configuration);
  return {
    /**
     *
     * @summary Apply Populator
     * @param {PopulatorsApiApplyPopulatorApplyPopulatorPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyPopulatorApplyPopulatorPost(
      requestParameters: PopulatorsApiApplyPopulatorApplyPopulatorPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ApplyPopulatorResponse> {
      return localVarFp
        .applyPopulatorApplyPopulatorPost(
          requestParameters.applyPopulatorPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create Populator
     * @param {PopulatorsApiCreatePopulatorPopulatorsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPopulatorPopulatorsPost(
      requestParameters: PopulatorsApiCreatePopulatorPopulatorsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreatePopulatorResponse> {
      return localVarFp
        .createPopulatorPopulatorsPost(
          requestParameters.createPopulatorPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Delete populators.  If name is specified, delete all populators with that name. If path is specified, delete popualtor at that path. In either case, delete all populators whose creation_time + ttl_seconds < current_time
     * @summary Delete Populators
     * @param {PopulatorsApiDeletePopulatorsPopulatorsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePopulatorsPopulatorsDelete(
      requestParameters: PopulatorsApiDeletePopulatorsPopulatorsDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .deletePopulatorsPopulatorsDelete(
          requestParameters.deletePopulatorPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch all populators in shared S3 bucket.
     * @summary Get Populators
     * @param {PopulatorsApiGetPopulatorsPopulatorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPopulatorsPopulatorsGet(
      requestParameters: PopulatorsApiGetPopulatorsPopulatorsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetPopulatorsResponse> {
      return localVarFp
        .getPopulatorsPopulatorsGet(requestParameters.ignoreCache, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for applyPopulatorApplyPopulatorPost operation in PopulatorsApi.
 * @export
 * @interface PopulatorsApiApplyPopulatorApplyPopulatorPostRequest
 */
export interface PopulatorsApiApplyPopulatorApplyPopulatorPostRequest {
  /**
   *
   * @type {ApplyPopulatorPayload}
   * @memberof PopulatorsApiApplyPopulatorApplyPopulatorPost
   */
  readonly applyPopulatorPayload: ApplyPopulatorPayload;
}

/**
 * Request parameters for createPopulatorPopulatorsPost operation in PopulatorsApi.
 * @export
 * @interface PopulatorsApiCreatePopulatorPopulatorsPostRequest
 */
export interface PopulatorsApiCreatePopulatorPopulatorsPostRequest {
  /**
   *
   * @type {CreatePopulatorPayload}
   * @memberof PopulatorsApiCreatePopulatorPopulatorsPost
   */
  readonly createPopulatorPayload: CreatePopulatorPayload;
}

/**
 * Request parameters for deletePopulatorsPopulatorsDelete operation in PopulatorsApi.
 * @export
 * @interface PopulatorsApiDeletePopulatorsPopulatorsDeleteRequest
 */
export interface PopulatorsApiDeletePopulatorsPopulatorsDeleteRequest {
  /**
   *
   * @type {DeletePopulatorPayload}
   * @memberof PopulatorsApiDeletePopulatorsPopulatorsDelete
   */
  readonly deletePopulatorPayload: DeletePopulatorPayload;
}

/**
 * Request parameters for getPopulatorsPopulatorsGet operation in PopulatorsApi.
 * @export
 * @interface PopulatorsApiGetPopulatorsPopulatorsGetRequest
 */
export interface PopulatorsApiGetPopulatorsPopulatorsGetRequest {
  /**
   *
   * @type {boolean}
   * @memberof PopulatorsApiGetPopulatorsPopulatorsGet
   */
  readonly ignoreCache?: boolean;
}

/**
 * PopulatorsApi - object-oriented interface
 * @export
 * @class PopulatorsApi
 * @extends {BaseAPI}
 */
export class PopulatorsApi extends BaseAPI {
  /**
   *
   * @summary Apply Populator
   * @param {PopulatorsApiApplyPopulatorApplyPopulatorPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PopulatorsApi
   */
  public applyPopulatorApplyPopulatorPost(
    requestParameters: PopulatorsApiApplyPopulatorApplyPopulatorPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PopulatorsApiFp(this.configuration)
      .applyPopulatorApplyPopulatorPost(
        requestParameters.applyPopulatorPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Populator
   * @param {PopulatorsApiCreatePopulatorPopulatorsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PopulatorsApi
   */
  public createPopulatorPopulatorsPost(
    requestParameters: PopulatorsApiCreatePopulatorPopulatorsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PopulatorsApiFp(this.configuration)
      .createPopulatorPopulatorsPost(
        requestParameters.createPopulatorPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete populators.  If name is specified, delete all populators with that name. If path is specified, delete popualtor at that path. In either case, delete all populators whose creation_time + ttl_seconds < current_time
   * @summary Delete Populators
   * @param {PopulatorsApiDeletePopulatorsPopulatorsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PopulatorsApi
   */
  public deletePopulatorsPopulatorsDelete(
    requestParameters: PopulatorsApiDeletePopulatorsPopulatorsDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PopulatorsApiFp(this.configuration)
      .deletePopulatorsPopulatorsDelete(
        requestParameters.deletePopulatorPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch all populators in shared S3 bucket.
   * @summary Get Populators
   * @param {PopulatorsApiGetPopulatorsPopulatorsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PopulatorsApi
   */
  public getPopulatorsPopulatorsGet(
    requestParameters: PopulatorsApiGetPopulatorsPopulatorsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return PopulatorsApiFp(this.configuration)
      .getPopulatorsPopulatorsGet(requestParameters.ignoreCache, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PostprocessorsApi - axios parameter creator
 * @export
 */
export const PostprocessorsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Fetch Postprocessors
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchPostprocessorsNodesNodeUidPostprocessorsGet: async (
      nodeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchPostprocessorsNodesNodeUidPostprocessorsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/postprocessors`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Put Postprocessors
     * @param {number} nodeUid
     * @param {PutPostprocessorPayload} putPostprocessorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putPostprocessorsNodesNodeUidPostprocessorsPut: async (
      nodeUid: number,
      putPostprocessorPayload: PutPostprocessorPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'putPostprocessorsNodesNodeUidPostprocessorsPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'putPostprocessorPayload' is not null or undefined
      assertParamExists(
        'putPostprocessorsNodesNodeUidPostprocessorsPut',
        'putPostprocessorPayload',
        putPostprocessorPayload,
      );
      const localVarPath = `/nodes/{node_uid}/postprocessors`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putPostprocessorPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PostprocessorsApi - functional programming interface
 * @export
 */
export const PostprocessorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PostprocessorsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Fetch Postprocessors
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchPostprocessorsNodesNodeUidPostprocessorsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchPostprocessorsNodesNodeUidPostprocessorsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PostprocessorsApi.fetchPostprocessorsNodesNodeUidPostprocessorsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Put Postprocessors
     * @param {number} nodeUid
     * @param {PutPostprocessorPayload} putPostprocessorPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putPostprocessorsNodesNodeUidPostprocessorsPut(
      nodeUid: number,
      putPostprocessorPayload: PutPostprocessorPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putPostprocessorsNodesNodeUidPostprocessorsPut(
          nodeUid,
          putPostprocessorPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PostprocessorsApi.putPostprocessorsNodesNodeUidPostprocessorsPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PostprocessorsApi - factory interface
 * @export
 */
export const PostprocessorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PostprocessorsApiFp(configuration);
  return {
    /**
     *
     * @summary Fetch Postprocessors
     * @param {PostprocessorsApiFetchPostprocessorsNodesNodeUidPostprocessorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchPostprocessorsNodesNodeUidPostprocessorsGet(
      requestParameters: PostprocessorsApiFetchPostprocessorsNodesNodeUidPostprocessorsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchPostprocessorsNodesNodeUidPostprocessorsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Put Postprocessors
     * @param {PostprocessorsApiPutPostprocessorsNodesNodeUidPostprocessorsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putPostprocessorsNodesNodeUidPostprocessorsPut(
      requestParameters: PostprocessorsApiPutPostprocessorsNodesNodeUidPostprocessorsPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .putPostprocessorsNodesNodeUidPostprocessorsPut(
          requestParameters.nodeUid,
          requestParameters.putPostprocessorPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for fetchPostprocessorsNodesNodeUidPostprocessorsGet operation in PostprocessorsApi.
 * @export
 * @interface PostprocessorsApiFetchPostprocessorsNodesNodeUidPostprocessorsGetRequest
 */
export interface PostprocessorsApiFetchPostprocessorsNodesNodeUidPostprocessorsGetRequest {
  /**
   *
   * @type {number}
   * @memberof PostprocessorsApiFetchPostprocessorsNodesNodeUidPostprocessorsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for putPostprocessorsNodesNodeUidPostprocessorsPut operation in PostprocessorsApi.
 * @export
 * @interface PostprocessorsApiPutPostprocessorsNodesNodeUidPostprocessorsPutRequest
 */
export interface PostprocessorsApiPutPostprocessorsNodesNodeUidPostprocessorsPutRequest {
  /**
   *
   * @type {number}
   * @memberof PostprocessorsApiPutPostprocessorsNodesNodeUidPostprocessorsPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {PutPostprocessorPayload}
   * @memberof PostprocessorsApiPutPostprocessorsNodesNodeUidPostprocessorsPut
   */
  readonly putPostprocessorPayload: PutPostprocessorPayload;
}

/**
 * PostprocessorsApi - object-oriented interface
 * @export
 * @class PostprocessorsApi
 * @extends {BaseAPI}
 */
export class PostprocessorsApi extends BaseAPI {
  /**
   *
   * @summary Fetch Postprocessors
   * @param {PostprocessorsApiFetchPostprocessorsNodesNodeUidPostprocessorsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostprocessorsApi
   */
  public fetchPostprocessorsNodesNodeUidPostprocessorsGet(
    requestParameters: PostprocessorsApiFetchPostprocessorsNodesNodeUidPostprocessorsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PostprocessorsApiFp(this.configuration)
      .fetchPostprocessorsNodesNodeUidPostprocessorsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Put Postprocessors
   * @param {PostprocessorsApiPutPostprocessorsNodesNodeUidPostprocessorsPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PostprocessorsApi
   */
  public putPostprocessorsNodesNodeUidPostprocessorsPut(
    requestParameters: PostprocessorsApiPutPostprocessorsNodesNodeUidPostprocessorsPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PostprocessorsApiFp(this.configuration)
      .putPostprocessorsNodesNodeUidPostprocessorsPut(
        requestParameters.nodeUid,
        requestParameters.putPostprocessorPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PredictionApiApi - axios parameter creator
 * @export
 */
export const PredictionApiApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Manually create a dedicated inference service for the specified application deployment
     * @summary Create Dedicated Inference Service
     * @param {CreatePredictionAPIServiceParams} createPredictionAPIServiceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDedicatedInferenceServicePredictionApiPost: async (
      createPredictionAPIServiceParams: CreatePredictionAPIServiceParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPredictionAPIServiceParams' is not null or undefined
      assertParamExists(
        'createDedicatedInferenceServicePredictionApiPost',
        'createPredictionAPIServiceParams',
        createPredictionAPIServiceParams,
      );
      const localVarPath = `/prediction-api`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPredictionAPIServiceParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete the dedicated inference service (if one exists), cleaning up the deployment, service, and networking ingress.
     * @summary Delete Dedicated Inference Service
     * @param {number} deploymentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDedicatedInferenceServicePredictionApiDeploymentUidDelete: async (
      deploymentUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deploymentUid' is not null or undefined
      assertParamExists(
        'deleteDedicatedInferenceServicePredictionApiDeploymentUidDelete',
        'deploymentUid',
        deploymentUid,
      );
      const localVarPath = `/prediction-api/{deployment_uid}`.replace(
        `{${'deployment_uid'}}`,
        encodeURIComponent(String(deploymentUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Dedicated Inference Service
     * @param {number} deploymentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDedicatedInferenceServicePredictionApiDeploymentUidGet: async (
      deploymentUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deploymentUid' is not null or undefined
      assertParamExists(
        'getDedicatedInferenceServicePredictionApiDeploymentUidGet',
        'deploymentUid',
        deploymentUid,
      );
      const localVarPath = `/prediction-api/{deployment_uid}`.replace(
        `{${'deployment_uid'}}`,
        encodeURIComponent(String(deploymentUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Dedicated Inference Services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDedicatedInferenceServicesPredictionApiGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/prediction-api`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PredictionApiApi - functional programming interface
 * @export
 */
export const PredictionApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PredictionApiApiAxiosParamCreator(configuration);
  return {
    /**
     * Manually create a dedicated inference service for the specified application deployment
     * @summary Create Dedicated Inference Service
     * @param {CreatePredictionAPIServiceParams} createPredictionAPIServiceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDedicatedInferenceServicePredictionApiPost(
      createPredictionAPIServiceParams: CreatePredictionAPIServiceParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createDedicatedInferenceServicePredictionApiPost(
          createPredictionAPIServiceParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PredictionApiApi.createDedicatedInferenceServicePredictionApiPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete the dedicated inference service (if one exists), cleaning up the deployment, service, and networking ingress.
     * @summary Delete Dedicated Inference Service
     * @param {number} deploymentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDedicatedInferenceServicePredictionApiDeploymentUidDelete(
      deploymentUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteDedicatedInferenceServicePredictionApiDeploymentUidDelete(
          deploymentUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PredictionApiApi.deleteDedicatedInferenceServicePredictionApiDeploymentUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Dedicated Inference Service
     * @param {number} deploymentUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDedicatedInferenceServicePredictionApiDeploymentUidGet(
      deploymentUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictionAPI>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDedicatedInferenceServicePredictionApiDeploymentUidGet(
          deploymentUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PredictionApiApi.getDedicatedInferenceServicePredictionApiDeploymentUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Dedicated Inference Services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDedicatedInferenceServicesPredictionApiGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PredictionAPIListResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDedicatedInferenceServicesPredictionApiGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PredictionApiApi.getDedicatedInferenceServicesPredictionApiGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PredictionApiApi - factory interface
 * @export
 */
export const PredictionApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PredictionApiApiFp(configuration);
  return {
    /**
     * Manually create a dedicated inference service for the specified application deployment
     * @summary Create Dedicated Inference Service
     * @param {PredictionApiApiCreateDedicatedInferenceServicePredictionApiPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDedicatedInferenceServicePredictionApiPost(
      requestParameters: PredictionApiApiCreateDedicatedInferenceServicePredictionApiPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .createDedicatedInferenceServicePredictionApiPost(
          requestParameters.createPredictionAPIServiceParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Delete the dedicated inference service (if one exists), cleaning up the deployment, service, and networking ingress.
     * @summary Delete Dedicated Inference Service
     * @param {PredictionApiApiDeleteDedicatedInferenceServicePredictionApiDeploymentUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDedicatedInferenceServicePredictionApiDeploymentUidDelete(
      requestParameters: PredictionApiApiDeleteDedicatedInferenceServicePredictionApiDeploymentUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .deleteDedicatedInferenceServicePredictionApiDeploymentUidDelete(
          requestParameters.deploymentUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Dedicated Inference Service
     * @param {PredictionApiApiGetDedicatedInferenceServicePredictionApiDeploymentUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDedicatedInferenceServicePredictionApiDeploymentUidGet(
      requestParameters: PredictionApiApiGetDedicatedInferenceServicePredictionApiDeploymentUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PredictionAPI> {
      return localVarFp
        .getDedicatedInferenceServicePredictionApiDeploymentUidGet(
          requestParameters.deploymentUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Dedicated Inference Services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDedicatedInferenceServicesPredictionApiGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PredictionAPIListResponse> {
      return localVarFp
        .getDedicatedInferenceServicesPredictionApiGet(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createDedicatedInferenceServicePredictionApiPost operation in PredictionApiApi.
 * @export
 * @interface PredictionApiApiCreateDedicatedInferenceServicePredictionApiPostRequest
 */
export interface PredictionApiApiCreateDedicatedInferenceServicePredictionApiPostRequest {
  /**
   *
   * @type {CreatePredictionAPIServiceParams}
   * @memberof PredictionApiApiCreateDedicatedInferenceServicePredictionApiPost
   */
  readonly createPredictionAPIServiceParams: CreatePredictionAPIServiceParams;
}

/**
 * Request parameters for deleteDedicatedInferenceServicePredictionApiDeploymentUidDelete operation in PredictionApiApi.
 * @export
 * @interface PredictionApiApiDeleteDedicatedInferenceServicePredictionApiDeploymentUidDeleteRequest
 */
export interface PredictionApiApiDeleteDedicatedInferenceServicePredictionApiDeploymentUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof PredictionApiApiDeleteDedicatedInferenceServicePredictionApiDeploymentUidDelete
   */
  readonly deploymentUid: number;
}

/**
 * Request parameters for getDedicatedInferenceServicePredictionApiDeploymentUidGet operation in PredictionApiApi.
 * @export
 * @interface PredictionApiApiGetDedicatedInferenceServicePredictionApiDeploymentUidGetRequest
 */
export interface PredictionApiApiGetDedicatedInferenceServicePredictionApiDeploymentUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof PredictionApiApiGetDedicatedInferenceServicePredictionApiDeploymentUidGet
   */
  readonly deploymentUid: number;
}

/**
 * PredictionApiApi - object-oriented interface
 * @export
 * @class PredictionApiApi
 * @extends {BaseAPI}
 */
export class PredictionApiApi extends BaseAPI {
  /**
   * Manually create a dedicated inference service for the specified application deployment
   * @summary Create Dedicated Inference Service
   * @param {PredictionApiApiCreateDedicatedInferenceServicePredictionApiPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictionApiApi
   */
  public createDedicatedInferenceServicePredictionApiPost(
    requestParameters: PredictionApiApiCreateDedicatedInferenceServicePredictionApiPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PredictionApiApiFp(this.configuration)
      .createDedicatedInferenceServicePredictionApiPost(
        requestParameters.createPredictionAPIServiceParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete the dedicated inference service (if one exists), cleaning up the deployment, service, and networking ingress.
   * @summary Delete Dedicated Inference Service
   * @param {PredictionApiApiDeleteDedicatedInferenceServicePredictionApiDeploymentUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictionApiApi
   */
  public deleteDedicatedInferenceServicePredictionApiDeploymentUidDelete(
    requestParameters: PredictionApiApiDeleteDedicatedInferenceServicePredictionApiDeploymentUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PredictionApiApiFp(this.configuration)
      .deleteDedicatedInferenceServicePredictionApiDeploymentUidDelete(
        requestParameters.deploymentUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Dedicated Inference Service
   * @param {PredictionApiApiGetDedicatedInferenceServicePredictionApiDeploymentUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictionApiApi
   */
  public getDedicatedInferenceServicePredictionApiDeploymentUidGet(
    requestParameters: PredictionApiApiGetDedicatedInferenceServicePredictionApiDeploymentUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PredictionApiApiFp(this.configuration)
      .getDedicatedInferenceServicePredictionApiDeploymentUidGet(
        requestParameters.deploymentUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Dedicated Inference Services
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictionApiApi
   */
  public getDedicatedInferenceServicesPredictionApiGet(
    options?: RawAxiosRequestConfig,
  ) {
    return PredictionApiApiFp(this.configuration)
      .getDedicatedInferenceServicesPredictionApiGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PredictionsApi - axios parameter creator
 * @export
 */
export const PredictionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Predictions
     * @param {number} nodeUid
     * @param {Predictions} predictions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPredictionsNodesNodeUidPredictionsPost: async (
      nodeUid: number,
      predictions: Predictions,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addPredictionsNodesNodeUidPredictionsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'predictions' is not null or undefined
      assertParamExists(
        'addPredictionsNodesNodeUidPredictionsPost',
        'predictions',
        predictions,
      );
      const localVarPath = `/nodes/{node_uid}/predictions`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        predictions,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Exports predictions as download-able CSV.
     * @summary Export Predictions
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {number} [chunkSize]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet: async (
      nodeUid: number,
      modelUid: number,
      startDate?: string,
      endDate?: string,
      chunkSize?: number,
      applyPostprocessors?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'exportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'exportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet',
        'modelUid',
        modelUid,
      );
      const localVarPath =
        `/nodes/{node_uid}/models/{model_uid}/predictions/export`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (chunkSize !== undefined) {
        localVarQueryParameter['chunk_size'] = chunkSize;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch Predictions
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [isContext]
     * @param {boolean} [returnLabels]
     * @param {boolean} [returnProbs]
     * @param {boolean} [returnConfidences]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchPredictionsNodesNodeUidModelsModelUidPredictionsGet: async (
      nodeUid: number,
      modelUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      isContext?: boolean,
      returnLabels?: boolean,
      returnProbs?: boolean,
      returnConfidences?: boolean,
      applyPostprocessors?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'fetchPredictionsNodesNodeUidModelsModelUidPredictionsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'fetchPredictionsNodesNodeUidModelsModelUidPredictionsGet',
        'modelUid',
        modelUid,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}/predictions`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (isContext !== undefined) {
        localVarQueryParameter['is_context'] = isContext;
      }

      if (returnLabels !== undefined) {
        localVarQueryParameter['return_labels'] = returnLabels;
      }

      if (returnProbs !== undefined) {
        localVarQueryParameter['return_probs'] = returnProbs;
      }

      if (returnConfidences !== undefined) {
        localVarQueryParameter['return_confidences'] = returnConfidences;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get X Uid Predictions
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {string} xUid
     * @param {boolean} [isContext]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet: async (
      nodeUid: number,
      modelUid: number,
      xUid: string,
      isContext?: boolean,
      applyPostprocessors?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet',
        'modelUid',
        modelUid,
      );
      // verify required parameter 'xUid' is not null or undefined
      assertParamExists(
        'getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet',
        'xUid',
        xUid,
      );
      const localVarPath =
        `/nodes/{node_uid}/models/{model_uid}/x_uid/{x_uid}/predictions`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)))
          .replace(`{${'x_uid'}}`, encodeURIComponent(String(xUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isContext !== undefined) {
        localVarQueryParameter['is_context'] = isContext;
      }

      if (applyPostprocessors !== undefined) {
        localVarQueryParameter['apply_postprocessors'] = applyPostprocessors;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search Predictions
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {FetchModelPredictions} fetchModelPredictions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPredictionsNodesNodeUidModelsModelUidPredictionsPost: async (
      nodeUid: number,
      modelUid: number,
      fetchModelPredictions: FetchModelPredictions,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'searchPredictionsNodesNodeUidModelsModelUidPredictionsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'searchPredictionsNodesNodeUidModelsModelUidPredictionsPost',
        'modelUid',
        modelUid,
      );
      // verify required parameter 'fetchModelPredictions' is not null or undefined
      assertParamExists(
        'searchPredictionsNodesNodeUidModelsModelUidPredictionsPost',
        'fetchModelPredictions',
        fetchModelPredictions,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}/predictions`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fetchModelPredictions,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PredictionsApi - functional programming interface
 * @export
 */
export const PredictionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PredictionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Predictions
     * @param {number} nodeUid
     * @param {Predictions} predictions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addPredictionsNodesNodeUidPredictionsPost(
      nodeUid: number,
      predictions: Predictions,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addPredictionsNodesNodeUidPredictionsPost(
          nodeUid,
          predictions,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PredictionsApi.addPredictionsNodesNodeUidPredictionsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Exports predictions as download-able CSV.
     * @summary Export Predictions
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {number} [chunkSize]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet(
      nodeUid: number,
      modelUid: number,
      startDate?: string,
      endDate?: string,
      chunkSize?: number,
      applyPostprocessors?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet(
          nodeUid,
          modelUid,
          startDate,
          endDate,
          chunkSize,
          applyPostprocessors,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PredictionsApi.exportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Fetch Predictions
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [isContext]
     * @param {boolean} [returnLabels]
     * @param {boolean} [returnProbs]
     * @param {boolean} [returnConfidences]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchPredictionsNodesNodeUidModelsModelUidPredictionsGet(
      nodeUid: number,
      modelUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      isContext?: boolean,
      returnLabels?: boolean,
      returnProbs?: boolean,
      returnConfidences?: boolean,
      applyPostprocessors?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchPredictionsNodesNodeUidModelsModelUidPredictionsGet(
          nodeUid,
          modelUid,
          split,
          startDate,
          endDate,
          isContext,
          returnLabels,
          returnProbs,
          returnConfidences,
          applyPostprocessors,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PredictionsApi.fetchPredictionsNodesNodeUidModelsModelUidPredictionsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get X Uid Predictions
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {string} xUid
     * @param {boolean} [isContext]
     * @param {boolean} [applyPostprocessors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet(
      nodeUid: number,
      modelUid: number,
      xUid: string,
      isContext?: boolean,
      applyPostprocessors?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet(
          nodeUid,
          modelUid,
          xUid,
          isContext,
          applyPostprocessors,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PredictionsApi.getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Search Predictions
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {FetchModelPredictions} fetchModelPredictions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchPredictionsNodesNodeUidModelsModelUidPredictionsPost(
      nodeUid: number,
      modelUid: number,
      fetchModelPredictions: FetchModelPredictions,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FetchModelPredictionsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.searchPredictionsNodesNodeUidModelsModelUidPredictionsPost(
          nodeUid,
          modelUid,
          fetchModelPredictions,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PredictionsApi.searchPredictionsNodesNodeUidModelsModelUidPredictionsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PredictionsApi - factory interface
 * @export
 */
export const PredictionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PredictionsApiFp(configuration);
  return {
    /**
     *
     * @summary Add Predictions
     * @param {PredictionsApiAddPredictionsNodesNodeUidPredictionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPredictionsNodesNodeUidPredictionsPost(
      requestParameters: PredictionsApiAddPredictionsNodesNodeUidPredictionsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .addPredictionsNodesNodeUidPredictionsPost(
          requestParameters.nodeUid,
          requestParameters.predictions,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Exports predictions as download-able CSV.
     * @summary Export Predictions
     * @param {PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet(
      requestParameters: PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .exportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.startDate,
          requestParameters.endDate,
          requestParameters.chunkSize,
          requestParameters.applyPostprocessors,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch Predictions
     * @param {PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchPredictionsNodesNodeUidModelsModelUidPredictionsGet(
      requestParameters: PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .fetchPredictionsNodesNodeUidModelsModelUidPredictionsGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          requestParameters.isContext,
          requestParameters.returnLabels,
          requestParameters.returnProbs,
          requestParameters.returnConfidences,
          requestParameters.applyPostprocessors,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get X Uid Predictions
     * @param {PredictionsApiGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet(
      requestParameters: PredictionsApiGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ResponseGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet> {
      return localVarFp
        .getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.xUid,
          requestParameters.isContext,
          requestParameters.applyPostprocessors,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search Predictions
     * @param {PredictionsApiSearchPredictionsNodesNodeUidModelsModelUidPredictionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchPredictionsNodesNodeUidModelsModelUidPredictionsPost(
      requestParameters: PredictionsApiSearchPredictionsNodesNodeUidModelsModelUidPredictionsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FetchModelPredictionsResponse> {
      return localVarFp
        .searchPredictionsNodesNodeUidModelsModelUidPredictionsPost(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.fetchModelPredictions,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addPredictionsNodesNodeUidPredictionsPost operation in PredictionsApi.
 * @export
 * @interface PredictionsApiAddPredictionsNodesNodeUidPredictionsPostRequest
 */
export interface PredictionsApiAddPredictionsNodesNodeUidPredictionsPostRequest {
  /**
   *
   * @type {number}
   * @memberof PredictionsApiAddPredictionsNodesNodeUidPredictionsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {Predictions}
   * @memberof PredictionsApiAddPredictionsNodesNodeUidPredictionsPost
   */
  readonly predictions: Predictions;
}

/**
 * Request parameters for exportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet operation in PredictionsApi.
 * @export
 * @interface PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGetRequest
 */
export interface PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGetRequest {
  /**
   *
   * @type {number}
   * @memberof PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet
   */
  readonly endDate?: string;

  /**
   *
   * @type {number}
   * @memberof PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet
   */
  readonly chunkSize?: number;

  /**
   *
   * @type {boolean}
   * @memberof PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet
   */
  readonly applyPostprocessors?: boolean;
}

/**
 * Request parameters for fetchPredictionsNodesNodeUidModelsModelUidPredictionsGet operation in PredictionsApi.
 * @export
 * @interface PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGetRequest
 */
export interface PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGetRequest {
  /**
   *
   * @type {number}
   * @memberof PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGet
   */
  readonly endDate?: string;

  /**
   *
   * @type {boolean}
   * @memberof PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGet
   */
  readonly isContext?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGet
   */
  readonly returnLabels?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGet
   */
  readonly returnProbs?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGet
   */
  readonly returnConfidences?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGet
   */
  readonly applyPostprocessors?: boolean;
}

/**
 * Request parameters for getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet operation in PredictionsApi.
 * @export
 * @interface PredictionsApiGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGetRequest
 */
export interface PredictionsApiGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGetRequest {
  /**
   *
   * @type {number}
   * @memberof PredictionsApiGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof PredictionsApiGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {string}
   * @memberof PredictionsApiGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet
   */
  readonly xUid: string;

  /**
   *
   * @type {boolean}
   * @memberof PredictionsApiGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet
   */
  readonly isContext?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof PredictionsApiGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet
   */
  readonly applyPostprocessors?: boolean;
}

/**
 * Request parameters for searchPredictionsNodesNodeUidModelsModelUidPredictionsPost operation in PredictionsApi.
 * @export
 * @interface PredictionsApiSearchPredictionsNodesNodeUidModelsModelUidPredictionsPostRequest
 */
export interface PredictionsApiSearchPredictionsNodesNodeUidModelsModelUidPredictionsPostRequest {
  /**
   *
   * @type {number}
   * @memberof PredictionsApiSearchPredictionsNodesNodeUidModelsModelUidPredictionsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof PredictionsApiSearchPredictionsNodesNodeUidModelsModelUidPredictionsPost
   */
  readonly modelUid: number;

  /**
   *
   * @type {FetchModelPredictions}
   * @memberof PredictionsApiSearchPredictionsNodesNodeUidModelsModelUidPredictionsPost
   */
  readonly fetchModelPredictions: FetchModelPredictions;
}

/**
 * PredictionsApi - object-oriented interface
 * @export
 * @class PredictionsApi
 * @extends {BaseAPI}
 */
export class PredictionsApi extends BaseAPI {
  /**
   *
   * @summary Add Predictions
   * @param {PredictionsApiAddPredictionsNodesNodeUidPredictionsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictionsApi
   */
  public addPredictionsNodesNodeUidPredictionsPost(
    requestParameters: PredictionsApiAddPredictionsNodesNodeUidPredictionsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PredictionsApiFp(this.configuration)
      .addPredictionsNodesNodeUidPredictionsPost(
        requestParameters.nodeUid,
        requestParameters.predictions,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Exports predictions as download-able CSV.
   * @summary Export Predictions
   * @param {PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictionsApi
   */
  public exportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet(
    requestParameters: PredictionsApiExportPredictionsNodesNodeUidModelsModelUidPredictionsExportGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PredictionsApiFp(this.configuration)
      .exportPredictionsNodesNodeUidModelsModelUidPredictionsExportGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.chunkSize,
        requestParameters.applyPostprocessors,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch Predictions
   * @param {PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictionsApi
   */
  public fetchPredictionsNodesNodeUidModelsModelUidPredictionsGet(
    requestParameters: PredictionsApiFetchPredictionsNodesNodeUidModelsModelUidPredictionsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PredictionsApiFp(this.configuration)
      .fetchPredictionsNodesNodeUidModelsModelUidPredictionsGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.isContext,
        requestParameters.returnLabels,
        requestParameters.returnProbs,
        requestParameters.returnConfidences,
        requestParameters.applyPostprocessors,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get X Uid Predictions
   * @param {PredictionsApiGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictionsApi
   */
  public getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet(
    requestParameters: PredictionsApiGetXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PredictionsApiFp(this.configuration)
      .getXUidPredictionsNodesNodeUidModelsModelUidXUidXUidPredictionsGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.xUid,
        requestParameters.isContext,
        requestParameters.applyPostprocessors,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search Predictions
   * @param {PredictionsApiSearchPredictionsNodesNodeUidModelsModelUidPredictionsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PredictionsApi
   */
  public searchPredictionsNodesNodeUidModelsModelUidPredictionsPost(
    requestParameters: PredictionsApiSearchPredictionsNodesNodeUidModelsModelUidPredictionsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PredictionsApiFp(this.configuration)
      .searchPredictionsNodesNodeUidModelsModelUidPredictionsPost(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.fetchModelPredictions,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ProgressReportApi - axios parameter creator
 * @export
 */
export const ProgressReportApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Build Progress Report
     * @param {number} nodeUid
     * @param {string} startDate
     * @param {string} endDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildProgressReportNodesNodeUidProgressReportGet: async (
      nodeUid: number,
      startDate: string,
      endDate: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'buildProgressReportNodesNodeUidProgressReportGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists(
        'buildProgressReportNodesNodeUidProgressReportGet',
        'startDate',
        startDate,
      );
      // verify required parameter 'endDate' is not null or undefined
      assertParamExists(
        'buildProgressReportNodesNodeUidProgressReportGet',
        'endDate',
        endDate,
      );
      const localVarPath = `/nodes/{node_uid}/progress-report`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Progress Report
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProgressReportProgressReportJobUidGet: async (
      jobUid: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobUid' is not null or undefined
      assertParamExists(
        'getProgressReportProgressReportJobUidGet',
        'jobUid',
        jobUid,
      );
      const localVarPath = `/progress-report/{job_uid}`.replace(
        `{${'job_uid'}}`,
        encodeURIComponent(String(jobUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProgressReportApi - functional programming interface
 * @export
 */
export const ProgressReportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ProgressReportApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Build Progress Report
     * @param {number} nodeUid
     * @param {string} startDate
     * @param {string} endDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buildProgressReportNodesNodeUidProgressReportGet(
      nodeUid: number,
      startDate: string,
      endDate: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.buildProgressReportNodesNodeUidProgressReportGet(
          nodeUid,
          startDate,
          endDate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ProgressReportApi.buildProgressReportNodesNodeUidProgressReportGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Progress Report
     * @param {string} jobUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProgressReportProgressReportJobUidGet(
      jobUid: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getProgressReportProgressReportJobUidGet(
          jobUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ProgressReportApi.getProgressReportProgressReportJobUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ProgressReportApi - factory interface
 * @export
 */
export const ProgressReportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ProgressReportApiFp(configuration);
  return {
    /**
     *
     * @summary Build Progress Report
     * @param {ProgressReportApiBuildProgressReportNodesNodeUidProgressReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildProgressReportNodesNodeUidProgressReportGet(
      requestParameters: ProgressReportApiBuildProgressReportNodesNodeUidProgressReportGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .buildProgressReportNodesNodeUidProgressReportGet(
          requestParameters.nodeUid,
          requestParameters.startDate,
          requestParameters.endDate,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Progress Report
     * @param {ProgressReportApiGetProgressReportProgressReportJobUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProgressReportProgressReportJobUidGet(
      requestParameters: ProgressReportApiGetProgressReportProgressReportJobUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getProgressReportProgressReportJobUidGet(
          requestParameters.jobUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for buildProgressReportNodesNodeUidProgressReportGet operation in ProgressReportApi.
 * @export
 * @interface ProgressReportApiBuildProgressReportNodesNodeUidProgressReportGetRequest
 */
export interface ProgressReportApiBuildProgressReportNodesNodeUidProgressReportGetRequest {
  /**
   *
   * @type {number}
   * @memberof ProgressReportApiBuildProgressReportNodesNodeUidProgressReportGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof ProgressReportApiBuildProgressReportNodesNodeUidProgressReportGet
   */
  readonly startDate: string;

  /**
   *
   * @type {string}
   * @memberof ProgressReportApiBuildProgressReportNodesNodeUidProgressReportGet
   */
  readonly endDate: string;
}

/**
 * Request parameters for getProgressReportProgressReportJobUidGet operation in ProgressReportApi.
 * @export
 * @interface ProgressReportApiGetProgressReportProgressReportJobUidGetRequest
 */
export interface ProgressReportApiGetProgressReportProgressReportJobUidGetRequest {
  /**
   *
   * @type {string}
   * @memberof ProgressReportApiGetProgressReportProgressReportJobUidGet
   */
  readonly jobUid: string;
}

/**
 * ProgressReportApi - object-oriented interface
 * @export
 * @class ProgressReportApi
 * @extends {BaseAPI}
 */
export class ProgressReportApi extends BaseAPI {
  /**
   *
   * @summary Build Progress Report
   * @param {ProgressReportApiBuildProgressReportNodesNodeUidProgressReportGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProgressReportApi
   */
  public buildProgressReportNodesNodeUidProgressReportGet(
    requestParameters: ProgressReportApiBuildProgressReportNodesNodeUidProgressReportGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ProgressReportApiFp(this.configuration)
      .buildProgressReportNodesNodeUidProgressReportGet(
        requestParameters.nodeUid,
        requestParameters.startDate,
        requestParameters.endDate,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Progress Report
   * @param {ProgressReportApiGetProgressReportProgressReportJobUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProgressReportApi
   */
  public getProgressReportProgressReportJobUidGet(
    requestParameters: ProgressReportApiGetProgressReportProgressReportJobUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ProgressReportApiFp(this.configuration)
      .getProgressReportProgressReportJobUidGet(
        requestParameters.jobUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PromptBuilderApi - axios parameter creator
 * @export
 */
export const PromptBuilderApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Supported Prompt Builder Models
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGet:
      async (
        nodeUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGet',
          'nodeUid',
          nodeUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/supported-prompt-builder-models`.replace(
            `{${'node_uid'}}`,
            encodeURIComponent(String(nodeUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Run All Prompt Inference
     * @param {number} nodeUid
     * @param {RunAllPromptInferenceRequest} runAllPromptInferenceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runAllPromptInferenceNodesNodeUidRunAllPromptInferencePost: async (
      nodeUid: number,
      runAllPromptInferenceRequest: RunAllPromptInferenceRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'runAllPromptInferenceNodesNodeUidRunAllPromptInferencePost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'runAllPromptInferenceRequest' is not null or undefined
      assertParamExists(
        'runAllPromptInferenceNodesNodeUidRunAllPromptInferencePost',
        'runAllPromptInferenceRequest',
        runAllPromptInferenceRequest,
      );
      const localVarPath = `/nodes/{node_uid}/run-all-prompt-inference`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        runAllPromptInferenceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PromptBuilderApi - functional programming interface
 * @export
 */
export const PromptBuilderApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PromptBuilderApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Supported Prompt Builder Models
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSupportedPromptBuilderModelsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PromptBuilderApi.getSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Run All Prompt Inference
     * @param {number} nodeUid
     * @param {RunAllPromptInferenceRequest} runAllPromptInferenceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runAllPromptInferenceNodesNodeUidRunAllPromptInferencePost(
      nodeUid: number,
      runAllPromptInferenceRequest: RunAllPromptInferenceRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RunPromptOutputResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.runAllPromptInferenceNodesNodeUidRunAllPromptInferencePost(
          nodeUid,
          runAllPromptInferenceRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'PromptBuilderApi.runAllPromptInferenceNodesNodeUidRunAllPromptInferencePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PromptBuilderApi - factory interface
 * @export
 */
export const PromptBuilderApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PromptBuilderApiFp(configuration);
  return {
    /**
     *
     * @summary Get Supported Prompt Builder Models
     * @param {PromptBuilderApiGetSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGet(
      requestParameters: PromptBuilderApiGetSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetSupportedPromptBuilderModelsResponse> {
      return localVarFp
        .getSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Run All Prompt Inference
     * @param {PromptBuilderApiRunAllPromptInferenceNodesNodeUidRunAllPromptInferencePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runAllPromptInferenceNodesNodeUidRunAllPromptInferencePost(
      requestParameters: PromptBuilderApiRunAllPromptInferenceNodesNodeUidRunAllPromptInferencePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RunPromptOutputResponse> {
      return localVarFp
        .runAllPromptInferenceNodesNodeUidRunAllPromptInferencePost(
          requestParameters.nodeUid,
          requestParameters.runAllPromptInferenceRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGet operation in PromptBuilderApi.
 * @export
 * @interface PromptBuilderApiGetSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGetRequest
 */
export interface PromptBuilderApiGetSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGetRequest {
  /**
   *
   * @type {number}
   * @memberof PromptBuilderApiGetSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for runAllPromptInferenceNodesNodeUidRunAllPromptInferencePost operation in PromptBuilderApi.
 * @export
 * @interface PromptBuilderApiRunAllPromptInferenceNodesNodeUidRunAllPromptInferencePostRequest
 */
export interface PromptBuilderApiRunAllPromptInferenceNodesNodeUidRunAllPromptInferencePostRequest {
  /**
   *
   * @type {number}
   * @memberof PromptBuilderApiRunAllPromptInferenceNodesNodeUidRunAllPromptInferencePost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {RunAllPromptInferenceRequest}
   * @memberof PromptBuilderApiRunAllPromptInferenceNodesNodeUidRunAllPromptInferencePost
   */
  readonly runAllPromptInferenceRequest: RunAllPromptInferenceRequest;
}

/**
 * PromptBuilderApi - object-oriented interface
 * @export
 * @class PromptBuilderApi
 * @extends {BaseAPI}
 */
export class PromptBuilderApi extends BaseAPI {
  /**
   *
   * @summary Get Supported Prompt Builder Models
   * @param {PromptBuilderApiGetSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PromptBuilderApi
   */
  public getSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGet(
    requestParameters: PromptBuilderApiGetSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PromptBuilderApiFp(this.configuration)
      .getSupportedPromptBuilderModelsNodesNodeUidSupportedPromptBuilderModelsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Run All Prompt Inference
   * @param {PromptBuilderApiRunAllPromptInferenceNodesNodeUidRunAllPromptInferencePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PromptBuilderApi
   */
  public runAllPromptInferenceNodesNodeUidRunAllPromptInferencePost(
    requestParameters: PromptBuilderApiRunAllPromptInferenceNodesNodeUidRunAllPromptInferencePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PromptBuilderApiFp(this.configuration)
      .runAllPromptInferenceNodesNodeUidRunAllPromptInferencePost(
        requestParameters.nodeUid,
        requestParameters.runAllPromptInferenceRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * RayApi - axios parameter creator
 * @export
 */
export const RayApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get ray cluster memory information
     * @summary Memory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    memoryRayMemoryGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/ray/memory`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RayApi - functional programming interface
 * @export
 */
export const RayApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RayApiAxiosParamCreator(configuration);
  return {
    /**
     * Get ray cluster memory information
     * @summary Memory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async memoryRayMemoryGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RayMemory>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.memoryRayMemoryGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['RayApi.memoryRayMemoryGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * RayApi - factory interface
 * @export
 */
export const RayApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RayApiFp(configuration);
  return {
    /**
     * Get ray cluster memory information
     * @summary Memory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    memoryRayMemoryGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RayMemory> {
      return localVarFp
        .memoryRayMemoryGet(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * RayApi - object-oriented interface
 * @export
 * @class RayApi
 * @extends {BaseAPI}
 */
export class RayApi extends BaseAPI {
  /**
   * Get ray cluster memory information
   * @summary Memory
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RayApi
   */
  public memoryRayMemoryGet(options?: RawAxiosRequestConfig) {
    return RayApiFp(this.configuration)
      .memoryRayMemoryGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * RayDashboardApi - axios parameter creator
 * @export
 */
export const RayDashboardApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Dashboard Request
     * @param {string} path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardRequestInferenceDashboardPathGet: async (
      path: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'path' is not null or undefined
      assertParamExists(
        'dashboardRequestInferenceDashboardPathGet',
        'path',
        path,
      );
      const localVarPath = `/inference-dashboard/{path}`.replace(
        `{${'path'}}`,
        encodeURIComponent(String(path)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RayDashboardApi - functional programming interface
 * @export
 */
export const RayDashboardApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    RayDashboardApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Dashboard Request
     * @param {string} path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dashboardRequestInferenceDashboardPathGet(
      path: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dashboardRequestInferenceDashboardPathGet(
          path,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'RayDashboardApi.dashboardRequestInferenceDashboardPathGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * RayDashboardApi - factory interface
 * @export
 */
export const RayDashboardApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RayDashboardApiFp(configuration);
  return {
    /**
     *
     * @summary Dashboard Request
     * @param {RayDashboardApiDashboardRequestInferenceDashboardPathGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dashboardRequestInferenceDashboardPathGet(
      requestParameters: RayDashboardApiDashboardRequestInferenceDashboardPathGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .dashboardRequestInferenceDashboardPathGet(
          requestParameters.path,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for dashboardRequestInferenceDashboardPathGet operation in RayDashboardApi.
 * @export
 * @interface RayDashboardApiDashboardRequestInferenceDashboardPathGetRequest
 */
export interface RayDashboardApiDashboardRequestInferenceDashboardPathGetRequest {
  /**
   *
   * @type {string}
   * @memberof RayDashboardApiDashboardRequestInferenceDashboardPathGet
   */
  readonly path: string;
}

/**
 * RayDashboardApi - object-oriented interface
 * @export
 * @class RayDashboardApi
 * @extends {BaseAPI}
 */
export class RayDashboardApi extends BaseAPI {
  /**
   *
   * @summary Dashboard Request
   * @param {RayDashboardApiDashboardRequestInferenceDashboardPathGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RayDashboardApi
   */
  public dashboardRequestInferenceDashboardPathGet(
    requestParameters: RayDashboardApiDashboardRequestInferenceDashboardPathGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return RayDashboardApiFp(this.configuration)
      .dashboardRequestInferenceDashboardPathGet(
        requestParameters.path,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * RbacResourceRolesRouterApi - axios parameter creator
 * @export
 */
export const RbacResourceRolesRouterApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Activate Resource Roles
     * @param {ServicesRbacModelsResourceType} resourceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateResourceRolesRbacResourceRolesResourceTypeActivatePost: async (
      resourceType: ServicesRbacModelsResourceType,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resourceType' is not null or undefined
      assertParamExists(
        'activateResourceRolesRbacResourceRolesResourceTypeActivatePost',
        'resourceType',
        resourceType,
      );
      const localVarPath =
        `/rbac-resource-roles/{resource_type}/activate`.replace(
          `{${'resource_type'}}`,
          encodeURIComponent(String(resourceType)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deactivate Resource Roles
     * @param {ServicesRbacModelsResourceType} resourceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePost: async (
      resourceType: ServicesRbacModelsResourceType,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resourceType' is not null or undefined
      assertParamExists(
        'deactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePost',
        'resourceType',
        resourceType,
      );
      const localVarPath =
        `/rbac-resource-roles/{resource_type}/deactivate`.replace(
          `{${'resource_type'}}`,
          encodeURIComponent(String(resourceType)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Rbac Resource Enabled
     * @param {ServicesRbacModelsResourceType} resourceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGet: async (
      resourceType: ServicesRbacModelsResourceType,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resourceType' is not null or undefined
      assertParamExists(
        'getRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGet',
        'resourceType',
        resourceType,
      );
      const localVarPath =
        `/rbac-resource-roles/{resource_type}/enabled`.replace(
          `{${'resource_type'}}`,
          encodeURIComponent(String(resourceType)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RbacResourceRolesRouterApi - functional programming interface
 * @export
 */
export const RbacResourceRolesRouterApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    RbacResourceRolesRouterApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Activate Resource Roles
     * @param {ServicesRbacModelsResourceType} resourceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateResourceRolesRbacResourceRolesResourceTypeActivatePost(
      resourceType: ServicesRbacModelsResourceType,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.activateResourceRolesRbacResourceRolesResourceTypeActivatePost(
          resourceType,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'RbacResourceRolesRouterApi.activateResourceRolesRbacResourceRolesResourceTypeActivatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Deactivate Resource Roles
     * @param {ServicesRbacModelsResourceType} resourceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePost(
      resourceType: ServicesRbacModelsResourceType,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePost(
          resourceType,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'RbacResourceRolesRouterApi.deactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Rbac Resource Enabled
     * @param {ServicesRbacModelsResourceType} resourceType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGet(
      resourceType: ServicesRbacModelsResourceType,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGet(
          resourceType,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'RbacResourceRolesRouterApi.getRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * RbacResourceRolesRouterApi - factory interface
 * @export
 */
export const RbacResourceRolesRouterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RbacResourceRolesRouterApiFp(configuration);
  return {
    /**
     *
     * @summary Activate Resource Roles
     * @param {RbacResourceRolesRouterApiActivateResourceRolesRbacResourceRolesResourceTypeActivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateResourceRolesRbacResourceRolesResourceTypeActivatePost(
      requestParameters: RbacResourceRolesRouterApiActivateResourceRolesRbacResourceRolesResourceTypeActivatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .activateResourceRolesRbacResourceRolesResourceTypeActivatePost(
          requestParameters.resourceType,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Deactivate Resource Roles
     * @param {RbacResourceRolesRouterApiDeactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePost(
      requestParameters: RbacResourceRolesRouterApiDeactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePost(
          requestParameters.resourceType,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Rbac Resource Enabled
     * @param {RbacResourceRolesRouterApiGetRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGet(
      requestParameters: RbacResourceRolesRouterApiGetRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .getRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGet(
          requestParameters.resourceType,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for activateResourceRolesRbacResourceRolesResourceTypeActivatePost operation in RbacResourceRolesRouterApi.
 * @export
 * @interface RbacResourceRolesRouterApiActivateResourceRolesRbacResourceRolesResourceTypeActivatePostRequest
 */
export interface RbacResourceRolesRouterApiActivateResourceRolesRbacResourceRolesResourceTypeActivatePostRequest {
  /**
   *
   * @type {ServicesRbacModelsResourceType}
   * @memberof RbacResourceRolesRouterApiActivateResourceRolesRbacResourceRolesResourceTypeActivatePost
   */
  readonly resourceType: ServicesRbacModelsResourceType;
}

/**
 * Request parameters for deactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePost operation in RbacResourceRolesRouterApi.
 * @export
 * @interface RbacResourceRolesRouterApiDeactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePostRequest
 */
export interface RbacResourceRolesRouterApiDeactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePostRequest {
  /**
   *
   * @type {ServicesRbacModelsResourceType}
   * @memberof RbacResourceRolesRouterApiDeactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePost
   */
  readonly resourceType: ServicesRbacModelsResourceType;
}

/**
 * Request parameters for getRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGet operation in RbacResourceRolesRouterApi.
 * @export
 * @interface RbacResourceRolesRouterApiGetRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGetRequest
 */
export interface RbacResourceRolesRouterApiGetRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGetRequest {
  /**
   *
   * @type {ServicesRbacModelsResourceType}
   * @memberof RbacResourceRolesRouterApiGetRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGet
   */
  readonly resourceType: ServicesRbacModelsResourceType;
}

/**
 * RbacResourceRolesRouterApi - object-oriented interface
 * @export
 * @class RbacResourceRolesRouterApi
 * @extends {BaseAPI}
 */
export class RbacResourceRolesRouterApi extends BaseAPI {
  /**
   *
   * @summary Activate Resource Roles
   * @param {RbacResourceRolesRouterApiActivateResourceRolesRbacResourceRolesResourceTypeActivatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RbacResourceRolesRouterApi
   */
  public activateResourceRolesRbacResourceRolesResourceTypeActivatePost(
    requestParameters: RbacResourceRolesRouterApiActivateResourceRolesRbacResourceRolesResourceTypeActivatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return RbacResourceRolesRouterApiFp(this.configuration)
      .activateResourceRolesRbacResourceRolesResourceTypeActivatePost(
        requestParameters.resourceType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deactivate Resource Roles
   * @param {RbacResourceRolesRouterApiDeactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RbacResourceRolesRouterApi
   */
  public deactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePost(
    requestParameters: RbacResourceRolesRouterApiDeactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return RbacResourceRolesRouterApiFp(this.configuration)
      .deactivateResourceRolesRbacResourceRolesResourceTypeDeactivatePost(
        requestParameters.resourceType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Rbac Resource Enabled
   * @param {RbacResourceRolesRouterApiGetRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RbacResourceRolesRouterApi
   */
  public getRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGet(
    requestParameters: RbacResourceRolesRouterApiGetRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return RbacResourceRolesRouterApiFp(this.configuration)
      .getRbacResourceEnabledRbacResourceRolesResourceTypeEnabledGet(
        requestParameters.resourceType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ResourceRecencyRouterApi - axios parameter creator
 * @export
 */
export const ResourceRecencyRouterApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Recent Applications
     * @param {number} userUid
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecentApplicationsUsersUserUidRecentApplicationsGet: async (
      userUid: number,
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'getRecentApplicationsUsersUserUidRecentApplicationsGet',
        'userUid',
        userUid,
      );
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'getRecentApplicationsUsersUserUidRecentApplicationsGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/users/{user_uid}/recent-applications`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Recent Datasets
     * @param {number} userUid
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecentDatasetsUsersUserUidRecentDatasetsGet: async (
      userUid: number,
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'getRecentDatasetsUsersUserUidRecentDatasetsGet',
        'userUid',
        userUid,
      );
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'getRecentDatasetsUsersUserUidRecentDatasetsGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/users/{user_uid}/recent-datasets`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Workspace Recent Applications
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGet:
      async (
        workspaceUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'workspaceUid' is not null or undefined
        assertParamExists(
          'getWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGet',
          'workspaceUid',
          workspaceUid,
        );
        const localVarPath =
          `/workspace/{workspace_uid}/recent-applications`.replace(
            `{${'workspace_uid'}}`,
            encodeURIComponent(String(workspaceUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Workspace Recent Datasets
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGet: async (
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'getWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/workspace/{workspace_uid}/recent-datasets`.replace(
        `{${'workspace_uid'}}`,
        encodeURIComponent(String(workspaceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary View Application
     * @param {number} userUid
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    viewApplicationUsersUserUidViewApplicationApplicationUidPost: async (
      userUid: number,
      applicationUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'viewApplicationUsersUserUidViewApplicationApplicationUidPost',
        'userUid',
        userUid,
      );
      // verify required parameter 'applicationUid' is not null or undefined
      assertParamExists(
        'viewApplicationUsersUserUidViewApplicationApplicationUidPost',
        'applicationUid',
        applicationUid,
      );
      const localVarPath =
        `/users/{user_uid}/view-application/{application_uid}`
          .replace(`{${'user_uid'}}`, encodeURIComponent(String(userUid)))
          .replace(
            `{${'application_uid'}}`,
            encodeURIComponent(String(applicationUid)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary View Dataset
     * @param {number} userUid
     * @param {number} datasetUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    viewDatasetUsersUserUidViewDatasetDatasetUidPost: async (
      userUid: number,
      datasetUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'viewDatasetUsersUserUidViewDatasetDatasetUidPost',
        'userUid',
        userUid,
      );
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'viewDatasetUsersUserUidViewDatasetDatasetUidPost',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/users/{user_uid}/view-dataset/{dataset_uid}`
        .replace(`{${'user_uid'}}`, encodeURIComponent(String(userUid)))
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ResourceRecencyRouterApi - functional programming interface
 * @export
 */
export const ResourceRecencyRouterApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    ResourceRecencyRouterApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Recent Applications
     * @param {number} userUid
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecentApplicationsUsersUserUidRecentApplicationsGet(
      userUid: number,
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ApplicationInfo>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRecentApplicationsUsersUserUidRecentApplicationsGet(
          userUid,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ResourceRecencyRouterApi.getRecentApplicationsUsersUserUidRecentApplicationsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Recent Datasets
     * @param {number} userUid
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecentDatasetsUsersUserUidRecentDatasetsGet(
      userUid: number,
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasetInfo>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRecentDatasetsUsersUserUidRecentDatasetsGet(
          userUid,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ResourceRecencyRouterApi.getRecentDatasetsUsersUserUidRecentDatasetsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Workspace Recent Applications
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGet(
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ApplicationInfo>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ResourceRecencyRouterApi.getWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Workspace Recent Datasets
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGet(
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DatasetInfo>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ResourceRecencyRouterApi.getWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary View Application
     * @param {number} userUid
     * @param {number} applicationUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async viewApplicationUsersUserUidViewApplicationApplicationUidPost(
      userUid: number,
      applicationUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.viewApplicationUsersUserUidViewApplicationApplicationUidPost(
          userUid,
          applicationUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ResourceRecencyRouterApi.viewApplicationUsersUserUidViewApplicationApplicationUidPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary View Dataset
     * @param {number} userUid
     * @param {number} datasetUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async viewDatasetUsersUserUidViewDatasetDatasetUidPost(
      userUid: number,
      datasetUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.viewDatasetUsersUserUidViewDatasetDatasetUidPost(
          userUid,
          datasetUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'ResourceRecencyRouterApi.viewDatasetUsersUserUidViewDatasetDatasetUidPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ResourceRecencyRouterApi - factory interface
 * @export
 */
export const ResourceRecencyRouterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ResourceRecencyRouterApiFp(configuration);
  return {
    /**
     *
     * @summary Get Recent Applications
     * @param {ResourceRecencyRouterApiGetRecentApplicationsUsersUserUidRecentApplicationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecentApplicationsUsersUserUidRecentApplicationsGet(
      requestParameters: ResourceRecencyRouterApiGetRecentApplicationsUsersUserUidRecentApplicationsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ApplicationInfo>> {
      return localVarFp
        .getRecentApplicationsUsersUserUidRecentApplicationsGet(
          requestParameters.userUid,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Recent Datasets
     * @param {ResourceRecencyRouterApiGetRecentDatasetsUsersUserUidRecentDatasetsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecentDatasetsUsersUserUidRecentDatasetsGet(
      requestParameters: ResourceRecencyRouterApiGetRecentDatasetsUsersUserUidRecentDatasetsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasetInfo>> {
      return localVarFp
        .getRecentDatasetsUsersUserUidRecentDatasetsGet(
          requestParameters.userUid,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Workspace Recent Applications
     * @param {ResourceRecencyRouterApiGetWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGet(
      requestParameters: ResourceRecencyRouterApiGetWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ApplicationInfo>> {
      return localVarFp
        .getWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGet(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Workspace Recent Datasets
     * @param {ResourceRecencyRouterApiGetWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGet(
      requestParameters: ResourceRecencyRouterApiGetWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DatasetInfo>> {
      return localVarFp
        .getWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGet(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary View Application
     * @param {ResourceRecencyRouterApiViewApplicationUsersUserUidViewApplicationApplicationUidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    viewApplicationUsersUserUidViewApplicationApplicationUidPost(
      requestParameters: ResourceRecencyRouterApiViewApplicationUsersUserUidViewApplicationApplicationUidPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .viewApplicationUsersUserUidViewApplicationApplicationUidPost(
          requestParameters.userUid,
          requestParameters.applicationUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary View Dataset
     * @param {ResourceRecencyRouterApiViewDatasetUsersUserUidViewDatasetDatasetUidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    viewDatasetUsersUserUidViewDatasetDatasetUidPost(
      requestParameters: ResourceRecencyRouterApiViewDatasetUsersUserUidViewDatasetDatasetUidPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .viewDatasetUsersUserUidViewDatasetDatasetUidPost(
          requestParameters.userUid,
          requestParameters.datasetUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getRecentApplicationsUsersUserUidRecentApplicationsGet operation in ResourceRecencyRouterApi.
 * @export
 * @interface ResourceRecencyRouterApiGetRecentApplicationsUsersUserUidRecentApplicationsGetRequest
 */
export interface ResourceRecencyRouterApiGetRecentApplicationsUsersUserUidRecentApplicationsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ResourceRecencyRouterApiGetRecentApplicationsUsersUserUidRecentApplicationsGet
   */
  readonly userUid: number;

  /**
   *
   * @type {number}
   * @memberof ResourceRecencyRouterApiGetRecentApplicationsUsersUserUidRecentApplicationsGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for getRecentDatasetsUsersUserUidRecentDatasetsGet operation in ResourceRecencyRouterApi.
 * @export
 * @interface ResourceRecencyRouterApiGetRecentDatasetsUsersUserUidRecentDatasetsGetRequest
 */
export interface ResourceRecencyRouterApiGetRecentDatasetsUsersUserUidRecentDatasetsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ResourceRecencyRouterApiGetRecentDatasetsUsersUserUidRecentDatasetsGet
   */
  readonly userUid: number;

  /**
   *
   * @type {number}
   * @memberof ResourceRecencyRouterApiGetRecentDatasetsUsersUserUidRecentDatasetsGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for getWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGet operation in ResourceRecencyRouterApi.
 * @export
 * @interface ResourceRecencyRouterApiGetWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGetRequest
 */
export interface ResourceRecencyRouterApiGetWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ResourceRecencyRouterApiGetWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for getWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGet operation in ResourceRecencyRouterApi.
 * @export
 * @interface ResourceRecencyRouterApiGetWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGetRequest
 */
export interface ResourceRecencyRouterApiGetWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ResourceRecencyRouterApiGetWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for viewApplicationUsersUserUidViewApplicationApplicationUidPost operation in ResourceRecencyRouterApi.
 * @export
 * @interface ResourceRecencyRouterApiViewApplicationUsersUserUidViewApplicationApplicationUidPostRequest
 */
export interface ResourceRecencyRouterApiViewApplicationUsersUserUidViewApplicationApplicationUidPostRequest {
  /**
   *
   * @type {number}
   * @memberof ResourceRecencyRouterApiViewApplicationUsersUserUidViewApplicationApplicationUidPost
   */
  readonly userUid: number;

  /**
   *
   * @type {number}
   * @memberof ResourceRecencyRouterApiViewApplicationUsersUserUidViewApplicationApplicationUidPost
   */
  readonly applicationUid: number;
}

/**
 * Request parameters for viewDatasetUsersUserUidViewDatasetDatasetUidPost operation in ResourceRecencyRouterApi.
 * @export
 * @interface ResourceRecencyRouterApiViewDatasetUsersUserUidViewDatasetDatasetUidPostRequest
 */
export interface ResourceRecencyRouterApiViewDatasetUsersUserUidViewDatasetDatasetUidPostRequest {
  /**
   *
   * @type {number}
   * @memberof ResourceRecencyRouterApiViewDatasetUsersUserUidViewDatasetDatasetUidPost
   */
  readonly userUid: number;

  /**
   *
   * @type {number}
   * @memberof ResourceRecencyRouterApiViewDatasetUsersUserUidViewDatasetDatasetUidPost
   */
  readonly datasetUid: number;
}

/**
 * ResourceRecencyRouterApi - object-oriented interface
 * @export
 * @class ResourceRecencyRouterApi
 * @extends {BaseAPI}
 */
export class ResourceRecencyRouterApi extends BaseAPI {
  /**
   *
   * @summary Get Recent Applications
   * @param {ResourceRecencyRouterApiGetRecentApplicationsUsersUserUidRecentApplicationsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceRecencyRouterApi
   */
  public getRecentApplicationsUsersUserUidRecentApplicationsGet(
    requestParameters: ResourceRecencyRouterApiGetRecentApplicationsUsersUserUidRecentApplicationsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ResourceRecencyRouterApiFp(this.configuration)
      .getRecentApplicationsUsersUserUidRecentApplicationsGet(
        requestParameters.userUid,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Recent Datasets
   * @param {ResourceRecencyRouterApiGetRecentDatasetsUsersUserUidRecentDatasetsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceRecencyRouterApi
   */
  public getRecentDatasetsUsersUserUidRecentDatasetsGet(
    requestParameters: ResourceRecencyRouterApiGetRecentDatasetsUsersUserUidRecentDatasetsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ResourceRecencyRouterApiFp(this.configuration)
      .getRecentDatasetsUsersUserUidRecentDatasetsGet(
        requestParameters.userUid,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Workspace Recent Applications
   * @param {ResourceRecencyRouterApiGetWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceRecencyRouterApi
   */
  public getWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGet(
    requestParameters: ResourceRecencyRouterApiGetWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ResourceRecencyRouterApiFp(this.configuration)
      .getWorkspaceRecentApplicationsWorkspaceWorkspaceUidRecentApplicationsGet(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Workspace Recent Datasets
   * @param {ResourceRecencyRouterApiGetWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceRecencyRouterApi
   */
  public getWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGet(
    requestParameters: ResourceRecencyRouterApiGetWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ResourceRecencyRouterApiFp(this.configuration)
      .getWorkspaceRecentDatasetsWorkspaceWorkspaceUidRecentDatasetsGet(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary View Application
   * @param {ResourceRecencyRouterApiViewApplicationUsersUserUidViewApplicationApplicationUidPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceRecencyRouterApi
   */
  public viewApplicationUsersUserUidViewApplicationApplicationUidPost(
    requestParameters: ResourceRecencyRouterApiViewApplicationUsersUserUidViewApplicationApplicationUidPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ResourceRecencyRouterApiFp(this.configuration)
      .viewApplicationUsersUserUidViewApplicationApplicationUidPost(
        requestParameters.userUid,
        requestParameters.applicationUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary View Dataset
   * @param {ResourceRecencyRouterApiViewDatasetUsersUserUidViewDatasetDatasetUidPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourceRecencyRouterApi
   */
  public viewDatasetUsersUserUidViewDatasetDatasetUidPost(
    requestParameters: ResourceRecencyRouterApiViewDatasetUsersUserUidViewDatasetDatasetUidPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return ResourceRecencyRouterApiFp(this.configuration)
      .viewDatasetUsersUserUidViewDatasetDatasetUidPost(
        requestParameters.userUid,
        requestParameters.datasetUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SecretsApi - axios parameter creator
 * @export
 */
export const SecretsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Secret
     * @param {DeleteSecretParams} deleteSecretParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSecretSecretsDelete: async (
      deleteSecretParams: DeleteSecretParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteSecretParams' is not null or undefined
      assertParamExists(
        'deleteSecretSecretsDelete',
        'deleteSecretParams',
        deleteSecretParams,
      );
      const localVarPath = `/secrets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteSecretParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Secret Keys
     * @param {ListSecretParams} listSecretParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSecretKeysSecretsPut: async (
      listSecretParams: ListSecretParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'listSecretParams' is not null or undefined
      assertParamExists(
        'listSecretKeysSecretsPut',
        'listSecretParams',
        listSecretParams,
      );
      const localVarPath = `/secrets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        listSecretParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Secret
     * @param {SetSecretParams} setSecretParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSecretSecretsPost: async (
      setSecretParams: SetSecretParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'setSecretParams' is not null or undefined
      assertParamExists(
        'setSecretSecretsPost',
        'setSecretParams',
        setSecretParams,
      );
      const localVarPath = `/secrets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        setSecretParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SecretsApi - functional programming interface
 * @export
 */
export const SecretsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SecretsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Secret
     * @param {DeleteSecretParams} deleteSecretParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSecretSecretsDelete(
      deleteSecretParams: DeleteSecretParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSecretSecretsDelete(
          deleteSecretParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SecretsApi.deleteSecretSecretsDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Secret Keys
     * @param {ListSecretParams} listSecretParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listSecretKeysSecretsPut(
      listSecretParams: ListSecretParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ListSecretResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listSecretKeysSecretsPut(
          listSecretParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SecretsApi.listSecretKeysSecretsPut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Set Secret
     * @param {SetSecretParams} setSecretParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setSecretSecretsPost(
      setSecretParams: SetSecretParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setSecretSecretsPost(
          setSecretParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SecretsApi.setSecretSecretsPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SecretsApi - factory interface
 * @export
 */
export const SecretsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SecretsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Secret
     * @param {SecretsApiDeleteSecretSecretsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSecretSecretsDelete(
      requestParameters: SecretsApiDeleteSecretSecretsDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteSecretSecretsDelete(
          requestParameters.deleteSecretParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Secret Keys
     * @param {SecretsApiListSecretKeysSecretsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSecretKeysSecretsPut(
      requestParameters: SecretsApiListSecretKeysSecretsPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ListSecretResponse> {
      return localVarFp
        .listSecretKeysSecretsPut(requestParameters.listSecretParams, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Set Secret
     * @param {SecretsApiSetSecretSecretsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSecretSecretsPost(
      requestParameters: SecretsApiSetSecretSecretsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .setSecretSecretsPost(requestParameters.setSecretParams, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteSecretSecretsDelete operation in SecretsApi.
 * @export
 * @interface SecretsApiDeleteSecretSecretsDeleteRequest
 */
export interface SecretsApiDeleteSecretSecretsDeleteRequest {
  /**
   *
   * @type {DeleteSecretParams}
   * @memberof SecretsApiDeleteSecretSecretsDelete
   */
  readonly deleteSecretParams: DeleteSecretParams;
}

/**
 * Request parameters for listSecretKeysSecretsPut operation in SecretsApi.
 * @export
 * @interface SecretsApiListSecretKeysSecretsPutRequest
 */
export interface SecretsApiListSecretKeysSecretsPutRequest {
  /**
   *
   * @type {ListSecretParams}
   * @memberof SecretsApiListSecretKeysSecretsPut
   */
  readonly listSecretParams: ListSecretParams;
}

/**
 * Request parameters for setSecretSecretsPost operation in SecretsApi.
 * @export
 * @interface SecretsApiSetSecretSecretsPostRequest
 */
export interface SecretsApiSetSecretSecretsPostRequest {
  /**
   *
   * @type {SetSecretParams}
   * @memberof SecretsApiSetSecretSecretsPost
   */
  readonly setSecretParams: SetSecretParams;
}

/**
 * SecretsApi - object-oriented interface
 * @export
 * @class SecretsApi
 * @extends {BaseAPI}
 */
export class SecretsApi extends BaseAPI {
  /**
   *
   * @summary Delete Secret
   * @param {SecretsApiDeleteSecretSecretsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecretsApi
   */
  public deleteSecretSecretsDelete(
    requestParameters: SecretsApiDeleteSecretSecretsDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SecretsApiFp(this.configuration)
      .deleteSecretSecretsDelete(requestParameters.deleteSecretParams, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Secret Keys
   * @param {SecretsApiListSecretKeysSecretsPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecretsApi
   */
  public listSecretKeysSecretsPut(
    requestParameters: SecretsApiListSecretKeysSecretsPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SecretsApiFp(this.configuration)
      .listSecretKeysSecretsPut(requestParameters.listSecretParams, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Secret
   * @param {SecretsApiSetSecretSecretsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SecretsApi
   */
  public setSecretSecretsPost(
    requestParameters: SecretsApiSetSecretSecretsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SecretsApiFp(this.configuration)
      .setSecretSecretsPost(requestParameters.setSecretParams, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SlicesApi - axios parameter creator
 * @export
 */
export const SlicesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Xuids To Slice
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {ModifySliceRequest} modifySliceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost: async (
      datasetUid: number,
      sliceUid: number,
      modifySliceRequest: ModifySliceRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'sliceUid' is not null or undefined
      assertParamExists(
        'addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost',
        'sliceUid',
        sliceUid,
      );
      // verify required parameter 'modifySliceRequest' is not null or undefined
      assertParamExists(
        'addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost',
        'modifySliceRequest',
        modifySliceRequest,
      );
      const localVarPath = `/dataset/{dataset_uid}/add-xuids/{slice_uid}`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'slice_uid'}}`, encodeURIComponent(String(sliceUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        modifySliceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Apply Slice
     * @param {number} datasetUid
     * @param {SliceApplyParams} sliceApplyParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applySliceDatasetDatasetUidApplyPost: async (
      datasetUid: number,
      sliceApplyParams: SliceApplyParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'applySliceDatasetDatasetUidApplyPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'sliceApplyParams' is not null or undefined
      assertParamExists(
        'applySliceDatasetDatasetUidApplyPost',
        'sliceApplyParams',
        sliceApplyParams,
      );
      const localVarPath = `/dataset/{dataset_uid}/apply`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sliceApplyParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Slice
     * @param {number} datasetUid
     * @param {SliceCreationRequest} sliceCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSliceDatasetDatasetUidSlicePost: async (
      datasetUid: number,
      sliceCreationRequest: SliceCreationRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'createSliceDatasetDatasetUidSlicePost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'sliceCreationRequest' is not null or undefined
      assertParamExists(
        'createSliceDatasetDatasetUidSlicePost',
        'sliceCreationRequest',
        sliceCreationRequest,
      );
      const localVarPath = `/dataset/{dataset_uid}/slice`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sliceCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Slice
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSliceDatasetDatasetUidSliceSliceUidDelete: async (
      datasetUid: number,
      sliceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'deleteSliceDatasetDatasetUidSliceSliceUidDelete',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'sliceUid' is not null or undefined
      assertParamExists(
        'deleteSliceDatasetDatasetUidSliceSliceUidDelete',
        'sliceUid',
        sliceUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/slice/{slice_uid}`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'slice_uid'}}`, encodeURIComponent(String(sliceUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Slice Overrides
     * @param {number} datasetUid
     * @param {Array<SliceOverrideDelete>} sliceOverrideDelete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSliceOverridesDatasetDatasetUidSliceOverridesDelete: async (
      datasetUid: number,
      sliceOverrideDelete: Array<SliceOverrideDelete>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'deleteSliceOverridesDatasetDatasetUidSliceOverridesDelete',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'sliceOverrideDelete' is not null or undefined
      assertParamExists(
        'deleteSliceOverridesDatasetDatasetUidSliceOverridesDelete',
        'sliceOverrideDelete',
        sliceOverrideDelete,
      );
      const localVarPath = `/dataset/{dataset_uid}/slice-overrides`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sliceOverrideDelete,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Slice
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSliceDatasetDatasetUidSliceSliceUidGet: async (
      datasetUid: number,
      sliceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getSliceDatasetDatasetUidSliceSliceUidGet',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'sliceUid' is not null or undefined
      assertParamExists(
        'getSliceDatasetDatasetUidSliceSliceUidGet',
        'sliceUid',
        sliceUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/slice/{slice_uid}`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'slice_uid'}}`, encodeURIComponent(String(sliceUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Slice Membership
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet: async (
      datasetUid: number,
      sliceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'sliceUid' is not null or undefined
      assertParamExists(
        'getSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet',
        'sliceUid',
        sliceUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/slice/{slice_uid}/membership`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'slice_uid'}}`, encodeURIComponent(String(sliceUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Slice Membership New
     * @param {number} datasetUid
     * @param {GetSliceMembershipRequest} getSliceMembershipRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost:
      async (
        datasetUid: number,
        getSliceMembershipRequest: GetSliceMembershipRequest,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'datasetUid' is not null or undefined
        assertParamExists(
          'getSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost',
          'datasetUid',
          datasetUid,
        );
        // verify required parameter 'getSliceMembershipRequest' is not null or undefined
        assertParamExists(
          'getSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost',
          'getSliceMembershipRequest',
          getSliceMembershipRequest,
        );
        const localVarPath =
          `/dataset/{dataset_uid}/get-slice-membership-and-overrides`.replace(
            `{${'dataset_uid'}}`,
            encodeURIComponent(String(datasetUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          getSliceMembershipRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Slice Xuids
     * @param {number} datasetUid
     * @param {GetSliceMapRequest} getSliceMapRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost: async (
      datasetUid: number,
      getSliceMapRequest: GetSliceMapRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'getSliceMapRequest' is not null or undefined
      assertParamExists(
        'getSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost',
        'getSliceMapRequest',
        getSliceMapRequest,
      );
      const localVarPath =
        `/dataset/{dataset_uid}/get-xuid-to-slice-map`.replace(
          `{${'dataset_uid'}}`,
          encodeURIComponent(String(datasetUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getSliceMapRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Slices
     * @param {number} datasetUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSlicesDatasetDatasetUidSlicesGet: async (
      datasetUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'getSlicesDatasetDatasetUidSlicesGet',
        'datasetUid',
        datasetUid,
      );
      const localVarPath = `/dataset/{dataset_uid}/slices`.replace(
        `{${'dataset_uid'}}`,
        encodeURIComponent(String(datasetUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove Xuids From Slice
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {ModifySliceRequest} modifySliceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost: async (
      datasetUid: number,
      sliceUid: number,
      modifySliceRequest: ModifySliceRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'sliceUid' is not null or undefined
      assertParamExists(
        'removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost',
        'sliceUid',
        sliceUid,
      );
      // verify required parameter 'modifySliceRequest' is not null or undefined
      assertParamExists(
        'removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost',
        'modifySliceRequest',
        modifySliceRequest,
      );
      const localVarPath = `/dataset/{dataset_uid}/remove-xuids/{slice_uid}`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'slice_uid'}}`, encodeURIComponent(String(sliceUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        modifySliceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Slice Membership
     * @param {number} datasetUid
     * @param {string} xUid
     * @param {number} sliceUid
     * @param {Membership} membership
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut:
      async (
        datasetUid: number,
        xUid: string,
        sliceUid: number,
        membership: Membership,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'datasetUid' is not null or undefined
        assertParamExists(
          'setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut',
          'datasetUid',
          datasetUid,
        );
        // verify required parameter 'xUid' is not null or undefined
        assertParamExists(
          'setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut',
          'xUid',
          xUid,
        );
        // verify required parameter 'sliceUid' is not null or undefined
        assertParamExists(
          'setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut',
          'sliceUid',
          sliceUid,
        );
        // verify required parameter 'membership' is not null or undefined
        assertParamExists(
          'setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut',
          'membership',
          membership,
        );
        const localVarPath =
          `/dataset/{dataset_uid}/x-uid/{x_uid}/slice-uid/{slice_uid}/slice-membership`
            .replace(
              `{${'dataset_uid'}}`,
              encodeURIComponent(String(datasetUid)),
            )
            .replace(`{${'x_uid'}}`, encodeURIComponent(String(xUid)))
            .replace(`{${'slice_uid'}}`, encodeURIComponent(String(sliceUid)));
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PUT',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (membership !== undefined) {
          localVarQueryParameter['membership'] = membership;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Update Slice
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {UpdateSliceRequest} updateSliceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSliceDatasetDatasetUidSliceSliceUidPut: async (
      datasetUid: number,
      sliceUid: number,
      updateSliceRequest: UpdateSliceRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'datasetUid' is not null or undefined
      assertParamExists(
        'updateSliceDatasetDatasetUidSliceSliceUidPut',
        'datasetUid',
        datasetUid,
      );
      // verify required parameter 'sliceUid' is not null or undefined
      assertParamExists(
        'updateSliceDatasetDatasetUidSliceSliceUidPut',
        'sliceUid',
        sliceUid,
      );
      // verify required parameter 'updateSliceRequest' is not null or undefined
      assertParamExists(
        'updateSliceDatasetDatasetUidSliceSliceUidPut',
        'updateSliceRequest',
        updateSliceRequest,
      );
      const localVarPath = `/dataset/{dataset_uid}/slice/{slice_uid}`
        .replace(`{${'dataset_uid'}}`, encodeURIComponent(String(datasetUid)))
        .replace(`{${'slice_uid'}}`, encodeURIComponent(String(sliceUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSliceRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SlicesApi - functional programming interface
 * @export
 */
export const SlicesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SlicesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Xuids To Slice
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {ModifySliceRequest} modifySliceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost(
      datasetUid: number,
      sliceUid: number,
      modifySliceRequest: ModifySliceRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost(
          datasetUid,
          sliceUid,
          modifySliceRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SlicesApi.addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Apply Slice
     * @param {number} datasetUid
     * @param {SliceApplyParams} sliceApplyParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applySliceDatasetDatasetUidApplyPost(
      datasetUid: number,
      sliceApplyParams: SliceApplyParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applySliceDatasetDatasetUidApplyPost(
          datasetUid,
          sliceApplyParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SlicesApi.applySliceDatasetDatasetUidApplyPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Create Slice
     * @param {number} datasetUid
     * @param {SliceCreationRequest} sliceCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSliceDatasetDatasetUidSlicePost(
      datasetUid: number,
      sliceCreationRequest: SliceCreationRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createSliceDatasetDatasetUidSlicePost(
          datasetUid,
          sliceCreationRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SlicesApi.createSliceDatasetDatasetUidSlicePost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Slice
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSliceDatasetDatasetUidSliceSliceUidDelete(
      datasetUid: number,
      sliceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSliceDatasetDatasetUidSliceSliceUidDelete(
          datasetUid,
          sliceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SlicesApi.deleteSliceDatasetDatasetUidSliceSliceUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Slice Overrides
     * @param {number} datasetUid
     * @param {Array<SliceOverrideDelete>} sliceOverrideDelete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSliceOverridesDatasetDatasetUidSliceOverridesDelete(
      datasetUid: number,
      sliceOverrideDelete: Array<SliceOverrideDelete>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSliceOverridesDatasetDatasetUidSliceOverridesDelete(
          datasetUid,
          sliceOverrideDelete,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SlicesApi.deleteSliceOverridesDatasetDatasetUidSliceOverridesDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Slice
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSliceDatasetDatasetUidSliceSliceUidGet(
      datasetUid: number,
      sliceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SliceObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSliceDatasetDatasetUidSliceSliceUidGet(
          datasetUid,
          sliceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SlicesApi.getSliceDatasetDatasetUidSliceSliceUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Slice Membership
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet(
      datasetUid: number,
      sliceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet(
          datasetUid,
          sliceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SlicesApi.getSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Slice Membership New
     * @param {number} datasetUid
     * @param {GetSliceMembershipRequest} getSliceMembershipRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost(
      datasetUid: number,
      getSliceMembershipRequest: GetSliceMembershipRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{
        [key: string]: { [key: string]: SliceMembershipInfo };
      }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost(
          datasetUid,
          getSliceMembershipRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SlicesApi.getSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Slice Xuids
     * @param {number} datasetUid
     * @param {GetSliceMapRequest} getSliceMapRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost(
      datasetUid: number,
      getSliceMapRequest: GetSliceMapRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: any }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost(
          datasetUid,
          getSliceMapRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SlicesApi.getSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Slices
     * @param {number} datasetUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSlicesDatasetDatasetUidSlicesGet(
      datasetUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<SliceObject>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSlicesDatasetDatasetUidSlicesGet(
          datasetUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SlicesApi.getSlicesDatasetDatasetUidSlicesGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Remove Xuids From Slice
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {ModifySliceRequest} modifySliceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost(
      datasetUid: number,
      sliceUid: number,
      modifySliceRequest: ModifySliceRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost(
          datasetUid,
          sliceUid,
          modifySliceRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SlicesApi.removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Set Slice Membership
     * @param {number} datasetUid
     * @param {string} xUid
     * @param {number} sliceUid
     * @param {Membership} membership
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut(
      datasetUid: number,
      xUid: string,
      sliceUid: number,
      membership: Membership,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut(
          datasetUid,
          xUid,
          sliceUid,
          membership,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SlicesApi.setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Slice
     * @param {number} datasetUid
     * @param {number} sliceUid
     * @param {UpdateSliceRequest} updateSliceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSliceDatasetDatasetUidSliceSliceUidPut(
      datasetUid: number,
      sliceUid: number,
      updateSliceRequest: UpdateSliceRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SliceObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateSliceDatasetDatasetUidSliceSliceUidPut(
          datasetUid,
          sliceUid,
          updateSliceRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SlicesApi.updateSliceDatasetDatasetUidSliceSliceUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SlicesApi - factory interface
 * @export
 */
export const SlicesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SlicesApiFp(configuration);
  return {
    /**
     *
     * @summary Add Xuids To Slice
     * @param {SlicesApiAddXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost(
      requestParameters: SlicesApiAddXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost(
          requestParameters.datasetUid,
          requestParameters.sliceUid,
          requestParameters.modifySliceRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Apply Slice
     * @param {SlicesApiApplySliceDatasetDatasetUidApplyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applySliceDatasetDatasetUidApplyPost(
      requestParameters: SlicesApiApplySliceDatasetDatasetUidApplyPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .applySliceDatasetDatasetUidApplyPost(
          requestParameters.datasetUid,
          requestParameters.sliceApplyParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create Slice
     * @param {SlicesApiCreateSliceDatasetDatasetUidSlicePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSliceDatasetDatasetUidSlicePost(
      requestParameters: SlicesApiCreateSliceDatasetDatasetUidSlicePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<number> {
      return localVarFp
        .createSliceDatasetDatasetUidSlicePost(
          requestParameters.datasetUid,
          requestParameters.sliceCreationRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Slice
     * @param {SlicesApiDeleteSliceDatasetDatasetUidSliceSliceUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSliceDatasetDatasetUidSliceSliceUidDelete(
      requestParameters: SlicesApiDeleteSliceDatasetDatasetUidSliceSliceUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteSliceDatasetDatasetUidSliceSliceUidDelete(
          requestParameters.datasetUid,
          requestParameters.sliceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Slice Overrides
     * @param {SlicesApiDeleteSliceOverridesDatasetDatasetUidSliceOverridesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSliceOverridesDatasetDatasetUidSliceOverridesDelete(
      requestParameters: SlicesApiDeleteSliceOverridesDatasetDatasetUidSliceOverridesDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteSliceOverridesDatasetDatasetUidSliceOverridesDelete(
          requestParameters.datasetUid,
          requestParameters.sliceOverrideDelete,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Slice
     * @param {SlicesApiGetSliceDatasetDatasetUidSliceSliceUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSliceDatasetDatasetUidSliceSliceUidGet(
      requestParameters: SlicesApiGetSliceDatasetDatasetUidSliceSliceUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SliceObject> {
      return localVarFp
        .getSliceDatasetDatasetUidSliceSliceUidGet(
          requestParameters.datasetUid,
          requestParameters.sliceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Slice Membership
     * @param {SlicesApiGetSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet(
      requestParameters: SlicesApiGetSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .getSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet(
          requestParameters.datasetUid,
          requestParameters.sliceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Slice Membership New
     * @param {SlicesApiGetSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost(
      requestParameters: SlicesApiGetSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: { [key: string]: SliceMembershipInfo } }> {
      return localVarFp
        .getSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost(
          requestParameters.datasetUid,
          requestParameters.getSliceMembershipRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Slice Xuids
     * @param {SlicesApiGetSliceXuidsDatasetDatasetUidGetXuidToSliceMapPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost(
      requestParameters: SlicesApiGetSliceXuidsDatasetDatasetUidGetXuidToSliceMapPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: any }> {
      return localVarFp
        .getSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost(
          requestParameters.datasetUid,
          requestParameters.getSliceMapRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Slices
     * @param {SlicesApiGetSlicesDatasetDatasetUidSlicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSlicesDatasetDatasetUidSlicesGet(
      requestParameters: SlicesApiGetSlicesDatasetDatasetUidSlicesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<SliceObject>> {
      return localVarFp
        .getSlicesDatasetDatasetUidSlicesGet(
          requestParameters.datasetUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Remove Xuids From Slice
     * @param {SlicesApiRemoveXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost(
      requestParameters: SlicesApiRemoveXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost(
          requestParameters.datasetUid,
          requestParameters.sliceUid,
          requestParameters.modifySliceRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Set Slice Membership
     * @param {SlicesApiSetSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut(
      requestParameters: SlicesApiSetSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut(
          requestParameters.datasetUid,
          requestParameters.xUid,
          requestParameters.sliceUid,
          requestParameters.membership,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Slice
     * @param {SlicesApiUpdateSliceDatasetDatasetUidSliceSliceUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSliceDatasetDatasetUidSliceSliceUidPut(
      requestParameters: SlicesApiUpdateSliceDatasetDatasetUidSliceSliceUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SliceObject> {
      return localVarFp
        .updateSliceDatasetDatasetUidSliceSliceUidPut(
          requestParameters.datasetUid,
          requestParameters.sliceUid,
          requestParameters.updateSliceRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost operation in SlicesApi.
 * @export
 * @interface SlicesApiAddXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPostRequest
 */
export interface SlicesApiAddXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPostRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiAddXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof SlicesApiAddXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost
   */
  readonly sliceUid: number;

  /**
   *
   * @type {ModifySliceRequest}
   * @memberof SlicesApiAddXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost
   */
  readonly modifySliceRequest: ModifySliceRequest;
}

/**
 * Request parameters for applySliceDatasetDatasetUidApplyPost operation in SlicesApi.
 * @export
 * @interface SlicesApiApplySliceDatasetDatasetUidApplyPostRequest
 */
export interface SlicesApiApplySliceDatasetDatasetUidApplyPostRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiApplySliceDatasetDatasetUidApplyPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {SliceApplyParams}
   * @memberof SlicesApiApplySliceDatasetDatasetUidApplyPost
   */
  readonly sliceApplyParams: SliceApplyParams;
}

/**
 * Request parameters for createSliceDatasetDatasetUidSlicePost operation in SlicesApi.
 * @export
 * @interface SlicesApiCreateSliceDatasetDatasetUidSlicePostRequest
 */
export interface SlicesApiCreateSliceDatasetDatasetUidSlicePostRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiCreateSliceDatasetDatasetUidSlicePost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {SliceCreationRequest}
   * @memberof SlicesApiCreateSliceDatasetDatasetUidSlicePost
   */
  readonly sliceCreationRequest: SliceCreationRequest;
}

/**
 * Request parameters for deleteSliceDatasetDatasetUidSliceSliceUidDelete operation in SlicesApi.
 * @export
 * @interface SlicesApiDeleteSliceDatasetDatasetUidSliceSliceUidDeleteRequest
 */
export interface SlicesApiDeleteSliceDatasetDatasetUidSliceSliceUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiDeleteSliceDatasetDatasetUidSliceSliceUidDelete
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof SlicesApiDeleteSliceDatasetDatasetUidSliceSliceUidDelete
   */
  readonly sliceUid: number;
}

/**
 * Request parameters for deleteSliceOverridesDatasetDatasetUidSliceOverridesDelete operation in SlicesApi.
 * @export
 * @interface SlicesApiDeleteSliceOverridesDatasetDatasetUidSliceOverridesDeleteRequest
 */
export interface SlicesApiDeleteSliceOverridesDatasetDatasetUidSliceOverridesDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiDeleteSliceOverridesDatasetDatasetUidSliceOverridesDelete
   */
  readonly datasetUid: number;

  /**
   *
   * @type {Array<SliceOverrideDelete>}
   * @memberof SlicesApiDeleteSliceOverridesDatasetDatasetUidSliceOverridesDelete
   */
  readonly sliceOverrideDelete: Array<SliceOverrideDelete>;
}

/**
 * Request parameters for getSliceDatasetDatasetUidSliceSliceUidGet operation in SlicesApi.
 * @export
 * @interface SlicesApiGetSliceDatasetDatasetUidSliceSliceUidGetRequest
 */
export interface SlicesApiGetSliceDatasetDatasetUidSliceSliceUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiGetSliceDatasetDatasetUidSliceSliceUidGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof SlicesApiGetSliceDatasetDatasetUidSliceSliceUidGet
   */
  readonly sliceUid: number;
}

/**
 * Request parameters for getSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet operation in SlicesApi.
 * @export
 * @interface SlicesApiGetSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGetRequest
 */
export interface SlicesApiGetSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGetRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiGetSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof SlicesApiGetSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet
   */
  readonly sliceUid: number;
}

/**
 * Request parameters for getSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost operation in SlicesApi.
 * @export
 * @interface SlicesApiGetSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPostRequest
 */
export interface SlicesApiGetSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPostRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiGetSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {GetSliceMembershipRequest}
   * @memberof SlicesApiGetSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost
   */
  readonly getSliceMembershipRequest: GetSliceMembershipRequest;
}

/**
 * Request parameters for getSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost operation in SlicesApi.
 * @export
 * @interface SlicesApiGetSliceXuidsDatasetDatasetUidGetXuidToSliceMapPostRequest
 */
export interface SlicesApiGetSliceXuidsDatasetDatasetUidGetXuidToSliceMapPostRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiGetSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {GetSliceMapRequest}
   * @memberof SlicesApiGetSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost
   */
  readonly getSliceMapRequest: GetSliceMapRequest;
}

/**
 * Request parameters for getSlicesDatasetDatasetUidSlicesGet operation in SlicesApi.
 * @export
 * @interface SlicesApiGetSlicesDatasetDatasetUidSlicesGetRequest
 */
export interface SlicesApiGetSlicesDatasetDatasetUidSlicesGetRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiGetSlicesDatasetDatasetUidSlicesGet
   */
  readonly datasetUid: number;
}

/**
 * Request parameters for removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost operation in SlicesApi.
 * @export
 * @interface SlicesApiRemoveXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPostRequest
 */
export interface SlicesApiRemoveXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPostRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiRemoveXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof SlicesApiRemoveXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost
   */
  readonly sliceUid: number;

  /**
   *
   * @type {ModifySliceRequest}
   * @memberof SlicesApiRemoveXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost
   */
  readonly modifySliceRequest: ModifySliceRequest;
}

/**
 * Request parameters for setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut operation in SlicesApi.
 * @export
 * @interface SlicesApiSetSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPutRequest
 */
export interface SlicesApiSetSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPutRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiSetSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut
   */
  readonly datasetUid: number;

  /**
   *
   * @type {string}
   * @memberof SlicesApiSetSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut
   */
  readonly xUid: string;

  /**
   *
   * @type {number}
   * @memberof SlicesApiSetSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut
   */
  readonly sliceUid: number;

  /**
   *
   * @type {Membership}
   * @memberof SlicesApiSetSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut
   */
  readonly membership: Membership;
}

/**
 * Request parameters for updateSliceDatasetDatasetUidSliceSliceUidPut operation in SlicesApi.
 * @export
 * @interface SlicesApiUpdateSliceDatasetDatasetUidSliceSliceUidPutRequest
 */
export interface SlicesApiUpdateSliceDatasetDatasetUidSliceSliceUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof SlicesApiUpdateSliceDatasetDatasetUidSliceSliceUidPut
   */
  readonly datasetUid: number;

  /**
   *
   * @type {number}
   * @memberof SlicesApiUpdateSliceDatasetDatasetUidSliceSliceUidPut
   */
  readonly sliceUid: number;

  /**
   *
   * @type {UpdateSliceRequest}
   * @memberof SlicesApiUpdateSliceDatasetDatasetUidSliceSliceUidPut
   */
  readonly updateSliceRequest: UpdateSliceRequest;
}

/**
 * SlicesApi - object-oriented interface
 * @export
 * @class SlicesApi
 * @extends {BaseAPI}
 */
export class SlicesApi extends BaseAPI {
  /**
   *
   * @summary Add Xuids To Slice
   * @param {SlicesApiAddXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost(
    requestParameters: SlicesApiAddXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .addXuidsToSliceDatasetDatasetUidAddXuidsSliceUidPost(
        requestParameters.datasetUid,
        requestParameters.sliceUid,
        requestParameters.modifySliceRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Apply Slice
   * @param {SlicesApiApplySliceDatasetDatasetUidApplyPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public applySliceDatasetDatasetUidApplyPost(
    requestParameters: SlicesApiApplySliceDatasetDatasetUidApplyPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .applySliceDatasetDatasetUidApplyPost(
        requestParameters.datasetUid,
        requestParameters.sliceApplyParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Slice
   * @param {SlicesApiCreateSliceDatasetDatasetUidSlicePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public createSliceDatasetDatasetUidSlicePost(
    requestParameters: SlicesApiCreateSliceDatasetDatasetUidSlicePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .createSliceDatasetDatasetUidSlicePost(
        requestParameters.datasetUid,
        requestParameters.sliceCreationRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Slice
   * @param {SlicesApiDeleteSliceDatasetDatasetUidSliceSliceUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public deleteSliceDatasetDatasetUidSliceSliceUidDelete(
    requestParameters: SlicesApiDeleteSliceDatasetDatasetUidSliceSliceUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .deleteSliceDatasetDatasetUidSliceSliceUidDelete(
        requestParameters.datasetUid,
        requestParameters.sliceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Slice Overrides
   * @param {SlicesApiDeleteSliceOverridesDatasetDatasetUidSliceOverridesDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public deleteSliceOverridesDatasetDatasetUidSliceOverridesDelete(
    requestParameters: SlicesApiDeleteSliceOverridesDatasetDatasetUidSliceOverridesDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .deleteSliceOverridesDatasetDatasetUidSliceOverridesDelete(
        requestParameters.datasetUid,
        requestParameters.sliceOverrideDelete,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Slice
   * @param {SlicesApiGetSliceDatasetDatasetUidSliceSliceUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public getSliceDatasetDatasetUidSliceSliceUidGet(
    requestParameters: SlicesApiGetSliceDatasetDatasetUidSliceSliceUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .getSliceDatasetDatasetUidSliceSliceUidGet(
        requestParameters.datasetUid,
        requestParameters.sliceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Slice Membership
   * @param {SlicesApiGetSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public getSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet(
    requestParameters: SlicesApiGetSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .getSliceMembershipDatasetDatasetUidSliceSliceUidMembershipGet(
        requestParameters.datasetUid,
        requestParameters.sliceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Slice Membership New
   * @param {SlicesApiGetSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public getSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost(
    requestParameters: SlicesApiGetSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .getSliceMembershipNewDatasetDatasetUidGetSliceMembershipAndOverridesPost(
        requestParameters.datasetUid,
        requestParameters.getSliceMembershipRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Slice Xuids
   * @param {SlicesApiGetSliceXuidsDatasetDatasetUidGetXuidToSliceMapPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public getSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost(
    requestParameters: SlicesApiGetSliceXuidsDatasetDatasetUidGetXuidToSliceMapPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .getSliceXuidsDatasetDatasetUidGetXuidToSliceMapPost(
        requestParameters.datasetUid,
        requestParameters.getSliceMapRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Slices
   * @param {SlicesApiGetSlicesDatasetDatasetUidSlicesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public getSlicesDatasetDatasetUidSlicesGet(
    requestParameters: SlicesApiGetSlicesDatasetDatasetUidSlicesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .getSlicesDatasetDatasetUidSlicesGet(
        requestParameters.datasetUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Remove Xuids From Slice
   * @param {SlicesApiRemoveXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost(
    requestParameters: SlicesApiRemoveXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .removeXuidsFromSliceDatasetDatasetUidRemoveXuidsSliceUidPost(
        requestParameters.datasetUid,
        requestParameters.sliceUid,
        requestParameters.modifySliceRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Slice Membership
   * @param {SlicesApiSetSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut(
    requestParameters: SlicesApiSetSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .setSliceMembershipDatasetDatasetUidXUidXUidSliceUidSliceUidSliceMembershipPut(
        requestParameters.datasetUid,
        requestParameters.xUid,
        requestParameters.sliceUid,
        requestParameters.membership,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Slice
   * @param {SlicesApiUpdateSliceDatasetDatasetUidSliceSliceUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlicesApi
   */
  public updateSliceDatasetDatasetUidSliceSliceUidPut(
    requestParameters: SlicesApiUpdateSliceDatasetDatasetUidSliceSliceUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SlicesApiFp(this.configuration)
      .updateSliceDatasetDatasetUidSliceSliceUidPut(
        requestParameters.datasetUid,
        requestParameters.sliceUid,
        requestParameters.updateSliceRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SourcesApi - axios parameter creator
 * @export
 */
export const SourcesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Source
     * @param {AddSourceParams} addSourceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSourceSourcesPost: async (
      addSourceParams: AddSourceParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addSourceParams' is not null or undefined
      assertParamExists(
        'addSourceSourcesPost',
        'addSourceParams',
        addSourceParams,
      );
      const localVarPath = `/sources`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addSourceParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Source
     * @param {number} sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSourceSourcesSourceUidDelete: async (
      sourceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sourceUid' is not null or undefined
      assertParamExists(
        'deleteSourceSourcesSourceUidDelete',
        'sourceUid',
        sourceUid,
      );
      const localVarPath = `/sources/{source_uid}`.replace(
        `{${'source_uid'}}`,
        encodeURIComponent(String(sourceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Sources
     * @param {number} [batchUid]
     * @param {number} [nodeUid]
     * @param {number} [workspaceUid]
     * @param {string} [sourceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSourcesSourcesGet: async (
      batchUid?: number,
      nodeUid?: number,
      workspaceUid?: number,
      sourceType?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sources`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (batchUid !== undefined) {
        localVarQueryParameter['batch_uid'] = batchUid;
      }

      if (nodeUid !== undefined) {
        localVarQueryParameter['node_uid'] = nodeUid;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      if (sourceType !== undefined) {
        localVarQueryParameter['source_type'] = sourceType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Source
     * @param {number} sourceUid
     * @param {UpdateSourceParams} updateSourceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSourceSourcesSourceUidPut: async (
      sourceUid: number,
      updateSourceParams: UpdateSourceParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sourceUid' is not null or undefined
      assertParamExists(
        'updateSourceSourcesSourceUidPut',
        'sourceUid',
        sourceUid,
      );
      // verify required parameter 'updateSourceParams' is not null or undefined
      assertParamExists(
        'updateSourceSourcesSourceUidPut',
        'updateSourceParams',
        updateSourceParams,
      );
      const localVarPath = `/sources/{source_uid}`.replace(
        `{${'source_uid'}}`,
        encodeURIComponent(String(sourceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSourceParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SourcesApi - functional programming interface
 * @export
 */
export const SourcesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SourcesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Source
     * @param {AddSourceParams} addSourceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addSourceSourcesPost(
      addSourceParams: AddSourceParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AddSourceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addSourceSourcesPost(
          addSourceParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SourcesApi.addSourceSourcesPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Source
     * @param {number} sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSourceSourcesSourceUidDelete(
      sourceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSourceSourcesSourceUidDelete(
          sourceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SourcesApi.deleteSourceSourcesSourceUidDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Sources
     * @param {number} [batchUid]
     * @param {number} [nodeUid]
     * @param {number} [workspaceUid]
     * @param {string} [sourceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSourcesSourcesGet(
      batchUid?: number,
      nodeUid?: number,
      workspaceUid?: number,
      sourceType?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSourcesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSourcesSourcesGet(
          batchUid,
          nodeUid,
          workspaceUid,
          sourceType,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SourcesApi.getSourcesSourcesGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Source
     * @param {number} sourceUid
     * @param {UpdateSourceParams} updateSourceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSourceSourcesSourceUidPut(
      sourceUid: number,
      updateSourceParams: UpdateSourceParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Source>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateSourceSourcesSourceUidPut(
          sourceUid,
          updateSourceParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SourcesApi.updateSourceSourcesSourceUidPut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SourcesApi - factory interface
 * @export
 */
export const SourcesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SourcesApiFp(configuration);
  return {
    /**
     *
     * @summary Add Source
     * @param {SourcesApiAddSourceSourcesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSourceSourcesPost(
      requestParameters: SourcesApiAddSourceSourcesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AddSourceResponse> {
      return localVarFp
        .addSourceSourcesPost(requestParameters.addSourceParams, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Source
     * @param {SourcesApiDeleteSourceSourcesSourceUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSourceSourcesSourceUidDelete(
      requestParameters: SourcesApiDeleteSourceSourcesSourceUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteSourceSourcesSourceUidDelete(
          requestParameters.sourceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Sources
     * @param {SourcesApiGetSourcesSourcesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSourcesSourcesGet(
      requestParameters: SourcesApiGetSourcesSourcesGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetSourcesResponse> {
      return localVarFp
        .getSourcesSourcesGet(
          requestParameters.batchUid,
          requestParameters.nodeUid,
          requestParameters.workspaceUid,
          requestParameters.sourceType,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Source
     * @param {SourcesApiUpdateSourceSourcesSourceUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSourceSourcesSourceUidPut(
      requestParameters: SourcesApiUpdateSourceSourcesSourceUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Source> {
      return localVarFp
        .updateSourceSourcesSourceUidPut(
          requestParameters.sourceUid,
          requestParameters.updateSourceParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addSourceSourcesPost operation in SourcesApi.
 * @export
 * @interface SourcesApiAddSourceSourcesPostRequest
 */
export interface SourcesApiAddSourceSourcesPostRequest {
  /**
   *
   * @type {AddSourceParams}
   * @memberof SourcesApiAddSourceSourcesPost
   */
  readonly addSourceParams: AddSourceParams;
}

/**
 * Request parameters for deleteSourceSourcesSourceUidDelete operation in SourcesApi.
 * @export
 * @interface SourcesApiDeleteSourceSourcesSourceUidDeleteRequest
 */
export interface SourcesApiDeleteSourceSourcesSourceUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof SourcesApiDeleteSourceSourcesSourceUidDelete
   */
  readonly sourceUid: number;
}

/**
 * Request parameters for getSourcesSourcesGet operation in SourcesApi.
 * @export
 * @interface SourcesApiGetSourcesSourcesGetRequest
 */
export interface SourcesApiGetSourcesSourcesGetRequest {
  /**
   *
   * @type {number}
   * @memberof SourcesApiGetSourcesSourcesGet
   */
  readonly batchUid?: number;

  /**
   *
   * @type {number}
   * @memberof SourcesApiGetSourcesSourcesGet
   */
  readonly nodeUid?: number;

  /**
   *
   * @type {number}
   * @memberof SourcesApiGetSourcesSourcesGet
   */
  readonly workspaceUid?: number;

  /**
   *
   * @type {string}
   * @memberof SourcesApiGetSourcesSourcesGet
   */
  readonly sourceType?: string;
}

/**
 * Request parameters for updateSourceSourcesSourceUidPut operation in SourcesApi.
 * @export
 * @interface SourcesApiUpdateSourceSourcesSourceUidPutRequest
 */
export interface SourcesApiUpdateSourceSourcesSourceUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof SourcesApiUpdateSourceSourcesSourceUidPut
   */
  readonly sourceUid: number;

  /**
   *
   * @type {UpdateSourceParams}
   * @memberof SourcesApiUpdateSourceSourcesSourceUidPut
   */
  readonly updateSourceParams: UpdateSourceParams;
}

/**
 * SourcesApi - object-oriented interface
 * @export
 * @class SourcesApi
 * @extends {BaseAPI}
 */
export class SourcesApi extends BaseAPI {
  /**
   *
   * @summary Add Source
   * @param {SourcesApiAddSourceSourcesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SourcesApi
   */
  public addSourceSourcesPost(
    requestParameters: SourcesApiAddSourceSourcesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SourcesApiFp(this.configuration)
      .addSourceSourcesPost(requestParameters.addSourceParams, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Source
   * @param {SourcesApiDeleteSourceSourcesSourceUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SourcesApi
   */
  public deleteSourceSourcesSourceUidDelete(
    requestParameters: SourcesApiDeleteSourceSourcesSourceUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SourcesApiFp(this.configuration)
      .deleteSourceSourcesSourceUidDelete(requestParameters.sourceUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Sources
   * @param {SourcesApiGetSourcesSourcesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SourcesApi
   */
  public getSourcesSourcesGet(
    requestParameters: SourcesApiGetSourcesSourcesGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return SourcesApiFp(this.configuration)
      .getSourcesSourcesGet(
        requestParameters.batchUid,
        requestParameters.nodeUid,
        requestParameters.workspaceUid,
        requestParameters.sourceType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Source
   * @param {SourcesApiUpdateSourceSourcesSourceUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SourcesApi
   */
  public updateSourceSourcesSourceUidPut(
    requestParameters: SourcesApiUpdateSourceSourcesSourceUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SourcesApiFp(this.configuration)
      .updateSourceSourcesSourceUidPut(
        requestParameters.sourceUid,
        requestParameters.updateSourceParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SsoApi - axios parameter creator
 * @export
 */
export const SsoApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Callback Oidc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callbackOidcSsoOidcCallbackGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sso/oidc/callback`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Auth Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuthSettingsAuthenticationSettingsGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/authentication/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Oidc Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOidcSettingsSsoSettingsOidcGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sso/settings/oidc`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Saml Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSamlSettingsSsoSettingsSamlGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sso/settings/saml`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Sso Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSsoSettingsSsoSettingsGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sso/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Has Oidc Envs Set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hasOidcEnvsSetSsoOidcUseEnvGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sso/oidc-use-env`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Admission Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAdmissionRolesSsoAdmissionRolesGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sso/admission-roles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Saml Login Callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    samlLoginCallbackSsoSamlAcsPost: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sso/saml/acs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Saml Settings
     * @param {SamlIdpSettings} samlIdpSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    samlSettingsSsoSettingsSamlPost: async (
      samlIdpSettings: SamlIdpSettings,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'samlIdpSettings' is not null or undefined
      assertParamExists(
        'samlSettingsSsoSettingsSamlPost',
        'samlIdpSettings',
        samlIdpSettings,
      );
      const localVarPath = `/sso/settings/saml`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        samlIdpSettings,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Admission Roles
     * @param {AdmissionRoles} admissionRoles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setAdmissionRolesSsoAdmissionRolesPost: async (
      admissionRoles: AdmissionRoles,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'admissionRoles' is not null or undefined
      assertParamExists(
        'setAdmissionRolesSsoAdmissionRolesPost',
        'admissionRoles',
        admissionRoles,
      );
      const localVarPath = `/sso/admission-roles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        admissionRoles,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Auth Settings
     * @param {AuthSettings} authSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setAuthSettingsAuthenticationSettingsPost: async (
      authSettings: AuthSettings,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authSettings' is not null or undefined
      assertParamExists(
        'setAuthSettingsAuthenticationSettingsPost',
        'authSettings',
        authSettings,
      );
      const localVarPath = `/authentication/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        authSettings,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Oidc Settings
     * @param {OidcClientSettings} oidcClientSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setOidcSettingsSsoSettingsOidcPost: async (
      oidcClientSettings: OidcClientSettings,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'oidcClientSettings' is not null or undefined
      assertParamExists(
        'setOidcSettingsSsoSettingsOidcPost',
        'oidcClientSettings',
        oidcClientSettings,
      );
      const localVarPath = `/sso/settings/oidc`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        oidcClientSettings,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Sso Settings
     * @param {SsoSettings} ssoSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ssoSettingsSsoSettingsPost: async (
      ssoSettings: SsoSettings,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ssoSettings' is not null or undefined
      assertParamExists(
        'ssoSettingsSsoSettingsPost',
        'ssoSettings',
        ssoSettings,
      );
      const localVarPath = `/sso/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        ssoSettings,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start Oidc
     * @param {string} [nextUrl]
     * @param {string} [inviteLink]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startOidcSsoOidcStartGet: async (
      nextUrl?: string,
      inviteLink?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sso/oidc/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (nextUrl !== undefined) {
        localVarQueryParameter['next_url'] = nextUrl;
      }

      if (inviteLink !== undefined) {
        localVarQueryParameter['invite_link'] = inviteLink;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start Saml Auth Flow
     * @param {string} [returnTo]
     * @param {string} [inviteLink]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startSamlAuthFlowSsoSamlStartGet: async (
      returnTo?: string,
      inviteLink?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/sso/saml/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (returnTo !== undefined) {
        localVarQueryParameter['return_to'] = returnTo;
      }

      if (inviteLink !== undefined) {
        localVarQueryParameter['invite_link'] = inviteLink;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SsoApi - functional programming interface
 * @export
 */
export const SsoApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SsoApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Callback Oidc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async callbackOidcSsoOidcCallbackGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OidcCallbackResponseData>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.callbackOidcSsoOidcCallbackGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.callbackOidcSsoOidcCallbackGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Auth Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAuthSettingsAuthenticationSettingsGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthSettings>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAuthSettingsAuthenticationSettingsGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.getAuthSettingsAuthenticationSettingsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Oidc Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOidcSettingsSsoSettingsOidcGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OidcClientSettings>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOidcSettingsSsoSettingsOidcGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.getOidcSettingsSsoSettingsOidcGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Saml Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSamlSettingsSsoSettingsSamlGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SamlSettingsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSamlSettingsSsoSettingsSamlGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.getSamlSettingsSsoSettingsSamlGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Sso Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSsoSettingsSsoSettingsGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SsoSettings>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSsoSettingsSsoSettingsGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.getSsoSettingsSsoSettingsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Has Oidc Envs Set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async hasOidcEnvsSetSsoOidcUseEnvGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.hasOidcEnvsSetSsoOidcUseEnvGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.hasOidcEnvsSetSsoOidcUseEnvGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Admission Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listAdmissionRolesSsoAdmissionRolesGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdmissionRoles>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listAdmissionRolesSsoAdmissionRolesGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.listAdmissionRolesSsoAdmissionRolesGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Saml Login Callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async samlLoginCallbackSsoSamlAcsPost(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SamlResponseData>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.samlLoginCallbackSsoSamlAcsPost(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.samlLoginCallbackSsoSamlAcsPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Saml Settings
     * @param {SamlIdpSettings} samlIdpSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async samlSettingsSsoSettingsSamlPost(
      samlIdpSettings: SamlIdpSettings,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.samlSettingsSsoSettingsSamlPost(
          samlIdpSettings,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.samlSettingsSsoSettingsSamlPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Set Admission Roles
     * @param {AdmissionRoles} admissionRoles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setAdmissionRolesSsoAdmissionRolesPost(
      admissionRoles: AdmissionRoles,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setAdmissionRolesSsoAdmissionRolesPost(
          admissionRoles,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.setAdmissionRolesSsoAdmissionRolesPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Set Auth Settings
     * @param {AuthSettings} authSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setAuthSettingsAuthenticationSettingsPost(
      authSettings: AuthSettings,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setAuthSettingsAuthenticationSettingsPost(
          authSettings,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'SsoApi.setAuthSettingsAuthenticationSettingsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Set Oidc Settings
     * @param {OidcClientSettings} oidcClientSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setOidcSettingsSsoSettingsOidcPost(
      oidcClientSettings: OidcClientSettings,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setOidcSettingsSsoSettingsOidcPost(
          oidcClientSettings,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.setOidcSettingsSsoSettingsOidcPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Sso Settings
     * @param {SsoSettings} ssoSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ssoSettingsSsoSettingsPost(
      ssoSettings: SsoSettings,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.ssoSettingsSsoSettingsPost(
          ssoSettings,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.ssoSettingsSsoSettingsPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Start Oidc
     * @param {string} [nextUrl]
     * @param {string} [inviteLink]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startOidcSsoOidcStartGet(
      nextUrl?: string,
      inviteLink?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OidcStartSso>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.startOidcSsoOidcStartGet(
          nextUrl,
          inviteLink,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.startOidcSsoOidcStartGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Start Saml Auth Flow
     * @param {string} [returnTo]
     * @param {string} [inviteLink]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startSamlAuthFlowSsoSamlStartGet(
      returnTo?: string,
      inviteLink?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SamlStartSso>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.startSamlAuthFlowSsoSamlStartGet(
          returnTo,
          inviteLink,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SsoApi.startSamlAuthFlowSsoSamlStartGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SsoApi - factory interface
 * @export
 */
export const SsoApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SsoApiFp(configuration);
  return {
    /**
     *
     * @summary Callback Oidc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callbackOidcSsoOidcCallbackGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<OidcCallbackResponseData> {
      return localVarFp
        .callbackOidcSsoOidcCallbackGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Auth Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuthSettingsAuthenticationSettingsGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AuthSettings> {
      return localVarFp
        .getAuthSettingsAuthenticationSettingsGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Oidc Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOidcSettingsSsoSettingsOidcGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<OidcClientSettings> {
      return localVarFp
        .getOidcSettingsSsoSettingsOidcGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Saml Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSamlSettingsSsoSettingsSamlGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SamlSettingsResponse> {
      return localVarFp
        .getSamlSettingsSsoSettingsSamlGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Sso Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSsoSettingsSsoSettingsGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SsoSettings> {
      return localVarFp
        .getSsoSettingsSsoSettingsGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Has Oidc Envs Set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hasOidcEnvsSetSsoOidcUseEnvGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<boolean> {
      return localVarFp
        .hasOidcEnvsSetSsoOidcUseEnvGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Admission Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAdmissionRolesSsoAdmissionRolesGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AdmissionRoles> {
      return localVarFp
        .listAdmissionRolesSsoAdmissionRolesGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Saml Login Callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    samlLoginCallbackSsoSamlAcsPost(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SamlResponseData> {
      return localVarFp
        .samlLoginCallbackSsoSamlAcsPost(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Saml Settings
     * @param {SsoApiSamlSettingsSsoSettingsSamlPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    samlSettingsSsoSettingsSamlPost(
      requestParameters: SsoApiSamlSettingsSsoSettingsSamlPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .samlSettingsSsoSettingsSamlPost(
          requestParameters.samlIdpSettings,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Set Admission Roles
     * @param {SsoApiSetAdmissionRolesSsoAdmissionRolesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setAdmissionRolesSsoAdmissionRolesPost(
      requestParameters: SsoApiSetAdmissionRolesSsoAdmissionRolesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .setAdmissionRolesSsoAdmissionRolesPost(
          requestParameters.admissionRoles,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Set Auth Settings
     * @param {SsoApiSetAuthSettingsAuthenticationSettingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setAuthSettingsAuthenticationSettingsPost(
      requestParameters: SsoApiSetAuthSettingsAuthenticationSettingsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .setAuthSettingsAuthenticationSettingsPost(
          requestParameters.authSettings,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Set Oidc Settings
     * @param {SsoApiSetOidcSettingsSsoSettingsOidcPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setOidcSettingsSsoSettingsOidcPost(
      requestParameters: SsoApiSetOidcSettingsSsoSettingsOidcPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .setOidcSettingsSsoSettingsOidcPost(
          requestParameters.oidcClientSettings,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Sso Settings
     * @param {SsoApiSsoSettingsSsoSettingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ssoSettingsSsoSettingsPost(
      requestParameters: SsoApiSsoSettingsSsoSettingsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .ssoSettingsSsoSettingsPost(requestParameters.ssoSettings, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Start Oidc
     * @param {SsoApiStartOidcSsoOidcStartGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startOidcSsoOidcStartGet(
      requestParameters: SsoApiStartOidcSsoOidcStartGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<OidcStartSso> {
      return localVarFp
        .startOidcSsoOidcStartGet(
          requestParameters.nextUrl,
          requestParameters.inviteLink,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Start Saml Auth Flow
     * @param {SsoApiStartSamlAuthFlowSsoSamlStartGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startSamlAuthFlowSsoSamlStartGet(
      requestParameters: SsoApiStartSamlAuthFlowSsoSamlStartGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SamlStartSso> {
      return localVarFp
        .startSamlAuthFlowSsoSamlStartGet(
          requestParameters.returnTo,
          requestParameters.inviteLink,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for samlSettingsSsoSettingsSamlPost operation in SsoApi.
 * @export
 * @interface SsoApiSamlSettingsSsoSettingsSamlPostRequest
 */
export interface SsoApiSamlSettingsSsoSettingsSamlPostRequest {
  /**
   *
   * @type {SamlIdpSettings}
   * @memberof SsoApiSamlSettingsSsoSettingsSamlPost
   */
  readonly samlIdpSettings: SamlIdpSettings;
}

/**
 * Request parameters for setAdmissionRolesSsoAdmissionRolesPost operation in SsoApi.
 * @export
 * @interface SsoApiSetAdmissionRolesSsoAdmissionRolesPostRequest
 */
export interface SsoApiSetAdmissionRolesSsoAdmissionRolesPostRequest {
  /**
   *
   * @type {AdmissionRoles}
   * @memberof SsoApiSetAdmissionRolesSsoAdmissionRolesPost
   */
  readonly admissionRoles: AdmissionRoles;
}

/**
 * Request parameters for setAuthSettingsAuthenticationSettingsPost operation in SsoApi.
 * @export
 * @interface SsoApiSetAuthSettingsAuthenticationSettingsPostRequest
 */
export interface SsoApiSetAuthSettingsAuthenticationSettingsPostRequest {
  /**
   *
   * @type {AuthSettings}
   * @memberof SsoApiSetAuthSettingsAuthenticationSettingsPost
   */
  readonly authSettings: AuthSettings;
}

/**
 * Request parameters for setOidcSettingsSsoSettingsOidcPost operation in SsoApi.
 * @export
 * @interface SsoApiSetOidcSettingsSsoSettingsOidcPostRequest
 */
export interface SsoApiSetOidcSettingsSsoSettingsOidcPostRequest {
  /**
   *
   * @type {OidcClientSettings}
   * @memberof SsoApiSetOidcSettingsSsoSettingsOidcPost
   */
  readonly oidcClientSettings: OidcClientSettings;
}

/**
 * Request parameters for ssoSettingsSsoSettingsPost operation in SsoApi.
 * @export
 * @interface SsoApiSsoSettingsSsoSettingsPostRequest
 */
export interface SsoApiSsoSettingsSsoSettingsPostRequest {
  /**
   *
   * @type {SsoSettings}
   * @memberof SsoApiSsoSettingsSsoSettingsPost
   */
  readonly ssoSettings: SsoSettings;
}

/**
 * Request parameters for startOidcSsoOidcStartGet operation in SsoApi.
 * @export
 * @interface SsoApiStartOidcSsoOidcStartGetRequest
 */
export interface SsoApiStartOidcSsoOidcStartGetRequest {
  /**
   *
   * @type {string}
   * @memberof SsoApiStartOidcSsoOidcStartGet
   */
  readonly nextUrl?: string;

  /**
   *
   * @type {string}
   * @memberof SsoApiStartOidcSsoOidcStartGet
   */
  readonly inviteLink?: string;
}

/**
 * Request parameters for startSamlAuthFlowSsoSamlStartGet operation in SsoApi.
 * @export
 * @interface SsoApiStartSamlAuthFlowSsoSamlStartGetRequest
 */
export interface SsoApiStartSamlAuthFlowSsoSamlStartGetRequest {
  /**
   *
   * @type {string}
   * @memberof SsoApiStartSamlAuthFlowSsoSamlStartGet
   */
  readonly returnTo?: string;

  /**
   *
   * @type {string}
   * @memberof SsoApiStartSamlAuthFlowSsoSamlStartGet
   */
  readonly inviteLink?: string;
}

/**
 * SsoApi - object-oriented interface
 * @export
 * @class SsoApi
 * @extends {BaseAPI}
 */
export class SsoApi extends BaseAPI {
  /**
   *
   * @summary Callback Oidc
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public callbackOidcSsoOidcCallbackGet(options?: RawAxiosRequestConfig) {
    return SsoApiFp(this.configuration)
      .callbackOidcSsoOidcCallbackGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Auth Settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public getAuthSettingsAuthenticationSettingsGet(
    options?: RawAxiosRequestConfig,
  ) {
    return SsoApiFp(this.configuration)
      .getAuthSettingsAuthenticationSettingsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Oidc Settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public getOidcSettingsSsoSettingsOidcGet(options?: RawAxiosRequestConfig) {
    return SsoApiFp(this.configuration)
      .getOidcSettingsSsoSettingsOidcGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Saml Settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public getSamlSettingsSsoSettingsSamlGet(options?: RawAxiosRequestConfig) {
    return SsoApiFp(this.configuration)
      .getSamlSettingsSsoSettingsSamlGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Sso Settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public getSsoSettingsSsoSettingsGet(options?: RawAxiosRequestConfig) {
    return SsoApiFp(this.configuration)
      .getSsoSettingsSsoSettingsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Has Oidc Envs Set
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public hasOidcEnvsSetSsoOidcUseEnvGet(options?: RawAxiosRequestConfig) {
    return SsoApiFp(this.configuration)
      .hasOidcEnvsSetSsoOidcUseEnvGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Admission Roles
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public listAdmissionRolesSsoAdmissionRolesGet(
    options?: RawAxiosRequestConfig,
  ) {
    return SsoApiFp(this.configuration)
      .listAdmissionRolesSsoAdmissionRolesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Saml Login Callback
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public samlLoginCallbackSsoSamlAcsPost(options?: RawAxiosRequestConfig) {
    return SsoApiFp(this.configuration)
      .samlLoginCallbackSsoSamlAcsPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Saml Settings
   * @param {SsoApiSamlSettingsSsoSettingsSamlPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public samlSettingsSsoSettingsSamlPost(
    requestParameters: SsoApiSamlSettingsSsoSettingsSamlPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SsoApiFp(this.configuration)
      .samlSettingsSsoSettingsSamlPost(
        requestParameters.samlIdpSettings,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Admission Roles
   * @param {SsoApiSetAdmissionRolesSsoAdmissionRolesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public setAdmissionRolesSsoAdmissionRolesPost(
    requestParameters: SsoApiSetAdmissionRolesSsoAdmissionRolesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SsoApiFp(this.configuration)
      .setAdmissionRolesSsoAdmissionRolesPost(
        requestParameters.admissionRoles,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Auth Settings
   * @param {SsoApiSetAuthSettingsAuthenticationSettingsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public setAuthSettingsAuthenticationSettingsPost(
    requestParameters: SsoApiSetAuthSettingsAuthenticationSettingsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SsoApiFp(this.configuration)
      .setAuthSettingsAuthenticationSettingsPost(
        requestParameters.authSettings,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Oidc Settings
   * @param {SsoApiSetOidcSettingsSsoSettingsOidcPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public setOidcSettingsSsoSettingsOidcPost(
    requestParameters: SsoApiSetOidcSettingsSsoSettingsOidcPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SsoApiFp(this.configuration)
      .setOidcSettingsSsoSettingsOidcPost(
        requestParameters.oidcClientSettings,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Sso Settings
   * @param {SsoApiSsoSettingsSsoSettingsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public ssoSettingsSsoSettingsPost(
    requestParameters: SsoApiSsoSettingsSsoSettingsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SsoApiFp(this.configuration)
      .ssoSettingsSsoSettingsPost(requestParameters.ssoSettings, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start Oidc
   * @param {SsoApiStartOidcSsoOidcStartGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public startOidcSsoOidcStartGet(
    requestParameters: SsoApiStartOidcSsoOidcStartGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return SsoApiFp(this.configuration)
      .startOidcSsoOidcStartGet(
        requestParameters.nextUrl,
        requestParameters.inviteLink,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start Saml Auth Flow
   * @param {SsoApiStartSamlAuthFlowSsoSamlStartGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SsoApi
   */
  public startSamlAuthFlowSsoSamlStartGet(
    requestParameters: SsoApiStartSamlAuthFlowSsoSamlStartGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return SsoApiFp(this.configuration)
      .startSamlAuthFlowSsoSamlStartGet(
        requestParameters.returnTo,
        requestParameters.inviteLink,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * StaticAssetApi - axios parameter creator
 * @export
 */
export const StaticAssetApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary List Asset Folder Files
     * @param {number} workspaceUid
     * @param {string} folderName
     * @param {string} folderType
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAssetFolderFilesStaticAssetListAssetFolderFilesGet: async (
      workspaceUid: number,
      folderName: string,
      folderType: string,
      page?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'listAssetFolderFilesStaticAssetListAssetFolderFilesGet',
        'workspaceUid',
        workspaceUid,
      );
      // verify required parameter 'folderName' is not null or undefined
      assertParamExists(
        'listAssetFolderFilesStaticAssetListAssetFolderFilesGet',
        'folderName',
        folderName,
      );
      // verify required parameter 'folderType' is not null or undefined
      assertParamExists(
        'listAssetFolderFilesStaticAssetListAssetFolderFilesGet',
        'folderType',
        folderType,
      );
      const localVarPath = `/static-asset/list-asset-folder-files`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      if (folderName !== undefined) {
        localVarQueryParameter['folder_name'] = folderName;
      }

      if (folderType !== undefined) {
        localVarQueryParameter['folder_type'] = folderType;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Asset Folder Names
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAssetFolderNamesStaticAssetListAssetFolderNamesGet: async (
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'listAssetFolderNamesStaticAssetListAssetFolderNamesGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/static-asset/list-asset-folder-names`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Local
     * @param {number} workspaceUid
     * @param {AssetUploadType} fileType
     * @param {Array<File>} files
     * @param {string} targetPath
     * @param {boolean} [overwriteExisting]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadLocalStaticAssetUploadLocalFilesPost: async (
      workspaceUid: number,
      fileType: AssetUploadType,
      files: Array<File>,
      targetPath: string,
      overwriteExisting?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'uploadLocalStaticAssetUploadLocalFilesPost',
        'workspaceUid',
        workspaceUid,
      );
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists(
        'uploadLocalStaticAssetUploadLocalFilesPost',
        'fileType',
        fileType,
      );
      // verify required parameter 'files' is not null or undefined
      assertParamExists(
        'uploadLocalStaticAssetUploadLocalFilesPost',
        'files',
        files,
      );
      // verify required parameter 'targetPath' is not null or undefined
      assertParamExists(
        'uploadLocalStaticAssetUploadLocalFilesPost',
        'targetPath',
        targetPath,
      );
      const localVarPath = `/static-asset/upload-local-files`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      if (fileType !== undefined) {
        localVarQueryParameter['file_type'] = fileType;
      }

      if (overwriteExisting !== undefined) {
        localVarQueryParameter['overwrite_existing'] = overwriteExisting;
      }

      if (files) {
        files.forEach(element => {
          localVarFormParams.append('files', element as any);
        });
      }

      if (targetPath !== undefined) {
        localVarFormParams.append('target_path', targetPath as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Remote
     * @param {number} workspaceUid
     * @param {UploadRemoteObjectsParams} uploadRemoteObjectsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadRemoteStaticAssetUploadRemoteFilesPost: async (
      workspaceUid: number,
      uploadRemoteObjectsParams: UploadRemoteObjectsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'uploadRemoteStaticAssetUploadRemoteFilesPost',
        'workspaceUid',
        workspaceUid,
      );
      // verify required parameter 'uploadRemoteObjectsParams' is not null or undefined
      assertParamExists(
        'uploadRemoteStaticAssetUploadRemoteFilesPost',
        'uploadRemoteObjectsParams',
        uploadRemoteObjectsParams,
      );
      const localVarPath = `/static-asset/upload-remote-files`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uploadRemoteObjectsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StaticAssetApi - functional programming interface
 * @export
 */
export const StaticAssetApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    StaticAssetApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List Asset Folder Files
     * @param {number} workspaceUid
     * @param {string} folderName
     * @param {string} folderType
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listAssetFolderFilesStaticAssetListAssetFolderFilesGet(
      workspaceUid: number,
      folderName: string,
      folderType: string,
      page?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<StaticAssetFileMetadata>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listAssetFolderFilesStaticAssetListAssetFolderFilesGet(
          workspaceUid,
          folderName,
          folderType,
          page,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetApi.listAssetFolderFilesStaticAssetListAssetFolderFilesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Asset Folder Names
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listAssetFolderNamesStaticAssetListAssetFolderNamesGet(
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: StaticAssetFolderMetadata }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listAssetFolderNamesStaticAssetListAssetFolderNamesGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetApi.listAssetFolderNamesStaticAssetListAssetFolderNamesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Upload Local
     * @param {number} workspaceUid
     * @param {AssetUploadType} fileType
     * @param {Array<File>} files
     * @param {string} targetPath
     * @param {boolean} [overwriteExisting]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadLocalStaticAssetUploadLocalFilesPost(
      workspaceUid: number,
      fileType: AssetUploadType,
      files: Array<File>,
      targetPath: string,
      overwriteExisting?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadLocalFileResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadLocalStaticAssetUploadLocalFilesPost(
          workspaceUid,
          fileType,
          files,
          targetPath,
          overwriteExisting,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetApi.uploadLocalStaticAssetUploadLocalFilesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Upload Remote
     * @param {number} workspaceUid
     * @param {UploadRemoteObjectsParams} uploadRemoteObjectsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadRemoteStaticAssetUploadRemoteFilesPost(
      workspaceUid: number,
      uploadRemoteObjectsParams: UploadRemoteObjectsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadRemoteStaticAssetUploadRemoteFilesPost(
          workspaceUid,
          uploadRemoteObjectsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetApi.uploadRemoteStaticAssetUploadRemoteFilesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * StaticAssetApi - factory interface
 * @export
 */
export const StaticAssetApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StaticAssetApiFp(configuration);
  return {
    /**
     *
     * @summary List Asset Folder Files
     * @param {StaticAssetApiListAssetFolderFilesStaticAssetListAssetFolderFilesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAssetFolderFilesStaticAssetListAssetFolderFilesGet(
      requestParameters: StaticAssetApiListAssetFolderFilesStaticAssetListAssetFolderFilesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<StaticAssetFileMetadata>> {
      return localVarFp
        .listAssetFolderFilesStaticAssetListAssetFolderFilesGet(
          requestParameters.workspaceUid,
          requestParameters.folderName,
          requestParameters.folderType,
          requestParameters.page,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Asset Folder Names
     * @param {StaticAssetApiListAssetFolderNamesStaticAssetListAssetFolderNamesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAssetFolderNamesStaticAssetListAssetFolderNamesGet(
      requestParameters: StaticAssetApiListAssetFolderNamesStaticAssetListAssetFolderNamesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: StaticAssetFolderMetadata }> {
      return localVarFp
        .listAssetFolderNamesStaticAssetListAssetFolderNamesGet(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Local
     * @param {StaticAssetApiUploadLocalStaticAssetUploadLocalFilesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadLocalStaticAssetUploadLocalFilesPost(
      requestParameters: StaticAssetApiUploadLocalStaticAssetUploadLocalFilesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UploadLocalFileResponseModel> {
      return localVarFp
        .uploadLocalStaticAssetUploadLocalFilesPost(
          requestParameters.workspaceUid,
          requestParameters.fileType,
          requestParameters.files,
          requestParameters.targetPath,
          requestParameters.overwriteExisting,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Remote
     * @param {StaticAssetApiUploadRemoteStaticAssetUploadRemoteFilesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadRemoteStaticAssetUploadRemoteFilesPost(
      requestParameters: StaticAssetApiUploadRemoteStaticAssetUploadRemoteFilesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .uploadRemoteStaticAssetUploadRemoteFilesPost(
          requestParameters.workspaceUid,
          requestParameters.uploadRemoteObjectsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for listAssetFolderFilesStaticAssetListAssetFolderFilesGet operation in StaticAssetApi.
 * @export
 * @interface StaticAssetApiListAssetFolderFilesStaticAssetListAssetFolderFilesGetRequest
 */
export interface StaticAssetApiListAssetFolderFilesStaticAssetListAssetFolderFilesGetRequest {
  /**
   *
   * @type {number}
   * @memberof StaticAssetApiListAssetFolderFilesStaticAssetListAssetFolderFilesGet
   */
  readonly workspaceUid: number;

  /**
   *
   * @type {string}
   * @memberof StaticAssetApiListAssetFolderFilesStaticAssetListAssetFolderFilesGet
   */
  readonly folderName: string;

  /**
   *
   * @type {string}
   * @memberof StaticAssetApiListAssetFolderFilesStaticAssetListAssetFolderFilesGet
   */
  readonly folderType: string;

  /**
   *
   * @type {number}
   * @memberof StaticAssetApiListAssetFolderFilesStaticAssetListAssetFolderFilesGet
   */
  readonly page?: number;
}

/**
 * Request parameters for listAssetFolderNamesStaticAssetListAssetFolderNamesGet operation in StaticAssetApi.
 * @export
 * @interface StaticAssetApiListAssetFolderNamesStaticAssetListAssetFolderNamesGetRequest
 */
export interface StaticAssetApiListAssetFolderNamesStaticAssetListAssetFolderNamesGetRequest {
  /**
   *
   * @type {number}
   * @memberof StaticAssetApiListAssetFolderNamesStaticAssetListAssetFolderNamesGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for uploadLocalStaticAssetUploadLocalFilesPost operation in StaticAssetApi.
 * @export
 * @interface StaticAssetApiUploadLocalStaticAssetUploadLocalFilesPostRequest
 */
export interface StaticAssetApiUploadLocalStaticAssetUploadLocalFilesPostRequest {
  /**
   *
   * @type {number}
   * @memberof StaticAssetApiUploadLocalStaticAssetUploadLocalFilesPost
   */
  readonly workspaceUid: number;

  /**
   *
   * @type {AssetUploadType}
   * @memberof StaticAssetApiUploadLocalStaticAssetUploadLocalFilesPost
   */
  readonly fileType: AssetUploadType;

  /**
   *
   * @type {Array<File>}
   * @memberof StaticAssetApiUploadLocalStaticAssetUploadLocalFilesPost
   */
  readonly files: Array<File>;

  /**
   *
   * @type {string}
   * @memberof StaticAssetApiUploadLocalStaticAssetUploadLocalFilesPost
   */
  readonly targetPath: string;

  /**
   *
   * @type {boolean}
   * @memberof StaticAssetApiUploadLocalStaticAssetUploadLocalFilesPost
   */
  readonly overwriteExisting?: boolean;
}

/**
 * Request parameters for uploadRemoteStaticAssetUploadRemoteFilesPost operation in StaticAssetApi.
 * @export
 * @interface StaticAssetApiUploadRemoteStaticAssetUploadRemoteFilesPostRequest
 */
export interface StaticAssetApiUploadRemoteStaticAssetUploadRemoteFilesPostRequest {
  /**
   *
   * @type {number}
   * @memberof StaticAssetApiUploadRemoteStaticAssetUploadRemoteFilesPost
   */
  readonly workspaceUid: number;

  /**
   *
   * @type {UploadRemoteObjectsParams}
   * @memberof StaticAssetApiUploadRemoteStaticAssetUploadRemoteFilesPost
   */
  readonly uploadRemoteObjectsParams: UploadRemoteObjectsParams;
}

/**
 * StaticAssetApi - object-oriented interface
 * @export
 * @class StaticAssetApi
 * @extends {BaseAPI}
 */
export class StaticAssetApi extends BaseAPI {
  /**
   *
   * @summary List Asset Folder Files
   * @param {StaticAssetApiListAssetFolderFilesStaticAssetListAssetFolderFilesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetApi
   */
  public listAssetFolderFilesStaticAssetListAssetFolderFilesGet(
    requestParameters: StaticAssetApiListAssetFolderFilesStaticAssetListAssetFolderFilesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetApiFp(this.configuration)
      .listAssetFolderFilesStaticAssetListAssetFolderFilesGet(
        requestParameters.workspaceUid,
        requestParameters.folderName,
        requestParameters.folderType,
        requestParameters.page,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Asset Folder Names
   * @param {StaticAssetApiListAssetFolderNamesStaticAssetListAssetFolderNamesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetApi
   */
  public listAssetFolderNamesStaticAssetListAssetFolderNamesGet(
    requestParameters: StaticAssetApiListAssetFolderNamesStaticAssetListAssetFolderNamesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetApiFp(this.configuration)
      .listAssetFolderNamesStaticAssetListAssetFolderNamesGet(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Local
   * @param {StaticAssetApiUploadLocalStaticAssetUploadLocalFilesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetApi
   */
  public uploadLocalStaticAssetUploadLocalFilesPost(
    requestParameters: StaticAssetApiUploadLocalStaticAssetUploadLocalFilesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetApiFp(this.configuration)
      .uploadLocalStaticAssetUploadLocalFilesPost(
        requestParameters.workspaceUid,
        requestParameters.fileType,
        requestParameters.files,
        requestParameters.targetPath,
        requestParameters.overwriteExisting,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Remote
   * @param {StaticAssetApiUploadRemoteStaticAssetUploadRemoteFilesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetApi
   */
  public uploadRemoteStaticAssetUploadRemoteFilesPost(
    requestParameters: StaticAssetApiUploadRemoteStaticAssetUploadRemoteFilesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetApiFp(this.configuration)
      .uploadRemoteStaticAssetUploadRemoteFilesPost(
        requestParameters.workspaceUid,
        requestParameters.uploadRemoteObjectsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * StaticAssetUploadMethodApi - axios parameter creator
 * @export
 */
export const StaticAssetUploadMethodApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Activate Static Asset Upload Method
     * @param {StaticAssetUploadMethodActivateRequest} staticAssetUploadMethodActivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePost: async (
      staticAssetUploadMethodActivateRequest: StaticAssetUploadMethodActivateRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'staticAssetUploadMethodActivateRequest' is not null or undefined
      assertParamExists(
        'activateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePost',
        'staticAssetUploadMethodActivateRequest',
        staticAssetUploadMethodActivateRequest,
      );
      const localVarPath = `/static-asset-upload-methods-activate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        staticAssetUploadMethodActivateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deactivate Static Asset Upload Method
     * @param {StaticAssetUploadMethodActivateRequest} staticAssetUploadMethodActivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePost:
      async (
        staticAssetUploadMethodActivateRequest: StaticAssetUploadMethodActivateRequest,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'staticAssetUploadMethodActivateRequest' is not null or undefined
        assertParamExists(
          'deactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePost',
          'staticAssetUploadMethodActivateRequest',
          staticAssetUploadMethodActivateRequest,
        );
        const localVarPath = `/static-asset-upload-methods-deactivate`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'POST',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          staticAssetUploadMethodActivateRequest,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Static Asset Upload Method State
     * @param {StaticAssetUploadMethod} staticAssetUploadMethod
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet:
      async (
        staticAssetUploadMethod: StaticAssetUploadMethod,
        workspaceUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'staticAssetUploadMethod' is not null or undefined
        assertParamExists(
          'getStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet',
          'staticAssetUploadMethod',
          staticAssetUploadMethod,
        );
        // verify required parameter 'workspaceUid' is not null or undefined
        assertParamExists(
          'getStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet',
          'workspaceUid',
          workspaceUid,
        );
        const localVarPath =
          `/static-asset-upload-method-states/{static_asset_upload_method}`.replace(
            `{${'static_asset_upload_method'}}`,
            encodeURIComponent(String(staticAssetUploadMethod)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (workspaceUid !== undefined) {
          localVarQueryParameter['workspace_uid'] = workspaceUid;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary List Static Asset Upload Method State
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGet: async (
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'listStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/static-asset-upload-method-states`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StaticAssetUploadMethodApi - functional programming interface
 * @export
 */
export const StaticAssetUploadMethodApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    StaticAssetUploadMethodApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Activate Static Asset Upload Method
     * @param {StaticAssetUploadMethodActivateRequest} staticAssetUploadMethodActivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePost(
      staticAssetUploadMethodActivateRequest: StaticAssetUploadMethodActivateRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.activateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePost(
          staticAssetUploadMethodActivateRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetUploadMethodApi.activateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Deactivate Static Asset Upload Method
     * @param {StaticAssetUploadMethodActivateRequest} staticAssetUploadMethodActivateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePost(
      staticAssetUploadMethodActivateRequest: StaticAssetUploadMethodActivateRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePost(
          staticAssetUploadMethodActivateRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetUploadMethodApi.deactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Static Asset Upload Method State
     * @param {StaticAssetUploadMethod} staticAssetUploadMethod
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet(
      staticAssetUploadMethod: StaticAssetUploadMethod,
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StaticAssetUploadMethodStateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet(
          staticAssetUploadMethod,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetUploadMethodApi.getStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Static Asset Upload Method State
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGet(
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: StaticAssetUploadMethodStateResponse }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetUploadMethodApi.listStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * StaticAssetUploadMethodApi - factory interface
 * @export
 */
export const StaticAssetUploadMethodApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StaticAssetUploadMethodApiFp(configuration);
  return {
    /**
     *
     * @summary Activate Static Asset Upload Method
     * @param {StaticAssetUploadMethodApiActivateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePost(
      requestParameters: StaticAssetUploadMethodApiActivateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .activateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePost(
          requestParameters.staticAssetUploadMethodActivateRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Deactivate Static Asset Upload Method
     * @param {StaticAssetUploadMethodApiDeactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePost(
      requestParameters: StaticAssetUploadMethodApiDeactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePost(
          requestParameters.staticAssetUploadMethodActivateRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Static Asset Upload Method State
     * @param {StaticAssetUploadMethodApiGetStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet(
      requestParameters: StaticAssetUploadMethodApiGetStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<StaticAssetUploadMethodStateResponse> {
      return localVarFp
        .getStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet(
          requestParameters.staticAssetUploadMethod,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Static Asset Upload Method State
     * @param {StaticAssetUploadMethodApiListStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGet(
      requestParameters: StaticAssetUploadMethodApiListStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: StaticAssetUploadMethodStateResponse }> {
      return localVarFp
        .listStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGet(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for activateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePost operation in StaticAssetUploadMethodApi.
 * @export
 * @interface StaticAssetUploadMethodApiActivateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePostRequest
 */
export interface StaticAssetUploadMethodApiActivateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePostRequest {
  /**
   *
   * @type {StaticAssetUploadMethodActivateRequest}
   * @memberof StaticAssetUploadMethodApiActivateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePost
   */
  readonly staticAssetUploadMethodActivateRequest: StaticAssetUploadMethodActivateRequest;
}

/**
 * Request parameters for deactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePost operation in StaticAssetUploadMethodApi.
 * @export
 * @interface StaticAssetUploadMethodApiDeactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePostRequest
 */
export interface StaticAssetUploadMethodApiDeactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePostRequest {
  /**
   *
   * @type {StaticAssetUploadMethodActivateRequest}
   * @memberof StaticAssetUploadMethodApiDeactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePost
   */
  readonly staticAssetUploadMethodActivateRequest: StaticAssetUploadMethodActivateRequest;
}

/**
 * Request parameters for getStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet operation in StaticAssetUploadMethodApi.
 * @export
 * @interface StaticAssetUploadMethodApiGetStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGetRequest
 */
export interface StaticAssetUploadMethodApiGetStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGetRequest {
  /**
   *
   * @type {StaticAssetUploadMethod}
   * @memberof StaticAssetUploadMethodApiGetStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet
   */
  readonly staticAssetUploadMethod: StaticAssetUploadMethod;

  /**
   *
   * @type {number}
   * @memberof StaticAssetUploadMethodApiGetStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for listStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGet operation in StaticAssetUploadMethodApi.
 * @export
 * @interface StaticAssetUploadMethodApiListStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGetRequest
 */
export interface StaticAssetUploadMethodApiListStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGetRequest {
  /**
   *
   * @type {number}
   * @memberof StaticAssetUploadMethodApiListStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGet
   */
  readonly workspaceUid: number;
}

/**
 * StaticAssetUploadMethodApi - object-oriented interface
 * @export
 * @class StaticAssetUploadMethodApi
 * @extends {BaseAPI}
 */
export class StaticAssetUploadMethodApi extends BaseAPI {
  /**
   *
   * @summary Activate Static Asset Upload Method
   * @param {StaticAssetUploadMethodApiActivateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetUploadMethodApi
   */
  public activateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePost(
    requestParameters: StaticAssetUploadMethodApiActivateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetUploadMethodApiFp(this.configuration)
      .activateStaticAssetUploadMethodStaticAssetUploadMethodsActivatePost(
        requestParameters.staticAssetUploadMethodActivateRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deactivate Static Asset Upload Method
   * @param {StaticAssetUploadMethodApiDeactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetUploadMethodApi
   */
  public deactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePost(
    requestParameters: StaticAssetUploadMethodApiDeactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetUploadMethodApiFp(this.configuration)
      .deactivateStaticAssetUploadMethodStaticAssetUploadMethodsDeactivatePost(
        requestParameters.staticAssetUploadMethodActivateRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Static Asset Upload Method State
   * @param {StaticAssetUploadMethodApiGetStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetUploadMethodApi
   */
  public getStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet(
    requestParameters: StaticAssetUploadMethodApiGetStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetUploadMethodApiFp(this.configuration)
      .getStaticAssetUploadMethodStateStaticAssetUploadMethodStatesStaticAssetUploadMethodGet(
        requestParameters.staticAssetUploadMethod,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Static Asset Upload Method State
   * @param {StaticAssetUploadMethodApiListStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetUploadMethodApi
   */
  public listStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGet(
    requestParameters: StaticAssetUploadMethodApiListStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetUploadMethodApiFp(this.configuration)
      .listStaticAssetUploadMethodStateStaticAssetUploadMethodStatesGet(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * StaticAssetUploadMethodRolesRouterApi - axios parameter creator
 * @export
 */
export const StaticAssetUploadMethodRolesRouterApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Static Asset Upload Method Role
     * @param {StaticAssetUploadMethodRoleCreationRequest} staticAssetUploadMethodRoleCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPost: async (
      staticAssetUploadMethodRoleCreationRequest: StaticAssetUploadMethodRoleCreationRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'staticAssetUploadMethodRoleCreationRequest' is not null or undefined
      assertParamExists(
        'createStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPost',
        'staticAssetUploadMethodRoleCreationRequest',
        staticAssetUploadMethodRoleCreationRequest,
      );
      const localVarPath = `/static-asset-upload-method-roles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        staticAssetUploadMethodRoleCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Static Asset Upload Method Role
     * @param {number} roleUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDelete:
      async (
        roleUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'roleUid' is not null or undefined
        assertParamExists(
          'deleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDelete',
          'roleUid',
          roleUid,
        );
        const localVarPath =
          `/static-asset-upload-method-roles/{role_uid}`.replace(
            `{${'role_uid'}}`,
            encodeURIComponent(String(roleUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'DELETE',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get Static Asset Upload Method Role
     * @param {number} roleUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGet:
      async (
        roleUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'roleUid' is not null or undefined
        assertParamExists(
          'getStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGet',
          'roleUid',
          roleUid,
        );
        const localVarPath =
          `/static-asset-upload-method-roles/{role_uid}`.replace(
            `{${'role_uid'}}`,
            encodeURIComponent(String(roleUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary List Static Asset Upload Method Roles
     * @param {StaticAssetUploadMethod} [staticAssetUploadMethod]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGet: async (
      staticAssetUploadMethod?: StaticAssetUploadMethod,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/static-asset-upload-method-roles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (staticAssetUploadMethod !== undefined) {
        localVarQueryParameter['static_asset_upload_method'] =
          staticAssetUploadMethod;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StaticAssetUploadMethodRolesRouterApi - functional programming interface
 * @export
 */
export const StaticAssetUploadMethodRolesRouterApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    StaticAssetUploadMethodRolesRouterApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Static Asset Upload Method Role
     * @param {StaticAssetUploadMethodRoleCreationRequest} staticAssetUploadMethodRoleCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPost(
      staticAssetUploadMethodRoleCreationRequest: StaticAssetUploadMethodRoleCreationRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPost(
          staticAssetUploadMethodRoleCreationRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetUploadMethodRolesRouterApi.createStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Static Asset Upload Method Role
     * @param {number} roleUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDelete(
      roleUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDelete(
          roleUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetUploadMethodRolesRouterApi.deleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Static Asset Upload Method Role
     * @param {number} roleUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGet(
      roleUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StaticAssetUploadMethod>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGet(
          roleUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetUploadMethodRolesRouterApi.getStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary List Static Asset Upload Method Roles
     * @param {StaticAssetUploadMethod} [staticAssetUploadMethod]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGet(
      staticAssetUploadMethod?: StaticAssetUploadMethod,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: any }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGet(
          staticAssetUploadMethod,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'StaticAssetUploadMethodRolesRouterApi.listStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * StaticAssetUploadMethodRolesRouterApi - factory interface
 * @export
 */
export const StaticAssetUploadMethodRolesRouterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StaticAssetUploadMethodRolesRouterApiFp(configuration);
  return {
    /**
     *
     * @summary Create Static Asset Upload Method Role
     * @param {StaticAssetUploadMethodRolesRouterApiCreateStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPost(
      requestParameters: StaticAssetUploadMethodRolesRouterApiCreateStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<number> {
      return localVarFp
        .createStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPost(
          requestParameters.staticAssetUploadMethodRoleCreationRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Static Asset Upload Method Role
     * @param {StaticAssetUploadMethodRolesRouterApiDeleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDelete(
      requestParameters: StaticAssetUploadMethodRolesRouterApiDeleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDelete(
          requestParameters.roleUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Static Asset Upload Method Role
     * @param {StaticAssetUploadMethodRolesRouterApiGetStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGet(
      requestParameters: StaticAssetUploadMethodRolesRouterApiGetStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<StaticAssetUploadMethod> {
      return localVarFp
        .getStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGet(
          requestParameters.roleUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List Static Asset Upload Method Roles
     * @param {StaticAssetUploadMethodRolesRouterApiListStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGet(
      requestParameters: StaticAssetUploadMethodRolesRouterApiListStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: any }> {
      return localVarFp
        .listStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGet(
          requestParameters.staticAssetUploadMethod,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPost operation in StaticAssetUploadMethodRolesRouterApi.
 * @export
 * @interface StaticAssetUploadMethodRolesRouterApiCreateStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPostRequest
 */
export interface StaticAssetUploadMethodRolesRouterApiCreateStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPostRequest {
  /**
   *
   * @type {StaticAssetUploadMethodRoleCreationRequest}
   * @memberof StaticAssetUploadMethodRolesRouterApiCreateStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPost
   */
  readonly staticAssetUploadMethodRoleCreationRequest: StaticAssetUploadMethodRoleCreationRequest;
}

/**
 * Request parameters for deleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDelete operation in StaticAssetUploadMethodRolesRouterApi.
 * @export
 * @interface StaticAssetUploadMethodRolesRouterApiDeleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDeleteRequest
 */
export interface StaticAssetUploadMethodRolesRouterApiDeleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof StaticAssetUploadMethodRolesRouterApiDeleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDelete
   */
  readonly roleUid: number;
}

/**
 * Request parameters for getStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGet operation in StaticAssetUploadMethodRolesRouterApi.
 * @export
 * @interface StaticAssetUploadMethodRolesRouterApiGetStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGetRequest
 */
export interface StaticAssetUploadMethodRolesRouterApiGetStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof StaticAssetUploadMethodRolesRouterApiGetStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGet
   */
  readonly roleUid: number;
}

/**
 * Request parameters for listStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGet operation in StaticAssetUploadMethodRolesRouterApi.
 * @export
 * @interface StaticAssetUploadMethodRolesRouterApiListStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGetRequest
 */
export interface StaticAssetUploadMethodRolesRouterApiListStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGetRequest {
  /**
   *
   * @type {StaticAssetUploadMethod}
   * @memberof StaticAssetUploadMethodRolesRouterApiListStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGet
   */
  readonly staticAssetUploadMethod?: StaticAssetUploadMethod;
}

/**
 * StaticAssetUploadMethodRolesRouterApi - object-oriented interface
 * @export
 * @class StaticAssetUploadMethodRolesRouterApi
 * @extends {BaseAPI}
 */
export class StaticAssetUploadMethodRolesRouterApi extends BaseAPI {
  /**
   *
   * @summary Create Static Asset Upload Method Role
   * @param {StaticAssetUploadMethodRolesRouterApiCreateStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetUploadMethodRolesRouterApi
   */
  public createStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPost(
    requestParameters: StaticAssetUploadMethodRolesRouterApiCreateStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetUploadMethodRolesRouterApiFp(this.configuration)
      .createStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesPost(
        requestParameters.staticAssetUploadMethodRoleCreationRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Static Asset Upload Method Role
   * @param {StaticAssetUploadMethodRolesRouterApiDeleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetUploadMethodRolesRouterApi
   */
  public deleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDelete(
    requestParameters: StaticAssetUploadMethodRolesRouterApiDeleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetUploadMethodRolesRouterApiFp(this.configuration)
      .deleteStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidDelete(
        requestParameters.roleUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Static Asset Upload Method Role
   * @param {StaticAssetUploadMethodRolesRouterApiGetStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetUploadMethodRolesRouterApi
   */
  public getStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGet(
    requestParameters: StaticAssetUploadMethodRolesRouterApiGetStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetUploadMethodRolesRouterApiFp(this.configuration)
      .getStaticAssetUploadMethodRoleStaticAssetUploadMethodRolesRoleUidGet(
        requestParameters.roleUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Static Asset Upload Method Roles
   * @param {StaticAssetUploadMethodRolesRouterApiListStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StaticAssetUploadMethodRolesRouterApi
   */
  public listStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGet(
    requestParameters: StaticAssetUploadMethodRolesRouterApiListStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return StaticAssetUploadMethodRolesRouterApiFp(this.configuration)
      .listStaticAssetUploadMethodRolesStaticAssetUploadMethodRolesGet(
        requestParameters.staticAssetUploadMethod,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TagMapApi - axios parameter creator
 * @export
 */
export const TagMapApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Tag Map
     * @param {number} nodeUid
     * @param {GetTagMapReq} getTagMapReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagMapNodesNodeUidGetTagMapPost: async (
      nodeUid: number,
      getTagMapReq: GetTagMapReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getTagMapNodesNodeUidGetTagMapPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'getTagMapReq' is not null or undefined
      assertParamExists(
        'getTagMapNodesNodeUidGetTagMapPost',
        'getTagMapReq',
        getTagMapReq,
      );
      const localVarPath = `/nodes/{node_uid}/get-tag-map`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getTagMapReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Tag Map
     * @param {number} nodeUid
     * @param {UpdateTagMapReq} updateTagMapReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagMapNodesNodeUidUpdateTagMapPost: async (
      nodeUid: number,
      updateTagMapReq: UpdateTagMapReq,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'updateTagMapNodesNodeUidUpdateTagMapPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'updateTagMapReq' is not null or undefined
      assertParamExists(
        'updateTagMapNodesNodeUidUpdateTagMapPost',
        'updateTagMapReq',
        updateTagMapReq,
      );
      const localVarPath = `/nodes/{node_uid}/update-tag-map`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTagMapReq,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TagMapApi - functional programming interface
 * @export
 */
export const TagMapApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TagMapApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Tag Map
     * @param {number} nodeUid
     * @param {GetTagMapReq} getTagMapReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTagMapNodesNodeUidGetTagMapPost(
      nodeUid: number,
      getTagMapReq: GetTagMapReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: any }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTagMapNodesNodeUidGetTagMapPost(
          nodeUid,
          getTagMapReq,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TagMapApi.getTagMapNodesNodeUidGetTagMapPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Tag Map
     * @param {number} nodeUid
     * @param {UpdateTagMapReq} updateTagMapReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTagMapNodesNodeUidUpdateTagMapPost(
      nodeUid: number,
      updateTagMapReq: UpdateTagMapReq,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateTagMapNodesNodeUidUpdateTagMapPost(
          nodeUid,
          updateTagMapReq,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TagMapApi.updateTagMapNodesNodeUidUpdateTagMapPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TagMapApi - factory interface
 * @export
 */
export const TagMapApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TagMapApiFp(configuration);
  return {
    /**
     *
     * @summary Get Tag Map
     * @param {TagMapApiGetTagMapNodesNodeUidGetTagMapPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagMapNodesNodeUidGetTagMapPost(
      requestParameters: TagMapApiGetTagMapNodesNodeUidGetTagMapPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: any }> {
      return localVarFp
        .getTagMapNodesNodeUidGetTagMapPost(
          requestParameters.nodeUid,
          requestParameters.getTagMapReq,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Tag Map
     * @param {TagMapApiUpdateTagMapNodesNodeUidUpdateTagMapPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagMapNodesNodeUidUpdateTagMapPost(
      requestParameters: TagMapApiUpdateTagMapNodesNodeUidUpdateTagMapPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateTagMapNodesNodeUidUpdateTagMapPost(
          requestParameters.nodeUid,
          requestParameters.updateTagMapReq,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getTagMapNodesNodeUidGetTagMapPost operation in TagMapApi.
 * @export
 * @interface TagMapApiGetTagMapNodesNodeUidGetTagMapPostRequest
 */
export interface TagMapApiGetTagMapNodesNodeUidGetTagMapPostRequest {
  /**
   *
   * @type {number}
   * @memberof TagMapApiGetTagMapNodesNodeUidGetTagMapPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {GetTagMapReq}
   * @memberof TagMapApiGetTagMapNodesNodeUidGetTagMapPost
   */
  readonly getTagMapReq: GetTagMapReq;
}

/**
 * Request parameters for updateTagMapNodesNodeUidUpdateTagMapPost operation in TagMapApi.
 * @export
 * @interface TagMapApiUpdateTagMapNodesNodeUidUpdateTagMapPostRequest
 */
export interface TagMapApiUpdateTagMapNodesNodeUidUpdateTagMapPostRequest {
  /**
   *
   * @type {number}
   * @memberof TagMapApiUpdateTagMapNodesNodeUidUpdateTagMapPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {UpdateTagMapReq}
   * @memberof TagMapApiUpdateTagMapNodesNodeUidUpdateTagMapPost
   */
  readonly updateTagMapReq: UpdateTagMapReq;
}

/**
 * TagMapApi - object-oriented interface
 * @export
 * @class TagMapApi
 * @extends {BaseAPI}
 */
export class TagMapApi extends BaseAPI {
  /**
   *
   * @summary Get Tag Map
   * @param {TagMapApiGetTagMapNodesNodeUidGetTagMapPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagMapApi
   */
  public getTagMapNodesNodeUidGetTagMapPost(
    requestParameters: TagMapApiGetTagMapNodesNodeUidGetTagMapPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TagMapApiFp(this.configuration)
      .getTagMapNodesNodeUidGetTagMapPost(
        requestParameters.nodeUid,
        requestParameters.getTagMapReq,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Tag Map
   * @param {TagMapApiUpdateTagMapNodesNodeUidUpdateTagMapPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagMapApi
   */
  public updateTagMapNodesNodeUidUpdateTagMapPost(
    requestParameters: TagMapApiUpdateTagMapNodesNodeUidUpdateTagMapPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TagMapApiFp(this.configuration)
      .updateTagMapNodesNodeUidUpdateTagMapPost(
        requestParameters.nodeUid,
        requestParameters.updateTagMapReq,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Tag Type
     * @param {number} nodeUid
     * @param {AddTagType} addTagType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTagTypeNodesNodeUidTagTypesPost: async (
      nodeUid: number,
      addTagType: AddTagType,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addTagTypeNodesNodeUidTagTypesPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'addTagType' is not null or undefined
      assertParamExists(
        'addTagTypeNodesNodeUidTagTypesPost',
        'addTagType',
        addTagType,
      );
      const localVarPath = `/nodes/{node_uid}/tag-types`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addTagType,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Tag Type
     * @param {number} nodeUid
     * @param {number} tagTypeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete: async (
      nodeUid: number,
      tagTypeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'deleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'tagTypeUid' is not null or undefined
      assertParamExists(
        'deleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete',
        'tagTypeUid',
        tagTypeUid,
      );
      const localVarPath = `/nodes/{node_uid}/tag-types/{tag_type_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'tag_type_uid'}}`, encodeURIComponent(String(tagTypeUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export Tag Types
     * @param {number} nodeUid
     * @param {boolean} [isContextTagType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportTagTypesNodesNodeUidExportTagTypesGet: async (
      nodeUid: number,
      isContextTagType?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'exportTagTypesNodesNodeUidExportTagTypesGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/export-tag-types`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isContextTagType !== undefined) {
        localVarQueryParameter['is_context_tag_type'] = isContextTagType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Tag Summary
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {boolean} [isContextTagType]
     * @param {boolean} [gtOnly]
     * @param {string} [split]
     * @param {number} [providedLabel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagSummaryNodesNodeUidModelsModelUidTagSummaryGet: async (
      nodeUid: number,
      modelUid: number,
      isContextTagType?: boolean,
      gtOnly?: boolean,
      split?: string,
      providedLabel?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getTagSummaryNodesNodeUidModelsModelUidTagSummaryGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'getTagSummaryNodesNodeUidModelsModelUidTagSummaryGet',
        'modelUid',
        modelUid,
      );
      const localVarPath = `/nodes/{node_uid}/models/{model_uid}/tag-summary`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isContextTagType !== undefined) {
        localVarQueryParameter['is_context_tag_type'] = isContextTagType;
      }

      if (gtOnly !== undefined) {
        localVarQueryParameter['gt_only'] = gtOnly;
      }

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (providedLabel !== undefined) {
        localVarQueryParameter['provided_label'] = providedLabel;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Tag Type
     * @param {number} nodeUid
     * @param {number} tagTypeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagTypeNodesNodeUidTagTypesTagTypeUidGet: async (
      nodeUid: number,
      tagTypeUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getTagTypeNodesNodeUidTagTypesTagTypeUidGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'tagTypeUid' is not null or undefined
      assertParamExists(
        'getTagTypeNodesNodeUidTagTypesTagTypeUidGet',
        'tagTypeUid',
        tagTypeUid,
      );
      const localVarPath = `/nodes/{node_uid}/tag-types/{tag_type_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'tag_type_uid'}}`, encodeURIComponent(String(tagTypeUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Tag Types
     * @param {number} nodeUid
     * @param {boolean} [isContextTagType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagTypes: async (
      nodeUid: number,
      isContextTagType?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists('getTagTypes', 'nodeUid', nodeUid);
      const localVarPath = `/nodes/{node_uid}/tag-types`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isContextTagType !== undefined) {
        localVarQueryParameter['is_context_tag_type'] = isContextTagType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import Tag Types
     * @param {number} nodeUid
     * @param {ImportTagTypes} importTagTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importTagTypesNodesNodeUidImportTagTypesPost: async (
      nodeUid: number,
      importTagTypes: ImportTagTypes,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'importTagTypesNodesNodeUidImportTagTypesPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'importTagTypes' is not null or undefined
      assertParamExists(
        'importTagTypesNodesNodeUidImportTagTypesPost',
        'importTagTypes',
        importTagTypes,
      );
      const localVarPath = `/nodes/{node_uid}/import-tag-types`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        importTagTypes,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Tag Type
     * @param {number} nodeUid
     * @param {number} tagTypeUid
     * @param {UpdateTagType} updateTagType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagTypeNodesNodeUidTagTypesTagTypeUidPut: async (
      nodeUid: number,
      tagTypeUid: number,
      updateTagType: UpdateTagType,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'updateTagTypeNodesNodeUidTagTypesTagTypeUidPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'tagTypeUid' is not null or undefined
      assertParamExists(
        'updateTagTypeNodesNodeUidTagTypesTagTypeUidPut',
        'tagTypeUid',
        tagTypeUid,
      );
      // verify required parameter 'updateTagType' is not null or undefined
      assertParamExists(
        'updateTagTypeNodesNodeUidTagTypesTagTypeUidPut',
        'updateTagType',
        updateTagType,
      );
      const localVarPath = `/nodes/{node_uid}/tag-types/{tag_type_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(`{${'tag_type_uid'}}`, encodeURIComponent(String(tagTypeUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTagType,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Tag Type
     * @param {number} nodeUid
     * @param {AddTagType} addTagType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addTagTypeNodesNodeUidTagTypesPost(
      nodeUid: number,
      addTagType: AddTagType,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagType>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addTagTypeNodesNodeUidTagTypesPost(
          nodeUid,
          addTagType,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TagsApi.addTagTypeNodesNodeUidTagTypesPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Tag Type
     * @param {number} nodeUid
     * @param {number} tagTypeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete(
      nodeUid: number,
      tagTypeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete(
          nodeUid,
          tagTypeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TagsApi.deleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Export Tag Types
     * @param {number} nodeUid
     * @param {boolean} [isContextTagType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportTagTypesNodesNodeUidExportTagTypesGet(
      nodeUid: number,
      isContextTagType?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportTagTypesNodesNodeUidExportTagTypesGet(
          nodeUid,
          isContextTagType,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TagsApi.exportTagTypesNodesNodeUidExportTagTypesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Tag Summary
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {boolean} [isContextTagType]
     * @param {boolean} [gtOnly]
     * @param {string} [split]
     * @param {number} [providedLabel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTagSummaryNodesNodeUidModelsModelUidTagSummaryGet(
      nodeUid: number,
      modelUid: number,
      isContextTagType?: boolean,
      gtOnly?: boolean,
      split?: string,
      providedLabel?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TagSummaryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTagSummaryNodesNodeUidModelsModelUidTagSummaryGet(
          nodeUid,
          modelUid,
          isContextTagType,
          gtOnly,
          split,
          providedLabel,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TagsApi.getTagSummaryNodesNodeUidModelsModelUidTagSummaryGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Tag Type
     * @param {number} nodeUid
     * @param {number} tagTypeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTagTypeNodesNodeUidTagTypesTagTypeUidGet(
      nodeUid: number,
      tagTypeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagType>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTagTypeNodesNodeUidTagTypesTagTypeUidGet(
          nodeUid,
          tagTypeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TagsApi.getTagTypeNodesNodeUidTagTypesTagTypeUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Tag Types
     * @param {number} nodeUid
     * @param {boolean} [isContextTagType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTagTypes(
      nodeUid: number,
      isContextTagType?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagType>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTagTypes(
        nodeUid,
        isContextTagType,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TagsApi.getTagTypes']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Import Tag Types
     * @param {number} nodeUid
     * @param {ImportTagTypes} importTagTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importTagTypesNodesNodeUidImportTagTypesPost(
      nodeUid: number,
      importTagTypes: ImportTagTypes,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.importTagTypesNodesNodeUidImportTagTypesPost(
          nodeUid,
          importTagTypes,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TagsApi.importTagTypesNodesNodeUidImportTagTypesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Tag Type
     * @param {number} nodeUid
     * @param {number} tagTypeUid
     * @param {UpdateTagType} updateTagType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTagTypeNodesNodeUidTagTypesTagTypeUidPut(
      nodeUid: number,
      tagTypeUid: number,
      updateTagType: UpdateTagType,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagType>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateTagTypeNodesNodeUidTagTypesTagTypeUidPut(
          nodeUid,
          tagTypeUid,
          updateTagType,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TagsApi.updateTagTypeNodesNodeUidTagTypesTagTypeUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TagsApiFp(configuration);
  return {
    /**
     *
     * @summary Add Tag Type
     * @param {TagsApiAddTagTypeNodesNodeUidTagTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTagTypeNodesNodeUidTagTypesPost(
      requestParameters: TagsApiAddTagTypeNodesNodeUidTagTypesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TagType> {
      return localVarFp
        .addTagTypeNodesNodeUidTagTypesPost(
          requestParameters.nodeUid,
          requestParameters.addTagType,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Tag Type
     * @param {TagsApiDeleteTagTypeNodesNodeUidTagTypesTagTypeUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete(
      requestParameters: TagsApiDeleteTagTypeNodesNodeUidTagTypesTagTypeUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete(
          requestParameters.nodeUid,
          requestParameters.tagTypeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Export Tag Types
     * @param {TagsApiExportTagTypesNodesNodeUidExportTagTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportTagTypesNodesNodeUidExportTagTypesGet(
      requestParameters: TagsApiExportTagTypesNodesNodeUidExportTagTypesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .exportTagTypesNodesNodeUidExportTagTypesGet(
          requestParameters.nodeUid,
          requestParameters.isContextTagType,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Tag Summary
     * @param {TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagSummaryNodesNodeUidModelsModelUidTagSummaryGet(
      requestParameters: TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TagSummaryResponse> {
      return localVarFp
        .getTagSummaryNodesNodeUidModelsModelUidTagSummaryGet(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.isContextTagType,
          requestParameters.gtOnly,
          requestParameters.split,
          requestParameters.providedLabel,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Tag Type
     * @param {TagsApiGetTagTypeNodesNodeUidTagTypesTagTypeUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagTypeNodesNodeUidTagTypesTagTypeUidGet(
      requestParameters: TagsApiGetTagTypeNodesNodeUidTagTypesTagTypeUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TagType> {
      return localVarFp
        .getTagTypeNodesNodeUidTagTypesTagTypeUidGet(
          requestParameters.nodeUid,
          requestParameters.tagTypeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Tag Types
     * @param {TagsApiGetTagTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTagTypes(
      requestParameters: TagsApiGetTagTypesRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<TagType>> {
      return localVarFp
        .getTagTypes(
          requestParameters.nodeUid,
          requestParameters.isContextTagType,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Import Tag Types
     * @param {TagsApiImportTagTypesNodesNodeUidImportTagTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importTagTypesNodesNodeUidImportTagTypesPost(
      requestParameters: TagsApiImportTagTypesNodesNodeUidImportTagTypesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .importTagTypesNodesNodeUidImportTagTypesPost(
          requestParameters.nodeUid,
          requestParameters.importTagTypes,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Tag Type
     * @param {TagsApiUpdateTagTypeNodesNodeUidTagTypesTagTypeUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagTypeNodesNodeUidTagTypesTagTypeUidPut(
      requestParameters: TagsApiUpdateTagTypeNodesNodeUidTagTypesTagTypeUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TagType> {
      return localVarFp
        .updateTagTypeNodesNodeUidTagTypesTagTypeUidPut(
          requestParameters.nodeUid,
          requestParameters.tagTypeUid,
          requestParameters.updateTagType,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addTagTypeNodesNodeUidTagTypesPost operation in TagsApi.
 * @export
 * @interface TagsApiAddTagTypeNodesNodeUidTagTypesPostRequest
 */
export interface TagsApiAddTagTypeNodesNodeUidTagTypesPostRequest {
  /**
   *
   * @type {number}
   * @memberof TagsApiAddTagTypeNodesNodeUidTagTypesPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {AddTagType}
   * @memberof TagsApiAddTagTypeNodesNodeUidTagTypesPost
   */
  readonly addTagType: AddTagType;
}

/**
 * Request parameters for deleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete operation in TagsApi.
 * @export
 * @interface TagsApiDeleteTagTypeNodesNodeUidTagTypesTagTypeUidDeleteRequest
 */
export interface TagsApiDeleteTagTypeNodesNodeUidTagTypesTagTypeUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof TagsApiDeleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TagsApiDeleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete
   */
  readonly tagTypeUid: number;
}

/**
 * Request parameters for exportTagTypesNodesNodeUidExportTagTypesGet operation in TagsApi.
 * @export
 * @interface TagsApiExportTagTypesNodesNodeUidExportTagTypesGetRequest
 */
export interface TagsApiExportTagTypesNodesNodeUidExportTagTypesGetRequest {
  /**
   *
   * @type {number}
   * @memberof TagsApiExportTagTypesNodesNodeUidExportTagTypesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof TagsApiExportTagTypesNodesNodeUidExportTagTypesGet
   */
  readonly isContextTagType?: boolean;
}

/**
 * Request parameters for getTagSummaryNodesNodeUidModelsModelUidTagSummaryGet operation in TagsApi.
 * @export
 * @interface TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGetRequest
 */
export interface TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGetRequest {
  /**
   *
   * @type {number}
   * @memberof TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGet
   */
  readonly modelUid: number;

  /**
   *
   * @type {boolean}
   * @memberof TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGet
   */
  readonly isContextTagType?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGet
   */
  readonly gtOnly?: boolean;

  /**
   *
   * @type {string}
   * @memberof TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGet
   */
  readonly split?: string;

  /**
   *
   * @type {number}
   * @memberof TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGet
   */
  readonly providedLabel?: number;
}

/**
 * Request parameters for getTagTypeNodesNodeUidTagTypesTagTypeUidGet operation in TagsApi.
 * @export
 * @interface TagsApiGetTagTypeNodesNodeUidTagTypesTagTypeUidGetRequest
 */
export interface TagsApiGetTagTypeNodesNodeUidTagTypesTagTypeUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof TagsApiGetTagTypeNodesNodeUidTagTypesTagTypeUidGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TagsApiGetTagTypeNodesNodeUidTagTypesTagTypeUidGet
   */
  readonly tagTypeUid: number;
}

/**
 * Request parameters for getTagTypes operation in TagsApi.
 * @export
 * @interface TagsApiGetTagTypesRequest
 */
export interface TagsApiGetTagTypesRequest {
  /**
   *
   * @type {number}
   * @memberof TagsApiGetTagTypes
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof TagsApiGetTagTypes
   */
  readonly isContextTagType?: boolean;
}

/**
 * Request parameters for importTagTypesNodesNodeUidImportTagTypesPost operation in TagsApi.
 * @export
 * @interface TagsApiImportTagTypesNodesNodeUidImportTagTypesPostRequest
 */
export interface TagsApiImportTagTypesNodesNodeUidImportTagTypesPostRequest {
  /**
   *
   * @type {number}
   * @memberof TagsApiImportTagTypesNodesNodeUidImportTagTypesPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ImportTagTypes}
   * @memberof TagsApiImportTagTypesNodesNodeUidImportTagTypesPost
   */
  readonly importTagTypes: ImportTagTypes;
}

/**
 * Request parameters for updateTagTypeNodesNodeUidTagTypesTagTypeUidPut operation in TagsApi.
 * @export
 * @interface TagsApiUpdateTagTypeNodesNodeUidTagTypesTagTypeUidPutRequest
 */
export interface TagsApiUpdateTagTypeNodesNodeUidTagTypesTagTypeUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof TagsApiUpdateTagTypeNodesNodeUidTagTypesTagTypeUidPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TagsApiUpdateTagTypeNodesNodeUidTagTypesTagTypeUidPut
   */
  readonly tagTypeUid: number;

  /**
   *
   * @type {UpdateTagType}
   * @memberof TagsApiUpdateTagTypeNodesNodeUidTagTypesTagTypeUidPut
   */
  readonly updateTagType: UpdateTagType;
}

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
  /**
   *
   * @summary Add Tag Type
   * @param {TagsApiAddTagTypeNodesNodeUidTagTypesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public addTagTypeNodesNodeUidTagTypesPost(
    requestParameters: TagsApiAddTagTypeNodesNodeUidTagTypesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TagsApiFp(this.configuration)
      .addTagTypeNodesNodeUidTagTypesPost(
        requestParameters.nodeUid,
        requestParameters.addTagType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Tag Type
   * @param {TagsApiDeleteTagTypeNodesNodeUidTagTypesTagTypeUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public deleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete(
    requestParameters: TagsApiDeleteTagTypeNodesNodeUidTagTypesTagTypeUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TagsApiFp(this.configuration)
      .deleteTagTypeNodesNodeUidTagTypesTagTypeUidDelete(
        requestParameters.nodeUid,
        requestParameters.tagTypeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export Tag Types
   * @param {TagsApiExportTagTypesNodesNodeUidExportTagTypesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public exportTagTypesNodesNodeUidExportTagTypesGet(
    requestParameters: TagsApiExportTagTypesNodesNodeUidExportTagTypesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TagsApiFp(this.configuration)
      .exportTagTypesNodesNodeUidExportTagTypesGet(
        requestParameters.nodeUid,
        requestParameters.isContextTagType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Tag Summary
   * @param {TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public getTagSummaryNodesNodeUidModelsModelUidTagSummaryGet(
    requestParameters: TagsApiGetTagSummaryNodesNodeUidModelsModelUidTagSummaryGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TagsApiFp(this.configuration)
      .getTagSummaryNodesNodeUidModelsModelUidTagSummaryGet(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.isContextTagType,
        requestParameters.gtOnly,
        requestParameters.split,
        requestParameters.providedLabel,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Tag Type
   * @param {TagsApiGetTagTypeNodesNodeUidTagTypesTagTypeUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public getTagTypeNodesNodeUidTagTypesTagTypeUidGet(
    requestParameters: TagsApiGetTagTypeNodesNodeUidTagTypesTagTypeUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TagsApiFp(this.configuration)
      .getTagTypeNodesNodeUidTagTypesTagTypeUidGet(
        requestParameters.nodeUid,
        requestParameters.tagTypeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Tag Types
   * @param {TagsApiGetTagTypesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public getTagTypes(
    requestParameters: TagsApiGetTagTypesRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TagsApiFp(this.configuration)
      .getTagTypes(
        requestParameters.nodeUid,
        requestParameters.isContextTagType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import Tag Types
   * @param {TagsApiImportTagTypesNodesNodeUidImportTagTypesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public importTagTypesNodesNodeUidImportTagTypesPost(
    requestParameters: TagsApiImportTagTypesNodesNodeUidImportTagTypesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TagsApiFp(this.configuration)
      .importTagTypesNodesNodeUidImportTagTypesPost(
        requestParameters.nodeUid,
        requestParameters.importTagTypes,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Tag Type
   * @param {TagsApiUpdateTagTypeNodesNodeUidTagTypesTagTypeUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public updateTagTypeNodesNodeUidTagTypesTagTypeUidPut(
    requestParameters: TagsApiUpdateTagTypeNodesNodeUidTagTypesTagTypeUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TagsApiFp(this.configuration)
      .updateTagTypeNodesNodeUidTagTypesTagTypeUidPut(
        requestParameters.nodeUid,
        requestParameters.tagTypeUid,
        requestParameters.updateTagType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TaskDatasourcesApi - axios parameter creator
 * @export
 */
export const TaskDatasourcesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Load Config
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoadConfigNodesNodeUidDataSourcesLoadConfigGet: async (
      nodeUid: number,
      split: string,
      startDate?: string,
      endDate?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getLoadConfigNodesNodeUidDataSourcesLoadConfigGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'split' is not null or undefined
      assertParamExists(
        'getLoadConfigNodesNodeUidDataSourcesLoadConfigGet',
        'split',
        split,
      );
      const localVarPath = `/nodes/{node_uid}/data-sources/load-config`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get X Uids And Datasources
     * @param {number} nodeUid
     * @param {boolean} [isContext]
     * @param {Array<number>} [datasourceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getXUidsAndDatasourcesNodesNodeUidXUidsGet: async (
      nodeUid: number,
      isContext?: boolean,
      datasourceUid?: Array<number>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getXUidsAndDatasourcesNodesNodeUidXUidsGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/x-uids`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isContext !== undefined) {
        localVarQueryParameter['is_context'] = isContext;
      }

      if (datasourceUid) {
        localVarQueryParameter['datasource_uid'] = datasourceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List all dataset configs for task, optionally filtered by split and ds.
     * @summary List Task Datasources
     * @param {number} nodeUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [isActive]
     * @param {boolean} [supportsDev]
     * @param {boolean} [computeStatistics]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listTaskDatasourcesNodesNodeUidDataSourcesGet: async (
      nodeUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      isActive?: boolean,
      supportsDev?: boolean,
      computeStatistics?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'listTaskDatasourcesNodesNodeUidDataSourcesGet',
        'nodeUid',
        nodeUid,
      );
      const localVarPath = `/nodes/{node_uid}/data-sources`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] = endDate;
      }

      if (isActive !== undefined) {
        localVarQueryParameter['is_active'] = isActive;
      }

      if (supportsDev !== undefined) {
        localVarQueryParameter['supports_dev'] = supportsDev;
      }

      if (computeStatistics !== undefined) {
        localVarQueryParameter['compute_statistics'] = computeStatistics;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Task Datasource
     * @param {number} nodeUid
     * @param {number} datasourceUid
     * @param {PatchTaskDatasourceParams} patchTaskDatasourceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch: async (
      nodeUid: number,
      datasourceUid: number,
      patchTaskDatasourceParams: PatchTaskDatasourceParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'datasourceUid' is not null or undefined
      assertParamExists(
        'patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch',
        'datasourceUid',
        datasourceUid,
      );
      // verify required parameter 'patchTaskDatasourceParams' is not null or undefined
      assertParamExists(
        'patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch',
        'patchTaskDatasourceParams',
        patchTaskDatasourceParams,
      );
      const localVarPath = `/nodes/{node_uid}/data-sources/{datasource_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'datasource_uid'}}`,
          encodeURIComponent(String(datasourceUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchTaskDatasourceParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Task Datasources Active
     * @param {number} nodeUid
     * @param {TaskDatasourceBulkUpdateParams} taskDatasourceBulkUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch: async (
      nodeUid: number,
      taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'setTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'taskDatasourceBulkUpdateParams' is not null or undefined
      assertParamExists(
        'setTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch',
        'taskDatasourceBulkUpdateParams',
        taskDatasourceBulkUpdateParams,
      );
      const localVarPath = `/nodes/{node_uid}/data-sources/set-active`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        taskDatasourceBulkUpdateParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Task Datasources Inactive
     * @param {number} nodeUid
     * @param {TaskDatasourceBulkUpdateParams} taskDatasourceBulkUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch: async (
      nodeUid: number,
      taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'setTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'taskDatasourceBulkUpdateParams' is not null or undefined
      assertParamExists(
        'setTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch',
        'taskDatasourceBulkUpdateParams',
        taskDatasourceBulkUpdateParams,
      );
      const localVarPath =
        `/nodes/{node_uid}/data-sources/set-inactive`.replace(
          `{${'node_uid'}}`,
          encodeURIComponent(String(nodeUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        taskDatasourceBulkUpdateParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set Task Datasources No Supports Dev
     * @param {number} nodeUid
     * @param {TaskDatasourceBulkUpdateParams} taskDatasourceBulkUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch:
      async (
        nodeUid: number,
        taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'setTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'taskDatasourceBulkUpdateParams' is not null or undefined
        assertParamExists(
          'setTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch',
          'taskDatasourceBulkUpdateParams',
          taskDatasourceBulkUpdateParams,
        );
        const localVarPath =
          `/nodes/{node_uid}/data-sources/set-no-supports-dev`.replace(
            `{${'node_uid'}}`,
            encodeURIComponent(String(nodeUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          taskDatasourceBulkUpdateParams,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Set Task Datasources Supports Dev
     * @param {number} nodeUid
     * @param {TaskDatasourceBulkUpdateParams} taskDatasourceBulkUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch:
      async (
        nodeUid: number,
        taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'setTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'taskDatasourceBulkUpdateParams' is not null or undefined
        assertParamExists(
          'setTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch',
          'taskDatasourceBulkUpdateParams',
          taskDatasourceBulkUpdateParams,
        );
        const localVarPath =
          `/nodes/{node_uid}/data-sources/set-supports-dev`.replace(
            `{${'node_uid'}}`,
            encodeURIComponent(String(nodeUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'PATCH',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };
        localVarRequestOptions.data = serializeDataIfNeeded(
          taskDatasourceBulkUpdateParams,
          localVarRequestOptions,
          configuration,
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
  };
};

/**
 * TaskDatasourcesApi - functional programming interface
 * @export
 */
export const TaskDatasourcesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TaskDatasourcesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Load Config
     * @param {number} nodeUid
     * @param {string} split
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLoadConfigNodesNodeUidDataSourcesLoadConfigGet(
      nodeUid: number,
      split: string,
      startDate?: string,
      endDate?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLoadConfigNodesNodeUidDataSourcesLoadConfigGet(
          nodeUid,
          split,
          startDate,
          endDate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TaskDatasourcesApi.getLoadConfigNodesNodeUidDataSourcesLoadConfigGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get X Uids And Datasources
     * @param {number} nodeUid
     * @param {boolean} [isContext]
     * @param {Array<number>} [datasourceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getXUidsAndDatasourcesNodesNodeUidXUidsGet(
      nodeUid: number,
      isContext?: boolean,
      datasourceUid?: Array<number>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getXUidsAndDatasourcesNodesNodeUidXUidsGet(
          nodeUid,
          isContext,
          datasourceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TaskDatasourcesApi.getXUidsAndDatasourcesNodesNodeUidXUidsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List all dataset configs for task, optionally filtered by split and ds.
     * @summary List Task Datasources
     * @param {number} nodeUid
     * @param {string} [split]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {boolean} [isActive]
     * @param {boolean} [supportsDev]
     * @param {boolean} [computeStatistics]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listTaskDatasourcesNodesNodeUidDataSourcesGet(
      nodeUid: number,
      split?: string,
      startDate?: string,
      endDate?: string,
      isActive?: boolean,
      supportsDev?: boolean,
      computeStatistics?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TaskDatasourcesResponse>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listTaskDatasourcesNodesNodeUidDataSourcesGet(
          nodeUid,
          split,
          startDate,
          endDate,
          isActive,
          supportsDev,
          computeStatistics,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TaskDatasourcesApi.listTaskDatasourcesNodesNodeUidDataSourcesGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Task Datasource
     * @param {number} nodeUid
     * @param {number} datasourceUid
     * @param {PatchTaskDatasourceParams} patchTaskDatasourceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch(
      nodeUid: number,
      datasourceUid: number,
      patchTaskDatasourceParams: PatchTaskDatasourceParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch(
          nodeUid,
          datasourceUid,
          patchTaskDatasourceParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TaskDatasourcesApi.patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Set Task Datasources Active
     * @param {number} nodeUid
     * @param {TaskDatasourceBulkUpdateParams} taskDatasourceBulkUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch(
      nodeUid: number,
      taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch(
          nodeUid,
          taskDatasourceBulkUpdateParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TaskDatasourcesApi.setTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Set Task Datasources Inactive
     * @param {number} nodeUid
     * @param {TaskDatasourceBulkUpdateParams} taskDatasourceBulkUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch(
      nodeUid: number,
      taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch(
          nodeUid,
          taskDatasourceBulkUpdateParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TaskDatasourcesApi.setTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Set Task Datasources No Supports Dev
     * @param {number} nodeUid
     * @param {TaskDatasourceBulkUpdateParams} taskDatasourceBulkUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch(
      nodeUid: number,
      taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch(
          nodeUid,
          taskDatasourceBulkUpdateParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TaskDatasourcesApi.setTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Set Task Datasources Supports Dev
     * @param {number} nodeUid
     * @param {TaskDatasourceBulkUpdateParams} taskDatasourceBulkUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch(
      nodeUid: number,
      taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch(
          nodeUid,
          taskDatasourceBulkUpdateParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TaskDatasourcesApi.setTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TaskDatasourcesApi - factory interface
 * @export
 */
export const TaskDatasourcesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TaskDatasourcesApiFp(configuration);
  return {
    /**
     *
     * @summary Get Load Config
     * @param {TaskDatasourcesApiGetLoadConfigNodesNodeUidDataSourcesLoadConfigGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoadConfigNodesNodeUidDataSourcesLoadConfigGet(
      requestParameters: TaskDatasourcesApiGetLoadConfigNodesNodeUidDataSourcesLoadConfigGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getLoadConfigNodesNodeUidDataSourcesLoadConfigGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get X Uids And Datasources
     * @param {TaskDatasourcesApiGetXUidsAndDatasourcesNodesNodeUidXUidsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getXUidsAndDatasourcesNodesNodeUidXUidsGet(
      requestParameters: TaskDatasourcesApiGetXUidsAndDatasourcesNodesNodeUidXUidsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getXUidsAndDatasourcesNodesNodeUidXUidsGet(
          requestParameters.nodeUid,
          requestParameters.isContext,
          requestParameters.datasourceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List all dataset configs for task, optionally filtered by split and ds.
     * @summary List Task Datasources
     * @param {TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listTaskDatasourcesNodesNodeUidDataSourcesGet(
      requestParameters: TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<TaskDatasourcesResponse>> {
      return localVarFp
        .listTaskDatasourcesNodesNodeUidDataSourcesGet(
          requestParameters.nodeUid,
          requestParameters.split,
          requestParameters.startDate,
          requestParameters.endDate,
          requestParameters.isActive,
          requestParameters.supportsDev,
          requestParameters.computeStatistics,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Task Datasource
     * @param {TaskDatasourcesApiPatchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch(
      requestParameters: TaskDatasourcesApiPatchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch(
          requestParameters.nodeUid,
          requestParameters.datasourceUid,
          requestParameters.patchTaskDatasourceParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Set Task Datasources Active
     * @param {TaskDatasourcesApiSetTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch(
      requestParameters: TaskDatasourcesApiSetTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .setTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch(
          requestParameters.nodeUid,
          requestParameters.taskDatasourceBulkUpdateParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Set Task Datasources Inactive
     * @param {TaskDatasourcesApiSetTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch(
      requestParameters: TaskDatasourcesApiSetTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .setTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch(
          requestParameters.nodeUid,
          requestParameters.taskDatasourceBulkUpdateParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Set Task Datasources No Supports Dev
     * @param {TaskDatasourcesApiSetTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch(
      requestParameters: TaskDatasourcesApiSetTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .setTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch(
          requestParameters.nodeUid,
          requestParameters.taskDatasourceBulkUpdateParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Set Task Datasources Supports Dev
     * @param {TaskDatasourcesApiSetTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch(
      requestParameters: TaskDatasourcesApiSetTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .setTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch(
          requestParameters.nodeUid,
          requestParameters.taskDatasourceBulkUpdateParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getLoadConfigNodesNodeUidDataSourcesLoadConfigGet operation in TaskDatasourcesApi.
 * @export
 * @interface TaskDatasourcesApiGetLoadConfigNodesNodeUidDataSourcesLoadConfigGetRequest
 */
export interface TaskDatasourcesApiGetLoadConfigNodesNodeUidDataSourcesLoadConfigGetRequest {
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesApiGetLoadConfigNodesNodeUidDataSourcesLoadConfigGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof TaskDatasourcesApiGetLoadConfigNodesNodeUidDataSourcesLoadConfigGet
   */
  readonly split: string;

  /**
   *
   * @type {string}
   * @memberof TaskDatasourcesApiGetLoadConfigNodesNodeUidDataSourcesLoadConfigGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof TaskDatasourcesApiGetLoadConfigNodesNodeUidDataSourcesLoadConfigGet
   */
  readonly endDate?: string;
}

/**
 * Request parameters for getXUidsAndDatasourcesNodesNodeUidXUidsGet operation in TaskDatasourcesApi.
 * @export
 * @interface TaskDatasourcesApiGetXUidsAndDatasourcesNodesNodeUidXUidsGetRequest
 */
export interface TaskDatasourcesApiGetXUidsAndDatasourcesNodesNodeUidXUidsGetRequest {
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesApiGetXUidsAndDatasourcesNodesNodeUidXUidsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {boolean}
   * @memberof TaskDatasourcesApiGetXUidsAndDatasourcesNodesNodeUidXUidsGet
   */
  readonly isContext?: boolean;

  /**
   *
   * @type {Array<number>}
   * @memberof TaskDatasourcesApiGetXUidsAndDatasourcesNodesNodeUidXUidsGet
   */
  readonly datasourceUid?: Array<number>;
}

/**
 * Request parameters for listTaskDatasourcesNodesNodeUidDataSourcesGet operation in TaskDatasourcesApi.
 * @export
 * @interface TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGetRequest
 */
export interface TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGetRequest {
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {string}
   * @memberof TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGet
   */
  readonly split?: string;

  /**
   *
   * @type {string}
   * @memberof TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGet
   */
  readonly startDate?: string;

  /**
   *
   * @type {string}
   * @memberof TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGet
   */
  readonly endDate?: string;

  /**
   *
   * @type {boolean}
   * @memberof TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGet
   */
  readonly isActive?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGet
   */
  readonly supportsDev?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGet
   */
  readonly computeStatistics?: boolean;
}

/**
 * Request parameters for patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch operation in TaskDatasourcesApi.
 * @export
 * @interface TaskDatasourcesApiPatchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatchRequest
 */
export interface TaskDatasourcesApiPatchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatchRequest {
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesApiPatchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesApiPatchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch
   */
  readonly datasourceUid: number;

  /**
   *
   * @type {PatchTaskDatasourceParams}
   * @memberof TaskDatasourcesApiPatchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch
   */
  readonly patchTaskDatasourceParams: PatchTaskDatasourceParams;
}

/**
 * Request parameters for setTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch operation in TaskDatasourcesApi.
 * @export
 * @interface TaskDatasourcesApiSetTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatchRequest
 */
export interface TaskDatasourcesApiSetTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatchRequest {
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesApiSetTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TaskDatasourceBulkUpdateParams}
   * @memberof TaskDatasourcesApiSetTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch
   */
  readonly taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams;
}

/**
 * Request parameters for setTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch operation in TaskDatasourcesApi.
 * @export
 * @interface TaskDatasourcesApiSetTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatchRequest
 */
export interface TaskDatasourcesApiSetTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatchRequest {
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesApiSetTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TaskDatasourceBulkUpdateParams}
   * @memberof TaskDatasourcesApiSetTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch
   */
  readonly taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams;
}

/**
 * Request parameters for setTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch operation in TaskDatasourcesApi.
 * @export
 * @interface TaskDatasourcesApiSetTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatchRequest
 */
export interface TaskDatasourcesApiSetTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatchRequest {
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesApiSetTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TaskDatasourceBulkUpdateParams}
   * @memberof TaskDatasourcesApiSetTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch
   */
  readonly taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams;
}

/**
 * Request parameters for setTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch operation in TaskDatasourcesApi.
 * @export
 * @interface TaskDatasourcesApiSetTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatchRequest
 */
export interface TaskDatasourcesApiSetTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatchRequest {
  /**
   *
   * @type {number}
   * @memberof TaskDatasourcesApiSetTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TaskDatasourceBulkUpdateParams}
   * @memberof TaskDatasourcesApiSetTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch
   */
  readonly taskDatasourceBulkUpdateParams: TaskDatasourceBulkUpdateParams;
}

/**
 * TaskDatasourcesApi - object-oriented interface
 * @export
 * @class TaskDatasourcesApi
 * @extends {BaseAPI}
 */
export class TaskDatasourcesApi extends BaseAPI {
  /**
   *
   * @summary Get Load Config
   * @param {TaskDatasourcesApiGetLoadConfigNodesNodeUidDataSourcesLoadConfigGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskDatasourcesApi
   */
  public getLoadConfigNodesNodeUidDataSourcesLoadConfigGet(
    requestParameters: TaskDatasourcesApiGetLoadConfigNodesNodeUidDataSourcesLoadConfigGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TaskDatasourcesApiFp(this.configuration)
      .getLoadConfigNodesNodeUidDataSourcesLoadConfigGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get X Uids And Datasources
   * @param {TaskDatasourcesApiGetXUidsAndDatasourcesNodesNodeUidXUidsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskDatasourcesApi
   */
  public getXUidsAndDatasourcesNodesNodeUidXUidsGet(
    requestParameters: TaskDatasourcesApiGetXUidsAndDatasourcesNodesNodeUidXUidsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TaskDatasourcesApiFp(this.configuration)
      .getXUidsAndDatasourcesNodesNodeUidXUidsGet(
        requestParameters.nodeUid,
        requestParameters.isContext,
        requestParameters.datasourceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List all dataset configs for task, optionally filtered by split and ds.
   * @summary List Task Datasources
   * @param {TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskDatasourcesApi
   */
  public listTaskDatasourcesNodesNodeUidDataSourcesGet(
    requestParameters: TaskDatasourcesApiListTaskDatasourcesNodesNodeUidDataSourcesGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TaskDatasourcesApiFp(this.configuration)
      .listTaskDatasourcesNodesNodeUidDataSourcesGet(
        requestParameters.nodeUid,
        requestParameters.split,
        requestParameters.startDate,
        requestParameters.endDate,
        requestParameters.isActive,
        requestParameters.supportsDev,
        requestParameters.computeStatistics,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Task Datasource
   * @param {TaskDatasourcesApiPatchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskDatasourcesApi
   */
  public patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch(
    requestParameters: TaskDatasourcesApiPatchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TaskDatasourcesApiFp(this.configuration)
      .patchTaskDatasourceNodesNodeUidDataSourcesDatasourceUidPatch(
        requestParameters.nodeUid,
        requestParameters.datasourceUid,
        requestParameters.patchTaskDatasourceParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Task Datasources Active
   * @param {TaskDatasourcesApiSetTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskDatasourcesApi
   */
  public setTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch(
    requestParameters: TaskDatasourcesApiSetTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TaskDatasourcesApiFp(this.configuration)
      .setTaskDatasourcesActiveNodesNodeUidDataSourcesSetActivePatch(
        requestParameters.nodeUid,
        requestParameters.taskDatasourceBulkUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Task Datasources Inactive
   * @param {TaskDatasourcesApiSetTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskDatasourcesApi
   */
  public setTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch(
    requestParameters: TaskDatasourcesApiSetTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TaskDatasourcesApiFp(this.configuration)
      .setTaskDatasourcesInactiveNodesNodeUidDataSourcesSetInactivePatch(
        requestParameters.nodeUid,
        requestParameters.taskDatasourceBulkUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Task Datasources No Supports Dev
   * @param {TaskDatasourcesApiSetTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskDatasourcesApi
   */
  public setTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch(
    requestParameters: TaskDatasourcesApiSetTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TaskDatasourcesApiFp(this.configuration)
      .setTaskDatasourcesNoSupportsDevNodesNodeUidDataSourcesSetNoSupportsDevPatch(
        requestParameters.nodeUid,
        requestParameters.taskDatasourceBulkUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set Task Datasources Supports Dev
   * @param {TaskDatasourcesApiSetTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskDatasourcesApi
   */
  public setTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch(
    requestParameters: TaskDatasourcesApiSetTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TaskDatasourcesApiFp(this.configuration)
      .setTaskDatasourcesSupportsDevNodesNodeUidDataSourcesSetSupportsDevPatch(
        requestParameters.nodeUid,
        requestParameters.taskDatasourceBulkUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * List probabilistic label counts for each task version.
     * @summary Get Task
     * @param {number} taskUid
     * @param {boolean} [duplicateTask]
     * @param {boolean} [trainingSetIsActive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskTasksTaskUidGet: async (
      taskUid: number,
      duplicateTask?: boolean,
      trainingSetIsActive?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskUid' is not null or undefined
      assertParamExists('getTaskTasksTaskUidGet', 'taskUid', taskUid);
      const localVarPath = `/tasks/{task_uid}`.replace(
        `{${'task_uid'}}`,
        encodeURIComponent(String(taskUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (duplicateTask !== undefined) {
        localVarQueryParameter['duplicate_task'] = duplicateTask;
      }

      if (trainingSetIsActive !== undefined) {
        localVarQueryParameter['training_set_is_active'] = trainingSetIsActive;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration);
  return {
    /**
     * List probabilistic label counts for each task version.
     * @summary Get Task
     * @param {number} taskUid
     * @param {boolean} [duplicateTask]
     * @param {boolean} [trainingSetIsActive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTaskTasksTaskUidGet(
      taskUid: number,
      duplicateTask?: boolean,
      trainingSetIsActive?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTaskTasksTaskUidGet(
          taskUid,
          duplicateTask,
          trainingSetIsActive,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TasksApi.getTaskTasksTaskUidGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TasksApiFp(configuration);
  return {
    /**
     * List probabilistic label counts for each task version.
     * @summary Get Task
     * @param {TasksApiGetTaskTasksTaskUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskTasksTaskUidGet(
      requestParameters: TasksApiGetTaskTasksTaskUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getTaskTasksTaskUidGet(
          requestParameters.taskUid,
          requestParameters.duplicateTask,
          requestParameters.trainingSetIsActive,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getTaskTasksTaskUidGet operation in TasksApi.
 * @export
 * @interface TasksApiGetTaskTasksTaskUidGetRequest
 */
export interface TasksApiGetTaskTasksTaskUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof TasksApiGetTaskTasksTaskUidGet
   */
  readonly taskUid: number;

  /**
   *
   * @type {boolean}
   * @memberof TasksApiGetTaskTasksTaskUidGet
   */
  readonly duplicateTask?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof TasksApiGetTaskTasksTaskUidGet
   */
  readonly trainingSetIsActive?: boolean;
}

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
  /**
   * List probabilistic label counts for each task version.
   * @summary Get Task
   * @param {TasksApiGetTaskTasksTaskUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public getTaskTasksTaskUidGet(
    requestParameters: TasksApiGetTaskTasksTaskUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .getTaskTasksTaskUidGet(
        requestParameters.taskUid,
        requestParameters.duplicateTask,
        requestParameters.trainingSetIsActive,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TmpFilesApi - axios parameter creator
 * @export
 */
export const TmpFilesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Upload
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadTmpFilesUploadPost: async (
      file: File,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('uploadTmpFilesUploadPost', 'file', file);
      const localVarPath = `/tmp_files/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TmpFilesApi - functional programming interface
 * @export
 */
export const TmpFilesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TmpFilesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Upload
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadTmpFilesUploadPost(
      file: File,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadFileResponseModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadTmpFilesUploadPost(file, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['TmpFilesApi.uploadTmpFilesUploadPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TmpFilesApi - factory interface
 * @export
 */
export const TmpFilesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TmpFilesApiFp(configuration);
  return {
    /**
     *
     * @summary Upload
     * @param {TmpFilesApiUploadTmpFilesUploadPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadTmpFilesUploadPost(
      requestParameters: TmpFilesApiUploadTmpFilesUploadPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UploadFileResponseModel> {
      return localVarFp
        .uploadTmpFilesUploadPost(requestParameters.file, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for uploadTmpFilesUploadPost operation in TmpFilesApi.
 * @export
 * @interface TmpFilesApiUploadTmpFilesUploadPostRequest
 */
export interface TmpFilesApiUploadTmpFilesUploadPostRequest {
  /**
   *
   * @type {File}
   * @memberof TmpFilesApiUploadTmpFilesUploadPost
   */
  readonly file: File;
}

/**
 * TmpFilesApi - object-oriented interface
 * @export
 * @class TmpFilesApi
 * @extends {BaseAPI}
 */
export class TmpFilesApi extends BaseAPI {
  /**
   *
   * @summary Upload
   * @param {TmpFilesApiUploadTmpFilesUploadPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TmpFilesApi
   */
  public uploadTmpFilesUploadPost(
    requestParameters: TmpFilesApiUploadTmpFilesUploadPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TmpFilesApiFp(this.configuration)
      .uploadTmpFilesUploadPost(requestParameters.file, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TrainingSetsApi - axios parameter creator
 * @export
 */
export const TrainingSetsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add Training Set Direct
     * @param {number} nodeUid
     * @param {DirectTrainingSetPayload} directTrainingSetPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTrainingSetDirectNodesNodeUidTrainingSetDirectPost: async (
      nodeUid: number,
      directTrainingSetPayload: DirectTrainingSetPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addTrainingSetDirectNodesNodeUidTrainingSetDirectPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'directTrainingSetPayload' is not null or undefined
      assertParamExists(
        'addTrainingSetDirectNodesNodeUidTrainingSetDirectPost',
        'directTrainingSetPayload',
        directTrainingSetPayload,
      );
      const localVarPath = `/nodes/{node_uid}/training-set-direct`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        directTrainingSetPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add Training Set
     * @param {number} nodeUid
     * @param {TrainingSet} trainingSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTrainingSetNodesNodeUidAddTrainingSetPost: async (
      nodeUid: number,
      trainingSet: TrainingSet,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'addTrainingSetNodesNodeUidAddTrainingSetPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'trainingSet' is not null or undefined
      assertParamExists(
        'addTrainingSetNodesNodeUidAddTrainingSetPost',
        'trainingSet',
        trainingSet,
      );
      const localVarPath = `/nodes/{node_uid}/add-training-set`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        trainingSet,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Metadata
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet: async (
      nodeUid: number,
      trainingSet: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'getMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'trainingSet' is not null or undefined
      assertParamExists(
        'getMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet',
        'trainingSet',
        trainingSet,
      );
      const localVarPath =
        `/nodes/{node_uid}/training-sets/{training_set}/metadata`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(
            `{${'training_set'}}`,
            encodeURIComponent(String(trainingSet)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Training Set Statistics
     * @param {number} nodeUid
     * @param {number} trainingSetUid
     * @param {number} [sampleSize]
     * @param {number} [randomSeed]
     * @param {string} [split]
     * @param {boolean} [ignoreCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet:
      async (
        nodeUid: number,
        trainingSetUid: number,
        sampleSize?: number,
        randomSeed?: number,
        split?: string,
        ignoreCache?: boolean,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'trainingSetUid' is not null or undefined
        assertParamExists(
          'getTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet',
          'trainingSetUid',
          trainingSetUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/training-sets/{training_set_uid}/statistics`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(
              `{${'training_set_uid'}}`,
              encodeURIComponent(String(trainingSetUid)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (sampleSize !== undefined) {
          localVarQueryParameter['sample_size'] = sampleSize;
        }

        if (randomSeed !== undefined) {
          localVarQueryParameter['random_seed'] = randomSeed;
        }

        if (split !== undefined) {
          localVarQueryParameter['split'] = split;
        }

        if (ignoreCache !== undefined) {
          localVarQueryParameter['ignore_cache'] = ignoreCache;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Label
     * @param {number} nodeUid
     * @param {LabelJob} labelJob
     * @param {string} [name]
     * @param {boolean} [isActive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    labelNodesNodeUidTrainingSetsPost: async (
      nodeUid: number,
      labelJob: LabelJob,
      name?: string,
      isActive?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'labelNodesNodeUidTrainingSetsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'labelJob' is not null or undefined
      assertParamExists(
        'labelNodesNodeUidTrainingSetsPost',
        'labelJob',
        labelJob,
      );
      const localVarPath = `/nodes/{node_uid}/training-sets`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (isActive !== undefined) {
        localVarQueryParameter['is_active'] = isActive;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        labelJob,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a boolean dictionary of whether each x_uid is labeled correctly by a training set
     * @summary Load Training Set Correctness
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {string} [split]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet:
      async (
        nodeUid: number,
        trainingSet: number,
        split?: string,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'loadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet',
          'nodeUid',
          nodeUid,
        );
        // verify required parameter 'trainingSet' is not null or undefined
        assertParamExists(
          'loadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet',
          'trainingSet',
          trainingSet,
        );
        const localVarPath =
          `/nodes/{node_uid}/training-sets/{training_set}/correctness-breakdown`
            .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
            .replace(
              `{${'training_set'}}`,
              encodeURIComponent(String(trainingSet)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        if (split !== undefined) {
          localVarQueryParameter['split'] = split;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Load Training Set Labels
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {string} [split]
     * @param {boolean} [filterUnlabeled]
     * @param {boolean} [filterUncertainLabels]
     * @param {boolean} [loadGroundTruth]
     * @param {boolean} [discretizeLabels]
     * @param {string} [tieBreakPolicy]
     * @param {string} [samplerStrategy]
     * @param {string} [samplerParams]
     * @param {string} [samplerClassCounts]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet: async (
      nodeUid: number,
      trainingSet: number,
      split?: string,
      filterUnlabeled?: boolean,
      filterUncertainLabels?: boolean,
      loadGroundTruth?: boolean,
      discretizeLabels?: boolean,
      tieBreakPolicy?: string,
      samplerStrategy?: string,
      samplerParams?: string,
      samplerClassCounts?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'loadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'trainingSet' is not null or undefined
      assertParamExists(
        'loadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet',
        'trainingSet',
        trainingSet,
      );
      const localVarPath =
        `/nodes/{node_uid}/training-sets/{training_set}/labels`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(
            `{${'training_set'}}`,
            encodeURIComponent(String(trainingSet)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (filterUnlabeled !== undefined) {
        localVarQueryParameter['filter_unlabeled'] = filterUnlabeled;
      }

      if (filterUncertainLabels !== undefined) {
        localVarQueryParameter['filter_uncertain_labels'] =
          filterUncertainLabels;
      }

      if (loadGroundTruth !== undefined) {
        localVarQueryParameter['load_ground_truth'] = loadGroundTruth;
      }

      if (discretizeLabels !== undefined) {
        localVarQueryParameter['discretize_labels'] = discretizeLabels;
      }

      if (tieBreakPolicy !== undefined) {
        localVarQueryParameter['tie_break_policy'] = tieBreakPolicy;
      }

      if (samplerStrategy !== undefined) {
        localVarQueryParameter['sampler_strategy'] = samplerStrategy;
      }

      if (samplerParams !== undefined) {
        localVarQueryParameter['sampler_params'] = samplerParams;
      }

      if (samplerClassCounts !== undefined) {
        localVarQueryParameter['sampler_class_counts'] = samplerClassCounts;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Load Xuids And Labels
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {string} [split]
     * @param {boolean} [filterUnlabeled]
     * @param {boolean} [filterUncertainLabels]
     * @param {boolean} [loadGroundTruth]
     * @param {boolean} [discretizeLabels]
     * @param {string} [tieBreakPolicy]
     * @param {string} [samplerStrategy]
     * @param {string} [samplerParams]
     * @param {string} [samplerClassCounts]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet: async (
      nodeUid: number,
      trainingSet: number,
      split?: string,
      filterUnlabeled?: boolean,
      filterUncertainLabels?: boolean,
      loadGroundTruth?: boolean,
      discretizeLabels?: boolean,
      tieBreakPolicy?: string,
      samplerStrategy?: string,
      samplerParams?: string,
      samplerClassCounts?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'loadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'trainingSet' is not null or undefined
      assertParamExists(
        'loadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet',
        'trainingSet',
        trainingSet,
      );
      const localVarPath = `/nodes/{node_uid}/training-sets/{training_set}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'training_set'}}`,
          encodeURIComponent(String(trainingSet)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (split !== undefined) {
        localVarQueryParameter['split'] = split;
      }

      if (filterUnlabeled !== undefined) {
        localVarQueryParameter['filter_unlabeled'] = filterUnlabeled;
      }

      if (filterUncertainLabels !== undefined) {
        localVarQueryParameter['filter_uncertain_labels'] =
          filterUncertainLabels;
      }

      if (loadGroundTruth !== undefined) {
        localVarQueryParameter['load_ground_truth'] = loadGroundTruth;
      }

      if (discretizeLabels !== undefined) {
        localVarQueryParameter['discretize_labels'] = discretizeLabels;
      }

      if (tieBreakPolicy !== undefined) {
        localVarQueryParameter['tie_break_policy'] = tieBreakPolicy;
      }

      if (samplerStrategy !== undefined) {
        localVarQueryParameter['sampler_strategy'] = samplerStrategy;
      }

      if (samplerParams !== undefined) {
        localVarQueryParameter['sampler_params'] = samplerParams;
      }

      if (samplerClassCounts !== undefined) {
        localVarQueryParameter['sampler_class_counts'] = samplerClassCounts;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Package Label Train
     * @param {number} nodeUid
     * @param {PackageLabelTrainApiModel} packageLabelTrainApiModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packageLabelTrainNodesNodeUidPackageLabelTrainPost: async (
      nodeUid: number,
      packageLabelTrainApiModel: PackageLabelTrainApiModel,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'packageLabelTrainNodesNodeUidPackageLabelTrainPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'packageLabelTrainApiModel' is not null or undefined
      assertParamExists(
        'packageLabelTrainNodesNodeUidPackageLabelTrainPost',
        'packageLabelTrainApiModel',
        packageLabelTrainApiModel,
      );
      const localVarPath = `/nodes/{node_uid}/package-label-train`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        packageLabelTrainApiModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove Training Set
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {boolean} [deleteUptoVersion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete: async (
      nodeUid: number,
      trainingSet: number,
      deleteUptoVersion?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'removeTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'trainingSet' is not null or undefined
      assertParamExists(
        'removeTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete',
        'trainingSet',
        trainingSet,
      );
      const localVarPath = `/nodes/{node_uid}/training-sets/{training_set}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'training_set'}}`,
          encodeURIComponent(String(trainingSet)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deleteUptoVersion !== undefined) {
        localVarQueryParameter['delete_upto_version'] = deleteUptoVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search Xuids And Labels
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {FetchTrainingSetLabels} fetchTrainingSetLabels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost: async (
      nodeUid: number,
      trainingSet: number,
      fetchTrainingSetLabels: FetchTrainingSetLabels,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'trainingSet' is not null or undefined
      assertParamExists(
        'searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost',
        'trainingSet',
        trainingSet,
      );
      // verify required parameter 'fetchTrainingSetLabels' is not null or undefined
      assertParamExists(
        'searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost',
        'fetchTrainingSetLabels',
        fetchTrainingSetLabels,
      );
      const localVarPath = `/nodes/{node_uid}/training-sets/{training_set}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'training_set'}}`,
          encodeURIComponent(String(trainingSet)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fetchTrainingSetLabels,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Training Set
     * @param {number} nodeUid
     * @param {number} trainingSetUid
     * @param {UpdateTrainingSetParams} updateTrainingSetParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut: async (
      nodeUid: number,
      trainingSetUid: number,
      updateTrainingSetParams: UpdateTrainingSetParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'trainingSetUid' is not null or undefined
      assertParamExists(
        'updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut',
        'trainingSetUid',
        trainingSetUid,
      );
      // verify required parameter 'updateTrainingSetParams' is not null or undefined
      assertParamExists(
        'updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut',
        'updateTrainingSetParams',
        updateTrainingSetParams,
      );
      const localVarPath = `/nodes/{node_uid}/training-sets/{training_set_uid}`
        .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
        .replace(
          `{${'training_set_uid'}}`,
          encodeURIComponent(String(trainingSetUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTrainingSetParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TrainingSetsApi - functional programming interface
 * @export
 */
export const TrainingSetsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TrainingSetsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add Training Set Direct
     * @param {number} nodeUid
     * @param {DirectTrainingSetPayload} directTrainingSetPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addTrainingSetDirectNodesNodeUidTrainingSetDirectPost(
      nodeUid: number,
      directTrainingSetPayload: DirectTrainingSetPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DirectTrainingSetResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addTrainingSetDirectNodesNodeUidTrainingSetDirectPost(
          nodeUid,
          directTrainingSetPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.addTrainingSetDirectNodesNodeUidTrainingSetDirectPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Add Training Set
     * @param {number} nodeUid
     * @param {TrainingSet} trainingSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addTrainingSetNodesNodeUidAddTrainingSetPost(
      nodeUid: number,
      trainingSet: TrainingSet,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TrainingSetResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addTrainingSetNodesNodeUidAddTrainingSetPost(
          nodeUid,
          trainingSet,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.addTrainingSetNodesNodeUidAddTrainingSetPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Metadata
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet(
      nodeUid: number,
      trainingSet: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TrainingSetMetadataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet(
          nodeUid,
          trainingSet,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.getMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Training Set Statistics
     * @param {number} nodeUid
     * @param {number} trainingSetUid
     * @param {number} [sampleSize]
     * @param {number} [randomSeed]
     * @param {string} [split]
     * @param {boolean} [ignoreCache]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet(
      nodeUid: number,
      trainingSetUid: number,
      sampleSize?: number,
      randomSeed?: number,
      split?: string,
      ignoreCache?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TrainingSetStats>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet(
          nodeUid,
          trainingSetUid,
          sampleSize,
          randomSeed,
          split,
          ignoreCache,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.getTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Label
     * @param {number} nodeUid
     * @param {LabelJob} labelJob
     * @param {string} [name]
     * @param {boolean} [isActive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async labelNodesNodeUidTrainingSetsPost(
      nodeUid: number,
      labelJob: LabelJob,
      name?: string,
      isActive?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.labelNodesNodeUidTrainingSetsPost(
          nodeUid,
          labelJob,
          name,
          isActive,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.labelNodesNodeUidTrainingSetsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Returns a boolean dictionary of whether each x_uid is labeled correctly by a training set
     * @summary Load Training Set Correctness
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {string} [split]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet(
      nodeUid: number,
      trainingSet: number,
      split?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TrainingSetCorrectnessBreakdown>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.loadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet(
          nodeUid,
          trainingSet,
          split,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.loadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Load Training Set Labels
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {string} [split]
     * @param {boolean} [filterUnlabeled]
     * @param {boolean} [filterUncertainLabels]
     * @param {boolean} [loadGroundTruth]
     * @param {boolean} [discretizeLabels]
     * @param {string} [tieBreakPolicy]
     * @param {string} [samplerStrategy]
     * @param {string} [samplerParams]
     * @param {string} [samplerClassCounts]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet(
      nodeUid: number,
      trainingSet: number,
      split?: string,
      filterUnlabeled?: boolean,
      filterUncertainLabels?: boolean,
      loadGroundTruth?: boolean,
      discretizeLabels?: boolean,
      tieBreakPolicy?: string,
      samplerStrategy?: string,
      samplerParams?: string,
      samplerClassCounts?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.loadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet(
          nodeUid,
          trainingSet,
          split,
          filterUnlabeled,
          filterUncertainLabels,
          loadGroundTruth,
          discretizeLabels,
          tieBreakPolicy,
          samplerStrategy,
          samplerParams,
          samplerClassCounts,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.loadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Load Xuids And Labels
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {string} [split]
     * @param {boolean} [filterUnlabeled]
     * @param {boolean} [filterUncertainLabels]
     * @param {boolean} [loadGroundTruth]
     * @param {boolean} [discretizeLabels]
     * @param {string} [tieBreakPolicy]
     * @param {string} [samplerStrategy]
     * @param {string} [samplerParams]
     * @param {string} [samplerClassCounts]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet(
      nodeUid: number,
      trainingSet: number,
      split?: string,
      filterUnlabeled?: boolean,
      filterUncertainLabels?: boolean,
      loadGroundTruth?: boolean,
      discretizeLabels?: boolean,
      tieBreakPolicy?: string,
      samplerStrategy?: string,
      samplerParams?: string,
      samplerClassCounts?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.loadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet(
          nodeUid,
          trainingSet,
          split,
          filterUnlabeled,
          filterUncertainLabels,
          loadGroundTruth,
          discretizeLabels,
          tieBreakPolicy,
          samplerStrategy,
          samplerParams,
          samplerClassCounts,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.loadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Package Label Train
     * @param {number} nodeUid
     * @param {PackageLabelTrainApiModel} packageLabelTrainApiModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async packageLabelTrainNodesNodeUidPackageLabelTrainPost(
      nodeUid: number,
      packageLabelTrainApiModel: PackageLabelTrainApiModel,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PackageLabelTrainResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.packageLabelTrainNodesNodeUidPackageLabelTrainPost(
          nodeUid,
          packageLabelTrainApiModel,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.packageLabelTrainNodesNodeUidPackageLabelTrainPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Remove Training Set
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {boolean} [deleteUptoVersion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete(
      nodeUid: number,
      trainingSet: number,
      deleteUptoVersion?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete(
          nodeUid,
          trainingSet,
          deleteUptoVersion,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.removeTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Search Xuids And Labels
     * @param {number} nodeUid
     * @param {number} trainingSet
     * @param {FetchTrainingSetLabels} fetchTrainingSetLabels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost(
      nodeUid: number,
      trainingSet: number,
      fetchTrainingSetLabels: FetchTrainingSetLabels,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchTrainingSetResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost(
          nodeUid,
          trainingSet,
          fetchTrainingSetLabels,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Training Set
     * @param {number} nodeUid
     * @param {number} trainingSetUid
     * @param {UpdateTrainingSetParams} updateTrainingSetParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut(
      nodeUid: number,
      trainingSetUid: number,
      updateTrainingSetParams: UpdateTrainingSetParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut(
          nodeUid,
          trainingSetUid,
          updateTrainingSetParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TrainingSetsApi.updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TrainingSetsApi - factory interface
 * @export
 */
export const TrainingSetsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TrainingSetsApiFp(configuration);
  return {
    /**
     *
     * @summary Add Training Set Direct
     * @param {TrainingSetsApiAddTrainingSetDirectNodesNodeUidTrainingSetDirectPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTrainingSetDirectNodesNodeUidTrainingSetDirectPost(
      requestParameters: TrainingSetsApiAddTrainingSetDirectNodesNodeUidTrainingSetDirectPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DirectTrainingSetResponse> {
      return localVarFp
        .addTrainingSetDirectNodesNodeUidTrainingSetDirectPost(
          requestParameters.nodeUid,
          requestParameters.directTrainingSetPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Add Training Set
     * @param {TrainingSetsApiAddTrainingSetNodesNodeUidAddTrainingSetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addTrainingSetNodesNodeUidAddTrainingSetPost(
      requestParameters: TrainingSetsApiAddTrainingSetNodesNodeUidAddTrainingSetPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TrainingSetResponse> {
      return localVarFp
        .addTrainingSetNodesNodeUidAddTrainingSetPost(
          requestParameters.nodeUid,
          requestParameters.trainingSet,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Metadata
     * @param {TrainingSetsApiGetMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet(
      requestParameters: TrainingSetsApiGetMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TrainingSetMetadataResponse> {
      return localVarFp
        .getMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet(
          requestParameters.nodeUid,
          requestParameters.trainingSet,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Training Set Statistics
     * @param {TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet(
      requestParameters: TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TrainingSetStats> {
      return localVarFp
        .getTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet(
          requestParameters.nodeUid,
          requestParameters.trainingSetUid,
          requestParameters.sampleSize,
          requestParameters.randomSeed,
          requestParameters.split,
          requestParameters.ignoreCache,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Label
     * @param {TrainingSetsApiLabelNodesNodeUidTrainingSetsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    labelNodesNodeUidTrainingSetsPost(
      requestParameters: TrainingSetsApiLabelNodesNodeUidTrainingSetsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .labelNodesNodeUidTrainingSetsPost(
          requestParameters.nodeUid,
          requestParameters.labelJob,
          requestParameters.name,
          requestParameters.isActive,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Returns a boolean dictionary of whether each x_uid is labeled correctly by a training set
     * @summary Load Training Set Correctness
     * @param {TrainingSetsApiLoadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet(
      requestParameters: TrainingSetsApiLoadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TrainingSetCorrectnessBreakdown> {
      return localVarFp
        .loadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet(
          requestParameters.nodeUid,
          requestParameters.trainingSet,
          requestParameters.split,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Load Training Set Labels
     * @param {TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet(
      requestParameters: TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .loadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet(
          requestParameters.nodeUid,
          requestParameters.trainingSet,
          requestParameters.split,
          requestParameters.filterUnlabeled,
          requestParameters.filterUncertainLabels,
          requestParameters.loadGroundTruth,
          requestParameters.discretizeLabels,
          requestParameters.tieBreakPolicy,
          requestParameters.samplerStrategy,
          requestParameters.samplerParams,
          requestParameters.samplerClassCounts,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Load Xuids And Labels
     * @param {TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet(
      requestParameters: TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .loadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet(
          requestParameters.nodeUid,
          requestParameters.trainingSet,
          requestParameters.split,
          requestParameters.filterUnlabeled,
          requestParameters.filterUncertainLabels,
          requestParameters.loadGroundTruth,
          requestParameters.discretizeLabels,
          requestParameters.tieBreakPolicy,
          requestParameters.samplerStrategy,
          requestParameters.samplerParams,
          requestParameters.samplerClassCounts,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Package Label Train
     * @param {TrainingSetsApiPackageLabelTrainNodesNodeUidPackageLabelTrainPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    packageLabelTrainNodesNodeUidPackageLabelTrainPost(
      requestParameters: TrainingSetsApiPackageLabelTrainNodesNodeUidPackageLabelTrainPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PackageLabelTrainResponse> {
      return localVarFp
        .packageLabelTrainNodesNodeUidPackageLabelTrainPost(
          requestParameters.nodeUid,
          requestParameters.packageLabelTrainApiModel,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Remove Training Set
     * @param {TrainingSetsApiRemoveTrainingSetNodesNodeUidTrainingSetsTrainingSetDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete(
      requestParameters: TrainingSetsApiRemoveTrainingSetNodesNodeUidTrainingSetsTrainingSetDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .removeTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete(
          requestParameters.nodeUid,
          requestParameters.trainingSet,
          requestParameters.deleteUptoVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search Xuids And Labels
     * @param {TrainingSetsApiSearchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost(
      requestParameters: TrainingSetsApiSearchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SearchTrainingSetResponse> {
      return localVarFp
        .searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost(
          requestParameters.nodeUid,
          requestParameters.trainingSet,
          requestParameters.fetchTrainingSetLabels,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Training Set
     * @param {TrainingSetsApiUpdateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut(
      requestParameters: TrainingSetsApiUpdateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut(
          requestParameters.nodeUid,
          requestParameters.trainingSetUid,
          requestParameters.updateTrainingSetParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for addTrainingSetDirectNodesNodeUidTrainingSetDirectPost operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiAddTrainingSetDirectNodesNodeUidTrainingSetDirectPostRequest
 */
export interface TrainingSetsApiAddTrainingSetDirectNodesNodeUidTrainingSetDirectPostRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiAddTrainingSetDirectNodesNodeUidTrainingSetDirectPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {DirectTrainingSetPayload}
   * @memberof TrainingSetsApiAddTrainingSetDirectNodesNodeUidTrainingSetDirectPost
   */
  readonly directTrainingSetPayload: DirectTrainingSetPayload;
}

/**
 * Request parameters for addTrainingSetNodesNodeUidAddTrainingSetPost operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiAddTrainingSetNodesNodeUidAddTrainingSetPostRequest
 */
export interface TrainingSetsApiAddTrainingSetNodesNodeUidAddTrainingSetPostRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiAddTrainingSetNodesNodeUidAddTrainingSetPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TrainingSet}
   * @memberof TrainingSetsApiAddTrainingSetNodesNodeUidAddTrainingSetPost
   */
  readonly trainingSet: TrainingSet;
}

/**
 * Request parameters for getMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiGetMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGetRequest
 */
export interface TrainingSetsApiGetMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGetRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiGetMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiGetMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet
   */
  readonly trainingSet: number;
}

/**
 * Request parameters for getTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGetRequest
 */
export interface TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGetRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet
   */
  readonly trainingSetUid: number;

  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet
   */
  readonly sampleSize?: number;

  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet
   */
  readonly randomSeed?: number;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet
   */
  readonly split?: string;

  /**
   *
   * @type {boolean}
   * @memberof TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet
   */
  readonly ignoreCache?: boolean;
}

/**
 * Request parameters for labelNodesNodeUidTrainingSetsPost operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiLabelNodesNodeUidTrainingSetsPostRequest
 */
export interface TrainingSetsApiLabelNodesNodeUidTrainingSetsPostRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiLabelNodesNodeUidTrainingSetsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {LabelJob}
   * @memberof TrainingSetsApiLabelNodesNodeUidTrainingSetsPost
   */
  readonly labelJob: LabelJob;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLabelNodesNodeUidTrainingSetsPost
   */
  readonly name?: string;

  /**
   *
   * @type {boolean}
   * @memberof TrainingSetsApiLabelNodesNodeUidTrainingSetsPost
   */
  readonly isActive?: boolean;
}

/**
 * Request parameters for loadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiLoadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGetRequest
 */
export interface TrainingSetsApiLoadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGetRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiLoadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiLoadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet
   */
  readonly trainingSet: number;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLoadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet
   */
  readonly split?: string;
}

/**
 * Request parameters for loadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGetRequest
 */
export interface TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGetRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet
   */
  readonly trainingSet: number;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet
   */
  readonly split?: string;

  /**
   *
   * @type {boolean}
   * @memberof TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet
   */
  readonly filterUnlabeled?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet
   */
  readonly filterUncertainLabels?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet
   */
  readonly loadGroundTruth?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet
   */
  readonly discretizeLabels?: boolean;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet
   */
  readonly tieBreakPolicy?: string;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet
   */
  readonly samplerStrategy?: string;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet
   */
  readonly samplerParams?: string;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet
   */
  readonly samplerClassCounts?: string;
}

/**
 * Request parameters for loadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGetRequest
 */
export interface TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGetRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet
   */
  readonly trainingSet: number;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet
   */
  readonly split?: string;

  /**
   *
   * @type {boolean}
   * @memberof TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet
   */
  readonly filterUnlabeled?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet
   */
  readonly filterUncertainLabels?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet
   */
  readonly loadGroundTruth?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet
   */
  readonly discretizeLabels?: boolean;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet
   */
  readonly tieBreakPolicy?: string;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet
   */
  readonly samplerStrategy?: string;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet
   */
  readonly samplerParams?: string;

  /**
   *
   * @type {string}
   * @memberof TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet
   */
  readonly samplerClassCounts?: string;
}

/**
 * Request parameters for packageLabelTrainNodesNodeUidPackageLabelTrainPost operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiPackageLabelTrainNodesNodeUidPackageLabelTrainPostRequest
 */
export interface TrainingSetsApiPackageLabelTrainNodesNodeUidPackageLabelTrainPostRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiPackageLabelTrainNodesNodeUidPackageLabelTrainPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {PackageLabelTrainApiModel}
   * @memberof TrainingSetsApiPackageLabelTrainNodesNodeUidPackageLabelTrainPost
   */
  readonly packageLabelTrainApiModel: PackageLabelTrainApiModel;
}

/**
 * Request parameters for removeTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiRemoveTrainingSetNodesNodeUidTrainingSetsTrainingSetDeleteRequest
 */
export interface TrainingSetsApiRemoveTrainingSetNodesNodeUidTrainingSetsTrainingSetDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiRemoveTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiRemoveTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete
   */
  readonly trainingSet: number;

  /**
   *
   * @type {boolean}
   * @memberof TrainingSetsApiRemoveTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete
   */
  readonly deleteUptoVersion?: boolean;
}

/**
 * Request parameters for searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiSearchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPostRequest
 */
export interface TrainingSetsApiSearchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPostRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiSearchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiSearchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost
   */
  readonly trainingSet: number;

  /**
   *
   * @type {FetchTrainingSetLabels}
   * @memberof TrainingSetsApiSearchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost
   */
  readonly fetchTrainingSetLabels: FetchTrainingSetLabels;
}

/**
 * Request parameters for updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut operation in TrainingSetsApi.
 * @export
 * @interface TrainingSetsApiUpdateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPutRequest
 */
export interface TrainingSetsApiUpdateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiUpdateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof TrainingSetsApiUpdateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut
   */
  readonly trainingSetUid: number;

  /**
   *
   * @type {UpdateTrainingSetParams}
   * @memberof TrainingSetsApiUpdateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut
   */
  readonly updateTrainingSetParams: UpdateTrainingSetParams;
}

/**
 * TrainingSetsApi - object-oriented interface
 * @export
 * @class TrainingSetsApi
 * @extends {BaseAPI}
 */
export class TrainingSetsApi extends BaseAPI {
  /**
   *
   * @summary Add Training Set Direct
   * @param {TrainingSetsApiAddTrainingSetDirectNodesNodeUidTrainingSetDirectPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public addTrainingSetDirectNodesNodeUidTrainingSetDirectPost(
    requestParameters: TrainingSetsApiAddTrainingSetDirectNodesNodeUidTrainingSetDirectPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .addTrainingSetDirectNodesNodeUidTrainingSetDirectPost(
        requestParameters.nodeUid,
        requestParameters.directTrainingSetPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add Training Set
   * @param {TrainingSetsApiAddTrainingSetNodesNodeUidAddTrainingSetPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public addTrainingSetNodesNodeUidAddTrainingSetPost(
    requestParameters: TrainingSetsApiAddTrainingSetNodesNodeUidAddTrainingSetPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .addTrainingSetNodesNodeUidAddTrainingSetPost(
        requestParameters.nodeUid,
        requestParameters.trainingSet,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Metadata
   * @param {TrainingSetsApiGetMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public getMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet(
    requestParameters: TrainingSetsApiGetMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .getMetadataNodesNodeUidTrainingSetsTrainingSetMetadataGet(
        requestParameters.nodeUid,
        requestParameters.trainingSet,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Training Set Statistics
   * @param {TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public getTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet(
    requestParameters: TrainingSetsApiGetTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .getTrainingSetStatisticsNodesNodeUidTrainingSetsTrainingSetUidStatisticsGet(
        requestParameters.nodeUid,
        requestParameters.trainingSetUid,
        requestParameters.sampleSize,
        requestParameters.randomSeed,
        requestParameters.split,
        requestParameters.ignoreCache,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Label
   * @param {TrainingSetsApiLabelNodesNodeUidTrainingSetsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public labelNodesNodeUidTrainingSetsPost(
    requestParameters: TrainingSetsApiLabelNodesNodeUidTrainingSetsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .labelNodesNodeUidTrainingSetsPost(
        requestParameters.nodeUid,
        requestParameters.labelJob,
        requestParameters.name,
        requestParameters.isActive,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Returns a boolean dictionary of whether each x_uid is labeled correctly by a training set
   * @summary Load Training Set Correctness
   * @param {TrainingSetsApiLoadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public loadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet(
    requestParameters: TrainingSetsApiLoadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .loadTrainingSetCorrectnessNodesNodeUidTrainingSetsTrainingSetCorrectnessBreakdownGet(
        requestParameters.nodeUid,
        requestParameters.trainingSet,
        requestParameters.split,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Load Training Set Labels
   * @param {TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public loadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet(
    requestParameters: TrainingSetsApiLoadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .loadTrainingSetLabelsNodesNodeUidTrainingSetsTrainingSetLabelsGet(
        requestParameters.nodeUid,
        requestParameters.trainingSet,
        requestParameters.split,
        requestParameters.filterUnlabeled,
        requestParameters.filterUncertainLabels,
        requestParameters.loadGroundTruth,
        requestParameters.discretizeLabels,
        requestParameters.tieBreakPolicy,
        requestParameters.samplerStrategy,
        requestParameters.samplerParams,
        requestParameters.samplerClassCounts,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Load Xuids And Labels
   * @param {TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public loadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet(
    requestParameters: TrainingSetsApiLoadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .loadXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetGet(
        requestParameters.nodeUid,
        requestParameters.trainingSet,
        requestParameters.split,
        requestParameters.filterUnlabeled,
        requestParameters.filterUncertainLabels,
        requestParameters.loadGroundTruth,
        requestParameters.discretizeLabels,
        requestParameters.tieBreakPolicy,
        requestParameters.samplerStrategy,
        requestParameters.samplerParams,
        requestParameters.samplerClassCounts,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Package Label Train
   * @param {TrainingSetsApiPackageLabelTrainNodesNodeUidPackageLabelTrainPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public packageLabelTrainNodesNodeUidPackageLabelTrainPost(
    requestParameters: TrainingSetsApiPackageLabelTrainNodesNodeUidPackageLabelTrainPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .packageLabelTrainNodesNodeUidPackageLabelTrainPost(
        requestParameters.nodeUid,
        requestParameters.packageLabelTrainApiModel,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Remove Training Set
   * @param {TrainingSetsApiRemoveTrainingSetNodesNodeUidTrainingSetsTrainingSetDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public removeTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete(
    requestParameters: TrainingSetsApiRemoveTrainingSetNodesNodeUidTrainingSetsTrainingSetDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .removeTrainingSetNodesNodeUidTrainingSetsTrainingSetDelete(
        requestParameters.nodeUid,
        requestParameters.trainingSet,
        requestParameters.deleteUptoVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search Xuids And Labels
   * @param {TrainingSetsApiSearchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost(
    requestParameters: TrainingSetsApiSearchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .searchXuidsAndLabelsNodesNodeUidTrainingSetsTrainingSetPost(
        requestParameters.nodeUid,
        requestParameters.trainingSet,
        requestParameters.fetchTrainingSetLabels,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Training Set
   * @param {TrainingSetsApiUpdateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingSetsApi
   */
  public updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut(
    requestParameters: TrainingSetsApiUpdateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TrainingSetsApiFp(this.configuration)
      .updateTrainingSetNodesNodeUidTrainingSetsTrainingSetUidPut(
        requestParameters.nodeUid,
        requestParameters.trainingSetUid,
        requestParameters.updateTrainingSetParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TransferApi - axios parameter creator
 * @export
 */
export const TransferApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Merge Assets
     * @param {number} nodeUid
     * @param {MergeAsset} mergeAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeAssetsNodesNodeUidMergeAssetsPut: async (
      nodeUid: number,
      mergeAsset: MergeAsset,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'mergeAssetsNodesNodeUidMergeAssetsPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'mergeAsset' is not null or undefined
      assertParamExists(
        'mergeAssetsNodesNodeUidMergeAssetsPut',
        'mergeAsset',
        mergeAsset,
      );
      const localVarPath = `/nodes/{node_uid}/merge-assets`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        mergeAsset,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Transfer Annotations
     * @param {number} nodeUid
     * @param {TransferAnnotationsParams} transferAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferAnnotationsNodesNodeUidTransferAnnotationsPost: async (
      nodeUid: number,
      transferAnnotationsParams: TransferAnnotationsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'transferAnnotationsNodesNodeUidTransferAnnotationsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'transferAnnotationsParams' is not null or undefined
      assertParamExists(
        'transferAnnotationsNodesNodeUidTransferAnnotationsPost',
        'transferAnnotationsParams',
        transferAnnotationsParams,
      );
      const localVarPath = `/nodes/{node_uid}/transfer-annotations`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transferAnnotationsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Transfer Assets
     * @param {number} nodeUid
     * @param {ImportAssets} importAssets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferAssetsNodesNodeUidTransferAssetsPut: async (
      nodeUid: number,
      importAssets: ImportAssets,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'transferAssetsNodesNodeUidTransferAssetsPut',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'importAssets' is not null or undefined
      assertParamExists(
        'transferAssetsNodesNodeUidTransferAssetsPut',
        'importAssets',
        importAssets,
      );
      const localVarPath = `/nodes/{node_uid}/transfer-assets`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        importAssets,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Transfer Batches
     * @param {number} nodeUid
     * @param {TransferBatchesParams} transferBatchesParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferBatchesNodesNodeUidTransferBatchesPost: async (
      nodeUid: number,
      transferBatchesParams: TransferBatchesParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'transferBatchesNodesNodeUidTransferBatchesPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'transferBatchesParams' is not null or undefined
      assertParamExists(
        'transferBatchesNodesNodeUidTransferBatchesPost',
        'transferBatchesParams',
        transferBatchesParams,
      );
      const localVarPath = `/nodes/{node_uid}/transfer-batches`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transferBatchesParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Transfer Comments
     * @param {number} nodeUid
     * @param {TransferComments} transferComments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferCommentsNodesNodeUidTransferCommentsPost: async (
      nodeUid: number,
      transferComments: TransferComments,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'transferCommentsNodesNodeUidTransferCommentsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'transferComments' is not null or undefined
      assertParamExists(
        'transferCommentsNodesNodeUidTransferCommentsPost',
        'transferComments',
        transferComments,
      );
      const localVarPath = `/nodes/{node_uid}/transfer-comments`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transferComments,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Transfer Gts
     * @param {number} nodeUid
     * @param {TransferGTs} transferGTs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferGtsNodesNodeUidTransferGtsPost: async (
      nodeUid: number,
      transferGTs: TransferGTs,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'transferGtsNodesNodeUidTransferGtsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'transferGTs' is not null or undefined
      assertParamExists(
        'transferGtsNodesNodeUidTransferGtsPost',
        'transferGTs',
        transferGTs,
      );
      const localVarPath = `/nodes/{node_uid}/transfer-gts`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transferGTs,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Transfer Lfs
     * @param {number} nodeUid
     * @param {TransferLFs} transferLFs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferLfsNodesNodeUidTransferLfsPost: async (
      nodeUid: number,
      transferLFs: TransferLFs,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'transferLfsNodesNodeUidTransferLfsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'transferLFs' is not null or undefined
      assertParamExists(
        'transferLfsNodesNodeUidTransferLfsPost',
        'transferLFs',
        transferLFs,
      );
      const localVarPath = `/nodes/{node_uid}/transfer-lfs`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transferLFs,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Transfer Tags
     * @param {number} nodeUid
     * @param {TransferTags} transferTags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferTagsNodesNodeUidTransferTagsPost: async (
      nodeUid: number,
      transferTags: TransferTags,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'transferTagsNodesNodeUidTransferTagsPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'transferTags' is not null or undefined
      assertParamExists(
        'transferTagsNodesNodeUidTransferTagsPost',
        'transferTags',
        transferTags,
      );
      const localVarPath = `/nodes/{node_uid}/transfer-tags`.replace(
        `{${'node_uid'}}`,
        encodeURIComponent(String(nodeUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transferTags,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransferApi - functional programming interface
 * @export
 */
export const TransferApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TransferApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Merge Assets
     * @param {number} nodeUid
     * @param {MergeAsset} mergeAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mergeAssetsNodesNodeUidMergeAssetsPut(
      nodeUid: number,
      mergeAsset: MergeAsset,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.mergeAssetsNodesNodeUidMergeAssetsPut(
          nodeUid,
          mergeAsset,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TransferApi.mergeAssetsNodesNodeUidMergeAssetsPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Transfer Annotations
     * @param {number} nodeUid
     * @param {TransferAnnotationsParams} transferAnnotationsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transferAnnotationsNodesNodeUidTransferAnnotationsPost(
      nodeUid: number,
      transferAnnotationsParams: TransferAnnotationsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ImportAnnotationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transferAnnotationsNodesNodeUidTransferAnnotationsPost(
          nodeUid,
          transferAnnotationsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TransferApi.transferAnnotationsNodesNodeUidTransferAnnotationsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Transfer Assets
     * @param {number} nodeUid
     * @param {ImportAssets} importAssets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transferAssetsNodesNodeUidTransferAssetsPut(
      nodeUid: number,
      importAssets: ImportAssets,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transferAssetsNodesNodeUidTransferAssetsPut(
          nodeUid,
          importAssets,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TransferApi.transferAssetsNodesNodeUidTransferAssetsPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Transfer Batches
     * @param {number} nodeUid
     * @param {TransferBatchesParams} transferBatchesParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transferBatchesNodesNodeUidTransferBatchesPost(
      nodeUid: number,
      transferBatchesParams: TransferBatchesParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransferBatchesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transferBatchesNodesNodeUidTransferBatchesPost(
          nodeUid,
          transferBatchesParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TransferApi.transferBatchesNodesNodeUidTransferBatchesPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Transfer Comments
     * @param {number} nodeUid
     * @param {TransferComments} transferComments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transferCommentsNodesNodeUidTransferCommentsPost(
      nodeUid: number,
      transferComments: TransferComments,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transferCommentsNodesNodeUidTransferCommentsPost(
          nodeUid,
          transferComments,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TransferApi.transferCommentsNodesNodeUidTransferCommentsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Transfer Gts
     * @param {number} nodeUid
     * @param {TransferGTs} transferGTs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transferGtsNodesNodeUidTransferGtsPost(
      nodeUid: number,
      transferGTs: TransferGTs,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transferGtsNodesNodeUidTransferGtsPost(
          nodeUid,
          transferGTs,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TransferApi.transferGtsNodesNodeUidTransferGtsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Transfer Lfs
     * @param {number} nodeUid
     * @param {TransferLFs} transferLFs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transferLfsNodesNodeUidTransferLfsPost(
      nodeUid: number,
      transferLFs: TransferLFs,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transferLfsNodesNodeUidTransferLfsPost(
          nodeUid,
          transferLFs,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TransferApi.transferLfsNodesNodeUidTransferLfsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Transfer Tags
     * @param {number} nodeUid
     * @param {TransferTags} transferTags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transferTagsNodesNodeUidTransferTagsPost(
      nodeUid: number,
      transferTags: TransferTags,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.transferTagsNodesNodeUidTransferTagsPost(
          nodeUid,
          transferTags,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'TransferApi.transferTagsNodesNodeUidTransferTagsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TransferApi - factory interface
 * @export
 */
export const TransferApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TransferApiFp(configuration);
  return {
    /**
     *
     * @summary Merge Assets
     * @param {TransferApiMergeAssetsNodesNodeUidMergeAssetsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeAssetsNodesNodeUidMergeAssetsPut(
      requestParameters: TransferApiMergeAssetsNodesNodeUidMergeAssetsPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .mergeAssetsNodesNodeUidMergeAssetsPut(
          requestParameters.nodeUid,
          requestParameters.mergeAsset,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Transfer Annotations
     * @param {TransferApiTransferAnnotationsNodesNodeUidTransferAnnotationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferAnnotationsNodesNodeUidTransferAnnotationsPost(
      requestParameters: TransferApiTransferAnnotationsNodesNodeUidTransferAnnotationsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ImportAnnotationsResponse> {
      return localVarFp
        .transferAnnotationsNodesNodeUidTransferAnnotationsPost(
          requestParameters.nodeUid,
          requestParameters.transferAnnotationsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Transfer Assets
     * @param {TransferApiTransferAssetsNodesNodeUidTransferAssetsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferAssetsNodesNodeUidTransferAssetsPut(
      requestParameters: TransferApiTransferAssetsNodesNodeUidTransferAssetsPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .transferAssetsNodesNodeUidTransferAssetsPut(
          requestParameters.nodeUid,
          requestParameters.importAssets,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Transfer Batches
     * @param {TransferApiTransferBatchesNodesNodeUidTransferBatchesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferBatchesNodesNodeUidTransferBatchesPost(
      requestParameters: TransferApiTransferBatchesNodesNodeUidTransferBatchesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TransferBatchesResponse> {
      return localVarFp
        .transferBatchesNodesNodeUidTransferBatchesPost(
          requestParameters.nodeUid,
          requestParameters.transferBatchesParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Transfer Comments
     * @param {TransferApiTransferCommentsNodesNodeUidTransferCommentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferCommentsNodesNodeUidTransferCommentsPost(
      requestParameters: TransferApiTransferCommentsNodesNodeUidTransferCommentsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .transferCommentsNodesNodeUidTransferCommentsPost(
          requestParameters.nodeUid,
          requestParameters.transferComments,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Transfer Gts
     * @param {TransferApiTransferGtsNodesNodeUidTransferGtsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferGtsNodesNodeUidTransferGtsPost(
      requestParameters: TransferApiTransferGtsNodesNodeUidTransferGtsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .transferGtsNodesNodeUidTransferGtsPost(
          requestParameters.nodeUid,
          requestParameters.transferGTs,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Transfer Lfs
     * @param {TransferApiTransferLfsNodesNodeUidTransferLfsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferLfsNodesNodeUidTransferLfsPost(
      requestParameters: TransferApiTransferLfsNodesNodeUidTransferLfsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .transferLfsNodesNodeUidTransferLfsPost(
          requestParameters.nodeUid,
          requestParameters.transferLFs,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Transfer Tags
     * @param {TransferApiTransferTagsNodesNodeUidTransferTagsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transferTagsNodesNodeUidTransferTagsPost(
      requestParameters: TransferApiTransferTagsNodesNodeUidTransferTagsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .transferTagsNodesNodeUidTransferTagsPost(
          requestParameters.nodeUid,
          requestParameters.transferTags,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for mergeAssetsNodesNodeUidMergeAssetsPut operation in TransferApi.
 * @export
 * @interface TransferApiMergeAssetsNodesNodeUidMergeAssetsPutRequest
 */
export interface TransferApiMergeAssetsNodesNodeUidMergeAssetsPutRequest {
  /**
   *
   * @type {number}
   * @memberof TransferApiMergeAssetsNodesNodeUidMergeAssetsPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {MergeAsset}
   * @memberof TransferApiMergeAssetsNodesNodeUidMergeAssetsPut
   */
  readonly mergeAsset: MergeAsset;
}

/**
 * Request parameters for transferAnnotationsNodesNodeUidTransferAnnotationsPost operation in TransferApi.
 * @export
 * @interface TransferApiTransferAnnotationsNodesNodeUidTransferAnnotationsPostRequest
 */
export interface TransferApiTransferAnnotationsNodesNodeUidTransferAnnotationsPostRequest {
  /**
   *
   * @type {number}
   * @memberof TransferApiTransferAnnotationsNodesNodeUidTransferAnnotationsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TransferAnnotationsParams}
   * @memberof TransferApiTransferAnnotationsNodesNodeUidTransferAnnotationsPost
   */
  readonly transferAnnotationsParams: TransferAnnotationsParams;
}

/**
 * Request parameters for transferAssetsNodesNodeUidTransferAssetsPut operation in TransferApi.
 * @export
 * @interface TransferApiTransferAssetsNodesNodeUidTransferAssetsPutRequest
 */
export interface TransferApiTransferAssetsNodesNodeUidTransferAssetsPutRequest {
  /**
   *
   * @type {number}
   * @memberof TransferApiTransferAssetsNodesNodeUidTransferAssetsPut
   */
  readonly nodeUid: number;

  /**
   *
   * @type {ImportAssets}
   * @memberof TransferApiTransferAssetsNodesNodeUidTransferAssetsPut
   */
  readonly importAssets: ImportAssets;
}

/**
 * Request parameters for transferBatchesNodesNodeUidTransferBatchesPost operation in TransferApi.
 * @export
 * @interface TransferApiTransferBatchesNodesNodeUidTransferBatchesPostRequest
 */
export interface TransferApiTransferBatchesNodesNodeUidTransferBatchesPostRequest {
  /**
   *
   * @type {number}
   * @memberof TransferApiTransferBatchesNodesNodeUidTransferBatchesPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TransferBatchesParams}
   * @memberof TransferApiTransferBatchesNodesNodeUidTransferBatchesPost
   */
  readonly transferBatchesParams: TransferBatchesParams;
}

/**
 * Request parameters for transferCommentsNodesNodeUidTransferCommentsPost operation in TransferApi.
 * @export
 * @interface TransferApiTransferCommentsNodesNodeUidTransferCommentsPostRequest
 */
export interface TransferApiTransferCommentsNodesNodeUidTransferCommentsPostRequest {
  /**
   *
   * @type {number}
   * @memberof TransferApiTransferCommentsNodesNodeUidTransferCommentsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TransferComments}
   * @memberof TransferApiTransferCommentsNodesNodeUidTransferCommentsPost
   */
  readonly transferComments: TransferComments;
}

/**
 * Request parameters for transferGtsNodesNodeUidTransferGtsPost operation in TransferApi.
 * @export
 * @interface TransferApiTransferGtsNodesNodeUidTransferGtsPostRequest
 */
export interface TransferApiTransferGtsNodesNodeUidTransferGtsPostRequest {
  /**
   *
   * @type {number}
   * @memberof TransferApiTransferGtsNodesNodeUidTransferGtsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TransferGTs}
   * @memberof TransferApiTransferGtsNodesNodeUidTransferGtsPost
   */
  readonly transferGTs: TransferGTs;
}

/**
 * Request parameters for transferLfsNodesNodeUidTransferLfsPost operation in TransferApi.
 * @export
 * @interface TransferApiTransferLfsNodesNodeUidTransferLfsPostRequest
 */
export interface TransferApiTransferLfsNodesNodeUidTransferLfsPostRequest {
  /**
   *
   * @type {number}
   * @memberof TransferApiTransferLfsNodesNodeUidTransferLfsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TransferLFs}
   * @memberof TransferApiTransferLfsNodesNodeUidTransferLfsPost
   */
  readonly transferLFs: TransferLFs;
}

/**
 * Request parameters for transferTagsNodesNodeUidTransferTagsPost operation in TransferApi.
 * @export
 * @interface TransferApiTransferTagsNodesNodeUidTransferTagsPostRequest
 */
export interface TransferApiTransferTagsNodesNodeUidTransferTagsPostRequest {
  /**
   *
   * @type {number}
   * @memberof TransferApiTransferTagsNodesNodeUidTransferTagsPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {TransferTags}
   * @memberof TransferApiTransferTagsNodesNodeUidTransferTagsPost
   */
  readonly transferTags: TransferTags;
}

/**
 * TransferApi - object-oriented interface
 * @export
 * @class TransferApi
 * @extends {BaseAPI}
 */
export class TransferApi extends BaseAPI {
  /**
   *
   * @summary Merge Assets
   * @param {TransferApiMergeAssetsNodesNodeUidMergeAssetsPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public mergeAssetsNodesNodeUidMergeAssetsPut(
    requestParameters: TransferApiMergeAssetsNodesNodeUidMergeAssetsPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TransferApiFp(this.configuration)
      .mergeAssetsNodesNodeUidMergeAssetsPut(
        requestParameters.nodeUid,
        requestParameters.mergeAsset,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Transfer Annotations
   * @param {TransferApiTransferAnnotationsNodesNodeUidTransferAnnotationsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public transferAnnotationsNodesNodeUidTransferAnnotationsPost(
    requestParameters: TransferApiTransferAnnotationsNodesNodeUidTransferAnnotationsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TransferApiFp(this.configuration)
      .transferAnnotationsNodesNodeUidTransferAnnotationsPost(
        requestParameters.nodeUid,
        requestParameters.transferAnnotationsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Transfer Assets
   * @param {TransferApiTransferAssetsNodesNodeUidTransferAssetsPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public transferAssetsNodesNodeUidTransferAssetsPut(
    requestParameters: TransferApiTransferAssetsNodesNodeUidTransferAssetsPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TransferApiFp(this.configuration)
      .transferAssetsNodesNodeUidTransferAssetsPut(
        requestParameters.nodeUid,
        requestParameters.importAssets,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Transfer Batches
   * @param {TransferApiTransferBatchesNodesNodeUidTransferBatchesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public transferBatchesNodesNodeUidTransferBatchesPost(
    requestParameters: TransferApiTransferBatchesNodesNodeUidTransferBatchesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TransferApiFp(this.configuration)
      .transferBatchesNodesNodeUidTransferBatchesPost(
        requestParameters.nodeUid,
        requestParameters.transferBatchesParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Transfer Comments
   * @param {TransferApiTransferCommentsNodesNodeUidTransferCommentsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public transferCommentsNodesNodeUidTransferCommentsPost(
    requestParameters: TransferApiTransferCommentsNodesNodeUidTransferCommentsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TransferApiFp(this.configuration)
      .transferCommentsNodesNodeUidTransferCommentsPost(
        requestParameters.nodeUid,
        requestParameters.transferComments,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Transfer Gts
   * @param {TransferApiTransferGtsNodesNodeUidTransferGtsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public transferGtsNodesNodeUidTransferGtsPost(
    requestParameters: TransferApiTransferGtsNodesNodeUidTransferGtsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TransferApiFp(this.configuration)
      .transferGtsNodesNodeUidTransferGtsPost(
        requestParameters.nodeUid,
        requestParameters.transferGTs,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Transfer Lfs
   * @param {TransferApiTransferLfsNodesNodeUidTransferLfsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public transferLfsNodesNodeUidTransferLfsPost(
    requestParameters: TransferApiTransferLfsNodesNodeUidTransferLfsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TransferApiFp(this.configuration)
      .transferLfsNodesNodeUidTransferLfsPost(
        requestParameters.nodeUid,
        requestParameters.transferLFs,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Transfer Tags
   * @param {TransferApiTransferTagsNodesNodeUidTransferTagsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferApi
   */
  public transferTagsNodesNodeUidTransferTagsPost(
    requestParameters: TransferApiTransferTagsNodesNodeUidTransferTagsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return TransferApiFp(this.configuration)
      .transferTagsNodesNodeUidTransferTagsPost(
        requestParameters.nodeUid,
        requestParameters.transferTags,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UserSettingsApi - axios parameter creator
 * @export
 */
export const UserSettingsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete User Setting
     * @param {DeleteUserSettingsRequest} deleteUserSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserSettingUserSettingsDelete: async (
      deleteUserSettingsRequest: DeleteUserSettingsRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteUserSettingsRequest' is not null or undefined
      assertParamExists(
        'deleteUserSettingUserSettingsDelete',
        'deleteUserSettingsRequest',
        deleteUserSettingsRequest,
      );
      const localVarPath = `/user-settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteUserSettingsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Backend Feature Flags
     * @param {number} [userUid]
     * @param {string} [requestUrl]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet: async (
      userUid?: number,
      requestUrl?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user-settings-backend-feature-flags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      if (requestUrl !== undefined) {
        localVarQueryParameter['request_url'] = requestUrl;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get User Settings All
     * @param {number} [applicationUid]
     * @param {number} [nodeUid]
     * @param {number} [userUid]
     * @param {number} [datasetBatchUid]
     * @param {BackendFeatureFlagNames} [backendFeatureFlagNames]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSettingsAllUserSettingsRawGet: async (
      applicationUid?: number,
      nodeUid?: number,
      userUid?: number,
      datasetBatchUid?: number,
      backendFeatureFlagNames?: BackendFeatureFlagNames,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user-settings-raw`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (applicationUid !== undefined) {
        localVarQueryParameter['application_uid'] = applicationUid;
      }

      if (nodeUid !== undefined) {
        localVarQueryParameter['node_uid'] = nodeUid;
      }

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      if (datasetBatchUid !== undefined) {
        localVarQueryParameter['dataset_batch_uid'] = datasetBatchUid;
      }

      if (backendFeatureFlagNames !== undefined) {
        localVarQueryParameter['backend_feature_flag_names'] =
          backendFeatureFlagNames;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get User Settings
     * @param {boolean} [organization]
     * @param {number} [applicationUid]
     * @param {number} [nodeUid]
     * @param {number} [userUid]
     * @param {number} [datasetBatchUid]
     * @param {boolean} [exact]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSettingsUserSettingsGet: async (
      organization?: boolean,
      applicationUid?: number,
      nodeUid?: number,
      userUid?: number,
      datasetBatchUid?: number,
      exact?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user-settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (organization !== undefined) {
        localVarQueryParameter['organization'] = organization;
      }

      if (applicationUid !== undefined) {
        localVarQueryParameter['application_uid'] = applicationUid;
      }

      if (nodeUid !== undefined) {
        localVarQueryParameter['node_uid'] = nodeUid;
      }

      if (userUid !== undefined) {
        localVarQueryParameter['user_uid'] = userUid;
      }

      if (datasetBatchUid !== undefined) {
        localVarQueryParameter['dataset_batch_uid'] = datasetBatchUid;
      }

      if (exact !== undefined) {
        localVarQueryParameter['exact'] = exact;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Backend Feature Flags
     * @param {UpdateBackendFeatureFlagsRequest} updateBackendFeatureFlagsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost: async (
      updateBackendFeatureFlagsRequest: UpdateBackendFeatureFlagsRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateBackendFeatureFlagsRequest' is not null or undefined
      assertParamExists(
        'updateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost',
        'updateBackendFeatureFlagsRequest',
        updateBackendFeatureFlagsRequest,
      );
      const localVarPath = `/user-settings-backend-feature-flags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateBackendFeatureFlagsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update User Setting
     * @param {UpdateUserSettingsRequest} updateUserSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserSettingUserSettingsPost: async (
      updateUserSettingsRequest: UpdateUserSettingsRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateUserSettingsRequest' is not null or undefined
      assertParamExists(
        'updateUserSettingUserSettingsPost',
        'updateUserSettingsRequest',
        updateUserSettingsRequest,
      );
      const localVarPath = `/user-settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserSettingsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserSettingsApi - functional programming interface
 * @export
 */
export const UserSettingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UserSettingsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete User Setting
     * @param {DeleteUserSettingsRequest} deleteUserSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUserSettingUserSettingsDelete(
      deleteUserSettingsRequest: DeleteUserSettingsRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteUserSettingUserSettingsDelete(
          deleteUserSettingsRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'UserSettingsApi.deleteUserSettingUserSettingsDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Backend Feature Flags
     * @param {number} [userUid]
     * @param {string} [requestUrl]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet(
      userUid?: number,
      requestUrl?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: UnifiedFlagResponse }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet(
          userUid,
          requestUrl,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'UserSettingsApi.getBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get User Settings All
     * @param {number} [applicationUid]
     * @param {number} [nodeUid]
     * @param {number} [userUid]
     * @param {number} [datasetBatchUid]
     * @param {BackendFeatureFlagNames} [backendFeatureFlagNames]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserSettingsAllUserSettingsRawGet(
      applicationUid?: number,
      nodeUid?: number,
      userUid?: number,
      datasetBatchUid?: number,
      backendFeatureFlagNames?: BackendFeatureFlagNames,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RawUserSettingsJsons>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserSettingsAllUserSettingsRawGet(
          applicationUid,
          nodeUid,
          userUid,
          datasetBatchUid,
          backendFeatureFlagNames,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'UserSettingsApi.getUserSettingsAllUserSettingsRawGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get User Settings
     * @param {boolean} [organization]
     * @param {number} [applicationUid]
     * @param {number} [nodeUid]
     * @param {number} [userUid]
     * @param {number} [datasetBatchUid]
     * @param {boolean} [exact]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserSettingsUserSettingsGet(
      organization?: boolean,
      applicationUid?: number,
      nodeUid?: number,
      userUid?: number,
      datasetBatchUid?: number,
      exact?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserSettingsJson>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserSettingsUserSettingsGet(
          organization,
          applicationUid,
          nodeUid,
          userUid,
          datasetBatchUid,
          exact,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UserSettingsApi.getUserSettingsUserSettingsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Backend Feature Flags
     * @param {UpdateBackendFeatureFlagsRequest} updateBackendFeatureFlagsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost(
      updateBackendFeatureFlagsRequest: UpdateBackendFeatureFlagsRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BackendFeatureFlags>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost(
          updateBackendFeatureFlagsRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'UserSettingsApi.updateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update User Setting
     * @param {UpdateUserSettingsRequest} updateUserSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserSettingUserSettingsPost(
      updateUserSettingsRequest: UpdateUserSettingsRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserSettingsJson>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserSettingUserSettingsPost(
          updateUserSettingsRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'UserSettingsApi.updateUserSettingUserSettingsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UserSettingsApi - factory interface
 * @export
 */
export const UserSettingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserSettingsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete User Setting
     * @param {UserSettingsApiDeleteUserSettingUserSettingsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserSettingUserSettingsDelete(
      requestParameters: UserSettingsApiDeleteUserSettingUserSettingsDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteUserSettingUserSettingsDelete(
          requestParameters.deleteUserSettingsRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Backend Feature Flags
     * @param {UserSettingsApiGetBackendFeatureFlagsUserSettingsBackendFeatureFlagsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet(
      requestParameters: UserSettingsApiGetBackendFeatureFlagsUserSettingsBackendFeatureFlagsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: UnifiedFlagResponse }> {
      return localVarFp
        .getBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet(
          requestParameters.userUid,
          requestParameters.requestUrl,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get User Settings All
     * @param {UserSettingsApiGetUserSettingsAllUserSettingsRawGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSettingsAllUserSettingsRawGet(
      requestParameters: UserSettingsApiGetUserSettingsAllUserSettingsRawGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RawUserSettingsJsons> {
      return localVarFp
        .getUserSettingsAllUserSettingsRawGet(
          requestParameters.applicationUid,
          requestParameters.nodeUid,
          requestParameters.userUid,
          requestParameters.datasetBatchUid,
          requestParameters.backendFeatureFlagNames,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get User Settings
     * @param {UserSettingsApiGetUserSettingsUserSettingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSettingsUserSettingsGet(
      requestParameters: UserSettingsApiGetUserSettingsUserSettingsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserSettingsJson> {
      return localVarFp
        .getUserSettingsUserSettingsGet(
          requestParameters.organization,
          requestParameters.applicationUid,
          requestParameters.nodeUid,
          requestParameters.userUid,
          requestParameters.datasetBatchUid,
          requestParameters.exact,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Backend Feature Flags
     * @param {UserSettingsApiUpdateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost(
      requestParameters: UserSettingsApiUpdateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<BackendFeatureFlags> {
      return localVarFp
        .updateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost(
          requestParameters.updateBackendFeatureFlagsRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update User Setting
     * @param {UserSettingsApiUpdateUserSettingUserSettingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserSettingUserSettingsPost(
      requestParameters: UserSettingsApiUpdateUserSettingUserSettingsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserSettingsJson> {
      return localVarFp
        .updateUserSettingUserSettingsPost(
          requestParameters.updateUserSettingsRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteUserSettingUserSettingsDelete operation in UserSettingsApi.
 * @export
 * @interface UserSettingsApiDeleteUserSettingUserSettingsDeleteRequest
 */
export interface UserSettingsApiDeleteUserSettingUserSettingsDeleteRequest {
  /**
   *
   * @type {DeleteUserSettingsRequest}
   * @memberof UserSettingsApiDeleteUserSettingUserSettingsDelete
   */
  readonly deleteUserSettingsRequest: DeleteUserSettingsRequest;
}

/**
 * Request parameters for getBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet operation in UserSettingsApi.
 * @export
 * @interface UserSettingsApiGetBackendFeatureFlagsUserSettingsBackendFeatureFlagsGetRequest
 */
export interface UserSettingsApiGetBackendFeatureFlagsUserSettingsBackendFeatureFlagsGetRequest {
  /**
   *
   * @type {number}
   * @memberof UserSettingsApiGetBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet
   */
  readonly userUid?: number;

  /**
   *
   * @type {string}
   * @memberof UserSettingsApiGetBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet
   */
  readonly requestUrl?: string;
}

/**
 * Request parameters for getUserSettingsAllUserSettingsRawGet operation in UserSettingsApi.
 * @export
 * @interface UserSettingsApiGetUserSettingsAllUserSettingsRawGetRequest
 */
export interface UserSettingsApiGetUserSettingsAllUserSettingsRawGetRequest {
  /**
   *
   * @type {number}
   * @memberof UserSettingsApiGetUserSettingsAllUserSettingsRawGet
   */
  readonly applicationUid?: number;

  /**
   *
   * @type {number}
   * @memberof UserSettingsApiGetUserSettingsAllUserSettingsRawGet
   */
  readonly nodeUid?: number;

  /**
   *
   * @type {number}
   * @memberof UserSettingsApiGetUserSettingsAllUserSettingsRawGet
   */
  readonly userUid?: number;

  /**
   *
   * @type {number}
   * @memberof UserSettingsApiGetUserSettingsAllUserSettingsRawGet
   */
  readonly datasetBatchUid?: number;

  /**
   *
   * @type {BackendFeatureFlagNames}
   * @memberof UserSettingsApiGetUserSettingsAllUserSettingsRawGet
   */
  readonly backendFeatureFlagNames?: BackendFeatureFlagNames;
}

/**
 * Request parameters for getUserSettingsUserSettingsGet operation in UserSettingsApi.
 * @export
 * @interface UserSettingsApiGetUserSettingsUserSettingsGetRequest
 */
export interface UserSettingsApiGetUserSettingsUserSettingsGetRequest {
  /**
   *
   * @type {boolean}
   * @memberof UserSettingsApiGetUserSettingsUserSettingsGet
   */
  readonly organization?: boolean;

  /**
   *
   * @type {number}
   * @memberof UserSettingsApiGetUserSettingsUserSettingsGet
   */
  readonly applicationUid?: number;

  /**
   *
   * @type {number}
   * @memberof UserSettingsApiGetUserSettingsUserSettingsGet
   */
  readonly nodeUid?: number;

  /**
   *
   * @type {number}
   * @memberof UserSettingsApiGetUserSettingsUserSettingsGet
   */
  readonly userUid?: number;

  /**
   *
   * @type {number}
   * @memberof UserSettingsApiGetUserSettingsUserSettingsGet
   */
  readonly datasetBatchUid?: number;

  /**
   *
   * @type {boolean}
   * @memberof UserSettingsApiGetUserSettingsUserSettingsGet
   */
  readonly exact?: boolean;
}

/**
 * Request parameters for updateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost operation in UserSettingsApi.
 * @export
 * @interface UserSettingsApiUpdateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPostRequest
 */
export interface UserSettingsApiUpdateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPostRequest {
  /**
   *
   * @type {UpdateBackendFeatureFlagsRequest}
   * @memberof UserSettingsApiUpdateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost
   */
  readonly updateBackendFeatureFlagsRequest: UpdateBackendFeatureFlagsRequest;
}

/**
 * Request parameters for updateUserSettingUserSettingsPost operation in UserSettingsApi.
 * @export
 * @interface UserSettingsApiUpdateUserSettingUserSettingsPostRequest
 */
export interface UserSettingsApiUpdateUserSettingUserSettingsPostRequest {
  /**
   *
   * @type {UpdateUserSettingsRequest}
   * @memberof UserSettingsApiUpdateUserSettingUserSettingsPost
   */
  readonly updateUserSettingsRequest: UpdateUserSettingsRequest;
}

/**
 * UserSettingsApi - object-oriented interface
 * @export
 * @class UserSettingsApi
 * @extends {BaseAPI}
 */
export class UserSettingsApi extends BaseAPI {
  /**
   *
   * @summary Delete User Setting
   * @param {UserSettingsApiDeleteUserSettingUserSettingsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSettingsApi
   */
  public deleteUserSettingUserSettingsDelete(
    requestParameters: UserSettingsApiDeleteUserSettingUserSettingsDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserSettingsApiFp(this.configuration)
      .deleteUserSettingUserSettingsDelete(
        requestParameters.deleteUserSettingsRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Backend Feature Flags
   * @param {UserSettingsApiGetBackendFeatureFlagsUserSettingsBackendFeatureFlagsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSettingsApi
   */
  public getBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet(
    requestParameters: UserSettingsApiGetBackendFeatureFlagsUserSettingsBackendFeatureFlagsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return UserSettingsApiFp(this.configuration)
      .getBackendFeatureFlagsUserSettingsBackendFeatureFlagsGet(
        requestParameters.userUid,
        requestParameters.requestUrl,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get User Settings All
   * @param {UserSettingsApiGetUserSettingsAllUserSettingsRawGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSettingsApi
   */
  public getUserSettingsAllUserSettingsRawGet(
    requestParameters: UserSettingsApiGetUserSettingsAllUserSettingsRawGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return UserSettingsApiFp(this.configuration)
      .getUserSettingsAllUserSettingsRawGet(
        requestParameters.applicationUid,
        requestParameters.nodeUid,
        requestParameters.userUid,
        requestParameters.datasetBatchUid,
        requestParameters.backendFeatureFlagNames,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get User Settings
   * @param {UserSettingsApiGetUserSettingsUserSettingsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSettingsApi
   */
  public getUserSettingsUserSettingsGet(
    requestParameters: UserSettingsApiGetUserSettingsUserSettingsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return UserSettingsApiFp(this.configuration)
      .getUserSettingsUserSettingsGet(
        requestParameters.organization,
        requestParameters.applicationUid,
        requestParameters.nodeUid,
        requestParameters.userUid,
        requestParameters.datasetBatchUid,
        requestParameters.exact,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Backend Feature Flags
   * @param {UserSettingsApiUpdateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSettingsApi
   */
  public updateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost(
    requestParameters: UserSettingsApiUpdateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserSettingsApiFp(this.configuration)
      .updateBackendFeatureFlagsUserSettingsBackendFeatureFlagsPost(
        requestParameters.updateBackendFeatureFlagsRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update User Setting
   * @param {UserSettingsApiUpdateUserSettingUserSettingsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSettingsApi
   */
  public updateUserSettingUserSettingsPost(
    requestParameters: UserSettingsApiUpdateUserSettingUserSettingsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserSettingsApiFp(this.configuration)
      .updateUserSettingUserSettingsPost(
        requestParameters.updateUserSettingsRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Add user to users table.
     * @summary Change Password
     * @param {ChangePassword} changePassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePasswordChangePasswordPost: async (
      changePassword: ChangePassword,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'changePassword' is not null or undefined
      assertParamExists(
        'changePasswordChangePasswordPost',
        'changePassword',
        changePassword,
      );
      const localVarPath = `/change-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changePassword,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add user to users table.
     * @summary Create User
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserUsersPost: async (
      createUserRequest: CreateUserRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUserRequest' is not null or undefined
      assertParamExists(
        'createUserUsersPost',
        'createUserRequest',
        createUserRequest,
      );
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Exchange a username and password for access and refresh tokens. Rejects requests with no matching usernames or passwords. Will also reject requests if SSO is required.
     * @summary Get Access Token
     * @param {GetAccessTokenRequest} getAccessTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessTokenGetTokenPost: async (
      getAccessTokenRequest: GetAccessTokenRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getAccessTokenRequest' is not null or undefined
      assertParamExists(
        'getAccessTokenGetTokenPost',
        'getAccessTokenRequest',
        getAccessTokenRequest,
      );
      const localVarPath = `/get-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getAccessTokenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Caller must include an active access-token in the authorization headers, the middleware will swap this out for a ContextVariable containing the call\'s user_uid, use that to return the corresponding User object
     * @summary Get Current User
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUserCurrentUserGet: async (
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/current-user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Header Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHeaderTokenHeaderTokenGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/header-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Jwt Signing Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJwtSigningInfoJwtSigningInfoGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/jwt-signing-info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List users that the requesting user has access to  Parameters ---------- include_inactive: Flag to include inactive users in the response workspace_uid: Optional workspace_uid to filter response by users with roles in the given workspace If not provided, all users within the workspaces the user has access to will be included in the response.  Returns ------- List of users
     * @summary Get List Users
     * @param {boolean} [includeInactive]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListUsersUsersGet: async (
      includeInactive?: boolean,
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (includeInactive !== undefined) {
        localVarQueryParameter['include_inactive'] = includeInactive;
      }

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Refresh Token
     * @param {RefreshToken} refreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshTokenRefreshTokenPost: async (
      refreshToken: RefreshToken,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'refreshToken' is not null or undefined
      assertParamExists(
        'refreshTokenRefreshTokenPost',
        'refreshToken',
        refreshToken,
      );
      const localVarPath = `/refresh-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        refreshToken,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete user.
     * @summary Remove User
     * @param {number} userUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeUserUsersUserUidDelete: async (
      userUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists('removeUserUsersUserUidDelete', 'userUid', userUid);
      const localVarPath = `/users/{user_uid}`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Allow administrators to change any user\'s password
     * @summary Reset Password
     * @param {ResetPasswordParams} resetPasswordParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPasswordResetPasswordPost: async (
      resetPasswordParams: ResetPasswordParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resetPasswordParams' is not null or undefined
      assertParamExists(
        'resetPasswordResetPasswordPost',
        'resetPasswordParams',
        resetPasswordParams,
      );
      const localVarPath = `/reset-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update User Email
     * @param {number} userUid
     * @param {UpdateUserEmail} updateUserEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserEmailUsersUserUidEmailPut: async (
      userUid: number,
      updateUserEmail: UpdateUserEmail,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'updateUserEmailUsersUserUidEmailPut',
        'userUid',
        userUid,
      );
      // verify required parameter 'updateUserEmail' is not null or undefined
      assertParamExists(
        'updateUserEmailUsersUserUidEmailPut',
        'updateUserEmail',
        updateUserEmail,
      );
      const localVarPath = `/users/{user_uid}/email`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserEmail,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update User Role
     * @param {number} userUid
     * @param {UpdateUserRole} updateUserRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserRoleUsersUserUidRolePut: async (
      userUid: number,
      updateUserRole: UpdateUserRole,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'updateUserRoleUsersUserUidRolePut',
        'userUid',
        userUid,
      );
      // verify required parameter 'updateUserRole' is not null or undefined
      assertParamExists(
        'updateUserRoleUsersUserUidRolePut',
        'updateUserRole',
        updateUserRole,
      );
      const localVarPath = `/users/{user_uid}/role`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserRole,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update User Timezone
     * @param {number} userUid
     * @param {UpdateUserTimezone} updateUserTimezone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserTimezoneUsersUserUidTimezonePut: async (
      userUid: number,
      updateUserTimezone: UpdateUserTimezone,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'updateUserTimezoneUsersUserUidTimezonePut',
        'userUid',
        userUid,
      );
      // verify required parameter 'updateUserTimezone' is not null or undefined
      assertParamExists(
        'updateUserTimezoneUsersUserUidTimezonePut',
        'updateUserTimezone',
        updateUserTimezone,
      );
      const localVarPath = `/users/{user_uid}/timezone`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserTimezone,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update User
     * @param {number} userUid
     * @param {UpdateUserPayload} updateUserPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserUsersUserUidPut: async (
      userUid: number,
      updateUserPayload: UpdateUserPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists('updateUserUsersUserUidPut', 'userUid', userUid);
      // verify required parameter 'updateUserPayload' is not null or undefined
      assertParamExists(
        'updateUserUsersUserUidPut',
        'updateUserPayload',
        updateUserPayload,
      );
      const localVarPath = `/users/{user_uid}`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update User View
     * @param {number} userUid
     * @param {UpdateUserView} updateUserView
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserViewUsersUserUidDefaultViewPut: async (
      userUid: number,
      updateUserView: UpdateUserView,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userUid' is not null or undefined
      assertParamExists(
        'updateUserViewUsersUserUidDefaultViewPut',
        'userUid',
        userUid,
      );
      // verify required parameter 'updateUserView' is not null or undefined
      assertParamExists(
        'updateUserViewUsersUserUidDefaultViewPut',
        'updateUserView',
        updateUserView,
      );
      const localVarPath = `/users/{user_uid}/default-view`.replace(
        `{${'user_uid'}}`,
        encodeURIComponent(String(userUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserView,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     * Add user to users table.
     * @summary Change Password
     * @param {ChangePassword} changePassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePasswordChangePasswordPost(
      changePassword: ChangePassword,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.changePasswordChangePasswordPost(
          changePassword,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.changePasswordChangePasswordPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Add user to users table.
     * @summary Create User
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUserUsersPost(
      createUserRequest: CreateUserRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createUserUsersPost(
          createUserRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.createUserUsersPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Exchange a username and password for access and refresh tokens. Rejects requests with no matching usernames or passwords. Will also reject requests if SSO is required.
     * @summary Get Access Token
     * @param {GetAccessTokenRequest} getAccessTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccessTokenGetTokenPost(
      getAccessTokenRequest: GetAccessTokenRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenPair>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAccessTokenGetTokenPost(
          getAccessTokenRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.getAccessTokenGetTokenPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Caller must include an active access-token in the authorization headers, the middleware will swap this out for a ContextVariable containing the call\'s user_uid, use that to return the corresponding User object
     * @summary Get Current User
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentUserCurrentUserGet(
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCurrentUserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentUserCurrentUserGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.getCurrentUserCurrentUserGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Header Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHeaderTokenHeaderTokenGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<HeaderTokenResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getHeaderTokenHeaderTokenGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.getHeaderTokenHeaderTokenGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Jwt Signing Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJwtSigningInfoJwtSigningInfoGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JWTSigningInfo>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getJwtSigningInfoJwtSigningInfoGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.getJwtSigningInfoJwtSigningInfoGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List users that the requesting user has access to  Parameters ---------- include_inactive: Flag to include inactive users in the response workspace_uid: Optional workspace_uid to filter response by users with roles in the given workspace If not provided, all users within the workspaces the user has access to will be included in the response.  Returns ------- List of users
     * @summary Get List Users
     * @param {boolean} [includeInactive]
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListUsersUsersGet(
      includeInactive?: boolean,
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ListUserResponse>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getListUsersUsersGet(
          includeInactive,
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.getListUsersUsersGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Refresh Token
     * @param {RefreshToken} refreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshTokenRefreshTokenPost(
      refreshToken: RefreshToken,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RefreshTokenResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.refreshTokenRefreshTokenPost(
          refreshToken,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.refreshTokenRefreshTokenPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete user.
     * @summary Remove User
     * @param {number} userUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeUserUsersUserUidDelete(
      userUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeUserUsersUserUidDelete(
          userUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.removeUserUsersUserUidDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Allow administrators to change any user\'s password
     * @summary Reset Password
     * @param {ResetPasswordParams} resetPasswordParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPasswordResetPasswordPost(
      resetPasswordParams: ResetPasswordParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resetPasswordResetPasswordPost(
          resetPasswordParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.resetPasswordResetPasswordPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update User Email
     * @param {number} userUid
     * @param {UpdateUserEmail} updateUserEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserEmailUsersUserUidEmailPut(
      userUid: number,
      updateUserEmail: UpdateUserEmail,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserEmailUsersUserUidEmailPut(
          userUid,
          updateUserEmail,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.updateUserEmailUsersUserUidEmailPut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update User Role
     * @param {number} userUid
     * @param {UpdateUserRole} updateUserRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserRoleUsersUserUidRolePut(
      userUid: number,
      updateUserRole: UpdateUserRole,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserRoleUsersUserUidRolePut(
          userUid,
          updateUserRole,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.updateUserRoleUsersUserUidRolePut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update User Timezone
     * @param {number} userUid
     * @param {UpdateUserTimezone} updateUserTimezone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserTimezoneUsersUserUidTimezonePut(
      userUid: number,
      updateUserTimezone: UpdateUserTimezone,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserTimezoneUsersUserUidTimezonePut(
          userUid,
          updateUserTimezone,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'UsersApi.updateUserTimezoneUsersUserUidTimezonePut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update User
     * @param {number} userUid
     * @param {UpdateUserPayload} updateUserPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserUsersUserUidPut(
      userUid: number,
      updateUserPayload: UpdateUserPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserUsersUserUidPut(
          userUid,
          updateUserPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsersApi.updateUserUsersUserUidPut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update User View
     * @param {number} userUid
     * @param {UpdateUserView} updateUserView
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserViewUsersUserUidDefaultViewPut(
      userUid: number,
      updateUserView: UpdateUserView,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserViewUsersUserUidDefaultViewPut(
          userUid,
          updateUserView,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'UsersApi.updateUserViewUsersUserUidDefaultViewPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     * Add user to users table.
     * @summary Change Password
     * @param {UsersApiChangePasswordChangePasswordPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePasswordChangePasswordPost(
      requestParameters: UsersApiChangePasswordChangePasswordPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .changePasswordChangePasswordPost(
          requestParameters.changePassword,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Add user to users table.
     * @summary Create User
     * @param {UsersApiCreateUserUsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserUsersPost(
      requestParameters: UsersApiCreateUserUsersPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserResponse> {
      return localVarFp
        .createUserUsersPost(requestParameters.createUserRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Exchange a username and password for access and refresh tokens. Rejects requests with no matching usernames or passwords. Will also reject requests if SSO is required.
     * @summary Get Access Token
     * @param {UsersApiGetAccessTokenGetTokenPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessTokenGetTokenPost(
      requestParameters: UsersApiGetAccessTokenGetTokenPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TokenPair> {
      return localVarFp
        .getAccessTokenGetTokenPost(
          requestParameters.getAccessTokenRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Caller must include an active access-token in the authorization headers, the middleware will swap this out for a ContextVariable containing the call\'s user_uid, use that to return the corresponding User object
     * @summary Get Current User
     * @param {UsersApiGetCurrentUserCurrentUserGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentUserCurrentUserGet(
      requestParameters: UsersApiGetCurrentUserCurrentUserGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetCurrentUserResponse> {
      return localVarFp
        .getCurrentUserCurrentUserGet(requestParameters.workspaceUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Header Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHeaderTokenHeaderTokenGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HeaderTokenResponse> {
      return localVarFp
        .getHeaderTokenHeaderTokenGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Jwt Signing Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJwtSigningInfoJwtSigningInfoGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<JWTSigningInfo> {
      return localVarFp
        .getJwtSigningInfoJwtSigningInfoGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     * List users that the requesting user has access to  Parameters ---------- include_inactive: Flag to include inactive users in the response workspace_uid: Optional workspace_uid to filter response by users with roles in the given workspace If not provided, all users within the workspaces the user has access to will be included in the response.  Returns ------- List of users
     * @summary Get List Users
     * @param {UsersApiGetListUsersUsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListUsersUsersGet(
      requestParameters: UsersApiGetListUsersUsersGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<ListUserResponse>> {
      return localVarFp
        .getListUsersUsersGet(
          requestParameters.includeInactive,
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Refresh Token
     * @param {UsersApiRefreshTokenRefreshTokenPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshTokenRefreshTokenPost(
      requestParameters: UsersApiRefreshTokenRefreshTokenPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RefreshTokenResponse> {
      return localVarFp
        .refreshTokenRefreshTokenPost(requestParameters.refreshToken, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Delete user.
     * @summary Remove User
     * @param {UsersApiRemoveUserUsersUserUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeUserUsersUserUidDelete(
      requestParameters: UsersApiRemoveUserUsersUserUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .removeUserUsersUserUidDelete(requestParameters.userUid, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Allow administrators to change any user\'s password
     * @summary Reset Password
     * @param {UsersApiResetPasswordResetPasswordPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPasswordResetPasswordPost(
      requestParameters: UsersApiResetPasswordResetPasswordPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .resetPasswordResetPasswordPost(
          requestParameters.resetPasswordParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update User Email
     * @param {UsersApiUpdateUserEmailUsersUserUidEmailPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserEmailUsersUserUidEmailPut(
      requestParameters: UsersApiUpdateUserEmailUsersUserUidEmailPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserResponse> {
      return localVarFp
        .updateUserEmailUsersUserUidEmailPut(
          requestParameters.userUid,
          requestParameters.updateUserEmail,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update User Role
     * @param {UsersApiUpdateUserRoleUsersUserUidRolePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserRoleUsersUserUidRolePut(
      requestParameters: UsersApiUpdateUserRoleUsersUserUidRolePutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserResponse> {
      return localVarFp
        .updateUserRoleUsersUserUidRolePut(
          requestParameters.userUid,
          requestParameters.updateUserRole,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update User Timezone
     * @param {UsersApiUpdateUserTimezoneUsersUserUidTimezonePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserTimezoneUsersUserUidTimezonePut(
      requestParameters: UsersApiUpdateUserTimezoneUsersUserUidTimezonePutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserResponse> {
      return localVarFp
        .updateUserTimezoneUsersUserUidTimezonePut(
          requestParameters.userUid,
          requestParameters.updateUserTimezone,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update User
     * @param {UsersApiUpdateUserUsersUserUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserUsersUserUidPut(
      requestParameters: UsersApiUpdateUserUsersUserUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateUserUsersUserUidPut(
          requestParameters.userUid,
          requestParameters.updateUserPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update User View
     * @param {UsersApiUpdateUserViewUsersUserUidDefaultViewPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserViewUsersUserUidDefaultViewPut(
      requestParameters: UsersApiUpdateUserViewUsersUserUidDefaultViewPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UserResponse> {
      return localVarFp
        .updateUserViewUsersUserUidDefaultViewPut(
          requestParameters.userUid,
          requestParameters.updateUserView,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for changePasswordChangePasswordPost operation in UsersApi.
 * @export
 * @interface UsersApiChangePasswordChangePasswordPostRequest
 */
export interface UsersApiChangePasswordChangePasswordPostRequest {
  /**
   *
   * @type {ChangePassword}
   * @memberof UsersApiChangePasswordChangePasswordPost
   */
  readonly changePassword: ChangePassword;
}

/**
 * Request parameters for createUserUsersPost operation in UsersApi.
 * @export
 * @interface UsersApiCreateUserUsersPostRequest
 */
export interface UsersApiCreateUserUsersPostRequest {
  /**
   *
   * @type {CreateUserRequest}
   * @memberof UsersApiCreateUserUsersPost
   */
  readonly createUserRequest: CreateUserRequest;
}

/**
 * Request parameters for getAccessTokenGetTokenPost operation in UsersApi.
 * @export
 * @interface UsersApiGetAccessTokenGetTokenPostRequest
 */
export interface UsersApiGetAccessTokenGetTokenPostRequest {
  /**
   *
   * @type {GetAccessTokenRequest}
   * @memberof UsersApiGetAccessTokenGetTokenPost
   */
  readonly getAccessTokenRequest: GetAccessTokenRequest;
}

/**
 * Request parameters for getCurrentUserCurrentUserGet operation in UsersApi.
 * @export
 * @interface UsersApiGetCurrentUserCurrentUserGetRequest
 */
export interface UsersApiGetCurrentUserCurrentUserGetRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiGetCurrentUserCurrentUserGet
   */
  readonly workspaceUid?: number;
}

/**
 * Request parameters for getListUsersUsersGet operation in UsersApi.
 * @export
 * @interface UsersApiGetListUsersUsersGetRequest
 */
export interface UsersApiGetListUsersUsersGetRequest {
  /**
   *
   * @type {boolean}
   * @memberof UsersApiGetListUsersUsersGet
   */
  readonly includeInactive?: boolean;

  /**
   *
   * @type {number}
   * @memberof UsersApiGetListUsersUsersGet
   */
  readonly workspaceUid?: number;
}

/**
 * Request parameters for refreshTokenRefreshTokenPost operation in UsersApi.
 * @export
 * @interface UsersApiRefreshTokenRefreshTokenPostRequest
 */
export interface UsersApiRefreshTokenRefreshTokenPostRequest {
  /**
   *
   * @type {RefreshToken}
   * @memberof UsersApiRefreshTokenRefreshTokenPost
   */
  readonly refreshToken: RefreshToken;
}

/**
 * Request parameters for removeUserUsersUserUidDelete operation in UsersApi.
 * @export
 * @interface UsersApiRemoveUserUsersUserUidDeleteRequest
 */
export interface UsersApiRemoveUserUsersUserUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiRemoveUserUsersUserUidDelete
   */
  readonly userUid: number;
}

/**
 * Request parameters for resetPasswordResetPasswordPost operation in UsersApi.
 * @export
 * @interface UsersApiResetPasswordResetPasswordPostRequest
 */
export interface UsersApiResetPasswordResetPasswordPostRequest {
  /**
   *
   * @type {ResetPasswordParams}
   * @memberof UsersApiResetPasswordResetPasswordPost
   */
  readonly resetPasswordParams: ResetPasswordParams;
}

/**
 * Request parameters for updateUserEmailUsersUserUidEmailPut operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserEmailUsersUserUidEmailPutRequest
 */
export interface UsersApiUpdateUserEmailUsersUserUidEmailPutRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUpdateUserEmailUsersUserUidEmailPut
   */
  readonly userUid: number;

  /**
   *
   * @type {UpdateUserEmail}
   * @memberof UsersApiUpdateUserEmailUsersUserUidEmailPut
   */
  readonly updateUserEmail: UpdateUserEmail;
}

/**
 * Request parameters for updateUserRoleUsersUserUidRolePut operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserRoleUsersUserUidRolePutRequest
 */
export interface UsersApiUpdateUserRoleUsersUserUidRolePutRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUpdateUserRoleUsersUserUidRolePut
   */
  readonly userUid: number;

  /**
   *
   * @type {UpdateUserRole}
   * @memberof UsersApiUpdateUserRoleUsersUserUidRolePut
   */
  readonly updateUserRole: UpdateUserRole;
}

/**
 * Request parameters for updateUserTimezoneUsersUserUidTimezonePut operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserTimezoneUsersUserUidTimezonePutRequest
 */
export interface UsersApiUpdateUserTimezoneUsersUserUidTimezonePutRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUpdateUserTimezoneUsersUserUidTimezonePut
   */
  readonly userUid: number;

  /**
   *
   * @type {UpdateUserTimezone}
   * @memberof UsersApiUpdateUserTimezoneUsersUserUidTimezonePut
   */
  readonly updateUserTimezone: UpdateUserTimezone;
}

/**
 * Request parameters for updateUserUsersUserUidPut operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserUsersUserUidPutRequest
 */
export interface UsersApiUpdateUserUsersUserUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUpdateUserUsersUserUidPut
   */
  readonly userUid: number;

  /**
   *
   * @type {UpdateUserPayload}
   * @memberof UsersApiUpdateUserUsersUserUidPut
   */
  readonly updateUserPayload: UpdateUserPayload;
}

/**
 * Request parameters for updateUserViewUsersUserUidDefaultViewPut operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserViewUsersUserUidDefaultViewPutRequest
 */
export interface UsersApiUpdateUserViewUsersUserUidDefaultViewPutRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUpdateUserViewUsersUserUidDefaultViewPut
   */
  readonly userUid: number;

  /**
   *
   * @type {UpdateUserView}
   * @memberof UsersApiUpdateUserViewUsersUserUidDefaultViewPut
   */
  readonly updateUserView: UpdateUserView;
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * Add user to users table.
   * @summary Change Password
   * @param {UsersApiChangePasswordChangePasswordPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public changePasswordChangePasswordPost(
    requestParameters: UsersApiChangePasswordChangePasswordPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .changePasswordChangePasswordPost(
        requestParameters.changePassword,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Add user to users table.
   * @summary Create User
   * @param {UsersApiCreateUserUsersPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createUserUsersPost(
    requestParameters: UsersApiCreateUserUsersPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .createUserUsersPost(requestParameters.createUserRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Exchange a username and password for access and refresh tokens. Rejects requests with no matching usernames or passwords. Will also reject requests if SSO is required.
   * @summary Get Access Token
   * @param {UsersApiGetAccessTokenGetTokenPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getAccessTokenGetTokenPost(
    requestParameters: UsersApiGetAccessTokenGetTokenPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .getAccessTokenGetTokenPost(
        requestParameters.getAccessTokenRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Caller must include an active access-token in the authorization headers, the middleware will swap this out for a ContextVariable containing the call\'s user_uid, use that to return the corresponding User object
   * @summary Get Current User
   * @param {UsersApiGetCurrentUserCurrentUserGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getCurrentUserCurrentUserGet(
    requestParameters: UsersApiGetCurrentUserCurrentUserGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .getCurrentUserCurrentUserGet(requestParameters.workspaceUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Header Token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getHeaderTokenHeaderTokenGet(options?: RawAxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .getHeaderTokenHeaderTokenGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Jwt Signing Info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getJwtSigningInfoJwtSigningInfoGet(options?: RawAxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .getJwtSigningInfoJwtSigningInfoGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List users that the requesting user has access to  Parameters ---------- include_inactive: Flag to include inactive users in the response workspace_uid: Optional workspace_uid to filter response by users with roles in the given workspace If not provided, all users within the workspaces the user has access to will be included in the response.  Returns ------- List of users
   * @summary Get List Users
   * @param {UsersApiGetListUsersUsersGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getListUsersUsersGet(
    requestParameters: UsersApiGetListUsersUsersGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .getListUsersUsersGet(
        requestParameters.includeInactive,
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Refresh Token
   * @param {UsersApiRefreshTokenRefreshTokenPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public refreshTokenRefreshTokenPost(
    requestParameters: UsersApiRefreshTokenRefreshTokenPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .refreshTokenRefreshTokenPost(requestParameters.refreshToken, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Delete user.
   * @summary Remove User
   * @param {UsersApiRemoveUserUsersUserUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public removeUserUsersUserUidDelete(
    requestParameters: UsersApiRemoveUserUsersUserUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .removeUserUsersUserUidDelete(requestParameters.userUid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Allow administrators to change any user\'s password
   * @summary Reset Password
   * @param {UsersApiResetPasswordResetPasswordPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public resetPasswordResetPasswordPost(
    requestParameters: UsersApiResetPasswordResetPasswordPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .resetPasswordResetPasswordPost(
        requestParameters.resetPasswordParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update User Email
   * @param {UsersApiUpdateUserEmailUsersUserUidEmailPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserEmailUsersUserUidEmailPut(
    requestParameters: UsersApiUpdateUserEmailUsersUserUidEmailPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .updateUserEmailUsersUserUidEmailPut(
        requestParameters.userUid,
        requestParameters.updateUserEmail,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update User Role
   * @param {UsersApiUpdateUserRoleUsersUserUidRolePutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserRoleUsersUserUidRolePut(
    requestParameters: UsersApiUpdateUserRoleUsersUserUidRolePutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .updateUserRoleUsersUserUidRolePut(
        requestParameters.userUid,
        requestParameters.updateUserRole,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update User Timezone
   * @param {UsersApiUpdateUserTimezoneUsersUserUidTimezonePutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserTimezoneUsersUserUidTimezonePut(
    requestParameters: UsersApiUpdateUserTimezoneUsersUserUidTimezonePutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .updateUserTimezoneUsersUserUidTimezonePut(
        requestParameters.userUid,
        requestParameters.updateUserTimezone,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update User
   * @param {UsersApiUpdateUserUsersUserUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserUsersUserUidPut(
    requestParameters: UsersApiUpdateUserUsersUserUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .updateUserUsersUserUidPut(
        requestParameters.userUid,
        requestParameters.updateUserPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update User View
   * @param {UsersApiUpdateUserViewUsersUserUidDefaultViewPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserViewUsersUserUidDefaultViewPut(
    requestParameters: UsersApiUpdateUserViewUsersUserUidDefaultViewPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .updateUserViewUsersUserUidDefaultViewPut(
        requestParameters.userUid,
        requestParameters.updateUserView,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * WarmStartApi - axios parameter creator
 * @export
 */
export const WarmStartApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Endpoint to get all supported warm start methods for a node.
     * @summary Get Available Warm Start Methods
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGet:
      async (
        nodeUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGet',
          'nodeUid',
          nodeUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/get-available-warm-start-methods`.replace(
            `{${'node_uid'}}`,
            encodeURIComponent(String(nodeUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Returns the Snorkel recommended models for each warm start method.
     * @summary Get Recommended Warm Start Models
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedWarmStartModelsGetRecommendedWarmStartModelsGet: async (
      workspaceUid?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/get-recommended-warm-start-models`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Endpoint to return the recommended ZSL and FSL warm start method for the welcome modal.  Return:     Option 1 (not supported):         msg: str             Reason why warm start is not supported     Option 2 (both supported):         results: Dict[str, Tuple[bool, str, Optional[str]]]             ZSL: tuple                 (supported_bool=True, method_name, [msg])             FSL: tuple                 (supported_bool=True, method_name, [msg])     Option 3 (only one supported):             ZSL: tuple                 (supported_bool=True, method_name, [msg])             FSL: tuple                 (supported_bool=False, msg)
     * @summary Get Supported Warm Start Methods
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGet:
      async (
        nodeUid: number,
        options: RawAxiosRequestConfig = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'nodeUid' is not null or undefined
        assertParamExists(
          'getSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGet',
          'nodeUid',
          nodeUid,
        );
        const localVarPath =
          `/nodes/{node_uid}/get-supported-warm-start-methods`.replace(
            `{${'node_uid'}}`,
            encodeURIComponent(String(nodeUid)),
          );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     * Endpoint to check that a foundation model is supported for a specified Warm Start method.
     * @summary Is Warm Start Model Supported
     * @param {ModelSupportStatusInput} modelSupportStatusInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isWarmStartModelSupportedIsWarmStartModelSupportedPost: async (
      modelSupportStatusInput: ModelSupportStatusInput,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelSupportStatusInput' is not null or undefined
      assertParamExists(
        'isWarmStartModelSupportedIsWarmStartModelSupportedPost',
        'modelSupportStatusInput',
        modelSupportStatusInput,
      );
      const localVarPath = `/is-warm-start-model-supported`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        modelSupportStatusInput,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WarmStartApi - functional programming interface
 * @export
 */
export const WarmStartApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    WarmStartApiAxiosParamCreator(configuration);
  return {
    /**
     * Endpoint to get all supported warm start methods for a node.
     * @summary Get Available Warm Start Methods
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WarmStartMethodsResults>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WarmStartApi.getAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Returns the Snorkel recommended models for each warm start method.
     * @summary Get Recommended Warm Start Models
     * @param {number} [workspaceUid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecommendedWarmStartModelsGetRecommendedWarmStartModelsGet(
      workspaceUid?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RecommendedWarmStartModelsResults>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRecommendedWarmStartModelsGetRecommendedWarmStartModelsGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WarmStartApi.getRecommendedWarmStartModelsGetRecommendedWarmStartModelsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Endpoint to return the recommended ZSL and FSL warm start method for the welcome modal.  Return:     Option 1 (not supported):         msg: str             Reason why warm start is not supported     Option 2 (both supported):         results: Dict[str, Tuple[bool, str, Optional[str]]]             ZSL: tuple                 (supported_bool=True, method_name, [msg])             FSL: tuple                 (supported_bool=True, method_name, [msg])     Option 3 (only one supported):             ZSL: tuple                 (supported_bool=True, method_name, [msg])             FSL: tuple                 (supported_bool=False, msg)
     * @summary Get Supported Warm Start Methods
     * @param {number} nodeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGet(
      nodeUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupportedWarmStartMethods>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGet(
          nodeUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WarmStartApi.getSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Endpoint to check that a foundation model is supported for a specified Warm Start method.
     * @summary Is Warm Start Model Supported
     * @param {ModelSupportStatusInput} modelSupportStatusInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isWarmStartModelSupportedIsWarmStartModelSupportedPost(
      modelSupportStatusInput: ModelSupportStatusInput,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ModelSupportStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.isWarmStartModelSupportedIsWarmStartModelSupportedPost(
          modelSupportStatusInput,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WarmStartApi.isWarmStartModelSupportedIsWarmStartModelSupportedPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * WarmStartApi - factory interface
 * @export
 */
export const WarmStartApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WarmStartApiFp(configuration);
  return {
    /**
     * Endpoint to get all supported warm start methods for a node.
     * @summary Get Available Warm Start Methods
     * @param {WarmStartApiGetAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGet(
      requestParameters: WarmStartApiGetAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<WarmStartMethodsResults> {
      return localVarFp
        .getAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Returns the Snorkel recommended models for each warm start method.
     * @summary Get Recommended Warm Start Models
     * @param {WarmStartApiGetRecommendedWarmStartModelsGetRecommendedWarmStartModelsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedWarmStartModelsGetRecommendedWarmStartModelsGet(
      requestParameters: WarmStartApiGetRecommendedWarmStartModelsGetRecommendedWarmStartModelsGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<RecommendedWarmStartModelsResults> {
      return localVarFp
        .getRecommendedWarmStartModelsGetRecommendedWarmStartModelsGet(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Endpoint to return the recommended ZSL and FSL warm start method for the welcome modal.  Return:     Option 1 (not supported):         msg: str             Reason why warm start is not supported     Option 2 (both supported):         results: Dict[str, Tuple[bool, str, Optional[str]]]             ZSL: tuple                 (supported_bool=True, method_name, [msg])             FSL: tuple                 (supported_bool=True, method_name, [msg])     Option 3 (only one supported):             ZSL: tuple                 (supported_bool=True, method_name, [msg])             FSL: tuple                 (supported_bool=False, msg)
     * @summary Get Supported Warm Start Methods
     * @param {WarmStartApiGetSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGet(
      requestParameters: WarmStartApiGetSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SupportedWarmStartMethods> {
      return localVarFp
        .getSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGet(
          requestParameters.nodeUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Endpoint to check that a foundation model is supported for a specified Warm Start method.
     * @summary Is Warm Start Model Supported
     * @param {WarmStartApiIsWarmStartModelSupportedIsWarmStartModelSupportedPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isWarmStartModelSupportedIsWarmStartModelSupportedPost(
      requestParameters: WarmStartApiIsWarmStartModelSupportedIsWarmStartModelSupportedPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ModelSupportStatusResponse> {
      return localVarFp
        .isWarmStartModelSupportedIsWarmStartModelSupportedPost(
          requestParameters.modelSupportStatusInput,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGet operation in WarmStartApi.
 * @export
 * @interface WarmStartApiGetAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGetRequest
 */
export interface WarmStartApiGetAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGetRequest {
  /**
   *
   * @type {number}
   * @memberof WarmStartApiGetAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for getRecommendedWarmStartModelsGetRecommendedWarmStartModelsGet operation in WarmStartApi.
 * @export
 * @interface WarmStartApiGetRecommendedWarmStartModelsGetRecommendedWarmStartModelsGetRequest
 */
export interface WarmStartApiGetRecommendedWarmStartModelsGetRecommendedWarmStartModelsGetRequest {
  /**
   *
   * @type {number}
   * @memberof WarmStartApiGetRecommendedWarmStartModelsGetRecommendedWarmStartModelsGet
   */
  readonly workspaceUid?: number;
}

/**
 * Request parameters for getSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGet operation in WarmStartApi.
 * @export
 * @interface WarmStartApiGetSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGetRequest
 */
export interface WarmStartApiGetSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGetRequest {
  /**
   *
   * @type {number}
   * @memberof WarmStartApiGetSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGet
   */
  readonly nodeUid: number;
}

/**
 * Request parameters for isWarmStartModelSupportedIsWarmStartModelSupportedPost operation in WarmStartApi.
 * @export
 * @interface WarmStartApiIsWarmStartModelSupportedIsWarmStartModelSupportedPostRequest
 */
export interface WarmStartApiIsWarmStartModelSupportedIsWarmStartModelSupportedPostRequest {
  /**
   *
   * @type {ModelSupportStatusInput}
   * @memberof WarmStartApiIsWarmStartModelSupportedIsWarmStartModelSupportedPost
   */
  readonly modelSupportStatusInput: ModelSupportStatusInput;
}

/**
 * WarmStartApi - object-oriented interface
 * @export
 * @class WarmStartApi
 * @extends {BaseAPI}
 */
export class WarmStartApi extends BaseAPI {
  /**
   * Endpoint to get all supported warm start methods for a node.
   * @summary Get Available Warm Start Methods
   * @param {WarmStartApiGetAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarmStartApi
   */
  public getAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGet(
    requestParameters: WarmStartApiGetAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WarmStartApiFp(this.configuration)
      .getAvailableWarmStartMethodsNodesNodeUidGetAvailableWarmStartMethodsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Returns the Snorkel recommended models for each warm start method.
   * @summary Get Recommended Warm Start Models
   * @param {WarmStartApiGetRecommendedWarmStartModelsGetRecommendedWarmStartModelsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarmStartApi
   */
  public getRecommendedWarmStartModelsGetRecommendedWarmStartModelsGet(
    requestParameters: WarmStartApiGetRecommendedWarmStartModelsGetRecommendedWarmStartModelsGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return WarmStartApiFp(this.configuration)
      .getRecommendedWarmStartModelsGetRecommendedWarmStartModelsGet(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Endpoint to return the recommended ZSL and FSL warm start method for the welcome modal.  Return:     Option 1 (not supported):         msg: str             Reason why warm start is not supported     Option 2 (both supported):         results: Dict[str, Tuple[bool, str, Optional[str]]]             ZSL: tuple                 (supported_bool=True, method_name, [msg])             FSL: tuple                 (supported_bool=True, method_name, [msg])     Option 3 (only one supported):             ZSL: tuple                 (supported_bool=True, method_name, [msg])             FSL: tuple                 (supported_bool=False, msg)
   * @summary Get Supported Warm Start Methods
   * @param {WarmStartApiGetSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarmStartApi
   */
  public getSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGet(
    requestParameters: WarmStartApiGetSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WarmStartApiFp(this.configuration)
      .getSupportedWarmStartMethodsNodesNodeUidGetSupportedWarmStartMethodsGet(
        requestParameters.nodeUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Endpoint to check that a foundation model is supported for a specified Warm Start method.
   * @summary Is Warm Start Model Supported
   * @param {WarmStartApiIsWarmStartModelSupportedIsWarmStartModelSupportedPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarmStartApi
   */
  public isWarmStartModelSupportedIsWarmStartModelSupportedPost(
    requestParameters: WarmStartApiIsWarmStartModelSupportedIsWarmStartModelSupportedPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WarmStartApiFp(this.configuration)
      .isWarmStartModelSupportedIsWarmStartModelSupportedPost(
        requestParameters.modelSupportStatusInput,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * WorkflowsApi - axios parameter creator
 * @export
 */
export const WorkflowsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Workflow
     * @param {number} workflowUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWorkflowWorkflowsWorkflowUidDelete: async (
      workflowUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workflowUid' is not null or undefined
      assertParamExists(
        'deleteWorkflowWorkflowsWorkflowUidDelete',
        'workflowUid',
        workflowUid,
      );
      const localVarPath = `/workflows/{workflow_uid}`.replace(
        `{${'workflow_uid'}}`,
        encodeURIComponent(String(workflowUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Execute Operators
     * @param {ExecuteOperatorsParams} executeOperatorsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeOperatorsWorkflowsExecuteOperatorsPost: async (
      executeOperatorsParams: ExecuteOperatorsParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'executeOperatorsParams' is not null or undefined
      assertParamExists(
        'executeOperatorsWorkflowsExecuteOperatorsPost',
        'executeOperatorsParams',
        executeOperatorsParams,
      );
      const localVarPath = `/workflows/execute-operators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        executeOperatorsParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Execute Workflow Async
     * @param {number} workflowUid
     * @param {ExecuteWorkflowFileParams} executeWorkflowFileParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost: async (
      workflowUid: number,
      executeWorkflowFileParams: ExecuteWorkflowFileParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workflowUid' is not null or undefined
      assertParamExists(
        'executeWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost',
        'workflowUid',
        workflowUid,
      );
      // verify required parameter 'executeWorkflowFileParams' is not null or undefined
      assertParamExists(
        'executeWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost',
        'executeWorkflowFileParams',
        executeWorkflowFileParams,
      );
      const localVarPath =
        `/workflows/{workflow_uid}/execute-batch-async`.replace(
          `{${'workflow_uid'}}`,
          encodeURIComponent(String(workflowUid)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        executeWorkflowFileParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Execute Workflow Online
     * @param {number} workflowUid
     * @param {ExecuteWorkflowJSONParams} executeWorkflowJSONParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost: async (
      workflowUid: number,
      executeWorkflowJSONParams: ExecuteWorkflowJSONParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workflowUid' is not null or undefined
      assertParamExists(
        'executeWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost',
        'workflowUid',
        workflowUid,
      );
      // verify required parameter 'executeWorkflowJSONParams' is not null or undefined
      assertParamExists(
        'executeWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost',
        'executeWorkflowJSONParams',
        executeWorkflowJSONParams,
      );
      const localVarPath = `/workflows/{workflow_uid}/execute-online`.replace(
        `{${'workflow_uid'}}`,
        encodeURIComponent(String(workflowUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        executeWorkflowJSONParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Execute Workflow
     * @param {number} workflowUid
     * @param {ExecuteWorkflowFileParams} executeWorkflowFileParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeWorkflowWorkflowsWorkflowUidExecuteBatchPost: async (
      workflowUid: number,
      executeWorkflowFileParams: ExecuteWorkflowFileParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workflowUid' is not null or undefined
      assertParamExists(
        'executeWorkflowWorkflowsWorkflowUidExecuteBatchPost',
        'workflowUid',
        workflowUid,
      );
      // verify required parameter 'executeWorkflowFileParams' is not null or undefined
      assertParamExists(
        'executeWorkflowWorkflowsWorkflowUidExecuteBatchPost',
        'executeWorkflowFileParams',
        executeWorkflowFileParams,
      );
      const localVarPath = `/workflows/{workflow_uid}/execute-batch`.replace(
        `{${'workflow_uid'}}`,
        encodeURIComponent(String(workflowUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        executeWorkflowFileParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Exports a workflow (processors, model, reducers/normalizers) for task.
     * @summary Export Workflow
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {ExportWorkflowParams} exportWorkflowParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost: async (
      nodeUid: number,
      modelUid: number,
      exportWorkflowParams: ExportWorkflowParams,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'nodeUid' is not null or undefined
      assertParamExists(
        'exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost',
        'nodeUid',
        nodeUid,
      );
      // verify required parameter 'modelUid' is not null or undefined
      assertParamExists(
        'exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost',
        'modelUid',
        modelUid,
      );
      // verify required parameter 'exportWorkflowParams' is not null or undefined
      assertParamExists(
        'exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost',
        'exportWorkflowParams',
        exportWorkflowParams,
      );
      const localVarPath =
        `/nodes/{node_uid}/models/{model_uid}/export-workflow`
          .replace(`{${'node_uid'}}`, encodeURIComponent(String(nodeUid)))
          .replace(`{${'model_uid'}}`, encodeURIComponent(String(modelUid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        exportWorkflowParams,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch workflows.  Parameters ----------     valid_dataset_uid         If specified, filter workflows by data_schemas that match the data_schema         of the specified valid_dataset_uid
     * @summary Fetch Workflows
     * @param {number} workspaceUid
     * @param {number} [validDatasetUid]
     * @param {boolean} [hydrate]
     * @param {number} [applicationUid]
     * @param {number} [deployedBy]
     * @param {number} [deployedWithinDays]
     * @param {SortableDeploymentColumns} [sortColumn]
     * @param {SortOrder} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchWorkflowsWorkflowsGet: async (
      workspaceUid: number,
      validDatasetUid?: number,
      hydrate?: boolean,
      applicationUid?: number,
      deployedBy?: number,
      deployedWithinDays?: number,
      sortColumn?: SortableDeploymentColumns,
      sortOrder?: SortOrder,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'fetchWorkflowsWorkflowsGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/workflows`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceUid !== undefined) {
        localVarQueryParameter['workspace_uid'] = workspaceUid;
      }

      if (validDatasetUid !== undefined) {
        localVarQueryParameter['valid_dataset_uid'] = validDatasetUid;
      }

      if (hydrate !== undefined) {
        localVarQueryParameter['hydrate'] = hydrate;
      }

      if (applicationUid !== undefined) {
        localVarQueryParameter['application_uid'] = applicationUid;
      }

      if (deployedBy !== undefined) {
        localVarQueryParameter['deployed_by'] = deployedBy;
      }

      if (deployedWithinDays !== undefined) {
        localVarQueryParameter['deployed_within_days'] = deployedWithinDays;
      }

      if (sortColumn !== undefined) {
        localVarQueryParameter['sort_column'] = sortColumn;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Workflow
     * @param {number} workflowUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkflowWorkflowsWorkflowUidGet: async (
      workflowUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workflowUid' is not null or undefined
      assertParamExists(
        'getWorkflowWorkflowsWorkflowUidGet',
        'workflowUid',
        workflowUid,
      );
      const localVarPath = `/workflows/{workflow_uid}`.replace(
        `{${'workflow_uid'}}`,
        encodeURIComponent(String(workflowUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WorkflowsApi - functional programming interface
 * @export
 */
export const WorkflowsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    WorkflowsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Workflow
     * @param {number} workflowUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteWorkflowWorkflowsWorkflowUidDelete(
      workflowUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteWorkflowWorkflowsWorkflowUidDelete(
          workflowUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkflowsApi.deleteWorkflowWorkflowsWorkflowUidDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Execute Operators
     * @param {ExecuteOperatorsParams} executeOperatorsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async executeOperatorsWorkflowsExecuteOperatorsPost(
      executeOperatorsParams: ExecuteOperatorsParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.executeOperatorsWorkflowsExecuteOperatorsPost(
          executeOperatorsParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkflowsApi.executeOperatorsWorkflowsExecuteOperatorsPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Execute Workflow Async
     * @param {number} workflowUid
     * @param {ExecuteWorkflowFileParams} executeWorkflowFileParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async executeWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost(
      workflowUid: number,
      executeWorkflowFileParams: ExecuteWorkflowFileParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AsyncJobResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.executeWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost(
          workflowUid,
          executeWorkflowFileParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkflowsApi.executeWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Execute Workflow Online
     * @param {number} workflowUid
     * @param {ExecuteWorkflowJSONParams} executeWorkflowJSONParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async executeWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost(
      workflowUid: number,
      executeWorkflowJSONParams: ExecuteWorkflowJSONParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.executeWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost(
          workflowUid,
          executeWorkflowJSONParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkflowsApi.executeWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Execute Workflow
     * @param {number} workflowUid
     * @param {ExecuteWorkflowFileParams} executeWorkflowFileParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async executeWorkflowWorkflowsWorkflowUidExecuteBatchPost(
      workflowUid: number,
      executeWorkflowFileParams: ExecuteWorkflowFileParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.executeWorkflowWorkflowsWorkflowUidExecuteBatchPost(
          workflowUid,
          executeWorkflowFileParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkflowsApi.executeWorkflowWorkflowsWorkflowUidExecuteBatchPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Exports a workflow (processors, model, reducers/normalizers) for task.
     * @summary Export Workflow
     * @param {number} nodeUid
     * @param {number} modelUid
     * @param {ExportWorkflowParams} exportWorkflowParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost(
      nodeUid: number,
      modelUid: number,
      exportWorkflowParams: ExportWorkflowParams,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost(
          nodeUid,
          modelUid,
          exportWorkflowParams,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkflowsApi.exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch workflows.  Parameters ----------     valid_dataset_uid         If specified, filter workflows by data_schemas that match the data_schema         of the specified valid_dataset_uid
     * @summary Fetch Workflows
     * @param {number} workspaceUid
     * @param {number} [validDatasetUid]
     * @param {boolean} [hydrate]
     * @param {number} [applicationUid]
     * @param {number} [deployedBy]
     * @param {number} [deployedWithinDays]
     * @param {SortableDeploymentColumns} [sortColumn]
     * @param {SortOrder} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchWorkflowsWorkflowsGet(
      workspaceUid: number,
      validDatasetUid?: number,
      hydrate?: boolean,
      applicationUid?: number,
      deployedBy?: number,
      deployedWithinDays?: number,
      sortColumn?: SortableDeploymentColumns,
      sortOrder?: SortOrder,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<HydrateApplicationDeploymentMetadata>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchWorkflowsWorkflowsGet(
          workspaceUid,
          validDatasetUid,
          hydrate,
          applicationUid,
          deployedBy,
          deployedWithinDays,
          sortColumn,
          sortOrder,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['WorkflowsApi.fetchWorkflowsWorkflowsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Workflow
     * @param {number} workflowUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkflowWorkflowsWorkflowUidGet(
      workflowUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationDeployment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWorkflowWorkflowsWorkflowUidGet(
          workflowUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['WorkflowsApi.getWorkflowWorkflowsWorkflowUidGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * WorkflowsApi - factory interface
 * @export
 */
export const WorkflowsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WorkflowsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Workflow
     * @param {WorkflowsApiDeleteWorkflowWorkflowsWorkflowUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWorkflowWorkflowsWorkflowUidDelete(
      requestParameters: WorkflowsApiDeleteWorkflowWorkflowsWorkflowUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteWorkflowWorkflowsWorkflowUidDelete(
          requestParameters.workflowUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Execute Operators
     * @param {WorkflowsApiExecuteOperatorsWorkflowsExecuteOperatorsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeOperatorsWorkflowsExecuteOperatorsPost(
      requestParameters: WorkflowsApiExecuteOperatorsWorkflowsExecuteOperatorsPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .executeOperatorsWorkflowsExecuteOperatorsPost(
          requestParameters.executeOperatorsParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Execute Workflow Async
     * @param {WorkflowsApiExecuteWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost(
      requestParameters: WorkflowsApiExecuteWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<AsyncJobResponse> {
      return localVarFp
        .executeWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost(
          requestParameters.workflowUid,
          requestParameters.executeWorkflowFileParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Execute Workflow Online
     * @param {WorkflowsApiExecuteWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost(
      requestParameters: WorkflowsApiExecuteWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .executeWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost(
          requestParameters.workflowUid,
          requestParameters.executeWorkflowJSONParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Execute Workflow
     * @param {WorkflowsApiExecuteWorkflowWorkflowsWorkflowUidExecuteBatchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeWorkflowWorkflowsWorkflowUidExecuteBatchPost(
      requestParameters: WorkflowsApiExecuteWorkflowWorkflowsWorkflowUidExecuteBatchPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .executeWorkflowWorkflowsWorkflowUidExecuteBatchPost(
          requestParameters.workflowUid,
          requestParameters.executeWorkflowFileParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Exports a workflow (processors, model, reducers/normalizers) for task.
     * @summary Export Workflow
     * @param {WorkflowsApiExportWorkflowNodesNodeUidModelsModelUidExportWorkflowPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost(
      requestParameters: WorkflowsApiExportWorkflowNodesNodeUidModelsModelUidExportWorkflowPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost(
          requestParameters.nodeUid,
          requestParameters.modelUid,
          requestParameters.exportWorkflowParams,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch workflows.  Parameters ----------     valid_dataset_uid         If specified, filter workflows by data_schemas that match the data_schema         of the specified valid_dataset_uid
     * @summary Fetch Workflows
     * @param {WorkflowsApiFetchWorkflowsWorkflowsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchWorkflowsWorkflowsGet(
      requestParameters: WorkflowsApiFetchWorkflowsWorkflowsGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<HydrateApplicationDeploymentMetadata>> {
      return localVarFp
        .fetchWorkflowsWorkflowsGet(
          requestParameters.workspaceUid,
          requestParameters.validDatasetUid,
          requestParameters.hydrate,
          requestParameters.applicationUid,
          requestParameters.deployedBy,
          requestParameters.deployedWithinDays,
          requestParameters.sortColumn,
          requestParameters.sortOrder,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Workflow
     * @param {WorkflowsApiGetWorkflowWorkflowsWorkflowUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkflowWorkflowsWorkflowUidGet(
      requestParameters: WorkflowsApiGetWorkflowWorkflowsWorkflowUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ApplicationDeployment> {
      return localVarFp
        .getWorkflowWorkflowsWorkflowUidGet(
          requestParameters.workflowUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for deleteWorkflowWorkflowsWorkflowUidDelete operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiDeleteWorkflowWorkflowsWorkflowUidDeleteRequest
 */
export interface WorkflowsApiDeleteWorkflowWorkflowsWorkflowUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof WorkflowsApiDeleteWorkflowWorkflowsWorkflowUidDelete
   */
  readonly workflowUid: number;
}

/**
 * Request parameters for executeOperatorsWorkflowsExecuteOperatorsPost operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiExecuteOperatorsWorkflowsExecuteOperatorsPostRequest
 */
export interface WorkflowsApiExecuteOperatorsWorkflowsExecuteOperatorsPostRequest {
  /**
   *
   * @type {ExecuteOperatorsParams}
   * @memberof WorkflowsApiExecuteOperatorsWorkflowsExecuteOperatorsPost
   */
  readonly executeOperatorsParams: ExecuteOperatorsParams;
}

/**
 * Request parameters for executeWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiExecuteWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPostRequest
 */
export interface WorkflowsApiExecuteWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPostRequest {
  /**
   *
   * @type {number}
   * @memberof WorkflowsApiExecuteWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost
   */
  readonly workflowUid: number;

  /**
   *
   * @type {ExecuteWorkflowFileParams}
   * @memberof WorkflowsApiExecuteWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost
   */
  readonly executeWorkflowFileParams: ExecuteWorkflowFileParams;
}

/**
 * Request parameters for executeWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiExecuteWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePostRequest
 */
export interface WorkflowsApiExecuteWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePostRequest {
  /**
   *
   * @type {number}
   * @memberof WorkflowsApiExecuteWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost
   */
  readonly workflowUid: number;

  /**
   *
   * @type {ExecuteWorkflowJSONParams}
   * @memberof WorkflowsApiExecuteWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost
   */
  readonly executeWorkflowJSONParams: ExecuteWorkflowJSONParams;
}

/**
 * Request parameters for executeWorkflowWorkflowsWorkflowUidExecuteBatchPost operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiExecuteWorkflowWorkflowsWorkflowUidExecuteBatchPostRequest
 */
export interface WorkflowsApiExecuteWorkflowWorkflowsWorkflowUidExecuteBatchPostRequest {
  /**
   *
   * @type {number}
   * @memberof WorkflowsApiExecuteWorkflowWorkflowsWorkflowUidExecuteBatchPost
   */
  readonly workflowUid: number;

  /**
   *
   * @type {ExecuteWorkflowFileParams}
   * @memberof WorkflowsApiExecuteWorkflowWorkflowsWorkflowUidExecuteBatchPost
   */
  readonly executeWorkflowFileParams: ExecuteWorkflowFileParams;
}

/**
 * Request parameters for exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiExportWorkflowNodesNodeUidModelsModelUidExportWorkflowPostRequest
 */
export interface WorkflowsApiExportWorkflowNodesNodeUidModelsModelUidExportWorkflowPostRequest {
  /**
   *
   * @type {number}
   * @memberof WorkflowsApiExportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost
   */
  readonly nodeUid: number;

  /**
   *
   * @type {number}
   * @memberof WorkflowsApiExportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost
   */
  readonly modelUid: number;

  /**
   *
   * @type {ExportWorkflowParams}
   * @memberof WorkflowsApiExportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost
   */
  readonly exportWorkflowParams: ExportWorkflowParams;
}

/**
 * Request parameters for fetchWorkflowsWorkflowsGet operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiFetchWorkflowsWorkflowsGetRequest
 */
export interface WorkflowsApiFetchWorkflowsWorkflowsGetRequest {
  /**
   *
   * @type {number}
   * @memberof WorkflowsApiFetchWorkflowsWorkflowsGet
   */
  readonly workspaceUid: number;

  /**
   *
   * @type {number}
   * @memberof WorkflowsApiFetchWorkflowsWorkflowsGet
   */
  readonly validDatasetUid?: number;

  /**
   *
   * @type {boolean}
   * @memberof WorkflowsApiFetchWorkflowsWorkflowsGet
   */
  readonly hydrate?: boolean;

  /**
   *
   * @type {number}
   * @memberof WorkflowsApiFetchWorkflowsWorkflowsGet
   */
  readonly applicationUid?: number;

  /**
   *
   * @type {number}
   * @memberof WorkflowsApiFetchWorkflowsWorkflowsGet
   */
  readonly deployedBy?: number;

  /**
   *
   * @type {number}
   * @memberof WorkflowsApiFetchWorkflowsWorkflowsGet
   */
  readonly deployedWithinDays?: number;

  /**
   *
   * @type {SortableDeploymentColumns}
   * @memberof WorkflowsApiFetchWorkflowsWorkflowsGet
   */
  readonly sortColumn?: SortableDeploymentColumns;

  /**
   *
   * @type {SortOrder}
   * @memberof WorkflowsApiFetchWorkflowsWorkflowsGet
   */
  readonly sortOrder?: SortOrder;
}

/**
 * Request parameters for getWorkflowWorkflowsWorkflowUidGet operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiGetWorkflowWorkflowsWorkflowUidGetRequest
 */
export interface WorkflowsApiGetWorkflowWorkflowsWorkflowUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof WorkflowsApiGetWorkflowWorkflowsWorkflowUidGet
   */
  readonly workflowUid: number;
}

/**
 * WorkflowsApi - object-oriented interface
 * @export
 * @class WorkflowsApi
 * @extends {BaseAPI}
 */
export class WorkflowsApi extends BaseAPI {
  /**
   *
   * @summary Delete Workflow
   * @param {WorkflowsApiDeleteWorkflowWorkflowsWorkflowUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public deleteWorkflowWorkflowsWorkflowUidDelete(
    requestParameters: WorkflowsApiDeleteWorkflowWorkflowsWorkflowUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkflowsApiFp(this.configuration)
      .deleteWorkflowWorkflowsWorkflowUidDelete(
        requestParameters.workflowUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Execute Operators
   * @param {WorkflowsApiExecuteOperatorsWorkflowsExecuteOperatorsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public executeOperatorsWorkflowsExecuteOperatorsPost(
    requestParameters: WorkflowsApiExecuteOperatorsWorkflowsExecuteOperatorsPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkflowsApiFp(this.configuration)
      .executeOperatorsWorkflowsExecuteOperatorsPost(
        requestParameters.executeOperatorsParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Execute Workflow Async
   * @param {WorkflowsApiExecuteWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public executeWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost(
    requestParameters: WorkflowsApiExecuteWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkflowsApiFp(this.configuration)
      .executeWorkflowAsyncWorkflowsWorkflowUidExecuteBatchAsyncPost(
        requestParameters.workflowUid,
        requestParameters.executeWorkflowFileParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Execute Workflow Online
   * @param {WorkflowsApiExecuteWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public executeWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost(
    requestParameters: WorkflowsApiExecuteWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkflowsApiFp(this.configuration)
      .executeWorkflowOnlineWorkflowsWorkflowUidExecuteOnlinePost(
        requestParameters.workflowUid,
        requestParameters.executeWorkflowJSONParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Execute Workflow
   * @param {WorkflowsApiExecuteWorkflowWorkflowsWorkflowUidExecuteBatchPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public executeWorkflowWorkflowsWorkflowUidExecuteBatchPost(
    requestParameters: WorkflowsApiExecuteWorkflowWorkflowsWorkflowUidExecuteBatchPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkflowsApiFp(this.configuration)
      .executeWorkflowWorkflowsWorkflowUidExecuteBatchPost(
        requestParameters.workflowUid,
        requestParameters.executeWorkflowFileParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Exports a workflow (processors, model, reducers/normalizers) for task.
   * @summary Export Workflow
   * @param {WorkflowsApiExportWorkflowNodesNodeUidModelsModelUidExportWorkflowPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost(
    requestParameters: WorkflowsApiExportWorkflowNodesNodeUidModelsModelUidExportWorkflowPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkflowsApiFp(this.configuration)
      .exportWorkflowNodesNodeUidModelsModelUidExportWorkflowPost(
        requestParameters.nodeUid,
        requestParameters.modelUid,
        requestParameters.exportWorkflowParams,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch workflows.  Parameters ----------     valid_dataset_uid         If specified, filter workflows by data_schemas that match the data_schema         of the specified valid_dataset_uid
   * @summary Fetch Workflows
   * @param {WorkflowsApiFetchWorkflowsWorkflowsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public fetchWorkflowsWorkflowsGet(
    requestParameters: WorkflowsApiFetchWorkflowsWorkflowsGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkflowsApiFp(this.configuration)
      .fetchWorkflowsWorkflowsGet(
        requestParameters.workspaceUid,
        requestParameters.validDatasetUid,
        requestParameters.hydrate,
        requestParameters.applicationUid,
        requestParameters.deployedBy,
        requestParameters.deployedWithinDays,
        requestParameters.sortColumn,
        requestParameters.sortOrder,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Workflow
   * @param {WorkflowsApiGetWorkflowWorkflowsWorkflowUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public getWorkflowWorkflowsWorkflowUidGet(
    requestParameters: WorkflowsApiGetWorkflowWorkflowsWorkflowUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkflowsApiFp(this.configuration)
      .getWorkflowWorkflowsWorkflowUidGet(
        requestParameters.workflowUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * WorkspaceApi - axios parameter creator
 * @export
 */
export const WorkspaceApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create Workspace
     * @param {CreateWorkspacePayload} createWorkspacePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWorkspaceWorkspacesPost: async (
      createWorkspacePayload: CreateWorkspacePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createWorkspacePayload' is not null or undefined
      assertParamExists(
        'createWorkspaceWorkspacesPost',
        'createWorkspacePayload',
        createWorkspacePayload,
      );
      const localVarPath = `/workspaces`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createWorkspacePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWorkspacesWorkspaceUidDelete: async (
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'deleteWorkspacesWorkspaceUidDelete',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/workspaces/{workspace_uid}`.replace(
        `{${'workspace_uid'}}`,
        encodeURIComponent(String(workspaceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch workspace AU usage audit information.
     * @summary Get Workspace Au Usage Audit
     * @param {string} [workspaceName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceAuUsageAuditWorkspaceAuUsageAuditGet: async (
      workspaceName?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/workspace/au-usage/audit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceName !== undefined) {
        localVarQueryParameter['workspace_name'] = workspaceName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch all workspace AU usage information.
     * @summary Get Workspace Au Usages
     * @param {string} [workspaceName]
     * @param {boolean} [showBreakdown]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceAuUsagesWorkspaceAuUsageGet: async (
      workspaceName?: string,
      showBreakdown?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/workspace/au-usage`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceName !== undefined) {
        localVarQueryParameter['workspace_name'] = workspaceName;
      }

      if (showBreakdown !== undefined) {
        localVarQueryParameter['show_breakdown'] = showBreakdown;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Workspace Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceSettingsWorkspaceSettingsGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/workspace-settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Workspace
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceWorkspacesWorkspaceUidGet: async (
      workspaceUid: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'getWorkspaceWorkspacesWorkspaceUidGet',
        'workspaceUid',
        workspaceUid,
      );
      const localVarPath = `/workspaces/{workspace_uid}`.replace(
        `{${'workspace_uid'}}`,
        encodeURIComponent(String(workspaceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List workspaces that the requesting user has access to  Parameters ---------- workspace_name : Workspace name to filter workspaces by roles : List of roles to filter workspaces by. The requesting user must have at least one of the roles within a workspace for it to be included in the response. If not provided, all workspaces the requesting user has access to view will be included in the response.  Returns ------- List of workspaces
     * @summary List Workspaces
     * @param {string} [workspaceName]
     * @param {Array<UserRole>} [roles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listWorkspacesWorkspacesGet: async (
      workspaceName?: string,
      roles?: Array<UserRole>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/workspaces`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workspaceName !== undefined) {
        localVarQueryParameter['workspace_name'] = workspaceName;
      }

      if (roles) {
        localVarQueryParameter['roles'] = roles;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Workspace Roles
     * @param {number} workspaceUid
     * @param {PatchWorkspaceRolesPayload} patchWorkspaceRolesPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch: async (
      workspaceUid: number,
      patchWorkspaceRolesPayload: PatchWorkspaceRolesPayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'patchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch',
        'workspaceUid',
        workspaceUid,
      );
      // verify required parameter 'patchWorkspaceRolesPayload' is not null or undefined
      assertParamExists(
        'patchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch',
        'patchWorkspaceRolesPayload',
        patchWorkspaceRolesPayload,
      );
      const localVarPath = `/workspaces/{workspace_uid}/roles`.replace(
        `{${'workspace_uid'}}`,
        encodeURIComponent(String(workspaceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchWorkspaceRolesPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Put Workspace
     * @param {number} workspaceUid
     * @param {PutWorkspacePayload} putWorkspacePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putWorkspaceWorkspacesWorkspaceUidPut: async (
      workspaceUid: number,
      putWorkspacePayload: PutWorkspacePayload,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceUid' is not null or undefined
      assertParamExists(
        'putWorkspaceWorkspacesWorkspaceUidPut',
        'workspaceUid',
        workspaceUid,
      );
      // verify required parameter 'putWorkspacePayload' is not null or undefined
      assertParamExists(
        'putWorkspaceWorkspacesWorkspaceUidPut',
        'putWorkspacePayload',
        putWorkspacePayload,
      );
      const localVarPath = `/workspaces/{workspace_uid}`.replace(
        `{${'workspace_uid'}}`,
        encodeURIComponent(String(workspaceUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        putWorkspacePayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Workspace Settings
     * @param {WorkspaceSettings} workspaceSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWorkspaceSettingsWorkspaceSettingsPut: async (
      workspaceSettings: WorkspaceSettings,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'workspaceSettings' is not null or undefined
      assertParamExists(
        'updateWorkspaceSettingsWorkspaceSettingsPut',
        'workspaceSettings',
        workspaceSettings,
      );
      const localVarPath = `/workspace-settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        workspaceSettings,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WorkspaceApi - functional programming interface
 * @export
 */
export const WorkspaceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    WorkspaceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Workspace
     * @param {CreateWorkspacePayload} createWorkspacePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createWorkspaceWorkspacesPost(
      createWorkspacePayload: CreateWorkspacePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateWorkspaceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createWorkspaceWorkspacesPost(
          createWorkspacePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['WorkspaceApi.createWorkspaceWorkspacesPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteWorkspacesWorkspaceUidDelete(
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteWorkspacesWorkspaceUidDelete(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['WorkspaceApi.deleteWorkspacesWorkspaceUidDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch workspace AU usage audit information.
     * @summary Get Workspace Au Usage Audit
     * @param {string} [workspaceName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkspaceAuUsageAuditWorkspaceAuUsageAuditGet(
      workspaceName?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkspaceAUUsageAuditResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWorkspaceAuUsageAuditWorkspaceAuUsageAuditGet(
          workspaceName,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkspaceApi.getWorkspaceAuUsageAuditWorkspaceAuUsageAuditGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Fetch all workspace AU usage information.
     * @summary Get Workspace Au Usages
     * @param {string} [workspaceName]
     * @param {boolean} [showBreakdown]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkspaceAuUsagesWorkspaceAuUsageGet(
      workspaceName?: string,
      showBreakdown?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkspaceAUUsagesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWorkspaceAuUsagesWorkspaceAuUsageGet(
          workspaceName,
          showBreakdown,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkspaceApi.getWorkspaceAuUsagesWorkspaceAuUsageGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Workspace Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkspaceSettingsWorkspaceSettingsGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WorkspaceSettings>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWorkspaceSettingsWorkspaceSettingsGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkspaceApi.getWorkspaceSettingsWorkspaceSettingsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Workspace
     * @param {number} workspaceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkspaceWorkspacesWorkspaceUidGet(
      workspaceUid: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetWorkspaceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWorkspaceWorkspacesWorkspaceUidGet(
          workspaceUid,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkspaceApi.getWorkspaceWorkspacesWorkspaceUidGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List workspaces that the requesting user has access to  Parameters ---------- workspace_name : Workspace name to filter workspaces by roles : List of roles to filter workspaces by. The requesting user must have at least one of the roles within a workspace for it to be included in the response. If not provided, all workspaces the requesting user has access to view will be included in the response.  Returns ------- List of workspaces
     * @summary List Workspaces
     * @param {string} [workspaceName]
     * @param {Array<UserRole>} [roles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listWorkspacesWorkspacesGet(
      workspaceName?: string,
      roles?: Array<UserRole>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ListWorkspaceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listWorkspacesWorkspacesGet(
          workspaceName,
          roles,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['WorkspaceApi.listWorkspacesWorkspacesGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Workspace Roles
     * @param {number} workspaceUid
     * @param {PatchWorkspaceRolesPayload} patchWorkspaceRolesPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch(
      workspaceUid: number,
      patchWorkspaceRolesPayload: PatchWorkspaceRolesPayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch(
          workspaceUid,
          patchWorkspaceRolesPayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkspaceApi.patchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Put Workspace
     * @param {number} workspaceUid
     * @param {PutWorkspacePayload} putWorkspacePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putWorkspaceWorkspacesWorkspaceUidPut(
      workspaceUid: number,
      putWorkspacePayload: PutWorkspacePayload,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putWorkspaceWorkspacesWorkspaceUidPut(
          workspaceUid,
          putWorkspacePayload,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkspaceApi.putWorkspaceWorkspacesWorkspaceUidPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Update Workspace Settings
     * @param {WorkspaceSettings} workspaceSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateWorkspaceSettingsWorkspaceSettingsPut(
      workspaceSettings: WorkspaceSettings,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateWorkspaceSettingsWorkspaceSettingsPut(
          workspaceSettings,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'WorkspaceApi.updateWorkspaceSettingsWorkspaceSettingsPut'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * WorkspaceApi - factory interface
 * @export
 */
export const WorkspaceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WorkspaceApiFp(configuration);
  return {
    /**
     *
     * @summary Create Workspace
     * @param {WorkspaceApiCreateWorkspaceWorkspacesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWorkspaceWorkspacesPost(
      requestParameters: WorkspaceApiCreateWorkspaceWorkspacesPostRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CreateWorkspaceResponse> {
      return localVarFp
        .createWorkspaceWorkspacesPost(
          requestParameters.createWorkspacePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete
     * @param {WorkspaceApiDeleteWorkspacesWorkspaceUidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteWorkspacesWorkspaceUidDelete(
      requestParameters: WorkspaceApiDeleteWorkspacesWorkspaceUidDeleteRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteWorkspacesWorkspaceUidDelete(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch workspace AU usage audit information.
     * @summary Get Workspace Au Usage Audit
     * @param {WorkspaceApiGetWorkspaceAuUsageAuditWorkspaceAuUsageAuditGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceAuUsageAuditWorkspaceAuUsageAuditGet(
      requestParameters: WorkspaceApiGetWorkspaceAuUsageAuditWorkspaceAuUsageAuditGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<WorkspaceAUUsageAuditResponse> {
      return localVarFp
        .getWorkspaceAuUsageAuditWorkspaceAuUsageAuditGet(
          requestParameters.workspaceName,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Fetch all workspace AU usage information.
     * @summary Get Workspace Au Usages
     * @param {WorkspaceApiGetWorkspaceAuUsagesWorkspaceAuUsageGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceAuUsagesWorkspaceAuUsageGet(
      requestParameters: WorkspaceApiGetWorkspaceAuUsagesWorkspaceAuUsageGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<WorkspaceAUUsagesResponse> {
      return localVarFp
        .getWorkspaceAuUsagesWorkspaceAuUsageGet(
          requestParameters.workspaceName,
          requestParameters.showBreakdown,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Workspace Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceSettingsWorkspaceSettingsGet(
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<WorkspaceSettings> {
      return localVarFp
        .getWorkspaceSettingsWorkspaceSettingsGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Workspace
     * @param {WorkspaceApiGetWorkspaceWorkspacesWorkspaceUidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkspaceWorkspacesWorkspaceUidGet(
      requestParameters: WorkspaceApiGetWorkspaceWorkspacesWorkspaceUidGetRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetWorkspaceResponse> {
      return localVarFp
        .getWorkspaceWorkspacesWorkspaceUidGet(
          requestParameters.workspaceUid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * List workspaces that the requesting user has access to  Parameters ---------- workspace_name : Workspace name to filter workspaces by roles : List of roles to filter workspaces by. The requesting user must have at least one of the roles within a workspace for it to be included in the response. If not provided, all workspaces the requesting user has access to view will be included in the response.  Returns ------- List of workspaces
     * @summary List Workspaces
     * @param {WorkspaceApiListWorkspacesWorkspacesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listWorkspacesWorkspacesGet(
      requestParameters: WorkspaceApiListWorkspacesWorkspacesGetRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ListWorkspaceResponse> {
      return localVarFp
        .listWorkspacesWorkspacesGet(
          requestParameters.workspaceName,
          requestParameters.roles,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Workspace Roles
     * @param {WorkspaceApiPatchWorkspaceRolesWorkspacesWorkspaceUidRolesPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch(
      requestParameters: WorkspaceApiPatchWorkspaceRolesWorkspacesWorkspaceUidRolesPatchRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .patchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch(
          requestParameters.workspaceUid,
          requestParameters.patchWorkspaceRolesPayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Put Workspace
     * @param {WorkspaceApiPutWorkspaceWorkspacesWorkspaceUidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putWorkspaceWorkspacesWorkspaceUidPut(
      requestParameters: WorkspaceApiPutWorkspaceWorkspacesWorkspaceUidPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .putWorkspaceWorkspacesWorkspaceUidPut(
          requestParameters.workspaceUid,
          requestParameters.putWorkspacePayload,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update Workspace Settings
     * @param {WorkspaceApiUpdateWorkspaceSettingsWorkspaceSettingsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWorkspaceSettingsWorkspaceSettingsPut(
      requestParameters: WorkspaceApiUpdateWorkspaceSettingsWorkspaceSettingsPutRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .updateWorkspaceSettingsWorkspaceSettingsPut(
          requestParameters.workspaceSettings,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createWorkspaceWorkspacesPost operation in WorkspaceApi.
 * @export
 * @interface WorkspaceApiCreateWorkspaceWorkspacesPostRequest
 */
export interface WorkspaceApiCreateWorkspaceWorkspacesPostRequest {
  /**
   *
   * @type {CreateWorkspacePayload}
   * @memberof WorkspaceApiCreateWorkspaceWorkspacesPost
   */
  readonly createWorkspacePayload: CreateWorkspacePayload;
}

/**
 * Request parameters for deleteWorkspacesWorkspaceUidDelete operation in WorkspaceApi.
 * @export
 * @interface WorkspaceApiDeleteWorkspacesWorkspaceUidDeleteRequest
 */
export interface WorkspaceApiDeleteWorkspacesWorkspaceUidDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof WorkspaceApiDeleteWorkspacesWorkspaceUidDelete
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for getWorkspaceAuUsageAuditWorkspaceAuUsageAuditGet operation in WorkspaceApi.
 * @export
 * @interface WorkspaceApiGetWorkspaceAuUsageAuditWorkspaceAuUsageAuditGetRequest
 */
export interface WorkspaceApiGetWorkspaceAuUsageAuditWorkspaceAuUsageAuditGetRequest {
  /**
   *
   * @type {string}
   * @memberof WorkspaceApiGetWorkspaceAuUsageAuditWorkspaceAuUsageAuditGet
   */
  readonly workspaceName?: string;
}

/**
 * Request parameters for getWorkspaceAuUsagesWorkspaceAuUsageGet operation in WorkspaceApi.
 * @export
 * @interface WorkspaceApiGetWorkspaceAuUsagesWorkspaceAuUsageGetRequest
 */
export interface WorkspaceApiGetWorkspaceAuUsagesWorkspaceAuUsageGetRequest {
  /**
   *
   * @type {string}
   * @memberof WorkspaceApiGetWorkspaceAuUsagesWorkspaceAuUsageGet
   */
  readonly workspaceName?: string;

  /**
   *
   * @type {boolean}
   * @memberof WorkspaceApiGetWorkspaceAuUsagesWorkspaceAuUsageGet
   */
  readonly showBreakdown?: boolean;
}

/**
 * Request parameters for getWorkspaceWorkspacesWorkspaceUidGet operation in WorkspaceApi.
 * @export
 * @interface WorkspaceApiGetWorkspaceWorkspacesWorkspaceUidGetRequest
 */
export interface WorkspaceApiGetWorkspaceWorkspacesWorkspaceUidGetRequest {
  /**
   *
   * @type {number}
   * @memberof WorkspaceApiGetWorkspaceWorkspacesWorkspaceUidGet
   */
  readonly workspaceUid: number;
}

/**
 * Request parameters for listWorkspacesWorkspacesGet operation in WorkspaceApi.
 * @export
 * @interface WorkspaceApiListWorkspacesWorkspacesGetRequest
 */
export interface WorkspaceApiListWorkspacesWorkspacesGetRequest {
  /**
   *
   * @type {string}
   * @memberof WorkspaceApiListWorkspacesWorkspacesGet
   */
  readonly workspaceName?: string;

  /**
   *
   * @type {Array<UserRole>}
   * @memberof WorkspaceApiListWorkspacesWorkspacesGet
   */
  readonly roles?: Array<UserRole>;
}

/**
 * Request parameters for patchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch operation in WorkspaceApi.
 * @export
 * @interface WorkspaceApiPatchWorkspaceRolesWorkspacesWorkspaceUidRolesPatchRequest
 */
export interface WorkspaceApiPatchWorkspaceRolesWorkspacesWorkspaceUidRolesPatchRequest {
  /**
   *
   * @type {number}
   * @memberof WorkspaceApiPatchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch
   */
  readonly workspaceUid: number;

  /**
   *
   * @type {PatchWorkspaceRolesPayload}
   * @memberof WorkspaceApiPatchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch
   */
  readonly patchWorkspaceRolesPayload: PatchWorkspaceRolesPayload;
}

/**
 * Request parameters for putWorkspaceWorkspacesWorkspaceUidPut operation in WorkspaceApi.
 * @export
 * @interface WorkspaceApiPutWorkspaceWorkspacesWorkspaceUidPutRequest
 */
export interface WorkspaceApiPutWorkspaceWorkspacesWorkspaceUidPutRequest {
  /**
   *
   * @type {number}
   * @memberof WorkspaceApiPutWorkspaceWorkspacesWorkspaceUidPut
   */
  readonly workspaceUid: number;

  /**
   *
   * @type {PutWorkspacePayload}
   * @memberof WorkspaceApiPutWorkspaceWorkspacesWorkspaceUidPut
   */
  readonly putWorkspacePayload: PutWorkspacePayload;
}

/**
 * Request parameters for updateWorkspaceSettingsWorkspaceSettingsPut operation in WorkspaceApi.
 * @export
 * @interface WorkspaceApiUpdateWorkspaceSettingsWorkspaceSettingsPutRequest
 */
export interface WorkspaceApiUpdateWorkspaceSettingsWorkspaceSettingsPutRequest {
  /**
   *
   * @type {WorkspaceSettings}
   * @memberof WorkspaceApiUpdateWorkspaceSettingsWorkspaceSettingsPut
   */
  readonly workspaceSettings: WorkspaceSettings;
}

/**
 * WorkspaceApi - object-oriented interface
 * @export
 * @class WorkspaceApi
 * @extends {BaseAPI}
 */
export class WorkspaceApi extends BaseAPI {
  /**
   *
   * @summary Create Workspace
   * @param {WorkspaceApiCreateWorkspaceWorkspacesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkspaceApi
   */
  public createWorkspaceWorkspacesPost(
    requestParameters: WorkspaceApiCreateWorkspaceWorkspacesPostRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkspaceApiFp(this.configuration)
      .createWorkspaceWorkspacesPost(
        requestParameters.createWorkspacePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete
   * @param {WorkspaceApiDeleteWorkspacesWorkspaceUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkspaceApi
   */
  public deleteWorkspacesWorkspaceUidDelete(
    requestParameters: WorkspaceApiDeleteWorkspacesWorkspaceUidDeleteRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkspaceApiFp(this.configuration)
      .deleteWorkspacesWorkspaceUidDelete(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch workspace AU usage audit information.
   * @summary Get Workspace Au Usage Audit
   * @param {WorkspaceApiGetWorkspaceAuUsageAuditWorkspaceAuUsageAuditGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkspaceApi
   */
  public getWorkspaceAuUsageAuditWorkspaceAuUsageAuditGet(
    requestParameters: WorkspaceApiGetWorkspaceAuUsageAuditWorkspaceAuUsageAuditGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return WorkspaceApiFp(this.configuration)
      .getWorkspaceAuUsageAuditWorkspaceAuUsageAuditGet(
        requestParameters.workspaceName,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Fetch all workspace AU usage information.
   * @summary Get Workspace Au Usages
   * @param {WorkspaceApiGetWorkspaceAuUsagesWorkspaceAuUsageGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkspaceApi
   */
  public getWorkspaceAuUsagesWorkspaceAuUsageGet(
    requestParameters: WorkspaceApiGetWorkspaceAuUsagesWorkspaceAuUsageGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return WorkspaceApiFp(this.configuration)
      .getWorkspaceAuUsagesWorkspaceAuUsageGet(
        requestParameters.workspaceName,
        requestParameters.showBreakdown,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Workspace Settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkspaceApi
   */
  public getWorkspaceSettingsWorkspaceSettingsGet(
    options?: RawAxiosRequestConfig,
  ) {
    return WorkspaceApiFp(this.configuration)
      .getWorkspaceSettingsWorkspaceSettingsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Workspace
   * @param {WorkspaceApiGetWorkspaceWorkspacesWorkspaceUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkspaceApi
   */
  public getWorkspaceWorkspacesWorkspaceUidGet(
    requestParameters: WorkspaceApiGetWorkspaceWorkspacesWorkspaceUidGetRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkspaceApiFp(this.configuration)
      .getWorkspaceWorkspacesWorkspaceUidGet(
        requestParameters.workspaceUid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * List workspaces that the requesting user has access to  Parameters ---------- workspace_name : Workspace name to filter workspaces by roles : List of roles to filter workspaces by. The requesting user must have at least one of the roles within a workspace for it to be included in the response. If not provided, all workspaces the requesting user has access to view will be included in the response.  Returns ------- List of workspaces
   * @summary List Workspaces
   * @param {WorkspaceApiListWorkspacesWorkspacesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkspaceApi
   */
  public listWorkspacesWorkspacesGet(
    requestParameters: WorkspaceApiListWorkspacesWorkspacesGetRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return WorkspaceApiFp(this.configuration)
      .listWorkspacesWorkspacesGet(
        requestParameters.workspaceName,
        requestParameters.roles,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Workspace Roles
   * @param {WorkspaceApiPatchWorkspaceRolesWorkspacesWorkspaceUidRolesPatchRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkspaceApi
   */
  public patchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch(
    requestParameters: WorkspaceApiPatchWorkspaceRolesWorkspacesWorkspaceUidRolesPatchRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkspaceApiFp(this.configuration)
      .patchWorkspaceRolesWorkspacesWorkspaceUidRolesPatch(
        requestParameters.workspaceUid,
        requestParameters.patchWorkspaceRolesPayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Put Workspace
   * @param {WorkspaceApiPutWorkspaceWorkspacesWorkspaceUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkspaceApi
   */
  public putWorkspaceWorkspacesWorkspaceUidPut(
    requestParameters: WorkspaceApiPutWorkspaceWorkspacesWorkspaceUidPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkspaceApiFp(this.configuration)
      .putWorkspaceWorkspacesWorkspaceUidPut(
        requestParameters.workspaceUid,
        requestParameters.putWorkspacePayload,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Workspace Settings
   * @param {WorkspaceApiUpdateWorkspaceSettingsWorkspaceSettingsPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkspaceApi
   */
  public updateWorkspaceSettingsWorkspaceSettingsPut(
    requestParameters: WorkspaceApiUpdateWorkspaceSettingsWorkspaceSettingsPutRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return WorkspaceApiFp(this.configuration)
      .updateWorkspaceSettingsWorkspaceSettingsPut(
        requestParameters.workspaceSettings,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}
