import type { SamlIdpSettings, SamlSpSettings, SsoSettings } from '@api/tdm';
import { usePageRegionAlerts } from '@components/PageRegion';
import useCachedRequest from '@hooks/useCachedRequest';
import getNotificationMetaData from '@hooks/useMakeOnRequestFailed/utils/getNotificationMetaData';
import useRequest from '@hooks/useRequestWithLogging';
import type { RequestError } from '@utils/api';
import { ssoApi } from '@utils/api/serverRequests';
import getErrorMessage from '@utils/getErrorMessage';

export const defaultSamlIDPSettings: SamlIdpSettings = {
  entity_id: '',
  sso_url: '',
  sso_binding: '',
  x509_cert: '',
};
export const defaultSamlSpSettings: SamlSpSettings = {
  entity_id: '',
  acs_binding: '',
  acs_url: '',
  name_id_format: '',
  signed_response: false,
  x509_cert: '',
};
export const defaultSsoSettings: SsoSettings = {
  sso_enabled: false,
  sso_required: false,
};

const useSAMLSettings = () => {
  const request = useRequest();
  const { showErrorAlert, showSuccessAlert } = usePageRegionAlerts();
  const ssoSettings = useCachedRequest(ssoApi.getSsoSettingsSsoSettingsGet, {});
  const samlSettings = useCachedRequest(
    ssoApi.getSamlSettingsSsoSettingsSamlGet,
    {},
  );

  const showError = (e: RequestError, message: string) => {
    showErrorAlert({
      message: getErrorMessage(e, message),
      metaData: getNotificationMetaData(e, {}),
      origin: 'useSAMLSettings',
    });
  };

  const reloadSettings = () => {
    ssoSettings.mutate();
    samlSettings.mutate();
  };

  const handleSamlSubmit = async (
    newSamlIDPSettings: SamlIdpSettings,
    newSsoSettings: SsoSettings,
  ) => {
    if (newSsoSettings.sso_enabled) {
      try {
        await request(
          ssoApi.samlSettingsSsoSettingsSamlPost,
          { samlIdpSettings: newSamlIDPSettings },
          { surfaceError: true },
        );
      } catch (e: any) {
        showError(e, 'Failed to save SAML IDP settings');
        reloadSettings();

        return;
      }
    }

    try {
      await request(
        ssoApi.ssoSettingsSsoSettingsPost,
        { ssoSettings: newSsoSettings },
        { surfaceError: true },
      );
    } catch (e: any) {
      showError(e, 'Failed to save SSO settings');
      reloadSettings();

      return;
    }

    reloadSettings();
    showSuccessAlert({
      message: 'SSO settings saved',
      origin: 'useSAMLSettings',
    });
  };

  return {
    loading: ssoSettings.isLoading || samlSettings.isLoading,
    ssoSettings: ssoSettings.data || defaultSsoSettings,
    samlIDPSettings: samlSettings.data?.idp_settings || defaultSamlIDPSettings,
    samlSPSettings: samlSettings.data?.sp_settings || defaultSamlSpSettings,
    handleSamlSubmit,
  };
};

export default useSAMLSettings;
