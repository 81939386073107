import { createPopper, flip, preventOverflow } from '@popperjs/core';
import type { ReactNode, Ref } from 'react';
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import OverlayContent from './OverlayContent';
import type { ArrowOptions } from './types';
import { OverlayElevation, OverlayPlacement } from './types';

import Icon from '../../components/Icon';
import useOutside from '../../hooks/useOutside';

export const DEFAULT_OFFSET = 0;

const ElementsThatCannotAcceptChildren = [
  Icon,
  'input',
  'textarea',
  'img',
  'meta',
  'embed',
  'br',
  'base',
  'source',
  'Tooltip',
];

export type OverlayProps = {
  children: ReactNode;
  content: ReactNode;
  open?: boolean;
  arrow?: ArrowOptions;
  offset?: number;
  placement?: OverlayPlacement;
  clickable?: boolean;
  static?: boolean;
  elevation?: OverlayElevation;
  id?: string;
  onBlur?: VoidFunction;
  shadow?: boolean;
  rounded?: boolean;
  inline?: boolean;
};

export type OverlayRef = {
  close: VoidFunction;
  open: VoidFunction;
  toggle: VoidFunction;
  isOpen: boolean;
  update: VoidFunction;
};

const Overlay = forwardRef(
  (props: OverlayProps, forwardedRef: Ref<OverlayRef>) => {
    const {
      elevation = OverlayElevation.OVERLAY,
      children,
      content,
      open,
      arrow,
      clickable,
      static: isStatic,
      offset = DEFAULT_OFFSET,
      placement = OverlayPlacement.BOTTOM,
      id,
      onBlur,
      shadow,
      rounded,
      inline = true,
    } = props;
    const popperRef = useRef<ReturnType<typeof createPopper>>();
    const ref = useRef<HTMLSpanElement>(null);
    const containerRef = useRef<HTMLSpanElement>(null);
    const arrowRef = useRef<HTMLDivElement>(null);

    const showTooltip = useCallback(async () => {
      if (!ref.current || !containerRef.current) {
        return;
      }

      if (!popperRef.current) {
        popperRef.current = createPopper(containerRef.current, ref.current, {
          placement,
          modifiers: [
            flip,
            preventOverflow,
            { name: 'offset', options: { offset: [0, offset] } },
            ...(arrow && arrowRef.current
              ? [{ name: 'arrow', options: { element: arrowRef.current } }]
              : []),
          ],
        });
      }

      if (ref.current.style) {
        ref.current.style.display = 'block';

        if (popperRef.current) {
          popperRef.current.forceUpdate();
        }
      }
    }, [placement, offset, arrow]);

    const hideTooltip = () => {
      if (!(ref.current && ref.current.style)) return;

      ref.current.style.display = 'none';
    };

    const toggleTooltip = () => {
      if (!(ref.current && ref.current.style)) return;

      if (ref.current.style.display === 'none') {
        showTooltip();

        return;
      }

      hideTooltip();
    };

    useEffect(() => {
      if (open) {
        showTooltip();

        return undefined;
      }

      hideTooltip();

      return () => {
        if (popperRef.current) {
          popperRef.current.destroy();
          popperRef.current = undefined;
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, showTooltip]);

    useOutside(
      [containerRef, ref],
      () => {
        if (clickable) {
          hideTooltip();

          if (onBlur) onBlur();
        }
      },
      undefined,
      () => Array.from(document.getElementsByClassName('tooltip-content')),
    );

    useImperativeHandle(forwardedRef, () => ({
      toggle: toggleTooltip,
      close: hideTooltip,
      isOpen: ref?.current?.style.display === 'block',
      open: showTooltip,
      update: () => {
        if (popperRef.current) {
          popperRef.current.forceUpdate();
        }
      },
    }));

    const clickEvents = clickable
      ? { onClick: toggleTooltip }
      : {
          onPointerEnter: showTooltip,
          onPointerLeave: hideTooltip,
          onFocus: showTooltip,
          onBlur: () => {
            hideTooltip();
            if (onBlur) onBlur();
          },
        };

    const actions = isStatic ? {} : clickEvents;
    const allContainerProps = { ...actions, 'aria-labelledby': id };

    return isValidElement(children) &&
      !ElementsThatCannotAcceptChildren.includes(children.type as any) ? (
      <>
        <>
          {cloneElement(
            children,
            { ref: containerRef, ...allContainerProps } as any,
            <>{Children.only(children).props.children}</>,
          )}
        </>
        <OverlayContent
          id={id}
          clickable={clickable}
          elevation={elevation}
          arrow={arrow}
          arrowRef={arrowRef}
          content={content}
          ref={ref}
          open={open}
          shadow={shadow}
          rounded={rounded}
        />
      </>
    ) : (
      <>
        <span
          data-cy="overlay"
          ref={containerRef}
          {...allContainerProps}
          className={inline ? 'inline-block' : ''}
        >
          {children}
        </span>
        <OverlayContent
          id={id}
          clickable={clickable}
          elevation={elevation}
          arrow={arrow}
          arrowRef={arrowRef}
          content={content}
          ref={ref}
          open={open}
          shadow={shadow}
          rounded={rounded}
        />
      </>
    );
  },
);

export { OverlayPlacement };
export default Overlay;
