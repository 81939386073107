import type { Middleware, Reducer, Store } from 'redux';
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from 'redux';

import type { GetCurrentUserResponse, ListWorkspaceResponse } from '@api/tdm';
import { initialState as initialStudioPageState } from '@app/StudioPage/state/initialState';
import { DEFAULT_WORKSPACE, DEFAULT_WORKSPACE_UID } from '@core/constants';
import { createControllerMiddleware } from '@utils/redux/controller';

import { rootController } from './controller';
import { rootReducer } from './reducer';
import { initialState as initialAnnotationState } from './reducers/annotation/slice';
import { initialState as initialAppDetailsState } from './reducers/appDetails/slice';
import type { AppDetailsState } from './reducers/appDetails/types';
import { initialState as initialAtomsState } from './reducers/atoms/slice';
import { initialState as initialAuthState } from './reducers/auth/slice';
import { initialState as initialCandidateState } from './reducers/candidate/slice';
import { initialState as initialDataState } from './reducers/data/slice';
import { initialState as initialDatasetsState } from './reducers/datasets/slice';
import { initialState as initialDeploymentState } from './reducers/deployment/slice';
import { initialState as initialFlagsState } from './reducers/flags/slice';
import { initialState as initialHighlightsState } from './reducers/highlights/slice';
import { initialState as initialJobsState } from './reducers/jobs/slice';
import { initialState as initialLfComposerState } from './reducers/lfComposer/slice';
import { initialState as initialLfsState } from './reducers/lfs/slice';
import { initialState as initialLfTableState } from './reducers/lfTable/slice';
import { initialState as initialManagerPageState } from './reducers/managerPage/initialState';
import { initialState as initialNavigationState } from './reducers/navigation/slice';
import { initialState as initialNodeDetailsState } from './reducers/nodeDetails/slice';
import { initialState as initialNotificationsState } from './reducers/notifications/slice';
import { initialState as initialOnboardingState } from './reducers/onboarding/slice';
import { initialState as initialPreprocessedDataState } from './reducers/preprocessedData/slice';
import { initialState as initialSandboxState } from './reducers/sandbox/slice';
import { initialState as initialTagsState } from './reducers/tags/slice';
import { initialState as initialTaskState } from './reducers/task/slice';
import { initialState as initialUserPrefsState } from './reducers/userPrefs/slice';
import { initialState as initialUsersState } from './reducers/users/slice';
import type { UserSettings } from './reducers/userSettings/slice';
import { initialState as initialUserSettigsState } from './reducers/userSettings/slice';
import { initialState as initialUxState } from './reducers/ux/slice';
import { initialState as initialWaveState } from './reducers/wave/slice';
import { initialState as initialWorkspacesState } from './reducers/workspaces/slice';
import type { RootState } from './types';
import persistState from './utils/persistState';

// -------------------------------------------------------------------------
const isDevelopment = process.env.NODE_ENV === 'development';

const initialState: RootState = {
  annotation: initialAnnotationState,
  appDetails: initialAppDetailsState,
  atoms: initialAtomsState,
  auth: initialAuthState,
  candidate: initialCandidateState,
  data: initialDataState,
  datasets: initialDatasetsState,
  deployment: initialDeploymentState,
  flags: initialFlagsState,
  highlights: initialHighlightsState,
  jobs: initialJobsState,
  lfComposer: initialLfComposerState,
  lfs: initialLfsState,
  lfTable: initialLfTableState,
  navigation: initialNavigationState,
  nodeDetails: initialNodeDetailsState,
  notifications: initialNotificationsState,
  onboarding: initialOnboardingState,
  preprocessedData: initialPreprocessedDataState,
  sandbox: initialSandboxState,
  studioPage: initialStudioPageState,
  managerPage: initialManagerPageState,
  tags: initialTagsState,
  task: initialTaskState,
  userPrefs: initialUserPrefsState,
  users: initialUsersState,
  userSettings: initialUserSettigsState,
  ux: initialUxState,
  wave: initialWaveState,
  workspaces: initialWorkspacesState,
};

// -------------------------------------------------------------------------
export const configureStore = (
  preloadedState: RootState | undefined = undefined,
): Store<RootState> => {
  const middlewares: Middleware[] = [
    createControllerMiddleware(rootController),
  ];

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers =
    typeof window === 'undefined'
      ? [middlewareEnhancer]
      : [middlewareEnhancer, persistState()];

  const composedEnhancers = (
    (isDevelopment &&
      typeof window === 'object' &&
      typeof (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ===
        'function' &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) ||
    compose
  )(...enhancers);

  return createStore(
    rootReducer as Reducer<RootState>,
    preloadedState,
    composedEnhancers,
  );
};

// -------------------------------------------------------------------------
// the store is declared as a global variable
// so getState can be exported
let store: Store<RootState>;

export const getState = () => store?.getState();

export type PreFetchedData = {
  existingUser: GetCurrentUserResponse;
  userSettings?: UserSettings;
  application?: AppDetailsState;
  workspaces?: ListWorkspaceResponse;
  flags?: Record<string, boolean>;
};

// populating the redux store with data that we fetched
// server side to prevent incorrect UI state rendered
// with default values
const createInitialStateFromFetchedData = (
  pageProps?: PreFetchedData,
): RootState => {
  if (!pageProps) {
    return initialState;
  }

  // on an application page, the selected workspace should be
  // the workspace related to the application
  const selectedWorkspaceId =
    (pageProps?.application
      ? pageProps?.application?.workspaceUid
      : pageProps?.userSettings?.global_preferences?.saved_workspace) ||
    DEFAULT_WORKSPACE_UID;

  const selectedWorkspace =
    pageProps?.workspaces?.workspaces.find(
      w => w.workspace_uid === selectedWorkspaceId,
    ) || DEFAULT_WORKSPACE;

  return {
    ...initialState,
    ...(pageProps.flags ? { flags: pageProps.flags } : {}),
    ...(pageProps.existingUser
      ? {
          auth: {
            ...initialAuthState,
            user: pageProps.existingUser,
          },
        }
      : {}),
    ...(pageProps.userSettings
      ? {
          userSettings: {
            ...initialUserSettigsState,
            settings: pageProps.userSettings,
            isFetching: false,
          },
        }
      : {}),
    ...(pageProps.workspaces
      ? {
          workspaces: {
            ...initialWorkspacesState,
            workspaces: pageProps?.workspaces?.workspaces,
            selectedWorkspace,
          },
        }
      : {}),
    ...(pageProps.application
      ? {
          appDetails: {
            ...initialAppDetailsState,
            ...pageProps.application,
          },
        }
      : {}),
  };
};

export const makeStore = <T extends PreFetchedData>(pageProps?: T) => {
  // on the client side, we want to initialize the store only once
  // on the server side, we want to create a new store for every request
  // so clients don't share the same initial store
  if (!store || (store && typeof window === 'undefined')) {
    store = configureStore(createInitialStateFromFetchedData(pageProps));
  }

  return store;
};
