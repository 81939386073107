import { UserRole, UserView } from '@api/tdm';
import { createReducerSlice } from '@utils/redux/reducer';
import type { SupportedFeatures } from '@utils/supportedFeatures';
import { defaultSupportedFeatures } from '@utils/supportedFeatures';

import type { AuthState } from './types';

export const loggedOutUserUid = -1;

const initialUser: AuthState['user'] = {
  username: '',
  user_uid: loggedOutUserUid,
  role: UserRole.Standard,
  user_roles: [],
  default_view: UserView.Standard,
  timezone: '',
};

export const initialState: AuthState = {
  user: initialUser,
  isLoggingOut: false,
  supportedFeatures: defaultSupportedFeatures,
};

const reducerSlice = createReducerSlice('auth', initialState, {
  setIsLoggingOut(
    state: AuthState,
    isLoggingOut: AuthState['isLoggingOut'],
  ): AuthState {
    return isLoggingOut === state.isLoggingOut
      ? state
      : { ...state, isLoggingOut };
  },

  setUser(state: AuthState, user: AuthState['user']): AuthState {
    return user === state.user ? state : { ...state, user };
  },

  setUserRole(state: AuthState, role: AuthState['user']['role']): AuthState {
    return role === state.user.role
      ? state
      : { ...state, user: { ...state.user, role } };
  },

  setSupportedFeatures(
    state: AuthState,
    supportedFeatures: SupportedFeatures,
  ): AuthState {
    return supportedFeatures === state.supportedFeatures
      ? state
      : { ...state, supportedFeatures };
  },

  setUserTimezone(
    state: AuthState,
    timezone: AuthState['user']['timezone'],
  ): AuthState {
    return timezone === state.user.timezone
      ? state
      : { ...state, user: { ...state.user, timezone } };
  },
});

export const { reducer, actionCreators } = reducerSlice;

export default reducerSlice;
