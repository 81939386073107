export enum GraphCombiner {
  AND = '$AND',
  OR = '$OR',
}

export const GraphCombinersList = [GraphCombiner.AND, GraphCombiner.OR];
export const NOT = '$NOT';
// Index of the template. Will be an array with NOT as first value if negated
export type GraphBuilder = [typeof NOT, number] | number;
// Contains either the above GraphBuilder or an array of GraphBuilders with a GraphCombiner used to combine them
export type GraphBlock = [GraphCombiner, ...GraphBuilder[]] | GraphBuilder;
// Contains an array of GraphBlocks and GraphCombiner used to combine them
export type GraphStructure = [GraphCombiner, ...GraphBlock[]];
export const DEFAULT_GRAPH: GraphStructure = [GraphCombiner.AND, 0];
